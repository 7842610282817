import React, { useState } from "react";
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalKind,
  SelectedIcon,
  themedStyled,
  UnselectedIcon,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import { KIND } from "baseui/button";
import { KIND as AlertKind } from "baseui/notification";
import { LabelLarge, LabelMedium } from "baseui/typography";
import { SIZE } from "baseui/input";
import { withStyle } from "baseui";
import { ModalFooterWrapper } from "../ModalFooterWrapper";
import {
  ConfirmChangesModalProps,
  ListProps,
  ReasonCodeInterface,
  ReasonCodeItem,
  ReasonCodes,
  ReasonListItemProps,
} from "../types/propTypes/PositionDetailsEdit.types";

const REASON_CODES: ReasonCodeInterface[] = [
  {
    title: "Fixed typos",
    code: ReasonCodes.TYPO,
  },
  {
    title: "Updated requirements",
    code: ReasonCodes.REQUIREMENTS,
  },
  {
    title: "Other",
    code: ReasonCodes.OTHER,
  },
];

// Create Reason codes list
const reasonCodes = REASON_CODES.map((reasonCode) => ({
  code: reasonCode.code,
  selected: false,
  title: reasonCode.title,
}));

const REASON_CODE_MIN_LENGTH = 5;
const REASON_CODE_MAX_LENGTH = 200;

export const ConfirmChangesModal = (props: ConfirmChangesModalProps) => {
  const [, theme] = useThemedStyletron();
  const { changes, modalOpen, onClose, onPublish } = props;
  const [list, setList] = useState<ReasonCodeItem[]>(reasonCodes);
  const [inputValue, setInputValue] = useState<string>("");
  const [reasonCode, setReasonCode] = useState<ReasonCodeItem>();
  const [charactersError, setCharactersError] = useState<boolean>(false);
  const otherReasonCode = reasonCode?.code === ReasonCodes.OTHER;
  // if "Other" reason is selected check input value length
  const publishEnabled: boolean | ReasonCodeInterface = otherReasonCode
    ? inputValue.length >= REASON_CODE_MIN_LENGTH
    : !!reasonCode;

  const onClearReasonCodeInput = () => {
    setInputValue("");
    setCharactersError(false);
  };

  const onItemSelect = (item: ReasonCodeItem) => {
    if (otherReasonCode && item.code !== ReasonCodes.OTHER) {
      // Clear the ReasonCode input field when switching
      onClearReasonCodeInput();
    }
    const newList = list.map((val: ReasonCodeItem) => ({
      ...val,
      selected: val === item,
    }));
    setReasonCode(item);
    setList(newList);
  };

  const onCloseModal = () => {
    onClose();
    setInitialState();
  };

  const onListItemRenderer = (item: ReasonCodeItem, onClick: () => void) =>
    reasonListItem({
      charactersError,
      inputValue,
      item,
      onClick,
      setCharactersError,
      setInputValue,
    });

  // if "Other" reason is selected we should take reason text from input
  const getReasonCode = (): any => {
    if (otherReasonCode) {
      return {
        ...reasonCode,
        inputValue,
      };
    }
    return reasonCode;
  };

  const setInitialState = () => {
    setList(reasonCodes);
    setInputValue("");
    onClearReasonCodeInput();
  };

  return (
    <Modal
      kind={ModalKind.DEFAULT}
      header="Confirm changes"
      body={
        <ModalBody>
          <LabelMedium $style={{ fontWeight: 400, marginBottom: theme.sizing.scale700 }}>
            Changes to {changes}
          </LabelMedium>
          <>
            <LabelLarge $style={{ marginBottom: theme.sizing.scale200 }}>
              Reason for changes (required)
            </LabelLarge>
            <List items={list} onItemClick={onItemSelect} listItemRenderer={onListItemRenderer} />
          </>
          <AlertContainer>
            <Alert type={AlertKind.warning} width="100%">
              <BoldText>
                Crew members may not see these updates in time for their next shift.
              </BoldText>{" "}
              If this is a critical update, please communicate with the crew members directly.
            </Alert>
          </AlertContainer>
        </ModalBody>
      }
      footer={
        <FooterWrapper>
          <Button onClick={onCloseModal} kind={KIND.tertiary}>
            Cancel
          </Button>
          <ContainerWithMargin>
            <Button
              disabled={!publishEnabled}
              onClick={() => {
                const selectedReason = getReasonCode();
                onPublish(selectedReason);
              }}
            >
              Publish
            </Button>
          </ContainerWithMargin>
        </FooterWrapper>
      }
      isOpen={modalOpen}
      onClose={onCloseModal}
    />
  );
};

const List = ({ items, onItemClick, listItemRenderer }: ListProps) => (
  <>
    {items?.map((item: ReasonCodeItem) => {
      const onCLick = () => onItemClick(item);
      return listItemRenderer(item, onCLick);
    })}
  </>
);

const reasonListItem = ({
  charactersError,
  inputValue,
  item,
  onClick,
  setCharactersError,
  setInputValue,
}: ReasonListItemProps) => (
  <ListItemContainer key={item.title}>
    <ListItem onClick={onClick}>
      {item.selected ? <SelectedIcon /> : <UnselectedIcon />}
      <LabelMedium $style={{ fontWeight: 400, paddingLeft: "8px", paddingRight: "8px" }}>
        {item.title}
      </LabelMedium>
    </ListItem>
    {item.code === ReasonCodes.OTHER && item.selected ? (
      <InputContainer>
        <Input
          maxLength={REASON_CODE_MAX_LENGTH}
          onFocus={() => charactersError && setCharactersError(false)}
          placeholder="Required"
          size={SIZE.mini}
          value={inputValue}
          onBlur={() => {
            const customReasonCode = inputValue.trim();
            setInputValue(customReasonCode);
            if (customReasonCode.length < 5) {
              setCharactersError(true);
            }
          }}
          onChange={(value) => setInputValue(value)}
        />
        {inputValue.trim()?.length < 5 ? (
          <ErrorText charactersError={charactersError}>
            {`Minimum of ${REASON_CODE_MIN_LENGTH} characters required`}
          </ErrorText>
        ) : null}
      </InputContainer>
    ) : null}
  </ListItemContainer>
);

const ErrorText = themedStyled<"span", { charactersError: boolean }>(
  "span",
  ({ $theme, charactersError }) => ({
    ...$theme.typography.font100,
    lineHeight: $theme.sizing.scale800,
    color: charactersError ? $theme.colors.coral : $theme.colors.stone,
    position: "absolute",
    right: 0,
    fontWeight: 600,
  }),
);

const FooterWrapper = withStyle(ModalFooterWrapper, () => ({
  justifyContent: "end",
}));

const ListItemContainer = themedStyled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const ListItem = themedStyled("div", ({ $theme }) => ({
  alignItems: "center",
  display: "flex",
  flexShrink: 0,
  padding: `${$theme.sizing.scale400} ${$theme.sizing.scale400} ${$theme.sizing.scale400} ${$theme.sizing.scale200}`,
}));

const InputContainer = themedStyled("div", () => ({
  position: "relative",
  width: "100%",
}));

const ModalBody = themedStyled("div", ({ $theme }) => ({
  marginBottom: $theme.sizing.scale500,
  marginLeft: $theme.sizing.scale600,
  marginRight: $theme.sizing.scale600,
}));

const ContainerWithMargin = themedStyled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
}));

const BoldText = themedStyled("span", () => ({
  fontWeight: 600,
}));

const AlertContainer = themedStyled("div", ({ $theme }) => ({
  margin: `${$theme.sizing.scale700} 0`,
}));
