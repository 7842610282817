import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Block } from "@bluecrew/web-react-core";

// @ts-ignore $FlowFixStyledComponents
const PositionContentBlock = styled(Block)`
  background-color: ${p("white")};
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${p("aluminum")};
  box-sizing: border-box;
  box-shadow: 0px 3px 7px rgba(100, 106, 117, 0.08);
`;

export default PositionContentBlock;
