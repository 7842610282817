import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const ChevronUpIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();

  const { color = theme.colors.accent, height = 16, width = 16 } = props;

  return (
    <svg
      width={width}
      height={height}
      style={{
        transform: 'rotate(180deg)',
      }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6778 6.85455L8.77778 11.6818C8.34822 12.1061 7.65178 12.1061 7.22222 11.6818L2.32217 6.85455C1.89261 6.4303 1.89261 5.74245 2.32217 5.31819C2.75172 4.89394 3.44817 4.89394 3.87772 5.31819L8 9.37727L12.1223 5.31819C12.5518 4.89394 13.2483 4.89394 13.6778 5.31819C14.1074 5.74245 14.1074 6.4303 13.6778 6.85455Z"
        fill={color}
      />
    </svg>
  );
};
