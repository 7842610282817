import * as React from 'react';
/* Libraries */
import { Button } from 'baseui/button';
import { StyleObject } from 'styletron-react';
import { styled, withStyle } from 'baseui';
/* Utils */
import { LabelMedium } from 'baseui/typography';
import {
  centeredItem,
  getBorder,
  getPadding,
  getMargin,
} from '../helpers/styleHelpers';
/* Assets */
import { ChevronDownIcon, SvgPropType } from '../assets';
import { themedStyled, useThemedStyletron } from '../theme';

export enum SelectionButtonKinds {
  NORMAL = 'normal',
  WITH_SUBHEADER = 'withSubheader',
}

interface SelectionButtonProps {
  placeholder: string;
  kind: SelectionButtonKinds;
  onClick: () => void;
  header?: string;
  subHeader?: string;
  buttonWidth?: string;
  error?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  dataTestId?: string;
}

export const SelectionButton = ({
  placeholder,
  kind = SelectionButtonKinds.NORMAL,
  onClick,
  header,
  subHeader,
  buttonWidth = '316px',
  icon,
  isLoading = false,
  error = false,
  disabled = false,
  dataTestId,
}: SelectionButtonProps) => {
  const isEmpty = !header;
  const [, theme] = useThemedStyletron();

  const coloredIcon =
    disabled && React.isValidElement<SvgPropType>(icon)
      ? React.cloneElement(icon, {
          color: theme.colors.pebble,
        })
      : icon;

  const renderContent = () =>
    kind === SelectionButtonKinds.WITH_SUBHEADER ? (
      <Wrapper>
        <StyledLabel>{header}</StyledLabel>
        <Text>{subHeader}</Text>
      </Wrapper>
    ) : (
      <IconWrapper>
        {icon ? (
          <>
            <IconContainer>{coloredIcon}</IconContainer>
            <PositionedText $disabled={disabled}>{header}</PositionedText>
          </>
        ) : (
          <Text $disabled={disabled}>{header}</Text>
        )}
      </IconWrapper>
    );

  return (
    <Button
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            ...rootStyles($theme, error, kind, isEmpty),
            width: buttonWidth,
          }),
          props: {
            ...(dataTestId && { 'data-testid': dataTestId }),
          },
        },
        EndEnhancer: {
          style: {
            alignItems: 'center',
            marginLeft: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
          },
        },
        LoadingSpinnerContainer: {
          style: {
            ...centeredItem,
            height: 'inherit',
            width: 'inherit',
          },
        },
      }}
      endEnhancer={
        <ChevronDownIcon
          color={disabled ? theme.colors.highlight : theme.colors.ultramarine}
        />
      }
      isLoading={isLoading}
      onClick={onClick}
      disabled={disabled}
    >
      {isEmpty ? placeholder : renderContent()}
    </Button>
  );
};

// 'const as const' is used to fix typescript compile error,
// with css prop whiteSpace: 'nowrap'
// https://stackoverflow.com/questions/46710747/type-string-is-not-assignable-to-type-inherit-initial-unset-fixe
const truncateWithEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  whiteSpace: 'nowrap',
} as const;

const overflowStyles = {
  display: 'flex',
  overflow: 'hidden',
};

const Wrapper = styled('div', ({ $theme }) => ({
  alignItems: 'flex-start',
  color: $theme.colors.primary,
  flexDirection: 'column',
  marginRight: '20px',
  textAlign: 'left',
  ...overflowStyles,
}));

const Text = themedStyled<'p', { $disabled?: boolean }>(
  'p',
  ({ $theme, $disabled }) => ({
    ...getMargin('0'),
    ...truncateWithEllipsis,
    color: $disabled ? $theme.colors.stone : $theme.colors.primary,
  }),
);

const StyledLabel = styled(LabelMedium, () => ({
  ...truncateWithEllipsis,
}));

const IconContainer = styled('div', () => ({
  display: 'flex',
}));

const PositionedText = withStyle(Text, () => ({
  marginLeft: '10px',
  marginRight: '10px',
}));

const IconWrapper = styled('div', ({ $theme }) => ({
  ...overflowStyles,
  color: $theme.colors.primary,
  justifyContent: 'center',
}));

const rootStyles = (
  $theme: any,
  error: boolean,
  kind: SelectionButtonKinds,
  isEmpty: boolean,
): StyleObject => ({
  backgroundColor: 'transparent',
  color: $theme.colors.primary500,
  ...$theme.typography.font300,
  height: `${kind === SelectionButtonKinds.WITH_SUBHEADER ? '80px' : '48px'}`,
  boxSizing: 'border-box',
  ...centeredItem,
  alignItems: `${
    kind === SelectionButtonKinds.WITH_SUBHEADER && isEmpty
      ? 'flex-start'
      : 'center'
  }`,
  ...getBorder({
    ...(error ? $theme.borders.border200 : $theme.borders.border100),
    borderColor: error ? $theme.colors.negative : $theme.colors.primary300,
  }),
  ...getPadding(
    `${
      kind === SelectionButtonKinds.WITH_SUBHEADER
        ? $theme.sizing.scale550
        : $theme.sizing.scale500
    }`,
  ),
  paddingRight: $theme.sizing.scale700,
  ':hover': {
    backgroundColor: 'none',
  },
  ':active': {
    backgroundColor: 'none',
  },
});
