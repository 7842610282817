import React from 'react';
import { SvgPropType } from './SvgPropType';
import { useThemedStyletron } from '../../theme';

export const CrewGroupIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const {
    width = 48,
    height = 48,
    color = theme.colors.ultramarine,
    stroke = theme.colors.white,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill={stroke} />
      <path
        d="M18 19.5C15.932 19.5 14.25 17.818 14.25 15.75C14.25 13.682 15.932 12 18 12C20.068 12 21.75 13.682 21.75 15.75C21.75 17.818 20.068 19.5 18 19.5ZM18 13.5C16.759 13.5 15.75 14.509 15.75 15.75C15.75 16.991 16.759 18 18 18C19.241 18 20.25 16.991 20.25 15.75C20.25 14.509 19.241 13.5 18 13.5Z"
        fill={color}
      />
      <path
        d="M15.75 36C15.363 36 15.042 35.71 15.004 35.325L14.321 28.5H12.75C12.336 28.5 12 28.164 12 27.75V25.5C12 22.192 14.692 19.5 18 19.5C21.308 19.5 24 22.192 24 25.5V27.75C24 28.164 23.664 28.5 23.25 28.5H21.679L20.997 35.325C20.958 35.71 20.637 36 20.25 36H15.75ZM19.571 34.5L20.253 27.675C20.292 27.29 20.613 27 21 27H22.5V25.5C22.5 23.019 20.481 21 18 21C15.519 21 13.5 23.019 13.5 25.5V27H15C15.387 27 15.708 27.29 15.746 27.675L16.429 34.5H19.571Z"
        fill={color}
      />
      <path
        d="M30 19.5C27.932 19.5 26.25 17.818 26.25 15.75C26.25 13.682 27.932 12 30 12C32.068 12 33.75 13.682 33.75 15.75C33.75 17.818 32.068 19.5 30 19.5ZM30 13.5C28.759 13.5 27.75 14.509 27.75 15.75C27.75 16.991 28.759 18 30 18C31.241 18 32.25 16.991 32.25 15.75C32.25 14.509 31.241 13.5 30 13.5Z"
        fill={color}
      />
      <path
        d="M27.75 35.9999C27.363 35.9999 27.042 35.7089 27.004 35.3239L26.321 28.4999H25.5C25.086 28.4999 24.75 28.1639 24.75 27.7499C24.75 27.3359 25.086 26.9999 25.5 26.9999H27C27.387 26.9999 27.708 27.2909 27.746 27.6759L28.429 34.4999H31.572L32.255 27.6749C32.292 27.2909 32.613 26.9999 33 26.9999H34.5V25.4999C34.499 24.2989 34.03 23.1689 33.179 22.3199C32.33 21.4709 31.201 21.0039 30 21.0039C28.427 21.0049 26.951 21.8409 26.143 23.1859C26.008 23.4099 25.761 23.5499 25.5 23.5499C25.364 23.5499 25.231 23.5129 25.114 23.4429C24.76 23.2299 24.644 22.7679 24.857 22.4139C25.934 20.6209 27.903 19.5059 29.995 19.5039C31.6 19.5039 33.106 20.1269 34.239 21.2579C35.373 22.3899 35.999 23.8969 36 25.4989V27.7489C36 28.1629 35.664 28.4989 35.25 28.4989H33.679L32.996 35.3239C32.958 35.7089 32.637 35.9999 32.25 35.9999H27.75Z"
        fill={color}
      />
    </svg>
  );
};
