import React, { ForwardedRef, forwardRef, Ref } from "react";
import { LabelSmall, LabelMedium } from "baseui/typography";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import { RichListItem } from "./RichListItem";
import {
  ComponentPosition,
  EditVersionListItemProps,
  EditVersionListItemT,
  ListItemRendererT,
  VerticalAlign,
} from "./types";
import { StyledTitle, StyledWeekdaysContainer } from "./styled-components";

const EditVersionListItemCmp = <T extends EditVersionListItemT>(
  { item, onClick, underlineLastItem }: EditVersionListItemProps<T>,
  ref: Ref<HTMLLIElement>,
) => (
  <RichListItem<T>
    ref={ref}
    item={item}
    checkboxPosition={ComponentPosition.LEFT}
    onClick={onClick}
    titleRenderer={titleRenderer}
    subtitleRenderer={subtitleRenderer}
    underlineLastItem={underlineLastItem}
    leftContainerContentAlign={VerticalAlign.CENTER}
    showCheckBox
  />
);

const titleRenderer: ListItemRendererT<EditVersionListItemT> = (listItem) => (
  <>
    <StyledTitle $style={{ marginRight: "0px" }} $as="span">
      {listItem.title}
    </StyledTitle>
  </>
);

const subtitleRenderer: ListItemRendererT<EditVersionListItemT> = (listItem) => (
  <StyledWeekdaysContainer style={{ color: blueprintTheme.colors.obsidian }}>
    <LabelMedium $style={{ fontWeight: 400 }}>{listItem.subtitle}</LabelMedium>
    {listItem.note ? (
      <LabelMedium $style={{ fontWeight: 400 }}>&quot;{listItem.note}&quot;</LabelMedium>
    ) : null}
    {listItem.submittedBy ? (
      <LabelSmall color={blueprintTheme.colors.slate} $style={{ fontWeight: 400 }}>
        {listItem.submittedBy}
      </LabelSmall>
    ) : null}
  </StyledWeekdaysContainer>
);

export const EditVersionListItem = forwardRef(EditVersionListItemCmp) as <
  T extends EditVersionListItemT,
>(
  props: EditVersionListItemProps<T> & { ref?: ForwardedRef<HTMLLIElement> },
) => JSX.Element;
