import { GeocodedAddressComponent, WorksiteAddress } from "./types/propTypes/Worksite.types";

/**
 * takes in addressComponent returned from geocoding api
 * https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingResults
 * grabs correct data for the endpoint query strings
 * @param  {Array} addressComponents
 */
const getAddressDetails = (addressComponents: Array<GeocodedAddressComponent>) => {
  const payload = {
    city: "",
    state: "",
    county: "",
    postalCode: "",
    countryCode: "",
    streetNumber: "",
    streetAddress: "",
    route: "",
  };
  addressComponents.forEach((component) =>
    component.types.forEach((type) => {
      if (type === "locality") {
        payload.city = component.short_name;
      } else if (type === "administrative_area_level_1") {
        payload.state = component.short_name;
      } else if (type === "administrative_area_level_2") {
        payload.county = component.short_name;
      } else if (type === "country") {
        payload.countryCode = component.short_name;
      } else if (type === "postal_code") {
        payload.postalCode = component.short_name;
      } else if (type === "street_number") {
        payload.streetNumber = component.short_name;
      } else if (type === "street_address") {
        payload.streetAddress = component.short_name;
      } else if (type === "route") {
        payload.route = component.short_name;
      }
    }),
  );
  return payload;
};

const getCustomAddress = (
  originalAddress: google.maps.places.AutocompletePrediction,
  geoCodedAddress?: google.maps.GeocoderResult,
) => {
  // After address was selected we can receive geo info which includes address zip code,
  // but this address doesn't include the Name of the place. For example "Empire State Building".
  let customAddress = geoCodedAddress
    ? geoCodedAddress.formatted_address
    : originalAddress.description;
  // Main text is the Name of the place
  const mainText = originalAddress.structured_formatting.main_text;
  if (mainText) {
    // If address string includes name of the place just skip any modifications to the string.
    // Otherwise, add name at the beginning
    const addressHasPlaceName = customAddress.includes(mainText);
    customAddress = addressHasPlaceName ? customAddress : `${mainText}, ${customAddress}`;
  }
  return customAddress;
};

export class WorksiteAddressMapper {
  static toModel = (
    originalPrediction: google.maps.places.AutocompletePrediction,
    geoCodedPrediction?: google.maps.GeocoderResult,
  ): WorksiteAddress => ({
    address: getCustomAddress(originalPrediction, geoCodedPrediction),
    ...(geoCodedPrediction && {
      lat: geoCodedPrediction.geometry.location.lat(),
      lng: geoCodedPrediction.geometry.location.lng(),
    }),
    mainText: originalPrediction.structured_formatting.main_text,
    secondaryText: originalPrediction.structured_formatting.secondary_text,
    placeId: originalPrediction.place_id,
    ...(geoCodedPrediction && getAddressDetails(geoCodedPrediction?.address_components)),
  });
}
