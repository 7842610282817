import { Block, Flex } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import styled from "styled-components";
import { CONTAINER_MAX_WIDTH } from "../../../../shared/constants";

export const ViewContainer = styled(Flex)`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  flex-flow: column;
`;

export const ViewContent = styled(Block)`
  background-color: ${p("frost")};
  padding: 0 0 2rem 0;
  flex-grow: 1;
`;

export const PositionListContainer = styled(Block)`
  // TODO:
  // - BCEMPL-661: discuss responsiveness w/ designers
  // - BCEMPL-660: create layout components
  // - BCEMPL-662: figure out solution to double scroll bars (sometimes)
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
`;
