/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
import React from "react";
import { CalendarDay } from "react-dates";
import moment from "moment-timezone";
import type { Moment } from "moment-timezone";
import memoize from "lodash/memoize";
import { Heading, Image, Paragraph, Divider, TooltipOverlay } from "@bluecrew/web-react-core";
import { Field as FormikField, ErrorMessage } from "formik";
import MultiDatePicker from "../../../components/MultiDatePicker";
import MultistepForm, { ImplicitValidatorProp } from "../../../components/MultistepForm";
import { ScheduleWorkers } from "../../../redux/selectors/schedule";
import {
  SCHED_EXC_ADD_DAY,
  SCHED_EXC_DAY_OFF,
  SCHED_EXC_EDIT_DAY,
  ScheduleException,
} from "../../../api/bluecrew/types";
import { logError } from "../../../sentry";
import { PageContainer, RevealedBlock, RevealOnHover, UnrevealedBlock } from "./styledComponents";
import { PageCopy } from "./types";

const CHANGE = "change";
const REMOVAL = "removal";

export type ModifyDatesProps = {
  dates: Array<Moment>;
  highlightedOnly: boolean;
  iconPath: string;
  pageCopy: PageCopy;
  // These are the selected workers.
  workerIds: Array<number>;
  // This is a collection of all worker data.
  crewData: ScheduleWorkers;
  // These are exceptions in the schedule.
  scheduleExceptions: null | ScheduleException[];
  validate: ImplicitValidatorProp;
  timezone: string;
};

const getIsDayHighlighted = (isHighlighted, workerExceptionsForDate) => {
  const { changeOrRemoval } = workerExceptionsForDate;

  if (changeOrRemoval.size > 0) {
    // Return false to unhighlight removals, true to
    // highlight changes (additions / edits).
    return changeOrRemoval.has(CHANGE) && changeOrRemoval.size === 1;
  }

  return isHighlighted;
};

const today = moment();
const allowPastDates = false;

function getWorkerExceptions(
  day: Moment,
  getWorkerExceptionsForDate,
  timezone,
  workers,
  exceptionsByDate,
) {
  return getWorkerExceptionsForDate(
    // Ensure the date picker date is always in job timezone.
    moment(day).tz(timezone).format("YYYY-MM-DD"),
    exceptionsByDate,
    workers,
  );
}

function dayIsActionable(day: null | Moment) {
  if (day && !allowPastDates && day.isBefore(today, "day")) {
    return false;
  }
  return true;
}

const getExceptionsByDate = (scheduleExceptions, timezone) => {
  const exceptionsByDate: {
    [date: string]: Array<ScheduleException>;
  } = {};
  if (scheduleExceptions) {
    for (const exc of scheduleExceptions) {
      // We are matching this exception date to the local date of the job/position
      const key = moment(exc.startDateTime).tz(timezone).format("YYYY-MM-DD");
      if (!exceptionsByDate[key]) {
        exceptionsByDate[key] = [];
      }
      exceptionsByDate[key].push(exc);
    }
  }

  return exceptionsByDate;
};

function _getWorkerExceptionsForDate(dateKey: string, exceptionsByDate, workers) {
  const exceptions = exceptionsByDate[dateKey] || [];
  const affectedWorkers = new Set();
  const lastExc: {
    [workerId: string]: ScheduleException;
  } = {};
  const excByJob: {
    [jobId: string]: ScheduleException;
  } = {};

  for (const exc of exceptions) {
    excByJob[String(exc.jobId)] = exc;
  }

  for (const wrk of workers) {
    // @ts-ignore
    const exc = excByJob[String(wrk.eventAppliedJobId)];
    if (!exc) {
      continue;
    }

    // @ts-ignore
    affectedWorkers.add(wrk.user_id);

    // @ts-ignore
    const workerKey = String(wrk.user_id);
    if (lastExc[workerKey] === undefined) {
      lastExc[workerKey] = exc;
    }
    if (exc.id > lastExc[workerKey].id) {
      // Use the ID to store the most recently added exception
      // for this worker.
      lastExc[workerKey] = exc;
    }
  }

  const changeOrRemoval = new Set();

  Object.keys(lastExc).forEach((k) => {
    // The effective type is the most recently created exception type.
    const exc = lastExc[k];

    // Build a set of changes and removals to make detection easier.
    if (exc.type === SCHED_EXC_ADD_DAY || exc.type === SCHED_EXC_EDIT_DAY) {
      changeOrRemoval.add(CHANGE);
    } else if (exc.type === SCHED_EXC_DAY_OFF) {
      changeOrRemoval.add(REMOVAL);
    } else {
      logError({
        error: new Error(`Unexpected schedule exception type: ${exc.type}`),
      });
    }
  });

  return { affectedWorkers, changeOrRemoval };
}

const getWorkers = (crewData, workerIds) => {
  const workers = [];
  for (const d of crewData) {
    if (workerIds.includes(d.user_id)) {
      // @ts-ignore
      workers.push(d);
    }
  }
  return workers;
};

export function ModifyDates({
  crewData,
  dates,
  highlightedOnly,
  iconPath,
  pageCopy,
  scheduleExceptions,
  workerIds,
  timezone,
}: ModifyDatesProps) {
  const workers = getWorkers(crewData, workerIds);

  const exceptionsByDate = getExceptionsByDate(scheduleExceptions, timezone);
  // For every render, create a short-lived cache per day so it can be
  // called repeatedly.
  const getWorkerExceptionsForDate = memoize(_getWorkerExceptionsForDate);

  function getHasConflicts(day: Moment) {
    const { affectedWorkers, changeOrRemoval } = getWorkerExceptions(
      day,
      getWorkerExceptionsForDate,
      timezone,
      workers,
      exceptionsByDate,
    );

    // There are conflicts if...
    return (
      // some workers were affected...
      affectedWorkers.size > 0 && // but not all workers were affected...
      (affectedWorkers.size !== workerIds.length || // ...or there were both changes *and* removals...
        changeOrRemoval.size > 1)
    );
  }

  return (
    <PageContainer key="modify-dates">
      {/* @ts-ignore */}
      <MultistepForm.Page>
        <Image className="modal-icon" src={iconPath} />
        <Heading as="h3">{pageCopy.header}</Heading>
        <Paragraph>{pageCopy.subheader}</Paragraph>
        <Divider />
        <FormikField
          name="dates"
          render={({ form: { setFieldValue } }) => (
            <MultiDatePicker
              today={today}
              isDayBlocked={(day) => {
                const hasConflicts = getHasConflicts(day);
                if (hasConflicts) {
                  const isActionable = dayIsActionable(day);
                  return isActionable;
                }
                return false;
              }}
              renderCalendarDay={({ day, modifiers, props }) => {
                let content = <CalendarDay {...props} modifiers={modifiers} />;

                if (day) {
                  const hasConflicts = getHasConflicts(day);

                  if (hasConflicts && dayIsActionable(day)) {
                    // Render a tooltip explaining why the day is blocked.
                    content = (
                      <CalendarDay
                        {...props}
                        renderDayContents={(d) => (
                          <RevealOnHover>
                            <RevealedBlock>{d.format("D")}</RevealedBlock>
                            <UnrevealedBlock>
                              <TooltipOverlay
                                // @ts-ignore
                                heading="Conflicting schedules"
                                description={`
                                        Some workers have a
                                        conflicting schedule on this day.
                                        Go back and select fewer workers
                                        to modify this day.
                                      `}
                              />
                            </UnrevealedBlock>
                          </RevealOnHover>
                        )}
                        modifiers={modifiers}
                      />
                    );
                  }
                }

                return content;
              }}
              dayIsOutsideRange={({ day, isOutside }) => {
                const isDayActionable = dayIsActionable(day);
                if (isDayActionable) {
                  const workerExceptions = getWorkerExceptions(
                    day,
                    getWorkerExceptionsForDate,
                    timezone,
                    workers,
                    exceptionsByDate,
                  );
                  if (workerExceptions.affectedWorkers.size > 0) {
                    // If there's any exception for this date
                    // (addition / edit / removal) then return
                    // false to make this date selectable.

                    return false;
                  }
                }

                return isOutside;
              }}
              dayIsHighlighted={({ day, isHighlighted }) => {
                const hasConflicts = getHasConflicts(day);

                if (hasConflicts) {
                  // This makes sure a removal action can't be
                  // performed on a blocked day.
                  return false;
                }

                const workerExceptionsForDate = getWorkerExceptions(
                  day,
                  getWorkerExceptionsForDate,
                  timezone,
                  workers,
                  exceptionsByDate,
                );

                return getIsDayHighlighted(isHighlighted, workerExceptionsForDate);
              }}
              dates={dates}
              allowDateOutsideHighlightedRange={false}
              allowPastDates={allowPastDates}
              onChange={(dates) => {
                setFieldValue("dates", dates);
              }}
              allowSelectHighlightedOnly={highlightedOnly}
            />
          )}
        />
        <ErrorMessage name="dates" render={(msg) => <div className="error-message">{msg}</div>} />
      </MultistepForm.Page>
    </PageContainer>
  );
}
