import React from "react";
import {
  CrewMemberCard,
  EmptyState,
  Tabs,
  TabWithBadge,
  Container,
  Panel,
  Block,
} from "@bluecrew/web-react-core";
import { ZeroStates } from "../../../shared/constants";
import ViewDeclines from "../ViewDeclines";
import {
  StyledCrewContainer,
  StyledEmptyStateContainer,
  StyledTabContainer,
} from "./styledComponents";

interface CrewTabsProps {
  accepted: Array<any>;
  declinedOffers: Array<any>;
  offers: Array<any>;
  timezone: any;
}

export const CrewTabs = ({ accepted, declinedOffers, offers, timezone }: CrewTabsProps) => (
  <Container>
    {(tabs) => (
      <Block>
        {/* Tabs */}
        <Tabs>
          <StyledTabContainer>
            <TabWithBadge
              tabId="first"
              classes="first"
              tabText="Accepted"
              tabs={tabs}
              badgeCount={accepted.length}
            />
          </StyledTabContainer>
          <StyledTabContainer>
            <TabWithBadge tabId="second" tabText="Offers" tabs={tabs} badgeCount={offers.length} />
          </StyledTabContainer>
        </Tabs>

        {/* Tab Panel 1 */}
        <Panel tab="first" {...tabs}>
          {accepted.length > 0 ? (
            accepted.map((user) => (
              <StyledCrewContainer key={user.external_id}>
                <CrewMemberCard
                  // @ts-ignore
                  crewMember={user}
                  status="accepted"
                  timezone={timezone}
                  // @ts-ignore
                />
              </StyledCrewContainer>
            ))
          ) : (
            <StyledEmptyStateContainer>
              <EmptyState
                icon={ZeroStates.NoJobsAccepted.icon}
                text={ZeroStates.NoJobsAccepted.text}
              />
            </StyledEmptyStateContainer>
          )}
        </Panel>

        {/* Tab Panel 2 */}
        <Panel tab="second" {...tabs}>
          {offers.length > 0 ? (
            offers.map((user) => (
              <StyledCrewContainer key={user.external_id}>
                {
                  <CrewMemberCard
                    // @ts-ignore
                    crewMember={user}
                    status="awaiting"
                    timezone={timezone}
                    // @ts-ignore
                  />
                }
              </StyledCrewContainer>
            ))
          ) : (
            <StyledEmptyStateContainer>
              <EmptyState
                icon={ZeroStates.NoJobsAccepted.icon}
                text={ZeroStates.NoJobsAccepted.text}
              />
            </StyledEmptyStateContainer>
          )}
          {/* Declined Offers List */}
          {declinedOffers.length > 0 && (
            <StyledCrewContainer>
              <ViewDeclines
                // @ts-ignore
                timezone={timezone}
                declinedOffers={declinedOffers}
              />
            </StyledCrewContainer>
          )}
        </Panel>
      </Block>
    )}
  </Container>
);
