import React from "react";
import { StyledIcon } from "../styledComponents";
import {
  PositionAddressContainer,
  PositionColumn,
  PositionInfoContainer,
  PositionNameContainer,
  PositionTimezoneContainer,
} from "./styledComponents";

const positionInfoIcon = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/position-info.svg`;

export type PositionInfoProps = {
  positionName: string;
  positionAddress: string;
  timezone: string;
};
export const PositionInfo = ({ positionName, positionAddress, timezone }: PositionInfoProps) => (
  <PositionInfoContainer>
    <StyledIcon src={positionInfoIcon} alt={""} />
    <PositionColumn>
      <PositionNameContainer>{positionName}</PositionNameContainer>
      <PositionTimezoneContainer>({timezone})</PositionTimezoneContainer>
      <PositionAddressContainer>{positionAddress}</PositionAddressContainer>
    </PositionColumn>
  </PositionInfoContainer>
);
