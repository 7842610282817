import React from 'react';
import { SvgPropType } from './SvgPropType';
import { useThemedStyletron } from '../../theme';

export const GreaterThanIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 6, height = 9, color = theme.colors.slate } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70711 0.292893L5.07107 3.65685C5.46159 4.04738 5.46159 4.68054 5.07107 5.07107L1.70711 8.43503C1.31658 8.82555 0.683418 8.82555 0.292893 8.43503C-0.0976311 8.0445 -0.0976311 7.41134 0.292893 7.02081L2.94975 4.36396L0.292893 1.70711C-0.097631 1.31658 -0.097631 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893Z"
        fill={color}
      />
    </svg>
  );
};
