import { api, apiV1 } from "../../api/bluecrew/constants/index";
import { fetchSchedule } from "./schedule";
import {
  FETCH_CREW_MEMBERS_REQUEST,
  FETCH_CREW_MEMBERS_SUCCESS,
  FETCH_CREW_MEMBERS_FAILURE,
  POST_CREW_MEMBERS_REQUEST,
  POST_CREW_MEMBERS_SUCCESS,
  POST_CREW_MEMBERS_FAILURE,
} from "../constants";
import confirmationModalActions from "./confirmationModal";
import { SimpleModalTypes } from "../../components/SimpleModal";
import { Modals } from "../../../shared/constants";

const fetchCrewMembersRequest = () => ({
  type: FETCH_CREW_MEMBERS_REQUEST,
});

const fetchCrewMembersSuccess = (data) => ({
  type: FETCH_CREW_MEMBERS_SUCCESS,
  payload: data,
});

const fetchCrewMembersFailure = (data) => ({
  type: FETCH_CREW_MEMBERS_FAILURE,
  payload: data,
});

const postCrewMembersRequest = () => ({
  type: POST_CREW_MEMBERS_REQUEST,
});

const postCrewMembersSuccess = (data) => ({
  type: POST_CREW_MEMBERS_SUCCESS,
  payload: data,
});

const postCrewMembersFailure = (data) => ({
  type: POST_CREW_MEMBERS_FAILURE,
  payload: data,
});

export type PostCrewMembersParams = {
  controlModals?: boolean;
  data: {
    scheduleId: number;
    formattedValues: {
      // This is a bitmask.
      days: number;
      endDate: string;
      // This is actually the job template's external ID.
      jobTemplateId: string;
      needed: number;
      startDate: string;
      supervisor: number;
      userIds: Array<string>;
    };
  };
};

export const postCrewMembers = (params: PostCrewMembersParams) => (dispatch) => {
  const { controlModals = true, data } = params;
  dispatch(postCrewMembersRequest());
  api
    .post(`v2/schedules/${data.scheduleId}/direct_invite`, {
      json: data.formattedValues,
    })
    .json()
    .then((res) => {
      dispatch(postCrewMembersSuccess(res));
      // fetchSchedule to refresh request log
      dispatch(fetchSchedule(data.scheduleId));
      if (controlModals) {
        dispatch(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.SUCCESS,
            contentType: Modals.ADD_PREVIOUS_CREW,
            redirectPath: null,
          }),
        );
      }
    })
    .catch((err) => {
      dispatch(postCrewMembersFailure(err));
      if (controlModals) {
        dispatch(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.FAILURE,
            contentType: Modals.ADD_PREVIOUS_CREW,
            redirectPath: null,
          }),
        );
      }
    });
};

export const fetchCrewMembers = () => async (dispatch) => {
  dispatch(fetchCrewMembersRequest());
  try {
    const response = await apiV1.get("routes/company-workers-slim?excludeDsb=true").json();
    dispatch(fetchCrewMembersSuccess(response));
  } catch (error) {
    dispatch(fetchCrewMembersFailure(error));
  }
};
