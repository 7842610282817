import React from "react";
import { DatePickerArrowIcon } from "./DatePickerArowIcon";
import { StyledButton } from "./styledComponents";

type StyledCalendarArrowButtonProps = {
  direction: string;
  handleClick: any;
  disabled: boolean;
};

export const StyledCalendarArrowButton = ({
  direction,
  handleClick,
  disabled,
}: StyledCalendarArrowButtonProps) => (
  <StyledButton disabled={disabled} onClick={handleClick}>
    {!disabled && <DatePickerArrowIcon directionIsLeft={direction === "left"} />}
  </StyledButton>
);
