import { Dialog as PRDialog, DialogProps } from "primereact/dialog";
import styled from "styled-components";
import React from "react";

const DialogWrapper = (props: DialogProps) => <PRDialog className={props.className} {...props} />;

export const Dialog = styled(DialogWrapper)`
  .p-dialog-header {
    .p-dialog-header-icon {
      color: ${({ theme }) => theme.colorTokens.blue400};
      :enabled:hover {
        border-color: ${({ theme }) => theme.colorTokens.blue850};
        background: ${({ theme }) => theme.colorTokens.blue850};
        color: ${({ theme }) => theme.colorTokens.white};
      }
      :focus {
        box-shadow: none;
        border-color: ${({ theme }) => theme.colorTokens.blue100};
      }
    }
  }
`;
