import { useController } from "react-hook-form";
import {
  AlertIcon,
  CurrencyIcon,
  Input,
  themedStyled,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import React, { useCallback, useEffect, useState } from "react";
import { styled } from "baseui";
import { FieldRowWrapper } from "./FieldRowWrapper";
import { FieldLabelRow } from "./FieldLabelRow";
import { HourlyPayFieldProps, HourlyPayFieldType } from "./types/propTypes/HourlyPayField.types";
import { Spacer, SpacerType } from "../../components/Spacer";

export const HourlyPayField = ({
  hasIconLabel,
  width = "127px",
  placeholder,
  control,
  trigger,
  disabled = false,
  canViewField = true,
}: HourlyPayFieldProps) => {
  const fieldName = "hourlyPay";
  const validate = trigger.bind(null, fieldName);
  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: (data: HourlyPayFieldType) => {
        if (disabled) {
          // Skip validation if wage field is disabled
          return true;
        }
        // When wage field is empty or wage is lower than the minWage,
        // show error.
        return !!(data.wage && data.minWage && data.wage >= data.minWage);
      },
    },
  });
  const [, theme] = useThemedStyletron();
  const [userInput, setUserInput] = useState<string>(value.wage ? value.wage.toFixed(2) : "");

  const handleChange = (wage: string) => {
    const [whole, decimal] = wage.split(".");

    switch (true) {
      case wage !== "" && !wage.match(/^\d{0,}(\.\d{0,2})?$/):
        break;
      case wage.length === 10:
        break;
      case decimal && decimal.length > 2:
        updateFormState(`${whole}.${decimal.substr(0, 2)}`);
        break;
      default:
        updateFormState(wage);
    }
  };

  const updateFormState = (input: string) => {
    const wage = parseFloat(input);

    setUserInput(input);
    onChange({ ...value, wage: !Number.isNaN(wage) ? wage : 0 });
    validate();
  };

  const onBlur = useCallback(() => {
    // Handle blur formatting & trigger validation
    const input = Number(userInput || "0").toFixed(2);
    updateFormState(input);
  }, [userInput, updateFormState]);

  useEffect(() => {
    if (value.wage && value.minWage) {
      // Validate wage field if there is data in the input and minWage was changed.
      // This happens when user changes position address
      validate();
    }
  }, [value.minWage]);

  return (
    <>
      <FieldRowWrapper data-pendo-key="HourlyPayEditField">
        {hasIconLabel ? (
          <CurrencyIcon width={theme.sizing.scale800} height={theme.sizing.scale800} />
        ) : (
          <FieldLabelRow>Hourly pay</FieldLabelRow>
        )}
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <InputWrapper $width={width}>
          {canViewField ? (
            <Input
              value={userInput}
              error={invalid}
              placeholder={placeholder || "0.00"}
              onChange={(wage) => handleChange(wage)}
              onBlur={onBlur}
              icon={!hasIconLabel ? <CurrencyIcon /> : undefined}
              disabled={disabled}
            />
          ) : (
            <Input
              value={"-.--"}
              error={invalid}
              onChange={() => {}}
              onBlur={() => {}}
              icon={!hasIconLabel ? <CurrencyIcon /> : undefined}
              disabled
            />
          )}
          {hasIconLabel && <SubLabel>per hour</SubLabel>}
        </InputWrapper>
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        {invalid && (
          // TODO:(BW-375) Re-use existing ErrorMessage component from ErrorMessage.tsx
          <ErrorMessageWrapper>
            <AlertIcon width={16} height={16} />
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale300} />
            <ErrorMessage>
              {`The minimum wage in your area is $${value.minWage.toFixed(2)}.`}
            </ErrorMessage>
          </ErrorMessageWrapper>
        )}
      </FieldRowWrapper>
      {hasIconLabel && <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale850} />}
    </>
  );
};

const InputWrapper = styled<{ $width: string }, "div">("div", ({ $width }) => ({
  width: $width,
  position: "relative",
}));

// TODO:(BW-375) Re-use existing ErrorMessage component from ErrorMessage.tsx
const ErrorMessageWrapper = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const ErrorMessage = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font250,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.coral,
}));

const SubLabel = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.stone,
  position: "absolute",
  marginTop: $theme.sizing.scale300,
}));
