import {
  init,
  SeverityLevel,
  browserTracingIntegration,
  captureMessage,
  captureException,
  setUser,
} from "@sentry/react";

const SentryEnvs = {
  Production: "production",
  Staging: "staging",
  Development: "development",
  LocalHost: "localhost",
} as const;

const CookieDomains = {
  Production: ".bluecrewjobs.com",
  Staging: ".stg.bluecrewenv.com",
  Development: ".dev.bluecrewenv.com",
  Localhost: "localhost",
} as const;

const cookieDomain = import.meta.env.VITE_COOKIE_DOMAIN ?? "";

// Only initialize sentry if we are running in staging or production
const sentryEnvsToLogTo: string[] = [CookieDomains.Staging, CookieDomains.Production];
const sentryEnabled = sentryEnvsToLogTo.includes(cookieDomain);

const getSentryEnv = () => {
  switch (cookieDomain) {
    case CookieDomains.Production:
      return SentryEnvs.Production;

    case CookieDomains.Staging:
      return SentryEnvs.Staging;

    case CookieDomains.Development:
      return SentryEnvs.Development;

    case CookieDomains.Localhost:
      return SentryEnvs.LocalHost;

    default:
      return SentryEnvs.Production;
  }
};

/**
 * @param forceEnable Set to true to always initialize Sentry. Useful if you want to send errors / events to
 *  it when running locally and you don't want to mess with config
 */
export function initializeSentry(forceEnable: boolean = false) {
  if (!sentryEnabled && !forceEnable) {
    // eslint-disable-next-line no-console
    console.info(`Not initalizing Sentry: Cookie domain is ${cookieDomain}`);
    return;
  }

  if (forceEnable) {
    // eslint-disable-next-line no-console
    console.warn(`Initalizing Sentry: forceEnable is true`);
  }

  init({
    dsn: "https://05e398c04881488c87d9d5546523e16b@o1235227.ingest.sentry.io/4505053623943168",
    integrations: [
      browserTracingIntegration(),
      // See docs for support of different versions of variation of react router
    ],
    tracesSampleRate: 0.001, // If we want, we could switch traceSampleRate based on env (may want 100% in dev,stg, 10% in prod)
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // NOTE: We always get 100% of errors, regardless of tracesSampleRate
    // tracesSampleRate: 1.0,
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/
    environment: getSentryEnv(), // filter errors by env
    ignoreErrors: [
      // We don't use resizeObserver anywhere in the code base.
      // These error can be triggered by browser extensions modifing your page. For more details see:
      // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/#understanding-the-resizeobserver-loop-limit-exceeded-error-message
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
    ],
  });
}

type ConsoleSeverityLevel = "log" | "info" | "warn" | "error" | "debug";

function mapSeverityToConsoleLevel(level: SeverityLevel): ConsoleSeverityLevel {
  switch (level) {
    case "warning":
      return "warn";
    case "fatal":
      return "error";
    default:
      return level;
  }
}

function logToConsoleByLevel({
  message,
  level,
  context,
}: {
  message: string;
  level: SeverityLevel;
  context?: string;
}) {
  const ctx = context ? `${context}:` : "";
  const msg = `${ctx} ${message}`;
  const consoleLevel = mapSeverityToConsoleLevel(level);
  // eslint-disable-next-line no-console
  console[consoleLevel](msg);
}

export function setSentryUser(email: string, externalUserId: string | null = null) {
  setUser({ id: externalUserId ?? "", email });
}

/**
 * Report a message to Sentry; default level is "error" but can be "info", "warning" etc.
 * If sentry is disabled, will log to console instead.
 */
export function logMessage({
  message,
  level = "error",
  context,
}: {
  message: string;
  level?: SeverityLevel;
  context?: string;
}) {
  if (sentryEnabled) {
    captureMessage(message, { level, extra: { context } });
  } else {
    logToConsoleByLevel({ message, level, context });
  }
}

/**
 * Report an error to Sentry.
 * If sentry is disabled, will log to console instead.
 */
export function logError({ error, context }: { error: Error; context?: string }) {
  try {
    // Re-throw the error to make sure it has a stack.
    throw error;
  } catch (errorWithStack) {
    if (sentryEnabled) {
      captureException(error, { extra: { context } });
    } else {
      // eslint-disable-next-line no-console
      console.error(errorWithStack);
    }
  }
}
