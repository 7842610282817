import React from 'react';
import { Button as BaseButton, KIND, SHAPE, SIZE } from 'baseui/button';
import { SvgPropType } from '../assets';
import {
  getBorder,
  getHorizontalPadding,
  getPadding,
} from '../helpers/styleHelpers';
import { BlueprintTheme, themedStyled, useThemedStyletron } from '../theme';

type ButtonProps = React.PropsWithChildren<{
  leftIcon?: React.ComponentType<SvgPropType>;
  rightIcon?: React.ComponentType<SvgPropType>;
  size?: SIZE[keyof SIZE];
  kind?: KIND[keyof KIND];
  shape?: SHAPE[keyof SHAPE];
  onClick?: () => void;
  style?: React.CSSProperties;
  width?: string;
  disabled?: boolean;
  iconBalanceVisible?: boolean;
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
  withPadding?: boolean;
  dataTestId?: string;
}>;

export function getButtonBorder(
  kind: KIND[keyof KIND],
  borders: BlueprintTheme['borders'],
) {
  switch (kind) {
    case KIND.secondary:
      return getBorder(borders.border100);
    default:
      return {};
  }
}

function getCustomStyles(styles: {
  backgroundColor?: string;
  color?: string;
  fontWeight?: string;
}) {
  return JSON.parse(JSON.stringify(styles));
}

export function getColors(
  kind: KIND[keyof KIND],
  colors: BlueprintTheme['colors'],
) {
  switch (kind) {
    case KIND.primary:
      return {
        iconColor: colors.buttonPrimaryText,
      };
    default:
      return {
        iconColor: colors.buttonSecondaryText,
      };
  }
}

export const Button = ({
  disabled = false,
  style,
  width,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  size = SIZE.default,
  kind = KIND.primary,
  shape = SHAPE.default,
  backgroundColor,
  color,
  iconBalanceVisible = true,
  fontWeight,
  onClick,
  withPadding = true,
  children,
  dataTestId,
}: ButtonProps) => {
  const [, theme] = useThemedStyletron();

  const { paddingHorizontal, iconSize, iconSpacer, height } = {
    [SIZE.mini]: {
      paddingHorizontal: theme.sizing.scale300,
      iconSize: theme.sizing.scale600,
      iconSpacer: theme.sizing.scale200,
      height: theme.sizing.scale850, // 28px
    },
    [SIZE.compact]: {
      paddingHorizontal: theme.sizing.scale500,
      iconSize: theme.sizing.scale600,
      iconSpacer: theme.sizing.scale400,
      height: theme.sizing.scale950, // 36px
    },
    [SIZE.default]: {
      paddingHorizontal: theme.sizing.scale600,
      iconSize: theme.sizing.scale700,
      iconSpacer: theme.sizing.scale500,
      height: theme.sizing.scale1200, // 48px
    },
    [SIZE.large]: {
      paddingHorizontal: theme.sizing.scale700,
      iconSize: theme.sizing.scale700,
      iconSpacer: theme.sizing.scale500,
      height: theme.sizing.scale1400, // 56px
    },
  }[size];

  const { iconColor } = getColors(kind, theme.colors);

  // @ts-ignore
  const IconBalance = iconBalanceVisible ? themedStyled('div', {}) : undefined;

  return (
    <BaseButton
      onClick={onClick}
      size={size}
      kind={kind}
      shape={shape}
      startEnhancer={
        LeftIcon ? (
          <LeftIcon
            color={color || iconColor}
            stroke={color}
            width={iconSize}
            height={iconSize}
          />
        ) : (
          IconBalance && <IconBalance />
        )
      }
      endEnhancer={
        RightIcon ? (
          <RightIcon
            color={color || iconColor}
            stroke={color}
            width={iconSize}
            height={iconSize}
          />
        ) : (
          IconBalance && <IconBalance />
        )
      }
      disabled={disabled}
      overrides={{
        BaseButton: {
          style: () => ({
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: `${height}`,
            whiteSpace: 'nowrap',
            width: `${width}`,
            ':hover': {
              backgroundColor: 'none',
            },
            ...getCustomStyles({ backgroundColor, color, fontWeight }),
            ...getButtonBorder(kind, theme.borders),
            ...(withPadding
              ? getHorizontalPadding(paddingHorizontal)
              : getPadding('0')),
            ...style,
          }),
          props: {
            ...(dataTestId && { 'data-testid': dataTestId }),
          },
        },
        StartEnhancer: {
          style: {
            marginRight: LeftIcon ? iconSpacer : 0,
            ...(!LeftIcon && {
              flexBasis: '100%',
              maxWidth: `calc(${iconSize} + ${iconSpacer})`,
            }),
          },
        },
        EndEnhancer: {
          style: {
            marginLeft: RightIcon ? iconSpacer : 0,
            ...(!RightIcon && {
              flexBasis: '100%',
              maxWidth: `calc(${iconSize} + ${iconSpacer})`,
            }),
          },
        },
      }}
    >
      {children}
    </BaseButton>
  );
};
