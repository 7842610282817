// AUTH - API
// =============================================================================

import ky from "ky-universal";

import { LoginRequest, TokenResponse, TokenBearerResponse, TokenExchangeResponse } from "./types";
import { api } from "./constants/index";
import { logError } from "../../sentry";
import { TokenRequestPayload } from "../../redux/actions/auth";
import { BEARER_TOKEN_NAME, JWT_TOKEN_NAME } from "../../../shared/constants";

export const login = (json: LoginRequest): Promise<TokenResponse> =>
  api
    .post(`token`, {
      json,
    })
    .json();

const exchangeJWT = (
  json: TokenRequestPayload,
): Promise<TokenExchangeResponse | TokenBearerResponse> => {
  const tokens = json.token;
  return api
    .post(`v2/token/exchange`, {
      json: { audience: json.audience },
    })
    .json()
    .catch((e) => {
      // Report this error because it will trigger a post to token/bearer
      // which might fail if a bearer token doesn't exist. If it does,
      // this will log the client out.
      //
      // Notes:
      // - Depending on browser, the thrown error will either contain
      //   the status (e.g. 403) or the status string (e.g. Unauthorized)
      // - Status will be 403 for clients that don't have job post access
      // - Status will be 401 for other cases
      logError({
        error: new Error(
          `exchangeJWT() failed (bearer? ${tokens[BEARER_TOKEN_NAME] ? "YES" : "NO"}): ${e}`,
        ),
        context: `Bearer(${tokens[BEARER_TOKEN_NAME] ? "YES" : "NO"});JWT(${
          tokens[JWT_TOKEN_NAME] ? "YES" : "NO"
        })`,
      });
      return exchangeBearer(json);
    }) as Promise<TokenExchangeResponse | TokenBearerResponse>;
};

const exchangeBearer = (json: TokenRequestPayload): Promise<TokenBearerResponse> => {
  const tokens = json.token;
  return api
    .post(`token/bearer`, {
      json: { token: tokens[BEARER_TOKEN_NAME], audience: json.audience },
    })
    .json();
};

// token exchange helper
export const token = (json: TokenRequestPayload) => {
  const tokens = json.token;
  if (tokens[JWT_TOKEN_NAME]) {
    return exchangeJWT(json);
  }
  if (tokens[BEARER_TOKEN_NAME]) {
    return exchangeBearer(json);
  }
  class HTTPError extends Error {}
  throw new HTTPError("Fetch error: No tokens provided");
};

export const resumeSession = () => ky.get("/session/resume").text();
