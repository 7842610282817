import React, { createRef, useEffect, useState } from "react";
import { differenceWith } from "lodash";
import {
  GetCrewMembersPositionIcon,
  Modal,
  SelectionButton,
  SelectionButtonKinds,
  Button,
} from "@bluecrew/blueprint-web";
import { useController } from "react-hook-form";
import { ModalBodyWrapper } from "../ModalBodyWrapper";
import { FieldWrapper } from "./FieldWrapper";
import { PositionMapper } from "./PositionMapper";
import { PositionFieldProps, PositionsListItem } from "../types/propTypes/PositionField.types";
import { PositionListItem, SelectionList } from "../../../components/SelectionList";
import { useSelectionList } from "../useSelectionList";
import { StyledModalFooterWrapper } from "../StyledModalFooterWrapper";

export const PositionField = ({
  control,
  trigger,
  positions = [],
  mapper = PositionMapper,
  onNewPosition,
  disabled,
}: PositionFieldProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const fieldName = "position";
  const validate = trigger.bind(null, fieldName);

  const closeModal = () => setModalOpen(false);
  const onNewPositionClick = () => {
    closeModal();
    onNewPosition && onNewPosition();
  };

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: { required: true },
  });

  const getListItems = () =>
    positions.map((position) => mapper.toListItem(position, value && value.id === position.id));

  const { list, toggleSelection, selected, setList } = useSelectionList<PositionsListItem>(
    getListItems(),
    {
      searchBy: "id",
    },
  );

  useEffect(() => {
    const newList = getListItems();
    // when new position is added, need to select it
    const newElements = differenceWith(newList, list, (a, b) => a.id === b.id);
    let updatedNewList: any = [];
    if (newElements.length) {
      updatedNewList = newList.map((element) => ({
        ...element,
        selected: element.id === newElements[0].id,
      }));
    }
    setList(updatedNewList.length ? updatedNewList : newList);
  }, [positions]);

  useEffect(() => {
    // We need this 'isSingleAssignmentView' check because
    // Get Crew Member Form by default selects first position in
    // positions list that we are getting from the server.
    // We have a lot of re-renders in this form, that's why the correct
    // pre-selected position gets overwritten by the first (default)
    // position from the list
    if (list?.length && !disabled) {
      const [position] = selected;

      if (!position) return;

      closeModal();
      onChange(mapper?.toModel(position));

      validate();
    }
  }, [list]);

  const modalRootRef = createRef<HTMLDivElement>();

  const setPositionSearchParams = (position: PositionsListItem) => {
    const url = new URL(window.location.href);
    url.searchParams.set("positionId", position?.id.toString());
    window.history.replaceState({}, "", url.toString());
  };

  return (
    <FieldWrapper icon={<GetCrewMembersPositionIcon />}>
      <SelectionButton
        disabled={disabled}
        placeholder="Choose a position"
        kind={SelectionButtonKinds.WITH_SUBHEADER}
        onClick={() => setModalOpen(true)}
        error={invalid}
        header={value?.name}
        subHeader={value?.address.displayAddress}
        buttonWidth="606px"
        dataTestId="PositionField-SelectionButton"
      />
      <Modal
        header="Positions"
        body={
          <ModalBodyWrapper ref={modalRootRef}>
            <SelectionList<PositionsListItem>
              rootRef={modalRootRef}
              items={list}
              listItemRenderer={PositionListItem}
              onItemClick={(item: PositionsListItem) => {
                toggleSelection(item);
                setPositionSearchParams(item);
              }}
            />
          </ModalBodyWrapper>
        }
        footer={
          <StyledModalFooterWrapper>
            <Button onClick={onNewPositionClick} width={"100%"}>
              New Position
            </Button>
          </StyledModalFooterWrapper>
        }
        isOpen={modalOpen}
        onClose={() => {
          closeModal();
          return validate();
        }}
      />
    </FieldWrapper>
  );
};
