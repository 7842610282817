import * as React from 'react';
import { Modal as BaseModal } from 'baseui/modal';
import { CloseIcon } from '../../assets';

import {
  Body,
  CloseButton,
  dialogStyles,
  Footer,
  Header,
  styleByKind,
} from './styles';
import { ModalProps } from './Modal.types';

export const DefaultModal = ({
  header,
  body,
  footer,
  isOpen,
  onClose,
  maxHeight,
  width,
  withCloseButton = true,
}: ModalProps) => (
  <BaseModal
    onClose={onClose}
    isOpen={isOpen}
    unstable_ModalBackdropScroll
    animate
    overrides={{
      Root: {
        style: () => ({
          zIndex: 999999, // NOTE: Must be at least 999999, since we want it to cover the zendesk widget on web-react (which has z-index 999998)
        }),
      },
      Dialog: {
        style: ({ $theme }) => ({
          ...styleByKind($theme),
          ...dialogStyles(width),
        }),
      },
      DialogContainer: {
        style: {
          backgroundColor: 'rgba(8,9,18, 0.8)',
        },
      },
      Close: {
        style: {
          display: 'none',
        },
      },
    }}
  >
    <Header>
      {header}
      {withCloseButton && (
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      )}
    </Header>
    <Body maxheight={maxHeight}>{body}</Body>
    <Footer>{footer}</Footer>
  </BaseModal>
);
