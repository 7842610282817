import styled from "styled-components";
import { Image } from "@bluecrew/web-react-core";

export const VaccinationStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
`;

export const VaccinationIcon = styled(Image)`
  margin-right: 8px;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;
