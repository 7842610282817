import React, { useRef } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { ActionColumnButtonWrapper } from "../styledComponents";
import {
  getPayrollActionKeyPrefix,
  useApproveShiftMutation,
} from "../../../../../api/bluecrew/hooks/payroll";
import { ActionButton, ActionButtonIcon } from "./styledComponents";
import { Toast } from "primereact/toast";
import { ActionButtonIconPathPrefix } from "./index";
import { buildShiftKey } from "../../../shiftUtils";
import { Tooltip } from "../../../../../components/Tooltip";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";

export type ButtonApproveProps = {
  rowData: TimesheetsShiftInfo;
  disabled: boolean;
};

export const ButtonApprove = ({ disabled, rowData }: ButtonApproveProps) => {
  const { user, job, userShift } = rowData;

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const approvePayload = {
    userId: user.externalId,
    jobId: job.externalId,
    shiftIndex: job.shiftIndex,
    shiftStart: userShift.start.time,
    shiftEnd: userShift.end.time,
    lunchDurationMinutes: userShift.break.durationMinutes,
  };

  const shiftKey = buildShiftKey(rowData);
  const tooltipAnchor = `approve-${shiftKey.cmId}-${shiftKey.jobId}-${shiftKey.shiftIndex}`;
  const { mutate } = useApproveShiftMutation(shiftKey, showErrorToast);
  const isAnyPayrollActionMutating = !!useIsMutating(getPayrollActionKeyPrefix(shiftKey));

  return (
    <ActionColumnButtonWrapper>
      <Toast ref={toast} />
      <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
        {"Approve"}
      </Tooltip>
      <ActionButton
        className={tooltipAnchor}
        disabled={disabled || isAnyPayrollActionMutating}
        icon={<ActionButtonIcon src={`${ActionButtonIconPathPrefix}/approve.svg`} />}
        rounded
        outlined
        onClick={() => mutate(approvePayload)}
      />
    </ActionColumnButtonWrapper>
  );
};
