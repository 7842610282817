import { Skill, SkillItem } from "../../../types/propTypes/SkillsetField.types";

export class SkillMapper {
  static toListItem = (skill: Skill, selected: boolean = false): SkillItem => ({
    id: skill.id,
    name: skill.name,
    iconName: skill.iconName,
    requirements: skill.requirements,
    description: skill.description,
    certifications: skill.certifications,
    selected,
    lastUsedAt: skill.lastUsedAt,
    warning: skill.warning,
  });

  static toModel = (skillItem: SkillItem): Skill => ({
    id: skillItem.id,
    name: skillItem.name,
    iconName: skillItem.iconName,
    requirements: skillItem.requirements,
    certifications: skillItem.certifications,
    description: skillItem.description,
    lastUsedAt: skillItem.lastUsedAt,
    warning: skillItem.warning,
  });
}
