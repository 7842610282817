import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "react-styled-flexboxgrid";
import { Spinner } from "@bluecrew/web-react-core";
import { AbilityContext } from "../PermissionsContext";
import { getUserCompany } from "../../redux/selectors/auth";
import { transformPositionData } from "./utilities";
import { PositionDetailsEditScreen } from "../GetCrewMembers/PositionDetailsEditScreen";
import { Container, StyledRow } from "./styledComponents";
import type { FormFieldTypes as CreatePositionFormFieldTypes } from "../GetCrewMembers/types/propTypes/CreatePositionScreen.types";
import { mapPosition } from "../GetCrewMembers/mapPostionData";
import { useGetJobTypesQuery } from "../../api/bluecrew/hooks/job";
import { useGetDashboardPositionsQuery } from "../../api/bluecrew/hooks/dashboard";
import { useMinWageQuery } from "../../api/bluecrew/hooks/minWage";
import {
  useEditPositionMutation,
  useGetEditHistoryQuery,
  useReadPositionQuery,
} from "../../api/bluecrew/hooks/position";
import { DISABLED_FIELDS } from "./constants";
import { UpdatePositionDetailsProps } from "../../api/bluecrew/position";
import { useAppSelector } from "../../redux";

export type EditPositionProps = {};

// eslint-disable-next-line no-empty-pattern
export const EditPosition = ({}: EditPositionProps) => {
  const routeParams = useParams();
  const positionId = routeParams.positionId!; // positionId  is always defined based on how this route is defined

  const userCompanyId = useAppSelector((state) => getUserCompany(state));

  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const [positionData, setPositionData] = useState<CreatePositionFormFieldTypes | null>(null);

  const { data: jobTypes } = useGetJobTypesQuery();
  const { mutate: editPositionMutation, isLoading: isEditPositionLoading } =
    useEditPositionMutation();
  const { data: positions } = useGetDashboardPositionsQuery(userCompanyId);
  const { data: editsHistory } = useGetEditHistoryQuery(positionId);
  const { data: position, isLoading: isPositionFetching } = useReadPositionQuery(
    parseInt(positionId, 10),
  );
  const { data: minWage, refetch: refetchMinWage } = useMinWageQuery(
    !!position?.id,
    position?.address!,
  );

  const onNavBack = () => navigate("/positions", { replace: true });

  useEffect(() => {
    // Ensure positionData recieves updates after component mounts
    if (!!position && !!minWage) {
      setPositionData(transformPositionData(position, jobTypes, minWage));
    }
  }, [isPositionFetching, minWage]);

  const canEditPosition = ability.can("create", "position");
  const canViewWages = ability.can("view", "wages");

  if (!position?.id || !jobTypes?.length || !editsHistory) {
    return (
      <StyledRow middle="xs" center="xs">
        <Col>
          <Spinner />
        </Col>
      </StyledRow>
    );
  }

  if (!positionData) {
    return <></>;
  }

  // We need this info to show the Recent section in Workplace address modal
  // @ts-ignore
  const positionsInfo = (positions ?? []).map((p) => mapPosition(p));

  return (
    <Container>
      <PositionDetailsEditScreen
        onLocationSelect={refetchMinWage}
        positions={positionsInfo}
        editHistory={editsHistory.positionHistory}
        notificationStatuses={editsHistory.notificationStatuses}
        backButtonTitle={"Dashboard"}
        disabledFields={DISABLED_FIELDS}
        hideTagsIfDisabled
        onBackPressed={onNavBack}
        onCancel={onNavBack}
        requestPending={isEditPositionLoading}
        onPublish={(data) => {
          // If field was not changes we will send undefined, so it will be skipped
          const updatedPositionData: UpdatePositionDetailsProps["payload"] = {
            arrival_instructions: data.arrivalInstructions,
            title: data.positionTitle,
            dress_code: data.dressCode,
            siteDesignation: data.siteDesignation,
            minWarnRadius: data.minWarnRadius,
            minBlockRadius: data.minBlockRadius,
            reason_code: data.reason_code,
            reason_text: data.reason_text,
            worksite: data.worksite,
            description: data.description,
          };
          editPositionMutation({
            payload: updatedPositionData,
            positionId,
          });
        }}
        position={positionData}
        skillsRequirements={(positionData && positionData.requirements) || []}
        certifications={(positionData && positionData.certifications) || []}
        canViewWages={canViewWages}
        canEditPosition={canEditPosition}
      />
    </Container>
  );
};
