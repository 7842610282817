import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";

export const PresentationContainer = styled.div`
  border: 1px solid ${p("aluminum")};
  border-radius: 0.5rem;
  padding: 0;

  font-size: 1.2rem;

  & > * {
    padding: 1rem;
    border-bottom: 1px solid ${p("aluminum")};
    &:last-child {
      margin-bottom: 2rem;
    }
  }
`;

export const FilterList: React.FC<{}> = ({
  children, // inputPlaceHolder = "Search messages...",
  // FirstRowComponents = [],
  // SecondRowComponents = [],
  // listItems = [1, 6, 3, 2, 4, 7, 8, 1, 6, 3, 2, 4, 7, 8],
  // sortButton = []
}) => <PresentationContainer>{children}</PresentationContainer>;
