// View Shift- VIEW
// =============================================================================
// View a shfit

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Field as FormikField, ErrorMessage } from "formik";
import {
  Box,
  DateRangePicker,
  Divider,
  Field,
  Group,
  Heading,
  Image,
  Button,
  Portal,
  Backdrop,
  Overlay,
  Paragraph,
} from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import MultistepForm, { ButtonAlignments, ButtonBarWidth } from "./MultistepForm";
import { DirectInviteAvatar } from "./DirectInviteAvatar";
import { fetchCrewMembers, postCrewMembers } from "../redux/actions/crewMembers";
import {
  getDirectInviteUsers,
  getformattedCrewMembersForCrewPicker,
  isFetchingCrewmembers,
} from "../redux/selectors/crewMembers";
import { SearchableList } from "./SearchableList";
import { getSchedule } from "../redux/selectors/schedule";
import { getDirectInviteUserIds } from "../redux/selectors/job";
import jobActions from "../redux/actions/job";
import { convertToDayMaskValue, updateDateWithTime } from "../utility";
import { assetUrl } from "../api/bluecrew/constants/index";
import { IconHeader } from "./IconHeader";
import { validateDateRange } from "../forms/validation";

const mapDispatchToProps = (dispatch) => ({
  clearDirectInviteUserIds: () => dispatch(jobActions.clearDirectInviteUserIds()),
  onSubmit: (data) => {
    dispatch(postCrewMembers({ data }));
  },
  onMount: () => {
    dispatch(fetchCrewMembers());
  },
});

const mapStateToProps = (state) => ({
  directInviteUserIds: getDirectInviteUserIds(state),
  directInviteUsers: getDirectInviteUsers(state),
  crewData: getformattedCrewMembersForCrewPicker(state),
  isFetchingCrewData: isFetchingCrewmembers(state),
  schedule: getSchedule(state),
});

const CloseModal = styled(Box)`
  background: #fff url(${assetUrl}/icons/modal-close.svg) center center no-repeat;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 32px;
  right: 32px;

  &:hover {
    cursor: pointer;
  }
`;

// TODO: - Added story for CSS polish, BCEMPL-484 (run)
const StyledOverlay = styled(Overlay)`
  padding: 0; // Overwriting padding that was displaying as grey
  width: 42rem;
`;

const StyledContainer = styled(Box)`
  background: ${p("white")};
  border-radius: 4px;
  padding: 32px 100px;
  position: relative;

  .button-group {
    display: block;
    padding: 1rem;
    text-align: right;
  }
  .cancel-button {
    margin-right: 15px;
  }
`;

const StyledContent = styled(Box)`
  text-align: center;

  // Error Message CSS
  .error-message {
    color: red;
    font-size: 0.75rem;
    text-align: center;
  }

  ${Button} {
    font-size: 16px;
    font-weight: normal;
  }
`;

const StyledHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    background-color: #d9f3ff;
    border: none;
    border-radius: 50%;
  }

  ${Heading} {
    font-weight: 500; //TODO: remove when fixed in web-core theme
    color: ${p("slate")};
    padding: 24px 0 8px 0;
    font-size: 20px;
  }

  ${Paragraph} {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0;
    color: ${p("platinum")};
  }
`;
// TODO: - Added story for CSS polish, BCEMPL-484 (run)
const StyledForm = styled(MultistepForm)`
  .DateInput_input {
    background-image: url(${assetUrl}/calendar-input-bg.svg);
    background-repeat: no-repeat;
    background-position: left 1rem center;
    padding-left: 3rem;
  }
`;

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AddPreviousCrewMember extends React.Component<Props, any> {
  initialValues = {
    crew_member_ids: this.props.directInviteUserIds || [],
    dates: { startDate: null, endDate: null },
    focusedInput: null,
  };

  formatValues = (values) => {
    const { schedule } = this.props;
    const newDatesWithTimes = updateDateWithTime({
      newStartDateMoment: values.dates.startDate,
      newEndDateMoment: values.dates.endDate,
      startDateTimestamp: schedule.start_date_time,
      endDateTimestamp: schedule.end_date_time,
      timezone: schedule.timezone,
    });

    return {
      needed: values.crew_member_ids.length,
      days: convertToDayMaskValue(
        // @ts-ignore
        this.props.schedule.shifts.map((shift) => shift.day_of_week),
      ),
      // @ts-ignore
      jobTemplateId: this.props.schedule.template_external_id,
      // @ts-ignore
      supervisor: this.props.schedule.supervisor.user_id,
      startDate: newDatesWithTimes.startDateTime,
      endDate: newDatesWithTimes.endDateTime,
      userIds: values.crew_member_ids,
    };
  };

  componentDidMount = () => {
    this.props.onMount();
  };

  handleFormSubmit = (values, actions) => {
    // @ts-ignore
    const { scheduleId } = this.props;
    const formattedValues = this.formatValues(values);
    this.props.onSubmit({
      formattedValues,
      scheduleId,
    });
    actions.setSubmitting(false);
    actions.resetForm(this.initialValues);
    this.closeModal();
  };

  closeModal = () => {
    // @ts-ignore
    const { clearDirectInviteUserIds, toggleModal } = this.props;
    clearDirectInviteUserIds();
    toggleModal();
  };

  renderHeaderIcon = () => {
    const { directInviteUsers } = this.props;

    return directInviteUsers.length > 0 ? (
      <DirectInviteAvatar user={directInviteUsers[0]} />
    ) : (
      "icons/calendar.png"
    );
  };

  render() {
    // @ts-ignore
    const { crewData, directInviteUsers, isFetchingCrewData, show } = this.props;
    const iconPath = `${assetUrl}/icons/actions-add-icon.svg`;

    // formatted list data for SearchableList component
    const formattedCrewMemberList = crewData.map((c) => ({
      avatar: c.profilePic,
      id: c.user_id,
      name: `${c.firstName} ${c.lastName}`,
    }));

    const pages = [];

    if (this.initialValues.crew_member_ids.length === 0) {
      // When not directly inviting crew members, insert a step to select crew members.
      pages.push(
        // @ts-ignore
        <StyledForm.Page
          key="add-crew-page"
          // @ts-ignore
          validate={(values) => {
            const errors = {};
            if (values.crew_member_ids.length < 1) {
              // NOTE: using validate here to disable next button, confirmed with UI/UX that error message does not need to be displayed
              // @ts-ignore
              errors.crew_member_ids = "Please select at least one crew member";
            }
            return errors;
          }}
        >
          <Group vertical>
            <StyledHeadingContainer>
              <div className="modal-icon-container">
                <Image className="modal-icon" src={iconPath} />
              </div>
              <Heading as="h4">Add a Crew Member</Heading>
              <Paragraph>{`Search for a crew member you'd like to add to this schedule`}</Paragraph>
            </StyledHeadingContainer>
            <Divider />
            <Field>
              <FormikField
                name="crew_member_ids"
                render={({ form: { setFieldValue, values } }) => (
                  <SearchableList
                    isFetchingList={isFetchingCrewData}
                    listItems={formattedCrewMemberList}
                    noListItemsMessage="Sorry, you currently don't have any crew members."
                    searchBarPlaceholder="Search for a crew member..."
                    // @ts-ignore
                    selectedUserIds={values.crew_member_ids}
                    onChangeItem={(selectedIds) => {
                      setFieldValue("crew_member_ids", selectedIds);
                    }}
                  />
                )}
              />
            </Field>
          </Group>
        </StyledForm.Page>,
      );
    }

    pages.push(
      // @ts-ignore
      <StyledForm.Page key="select-crew-dates-page">
        <IconHeader
          wrapAsSVG={directInviteUsers.length === 0}
          title="Add a Crew Member"
          text="Select the dates you would like the additional crew members to work"
          icon={this.renderHeaderIcon()}
        />

        <Divider />
        <Field>
          <FormikField
            name="dates"
            validate={validateDateRange}
            render={({ form: { setFieldTouched, setFieldValue, values } }) => (
              <>
                <DateRangePicker
                  isModal
                  startDate={values.dates.startDate} // momentPropTypes.momentObj or null
                  // @ts-ignore
                  startDateId="start_date"
                  endDate={values.dates.endDate} // momentPropTypes.momentObj or null
                  endDateId="end_date"
                  onDatesChange={(dates) => {
                    setFieldTouched("dates");
                    setFieldValue("dates", dates);
                  }}
                  focusedInput={values.focusedInput}
                  onFocusChange={(focusedInput) => {
                    setFieldValue("focusedInput", focusedInput);
                  }}
                />
                <ErrorMessage
                  name="dates"
                  render={(msg) => <div className="error-message">{msg}</div>}
                />
              </>
            )}
          />
        </Field>
      </StyledForm.Page>,
    );

    return (
      <React.Fragment>
        <Portal>
          <Backdrop fade visible={show} onClick={this.closeModal} />
          <StyledOverlay fade visible={show}>
            <StyledContainer>
              <CloseModal onClick={this.closeModal} />
              <StyledContent>
                {/** @ts-ignore */}
                <StyledForm
                  initialvalues={this.initialValues} // initialvalues left without casing to avoid DOM warning
                  onSubmit={this.handleFormSubmit}
                  submittext="Add Crew Member" // submittext left without casing to avoid DOM warning
                  buttonalignment={ButtonAlignments.CENTER}
                  buttonbarwidth={ButtonBarWidth.FULL}
                >
                  {pages}
                </StyledForm>
              </StyledContent>
            </StyledContainer>
          </StyledOverlay>
        </Portal>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPreviousCrewMember);
