// AUTH - SAGA
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, call, put, takeLatest, takeEvery } from "redux-saga/effects";

import { get } from "lodash";
import { fetchSchedule } from "../actions/schedule";
import positionActions from "../actions/position";
import * as jobAPI from "../../api/bluecrew/job";
import { RejectApplicantAction } from "../../api/bluecrew/types";
import jobActions, {
  CreateBlueShiftScheduleJobsAction,
  AddWorkRequestToSchAction,
  CreateCrewJobAndSchedAction,
  CreateCrewAndOptJobAndSchedAction,
  CreateDirectInviteAndOptJobAndSchedAction,
  AddCrewJobToScheduleAction,
} from "../actions/job";
import confirmationModalActions from "../actions/confirmationModal";
import { SimpleModalTypes } from "../../components/SimpleModal";
import { Modals } from "../../../shared/constants";
import { getErrorModalDataFromPayload } from "../utility";
import { createJobType } from "../../api/bluecrew/business-logic/jobs";

function* jobsSaga(): Generator<Effect, void, any> {
  try {
    const response = yield call(jobAPI.getAllJobs);
    yield put(jobActions.jobs.success(response));
  } catch (error) {
    yield put(jobActions.jobs.failure(error));
  }
}

function* createDepartmentSaga(payload: any): Generator<Effect, any, any> {
  return yield call(jobAPI.postDepartment, {
    companyId: payload.companyId,
    data: { tags: [payload.newDepartment] },
  });
}

function* createJobSaga(action: any): Generator<Effect, void, any> {
  let { payload } = action;
  const requestIncludedDirectInvite = false;
  const requestIncludedWorkRequest = false;
  const successfulDirectInvite = false;
  try {
    if (payload.newDepartment) {
      const departmentResponse = yield* createDepartmentSaga(payload);
      payload = {
        ...payload,
        data: {
          ...payload.data,
          tag_id: departmentResponse.insertId,
        },
      };
    }
    const response = yield call(jobAPI.postJob, payload);
    yield put(jobActions.createJob.success(response));
    if (payload.data.blueshift_request_id) {
      const modalData = getErrorModalDataFromPayload(
        payload,
        true,
        successfulDirectInvite,
        requestIncludedDirectInvite,
        requestIncludedWorkRequest,
      );
      yield put(jobActions.updateBulkSingleShiftResponses(modalData));
    }
  } catch (error) {
    yield put(jobActions.createJob.failure(error));
    if (payload.data.blueshift_request_id) {
      const modalData = getErrorModalDataFromPayload(
        payload,
        false,
        successfulDirectInvite,
        requestIncludedDirectInvite,
        requestIncludedWorkRequest,
      );

      yield put(jobActions.updateBulkSingleShiftResponses(modalData));
    }
  }
}

function* rejectApplicationJobSaga(action: RejectApplicantAction): Generator<Effect, void, any> {
  const { scheduleId } = action.payload;

  try {
    const response = yield call(jobAPI.rejectApplicationJob, action.payload);

    yield put(jobActions.rejectApplicationJob.success(response));
    yield put(
      // @ts-ignore
      fetchSchedule(scheduleId),
    );
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.SUCCESS,
        contentType: Modals.REJECT_APPLICATION_JOB,
        redirectPath: null,
      }),
    );
  } catch (error) {
    yield put(jobActions.rejectApplicationJob.failure(error));
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.FAILURE,
        contentType: Modals.REJECT_APPLICATION_JOB,
        redirectPath: null,
      }),
    );
  }
}

function* offerJobSaga(action: any): Generator<Effect, void, any> {
  const { scheduleId } = action.payload;
  try {
    const response = yield call(jobAPI.offerJob, action.payload);
    yield put(jobActions.offerJob.success(response));
    yield put(
      // @ts-ignore
      fetchSchedule(scheduleId),
    );
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.SUCCESS,
        contentType: Modals.OFFER_JOB,
        redirectPath: null,
      }),
    );
  } catch (error) {
    yield put(jobActions.offerJob.failure(error));
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.FAILURE,
        contentType: Modals.OFFER_JOB,
        redirectPath: null,
      }),
    );
  }
}

function* jobTypeSaga(action: any): Generator<Effect, void, any> {
  try {
    const { isCertificateFilteringDisabled } = action.payload;

    const response = yield call(jobAPI.getJobTypes);

    const formattedResponse = createJobType(response, isCertificateFilteringDisabled);

    yield put(jobActions.jobTypes.success(formattedResponse));
  } catch (error) {
    yield put(jobActions.jobTypes.failure(error));
  }
}

function* supervisorsSaga(): Generator<Effect, void, any> {
  try {
    const response = yield call(jobAPI.getSupervisors);
    yield put(jobActions.supervisors.success(response));
  } catch (error) {
    yield put(jobActions.supervisors.failure(error));
  }
}

function* createPositionSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(jobAPI.postPosition, action.payload);
    window.location.href = `/jobs/post?positionId=${response.position.id}`;
  } catch (error: any) {
    yield put(jobActions.createPosition.failure(error));
  }
}

function* addWorkRequestToSchSaga(action: AddWorkRequestToSchAction): Generator<Effect, void, any> {
  const { controlModals = true } = action.payload;
  try {
    const { scheduleId } = action.payload;
    const response = yield call(jobAPI.addWorkRequestToSch, action.payload);
    yield put(jobActions.addWorkRequestToSch.success(response));

    if (controlModals) {
      yield put(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.SUCCESS,
          contentType: Modals.REQUEST_CREW,
          redirectPath: null,
        }),
      );
    }

    yield put(
      // @ts-ignore
      fetchSchedule(scheduleId),
    );
    yield put(positionActions.positions.request());
  } catch (error: any) {
    yield put(jobActions.addWorkRequestToSch.failure(error));

    if (controlModals) {
      if (get(error, "response.status") === 400) {
        // if it's a 400 error, show the response message in the modal
        const body = yield error.response.json();
        if (body && body.message) {
          return yield put(
            confirmationModalActions.openModal({
              heading: "Request Error",
              body: body.message,
              responseType: SimpleModalTypes.FAILURE,
            }),
          );
        }
      }

      // else render the generic message
      yield put(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.FAILURE,
          contentType: Modals.REQUEST_CREW,
          redirectPath: null,
        }),
      );
    }
  }
}

function* createBlueShiftScheduleJobsSaga(
  action: CreateBlueShiftScheduleJobsAction,
): Generator<Effect, void, any> {
  const { controlModals = true } = action.payload;
  try {
    const response = yield call(jobAPI.createBlueShiftScheduleJobs, action.payload);
    yield put(jobActions.createBlueShiftScheduleJobs.success(response));

    if (controlModals) {
      yield put(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.SUCCESS,
          contentType: Modals.REQUEST_CREW,
          redirectPath: null,
        }),
      );
    }

    yield put(positionActions.positions.request());
  } catch (error: any) {
    yield put(jobActions.addWorkRequestToSch.failure(error));

    if (controlModals) {
      if (get(error, "response.status") === 400) {
        // if it's a 400 error, show the response message in the modal
        const body = yield error.response.json();
        if (body && body.message) {
          return yield put(
            confirmationModalActions.openModal({
              heading: "Request Error",
              body: body.message,
              responseType: SimpleModalTypes.FAILURE,
            }),
          );
        }
      }

      // else render the generic message
      yield put(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.FAILURE,
          contentType: Modals.REQUEST_CREW,
          redirectPath: null,
        }),
      );
    }
  }
}

function* createDirectInviteJobAndScheduleSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(jobAPI.postDirectInviteJobAndSchedule, action.payload);
    yield put(jobActions.createDirectInviteJobAndSchedule.success(response));
  } catch (error) {
    yield put(jobActions.createDirectInviteJobAndSchedule.failure(error));
  }
}
function* createDirectInviteAndOptJobAndScheduleSaga(
  action: CreateDirectInviteAndOptJobAndSchedAction,
): Generator<Effect, void, any> {
  let { payload } = action;
  let successfulDirectInvite = false;
  const requestIncludedDirectInvite = true;
  const requestIncludedWorkRequest = !!payload.additionalData;

  try {
    if (payload.newDepartment) {
      const departmentResponse = yield* createDepartmentSaga(payload);
      payload = {
        ...payload,
        data: {
          ...payload.data,
          tag_id: departmentResponse.insertId,
        },
      };
    }

    let response = yield call(jobAPI.postDirectInviteJobAndSchedule, payload);
    successfulDirectInvite = true;
    if (payload.additionalData) {
      const { scheduleId } = response;
      response = yield call(jobAPI.addWorkRequestToSch, {
        scheduleId,
        payload: payload.additionalData,
      });
    }

    yield put(jobActions.createDirectInviteAndOptJobAndSchedule.success(response));
    if (payload.data.blueshift_request_id) {
      const modalData = getErrorModalDataFromPayload(
        payload,
        true,
        successfulDirectInvite,
        requestIncludedDirectInvite,
        requestIncludedWorkRequest,
      );
      yield put(jobActions.updateBulkSingleShiftResponses(modalData));
    }
  } catch (error) {
    // requests for additional users can fail while direct invite portion still succeeds.
    if (payload.data.blueshift_request_id) {
      const modalData = getErrorModalDataFromPayload(
        payload,
        false,
        successfulDirectInvite,
        requestIncludedDirectInvite,
        requestIncludedWorkRequest,
      );
      yield put(jobActions.updateBulkSingleShiftResponses(modalData));
    }

    yield put(jobActions.createDirectInviteAndOptJobAndSchedule.failure(error));
  }
}

function* createCrewJobAndScheduleSaga(
  action: CreateCrewJobAndSchedAction,
): Generator<Effect, void, any> {
  try {
    const response = yield call(jobAPI.postCrewJobAndSchedule, action.payload);
    yield put(jobActions.createCrewJobAndSchedule.success(response));
  } catch (error) {
    yield put(jobActions.createCrewJobAndSchedule.failure(error));
  }
}
function* createCrewAndOptJobAndScheduleSaga(
  action: CreateCrewAndOptJobAndSchedAction,
): Generator<Effect, void, any> {
  let { payload } = action;
  let successfulDirectInvite = false;
  const requestIncludedWorkRequest = !!payload.additionalData;
  const requestIncludedDirectInvite = true;
  try {
    if (payload.newDepartment) {
      const departmentResponse = yield* createDepartmentSaga(payload);
      payload = {
        ...payload,
        data: {
          ...payload.data,
          tag_id: departmentResponse.insertId,
        },
      };
    }
    let response = yield call(jobAPI.postCrewJobAndSchedule, payload);
    successfulDirectInvite = true;

    if (payload.additionalData) {
      const { scheduleId } = response;
      response = yield call(jobAPI.addWorkRequestToSch, {
        scheduleId,
        payload: payload.additionalData,
      });
    }
    yield put(jobActions.createCrewAndOptJobAndSchedule.success(response));
    if (payload.data.blueshift_request_id) {
      const modalData = getErrorModalDataFromPayload(
        payload,
        true,
        successfulDirectInvite,
        requestIncludedDirectInvite,
        requestIncludedWorkRequest,
      );
      yield put(jobActions.updateBulkSingleShiftResponses(modalData));
    }
  } catch (error) {
    yield put(jobActions.createCrewAndOptJobAndSchedule.failure(error));
    if (payload.data.blueshift_request_id) {
      const modalData = getErrorModalDataFromPayload(
        payload,
        false,
        successfulDirectInvite,
        requestIncludedDirectInvite,
        requestIncludedWorkRequest,
      );
      yield put(jobActions.updateBulkSingleShiftResponses(modalData));
    }
  }
}

function* addCrewJobToScheduleSaga(
  action: AddCrewJobToScheduleAction,
): Generator<Effect, void, any> {
  try {
    const response = yield call(jobAPI.addCrewJobToSchedule, action.payload);
    yield put(jobActions.addCrewJobToSchedule.success(response));
  } catch (error) {
    yield put(jobActions.addCrewJobToSchedule.failure(error));
  }
}

export default {
  *watchJobSaga(): SagaIterator {
    yield takeLatest(jobActions.jobs.request, jobsSaga);
    yield takeLatest(jobActions.jobTypes.request, jobTypeSaga);
    yield takeEvery(jobActions.createJob.request, createJobSaga);
    yield takeLatest(jobActions.supervisors.request, supervisorsSaga);
    yield takeLatest(jobActions.createPosition.request, createPositionSaga);
    yield takeLatest(jobActions.addWorkRequestToSch.request, addWorkRequestToSchSaga);
    yield takeLatest(
      jobActions.createBlueShiftScheduleJobs.request,
      createBlueShiftScheduleJobsSaga,
    );
    yield takeLatest(jobActions.offerJob.request, offerJobSaga);
    yield takeLatest(jobActions.rejectApplicationJob.request, rejectApplicationJobSaga);
    yield takeEvery(
      jobActions.createDirectInviteJobAndSchedule.request,
      createDirectInviteJobAndScheduleSaga,
    );
    yield takeEvery(
      jobActions.createDirectInviteAndOptJobAndSchedule.request,
      createDirectInviteAndOptJobAndScheduleSaga,
    );
    yield takeEvery(jobActions.createCrewJobAndSchedule.request, createCrewJobAndScheduleSaga);
    yield takeEvery(
      jobActions.createCrewAndOptJobAndSchedule.request,
      createCrewAndOptJobAndScheduleSaga,
    );
    yield takeEvery(jobActions.addCrewJobToSchedule.request, addCrewJobToScheduleSaga);
  },
};
