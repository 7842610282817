import { useController } from "react-hook-form";
import { Input, PinIcon, StaticMap, useThemedStyletron } from "@bluecrew/blueprint-web";
import React, { useEffect, useState } from "react";
import { styled } from "baseui";
import { FieldRowWrapper } from "./FieldRowWrapper";
import { FieldLabelRow } from "./FieldLabelRow";
import { Spacer, SpacerType } from "../../components/Spacer";
import { Anchor } from "../../components/Anchor";
import { WorksiteAddress, WorksiteProps } from "./types/propTypes/Worksite.types";
import { WorksiteModal } from "./WorksiteModal";
import { SubText } from "../styledComponents";

export const WorksiteField = ({
  control,
  trigger,
  onLocationSelect,
  disabled = false,
  positions,
  editPositionPage,
}: WorksiteProps) => {
  const fieldName = "worksite";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      required: true,
    },
  });

  const [, theme] = useThemedStyletron();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [customAddress, setCustomAddress] = useState<string>(value?.address || "");

  useEffect(() => {
    if (value) {
      updateCustomAddress(value);
    }
  }, [value]);

  const onClose = () => {
    setIsOpen(false);
    validate();
  };

  const updateCustomAddress = (address: WorksiteAddress) => {
    // displayAddress is custom address which already has a zip code,
    // it's coming from Recent addresses section.
    const addressWithZipCode = address.displayAddress ? address.displayAddress : address.address;

    // Set custom address to include postal code
    setCustomAddress(addressWithZipCode);
  };

  const onItemSelect = (address: WorksiteAddress) => {
    onChange(address);
    updateCustomAddress(address);
    onClose();
  };

  return (
    <>
      <FieldRowWrapper data-pendo-key="WorkSiteEditField">
        <FieldLabelRow>Worksite</FieldLabelRow>
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <InputWrapper onClick={() => !disabled && setIsOpen(true)}>
          <Input
            value={customAddress || ""}
            error={invalid}
            placeholder="Enter the address"
            icon={<PinIcon />}
            disabled={disabled}
          />
        </InputWrapper>
      </FieldRowWrapper>
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale300} />
      <FieldRowWrapper>
        <FieldLabelRow />
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <InputWrapper>
          <StaticMap
            isMarkerShown
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...(value && {
              position: {
                lat: value.lat,
                lng: value.lng,
              },
            })}
            width="100%"
          />
        </InputWrapper>
      </FieldRowWrapper>
      <FieldRowWrapper>
        <FieldLabelRow />
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <SubText>
          Need to identify your location more specifically?&nbsp;
          <Anchor href="https://support.google.com/maps/answer/6320846">
            Pinpoint your location
          </Anchor>
          &nbsp;in Google maps to help Crew Members find the exact place to arrive.
        </SubText>
      </FieldRowWrapper>
      <WorksiteModal
        selectedPosition={value}
        editPositionPage={editPositionPage}
        positions={positions}
        isOpen={isOpen}
        onClose={onClose}
        onItemSelect={onItemSelect}
        onLocationSelect={onLocationSelect}
      />
    </>
  );
};

const InputWrapper = styled("div", () => ({ width: "502.82px" }));
