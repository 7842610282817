import React from "react";
import { useNavigate } from "react-router-dom";
import { ProgressBar, UserAvatar } from "@bluecrew/web-react-core";
// @ts-ignore
import findingIcon from "./icons/finding.svg";
// @ts-ignore
import reviewingIcon from "./icons/reviewing.svg";
// @ts-ignore
import fillingIcon from "./icons/filling.svg";
// @ts-ignore
import filledIcon from "./icons/filled.svg";
import { Can } from "../../containers/PermissionsContext";
import { StyledButton, StyledContainer } from "./styledComponents";

const states = {
  finding: {
    text: "Finding interested candidates",
    img: findingIcon,
  },
  reviewing: {
    text: "Review applicants",
    img: reviewingIcon,
  },
  filling: {
    text: "Filling",
    img: fillingIcon,
  },
  filled: {
    text: "Filled",
    img: filledIcon,
  },
};

function determineStatus(
  applicantsWithoutOffers: number,
  hasApplicationJobs: boolean,
  workersNeeded: number,
  workersFilled: number,
) {
  if (workersFilled === 0) {
    return "finding";
  }

  if (hasApplicationJobs && applicantsWithoutOffers > 0) {
    return "reviewing";
  }

  if (workersFilled > 0 && workersFilled >= workersNeeded) {
    return "filled";
  }
  return "filling";
}

type FillStatusProps = {
  classes: string;
  workersNeeded: number;
  workersFilled: number;
  offersPending: number;
  applicantsWithoutOffers: number;
  hasApplicationJobs: boolean;
  curated?: boolean;
  reviewButtonWidth?: number;
  showReviewButton?: boolean;
  scheduleId?: number;
};

export const FillStatus = ({
  classes,
  workersNeeded,
  workersFilled,
  offersPending,
  applicantsWithoutOffers,
  hasApplicationJobs,
  curated,
  reviewButtonWidth,
  showReviewButton,
  scheduleId,
}: FillStatusProps) => {
  const navigate = useNavigate();

  const redirectToApplicantReview = () => {
    navigate(`/schedules/${scheduleId}/review-applicants`);
  };

  const status = determineStatus(
    applicantsWithoutOffers,
    hasApplicationJobs,
    workersNeeded,
    workersFilled,
  );
  const fillStatusText = `${workersFilled} / ${workersNeeded} Accepted `;

  return (
    <StyledContainer className={classes} showReviewButton={showReviewButton}>
      <div className="fill-components">
        <div className="icon">
          <UserAvatar imgSrc={states[status].img} size="md" />
        </div>
        {(status === "finding" || status === "reviewing") && (
          <div className="info-container">
            <span className={`heading ${status === "reviewing" && "reviewing"}`}>
              {states[status].text}
            </span>
            {hasApplicationJobs ? (
              <span className="fill-info">
                {fillStatusText}&middot;{` ${offersPending} Offers pending`}
              </span>
            ) : (
              <span className="fill-info">{fillStatusText}</span>
            )}
          </div>
        )}

        {(status === "filling" || status === "filled") && (
          <div className="filling-container">
            <div className="header-with-progress">
              <span className="heading">{states[status].text}</span>
              <ProgressBar amountFilled={workersFilled} amountNeeded={workersNeeded} fullWidth />
            </div>
            {curated ? (
              <span className="fill-info">{`${workersFilled} / ${workersNeeded} Crew members filled`}</span>
            ) : (
              <span className="fill-info">{`${workersFilled} / ${workersNeeded} Accepted - ${offersPending} Offers pending`}</span>
            )}
          </div>
        )}
      </div>
      <Can I="update" a="job">
        {showReviewButton && hasApplicationJobs && applicantsWithoutOffers > 0 && (
          <div className="review-applicants">
            <StyledButton
              reviewButtonWidth={reviewButtonWidth}
              palette="primary"
              onClick={() => redirectToApplicantReview()}
            >
              <span>{`View applicants (${applicantsWithoutOffers})`}</span>
            </StyledButton>
          </div>
        )}
      </Can>
    </StyledContainer>
  );
};
