import styled from "styled-components";
import { Row } from "react-styled-flexboxgrid";

export const Container = styled.div`
  margin-left: 80px;
`;

export const StyledRow = styled(Row)`
  height: 80%;
  flex: 1;
`;
