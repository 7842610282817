import React, { useEffect, useState } from "react";
import { Button } from "@bluecrew/web-react-core";
import { RadioWrapper } from "./styledComponents";
import { ListItemCheckbox } from "../SelectionList/ListItemCheckbox";

export type RadioOption = {
  label: string;
  value: any;
  text: string;
  testId?: string;
};

type RadioListProps = {
  disabled?: boolean; // Toggles if list is interactable or not
  options: Array<RadioOption>; // List of options to display in the radio list
  selected?: number; // Index of option to be selected by default. Leave out if no option should be selected initially
  setSelected: (index: number) => void; // Function that radioList can use to set selected index in parent component
};

// For some text, only some parts of it is bolded. This function formats the text so that the bolded parts are wrapped in a <strong> tag
const formatBoldText = (text: string) => {
  const [textBefore, boldText, textAfter] = text.split("**");
  return (
    <>
      {textBefore}
      <strong>{boldText}</strong>
      {textAfter}
    </>
  );
};

export const RadioList = ({ disabled = false, options, selected, setSelected }: RadioListProps) => {
  const [radioValue, setRadioValue] = useState();

  useEffect(() => {
    if (selected !== null && selected !== undefined) {
      setRadioValue(options[selected].value);
    }
  }, [selected]);

  return (
    <RadioWrapper>
      {options.map((option, index) => (
        <div
          key={option.label}
          className="row"
          role="option"
          data-testid={option.testId}
          aria-selected={radioValue === option.value}
          aria-checked={radioValue === option.value}
        >
          <div>
            <Button
              disabled={disabled}
              value={option.value}
              onClick={() => {
                setRadioValue(option.value);
                setSelected(index);
              }}
            >
              <ListItemCheckbox selected={radioValue === option.value} />
            </Button>
          </div>
          <div className="item-text">
            <div className="label">{option.label}</div>
            <span>{formatBoldText(option.text)}</span>
          </div>
        </div>
      ))}
    </RadioWrapper>
  );
};
