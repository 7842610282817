import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const WarnExclaim = ({ width, height, color }: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={svgColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4072 17.027L11.4995 1.34162C11.3562 1.05756 11.1408 0.819645 10.8766 0.653649C10.6124 0.487653 10.3096 0.399902 10.0008 0.399902C9.69206 0.399902 9.38918 0.487653 9.12501 0.653649C8.86083 0.819645 8.64541 1.05756 8.5021 1.34162L0.593561 17.027C0.45847 17.2949 0.392213 17.5944 0.401196 17.8967C0.41018 18.1989 0.494101 18.4937 0.644843 18.7524C0.795585 19.0111 1.00805 19.225 1.26169 19.3735C1.51532 19.522 1.80155 19.6 2.0927 19.5999H17.9081C18.1992 19.6 18.4855 19.522 18.7391 19.3735C18.9927 19.225 19.2052 19.0111 19.3559 18.7524C19.5067 18.4937 19.5906 18.1989 19.5996 17.8967C19.6086 17.5944 19.5423 17.2949 19.4072 17.027ZM9.15438 6.8539C9.15438 6.62085 9.24351 6.39734 9.40217 6.23254C9.56083 6.06775 9.77601 5.97517 10.0004 5.97517C10.2248 5.97517 10.44 6.06775 10.5986 6.23254C10.7573 6.39734 10.8464 6.62085 10.8464 6.8539V12.1263C10.8464 12.3593 10.7573 12.5829 10.5986 12.7476C10.44 12.9124 10.2248 13.005 10.0004 13.005C9.77601 13.005 9.56083 12.9124 9.40217 12.7476C9.24351 12.5829 9.15438 12.3593 9.15438 12.1263V6.8539ZM10.0427 16.9681H10.019C9.68537 16.967 9.36507 16.8319 9.12509 16.5912C8.88511 16.3505 8.74403 16.0227 8.73137 15.6764C8.72527 15.5041 8.75228 15.3324 8.81082 15.1711C8.86935 15.0099 8.95825 14.8623 9.0723 14.7372C9.18635 14.612 9.32328 14.5117 9.47508 14.4421C9.62687 14.3725 9.7905 14.3351 9.9564 14.3319H9.98009C10.3137 14.3323 10.6341 14.4667 10.8745 14.707C11.1148 14.9473 11.2563 15.2748 11.2694 15.621C11.276 15.7936 11.2492 15.9659 11.1908 16.1276C11.1324 16.2893 11.0434 16.4373 10.9291 16.5628C10.8148 16.6883 10.6775 16.7888 10.5253 16.8584C10.3731 16.928 10.209 16.9653 10.0427 16.9681Z"
        fill={svgColor}
      />
    </svg>
  );
};
