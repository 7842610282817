import React, { Dispatch, SetStateAction } from "react";
import { addDays, format } from "date-fns";
import { Spacer, SpacerType } from "../../../../components/Spacer";
import { ButtonPrevious } from "../../Timesheets/TimesheetsPageHeader/ButtonPreviousWeek";
import { ButtonNext } from "../../Timesheets/TimesheetsPageHeader/ButtonNextWeek";
import { ButtonCalendar } from "../../Timesheets/TimesheetsPageHeader/ButtonCalendarWeek";
import { RefreshButton } from "./RefreshButton";
import { SearchBar } from "./SearchBar";
import { ButtonWrapper, Divider, Header, HeaderText, PageHeaderWrapper, ChevronWrapper } from "./styledComponents";

export type PayrollReportsPageHeaderProps = {
  setFilterText: (filterText: string) => void;
  payrollWeek: Date;
  setPayrollWeek: Dispatch<SetStateAction<Date>>;
};

export const PayrollReportsPageHeader = ({ setFilterText, payrollWeek, setPayrollWeek }: PayrollReportsPageHeaderProps) => (
  <PageHeaderWrapper data-testid="payroll-reports-header">
    <Header>
      <HeaderText>
        {`Payroll Reports for week of ${format(payrollWeek, "MMM do")} - ${format(addDays(payrollWeek, 6), "MMM do",)}`}
      </HeaderText>
      <ChevronWrapper className="p-buttonset" style={{ textAlign: "left" }}>
        <ButtonPrevious setWeek={setPayrollWeek} week={payrollWeek} />
        <ButtonCalendar setWeek={setPayrollWeek} week={payrollWeek} />
        <ButtonNext setWeek={setPayrollWeek} week={payrollWeek} />
      </ChevronWrapper>
      <ButtonWrapper>
        <SearchBar onChange={setFilterText} />
        <Spacer $type={SpacerType.horizontal} $size="16px" />
        <RefreshButton />
      </ButtonWrapper>
    </Header>
    <Divider />
  </PageHeaderWrapper>
);
