import React from "react";
import { Link } from "@bluecrew/web-react-core";
import { DashboardPositionJob } from "../../../../api/bluecrew/types";
import { LinkContainer, NameFlexCenterRow } from "./styledComponents";

export const DashboardScheduleRequestCell = ({
  rowData,
  isSubRow,
  openRequestModal,
}: {
  rowData: DashboardPositionJob;
  isSubRow: boolean;
  openRequestModal: (job_id: number) => void;
}) => (
  <NameFlexCenterRow isSubRow={isSubRow}>
    <LinkContainer>
      <Link
        data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnNameLink"
        onClick={() => {
          openRequestModal(rowData.job_id);
        }}
      >
        Request {rowData.job_id}
      </Link>
    </LinkContainer>
  </NameFlexCenterRow>
);
