import React from "react";
import {
  DisputedTime,
  MissingTime,
  SubmittedTime,
  AlignmentContainer,
  JobInProgressTime,
  StyledTime,
  DisputedShiftTime,
} from "../styledComponents";
import { Tooltip } from "../../../../../components/Tooltip";

type TimeViewProps = {
  formattedTime: string;
  isCompleteShiftInDispute: boolean;
  isTimestampSubmitted: boolean;
  isTimestampMissing: boolean;
  hasShiftSubmissionTimeEnded: boolean;
  missingTimeTooltipMessage: string;
  tooltipAnchor: string;
  formattedTimeInDispute?: string;
};

export const TimeView = ({
  formattedTime,
  isCompleteShiftInDispute,
  formattedTimeInDispute,
  missingTimeTooltipMessage,
  isTimestampSubmitted,
  isTimestampMissing,
  tooltipAnchor,
  hasShiftSubmissionTimeEnded,
}: TimeViewProps) => {
  const getStyledTime = () => {
    if (isCompleteShiftInDispute) {
      return <DisputedShiftTime>{formattedTimeInDispute}</DisputedShiftTime>;
    }

    if (isTimestampMissing) {
      return (
        <>
          <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
            {missingTimeTooltipMessage}
          </Tooltip>
          <MissingTime className={tooltipAnchor}>{formattedTime}</MissingTime>
        </>
      );
    }

    if (isTimestampSubmitted) {
      return <SubmittedTime>{formattedTime}</SubmittedTime>;
    }

    if (!hasShiftSubmissionTimeEnded) {
      return <JobInProgressTime>{formattedTime}</JobInProgressTime>;
    }

    return <StyledTime>{formattedTime}</StyledTime>;
  };

  return (
    <AlignmentContainer>
      {getStyledTime()}
      {!isCompleteShiftInDispute && formattedTimeInDispute && (
        <DisputedTime>{formattedTimeInDispute}</DisputedTime>
      )}
    </AlignmentContainer>
  );
};
