import React from "react";
import { Routes, Route } from "react-router-dom";
import { CompanySettings } from "../containers/Settings/CompanySettings";

export const SettingsRoutes = () => {
  return (
    <Routes>
      {/* TODO:(BW-1014) Lift navigation side bar to here (probably)
        https://bluecrewjobs.atlassian.net/browse/BW-1014 */}
      <Route path="company" element={<CompanySettings />} />
      {/* FUTURE MIGRATION TARGETS */}
      {/* <Profile path="profile" />
      <CompanyUsers path="users" />
      <BillingInfo path="billing" />
      <Invoices path="invoices" />
      <Referals path="referals" /> */}
    </Routes>
  );
};
