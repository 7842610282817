import React, { useEffect, useState } from "react";
import {
  SideBarHeaderContainer,
  SideBarLabel,
  DropdownButton,
  CompanySideContainer,
  SideBarSectionContainer,
} from "./styledComponents";
import { CompanySearch } from "./CompanySearch";
import { CompanyFilter } from "./CompanyFilter";
import { UserCompanyInfo } from "../../../api/bluecrew/types";
import { useAppSelector } from "../../../redux";

type CompanyFilterSideBarProps = {
  companies: Array<UserCompanyInfo>;
};

export const CompanyFilterSideBar = ({ companies }: CompanyFilterSideBarProps) => {
  const selectedCompanies = useAppSelector(
    (state) => state.manageUsersCompanyFilter.selectedCompanies,
  );
  const [availableCompanies, setAvailableCompanies] = useState<UserCompanyInfo[]>([]);
  const [isFiltered, setIsFiltered] = useState(false); // only display 'All' checkbox if not filtered.
  const [isActive, setIsActive] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const selectedCompaniesIds = new Set(selectedCompanies.map((company) => company.companyId));
    const remainingCompanies = companies.filter(
      (company) => !selectedCompaniesIds.has(company.companyId),
    );

    if (searchText.trim().length !== 0) {
      const lowerCased = searchText.toLowerCase();
      const newCompanies = remainingCompanies.filter((company) =>
        company.companyName.toLowerCase().includes(lowerCased),
      );
      setAvailableCompanies(newCompanies);
      return;
    }
    setAvailableCompanies(remainingCompanies);
  }, [selectedCompanies, companies]);

  const onCompanySearchChange = (searchStr: string) => {
    setSearchText(searchStr);
    const isValidSearchStr = searchStr.trim().length > 0;
    const lowerCased = searchStr.toLowerCase();
    setIsFiltered(isValidSearchStr);
    const selectedCompaniesIds = selectedCompanies.map((company) => company.companyId);
    const newCompanies = companies.filter(
      (company) =>
        !selectedCompaniesIds.includes(company.companyId) &&
        company.companyName.toLowerCase().includes(lowerCased),
    );
    setAvailableCompanies(newCompanies);
  };

  return (
    <CompanySideContainer>
      <SideBarSectionContainer>
        <SideBarHeaderContainer>
          <SideBarLabel>Companies</SideBarLabel>
          <DropdownButton
            onClick={() => setIsActive(!isActive)}
            style={{ transform: isActive ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </SideBarHeaderContainer>
      </SideBarSectionContainer>
      {isActive && (
        <>
          <CompanyFilter // Displays companies already being filtered by
            allCompanies={companies}
            companies={selectedCompanies}
            isFiltered // implicitly true always. This will hide the 'All' checkbox.
          />
          <CompanySearch searchText={searchText} onCompanySearchChange={onCompanySearchChange} />
          <CompanyFilter // Displays available companies not yet filtered by
            allCompanies={companies}
            companies={availableCompanies}
            isFiltered={isFiltered}
          />
        </>
      )}
    </CompanySideContainer>
  );
};
