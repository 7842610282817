import { Modal, ModalKind } from "@bluecrew/blueprint-web";
import React, { useEffect, useState } from "react";
import { getJobRequestDetails } from "../../../api/bluecrew/dashboard";
import { JobRequest, ReasonCode } from "../../../api/bluecrew/types";
import { JobRequestModalBody } from "./JobRequestModalBody";
import { JobRequestModalHeader } from "./JobRequestModalHeader";
// eslint-disable-next-line import/no-cycle
import { NON_EXISTENT_JOB_REQUEST_ID } from "../DashboardSchedulesAndIndividualShiftsTable";
import { ScheduleType } from "../../GetCrewMembers/types/propTypes/Schedule.types";

interface JobRequestModalProps {
  requestModalIsOpen: boolean;
  closeRequestModal: () => void;
  requestId: number;
  companyId: number;
  positionId: number;
  updateCancelScheduleForJobRequest: (
    jobRequestExternalId: string,
    scheduleId: number,
    scheduleType: ScheduleType,
    workerShifts: number,
  ) => void;
  cancelSchedule: (reasonCode?: ReasonCode, reasonText?: string) => Promise<void>;
}

export const JobRequestModal = ({
  requestModalIsOpen,
  closeRequestModal,
  requestId,
  positionId,
  companyId,
  updateCancelScheduleForJobRequest,
  cancelSchedule,
}: JobRequestModalProps) => {
  // @ts-ignore
  const [jobRequestData, setJobRequestData] = useState<JobRequest>(null);

  useEffect(() => {
    if (requestId !== NON_EXISTENT_JOB_REQUEST_ID) {
      (async () => {
        const jobRequestDetailsResponse = await getJobRequestDetails(
          companyId,
          positionId,
          requestId,
        );
        setJobRequestData(jobRequestDetailsResponse);
      })();
    }
  }, [requestId]);

  return jobRequestData ? (
    <Modal
      header={<JobRequestModalHeader jobRequestData={jobRequestData} />}
      kind={ModalKind.DEFAULT}
      width="80%"
      maxHeight="700px"
      isOpen={requestModalIsOpen}
      onClose={closeRequestModal}
      body={
        <JobRequestModalBody
          cancelSchedule={cancelSchedule}
          updateCancelScheduleForJobRequest={updateCancelScheduleForJobRequest}
          jobRequestData={jobRequestData}
        />
      }
    />
  ) : null;
};
