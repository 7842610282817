import React from "react";
import { WarnExclaim } from "@bluecrew/blueprint-web/src/assets/svg/WarnExclaim";
import { TriageMessage, WarningMessage, WarningWrapper } from "./styledComponents";

export type AuditTrailWarningProps = {
  messageContent: string;
};

export const AuditTrailWarning = ({ messageContent }: AuditTrailWarningProps) => (
  <WarningWrapper>
    <span style={{ textAlign: "center", verticalAlign: "top" }}>
      <WarnExclaim height={"19.2px"} width={"19.2px"} color="#E72E3E" />
    </span>
    <WarningMessage>{messageContent}</WarningMessage>
    <TriageMessage>Please contact your Account Manager or Support for assistance</TriageMessage>
  </WarningWrapper>
);
