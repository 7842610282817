import { UserType } from "../../api/bluecrew/types";

// Add New User & Assign Companies Modal
export const USERID_PLACEHOLDER = NaN;
export const COMPANYID_PLACEHOLDER = NaN;
export const EXTERNALID_PLACEHOLDER = "TEMP_INTERNAL_USER";
export const LAST_ACTIVE_PLACEHOLDER = "";
// Bulk Assign Companies Modal
export const BULK_ASSIGN_USER_DISPLAY_LIMIT = 5; // how many users profile pictures to display in the bulk assign modal
export const ASSIGN_MODAL_WIDTH = 480;

export const INTERNAL_USER_ROLES = [
  UserType.ADMIN,
  UserType.PAYROLL_ADMIN,
  UserType.SUPER_ADMIN,
  UserType.BRANCH_OPS,
  UserType.BRANCH_VIS,
];

export enum UserRoleNames {
  ADMIN = "ADMIN",
  PAYROLL_ADMIN = "PAYROLL_ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  BRANCH_OPS = "BRANCH_OPS",
  BRANCH_VIS = "BRANCH_VIS",
}

export enum SubmitButtonText {
  CREATE_USER = "Create User",
  ASSIGN_COMPANIES = "Assign Companies",
}

export const USERS_THAT_CAN_ACCESS_ALL_COMPANIES = [
  UserType.ADMIN,
  UserType.PAYROLL_ADMIN,
  UserType.SUPER_ADMIN,
];

export const BRANCH_ROLES = [UserRoleNames.BRANCH_OPS, UserRoleNames.BRANCH_VIS] as const;
export const BRANCH_TYPES = [UserType.BRANCH_OPS, UserType.BRANCH_VIS];
export const UserTypeMapping = {
  0: "BLUECREW",
  1: "ADMIN",
  2: "CLIENT_ADMIN",
  3: "CLIENT",
  4: "MANAGER",
  5: "BRANCH_OPS",
  6: "BRANCH_VIS",
  7: "PAYROLL_ADMIN",
  8: "SUPER_ADMIN",
};

export const DEBOUNCE_LENGTH_MS = 400;

export const dropdownOptions = [
  {
    label: "Super Admin",
    value: "SUPER_ADMIN",
    order: 0,
  },
  {
    label: "Payroll Admin",
    value: "PAYROLL_ADMIN",
    order: 1,
  },
  {
    label: "Admin",
    value: "ADMIN",
    order: 2,
  },
  {
    label: "Branch Operations",
    value: "BRANCH_OPS",
    order: 3,
  },
  {
    label: "Branch Visibility",
    value: "BRANCH_VIS",
    order: 4,
  },
] as const;

type DropDownToEnumType = {
  [key: string]: UserType;
};

export const dropDownToEnum: DropDownToEnumType = {
  "Super Admin": UserType.SUPER_ADMIN,
  "Payroll Admin": UserType.PAYROLL_ADMIN,
  Admin: UserType.ADMIN,
  "Branch Operations": UserType.BRANCH_OPS,
  "Branch Visibility": UserType.BRANCH_VIS,
};

export const TABLE_ROW_HEIGHT = 27;

export const DUPLICATE_USER_ERROR = "An account with the given email already exists.";

// Constants for the data-testids
export const ADD_PERMISSIONS_BUTTON = "add-permissions-button";

export const ASSIGN_COMPANIES_MODAL_BODY = "assign-companies-modal-body";
export const ASSIGN_COMPANIES_CANCEL_BUTTON = "assign-companies-cancel-button";
export const ASSIGN_COMPANIES_UPDATE_BUTTON = "assign-companies-update-button";

export const BULK_ASSIGN_COMPANIES_MODAL_BODY = "bulk-assign-companies-modal-body";
export const BULK_ASSIGN_COMPANIES_CANCEL_BUTTON = "bulk-assign-companies-cancel-button";
export const BULK_ASSIGN_COMPANIES_UPDATE_BUTTON = "bulk-assign-companies-update-button";

export const ADD_NEW_USER_BUTTON = "add-new-user-button";
