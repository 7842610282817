import React, { useState } from 'react';
import { Button, KIND, SIZE } from 'baseui/button';
import { styled } from 'baseui';

interface TagsListProps {
  cap?: number;
  collapseText?: string;
  expander?: 'link' | 'button';
  expandText?: string;
  list: any[];
  percentageWidth?: number;
  renderListItem: (item: any, index: number) => void;
}

export const TagsList = ({
  cap = 20,
  collapseText = 'Show less...',
  expander = 'link',
  expandText = 'Show all...',
  list = [],
  percentageWidth = 100,
  renderListItem,
}: TagsListProps) => {
  const [showAllTags, setShowAllTags] = useState(false);
  const showHideText = showAllTags ? collapseText : expandText;

  const StyledTagContainer = styled('div', () => ({
    width: `${percentageWidth}%`,
  }));

  const StyledTextContainer = styled('div', () => ({
    ':hover': {
      cursor: 'pointer',
    },
    margin: '10px 0 0 10px',
  }));

  return (
    <StyledTagContainer>
      {list.length <= cap || showAllTags
        ? list.map((item, index) => renderListItem(item, index))
        : list.slice(0, cap).map((item, index) => renderListItem(item, index))}

      {list.length >= cap && (
        <StyledTextContainer>
          {/* Link Button */}
          {expander === 'link' && (
            <span
              role="button"
              onClick={() => setShowAllTags(!showAllTags)}
              onKeyPress={() => {}}
              tabIndex={0}
            >
              {showHideText}
            </span>
          )}

          {/* Button */}
          {expander === 'button' && (
            <Button
              kind={KIND.minimal}
              size={SIZE.compact}
              onClick={() => setShowAllTags(!showAllTags)}
            >
              {showHideText}
            </Button>
          )}
        </StyledTextContainer>
      )}
    </StyledTagContainer>
  );
};
