import React from "react";
import { CompanyWorker } from "../../../../../api/bluecrew/payroll";
import { WorkerListContainer, WorkerListItemContainer } from "../styledComponents";
import { WorkerInfo } from "../Components/WorkerInfo";

export type ConfirmWorkersScreenProps = {
  selectedWorkers: Array<CompanyWorker>;
};
export const ConfirmWorkersScreen = ({ selectedWorkers }: ConfirmWorkersScreenProps) => (
  <WorkerListContainer>
    {selectedWorkers.map((worker) => (
      <WorkerListItemContainer key={worker.externalId}>
        <WorkerInfo worker={worker} />
      </WorkerListItemContainer>
    ))}
  </WorkerListContainer>
);
