import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  useGetCrewGroupsQuery,
  useGetCrewMembersQuery,
} from "../../api/bluecrew/hooks/crewMembers";
import {
  useGetDepartmentsQuery,
  useGetJobTypesHistoryQuery,
  useGetJobTypesQuery,
  useGetSupervisorsQuery,
} from "../../api/bluecrew/hooks/job";
import { useGetPositionsQuery, useReadPositionQuery } from "../../api/bluecrew/hooks/position";
import { ListedPosition } from "../../api/bluecrew/types";
import { StateShape } from "../../redux/reducers";
import { getUserCompany, getUserId } from "../../redux/selectors/auth";
import { getCompanyDetails } from "../../redux/selectors/company";
import { DEFAULT_POSITION_TO_COPY } from "./constants";
import GetCrewForm, { GetCrewMembersRouteComponentProps } from "./GetCrewForm";
import { getSingleAssignmentViewFromUrl } from "./getCrewUtils/urlSearchParamUtils";
import jobActions from "../../redux/actions/job";
import positionActions from "../../redux/actions/position";
import { getTreatmentValue } from "../../redux/selectors/splitio";
import {
  BULK_SINGLE_SHIFT_SPLIT_NAME,
  BULK_SINGLE_SHIFT_50_DAY_MAXIMUM_SPLIT_NAME,
} from "../../../shared/constants";
import { getGetCrewFormValues } from "./getCrewUtils/getCrewFormValuesUtils";
import { useGetScheduleQuery } from "../../api/bluecrew/hooks/schedule";
import { useAppSelector } from "../../redux";

export type GetCrewFormWrapperProps = {
  googleMaps: any;
} & GetCrewMembersRouteComponentProps;

/**
 * This component wraps GetCrewForm.
 * It is used to pass in props, which were originally obtained from Redux.
 * However, since we want to use react-query and GetCrewForm is not a functional component, we need to pass them in this way until GetCrewForm is a functional component.
 */
export const GetCrewFormWrapper = ({ googleMaps }: GetCrewFormWrapperProps) => {
  const location = useLocation();
  const params = new URL(window.location.toString()).searchParams;
  let { scheduleId } = useParams();
  if (!scheduleId) {
    scheduleId = params.get("scheduleId") || undefined;
  }
  const duplicatePositionId = location?.state?.createFromPositionId;
  const companyId = useAppSelector((state) => getUserCompany(state));
  const companyDetails = useAppSelector((state) => getCompanyDetails(state));
  const userId = useAppSelector((state) => getUserId(state));
  const isBulkSingleShiftEnabled = useAppSelector(
    (state: StateShape) => getTreatmentValue(state, BULK_SINGLE_SHIFT_SPLIT_NAME) === "on",
  );
  const isBulkSingleShiftMax50DayEnabled = useAppSelector(
    (state: StateShape) =>
      getTreatmentValue(state, BULK_SINGLE_SHIFT_50_DAY_MAXIMUM_SPLIT_NAME) === "on",
  );

  const navigate = useNavigate();

  const { data: jobTypes, isLoading: isJobTypesFetching } = useGetJobTypesQuery();
  const {
    data: positions,
    isLoading: isPositionsFetching,
    refetch: refetchPositions,
  } = useGetPositionsQuery();
  const { data: departments, isLoading: isDepartmentsFetching } = useGetDepartmentsQuery(companyId);
  const { data: supervisors, isLoading: isSupervisorsFetching } = useGetSupervisorsQuery();

  const { data: crewGroups, isLoading: isCrewGroupsFetching } = useGetCrewGroupsQuery();
  const { data: crewMembers, isLoading: isCrewMembersFetching } = useGetCrewMembersQuery();
  const { data: positionToCopy, isInitialLoading: isPositionToCopyFetching } = useReadPositionQuery(
    duplicatePositionId!,
    !!duplicatePositionId,
  );
  const { data: jobTypesHistory, isLoading: isJobTypesHistoryFetching } =
    useGetJobTypesHistoryQuery();
  const { data: preselectedScheduleData, isInitialLoading: isPresectedScheduleInfoFetching } =
    useGetScheduleQuery(scheduleId!, !!scheduleId);

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(jobActions.clearDirectInviteUserIds());
      dispatch(jobActions.beginAnyJobPost());
      dispatch(jobActions.updateBulkSingleShiftResponsesRequired(0));
      dispatch(positionActions.clearReadPosition());
    },
    [],
  );

  if (
    companyDetails === null ||
    isJobTypesFetching ||
    jobTypes === undefined ||
    isPositionsFetching ||
    positions === undefined ||
    departments === undefined ||
    isDepartmentsFetching ||
    supervisors === undefined ||
    isSupervisorsFetching ||
    crewGroups === undefined ||
    isCrewGroupsFetching ||
    crewMembers === undefined ||
    isCrewMembersFetching ||
    isPositionToCopyFetching ||
    jobTypesHistory === undefined ||
    isJobTypesHistoryFetching ||
    isPresectedScheduleInfoFetching
  ) {
    return <SpinnerWithOverlay />;
  }
  const getCrewValues = getGetCrewFormValues({
    preselectedScheduleData,
    isBulkSingleShiftEnabled,
    supervisors,
    crewMembers,
    userId,
    positions,
    scheduleId,
  });

  return (
    <GetCrewForm
      googleMaps={googleMaps}
      companyId={companyId}
      companyDetails={companyDetails}
      jobTypes={jobTypes}
      positions={positions}
      refetchPositions={refetchPositions}
      departments={departments}
      supervisors={supervisors}
      crewGroups={crewGroups}
      crewMembers={crewMembers}
      userId={userId}
      positionToCopy={
        // TODO: BW-1488 Update Type
        (positionToCopy as unknown as ListedPosition) ??
        // TODO: BW-1488 Update Type
        (DEFAULT_POSITION_TO_COPY as unknown as ListedPosition)
      }
      jobTypesHistory={jobTypesHistory}
      singleAssignmentView={getSingleAssignmentViewFromUrl()}
      getCrewValues={getCrewValues}
      isBulkSingleShiftEnabled={isBulkSingleShiftEnabled}
      isBulkSingleShiftMax50DayEnabled={isBulkSingleShiftMax50DayEnabled}
      navigate={navigate}
    />
  );
};
