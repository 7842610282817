import * as React from "react";
import styled from "styled-components";
import { assetUrl } from "@bluecrew/web-react-employer/src/scripts/api/bluecrew/constants";
import { Button } from "@bluecrew/reakit";
import { IntercomGlobalVariableName } from "@bluecrew/web-react-employer/src/scripts/intercom";

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 16px;
  z-index: 998;
`;

const StyledButton = styled(Button)`
  margin: 8px 24px 24px 16px;
  width: 180px;
  height: 40px;
  align-items: center;
  padding: 8px 16px;
  display: flex;
  background-color: #3246f0;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #babbc4;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  position: relative;
`;

const StyledIcon = styled.img`
  margin-right: 8px;
`;

export const CustomerSupportButton = () => {
  const openIntercomWidget = () => window[IntercomGlobalVariableName]("show");
  return (
    <StyledContainer>
      <StyledButton onClick={openIntercomWidget}>
        <StyledIcon src={`${assetUrl}/customer-support-icon.svg`} />
        <span style={{ marginBottom: "2px" }}>Customer support</span>
      </StyledButton>
    </StyledContainer>
  );
};
