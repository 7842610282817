// GLOBAL STYLES
// =============================================================================

import { createGlobalStyle as injectGlobal } from "styled-components";

/**
 * NOTE:
 * The injectGlobal API is deprecated in styled-components v4, but we're
 * consuming it through reakit, which is still on v3. Once reakit upgrades to
 * v4, this should change to the easier style. For the time being, this is the
 * best way to reset styles not under Reakit's supervision and to apply global
 * settings like @font-face
 */
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

injectGlobal`
  html, body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: "Inter", sans-serif;
    vertical-align: baseline;
  }

  body {
    font-size: 85%;
  }

  /*
    REACH ROUTER FIX: Router renders a <div> with no other way to apply styling.
    For the purposes of this app, each of these <div>s is a view or a child
    view. Beacuse we use a Flexbox layout, the safest default is a flex element
    with flex: 1.
  */
  div[role="group"][tabindex] {
    display: flex;
    flex: 1;
  }
`;
