import React from 'react';
import { DatePicker, ORIENTATION } from 'baseui/datepicker';
import { styled } from 'baseui';
import en from 'date-fns/locale/en-US';
import { format } from 'date-fns';
import { Theme } from 'baseui/theme';
import { StyleObject } from 'styletron-react';
import { ChevronDownIcon } from '../assets/svg/ChevronDownIcon';
import {
  getBorder,
  getBorderColor,
  getBorderRadius,
  getBorderWidth,
} from '../helpers/styleHelpers';
import { BlueprintTheme, useThemedStyletron } from '../theme';

export interface CalendarProps {
  placeholder?: string;
  value?: Date | Date[];
  onChange?: (val: Date | Date[]) => void;
  onClose?: () => void;
  range?: boolean;
  monthsShown?: 1 | 2;
  error?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export const Calendar = (props: CalendarProps) => {
  const [, theme] = useThemedStyletron();
  const {
    placeholder,
    value,
    onChange,
    range = true,
    monthsShown = 2,
    error = false,
    onClose,
    minDate,
    maxDate,
    disabled = false,
  } = props;

  function formatDate(date: Date | Date[]): string {
    const [startDate, endDate] = Array.isArray(date) ? date : [date];
    const arrayOfWeekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    switch (true) {
      case !!startDate && !!endDate && (startDate !== endDate || !!range):
        if (startDate.getFullYear() !== endDate.getFullYear()) {
          return `${arrayOfWeekdays[startDate.getDay()]} ${format(
            startDate,
            'd LLL, yyyy',
          )} - ${arrayOfWeekdays[endDate.getDay()]} ${format(
            endDate,
            'd LLL, yyyy',
          )}`;
        }
        return `${arrayOfWeekdays[startDate.getDay()]} ${format(
          startDate,
          'd LLL',
        )} - ${arrayOfWeekdays[endDate.getDay()]} ${format(
          endDate,
          'd LLL',
        )}, ${endDate.getFullYear()}`;
      case !!startDate:
        return `${arrayOfWeekdays[startDate.getDay()]} ${format(
          startDate,
          'd LLL, yyyy',
        )}`;
      default:
        return '';
    }
  }

  return (
    <DatePicker
      locale={en}
      range={range}
      value={value}
      error={error}
      disabled={disabled}
      onChange={({ date }) => onChange && onChange(date)}
      placeholder={placeholder}
      orientation={
        monthsShown === 1 ? ORIENTATION.vertical : ORIENTATION.horizontal
      }
      onClose={onClose}
      monthsShown={monthsShown}
      formatDisplayValue={formatDate}
      mask={null}
      minDate={minDate}
      maxDate={maxDate}
      overrides={{
        Root: {
          style: ({ $theme }: any) => ({
            ...RootContainerStyles($theme),
          }),
        },
        Input: {
          props: {
            overrides: {
              Root: {
                style: ({ $isFocused, $theme, $error }: any) => ({
                  ...InputRootStyles($theme, $isFocused, $error),
                }),
              },
            },
          },
        },
        Popover: {
          props: {
            overrides: {
              Body: {
                style: ({ $theme }: any) => ({
                  ...PopoverBodyStyles($theme),
                }),
              },
              Inner: {
                style: ({ $theme }: any) => ({
                  ...PopoverInnerArrowStyles($theme),
                }),
              },
              Arrow: {
                style: ({ $theme }: any) => ({
                  ...PopoverInnerArrowStyles($theme),
                }),
              },
            },
          },
        },
        MonthYearSelectIconContainer: () => (
          <DownIconWrapper>
            <ChevronDownIcon
              width={9}
              height={14}
              color={theme.colors.primaryB}
            />
          </DownIconWrapper>
        ),
        Day: {
          style: ($props) => ({
            ...DayStyles($props),
          }),
        },
      }}
    />
  );
};

const DownIconWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  marginLeft: $theme.sizing.scale400,
}));

const RootContainerStyles = ($theme: Theme): StyleObject => ({
  ...getBorderRadius($theme.borders.radius200),
  overflow: 'hidden',
});

const InputRootStyles = (
  $theme: BlueprintTheme,
  $isFocused: boolean,
  $error: boolean,
): StyleObject => ({
  boxSizing: 'border-box',
  ...getInputBorder($theme, $isFocused, $error),
  boxShadow: $isFocused ? $theme.lighting.inputShadowActive : undefined,
});

const PopoverBodyStyles = ($theme: Theme): StyleObject => ({
  ...getBorderRadius($theme.borders.radius200),
  backgroundColor: $theme.colors.accent400,
  outline: 'none',
  left: $theme.sizing.scale500,
});

const PopoverInnerArrowStyles = ($theme: Theme): StyleObject => ({
  backgroundColor: $theme.colors.primaryB,
  ...getBorderRadius($theme.borders.radius200),
});

const DayStyles = ({
  $theme,
  $isHighlighted,
  $pseudoHighlighted,
  $pseudoSelected,
  $selected,
  $hasRangeHighlighted,
  $hasRangeSelected,
  $disabled,
}: any): StyleObject => ({
  color:
    // eslint-disable-next-line no-nested-ternary
    (($hasRangeSelected || $hasRangeHighlighted) &&
      ($isHighlighted || $selected || $pseudoHighlighted || $pseudoSelected)) ||
    $isHighlighted
      ? 'white'
      : $disabled
      ? 'grey'
      : 'black',
  ...getBorderRadius($theme.sizing.scale850),
  boxShadow:
    ($isHighlighted || $pseudoSelected) &&
    !($hasRangeSelected || $hasRangeHighlighted)
      ? $theme.lighting.inputShadowActive
      : 'none',
  ':hover': {
    color: $disabled ? 'grey' : $theme.colors.primaryB,
    boxShadow:
      // eslint-disable-next-line no-nested-ternary
      (($hasRangeSelected || $hasRangeHighlighted) &&
        ($isHighlighted ||
          $selected ||
          $pseudoHighlighted ||
          $pseudoSelected)) ||
      $isHighlighted
        ? $disabled
          ? 'none'
          : $theme.lighting.inputShadowActive
        : 'none',
  },
  ':before': {
    backgroundColor: $theme.colors.accent400,
    ...getBorderColor($theme.colors.accent400),
  },
  ':after': {
    backgroundColor: $theme.colors.accent400,
    ...getBorderWidth('0px'),
  },
});

function getInputBorder(
  $theme: BlueprintTheme,
  $isFocused: boolean,
  $error: boolean,
) {
  const { borders, colors } = $theme;

  if ($isFocused) {
    return getBorder({
      ...borders.border100,
      borderColor: colors.inputBorderActive,
    });
  }

  if ($error) {
    return getBorder({
      ...borders.border200,
      borderColor: colors.inputBorderError,
    });
  }
  return getBorder(borders.border100);
}
