import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

import {
  FETCH_CREW_MEMBERS_REQUEST,
  FETCH_CREW_MEMBERS_SUCCESS,
  FETCH_CREW_MEMBERS_FAILURE,
  POST_CREW_MEMBERS_REQUEST,
  POST_CREW_MEMBERS_SUCCESS,
  POST_CREW_MEMBERS_FAILURE,
} from "../constants";
import { BEGIN_ANY_JOB_POST } from "../actions/job";

const initialState = {
  crewMembers: { ...initialEndpointState },
  postCrewMembers: { ...initialEndpointState },
};

export type CrewMembersState = typeof initialState;

export default function crewMembersReducer(
  state: CrewMembersState = initialState, // @ts-ignore $FlowFixUnknownDefinition
  action,
) {
  switch (action.type) {
    case FETCH_CREW_MEMBERS_REQUEST:
      return handleRequest("crewMembers", state);
    case FETCH_CREW_MEMBERS_SUCCESS:
      return handleSuccess("crewMembers", state, action);
    case FETCH_CREW_MEMBERS_FAILURE:
      return handleFailure("crewMembers", state, action);
    case POST_CREW_MEMBERS_REQUEST:
      return handleRequest("postCrewMembers", state);
    case POST_CREW_MEMBERS_SUCCESS:
      return handleSuccess("postCrewMembers", state, action);
    case POST_CREW_MEMBERS_FAILURE:
      return handleFailure("postCrewMembers", state, action);
    case BEGIN_ANY_JOB_POST:
      return { ...state, postCrewMembers: { ...initialEndpointState } };
    default:
      return state;
  }
}
