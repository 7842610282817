import styled from "styled-components";
import { Row } from "react-styled-flexboxgrid";

export const StyledRow = styled(Row)`
  height: 80%;
  .back-button {
    margin-top: 25px;
    text-align: center;
  }
  .text-container {
    margin-bottom: 1rem;
    text-align: center;
  }
  .main-text {
    font-size: 1.75rem;
  }
  .sub-text {
    color: #7a7b81;
    font-size: 1.125rem;
  }
`;
