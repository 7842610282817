import styled from "styled-components";

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 36px;
  padding-top: 36px;
  position: sticky;
  top: 0px;
  background-color: ${({ theme }) => theme.colorTokens.white};
  padding-bottom: 104px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 24px;
  margin-bottom: 44px;
  border-top: 1px solid ${({ theme }) => theme.colorTokens.gray200};
`;

export const HeaderText = styled.div`
  font-family: ${({ theme }) => theme.typography.font750.fontFamily};
  font-size: ${({ theme }) => theme.typography.font750.fontSize};
  font-weight: ${({ theme }) => theme.typography.font750.fontWeight};
  line-height: ${({ theme }) => theme.typography.font750.lineHeight};
  font-style: normal;
  color: ${({ theme }) => theme.colorTokens.gray800};
  padding-right: 1rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0;
  button:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-direction: row;
  button:focus {
    outline: none;
    box-shadow: none;
  }
`;
