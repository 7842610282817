import React from "react";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { Button } from "@bluecrew/web-react-core";
import { StyledRow } from "./styledComponents";

interface ErrorRedirectProps {
  path?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
const ErrorRedirect = ({ path }: ErrorRedirectProps) => (
  <Grid>
    <StyledRow middle="xs">
      <Col xs={12}>
        <Row center="xs">
          <Col xs={6} className="text-container">
            <span className="main-text" data-testid="main-text">
              Oops! Something went wrong...
            </span>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={6} className="text-container">
            <span className="sub-text" data-testid="main-text">
              Try loading the page again later.
            </span>
          </Col>
        </Row>
        <Row center="xs">
          <Col xs={6} className="back-button">
            <Button
              palette="primary"
              onClick={() => {
                // @ts-ignore
                window.location = "/";
              }}
            >
              Back to Dashboard
            </Button>
          </Col>
        </Row>
      </Col>
    </StyledRow>
  </Grid>
);

export default ErrorRedirect;
