import React from "react";

import { Button } from "@bluecrew/web-react-core";

type ClickHandlerType = () => void;
interface CancelButtonProps {
  onClick: ClickHandlerType;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ onClick }) => (
  <Button
    data-testid="ConfirmModal-cancel"
    palette="secondary"
    className="cancel-button secondary"
    onClick={onClick}
  >
    No, go back
  </Button>
);
