import React from "react";
import styled from "styled-components";

const SpaceContainer = styled.span`
  display: block;
  height: 24px;
  margin: 0;
`;

const messages = {
  DEFAULT: {
    success: {
      heading: "Request Successful",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting complete your request.",
    },
  },
  ARCHIVE_SCHEDULE: {
    confirm: {
      heading: "Archive this schedule?",
      body(scheduleName) {
        return `Are you sure you want to archive the schedule '${scheduleName}'?`;
      },
    },
    success: {
      heading: "Schedule Archived",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to archive the schedule.",
    },
  },
  UNPIN_SCHEDULE: {
    confirm: {
      heading: "Unpin Schedule",
      body: (
        <>
          <div>Unpinned schedules will automatically archive when they become inactive.</div>
          <SpaceContainer />
          <div>Would you like to unpin this schedule?</div>
        </>
      ),
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to unpin the schedule.",
    },
  },
  ARCHIVE_UNPINNED_SCHEDULE: {
    confirm: {
      heading: "Archive schedule",
      body: (
        <>
          <div>This schedule is currently inactive. Unpinning it will archive it immediately.</div>
          <SpaceContainer />
          <div>Are you sure you want to archive this schedule?</div>
        </>
      ),
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to archive the schedule.",
    },
  },
  PIN_SCHEDULE: {
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to pin the schedule.",
    },
  },
  DELETE_SCHEDULE: {
    confirm: {
      heading: "Cancel all jobs for this schedule?",
      body: "Are you sure you want to cancel all jobs for this schedule? If you cancel within 24 hours of a shift’s start time you are billed for 4 hours of time for each crew member confirmed to work the job.",
    },
    success: {
      heading: "Schedule Cancelled",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to cancel your work requests.",
      err: "props.cancelFailureResponse.message",
    },
  },
  DELETE_WORK_REQUEST: {
    confirm: {
      heading: "Cancel this job request?",
      body: "Are you sure you want to cancel this job request? If you cancel within 24 hours of a shift’s start time you are billed for 4 hours of time for each crew member confirmed to work the job.",
    },
    success: {
      heading: "Job Request Cancelled",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to cancel your job request.",
      err: "props.cancelFailureResponse.message",
    },
  },
  REQUEST_CREW: {
    success: {
      heading: "Request Posted",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to create your work requests.",
      err: "props.addCrewfailureResponse.message",
    },
  },
  ADD_PREVIOUS_CREW: {
    success: {
      heading: "Schedule Sent to crew member",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to create your crew member requests.",
      err: "props.addPreviousCrewMemberFailureResponse.message",
    },
  },
  ADD_REMOVE_DAY: {
    success: {
      heading: "Schedule Updated",
      body: "We'll notify crew members that this change was made to their schedule.",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to update your schedule.",
      err: "props.createScheduleExceptionFailureResponse.message",
    },
  },
  MODIFY_TIME: {
    success: {
      heading: "Schedule Updated",
      body: "We'll notify crew members that this change was made to their schedule.",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to update your schedule.",
      err: "props.createScheduleExceptionFailureResponse.message",
    },
  },
  ARCHIVE_POSITION: {
    success: {
      heading: "Position deleted.",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to delete your position.",
      err: "props.deletePositionFailureResponse.message",
    },
  },
  UPDATE_POSITION_DETAILS: {
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to edit the position details.",
      err: "props.updatePositionDetailsFailureResponse.message",
    },
  },
  OFFER_JOB: {
    success: {
      heading: "Job Offer(s) sent.",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to offer this job(s).",
      err: "props.jobOfferFailureResponse.message",
    },
  },
  OPEN_APPLICATION_JOBS_TO_PLATFORM: {
    confirm: {
      heading: "Open request to platform?",
      body(scheduleName) {
        return `This will dispatch ${scheduleName} to our entire platform.`;
      },
      button: "Open request",
    },
    loading: "Opening request...",
    success: {
      heading: "Request opened to platform",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to open the request to the platform.",
    },
  },
  REJECT_APPLICATION_JOB: {
    success: {
      heading: "Applicant rejected.",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to reject this applicant.",
      err: "props.rejectJobApplicantFailureResponse.message",
    },
  },
  DISABLE_CONTINUOUS_FILL: {
    confirm: {
      heading: "Disable continuous fill",
      body(positionName) {
        return `By disabling, all existing schedules that have started (that are past their start date) under the "${positionName}" position will no longer be available to Crew Members. This will not affect any Crew Members that have already accepted the job.`;
      },
      button: "Disable continuous fill",
    },
    success: {
      heading: "Continuous fill disabled",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to disable continuous fill for this position.",
    },
  },
  ENABLE_CONTINUOUS_FILL: {
    confirm: {
      heading: "Enable continuous fill",
      body(positionName) {
        return (
          <>
            <span>
              Increase fill rates by continuing to fill openings even after jobs have started.
              Openings will refill starting on the next scheduled shift.
            </span>
            <SpaceContainer />
            <span>
              By enabling this feature, continuous fill will immediately try to find Crew Members
              for any unfilled schedules under the “{positionName}” position.
            </span>
          </>
        );
      },
      button: "Enable continuous fill",
    },
    success: {
      heading: "Continuous fill enabled",
      body: "",
    },
    failure: {
      heading: "Request Error",
      body: "An error occurred when attempting to enable continuous fill for this position",
    },
  },
};

export default messages;
