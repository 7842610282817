import React from "react";
import { StyledCancelButton, StyledConfirmButton } from "./styledComponents";

export type ShiftAdjustFooterProps = {
  footerButtonLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
};
export const ShiftAdjustFooter = ({
  footerButtonLabel,
  onConfirm,
  onCancel,
}: ShiftAdjustFooterProps) => (
  <div>
    <StyledCancelButton label="Cancel" className="p-button-text" onClick={onCancel} />
    <StyledConfirmButton label={footerButtonLabel} onClick={onConfirm} />
  </div>
);
