import styled from "styled-components";
import React from "react";
import { Tooltip as PRTooltip, TooltipProps } from "primereact/tooltip";

const TooltipWrapper = (props: TooltipProps) => (
  <PRTooltip className={props.className} {...props} />
);

export const Tooltip = styled(TooltipWrapper)`
  .p-tooltip-text {
    font-family: ${({ theme }) => theme.typography.font100.fontFamily};
    font-size: ${({ theme }) => theme.typography.font100.fontSize};
    font-weight: ${({ theme }) => theme.typography.font100.fontWeight};
    line-height: ${({ theme }) => theme.typography.font100.lineHeight};
    font-style: normal;
  }
`;
