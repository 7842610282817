/* eslint-disable no-param-reassign */
import { PayrollReportDataType, PayrollReportsResponseType } from "../../../api/bluecrew/payroll";

export type FormattedPayrollReportDataType = Pick<
  PayrollReportDataType,
  "accountManagerId" | "totalThisWeek"
> & {
  needsAction: PayrollReportDataType["needApprovalAllTime"];
  pendingReview: PayrollReportDataType["pendingReview"];
  overdue: PayrollReportDataType["overdue"];
  disputed: PayrollReportDataType["disputed"];
  internalCompanyId: PayrollReportDataType["id"];
  companyName: PayrollReportDataType["name"];
  accountManagerName: string;
};

export const formatPayrollReportsData = (
  data: PayrollReportsResponseType,
): Array<FormattedPayrollReportDataType> =>
  data.map(
    ({ accountManagerId, accountManagerName, needApprovalAllTime, totalThisWeek, name, id, pendingReview, overdue, disputed }) => ({
      accountManagerId,
      accountManagerName: accountManagerName || "",
      needsAction: needApprovalAllTime,
      totalThisWeek,
      companyName: name,
      internalCompanyId: id,
      pendingReview,
      overdue,
      disputed,
    }),
  );

export type AggregatedPayrollReportsDataType = {
    week: number,
    pendingReview: number,
    overdue: number,
    disputed: number,
}

const aggregatePayrollReportsData = (
  week: number,
  data: Array<FormattedPayrollReportDataType>,
): AggregatedPayrollReportsDataType => {
  return data.reduce((acc, cv) => {
    acc.pendingReview += cv.pendingReview
    acc.overdue += cv.overdue
    acc.disputed += cv.disputed
    return acc
  }, {week, pendingReview: 0, overdue: 0, disputed: 0})
}

export const getRetroMessageText = (
  week: number,
  data: Array<FormattedPayrollReportDataType>,
): string => {
  const aggregatedData = aggregatePayrollReportsData(week, data)
  const pendingReview= aggregatedData.pendingReview > 0 || aggregatedData.overdue > 0
  const disputed= aggregatedData.disputed > 0
  const prefix = (pr: boolean, disp: boolean) => {
    if (pr && !disp) return `shifts "Pending Review"`
    if (disp && !pr) return `"Disputed" shifts`
    if (disp && pr) return `"Pending Review" and "Disputed" shifts`
    return ""
  }
  return (!pendingReview && !disputed) ? "" : `There are ${prefix(pendingReview, disputed)} from last week. Please see previous week to address these issues.`
}
