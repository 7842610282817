import React from "react";
import { Row, Col } from "react-styled-flexboxgrid";
import { JobRequestTableCell, JobRequestTableCellProps } from "./JobRequestTableCell";

interface JobRequestTableRowProps {
  cells: JobRequestTableCellProps[];
}

export const JobRequestTableRow = ({ cells }: JobRequestTableRowProps) => (
  <Row start="xs">
    {cells.map((c: JobRequestTableCellProps, i: number) => (
      <Col key={i} xs={6} md={4}>
        <JobRequestTableCell cellTitle={c.cellTitle} cellContents={c.cellContents} />
      </Col>
    ))}
  </Row>
);
