import React from "react";
import { Checkbox } from "primereact/checkbox";
import { CompanyWorker } from "../../../../../api/bluecrew/payroll";
import { WorkerListContainer, WorkerListItemContainer } from "../styledComponents";
import { WorkerInfo } from "./WorkerInfo";

export type WorkerListProps = {
  allWorkers: Array<CompanyWorker>;
  selectedWorkers: Set<CompanyWorker>;
  setSelectedWorkers: (workers: Set<CompanyWorker>) => void;
  filterText: string;
};
export const WorkerList = ({
  allWorkers,
  selectedWorkers,
  setSelectedWorkers,
  filterText,
}: WorkerListProps) => {
  const handleListItemClick = (worker: CompanyWorker) => {
    const newSelectedWorkers = new Set<CompanyWorker>([...selectedWorkers]);
    if (selectedWorkers.has(worker)) {
      newSelectedWorkers.delete(worker);
    } else {
      newSelectedWorkers.add(worker);
    }
    setSelectedWorkers(newSelectedWorkers);
  };

  const filteredWorkers = filterText.length
    ? allWorkers.filter((worker) => `${worker.firstName} ${worker.lastName}`.includes(filterText))
    : allWorkers;

  return (
    <WorkerListContainer>
      {filteredWorkers.map((worker) => (
        <WorkerListItemContainer
          key={worker.externalId}
          onClick={() => handleListItemClick(worker)}
        >
          <WorkerInfo worker={worker} />
          <Checkbox checked={selectedWorkers.has(worker)} />
        </WorkerListItemContainer>
      ))}
    </WorkerListContainer>
  );
};
