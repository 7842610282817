import get from "lodash/get";
import { selectTreatmentValue } from "@splitsoftware/splitio-redux";
import { ACTIVE_SPLIT_NAMES } from "../../../shared/constants";
import { StateShape } from "../reducers";
import { getSplitIoIsBroken } from "./app";

export const getIsSplitioReady = (state: StateShape): boolean => {
  const splitIsBroken = getSplitIoIsBroken(state);
  return (
    get(state, "splitio.isReady") || // When split is broken, pretend that split is "ready."
    // This unblocks any screen waiting on readiness.
    splitIsBroken
  );
};

export const getTreatmentValue = (state: StateShape, key: string): "on" | "off" | "control" => {
  if (!ACTIVE_SPLIT_NAMES.includes(key)) {
    throw new Error(`This treatment name key "${key}" has not been added to ACTIVE_SPLIT_NAMES`);
  }
  // @ts-ignore
  return selectTreatmentValue(state.splitio || { treatments: {} }, key);
};
