import React from "react";
import { useDispatch } from "react-redux";
import { DropdownChangeEvent } from "primereact/dropdown";
import { getExternalUserId, getUserType } from "../../../redux/selectors/auth";
import { UserType } from "../../../api/bluecrew/types";
import { StyledDropdown } from "./styledComponents";
import {
  useEditClientRole,
  useUpdateUsersCompaniesAndRole,
} from "../../../api/bluecrew/hooks/user";
import { InternalUser } from "./InternalUser";
import { updateRoleAndClearAssignments } from "../AssignCompaniesModal/AssignCompaniesHelper";
import { USERS_THAT_CAN_ACCESS_ALL_COMPANIES, dropdownOptions } from "../constants";
import {
  setSelectedUser,
  setSelectedUserRole,
  setShowAssignCompaniesModal,
  setTableLoading,
} from "../slices/manageUsersModalSlice";
import { useAppSelector } from "../../../redux";

type RoleDropdownProps = {
  rowData: InternalUser;
};

export const RoleDropdown = ({ rowData }: RoleDropdownProps) => {
  const { externalId, userRole } = rowData;
  const dispatch = useDispatch();
  const siteUserType = useAppSelector(getUserType);
  const siteUserExternalId = useAppSelector(getExternalUserId);

  const internalUserOption = dropdownOptions.find(
    (roleOption) => UserType[roleOption.value] === userRole,
  )!;
  const siteUserTypeOption = dropdownOptions.find(
    (roleOption) => UserType[roleOption.value] === siteUserType,
  )!;

  const isUserHigherThanSelf = siteUserTypeOption.order >= internalUserOption.order;
  const isUserSelf = siteUserExternalId === externalId;
  const isSelfBranchRole =
    siteUserType === UserType.BRANCH_OPS || siteUserType === UserType.BRANCH_VIS;

  /**
   * @param newRole: The new role to change the user to
   * If the user's selected role can access all companies and their new role can't, the assign companies modal is shown.
   * If the user's selected role can't access all companies and their new role can, the role is updated and company assignments are cleared.
   * If the user's selected role and new role both can't or can access all companies, only the role is updated.
   */
  const changeUserRole = (newRole: string) => {
    const newRoleType: UserType = UserType[newRole as keyof typeof UserType];
    if (
      !USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(newRoleType) &&
      USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(rowData.userRole)
    ) {
      dispatch(setSelectedUser(rowData));
      dispatch(setSelectedUserRole(newRole));
      dispatch(setShowAssignCompaniesModal(true));
    } else if (
      USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(newRoleType) &&
      !USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(rowData.userRole)
    ) {
      const updateCompaniesAndRole = updateRoleAndClearAssignments(
        externalId,
        rowData.userId,
        newRole,
        [],
      );
      runUpdateUsersCompaniesAndRole(updateCompaniesAndRole);
      dispatch(setTableLoading(true));
    } else {
      runEditClientRole({
        userId: externalId,
        clientType: newRole,
      });
      dispatch(setTableLoading(true));
    }
  };

  const { mutate: runEditClientRole } = useEditClientRole(() => {});

  const { mutate: runUpdateUsersCompaniesAndRole } = useUpdateUsersCompaniesAndRole({
    onSuccess: () => {},
    onError: (/* updateError */) => {
      // TODO: when a user's role fails to update, show the error modal
    },
  });

  const filteredDropdownOptions = dropdownOptions.filter(
    (roleOption) =>
      roleOption.order > siteUserTypeOption.order && roleOption.value !== internalUserOption.value,
  );

  return (
    <>
      <StyledDropdown
        value={internalUserOption}
        onChange={(e: DropdownChangeEvent) => changeUserRole(e.value)}
        disabled={isUserHigherThanSelf || isSelfBranchRole || isUserSelf}
        options={filteredDropdownOptions}
        placeholder={internalUserOption.label}
      />
    </>
  );
};
