import styled from "styled-components";
import React from "react";
import { Calendar as PRCalendar, CalendarProps } from "primereact/calendar";

const CalendarInputIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25 17.25H2.25C1.42157 17.25 0.75 16.5784 0.75 15.75V3.75C0.75 2.92157 1.42157 2.25 2.25 2.25H15.75C16.5784 2.25 17.25 2.92157 17.25 3.75V8.25"
      stroke="#3246F0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.75 6.75H17.25"
      stroke="#3246F0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25 3.75V0.75"
      stroke="#3246F0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.75 3.75V0.75"
      stroke="#3246F0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z"
      stroke="#3246F0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.902 17.2496H17.25V14.5986"
      stroke="#3246F0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const CalendarWrapper = (props: CalendarProps) => (
  <PRCalendar
    panelClassName={props.className}
    icon={CalendarInputIcon}
    showIcon
    readOnlyInput
    dateFormat={props.showTime ? "M dd, yy •" : "M dd, yy"}
    hourFormat={"12"}
    {...props}
  />
);

export const Calendar = styled(CalendarWrapper)`
  padding: 0;

  :hover .p-inputtext,
  :hover .p-button {
    background: ${({ theme }) => theme.colorTokens.white};
    border-color: ${({ theme }) => theme.colorTokens.blue850};
    cursor: pointer;
  }

  :hover .p-disabled {
    border-color: ${({ theme }) => theme.colorTokens.gray400};
  }

  :focus-within .p-inputtext,
  :focus-within .p-button {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colorTokens.blue100};
  }

  .p-inputtext {
    border-right: 0;
    padding-right: 2px;
    border-color: ${({ theme }) => theme.colorTokens.gray400};
  }

  .p-button {
    background: ${({ theme }) => theme.colorTokens.white};
    border-left: 0;
    border-color: ${({ theme }) => theme.colorTokens.gray400};
  }

  .p-datepicker-group-container {
    .p-datepicker-group {
      .p-datepicker-header {
        background: ${({ theme }) => theme.colorTokens.blue400};
        margin: 0;
        border: 0;
        .p-datepicker-title {
          color: ${({ theme }) => theme.colorTokens.white};
        }
        .p-datepicker-month,
        .p-datepicker-year,
        .p-datepicker-prev,
        .p-datepicker-next {
          margin: 0;
          color: ${({ theme }) => theme.colorTokens.white};
          :hover {
            color: ${({ theme }) => theme.colorTokens.blue400};
            background-color: ${({ theme }) => theme.colorTokens.white};
          }
          :focus {
            box-shadow: none;
          }
        }
      }
      .p-datepicker-calendar {
        margin: 0;
        thead {
          color: ${({ theme }) => theme.colorTokens.white};
          background-color: ${({ theme }) => theme.colorTokens.blue400};
        }
      }
    }
  }

  table td > span {
    border: 0;
  }

  tbody td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5),
  td:nth-child(6) {
    font-weight: bold;
  }

  .p-timepicker {
    .p-link {
      color: ${({ theme }) => theme.colorTokens.blue400};
    }
  }
`;
