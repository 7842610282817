// BLUECREW - API CONSTANTS
// =============================================================================

// ky-universal is necessary for testing, since we don't have fetch on the server
import ky from "ky-universal";
import trimEnd from "lodash/trimEnd";
import { checkHttpStatus } from "../../utility/http";
import { CognitoUtils } from "../../../utility/cognito";
import { getV1AuthToken } from "../../../utility/auth";

export const apiV1Url = import.meta.env.VITE_API_V1_URL || "";
export const cmosUrl = import.meta.env.VITE_CMOS_URL || "";
export const webV1Url = trimEnd(import.meta.env.VITE_WEB_V1_URL, "/") || "";
export const assetUrl = import.meta.env.VITE_ASSET_BASE_URL || "";

const apiV1GatewayUrl = import.meta.env.VITE_API_V1_GATEWAY_URL || "";
const apiV2GatewayUrl = import.meta.env.VITE_API_V2_GATEWAY_URL || "";
const cmosGatewayUrl = import.meta.env.VITE_CMOS_GATEWAY_URL || "";
const financeGatewayUrl = import.meta.env.VITE_FINANCE_GATEWAY_URL || "";
const X_API_KEY_HEADER = "x-api-key";
const X_USER_ID_HEADER = "x-user-id"; // external user id

const getApiV1Url = () => {
  return apiV1GatewayUrl;
};

const getApiV2Url = () => {
  return apiV2GatewayUrl;
};

const getCmosUrl = () => {
  return cmosGatewayUrl;
};

const addCognitoAuthHeader = (request: Request) => {
  const idToken = CognitoUtils.getCognitoIdToken();
  if (idToken) {
    request.headers.set("Authorization", idToken);
  }
};

const addApiV1AccessoryAuthHeader = (request: Request) => {
  const token = getV1AuthToken();
  request.headers.set("api-v1-key", token);
};

const addCustomHeaders = (request: Request) => {
  if (process.env.NODE_ENV === "development" && request.url.includes("localhost:")) {
    const externalUserId = CognitoUtils.getExternalUserId();
    if (externalUserId === null) {
      throw Error(`User external id is null, cannot auth with local apis!`);
    }
    request.headers.set(X_USER_ID_HEADER, externalUserId);
    if (request.url.includes(apiV1GatewayUrl)) {
      request.headers.set(X_API_KEY_HEADER, import.meta.env.VITE_API_V1_KEY);
    }
    if (request.url.includes(apiV2GatewayUrl)) {
      request.headers.set(X_API_KEY_HEADER, import.meta.env.VITE_API_V2_KEY);
    }
    if (request.url.includes(cmosGatewayUrl)) {
      request.headers.set(X_API_KEY_HEADER, import.meta.env.VITE_CMOS_API_KEY);
    }
  }
};

const addApiV1AuthHeader = (request: Request) => {
  const token = getV1AuthToken();
  request.headers.set("Authorization", `Bearer ${token}`);
};

export const api = ky.extend({
  prefixUrl: getApiV2Url(),
  headers: {
    "content-type": "application/json",
  },
  timeout: 120 * 1000, // setting the timeout value to 120 seconds like for internal-tools
  hooks: {
    beforeRequest: [
      (request) => {
        addCognitoAuthHeader(request);
        addCustomHeaders(request);
        addApiV1AccessoryAuthHeader(request);
      },
    ],
  },
});

export const apiV1 = ky.extend({
  prefixUrl: getApiV1Url(),
  headers: {
    "content-type": "application/json",
  },
  timeout: 120 * 1000, // setting the timeout value to 120 seconds like for internal-tools
  hooks: {
    beforeRequest: [
      (request) => {
        addCognitoAuthHeader(request);
        addCustomHeaders(request);
      },
    ],
  },
});

export const apiV1LegacyDomain = ky.extend({
  prefixUrl: apiV1Url,
  headers: {
    "content-type": "application/json",
  },
  timeout: 120 * 1000, // setting the timeout value to 120 seconds like for internal-tools
  hooks: {
    beforeRequest: [
      (request) => {
        addApiV1AuthHeader(request);
        addCustomHeaders(request);
      },
    ],
  },
});

export const cmos = ky.extend({
  prefixUrl: getCmosUrl(),
  headers: {
    "content-type": "application/json",
  },
  timeout: 120 * 1000, // setting the timeout value to 120 seconds like for internal-tools
  hooks: {
    beforeRequest: [
      (request) => {
        addCognitoAuthHeader(request);
        addCustomHeaders(request);
      },
    ],
  },
});

export const financeApi = ky.extend({
  prefixUrl: financeGatewayUrl,
  headers: {
    "content-type": "application/json",
  },
  timeout: 120 * 1000, // setting the timeout value to 120 seconds like for internal-tools
  hooks: {
    beforeRequest: [
      (request) => {
        addCognitoAuthHeader(request);
      },
    ],
  },
});

export const apiWithErrorRedirect = api.extend({
  hooks: {
    afterResponse: [
      (response) => {
        checkHttpStatus(response); // check response status code and redirect to /error if 4xx or 5xx
      },
    ],
  },
});

export const apiV1WithErrorRedirect = apiV1.extend({
  hooks: {
    afterResponse: [
      (response) => {
        checkHttpStatus(response); // check response status code and redirect to /error if 4xx or 5xx
      },
    ],
  },
});
