import React from "react";
import { useDispatch } from "react-redux";
import { StyledUserSearchInput } from "../ManageUsersTable/styledComponents";
import { setNameFilter } from "../slices/manageUsersFilterSlice";
import { useAppSelector } from "../../../redux";

export const UserSearchInput: React.FC = () => {
  const { nameFilter } = useAppSelector((state) => state.manageUsersFilter);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setNameFilter(event.target.value));
  };

  return (
    <div>
      <StyledUserSearchInput
        value={nameFilter}
        onChange={handleChange}
        placeholder="Search users"
      />
    </div>
  );
};
