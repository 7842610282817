import React, { useState, useContext } from "react";
import { H2Heading, Button, Paragraph, Image } from "@bluecrew/web-react-core";
import { Switch, SwitchType } from "@bluecrew/blueprint-web";
// @ts-ignore
import InfoIcon from "@bluecrew/web-react-core/src/assets/icons/infoIcon.svg";
import { getCompanyExternalId, getCompanyName } from "../../../redux/selectors/company";
import {
  ButtonWrapper,
  SettingsCard,
  InputRow,
  SwitchWrapper,
  ToolTipContainer,
  ThirdPartyDropdownWrapper,
  StyledSettingText,
  StyledThirdPartyDropdown,
} from "../styledComponents";
import { timesheetsStrings } from "../CompanySettingStringsEN";
import { AbilityContext } from "../../PermissionsContext";
import { CompanyInfo, ThirdPartyTimetracker, UserType } from "../../../api/bluecrew/types";
import { TimesheetsInput } from "./TimesheetsInput";
import { getUserType, getUserUsername } from "../../../redux/selectors/auth";
import { TooltipMessage } from "../../../components/TooltipMessage";
import { usePendo, useUpdateCompanyDetailsMutation } from "../hooks";
import { useAppSelector } from "../../../redux";

enum InputUnits {
  Minutes = "min",
  MinutesEarly = "min early",
}

const TIMETRACKER_DISABLED_LABEL = "None (default)";

const ThirdPartyTimetrackerDropdownOptions = [
  TIMETRACKER_DISABLED_LABEL,
  ...Object.values(ThirdPartyTimetracker),
];

type TimesheetsProps = {
  initialTerminalModeEnabled: boolean;
  initialLateClockOutBufferMinutes: number;
  initialMinLunchDurationHours: number;
  initialEarlyClockInBufferMinutes: number;
  initialAutoApprovalEnabled: boolean;
  initialThirdPartyTimetracker: ThirdPartyTimetracker | null;
};

export const Timesheets = ({
  initialTerminalModeEnabled,
  initialLateClockOutBufferMinutes,
  initialMinLunchDurationHours,
  initialEarlyClockInBufferMinutes,
  initialAutoApprovalEnabled,
  initialThirdPartyTimetracker,
}: TimesheetsProps) => {
  const companyExternalId = useAppSelector(getCompanyExternalId);
  const companyName = useAppSelector(getCompanyName);
  const userUsername = useAppSelector(getUserUsername);
  const permissions = useContext(AbilityContext);
  const trackWithPendo = usePendo();
  // TODO: (BW-1060): https://bluecrewjobs.atlassian.net/browse/BW-1060
  // Create new permission for "edit" "admin". or "edit" "timesheets_admin_settings"
  // We shouldn't use view perms for restricting access to editing a thing
  const cannotViewAdmin = permissions.cannot("view", "admin");

  const [terminalModeEnabled, setTerminalModeEnabled] = useState<boolean>(
    initialTerminalModeEnabled,
  );
  const [lateClockOutBufferMinutes, setLateClockOutBufferMinutes] = useState<number>(
    initialLateClockOutBufferMinutes,
  );
  const [minLunchDurationHours, setMinLunchDurationHours] = useState<number>(
    initialMinLunchDurationHours,
  );
  const [earlyClockInBufferMinutes, setEarlyClockInBufferMinutes] = useState<number>(
    initialEarlyClockInBufferMinutes,
  );
  const [autoApprovalEnabled, setAutoApprovalEnabled] = useState(initialAutoApprovalEnabled);
  const [thirdPartyTimetracker, setThirdPartyTimetracker] = useState<
    ThirdPartyTimetracker | typeof TIMETRACKER_DISABLED_LABEL
  >(initialThirdPartyTimetracker ?? TIMETRACKER_DISABLED_LABEL);

  const userType = useAppSelector(getUserType);
  // TODO:(BW-1493) & (BW-1060) Remove this.
  const disabled = userType === UserType.MANAGER;

  const updateCompanyDetailsMutation = useUpdateCompanyDetailsMutation();

  const handleClick = (
    newTerminalMode: boolean,
    newLateTolerance: number,
    newMinLunchDuration: number,
    newEarlyClockInBuffer: number,
    newAutoApprovalEnabled: boolean,
    newThirdPartyTimetracker: ThirdPartyTimetracker | typeof TIMETRACKER_DISABLED_LABEL,
  ) => {
    const companyInfo: CompanyInfo = {
      // terminal_mode is stored as a tinyint. api-v1 WILL accept a boolean. Sending a number to be consistent
      terminal_mode: Number(newTerminalMode),
      min_lunch_duration: newMinLunchDuration,
      late_clockout_buffer: newLateTolerance,
      early_clockin_buffer: newEarlyClockInBuffer,
      auto_approval_on: newAutoApprovalEnabled,
      third_party_timetracker:
        newThirdPartyTimetracker === TIMETRACKER_DISABLED_LABEL ? null : newThirdPartyTimetracker,
    };

    updateCompanyDetailsMutation.mutate(companyInfo, {
      onSuccess: () => {
        // If terminal state has changed, eg. false -> true, or true -> false, then want to track both the settings changes AND that the terminal toggled in two separate pendo events.
        // Not all settings changes will include a terminal state change... but all terminal state changes will include a settings change hence why we need to track both if the terminal state changes.
        const pendoType =
          initialTerminalModeEnabled !== newTerminalMode
            ? timesheetsStrings.labels.pendo_terminal_mode
            : timesheetsStrings.labels.pendo_timesheets;
        const terminalTogglePendoType = pendoType.split(" & ")[0];

        // @ts-ignore
        window.pendo.track(terminalTogglePendoType, {
          username: userUsername,
          company_id: companyExternalId,
          company_name: companyName,
          terminal_value: !!companyInfo.terminal_mode,
          page: "Company Info", // Whether toggle was made in Timesheets or Company Info
          time: new Date().toUTCString(),
        });

        trackWithPendo(pendoType, { newValue: companyInfo });
      },
    });

    // Blur button after click so that hover color clears when button is un-hovered
    (document.activeElement as HTMLElement | undefined)?.blur();
  };

  return (
    <SettingsCard data-pendo-key="timesheetsSettingsSection">
      <H2Heading>{timesheetsStrings.title}</H2Heading>
      <Paragraph>{timesheetsStrings.description}</Paragraph>
      <InputRow>
        <TimesheetsInput
          disabled={cannotViewAdmin || disabled}
          label={timesheetsStrings.labels.default_lunch_length}
          unit={InputUnits.Minutes}
          value={Math.round(minLunchDurationHours * 60)}
          onChange={(val) => {
            const newMinLunchDuration = val / 60;
            // Column in database limits length of decimal to 2 places after the decimal
            const formattedLunchDuration = Number(newMinLunchDuration.toFixed(2));
            setMinLunchDurationHours(formattedLunchDuration);
          }}
        />
        <TimesheetsInput
          disabled={disabled}
          label={timesheetsStrings.labels.early_clockin}
          unit={InputUnits.MinutesEarly}
          value={earlyClockInBufferMinutes}
          onChange={setEarlyClockInBufferMinutes}
        />
        <TimesheetsInput
          disabled={disabled}
          label={timesheetsStrings.labels.flag_as_late_buffer}
          unit={InputUnits.Minutes}
          value={lateClockOutBufferMinutes}
          onChange={setLateClockOutBufferMinutes}
        />
      </InputRow>
      <SwitchWrapper>
        <Switch
          isSmaller
          type={cannotViewAdmin || disabled ? SwitchType.disabled : SwitchType.default}
          value={terminalModeEnabled}
          onChange={() => setTerminalModeEnabled(!terminalModeEnabled)}
        />
        {timesheetsStrings.labels.terminal_mode}
        <ToolTipContainer>
          <TooltipMessage
            placement="top"
            message="Terminal mode restricts time submissions to the onsite terminal"
          >
            <Image src={InfoIcon} />
          </TooltipMessage>
        </ToolTipContainer>
      </SwitchWrapper>
      <SwitchWrapper>
        <Switch
          isSmaller
          type={cannotViewAdmin || disabled ? SwitchType.disabled : SwitchType.default}
          value={autoApprovalEnabled}
          onChange={() => setAutoApprovalEnabled(!autoApprovalEnabled)}
        />
        {timesheetsStrings.labels.auto_approve}
        <ToolTipContainer>
          <TooltipMessage placement="top" message="Enable auto-approval of worker timesheets">
            <Image src={InfoIcon} />
          </TooltipMessage>
        </ToolTipContainer>
      </SwitchWrapper>
      <ThirdPartyDropdownWrapper>
        <StyledSettingText>{timesheetsStrings.labels.third_party_timetracker}</StyledSettingText>
        <StyledThirdPartyDropdown
          data-testid={timesheetsStrings.labels.third_party_timetracker}
          value={thirdPartyTimetracker}
          onChange={(e) => {
            setThirdPartyTimetracker(e.value);
          }}
          options={ThirdPartyTimetrackerDropdownOptions}
        />
      </ThirdPartyDropdownWrapper>
      <ButtonWrapper>
        <Button
          disabled={disabled}
          palette="primary"
          onClick={() =>
            handleClick(
              terminalModeEnabled,
              lateClockOutBufferMinutes,
              minLunchDurationHours,
              earlyClockInBufferMinutes,
              autoApprovalEnabled,
              thirdPartyTimetracker,
            )
          }
        >
          {timesheetsStrings.labels.save_button}
        </Button>
      </ButtonWrapper>
    </SettingsCard>
  );
};
