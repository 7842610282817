import styled from "styled-components";

export const SideBarHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SideBarLabel = styled.div`
  margin-left: -1px;
  font-size: 16px;
  font-weight: 600;
`;

export const DropdownButton = styled.div`
  width: 12px;
  height: 7px;
  background: url("/public/images/down-arrow-new.svg") no-repeat;
  background-size: contain; // Adjust as needed
  cursor: pointer;
`;

export const CompanySideContainer = styled.div`
  display: flex;
  flex-direction: column;
  i.pi-search {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.palette.brandBlue};
    margin-left: -25px;
  }
`;

export const SideBarSectionContainer = styled.div`
  display: flex;
  margin-left: 20px;
  width: 100px;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;

  h3 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  > div {
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    horizontal-align: center;
    &:not(:first-child) {
      margin-top: 8px;
    }

    label {
      margin-left: 4px;
      font-size: 12px;
      font-weight: normal;
      margin-bottom: 3px;
    }
  }
`;

export const AllCheckboxContainer = styled.div`
  margin-top: 2px;
  margin-bottom: -8px;
`;

export const CompanyFiltersContainer = styled.div`
  max-height: 25vh;
  overflow: hidden;
  margin-right: 50px;
  width: 300px;
  padding-top: 5px;
`;

export const FilterCheckBoxLabelStyle = styled.label`
  margin-top: -4px;
    :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorTokens.gray50};
    display: flex;
    border-radius: 5px;
  }
`;

export const CompanyFilterRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 5px;
  padding-top: 4px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorTokens.gray50};
    display: flex;
    border-radius: 5px;
  }
`;

export const RoleFilterRow = styled.div`
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorTokens.gray50};
    display: flex;
    border-radius: 5px;
  }
`;

export const Separator = styled.div`
  border-top: 1px solid #ccc;
  margin-left: 19px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 300px;
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 190px;
  maxHeight: 30vh;
`;