import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import get from "lodash/get";
import moment from "moment-timezone";
import { Field as FormikField, Formik } from "formik";
import { Button, SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { AbilityContext } from "../PermissionsContext";
import { Schedule } from "../../api/bluecrew/types";
import ScheduleDetailsFields from "../../components/ScheduleDetailsFields";
import { editScheduleSchema } from "../../forms/validation";
import { SimpleModal, SimpleModalTypes } from "../../components/SimpleModal";
import { ButtonContainer, StyledForm } from "./styledComponents";
import { useGetScheduleQuery, useUpdateScheduleMutation } from "../../api/bluecrew/hooks/schedule";
import { getCompanyId } from "../../redux/selectors/company";
import { SupervisorType } from "../../redux/selectors/job";
import { useGetDepartmentsQuery, useGetSupervisorsQuery } from "../../api/bluecrew/hooks/job";
import { useReadPositionQuery } from "../../api/bluecrew/hooks/position";
import { useAppSelector } from "../../redux";

type EditScheduleType = {};

/**
 * @param  workDays array of strings
 * ["1","5"]
 * returns the work days formatted for use with formik
 * [null,"1",null,null,null,"5",null,null]
 */
const formatWorkDays = (workDays: Array<string>) => {
  const formikWorkDays: Array<string | null> = [];

  for (let i = 0; i < 7; i += 1) {
    const formattedDay = `${i}`;
    if (workDays.includes(formattedDay)) {
      formikWorkDays.push(formattedDay);
    } else {
      formikWorkDays.push(null);
    }
  }

  return formikWorkDays;
};

const getInitialValues = (
  schedule: Schedule,
  departments: any,
  supervisors: SupervisorType[],
  position,
) => {
  const {
    base_wage: baseWage,
    end_date_time: endDateTime,
    job_updates: jobUpdates,
    shifts,
    start_date_time: startDateTime,
    supervisor,
    tag_id: tagId,
    timezone,
    total_work_requests: totalWorkRequests,
    work_request_name: workRequestName,
  } = schedule;

  // map shifts to an array of work days, ex. ["1", "3"]
  const workDays = (shifts || []).map((shift) => shift.day_of_week);

  // convert start and end dates to moment instances
  // TODO: the timezone here is always from the browsers not the address
  let startDate = moment();
  let endDate = moment();
  if (startDateTime || endDateTime) {
    startDate = moment.tz(startDateTime, timezone);
    endDate = moment.tz(endDateTime, timezone);
  }

  const scheduleName = {
    label: workRequestName,
    value: workRequestName,
  };

  const supervisorDropdown = supervisor
    ? {
        // @ts-ignore id does not exist on supervisor, but this doesn't break anything
        value: supervisor.id,
        label: supervisor.full_name,
      }
    : null;

  let department;
  if (tagId !== null) {
    const selectedTag = departments.find((d) => d.id === tagId);
    if (selectedTag) {
      department = {
        value: selectedTag.id,
        label: selectedTag.tag_name,
      };
    }
  }

  /**
   * The jobUpdatesDropdown does not seem to be used, but I am not confident enough to remove it
   * TODO: BW-1458
   */
  let jobUpdatesDropdown;
  if (jobUpdates) {
    jobUpdatesDropdown = supervisors
      // @ts-ignore
      .map((supervisor) => {
        // @ts-ignore
        if (jobUpdates.includes(supervisor.id)) {
          return {
            // @ts-ignore
            value: supervisor.id,
            // @ts-ignore
            label: `${supervisor.first_name} ${supervisor.last_name}`,
          };
        }
      }) // remove any empty / null values
      .filter((s) => s);
  }

  const initialValues = {
    dates: {
      startDate,
      endDate,
    },
    department,
    // calculating differential wage because value is not stored in DB
    // if this logic is used elsewhere in the future consider moving it to the /shared folder
    // @ts-ignore
    differentialWage: (
      parseFloat(baseWage as unknown as string) - parseFloat(position.wage_floor)
    ).toFixed(2),
    job_updates: jobUpdatesDropdown,
    totalWage: baseWage,
    base_wage: baseWage || "",
    end_time: endDate.format("HH:mm"),
    focused_input: null,
    schedule_name: scheduleName,
    schedule_nickname: workRequestName || "",
    start_time: startDate.format("HH:mm"),
    supervisor: supervisorDropdown,
    supervisor_user_id: get(supervisor, "user_id") || "",
    work_days: formatWorkDays(workDays),
    workers_needed: get(totalWorkRequests, "total_request") || 1,
    timezone: timezone || "",
  };

  return initialValues;
};

// eslint-disable-next-line no-empty-pattern
export const EditSchedule = ({}: EditScheduleType) => {
  const ability = useContext(AbilityContext);
  const navigate = useNavigate();
  const routeParams = useParams();
  const scheduleId = routeParams.scheduleId!; // scheduleId  is always defined based on how this route is defined
  const canCreateJob = ability.can("create", "job");

  const [showResultModal, setShowResultModal] = useState(false);
  const [showSuccessButtons, setShowSuccessButtons] = useState(false);
  const [showFailureButtons, setShowFailureButtons] = useState(false);
  const [modal, setModal] = useState({
    styleType: "",
    headingText: "",
    bodyText: "",
  });

  const companyId = useAppSelector((state) => getCompanyId(state));

  const { data: schedule } = useGetScheduleQuery(scheduleId);
  const { data: departments } = useGetDepartmentsQuery(Number(companyId));

  const { data: supervisors } = useGetSupervisorsQuery();
  const { data: position } = useReadPositionQuery(
    schedule?.position_id!, // We make sure that schedule is defined in the line below
    !!schedule,
  );
  const {
    mutate: updateScheduleMutation,
    isSuccess: isUpdateScheduleSuccess,
    isError: isUpdateScheduleError,
    isLoading: isUpdateSchedulePending,
    error: updateScheduleMutationError,
  } = useUpdateScheduleMutation();

  useEffect(() => {
    if (isUpdateScheduleSuccess) {
      setShowResultModal(true);
      setShowSuccessButtons(true);
      setShowFailureButtons(false);
      setModal({
        styleType: SimpleModalTypes.SUCCESS,
        headingText: "Schedule changes.",
        bodyText: `We'll notify your crew members that a change was made to their schedules`,
      });
    }
  }, [isUpdateScheduleSuccess]);

  useEffect(() => {
    if (isUpdateScheduleError) {
      setShowResultModal(true);
      setShowFailureButtons(true);
      setShowSuccessButtons(false);
      setModal({
        styleType: SimpleModalTypes.ERROR,
        headingText: "Schedule error",
        bodyText:
          get(updateScheduleMutationError, "message") ||
          "An error occurred when attempting to update your schedule.",
      });
    }
  }, [isUpdateScheduleError]);

  if (!canCreateJob) {
    navigate("/");
  }

  const handleCancel = () => {
    navigate(`/schedules/${scheduleId}`);
  };

  const handleSubmit = (values) => {
    const payload = { ...values };

    // Remove keys from this array if field become editable in the future
    [
      "end_date",
      "end_time",
      "start_date",
      "start_time",
      "timezone",
      "work_days",
      "workers_needed",
    ].forEach((key) => {
      // Remove each value which cannot be edited in the form (the fields are disabled).
      delete payload[key];
    });

    updateScheduleMutation({
      scheduleId: Number(scheduleId),
      payload,
    });
  };

  const handleNavToPositions = () => {
    navigate("/positions");
  };

  const handleNavToScheduleDetail = () => {
    const id = scheduleId || "";
    navigate(`/schedules/${id}`);
  };

  const handleTryAgain = () => {
    setShowResultModal(false);
  };

  const toggleResultModal = () => {
    setShowResultModal((prev) => !prev);
  };

  const formatValues = (values: any) => ({
    // sending total wage as base_wage to match DB Schema, may change in the future is column is renamed in DB
    base_wage: values.totalWage,
    end_date: moment(values.dates.endDate).format("YYYY-MM-DD"),
    end_time: values.end_time,
    schedule_name: values.schedule_nickname,
    start_date: moment(values.dates.startDate).format("YYYY-MM-DD"),
    start_time: values.start_time,
    supervisor_user_id: values.supervisor_user_id,
    timezone: values.timezone,
    work_days: values.work_days.filter((d) => d),
    workers_needed: values.workers_needed,
  });

  if (
    schedule === undefined ||
    departments === undefined ||
    supervisors === undefined ||
    position === undefined
  ) {
    return <SpinnerWithOverlay />;
  }

  const initialValues = getInitialValues(schedule, departments, supervisors, position);

  return (
    <>
      <SimpleModal
        visible={showResultModal}
        styleType={modal.styleType}
        headingText={modal.headingText}
        bodyText={modal.bodyText}
        toggle={toggleResultModal}
      >
        {showSuccessButtons && (
          <>
            <Button
              palette="secondary"
              onClick={handleNavToScheduleDetail}
              data-testid="EditScheduleForm-go-to-schedule-page-btn"
            >
              Done
            </Button>
            <Button palette="primary" onClick={handleNavToPositions}>
              Back to Dashboard
            </Button>
          </>
        )}
        {showFailureButtons && (
          <Button palette="danger" onClick={handleTryAgain}>
            Try Again
          </Button>
        )}
      </SimpleModal>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={editScheduleSchema}
        onSubmit={(values) => {
          handleSubmit(formatValues(values));
        }}
        render={({ isValid, isSubmitting }) => (
          <StyledForm>
            <FormikField
              render={({ form: { setFieldValue, values } }) => (
                <ScheduleDetailsFields
                  departments={departments}
                  scheduleType={schedule.schedule_type}
                  heading={"Edit schedule details"}
                  position={position}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              )}
            />
            <ButtonContainer>
              <Button
                disabled={isUpdateSchedulePending || isSubmitting}
                onClick={handleCancel}
                palette="secondary"
                type="button"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isUpdateSchedulePending || !isValid}
                palette="primary"
                data-testid="EditScheduleForm-save-schedule-btn"
              >
                Save
              </Button>
            </ButtonContainer>
          </StyledForm>
        )}
      />
    </>
  );
};
