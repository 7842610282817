import React from "react";
import { Modal, ModalKind, themedStyled, UserAvatar } from "@bluecrew/blueprint-web";
import moment from "moment-timezone";
import {
  CrewMemberProps,
  CrewMembersConfirmationsProps,
} from "../types/propTypes/PositionDetailsEdit.types";
import { ApprovedIcon } from "./ApprovedIcon";
import { RejectedIcon } from "./RejectedIcon";

const WEB_V1_URL = import.meta.env.VITE_WEB_V1_URL || "";

export const CrewMembersConfirmationsModal = (props: CrewMembersConfirmationsProps) => {
  const { list, modalOpen, onClose } = props;

  const getSubtitle = (crewMember: CrewMemberProps) => {
    const date = moment(crewMember?.updatedAt).format("MMM D, YYYY h:mm A") || "";
    switch (crewMember.notificationStatus) {
      case "ACCEPTED":
        return `Confirmed on ${date}`;
      case "REJECTED":
        return `Canceled and removed from assignment on ${date}`;
      default:
        return "Waiting for confirmation";
    }
  };

  return (
    <Modal
      body={
        <ModalWrapper>
          {list?.map((cm: CrewMemberProps) => {
            const { firstName, lastName, profilePicture } = cm.crewMember;
            const fullName = `${firstName} ${lastName}`;
            return (
              <CrewMemberContainer key={cm.crewMemberId}>
                <AvatarContainer>
                  <UserAvatar fullName={fullName} imgSrc={profilePicture} size="xl" />
                  {cm.notificationStatus === "ACCEPTED" && (
                    <IconContainer>
                      <ApprovedIcon />
                    </IconContainer>
                  )}
                  {cm.notificationStatus === "REJECTED" && (
                    <IconContainer>
                      <RejectedIcon />
                    </IconContainer>
                  )}
                </AvatarContainer>
                <CrewMemberInfo>
                  <StyledLink
                    href={`${WEB_V1_URL}/app.html#!/worker/${cm.crewMemberId}`}
                    target="blank"
                  >
                    <StyledUserName>{fullName}</StyledUserName>
                  </StyledLink>
                  <StyledSubtitle>{getSubtitle(cm)}</StyledSubtitle>
                </CrewMemberInfo>
              </CrewMemberContainer>
            );
          })}
        </ModalWrapper>
      }
      header="Confirmations"
      isOpen={modalOpen}
      kind={ModalKind.DEFAULT}
      onClose={onClose}
    />
  );
};

const AvatarContainer = themedStyled("div", () => ({
  position: "relative",
}));

const CrewMemberContainer = themedStyled("div", ({ $theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${$theme.sizing.scale300} ${$theme.sizing.scale300} ${$theme.sizing.scale300} 0`,
}));

const CrewMemberInfo = themedStyled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  marginLeft: $theme.sizing.scale600,
}));

const StyledUserName = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font350,
  color: $theme.colors.ultramarine,
}));

const StyledSubtitle = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.obsidian,
}));

const ModalWrapper = themedStyled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxHeight: "500px",
  paddingBottom: $theme.sizing.scale800,
  paddingLeft: $theme.sizing.scale600,
}));

const IconContainer = themedStyled("div", () => ({
  position: "absolute",
  bottom: 0,
}));

const StyledLink = themedStyled("a", ({ $theme }) => ({
  textDecoration: "none",
  color: $theme.colors.obsidian,
  ":hover": {
    color: $theme.colors.ultramarine,
  },
}));
