import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { UserAvatar } from "./UserAvatar";

const StyledAvatar = styled(UserAvatar)`
  margin-bottom: 16px;
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span.heading {
    color: ${p("slate")};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
    text-align: center;
    letter-spacing: -0.0005em;
  }

  span.text {
    font-size: 16px;
    line-height: 24px;
    color: ${p("platinum")};
    text-align: center;
    width: 236px;
  }
`;

export const EmptyState = (props) => {
  const { heading, icon, text } = props;

  return (
    <StyledWrapper>
      {/* @ts-ignore */}
      <StyledAvatar imgSrc={icon} size="lg" />
      {heading && <span className="heading">{heading}</span>}
      <span className="text">{text}</span>
    </StyledWrapper>
  );
};

EmptyState.propTypes = {
  heading: PropTypes.string,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

EmptyState.defaultProps = {
  heading: null,
};
