import React from "react";
import { useForm } from "react-hook-form";
import { VerificationCodeField } from "../FormFields/VerificationCodeField";
import { Prettify } from "../../../../types/util-types";
import { CommonFormProps } from "./types";
import { loginStrings } from "../strings";
import { StyledButton, StyledLoginFlowForm } from "../styledComponents";
import { BCButtonVariants } from "../../Button";

const FieldNames = {
  MFA: "mfaCode",
} as const;

const defaultValues = {
  [FieldNames.MFA]: "",
};
type FormValueProps = typeof defaultValues;

export function MfaForm({
  username,
  requestNewCode,
  confirmSignIn,
  confirmSignInLoading,
  children,
  onAnyFieldChange,
  footer,
}: {
  username: string;
  requestNewCode: () => void;
  confirmSignIn: (mfaCode: string) => void;
  confirmSignInLoading: boolean;
} & Prettify<CommonFormProps>) {
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onChange", defaultValues });

  const onSubmit = async ({ mfaCode }: FormValueProps) => {
    confirmSignIn(mfaCode);
    reset();
  };

  const { submitLabel, submittingLabel } = loginStrings.login;
  const { mfaRequired } = loginStrings.confirmLogin;

  return (
    <div>
      <StyledLoginFlowForm onSubmit={handleSubmit(onSubmit)}>
        <div>{loginStrings.confirmLogin.codeSentBlurb(username)}</div>
        <div style={{ display: "grid", gap: "12px" }}>
          <VerificationCodeField
            fieldName={FieldNames.MFA}
            fieldLabel={loginStrings.confirmLogin.enterCode}
            control={control}
            rules={{
              required: mfaRequired,
            }}
            fieldError={formErrors[FieldNames.MFA]?.message}
            onChange={onAnyFieldChange}
          />
          {children}
          <StyledButton
            variant={BCButtonVariants.Primary}
            label={confirmSignInLoading ? submittingLabel : submitLabel}
            type="submit"
          />
        </div>
      </StyledLoginFlowForm>
      <StyledButton
        variant={BCButtonVariants.Secondary}
        label={loginStrings.requestNewCode}
        type="submit"
        onClick={requestNewCode}
        style={{ marginTop: "15px" }}
      />
      {footer}
    </div>
  );
}
