import React from "react";
import { ActionColumnCellWrapper } from "../styledComponents";
import { getUserType } from "../../../../../redux/selectors/auth";
import { ClientUserActionButtons } from "./ClientUserActionButtons";
import { SupportUserActionButtons } from "./SupportUserActionButtons";
import { TimesheetSupportUserTypes } from "../../../types/Payroll.types";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";
import { useAppSelector } from "../../../../../redux";

export const ActionColumnCellWrapperClassName = "action-column-cell-wrapper";
export const ActionButtonIconPathPrefix = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/Actions`;

export type ActionColumnCellProps = {
  rowData: TimesheetsShiftInfo;
  activeIndex: number;
};

export const ActionColumnCell = ({ rowData, activeIndex }: ActionColumnCellProps) => {
  const userType = useAppSelector(getUserType);
  const supportUser = TimesheetSupportUserTypes.includes(userType);
  const { allowedWorkplaceActions, allowedSupportActions } = rowData.userShift;

  return (
    <ActionColumnCellWrapper className={ActionColumnCellWrapperClassName}>
      {!supportUser && (
        <ClientUserActionButtons
          activeTabIndex={activeIndex}
          allowedWorkplaceActions={allowedWorkplaceActions}
          rowData={rowData}
        />
      )}
      {supportUser && (
        <SupportUserActionButtons
          rowData={rowData}
          activeTabIndex={activeIndex}
          allowedSupportActions={allowedSupportActions}
        />
      )}
    </ActionColumnCellWrapper>
  );
};

/*
conditional behavior factors:
    - user type (2 states)
        - client
        - support
    - tab index

button types
    - approve (checkmark)
    - flag (flag)
    - undo (revert arrow)
    - excuse (x calendar)
    - edit (pencil)
    - reject (x)

states
    - Type: client
        - Tab: pending review
            - approve
            - flag
        - Tab: Approved
            - flag
            - undo -- except for BC support approvals
        - Tab: Disputed
        - Tab: Removed
    - Type: Support
        - Tab: pending review
            - approve
            - edit
            - excuse
            - reject
        - Tab: Approved
            - undo
            - edit
            - excuse
            - reject
        - Tab: Disputed
            - approve
            - edit
            - excuse
            - reject
        - Tab: Removed
            - approve
            - edit
*/
