import { useDispatch } from "react-redux";
import { KIND } from "baseui/notification";
import { useMutation } from "@tanstack/react-query";
import { HTTPError } from "ky-universal";
import { getCompanyId, getCompanyExternalId } from "../../redux/selectors/company";
import {
  UpdateCompanySettingsFailureMessage,
  UpdateCompanySettingsInputErrorMessage,
  UpdateCompanySettingsSuccessMessage,
} from "./CompanySettingStringsEN";
import { showToastAction } from "../../redux/actions/app";
import {
  createUpdateCompanyDetailsPayload,
  GetCompanyDetailsPayload,
  updateCompanyDetails,
} from "../../api/bluecrew/company";
import companyActions from "../../redux/actions/company";
import { CompanyInfo } from "../../api/bluecrew/types";
import { getExternalUserId, getUserUsername } from "../../redux/selectors/auth";
import { useAppSelector } from "../../redux";

export const useUpdateCompanyDetailsMutation = () => {
  const dispatch = useDispatch();
  const companyId = useAppSelector(getCompanyId);
  const companyExternalId = useAppSelector(getCompanyExternalId);

  return useMutation({
    mutationKey: ["updateCompanyDetails"],
    mutationFn: (companyInfo: CompanyInfo) => {
      const payload = createUpdateCompanyDetailsPayload(companyId, companyExternalId, companyInfo);

      return updateCompanyDetails(payload);
    },
    onSuccess: () => {
      dispatch(showToastAction(UpdateCompanySettingsSuccessMessage, KIND.positive));
      // Trigger the getCompanyDetails action so that if a user navigates to "Post a Job"
      // and tries to create a new position, they don't have to refresh the page to get the new values
      const getCompanyDetailsPayload: GetCompanyDetailsPayload = {
        companyId: parseInt(companyId.toString(), 10),
      };
      dispatch(companyActions.getCompanyDetails.request(getCompanyDetailsPayload));
    },
    onError: (err: HTTPError) => {
      if (err.response.status >= 400 && err.response.status < 500) {
        // TODO: More precise error handling. Backend needs to propagate error kinds
        // https://ebpp.atlassian.net/browse/WP-75
        dispatch(showToastAction(UpdateCompanySettingsInputErrorMessage, KIND.negative));
      } else {
        dispatch(showToastAction(UpdateCompanySettingsFailureMessage, KIND.negative));
      }
    },
  });
};

export const usePendo = () => {
  const companyExternalId = useAppSelector(getCompanyExternalId);
  const userUsername = useAppSelector(getUserUsername);
  const userExternalId = useAppSelector(getExternalUserId);

  return (trackType: string, values: Object) =>
    // @ts-ignore
    window.pendo.track(trackType, {
      userId: userExternalId,
      username: userUsername,
      company_id: companyExternalId,
      ...values,
    });
};
