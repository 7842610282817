// ROSTER - COMPONENT
// =============================================================================

import React from "react";
import { Icon } from "./Icon";
import { Header } from "./styledComponents";
import { Title } from "./Title";
import { Text } from "./Text";

export interface IconHeaderProps {
  wrapAsSVG?: boolean;
  icon: string | React.ReactElement<any>;
  text?: string;
  title?: string;
}

export const IconHeader = ({ wrapAsSVG = true, icon, text, title }: IconHeaderProps) => (
  <Header>
    <Icon icon={icon} wrapAsSVG={wrapAsSVG} />
    <Title title={title} />
    <Text text={text} />
  </Header>
);
