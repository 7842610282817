import { handleActions } from "redux-actions";

import scheduleActions from "../actions/schedule";
import { ScheduleExceptionsResponse } from "../../api/bluecrew/types";

import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

import {
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
  CANCEL_SCHEDULE_REQUEST,
  CANCEL_SCHEDULE_SUCCESS,
  CANCEL_SCHEDULE_FAILURE,
} from "../constants";

const handlerMap = {
  [FETCH_SCHEDULE_REQUEST]: (state: ScheduleState) => handleRequest("fetchSchedule", state),
  [FETCH_SCHEDULE_SUCCESS]: (state: ScheduleState, action) =>
    handleSuccess("fetchSchedule", state, action),
  [FETCH_SCHEDULE_FAILURE]: (state: ScheduleState, action) =>
    handleFailure("fetchSchedule", state, action),

  [CANCEL_SCHEDULE_REQUEST]: (state: ScheduleState) => handleRequest("cancelSchedule", state),
  [CANCEL_SCHEDULE_SUCCESS]: (state: ScheduleState, action) =>
    handleSuccess("cancelSchedule", state, action),
  [CANCEL_SCHEDULE_FAILURE]: (state: ScheduleState, action) =>
    handleFailure("cancelSchedule", state, action),

  [scheduleActions.readScheduleWorkers.request]: (state: ScheduleState) =>
    handleRequest("readScheduleWorkers", state),
  [scheduleActions.readScheduleWorkers.success]: (state: ScheduleState, action) =>
    handleSuccess("readScheduleWorkers", state, action),
  [scheduleActions.readScheduleWorkers.failure]: (state: ScheduleState, action) =>
    handleFailure("readScheduleWorkers", state, action),

  [scheduleActions.readScheduleExceptions.request]: (state: ScheduleState) =>
    handleRequest("readScheduleExceptions", state),
  [scheduleActions.readScheduleExceptions.success]: (state: ScheduleState, action) =>
    handleSuccess("readScheduleExceptions", state, action),
  [scheduleActions.readScheduleExceptions.failure]: (state: ScheduleState, action) =>
    handleFailure("readScheduleExceptions", state, action),

  [scheduleActions.createScheduleException.request]: (state: ScheduleState) =>
    handleRequest("createScheduleException", state),
  [scheduleActions.createScheduleException.success]: (state: ScheduleState, action) =>
    handleSuccess("createScheduleException", state, action),
  [scheduleActions.createScheduleException.failure]: (state: ScheduleState, action) =>
    handleFailure("createScheduleException", state, action),

  [scheduleActions.updateSchedule.request]: (state: ScheduleState) =>
    handleRequest("updateSchedule", state),
  [scheduleActions.updateSchedule.success]: (state: ScheduleState, action) =>
    handleSuccess("updateSchedule", state, action),
  [scheduleActions.updateSchedule.failure]: (state: ScheduleState, action) =>
    handleFailure("updateSchedule", state, action),

  [scheduleActions.openApplicationJobsToPlatform.request]: (state: ScheduleState) =>
    handleRequest("openApplicationJobsToPlatform", state),
  [scheduleActions.openApplicationJobsToPlatform.success]: (state: ScheduleState, action) =>
    handleSuccess("openApplicationJobsToPlatform", state, action),
  [scheduleActions.openApplicationJobsToPlatform.failure]: (state: ScheduleState, action) =>
    handleFailure("openApplicationJobsToPlatform", state, action),
};

const initialState = {
  fetchSchedule: { ...initialEndpointState },
  cancelSchedule: { ...initialEndpointState },
  readScheduleExceptions: { ...initialEndpointState },
  readScheduleWorkers: { ...initialEndpointState },
  createScheduleException: { ...initialEndpointState },
  updateSchedule: { ...initialEndpointState },
  openApplicationJobsToPlatform: { ...initialEndpointState },
};

type InitialState = typeof initialState;

export type ScheduleState = InitialState & {
  readScheduleExceptions: typeof initialEndpointState & {
    success: null | ScheduleExceptionsResponse;
  };
};

// @ts-ignore ScheduleState and InitialState do not match, but this correctly overrides the type
export default handleActions<ScheduleState>(handlerMap, initialState);
