import React, { useState } from "react";
import { CreateShiftScreenContainer } from "../styledComponents";
import { AddShiftFormTypes, Position, Supervisor } from "../AddShiftForm.types";
import { PositionSelectField } from "../Components/PositionSelectField";
import { WageSelectField } from "../Components/WageSelectField";
import { ShiftAdjustBody } from "../../TimesheetsPageTable/ShiftAdjust/ShiftAdjustBody";
import { SupervisorSelectField } from "../Components/SupervisorSelectField";

export type CreateShiftScreenProps = {
  formValues: AddShiftFormTypes;
  setFormValues: (v: AddShiftFormTypes) => void;
  setCurrentPosition: (v: Position) => void;
};

export const CreateShiftScreen = ({
  formValues,
  setFormValues,
  setCurrentPosition,
}: CreateShiftScreenProps) => {
  const [formattedTimezone, setFormattedTimezone] = useState("");

  const getAlternativeName = (timeZone: string) => {
    const opts: Intl.DateTimeFormatOptions = { timeZoneName: "longGeneric", timeZone };
    return Intl.DateTimeFormat("en-EN", opts).format(Date.now()).split(",")[1].trim();
  };

  const setPosition = (position: Position) => {
    const newFormattedTimezone = getAlternativeName(position.timezone);
    const newFormattedTimezoneString = `(${newFormattedTimezone})`;
    setFormattedTimezone(newFormattedTimezoneString);
    setCurrentPosition(position);
    setFormValues({
      ...formValues,
      position: position,
    });
  };

  const setSupervisor = (supervisor: Supervisor) => {
    setFormValues({
      ...formValues,
      supervisor: supervisor,
    });
  };

  const setWage = (wage: number) => {
    setFormValues({
      ...formValues,
      wageOverride: wage,
    });
  };

  const setClockIn = (clockIn: Date) => {
    setFormValues({
      ...formValues,
      clockIn: clockIn,
    });
  };

  const setClockOut = (clockIn: Date) => {
    setFormValues({
      ...formValues,
      clockOut: clockIn,
    });
  };

  const setBreakDuration = (breakDuration?: number) => {
    setFormValues({
      ...formValues,
      breakDurationMins: breakDuration,
    });
  };

  return (
    <CreateShiftScreenContainer>
      <PositionSelectField position={formValues.position} setPosition={setPosition} />
      <ShiftAdjustBody
        setClockIn={setClockIn}
        setClockOut={setClockOut}
        setBreakDurationMins={setBreakDuration}
        clockIn={formValues.clockIn}
        clockOut={formValues.clockOut}
        breakDurationMins={formValues.breakDurationMins}
        formattedTimezone={formattedTimezone}
      />
      <SupervisorSelectField supervisor={formValues.supervisor} setSupervisor={setSupervisor} />
      <WageSelectField
        wage={formValues.wageOverride}
        placeholderWage={formValues.position?.baseWage}
        setWage={setWage}
      />
    </CreateShiftScreenContainer>
  );
};
