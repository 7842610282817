import React from "react";
import { StyledCompanySearchInput } from "../ManageUsersTable/styledComponents";

type CompanySearchProps = {
  searchText: string;
  onCompanySearchChange: (searchText: string) => void;
};

export const CompanySearch: React.FC<CompanySearchProps> = ({ searchText, onCompanySearchChange }) => {

  return (
    <div>
      <StyledCompanySearchInput
        placeholder="Search Companies"
        onChange={(event) => {
          onCompanySearchChange(event.target.value);
        }}
        value={searchText}
      />
      <i className="pi pi-search" />
    </div>
  );
};
