import { CloseIcon } from "@bluecrew/blueprint-web";
import { Backdrop, Portal } from "@bluecrew/web-react-core";
import React from "react";
import { useDispatch } from "react-redux";
import { Spacer, SpacerType } from "../../../components/Spacer";
import companyActions from "../../../redux/actions/company";
import { getUserUsername } from "../../../redux/selectors/auth";
import { generalInfoStrings } from "../CompanySettingStringsEN";
import {
  CloseIconButton,
  HeaderDiv,
  LinkedCompanyOverlay,
  StyledHeader,
  StyledHeaderLink,
  StyledLink,
  StyledLinkedCompaniesContainer,
  StyledLinkedCompaniesModal,
  StyledNoCompaniesResponse,
  StyledSubHeaderLink,
} from "./styledComponents";
import { ParentCompanyRelationalData } from "./types";
import { useAppSelector } from "../../../redux";

interface LinkedCompaniesModalProps {
  show: boolean;
  childCompaniesData: ParentCompanyRelationalData[];
  parentCompanyData: ParentCompanyRelationalData;
  setShowLinkedCompaniesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinkedCompaniesModal = ({
  show,
  childCompaniesData,
  parentCompanyData,
  setShowLinkedCompaniesModal,
}: LinkedCompaniesModalProps) => {
  const username = useAppSelector(getUserUsername);
  const dispatch = useDispatch();

  const handleSwitchCompany = (companyId: string) => {
    dispatch(
      companyActions.switchCompany.request({
        companyId,
        audience: [import.meta.env.VITE_AUDIENCE],
        username,
      }),
    );
  };

  return (
    <Portal>
      <Backdrop fade visible={show} onClick={() => setShowLinkedCompaniesModal(false)} />
      <LinkedCompanyOverlay fade visible={show}>
        <StyledLinkedCompaniesModal>
          <HeaderDiv>
            <StyledHeader>{generalInfoStrings.linkedCompaniesModal.title}</StyledHeader>
            <CloseIconButton
              data-testid="close-button"
              onClick={() => setShowLinkedCompaniesModal(false)}
            >
              <CloseIcon />
            </CloseIconButton>
          </HeaderDiv>
          <Spacer $type={SpacerType.vertical} $size="16px" />
          <StyledHeaderLink onClick={() => handleSwitchCompany(parentCompanyData.id.toString())}>
            {parentCompanyData["data.name"]}
          </StyledHeaderLink>
          <Spacer $type={SpacerType.vertical} $size="32px" />
          <StyledSubHeaderLink>
            {generalInfoStrings.linkedCompaniesModal.subTitle}
          </StyledSubHeaderLink>
          <Spacer $type={SpacerType.vertical} $size="8px" />
          <StyledLinkedCompaniesContainer>
            {childCompaniesData.length ? (
              childCompaniesData.map((companyData) => {
                return (
                  <StyledLink
                    key={companyData.id}
                    onClick={() => handleSwitchCompany(companyData.id.toString())}
                  >
                    {companyData["data.name"]}
                  </StyledLink>
                );
              })
            ) : (
              <StyledNoCompaniesResponse>
                {generalInfoStrings.linkedCompaniesModal.noCompaniesMessage}
              </StyledNoCompaniesResponse>
            )}
          </StyledLinkedCompaniesContainer>
        </StyledLinkedCompaniesModal>
      </LinkedCompanyOverlay>
    </Portal>
  );
};
