import { Overlay, Heading, Paragraph, Button, Box } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import styled from "styled-components";

// CSS
export const StyledOverlay = styled(Overlay)`
  padding: 0; // Overwriting padding that was displaying as grey
`;

export const StyleContainer = styled(Box)`
  width: 27.3125rem;
  background: ${p("white")};
  border-radius: 4px;
`;

export const StyledButtonGroup = styled(Box)`
  border-top: 1px solid ${p("aluminum")};
  display: block;
  padding: 1.5rem;
  text-align: right;

  button {
    margin-left: 20px;
  }
`;

export const StyledContent = styled(Box)`
  padding: 2.375rem;
  text-align: center;

  ${Heading} {
    font-weight: 500; //TODO: remove when fixed in web-core theme
  }

  ${Paragraph} {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${p("platinum")};
  }

  ${Button} {
    font-size: 16px;
    font-weight: 500;
  }
`;
