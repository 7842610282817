import React from "react";
import { Can } from "../../containers/PermissionsContext";
import { PanelItemType, PanelItem } from "./PanelItem";
import { getTreatmentValue } from "../../redux/selectors/splitio";
import { TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME } from "../../../shared/constants";
import { useAppSelector } from "../../redux";

type DropdownProps = {
  dropdownItems: Array<PanelItemType>;
};

export const Dropdown = React.forwardRef(
  ({ dropdownItems }: DropdownProps, ref: React.ForwardedRef<HTMLUListElement>) => {
    const showTimesheetsInReact = useAppSelector((state) =>
      getTreatmentValue(state, TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME),
    );

    return (
      <div className="admin-dropdown">
        <ul ref={ref}>
          {dropdownItems.map((dropdownItem) => {
            if (dropdownItem.title === "Direct Deposit") {
              return (
                <Can I="create" a="payroll" key={dropdownItem.key}>
                  <PanelItem key={dropdownItem.title} item={dropdownItem} />
                </Can>
              );
            }
            if (dropdownItem.title === "Manage Users") {
              return (
                <Can I="view" a="manage_users" key={dropdownItem.key}>
                  <PanelItem key={dropdownItem.title} item={dropdownItem} />
                </Can>
              );
            }
            if (dropdownItem.title === "Timesheets") {
              const timesheetsItemWithUpdatedUrl = {
                ...dropdownItem,
                ...(showTimesheetsInReact === "on" && { url: "/payroll" }),
              };

              return (
                <Can I="view" a="timesheets" key={dropdownItem.key}>
                  <PanelItem
                    key={timesheetsItemWithUpdatedUrl.title}
                    item={timesheetsItemWithUpdatedUrl}
                  />
                </Can>
              );
            }
            if (dropdownItem.title === "Companies") {
              return (
                <Can I="view" a="companies" key={dropdownItem.key}>
                  <PanelItem key={dropdownItem.title} item={dropdownItem} />
                </Can>
              );
            }
            return <PanelItem key={dropdownItem.title} item={dropdownItem} />;
          })}
        </ul>
      </div>
    );
  },
);
