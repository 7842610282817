import { callMethodOnGlobalObject } from "../shared/global_helpers";
import { User } from "./api/bluecrew/types";

const segmentEnabled = import.meta.env.VITE_DISABLE_3RD_PARTIES === "false";

type Analytics = {
  identify: (
    userId: string,
    userProperties: {
      [key: string]: unknown;
    },
  ) => any;
};

const segmentGlobalVariableName = "analytics";

/**
 * Identify a user to Segment.
 *
 * @param {string} externalUserId The user's external user id
 * @param {{ [key: string]: mixed }} traits Properties to associate with this user in segment
 */
export function identify(externalUserId: string, traits: User) {
  if (segmentEnabled) {
    callMethodOnGlobalObject<Analytics>(segmentGlobalVariableName, "identify", [
      externalUserId,
      traits,
    ]);
  }
}

export function getSegmentInjectionScriptContent() {
  let segmentKey = import.meta.env.VITE_SEGMENT_KEY;
  if (!segmentKey) {
    console.warn("Env var SEGMENT_KEY is unexpectedly empty");
    segmentKey = "PURPOSEFULLY_INVALID_ID_FOR_LOCAL_DEV";
  }

  // Copied from: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
  let content = `
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
analytics.load("${segmentKey}");
analytics.page();
}}();
  `;
  if (!segmentEnabled) {
    content = "";
  }

  return content;
}
