import React from "react";
import styled from "styled-components";
import { StyledModal } from "../../AddUserModal/styledComponents";
import { StyledButton } from "../../../../components/Login/styledComponents";
import { BCButtonVariants } from "../../../../components/Button";

const StyledList = styled.ul`
  margin: 0;
  display: grid;
  gap: 5px;
`;

export const resendInviteStrings = {
  header: "Resend Invitation",
  confirmButtonText: "Yes, Resend",
  confirmButtonLoadingText: (plural: boolean) => `Resending invite${plural ? "s" : ""}...`,
  blurb: (plural: boolean) =>
    `Are you sure you want to reinvite ${plural ? "these users" : "this user"}?`,
  subtext: (plural: boolean) =>
    `This will cause a new account invitation email to be sent to the following ${
      plural ? "users" : "user"
    }:`,
  noteText: `Note: Only users who have been recently invited, or have had their invite expire can be re-invited.`,
};

export const ConfirmResendInviteModal = ({
  onConfirm,
  resendIsLoading,
  visible,
  setModalIsVisible,
  users,
}: {
  onConfirm: () => void;
  resendIsLoading: boolean;
  visible: boolean;
  setModalIsVisible: (value: boolean) => void;
  users: { email: string }[];
}) => {
  const plural = users.length > 1;
  const { header, confirmButtonText, confirmButtonLoadingText, blurb, subtext, noteText } =
    resendInviteStrings;

  return (
    <StyledModal
      id={"ConfirmResendInviteModal"}
      position={"center"}
      resizable={false}
      draggable={false}
      closable={false}
      closeOnEscape
      onHide={() => null}
      visible={visible}
      data-testid="confirm-resend-invite-modal"
      header={header}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          gap: "1.5rem",
          fontSize: "14px",
        }}
      >
        <div>{blurb(plural)}</div>
        <div>{subtext(plural)}</div>
        <StyledList>
          {users.map(({ email }) => (
            <li key={email}>{email}</li>
          ))}
        </StyledList>
        <div>{noteText}</div>
        <div style={{ display: "flex" }}>
          <StyledButton
            variant={BCButtonVariants.Secondary}
            label="Cancel"
            onClick={() => setModalIsVisible(false)}
          />
          <StyledButton
            variant={BCButtonVariants.Primary}
            label={resendIsLoading ? confirmButtonLoadingText(plural) : confirmButtonText}
            onClick={onConfirm}
          />
        </div>
      </div>
    </StyledModal>
  );
};
