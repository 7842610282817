import React, { useState } from "react";
import { H3Heading, Button, SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { KIND } from "baseui/toast";
import { SettingsCard, ButtonWrapper, SpinnerWrapper } from "../styledComponents";
import { RadioList, RadioOption } from "../../../components/RadioList";
import {
  covidPolicyStrings,
  UpdateCompanySettingsFailureMessage,
  UpdateCompanySettingsSuccessMessage,
} from "../CompanySettingStringsEN";
import { ConfirmCancellations } from "./ConfirmCancellations";
import { createCovidPolicyUpdatePayload } from "./utils";
import { UpdateCompanyCovidPolicyPayload, updateCovidPolicy } from "../../../api/bluecrew/company";
import { showToastAction } from "../../../redux/actions/app";
import { AbilityContext } from "../../PermissionsContext";
import { VaccinePolicies } from "../../../api/bluecrew/types";
import { ValueOf } from "../../../../types/util-types";

// The wonkiness you see in this file, (handleMainComponentClick, handleModalClick),
// and in redux are due to the endpoint itself behaving a bit weird.
//
// Sending a request to the endpoint can result in 2 different responses:
//  1. It simply updates the policy and returns `{updated: true}`, which can happen if there were no potential canellations
//  2. It can return `{updated: false, potential_cancellations: [user, user...]}`.
//     If it sends back this response, you need to present the potential_cancellations to the user and ask them to confirm
//     I.E. "These CMs will have their assignments canceled because their vaccination status does not match the updated policy"

export const covidPolicyRequiredTestId = "required";
export const covidPolicyNotRequiredTestId = "notRequired";

const { required, notRequired } = covidPolicyStrings.options;
const options: Array<RadioOption> = [
  {
    label: notRequired.label,
    text: notRequired.text,
    value: notRequired.value,
    testId: covidPolicyNotRequiredTestId,
  },
  {
    label: required.label,
    text: required.text,
    value: required.value,
    testId: covidPolicyRequiredTestId,
  },
];

type CovidPolicyProps = {
  initialCovidPolicy: string;
};

const getInitialCovidPolicy = (initialCovidPolicy: string) => {
  let index = 0;
  if (initialCovidPolicy.length > 0) {
    index = options.findIndex((option) => option.value === initialCovidPolicy);
  }
  return index;
};

export const CovidPolicy = ({ initialCovidPolicy }: CovidPolicyProps) => {
  const dispatch = useDispatch();

  const ability = React.useContext(AbilityContext);
  const canUpdateCovidPolicy = ability.can("update", "covid_policy");
  const [selected, setSelected] = useState(getInitialCovidPolicy(initialCovidPolicy));
  const [modalOpen, setModalOpen] = useState(false);

  const { data, isLoading, mutate } = useMutation(
    ["updateCompanyCovidPolicy"],
    (payload: UpdateCompanyCovidPolicyPayload) => updateCovidPolicy(payload),
    {
      onSuccess: (r) => {
        if (r.updated) {
          dispatch(showToastAction(UpdateCompanySettingsSuccessMessage, KIND.positive));
          setModalOpen(false);
        } else {
          setModalOpen(true);
        }
      },
      onError: () => {
        dispatch(showToastAction(UpdateCompanySettingsFailureMessage, KIND.negative));
        setModalOpen(false);
      },
    },
  );

  const potentialCancellations = data?.potential_cancellations ?? [];
  const updateCovidPolicyOnClick = (
    newValue: ValueOf<typeof VaccinePolicies>,
    confirmCancellations: boolean,
  ) => {
    mutate(createCovidPolicyUpdatePayload(newValue, confirmCancellations));
    // Blur button after click so that hover color clears when button is un-hovered
    (document.activeElement as HTMLElement | undefined)?.blur();
  };

  const handleClick = (newValue: ValueOf<typeof VaccinePolicies>) => {
    updateCovidPolicyOnClick(newValue, false);
  };

  const handleConfirmCancellations = (newValue: ValueOf<typeof VaccinePolicies>) => {
    updateCovidPolicyOnClick(newValue, true);
  };

  return (
    <>
      {isLoading && (
        <SpinnerWrapper>
          <SpinnerWithOverlay />
        </SpinnerWrapper>
      )}
      <SettingsCard data-pendo-key="covidPolicySection">
        <H3Heading>{covidPolicyStrings.title}</H3Heading>
        {modalOpen && (
          <ConfirmCancellations
            potentialCancellations={potentialCancellations}
            newCovidPolicyValue={options[selected].value}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleUpdateClick={handleConfirmCancellations}
          />
        )}
        <RadioList
          disabled={!canUpdateCovidPolicy}
          options={options}
          selected={selected}
          setSelected={setSelected}
        />
        <ButtonWrapper>
          <Button
            disabled={!canUpdateCovidPolicy}
            palette="primary"
            onClick={() => handleClick(options[selected].value)}
          >
            {covidPolicyStrings.labels.saveButton}
          </Button>
        </ButtonWrapper>
      </SettingsCard>
    </>
  );
};
