const { construct } = require("ramda");

class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.statusCode = 404;
    this.name = "NotFoundError";
  }
}

module.exports = (msg) => construct(NotFoundError)(msg);
