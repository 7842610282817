import { palette as p } from "styled-tools";
import styled, { DefaultTheme, css } from "styled-components";
import { Form } from "formik";
import { Overlay, Backdrop, Field, Input, Block, Button } from "@bluecrew/web-react-core";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button as BCButton, BCButtonVariant } from "../Button";
import { Loading } from "../Loading";

export const StyledBackdrop = styled(Backdrop)`
  z-index: 2000;
  top: -10%;
  right: -10%;
  bottom: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  overflow: hidden;
  background-color: var(--Ultramarine, #3246f0);
`;

export const StyledForm = styled(Form)`
  margin: 1rem;

  ${Input} {
    border: solid 1px ${p("platinum")};
  }

  .error-msg {
    color: red;
    padding-top: 0.1rem;
  }

  .bottom-actions {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    .action-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      :hover {
        cursor: pointer;
      }

      .action-icon {
        width: 64px;
      }
    }

    .action-container:first-child {
      padding-right: 16px;
    }
  }

  .submit-button {
    margin-top: 24px;
  }
`;

export const LoginBottomButtons = styled(Form)`
  margin: 0 16px 16px 16px;

  .bottom-actions {
    display: flex;
    justify-content: space-between;

    .action-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 16px;
      :hover {
        cursor: pointer;
      }

      .action-icon {
        width: 64px;
      }
    }
  }
`;

export const ForgotPassword = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  color: var(--Ultramarine, #3246f0);

  :hover {
    cursor: pointer;
  }
`;

export const StyledOverlay = styled(Overlay)`
  z-index: 3000;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  min-width: 20rem;
  width: 425px;
  background-color: ${({ theme }) => theme.colorTokens.white};
  color: rgb(33, 33, 33);
  border-radius: 8px;

  && > ${Loading}, ${StyledForm} {
    transition: opacity 0.25s ease-in-out;
  }

  && > ${Loading} {
    color: ${p("primary", 0)};
    position: absolute;
    align-self: center;
    margin: 0;
    top: 37.5%;
  }

  ${Field}, ${Button} {
    margin: 0.5rem 0;
  }

  && > * {
    margin: 1rem;
  }
`;

// <Card /> was used with styled overlay
export const LogoContainer = styled(Block)`
  text-align: center;
  margin: 0;
  align-content: center;
`;

export const LoginFailedDiv = styled.div`
  text-align: center;
`;

const variantStyles = (theme: DefaultTheme, variant: BCButtonVariant = "primary") =>
  ({
    primary: css`
      border: unset;
      height: 48px;
      background: ${theme.colors.ultramarine};
      .p-button-label {
        color: ${theme.colors.white};
      }
    `,
    secondary: css`
      border: unset;
      height: 40px;
      background: ${theme.colorTokens.white};
      .p-button-label {
        color: ${theme.colors.ultramarine};
      }
    `,
  }[variant]);

export const StyledButton = styled(BCButton)<{ variant: BCButtonVariant }>`
  width: 100%;
  border: unset;
  border-radius: ${({ theme }) => theme.borders.radius200};
  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
  }

  ${({ theme, variant }) => variantStyles(theme, variant)}

  // Shrink input boxes if screen get's smaller
  @media (max-height: 50rem) {
    height: 36px;
  }
`;

export const StyledInputText = styled(InputText)`
  width: 100%;
  height: ${({ theme }) => theme.sizing.scale1200};

  // Shrink input boxes if screen get's smaller
  @media (max-height: 50rem) {
    height: 36px;
  }

  border-radius: ${({ theme }) => theme.borders.radius200};
  border-color: ${({ theme }) => theme.colors.pebble};

  :focus {
    border-color: ${({ theme }) => theme.colors.pebble};
  }
  :hover {
    border-color: ${({ theme }) => theme.colors.pebble};
  }
`;

export const StyledPassword = styled(Password)`
  .p-inputtext {
    width: 100%;
    height: ${({ theme }) => theme.sizing.scale1200};
    border-radius: ${({ theme }) => theme.borders.radius200};
    border-color: ${({ theme }) => theme.colors.pebble};

    :focus {
      border-color: ${({ theme }) => theme.colors.pebble};
    }
    :hover {
      border-color: ${({ theme }) => theme.colors.pebble};
    }
  }

  // Shrink input boxes if screen get's smaller
  @media (max-height: 50rem) {
    .p-inputtext {
      height: 36px;
    }
  }

  // We use custom show/hide password icons, so we need to shift
  // where the icon rendered by a tiny bit to make it centered.
  i {
    margin-top: -0.7rem;
  }
`;

export const StyledLoginFlowForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  // Shrink input boxes if screen get's smaller
  @media (max-height: 40rem) {
    gap: 18px;
  }
`;
