import { getIntercomInjectionScriptContent } from "./intercom";
// import { getNewRelicInjectionScriptContent } from "./newrelic";
import { getPendoInjectionScriptContent } from "./pendo";
import { getSegmentInjectionScriptContent } from "./segment";

// eslint-disable-next-line no-restricted-syntax
for (const getInjectionScriptContent of [
  getIntercomInjectionScriptContent,
  getPendoInjectionScriptContent,
  getSegmentInjectionScriptContent,
  // getNewRelicInjectionScriptContent,
]) {
  const script = document.createElement("script");
  script.innerHTML = getInjectionScriptContent();
  script.type = "text/javascript";

  document.head.appendChild(script);
}
