import { Link, Overlay } from "@bluecrew/web-react-core";
import styled from "styled-components";

export const LinkedCompanyOverlay = styled(Overlay)`
  padding: 0;
  color: rgb(33, 33, 33);
`;

export const StyledLinkedCompaniesModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  padding: 24px;
  max-height: 675px;
  width: 432px;
`;

export const StyledLinkedCompaniesContainer = styled("div")`
  min-height: 20vh;
  max-height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled("div")`
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
`;

export const StyledLink = styled(Link)`
  font-family: Inter;
  margin: 0.1em 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
`;

export const StyledNoCompaniesResponse = styled("div")`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const StyledHeaderLink = styled(Link)`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
`;

export const StyledSubHeaderLink = styled("div")`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
`;

export const HeaderDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContactDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 0.5rem;
`;

export const InputField = styled("div")`
  width: 100%;
`;

export const ErrorText = styled("label")`
  color: ${({ theme }) => theme.colors.negative};
  font-size: 14px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  line-height: 20px;
  margin: 0px;
`;

export const CloseIconButton = styled("div")`
  width: auto;
  height: auto;
  cursor: pointer;
  align-items: center;
`;
