export const colorTokens = {
  white: '#FFFFFF',
  gray50: '#F2F3F7',
  gray100: '#F2F3F7',
  gray150: '#F8F9FB',
  gray200: '#DEDFE8',
  gray250: '#E4E5E6',
  gray300: '#CCCDD6',
  gray400: '#BABBC4',
  gray500: '#888992',
  gray600: '#55565F',
  gray700: '#33343D',
  gray800: '#080912',
  gray900: '#080912',
  black: '#080912',

  red100: '#FFDADD',
  red200: '#F7AA99',
  red300: '#F37F66',
  red400: '#E72E3E',
  red500: '#EB2A00',
  red600: '#BC2200',
  red700: '#8D1900',
  red800: '#5E1100',
  red900: '#2F0800',
  red1000: '#E03131',

  yellow100: '#FEF8CC',
  yellow200: '#FDF09A',
  yellow300: '#FDE967',
  yellow400: '#FFD343',
  yellow500: '#FBDA02',
  yellow600: '#C9AE02',
  yellow700: '#978301',
  yellow800: '#645701',
  yellow900: '#322C00',

  orange100: '#FFE2CC',
  orange200: '#FFC599',
  orange300: '#FFA766',
  orange400: '#F87900',
  orange500: '#FF6D00',
  orange600: '#CC5700',
  orange700: '#994100',
  orange800: '#662C00',
  orange900: '#331600',

  green100: '#D6F3E2',
  green200: '#ADE6C5',
  green300: '#85DAA8',
  green400: '#08942E',
  green500: '#33C16E',
  green600: '#299A58',
  green700: '#1F7442',
  green800: '#144D2C',
  green900: '#0A2716',

  blue100: '#D6DAFC',
  blue150: '#EAECFD',
  blue200: '#5163FF',
  blue300: '#4155FF',
  blue400: '#3246F0',
  blue500: '#0E26F0',
  blue600: '#0E26F0',
  blue700: '#0E26F0',
  blue800: '#141C60',
  blue850: '#143672',
  blue900: '#0A0E30',

  aqua100: '#CCF0FF',
  aqua200: '#99E0FF',
  aqua300: '#66D1FF',
  aqua400: '#33C1FF',
  aqua500: '#00B2FF',
  aqua600: '#008ECC',
  aqua700: '#006B99',
  aqua800: '#004766',
  aqua900: '#002433',

  teal100: '#CCE6F5',
  teal200: '#99CDEA',
  teal300: '#66B5E0',
  teal400: '#339CD5',
  teal500: '#0083CB',
  teal600: '#0069A2',
  teal700: '#004F7A',
  teal800: '#003451',
  teal900: '#001A29',

  navy100: '#CCD0EE',
  navy200: '#99A2DE',
  navy300: '#6673CD',
  navy400: '#3345BD',
  navy500: '#0016AC',
  navy600: '#00128A',
  navy700: '#000D67',
  navy800: '#000945',
  navy900: '#000422',

  coral100: '#FFDADD',
  coral200: '#FFB5BC',
  coral300: '#FF919A',
  coral400: '#FF6C79',
  coral500: '#FF4757',
  coral600: '#CC3946',
  coral700: '#992B34',
  coral800: '#661C23',
  coral900: '#330E11',
};
