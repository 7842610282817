import { DayOfWeek, DaysOfWeek } from "@bluecrew/blueprint-web";
import { FieldErrors } from "react-hook-form";
import { Mapper } from "../Mapper.types";
import { ScheduleType } from "./Schedule.types";
// eslint-disable-next-line import/no-cycle
import { ScheduleListItemT } from "../../../../components/SelectionList/types";
import { BaseWage } from "./PositionField.types";

export interface ScheduleListItem extends ScheduleListItemT {
  id: number | string;
  workingHoursEx: WorkingHours;
  scheduleType: ScheduleType;
}

// Lets use military (24 hours) time
export interface WorkingHours {
  formatted: string;
  startTime: string;
  endTime: string;
}

export interface Schedule {
  id: number | string;
  name: string;
  workingHours: WorkingHours;
  workingDays: DaysOfWeek;
  scheduleType: ScheduleType;
  totalWage: BaseWage;
  positionWage: BaseWage;
}

export type ShiftRaw = {
  day_of_week: DayOfWeek;
  start_time: string;
  end_time: string;
};

export interface ScheduleRawDataType {
  id: number | string;
  name: string;
  schedule_type: number;
  start_time: string;
  end_time: string;
  start_time_military: string;
  end_time_military: string;
  shifts: Array<ShiftRaw>;
  base_wage: BaseWage;
}

export interface NewSchedule extends Schedule {
  isNewSchedule: boolean;
}

export const isNewSchedule = (item: any): item is NewSchedule =>
  typeof item === "object" && item !== null && "isNewSchedule" in item;

export type Schedules = Array<Schedule>;

export interface ScheduleFieldProps {
  value: Schedule;
  onChange: (schedule: Schedule) => void;
  onClose: () => void;
  invalid: boolean;
  schedules?: Schedules;
  mapper?: Mapper<ScheduleListItem, Schedule, boolean>;
  isPositionSelected: boolean;
}

export interface ScheduleNameProps {
  value: string;
  onChange: (scheduleName: string) => void;
  invalid: boolean;
  errors: FieldErrors;
  scheduleType: ScheduleType;
}

export interface WorkDaysProps {
  value: DaysOfWeek;
  onChange: (scheduleName: DaysOfWeek) => void;
  invalid: boolean;
  editable?: boolean;
}

export const MIN_SCHEDULE_NAME_LENGTH = 5;
export const MAX_SCHEDULE_NAME_LENGTH = 128; // NOTE: This length is subject to change per vbeaufrere@
export const SCHEDULE_NAME_REQUIRED_MSG = "Schedule name is required!";
export const SHIFT_NAME_REQUIRED = "Please enter a name for these shifts!";
export const SCHEDULE_NAME_TOO_SHORT_MSG = `Schedule name must be at least ${MIN_SCHEDULE_NAME_LENGTH} characters long`;
export const SCHEDULE_NAME_TOO_LONG_MSG = `Schedule name must be ${MAX_SCHEDULE_NAME_LENGTH} characters or less`;
export const SUPERVISOR_REQUIRED = "Supervisor is required!";
