import React, { useState } from "react";
import { DollarSign } from "@bluecrew/blueprint-web/src/assets/svg/DollarSign";
import { InvoicePreviewModal } from "../InvoicePreviewModal";
import { StyledInvoiceButton } from "./styledComponents";

export type ButtonInvoicesProps = {
  companyExternalId: string;
  week: Date;
  companyName: string;
};

export const ButtonInvoices = ({ companyExternalId, week, companyName }: ButtonInvoicesProps) => {
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);

  return (
    <>
      <StyledInvoiceButton
        data-test-id={"invoice-preview-button"}
        label="Invoices"
        outlined
        icon={<DollarSign height={"16px"} width={"8.18px"} color={"#3246F0"} />}
        onClick={() => setShowInvoiceModal(true)}
      />
      <InvoicePreviewModal
        companyExternalId={companyExternalId}
        week={week}
        companyName={companyName}
        show={showInvoiceModal}
        onModalClose={() => setShowInvoiceModal(false)}
      />
    </>
  );
};
