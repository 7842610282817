import React from 'react';
import { SvgPropType } from './SvgPropType';
import { useThemedStyletron } from '../../theme';

export const SelectedIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const {
    width = 24,
    height = 24,
    color = theme.colors.ultramarine,
    stroke = theme.colors.white,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        d="M6 13L10 17L18 8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
