import React, { useRef, useState } from "react";
import { addWeeks } from "date-fns";
import { subYears } from "date-fns";
import { Toast } from "primereact/toast";

import { InvoicePreviewFileFormat } from "../../../../api/bluecrew/invoice";
import {
  StyledSelect,
  StyledDialog,
  StyledIcon,
  StyledCalendar,
  DownloadButton,
  CancelButton,
} from "./styledComponents";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { useInvoicePreviewMutation } from "../../../../api/bluecrew/hooks/invoice";

interface FileFormatItem {
  value: InvoicePreviewFileFormat;
  label: string;
}

export type InvoicePreviewModalProps = {
  companyExternalId: string;
  companyName: string;
  week: Date;
  onModalClose: () => void;
  show: boolean;
};

const calendarIconPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/calendar-range-select.svg`;

export const InvoicePreviewModal = ({
  onModalClose,
  companyExternalId,
  companyName,
  week,
  show,
}: InvoicePreviewModalProps) => {
  const minDate = subYears(Date.now(), 2);
  const maxDate = addWeeks(Date.now(), 1);

  const fileFormats: FileFormatItem[] = [
    { value: InvoicePreviewFileFormat.EXCEL, label: "Excel spreadsheet (.xlsx)" },
    { value: InvoicePreviewFileFormat.CSV, label: "Comma separated values (.csv)" },
  ];

  const workplaces = [
    { value: "main", label: companyName },
    { value: "all", label: "All workplaces" },
  ];

  const [selectedFileFormat, setSelectedFileFormat] = useState(fileFormats[0].value);
  const [selectedPreviewRegion, setSelectedPreviewRegion] = useState(workplaces[0].value);
  const [dates, setDates] = useState([week, addWeeks(week, 1)]);
  const [invoiceStartDateOfWeek, invoiceEndDateOfWeek] = dates;

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
    onModalClose();
  };

  const { mutate, isLoading } = useInvoicePreviewMutation(onModalClose, showErrorToast);

  const everyDayExceptMonday = [0, 2, 3, 4, 5, 6]
  const handleSubmit = () => {
    mutate({
      companyName,
      invoiceStartDateOfWeek,
      invoiceEndDateOfWeek,
      companyExternalId,
      selectedPreviewRegion,
      selectedFileFormat,
    });
  };

  const footerContent = (
    <div>
      <CancelButton
        label="Cancel"
        onClick={onModalClose}
        className="p-button-text"
        disabled={isLoading}
      />
      <DownloadButton label="Download" onClick={handleSubmit} disabled={isLoading} />
    </div>
  );

  return (
    <>
      {isLoading && <SpinnerWithOverlay />}
      <Toast ref={toast} />
      <StyledDialog
        data-testid={"invoice-preview-modal"}
        header="Invoice Preview"
        visible={show}
        onHide={onModalClose}
        footer={footerContent}
      >
        <b>Week range</b>
        <StyledCalendar
          value={dates}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(e) => setDates(e.value as Date[])}
          disabledDays={everyDayExceptMonday}
          selectionMode="range"
          readOnlyInput
          dateFormat="M d, yy (D)"
          icon={<StyledIcon src={calendarIconPath} alt={""} />}
          showIcon
        />
        <p>
          WARNING: All hours for the week are finalized and approved by Monday morning at 8 AM PST.
          Please note that reports pulled prior to that time may not reflect the total summary of
          charges.
        </p>
        <p>
          <b>Workplace</b>
          <StyledSelect
            value={selectedPreviewRegion}
            options={workplaces}
            onChange={(item) => setSelectedPreviewRegion(item.value)}
          />
        </p>
        <p>
          <b>File format</b>
          <StyledSelect
            value={selectedFileFormat}
            options={fileFormats}
            onChange={(item) => setSelectedFileFormat(item.value)}
          />
        </p>
      </StyledDialog>
    </>
  );
};
