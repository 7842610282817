import { styled } from "baseui";

export enum SpacerType {
  horizontal,
  vertical,
}

interface SpacerProps {
  $type: SpacerType;
  $size: string;
}

export const Spacer = styled<SpacerProps, "div">("div", ({ $type, $size }) => ({
  ...($type === SpacerType.horizontal
    ? { height: "100%", width: $size }
    : { width: "100%", height: $size }),
}));
