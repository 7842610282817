import React from "react";
import { Row } from "react-table";
import { CheckboxChangeEvent } from "primereact/checkbox";
import { useDispatch } from "react-redux";
import { InternalUser } from "./InternalUser";
import { StyledCheckbox } from "./styledComponents";
import { addToSelectedUsers, removeFromSelectedUsers } from "../slices/manageUsersModalSlice";
import { useAppSelector } from "../../../redux";

export type UserCheckboxProps = {
  row: Row<InternalUser>;
};

export const UserCheckbox: React.FC<UserCheckboxProps> = ({ row }: UserCheckboxProps) => {
  const dispatch = useDispatch();
  const isCurrentUserSelected = useAppSelector((state) =>
    state.manageUsersModal.selectedUsers.some((user) => user.userId === row.original.userId),
  );
  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    if (event.checked) {
      dispatch(addToSelectedUsers(row.original));
    } else {
      dispatch(removeFromSelectedUsers(row.original.userId));
    }
  };

  return (
    <StyledCheckbox
      onChange={handleCheckboxChange}
      checked={isCurrentUserSelected}
      data-testid={`user-checkbox-${row.original.userId}`}
    />
  );
};
