import React from "react";
import { Route, Routes } from "react-router-dom";
import Schedule from "../containers/schedules";
import { EditSchedule } from "../containers/EditSchedule";
import Applications from "../containers/Applications";
import { GetCrewMembersRoutes } from "./GetCrewMembersRoutes";

export const SchedulesRoutes = () => (
  <Routes>
    <Route path="new/*" element={<GetCrewMembersRoutes />} />
    <Route path=":id" element={<Schedule />} />
    <Route path=":scheduleId/edit" element={<EditSchedule />} />
    <Route path=":scheduleId/review-applicants" element={<Applications />} />
    <Route path=":scheduleId/request-crew/*" element={<GetCrewMembersRoutes />} />
  </Routes>
);
