import { AuthenticationErrorInfo, AuthenticationErrorType } from "../authenticationTypes";

export class AuthenticationError extends Error {
  errorType: AuthenticationErrorType;

  constructor(info: AuthenticationErrorInfo) {
    super(info.message);
    this.name = info.errorType;
    this.errorType = info.errorType;
  }
}
