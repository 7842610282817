import React, { useEffect, useRef, useState } from "react";
import { StyledContainer } from "./styledComponents";
import { Dropdown } from "./Dropdown";
import { panelItems } from "./utils";

export const AdminDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);

  const closeMenu = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      isOpen &&
      !dropdownRef.current.contains(event.target as HTMLUListElement)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [isOpen]);

  return (
    <StyledContainer>
      <div
        className="admin-container"
        onClick={() => {
          setIsOpen((currentState) => !currentState);
        }}
      >
        <span className="admin-text">{"Admin Tools"}</span>
      </div>

      {isOpen && <Dropdown dropdownItems={panelItems} ref={dropdownRef} />}
    </StyledContainer>
  );
};
