import { useNavigate } from "react-router-dom";
import type { ScheduleType } from "../GetCrewMembers/types/propTypes/Schedule.types";
import { formatUri } from "../../utility/uri";

export const useNavigateToScheduleDetailPage = () => {
  const navigate = useNavigate();

  return (id: number) => navigate(`/schedules/${id}`);
};

export const useNavigateToHandleRequestCrewPage = () => {
  const navigate = useNavigate();

  return (id: number, startTime: string, endTime: string, schedule_type: ScheduleType) => {
    let uri = `/schedules/${id}/request-crew`;

    const paramsObject = {
      scheduleType: schedule_type,
      startTime,
      endTime,
    };
    uri = formatUri(uri, paramsObject);

    navigate(uri);
  };
};

export const useNavigateToScheduleEditPage = () => {
  const navigate = useNavigate();

  return (id: number) => navigate(`/schedules/${id}/edit`);
};

export const useNavigateToApplicantReview = () => {
  const navigate = useNavigate();

  return (scheduleId: number) => navigate(`/schedules/${scheduleId}/review-applicants`);
};
