import React, { useEffect, useState } from "react";
import { FullLogo, Portal } from "@bluecrew/web-react-core";
import { useSearchParams } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import assert from "assert";
import { LogoContainer, StyledBackdrop, StyledOverlay } from "../styledComponents";
import {
  useActivateAccount,
  useChangePassword,
  useConfirmSignIn,
  usePostSignIn,
  useSignIn,
} from "../hooks";
import { AuthenticationResult } from "../authenticationTypes";
import { AuthenticationError } from "../Errors/AuthenticationError";
import { ChangePasswordForm } from "../Forms/ChangePasswordForm";
import { InitiateActivationForm } from "../Forms/InitiateActivationForm";
import { CollapsibleMessage } from "../../CollapsableMessage";
import { MessageVariant, MessageVariants } from "../../Message";
import { ValueOf } from "../../../../types/util-types";
import { BackToLoginButton } from "../BackToLoginButton";
import { MfaForm } from "../Forms/MfaForm";
import { loginStrings } from "../strings";
import { setSentryUser } from "../../../sentry";

const FlowSteps = {
  InitiateActivation: "initiateActivation",
  ChangePassword: "changePassword",
  ConfirmSignIn: "confirmSignIn", // Optional 3rd step for users who have MFA enabled
} as const;
type FlowStep = ValueOf<typeof FlowSteps>;

const QueryParamNames = {
  Email: "email",
  ActivationCode: "activationCode",
} as const;

export const ActivateAccountFlow = () => {
  const [flowStep, setFlowStep] = useState<FlowStep>(FlowSteps.InitiateActivation);
  const [flowMessage, setFlowMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState<MessageVariant>(MessageVariants.Negative);
  const [userPassword, setUserPassword] = useState("");
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const userUsername = cognitoUser?.getUsername() ?? "";

  const postSignIn = usePostSignIn();

  const onAuthnSuccess = (result: AuthenticationResult) => {
    // We only need to set cog user and signInStep if there is a second step to do
    // I.e. a user has to put an MFA token in.
    // If the login was successful, skip that (user does not have MFA enabled)
    setCognitoUser(result.user);
    if (result.status === FlowSteps.ChangePassword) {
      setFlowStep(FlowSteps.ChangePassword);
      setFlowMessage(""); // Reset errors between steps
      return;
    }
    if (result.status === FlowSteps.ConfirmSignIn) {
      setFlowStep(FlowSteps.ConfirmSignIn);
      setFlowMessage("");
      return;
    }
    if (result.status === "signedIn") {
      postSignIn(result.username);
      return;
    }
    throw Error(`Unexpected sign in result: ${result.status}`);
  };

  const { mutate: activateAccount, isLoading: activationIsLoading } = useActivateAccount(
    onAuthnSuccess,
    (error: AuthenticationError) => setFlowMessage(error.message), // onSignInError
  );

  const { mutate: setPasswordForUser, isLoading: setPasswordIsLoading } = useChangePassword(
    onAuthnSuccess,
    (error: AuthenticationError) => setFlowMessage(error.message), // onSignInError
  );

  const { mutate: signIn } = useSignIn(onAuthnSuccess, (error: AuthenticationError) => {
    setMessageVariant(MessageVariants.Negative);
    setFlowMessage(error.message); // onSignInError
  });

  const { mutate: confirmSignIn, isLoading: confirmSignInIsLoading } = useConfirmSignIn(
    () => postSignIn(cognitoUser?.getUsername() ?? ""), // onConfirmSignInSuccess,
    (error: AuthenticationError) => {
      setMessageVariant(MessageVariants.Negative);
      setFlowMessage(error.message); // onConfirmSignInError,
    },
  );

  // If email and activation code are provided via URL params,
  // automatically complete the first step of flow
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const emailFromUrl = urlSearchParams.get(QueryParamNames.Email) ?? "";
  const activationCodeFromUrl = urlSearchParams.get(QueryParamNames.ActivationCode) ?? "";
  useEffect(() => {
    if (!!emailFromUrl && !!activationCodeFromUrl) {
      activateAccount(
        { username: emailFromUrl, password: activationCodeFromUrl },
        {
          // Remove search params from url after we're done using them
          onSettled: () => setUrlSearchParams(undefined),
        },
      );
    }
  }, [emailFromUrl, activationCodeFromUrl]);

  const renderFlowMessage = (message?: string) => (
    <CollapsibleMessage isOpen={!!message} text={message} variant={MessageVariants.Negative} />
  );

  return (
    <Portal>
      <StyledBackdrop visible />
      <StyledOverlay visible>
        <LogoContainer>
          <FullLogo height="3rem" />
        </LogoContainer>
        {flowStep === FlowSteps.InitiateActivation && (
          <InitiateActivationForm
            activationInProgress={activationIsLoading}
            activateAccount={(username, password) => {
              setSentryUser(username);
              activateAccount({ username, password });
            }}
            showInputs={!!emailFromUrl && !!activationCodeFromUrl}
            onAnyFieldChange={() => setFlowMessage("")}
            footer={<BackToLoginButton />}
          >
            {renderFlowMessage(flowMessage)}
          </InitiateActivationForm>
        )}
        {flowStep === FlowSteps.ChangePassword && (
          <ChangePasswordForm
            onSubmit={(password) => {
              assert(cognitoUser != null, "Cognito user should not be null");
              setUserPassword(password);
              setPasswordForUser({ user: cognitoUser, password });
            }}
            onAnyFieldChange={() => setFlowMessage("")}
            submitIsLoading={setPasswordIsLoading}
            footer={<BackToLoginButton />}
          >
            {renderFlowMessage(flowMessage)}
          </ChangePasswordForm>
        )}
        {flowStep === FlowSteps.ConfirmSignIn && (
          <MfaForm
            username={userUsername}
            requestNewCode={() =>
              signIn(
                { username: userUsername, password: userPassword },
                {
                  onSuccess: () => {
                    setFlowMessage(loginStrings.codeResent);
                    setMessageVariant(MessageVariants.Positive);
                  },
                },
              )
            }
            confirmSignInLoading={confirmSignInIsLoading}
            confirmSignIn={(code: string) => confirmSignIn({ user: cognitoUser, code })}
            footer={<BackToLoginButton />}
            onAnyFieldChange={() => setFlowMessage("")}
          >
            <CollapsibleMessage
              isOpen={!!flowMessage}
              text={flowMessage}
              variant={messageVariant}
            />
          </MfaForm>
        )}
      </StyledOverlay>
    </Portal>
  );
};
