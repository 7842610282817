import { styled } from "baseui";

import { Modal, getMargin } from "@bluecrew/blueprint-web";

// Part of a small hacky fix to make the job types fill up the whole
// modal, (they were getting cut off before).
// The fix for that meant I had to add this to eliminate double scroll bars
export const StyledModal = styled(Modal, () => ({
  overflow: "unset",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const SkillSetsTitle = styled("div", () => ({
  marginBottom: "0.6em",
}));

export const StyledGroup = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const IconsWrapper = styled("div", () => ({
  display: "inline-flex",
  flexWrap: "wrap",
  flexDirection: "row",
}));

export const SkillSetsHeader = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
}));

export const FieldContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  width: "439px",
}));

export const Wrapper = styled("div", ({ $theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  overflowY: "auto",
  justifyContent: "center",
  alignX: "center",
  ...getMargin($theme.sizing.scale600),
  marginTop: 0,
}));

export const GroupHeader = styled("div", () => ({
  display: "flex",
  fontSize: "12px",
  width: "100%",
  textTransform: "uppercase",
  fontWeight: "bold",
  letterSpacing: "0.3px",
  borderBottomColor: "rgb(222, 223, 232)", // TODO:(BW-697) Use exising pre-defined color export constants instead of hardcoded value
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  paddingBottom: "0.2rem",
  marginTop: "1rem",
  marginBottom: "0.4rem",
}));

export const GroupTitle = styled("div", () => ({
  color: "gray",
  display: "block",
  flex: "4",
}));
