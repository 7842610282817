import { useQuery } from "@tanstack/react-query";
import {
  getCrewGroups,
  GetCrewGroupsQueryResultType,
  getCrewMembers,
  GetCrewMembersResultType,
} from "../crewMembers";

export const selectCrewMembers = (crewMembers: GetCrewMembersResultType[]) => {
  return crewMembers.map((crewMember) => ({
    id: crewMember.user_id,
    externalId: crewMember.external_id,
    name: `${crewMember.firstName} ${crewMember.lastName}`,
    shifts: `${crewMember.shiftsWorked.toString()} SHIFTS WITH YOU`,
    lastWorkedDate: crewMember.lastWorkedDate,
    lastWorkedPositionTitle: crewMember.lastWorkedPositionTitle,
    ...(crewMember.profilePic && { imgUrl: crewMember.profilePic }),
  }));
};

export const useGetCrewMembersQuery = () =>
  useQuery(["crewMembers"], () => getCrewMembers(), {
    select: (crewMembers) => selectCrewMembers(crewMembers),
  });

export const selectCrewGroups = (crewGroups: GetCrewGroupsQueryResultType) =>
  crewGroups.map((crewGroup) => ({
    id: crewGroup.id,
    name: crewGroup.name,
    members: crewGroup.users.length,
  }));

export const useGetCrewGroupsQuery = () =>
  useQuery(["crewGroups"], () => getCrewGroups(), {
    select: (crewGroups) => selectCrewGroups(crewGroups),
  });
