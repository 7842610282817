import React from "react";
import { useNavigate } from "react-router-dom";
import { LoginRouteStrings } from "../../../shared/constants";
import { BCButtonVariants } from "../Button";
import { StyledButton } from "./styledComponents";

export const BackToLoginButton = ({
  label = "Back to Login",
  onClickHandler,
}: {
  label?: string;
  onClickHandler?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const navigate = useNavigate();
  return (
    <StyledButton
      label={label}
      variant={BCButtonVariants.Secondary}
      onClick={(e) => {
        onClickHandler && onClickHandler(e);
        navigate(LoginRouteStrings.Login);
      }}
    />
  );
};
