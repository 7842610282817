import styled from "styled-components";
import { palette as p } from "styled-tools";
import RcCollapse, { Panel } from "rc-collapse";

// See the docs for props: https://github.com/react-component/collapse#api

export const StyledCollapse = styled(RcCollapse)`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 6em;

  ul {
    width: 100%;
    flex: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rc-collapse-anim-active {
    transition: height 0.2s ease-out;
  }
`;

export const StyledPanel = styled(Panel)`
  margin-top: 0.5em;
  border-radius: 0.3em;
  background-color: ${p("frost")};
  & > .rc-collapse-header {
    text-transform: uppercase;
    text-indent: 1rem;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: #a2a6ac;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.2em;
  }

  .rc-collapse-content-inactive {
    display: none;
  }
  .rc-collapse-content {
    overflow: hidden;
    color: #666;
    background-color: #fff;

    li {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    > .rc-collapse-content-box {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
`;
