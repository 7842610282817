import styled from "styled-components";

export const ExamplesContainer = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray700};
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  right: -22%;
  width: 367px;
  margin-top: 2px;
`;

export const ListContainer = styled.ul`
  padding: 0 0 0 18px;
  margin: 0;
`;
