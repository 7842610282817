import React, { useEffect } from "react";
import { styled } from "baseui";
import {
  themedStyled,
  UserAvatar,
  useThemedStyletron,
  Input,
  CurrencyIcon,
  BlueprintTheme,
} from "@bluecrew/blueprint-web";
import { Control, FieldValues, useController } from "react-hook-form";
import { FieldLabel } from "../FieldLabel";
import { LabelText } from "../LabelText";
import { ScheduleTimeField } from "./ScheduleTimeField";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { NotificationManagerListItem } from "./NotificationManagerListItem";
import { NotificationManagers } from "../types/propTypes/NotificationManagerField.types";
import { ScheduleDayField } from "./ScheduleDayField";
import { paySum } from "./ExtraPayField";
import { Schedule } from "../types/propTypes/ScheduleField.types";
import { BaseWage } from "../types/propTypes/PositionField.types";

type ExistingScheduleInfoProps = {
  baseWage?: BaseWage;
  existingScheduleInfo: any;
  isDepartmentsEnabled: boolean;
  notificationManagers: NotificationManagers;
  schedule: Schedule;
  styles?: any;
  control: Control<FieldValues>;
};

export const ExistingScheduleInfo = ({
  schedule,
  existingScheduleInfo,
  baseWage,
  notificationManagers,
  isDepartmentsEnabled,
  styles,
  control,
}: ExistingScheduleInfoProps) => {
  const [, theme] = useThemedStyletron();
  const timeRange = {
    endDateTime: schedule.workingHours.endTime,
    startDateTime: schedule.workingHours.startTime,
  };
  const supervisor = existingScheduleInfo?.supervisor;
  const existingScheduleExtraPay =
    parseFloat(existingScheduleInfo?.base_wage) - parseFloat(baseWage || "0");
  const extraPay = existingScheduleExtraPay ? existingScheduleExtraPay.toFixed(2) : 0;
  const departmentTag = existingScheduleInfo.tag_name;
  const existingScheduleManagers = notificationManagers
    .map((manager) => {
      if (
        existingScheduleInfo.job_updates &&
        existingScheduleInfo.job_updates.includes(manager.id)
      ) {
        return {
          id: manager.id,
          name: manager.name,
        };
      }
      return null;
    })
    .filter((m) => m);

  useController({
    name: "supervisor",
    control,
  });

  useEffect(() => {
    control.setValue("supervisor", supervisor);
  }, []);

  return (
    <ScheduleInfoContainer style={{ ...styles }}>
      <TopAlignmentWrapper>
        <ScheduleDayField
          editable={false}
          invalid={false}
          onChange={() => {}}
          value={schedule.workingDays}
        />
        <ScheduleTimeField
          disabled
          errors={{}}
          invalid={false}
          setTimeRange={() => {}}
          value={timeRange}
        />
      </TopAlignmentWrapper>
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
      <OptionsContainer>
        <ScheduleDetailsBlock>
          {supervisor ? (
            <div>
              <FieldLabel>Supervisor</FieldLabel>
              <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale300} />
              <StyledSupervisorContainer>
                {supervisor ? (
                  <UserAvatar fullName={supervisor.name} size="lg" imgSrc={supervisor.imgUrl} />
                ) : null}
                <TextWrapper>
                  <Text>{supervisor?.name}</Text>
                </TextWrapper>
              </StyledSupervisorContainer>
            </div>
          ) : null}
          {isDepartmentsEnabled && departmentTag ? (
            <DepartmentBlock>
              <FieldLabel>Department</FieldLabel>
              <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale300} />
              <Input disabled value={departmentTag} />
            </DepartmentBlock>
          ) : null}
        </ScheduleDetailsBlock>

        {extraPay ? (
          <ExtraPayBlock>
            <LabelText>
              Extra pay <SmallText>Base pay: ${baseWage || "0.00"}</SmallText>
            </LabelText>
            <FieldWrapper>
              <Input disabled value={extraPay} placeholder="0.00" icon={<CurrencyIcon />} />
            </FieldWrapper>
            <LabelText>Total pay: ${paySum(Number(baseWage || "0.00"), +extraPay)}</LabelText>
          </ExtraPayBlock>
        ) : null}
        {existingScheduleManagers.length ? (
          <StyledListWrapper>
            <LabelText>Notifications</LabelText>
            <StyledList>
              {existingScheduleManagers.map((manager: any) => (
                <NotificationManagerListItem key={manager?.id} item={manager} />
              ))}
            </StyledList>
          </StyledListWrapper>
        ) : null}
      </OptionsContainer>
    </ScheduleInfoContainer>
  );
};

const ScheduleInfoContainer = styled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale1000,
}));

const TopAlignmentWrapper = styled("div", ({ $theme }) => ({
  display: "flex",
  paddingTop: $theme.sizing.scale800,
  alignItems: "flex-start",
}));

const StyledSupervisorContainer = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const TextWrapper = themedStyled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: $theme.sizing.scale300,
  width: "185px",
}));

const Text = styled("p", ({ $theme }) => ({
  ...$theme.typography.font200,
  fontWeight: 600,
  margin: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const SmallText = styled("span", ({ $theme }) => ({
  ...$theme.typography.font200,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.primary500,
  paddingLeft: $theme.sizing.scale100,
}));

const FieldWrapper = styled("div", ({ $theme }) => ({
  width: "127px",
  marginTop: $theme.sizing.scale300,
  marginBottom: $theme.sizing.scale200,
}));

const StyledListWrapper = styled("div", ({ $theme }) => ({
  marginTop: $theme.sizing.scale800,
}));

const OptionsContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledList = styled<{}, "div", BlueprintTheme>("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.crystal,
  borderRadius: $theme.sizing.scale200,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingTop: $theme.sizing.scale600,
  paddingLeft: $theme.sizing.scale600,
  marginTop: $theme.sizing.scale300,
}));

const ScheduleDetailsBlock = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const DepartmentBlock = styled("div", () => ({
  width: "329px",
}));

const ExtraPayBlock = styled("div", ({ $theme }) => ({
  marginTop: $theme.sizing.scale800,
}));
