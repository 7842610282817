import React from "react";
import { InputText } from "../../../../../components/InputText";

export type WorkerSearchProps = {
  onChange: (val: string) => void;
};

export const WorkerSearch = ({ onChange }: WorkerSearchProps) => (
  <span className="p-input-icon-left">
    <i className="pi pi-search" />
    <InputText
      type="text"
      placeholder="Search for crew members"
      style={{ minWidth: "300px" }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
    />
  </span>
);
