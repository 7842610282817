/* eslint-disable import/no-cycle */
import React from "react";
import {ButtonApprove} from "./ButtonApprove";
import {ButtonEdit} from "./ButtonEdit";
import {ButtonExcuse} from "./ButtonExcuse";
import {ButtonReject} from "./ButtonReject";
import {PayrollHoursStatus, TimesheetTabs} from "../../../types/Payroll.types";
import {SupportAction, TimesheetsShiftInfo} from "../../../types/Shift.types";

export type SupportUserActionButtonsProps = {
  rowData: TimesheetsShiftInfo;
  activeTabIndex: number;
  allowedSupportActions: Array<SupportAction>;
};

export const SupportUserActionButtons = ({
  activeTabIndex,
  rowData,
  allowedSupportActions,
}: SupportUserActionButtonsProps) => (
  <>
    {activeTabIndex === TimesheetTabs.PENDING_REVIEW && (
      <>
        <ButtonApprove
          disabled={!allowedSupportActions.includes(SupportAction.APPROVE)}
          rowData={rowData}
        />
        <ButtonEdit
          disabled={!allowedSupportActions.includes(SupportAction.EDIT)}
          rowData={rowData}
        />
        <ButtonExcuse
          disabled={!allowedSupportActions.includes(SupportAction.EXCUSE)}
          rowData={rowData}
        />
        <ButtonReject
          disabled={!allowedSupportActions.includes(SupportAction.REJECT)}
          rowData={rowData}
        />
      </>
    )}
    {activeTabIndex === TimesheetTabs.APPROVED && (
      <>
        <ButtonEdit
          disabled={!allowedSupportActions.includes(SupportAction.EDIT)}
          rowData={rowData}
        />
        <ButtonExcuse
          disabled={!allowedSupportActions.includes(SupportAction.EXCUSE)}
          rowData={rowData}
        />
        <ButtonReject
          disabled={!allowedSupportActions.includes(SupportAction.REJECT)}
          rowData={rowData}
        />
      </>
    )}
    {activeTabIndex === TimesheetTabs.DISPUTED && (
      <>
        <ButtonApprove
          disabled={!allowedSupportActions.includes(SupportAction.APPROVE)}
          rowData={rowData}
        />
        <ButtonEdit
          disabled={!allowedSupportActions.includes(SupportAction.EDIT)}
          rowData={rowData}
        />
        <ButtonExcuse
          disabled={!allowedSupportActions.includes(SupportAction.EXCUSE)}
          rowData={rowData}
        />
        <ButtonReject
          disabled={!allowedSupportActions.includes(SupportAction.REJECT)}
          rowData={rowData}
        />
      </>
    )}
    {activeTabIndex === TimesheetTabs.REMOVED && (
      <>
        <ButtonEdit
          disabled={!allowedSupportActions.includes(SupportAction.EDIT)}
          rowData={rowData}
        />
        {rowData.userShift.status === PayrollHoursStatus.REJECTED && (<ButtonExcuse
          disabled={!allowedSupportActions.includes(SupportAction.EXCUSE)}
          rowData={rowData}
        />)}
        {rowData.userShift.status === PayrollHoursStatus.DAY_OFF && (<ButtonReject
          disabled={!allowedSupportActions.includes(SupportAction.REJECT)}
          rowData={rowData}
        />)}
      </>
    )}
  </>
);
