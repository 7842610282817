// AUTH - SAGA
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import * as positionsAPI from "../../api/bluecrew/position";
import * as dashboardApi from "../../api/bluecrew/dashboard";
import positionActions from "../actions/position";

const { call } = Effects;

function* positionsSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(positionsAPI.getPositions, action.payload);
    yield put(positionActions.positions.success(response));
  } catch (error) {
    yield put(positionActions.positions.failure(error));
  }
}

function* readPositionSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(positionsAPI.readPosition, action.payload);
    yield put(positionActions.readPosition.success(response));
  } catch (error) {
    yield put(positionActions.readPosition.failure(error));
  }
}

function* getPositionsHeaderDetailsSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(dashboardApi.getDashboardPositions, action.payload);
    yield put(positionActions.getPositionsHeaderDetails.success({ positions: response }));
  } catch (error) {
    yield put(positionActions.getPositionsHeaderDetails.failure(error));
  }
}

export default {
  *watchPositionsSaga(): SagaIterator {
    yield takeLatest(positionActions.positions.request, positionsSaga);
    yield takeLatest(positionActions.readPosition.request, readPositionSaga);
    yield takeLatest(
      positionActions.getPositionsHeaderDetails.request,
      getPositionsHeaderDetailsSaga,
    );
  },
};
