import { Message } from "primereact/message";
import React from "react";
import { WarnExclaim } from "@bluecrew/blueprint-web/src/assets/svg/WarnExclaim";
import { PayrollReportsPageBannerWrapper } from "./styledComponents";
import "./styledComponents.css";

export type PayrollReportsPageBannerProps = {
    messageContent: string;
};

export const PayrollReportsPageBanner = ({ messageContent }: PayrollReportsPageBannerProps) => (
  <>
    {messageContent.length > 0 && (
      <PayrollReportsPageBannerWrapper>
        <Message
          className="p-inline-message p-inline-message-info payroll-message"
          severity={"warn"}
          content={
            <>
              <span style={{ verticalAlign: "bottom" }}>
                <WarnExclaim height={"19.2px"} width={"19.2px"} color="#cc8925" />
              </span>
              <span className="payroll-message-subject" style={{fontWeight: "bold"}}>{"Attention "}</span>
              <span className="payroll-message-text">{messageContent}</span>
            </>
          }
        />
      </PayrollReportsPageBannerWrapper>
    )}
  </>

);
