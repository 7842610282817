import type { Moment } from "moment-timezone";
import moment from "moment";
import { calculateShiftLength } from "@bluecrew/bluecrew-lib";

/**
 * Converts a date and time to a single UTC value
 * @param   {Moment} theDate
 * @param   {string} theTime
 * @returns {string}
 */
const getUTCValueOfDateAndTime = (theDate: Moment, theTime: string): string => {
  const hoursMinutes = theTime.split(":");
  // @ts-ignore
  theDate.set({ hour: hoursMinutes[0], minute: hoursMinutes[1] });
  // @ts-ignore
  return moment.utc(theDate).valueOf();
};

/**
 * Determine the shift length as a fraction of hours
 * ex: 8hrs 15min -> 8.25
 * @param   {Moment} endDate
 * @param   {string} endTime
 * @param   {Moment} startDate
 * @param   {string} startTime
 * @param   {string} timeZone
 * @returns {number}
 */
export const calcShiftLengthInHours = ({
  endDate,
  endTime,
  startDate,
  startTime,
  timeZone,
}): number => {
  const startUtc = getUTCValueOfDateAndTime(startDate, startTime);
  const endUtc = getUTCValueOfDateAndTime(endDate, endTime);
  const durationInSeconds = calculateShiftLength(startUtc, endUtc, timeZone);
  const SECONDS_IN_HOUR = 3600;
  const hours = durationInSeconds / SECONDS_IN_HOUR;

  return hours;
};
