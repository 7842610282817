import React from "react";
import { colorTheme } from "@bluecrew/blueprint-web";

interface ScheduleDropdownIconProps {
  isExpanded: boolean;
}

export const ScheduleDropdownIcon = (props: ScheduleDropdownIconProps) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    transform={props.isExpanded ? "rotate(180)" : ""}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6778 1.85455L6.77778 6.68181C6.34822 7.10606 5.65178 7.10606 5.22222 6.68181L0.322167 1.85455C-0.107388 1.4303 -0.107388 0.742446 0.322166 0.318192C0.751721 -0.106063 1.44817 -0.106063 1.87772 0.318192L6 4.37727L10.1223 0.318191C10.5518 -0.106064 11.2483 -0.106064 11.6778 0.318191C12.1074 0.742446 12.1074 1.4303 11.6778 1.85455Z"
      fill={colorTheme.dashboardMainColor}
    />
  </svg>
);
