// TODO Update this component to a function component in its own directory broken into multiple files per https://bluecrewjobs.atlassian.net/wiki/spaces/WS/pages/1676967961/React+-+Best+practices
import React from "react";
import { connect } from "react-redux";
import { Button } from "@bluecrew/web-react-core";
import { useNavigate } from "react-router-dom";
import { SimpleModal, SimpleModalTypes } from "../components/SimpleModal";
import { confirmationModalData } from "../redux/selectors/confirmationModal";
import confirmationModalActions from "../redux/actions/confirmationModal";
import messages from "../../shared/messages";

const mapStateToProps = (state) => ({
  modalData: confirmationModalData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(confirmationModalActions.closeModal()),
});

type ModalDataType = {
  body: string;
  contentType: string;
  heading: string;
  redirectPath: string;
  responseType: string;
  show: boolean;
};

type ConfirmationModalContainerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {};

const ConfirmationModalContainer = ({ closeModal, modalData }: ConfirmationModalContainerProps) => {
  const navigate = useNavigate();

  const handleSuccessDoneClick = () => {
    const { redirectPath } = modalData;

    if (redirectPath) {
      navigate(redirectPath);
      closeModal();
    } else {
      closeModal();
    }
  };

  const { body, contentType, heading, responseType, show } = modalData as unknown as ModalDataType;

  const headingText = heading || (contentType && messages[contentType][responseType].heading) || "";
  const bodyText = body || (contentType && messages[contentType][responseType].body) || "";

  const buttonProps = { "data-testid": "ConfirmationModalContainer-done" };

  return (
    <>
      {show && (
        <SimpleModal
          visible
          styleType={responseType}
          headingText={headingText}
          bodyText={bodyText}
          toggle={closeModal}
        >
          {responseType === SimpleModalTypes.SUCCESS && (
            <Button {...buttonProps} palette="primary" onClick={handleSuccessDoneClick}>
              Done
            </Button>
          )}
          {responseType === SimpleModalTypes.FAILURE && (
            <Button {...buttonProps} palette="danger" onClick={closeModal}>
              Done
            </Button>
          )}
        </SimpleModal>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModalContainer);
