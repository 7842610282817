import React from "react";
import styled from "styled-components";
import { CollapsibleMessage, MessageAlignment } from "../../CollapsableMessage";
import { MessageVariants } from "../../Message";

type Props = {
  fieldName: string;
  fieldLabel?: string;
  fieldError?: string;
  children: React.ReactNode;
};

const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.typography.font250.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
`;

export const FieldWrapperWithError = ({
  fieldName,
  fieldLabel = fieldName,
  fieldError,
  children,
}: Props) => {
  const isOpen = !!fieldError;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <StyledLabel htmlFor={fieldName}>{fieldLabel}</StyledLabel>
        {children}
      </div>
      <CollapsibleMessage
        isOpen={isOpen}
        text={fieldError}
        variant={MessageVariants.Negative}
        alignment={MessageAlignment.Left}
        size="small"
        style={{ marginTop: isOpen ? "6px" : 0 }}
      />
    </div>
  );
};
