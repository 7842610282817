import React from "react";
import { Controller } from "react-hook-form";
import { BaseLoginFieldProps } from "./types";
import { FieldWrapperWithError } from "./FieldWrapperWithError";
import { StyledInputText } from "../styledComponents";

interface UsernameFieldProps extends BaseLoginFieldProps {}

export const UsernameField = (props: UsernameFieldProps) => {
  const { fieldName, control, rules, onChange } = props;
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={(field) => (
        <FieldWrapperWithError {...props}>
          <StyledInputText
            id={field.name}
            value={field.value}
            onChange={(e) => {
              if (onChange) onChange(e);
              field.onChange(e.target.value);
            }}
          />
        </FieldWrapperWithError>
      )}
    />
  );
};
