import React, { useState } from "react";
import { BulkAssignCompaniesCheckbox, BulkAssignCompaniesIcon } from "./styledComponents";
import { truncateName } from "../../../api/bluecrew/company";
import { UserCompanyInfo } from "../../../api/bluecrew/types";

// Represents the state of the assigned users for a company.
export enum AssignedUsersState {
  NONE = 0, // No users are assigned to the company
  PARTIAL = 1, // Some, but not all, users are assigned to the company
  ALL = 2, // All users are assigned to this company
}

type BulkAssignCompaniesCheckboxProps = {
  company: UserCompanyInfo;
  assignedUsersState: AssignedUsersState;
  onSelectionChange: (company: UserCompanyInfo) => void;
};

export const BulkCompaniesItem = ({
  company,
  assignedUsersState,
  onSelectionChange,
}: BulkAssignCompaniesCheckboxProps) => {
  const [assigned, setAssigned] = useState(assignedUsersState);
  const shouldShowRemoveIcon = [AssignedUsersState.PARTIAL, AssignedUsersState.ALL].includes(assignedUsersState);
  return (
    <BulkAssignCompaniesCheckbox 
      data-testid={`bulk-assign-companies-checkbox-${company.companyId}`}
      onClick={() => {
        setAssigned(
          // PARTIAL/ALL -> NONE, NONE -> ALL.
          assigned === AssignedUsersState.NONE ? AssignedUsersState.ALL : AssignedUsersState.NONE,
        );
        onSelectionChange(company);
      }}
      shouldShowRemoveIcon={shouldShowRemoveIcon}
    >
      {`${truncateName(company.companyName)} - ${company.companyId}`}
      <BulkAssignCompaniesIcon
        isAllAssigned={assigned === AssignedUsersState.ALL}
        isPartialAssigned={assigned === AssignedUsersState.PARTIAL}
      />
    </BulkAssignCompaniesCheckbox>
  );
};
