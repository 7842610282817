// MULTISTEP FORM - COMPONENT
// =============================================================================

import React from "react";
import { Formik, Form } from "formik";
import { get } from "lodash";
import { Block, Button, Heading, Field, Box, Paragraph } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { scrollAppToTop } from "../utility";

// If any form page defines a 'validate' prop, it will be used as a validator!
//
// TODO: anything but this. Reaching into a React component and inspecting
// its props defeats all benefits of encapsulation.
//
// The validator is given a formValues object and should return an error
// object. If there are no errors, the returned object should be empty.
export type ImplicitValidatorProp = (formValues: any) => {
  [formField: string]: string;
};

export const ButtonAlignments = {
  LEFT: "buttons-left",
  RIGHT: "buttons-right",
  CENTER: "buttons-center",
};

export const ButtonBarWidth = {
  FULL: "full",
  HALF: "half",
};

const FormButtonContainer = styled(Block)`
  padding-top: 4rem;

  &.full {
    width: 100%;
  }

  &.half {
    width: 50%;
  }

  &.buttons-center {
    text-align: center;

    button {
      margin: 0 10px;
    }
  }

  &.buttons-left {
    text-align: left;

    button {
      margin-right: 20px;
    }
  }

  &.buttons-right {
    margin-bottom: 64px;
    padding-right: 28px;
    padding-top: 48px;
    text-align: right;

    button {
      margin-left: 20px;
    }
  }

  &.custom-button-position {
    position: absolute;
    top: 58.5rem;
    right: 50%;
  }
`;

const StyledInlineTextBox = styled(Box)`
  display: inline-block;
`;

type Props = {
  children?: React.ReactNode;
  goBackPrompt: string;
  onSubmit: Function;
  submittext: string;
  values: any;
  bag: any;
  initialvalues: any;
  validationschema: any;
  validationschemas: Array<Object>;
  buttonalignment: string;
  buttonbarwidth: string;
  "*": string;
  handleCustomGoBackButtonClick: (params: {
    event: React.SyntheticEvent<HTMLElement>;
    values: unknown;
  }) => void;
  showCustomGoBackButton: boolean;
};

class MultistepForm extends React.Component<Props, any> {
  static Page = ({ children }: Props) => children;

  static defaultProps = {
    buttonalignment: "left",
    buttonbarwidth: "half",
    goBackPrompt: "Go Back",
    submittext: "Submit",
    onSubmit() {},
    handleCustomGoBackButtonClick() {},
    showCustomGoBackButton: false,
  };

  state = {
    initialValuesAreValid: false,
    page: 0,
    values: [], // initialvalues left without casing to avoid DOM warning
  };

  async componentDidMount() {
    const validationSchema = this.getActivePageValidationSchema();
    if (!validationSchema) {
      return;
    }

    const isValid = await validationSchema.isValid(this.props.initialvalues);
    this.setState({ initialValuesAreValid: isValid });
  }

  next = (values: any, validateForm: Function) => {
    const childrenArr: Array<React.ReactNode> = React.Children.toArray(this.props.children);
    this.setState(
      (state) => ({
        page: Math.min(state.page + 1, childrenArr.length - 1),
        values,
      }),
      // @ts-ignore
      validateForm,
    );
    scrollAppToTop();
  };

  previous = (validateForm: Function) => {
    this.setState(
      (state) => ({
        page: Math.max(state.page - 1, 0),
      }),
      // @ts-ignore
      validateForm,
    );
    scrollAppToTop();
  };

  validate = (values: any) => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];
    // @ts-ignore
    const { validate } = activePage.props;
    return validate ? validate(values) : {};
  };

  getActivePageValidationSchema() {
    const { validationschema, validationschemas } = this.props;
    const { page } = this.state;
    if (validationschema) {
      return validationschema;
    }
    return get(validationschemas, `[${page}]`);
  }

  handleSubmit = (values: any, bag: any) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values, bag.validateForm);
    }
  };

  render() {
    const {
      children,
      buttonalignment,
      buttonbarwidth,
      goBackPrompt,
      handleCustomGoBackButtonClick,
      showCustomGoBackButton,
      ...props
    } = this.props;

    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    // @ts-ignore
    const customClass = activePage.props.customButtonPosition ? "custom-button-position" : null;
    const conditionalClasses = [buttonalignment, buttonbarwidth, customClass].join(" ");

    return (
      <Formik
        initialValues={this.props.initialvalues}
        isInitialValid={this.state.initialValuesAreValid}
        enableReinitialize
        validationSchema={this.getActivePageValidationSchema()}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={({ values, handleSubmit, isSubmitting, isValid, validateForm }) => {
          const submitIsDisabled = isSubmitting || !isValid;

          return (
            // @ts-ignore
            <Form {...props} onSubmit={handleSubmit}>
              {activePage}
              <FormButtonContainer className={conditionalClasses}>
                <StyledInlineTextBox>
                  <Paragraph>
                    {
                      // @ts-ignore
                      activePage.props.customInlineButtonText
                    }
                  </Paragraph>
                </StyledInlineTextBox>
                {page > 0 && (
                  <Button
                    className="form-button secondary"
                    type="button"
                    palette="secondary"
                    onClick={() => this.previous(validateForm)}
                    disabled={isSubmitting}
                  >
                    {goBackPrompt}
                  </Button>
                )}

                {/* only show custom go back button on first page */}
                {showCustomGoBackButton && page === 0 && (
                  <Button
                    type="button"
                    palette="secondary"
                    onClick={(event) =>
                      handleCustomGoBackButtonClick({
                        event,
                        values,
                      })
                    }
                  >
                    {goBackPrompt}
                  </Button>
                )}

                {!isLastPage && (
                  <Button
                    data-testid="MultistepForm-next-button"
                    className="form-button primary"
                    type="submit"
                    palette="primary"
                    disabled={submitIsDisabled}
                  >
                    Next
                  </Button>
                )}
                {isLastPage && (
                  <Button
                    data-testid="MultistepForm-submit-button"
                    className="form-button primary"
                    palette="primary"
                    type="submit"
                    disabled={submitIsDisabled}
                  >
                    {/* submittext left without casing to avoid DOM warning */}
                    {this.props.submittext}
                  </Button>
                )}
              </FormButtonContainer>
            </Form>
          );
        }}
      />
    );
  }
}

export default styled(MultistepForm)`
  ${Heading} {
    margin-top: 0;
  }

  ${Field} {
    padding: 0.75rem 0;
  }
`;
