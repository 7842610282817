// MaskedField - COMPONENT
// =============================================================================
// Shallow wrapper around MaskedInput w/ some types to make things easier

import React, { Component } from "react";
import { Block } from "../index";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import MaskedInput from "react-text-mask";

const MaskTypes = {
  PHONE: [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
};

const FieldContainer = styled(Block)`
  input {
    padding: 0.9375rem 1.25rem;
    height: 3.375rem;
    border-radius: 0.25rem;
    background-color: #f6f6f7;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: -0.0063rem;
    color: ${p("slate")};
    border: 0;
  }
`;

type Props = {
  name: String;
  classes: String;
  disabled: Boolean;
  maskType: Array<String>;
  placeholder: String;
};

export class MaskedField extends Component<Props, any> {
  static defaultProps = {
    name: "",
    classes: "",
    disabled: false,
    maskType: MaskTypes.PHONE,
    placeholder: "",
  };

  render() {
    const { name, classes, disabled, maskType, placeholder, ...props } = this.props;

    return (
      <FieldContainer>
        <MaskedInput
          className={classes}
          disabled={disabled}
          mask={maskType}
          name={name}
          placeholder={placeholder}
          {...props}
        />
      </FieldContainer>
    );
  }
}
