import React, { SyntheticEvent } from "react";
import { DaysOfWeek } from "@bluecrew/blueprint-web/src/components/Weekdays";
import { BaseWage } from "../../containers/GetCrewMembers/types/propTypes/PositionField.types";

export interface BaseListItemT {
  selected: boolean;
  title: string;
  subtitle?: string;
  information?: string;
}

export interface ScheduleListItemT extends BaseListItemT {
  workingHours: string;
  workingDays: DaysOfWeek;
  totalWage: BaseWage;
  positionWage: BaseWage;
}

export interface UserListItemT extends BaseListItemT {
  imgUrl?: string;
}

export interface CrewGroupListItemT extends BaseListItemT {
  membersCount: number;
}

export interface EditVersionListItemT extends BaseListItemT {
  submittedBy?: string;
  note?: string;
}

export enum ComponentPosition {
  HIDDEN = "HIDDEN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum VerticalAlign {
  START = "start",
  CENTER = "center",
  END = "end",
}

export type ListItemRendererT<T> = (item: T) => JSX.Element;

export interface SelectionListProps<T extends BaseListItemT> {
  items: Array<T>;
  onItemClick?: (item: T) => void;
  topDivider?: boolean;
  bottomDivider?: boolean;
  listItemRenderer?: React.ComponentType<BaseListItemProps<T>>;
  rootRef?: React.Ref<HTMLElement>;
}

export interface BaseListItemProps<T extends BaseListItemT> {
  item: T;
  onClick?: (event: SyntheticEvent<HTMLElement, MouseEvent>) => void;
  underlineLastItem?: boolean;
  showCheckBox: boolean;
}

export interface RichListItemProps<T extends BaseListItemT> extends BaseListItemProps<T> {
  checkboxPosition?: ComponentPosition;
  leftContainerContentAlign?: VerticalAlign;
  avatarRenderer?: ListItemRendererT<T>;
  titleRenderer?: ListItemRendererT<T>;
  subtitleRenderer?: ListItemRendererT<T>;
  extraInfoRendererLeft?: ListItemRendererT<T>;
  extraInfoRendererRight?: ListItemRendererT<T>;
}
export interface ScheduleListItemProps<T extends ScheduleListItemT> extends BaseListItemProps<T> {}

export interface UserListItemProps<T extends UserListItemT> extends BaseListItemProps<T> {
  subtitleRenderer?: ListItemRendererT<T>;
}

export interface CrewGroupListItemProps<T extends CrewGroupListItemT>
  extends BaseListItemProps<T> {}

export interface EditVersionListItemProps<T extends EditVersionListItemT>
  extends BaseListItemProps<T> {}

export interface ListItemCheckboxProps {
  selected: boolean;
}

export interface StyledSelectionListProps {
  $topDivider: boolean;
  $bottomDivider: boolean;
}
