import React, { createRef, useEffect, useState } from "react";
import {
  Modal,
  Button,
  useThemedStyletron,
  UserAvatar,
  themedStyled,
} from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { useController, useForm } from "react-hook-form";
import { FieldLabel } from "../FieldLabel";
import { ModalBodyWrapper } from "../ModalBodyWrapper";
import { ModalFooterWrapper } from "../ModalFooterWrapper";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { SupervisorMapper } from "./SupervisorMapper";
import { CreateSupervisorModal } from "./CreateSupervisorModal";
import {
  CreateSupervisorInput,
  Supervisor,
  SupervisorsFieldProps,
  SupervisorsListItem,
} from "../types/propTypes/SupervisorField.types";
import { useSelectionList } from "../useSelectionList";
import { SelectionList } from "../../../components/SelectionList";
import { UserListItem } from "../../../components/SelectionList/UserListItem";
import { ErrorMessage } from "../ErrorMessage";
import { SUPERVISOR_REQUIRED } from "../types/propTypes/ScheduleField.types";
import {
  CreateSupervisorUserMutateRequest,
  useCreateSupervisorUser,
} from "../../../api/bluecrew/hooks/user";
import { CreateSupervisorUserRequest } from "../../../api/bluecrew/user";

export const SupervisorField = ({
  control,
  supervisors = [],
  mixed = false,
  createSupervisor,
  errors,
}: SupervisorsFieldProps) => {
  const fieldName = "supervisor";
  const {
    field: { onChange, value },
    meta: { invalid: invalidSupervisorName },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: (supervisor: Supervisor | null) => {
        if (!supervisor || supervisor.id === null || supervisor.name === null) {
          return SUPERVISOR_REQUIRED;
        }
        return true;
      },
    },
  });

  const {
    control: newSupervisorControl,
    errors: newSupervisorErrors,
    formState,
    getValues,
  } = useForm<CreateSupervisorInput>({ mode: "onBlur" });

  const [selectionModalOpen, setSelectionModalOpen] = useState(false);
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [creationModalLoading, setCreationModalLoading] = useState(false);
  const [createSupervisorError, setCreateSupervisorError] = useState<any>();
  const [hasSupervisor, setHasSupervisor] = useState(supervisors && supervisors.length > 0);

  const [isMixed, setIsMixed] = useState(mixed);

  useEffect(() => {
    value && select(value);
  }, []);

  const getListItems = () =>
    supervisors.map((supervisor) => SupervisorMapper.toListItem(supervisor));

  const { list, select, selected, addAndSelectItem } = useSelectionList<SupervisorsListItem>(
    getListItems(),
    { searchBy: "id" },
  );

  const { mutate: createSupervisorUser } = useCreateSupervisorUser(
    (data, { values }: CreateSupervisorUserMutateRequest) => {
      const supervisor = {
        // @ts-ignore
        id: data.user.id,
        name: `${values.normalized_first_name} ${values.normalized_last_name}`,
      };
      createSupervisor?.(supervisor);
      onChange(supervisor);
      addAndSelectItem(SupervisorMapper.toListItem(supervisor));
      setCreationModalOpen(false);
      setHasSupervisor(true);
      setCreationModalLoading(false);
    },
    (error) => {
      setCreateSupervisorError(error);
      setCreationModalLoading(false);
    },
  );

  const setSupervisorValue = () => {
    if (isMixed) setIsMixed(false);
    const [selectedSupervisor] = selected;
    onChange(SupervisorMapper.toModel(selectedSupervisor));
    setSelectionModalOpen(false);
  };

  const openSupervisorModal = () => {
    setSelectionModalOpen(false);
    setCreationModalOpen(true);
  };

  const [, theme] = useThemedStyletron();

  const modalRootRef = createRef<HTMLDivElement>();

  const supervisorTypeMapping = ["CLIENT_ADMIN", "MANAGER", "NO_BLUECREW_ACCOUNT"];

  return (
    <>
      <FieldContainer>
        <FieldLabel>Supervisor</FieldLabel>
        <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale300} />
        <StyledSupervisorContainer>
          {value ? avatarRenderer(value) : <img src={`/public/images/icons/avatar-user.svg`} />}
          <TextWrapper $withMargin>
            {isMixed ? (
              <Text>{"Mixed"}</Text>
            ) : (
              <Text>{value ? value.name : "No supervisor Assigned"}</Text>
            )}
            <ClickableText
              onClick={() => {
                if (hasSupervisor) {
                  setSelectionModalOpen(true);
                } else {
                  setCreationModalOpen(true);
                }
              }}
              data-testid="SupervisorField-ClickableText"
            >
              {hasSupervisor ? "change" : "add a supervisor"}
            </ClickableText>
          </TextWrapper>
        </StyledSupervisorContainer>
        {invalidSupervisorName && errors && <ErrorMessage text={errors.supervisor.message} />}
      </FieldContainer>
      {/* Selection Modal */}
      <Modal
        header="Managers"
        body={
          <ModalBodyWrapper ref={modalRootRef}>
            <SelectionList<SupervisorsListItem>
              rootRef={modalRootRef}
              items={list}
              bottomDivider
              listItemRenderer={UserListItem}
              onItemClick={(item) => select(item)}
            />
          </ModalBodyWrapper>
        }
        footer={
          <ModalFooterWrapper>
            {createSupervisor && (
              <>
                <Button width="100%" kind="secondary" onClick={openSupervisorModal}>
                  Add supervisor
                </Button>
                <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
              </>
            )}
            <Button width="100%" onClick={() => setSupervisorValue()}>
              Done
            </Button>
          </ModalFooterWrapper>
        }
        isOpen={selectionModalOpen}
        onClose={() => {
          setSelectionModalOpen(false);
        }}
      />
      {/* Creation Modal */}
      <CreateSupervisorModal
        control={newSupervisorControl}
        errors={newSupervisorErrors}
        responseError={createSupervisorError}
        isOpen={creationModalOpen}
        loading={creationModalLoading}
        onSubmit={() => {
          if (formState.isValid && createSupervisor) {
            setCreationModalLoading(true);
            setCreateSupervisorError(undefined);
            const input = getValues();
            const values: CreateSupervisorUserRequest = {
              normalized_first_name: input.firstName,
              normalized_last_name: input.lastName,
              phone: input.phone,
              userType: supervisorTypeMapping[input.accountType],
              username: input.email,
            };
            createSupervisorUser({ values });
          }
        }}
        onClose={() => {
          setCreateSupervisorError(undefined);
          setCreationModalOpen(false);
        }}
      />
    </>
  );
};

const avatarRenderer = (listItem: Supervisor) => (
  <UserAvatar fullName={listItem.name} size="lg" imgSrc={listItem.imgUrl} />
);

const FieldContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  width: "275px",
}));

const StyledSupervisorContainer = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const TextWrapper = themedStyled<"div", { $withMargin: boolean }>(
  "div",
  ({ $theme, $withMargin }) => ({
    display: "flex",
    flexDirection: "column",
    marginLeft: $withMargin ? $theme.sizing.scale300 : 0,
    width: "185px",
  }),
);

const Text = styled("p", ({ $theme }) => ({
  ...$theme.typography.font200,
  fontWeight: 600,
  margin: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const ClickableText = styled("p", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.accent700,
  cursor: "pointer",
  margin: 0,
}));
