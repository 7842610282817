import React from "react";
import { StyledRefreshButton } from "./styledComponents";

export const ButtonRefresh = () => (
  <StyledRefreshButton
    outlined
    label="Refresh"
    onClick={() => {
      window.location.reload();
    }}
  />
);
