import React from "react";
import { Image } from "@bluecrew/web-react-core";
import { assetUrl } from "../../api/bluecrew/constants/index";

interface ImageProps {
  icon: string | React.ReactElement<any>;
  wrapAsSVG: boolean;
}

export const Icon = ({ icon, wrapAsSVG }: ImageProps) => {
  if (typeof icon === "string") {
    const imageUrl = `${assetUrl}/${icon}`;
    const formattedImageUrl = imageUrl.replace("//", "/");
    return <Image src={formattedImageUrl} />;
  }

  if (!wrapAsSVG) {
    return icon;
  }

  return <div className="svg-container">{icon}</div>;
};
