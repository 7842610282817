import React from "react";
import PropTypes from "prop-types";
import { UserAvatar } from "./UserAvatar";
import styled from "styled-components";
import { palette as p } from "styled-tools";

const StyledBanner = styled.div`
  background-color: white;
  margin: 0 auto 47px auto;
  max-width: 1367px;
  height: 64px;
  border: 1px solid #d7d7d9;
  border-radius: 8px;
  border-left: none;

  .inner {
    display: flex;
    align-items: center;
    padding-left: 32px;
    border-left: 4px solid ${p("siren")};
    height: 100%;
    border-radius: 6px;
  }

  .icon {
    padding-right: 16px;
  }

  .message {
    color: ${p("slate")};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Banner = (props) => {
  return (
    <StyledBanner {...props}>
      <div className="inner">
        <UserAvatar classes="icon" imgSrc={props.icon} />
        <span className="message">{props.message}</span>
      </div>
    </StyledBanner>
  );
};

Banner.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
