import * as React from "react";
import { ComposableContainer } from "constate";
import StepContainer, {
  StepContainerSelectors,
  StepContainerActions,
  StepContainerState,
} from "./StepContainer";

const initialState: Partial<StepContainerState> = {
  loop: true,
  current: 0,
};

// istanbul ignore next
const TabsContainer: ComposableContainer<
  StepContainerState,
  StepContainerActions,
  StepContainerSelectors
> = (props) => (
  <StepContainer {...props} initialState={{ ...initialState, ...props.initialState }} />
);

export default TabsContainer;
