import React, { useContext, useState } from "react";
import { Button, H3Heading } from "@bluecrew/web-react-core";
import { KIND } from "baseui/tag";
import { useDispatch } from "react-redux";
import { AbilityContext } from "../../PermissionsContext";
import { getCompanyName } from "../../../redux/selectors/company";
import { useAppSelector } from "../../../redux";
import { ButtonWrapper, SettingsCard } from "../styledComponents";
import {
  getDrugScreeningStrings,
  UpdateCompanySettingsFailureMessage,
  UpdateCompanySettingsSuccessMessage,
} from "../CompanySettingStringsEN";
import { RadioList, RadioOption } from "../../../components/RadioList";
import { CompanyInfo, DrugScreeningTypes, SettingStates } from "../../../api/bluecrew/types";
import { ConfirmDisableSettingsModal } from "../../../components/BgCheckDrugScreeningModal";
import { useUpdateCompanyDetailsMutation } from "../hooks";
import { showToastAction } from "../../../redux/actions/app";

type DrugScreeningProps = {
  initialDrugScreening: number;
  drugScreenActive: boolean;
};

// TODO: Write follow-up RTL tests
export const drugScreeningEnabledTestId = "drugScreeningEnabled";
export const drugScreeningDisabledTestId = "drugScreeningDisabled";

export const DrugScreening = ({ initialDrugScreening, drugScreenActive }: DrugScreeningProps) => {
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch();
  const canUpdateDrugScreening = ability.can("update", "covid_policy"); // TODO: Is a placeholder. Replace with correct permission.
  const updateCompanyDetailsMutation = useUpdateCompanyDetailsMutation();
  const companyName = useAppSelector(getCompanyName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number>(
    drugScreenActive ? SettingStates.ENABLED : SettingStates.DISABLED,
  );

  // Text for 'Enabled' changes depending on 1. the value of `drug_screening_required` and 2. the company name
  const drugScreeningStrings = getDrugScreeningStrings(initialDrugScreening, companyName);
  const { enabled, disabled } = drugScreeningStrings.options;
  const options: Array<RadioOption> = [
    {
      label: enabled.label,
      text: enabled.text,
      value: enabled.value,
      testId: drugScreeningEnabledTestId,
    },
    {
      label: disabled.label,
      text: disabled.text,
      value: disabled.value,
      testId: drugScreeningDisabledTestId,
    },
  ];

  const updateCompanySettings = (companyInfo: CompanyInfo) => {
    updateCompanyDetailsMutation.mutate(companyInfo, {
      onSuccess: () => {
        dispatch(showToastAction(UpdateCompanySettingsSuccessMessage, KIND.positive));
      },
      onError: () => {
        dispatch(showToastAction(UpdateCompanySettingsFailureMessage, KIND.negative));
      },
    });
  };

  const handleClick = (newValue: DrugScreeningTypes) => {
    if (newValue === DrugScreeningTypes.DISABLED) {
      setIsModalOpen(true);
    } else {
      handleUpdateClick();
    }
  };

  const handleUpdateClick = () => {
    const drugScreenIsActive = options[selectedOption].value !== DrugScreeningTypes.DISABLED;
    const companyInfo: CompanyInfo = {
      drug_screen_active: drugScreenIsActive,
    };
    updateCompanySettings(companyInfo);
    setIsModalOpen(false);
  };

  return (
    <SettingsCard>
      <H3Heading>{drugScreeningStrings.title}</H3Heading>
      {isModalOpen && (
        <ConfirmDisableSettingsModal
          settingName={drugScreeningStrings.title.toLowerCase()}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleUpdateClick={handleUpdateClick}
        />
      )}
      <RadioList
        options={options}
        selected={selectedOption}
        setSelected={setSelectedOption}
        disabled={!canUpdateDrugScreening}
      />
      <ButtonWrapper>
        <Button
          palette="primary"
          onClick={() => handleClick(options[selectedOption].value)}
          disabled={!canUpdateDrugScreening}
        >
          {drugScreeningStrings.labels.updateButtonText}
        </Button>
      </ButtonWrapper>
    </SettingsCard>
  );
};
