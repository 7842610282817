import React, { useRef, useState } from "react";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useIsMutating } from "@tanstack/react-query";
import { ActionColumnButtonWrapper } from "../styledComponents";
import { ShiftAdjustment, ShiftAdjustModal } from "../ShiftAdjust/ShiftAdjustModal";
import { PayrollStatusReason } from "../../../types/Payroll.types";
import { useApproveShiftMutation } from "../../../../../api/bluecrew/hooks/payroll";
import QueryKeys from "../../../../../api/bluecrew/hooks/queryKeys";
import { ActionButton, ActionButtonIcon } from "./styledComponents";
import { buildShiftKey, buildShiftTimes } from "../../../shiftUtils";
import { Toast } from "primereact/toast";
import { ActionButtonIconPathPrefix } from "./index";
import { Tooltip } from "../../../../../components/Tooltip";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";

export type ButtonEditProps = {
  rowData: TimesheetsShiftInfo;
  disabled: boolean;
};

export const ButtonEdit = ({ disabled, rowData }: ButtonEditProps) => {
  const [isEditVisible, setIsEditVisible] = useState<boolean>(false);

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const headerProps = {
    headerText: "Adjust Timesheet",
    subHeaderText: "Please review and adjust time.",
  };
  const footerProps = {
    footerButtonLabel: "Approve",
  };
  const adjustmentCodes = [
    PayrollStatusReason.NO_ADJUSTMENTS,
    PayrollStatusReason.SUPPORT_UNADJUSTED,
    PayrollStatusReason.SUPPORT_ADJUSTED,
    PayrollStatusReason.TIME_THEFT,
    PayrollStatusReason.WALK_OFF,
    PayrollStatusReason.NO_LONGER_NEEDED,
    PayrollStatusReason.UNQUALIFIED,
  ];
  const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const adjustmentCodesWithTimeEdit = [...adjustmentCodes];

  const { userShift, job, user } = rowData;
  const shiftKey = buildShiftKey(rowData);
  const tooltipAnchor = `edit-${shiftKey.cmId}-${shiftKey.jobId}-${shiftKey.shiftIndex}`;
  const { mutate } = useApproveShiftMutation(shiftKey, showErrorToast);
  const isAnyPayrollActionMutating = !!useIsMutating(QueryKeys.Payroll_Action);

  /*
   * The TZ conversions allow us to give the illusion of zoned time in the prime react datetime picker
   * The prime picker interprets datetime as local regardless of tz:
   *   1. date is passed to component in client locale
   *   2. date is converted to job locale and passed to picker
   *   3. picker interprets only date time component as client locale (with offset applied), ignoring zone
   *   4. user edits date (with offset applied) in client locale
   *   5. date is passed from picker back to outer component for submission (in client locale with offset applied)
   *   6. date is force converted from job locale to client locale on submission (removing offset)
   * converting date to client locale on submission gives desired experience / behavior
   * https://github.com/primefaces/primereact/issues/4128
   */
  const editShiftHandler = ({ reason, clockIn, clockOut, breakDurationMins }: ShiftAdjustment) => {
    const baseStart = clockIn || userShift.start.time;
    const baseEnd = clockOut || userShift.end.time;

    const utcStart = zonedTimeToUtc(baseStart, job.timezone);
    const shiftStart = utcToZonedTime(utcStart, clientTz);

    const utcEnd = zonedTimeToUtc(baseEnd, job.timezone);
    const shiftEnd = utcToZonedTime(utcEnd, clientTz);

    const lunchDurationMinutes = Number.isFinite(breakDurationMins)
      ? breakDurationMins!
      : userShift.break.durationMinutes;

    mutate({
      shiftStart,
      shiftEnd,
      lunchDurationMinutes,
      jobId: job.externalId,
      shiftIndex: job.shiftIndex,
      userId: user.externalId,
      reason: reason.code,
    });
  };

  return (
    <ActionColumnButtonWrapper>
      <Toast ref={toast} />
      <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
        {"Edit"}
      </Tooltip>
      <ActionButton
        className={tooltipAnchor}
        disabled={disabled || isAnyPayrollActionMutating}
        icon={<ActionButtonIcon src={`${ActionButtonIconPathPrefix}/edit.svg`} />}
        rounded
        outlined
        onClick={() => {
          setIsEditVisible(true);
        }}
      />
      <ShiftAdjustModal
        headerProps={headerProps}
        footerProps={footerProps}
        shiftInfo={rowData}
        adjustmentCodes={adjustmentCodes}
        adjustmentCodesWithTimeEdit={adjustmentCodesWithTimeEdit}
        shiftAdjustModalVisible={isEditVisible}
        setShiftAdjustModalVisible={setIsEditVisible}
        shiftAdjustmentHandler={editShiftHandler}
        defaultShiftTimes={buildShiftTimes(rowData)}
      />
    </ActionColumnButtonWrapper>
  );
};
