/* eslint-disable import/no-cycle */
import React from "react";
import {
  UserColumnJobWrapper,
  UserColumnStyledAvatar,
  UserColumnName,
  UserColumnWrapper,
  UserColumnTextWrapper,
} from "./styledComponents";
import { TimesheetsJobInfo } from "../../../types/Shift.types";
import { AttributeJobInfo } from "./AttributeJobInfo";

export type UserColumnCellProps = {
  firstName: string;
  lastName: string;
  jobInfo: TimesheetsJobInfo;
  profilePic?: URL;
  className?: string;
};

export const UserColumnCell = ({
  firstName,
  lastName,
  jobInfo,
  profilePic,
  className,
}: UserColumnCellProps) => {

  const capitalizeFirstLetter = (word: string): string =>
    word.charAt(0).toUpperCase() + word.slice(1);
  const formatName = (first: string, last: string) =>
    `${capitalizeFirstLetter(first)} ${capitalizeFirstLetter(last)}`;

  const userNameText = formatName(firstName, lastName);

  return (
    <UserColumnWrapper className={className}>
      <UserColumnStyledAvatar fullName={userNameText || " "} size={"smd"} imgSrc={profilePic} />
      <UserColumnTextWrapper>
        <UserColumnName>{userNameText}</UserColumnName>
        <UserColumnJobWrapper>
          <AttributeJobInfo
            name={jobInfo.name}
            start={jobInfo.start}
            end={jobInfo.end}
            timezone={jobInfo.timezone}
          />
        </UserColumnJobWrapper>
      </UserColumnTextWrapper>
    </UserColumnWrapper>
  );
};
