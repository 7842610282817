import React from "react";
import { format } from "date-fns";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import styled from "styled-components";
import { StyledUserSubtitle } from "../../../../components/SelectionList/styled-components";
import {
  CrewMemberListItem,
  CrewMembersTabProps,
} from "../../types/propTypes/CrewMembersTab.types";
import { SelectionList } from "../../../../components/SelectionList";
import { UserListItem } from "../../../../components/SelectionList/UserListItem";

const BoldText = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

export const CrewMembersTab = ({ items, onItemClick, rootRef }: CrewMembersTabProps) => (
  <SelectionList<CrewMemberListItem>
    rootRef={rootRef}
    items={items}
    listItemRenderer={(props) => {
      return (
        <UserListItem
          item={props.item}
          showCheckBox
          onClick={props.onClick}
          subtitleRenderer={() => {
            if (props.item.lastWorkedDate && props.item.lastWorkedPositionTitle) {
              const formattedDate = format(new Date(props.item.lastWorkedDate), "MM/dd/yy");
              return (
                <StyledUserSubtitle $theme={blueprintTheme}>
                  <BoldText>{`${props.item.subtitle}`}</BoldText>
                  {`Last on ${formattedDate} (${props.item.lastWorkedPositionTitle})`}
                </StyledUserSubtitle>
              );
            }
            return <StyledUserSubtitle $theme={blueprintTheme}>{"No shift"}</StyledUserSubtitle>;
          }}
        />
      );
    }}
    onItemClick={onItemClick}
  />
);
