// Finance-Service - API
// =============================================================================

import {
  AuditTrailResponse,
  PayrollTimesheetResponse,
} from "@bluecrew/finance-service-node-client";
import { financeApi } from "./constants";

export const getCompanyShifts = async (
  companyId: string,
  week: number,
): Promise<PayrollTimesheetResponse> => {
  const url = `timesheet/${companyId}/payroll/${week}`;
  return financeApi.get(url).json();
};

export const getAuditTrail = async (
  companyId: string,
  jobId: string,
  shiftId: number,
  userId: string,
): Promise<AuditTrailResponse> => {
  const url = `timesheet/${companyId}/job/${jobId}/shift/${shiftId}/cms/${userId}/audit`;
  return financeApi.get(url).json();
};
