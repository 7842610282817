import React from "react";
import { TimeView } from "./TimeView";
import { AttributesDisplay } from "./AttributesDisplay";
import { hasShiftSubmissionTimeEnded, TimestampAttributeName } from "../../../shiftUtils";
import { PayrollHoursStatus, PayrollStatusReason } from "../../../types/Payroll.types";
import { ShiftBreak, ShiftComponent, TimesheetsShiftInfo } from "../../../types/Shift.types";

type BreakDisplayProps = {
  shiftBreak: ShiftBreak;
  shiftInfo: TimesheetsShiftInfo;
  shiftKey: string;
  showMessaging?: boolean;
};

export const BreakDisplay = ({
  shiftBreak,
  shiftInfo,
  shiftKey,
  showMessaging,
}: BreakDisplayProps) => {
  const { userShift, shiftThresholds } = shiftInfo;
  const { status, statusReason } = userShift;

  const formattedDuration = `${shiftBreak.durationMinutes.toFixed(0)} min`;
  let formattedDurationInDispute;

  if (shiftBreak.isDisputedDurationMissing) {
    formattedDurationInDispute = "Missing";
  } else if (shiftBreak.durationInDispute !== undefined) {
    formattedDurationInDispute = `${shiftBreak.durationInDispute} min`;
  }

  const isCompleteShiftInDispute =
    status === PayrollHoursStatus.DISPUTED &&
    !!statusReason &&
    ![PayrollStatusReason.SUPPORT_UNADJUSTED, PayrollStatusReason.RECEIVED_BREAKS].includes(
      statusReason,
    );

  return (
    <>
      <TimeView
        formattedTime={formattedDuration}
        isCompleteShiftInDispute={isCompleteShiftInDispute}
        formattedTimeInDispute={formattedDurationInDispute}
        missingTimeTooltipMessage={"An expected meal break has not been submitted"}
        isTimestampSubmitted={shiftBreak.attributes.includes(TimestampAttributeName.CM_SUBMITTED)}
        isTimestampMissing={shiftBreak.attributes.includes(
          TimestampAttributeName.CM_MISSING_SUBMISSION,
        )}
        hasShiftSubmissionTimeEnded={hasShiftSubmissionTimeEnded(shiftThresholds)}
        tooltipAnchor={`break-time-${shiftKey}`}
      />
      <AttributesDisplay
        attributes={userShift.break.attributes}
        shiftInfo={shiftInfo}
        timeType={ShiftComponent.BREAK_DURATION}
        tooltipAnchor={`break-attribute-${shiftKey}`}
        showMessaging={showMessaging}
      />
    </>
  );
};
