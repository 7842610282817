import React from "react";
import { styled } from "baseui";

type FieldWrapperProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  label?: string;
  iconTopMargin?: string;
  labelLineHeight?: string;
  paddingTop?: string;
};

export const FieldWrapper = ({
  children,
  icon,
  label,
  iconTopMargin,
  labelLineHeight,
  paddingTop,
}: FieldWrapperProps) => (
  <StyledContainer $paddingTop={paddingTop}>
    {icon && (
      <StyledIconContainer $label={label!} $iconTopMargin={iconTopMargin}>
        {icon}
      </StyledIconContainer>
    )}
    <StyledField>
      {label && <StyledLabel $lineHeight={labelLineHeight}>{label}</StyledLabel>}
      {children}
    </StyledField>
  </StyledContainer>
);

const StyledContainer = styled<{ $paddingTop?: string }, "div">(
  "div",
  ({ $theme, $paddingTop }) => ({
    display: "flex",
    paddingTop: $paddingTop || $theme.sizing.scale800,
  }),
);

const StyledField = styled("div", () => ({
  flexDirection: "column",
  width: "100%",
}));

const StyledLabel = styled<{ $lineHeight?: string }, "div">(
  "div",
  ({ $theme, $lineHeight = "60px" }) => ({
    ...$theme.typography.font450,
    lineHeight: $lineHeight,
  }),
);

const StyledIconContainer = styled<{ $label: string; $iconTopMargin?: string }, "div">(
  "div",
  ({
    $label,
    $theme,
    $iconTopMargin = $label ? $theme.sizing.scale600 : $theme.sizing.scale550,
  }) => ({
    marginRight: $theme.sizing.scale600,
    marginTop: $iconTopMargin,
  }),
);
