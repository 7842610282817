import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobOtherIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25089 5.25H30.7509C30.8921 5.25008 31.0305 5.21027 31.1502 5.13515C31.2698 5.06003 31.3658 4.95266 31.4271 4.82539C31.4883 4.69812 31.5124 4.55613 31.4966 4.41577C31.4807 4.27541 31.4255 4.14238 31.3374 4.032L28.7859 0.844503C28.5759 0.580601 28.309 0.367571 28.0051 0.221334C27.7012 0.0750969 27.3681 -0.000564772 27.0309 3.17389e-06H8.97089C8.63363 -0.000564772 8.30061 0.0750969 7.9967 0.221334C7.6928 0.367571 7.42588 0.580601 7.21589 0.844503L4.66589 4.032C4.57782 4.14228 4.52266 4.27517 4.50674 4.4154C4.49082 4.55563 4.51479 4.6975 4.57589 4.82471C4.63699 4.95193 4.73274 5.05932 4.85215 5.13456C4.97155 5.20979 5.10976 5.2498 5.25089 5.25Z"
        fill={svgColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.75 7.5H5.25C5.05109 7.5 4.86032 7.57902 4.71967 7.71967C4.57902 7.86032 4.5 8.05109 4.5 8.25V33.75C4.5 34.3467 4.73705 34.919 5.15901 35.341C5.58097 35.7629 6.15326 36 6.75 36H14.25C14.4489 36 14.6397 35.921 14.7803 35.7803C14.921 35.6397 15 35.4489 15 35.25V32.25C15 31.4543 15.3161 30.6913 15.8787 30.1287C16.4413 29.5661 17.2044 29.25 18 29.25C18.7956 29.25 19.5587 29.5661 20.1213 30.1287C20.6839 30.6913 21 31.4543 21 32.25V35.25C21 35.4489 21.079 35.6397 21.2197 35.7803C21.3603 35.921 21.5511 36 21.75 36H29.25C29.8467 36 30.419 35.7629 30.841 35.341C31.2629 34.919 31.5 34.3467 31.5 33.75V8.25C31.5 8.05109 31.421 7.86032 31.2803 7.71967C31.1397 7.57902 30.9489 7.5 30.75 7.5ZM19.8271 15.1571C19.613 14.2162 18.7357 13.5879 17.7883 13.6969C16.8409 13.806 16.1252 14.6176 16.125 15.5831C16.1249 16.1074 15.7051 16.5324 15.1873 16.5323C14.6696 16.5322 14.2499 16.107 14.25 15.5827C14.2504 13.6517 15.6818 12.0285 17.5766 11.8104C19.4714 11.5924 21.226 12.849 21.6542 14.7307C22.0825 16.6124 21.0479 18.5199 19.25 19.1636C19.0626 19.2307 18.9374 19.4101 18.9375 19.6114V19.8549C18.9375 20.3792 18.5178 20.8042 18 20.8042C17.4822 20.8042 17.0625 20.3792 17.0625 19.8549V19.6124C17.0622 18.6063 17.6883 17.7089 18.625 17.3735C19.524 17.0517 20.0412 16.098 19.8271 15.1571ZM18 21.7539C17.2881 21.7539 16.7109 22.3383 16.7109 23.0592C16.7109 23.7802 17.2881 24.3646 18 24.3646C18.7119 24.3646 19.2891 23.7802 19.2891 23.0592C19.2891 22.3383 18.7119 21.7539 18 21.7539Z"
        fill={svgColor}
      />
    </svg>
  );
};
