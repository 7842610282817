import { apiV1WithErrorRedirect } from "./constants";

export enum InvoicePreviewFileFormat {
  EXCEL = "EXCEL",
  CSV = "CSV",
}

export type InvoicePreviewPayload = {
  companyId: string;
  week: number;
  weekEnd?: number;
  pullAllChildInvoices: boolean;
  fileFormat: InvoicePreviewFileFormat;
};

export const invoicePreview = (payload: InvoicePreviewPayload): Promise<Blob> => {
  return apiV1WithErrorRedirect
    .post("invoices/preview", {
      json: payload,
    })
    .blob();
};
