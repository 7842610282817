import React from "react";
import { Input } from "@bluecrew/blueprint-web";
import { SIZE } from "baseui/input";
import styled from "styled-components";
import { GetCrewMembersFieldHeaderText } from "./MultiSelectDatePicker/styledComponents";
import { ErrorMessage } from "../ErrorMessage";
import { ScheduleType } from "../types/propTypes/Schedule.types";
import { ScheduleNameProps } from "../types/propTypes/ScheduleField.types";

export const ScheduleNameField = ({
  value,
  onChange,
  invalid,
  errors,
  scheduleType,
}: ScheduleNameProps) => (
  <FieldWrapper>
    {scheduleType === ScheduleType.SINGLE_SHIFT && (
      <GetCrewMembersFieldHeaderText>Name these shifts</GetCrewMembersFieldHeaderText>
    )}
    <Input
      value={value}
      onChange={(val) => {
        onChange(val);
      }}
      size={SIZE.default}
      error={invalid}
      data-testid="ScheduleNameField-Input"
      placeholder={`Pick a name for ${
        scheduleType === ScheduleType.SINGLE_SHIFT ? "these shifts" : "this schedule"
      }`}
    />
    {invalid && errors && <ErrorMessage text={errors.scheduleName.message} />}
  </FieldWrapper>
);

const FieldWrapper = styled.div`
  width: 605px;
`;
