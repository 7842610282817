import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobProductionNonFoodIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4302_23727)">
        <rect
          x="1.45801"
          y="24.175"
          width="33.0374"
          height="9.06556"
          rx="4.53278"
          stroke={svgColor}
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66227 28.8043C7.66227 29.2628 7.2906 29.6344 6.83211 29.6344C6.37363 29.6344 6.00195 29.2628 6.00195 28.8043C6.00195 28.3458 6.37363 27.9741 6.83211 27.9741C7.2906 27.9741 7.66227 28.3458 7.66227 28.8043ZM11.3771 28.8043C11.3771 29.2628 11.0054 29.6344 10.547 29.6344C10.0885 29.6344 9.7168 29.2628 9.7168 28.8043C9.7168 28.3458 10.0885 27.9741 10.547 27.9741C11.0054 27.9741 11.3771 28.3458 11.3771 28.8043ZM14.2618 29.6344C14.7203 29.6344 15.092 29.2628 15.092 28.8043C15.092 28.3458 14.7203 27.9741 14.2618 27.9741C13.8033 27.9741 13.4316 28.3458 13.4316 28.8043C13.4316 29.2628 13.8033 29.6344 14.2618 29.6344ZM18.8068 28.8043C18.8068 29.2628 18.4351 29.6344 17.9766 29.6344C17.5182 29.6344 17.1465 29.2628 17.1465 28.8043C17.1465 28.3458 17.5182 27.9741 17.9766 27.9741C18.4351 27.9741 18.8068 28.3458 18.8068 28.8043ZM21.6915 29.6344C22.15 29.6344 22.5216 29.2628 22.5216 28.8043C22.5216 28.3458 22.15 27.9741 21.6915 27.9741C21.233 27.9741 20.8613 28.3458 20.8613 28.8043C20.8613 29.2628 21.233 29.6344 21.6915 29.6344ZM26.2365 28.8043C26.2365 29.2628 25.8648 29.6344 25.4063 29.6344C24.9478 29.6344 24.5762 29.2628 24.5762 28.8043C24.5762 28.3458 24.9478 27.9741 25.4063 27.9741C25.8648 27.9741 26.2365 28.3458 26.2365 28.8043ZM29.1212 29.6344C29.5797 29.6344 29.9513 29.2628 29.9513 28.8043C29.9513 28.3458 29.5797 27.9741 29.1212 27.9741C28.6627 27.9741 28.291 28.3458 28.291 28.8043C28.291 29.2628 28.6627 29.6344 29.1212 29.6344Z"
          fill={svgColor}
        />
        <rect
          x="11.8896"
          y="10.3291"
          width="12.22"
          height="10.8381"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4302_23727">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
