module.exports = (object) => {
  var newObject = {};

  var objectKeys = Object.keys(object);
  for (var i = 0; i < objectKeys.length; i++) {
    var oldValue = object[objectKeys[i]];
    if (oldValue != null) {
      newObject[objectKeys[i]] = oldValue;
    }
  }

  return newObject;
};
