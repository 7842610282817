// JOB - API
// =============================================================================
import { ListedPositionResponse, PositionDetail, ReadPositionRequest } from "./types";
import { api, apiWithErrorRedirect } from "./constants/index";

type GetPositionsProps = {
  filter?: string;
};

export const getPositions = ({
  filter = "",
}: GetPositionsProps): Promise<ListedPositionResponse> => {
  const url = "v2/positions";
  const urlWithquery = filter !== "" ? `${url}?filter=${filter}` : url;

  return apiWithErrorRedirect.get(urlWithquery).json();
};

export const readPosition = ({
  positionId,
}: ReadPositionRequest): Promise<{ position: PositionDetail }> =>
  apiWithErrorRedirect.get(`v2/positions/${positionId}`).json();

type ReadPositionEditsQueryResult = {
  notificationStatuses: Array<any>;
  positionVersionId: number;
  positionHistory: Array<any>;
};

export const readPositionEdits = ({
  positionId,
}: ReadPositionRequest): Promise<ReadPositionEditsQueryResult> =>
  apiWithErrorRedirect.get(`v2/positions/${positionId}/positionHistory`).json();

export const archivePosition = ({ positionId }) => api.delete(`v2/positions/${positionId}`);

interface UpdatePositionPayload {
  title?: string;
  // eslint-disable-next-line camelcase
  arrival_instructions?: string;
  // eslint-disable-next-line camelcase
  refill_on?: boolean;
  dress_code?: string;
  change_notes?: string;
  description?: string;
  worksite?: {
    address: string;
    placeId: string;
  };

  reason_text?: string;
  reason_code: string;

  siteDesignation?: any;
  minWarnRadius?: any;
  minBlockRadius?: any;
}

export type UpdatePositionDetailsProps = {
  payload: UpdatePositionPayload;
  positionId: number;
};

export const updatePositionDetails = ({ payload, positionId }: UpdatePositionDetailsProps) =>
  api
    .post(`v2/positions/${positionId}`, {
      json: payload,
    })
    .json();

export const calculateFees = ({ payload, positionId }) =>
  api
    .post(`v2/positions/${positionId}/fees`, {
      json: payload,
    })
    .json();
