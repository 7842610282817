import { useState } from "react";

interface Selectable {
  [key: string]: any;
  selected: boolean;
}

interface Options<T> {
  multiSelect?: boolean;
  searchBy: keyof T;
}

/**
 * Convenience hook to be used with blueprint-web's SelectionList component
 *
 * @param list - the list of items to select from, which will be managed by this hook (if this list changes, the output `list` will not be updated -- use `setList` to do so)
 * @param options - `options.multiSelect = true` allows multiple items to be selected, `options.searchBy` provides the identity property by which items are compared (same identity property = assumption that objects are the same)
 */
export const useSelectionList = <T extends Selectable>(
  list: Array<T>,
  { multiSelect = false, searchBy }: Options<T>,
) => {
  const [collection, setCollection] = useState<Array<T>>(list);

  const select = (item: T) => {
    const newSelected = collection.map((listItem) => {
      const selected = multiSelect ? listItem.selected : false;

      return {
        ...listItem,
        selected: listItem[searchBy] === item[searchBy] ? true : selected,
      };
    });

    setCollection(newSelected);
  };

  const deselect = (item: T) => {
    const newSelected = collection.map((listItem) => ({
      ...listItem,
      ...(listItem[searchBy] === item[searchBy] && { selected: false }),
    }));

    setCollection(newSelected);
  };

  const toggleSelection = (item: T) => {
    const newSelected = collection.map((listItem) => {
      const selected = multiSelect ? listItem.selected : false;

      return {
        ...listItem,
        selected: listItem[searchBy] === item[searchBy] ? !selected : selected,
      };
    });

    setCollection(newSelected);
  };

  /**
   * Adds the provided item to the list, selects it, and deselects all other items.
   *
   * @param newItem
   */
  const addAndSelectItem = (newItem: T) => {
    const newCollection = collection.map((item) => ({
      ...item,
      selected: false,
    }));
    newCollection.push({ ...newItem, selected: true });
    setCollection(newCollection);
  };

  return {
    list: collection,
    setList: setCollection,
    selected: collection.filter((item) => item.selected),
    unSelected: collection.filter((item) => !item.selected),
    select,
    deselect,
    toggleSelection,
    addAndSelectItem,
  };
};
