import React from "react";
import { Avatar } from "primereact/avatar";
import {
  SearchEmptyIcon,
  SearchWarningIconWrapper,
  SearchWarningTextWrapper,
} from "./styledComponents";

export type EmptySearchProps = {
  path: string;
  message: string;
};

export const EmptySearch = ({ path, message }: EmptySearchProps) => (
  <>
    <SearchWarningIconWrapper>
      <Avatar
        icon={<SearchEmptyIcon src={`${path}/search-empty.svg`} />}
        size="xlarge"
        style={{ backgroundColor: "#CCF0FF", color: "#3246f0" }}
        shape="circle"
      />
    </SearchWarningIconWrapper>
    <SearchWarningTextWrapper>{message}</SearchWarningTextWrapper>
  </>
);
