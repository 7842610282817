// =============================================================================
// Presentational component that renders when an employer has not created any positions yet

import React from "react";
import { Block, Button, Image } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { CONTAINER_MAX_WIDTH } from "../../../../shared/constants";
import { assetUrl } from "../../../api/bluecrew/constants";
import { Can } from "../../PermissionsContext";

const EmptyPositionContainer = styled(Block)`
  background-color: ${p("white")};
  max-width: ${CONTAINER_MAX_WIDTH};
  border-radius: 0.5rem;
  border: 1px solid ${p("aluminum")};
  box-shadow: 0rem 0.1875rem 0.4375rem rgba(100, 106, 117, 0.08);
  margin-top: 2.5rem;
`;

const EmptyPositionContent = styled(Block)`
  padding: 3rem;
  text-align: center;
  img {
    display: inline-flex;
    max-width: unset;
    height: 4rem;
    width: 4rem;
  }

  p {
    margin: 1rem 0 1.5rem 0;
    color: ${p("platinum")};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
export const EmptyPositions = () => {
  const navigate = useNavigate();
  const handleOnClick = () => navigate("/positions/new");

  return (
    <EmptyPositionContainer>
      <EmptyPositionContent>
        <Image className="link-icon" src={`${assetUrl}/icons/position-search.svg`} />
        <Can I="create" a="job">
          <p data-testid="empty-position-content-text">
            Get started using Bluecrew, post your first job.
          </p>
          <Button onClick={handleOnClick} palette="primary">
            &#43; Post a Job
          </Button>
        </Can>
        <Can not I="create" a="job">
          <p data-testid="empty-position-content-text">
            Get started using Bluecrew. Once posted, your first job will appear here.
          </p>
        </Can>
      </EmptyPositionContent>
    </EmptyPositionContainer>
  );
};
