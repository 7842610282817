// TODO Update this component to a function component in its own directory broken into multiple files per https://bluecrewjobs.atlassian.net/wiki/spaces/WS/pages/1676967961/React+-+Best+practices
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import type { Moment } from "moment-timezone";
import get from "lodash/get";
import { NavigateFunction } from "react-router-dom";
import {
  JobTypeHistoryItem,
  JobTypesHistory,
  JobTypesHistoryResponse,
} from "../GetCrewMembers/types/JobTypesHistory.types";
import { Skills } from "../GetCrewMembers/types/propTypes/SkillsetField.types";
import {
  AddBlueShiftWorkRequestToSchPayloadData,
  AddCrewJobToSchedulePayload,
  AddWorkRequestToSchPayload,
  CompanyResponse,
  CreateBlueShiftScheduleJobPayload,
  CreateCrewAndOptJobAndSchedPayload,
  CreateDirectInviteAndOptJobAndSchedPayload,
  JobType,
  ListedPosition,
  PositionDetail,
  ScheduleStatus,
} from "../../api/bluecrew/types";
import jobActions from "../../redux/actions/job";
import { postCrewMembers, PostCrewMembersParams } from "../../redux/actions/crewMembers";
import { StateShape } from "../../redux/reducers";
import {
  getCreateAnyJobTypeFailureResponse,
  getCreateAnyJobTypePendingResponse,
  getCreateAnyJobTypeSuccessResponse,
  getPositionPendingResponse,
  postCreatePositionFailure,
  isDepartmentsEnabled,
  getFailedBulkSingleShifts,
  getSuccessfulBulkSingleShifts,
  SupervisorType,
} from "../../redux/selectors/job";
import {
  convertToDayMaskValue,
  formatMinWagePayload,
  getAllNotFalsyValuesFromArray,
} from "../../utility";
import { getMinWage, getMinWageIsFetching } from "../../redux/selectors/minWage";
import { readMinWage } from "../../api/bluecrew/minWage";
import { DEFAULT_BASE_WAGE } from "../../../shared/constants";
import { totalShifts } from "../../utility/schedule";
import { calculateFees } from "../../api/bluecrew/position";
import { api } from "../../api/bluecrew/constants";
import FormFlowContainer from "./FormFlowContainer";
import minWageActions from "../../redux/actions/minWage";
import { logError } from "../../sentry";
import { getIconName } from "../../utility/normalizeIconName";
import { insertBlueshiftRequestDataEntry } from "../../api/bluecrew/dashboard";
import { ErrorModal } from "./ErrorModal/ErrorModal";
import { calcShiftLengthInHours } from "./utils";
import { GetCrewMembers } from "../GetCrewMembers";
import { userCertToCertMapper, sortByAppearanceOrder } from "../../utility/userCertToCertMapper";
import { defaultFormValues } from "../GetCrewMembers/formUtils";
import { WorksiteAddress } from "../GetCrewMembers/types/propTypes/Worksite.types";
import { WorkflowSteps } from "../GetCrewMembers/types/propTypes/GetCrewMembers.types";
import { FormFieldTypes as GetCrewFormFieldTypes } from "../GetCrewMembers/types/propTypes/GetCrewMemberForm.types";
import { mapPosition } from "../GetCrewMembers/mapPostionData";
import { ScheduleType } from "../GetCrewMembers/types/propTypes/Schedule.types";
import { Wage } from "../GetCrewMembers/types/propTypes/HourlyPayField.types";
import { GeofenceFieldType } from "../GetCrewMembers/types/propTypes/GeofenceModal.types";
import { CrewGroup, CrewMembers } from "../GetCrewMembers/types/propTypes/ChooseCrewModal.types";

function ensure<T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there.",
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

const mapStateToProps = (state: StateShape) => ({
  jobCreatePending: getCreateAnyJobTypePendingResponse(state),
  jobCreatedSuccess: getCreateAnyJobTypeSuccessResponse(state),
  jobCreatedFailure: getCreateAnyJobTypeFailureResponse(state),
  minWage: getMinWage(state),
  minWageIsFetching: getMinWageIsFetching(state),
  createPositionPending: getPositionPendingResponse(state),
  createPositionFailure: postCreatePositionFailure(state),
  isDepartmentsEnabled: isDepartmentsEnabled(state),
  failedBulkSingleShifts: getFailedBulkSingleShifts(state),
  successfulBulkSingleShifts: getSuccessfulBulkSingleShifts(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  beginAnyJobPost: () => {
    dispatch(jobActions.beginAnyJobPost());
  },
  createBlueShiftScheduleJobs: (payload: CreateBlueShiftScheduleJobPayload) => {
    dispatch(jobActions.createBlueShiftScheduleJobs.request(payload));
  },
  createOptimizedJob: (payload) => {
    dispatch(jobActions.createJob.request(payload));
  },
  requestAdditionalCrew: (payload: AddWorkRequestToSchPayload) => {
    dispatch(jobActions.addWorkRequestToSch.request(payload));
  },
  addCrewJobToSchedule: (payload: AddCrewJobToSchedulePayload) => {
    dispatch(jobActions.addCrewJobToSchedule.request(payload));
  },
  createDirectInviteAndOptJobAndSchedule: (payload: CreateDirectInviteAndOptJobAndSchedPayload) => {
    dispatch(jobActions.createDirectInviteAndOptJobAndSchedule.request(payload));
  },
  createCrewAndOptJobAndScheduleSaga: (payload: CreateCrewAndOptJobAndSchedPayload) => {
    dispatch(jobActions.createCrewAndOptJobAndSchedule.request(payload));
  },
  postCrewMembers: (payload: PostCrewMembersParams) => dispatch(postCrewMembers(payload)),
  createPosition: (positionFields) => {
    dispatch(
      jobActions.createPosition.request({
        data: positionFields,
      }),
    );
  },
  getMinWage: (payload) => dispatch(minWageActions.readMinWage.request(payload)),
  minWageStartCalc: () => dispatch(minWageActions.startCalc()),
  updateBulkSingleShiftResponsesRequired: (payload) => {
    dispatch(jobActions.updateBulkSingleShiftResponsesRequired(payload));
  },
});

export const mapPositions = (positions: Array<ListedPosition>) =>
  // @ts-ignore
  positions.map(mapPosition).sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type GetCrewMembersRouteComponentProps = {};

interface IGetCrewForm {
  isBulkSingleShiftEnabled: boolean;
  isBulkSingleShiftMax50DayEnabled: boolean;
  singleAssignmentView: boolean;
  googleMaps: any;
  companyId: number;
  companyDetails: CompanyResponse;
  jobTypes: JobType[];
  positions: Array<ListedPosition>;
  refetchPositions: () => void;
  departments: any;
  supervisors: Array<SupervisorType>;
  crewGroups: Array<CrewGroup>;
  crewMembers: CrewMembers;
  userId: number;
  positionToCopy: ListedPosition;
  jobTypesHistory: JobTypesHistoryResponse["history"];
  getCrewValues: any;
  navigate: NavigateFunction;
}

export type GetCrewFormProps = IGetCrewForm &
  GetCrewMembersRouteComponentProps &
  StateProps &
  DispatchProps;

const DefaultRadii = {
  defaultOnsiteWarn: 250,
  defaultOnsiteBlock: 100000,
  defaultOffsiteWarn: 250,
  defaultOffsiteBlock: 100000,
};

interface State {
  modalOpen: boolean;
  hasSavedJob: boolean;
}

export class GetCrewForm extends React.Component<GetCrewFormProps, any> {
  state: State = {
    modalOpen: false,
    hasSavedJob: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.positionToCopy.title && prevProps.positionToCopy !== this.props.positionToCopy) {
      this.props.minWageStartCalc();
      this.fetchMinWageForPosition(this.props.positionToCopy);
    }
    if (
      prevProps.createPositionFailure !== this.props.createPositionFailure ||
      prevProps.jobCreatedFailure !== this.props.jobCreatedFailure
    ) {
      this.setState({ modalOpen: true });
    }
  }

  /*
   * Return a promise for a Google Places service result.
   */
  getGooglePlaceResult(doQuery: (onResult: (result: any, status: string) => void) => void) {
    const { googleMaps } = this.props;

    return new Promise((resolve, reject) => {
      const onResult = (result, status) => {
        if (status === googleMaps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          reject(new Error(`Unexpected PlacesServiceStatus: ${status || "[empty]"}`));
        }
      };
      doQuery(onResult);
    });
  }

  /*
   * Use the AutocompleteService to look up a place ID, if possible.
   */
  async predictGooglePlaceIdForPosition(position: PositionDetail) {
    const { googleMaps } = this.props;

    const input = `${position.address.street_address} ${position.address.city} ${position.address.country_area}`;

    let predictions;
    let predictionError;

    try {
      predictions = await this.getGooglePlaceResult((onResult) => {
        const service = new googleMaps.places.AutocompleteService();
        service.getQueryPredictions({ input }, onResult);
      });
      if (predictions.length === 0) {
        predictionError = new Error("Received zero predictions");
      }
    } catch (error) {
      predictionError = error;
    }

    if (predictionError) {
      logError({
        error: new Error(
          `AutocompleteService: no predictions for input "${input}"${
            predictionError ? `; error=${String(predictionError) || "[unknown]"}` : ""
          }`,
        ),
      });
    }

    if (predictions) {
      // Get the first prediction which is probably the right one
      // since we used a complete address.
      return predictions[0].place_id;
    }
    return undefined;
  }

  /*
   * Fetch the Google Place by ID for a position.
   */
  async fetchGooglePlace(position: PositionDetail, placeId: string) {
    const { googleMaps } = this.props;

    let place;
    let placeError;

    try {
      place = await this.getGooglePlaceResult((onResult) => {
        const service = new googleMaps.places.PlacesService( // We don't have a map but we need to pass in a throwaway DOM
          // node for where place attributes will be attached.
          document.createElement("div"),
        );
        service.getDetails({ placeId }, onResult);
      });
    } catch (error) {
      placeError = error;
    }

    if (!place) {
      logError({
        error: new Error(
          `Could not look up Google Place for position ID ${
            position.id
          }, place ID ${placeId}; error=${placeError || "[unknown]"}`,
        ),
      });
      return undefined;
    }
    return place;
  }

  async fetchMinWageForPosition(position) {
    let placeId = position.address.meta && position.address.meta.google_place_id;

    if (!placeId) {
      // Older positions could be missing a place ID. This error will
      // help us track how often it happens.
      logError({
        error: new Error(
          `No google_place_id for position ${position.id}, created ${position.created_at}`,
        ),
      });

      placeId = await this.predictGooglePlaceIdForPosition(position);
    }

    if (placeId) {
      const place = await this.fetchGooglePlace(position, placeId);
      if (place) {
        const payload = formatMinWagePayload(place.address_components);
        // Fetch and dispatch the minimum wage for this location.
        this.props.getMinWage(payload);
      }
    }
  }

  mapSkill(job, lastUsedAt) {
    return {
      ...job,
      iconName: getIconName(job.icon_name),
      requirements: job.users_requirements
        .map((requirement) => ({
          ...requirement,
          selected: requirement.appearance.preselected || requirement.appearance.is_locked,
          is_locked: requirement.appearance.is_locked,
          information: requirement.appearance.information,
        }))
        .sort(sortByAppearanceOrder),
      certifications: userCertToCertMapper(job.users_certs),
      lastUsedAt,
    };
  }

  mapSkills(jobTypes: any, jobTypesHistory: JobTypesHistory): Skills {
    // Pull out the last use for each job type, if available
    // Then use that info to augment each "Skill" with a lastUsedAt property
    const recentJobTypes: Record<number, JobTypeHistoryItem> = {};
    if (jobTypesHistory) {
      jobTypesHistory.forEach((item: JobTypeHistoryItem) => {
        const jtId = item.job_type_id;
        const jobTypeCached = jtId in recentJobTypes;
        const fresherThanCached = jobTypeCached
          ? moment(item.used_at).isAfter(recentJobTypes[jtId].used_at)
          : true;
        if (!jobTypeCached || fresherThanCached) {
          recentJobTypes[jtId] = item;
        }
      });
    }

    const getLastUsedAt = (jobId) =>
      jobId in recentJobTypes ? recentJobTypes[jobId].used_at : null;

    return jobTypes
      .filter((jobType) => jobType.enabled === 1)
      .map((job) => this.mapSkill(job, getLastUsedAt(job.id)));
  }

  joinDateWithTime(date, time, timezone): Moment {
    return moment.tz(`${moment(date).format("YYYY-MM-DD")} ${time}`, timezone);
  }

  isDepartmentNew(department) {
    return !this.props.departments.find((dep) => dep.id === department.id);
  }

  requestJob = (data) => {
    this.props.beginAnyJobPost();
    const {
      countWorkers,
      crew,
      schedule,
      position,
      date: [startDateTime, endDateTime],
      department,
      extraPay = 0,
      notificationManager = [{ id: 0 }],
      scheduleName,
      supervisor,
      timeRange: { startDateTime: startTime, endDateTime: endTime } = {
        startDateTime: null,
        endDateTime: null,
      },
      workDays,
      optimizedJob,
      isBlueShiftSchedule,
      blueshift_request_id,
    } = data;
    const groups = [] as any;
    let groupMembers = 0;
    const members = [] as any;
    if (crew.length && !crew[0].shifts) {
      crew.forEach(({ id, members, name }) => {
        if (id == null && name === "favorites") {
          groups.push("favorites");
        } else {
          groups.push(id);
        }
        groupMembers += members;
      });
    } else {
      const memberIdsMap = crew.reduce((acc, { id }) => ({ ...acc, [id]: 1 }), {});
      this.props.crewMembers.forEach(
        ({ id, externalId }) => memberIdsMap[id] && members.push(externalId),
      );
    }

    const { timezone, base_wage: baseWage } = ensure(
      this.props.positions.find(({ id }) => id === position.id),
    );
    if (isBlueShiftSchedule && schedule.scheduleType === ScheduleType.REPEATING) {
      const endDateTimeJoined = this.joinDateWithTime(endDateTime, endTime, timezone);
      const startDateTimeJoined = this.joinDateWithTime(startDateTime, startTime, timezone);

      const blueshiftRequestWithNewSchedulePayload = {
        schedule_details: {
          base_wage: extraPay ? (parseFloat(baseWage) + extraPay).toFixed(2) : baseWage,
          job_updates: notificationManager.map(({ id }) => id),
          schedule_name: scheduleName,
          schedule_type: schedule.scheduleType,
          poster_id: this.props.userId,
        },
        work_days: workDays,
        end_date_time: endDateTimeJoined.format("YYYY-MM-DDTHH:mm"),
        start_date_time: startDateTimeJoined.format("YYYY-MM-DDTHH:mm"),
        supervisor_user_id: supervisor.id,
        workers_needed: countWorkers,
      };

      const blueshiftRequestPayload = {
        schedule_id: schedule.id,
        work_days: workDays,
        end_date_time: endDateTimeJoined.format("YYYY-MM-DDTHH:mm"),
        start_date_time: startDateTimeJoined.format("YYYY-MM-DDTHH:mm"),
        supervisor_user_id: supervisor.id,
        workers_needed: countWorkers,
      };

      const basePayload: AddBlueShiftWorkRequestToSchPayloadData =
        schedule.id === -1 ? blueshiftRequestWithNewSchedulePayload : blueshiftRequestPayload;

      this.props.createBlueShiftScheduleJobs({
        controlModals: false,
        companyId: this.props.companyId,
        positionId: position.id,
        payload: basePayload,
      });
    } else if (schedule.id === -1) {
      // new schedule
      const startDate = this.joinDateWithTime(startDateTime, startTime, timezone);
      const endDate = this.joinDateWithTime(endDateTime, endTime, timezone);
      const basePayload = {
        // sending total wage as base_wage to match DB Schema, may change in the future is column is renamed in DB
        base_wage: extraPay ? (parseFloat(baseWage) + extraPay).toFixed(2) : baseWage,
        job_updates: notificationManager.map(({ id }) => id),
        position_id: position.id,
        schedule_name: scheduleName,
        work_days: workDays,
        schedule_type: schedule.scheduleType,
        blueshift_request_id,
      };
      if (this.props.isDepartmentsEnabled) {
        // @ts-ignore
        basePayload.tag_id = department.id;
      }
      if (schedule.schedule_type === ScheduleType.REPEATING) {
        // @ts-ignore
        basePayload.schedule_active_status = ScheduleStatus.PINNED;
      }
      if (!members.length && !groups.length) {
        // Optimized post
        this.props.createOptimizedJob({
          data: {
            ...basePayload,
            end_date_time: endDate,
            is_application: optimizedJob,
            poster_id: this.props.userId,
            start_date_time: startDate,
            supervisor_user_id: supervisor.id,
            workers_needed: countWorkers,
          },
          ...(this.props.isDepartmentsEnabled &&
            this.isDepartmentNew(department) && {
              newDepartment: department.tag_name,
              companyId: this.props.companyId,
            }),
        });
      } else if (groups.length) {
        // Group request
        const primaryNumberNeeded = Math.min(groupMembers, countWorkers);
        // @ts-ignore TODO: BW-1482 Fix this error
        const payload = {
          data: {
            ...basePayload,
            endDate,
            needed: primaryNumberNeeded,
            startDate,
            supervisor: supervisor.id,
            favorites: groups.includes("favorites"),
            crewIds: groups.filter(Boolean).filter((x) => x !== "favorites"),
          },
          ...(this.props.isDepartmentsEnabled &&
            this.isDepartmentNew(department) && {
              newDepartment: department.tag_name,
              companyId: this.props.companyId,
            }),
        } as CreateCrewAndOptJobAndSchedPayload;
        if (groupMembers < countWorkers) {
          // Data for additional Optimized request
          payload.additionalData = {
            is_application: optimizedJob,
            position_id: position.id,
            supervisor_user_id: supervisor.id,
            // job_template_id: schedule.template_id,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
            workers_needed: countWorkers - groupMembers,
            blueshift_request_id,
          };
        }
        this.props.createCrewAndOptJobAndScheduleSaga(payload);
      } else {
        // Direct invite request
        const primaryNumberNeeded = Math.min(members.length, countWorkers);
        // @ts-ignore TODO: BW-1482 Fix this error
        const payload = {
          data: {
            ...basePayload,
            endDate,
            needed: primaryNumberNeeded,
            startDate,
            supervisor: supervisor.id,
            userIds: members,
            schedule_type: schedule.scheduleType,
          },
          ...(this.props.isDepartmentsEnabled &&
            this.isDepartmentNew(department) && {
              newDepartment: department.tag_name,
              companyId: this.props.companyId,
            }),
        } as CreateDirectInviteAndOptJobAndSchedPayload;
        if (members.length < countWorkers) {
          // Data for additional Optimized request
          payload.additionalData = {
            is_application: optimizedJob,
            position_id: position.id,
            supervisor_user_id: supervisor.id,
            // job_template_id: schedule.template_id,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
            workers_needed: countWorkers - members.length,
            blueshift_request_id,
          };
        }
        this.props.createDirectInviteAndOptJobAndSchedule(payload);
      }
    } else {
      // existing schedule
      const existingSchedule = position.schedules.find(({ id }) => id === schedule.id);
      const startDate = this.joinDateWithTime(
        startDateTime,
        existingSchedule.workingHours.startTime,
        timezone,
      );
      const endDate = this.joinDateWithTime(
        endDateTime,
        existingSchedule.workingHours.endTime,
        timezone,
      );
      if (!members.length && !groups.length) {
        // Optimized post
        this.props.requestAdditionalCrew({
          controlModals: false,
          scheduleId: schedule.id,
          payload: {
            is_application: optimizedJob,
            position_id: position.id,
            supervisor_user_id: supervisor.id,
            // job_template_id: schedule.template_id,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
            workers_needed: countWorkers,
          },
        });
      } else if (groups.length) {
        // Group request
        const primaryNumberNeeded = Math.min(groupMembers, countWorkers);
        this.props.addCrewJobToSchedule({
          scheduleId: schedule.id,
          data: {
            crewIds: groups.filter(Boolean).filter((x) => x !== "favorites"),
            endDate,
            favorites: groups.includes("favorites"),
            // @ts-ignore
            jobTemplateId: "0",
            startDate,
            needed: primaryNumberNeeded,
            supervisor: supervisor.id,
            // userIds,
            work_days: existingSchedule.workingDays,
          },
        } as unknown as AddCrewJobToSchedulePayload);
        if (groupMembers < countWorkers) {
          // Additional Optimized request
          this.props.requestAdditionalCrew({
            controlModals: false,
            scheduleId: schedule.id,
            payload: {
              is_application: optimizedJob,
              position_id: position.id,
              supervisor_user_id: supervisor.id,
              // job_template_id: schedule.template_id,
              start_date: startDate.format("YYYY-MM-DD"),
              end_date: endDate.format("YYYY-MM-DD"),
              workers_needed: countWorkers - groupMembers,
            },
          });
        }
      } else {
        // Direct invite request
        const primaryNumberNeeded = Math.min(members.length, countWorkers);
        this.props.postCrewMembers({
          controlModals: false,
          data: {
            scheduleId: schedule.id,
            formattedValues: {
              startDate: startDate.format(),
              endDate: endDate.format(),
              days: convertToDayMaskValue(existingSchedule.workingDays),
              jobTemplateId: "0",
              needed: primaryNumberNeeded,
              supervisor: supervisor.id,
              userIds: members,
            },
          },
        });
        if (members.length < countWorkers) {
          // Additional Optimized request
          this.props.requestAdditionalCrew({
            controlModals: false,
            scheduleId: schedule.id,
            payload: {
              is_application: optimizedJob,
              position_id: position.id,
              supervisor_user_id: supervisor.id,
              // job_template_id: schedule.template_id,
              start_date: startDate.format("YYYY-MM-DD"),
              end_date: endDate.format("YYYY-MM-DD"),
              workers_needed: countWorkers - members.length,
            },
          });
        }
      }
    }
  };

  saveJobHelper = async (data) => {
    this.props.updateBulkSingleShiftResponsesRequired(0);
    if (
      data.schedule.scheduleType === ScheduleType.SINGLE_SHIFT &&
      this.props.isBulkSingleShiftEnabled
    ) {
      const { date, schedule } = data;
      this.props.updateBulkSingleShiftResponsesRequired(date.length);

      const blueshift_request_id = await insertBlueshiftRequestDataEntry(this.props.companyId);

      for (let i = 0; i < date.length; i += 1) {
        const workDays = [`${date[i].getDay()}`];
        this.requestJob({
          ...data,
          date: [date[i], date[i]],
          workDays,
          schedule: {
            ...schedule,
            workingDays: workDays,
          },
          blueshift_request_id,
        });
      }
    } else {
      this.requestJob(data);
    }
  };

  onSaveJob = async (data) => {
    // BW-853. With the change to takeEvery from takeLatest in the sagas to make the error modal work, if the user clicks on the send request button multiple times this check makes sure multiple requests are not sent.
    if (!this.state.hasSavedJob) {
      this.setState({ hasSavedJob: true }, () => {
        this.saveJobHelper(data);
      });
    }
  };

  createPosition(data) {
    const { companyId, createPosition } = this.props;
    const getSelectedReqTagLabels = (data) => data.map((requirement) => requirement.label);
    const getSelectedCertTagLabels = (data) => data.map((data) => data.label);
    const companyData = this.props.companyDetails.company.data;
    const createPositionPayload = {
      additional_description: data.description,
      address: data.worksite.address,
      arrival_instructions: data.arrivalInstructions,
      company_id: companyId,
      description: data.skillset.description,
      dress_code: data.dressCode || "",
      is_cloneable: 0, // future-proofing - leave for now
      job_type: data.skillset.id,
      place_id: data.worksite.placeId,
      min_warn_radius: data.minWarnRadius,
      min_block_radius: data.minBlockRadius,
      site_designation: data.siteDesignation,
      requirements: [
        ...getSelectedReqTagLabels(data.requirements),
        ...getSelectedCertTagLabels(data.certifications),
        ...getAllNotFalsyValuesFromArray(data.customRequirements),
      ],
      title: data.positionTitle,
      wage_floor: data.hourlyPay.wage || DEFAULT_BASE_WAGE,
      drug_screen_required: companyData.drug_screen_required,
      background_check_required: companyData.background_check_required,
    };
    createPosition(createPositionPayload);
  }

  locationSelect(address: WorksiteAddress): Promise<Wage> {
    const today = new Date();
    const payload = {
      date: today.toISOString(),
      city: address.city || "",
      state: address.state || "",
      county: address.county || "",
    };

    return readMinWage(payload) as Promise<Wage>;
  }

  getNumShifts = (data) =>
    totalShifts(data.workingDays, data.date[0], data.date[1], data.position.timezone);

  onGetScheduleInfo = async (id: string | number) => {
    const response: any = await this.fetchSchedule(id).catch(() => null);
    let scheduleData: any = null;
    if (response) {
      const { schedule } = response;
      scheduleData = schedule;
    }

    // Format data for using in Get Crew Member Form
    const scheduleInfo = scheduleData
      ? {
          ...scheduleData,
          ...(scheduleData.supervisor
            ? {
                supervisor: {
                  id: scheduleData.supervisor.user_id,
                  name: scheduleData.supervisor.full_name,
                  ...(scheduleData.supervisor.avatar_url && {
                    imgUrl: scheduleData.supervisor.avatar_url,
                  }),
                },
              }
            : null),
        }
      : null;
    // If request fails, return null
    // to hide the detailed schedule info from the UI
    return scheduleInfo;
  };

  getCreatePositionValues(positionToCopy, minWage) {
    let createPositionValues;

    if (positionToCopy.title) {
      const skill = this.props.jobTypes.find(
        // eslint-disable-next-line camelcase
        ({ icon_name }) => icon_name === positionToCopy.JobType.icon_name,
      );
      const skillset = skill ? this.mapSkill(skill, null) : null;
      createPositionValues = {
        skillset,
        requirements: skill?.users_requirements.reduce(
          (acc: Array<{ id: number; label: string; selected: boolean }>, { id, label }) => {
            if (positionToCopy.requirements.includes(label)) {
              acc.push({ id, label, selected: true });
            }
            return acc;
          },
          [],
        ),
        customRequirements: skill
          ? positionToCopy.requirements.filter((rq) => {
              const notReq = !skill.users_requirements.find(({ label }) => label === rq);
              const notCert = !skill.users_certs.find(({ label }) => label === rq);
              return notReq && notCert;
            })
          : [],
        certifications: skill?.users_certs.reduce(
          (acc: Array<{ id: number; label: string; selected: boolean }>, { id, label }) => {
            if (positionToCopy.requirements.includes(label)) {
              acc.push({ id, label, selected: true });
              skillset.certifications.some((cert) => {
                if (cert.label === label) {
                  // eslint-disable-next-line no-param-reassign
                  cert.selected = true;
                  return true;
                }
                return false;
              });
            }
            return acc;
          },
          [],
        ),
        positionTitle: positionToCopy.title,
        worksite: {
          address: `${positionToCopy.address.display_address} ${positionToCopy.address.postal_code}`,
          lat: Number(positionToCopy.address.latitude),
          lng: Number(positionToCopy.address.longitude),
          mainText: "",
          placeId: get(positionToCopy, "address.meta.google_place_id", ""),
          secondaryText: "",
        },
        hourlyPay: {
          minWage: Number(minWage),
          wage: Number(positionToCopy.base_wage),
        },
        arrivalInstructions: positionToCopy.arrival_instructions,
        description: positionToCopy.additional_description,
        dressCode: positionToCopy.dress_code,
      };
    } else {
      createPositionValues = {
        hourlyPay: {
          minWage: 15,
          wage: 15,
        },
        skillset: null,
        requirements: [],
        customRequirements: [],
        positionTitle: "",
        arrivalInstructions: "",
        description: "",
        dressCode: "",
        worksite: null,
      };
    }
    return createPositionValues;
  }

  render() {
    const {
      crewMembers,
      crewGroups,
      departments,
      supervisors,
      jobCreatePending,
      jobCreatedSuccess,
      jobTypes,
      createPositionPending,
      minWage,
      minWageIsFetching,
      positionToCopy,
      isDepartmentsEnabled,
      isBulkSingleShiftEnabled,
      isBulkSingleShiftMax50DayEnabled,
      successfulBulkSingleShifts,
      failedBulkSingleShifts,
      jobTypesHistory,
      getCrewValues,
      singleAssignmentView,
      navigate,
    } = this.props;

    if (jobCreatePending || createPositionPending || minWageIsFetching) {
      return <SpinnerWithOverlay />;
    }

    if (jobCreatedSuccess && failedBulkSingleShifts.length === 0) {
      navigate("/positions");
    }

    let defaultValues = {
      ...defaultFormValues,
      getCrew: getCrewValues,
    };

    // Duplicate position
    if (positionToCopy.title) {
      defaultValues = {
        ...defaultValues,
        createPosition: this.getCreatePositionValues(positionToCopy, minWage),
      };
    }

    const geofence: GeofenceFieldType = this.getGeofence();

    return (
      <FormFlowContainer>
        <ErrorModal
          partialRequestFail={successfulBulkSingleShifts && successfulBulkSingleShifts.length > 0}
          modalOpen={this.state.modalOpen}
          closeModal={() => this.setState({ modalOpen: false, hasSavedJob: false })}
          failedBulkSingleShifts={failedBulkSingleShifts}
          successfulBulkSingleShifts={successfulBulkSingleShifts}
        />
        <GetCrewMembers
          preselectedSchedule={this.props.getCrewValues.schedule}
          preselectedPosition={defaultValues.getCrew.position}
          activeStep={positionToCopy.title ? WorkflowSteps.CREATE_POSITION : WorkflowSteps.GET_CREW}
          data={{
            positions: mapPositions(this.props.positions),
            crewMembers,
            crewGroups,
            supervisors,
            departments,
            geofence,
            notificationManagers: supervisors,
            isDepartmentsEnabled: !!isDepartmentsEnabled,
            skills: this.mapSkills(jobTypes, jobTypesHistory),
          }}
          defaultValues={defaultValues}
          events={{
            onSaveJob: this.onSaveJob,
            onSavePosition: this.createPosition.bind(this),
            onLocationSelect: this.locationSelect.bind(this),
            onBeforeReviewOrder: this.beforeReviewOrder,
            onGetNumShifts: this.getNumShifts,
            // Get detailed info about existing schedule
            onGetScheduleInfo: this.onGetScheduleInfo.bind(this),
          }}
          isBulkSingleShiftEnabled={isBulkSingleShiftEnabled}
          isBulkSingleShiftMax50DayEnabled={isBulkSingleShiftMax50DayEnabled}
          isSingleAssignmentView={singleAssignmentView}
        />
      </FormFlowContainer>
    );
  }

  getGeofence() {
    const { companyDetails } = this.props;

    // if for some reason couldn't load company data then disable geofence in create position
    if (
      !companyDetails ||
      // @ts-ignore TODO: BW-1482 Fix this error
      !companyDetails.company ||
      // @ts-ignore TODO: BW-1482 Fix this error
      !companyDetails.company.data
    ) {
      return {
        disable_radii_use: true,
        min_onsite_warn_radius: DefaultRadii.defaultOnsiteWarn,
        min_onsite_block_radius: DefaultRadii.defaultOnsiteBlock,
        min_offsite_warn_radius: DefaultRadii.defaultOffsiteWarn,
        min_offsite_block_radius: DefaultRadii.defaultOffsiteBlock,
      };
    }
    return {
      // @ts-ignore TODO: BW-1482 Fix this error
      disable_radii_use: companyDetails.company.data.disable_radii_use
        ? // @ts-ignore TODO: BW-1482 Fix this error
          companyDetails.company.data.disable_radii_use
        : false,
      min_onsite_warn_radius:
        // @ts-ignore TODO: BW-1482 Fix this error
        companyDetails.company.data.min_onsite_warn_radius
          ? // @ts-ignore TODO: BW-1482 Fix this error
            companyDetails.company.data.min_onsite_warn_radius
          : DefaultRadii.defaultOnsiteWarn,
      min_onsite_block_radius:
        // @ts-ignore TODO: BW-1482 Fix this error
        companyDetails.company.data.min_onsite_block_radius
          ? // @ts-ignore TODO: BW-1482 Fix this error
            companyDetails.company.data.min_onsite_block_radius
          : DefaultRadii.defaultOnsiteBlock,
      // @ts-ignore TODO: BW-1482 Fix this error
      min_offsite_warn_radius: companyDetails.company.data.min_offsite_warn_radius
        ? // @ts-ignore TODO: BW-1482 Fix this error
          companyDetails.company.data.min_offsite_warn_radius
        : DefaultRadii.defaultOffsiteWarn,
      // @ts-ignore TODO: BW-1482 Fix this error
      min_offsite_block_radius: companyDetails.company.data.min_offsite_block_radius
        ? // @ts-ignore TODO: BW-1482 Fix this error
          companyDetails.company.data.min_offsite_block_radius
        : DefaultRadii.defaultOffsiteBlock,
    };
  }

  beforeReviewOrder = async (data: GetCrewFormFieldTypes) => {
    // the following does not happen but our GetCrewFormFieldTypes is poorly defined for this function
    if (data === null || data.schedule === null || data.position === null)
      throw new Error("Incomplete data was provided");

    let numShifts;
    if (data.scheduleType === ScheduleType.SINGLE_SHIFT && this.props.isBulkSingleShiftEnabled) {
      numShifts = data.date.length;
    } else {
      numShifts = totalShifts(
        data.schedule.workingDays,
        moment(data.date[0]).format(),
        moment(data.date[1]).format(),
        data.position.timezone,
      );
    }

    const shiftLength = calcShiftLengthInHours({
      endDate: moment(data.date[1]),
      endTime: data.schedule.workingHours.endTime,
      startDate: moment(data.date[0]),
      startTime: data.schedule.workingHours.startTime,
      timeZone: data.position.timezone,
    });

    const getWage = async () => {
      if (data.schedule === null || data.position === null)
        throw new Error("Incomplete data was provided");
      if (data.schedule.id === -1) {
        return data.extraPay
          ? Number(data.position.baseWage) + data.extraPay
          : Number(data.position.baseWage);
      }
      const {
        // @ts-ignore
        schedule: { base_wage: wage },
      } = await this.fetchSchedule(data.schedule.id).catch(() => ({
        schedule: { base_wage: 0 },
      }));
      return wage;
    };

    const jobData = {
      numShifts,
      shiftLength,
      // @ts-ignore
      wage: await getWage(),
      workers: data.countWorkers,
    };

    // @ts-ignore
    const { fee } = await calculateFees({
      payload: jobData,
      positionId: data.position.id,
    }).catch(() => ({
      fee: {
        subTotal: 0,
        serviceFee: 0,
        pricingType: "",
        taxesAndComp: 0,
        totalCost: 0,
      },
    }));

    return {
      shiftsCount: numShifts,
      shift_wage: await getWage(),
      fees: {
        wages: Number(fee.subTotal),
        serviceFee: Number(fee.serviceFee),
        pricingType: String(fee.pricingType),
        taxes: Number(fee.taxesAndComp || 0),
        total: Number(fee.totalCost || 0),
      },
    };
  };

  fetchSchedule = (scheduleId) => api.get(`v2/schedules/${scheduleId}`).json();
}

export default connect(mapStateToProps, mapDispatchToProps)(GetCrewForm);
