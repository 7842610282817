import { useController } from "react-hook-form";
import { RESIZE_VALUE, Textarea, useThemedStyletron } from "@bluecrew/blueprint-web";
import React, { useState } from "react";
import { styled } from "baseui";
import { FieldLabelRow } from "./FieldLabelRow";
import { FieldRowWrapper } from "./FieldRowWrapper";
import { errorMsg } from "./validationErrorMessages";
import { RelativeFlexFieldWrapper } from "./RelativeFlexFieldWrapper";
import { ExamplesTitleContainer, ExamplesToggleButton } from "./ExamplesToggleButton";
import { FieldContentExample } from "./FieldContentExample/FieldContentExample";
import { FieldTextLength } from "./FieldTextLength";
import { DescriptionFieldProps } from "./types/propTypes/DescriptionField.types";
import { Spacer, SpacerType } from "../../components/Spacer";

const DESCRIPTION_MIN_LENGTH = 10;
const DESCRIPTION_MAX_LENGTH = 5000;
const requirementsExamples = [
  {
    id: "4.1",
    text: "This text is included on every Job posting for this Position type.",
  },
];

export const GeneralRequirementsField = ({
  control,
  trigger,
  disabled = false,
  examplesEnabled = false,
}: DescriptionFieldProps) => {
  const fieldName = "generalRequirements";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      required: true,
      minLength: {
        value: DESCRIPTION_MIN_LENGTH,
        message: errorMsg("min", DESCRIPTION_MIN_LENGTH),
      },
    },
  });

  const handleChange = (instructions: string) => {
    onChange(instructions);
  };

  const [, theme] = useThemedStyletron();
  const [fieldFocus, onFieldFocus] = useState(false);
  const [examplesVisible, setExamplesVisible] = useState(false);

  return (
    <RelativeFlexFieldWrapper data-pendo-key="GeneralRequirementsField">
      <FieldRowWrapper>
        <ExamplesTitleContainer>
          <FieldLabelRow>General requirements</FieldLabelRow>
          {examplesEnabled && (
            <ExamplesToggleButton onClick={() => setExamplesVisible(!examplesVisible)}>
              Details
            </ExamplesToggleButton>
          )}
        </ExamplesTitleContainer>
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <InputWrapper>
          <Textarea
            value={value}
            error={invalid}
            onChange={(text) => handleChange(text)}
            onFocus={() => onFieldFocus(true)}
            onBlur={() => {
              onFieldFocus(false);
              validate();
            }}
            disabled={disabled}
            resize={RESIZE_VALUE.vertical}
            styles={{ minHeight: "200px" }}
          />
          <FieldTextLength
            focused={fieldFocus}
            error={invalid}
            minLength={DESCRIPTION_MIN_LENGTH}
            maxLength={DESCRIPTION_MAX_LENGTH}
            length={String(value).length}
          />
        </InputWrapper>
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
      </FieldRowWrapper>
      <FieldContentExample examples={requirementsExamples} examplesVisible={examplesVisible} />
    </RelativeFlexFieldWrapper>
  );
};

const InputWrapper = styled("div", () => ({ width: "502.82px" }));
