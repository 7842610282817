import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Separator, SideContainer } from "./styledComponents";
import { RoleFilter } from "./RoleFilter";
import { dropDownToEnum } from "../constants";
import { UserType } from "../../../api/bluecrew/types";
import { setRoleFilter } from "../slices/manageUsersFilterSlice";
import { useAppSelector } from "../../../redux";

type RoleFilterSideBarProps = {
  availableRoles: Array<string>;
};

export const RoleFilterSideBar = ({ availableRoles }: RoleFilterSideBarProps) => {
  const [allRoles, setAllRoles] = useState<Array<string>>([]);
  const { roleFilter } = useAppSelector((state) => state.manageUsersFilter);
  const dispatch = useDispatch();
  useEffect(() => {
    const convertedRoles: UserType[] = availableRoles.map(
      (roleString) => dropDownToEnum[roleString],
    );
    // overwrite only if the roleFilter is empty or the new convertedRoles don't include any of the selected roles
    if (roleFilter.length === 0 || !roleFilter.some((role) => convertedRoles.includes(role))) {
      setAllRoles(availableRoles);
      dispatch(setRoleFilter(convertedRoles));
    } else {
      // otherwise, we remove the filtered roles that are no longer in the availableRoles and keep the rest
      setAllRoles(availableRoles.filter((role) => roleFilter.includes(dropDownToEnum[role])));
      dispatch(setRoleFilter(convertedRoles.filter((role) => roleFilter.includes(role))));
    }
  }, [availableRoles]);

  // Logic for toggling Role filter selection boxes
  const handleRoleChange = (role: string) => {
    let updatedRoles: Array<string>;

    switch (role) {
      case "all":
        updatedRoles = availableRoles;
        break;
      case "none":
        updatedRoles = [];
        break;
      default:
        updatedRoles = allRoles.includes(role)
          ? allRoles.filter((r) => r !== role)
          : [...allRoles, role];
        break;
    }

    const convertedRoles: UserType[] = updatedRoles.map((roleString) => dropDownToEnum[roleString]);
    dispatch(setRoleFilter(convertedRoles));
    setAllRoles(updatedRoles);
  };

  return (
    <div>
      <SideContainer>
        <Separator />
        <RoleFilter
          availableRoles={availableRoles}
          selectedRoles={allRoles}
          onChange={handleRoleChange}
        />
      </SideContainer>
    </div>
  );
};
