import React from 'react';

export const GetCrewMembersMemberCountIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8C2.96243 8 0.5 10.4624 0.5 13.5C0.5 14.4002 0.5 15 0.5 16H3L4 23.5H8"
        stroke="#33343D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8C9.03757 8 11.5 10.4626 11.5 13.5002C11.5 14.4004 11.5 15.0002 11.5 16.0002H9L8 23.5002"
        stroke="#33343D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="5.5" cy="3.5" r="3" stroke="#33343D" />
      <path
        d="M18 8C14.9624 8 12.5 10.4624 12.5 13.5C12.5 14.4002 12.5 15 12.5 16H15L16 23.5H20"
        stroke="#33343D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8C21.0376 8 23.5 10.4626 23.5 13.5002C23.5 14.4004 23.5 15.0002 23.5 16.0002H21L20 23.5002"
        stroke="#33343D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="17.5" cy="3.5" r="3" stroke="#33343D" />
    </svg>
  );
};
