import styled from "styled-components";

export const ErrorMessage = styled.div`
  color: #e72e3e;
  font-size: 12px;
`;

export const StyledFieldLabel = styled.label``;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #080912;
`;

export const FormContainer = styled.div`
  font-family: "Inter";
  text-align: left;
`;

export const FormTextFieldContainer = styled.div`
  margin-top: 24px;
`;

export const StyledTextArea = styled.textarea<{ invalid: boolean }>`
  resize: none;
  ${({ invalid }) => (invalid ? "" : "border-color: #BABBC4")};
  ${({ invalid }) => (invalid ? "outline: 1px solid #E72E3E" : "")};
  ${({ invalid }) => (invalid ? "border: 1px solid #E72E3E" : "")};
  ${({ invalid }) => (invalid ? "border-radius: 5px" : "")};
`;
