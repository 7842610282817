import React from "react";
import { AccountManagerNameText, RowAlign, StyledUserAvatar } from "./styledComponents";

type AccountManagerCellProps = {
  accountManagerName: string;
};

export const AccountManagerCell = ({ accountManagerName }: AccountManagerCellProps) => (
  <RowAlign>
    <StyledUserAvatar fullName={accountManagerName || " "} size={"smd"} />
    <AccountManagerNameText>{accountManagerName || ""}</AccountManagerNameText>
  </RowAlign>
);
