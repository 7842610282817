import React from "react";
import { useExpanded, useRowSelect, useSortBy, useTable } from "react-table";
import styled from "styled-components";
import { SortingContainer } from "../../../components/ReactTable/SortingContainer";
import { TableColumnSortIcon } from "../../../svg/TableColumnSortIcon";
import { TableHeaderColumnContainer } from "../SharedComponents";

interface ReactTableComponentProps {
  columns: any;
  data: any;
  noRowsMessage: string;
}

const StyledNoRowsMessage = styled.div`
  font-size: 18px;
  font-weight: medium;
`;

export const ReactTableComponent = ({ columns, data, noRowsMessage }: ReactTableComponentProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      initialState: {
        // @ts-ignore TODO:(BW-1045) Fix React Table Types
        sortBy: [
          {
            id: "status",
            desc: true,
          },
        ],
      },
      data,
      disableSortRemove: true,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
  );
  return (
    <>
      {rows.length > 0 ? (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const headerKey = headerGroup.getHeaderGroupProps().key;

              return (
                // @ts-ignore TODO:(BW-1045) Fix React Table Types
                <tr key={headerKey} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const columnKey = column.getHeaderProps().key;

                    return (
                      <th
                        // @ts-ignore TODO:(BW-1045) Fix React Table Types
                        key={`${headerKey}-${columnKey}`}
                        {...column.getHeaderProps([
                          { className: column.className },
                          column.getSortByToggleProps(),
                        ])}
                      >
                        <TableHeaderColumnContainer
                          justifyContent={
                            columnKey === "header_status-updated" || columnKey === "header_status"
                              ? "flex-end"
                              : "normal"
                          }
                        >
                          {column.render("Header")}
                          {column.canSort && (
                            <SortingContainer
                              data-pendo-key={`ReactTableComponentRequestModal-${columnKey}`}
                              isSorted={column.isSorted}
                            >
                              <TableColumnSortIcon isAscending={!column.isSortedDesc} />
                            </SortingContainer>
                          )}
                        </TableHeaderColumnContainer>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const rowKey = row.getRowProps().key;

              return (
                // @ts-ignore TODO:(BW-1045) Fix React Table Types
                <tr key={rowKey} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const cellKey = cell.getCellProps().key;

                    return (
                      <td
                        // @ts-ignore TODO:(BW-1045) Fix React Table Types
                        key={`${rowKey}-${cellKey}`}
                        {...cell.getCellProps([{ className: cell.column.className }])}
                      >
                        {cell.render("Cell", {
                          rowData: row.original,
                          row,
                        })}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <StyledNoRowsMessage style={{ textAlign: "center" }}>{noRowsMessage}</StyledNoRowsMessage>
      )}
    </>
  );
};
