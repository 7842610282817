import React from "react";
import styled from "styled-components";
import { AlertBellIcon, Button, Heading, Link, Image } from "@bluecrew/web-react-core";
import { useNavigate } from "react-router-dom";
import { palette as p } from "styled-tools";
import { Switch, jobIconsMap, SwitchType, DEFAULT_ICON_NAME } from "@bluecrew/blueprint-web";
import { AbilityContext, Can } from "../PermissionsContext";
import { PositionAutoRefillToolTip } from "../../components/PositionAutoRefillToolTip";
import { TooltipMessage } from "../../components/TooltipMessage";
import DropDownMenu, { DropDownTypes } from "../../components/DropDrownMenu";
import { PositionRequestStats } from "../../api/bluecrew/types";
import AddSingleShiftIcon from "../../svg/AddSingleShiftIcon";
import AddNewScheduleIcon from "../../svg/AddNewScheduleIcon";
import DashboardCertificationBadge from "./DashboardCertificationBadge";
import { Certifications } from "../GetCrewMembers/types/propTypes/CertificationsField.type";

const StyledDisabledLink = styled(Link)`
  color: ${p("platinum")};
  opacity: 0.5;
  text-decoration: none;

  :hover {
    cursor: not-allowed;
  }
`;
const StyledPositionEdit = styled.div`
  display: flex;
  margin-bottom: 32px;
  width: 50%;
`;

const StyledPositionName = styled.div`
  display: flex;
`;

const PositionCertificationBadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  flex-flow: wrap;
  max-width: 600px;
`;

const StyledPositionHeader = styled.div`
  ${Button} {
    margin: auto 0;
  }

  ${Heading} span:nth-child(2) {
    padding-left: 10px;
  }

  & ${StyledPositionEdit} {
    button {
      margin-left: 15px;
      width: 100px;
    }
  }
`;

const StyledPositionTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.0008px;
  color: ${p("slate")};
  padding-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  ${Button} {
    margin: 0;
  }
`;

const ContinuousFillContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const ActionButtonContainer = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  &:hover {
    cursor: pointer;
  }
  margin-right: 24px;
`;

const StyledHeadingContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledPositionAddress = styled.div`
  padding-top: 5px;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${p("platinum")};
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 6px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PositionNameAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

type PositionBoxHeaderProps = {
  positionAddress: string;
  positionId: number;
  positionTitle: string;
  refillOn: boolean;
  toggleContinuousFill: () => void;
  can_archive: boolean;
  has_applications_to_review?: boolean;
  handlePostScheduleClick: () => void;
  handleArchivePositionClick: () => void;
  handleAddSingleShiftClick: () => void;
  requestStats?: PositionRequestStats;
  job_type_icon_name: string;
  certifications: Certifications;
};

const PositionBoxHeader = (props: PositionBoxHeaderProps) => {
  const navigate = useNavigate();
  const {
    positionAddress,
    positionTitle,
    has_applications_to_review,
    refillOn,
    job_type_icon_name,
    can_archive,
    toggleContinuousFill,
    handlePostScheduleClick,
    handleArchivePositionClick,
    handleAddSingleShiftClick,
    positionId,
    certifications,
  } = props;

  const duplicatePosition = () => {
    navigate("/positions/new", { state: { createFromPositionId: positionId } });
  };

  const navToEditPosition = () => {
    navigate(`/positions/${positionId}/edit`);
  };

  const iconByName =
    jobIconsMap[job_type_icon_name || DEFAULT_ICON_NAME] || jobIconsMap[DEFAULT_ICON_NAME];
  const Icon = iconByName.type;
  const archiveText = "Archive this position";

  const ability = React.useContext(AbilityContext);
  const canCreatePosition = ability.can("create", "position");

  return (
    <StyledPositionHeader data-pendo-key="PositionBoxHeaderContainer">
      <StyledHeadingContentContainer>
        <LeftContainer>
          <Icon width={"48px"} height={"48px"} color={"#CCCDD6"} />
          <PositionNameAddressContainer>
            <StyledPositionName data-pendo-key="DashboardPositionName">
              <StyledPositionTitle onClick={navToEditPosition}>
                <Link>{positionTitle}</Link>
              </StyledPositionTitle>
              {has_applications_to_review && (
                <div data-pendo-key="DashboardScheduleListItemApplicationReviewIndicator">
                  <TooltipMessage message="There are pending applicants to review">
                    <Image className="bell-icon" src={AlertBellIcon} alt="candidate review" />
                  </TooltipMessage>
                </div>
              )}
            </StyledPositionName>
            <StyledPositionAddress data-pendo-key="DashboardPositionAddress">
              {positionAddress}
            </StyledPositionAddress>
            <PositionCertificationBadgesContainer>
              {certifications?.map(
                (certification, i) =>
                  certification.selected && (
                    <DashboardCertificationBadge
                      key={`DashboardCertificationBadge${i}`}
                      label={certification.label}
                    />
                  ),
              )}
            </PositionCertificationBadgesContainer>
          </PositionNameAddressContainer>
        </LeftContainer>
        <RightContainer data-pendo-key="DashboardPositionHeaderRightContainer">
          <Can I="post" a="company_jobs">
            <Can I="create" a="job">
              <ActionButtonContainer
                onClick={handlePostScheduleClick}
                data-pendo-key="DashboardPositionHeaderAddNewSchedule"
              >
                <AddNewScheduleIcon />
                Add new schedule
              </ActionButtonContainer>
              <ActionButtonContainer
                onClick={handleAddSingleShiftClick}
                data-pendo-key="DashboardPositionHeaderAddIndividualShifts"
              >
                <AddSingleShiftIcon />
                Add individual shifts
              </ActionButtonContainer>
            </Can>
          </Can>
          <ContinuousFillContainer
            data-testid="continuous-fill-switch"
            data-pendo-key="DashboardPositionContinuousFillToggle"
          >
            <Can I="update" a="schedule" passThrough>
              {(allowed: boolean) => (
                <>
                  <Switch
                    type={allowed ? SwitchType.default : SwitchType.disabled}
                    value={refillOn}
                    onChange={toggleContinuousFill}
                    isSmaller
                  />
                  <PositionAutoRefillToolTip />
                </>
              )}
            </Can>
          </ContinuousFillContainer>
          <ActionsContainer>
            <div data-pendo-key="DashboardPositionHeaderActionsDropdown">
              <DropDownMenu
                buttonText=""
                buttonPalette="secondary"
                borderless
                displayType={DropDownTypes.THREE_DOT}
              >
                <Link data-testid="ScheduleListItem-action-view" onClick={navToEditPosition}>
                  View {canCreatePosition ? "/ Edit" : ""} position details
                </Link>
                <Can I="create" a="position">
                  <Link
                    data-pendo-key="DashboardScheduleListItemDropdownChangeSupervisor"
                    onClick={duplicatePosition}
                  >
                    Duplicate Position
                  </Link>
                </Can>
                <Can I="delete" a="position">
                  {can_archive ? (
                    <Link onClick={handleArchivePositionClick}>{archiveText}</Link>
                  ) : (
                    <StyledDisabledLink>{archiveText}</StyledDisabledLink>
                  )}
                </Can>
              </DropDownMenu>
            </div>
          </ActionsContainer>
        </RightContainer>
      </StyledHeadingContentContainer>
    </StyledPositionHeader>
  );
};

export default PositionBoxHeader;
