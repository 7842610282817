import { colorTokens } from "@bluecrew/blueprint-web/src/theme/colorTokens";
import React from "react";
import { PartialRequestFailBodyProps } from "./types";
import {
  FlexDiv,
  ModalBodyWrapper,
  ModalBoldText,
  ModalHeader,
  ModalHeaderWrapper,
  ModalText,
  Spacer,
  WarningModalIconWrapper,
} from "./styledComponents";
import { AlertIcon } from "./AlertIcon";

export const PartialRequestFailBody = ({
  failedBulkSingleShifts,
  successfulBulkSingleShifts,
}: PartialRequestFailBodyProps) => {
  const successfulShiftsCreatedCount = successfulBulkSingleShifts.reduce(
    (acc, obj) => acc + obj.shifts,
    0,
  );

  const sortedFailedBulkSingleShifts = [...failedBulkSingleShifts].sort(
    (a, b) => a.momentDate.valueOf() - b.momentDate.valueOf(),
  );

  return (
    <ModalBodyWrapper>
      <WarningModalIconWrapper>
        <AlertIcon color={colorTokens.orange400} height={33} width={34} />
      </WarningModalIconWrapper>
      <ModalHeaderWrapper>
        <ModalHeader>Your request was partially successful</ModalHeader>
      </ModalHeaderWrapper>
      <ModalText>
        {`We successfully created ${successfulShiftsCreatedCount} shifts, but there was an error creating some
        of the shifts in your request`}
      </ModalText>
      <Spacer />
      {sortedFailedBulkSingleShifts.map((failedShift) => (
        <FlexDiv key={failedShift.formattedDate}>
          <ModalBoldText>{failedShift.formattedDate}&nbsp;</ModalBoldText>
          <ModalText>{failedShift.invitee}</ModalText>
        </FlexDiv>
      ))}
      <Spacer />
      <ModalText>Please resubmit the request for these shifts.</ModalText>
    </ModalBodyWrapper>
  );
};
