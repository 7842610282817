import { SagaIterator } from "redux-saga";
import { Effect, call, put, takeLatest } from "redux-saga/effects";
import * as scheduleAPI from "../../api/bluecrew/schedule";
import scheduleActions, {
  fetchSchedule,
  OpenApplicationJobsToPlatformAction,
  ReadSchedExcReqAction,
} from "../actions/schedule";
import positionActions from "../actions/position";
import confirmationModalActions from "../actions/confirmationModal";
import { SimpleModalTypes } from "../../components/SimpleModal";
import { Modals } from "../../../shared/constants";
import { ScheduleStatus, UpdateScheduleRequest } from "../../api/bluecrew/types";

function* readScheduleWorkersSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(scheduleAPI.readScheduleWorkers, action.payload);

    yield put(scheduleActions.readScheduleWorkers.success(response));
  } catch (error) {
    yield put(scheduleActions.readScheduleWorkers.failure(error));
  }
}

function* readScheduleExceptionsSaga(action: ReadSchedExcReqAction): Generator<Effect, void, any> {
  try {
    const response = yield call(scheduleAPI.readScheduleExceptions, action.payload);

    yield put(scheduleActions.readScheduleExceptions.success(response));
  } catch (error) {
    yield put(scheduleActions.readScheduleExceptions.failure(error));
  }
}

function* createScheduleExceptionSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(scheduleAPI.createScheduleException, action.payload);

    yield put(scheduleActions.createScheduleException.success(response));
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.SUCCESS,
        contentType: Modals.MODIFY_TIME,
        redirectPath: null,
      }),
    );
    // Refresh the schedule.
    // @ts-ignore
    yield put(fetchSchedule(action.payload.scheduleId));
    // Refresh exceptions.
    yield put(
      scheduleActions.readScheduleExceptions.request({
        scheduleId: action.payload.scheduleId,
      }),
    );
    // Refresh workers on the schedule.
    yield put(
      scheduleActions.readScheduleWorkers.request({
        scheduleId: action.payload.scheduleId,
      }),
    );
  } catch (error) {
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.FAILURE,
        contentType: Modals.MODIFY_TIME,
        redirectPath: null,
      }),
    );
    yield put(scheduleActions.createScheduleException.failure(error));
  }
}

function* updateScheduleSaga(action: {
  payload: UpdateScheduleRequest;
}): Generator<Effect, void, any> {
  const isUpdatingScheduleActive = action.payload.payload.active !== undefined;

  try {
    const response = yield call(scheduleAPI.updateSchedule, action.payload);

    yield put(scheduleActions.updateSchedule.success(response));

    if (isUpdatingScheduleActive) {
      if (action.payload.positionId) {
        yield put(positionActions.updateSinglePropertySchedule(action.payload));
      } else {
        yield put(positionActions.positions.request());

        yield put(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.SUCCESS,
            contentType: Modals.ARCHIVE_SCHEDULE,
            redirectPath: isUpdatingScheduleActive ? "/positions" : null,
          }),
        );
      }
    }
  } catch (error) {
    yield put(scheduleActions.updateSchedule.failure(error));

    if (isUpdatingScheduleActive) {
      let modal;
      if (action.payload.payload.active === ScheduleStatus.ARCHIVED) {
        modal = Modals.ARCHIVE_SCHEDULE;
      } else {
        let undoActive; // revert pin change
        if (action.payload.payload.active === ScheduleStatus.ACTIVE) {
          modal = Modals.UNPIN_SCHEDULE;
          undoActive = ScheduleStatus.PINNED;
        } else {
          modal = Modals.PIN_SCHEDULE;
          undoActive = ScheduleStatus.ACTIVE;
        }

        yield put(
          positionActions.updateSinglePropertySchedule({
            ...action.payload,
            payload: {
              ...action.payload.payload,
              active: undoActive,
            },
          }),
        );
      }
      yield put(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.FAILURE,
          contentType: modal,
          redirectPath: null,
        }),
      );
    }
  }
}

function* openApplicationJobsToPlatformSaga(
  action: OpenApplicationJobsToPlatformAction,
): Generator<Effect, void, any> {
  try {
    const response = yield call(scheduleAPI.openApplicationJobsToPlatform, action.payload);

    yield put(scheduleActions.openApplicationJobsToPlatform.success(response));
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.SUCCESS,
        contentType: Modals.OPEN_APPLICATION_JOBS_TO_PLATFORM,
        redirectPath: `/schedules/${action.payload.scheduleId}/`,
      }),
    );
  } catch (error) {
    yield put(scheduleActions.openApplicationJobsToPlatform.failure(error));
    yield put(
      confirmationModalActions.openModal({
        responseType: SimpleModalTypes.FAILURE,
        contentType: Modals.OPEN_APPLICATION_JOBS_TO_PLATFORM,
        redirectPath: null,
      }),
    );
  }
}

export default {
  *watchReadScheduleWorkersSaga(): SagaIterator {
    yield takeLatest(scheduleActions.readScheduleWorkers.request, readScheduleWorkersSaga);
  },
  *watchReadScheduleExceptionsSaga(): SagaIterator {
    yield takeLatest(scheduleActions.readScheduleExceptions.request, readScheduleExceptionsSaga);
  },
  *watchCreateScheduleExceptionSaga(): SagaIterator {
    yield takeLatest(scheduleActions.createScheduleException.request, createScheduleExceptionSaga);
  },
  *updateScheduleSaga(): SagaIterator {
    yield takeLatest(scheduleActions.updateSchedule.request, updateScheduleSaga);
  },
  *openApplicationJobsToPlatformSaga(): SagaIterator {
    yield takeLatest(
      scheduleActions.openApplicationJobsToPlatform.request,
      openApplicationJobsToPlatformSaga,
    );
  },
};
