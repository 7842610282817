import {addWeeks, startOfWeek} from "date-fns";
import { webV1Url } from "@bluecrew/web-react-core/src/constants";
import React, { useEffect, useState } from "react";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME } from "../../../../shared/constants";
import { getTreatmentValue } from "../../../redux/selectors/splitio";
import { PayrollReportsPageHeader } from "./PayrollReportsPageHeader";
import { Container } from "../styledComponents";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { PayrollReportsTable } from "./PayrollReportsTable";
import { useGetPayrollReportsQuery } from "../../../api/bluecrew/hooks/payroll";
import {CompaniesResponse, getPayrollWeek} from "../../../api/bluecrew/payroll";
import { useGetAllCompaniesV1 } from "../../../api/bluecrew/hooks/company";
import { mergeCompaniesData, processCompaniesResponse } from "../payrollDataProcessors";
import { useAppSelector } from "../../../redux";
import { getRetroMessageText } from "./utils";
import { PayrollReportsPageBanner } from "./PayrollReportsPageBanner";
import { SimpleModal, SimpleModalTypes } from "../../../components/SimpleModal";

export type PayrollReportsProps = {};

// eslint-disable-next-line no-empty-pattern
export const PayrollReports = ({}: PayrollReportsProps) => {
  const showTimesheetsInReact = useAppSelector((state) =>
    getTreatmentValue(state, TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME),
  );
  const [payrollWeek, setPayrollWeek] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  );
  const [filterText, setFilterText] = useState("");
  const payrollReportsQuery = useGetPayrollReportsQuery(payrollWeek);
  const retroPayrollReportsQuery = useGetPayrollReportsQuery(addWeeks(payrollWeek, -1))
  const companiesQuery = useGetAllCompaniesV1<Array<CompaniesResponse>>(processCompaniesResponse);

  useEffect(() => {
    // Redirect to the old timesheets page (web-angular) if timesheets split isn't active
    if (showTimesheetsInReact === "off") {
      window.location.href = `${webV1Url}app.html#!/payroll/company`;
    }
  }, [showTimesheetsInReact]);

  return (
    <Container>
      {retroPayrollReportsQuery.isSuccess && (
        <PayrollReportsPageBanner messageContent={
          getRetroMessageText(getPayrollWeek(addWeeks(payrollWeek, -1)), retroPayrollReportsQuery.data)
        } />
      )}
      <SimpleModal
        headingText={"Error"}
        bodyText={"Something went wrong, please try again."}
        styleType={SimpleModalTypes.ERROR}
        visible={payrollReportsQuery.isError || companiesQuery.isError}
        toggle={() => {}}
      />
      <PayrollReportsPageHeader setFilterText={setFilterText} payrollWeek={payrollWeek} setPayrollWeek={setPayrollWeek} />
      {(payrollReportsQuery.isLoading || companiesQuery.isLoading) && <SpinnerWithOverlay />}
      {payrollReportsQuery.isSuccess && companiesQuery.isSuccess && (
        <PayrollReportsTable
          data={mergeCompaniesData(companiesQuery.data, payrollReportsQuery.data)}
          filter={filterText}
          payrollWeek={payrollWeek}
        />
      )}
      <Spacer $type={SpacerType.vertical} $size={"80px"} />
    </Container>
  );
};
