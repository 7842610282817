import styled from "styled-components";

export const StyledEmptyStateContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const StyledCrewContainer = styled.div`
  padding: 15px 32px 15px 32px;
`;

export const StyledTabContainer = styled.div`
  .first {
    margin-left: 32px;
  }
`;
