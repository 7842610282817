import * as React from "react";
import { useState } from "react";
import { compareFillStatus, convertSecondsToMillis, sortByDate } from "../SharedFunctions";
import { DashboardPositionJobTypes } from "../../../api/bluecrew/types";

interface SubRowComponentProps {
  row: any;
  rowProps: any;
  sortBy: any;
  openModal: Function;
  captureDrag?: boolean;
}

const SubRowComponent = ({
  row,
  rowProps,
  sortBy,
  openModal,
  captureDrag,
}: SubRowComponentProps) => {
  const [windowXOnClickStart, setWindowXOnClickStart] = useState<number>(1);
  const data = row.original.jobs;
  let sortedData;
  if (sortBy.length > 0) {
    sortedData = [...data].sort((a, b) => {
      // We only care about 1 type sorting
      switch (sortBy[0].id) {
        case "name":
          if (row.original.type === DashboardPositionJobTypes.bulkSingleShift) {
            return sortBy[0].desc
              ? b.schedule_name.localeCompare(a.schedule_name)
              : a.schedule_name.localeCompare(b.schedule_name);
          }
          return sortBy[0].desc ? b.job_id - a.job_id : a.job_id - b.job_id;
        case "fill-status":
          return compareFillStatus(a.filled, b.filled, a.needed, b.needed, sortBy[0].desc);
        case "schedule-time":
          const timeA = new Date(convertSecondsToMillis(a.start_date_time)).getHours();
          const timeB = new Date(convertSecondsToMillis(b.start_date_time)).getHours();
          return sortBy[0].desc ? timeB - timeA : timeA - timeB;
        case "wage":
          return sortBy[0].desc ? b.base_wage - a.base_wage : a.base_wage - b.base_wage;
        default:
          return sortByDate(a.start_date_time, b.start_date_time, sortBy[0].desc);
      }
    });
  } else {
    sortedData = [...data].sort((a, b) => sortByDate(a.start_date_time, b.start_date_time, true));
  }

  const clickOrDrag = (e: React.MouseEvent, cellColumnId: string, job: any) => {
    // stop request modal from opening on drag.
    const mouseUp = e.clientX;
    const PIXEL_CHANGE_TO_INDICATE_DRAG = 8;
    if (
      mouseUp < windowXOnClickStart + PIXEL_CHANGE_TO_INDICATE_DRAG &&
      mouseUp > windowXOnClickStart - PIXEL_CHANGE_TO_INDICATE_DRAG
    ) {
      canOpenModal(cellColumnId, job);
    }
  };

  const canOpenModal = (cellColumnId: string, job: any) => {
    if (cellColumnId !== "actionButtons" && cellColumnId !== "alertIcon") {
      openModal(job);
    }
  };

  const mouseDownCoords = (e) => {
    setWindowXOnClickStart(e.clientX);
  };

  let dragHandlers = {};

  return (
    <React.Fragment key={`${rowProps.key}-expanded-${row.original.schedule_id}`}>
      {sortedData.map((job, i) => (
        <tr
          {...rowProps}
          key={`${rowProps.key}-expanded-${i}-${job.job_id}`}
          className={"sub-level-row"}
          data-pendo-key="ScheduleRequestTableSubLevelRow"
        >
          {row.cells.map((cell) => {
            const cellKey = cell.getCellProps().key;
            if (captureDrag) {
              dragHandlers = {
                onMouseDown: (e) => mouseDownCoords(e),
                onMouseUp: (e) => clickOrDrag(e, cell.column.id, job),
              };
            } else {
              dragHandlers = {
                onClick: () => canOpenModal(cell.column.id, job),
              };
            }
            return (
              <td
                {...dragHandlers}
                key={`${rowProps.key}-expanded-${i}-${job.job_id}-${cellKey}`}
                {...cell.getCellProps()}
              >
                {cell.render("Cell", {
                  rowData: {
                    ...job,
                    isSchedule: false,
                  },
                  row: {
                    ...row,
                    canExtend: false,
                  },
                })}
              </td>
            );
          })}
        </tr>
      ))}
    </React.Fragment>
  );
};

export default SubRowComponent;
