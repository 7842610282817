import React from "react";
import {
  ShiftAdjustTimeActionButton,
  ShiftAdjustTimeActionsContainer,
  StyledTimeActionIcon,
} from "./styledComponents";

export const ShiftAdjustTimeActionsContainerClassName = "shift-adjust-time-actions-container";

export enum ShiftAdjustTimeAction {
  CONFIRM,
  EDIT,
  DELETE,
}

const IconPrefix = `${import.meta.env.VITE_ASSET_BASE_URL}/icons/Timesheets/AdjustShift`;

type ShiftAdjustTimeActionMeta = {
  canTakeAction: boolean;
  actionHandler: () => void;
};

export type ShiftAdjustTimeActionsProps = {
  isEditing: boolean;
  isDeleteEnabled: boolean;
  actionMap: Record<ShiftAdjustTimeAction, ShiftAdjustTimeActionMeta>;
};
export const ShiftAdjustTimeActions = ({
  isEditing,
  isDeleteEnabled,
  actionMap,
}: ShiftAdjustTimeActionsProps) => {
  const ActionIconMap = {
    [ShiftAdjustTimeAction.CONFIRM]: (
      <StyledTimeActionIcon src={`${IconPrefix}/confirm-time.svg`} />
    ),
    [ShiftAdjustTimeAction.EDIT]: <StyledTimeActionIcon src={`${IconPrefix}/edit-time.svg`} />,
    [ShiftAdjustTimeAction.DELETE]: <StyledTimeActionIcon src={`${IconPrefix}/delete-time.svg`} />,
  };

  return (
    <ShiftAdjustTimeActionsContainer
      className={ShiftAdjustTimeActionsContainerClassName}
      paddingTop={isEditing ? 24 : 0}
    >
      {isDeleteEnabled && (
        <ShiftAdjustTimeActionButton
          disabled={!actionMap[ShiftAdjustTimeAction.DELETE].canTakeAction}
          icon={ActionIconMap[ShiftAdjustTimeAction.DELETE]}
          onClick={actionMap[ShiftAdjustTimeAction.DELETE].actionHandler}
          rounded
          text
        />
      )}

      {isEditing ? (
        <ShiftAdjustTimeActionButton
          disabled={!actionMap[ShiftAdjustTimeAction.CONFIRM].canTakeAction}
          icon={ActionIconMap[ShiftAdjustTimeAction.CONFIRM]}
          onClick={actionMap[ShiftAdjustTimeAction.CONFIRM].actionHandler}
          rounded
          text
        />
      ) : (
        <ShiftAdjustTimeActionButton
          disabled={!actionMap[ShiftAdjustTimeAction.EDIT].canTakeAction}
          icon={ActionIconMap[ShiftAdjustTimeAction.EDIT]}
          onClick={actionMap[ShiftAdjustTimeAction.EDIT].actionHandler}
          rounded
          text
        />
      )}
    </ShiftAdjustTimeActionsContainer>
  );
};
