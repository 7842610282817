import React, { useContext, useState } from "react";
import { Button, H3Heading } from "@bluecrew/web-react-core";
import { KIND } from "baseui/toast";
import { useDispatch } from "react-redux";
import { RadioList, RadioOption } from "../../../components/RadioList";
import {
  backgroundCheckStrings,
  UpdateCompanySettingsFailureMessage,
  UpdateCompanySettingsSuccessMessage,
} from "../CompanySettingStringsEN";
import { getCompanyName } from "../../../redux/selectors/company";
import { useAppSelector } from "../../../redux";
import { ButtonWrapper, SettingsCard } from "../styledComponents";
import { AbilityContext } from "../../PermissionsContext";
import {
  AdvancedBackgroundCheckTypes,
  CompanyInfo,
  SettingStates,
} from "../../../api/bluecrew/types";
import { ConfirmDisableSettingsModal } from "../../../components/BgCheckDrugScreeningModal";
import { useUpdateCompanyDetailsMutation } from "../hooks";
import { showToastAction } from "../../../redux/actions/app";

export const advancedBackgroundCheckEnabledTestId = "advancedBackgroundCheckEnabled";
export const advancedBackgroundCheckDisabledTestId = "advancedBackgroundCheckDisabled";

type AdvancedBackgroundCheckProps = {
  initialBackgroundCheck: number;
  backgroundCheckActive: boolean;
};

export const AdvancedBackgroundCheck = ({
  initialBackgroundCheck,
  backgroundCheckActive,
}: AdvancedBackgroundCheckProps) => {
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch();
  const companyName = useAppSelector(getCompanyName);
  const updateCompanyDetailsMutation = useUpdateCompanyDetailsMutation();
  const canUpdateAdvancedBackgroundCheck = ability.can("update", "covid_policy"); // TODO: Is a placeholder. Replace with correct 'ability'/permission
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Which radio button is selected on render.
  const [selectedOption, setSelectedOption] = useState<number>(
    backgroundCheckActive ? SettingStates.ENABLED : SettingStates.DISABLED,
  );

  // Text for 'Enabled' changes depending on 1. the company name and 2. the value of `background_check_required`
  const backgroundCheckOptions = backgroundCheckStrings(companyName, initialBackgroundCheck);
  const { enabled, disabled } = backgroundCheckOptions.options;
  const options: Array<RadioOption> = [
    {
      label: enabled.label,
      text: enabled.text,
      value: enabled.value,
      testId: advancedBackgroundCheckEnabledTestId,
    },
    {
      label: disabled.label,
      text: disabled.text,
      value: disabled.value,
      testId: advancedBackgroundCheckDisabledTestId,
    },
  ];

  const updateCompanySettings = (companyInfo: CompanyInfo) => {
    updateCompanyDetailsMutation.mutate(companyInfo, {
      onSuccess: () => {
        dispatch(showToastAction(UpdateCompanySettingsSuccessMessage, KIND.positive));
      },
      onError: () => {
        dispatch(showToastAction(UpdateCompanySettingsFailureMessage, KIND.negative));
      },
    });
  };

  // Handles opening the modal
  const handleClick = (newValue: AdvancedBackgroundCheckTypes) => {
    if (newValue === AdvancedBackgroundCheckTypes.DISABLED) {
      setIsModalOpen(true);
    } else {
      handleUpdateClick();
    }
  };

  const handleUpdateClick = () => {
    const backgroundCheckIsActive =
      options[selectedOption].value !== AdvancedBackgroundCheckTypes.DISABLED;
    const companyInfo: CompanyInfo = {
      background_check_active: backgroundCheckIsActive,
    };
    updateCompanySettings(companyInfo);
    setIsModalOpen(false);
  };

  return (
    <SettingsCard>
      <H3Heading>{backgroundCheckOptions.title}</H3Heading>
      {isModalOpen && (
        <ConfirmDisableSettingsModal
          settingName={backgroundCheckOptions.title.toLowerCase()}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleUpdateClick={handleUpdateClick}
        />
      )}
      <RadioList
        options={options}
        selected={selectedOption}
        setSelected={setSelectedOption}
        disabled={!canUpdateAdvancedBackgroundCheck}
      />
      <ButtonWrapper>
        <Button
          palette="primary"
          onClick={() => handleClick(options[selectedOption].value)}
          disabled={!canUpdateAdvancedBackgroundCheck}
        >
          {backgroundCheckOptions.labels.updateButtonText}
        </Button>
      </ButtonWrapper>
    </SettingsCard>
  );
};
