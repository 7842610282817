import React from "react";
import { FlexCenterRow, SeeMoreRequestsContainer } from "../../SharedComponents";
import {
  DashboardPositionBulkSingleShifts,
  DashboardPositionJob,
  DashboardPositionJobTypes,
  DashboardPositionRepeatingSchedule,
  ScheduleAndIndividualShift,
} from "../../../../api/bluecrew/types";
import { SeeMoreRequestsButton } from "../../SeeMoreRequestsButton";
import { DashboardScheduleNameCell } from "./DashboardScheduleNameCell";
import { DashboardScheduleRequestCell } from "./DashboardScheduleRequestCell";
import { DashboardScheduleRequestNameCell } from "./DashboardScheduleRequestNameCell";

interface ScheduleNameCellProps {
  row: any;
  rowData: ScheduleAndIndividualShift;
  isExpanded: boolean;
  openRequestModal: (job_id: number) => void;
}

export const NameCell = ({ row, rowData, isExpanded, openRequestModal }: ScheduleNameCellProps) => {
  switch (rowData.type) {
    case DashboardPositionJobTypes.bulkSingleShift:
      return (
        <FlexCenterRow {...row.getToggleRowExpandedProps()}>
          <DashboardScheduleRequestNameCell
            rowData={rowData as DashboardPositionBulkSingleShifts}
          />
          <SeeMoreRequestsContainer>
            <SeeMoreRequestsButton isExpanded={isExpanded} />
          </SeeMoreRequestsContainer>
        </FlexCenterRow>
      );
    case DashboardPositionJobTypes.repeatingSchedule:
      return (
        <FlexCenterRow>
          <DashboardScheduleNameCell
            rowData={rowData as DashboardPositionRepeatingSchedule}
            isWidthSet
            isBigFont
          />
          <SeeMoreRequestsContainer {...row.getToggleRowExpandedProps()}>
            <SeeMoreRequestsButton isExpanded={isExpanded} />
          </SeeMoreRequestsContainer>
        </FlexCenterRow>
      );
    case DashboardPositionJobTypes.bulkSingleShiftSubRow:
      return (
        <DashboardScheduleNameCell
          rowData={rowData as DashboardPositionRepeatingSchedule}
          isSubRow
        />
      );
    case DashboardPositionJobTypes.repeatingScheduleSubRow:
      return (
        <DashboardScheduleRequestCell
          rowData={rowData as DashboardPositionJob}
          isSubRow
          openRequestModal={openRequestModal}
        />
      );
    case DashboardPositionJobTypes.singleShift:
    default:
      return <DashboardScheduleNameCell rowData={rowData as DashboardPositionJob} />;
  }
};
