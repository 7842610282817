import React from "react";
import { useForm } from "react-hook-form";
import { UsernameField } from "../FormFields/UsernameField";
import { Prettify } from "../../../../types/util-types";
import { CommonFormProps } from "./types";
import { StyledButton, StyledLoginFlowForm } from "../styledComponents";
import { loginStrings } from "../strings";
import { BCButtonVariants } from "../../Button";

const FieldNames = {
  Username: "username",
} as const;

const defaultValues = {
  [FieldNames.Username]: "",
};
type FormValueProps = typeof defaultValues;

export function RequestPasswordResetForm({
  requestPasswordReset,
  requestResetLoading,
  footer,
  children,
  onAnyFieldChange,
}: {
  requestPasswordReset: (username: string) => void;
  requestResetLoading: boolean;
} & Prettify<CommonFormProps>) {
  const onSubmit = ({ username }: FormValueProps) => {
    requestPasswordReset(username);
  };

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const usernameError = formErrors[FieldNames.Username]?.message;

  const { enterEmailLabel, emailRequired } = loginStrings.login;
  const { submitLabel, submittingLabel } = loginStrings.resetPassword;

  return (
    <StyledLoginFlowForm onSubmit={handleSubmit(onSubmit)}>
      <div>{loginStrings.resetPassword.enterEmailBlurb}</div>
      <div style={{ display: "grid", gap: "12px" }}>
        <UsernameField
          fieldName={FieldNames.Username}
          fieldLabel={enterEmailLabel}
          rules={{ required: emailRequired }}
          control={control}
          fieldError={usernameError}
          onChange={onAnyFieldChange}
        />
        {children}
        <StyledButton
          variant={BCButtonVariants.Primary}
          label={requestResetLoading ? submittingLabel : submitLabel}
          type="submit"
        />
        {footer}
      </div>
    </StyledLoginFlowForm>
  );
}
