import React from "react";
import { PasswordRequirement } from "./PasswordRequirement";
import { PasswordsMustMatchString, passwordReqs } from "./constants";

export interface CompanyPasswordRequirementsProps {
  password: string;
  confirmPassword: string;
}

export const CompanyPasswordRequirements = ({
  password,
  confirmPassword,
}: CompanyPasswordRequirementsProps) => {
  const displayReqs = passwordReqs.map(({ reqTest, reqText }) => (
    <PasswordRequirement key={reqText} text={reqText} reqMet={reqTest(password)} />
  ));

  const passwordsMatch = password === confirmPassword && password !== "";
  return (
    <div style={{ display: "grid", gap: "8px" }}>
      {displayReqs}
      <PasswordRequirement text={PasswordsMustMatchString} reqMet={passwordsMatch} />
    </div>
  );
};
