import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { StateShape } from "../../../redux/reducers";
import withLoading from "../../../components/withLoading";
import { getUserCompany } from "../../../redux/selectors/auth";
import { getIsSplitioReady } from "../../../redux/selectors/splitio";
import jobActions from "../../../redux/actions/job";
import { getQueryParam } from "../../../utility";
import ApplicationsToReviewBanner from "../ApplicationsToReviewBanner";
import { ViewContainer, ViewContent } from "./styledComponents";
import { PositionsList } from "./PositionsList";
import { PositionBoxes } from "./PositionBoxes";
import {
  INITIAL_DASHBOARD_POSITIONS,
  useGetDashboardPositionsQuery,
} from "../../../api/bluecrew/hooks/dashboard";
import { useGetJobTypesQuery } from "../../../api/bluecrew/hooks/job";
import {
  useArchivePositionMutation,
  useUpdateContinuousFillMutation,
} from "../../../api/bluecrew/hooks/position";
// eslint-disable-next-line import/no-cycle
import { PositionsHeader } from "./PositionsHeader";
import { sortDashboardPositionCreatedDate, sortDashboardPositionTitle } from "./helperFunctions";
import { getIsLoggedIn } from "../../../utility/auth";

export enum PositionsSortOptions {
  SORT_BY_POSITION_TITLE_ASCENDING = "Position name (a-z)",
  SORT_BY_POSITION_TITLE_DESCENDING = "Position name (z-a)",
  SORT_BY_POSITION_CREATED_DATE_ASCENDING = "Date created (newest first)",
  SORT_BY_POSITION_CREATED_DATE_DESCENDING = "Date created (oldest first)",
}

// HOC that renders the Positions List or a Spinner if data from endpoint hasn't successfully returned yet
const PositionsListWithLoading = withLoading(PositionsList);

const mapDispatchToProps = (dispatch: any) => ({
  saveDirectInviteUserIds: (userIds: Array<string>) =>
    dispatch(jobActions.saveDirectInviteUserIds({ userIds })),
  saveCrewForHireIds: (crewIds: Array<number>) =>
    dispatch(jobActions.saveCrewForHireIds({ crewIds })),
});

const mapStateToProps = (state: StateShape) => ({
  isSplitioReady: getIsSplitioReady(state),
  userCompanyId: getUserCompany(state),
});

type PositionProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Positions = ({
  saveDirectInviteUserIds,
  saveCrewForHireIds,
  isSplitioReady,
  userCompanyId,
}: PositionProps) => {
  const loggedIn = getIsLoggedIn();

  if (!loggedIn) return null;

  const { data: jobTypes, isLoading: isFetchingJobTypes } = useGetJobTypesQuery();
  const { data: dashboardPositions, isLoading: isFetchingDashboardPositions } =
    useGetDashboardPositionsQuery(userCompanyId);
  const { mutate: handleArchivePosition, isLoading: isArchivePositionLoading } =
    useArchivePositionMutation();
  const { mutate: updateContinuousFill, isLoading: isUpdatingContinuousFill } =
    useUpdateContinuousFillMutation();

  const isProcessingAction = isUpdatingContinuousFill || isArchivePositionLoading;

  const [positionNameSearchInput, setPositionNameSearchInput] = useState("");
  const [positionSortOption, setPositionSortOption] = useState(
    PositionsSortOptions.SORT_BY_POSITION_TITLE_ASCENDING,
  );

  useEffect(() => {
    // Regex that matches this param. "A" is a Anchors that Matches at the start of the string the regex pattern is applied to.
    // https://www.regular-expressions.info/refanchors.html
    if (window.location.search.match(/A?directInviteUserIds=/)) {
      const directInviteParams = getQueryParam(window.location, "directInviteUserIds");
      try {
        if (!directInviteParams) {
          throw new Error("DirectInviteParams is null");
        }
        const userIds = JSON.parse(directInviteParams);
        saveDirectInviteUserIds(userIds);
        // Clean the URL up. Take the query string out of the URL
        window.history.replaceState({}, "", "post");
      } catch (error) {
        console.error(`Invalid direct invite user ids: ${directInviteParams || "null"}`, error);
      }
    }

    if (window.location.search.match(/A?crewForHireIds=/)) {
      const crewForHireParams = getQueryParam(window.location, "crewForHireIds");
      try {
        if (!crewForHireParams) {
          throw new Error("crewForHireIds is null");
        }
        const crewIds = JSON.parse(crewForHireParams);
        saveCrewForHireIds(crewIds);
        // Clean the URL up. Take the query string out of the URL
        window.history.replaceState({}, "", "post");
      } catch (error) {
        console.error(`Invalid crew ids: ${crewForHireParams || "null"}`, error);
      }
    }
  }, []);

  const sortedDashboardPositions = [...(dashboardPositions ?? INITIAL_DASHBOARD_POSITIONS)].sort(
    (a, b) => {
      switch (positionSortOption) {
        case PositionsSortOptions.SORT_BY_POSITION_TITLE_DESCENDING:
          return sortDashboardPositionTitle(a, b, false);
          break;
        case PositionsSortOptions.SORT_BY_POSITION_CREATED_DATE_ASCENDING:
          return sortDashboardPositionCreatedDate(a, b, false);
          break;
        case PositionsSortOptions.SORT_BY_POSITION_CREATED_DATE_DESCENDING:
          return sortDashboardPositionCreatedDate(a, b, true);
          break;
        case PositionsSortOptions.SORT_BY_POSITION_TITLE_ASCENDING:
        default:
          return sortDashboardPositionTitle(a, b, true);
          break;
      }
    },
  );

  return (
    <ViewContainer>
      <ViewContent>
        <ApplicationsToReviewBanner companyId={userCompanyId} />
        <PositionsListWithLoading
          isFetching={
            isFetchingDashboardPositions || // Since we are using split to show/hide the dashboard,
            // don't render anything until it's ready. Otherwise,
            // there could be a flicker.
            !isSplitioReady ||
            isFetchingJobTypes
          }
          sortedPositions={sortedDashboardPositions}
        >
          <PositionsHeader
            positionNameSearchInput={positionNameSearchInput}
            setPositionNameSearchInput={setPositionNameSearchInput}
            positionSortOption={positionSortOption}
            setPositionSortOption={setPositionSortOption}
          />
          <PositionBoxes
            sortedDashboardPositions={sortedDashboardPositions}
            jobTypes={jobTypes ?? []}
            updateContinuousFill={updateContinuousFill}
            handleArchivePosition={handleArchivePosition}
            positionNameSearchInput={positionNameSearchInput}
          />
        </PositionsListWithLoading>
        {isProcessingAction && <SpinnerWithOverlay />}
      </ViewContent>
    </ViewContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Positions);
