import React, { useEffect } from "react";
import { useController } from "react-hook-form";
import { isOffSiteJob } from "./JobType";
import { PositionAdvancedDetailsSectionProps } from "../../types/propTypes/PositionAdvancedDetailsSection.types";
import { SiteDesignation } from "../../types/propTypes/CreatePositionScreen.types";
import { WorksiteAdvancedControl } from "../../PositionDetailsEditScreen/WorksiteAdvancedControl";

export const CreatePositionGeofenceSection = ({
  control,
  trigger,
  skillId,
  workplaceLocation,
  minOffsiteWarnRadius,
  minOffsiteBlockRadius,
  minOnsiteWarnRadius,
  minOnsiteBlockRadius,
}: PositionAdvancedDetailsSectionProps) => {
  const {
    field: { onChange: onChangeSiteDesignation },
  } = useController({
    name: "siteDesignation",
    control,
    rules: {
      required: true,
    },
  });

  useEffect(() => {
    onChangeSiteDesignation(
      isOffSiteJob(skillId) ? SiteDesignation.OFFSITE : SiteDesignation.ONSITE,
    );
  }, [skillId]);

  return (
    <WorksiteAdvancedControl
      control={control}
      trigger={trigger}
      workplaceLocation={workplaceLocation}
      defaultWarnRadius={isOffSiteJob(skillId) ? minOffsiteWarnRadius : minOnsiteWarnRadius}
      defaultBlockRadius={isOffSiteJob(skillId) ? minOffsiteBlockRadius : minOnsiteBlockRadius}
    />
  );
};
