import { ButtonGroup, SIZE } from 'baseui/button-group';
import React from 'react';
import { Button } from 'baseui/button';
import { getPadding } from '../helpers/styleHelpers';
import { useThemedStyletron } from '../theme';
import { translations } from '../translations';

export type DayOfWeek = '0' | '1' | '2' | '3' | '4' | '5' | '6';
export type DaysOfWeek = DayOfWeek[];

interface WeekdaysProps {
  selected?: DaysOfWeek;
  onChange?: (daysOfWeek: DaysOfWeek) => void;
  size?: SIZE[keyof SIZE];
  editable?: boolean;
}

export const Weekdays = ({
  selected = [],
  onChange,
  size = SIZE.default,
  editable = true,
}: WeekdaysProps) => {
  const daysOfWeek: {
    day: DayOfWeek;
    label: string;
    ariaLabel: string;
  }[] = [
    {
      day: '0',
      label: translations.Weekdays.sunday.short,
      ariaLabel: translations.Weekdays.sunday.full,
    },
    {
      day: '1',
      label: translations.Weekdays.monday.short,
      ariaLabel: translations.Weekdays.monday.full,
    },
    {
      day: '2',
      label: translations.Weekdays.tuesday.short,
      ariaLabel: translations.Weekdays.tuesday.full,
    },
    {
      day: '3',
      label: translations.Weekdays.wednesday.short,
      ariaLabel: translations.Weekdays.wednesday.full,
    },
    {
      day: '4',
      label: translations.Weekdays.thursday.short,
      ariaLabel: translations.Weekdays.thursday.full,
    },
    {
      day: '5',
      label: translations.Weekdays.friday.short,
      ariaLabel: translations.Weekdays.friday.full,
    },
    {
      day: '6',
      label: translations.Weekdays.saturday.short,
      ariaLabel: translations.Weekdays.saturday.full,
    },
  ];

  const [, $theme] = useThemedStyletron();
  const selectedMap = new Map<DayOfWeek, boolean>(
    daysOfWeek.map(({ day }) => [day, selected.includes(day)]),
  );

  const { dimension, font } = {
    [SIZE.mini]: {
      font: $theme.typography.font150,
      dimension: $theme.sizing.scale700,
    },
    [SIZE.compact]: {
      font: $theme.typography.font150,
      dimension: $theme.sizing.scale800,
    },
    [SIZE.default]: {
      font: $theme.typography.font250,
      dimension: $theme.sizing.scale850,
    },
    [SIZE.large]: {
      font: $theme.typography.font350,
      dimension: $theme.sizing.scale950,
    },
  }[size];

  const handleChange = (day: DayOfWeek) => {
    if (!onChange) return;

    selectedMap.set(day, !selectedMap.get(day));

    onChange(
      Array.from(selectedMap.entries())
        .filter(([, isDaySelected]) => !!isDaySelected)
        .map(([selectedDay]) => selectedDay),
    );
  };

  const isSelected = (day: DayOfWeek) => selectedMap.get(day);

  const isNextSelected = (day: DayOfWeek) =>
    !!selectedMap.get((+day + 1).toString() as any);

  const isPrevSelected = (day: DayOfWeek) =>
    !!selectedMap.get((+day - 1).toString() as any);

  return (
    <ButtonGroup
      size={size}
      disabled={!editable}
      overrides={{
        Root: {
          props: {
            'data-testid': `Weekdays-ButtonGroup-${
              editable ? 'Editable' : 'Disabled'
            }`,
          },
        },
      }}
    >
      {daysOfWeek.map(({ day, label, ariaLabel }) => (
        <Button
          key={day}
          overrides={{
            BaseButton: {
              style: {
                width: dimension,
                height: dimension,
                ...getPadding('0'),
                ...font,
                borderTopLeftRadius: isPrevSelected(day) ? 0 : dimension,
                borderBottomLeftRadius: isPrevSelected(day) ? 0 : dimension,
                borderTopRightRadius: isNextSelected(day) ? 0 : dimension,
                borderBottomRightRadius: isNextSelected(day) ? 0 : dimension,
                backgroundColor: isSelected(day)
                  ? $theme.colors.weekdaysFillSelected
                  : $theme.colors.weekdaysFill,
                color: isSelected(day)
                  ? $theme.colors.weekdaysTextSelected
                  : $theme.colors.weekdaysText,
                ':hover': {
                  backgroundColor: 'none',
                },
                ':active': {
                  backgroundColor: 'none',
                },
                ':disabled': {
                  pointerEvents: 'none',
                  backgroundColor: isSelected(day)
                    ? $theme.colors.weekdaysFillSelectedDisabled
                    : $theme.colors.weekdaysFill,
                  color: isSelected(day)
                    ? $theme.colors.weekdaysTextSelectedDisabled
                    : $theme.colors.weekdaysTextDisabled,
                },
              },
            },
          }}
          isSelected={isSelected(day)}
          aria-label={ariaLabel}
          onClick={() => handleChange(day)}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
