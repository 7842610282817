import React from "react";
import { Toast } from "../../components/Toast";
import { ToastContainer } from "./styledComponents";
import { useAppSelector } from "../../redux";

// This toast component can be triggered, "shown" by dispatching an action to redux
// e.g. dispatch(showToastAction("A message!", KIND.positive));
export const AppToast = () => {
  const { visible, date, kind, message } = useAppSelector((state) => state.app.toast);
  return (
    <>
      {visible && (
        <ToastContainer>
          <Toast trigger={date} type={kind}>
            {message}
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};
