export enum FileType {
  CSV = "text/csv",
  XML = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

export type DownloadFileParams = {
  type: FileType;
  blobData: Blob;
  fileName: string;
};

export const downloadFile = ({ type, blobData, fileName }: DownloadFileParams) => {
  const file = new Blob([blobData], { type });
  const fileURL = URL.createObjectURL(file);
  let a = document.createElement("a");
  a.href = fileURL;
  a.target = "_blank";
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
