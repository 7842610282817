import { Wage } from "./HourlyPayField.types";
import { GetCrewMemberScreenData } from "./GetCrewMemberScreen.types";
import { FormFieldTypes as GetCrewFieldTypes } from "./GetCrewMemberForm.types";
import {
  FormFieldTypes as CreatePositionFieldTypes,
  CreatePositionScreenData,
} from "./CreatePositionScreen.types";
import { ReviewOrderExtraProps } from "./ReviewOrderScreen.types";
import { WorksiteAddress } from "./Worksite.types";
import { GetNumShiftsTypes } from "./GetNumShifts.types";
import { Schedule } from "./ScheduleField.types";
import { Position } from "./PositionField.types";

export enum WorkflowSteps {
  GET_CREW,
  REVIEW_ORDER,
  CREATE_POSITION,
}

export interface DefaultValues<T = GetCrewFieldTypes> {
  getCrew: T;
  createPosition: CreatePositionFieldTypes;
}

interface GetCrewMembersEvents<T = GetCrewFieldTypes> {
  onSaveJob?: (data: T) => void;
  onSavePosition?: (data: CreatePositionFieldTypes) => void;
  onBeforeReviewOrder?: (data: T) => Promise<ReviewOrderExtraProps>;
  onLocationSelect?: (address: WorksiteAddress) => Promise<Wage>;
  onGetNumShifts?: (data: GetNumShiftsTypes) => number;
  onGetScheduleInfo?: (data: string | number) => void;
}

export interface GetCrewMembersProps<T = GetCrewFieldTypes> {
  data: GetCrewMemberScreenData & CreatePositionScreenData;
  defaultValues: DefaultValues<T>;
  events?: GetCrewMembersEvents<T>;
  activeStep?: WorkflowSteps;
  isBulkSingleShiftEnabled: boolean;
  isBulkSingleShiftMax50DayEnabled: boolean;
  isSingleAssignmentView?: boolean;
  preselectedSchedule: Schedule | null;
  preselectedPosition: Position | null;
}
