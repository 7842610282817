import React from "react";
import { AlertIcon } from "./AlertIcon";
import {
  FailedModalIconWrapper,
  ModalBodyWrapper,
  ModalHeader,
  ModalHeaderWrapper,
  ModalText,
} from "./styledComponents";

export const RequestFailedForUnknownReasonBody = () => (
  <ModalBodyWrapper>
    <FailedModalIconWrapper>
      <AlertIcon height={33} width={34} />
    </FailedModalIconWrapper>
    <ModalHeaderWrapper>
      <ModalHeader>Request failed</ModalHeader>
    </ModalHeaderWrapper>
    <ModalText>Your request was unsuccessful due to a server error. Please try again.</ModalText>
  </ModalBodyWrapper>
);
