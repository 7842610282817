import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Tab as BaseTab, Tabs as BaseTabs, TabsContainer, TabsPanel } from "@bluecrew/reakit";

export const Tabs = styled(BaseTabs)`
  border-bottom: 1px solid ${p("frost")};
  ${
    // @ts-ignore
    BaseTab
  } {
    font-size: 1rem;
    line-height: 2.4rem;
    height: 4rem;
    color: ${p("platinum")};
    flex: none;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    &.active {
      border-bottom: 2px solid ${p("brandBlue")};
      color: ${p("slate")};
    }
  }
`;

export const Container = styled(TabsContainer)`
  background: #FFF:
`;

export const Tab = styled(BaseTab)`
  font-size: 1rem;
  line-height: 2.4rem;
  height: 4rem;
  color: ${p("platinum")};
  flex: none;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  &.active {
    border-bottom: 2px solid ${p("brandBlue")};
    color: ${p("slate")};
  }
  :hover {
    cursor: pointer;
  }
`;

export const Panel = styled(TabsPanel)`
  margin-top: 25px;
`;
