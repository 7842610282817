const freeze = require("deep-freeze");
//TODO: WP-22 confirm if this is actually ever used, does not seem like it
const userTypes = [
  0, //BLUECREW
  1, //MANAGER
  2, //CLIENT_LEAD
  3, //CLIENT
  4, //CLIENT_ADMIN
  5, //BRANCH_OPS
  6, //BRANCH_VIS
  7, //ADMIN
  8, //PAYROLL_ADMIN
  9, //SUPER_ADMIN
];

const permissionsTable = {
  0: [
    "REVIEW_COMPANY",
    "GET_COMPANY_INFO",
    "UPLOAD_PROFILE_PICTURE",
    "UPLOAD_RESUME",
    "UPLOAD_SIGNATURE",
    "UPLOAD_VOID_CHECK",
    "ACCEPT_JOB",
    "CANCEL_JOB",
    "GET_JOB",
    "GET_JOB_TYPES",
    "VIEW_DIRECT_DEPOSIT",
    "PUT_CLOCK_DATA",
    "ADD_POINTS",
    "ADD_REFERRAL",
    "FETCH_INFO",
    "GET_USER",
    "GET_AVAILABLE_JOBS",
    "GET_CURRENT_JOB",
    "GET_FAVORITED_BY_COMPANIES",
    "GET_USER_INFO",
    "GET_USER_SKILLS",
    "GET_USER_POINTS",
    "GET_POINTS",
    "GET_UPCOMING_JOBS",
    "GET_WHOLE_PROFILE",
    "CHANGE_SETTINGS",
    "ENTER_SIGNUP_INFO",
    "UPDATE_PROFILE",
    "UPDATE_USER",
    "RATE_COMPANIES",
    "SEE_RESUMES",
    "VIEW_PROFILE",
    "GET_PINGS",
    "RUN_BACKGROUND",
    "GET_COMPANY_REVIEWS",
    "RATE_COMPANY_REVIEWS",
    "GET_TIMESTAMPS",
    "SUBMIT_APPLICATION",
    "GET_JOB_STATUS",
    "SUBMIT_JOB_APPEAL",
    "GET_CONTACTS",
    "GET_PIPELINE",
    "GET_PAYSTUB",
    "GET_RANK",
    "SEND_SMS_REFERRAL",
    "GET_QUESTIONS",
  ],
  1: [
    "GET_COMPANY_JOBS",
    "SEE_PROFILE_PICTURES",
    "ADD_NO_SHOW",
    "GET_JOB_SUPERVISING",
    "GET_NOTES",
    "RECORD_NOTE",
    "ADD_FAVORITE",
    "NOT_INVITE_BACK",
    "RATE_WORKERS",
    "EDIT_RATING",
    "VIEW_DASH",
    "VIEW_COMPANY_JOBS",
    "VIEW_MY_WORKFORCE",
    "VIEW_POSTED_JOBS",
    "SEND_GROUP_TEXTS",
    "SEND_TEXT",
    "ENTER_CLIENT_INFO",
    "PUT_OTHER_USER_CLOCK_DATA",
    "HIDE_USERS",
    "ADD_CREWS",
    "GET_CREWS",
    "INVITE_MANAGER_TO_COMPANY",
    "CHANGE_JOB_APPLIED_STATUS",
    "VIEW_ALL_APPLICATIONS",
    "EDIT_EMAIL_PREFERENCES",
    "PUT_ON_JOB",
    "GET_COMPANIES_TAGS",
    "REPOST_JOB",
  ],
  2: ["CREATE_PIPELINE"],
  3: [
    "GET_OTHERS_JOBS",
    "GET_OTHERS_TIMESTAMPS",
    "UPLOAD_COMPANY_LOGO",
    "ADD_SUPERVISOR",
    "GET_SUPERVISORS",
    "CANCEL_JOB_POST",
    "CREATE_JOB",
    "SEND_JOB",
    "SEND_INVITE",
    "CREATE_JOB_TEMPLATES",
    "GET_JOB_TEMPLATES",
    "VIEW_EMPLOYER_SETTINGS",
    "INVITE_TO_COMPANY",
    "ACCESS_EOR",
    "USE_PAYMENTS",
    "EDIT_CLIENT_TYPE",
    "VIEW_TIMESTAMPS",
    "APPROVE_HOURS",
    "REJECT_HOURS",
    "UPDATE_JOB_SETTINGS",
    "START_NICK",
    "CREATE_PIPELINE",
    "UPDATE_APPROVED_SKILLS",
    "SEND_PIPELINE",
    "GET_CLIENT_ANALYTICS",
    "CREATE_COMPANY",
    "VIEW_CHILD_COMPANIES",
    "ADD_USER_TO_CHILD_COMPANY",
    "POST_CRAIGSLIST",
    "COMPANY_ANALYTICS",
    "GET_INVOICES",
    "CREATE_COMPANIES_TAGS",
  ],
  4: [],
  5: [
    "VIEW_PROFILES",
    "SEARCH_USERS",
    "CANCEL_INTERVIEW_POST",
    "CREATE_INTERVIEW",
    "SEND_INTERVIEW",
    "GET_RATINGS",
    "GET_ALL_RATINGS",
    "GET_JOB_ACTIVITY",
    "GET_COMPANY_INTERVIEW",
    "RECRUITER_UPLOAD_PROFILE_PICTURE",
    "CREATE_SOURCE",
    "UPDATE_APPROVED_SKILLS",
    "SEND_EMAIL",
    "GET_TIMELINE",
    "EDIT_USER_POINTS",
    "UPDATE_OTHER_USERS_PROFILE",
    "UPDATE_INTERNAL_FIELDS",
    "FILE_ZENDESK",
    "SEARCH_ALL_PIPELINE",
    "VIEW_COMPANIES_WORKFORCE",
    "GET_PLATFORM_JOBS",
    "GET_ANY_USER_COLUMN",
    "GET_NEW_USERS",
    "VIEW_MANAGE_JOBS",
    "CHANGE_JOB_APPLIED_STATUS",
    "EMAIL_CLIENT_ON_JOB_FILL",
    "GET_COMPANY_RATINGS",
  ],
  6: [
    "GET_CLIENTS_COMPANY_INFO",
    "VIEW_PAYROLL",
    "VIEW_COMPANIES",
    "CREATE_TIMESTAMP",
    "SEARCH_TIMESTAMPS",
    "SEARCH_PAYROLL",
    "UPDATE_STATUS",
    "APPROVE_HOURS",
    "REJECT_HOURS",
    "FIRE_USER",
    "CREATE_OTHER_COMPANY_JOB_POST",
  ],
  7: [
    "APPROVE_PROMO_LEVEL",
    "GET_CHECKR_DATA",
    "ADMIN_CREATE_COMPANY",
    "UPDATE_ANY_COMPANY_VALUE",
    "SEE_SIGNATURES",
    "CHANGE_JOB_APPLIED_STATUS",
    "EMAIL_CLIENT_ON_JOB_FILL",
    "GET_COMPANY_RATINGS",
    "VIEW_MANAGE_JOBS",
    "ADD_USER_TO_COMPANY",
    "SEND_DRIP",
    "ADD_USER_TO_WORKFORCE",
    "GET_ANY_USER_COLUMN",
    "GET_NEW_USERS",
    "VIEW_COMPANY_PULSE",
    "GET_LOGS",
    "GET_PLATFORM_JOBS",
    "START_NICK",
    "EXPORT_JOB_USERS",
    "SEND_PING",
    "GET_PING_RESPONSES",
    "INCREASE_WAGE",
    "ADD_COMP_CODES",
    "UPDATE_JOB_REQUIREMENTS",
    "RUN_NICK_SIMULATION",
    "GET_ANALYTICS",
    "VENSURE_ID",
    "SEND_JOB_REMINDER",
    "GET_REGIONS",
    "UPDATE_SUPERVISORS",
    "APPROVE_COMPANY_REVIEWS",
    "REMOVE_MARK",
    "VIEW_ALL_APPLICATIONS",
    "ALGOLIA_DRIP",
    "GET_LEADS",
    "TERMINATE_USER",
  ],
  8: ["GET_BANK_DATA"],
  9: ["RUN_PAYROLL", "RUN_DWOLLA"],
};

const permissions = {};

for (const userType in userTypes) {
  if (userTypes.hasOwnProperty(userType)) {
    permissions[userType] = {};
    for (let j = userType; j >= 0; j--) {
      for (let k = 0, l = permissionsTable[userTypes[j]].length; k < l; k++) {
        permissions[userType][permissionsTable[userTypes[j]][k]] = true;
      }
    }
  }
}

freeze(permissions);

module.exports = (userType, permission) => {
  return permissions[userType][permission] === true;
};
