import { api, apiV1, apiV1WithErrorRedirect } from "./constants/index";
import { logError } from "../../sentry";
import { GetSegmentIdentifyTraitsRequest, SessionResponse } from "./types";

export type CreateSupervisorUserRequest = {
  normalized_first_name: string;
  normalized_last_name: string;
  phone: string;
  userType: string;
  username: string;
};

export const createSupervisorUser = async (data: CreateSupervisorUserRequest) => {
  try {
    const response = await api
      .post("signup", {
        json: data,
      })
      .json();
    return response;
  } catch (error) {
    if (error instanceof Error) logError({ error, context: "createSupervisorUser" });
    return { error };
  }
};

export type EditClientRoleRequest = {
  userId: string;
  clientType: string;
};

export const editClientRole = ({ userId, clientType }: EditClientRoleRequest) => {
  return apiV1WithErrorRedirect.post("user/editClientType", {
    json: {
      userId,
      clientType,
    },
  });
};

export const getUserForIntercom = (): Promise<SessionResponse> => apiV1.get(`user/details`).json();

export const getSegmentIdentifyTraits = (
  request: GetSegmentIdentifyTraitsRequest,
): Promise<SessionResponse> => apiV1.get(`users/${request.userId}/session`).json();

export type UserDetailsResponse = {
  permissions: any[];
  user: any;
};

export const getUserDetails = (): Promise<UserDetailsResponse> =>
  // Switching to calling token/exchange for now since it also gives us user
  // details, and tokens. This makes having the legacy auth system next to the cog one easier
  api.post(`v2/token/exchange`, { json: { audience: "" } }).json();

export type CreateNewUserRequest = {
  companyIds: string[];
  username: string;
  firstName: string;
  lastName: string;
  userType: string;
  phoneNumber: string | null;
  region: string | null;
};

export async function createAndInviteNewUser(data: CreateNewUserRequest): Promise<string> {
  try {
    const response = await apiV1WithErrorRedirect.post("company/sendInvite", {
      json: data,
    });
    const userId = await response.json();
    return userId.toString();
  } catch (error: any) {
    throw await error.response.json();
  }
}

export type UsersCompanies = {
  userId: number;
  companyIds: number[];
};

export type UpdateUsersCompaniesRequest = {
  usersCompanies: UsersCompanies[];
};

export async function updateUsersCompanies(
  usersCompaniesRequest: UpdateUsersCompaniesRequest,
): Promise<number> {
  const request = await api.patch("v2/users_companies/updateUsersCompanies", {
    json: usersCompaniesRequest,
  });
  return request.status;
}

export type UpdateUsersCompaniesAndRoleRequest = {
  updateUsersCompaniesData: UpdateUsersCompaniesRequest;
  editClientRoleData: EditClientRoleRequest;
};

export type MessageResponse = {
  message?: string;
  email: string;
  reason?: string; // Typically present if api-v1 returned an error
  statusCode?: string; // Typically present if api-v1 returned an error
};
export async function resendInvite(email: string): Promise<MessageResponse> {
  return api
    .post("v2/user_management/resendAccountInvite", { json: { email } })
    .json()
    .then((r: any) => ({ message: r.message, email }));
}
