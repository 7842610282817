import React from "react";
import { useDispatch } from "react-redux";
import { AddNewUserButton } from "../AddUserModal/styledComponents";
import { BulkUserActions } from "../BulkUserActions/BulkUserActions";
import { ADD_NEW_USER_BUTTON, USERS_THAT_CAN_ACCESS_ALL_COMPANIES } from "../constants";
import {
  setSelectedUser,
  setSelectedUsers,
  setShowAssignCompaniesModal,
  setShowBulkAssignCompaniesModal,
  setShowAddUserModal,
} from "../slices/manageUsersModalSlice";
import { useAppSelector } from "../../../redux";

type HeaderButtonsProps = {};

export const HeaderButtons: React.FC<HeaderButtonsProps> = () => {
  const dispatch = useDispatch();
  const selectedUsers = useAppSelector((state) => state.manageUsersModal.selectedUsers);

  // Button should be disabled if no branch users are selected.
  const onBulkAssignCompanies = () => {
    // Admins already have access to all companies so we only want non-admin users.
    const selectedNonAdminUsers = selectedUsers.filter(
      (user) => !USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(user.userRole), // in case any other user types are added in the future
    );
    // If only one user is selected, just display the single add companies modal.
    if (selectedNonAdminUsers.length === 1) {
      dispatch(setSelectedUser(selectedNonAdminUsers[0]));
      dispatch(setShowAssignCompaniesModal(true));
      return;
    }
    dispatch(setShowBulkAssignCompaniesModal(true));
    dispatch(setSelectedUsers(selectedNonAdminUsers));
  };
  const onClick = () => {
    dispatch(setShowAddUserModal(true));
  };

  if (selectedUsers.length > 0) {
    return (
      <BulkUserActions
        selectedUsers={selectedUsers}
        onBulkAssignCompanies={onBulkAssignCompanies}
      />
    );
  }
  return (
    <AddNewUserButton data-testid={ADD_NEW_USER_BUTTON} onClick={onClick}>
      Add new user
    </AddNewUserButton>
  );
};
