// Taken from: https://stackoverflow.com/a/62002044/12060284

export function makeObservable<T>(target: T) {
  let listeners: { (value: T): any }[] = [];
  let value: T = target;

  function get() {
    return value;
  }

  function set(newValue: T) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function subscribe(listenerFunc: (value: T) => any) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc);
  }

  function unsubscribe(listenerFunc: (value: T) => any) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  return {
    get,
    set,
    subscribe,
  };
}
