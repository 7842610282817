import { useController } from "react-hook-form";
import { Input, useThemedStyletron } from "@bluecrew/blueprint-web";
import React, { useState } from "react";
import { styled } from "baseui";
import { FieldLabelRow } from "./FieldLabelRow";
import { FieldRowWrapper } from "./FieldRowWrapper";
import { errorMsg } from "./validationErrorMessages";
import { RelativeFlexFieldWrapper } from "./RelativeFlexFieldWrapper";
import { FieldTextLength } from "./FieldTextLength";
import { PositionTitleFieldProps } from "./types/propTypes/PositionTitleField.types";
import { Spacer, SpacerType } from "../../components/Spacer";

const POSITION_TITLE_MIN_LENGTH = 5;
const POSITION_TITLE_MAX_LENGTH = 30;

export const PositionTitleField = ({
  control,
  trigger,
  disabled = false,
}: PositionTitleFieldProps) => {
  const fieldName = "positionTitle";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      required: true,
      minLength: {
        value: POSITION_TITLE_MIN_LENGTH,
        message: errorMsg("min", POSITION_TITLE_MIN_LENGTH),
      },
      maxLength: {
        value: POSITION_TITLE_MAX_LENGTH,
        message: errorMsg("max", POSITION_TITLE_MAX_LENGTH),
      },
    },
  });

  const handleChange = (title: string) => {
    onChange(title);
  };

  const [, theme] = useThemedStyletron();
  const [fieldFocus, onFieldFocus] = useState(false);

  return (
    <RelativeFlexFieldWrapper data-pendo-key="PositionTitleEditField">
      <FieldRowWrapper>
        <FieldLabelRow>Position title</FieldLabelRow>
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <InputWrapper>
          <Input
            value={value}
            error={invalid}
            onChange={(text) => handleChange(text)}
            onFocus={() => onFieldFocus(true)}
            onBlur={() => {
              onFieldFocus(false);
              validate();
            }}
            disabled={disabled}
          />
          <FieldTextLength
            focused={fieldFocus}
            error={invalid}
            minLength={POSITION_TITLE_MIN_LENGTH}
            maxLength={POSITION_TITLE_MAX_LENGTH}
            length={String(value).length}
          />
        </InputWrapper>

        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
      </FieldRowWrapper>
    </RelativeFlexFieldWrapper>
  );
};

const InputWrapper = styled("div", () => ({
  width: "502.82px",
}));
