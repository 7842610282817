import * as React from 'react';
import { Button } from 'baseui/button';
import { LabelSmall } from 'baseui/typography';
import { styled } from 'baseui';
import { StyleObject } from 'styletron-react';
import { Theme } from 'baseui/theme';
import {
  CheckedIcon,
  JobBartendingIcon,
  JobBigTruckIcon,
  JobCateringIcon,
  JobChecklistIcon,
  JobCulinaryIcon,
  JobCustomerSupportIcon,
  JobDeliveryIcon,
  JobDrivingIcon,
  JobForkliftIcon,
  JobPackIcon,
  JobPliersIcon, // -- disabled as of 6/28/2022 (BW-566)
  JobRecycleIcon,
  JobConcessionsIcon,
  JobCookIcon,
  JobDishwasherIcon,
  JobEventServerIcon,
  JobHousekeeperIcon,
  JobProductionFoodIcon,
  JobProductionNonFoodIcon,
  JobRetailIcon,
  JobServerIcon,
  JobOtherIcon,
} from '../assets';
import {
  getBorderRadius,
  getBorder,
  getHorizontalPadding,
  getVerticalPadding,
} from '../helpers/styleHelpers';
import { useThemedStyletron } from '../theme';

interface TagWithIconProps {
  iconName: string;
  label?: string;
  onClick?: (val: boolean) => void;
  selected?: boolean;
}

export const DEFAULT_ICON_NAME = 'job-other';

export const jobIconsMap: { [key: string]: JSX.Element } = {
  'job-delivery': <JobDeliveryIcon />, // Moving
  'job-pack': <JobPackIcon />, // Warehouse
  'job-warehouse': <JobPackIcon />, // Warehouse
  'job-forklift': <JobForkliftIcon />, // Forklift
  'job-production-food': <JobProductionFoodIcon />, //
  'job-production-non-food': <JobProductionNonFoodIcon />, //
  'job-concession': <JobConcessionsIcon />, //
  'job-event-server': <JobEventServerIcon />, //
  'job-catering': <JobCateringIcon />, // Events
  'job-retail': <JobRetailIcon />, //
  'job-bartending': <JobBartendingIcon />, // Bartending
  'job-bar-tending': <JobBartendingIcon />, // Bartending
  'job-bar-tender': <JobBartendingIcon />, // Bartending
  'job-cook': <JobCookIcon />, //
  'job-dishwasher': <JobDishwasherIcon />, //
  'job-server': <JobServerIcon />, //
  'job-culinary': <JobCulinaryIcon />, // Food Service
  'job-food-service': <JobCulinaryIcon />, // Food Service
  'job-driving': <JobDrivingIcon />, // Driving
  'job-general-driving': <JobDrivingIcon />, // Driving
  'job-moving': <JobBigTruckIcon />, // Commercial Moving
  'job-big-truck': <JobBigTruckIcon />, //Truck Driver
  'job-truck': <JobBigTruckIcon />, //Drivers
  'job-customer-support': <JobCustomerSupportIcon />, // Customer support
  'job-housekeeper': <JobHousekeeperIcon />, //
  'job-recycle': <JobRecycleIcon />, // Janitorial
  'job-checklist': <JobChecklistIcon />, // Office
  'job-office': <JobChecklistIcon />, // Office
  'job-delivery-driving': <JobBigTruckIcon />, // Delivery & Pickup -- disabled as of 6/28/2022 (BW-566)
  'job-pliers': <JobPliersIcon />, // IT -- disabled as of 6/28/2022 (BW-566)
  'job-infotech': <JobPliersIcon />, // IT -- disabled as of 6/28/2022 (BW-566)
  'job-unknown': <JobOtherIcon />,
  [DEFAULT_ICON_NAME]: <JobOtherIcon />, // The icon used for when there is no matching icon name
};

// Order set per BW-697
// Some job types are not listed. Said job types may be
// disabled, or are new and have not yet been added to the order
export const jobIconsOrder = [
  'Warehouse Work',
  'Forklift',
  'Production - Food',
  'Production - Non Food',
  'Concession / Stand Worker',
  'Event Server',
  'Event Staff',
  'Retail',
  'Bartending',
  'Cook',
  'Dishwasher',
  'Server',
  'Food Service',
  'Drivers',
  'Commercial Moving',
  'Customer Support',
  'Housekeeper',
  'Janitorial Work',
  'Office Work',
];

export const TagWithIcon = ({
  iconName = DEFAULT_ICON_NAME,
  label,
  onClick,
  selected,
}: TagWithIconProps) => {
  const [, theme] = useThemedStyletron();
  const iconByName = jobIconsMap[iconName] ?? jobIconsMap[DEFAULT_ICON_NAME]; // Account for when given iconName is not in the jobIconsMap
  const Icon = iconByName.type;

  return (
    <Button
      startEnhancer={() => {
        const { height, width: iconWidth } = iconByName.props;

        return (
          <Icon
            color={
              selected
                ? theme.colors.buttonSecondaryFill
                : theme.colors.buttonPrimaryFill
            }
            height={height}
            width={iconWidth}
          />
        );
      }}
      onClick={() => onClick && onClick(!selected)}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({ ...buttonStyles($theme, selected) }),
        },
        StartEnhancer: {
          style: {
            marginRight: 0,
          },
        },
      }}
    >
      {selected && (
        <StyledCheckbox>
          <CheckedIcon stroke={theme.colors.buttonPrimaryFill} />
        </StyledCheckbox>
      )}
      <LabelSmall
        overrides={{
          Block: {
            style: ({ $theme }) => ({ ...labelStyles($theme, selected) }),
          },
        }}
      >
        {label}
      </LabelSmall>
    </Button>
  );
};

const centerStyles = {
  alignItems: 'center',
  justifyContent: 'center',
};

const StyledCheckbox = styled('div', ({ $theme }) => ({
  ...centerStyles,
  backgroundColor: $theme.colors.buttonSecondaryFill,
  borderRadius: $theme.sizing.scale700,
  display: 'flex',
  height: '32px',
  left: 0,
  margin: $theme.sizing.scale300,
  position: 'absolute',
  top: 0,
  width: '32px',
}));

const buttonStyles = (
  $theme: Theme,
  selected: boolean | undefined,
): StyleObject => {
  const backgroundColor = selected
    ? $theme.colors.buttonPrimaryFill
    : $theme.colors.buttonSecondaryFill;
  const border = selected ? null : getBorder({ ...$theme.borders.border100 });
  return {
    ...getBorderRadius($theme.borders.buttonBorderRadius),
    ...getHorizontalPadding($theme.sizing.scale550),
    ...getVerticalPadding($theme.sizing.scale600),
    ...border,
    ...centerStyles,
    backgroundColor,
    flexDirection: 'column',
    height: '96px',
    width: '50%',
    position: 'relative',
    ':hover': {
      backgroundColor,
    },
    ':active': {
      backgroundColor,
    },
  };
};

const labelStyles = (
  $theme: Theme,
  selected: boolean | undefined,
): StyleObject => ({
  color: selected
    ? $theme.colors.buttonPrimaryText
    : $theme.colors.buttonSecondaryText,
  marginTop: $theme.sizing.scale300,
});
