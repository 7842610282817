import { UpdateCompanyCovidPolicyPayload } from "../../../api/bluecrew/company";

export const createCovidPolicyUpdatePayload = (
  newCovidPolicy,
  confirm_cancellations,
): UpdateCompanyCovidPolicyPayload => ({
  covidPayload: {
    covid_policy: newCovidPolicy,
    confirm_cancellations,
  },
});
