import React from 'react';
// Base Web theming components
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider } from 'baseui';
import { Client } from 'styletron-engine-atomic';

import './blueprintTheme.css';
import { blueprintTheme } from './blueprintTheme';

// let styletron: any;
let engine: any = null;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  // styletron = require('styletron-engine-atomic');
  engine = new Client(); // styletron.Client();
}

/**
 * HOC that wraps a component with required StyleTron BaseWeb providers and theming.
 *
 * @example
 *
 *  import withBlueprintTheme from 'blueprint-web'
 *  const StyledApp = withBlueprintTheme(App)
 *
 *  <Provider>
 *    <StyledApp />
 *  </Provider>
 */

export const withBlueCrewTheme =
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) =>
    engine ? (
      <StyletronProvider value={engine}>
        <BaseProvider theme={blueprintTheme}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...props} />
        </BaseProvider>
      </StyletronProvider>
    ) : null;
