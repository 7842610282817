import React from "react";
import { ScheduleDropdownIcon } from "../../svg/ScheduleDropdownIcon";

interface SeeMoreRequestsButtonProps {
  isExpanded: boolean;
}

export const SeeMoreRequestsButton = ({ isExpanded }: SeeMoreRequestsButtonProps) => (
  <ScheduleDropdownIcon data-testid={"schedule-dropdown-icon"} isExpanded={isExpanded} />
);
