import * as Finance from "@bluecrew/finance-service-node-client";
import {TimestampAttributeName} from "../../shiftUtils";
import {ShiftComponent, SupportAction, WorkplaceAction} from "../../types/Shift.types";
import {PayrollHoursStatus, PayrollStatusReason, TimestampType} from "../../types/Payroll.types";
import {UserType} from "../../../../api/bluecrew/types";

export const convertTimestampAttributeName = (unconvertedAttribute : Finance.TimestampAttributeName) : TimestampAttributeName => {
  return TimestampAttributeName[unconvertedAttribute as keyof typeof TimestampAttributeName]
}

export const convertShiftComponent = (financeShiftComponent : Finance.ShiftComponent) : ShiftComponent => {
  return ShiftComponent[financeShiftComponent as keyof typeof ShiftComponent]
}

export const convertTimestampType = (unconvertedTimestamp : Finance.TimestampType) : TimestampType => {
  return TimestampType[unconvertedTimestamp as keyof typeof TimestampType]
}

export const convertWorkplaceAction = (unconvertedAction : Finance.WorkplaceAction) : WorkplaceAction => {
  return WorkplaceAction[unconvertedAction as keyof typeof WorkplaceAction]
}

export const convertSupportAction = (unconvertedSupportAction : Finance.SupportAction) : SupportAction => {
  return SupportAction[unconvertedSupportAction as keyof typeof SupportAction]
}

export const convertUserType = (unconvertedUserType : Finance.UserType) : UserType => {
  return UserType[unconvertedUserType as keyof typeof UserType]
}

export const convertPayrollHoursStatus = (unconvertedStatus : Finance.PayrollHoursStatus) : PayrollHoursStatus => {
  return PayrollHoursStatus[unconvertedStatus as keyof typeof PayrollHoursStatus]
}

export const convertPayrollStatusReason = (unconvertedReason : Finance.PayrollStatusReason | undefined) : PayrollStatusReason | undefined => {
  if (unconvertedReason === undefined) {
    return undefined
  }
  return PayrollStatusReason[unconvertedReason as keyof typeof PayrollStatusReason]
}
