import { useQuery } from "@tanstack/react-query";
import {
  fetchCompanyUsers,
  getCompanyV1,
  getCompaniesInfo,
  getAllCompanies,
  GetAllCompaniesResponse,
  GetCompanyDetailsPayload,
  getCompanyDetails,
} from "../company";
import { convertInternalUsersResponseToTableData } from "../../../containers/ManageUsers/ManageUsersTable/InternalUser";
import QueryKeys from "./queryKeys";
import { useAppSelector } from "../../../redux";
import { getUserCompany } from "../../../redux/selectors/auth";
import { logError } from "../../../sentry";

export const useGetCompanyV1Query = (internalCompanyId: number, isEnabled: boolean) =>
  useQuery({
    queryKey: ["companyDetailsV1", internalCompanyId],
    queryFn: () => getCompanyV1(internalCompanyId),
    enabled: isEnabled,
    select: (data) => data,
  });

export const useGetCompanyUsersQuery = (companyIds: number[], includeSignUpInfo: boolean = false) =>
  useQuery({
    queryKey: [QueryKeys.ManageUsers_Users, companyIds, includeSignUpInfo],
    queryFn: () => fetchCompanyUsers(companyIds, includeSignUpInfo),
    select: (data) => convertInternalUsersResponseToTableData(data),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data[0].signUpStatus === undefined) {
        const msg =
          "SignUp status is missing for at least 1 user; is the API returning sign up status correctly?";
        logError({ error: Error(msg), context: "Retrieving users for Manage Users" });
      }
    },
  });

export const useGetCompaniesInfoQuery = () =>
  useQuery({
    queryKey: QueryKeys.Companies_GetInfo,
    queryFn: () => getCompaniesInfo(),
    select: (data) => data,
    refetchOnWindowFocus: false,
  });

export const useGetAllCompaniesV1 = <T>(
  companiesResponseProcessor: (companies: Array<GetAllCompaniesResponse>) => T,
) =>
  useQuery({
    queryKey: [...QueryKeys.Companies_GetAllV1],
    queryFn: () => getAllCompanies(),
    select: (data) => companiesResponseProcessor(data.companies),
  });

export const useGetCompanyDetails = ({ companyId }: Partial<GetCompanyDetailsPayload>) => {
  let userCompanyId = companyId;
  if (!userCompanyId) {
    userCompanyId = useAppSelector((state) => getUserCompany(state));
  }

  return useQuery({
    queryKey: ["companyDetails", userCompanyId],
    queryFn: () => getCompanyDetails({ companyId: userCompanyId! }),
  });
};
