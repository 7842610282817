import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobPackIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip-pack-icon)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.9005 26.0286C4.98115 26.7307 5.31724 27.3786 5.84475 27.8488C6.37226 28.3191 7.05432 28.5788 7.761 28.5786H34.56C34.9419 28.5786 35.3082 28.4269 35.5782 28.1569C35.8483 27.8868 36 27.5206 36 27.1386C36 26.7567 35.8483 26.3905 35.5782 26.1204C35.3082 25.8504 34.9419 25.6986 34.56 25.6986H9.042C8.68904 25.6989 8.34829 25.5694 8.08448 25.3349C7.82067 25.1004 7.65218 24.7772 7.611 24.4266L5.1795 3.75665C5.09748 3.05567 4.7608 2.40925 4.23343 1.94023C3.70606 1.47121 3.02476 1.21228 2.319 1.21265H1.44C1.05809 1.21265 0.691818 1.36436 0.421768 1.63441C0.151714 1.90447 0 2.27074 0 2.65265C0 3.03456 0.151714 3.40083 0.421768 3.67088C0.691818 3.94093 1.05809 4.09265 1.44 4.09265H1.9995C2.08761 4.09278 2.17262 4.12522 2.23841 4.18383C2.3042 4.24244 2.34622 4.32314 2.3565 4.41065L4.9005 26.0286ZM25.2 32.1726C25.2 33.7632 26.4894 35.0526 28.08 35.0526C29.6705 35.0526 30.96 33.7632 30.96 32.1726C30.96 30.582 29.6705 29.2926 28.08 29.2926C26.4894 29.2926 25.2 30.582 25.2 32.1726ZM9.36012 32.1726C9.36012 33.7632 10.6495 35.0526 12.2401 35.0526C13.8307 35.0526 15.1201 33.7632 15.1201 32.1726C15.1201 30.582 13.8307 29.2926 12.2401 29.2926C10.6495 29.2926 9.36012 30.582 9.36012 32.1726ZM33.1196 17.7727H23.0396C22.2443 17.7727 21.5996 18.4174 21.5996 19.2127V22.0927C21.5996 22.888 22.2443 23.5327 23.0396 23.5327H33.1196C33.9149 23.5327 34.5596 22.888 34.5596 22.0927V19.2127C34.5596 18.4174 33.9149 17.7727 33.1196 17.7727ZM23.0402 11.2926H28.8002C29.5955 11.2926 30.2402 11.9373 30.2402 12.7326V14.1726C30.2402 14.9679 29.5955 15.6126 28.8002 15.6126H23.0402C22.2449 15.6126 21.6002 14.9679 21.6002 14.1726V12.7326C21.6002 11.9373 22.2449 11.2926 23.0402 11.2926ZM18.0004 7.69275H10.8004C10.0051 7.69275 9.36043 8.33746 9.36043 9.13275V22.0928C9.36043 22.888 10.0051 23.5327 10.8004 23.5327H18.0004C18.7957 23.5327 19.4404 22.888 19.4404 22.0928V9.13275C19.4404 8.33746 18.7957 7.69275 18.0004 7.69275Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip-pack-icon">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="matrix(-1 0 0 1 36 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
