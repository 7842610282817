import { $Keys } from "utility-types";

/*
 * Tries to call a method on a global object, such as newrelic.noticeError(...) or segment.identify(...),
 * while swallowing (but also logging) any errors encountered in the process.
 *
 * Example: callMethodOnGlobalObject("newrelic", "noticeError", [new Error("some error")])
 *
 * @param {String} globalVarName the global module name (e.g. `newrelic`, `segment`)
 * @param {String} method name
 * @param {Array<any>} arguments for the method
 */
export function callMethodOnGlobalObject<T extends {}>(
  globalVarName: string,
  method: $Keys<T>,
  args: Array<unknown>,
) {
  const global = typeof window[globalVarName] === "object" ? window[globalVarName] : null;
  const callable = global ? global[method] : undefined;
  const signature = `${globalVarName}.${String(method)}`;

  if (!global) {
    console.error(
      `${globalVarName} does not exist, ignoring call to ${signature}. Was the initializing script added to the header? Was it disabled by an ad blocker maybe?`,
    );
  } else if (!callable) {
    console.error(`${signature} does not exist, ignoring this call`);
  } else {
    try {
      return callable(...args);
    } catch (error) {
      // It would be unlikely for this to throw an error but, yeah.
      console.error(`${signature}: ignoring: ${error}`);
    }
  }
}
