import React from "react";
import { DashboardScheduleRowData } from "../../../api/bluecrew/types";
import { DirectInviteTooltip } from "../../../components/DirectInviteTooltip";
import { RowContainer } from "../SharedComponents";

interface DirectHireCellProps {
  rowData: DashboardScheduleRowData;
}

export const DirectHireCell = ({ rowData }: DirectHireCellProps) =>
  rowData.direct_hire > 0 ? (
    <RowContainer data-pendo-key="ScheduleRequestTableColumnDirectHireIndicator">
      <DirectInviteTooltip />
    </RowContainer>
  ) : null;
