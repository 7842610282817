import { useMutation, useQuery } from "@tanstack/react-query";
import { formatPayrollReportsData } from "../../../containers/Payroll/PayrollReports/utils";
import {
  buildApproveShiftPayload,
  buildCreateIntercomDisputePayload,
  buildCreateRawJobPayload,
  buildPutOnJobPayload,
  buildUpdateHoursPayload,
  buildUpdatePayrollHoursStatusPayload,
  processAllWorkers,
  processTimesheetsShiftInfoApiV1,
  processCompanyPositions,
} from "../../../containers/Payroll/payrollDataProcessors";
import {
  approveShift,
  createIntercomDispute,
  createRawJob,
  getAllWorkers,
  getCompanyPositions,
  getPayrollReportsRequest,
  getPayrollWeek,
  postCompanyPayrollRequestV1,
  postPayrollHoursRequest,
  putOnJob,
  undoPayrollAction,
  updatePayrollHours,
  updatePayrollHoursStatus,
} from "../payroll";
import { AddShiftRequest } from "../../../containers/Payroll/Timesheets/AddShift/AddShiftForm.types";
import QueryKeys from "./queryKeys";
import { queryClient } from "../../../queryClient";
import {
  ApproveShiftRequest,
  DisputeShiftRequest,
  ExcuseShiftRequest,
  PayrollAction,
  PayrollHoursStatus,
  RejectShiftRequest,
} from "../../../containers/Payroll/types/Payroll.types";
import { 
  ShiftKey, 
  TimesheetsShiftInfo, 
} from "../../../containers/Payroll/types/Shift.types";
import { 
  intercomGroup, 
  IntercomGroupPayload, 
  TimesheetMessagePayload, 
} from "../intercom";
import { useGetCompanyShifts } from "./finance";

const GenericErrorMessage = "An error occurred. Please, try again.";

export const useGetPayrollReportsQuery = (payrollWeek = new Date()) =>
  useQuery({
    queryKey: [...QueryKeys.Payroll_PayrollReports, payrollWeek],
    queryFn: () => getPayrollReportsRequest(payrollWeek),
    select: (data) => {
      const formattedData = formatPayrollReportsData(data);
      return formattedData;
    },
  });

export const usePostCompanyPayrollQueryV1 = (date: Date, companyId: string | undefined) =>
  useQuery({
    queryKey: [...QueryKeys.Payroll_CompanyPayroll, companyId, date],
    queryFn: () => postCompanyPayrollRequestV1(companyId!, getPayrollWeek(date)),
    enabled: !!companyId,
    select: (data) => processTimesheetsShiftInfoApiV1(data),
  });

export const useSplitPostCompanyPayrollQuery = (
  date: Date,
  companyId: string | undefined,
  splitValue: string,
) => {
  if (splitValue === "on") {
    return useGetCompanyShifts(date, companyId);
  }
  return usePostCompanyPayrollQueryV1(date, companyId);
};

export const usePostPayrollHoursQuery = (date: Date, companyId: string) =>
  useQuery({
    queryKey: [...QueryKeys.Payroll_PayrollHours, date, companyId],
    queryFn: () => postPayrollHoursRequest(companyId, getPayrollWeek(date)),
  });

export const useGetCompanyPositionsQuery = (companyId: string) =>
  useQuery({
    queryKey: [...QueryKeys.Payroll_CompanyPositions, companyId],
    queryFn: () => getCompanyPositions(companyId),
    select: (data) => processCompanyPositions(data.positions),
  });

export const useGetAllWorkersQuery = (companyId: string) =>
  useQuery({
    queryKey: [...QueryKeys.Payroll_GetAllWorkers, companyId],
    queryFn: () => getAllWorkers(companyId),
    select: (data) => processAllWorkers(data),
  });

export const useAddShiftMutation = (onSuccess: () => void, onError: (message: string) => void) =>
  useMutation({
    mutationKey: QueryKeys.Payroll_AddShift,
    mutationFn: async (request: AddShiftRequest) => {
      const createRawJobResponse = await createRawJob(buildCreateRawJobPayload(request));
      await putOnJob(buildPutOnJobPayload(request, createRawJobResponse.jobId));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.Finance_GetCompanyShifts });
      onSuccess();
    },
    onError: (error: { message?: string }) => {
      const errorMessage = error?.message ? error.message : GenericErrorMessage;
      onError(errorMessage);
    },
    retry: false,
  });

const getPayrollActionKey = (shiftKey: ShiftKey, action: PayrollAction) => [
  ...getPayrollActionKeyPrefix(shiftKey),
  action,
];

export const getPayrollActionKeyPrefix = (shiftKey: ShiftKey) => [
  ...QueryKeys.Payroll_Action,
  ...Object.values(shiftKey),
];

export const useApproveShiftMutation = (shiftKey: ShiftKey, onError: (message: string) => void) =>
  useMutation({
    mutationKey: getPayrollActionKey(shiftKey, PayrollAction.APPROVE),
    mutationFn: (request: ApproveShiftRequest) => approveShift(buildApproveShiftPayload(request)),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: QueryKeys.Finance_GetCompanyShifts }),
        queryClient.invalidateQueries({ queryKey: QueryKeys.Payroll_CompanyPayroll }),
      ]),
    onError: (error: { message?: string }) => {
      const errorMessage = error?.message ? error.message : GenericErrorMessage;
      onError(errorMessage);
    },
    retry: false,
  });

export const useDisputeShiftMutation = (shiftKey: ShiftKey, onError: (message: string) => void) =>
  useMutation({
    mutationKey: getPayrollActionKey(shiftKey, PayrollAction.DISPUTE),
    mutationFn: async (request: DisputeShiftRequest) => {
      const hoursId = await updatePayrollHoursStatus(
        buildUpdatePayrollHoursStatusPayload(
          request,
          PayrollHoursStatus.DISPUTED,
          request.disputeInfo.reason.code,
        ),
      );
      const { clockIn, clockOut, breakDurationMins } = request.disputeInfo;
      const anyUserHoursEdits = clockIn || clockOut || Number.isFinite(breakDurationMins);
      if (anyUserHoursEdits) {
        try {
          await updatePayrollHours(
            buildUpdateHoursPayload(hoursId, clockIn, clockOut, breakDurationMins),
          );
        } catch (e) {
          await undoPayrollAction(hoursId); // Move hours out of dispute if shift/time updates fail. This gives WP client the chance for another manual retry.
        }
      }
      createIntercomDispute(buildCreateIntercomDisputePayload(request));
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: QueryKeys.Finance_GetCompanyShifts }),
        queryClient.invalidateQueries({ queryKey: QueryKeys.Payroll_CompanyPayroll }),
      ]),
    onError: (error: { message?: string }) => {
      const errorMessage = error?.message ? error.message : GenericErrorMessage;
      onError(errorMessage);
    },
    retry: false,
  });

export const useRejectShiftMutation = (shiftKey: ShiftKey, onError: (message: string) => void) =>
  useMutation({
    mutationKey: getPayrollActionKey(shiftKey, PayrollAction.REJECT),
    mutationFn: (request: RejectShiftRequest) =>
      updatePayrollHoursStatus(
        buildUpdatePayrollHoursStatusPayload(request, PayrollHoursStatus.REJECTED, request.reason),
      ),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: QueryKeys.Finance_GetCompanyShifts }),
        queryClient.invalidateQueries({ queryKey: QueryKeys.Payroll_CompanyPayroll }),
      ]),
    onError: (error: { message?: string }) => {
      const errorMessage = error?.message ? error.message : GenericErrorMessage;
      onError(errorMessage);
    },
    retry: false,
  });

export const useExcuseShiftMutation = (shiftKey: ShiftKey, onError: (message: string) => void) =>
  useMutation({
    mutationKey: getPayrollActionKey(shiftKey, PayrollAction.Excuse),
    mutationFn: (request: ExcuseShiftRequest) =>
      updatePayrollHoursStatus(
        buildUpdatePayrollHoursStatusPayload(request, PayrollHoursStatus.DAY_OFF, request.reason),
      ),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: QueryKeys.Finance_GetCompanyShifts }),
        queryClient.invalidateQueries({ queryKey: QueryKeys.Payroll_CompanyPayroll }),
      ]),
    onError: (error: { message?: string }) => {
      const errorMessage = error?.message ? error.message : GenericErrorMessage;
      onError(errorMessage);
    },
    retry: false,
  });

export const useUndoPayrollActionMutation = (
  shiftKey: ShiftKey,
  onError: (message: string) => void,
) =>
  useMutation({
    mutationKey: getPayrollActionKey(shiftKey, PayrollAction.UNDO),
    mutationFn: (hoursId: number) => undoPayrollAction(hoursId),
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: QueryKeys.Finance_GetCompanyShifts }),
        queryClient.invalidateQueries({ queryKey: QueryKeys.Payroll_CompanyPayroll }),
      ]),
    onError: (error: { message?: string }) => {
      const errorMessage = error?.message ? error.message : GenericErrorMessage;
      onError(errorMessage);
    },
    retry: false,
  });

export const usePayrollSendMessage = (
  onSuccess: () => void,
  onError: (message: string) => void,
) => {
  return useMutation(
    QueryKeys.Payroll_SendMessage,
    ({ userId, message, shiftInfo }: { userId: string; message: string, shiftInfo?: TimesheetsShiftInfo }) => {
    
      if (shiftInfo) {

        const timesheetMessagePayload : TimesheetMessagePayload = {
          externalCompanyId: shiftInfo.company.externalId,
          week: getPayrollWeek(shiftInfo.job.start),
          externalJobId: shiftInfo.job.externalId,
          timeSegmentAbsoluteId: shiftInfo.job.shiftIndex,
        };

        const payload: IntercomGroupPayload = {
          fromCompany: false,
          // api-v1 requires this to be arrays of the same length. For now we use this just for a single CM, so do this array-hack
          userIds: [userId],
          messages: [message],
          timesheetMessages: [timesheetMessagePayload],
        };

        return intercomGroup(payload);
      } 

      const payload: IntercomGroupPayload = {
        fromCompany: false,
        // api-v1 requires this to be arrays of the same length. For now we use this just for a single CM, so do this array-hack
        userIds: [userId],
        messages: [message],
      };

      return intercomGroup(payload);

    },
    {
      onSuccess,
      onError: () => {
        const errorMessage = "An error occurred. Please, try again.";
        onError(errorMessage);
      },
    },
  );
};
