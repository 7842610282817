module.exports = {
  docType: {
    US_PASSPORT: {
      doc: "list_a",
      issuing: "U.S. Department of State",
      expiration_date_required: true,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/US_passport_Card.jpeg",
      ],
    },
    DRIVERS_LICENSE: {
      doc: "list_b",
      issuing: "State of {{state}}",
      expiration_date_required: true,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/Drivers-License_Thumb.png",
      ],
    },
    STATE_ID_CARD: {
      doc: "list_b",
      issuing: "State of {{state}}",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/ID_Card.png",
      ],
    },
    GOVERNMENT_ID_CARD: {
      doc: "list_b",
      issuing: "{{department}}",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/ID_Card.png",
      ],
    },
    SOCIAL_SECURITY_CARD: {
      doc: "list_c",
      issuing: "Social Security Administration",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/SocialSecuritycard.jpg",
      ],
    },
    US_BIRTH_CERTIFICATE: {
      doc: "list_c",
      issuing: "State of {{state}}",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/RI-BirthCert.jpg",
      ],
    },
    SCHOOL_ID_CARD: {
      doc: "list_b",
      issuing: "University of {{school}}",
      expiration_date_required: false,
      images: [],
    },
    FORM_I551: {
      doc: "list_a",
      issuing: "USCIS",
      expiration_date_required: false,
      citizenship: "LAWFUL_PERMANENT_RESIDENT",
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/PRC_Current_Front_Zoom.jpg",
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/sen-doc-3.jpg",
      ],
    },
    FOREIGN_PASSPORT_WITH_I551_STAMP: {
      doc: "list_a",
      issuing: "{{country}}",
      expiration_date_required: true,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/sen-doc-5.jpg",
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/Form_I551_stamp.jpeg",
      ],
    },
    FOREIGN_PASSPORT_WITH_FORM_I94: {
      doc: "list_a",
      issuing: "{{country}}",
      expiration_date_required: true,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/I-94A.jpg",
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/passport_Micro.jpeg",
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/passport_marshall.jpeg",
      ],
    },
    FORM_I766: {
      doc: "list_a",
      issuing: "USDHS",
      expiration_date_required: true,
      citizenship: "ALIEN_AUTHORIZED_TO_WORK",
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/I-94_Image.png",
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/EAD_Current_Front.jpg",
      ],
    },
    VOTER_REGISTRATION_CARD: {
      doc: "list_b",
      issuing: "{{County or territory}}",
      expiration_date_required: false,
      images: [],
    },
    US_MILITARY_CARD: {
      doc: "list_b",
      issuing: "{{Military Branch}}",
      expiration_date_required: false,
      images: [],
    },
    MILITARY_DEPENDENT_ID_CARD: {
      doc: "list_b",
      issuing: "{{Military Branch}}",
      expiration_date_required: false,
      images: [],
    },
    FS_545: {
      doc: "list_c",
      issuing: "U.S. Department of State",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/FS-545.jpg",
      ],
    },
    DS_1350: {
      doc: "list_c",
      issuing: "U.S. Department of State",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/DS-1350.jpg",
      ],
    },
    FORM_I197: {
      doc: "list_c",
      issuing: "INS",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/I-197Front.jpg",
      ],
    },
    NATIVE_AMERICAN_TRIBAL_DOCUMENT: {
      doc: "list_c",
      issuing: "{{Dakota Sioux}}",
      expiration_date_required: false,
      images: [],
    },
    EMPLOYMENT_AUTHORIZATION_DOCUMENT: {
      doc: "list_c",
      issuing: "USDHS",
      expiration_date_required: false,
      images: [],
    },
    US_COAST_GUARD_CARD: {
      doc: "list_b",
      issuing: "US Coast Guard Merchant Mariner Card",
      expiration_date_required: false,
      images: [],
    },
    CANADIAN_DRIVERS_LICENSE: {
      doc: "list_b",
      issuing: "Canadian government",
      expiration_date_required: false,
      images: [],
    },
    FORM_I179: {
      doc: "list_c",
      issuing: "INS",
      expiration_date_required: false,
      images: [
        "https://www.uscis.gov/sites/default/files/images/Verification/I9Central/I-179Front.jpg",
      ],
    },
  },
  reasons_for_delay: {
    AWAITING_SOCIAL_SECURITY_NUMBER: "Awaiting Social Security Number",
    TECHNICAL_PROBLEMS: "Technical Problems",
    AUDIT_REVEALED_THAT_NEW_HIRE_WAS_NOT_RUN: "Audit Revealed that New Hire Was Not Run",
    FEDERAL_CONTRACTOR: "Federal Contractor with E-Verify Clause verifying an existing employee",
    OTHER: "Other",
  },
  countries: {
    AFG: "Afghanistan",
    AGO: "Angola",
    ALB: "Albania",
    AND: "Andorra",
    ARE: "United Arab Emirates",
    ARG: "Argentina",
    ARM: "Armenia",
    ATG: "Antigua and Barbuda",
    AUS: "Australia",
    AUT: "Austria",
    AZE: "Azerbaijan",
    BDI: "Burundi",
    BEL: "Belgium",
    BEN: "Benin",
    BFA: "Burkina Faso",
    BGD: "Bangladesh",
    BGR: "Bulgaria",
    BHR: "Bahrain",
    BHS: "Bahamas",
    BIH: "Bosnia and Herzegovina",
    BLR: "Belarus",
    BLZ: "Belize",
    BOL: "Bolivia, Plurinational State of",
    BRA: "Brazil",
    BRB: "Barbados",
    BRN: "Brunei",
    BTN: "Bhutan",
    BWA: "Botswana",
    CAF: "Central African Republic",
    CAN: "Canada",
    CHE: "Switzerland",
    CHL: "Chile",
    CHN: "China",
    CIV: "Cote dIvoire (Ivory Coast)",
    CMR: "Cameroon",
    COD: "Congo, Democratic Republic of the",
    COG: "Congo, Republic of the",
    COL: "Colombia",
    COM: "Comoros",
    CPV: "Cape Verde",
    CRI: "Costa Rica",
    CUB: "Cuba",
    CYP: "Cyprus",
    CZE: "Czech Republic",
    DEU: "Germany",
    DJI: "Djibouti",
    DMA: "Dominica",
    DNK: "Denmark",
    DOM: "Dominican Republic",
    DZA: "Algeria",
    ECU: "Ecuador",
    EGY: "Egypt",
    ERI: "Eritrea",
    ESP: "Spain",
    EST: "Estonia",
    ETH: "Ethiopia",
    FIN: "Finland",
    FJI: "Fiji",
    FRA: "France",
    FSM: "Micronesia, Federated States of",
    GAB: "Gabon",
    GBR: "United Kingdom",
    GEO: "Georgia",
    GHA: "Ghana",
    GIN: "Guinea",
    GMB: "Gambia",
    GNB: "Guinea-Bissau",
    GNQ: "Equatorial Guinea",
    GRC: "Greece",
    GRD: "Grenada",
    GTM: "Guatemala",
    GUY: "Guyana",
    HND: "Honduras",
    HKG: "Hong Kong",
    HRV: "Croatia",
    HTI: "Haiti",
    HUN: "Hungary",
    IDN: "Indonesia",
    IND: "India",
    IRL: "Ireland",
    IRN: "Iran, Islamic Republic of",
    IRQ: "Iraq",
    ISL: "Iceland",
    ISR: "Israel",
    ITA: "Italy",
    JAM: "Jamaica",
    JOR: "Jordan",
    JPN: "Japan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KGZ: "Kyrgyzstan",
    KHM: "Cambodia",
    KIR: "Kiribati",
    KNA: "Saint Kitts and Nevis",
    KOR: "Korea, Republic of (South)",
    KVO: "Kosovo",
    KWT: "Kuwait",
    LAO: "Laos",
    LBN: "Lebanon",
    LBR: "Liberia",
    LBY: "Libya",
    LCA: "Saint Lucia",
    LIE: "Liechtenstein",
    LKA: "Sri Lanka",
    LSO: "Lesotho",
    LTU: "Lithuania",
    LUX: "Luxembourg",
    LVA: "Latvia",
    MAR: "Morocco",
    MCO: "Monaco",
    MDA: "Moldova, Republic of",
    MDG: "Madagascar",
    MDV: "Maldives",
    MEX: "Mexico",
    MHL: "Marshall Islands, Republic of the",
    MKD: "Macedonia, the Former Yugoslav Republic of",
    MLI: "Mali",
    MLT: "Malta",
    MMR: "Burma (Myanmar)",
    MNE: "Montenegro",
    MNG: "Mongolia",
    MOZ: "Mozambique",
    MRT: "Mauritania",
    MUS: "Mauritius",
    MWI: "Malawi",
    MYS: "Malaysia",
    NAM: "Namibia",
    NER: "Niger",
    NGA: "Nigeria",
    NIC: "Nicaragua",
    NLD: "Netherlands",
    NOR: "Norway",
    NPL: "Nepal",
    NRU: "Nauru",
    NZL: "New Zealand",
    OMN: "Oman",
    PAK: "Pakistan",
    PAN: "Panama",
    PER: "Peru",
    PHL: "Philippines",
    PLW: "Palau",
    PNG: "Papua New Guinea",
    POL: "Poland",
    PRK: "Korea, Democratic Republic of (North)",
    PRT: "Portugal",
    PRY: "Paraguay",
    PSE: "Palestinian Authority",
    QAT: "Qatar",
    ROU: "Romania",
    RUS: "Russia",
    RWA: "Rwanda",
    SAU: "Saudi Arabia",
    SDN: "Sudan",
    SEN: "Senegal",
    SGP: "Singapore",
    SLB: "Solomon Islands",
    SLE: "Sierra Leone",
    SLV: "El Salvador",
    SMR: "San Marino",
    SOM: "Somalia",
    SRB: "Serbia",
    SSD: "South Sudan",
    STP: "Sao Tome and Principe",
    SUR: "Suriname",
    SVK: "Slovakia",
    SVN: "Slovenia",
    SWE: "Sweden",
    SWZ: "Swaziland",
    SYC: "Seychelles",
    SYR: "Syria",
    TCD: "Chad",
    TGO: "Togo",
    THA: "Thailand",
    TJK: "Tajikistan",
    TKM: "Turkmenistan",
    TLS: "Timor-leste",
    TON: "Tonga",
    TTO: "Trinidad and Tobago",
    TUN: "Tunisia",
    TUR: "Turkey",
    TUV: "Tuvalu",
    TWN: "Taiwan",
    TZA: "Tanzania, United Republic of",
    UGA: "Uganda",
    UKR: "Ukraine",
    URY: "Uruguay",
    UZB: "Uzbekistan",
    VAT: "Holy See (Vatican City State)",
    VCT: "Saint Vincent and the Grenadines",
    VEN: "Venezuela, Bolivarian Republic of",
    VNM: "Viet Nam",
    VUT: "Vanuatu",
    WSM: "Samoa",
    YEM: "Yemen",
    ZAF: "South Africa",
    ZMB: "Zambia",
    ZWE: "Zimbabwe",
  },
};
