import { Input, SelectedIcon, UnselectedIcon } from "@bluecrew/blueprint-web";
import React, { useEffect } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useController, useFormContext } from "react-hook-form";
import { ErrorMessage as ErrorMsg } from "../../GetCrewMembers/ErrorMessage";
import { Column } from "../styledComponents";
import {
  CenterAlignedFlexText,
  DistanceUnitButton,
  ListItemContainer,
} from "../../styledComponents";
import { DistanceUnit } from "../../../api/bluecrew/types";

type GeofenceInputProps = {
  label: string;
  testId: string;
  disabled: boolean;
  defaultValue: number;
  unitFieldName: string;
  radiusFieldName: string;
  validateField: (radiusFieldName: string) => boolean | string;
};

export const GeofenceInput = ({
  label,
  testId,
  disabled,
  defaultValue,
  validateField,
  unitFieldName,
  radiusFieldName,
}: GeofenceInputProps) => {
  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    field: { value, onChange, onBlur },
    meta: { isDirty },
  } = useController({
    name: radiusFieldName,
    control,
    rules: {
      required: true,
      validate: () => {
        // Don't run validation until user actually tries to change something
        if (!isDirty) return true;
        return validateField(radiusFieldName);
      },
    },
    defaultValue,
  });

  const watchUnit = watch(unitFieldName, DistanceUnit.METERS);

  useEffect(() => {
    if (defaultValue >= 1000) {
      onChange(value / 1000);
      setValue(unitFieldName, DistanceUnit.KILOMETERS);
    } else {
      onChange(value);
      setValue(unitFieldName, DistanceUnit.METERS);
    }
  }, []);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const unit = event.currentTarget.value;
    if (watchUnit === unit) return;

    if (unit === DistanceUnit.METERS) {
      onChange(value * 1000);
    } else {
      onChange(value / 1000);
    }
    setValue(unitFieldName, unit);
  };

  // Set the unit for the field on initial load
  useEffect(() => {
    if (defaultValue >= 1000) {
      onChange(defaultValue / 1000);
      setValue(unitFieldName, DistanceUnit.KILOMETERS);
    } else {
      onChange(defaultValue);
      setValue(unitFieldName, DistanceUnit.METERS);
    }
  }, [defaultValue]);

  return (
    <div className="input-block-and-error-msg" data-testid={testId}>
      <CenterAlignedFlexText className="input-block">
        <CenterAlignedFlexText>{label}</CenterAlignedFlexText>
        <Column>
          <Input
            type="number"
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            error={errors[radiusFieldName]}
            styles={{
              textAlign: "center",
              width: "96px",
              height: "48px",
            }}
          />
        </Column>
        <ListItemContainer>
          <DistanceUnitButton>
            <input
              type="radio"
              value={DistanceUnit.METERS}
              id={`${unitFieldName}-${DistanceUnit.METERS}`}
              name={unitFieldName}
              data-testid={`${unitFieldName}-${DistanceUnit.METERS}`}
              ref={register}
              hidden
              onChange={onChangeHandler}
            />
            <label htmlFor={`${unitFieldName}-${DistanceUnit.METERS}`}>
              {watchUnit === DistanceUnit.METERS ? <SelectedIcon /> : <UnselectedIcon />}
            </label>
            {DistanceUnit.METERS}
          </DistanceUnitButton>
          <DistanceUnitButton>
            <input
              type="radio"
              value={DistanceUnit.KILOMETERS}
              name={unitFieldName}
              id={`${unitFieldName}-${DistanceUnit.KILOMETERS}`}
              ref={register}
              data-testid={`${unitFieldName}-${DistanceUnit.KILOMETERS}`}
              hidden
              onChange={onChangeHandler}
            />
            <label htmlFor={`${unitFieldName}-${DistanceUnit.KILOMETERS}`}>
              {watchUnit === DistanceUnit.KILOMETERS ? <SelectedIcon /> : <UnselectedIcon />}
            </label>
            {DistanceUnit.KILOMETERS}
          </DistanceUnitButton>
        </ListItemContainer>
      </CenterAlignedFlexText>
      <ErrorMessage
        errors={errors}
        name={radiusFieldName}
        render={({ message }) => <ErrorMsg text={message} />}
      />
    </div>
  );
};
