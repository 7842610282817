import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const MagnifyingGlassIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 14, height = 14, color = theme.colors.accent } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6586 12.0148L10.9507 9.30538C11.7752 8.07046 12.1112 6.57283 11.8932 5.10381C11.6752 3.63478 10.9189 2.2994 9.77142 1.3574C8.6239 0.415404 7.16721 -0.0658471 5.6847 0.00725745C4.20219 0.080362 2.79985 0.702595 1.75047 1.75292C0.701087 2.80325 0.0796902 4.20657 0.00714974 5.68993C-0.0653907 7.17328 0.416112 8.63059 1.358 9.77841C2.29988 10.9262 3.63481 11.6825 5.10311 11.9C6.5714 12.1176 8.06809 11.7809 9.30204 10.9555L12.01 13.6643C12.2305 13.8795 12.5263 14 12.8343 14C13.1423 14 13.4381 13.8795 13.6586 13.6643C13.8772 13.4456 14 13.1489 14 12.8396C14 12.5302 13.8772 12.2336 13.6586 12.0148ZM5.98432 1.75695C6.82026 1.75695 7.63743 2.00497 8.33249 2.46965C9.02754 2.93432 9.56927 3.59479 9.88917 4.36751C10.2091 5.14024 10.2928 5.99053 10.1297 6.81086C9.96661 7.63119 9.56406 8.3847 8.97297 8.97612C8.38187 9.56754 7.62876 9.97031 6.80889 10.1335C5.98901 10.2967 5.13919 10.2129 4.36688 9.89283C3.59457 9.57276 2.93447 9.03073 2.47005 8.33529C2.00563 7.63985 1.75774 6.82224 1.75774 5.98584C1.75898 4.86465 2.20467 3.78973 2.99704 2.99693C3.78941 2.20412 4.86374 1.75818 5.98432 1.75695Z"
        fill={color}
      />
    </svg>
  );
};
