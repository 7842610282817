import React, { useState } from "react";
import { useStep } from "react-hooks-helper";
import { HistoryConfirmationModal } from "./higher-order-components/HistoryConfirmationModal";
import { FormFieldTypes as FormFieldTypesCreatePosition } from "./types/propTypes/CreatePositionScreen.types";
import { FormFieldTypes as FormFieldTypesGetCrew } from "./types/propTypes/GetCrewMemberForm.types";
import {
  DefaultValues,
  GetCrewMembersProps,
  WorkflowSteps,
} from "./types/propTypes/GetCrewMembers.types";
import { ReviewOrderExtraProps } from "./types/propTypes/ReviewOrderScreen.types";
import { defaultFormValues } from "./formUtils";
import { CreatePositionScreen } from "./CreatePositionScreen";
import { GetCrewMemberScreen } from "./GetCrewMemberScreen";
import { ReviewOrderScreen } from "./ReviewOrderScreen";

export const GetCrewMembers = ({
  data: {
    positions,
    crewMembers,
    crewGroups,
    supervisors,
    departments,
    geofence,
    notificationManagers,
    skills,
    isDepartmentsEnabled = false,
  },
  defaultValues = defaultFormValues,
  events,
  activeStep = WorkflowSteps.GET_CREW,
  isBulkSingleShiftEnabled,
  isBulkSingleShiftMax50DayEnabled,
  isSingleAssignmentView,
  preselectedSchedule,
  preselectedPosition,
}: GetCrewMembersProps) => {
  const {
    index: step,
    navigation: { go },
  } = useStep({
    steps: 3,
    initialStep: activeStep,
  });

  const [formData, setFormData] = useState<DefaultValues>(defaultValues);
  const [reviewOrderData, setReviewOrderData] = useState<ReviewOrderExtraProps>(
    defaultFormValues.reviewOrder,
  );

  const updateFormState = (
    key: keyof DefaultValues,
    newState: FormFieldTypesGetCrew | FormFieldTypesCreatePosition | ReviewOrderExtraProps,
  ) => {
    setFormData({
      ...formData,
      [key]: newState,
    });
  };

  const backButtonModalText = {
    header: "Are you sure you want to return to the dashboard?",
    label: "Returning to the Dashboard will lose this schedule",
    body:
      "If you want to edit the current schedule press 'Cancel' on\n" +
      "this modal and use the 'Edit request' link below,\n" +
      "otherwise press 'Continue' to return to the dashboard.",
  };

  const BackButtonAugmentedReviewOrder = HistoryConfirmationModal(
    ReviewOrderScreen,
    backButtonModalText,
  );

  const renderGetCrewForm = () => (
    <GetCrewMemberScreen
      isSingleAssignmentView={isSingleAssignmentView}
      preselectedSchedule={preselectedSchedule}
      preselectedPosition={preselectedPosition}
      data={{
        positions,
        crewMembers,
        crewGroups,
        supervisors,
        departments,
        notificationManagers,
        isDepartmentsEnabled,
      }}
      events={{
        onNewPosition: () => {
          go && go(WorkflowSteps.CREATE_POSITION);
        },
        onNext: async (data) => {
          if (events?.onBeforeReviewOrder) {
            const res = await events.onBeforeReviewOrder(data);

            res && setReviewOrderData(res);
          }
          return go && go(WorkflowSteps.REVIEW_ORDER);
        },
        onSubmit: events?.onSaveJob,
        onGetNumShifts: events?.onGetNumShifts,
        onGetScheduleInfo: events?.onGetScheduleInfo,
      }}
      defaultValues={formData?.getCrew}
      updateFormState={(formState) =>
        updateFormState("getCrew", {
          ...defaultFormValues.getCrew,
          ...formState,
        })
      }
      isBulkSingleShiftEnabled={isBulkSingleShiftEnabled}
      isBulkSingleShiftMax50DayEnabled={isBulkSingleShiftMax50DayEnabled}
    />
  );

  return (
    <>
      {step === WorkflowSteps.GET_CREW && renderGetCrewForm()}
      {step === WorkflowSteps.REVIEW_ORDER && (
        <BackButtonAugmentedReviewOrder
          data={{
            ...formData.getCrew,
            ...reviewOrderData,
          }}
          events={{
            onEditRequest: () => go && go(WorkflowSteps.GET_CREW),
            onSendRequest: () => events?.onSaveJob && events.onSaveJob(formData.getCrew),
          }}
          isBulkSingleShiftEnabled={isBulkSingleShiftEnabled}
        />
      )}
      {step === WorkflowSteps.CREATE_POSITION && (
        <CreatePositionScreen
          data={{ skills, positions, geofence }}
          events={{
            onSave: (data) => {
              events?.onSavePosition?.(data);
              go && go(WorkflowSteps.GET_CREW);
            },
            onLocationSelect: events?.onLocationSelect,
          }}
          defaultValues={formData?.createPosition}
        />
      )}
    </>
  );
};
