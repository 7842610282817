import React from 'react';
import { SvgPropType } from './SvgPropType';
import { useThemedStyletron } from '../../theme';

export const UnselectedIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const {
    width = 24,
    height = 24,
    color = theme.colors.white,
    stroke = theme.colors.tempest,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11"
        fill={color}
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};
