// PERMISSIONS CONTEXT - CONTAINER
// =============================================================================

import { AbilityBuilder, Ability } from "@casl/ability";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";

const { build } = new AbilityBuilder(Ability);
export const AbilityContext = createContext(build());
// @ts-ignore TODO(dloukusa-bc or AndrewThongsavathBC) Figure out why this is complaining
export const Can = createContextualCan(AbilityContext.Consumer);

// defineAbility in casl/ability returns a AnyMongoAbility which is not neatly typed.
export const defineAbility = (fn) => {
  const { can, cannot, build } = new AbilityBuilder(Ability);
  fn(can, cannot);
  return build();
};
