import { Tabs as BaseTabs, Tab, FILL } from 'baseui/tabs-motion';
import * as React from 'react';
import { StyleObject } from 'styletron-standard';
import { BlueprintTheme, useThemedStyletron } from '../theme';
import { getBorderRadius, getPadding } from '../helpers/styleHelpers';

export enum TabsKind {
  UNDERLINED = 'underlined',
  PILL = 'pill',
  PILL_ICON = 'pill icon',
}

interface TabsList {
  title: React.ReactNode;
  content?: React.ReactNode;
}

interface TabsProps {
  kind?: TabsKind;
  renderList?: TabsList[];
  activeTab?: string;
  onChange?: (activeTab: string) => void;
  tabHeaderMarginLeft?: string;
  tabHeaderMarginRight?: string;
  disabled?: boolean;
}

export const Tabs = ({
  kind = TabsKind.PILL,
  activeTab = '0',
  onChange,
  renderList,
  tabHeaderMarginLeft,
  tabHeaderMarginRight,
  disabled,
}: TabsProps) => {
  const [, $theme] = useThemedStyletron();

  const isCurrentTabActive = (activeKey: React.ReactNode, activeEl: string) =>
    activeKey === activeEl;

  const onTabChange = (params: { activeKey: React.Key }) =>
    onChange && onChange(params.activeKey.toString());

  return (
    <BaseTabs
      disabled={disabled}
      activeKey={activeTab}
      onChange={onTabChange}
      activateOnFocus={false}
      fill={kind === TabsKind.PILL ? FILL.fixed : undefined}
      overrides={{
        TabList: {
          style: () => ({
            pointerEvents: disabled ? 'none' : 'auto',
            ...tabListStyle(
              $theme,
              kind,
              tabHeaderMarginLeft,
              tabHeaderMarginRight,
            ),
          }),
        },
        TabBorder: {
          style: () =>
            kind === TabsKind.UNDERLINED
              ? {
                  height: $theme.sizing.scale100,
                  backgroundColor: $theme.colors.mono200,
                  marginLeft: tabHeaderMarginLeft || '0px',
                  marginRight: tabHeaderMarginRight || '0px',
                }
              : displayNone,
        },
        TabHighlight: {
          style: () =>
            kind === TabsKind.UNDERLINED
              ? {
                  height: $theme.sizing.scale100,
                  bottom: '1px',
                  backgroundColor: $theme.colors.accent400,
                }
              : displayNone,
        },
      }}
    >
      {renderList &&
        renderList.map((item, index) => (
          <Tab
            title={
              kind === TabsKind.PILL_ICON && React.isValidElement(item.title)
                ? React.cloneElement(item.title, {
                    // @ts-ignore
                    color: $theme.colors.accent,
                    width: $theme.sizing.scale600,
                    height: $theme.sizing.scale600,
                  })
                : item.title
            }
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            overrides={{
              Tab: {
                style: () => ({
                  ...tabStyle(
                    $theme,
                    isCurrentTabActive(activeTab, `${index}`),
                    kind,
                  ),
                }),
              },
              TabPanel: {
                style: () =>
                  kind === TabsKind.UNDERLINED
                    ? {
                        ...getPadding('0'),
                        paddingTop: $theme.sizing.scale800,
                      }
                    : {
                        ...getPadding('0'),
                        paddingTop: $theme.sizing.scale400,
                      },
              },
            }}
          >
            {item.content}
          </Tab>
        ))}
    </BaseTabs>
  );
};

const tabListStyle = (
  $theme: BlueprintTheme,
  kind: TabsKind,
  tabHeaderMarginLeft?: string,
  tabHeaderMarginRight?: string,
) =>
  kind === TabsKind.UNDERLINED
    ? {
        marginLeft: tabHeaderMarginLeft || '0px',
        marginRight: tabHeaderMarginRight || '0px',
      }
    : {
        width: kind === TabsKind.PILL ? 'auto' : 'fit-content',
        marginLeft: tabHeaderMarginLeft || '0px',
        marginRight: tabHeaderMarginRight || '0px',
        ...getPadding($theme.sizing.scale0),
        ...getBorderRadius('40px'),
        background: $theme.colors.tabBarFill,
      };

const tabStyle = (
  $theme: BlueprintTheme,
  isCurrentTabActive: boolean,
  kind: TabsKind,
): StyleObject => {
  if (kind === TabsKind.UNDERLINED) {
    return {
      color: isCurrentTabActive
        ? $theme.colors.accent400
        : $theme.colors.tabColor,
    };
  }
  return {
    ...getPadding(
      kind === TabsKind.PILL ? $theme.sizing.scale200 : $theme.sizing.scale400,
    ),
    ...getBorderRadius($theme.sizing.scale950),
    marginLeft: '1px',
    marginRight: '1px',
    background: isCurrentTabActive
      ? $theme.colors.tabFillActive
      : $theme.colors.tabFill,
    boxShadow: isCurrentTabActive ? '0px 1px 4px rgba(8, 9, 18, 0.16)' : 'none',
    color: isCurrentTabActive
      ? $theme.colors.tabColorActive
      : $theme.colors.tabColor,
    ...$theme.typography.font200,
    fontWeight: 600,
    ':first-child': {
      marginLeft: 0,
    },
    ':last-of-type': {
      marginRight: 0,
    },
  };
};

const displayNone = { display: 'none' };
