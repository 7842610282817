import { SagaIterator } from "redux-saga";
import { Effect, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getUserId, getExternalUserId } from "../selectors/auth";
import { getSegmentIdentifyTraits } from "../../api/bluecrew/user";
import segmentActions from "../actions/segment";
import { identify } from "../../segment";
import { logError } from "../../sentry";
import { initializePendo } from "../../pendo";

function* segmentPendoIdentifySaga(): Generator<Effect, void, any> {
  try {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;
    const mobileWindowPhysicalWidth = window.devicePixelRatio * windowWidth;
    const mobileWindowPhysicalHeight = window.devicePixelRatio * windowHeight;
    const userId = yield select(getUserId);
    const externalUserId = yield select(getExternalUserId);
    const identifyTraits = yield call(getSegmentIdentifyTraits, {
      userId,
    });
    // This calls analytics.identify() with traits that come back from the v1 endpoint above. You can think
    // of the traits that get passed to identify as being like a cache of user data that will be made
    // available to apps (like braze) that are connected to segment. When those connected apps receive an
    // event that references a particular user, they will be able to retrieve the data reported to identify.
    // These traits include company data, so it's important that we call identify after switching companies,
    // in addition to after a user logs in. On the other hand, there is currently aggressive caching in the
    // v1 endpoint called above that will prevent those company traits from updating more often than every
    // 15 minutes, so... be careful.
    yield call(identify, externalUserId, identifyTraits);
    // Initialize Pendo
    yield fork(initializePendo, {
      userTraits: identifyTraits,
      windowWidth,
      windowHeight,
      mobileWindowPhysicalWidth,
      mobileWindowPhysicalHeight,
    });
    yield put(segmentActions.identifyTraits.success(identifyTraits));
  } catch (error: any) {
    yield put(segmentActions.identifyTraits.failure(error));
    logError(error);
  }
}

export default {
  *watchSegmentPendoIdentifySaga(): SagaIterator {
    yield takeLatest(segmentActions.identifyTraits.request.toString(), segmentPendoIdentifySaga);
  },
};
