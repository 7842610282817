import { styled } from 'baseui';
import { StyleObject } from 'styletron-react';
import { Theme } from 'baseui/theme';
// eslint-disable-next-line import/no-cycle
import {
  getBorderRadius,
  getMargin,
  getPadding,
} from '../../helpers/styleHelpers';

export const Header = styled('div', ({ $theme }) => ({
  ...$theme.typography.font650,
  ...getMargin('0'),
  ...getPadding($theme.sizing.scale600),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
}));

export const Body = styled(
  'div',
  ({ maxheight }: { maxheight: string | undefined }) => ({
    ...getMargin('0'),
    ...getPadding('0'),
    maxHeight: maxheight || '80%',
    overflowY: 'auto',
  }),
);

export const Footer = styled('div', () => ({
  ...getMargin('0'),
}));

export const CloseButton = styled('div', ({ $theme }) => ({
  width: 'auto',
  height: 'auto',
  top: $theme.sizing.scale750,
  right: $theme.sizing.scale700,
  cursor: 'pointer',
}));

export const dialogStyles = (width: string | undefined): StyleObject => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width,
  ...getMargin('0'),
});

export const styleByKind = ($theme: Theme): any => ({
  ...getBorderRadius($theme.borders.radius400),
});
