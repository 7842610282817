import Cookies from "js-cookie";
import { ICognitoStorage } from "amazon-cognito-identity-js";
import { getCookieOptions } from ".";
import { CognitoItemNames, extractItemNameFromKey, useCookieStorage } from "./cognito";

/**
 * This file contains an implemenation of custom storage for Amplify.
 * It allows us to write some cognito items into cookies, and the rest into
 * local storage.
 *
 * Reducing the amount of data written into cookies is good, as there is a
 * limit to how much data you can store in cookies; The actual limit varies
 * based on browser.
 */

/**
 * This is used to set a specific item in storage
 */
function setItem(key: string, value: any) {
  const { idToken, accessToken, refreshToken } = CognitoItemNames;
  if (useCookieStorage(key)) {
    let expiry = 1;
    const itemName = extractItemNameFromKey(key);
    if (itemName === idToken || itemName === accessToken) {
      expiry = 7; // Cognito id and access tokens default expiration is 1 hour from cognito, but we're setting it to 7 days for now, until we stop using idToken precence as a "Logged In" flag
    } else if (itemName === refreshToken) {
      expiry = 7; // Days - Refresh token actually lasts for 30 days from cognito, but we can set it to 7 days here so we don't have cookies sitting around for a month
    }
    const cookieOptions = getCookieOptions(expiry);
    Cookies.set(key, value, cookieOptions);
  } else {
    window.localStorage.setItem(key, value);
  }
}

/**
 * Get a specific key from storage
 */
function getItem(key: string) {
  if (useCookieStorage(key)) {
    return Cookies.get(key) ?? null;
  }
  return window.localStorage.getItem(key);
}

/**
 * Remove an item from storage
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function removeItem(_key: string) {
  // Custom storage is not allowed to delete items.
  // Reasoning:
  // Amplify sometimes tries to delete cognito items before we can call our logout
  // saga, e.g. if a refresh auth call fails it will try to delete the cognito tokens.
  // We use the existance of those to track if a user is "logged in", so if they
  // are deleted before we expect them to be, it can cause issues.
  // Disabling Amplify's ability to delete them is fine, because we wipe local
  // storage and cookies via our logout saga.
}

/**
 * Clear the storage
 */
function clear() {
  // Custom storage is not allowed to delete items.
  // We manage local storage and cookies via our logout saga.
}

export const AuthStorage: ICognitoStorage = {
  setItem,
  getItem,
  removeItem,
  clear,
};
