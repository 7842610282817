import styled from "styled-components";
import { UserAvatar } from "@bluecrew/web-react-core";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { assetUrl } from "../../../api/bluecrew/constants";
import { Dropdown } from "../../../components/Dropdown";

export const StyledTableContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  max-height: 78vh;
  width: 100%;
  overflow: auto;
`;

export const StyledTable = styled.table<{ paddingTop: number; paddingBottom: number }>`
  --virtualPaddingTop: ${(props) => props.paddingTop || 0}px;
  --virtualPaddingBottom: ${(props) => props.paddingBottom || 0}px;
  height: fit-content;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  td.select-user {
    padding-left: 16px;
    width: 30px;
  }
  td.user-pfp {
    padding-right: 20px;
    width: 50px;
  }
  td.internal-user-name {
    width: 200px;
  }
  td.contact-info {
    width: 280px;
  }
  td.assigned-companies {
    width: 110px;
  }
  td.user-roles {
    width: 200px;
  }
  td.table-actions {
    width: 20px;
  }
  thead {
    position: sticky;
    top: 0px;
    tr {
      th.select-user {
        width: 3px;
      }
      th.user-pfp {
        width: 3px;
      }
      th.internal-user-name {
        width: 10px;
      }
      th.assigned-companies {
        width: 6px;
      }
      th.contact-info {
        width: 14px;
      }
      th.user-roles {
        width: 10px;
      }
      th.table-actions {
        width: 3px;
      }
    }
    z-index: 1;
    background-color: ${({ theme }) => theme.colorTokens.white};
    align-self: flex-start;
    box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colorTokens.gray200};
    height: 20px;
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    font-style: normal;
    color: ${({ theme }) => theme.colorTokens.gray800};
    text-align: left;
  }
  tbody::before {
    display: block;
    padding-top: var(--virtualPaddingTop);
    content: "";
  }

  tbody::after {
    display: block;
    padding-bottom: var(--virtualPaddingBottom);
    content: "";
  }
  tbody {
    td {
      font-family: ${({ theme }) => theme.typography.font300.fontFamily};
      font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
      font-size: ${({ theme }) => theme.typography.font300.fontSize};
      line-height: ${({ theme }) => theme.typography.font300.lineHeight};
      font-style: normal;
      text-align: left;
      padding-left: 8px;
      color: ${({ theme }) => theme.colorTokens.gray800};
      :first-child {
        border-radius: 10px 0 0 10px;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
      }
    }
    tr {
      height: 60px;
      .action {
        visibility: hidden;
      }
      :hover {
        background-color: ${({ theme }) => theme.colorTokens.gray100};
        .action {
          visibility: visible;
        }
      }
    }
  }

  .odd {
    background-color: ${({ theme }) => theme.colorTokens.gray150};
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 166px;
  height: 28px;
  .p-dropdown-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 4px;
    letter-spacing: 0em;
    text-align: left;
    overflow: visible;
  }
  .p-dropdown-trigger {
    color: ${({ theme }) => theme.palette.brandBlue};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .p-checkbox {
    width: 16px;
    height: 16px;
  }
  .p-checkbox-box {
    border: 1px solid #ced4da;
    width: 16px;
    height: 16px;
    color: #495057;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-checkbox-icon.p-icon {
    width: 12px;
    height: 10px;
    background-image: url(${assetUrl}/icons/ManageUsers/checkbox-checkmark-icon.svg);;
  }
  .p-checkbox-box.p-highlight {
    border-color: ${({ theme }) => theme.palette.brandBlue};
    background: ${({ theme }) => theme.palette.brandBlue};
  }
}

`;

export const UserName = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray700};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-size: 16px;
`;

export const LastActive = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray500};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-align: left;
`;

export const EmailAddress = styled.a`
  color: ${({ theme }) => theme.colorTokens.blue400};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-align: left;
  padding-top: 4px;
  text-decoration: none;
`;

export const PhoneNumber = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray700};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.01em;
  text-align: left;
`;

export const DuplicateUserButton = styled.div`
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  background-image: url(${assetUrl}/icons/ManageUsers/duplicate-user-icon.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const DeactivateUserButton = styled.div`
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  background-image: url(${assetUrl}/icons/ManageUsers/deactivate-user-icon.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 10px 10px;
`;

export const BaseFilterButton = styled(Button)`
  margin-top: 6px;
  margin-right: 10px;
  width: 140px;
  height: 30px;
  padding: 16px;
  border-radius: 6px;
  gap: 20px;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 530;
  line-height: 20px;
  letter-spacing: 0em;
  border-color: ${({ theme }) => `${theme.colorTokens.blue400}`};
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
  outline-color: transparent;
  justify-content: center;
  transition: all 0.3s ease-out;
`;

export const ApplyFilterButton = styled(BaseFilterButton)`
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
`;

export const ResetFilterButton = styled(BaseFilterButton)`
  background-color: ${({ theme }) => theme.colorTokens.white};
  color: ${({ theme }) => theme.colorTokens.blue400};
`;

export const ActionsContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

export const StyledUserAvatar = styled(UserAvatar)`
  background: ${({ theme }) => theme.colorTokens.aqua100};
  > span {
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.colorTokens.gray800} !important;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 4px;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ManageUsersContainer = styled.div`
  width: 100%;
`;

export const SidebarCheckbox = styled(Checkbox)`
  .p-checkbox {
    width: 16px;
    height: 16px;
  }

  .p-checkbox-box {
    border: 1px solid #ced4da;
    width: 16px;
    height: 16px;
    color: #495057;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

  .p-checkbox-icon.p-icon {
    width: 12px;
    height: 10px;
    background-image: url(${assetUrl}/icons/ManageUsers/checkbox-checkmark-icon.svg);
  }

  .p-checkbox-box.p-highlight {
    border-color: ${({ theme }) => theme.palette.brandBlue};
    background: ${({ theme }) => theme.palette.brandBlue};
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
`;

export const PageTitle = styled.h1`
  font-size: 32px;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-right: 50px;
  text-align: left;
`;

export const StyledInputText = styled(InputText)`
  flex: 1;
  font-size: 14px;
  padding: 8px;
  margin-left: 20px;
  border-radius: 7px;
  border-color: #babbc4;
`;

export const StyledUserSearchInput = styled(StyledInputText)`
  margin-left: 60px;
  width: 300px;
`;

export const StyledCompanySearchInput = styled(StyledInputText)`
  margin-bottom: 12px;
  margin-top: 12px;
  width: 96%;
  font-size: 12px;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  float: left;
  margin-right: 10px;
  margin-bottom: 20px;
`;

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-right: 50px;
  position: sticky;
  top: 80px;
`;
