/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserType } from "../../../api/bluecrew/types";

export type ManageUsersFilterState = {
  roleFilter: UserType[];
  nameFilter: string;
};

const initialState: ManageUsersFilterState = {
  roleFilter: [],
  nameFilter: "",
};

export const manageUsersFilterSlice = createSlice({
  name: "manageUsersFilter",
  initialState,
  reducers: {
    setRoleFilter: (state, action: PayloadAction<UserType[]>) => {
      state.roleFilter = action.payload;
    },
    setNameFilter: (state, action: PayloadAction<string>) => {
      state.nameFilter = action.payload;
    }
  },
});

export const {
  setRoleFilter,
  setNameFilter,
} = manageUsersFilterSlice.actions;

export default manageUsersFilterSlice.reducer;
