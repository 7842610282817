import React from 'react';
import { SvgPropType } from './SvgPropType';

export const ArrowRightIcon = ({
  width = 20,
  height = 15,
  color = 'none',
}: SvgPropType) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3431 3.29289L19.7071 9.65685C20.0976 10.0474 20.0976 10.6805 19.7071 11.0711L13.3431 17.435C12.9526 17.8256 12.3195 17.8256 11.9289 17.435C11.5384 17.0445 11.5384 16.4113 11.9289 16.0208L16.5858 11.364H0V9.36396H16.5858L11.9289 4.70711C11.5384 4.31658 11.5384 3.68342 11.9289 3.29289C12.3195 2.90237 12.9526 2.90237 13.3431 3.29289Z"
      fill={color}
    />
  </svg>
);
