import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, useSortBy, Row, useFilters } from "react-table";
import { SortingContainer } from "../../../../components/ReactTable/SortingContainer";
import { TableColumnSortIcon } from "../../../../svg/TableColumnSortIcon";
import { FormattedPayrollReportDataType } from "../utils";
import { FlexRow, StyledTable, TableRow } from "./styledComponents";
import { TableColumns } from "./TableColumns";

type PayrollReportsTableProps = { data: Array<FormattedPayrollReportDataType>; filter: string; payrollWeek: Date };

export const PayrollReportsTable = ({ data, filter, payrollWeek }: PayrollReportsTableProps) => {
  const columns = React.useMemo(() => TableColumns, []);
  const navigate = useNavigate();
  const contextData = { payrollWeek }

  const onCellContentClick = (columnId: string, row: Row<FormattedPayrollReportDataType>) => {
    if (columnId === "company") {
      navigate(`../${row.original.internalCompanyId}`, { state: contextData});
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setFilter } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
  );

  useEffect(() => {
    setFilter("company", filter);
  }, [filter]);

  return (
    <div data-testid="payroll-reports-table">
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupKey = headerGroup.getHeaderGroupProps().key;

            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupKey}>
                {headerGroup.headers.map((column) => {
                  const columnKey = column.getHeaderProps().key;

                  return (
                    <th
                      {...column.getHeaderProps([
                        { className: column.className },
                        column.getSortByToggleProps(),
                      ])}
                      key={`${headerGroupKey}-${columnKey}`}
                    >
                      <FlexRow className={column.className}>
                        {column.render("Header")}
                        <SortingContainer isSorted={column.isSorted}>
                          <TableColumnSortIcon isAscending={!column.isSortedDesc} />
                        </SortingContainer>
                      </FlexRow>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <React.Fragment key={row.original.internalCompanyId}>
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const cellKey = cell.getCellProps().key;

                    return (
                      <td
                        {...cell.getCellProps([{ className: cell.column.className }])}
                        key={cellKey}
                        onClick={() => onCellContentClick(cell.column.id, row)}
                      >
                        {cell.render("Cell", {
                          rowData: { ...row.original },
                        })}
                      </td>
                    );
                  })}
                </TableRow>
              </React.Fragment>
            );
          })}
        </tbody>
      </StyledTable>
    </div>
  );
};
