import React, { useMemo } from "react";
import { useTable, useSortBy, Column } from "react-table";
import { SortingContainer } from "../../../../components/ReactTable/SortingContainer";
import { FlexRow, StyledTable } from "./styledComponents";
import { TableColumnSortIcon } from "../../../../svg/TableColumnSortIcon";
import { TableColumns } from "./TableColumns";
import { TableStageRow } from "./TableStageRow";
import { TimesheetTabs } from "../../types/Payroll.types";
import { TimesheetsShiftInfo } from "../../types/Shift.types";

export type TimesheetsTableStageProps = {
  data: TimesheetsShiftInfo[];
  activeIndex: TimesheetTabs;
};

/**
 * This is the inner table composed of columns / cells generated from the raw payroll data
 * The outer collapsible table will be created by TODO: BW-1280 https://bluecrewjobs.atlassian.net/browse/BW-1280
 */
export const TimesheetsTableStage = ({ data, activeIndex }: TimesheetsTableStageProps) => {
  const columns = useMemo<Column<TimesheetsShiftInfo>[]>(() => TableColumns, [activeIndex]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  return (
    <>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupKey = headerGroup.getHeaderGroupProps().key;

            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupKey}>
                {headerGroup.headers.map((column) => {
                  const columnKey = column.getHeaderProps().key;

                  return (
                    <th
                      {...column.getHeaderProps([
                        { className: column.className },
                        column.getSortByToggleProps(),
                      ])}
                      key={`${headerGroupKey}-${columnKey}`}
                    >
                      <FlexRow className={column.className}>
                        {column.render("Header")}
                        <SortingContainer isSorted={column.isSorted}>
                          <TableColumnSortIcon isAscending={!column.isSortedDesc} />
                        </SortingContainer>
                      </FlexRow>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const key = `${row.original.job.externalId}-${row.original.job.shiftIndex}-${row.original.user.externalId}`;
            return <TableStageRow key={key} row={row} activeIndex={activeIndex} />;
          })}
        </tbody>
      </StyledTable>
    </>
  );
};
