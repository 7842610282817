import { useMutation } from "@tanstack/react-query";
import QueryKeys from "./queryKeys";
import { queryClient } from "../../../queryClient";
import {
  createAndInviteNewUser,
  CreateNewUserRequest,
  createSupervisorUser,
  CreateSupervisorUserRequest,
  editClientRole,
  EditClientRoleRequest,
  updateUsersCompanies,
  UpdateUsersCompaniesRequest,
  UpdateUsersCompaniesAndRoleRequest,
  resendInvite,
} from "../user";
import { FormikActions } from "formik";

export type CreateSupervisorUserMutateRequest = {
  values: CreateSupervisorUserRequest;
  actions?: FormikActions<CreateSupervisorUserRequest>;
};

export const useCreateSupervisorUser = (
  onSuccess: (data: any, variables: CreateSupervisorUserMutateRequest) => void,
  onError: (error: any) => void,
) =>
  useMutation({
    mutationKey: QueryKeys.Signup_CreateSupervisorUser,
    mutationFn: async (request: CreateSupervisorUserMutateRequest) => {
      return createSupervisorUser(request.values);
    },
    onSuccess,
    onError,
    retry: false,
  });

export const useEditClientRole = (onSuccess: () => void) =>
  useMutation({
    mutationKey: QueryKeys.ManageUsers_EditRole,
    mutationFn: async (request: EditClientRoleRequest) => {
      await editClientRole(request);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ManageUsers_Users]);
      onSuccess();
    },
    retry: 3,
  });

export type UseCreateAndInviteNewUserError = {
  reason: string;
  statusCode: number;
};

type UseCreateAndInviteNewUserOptions = {
  onSuccess: (newUserId: string) => void;
  onError: (error: UseCreateAndInviteNewUserError) => void;
};

export const useCreateAndInviteNewUser = ({
  onSuccess,
  onError,
}: UseCreateAndInviteNewUserOptions) =>
  useMutation({
    mutationKey: QueryKeys.ManageUsers_AddNewUser,
    mutationFn: async (request: CreateNewUserRequest) => {
      return createAndInviteNewUser(request);
    },
    onSuccess: (newUserId) => {
      queryClient.invalidateQueries([QueryKeys.ManageUsers_Users]);
      onSuccess(newUserId);
    },
    onError: (error) => {
      onError(error as UseCreateAndInviteNewUserError);
    },
    retry: false,
  });

type UseUpdateUsersCompaniesOptions = {
  onSuccess: (status: string | number) => void;
  onError: (error: Error) => void;
};

export const useUpdateUsersCompanies = ({ onSuccess, onError }: UseUpdateUsersCompaniesOptions) =>
  useMutation({
    mutationKey: QueryKeys.ManageUsers_UpdateUsersCompanies,
    mutationFn: async (request: UpdateUsersCompaniesRequest) => {
      return updateUsersCompanies(request);
    },
    onSuccess: (status) => {
      queryClient.invalidateQueries([QueryKeys.ManageUsers_Users]);
      onSuccess(status);
    },
    onError: (error) => {
      onError(error as Error);
    },
    retry: 3,
  });

type UseUpdateUsersCompaniesAndRoleOptions = {
  onSuccess: (status: string) => void;
  onError: (error: Error) => void;
};

export const useUpdateUsersCompaniesAndRole = ({
  onSuccess,
  onError,
}: UseUpdateUsersCompaniesAndRoleOptions) =>
  useMutation({
    mutationKey: QueryKeys.ManageUsers_UpdateUsersCompanies,
    mutationFn: async ({
      updateUsersCompaniesData,
      editClientRoleData,
    }: UpdateUsersCompaniesAndRoleRequest) => {
      await updateUsersCompanies(updateUsersCompaniesData);
      await editClientRole(editClientRoleData);
      // TODO: if one of the requests fails, we should rollback the other one or retry
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([QueryKeys.ManageUsers_Users]);
      onSuccess(variables.editClientRoleData.clientType);
    },
    onError: (error) => {
      onError(error as Error);
    },
    retry: 3,
  });

type Options = {
  onSuccess?: (messsage: string) => void;
  onError?: (error: Error) => void;
};

export const useResendAccountInvite = ({ onSuccess, onError }: Options = {}) =>
  useMutation({
    mutationKey: ["ResendAccountInvite"],
    mutationFn: (input: { email: string }) => resendInvite(input.email),
    onSuccess: (data) => onSuccess && onSuccess(data.message ?? "Message was undefined"),
    onError,
  });
