import React, { useRef, useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { ActionColumnButtonWrapper } from "../styledComponents";
import {
  getPayrollActionKeyPrefix,
  useExcuseShiftMutation,
} from "../../../../../api/bluecrew/hooks/payroll";
import { ActionButton, ActionButtonIcon } from "./styledComponents";
import { Toast } from "primereact/toast";
import { ActionButtonIconPathPrefix } from "./index";
import { PayrollStatusReason } from "../../../types/Payroll.types";
import { RemoveModal } from "../ShiftReject/RemoveModal";
import { buildShiftKey } from "../../../shiftUtils";
import { Tooltip } from "../../../../../components/Tooltip";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";

export type ButtonExcuseProps = {
  disabled: boolean;
  rowData: TimesheetsShiftInfo;
};

export const ButtonExcuse = ({ disabled, rowData }: ButtonExcuseProps) => {
  const [isExcuseVisible, setIsExcuseVisible] = useState<boolean>(false);
  const { user, job } = rowData;

  const adjustmentCodes = [
    PayrollStatusReason.FAMILY_EMERGENCY,
    PayrollStatusReason.HOLIDAY,
    PayrollStatusReason.CLIENT_CANCELLED,
    PayrollStatusReason.SICK,
    PayrollStatusReason.TRANSPORT_ISSUE,
  ];

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const shiftKey = buildShiftKey(rowData);
  const tooltipAnchor = `excuse-${shiftKey.cmId}-${shiftKey.jobId}-${shiftKey.shiftIndex}`;
  const { mutate } = useExcuseShiftMutation(shiftKey, showErrorToast);
  const isAnyPayrollActionMutating = !!useIsMutating(getPayrollActionKeyPrefix(shiftKey));

  return (
    <ActionColumnButtonWrapper>
      <Toast ref={toast} />
      <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
        {"Excuse"}
      </Tooltip>
      <ActionButton
        className={tooltipAnchor}
        disabled={disabled || isAnyPayrollActionMutating}
        icon={<ActionButtonIcon src={`${ActionButtonIconPathPrefix}/excuse.svg`} />}
        rounded
        outlined
        onClick={() => setIsExcuseVisible(true)}
      />
      <RemoveModal
        isRemoveModalVisible={isExcuseVisible}
        setIsRemoveModalVisible={setIsExcuseVisible}
        headerText={"Excuse Timestamps"}
        footerButtonLabel={"Excuse"}
        shiftInfo={rowData}
        adjustmentCodes={adjustmentCodes}
        removeShiftHandler={(reason: PayrollStatusReason) =>
          mutate({
            userId: user.externalId,
            jobId: job.externalId,
            shiftIndex: job.shiftIndex,
            reason,
          })
        }
      />
    </ActionColumnButtonWrapper>
  );
};
