import { Navigate, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { webV1Url } from "@bluecrew/web-react-core/src/constants";
import { TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME } from "../../shared/constants";
import { getTreatmentValue } from "../redux/selectors/splitio";
import { Timesheets } from "../containers/Payroll/Timesheets";
import { PayrollReports } from "../containers/Payroll/PayrollReports";
import { getUserCompany, getUserType } from "../redux/selectors/auth";
import { TimesheetSupportUserTypes } from "../containers/Payroll/types/Payroll.types";
import { useAppSelector } from "../redux";

export const PayrollRoutes = () => {
  const showTimesheetsInReact = useAppSelector((state) =>
    getTreatmentValue(state, TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME),
  );
  const userType = useAppSelector(getUserType);
  const companyId = useAppSelector(getUserCompany);

  useEffect(() => {
    // Redirect to the old timesheets page (web-angular) if timesheets split isn't active
    if (showTimesheetsInReact === "off") {
      window.location.href = `${webV1Url}app.html#!/payroll/company`;
    }
  }, [showTimesheetsInReact]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          TimesheetSupportUserTypes.includes(userType) ? (
            <PayrollReports />
          ) : (
            <Navigate to={`../${companyId}`} />
          )
        }
      />
      <Route path="/:internalCompanyId" element={<Timesheets />} />
    </Routes>
  );
};
