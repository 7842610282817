import React from "react";

export default () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.2" cx="24" cy="24" r="24" fill="#00B2FF" />
    <path
      d="M30.707 21.207L31.707 20.207C31.8025 20.1148 31.8787 20.0044 31.9311 19.8824C31.9835 19.7604 32.0111 19.6292 32.0122 19.4964C32.0134 19.3636 31.9881 19.232 31.9378 19.1091C31.8875 18.9862 31.8133 18.8745 31.7194 18.7806C31.6255 18.6867 31.5138 18.6125 31.3909 18.5622C31.268 18.5119 31.1364 18.4866 31.0036 18.4878C30.8708 18.4889 30.7396 18.5165 30.6176 18.5689C30.4956 18.6213 30.3852 18.6975 30.293 18.793L29.293 19.793C29.2001 19.886 29.0898 19.9598 28.9684 20.0101C28.847 20.0604 28.7169 20.0863 28.5855 20.0863C28.4541 20.0863 28.3239 20.0604 28.2025 20.0101C28.0811 19.9598 27.9709 19.886 27.878 19.793C27.3065 19.2476 26.5469 18.9433 25.757 18.9433C24.967 18.9433 24.2074 19.2476 23.636 19.793C23.4485 19.9805 23.1941 20.0858 22.929 20.0858C22.6638 20.0858 22.4095 19.9805 22.222 19.793C21.6504 19.2475 20.8906 18.9432 20.1005 18.9432C19.3104 18.9432 18.5506 19.2475 17.979 19.793L16.979 20.793C16.7915 20.9807 16.6862 21.2351 16.6863 21.5004C16.6864 21.7656 16.7918 22.02 16.9795 22.2075C17.1671 22.395 17.4216 22.5003 17.6868 22.5002C17.9521 22.5001 18.2065 22.3947 18.394 22.207L19.394 21.207C19.5845 21.0252 19.8377 20.9238 20.101 20.9238C20.3643 20.9238 20.6175 21.0252 20.808 21.207C21.3706 21.7694 22.1335 22.0854 22.929 22.0854C23.7245 22.0854 24.4874 21.7694 25.05 21.207C25.2405 21.0252 25.4937 20.9238 25.757 20.9238C26.0203 20.9238 26.2735 21.0252 26.464 21.207C26.7426 21.4856 27.0733 21.7067 27.4373 21.8575C27.8013 22.0083 28.1915 22.0859 28.5855 22.0859C28.9795 22.0859 29.3696 22.0083 29.7336 21.8575C30.0977 21.7067 30.4284 21.4856 30.707 21.207Z"
      fill="#3246F0"
    />
    <path
      d="M23.636 25.793C23.4485 25.9805 23.1941 26.0858 22.929 26.0858C22.6638 26.0858 22.4095 25.9805 22.222 25.793C21.6504 25.2475 20.8906 24.9432 20.1005 24.9432C19.3104 24.9432 18.5506 25.2475 17.979 25.793L16.979 26.793C16.7915 26.9806 16.6862 27.2351 16.6863 27.5003C16.6864 27.7656 16.7918 28.02 16.9795 28.2075C17.1671 28.395 17.4216 28.5003 17.6868 28.5002C17.9521 28.5001 18.2065 28.3946 18.394 28.207L19.394 27.207C19.5845 27.0252 19.8377 26.9237 20.101 26.9237C20.3643 26.9237 20.6175 27.0252 20.808 27.207C21.3706 27.7694 22.1335 28.0853 22.929 28.0853C23.7245 28.0853 24.4874 27.7694 25.05 27.207C25.2321 27.0184 25.3329 26.7658 25.3307 26.5036C25.3284 26.2414 25.2232 25.9906 25.0378 25.8052C24.8524 25.6198 24.6016 25.5146 24.3394 25.5123C24.0772 25.51 23.8246 25.6108 23.636 25.793Z"
      fill="#3246F0"
    />
    <path
      d="M36 14C36 13.4696 35.7893 12.9609 35.4142 12.5858C35.0391 12.2107 34.5304 12 34 12H14C13.4696 12 12.9609 12.2107 12.5858 12.5858C12.2107 12.9609 12 13.4696 12 14V34C12 34.5304 12.2107 35.0391 12.5858 35.4142C12.9609 35.7893 13.4696 36 14 36H28C28.2652 35.9999 28.5195 35.8946 28.707 35.707L35.707 28.707C35.8946 28.5195 35.9999 28.2652 36 28V14ZM14 14.5C14 14.3674 14.0527 14.2402 14.1464 14.1464C14.2402 14.0527 14.3674 14 14.5 14H33.5C33.6326 14 33.7598 14.0527 33.8536 14.1464C33.9473 14.2402 34 14.3674 34 14.5V27C34 27.1326 33.9473 27.2598 33.8536 27.3536C33.7598 27.4473 33.6326 27.5 33.5 27.5H29.5C28.9696 27.5 28.4609 27.7107 28.0858 28.0858C27.7107 28.4609 27.5 28.9696 27.5 29.5V33.5C27.5 33.6326 27.4473 33.7598 27.3536 33.8536C27.2598 33.9473 27.1326 34 27 34H14.5C14.3674 34 14.2402 33.9473 14.1464 33.8536C14.0527 33.7598 14 33.6326 14 33.5V14.5Z"
      fill="#3246F0"
    />
  </svg>
);
