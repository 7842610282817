import React, { ForwardedRef, forwardRef, Ref } from "react";
import { Weekdays } from "@bluecrew/blueprint-web/src/components/Weekdays";
import styled from "styled-components";
import { RichListItem } from "./RichListItem";
import {
  ComponentPosition,
  ListItemRendererT,
  ScheduleListItemProps,
  ScheduleListItemT,
} from "./types";
import { StyledSubtitle, StyledTitle, StyledWeekdaysContainer } from "./styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  align-items: center;
`;

const TextContainer = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #33343d;
  padding-right: 20px;
  margin-right: 16px;
`;

const ScheduleListItemCmp = <T extends ScheduleListItemT>(
  { item, onClick, underlineLastItem }: ScheduleListItemProps<T>,
  ref: Ref<HTMLLIElement>,
) => (
  <RichListItem<T>
    ref={ref}
    item={item}
    checkboxPosition={ComponentPosition.LEFT}
    onClick={onClick}
    titleRenderer={titleRenderer}
    subtitleRenderer={subtitleRenderer}
    underlineLastItem={underlineLastItem}
    extraInfoRendererRight={extraInfoRendererRight}
    showCheckBox={false}
  />
);

const titleRenderer: ListItemRendererT<ScheduleListItemT> = (listItem) => (
  <StyledTitle
    $style={{
      marginRight: "0px",
      marginLeft: "8px",
      overflowWrap: "break-word",
      whiteSpace: "unset",
      paddingRight: "32px",
    }}
  >
    {listItem.title}
  </StyledTitle>
);

const subtitleRenderer: ListItemRendererT<ScheduleListItemT> = (listItem) => {
  const totalWage = listItem.totalWage ? parseFloat(listItem.totalWage) : 0;
  const positionWage = listItem.positionWage ? parseFloat(listItem.positionWage) : 0;

  const additionalPay = totalWage - positionWage;

  return (
    <StyledSubtitle $style={{ marginRight: "0px", marginLeft: "8px" }}>
      {additionalPay > 0 ? `Extra pay: $${additionalPay}` : ``}
    </StyledSubtitle>
  );
};

const extraInfoRendererRight: ListItemRendererT<ScheduleListItemT> = (listItem) => (
  <Container>
    <TextContainer>{listItem.workingHours}</TextContainer>
    <StyledWeekdaysContainer>
      <Weekdays selected={listItem.workingDays} editable={false} />
    </StyledWeekdaysContainer>
  </Container>
);

export const ScheduleListItem = forwardRef(ScheduleListItemCmp) as <T extends ScheduleListItemT>(
  props: ScheduleListItemProps<T> & { ref?: ForwardedRef<HTMLLIElement> },
) => JSX.Element;
