import {
  BreakDto,
  ShiftBreakDto,
  ShiftTimestampDto,
  TimestampDto,
  UserDto,
  UserShiftDto,
  UserShiftLastUpdateByDto,
} from "@bluecrew/finance-service-node-client";
import {
  BreakTimestamps,
  ShiftBreak,
  ShiftComponent,
  ShiftTimestamp,
  SupportAction,
  TimesheetsUserInfo,
  UserShift,
  UserShiftLastUpdateBy,
  WorkplaceAction,
} from "../types/Shift.types";
import { UserTimestamp } from "../../../api/bluecrew/payroll";
import { PayrollHoursStatus, PayrollStatusReason, TimestampType } from "../types/Payroll.types";
import { TimestampAttributeName } from "../shiftUtils";
import { UserType } from "../../../api/bluecrew/types";

export const mapBreakTimestamps = (dto: BreakDto): BreakTimestamps => {
  return {
    start: mapUserTimestamp(dto.clockIn)!,
    end: mapUserTimestamp(dto.clockOut)!,
  };
};

export const mapShiftBreak = (dto: ShiftBreakDto): ShiftBreak => {
  return {
    type: ShiftComponent[dto.type],
    attributes: dto.attributes.map((e) => TimestampAttributeName[e]),
    durationMinutes: dto.durationMinutes,
    breakTimestamps: dto.breakTimestamps.map((bts) => mapBreakTimestamps(bts)),
    isDisputedDurationMissing: dto.isDisputedDurationMissing,
    durationInDispute: dto.durationInDispute ?? undefined,
  };
};

export const mapShiftTimestamp = (dto: ShiftTimestampDto): ShiftTimestamp => {
  return {
    type: ShiftComponent[dto.type],
    attributes: dto.attributes.map((e) => TimestampAttributeName[e]),
    time: dto.time,
    isDisputedTimeMissing: dto.isDisputedTimeMissing,
    userTimestamp: mapUserTimestamp(dto.userTimestamp),
    timeInDispute: dto.timeInDispute,
  };
};

export const mapUser = (dto: UserDto): TimesheetsUserInfo => {
  return dto as TimesheetsUserInfo; // same structure
};

export const mapUserShift = (dto: UserShiftDto): UserShift => {
  return {
    start: mapShiftTimestamp(dto.start),
    end: mapShiftTimestamp(dto.end),
    break: mapShiftBreak(dto.break),
    allowedWorkplaceActions: dto.allowedWorkplaceActions.map((e) => WorkplaceAction[e]),
    allowedSupportActions: dto.allowedSupportActions.map((e) => SupportAction[e]),
    isAutoApproved: dto.isAutoApproved,
    lastUpdateBy: mapUserShiftLastUpdateBy(dto.lastUpdateBy),
    status: PayrollHoursStatus[dto.status],
    statusReason: dto.statusReason ? PayrollStatusReason[dto.statusReason] : undefined,
  };
};

export const mapUserShiftLastUpdateBy = (
  dto: UserShiftLastUpdateByDto | undefined,
): UserShiftLastUpdateBy | undefined => {
  if (dto) {
    return {
      name: dto.name,
      userType: UserType[dto.userType],
      updatedAt: dto.updatedAt,
    };
  }
  return undefined;
};

export const mapUserTimestamp = (dto: TimestampDto | undefined): UserTimestamp | undefined => {
  if (dto) {
    return {
      id: dto.id,
      time: dto.time,
      type: TimestampType[dto.type!],
      distance: toNullable(dto.distance),
      latitude: toNullable(dto.latitude),
      longitude: toNullable(dto.longitude),
      isUserEdited: dto.isEdited === true,
      noPhoneClock: dto.isNoPhoneClock === true,
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
    };
  }
  return undefined;
};

function toNullable<T>(value: T | undefined): T | null {
  return value !== undefined ? value : null;
}
