// The typescript in this file is very poorly defined leading to mostly ignore statements
// SCHEDULE - SELECTORS
// =============================================================================
import { createSelector } from "reselect";
import moment from "moment-timezone";
import { cloneDeep, get, orderBy, uniqBy } from "lodash";
import { StateShape } from "../reducers";
import { ScheduleException } from "../../api/bluecrew/types";
import { convertDecimalToPercent, formatPhone } from "../../utility";

export const getSchedule = (state: StateShape) =>
  get(state, "schedule.fetchSchedule.success.schedule") || {
    timezone: "",
    schedule_id: NaN,
    end_date_time: "",
    start_date_time: "",
    shifts: [],
    work_request_name: "",
    supervisor: [],
    total_work_requests: [],
    request_log: [],
    request: [],
    roster: {
      active: [],
      upcoming: [],
    },
  };

export const getScheduleExceptions = (state: StateShape): null | ScheduleException[] =>
  state.schedule.readScheduleExceptions.success
    ? state.schedule.readScheduleExceptions.success.scheduleexceptions
    : null;

export const getIsFetching = (state: StateShape) =>
  get(state, "schedule.fetchSchedule.isFetching", false);

export const getIsCreatingScheduleException = (state: StateShape) =>
  get(state, "schedule.createScheduleException.isFetching", false);

export const getUpdateScheduleSuccessResponse = (state: StateShape) =>
  get(state, "schedule.updateSchedule.success") || null;

export const getUpdateScheduleFailureResponse = (state: StateShape) =>
  get(state, "schedule.updateSchedule.failure") || null;

export const getUpdateScheduleIsFetching = (state: StateShape) =>
  get(state, "schedule.updateSchedule.isFetching") || null;

export const getOpenApplicationJobsInProgress = (state: StateShape) =>
  get(state, "schedule.openApplicationJobsToPlatform.isFetching") || null;

/**
 * Returns the default start time of a shift under that schedule (military time).
 * e.g. 08:00
 */
const getScheduleStartTime = createSelector([getSchedule], (schedule) =>
  get(schedule, "shifts.0.start_time"),
);

/**
 * Returns the default end time of a shift under that schedule (military time).
 * e.g. 16:00
 */
const getScheduleEndTime = createSelector([getSchedule], (schedule) =>
  get(schedule, "shifts.0.end_time"),
);

// reselect function
// @ts-ignore $FlowFixReselect
export const getFormattedSchedule = createSelector(
  [getSchedule, getScheduleStartTime, getScheduleEndTime],
  // @ts-ignore TODO: BW-1482 Fix this error
  (schedule, startTimeFromSelector, endTimeFromSelector) => {
    const scheduleClone = cloneDeep(schedule);
    // A simple check for timezone, if null we are setting a default
    const timezone = get(scheduleClone, "timezone") || "America/Los_Angeles";
    const displayTz = moment.tz(timezone).format("z") || "PST";

    if (scheduleClone) {
      // @ts-ignore TODO: BW-1482 Fix this error
      const formattedPhoneNumber = formatPhone(scheduleClone.supervisor.phone);
      let startTime = startTimeFromSelector;
      let endTime = endTimeFromSelector;

      if (startTime && endTime) {
        // @ts-ignore TODO: BW-1482 Fix this error
        startTime = moment(`1970-01-01T${startTime}`).format("h:mma");
        // @ts-ignore TODO: BW-1482 Fix this error
        endTime = moment(`1970-01-01T${endTime}`).format("h:mma");
      } else {
        // XXX seems impossible, but we should probably handle that case better
      }

      return {
        ...scheduleClone,
        displayTimeZone: displayTz,
        start_date_time: {
          date: moment(scheduleClone.start_date_time).tz(timezone).format("ddd, MMM D YYYY"),
          time: startTime,
        },
        end_date_time: {
          date: moment(scheduleClone.end_date_time).tz(timezone).format("ddd, MMM D YYYY"),
          time: endTime,
        },
        request_log: scheduleClone.request,
        supervisor: {
          ...scheduleClone.supervisor,
          phone: formattedPhoneNumber,
        },
      };
    }
  },
);

const getScheduleWorkers = (state: StateShape) =>
  get(state, "schedule.readScheduleWorkers.success") || [];

export type ScheduleWorkers = Array<{
  eventAppliedJobId: number;
  external_id: string;
  firstName: string;
  lastName: string;
  profilePic: string | null;
  user_id: number;
}>;

// @ts-ignore $FlowFixReselect
export const getUsersForCrewPicker = createSelector(getScheduleWorkers, (workers) => {
  // @ts-ignore TODO: BW-1482 Fix this error
  const members: ScheduleWorkers = workers.map((worker) => ({
    eventAppliedJobId: worker.EventJobApplied.job_id,
    external_id: worker.external_id,
    firstName: worker.data.first_name,
    lastName: worker.data.last_name,
    // TODO: there is no avatar/picture url on the worker object
    profilePic: get(worker.data, "avatar.url"),
    user_id: worker.id,
  }));

  return uniqBy(members, "external_id");
});

const getCurrentAndUpcomingRequests = createSelector(getSchedule, (schedule) =>
  schedule.request.filter((job) =>
    // @ts-ignore TODO: BW-1482 Fix this error
    moment(job.end_date_time).isSameOrAfter(moment()),
  ),
);

const getDsbdWorkers = createSelector(getSchedule, (schedule) => {
  let dsbdWorkers = [];
  schedule?.request?.forEach((request) => {
    // Only Show DSB'd workers if the end date for the request
    // they are associated with is in the future
    // @ts-ignore TODO: BW-1482 Fix this error
    if (moment(request.end_date_time).isSameOrAfter(moment())) {
      // @ts-ignore TODO: BW-1482 Fix this error
      dsbdWorkers = dsbdWorkers.concat(request.rosterAndWaitlist?.dsbdWorkers);
    }
  });
  return dsbdWorkers;
});

const getOpenDirectInvitees = createSelector(getSchedule, (schedule) => {
  const openDirectInvitees: any = [];
  schedule?.request?.forEach((request) => {
    // Only Show Open Direct invited workers if the end date for the request
    // they are associated with is in the future
    if (
      // @ts-ignore TODO: BW-1482 Fix this error
      moment(request.end_date_time).isSameOrAfter(moment()) &&
      // @ts-ignore TODO: BW-1482 Fix this error
      request.rosterAndWaitlist?.openDirectInvitees?.length > 0
    ) {
      openDirectInvitees.push(
        // @ts-ignore TODO: BW-1482 Fix this error
        ...request.rosterAndWaitlist.openDirectInvitees.map((member) => ({
          ...member,
          // @ts-ignore TODO: BW-1482 Fix this error
          end_date: request.end_date,
          // @ts-ignore TODO: BW-1482 Fix this error
          start_date: request.start_date,
          // @ts-ignore TODO: BW-1482 Fix this error
          start_date_time: request.start_date_time,
          // @ts-ignore TODO: BW-1482 Fix this error
          end_date_time: request.end_date_time,
        })),
      );
    }
  });
  return openDirectInvitees;
});

export const getScheduleIsActive = createSelector(
  getCurrentAndUpcomingRequests,
  (requests) => requests.length > 0,
);

// response body from GET / schedules /:id returns applicant data categorized by work
// request dates.We want the data organized by type(roster, waitlist, declined offers,
// accepted offers, no offer)
// This selector takes the response body and returns an array of applicant / waitlist user objects with their associated work request dates
// @ts-ignore $FlowFixReselect
const getRosterAndWaitlistWithDates = createSelector(getCurrentAndUpcomingRequests, (requests) => {
  const rosterAndWaitlistWithDates = {
    applicantsAcceptedOffers: [] as any,
    applicantsDeclinedOffers: [] as any,
    applicantsWithOffers: [] as any,
    applicantsWithoutOffers: [] as any,
    curatedWorkers: [] as any,
  };

  // format the current requests
  requests.forEach((job) => {
    const { end_date, start_date, start_date_time, end_date_time, id: jobId } = job;

    // adding start_date and end_date to applicant objects
    // and adding updated objects to the final return hash
    rosterAndWaitlistWithDates.applicantsAcceptedOffers.push(
      // @ts-ignore TODO: BW-1482 Fix this error
      ...job.rosterAndWaitlist.applicantsAcceptedOffers.map((applicant) => ({
        ...applicant,
        rating: convertDecimalToPercent(applicant.rating),
        start_date,
        end_date,
        jobId,
        start_date_time,
        end_date_time,
      })),
    );

    rosterAndWaitlistWithDates.applicantsDeclinedOffers.push(
      // @ts-ignore TODO: BW-1482 Fix this error
      ...job.rosterAndWaitlist.applicantsDeclinedOffers.map((applicant) => ({
        ...applicant,
        rating: convertDecimalToPercent(applicant.rating),
        start_date,
        end_date,
        jobId,
        start_date_time,
        end_date_time,
      })),
    );

    rosterAndWaitlistWithDates.applicantsWithOffers.push(
      // @ts-ignore TODO: BW-1482 Fix this error
      ...job.rosterAndWaitlist.applicantsWithOffers.map((applicant) => ({
        ...applicant,
        rating: convertDecimalToPercent(applicant.rating),
        start_date,
        end_date,
        jobId,
        start_date_time,
        end_date_time,
      })),
    );

    rosterAndWaitlistWithDates.applicantsWithoutOffers.push(
      // @ts-ignore TODO: BW-1482 Fix this error
      ...job.rosterAndWaitlist.applicantsWithoutOffers.map((applicant) => ({
        ...applicant,
        rating: convertDecimalToPercent(applicant.rating),
        start_date,
        end_date,
        jobId,
        start_date_time,
        end_date_time,
      })),
    );

    rosterAndWaitlistWithDates.curatedWorkers.push(
      // @ts-ignore TODO: BW-1482 Fix this error
      ...job.rosterAndWaitlist.curatedWorkers.map((applicant) => ({
        ...applicant,
        rating: convertDecimalToPercent(applicant.rating),
        start_date,
        end_date,
        jobId,
        start_date_time,
        end_date_time,
      })),
    );
  });

  return rosterAndWaitlistWithDates;
});

// @ts-ignore $FlowFixReselect
export const getApplicantsWithoutOffers = createSelector(
  getRosterAndWaitlistWithDates,
  (rosterAndWaitlistWithDates) => get(rosterAndWaitlistWithDates, "applicantsWithoutOffers"),
);

// @ts-ignore $FlowFixReselect
export const getApplicantsDeclinedOffers = createSelector(
  getRosterAndWaitlistWithDates,
  (rosterAndWaitlistWithDates) => get(rosterAndWaitlistWithDates, "applicantsDeclinedOffers"),
);

// @ts-ignore $FlowFixReselect
export const getApplicantsAcceptedOffers = createSelector(
  getRosterAndWaitlistWithDates,
  (rosterAndWaitlistWithDates) => get(rosterAndWaitlistWithDates, "applicantsAcceptedOffers"),
);

// @ts-ignore $FlowFixReselect
export const getApplicantsWithOffers = createSelector(
  getRosterAndWaitlistWithDates,
  (rosterAndWaitlistWithDates) => get(rosterAndWaitlistWithDates, "applicantsWithOffers"),
);

// Selector to get an array of the current roster of workers (curated workers and applicants that have accepted offers) for a schedule
// @ts-ignore $FlowFixReselect
export const getRoster = createSelector(
  getRosterAndWaitlistWithDates,
  getApplicantsAcceptedOffers,
  (rosterAndWaitlistWithDates, applicantsAcceptedOffers) => {
    let curatedWorkersAndApplicantsAcceptedOffers = [];

    const curatedWorkers = get(rosterAndWaitlistWithDates, "curatedWorkers");

    // @ts-ignore
    curatedWorkersAndApplicantsAcceptedOffers = [...curatedWorkers, ...applicantsAcceptedOffers];

    return curatedWorkersAndApplicantsAcceptedOffers;
  },
);

export const getRosterActiveAndUpcoming = createSelector(
  getRoster,
  getDsbdWorkers,
  getOpenDirectInvitees,
  (roster, dsbdWorkers, openDirectInvitees) => {
    const activeAndUpcoming = {
      active: [] as any,
      upcoming: [] as any,
      dontSendBack: [] as any,
      openDirectInvitees: [] as any,
    };
    roster.forEach((crewMember: any) => {
      if (moment().isBetween(crewMember.start_date_time, crewMember.end_date_time)) {
        activeAndUpcoming.active.push(crewMember);
      }

      if (moment().isBefore(crewMember.start_date_time)) {
        activeAndUpcoming.upcoming.push(crewMember);
      }
    });

    if (dsbdWorkers?.length) {
      activeAndUpcoming.dontSendBack = dsbdWorkers;
    }

    if (openDirectInvitees?.length) {
      activeAndUpcoming.openDirectInvitees = openDirectInvitees;
    }

    // sorting active and upcoming arrays by start date and user id
    activeAndUpcoming.active = orderBy(
      activeAndUpcoming.active,
      ["worker_start_date", "id"],
      ["asc", "asc"],
    );

    activeAndUpcoming.upcoming = orderBy(
      activeAndUpcoming.upcoming,
      ["worker_start_date", "id"],
      ["asc", "asc"],
    );

    return activeAndUpcoming;
  },
);

// @ts-ignore $FlowFixReselect
export const getCurrentTotalRequested = createSelector(
  getCurrentAndUpcomingRequests,
  // @ts-ignore TODO: BW-1482 Fix this error
  (requests) => requests.reduce((total, request) => total + request.request, 0),
);

// @ts-ignore $FlowFixReselect
export const getCurrentTotalFilled = createSelector(getRoster, (roster) => roster.length);
