import styled from "styled-components";
import { fontTheme, colorTheme, borderTheme } from "@bluecrew/blueprint-web";

const certSpacer = "24px";
const itemContainerPadding = "14px";
const itemPadding = "10px";

export const StyledCertList = styled("div")`
  border-top: ${borderTheme.border100.borderWidth} ${borderTheme.border100.borderStyle}
    ${colorTheme.primary200};
  border-bottom: ${borderTheme.border100.borderWidth} ${borderTheme.border100.borderStyle}
    ${colorTheme.primary200};
  margin-top: ${certSpacer};
  padding-bottom: ${itemContainerPadding};

  &.is-disabled {
    pointer-events: none;
    opacity: 0.8;
  }
  .cert-list-title {
    font-family: ${fontTheme.LabelMedium.fontFamily};
    font-size: ${fontTheme.LabelMedium.fontSize};
    font-weight: ${fontTheme.LabelMedium.fontWeight};
    line-height: ${fontTheme.LabelMedium.lineHeight};
    padding-top: ${certSpacer};
  }

  .cert-list-message {
    font-family: ${fontTheme.ParagraphMedium.fontFamily};
    font-size: ${fontTheme.ParagraphMedium.fontSize};
    font-weight: ${fontTheme.ParagraphMedium.fontWeight};
    line-height: ${fontTheme.ParagraphMedium.lineHeight};
    color: ${colorTheme.granite};
  }

  ul {
    overflow: visible;

    li:first-of-type {
      padding-top: ${itemPadding};
    }
    li {
      div:nth-of-type(2) {
        border-bottom: none;
      }
      span {
        font-family: ${fontTheme.ParagraphMedium.fontFamily};
        font-size: ${fontTheme.ParagraphMedium.fontSize};
        font-weight: ${fontTheme.ParagraphMedium.fontWeight};
        line-height: ${fontTheme.ParagraphMedium.lineHeight};
      }
    }
  }
`;
