import React, { Dispatch, SetStateAction, useEffect } from "react";
import { startOfTomorrow, isBefore } from "date-fns";
import {
  TimesheetsFilterOptions,
  TableCollapseFormat,
  TimesheetsFiltersType,
  TimesheetTabs,
} from "../../types/Payroll.types";
import { TimesheetsShiftInfo } from "../../types/Shift.types";
import {
  filterTableDataByTabIndex,
  generateCollapseData,
  parsePayrollFilterOptions,
  applyPayrollFilters,
} from "../../payrollDataProcessors";
import { CollapseTab } from "./TableCollapseTab";
import { EmptySearch } from "../TimesheetsPageSearchWarning";

// TODO: FIN-407 - Add Test Coverage - https://ebpp.atlassian.net/browse/FIN-407

export const TimesheetsIconPathPrefix = `${import.meta.env.VITE_ASSET_BASE_URL}/icons/Timesheets`;

export type TimesheetsTableProps = {
  activeIndex: TimesheetTabs;
  setFilterOptions: Dispatch<SetStateAction<TimesheetsFilterOptions>>;
  tableData: TimesheetsShiftInfo[];
  tableFilters: TimesheetsFiltersType;
};

export const TimesheetsTable = ({
  activeIndex,
  setFilterOptions,
  tableData,
  tableFilters,
}: TimesheetsTableProps) => {
  const truncatedTableData = tableData.filter((value) => {
    return isBefore(value.shiftThresholds.expectedStart, startOfTomorrow());
  });
  const activeIndexData = filterTableDataByTabIndex(truncatedTableData, activeIndex);

  // filter options not reduced by active filters b.c. you can only select one at a time
  useEffect(() => {
    setFilterOptions(parsePayrollFilterOptions(activeIndexData)); // maybe reduce at later date?
  }, []);

  const filteredView = applyPayrollFilters(activeIndexData, tableFilters);

  const hasRows = !!filteredView.length;
  const hasSearchString = !!tableFilters.search.userName;

  const tabs: TableCollapseFormat[] = generateCollapseData(filteredView);

  return (
    <>
      <div>
        {tabs.map((tab) => (
          <CollapseTab key={tab.formattedDate} collapseData={tab} activeIndex={activeIndex} />
        ))}
      </div>
      {!hasRows && hasSearchString && (
        <EmptySearch path={TimesheetsIconPathPrefix} message={"No results based on your search"} />
      )}
      {!hasRows && !hasSearchString && (
        <EmptySearch path={TimesheetsIconPathPrefix} message={"No results"} />
      )}
    </>
  );
};
