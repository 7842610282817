import React from "react";
import { StyledIcon, StyledMessageSentContent } from "./styledComponents";

type MessageSentContent = {
  cmFirstName: string;
};

const iconPath = `${import.meta.env.VITE_ASSET_BASE_URL}/icons/Timesheets/message-sent.svg`;

export const MessageSentContent = ({ cmFirstName }: MessageSentContent) => (
  <StyledMessageSentContent>
    <StyledIcon src={iconPath} alt={""} />
    <h3>Message Sent</h3>
    <p>{cmFirstName} has been notified of the timestamp violation.</p>
  </StyledMessageSentContent>
);
