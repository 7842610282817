import React from 'react';
import { Option, OptionsT, Select as BaseUiSelect, SIZE } from 'baseui/select';
import { StyleObject } from 'styletron-react';
import { getBorder, getBorderRadius } from '../helpers/styleHelpers';
import { BlueprintTheme } from '../theme/blueprintTheme';
import { useThemedStyletron } from '../theme';
import { ChevronDownIcon } from '../assets';

export interface SelectProps {
  placeholder?: string;
  size?: SIZE[keyof SIZE];
  value?: ReadonlyArray<Option>;
  onChange?: (val: ReadonlyArray<Option>) => void;
  onBlur?: (e: Event) => any;
  onClose?: () => void;
  options?: OptionsT;
  searchable?: boolean;
  error?: boolean;
  maxDropdownHeight?: string;
  disabled?: boolean;
}

export const Select = (props: SelectProps) => {
  const [, $theme] = useThemedStyletron();

  const {
    placeholder,
    options,
    value,
    size = SIZE.default,
    onChange,
    onBlur,
    onClose,
    searchable = false,
    error = false,
    maxDropdownHeight,
    disabled = false,
  } = props;

  const { height } = {
    [SIZE.mini]: {
      height: $theme.sizing.scale850,
      paddingX: $theme.sizing.scale300,
    },
    [SIZE.compact]: {
      height: $theme.sizing.scale950,
      paddingX: $theme.sizing.scale500,
    },
    [SIZE.default]: {
      height: $theme.sizing.scale1200,
      paddingX: $theme.sizing.scale600,
    },
    [SIZE.large]: {
      height: $theme.sizing.scale1400,
      paddingX: $theme.sizing.scale700,
    },
  }[size];

  return (
    <BaseUiSelect
      clearable={false}
      error={error}
      searchable={searchable}
      options={options}
      value={value}
      placeholder={placeholder}
      maxDropdownHeight={maxDropdownHeight}
      onChange={(params) => onChange && onChange(params.value)}
      onBlur={onBlur}
      onClose={onClose}
      disabled={disabled}
      overrides={{
        ControlContainer: {
          style: ({ $isFocused, $error }) => ({
            ...ControlContainerStyles($theme, $isFocused, $error, height),
          }),
        },
        Dropdown: {
          style: { ...DropdownStyles($theme) },
        },
        DropdownListItem: {
          style: { ...DropdownListItemStyles($theme) },
        },
        SelectArrow: () => (
          <ChevronDownIcon color={$theme.colors.ultramarine} />
        ),
        Popover: {
          props: {
            overrides: {
              Body: {
                style: { ...PopoverBodyStyles($theme) },
              },
            },
          },
        },
      }}
    />
  );
};

const ControlContainerStyles = (
  $theme: BlueprintTheme,
  $isFocused: boolean,
  $error: boolean,
  height: string,
): StyleObject => ({
  ...getInputBorder($theme, $isFocused, $error),
  boxShadow: $isFocused ? $theme.lighting.inputShadowActive : undefined,
  height,
});

const DropdownStyles = ($theme: BlueprintTheme): StyleObject => ({
  ...getBorderRadius($theme.borders.radius200),
  outline: 'none',
});

const DropdownListItemStyles = ($theme: BlueprintTheme): StyleObject => ({
  color: $theme.colors.black,
});

const PopoverBodyStyles = ($theme: BlueprintTheme): StyleObject => ({
  marginTop: $theme.sizing.scale400,
  zIndex: 1000000,
});

function getInputBorder(
  $theme: BlueprintTheme,
  $isFocused: boolean,
  $error: boolean,
) {
  const { borders, colors } = $theme;

  if ($isFocused) {
    return getBorder({
      ...borders.border100,
      borderColor: colors.inputBorderActive,
    });
  }

  if ($error) {
    return getBorder({
      ...borders.border200,
      borderColor: colors.inputBorderError,
    });
  }
  return getBorder(borders.border100);
}
