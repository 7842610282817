import { useState } from "react";

const getID = () => Math.random().toString(36).substr(2, 9);

export const useUniqKeys = (count: number = 1) => {
  const [keys, setKeys] = useState(Array.from(Array(count)).map(() => getID()));

  return {
    keys,
    setKeys,
  };
};
