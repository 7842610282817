// JOB - API
// =============================================================================

import {
  AddWorkRequestToSchPayload,
  CreateCrewJobAndSchedPayload,
  CreateDirectInviteJobAndSchedPayload,
  PostPositionRequest,
  PostJobRequest,
  RejectApplicant,
  AddCrewJobToSchedulePayload,
  CreateBlueShiftScheduleJobPayload,
  CreateBlueShiftScheduleJobsResponse,
  UserRequirement,
  JobType,
  JobTypeResponse,
  Supervisor,
} from "./types";
import { api, apiWithErrorRedirect, apiV1WithErrorRedirect } from "./constants/index";
import { JobTypesHistoryResponse } from "../../containers/GetCrewMembers/types/JobTypesHistory.types";

export const getAllJobs = () => apiWithErrorRedirect.get("jobs").json();

// TODO: make API call
export const postPosition = (payload: PostPositionRequest) =>
  api
    .post("v2/positions", {
      json: payload.data,
    })
    .json();

export const postJob = (payload: PostJobRequest) =>
  api
    .post("v2/jobs", {
      json: payload.data,
    })
    .json();

const queryJobTypes = async (): Promise<JobTypeResponse> =>
  apiWithErrorRedirect.get("v2/jobs/types").json();

/* The following magic numbers and values are here because this is at this point in time the single case where a
  certification has extra information for a popover.  As such for speed's sake we didn't update the model, table row
  nor insert a new row for the forklift cert.  Instead we will add it here since we are already iterating the returned
  values.  If this ever changes and there are more cases of extra information pop ups, we should revisit this decision
 */
const FORK_LIFT_LABEL = "Forklift Certification";
const FORK_LIFT_INFO =
  "Workplaces are responsible to provide Crew Members the necessary site-specific powered industrial truck training and evaluation applicable to their workplace.";

export const getJobTypes = async () => {
  const jobtypesResponse = await queryJobTypes();
  jobtypesResponse.jobtypes.forEach((jobType: JobType) => {
    jobType.users_certs = [];
    jobType.users_requirements = jobType.users_requirements.filter((userReq: UserRequirement) => {
      if (userReq.appearance.is_cert) {
        if (userReq.label === FORK_LIFT_LABEL) {
          jobType.users_certs.push({
            ...userReq,
            information: FORK_LIFT_INFO,
          });
        } else {
          jobType.users_certs.push(userReq);
        }

        return false;
      }
      return true;
    });
  });
  return jobtypesResponse;
};

export const getSupervisors = (companyId?: string): Promise<Supervisor[]> => {
  const queryParam = companyId ? `?companyId=${companyId}` : "";
  return apiV1WithErrorRedirect.get(`user/getNewSupervisors${queryParam}`).json();
};

type Department = {
  tag_category: string;
  id: number;
  tag_name: string;
  company_id: number;
  created_by: number;
  created_at: string; // "2019-12-19T17:01:03.000Z",
  updated_at: string; // "2019-12-19T17:01:03.000Z",
  updated_by: number | null;
  is_temporary: number | null;
  active: number;
};

export const getDepartments = ({ companyId }): Promise<Department[]> =>
  apiV1WithErrorRedirect.get(`companies/${companyId}/tags`).json();

export const postDepartment = ({ companyId, data }): any =>
  apiV1WithErrorRedirect
    .post(`companies/${companyId}/tags`, {
      json: data,
    })
    .json();

export const addWorkRequestToSch = (payload: AddWorkRequestToSchPayload): any =>
  api
    .post(`v2/schedules/${payload.scheduleId.toString()}/work_requests`, {
      json: payload.payload,
    })
    .json();

export const offerJob = (payload) =>
  api
    .post(`v2/jobs/offer_job`, {
      json: payload.data,
    })
    .json();

export const rejectApplicationJob = (payload: RejectApplicant) =>
  api
    .post(`v2/jobs/reject_applicant`, {
      json: payload.data,
    })
    .json();

export const postDirectInviteJobAndSchedule = (
  payload: CreateDirectInviteJobAndSchedPayload,
): Promise<{ jobId: number; scheduleId: number }> =>
  api
    .post("v2/direct_invite_jobs", {
      json: payload.data,
    })
    .json();

export const postCrewJobAndSchedule = (
  payload: CreateCrewJobAndSchedPayload,
): Promise<{ jobId: number; scheduleId: number }> =>
  api
    .post("v2/crew_jobs", {
      json: payload.data,
    })
    .json();

export const addCrewJobToSchedule = (payload: AddCrewJobToSchedulePayload) =>
  api
    .post(`v2/schedules/${payload.scheduleId}/crew_jobs`, {
      json: payload.data,
    })
    .json();

export const createBlueShiftScheduleJobs = async (
  payload: CreateBlueShiftScheduleJobPayload,
): Promise<CreateBlueShiftScheduleJobsResponse> =>
  api
    .post(`v2/dashboard/${payload.companyId}/positions/${payload.positionId}/postJob`, {
      json: payload.payload,
    })
    .json();

const JOB_TYPES_HISTORY_LENGTH = 365;

export const getJobTypesHistory = (): Promise<JobTypesHistoryResponse> => {
  const url = `v2/jobs/types/history?length=${JOB_TYPES_HISTORY_LENGTH}`;
  return api.get(url).json();
};
