import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Heading, Paragraph, Button, Input, Link, UserAvatar } from "@bluecrew/web-react-core";
import PropTypes from "prop-types";
import { webV1Url } from "@bluecrew/web-react-core/src/constants";
import { OFFER_JOB, REJECT_APPLICATION } from "../../../shared/constants";
import { CovidPolicyStatus } from "../CovidPolicyStatus";

const ContentWrapper = styled.div`
  display: flex;
  padding: 30px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${p("aluminum")};
`;

const ImageWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0 16px 0px 0px;
  flex-direction: row;

  ${Input} {
    margin-right: 14px;
    margin-top: 13px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;

  ${Paragraph} {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${p("platinum")};
    width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Heading} {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${p("slate")};
    margin: 0;
    width: 100%;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

const RejectButton = styled(Button)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;
const OfferJobButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  &,
  &:hover {
    border-left: none;
  }
`;

const QuoteWrapper = styled.div`
  span {
    font-size: 14px;
    color: ${p("platinum")};
  }
`;

type ApplicantProps = {
  applicantId: number;
  applicationQuote: string;
  avatarUrl: string;
  fullName: string;
  // ratingPercentage and shiftsWorked  may change depending on api response
  ratingPercentage: number;
  shiftsWorked: number;
  workRequestDateStr: string;
  covidPolicy: Object;
  timezone?: string;
};

export class Applicant extends React.Component<ApplicantProps, any> {
  handleMessageClick = () => {
    const { applicantId } = this.props;
    window.alert(`Message Applicant with id ${applicantId}`);
  };

  openJobOfferModal = () => {
    // @ts-ignore
    const { openModal, applicantId, workRequestId, fullName } = this.props;
    // send data up to parent
    openModal(applicantId, workRequestId, fullName, OFFER_JOB);
  };

  openRejectApplicationModal = () => {
    // @ts-ignore
    const { openModal, applicantId, workRequestId, fullName } = this.props;
    // passing user data up to parent
    openModal(applicantId, workRequestId, fullName, REJECT_APPLICATION);
  };

  render() {
    const {
      applicationQuote,
      avatarUrl,
      covidPolicy,
      // @ts-ignore
      externalId,
      fullName,
      shiftsWorked,
      workRequestDateStr,
      timezone,
    } = this.props;
    const {
      // @ts-ignore
      covidVaccinationDate,
      // @ts-ignore
      covidTestingLatestDate,
      // @ts-ignore
      covidExemption,
      // @ts-ignore
    } = covidPolicy;
    const covidPolicyStatusEnabled =
      covidExemption || covidTestingLatestDate || covidVaccinationDate;
    return (
      <ContentWrapper>
        <ImageWrapper>
          {/* TODO: update styles on checkbox and uncomment when handling */}
          {/* <Input type="checkbox" /> */}
          <UserAvatar imgSrc={avatarUrl} size="xl" fullName={fullName} />
        </ImageWrapper>
        <TextWrapper>
          <HeaderWrapper>
            <HeadingWrapper>
              <Link href={`${webV1Url}/app.html#!/worker/${externalId}`} target="blank">
                <Heading as="h4">{fullName}</Heading>
              </Link>
              <Paragraph style={{ display: "flex" }}>
                {`${shiftsWorked} Shifts`}
                {covidPolicyStatusEnabled && (
                  <CovidPolicyStatus
                    // @ts-ignore
                    styles={{ marginLeft: "8px" }}
                    timezone={timezone}
                    covidVaccinationDate={covidVaccinationDate}
                    covidTestingLatestDate={covidTestingLatestDate}
                    covidExemption={covidExemption}
                  />
                )}
              </Paragraph>
            </HeadingWrapper>
            <ButtonWrapper>
              <RejectButton palette="secondary" onClick={this.openRejectApplicationModal}>
                Reject
              </RejectButton>
              <OfferJobButton palette="secondary" onClick={this.openJobOfferModal}>
                Offer job
              </OfferJobButton>
            </ButtonWrapper>
          </HeaderWrapper>
          <QuoteWrapper>
            <Paragraph>{applicationQuote ? `"${applicationQuote}"` : "No Response"}</Paragraph>
            <span>{`Applied for ${workRequestDateStr || "N/A"}`}</span>
          </QuoteWrapper>
        </TextWrapper>
      </ContentWrapper>
    );
  }
}

// @ts-ignore
Applicant.defaultProps = {
  applicantId: NaN,
  applicationQuote: "",
  avatarUrl: "",
  fullName: "Crew Member Name",
  ratingPercentage: null,
  shiftsWorked: 0,
  workRequestDateStr: "",
};

// @ts-ignore
Applicant.propTypes = {
  applicantId: PropTypes.number.isRequired,
  applicationQuote: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  // ratingPercentage and shiftsWorked  may change depending on api response
  ratingPercentage: PropTypes.number.isRequired,
  shiftsWorked: PropTypes.number.isRequired,
  workRequestDateStr: PropTypes.string.isRequired,
  covidPolicy: PropTypes.object,
  timezone: PropTypes.string,
};
