// ROOT - REDUCER
// =============================================================================
// Root collection point for all reducers. This must not ever use "export *" -
// it causes cross-environment problems.

import { splitReducer } from "@splitsoftware/splitio-redux";
import app, { AppState } from "./app";
import auth, { AuthState } from "./auth";
import job, { JobState } from "./job";
import position, { PositionState } from "./position";
import confirmationModal, { ConfirmationModalState } from "./confirmationModal";
import crewMember, { CrewMembersState } from "./crewMembers";
import schedule, { ScheduleState } from "./schedule";
import segment, { SegmentState } from "./segment";
import { SessionState } from "./session";
import minWage, { MinWageState } from "./minWage";
import company, { CompanyState } from "./company";
import {
  ManageUsersModalState,
  manageUsersModalSlice,
} from "../../containers/ManageUsers/slices/manageUsersModalSlice";
import { 
  ManageUsersFilterState, 
  manageUsersFilterSlice 
} from "../../containers/ManageUsers/slices/manageUsersFilterSlice";
import {
  ManageUsersCompanyFilterState,
  manageUsersCompanyFilterSlice,
} from "../../containers/ManageUsers/slices/CompanyFilterSlice";

export type StateShape = {
  app: AppState;
  auth: AuthState;
  confirmationModal: ConfirmationModalState;
  company: CompanyState;
  crewMember: CrewMembersState;
  job: JobState;
  minWage: MinWageState;
  position: PositionState;
  schedule: ScheduleState;
  segment: SegmentState;
  session: SessionState;
  splitio: any;
  manageUsersModal: ManageUsersModalState;
  manageUsersFilter: ManageUsersFilterState;
  manageUsersCompanyFilter: ManageUsersCompanyFilterState;
};

const rootReducer = {
  app,
  auth,
  company,
  confirmationModal,
  crewMember,
  job,
  minWage,
  position,
  schedule,
  segment,
  splitio: splitReducer,
  manageUsersModal: manageUsersModalSlice.reducer,
  manageUsersFilter: manageUsersFilterSlice.reducer,
  manageUsersCompanyFilter: manageUsersCompanyFilterSlice.reducer,
};

export default rootReducer;
