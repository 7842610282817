import React, { useEffect, useState } from "react";
import { KIND, SIZE } from "baseui/button";
import { Button, PlusIcon } from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { useWatch } from "react-hook-form";
import { ExtraPayField } from "./ExtraPayField";
import { ScheduleOptionsProps } from "../types/propTypes/ScheduleOptions.types";
import { NotificationField } from "./NotificationField";

export const ScheduleOptions = ({
  control,
  trigger,
  notificationManagers,
  errors,
  baseWage,
  hideExtraPayButton,
  reset,
  currentFormValues,
}: ScheduleOptionsProps) => {
  const [isExtraPaySelected, setExtraPaySelected] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { notificationManager: notificationManagersSelected, extraPay: extraPayValue } = useWatch({
    control,
    name: ["notificationManager", "extraPay"],
  });

  const isNotificationManagersSelected: boolean = notificationManagersSelected?.length;

  useEffect(() => {
    if (extraPayValue) {
      setExtraPaySelected(!!extraPayValue);
    }
  }, [extraPayValue]);

  return (
    <>
      {isExtraPaySelected && (
        <ExtraPayField control={control} trigger={trigger} errors={errors} baseWage={baseWage} />
      )}

      {(isNotificationManagersSelected || isModalOpen) && (
        <NotificationField
          control={control}
          trigger={trigger}
          notificationManagers={notificationManagers}
          notificationManagersSelected={notificationManagersSelected}
          isModalOpen={isModalOpen}
          openModal={(val: boolean) => setModalOpen(val)}
          reset={reset}
          currentFormValues={currentFormValues}
        />
      )}

      {!((isExtraPaySelected || hideExtraPayButton) && isNotificationManagersSelected) && (
        <OptionsText>Options</OptionsText>
      )}

      <ButtonsWrapper data-testid="ScheduleOptions-Buttons">
        {!isNotificationManagersSelected && (
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            width="136px"
            leftIcon={PlusIcon}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Notification
          </Button>
        )}

        {!isExtraPaySelected && !hideExtraPayButton && (
          <ExtraPayButtonWrapper $isSelected={isNotificationManagersSelected}>
            <Button
              kind={KIND.secondary}
              size={SIZE.compact}
              width="136px"
              leftIcon={PlusIcon}
              onClick={() => {
                setExtraPaySelected(true);
              }}
            >
              Extra pay
            </Button>
          </ExtraPayButtonWrapper>
        )}
      </ButtonsWrapper>
    </>
  );
};

const ExtraPayButtonWrapper = styled<{ $isSelected: boolean }, "div">(
  "div",
  ({ $isSelected, $theme }) => ({
    marginLeft: $isSelected ? "0" : $theme.sizing.scale200,
  }),
);

const ButtonsWrapper = styled("div", ({ $theme }) => ({
  display: "flex",
  marginTop: $theme.sizing.scale200,
  flexDirection: "row",
}));

const OptionsText = styled("p", ({ $theme }) => ({
  ...$theme.typography.font350,
  margin: 0,
  paddingTop: $theme.sizing.scale800,
  paddingBottom: $theme.sizing.scale300,
}));
