import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const CheckedIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 13, height = 10, stroke = theme.colors.white } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.44444L4.33333 9L12 1"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
