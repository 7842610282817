import { Button, Image, AlertBellIcon } from "@bluecrew/web-react-core";
import { assetUrl } from "@bluecrew/web-react-core/src/constants";
import React, { useState } from "react";
import styled from "styled-components";
import { CenteredWrapper } from "../../GetCrewMembers/CenteredWrapper";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { JobRequest, ReasonCode } from "../../../api/bluecrew/types";
import { CancelScheduleModal } from "../../../components/CancelScheduleModal";
import {
  useNavigateToHandleRequestCrewPage,
  useNavigateToApplicantReview,
} from "../navigationHooks";
import { JobRequestCrewMemberTable } from "./JobRequestCrewMemberTable";
import { JobRequestTable } from "./JobRequestTable";
import { Can } from "../../PermissionsContext";
import { TooltipMessage } from "../../../components/TooltipMessage";
import { ScheduleType } from "../../GetCrewMembers/types/propTypes/Schedule.types";

interface JobRequestModalBodyProps {
  jobRequestData: JobRequest;
  updateCancelScheduleForJobRequest: (
    jobRequestExternalId: string,
    scheduleId: number,
    workerShifts: number,
    scheduleType: ScheduleType,
  ) => void;
  cancelSchedule: (reasonCode?: ReasonCode, reasonText?: string) => Promise<void>;
}

const StyledJobRequestModalBody = styled.div`
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
`;

const StyledCancelButton = styled.button`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;

  width: 180px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorTokens.gray200};
  }

  /* Cirrus */
  background: #ffffff;
  /* Pebble */

  border: 1px solid ${({ theme }) => theme.colorTokens.gray400};
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  /* Text / Bold Compact */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Coral */

  color: ${({ theme }) => theme.colorTokens.red400};
`;

const StyledAlertIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;

  margin-left: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const JobRequestModalBody = ({
  jobRequestData,
  updateCancelScheduleForJobRequest,
  cancelSchedule,
}: JobRequestModalBodyProps) => (
  <StyledJobRequestModalBody>
    <JobRequestModalBodyButtonSection
      updateCancelScheduleForJobRequest={updateCancelScheduleForJobRequest}
      jobRequestData={jobRequestData}
      cancelSchedule={cancelSchedule}
    />
    <Spacer $type={SpacerType.vertical} $size="40px" />
    <JobRequestTable jobRequestData={jobRequestData} />
    <Spacer $type={SpacerType.vertical} $size="16px" />
    <JobRequestCrewMemberTable crewMembers={jobRequestData.crew_members} />
  </StyledJobRequestModalBody>
);

interface JobRequestModalBodyButtonSectionProps {
  jobRequestData: JobRequest;
  updateCancelScheduleForJobRequest: (
    jobRequestExternalId: string,
    scheduleId: number,
    workerShifts: number,
    scheduleType: ScheduleType,
  ) => void;
  cancelSchedule: (reasonCode?: ReasonCode, reasonText?: string) => Promise<void>;
}

const GetMoreCrewMembersSpan = styled.span`
  margin-left: 6px;
`;

const GetCrewMembersButton = styled(Button)`
  width: 200px;
`;

const JobRequestModalBodyButtonSection = ({
  jobRequestData,
  updateCancelScheduleForJobRequest,
  cancelSchedule,
}: JobRequestModalBodyButtonSectionProps) => {
  const navigateToApplicantReview = useNavigateToApplicantReview();
  const navigateToHandleRequestCrewPage = useNavigateToHandleRequestCrewPage();

  const [cancelScheduleModalIsOpen, setCancelSchedulModalIsOpen] = useState(false);

  const hasToReviewApplicants =
    jobRequestData.is_application > 0 && jobRequestData.application_review_count > 0;
  return (
    <CenteredWrapper>
      {/* Empty link so focus won't be on Get Crew Members button and that button backgroudn color will be correct on modal open */}
      <a href="#"></a>
      <Can I="post" a="company_jobs">
        <Can I="create" a="job">
          <GetCrewMembersButton
            onClick={() =>
              navigateToHandleRequestCrewPage(
                jobRequestData.schedule_id,
                jobRequestData.start_time,
                jobRequestData.end_time,
                jobRequestData.schedule_type,
              )
            }
            data-pendo-key={`JobRequestModalBodyGetMoreCrewMembersButton`}
            palette="primary"
          >
            <Image src={`${assetUrl}/invite-user-white.svg`} />
            <GetMoreCrewMembersSpan>Get More Crew Members</GetMoreCrewMembersSpan>
          </GetCrewMembersButton>
        </Can>
      </Can>
      <Spacer $type={SpacerType.horizontal} $size="24px" />
      <CancelScheduleModal
        cancelSchedule={cancelSchedule}
        showModal={cancelScheduleModalIsOpen}
        toggle={() => {
          setCancelSchedulModalIsOpen((prevState) => !prevState);
        }}
        workerShifts={1}
        forIndividualWorkRequest
      />
      <Can I="update" a="job">
        <StyledCancelButton
          onClick={() => {
            updateCancelScheduleForJobRequest(
              jobRequestData.external_id,
              jobRequestData.schedule_id,
              jobRequestData.schedule_type,
              1,
            );
            setCancelSchedulModalIsOpen(true);
          }}
          data-pendo-key={`JobRequestModalBodyCancelButton`}
        >
          Cancel job request
        </StyledCancelButton>
        {hasToReviewApplicants && (
          <StyledAlertIconContainer>
            <TooltipMessage
              placement="bottom"
              message={`Review your applicants ${jobRequestData.application_review_count}`}
            >
              <Image
                className="bell-icon"
                src={AlertBellIcon}
                alt="candidate review"
                onClick={() => {
                  navigateToApplicantReview(jobRequestData.schedule_id);
                }}
              />
            </TooltipMessage>
          </StyledAlertIconContainer>
        )}
      </Can>
    </CenteredWrapper>
  );
};
