import { format } from "date-fns";
import { produce } from "immer";
import { UserShiftDto } from "@bluecrew/finance-service-node-client";
import cloneDeep from "lodash/cloneDeep";
import { convergeDate } from "../../../../../../shared/helpers";
import {
  AuditEditRow,
  ShiftBreak,
  ShiftTimestamp,
  TimesheetsShiftInfo,
  UserShift,
} from "../../../types/Shift.types";
import { PayrollHoursStatus, TimesheetSupportUserTypes } from "../../../types/Payroll.types";
import { mapUserShift } from "../../shiftMapperUtils";
import { TimestampAttributeName } from "../../../shiftUtils";

export const buildOriginalTimestamps = (
  shiftInfo: TimesheetsShiftInfo,
  original?: UserShiftDto,
): TimesheetsShiftInfo => {
  if (original) {
    const mappedShift = mapUserShift(original);
    const tableUserShift = shiftInfo.userShift;
    return replaceIconsFromShiftInfo(
      shiftInfo,
      mappedShift,
      // remove CM_EDIT icons from original timestamps FIN-610
      removeItem(tableUserShift.start.attributes, TimestampAttributeName.CM_EDITED_SUBMISSION),
      removeItem(tableUserShift.end.attributes, TimestampAttributeName.CM_EDITED_SUBMISSION),
      removeItem(tableUserShift.break.attributes, TimestampAttributeName.CM_EDITED_SUBMISSION),
    );
  }

  /*
  If there is no original timestamp (it should come from Timekeeping Service),
  and at the same time user shift is approved,
  this means that user has not submitted his/her timestamps,
  and the timestamps (specifically, timestamps of start/break/end fields)
  in "shiftInfo.userShift" were submitted by Bluecrew Support.

  But at this point we want to show original timestamp (which is missing, hehe)
  in the Audit Window the exact same way as it is shown in "Pending Review" tab.

  So, we just deep-copy "shiftInfo.userShift" into a new object (to not damage the original userShift object)
  and replace start/break/ent timestamps with timestamps from job,
  and use respective status and attributes. This way "shiftInfoCopy" will be displayed the same exact way
  as in "Pending Review" tab.
   */
  if (shiftInfo.userShift.status === PayrollHoursStatus.APPROVED) {
    const shiftInfoCopy = cloneDeep(shiftInfo);

    shiftInfoCopy.userShift.status = PayrollHoursStatus.NEEDS_VERIFICATION;

    const missingSubmissionAttributesArray = [TimestampAttributeName.CM_MISSING_SUBMISSION];

    shiftInfoCopy.userShift.start.attributes = missingSubmissionAttributesArray;
    shiftInfoCopy.userShift.start.time = shiftInfo.job.start;

    shiftInfoCopy.userShift.end.attributes = missingSubmissionAttributesArray;
    shiftInfoCopy.userShift.end.time = shiftInfo.job.end;

    shiftInfoCopy.userShift.break.attributes = missingSubmissionAttributesArray;
    shiftInfoCopy.userShift.break.durationMinutes = 0;

    return shiftInfoCopy;
  }
  return shiftInfo;
};

function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  return arr.filter((item) => item !== value);
}

/**
 * This function is only applicable during Timesheets P1 and
 * will be removed during subsequent phases of Timesheets project.
 *
 * Whether a client or support user made an edit is already available
 * in the TimesheetsTable row; we just need to search for it. Going
 * forward, all edits will be available in Timekeeping service and
 * will be fetched by Finance service for the audit trail endpoint.
 */
export const createClientSupportRow = (shiftInfo: TimesheetsShiftInfo): AuditEditRow => {
  const { userShift } = shiftInfo;
  const { lastUpdateBy } = userShift;

  if (lastUpdateBy && lastUpdateBy.userType && lastUpdateBy.userType.valueOf() > 0) {
    const supportUser = TimesheetSupportUserTypes.includes(lastUpdateBy.userType);
    const editorName = supportUser ? "Bluecrew Support" : lastUpdateBy.name; // client user
    return {
      editDescription: "Client/Bluecrew Support",
      editTimestamp: format(convergeDate(lastUpdateBy.updatedAt), "MMM dd, yyyy h:mm aaa"),
      editUsername: editorName,
      shiftInfo: replaceIconsFromShiftInfo(shiftInfo, shiftInfo.userShift), // removes icons
      rowKey: "fixedClientSupportRow",
    };
  }
  // no edits
  return {
    editDescription: "Client/Bluecrew Support",
    rowKey: "fixedClientSupportRow",
  };
};

function replaceIconsFromShiftInfo(
  shiftInfo: TimesheetsShiftInfo,
  userShift: UserShift,
  startTimestampAttributes?: TimestampAttributeName[],
  endTimestampAttributes?: TimestampAttributeName[],
  breakTimestampAttributes?: TimestampAttributeName[],
): TimesheetsShiftInfo {
  const { start, end } = userShift;
  return produce(shiftInfo, (draftShift) => {
    // eslint-disable-next-line no-param-reassign
    draftShift.userShift = produce(userShift, (draft) => {
      // show no icons for Client/Support edits
      draft.start = replaceIcons(start, startTimestampAttributes);
      draft.end = replaceIcons(end, endTimestampAttributes);
      draft.break = replaceBreakIcons(userShift.break, breakTimestampAttributes);
    });
  });
}

function replaceIcons(
  timestamp: ShiftTimestamp,
  timestampAttributes?: TimestampAttributeName[],
): ShiftTimestamp {
  return produce(timestamp, (draft) => {
    // override or don't display any icons
    draft.attributes = timestampAttributes ?? [];
    draft.isDisputedTimeMissing = false;
    draft.timeInDispute = undefined;
  });
}

function replaceBreakIcons(
  timestamp: ShiftBreak,
  timestampAttributes?: TimestampAttributeName[],
): ShiftBreak {
  return produce(timestamp, (draft) => {
    // override or don't display any icons
    draft.attributes = timestampAttributes ?? [];
    draft.isDisputedDurationMissing = false;
    draft.durationInDispute = undefined;
  });
}
