import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, useThemedStyletron } from "@bluecrew/blueprint-web";
import { useStep } from "react-hooks-helper";
import { styled } from "baseui";
import { Toolbar } from "../Toolbar";
import { Wage } from "../types/propTypes/HourlyPayField.types";
import {
  CreatePositionScreenProps,
  FormFieldTypes,
} from "../types/propTypes/CreatePositionScreen.types";
import { SkillsetSection } from "./SkillsetSection";
import { RequirementsSection } from "./RequirementsSection";
import { PositionDetailsSection } from "./PositionDetailsSection";
import { Divider } from "../../../components/Divider";
import { ScreenHeader } from "../ScreenHeader";

export const CreatePositionScreen = ({
  defaultValues,
  events,
  data: { skills, positions, geofence },
  config = {},
}: CreatePositionScreenProps) => {
  const { control, trigger, handleSubmit, errors, watch, setValue, getValues, formState } =
    useForm<FormFieldTypes>({
      defaultValues,
    });
  const {
    index: step,
    navigation: { next },
  } = useStep({ steps: 3, initialStep: defaultValues.skillset ? 2 : 0 });

  const onSubmit = (formData: FormFieldTypes) => {
    events && events.onSave && events.onSave(formData);
  };

  const [, theme] = useThemedStyletron();

  const selectedSkillset = watch("skillset");

  const onSkillsetNext = async () => {
    await trigger("skillset");
    if (!("skillset" in errors)) {
      next();
    }
  };

  useEffect(() => {
    if (formState.isDirty) {
      setValue(
        "requirements",
        selectedSkillset?.requirements.filter((req) => req.selected),
      );
      setValue("customRequirements", []);
    }
    setValue("generalRequirements", `***General Requirements***\n${selectedSkillset?.description}`);
  }, [selectedSkillset]);

  return (
    <>
      <ScreenHeader>Create a position</ScreenHeader>
      <FormStyledWrapper>
        <SkillsetSection
          control={control}
          trigger={trigger}
          onNext={onSkillsetNext}
          skills={skills}
        />
        {step >= 1 && (
          <>
            {selectedSkillset?.warning != null && (
              <StyledText
                $style={{ paddingBottom: "0px", paddingTop: "24px" }}
                dangerouslySetInnerHTML={{ __html: selectedSkillset?.warning }}
              />
            )}
            <RequirementsSection
              control={control}
              trigger={trigger}
              requirements={selectedSkillset!.requirements}
              certifications={selectedSkillset!.certifications}
              skillsetId={Number(selectedSkillset!.id)}
            />
            <PositionDetailsSection
              errors={errors}
              control={control}
              trigger={trigger}
              positions={positions}
              geofence={geofence}
              skill={selectedSkillset}
              onLocationSelect={async (address) => {
                if (events?.onLocationSelect) {
                  const res: Wage = await events.onLocationSelect(address);
                  // Get current wage to not rewrite the existing value in the field
                  const inputWageValue = getValues("hourlyPay")?.wage || 0;
                  setValue("hourlyPay", {
                    wage: inputWageValue,
                    minWage: res.wage,
                  });
                }
              }}
              config={config.positionDetails}
            />
          </>
        )}
        {step >= 1 && (
          <>
            <Divider $marginTop={theme.sizing.scale1000} />
            <Toolbar $justifyContent="flex-end" style={{ paddingBottom: "80px" }}>
              <Button onClick={handleSubmit(onSubmit)}>Save position</Button>
            </Toolbar>
          </>
        )}
      </FormStyledWrapper>
    </>
  );
};

const FormStyledWrapper = styled("div", ({ $theme }) => ({
  width: "827px",
  marginLeft: "80px",
  marginTop: $theme.sizing.scale1000,
}));

const StyledText = styled("p", ({ $theme }) => ({
  ...$theme.typography.font300,
  color: $theme.colors.primary700,
  paddingBottom: $theme.sizing.scale900,
  margin: 0,
}));
