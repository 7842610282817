// BLUECREW FULL LOGO - SVG
// =============================================================================

import React from "react";

export const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g fill="none" fillRule="evenodd">
      <circle cx="24" cy="24" r="24" fill="#FFD4D5" />
      <path
        fill="#E03131"
        fillRule="nonzero"
        d="M35 22.586L25.414 13a2 2 0 0 0-2.828 0L13 22.587a2 2 0 0 0 0 2.828L22.586 35a2 2 0 0 0 2.828 0L35 25.415a2 2 0 0 0 0-2.829zm-11.932-4.169a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0v-6zm1.051 11.51h-.028a1.529 1.529 0 0 1-1.522-1.47 1.475 1.475 0 0 1 1.448-1.53h.028c.82.002 1.491.65 1.522 1.47a1.475 1.475 0 0 1-1.448 1.53z"
      />
    </g>
  </svg>
);
