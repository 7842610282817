import React, { Dispatch, SetStateAction } from "react";
import { startOfWeek } from "date-fns";
import { StyledCalendarChangeButton } from "./styledComponents";

export type ButtonCalendarProps = { setWeek: Dispatch<SetStateAction<Date>>; week: Date };

export const ButtonCalendar = ({ setWeek, week }: ButtonCalendarProps) => {
  return (
    <StyledCalendarChangeButton
      value={week}
      maxDate={new Date()}
      onChange={(selectedDate) =>
        setWeek(startOfWeek(selectedDate.value as Date, { weekStartsOn: 1 }))
      }
    />
  );
};
