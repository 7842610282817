import { PositionDetail } from "../../api/bluecrew/types";
import { getIconName } from "../../utility/normalizeIconName";
import { getCertifications } from "../../utility/getCertifications";
import type { FormFieldTypes as CreatePositionFormFieldTypes } from "../GetCrewMembers/types/propTypes/CreatePositionScreen.types";

/**
 * Transforms position data into the shape that the Edit Position Form components want to use.  None of these are typed currently
 * other than position.
 * @param position {PositionDetail }
 * @param jobTypes {any | null}
 * @param minWage {number}
 */
export const transformPositionData = (
  position: PositionDetail,
  jobTypes: any,
  minWage: number,
): CreatePositionFormFieldTypes => {
  // TODO now that this is extracted and tested we can refactor it further
  const skill = jobTypes.find(({ id }) => id === position?.JobType.id);
  const getCustomRequirements = () =>
    skill
      ? position.requirements.filter((rq) => {
          const reqNotFound = !skill.users_requirements.find(({ label }) => label === rq);
          const certNotFound = !skill.users_certs.find(({ label }) => label === rq);
          return reqNotFound && certNotFound;
        })
      : [];

  const getRequirements = () =>
    skill &&
    skill.users_requirements.reduce((acc, { id, label }) => {
      if (position?.requirements.includes(label)) {
        acc.push({ id, label, selected: true });
      }
      return acc;
    }, []);

  const positionDescription = position?.additional_description || "";
  const generalRequirements = position?.description
    ? `***General Requirements***\n${position.description}`
    : "";
  const transformedData = {
    skillset: {
      id: position?.JobType.id,
      name: position?.JobType.name,
      iconName: getIconName(position?.JobType.icon_name || ""),
      description: position?.JobType.description,
      requirements: getRequirements(),
      certifications: getCertifications(skill, position),
      lastUsedAt: position?.created_at,
    },
    hourlyPay: {
      minWage: Number(minWage),
      wage: Number(position?.base_wage || ""),
    },
    requirements: getRequirements(),
    customRequirements: getCustomRequirements(),
    certifications: getCertifications(skill, position),
    positionTitle: position?.title,
    worksite: {
      address: `${position?.address.display_address} ${position?.address.postal_code}`,
      lat: Number(position?.address.latitude),
      lng: Number(position?.address.longitude),
      mainText: position?.address.street_address,
      placeId: position?.address.meta?.google_place_id,
      secondaryText: position?.address.street_address_two || undefined,
    },
    arrivalInstructions: position?.arrival_instructions,
    description: positionDescription,
    generalRequirements,
    dressCode: position?.dress_code || "",
    siteDesignation: position?.site_designation,
    minWarnRadius: position?.min_warn_radius,
    minBlockRadius: position?.min_block_radius,
    backgroundCheckRequired: position.background_check_required,
    drugScreenRequired: position.drug_screen_required,
  };

  return transformedData;
};
