import * as React from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Step, StepProps } from "./Step";
import { StepContainerActions, StepContainerSelectors } from "./StepContainer";
import { Omit } from "./types";

type Function = (...args: any[]) => void;

const callAll =
  (...fns: (Function | undefined)[]) =>
  (...args: any[]) =>
    fns.forEach((fn) => fn && fn(...args));

function createElementRef<T extends React.Component<any>>(scope: T, property: keyof T) {
  return (element: any) => {
    scope[property] = element;
    if (scope.props && scope.props.elementRef) {
      scope.props.elementRef(element);
    }
  };
}

interface TabsTabProps extends Omit<StepProps, "step"> {
  tab: string;
  current: number;
  register: StepContainerActions["register"];
  update: StepContainerActions["update"];
  unregister: StepContainerActions["unregister"];
  show: StepContainerActions["show"];
  next: StepContainerActions["next"];
  previous: StepContainerActions["previous"];
  isCurrent: StepContainerSelectors["isCurrent"];
}

interface KeyMap {
  ArrowLeft: StepContainerActions["previous"];
  ArrowRight: StepContainerActions["next"];
}

class TabsTabComponent extends React.Component<TabsTabProps> {
  element = React.createRef<HTMLElement>();

  componentDidUpdate(prevProps: TabsTabProps) {
    const { current, isCurrent, tab } = this.props;

    if (prevProps.current !== current && isCurrent(tab) && this.element && this.element.current) {
      this.element.current.focus();
    }
  }

  show = () => {
    const { show, isCurrent, tab } = this.props;
    if (!isCurrent(tab)) {
      show(tab);
    }
  };

  keyDown = (e: KeyboardEvent) => {
    const keyMap: KeyMap = {
      ArrowLeft: this.props.previous,
      ArrowRight: this.props.next,
    };

    const inKeyMap = (key: string): key is keyof KeyMap => key in keyMap;

    if (inKeyMap(e.key)) {
      e.preventDefault();
      keyMap[e.key]();
    }
  };

  render() {
    const { isCurrent, tab, className, onClick, onFocus, onKeyDown } = this.props;

    const active = isCurrent(tab);
    const activeClassName = active ? "active" : "";
    const finalClassName = [className, activeClassName].filter(Boolean).join(" ");

    return (
      <Step
        id={`${tab}Tab`}
        step={tab}
        aria-selected={active}
        aria-controls={`${tab}Panel`}
        tabIndex={active ? 0 : -1}
        visible
        {...this.props}
        onClick={callAll(this.show, onClick)}
        onFocus={callAll(this.show, onFocus)}
        onKeyDown={callAll(this.keyDown, onKeyDown)}
        className={finalClassName}
        elementRef={createElementRef(this, "element")}
      />
    );
  }
}

const StyledTabsTab = styled(TabsTabComponent).attrs({ as: "li" })`
  ${theme("TabsTab")};
`;

const TabsTab = (props: TabsTabProps) => {
  const { children, ...attrs } = props;
  return <StyledTabsTab {...attrs}>{children}</StyledTabsTab>;
};

const noop = () => {};

TabsTab.defaultProps = {
  role: "tab",
  register: noop,
  update: noop,
  unregister: noop,
  isCurrent: () => false,
  show: noop,
  next: noop,
  previous: noop,
};

export default TabsTab;
