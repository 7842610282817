const { assoc, pipe } = require("ramda");

/**
 * Helper module to send a 403 - forbidden HTTP response.
 */
module.exports = (ctx) =>
  pipe(
    assoc(
      "error",
      `Unauthorized call to ${ctx._internal.req.path} by user: ${ctx.user.info.external_id}`,
    ),
    assoc("status", 403),
    ctx.error,
  )({});
