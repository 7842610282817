/* eslint-disable max-classes-per-file */
import styled, { css } from "styled-components";
import { prop, theme, ifProp } from "styled-tools";
import React, { ReactChildren, ReactNode } from "react";
import { createPortal, findDOMNode } from "react-dom";
import { BaseStyleProps, Box } from "./Box";
import {
  hasTransition,
  translateWithProps,
  originWithProps,
  scaleWithProps,
  slideWithProps,
} from "./styledProps";

type Function = (...args: any[]) => void;

const callAll =
  (...fns: (Function | undefined)[]) =>
  (...args: any[]) =>
    fns.forEach((fn) => fn && fn(...args));

interface HiddenContainerActions {
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

interface MouseClickEvent extends MouseEvent {
  target: Node;
}

export type Position = "top" | "right" | "bottom" | "left";

export interface HiddenProps extends BaseStyleProps {
  elementRef?: React.Ref<any>;
  children?: React.ReactNode;
  palette?: string;
  tone?: number;
  visible?: boolean;
  hide?: HiddenContainerActions["hide"];
  onTransitionEnd?: React.TransitionEventHandler;
  hideOnEsc?: boolean;
  hideOnClickOutside?: boolean;
  unmount?: boolean;
  fade?: boolean;
  expand?: boolean | "center" | Position;
  slide?: boolean | Position;
  duration?: any;
  delay?: string;
  timing?: string;
  animated?: boolean;
  transitioning?: boolean;
  translateX?: number | string;
  translateY?: number | string;
  defaultSlide?: boolean | Position;
  defaultExpand?: boolean | Position;
  slideOffset?: number | string;
  id?: string;
  onClick?: () => void;
  onFocus?: () => void;
  onKeyDown?: () => void;
}

interface HiddenState {
  visible?: boolean;
  transitioning?: boolean;
}

class HiddenComponent extends React.Component<HiddenProps, HiddenState> {
  state = {
    visible: this.props.visible,
    transitioning: this.props.transitioning,
  };

  componentDidMount() {
    const { hideOnEsc, hideOnClickOutside } = this.props;

    if (hideOnEsc) {
      document.body.addEventListener("keydown", this.handleKeyDown);
    }

    if (hideOnClickOutside) {
      document.body.addEventListener("click", this.handleClickOutside);
    }
  }

  applyState = () => {
    const { visible, unmount } = this.props;

    if (typeof window !== "undefined" && unmount && hasTransition(this.props)) {
      if (visible) {
        this.setState({ transitioning: true });
        requestAnimationFrame(() =>
          // it may be still transitioning, but it doesn't matter
          // we just need to set it to false in another loop
          this.setState({ transitioning: false, visible: true }),
        );
      } else {
        this.setState({ visible: false, transitioning: true });
      }
    } else {
      this.setState({ visible });
    }
  };

  componentDidUpdate(prevProps: HiddenProps) {
    if (prevProps.visible !== this.props.visible) {
      this.applyState();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleKeyDown);
    document.body.removeEventListener("click", this.handleClickOutside);
  }

  handleTransitionEnd = () => {
    const { visible, unmount } = this.props;
    if (unmount && !visible) {
      // at this point, this is the last state left to return null on render
      this.setState({ transitioning: false });
    }
  };

  handleKeyDown = (e: KeyboardEvent) => {
    const { visible, hide } = this.props;
    if (e.key === "Escape" && visible && hide) {
      hide();
    }
  };

  handleClickOutside = (e: MouseEvent) => {
    const node = findDOMNode(this);
    const { hide, visible } = this.props;
    const shouldHide =
      node instanceof Element && !node.contains((e as MouseClickEvent).target) && visible && hide;

    if (shouldHide) {
      // it's possible that the outside click was on a toggle button
      // in that case, we should "wait" before hiding it
      // otherwise it could hide before and then toggle, showing it again
      setTimeout(() => this.props.visible && hide && hide());
    }
  };

  render() {
    const { className, unmount, onTransitionEnd } = this.props;
    const { visible, transitioning } = this.state;

    if (unmount && !visible && !transitioning) {
      return null;
    }

    return (
      <Box
        className={className}
        aria-hidden={!visible}
        hidden={!visible && !hasTransition(this.props)}
        {...this.props}
        {...this.state}
        onTransitionEnd={callAll(this.handleTransitionEnd, onTransitionEnd)}
      />
    );
  }
}

const DEFAULT_DURATION = "250ms";

const Hidden = styled(HiddenComponent)`
  transform: ${translateWithProps};
  ${ifProp(
    hasTransition,
    css<Pick<HiddenProps, "duration" | "timing" | "delay">>`
      transform-origin: ${originWithProps};
      transition: all ${prop("duration", DEFAULT_DURATION)} ${prop("timing")} ${prop("delay")};
    `,
  )};
  &[aria-hidden="true"] {
    pointer-events: none;
    ${ifProp("fade", "opacity: 0")};
    ${ifProp(
      hasTransition,
      css`
        transform: ${slideWithProps} ${scaleWithProps};
        visibility: hidden;
        will-change: transform, opacity;
      `,
      "display: none !important",
    )};
  }
  ${theme("Hidden")};
`;

Hidden.defaultProps = {
  duration: DEFAULT_DURATION,
  timing: "ease-in-out",
};

interface BackdropPros extends HiddenProps {
  fade?: boolean;
  visible: boolean;
  onClick?: () => void;
}

const StyledBackdrop = styled(Hidden)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  ${theme("Backdrop")};
`;

const Backdrop = (props: BackdropPros) => {
  const { fade, visible, onClick, ...attr } = props;
  return <StyledBackdrop fade={fade} visible={visible} onClick={onClick} {...attr} />;
};

Backdrop.defaultProps = {
  role: "button",
  tabIndex: -1,
  palette: "shadow",
  tone: 2,
};

interface OverlayArg extends HiddenProps {
  children: ReactChildren | ReactNode | ReactNode[] | string;
  fade: boolean;
  visible: boolean;
}
// NOTE: We seem to use Overlay for the Portal tag in every instance
// That is: StyledOverlay = styled(Overlay); <StyledOverlay as={Portal} fade />
const StyledOverlay = styled(Hidden)`
  position: fixed;
  z-index: 19900410;
  left: 50%;
  top: 50%;
  opacity: 1;
  ${theme("Overlay")};
`;

const Overlay = (props: OverlayArg) => {
  const { fade, visible, children, ...attr } = props;
  return (
    <StyledOverlay fade={fade} visible={visible} {...attr}>
      {children}
    </StyledOverlay>
  );
};

Overlay.defaultProps = {
  role: "dialog",
  "aria-modal": true,
  hideOnEsc: true,
  translateX: "-50%",
  translateY: "-50%",
  defaultSlide: "top",
  palette: "background",
  tone: -1,
  fade: false,
};

interface PortalProps extends BaseStyleProps {
  children?: React.ReactNode;
  fade?: boolean;
  visible?: boolean;
}

interface PortalState {
  wrapper?: React.ReactNode;
}

// Styled portal may need a className wrapper
class PortalComponent extends React.Component<PortalProps, PortalState> {
  state = { wrapper: null };

  componentDidMount() {
    const wrapper = document.createElement("div");
    // const wrapper = <div className={this.props.className} />;
    this.setState({ wrapper });
    document.body.appendChild(wrapper);
  }

  componentWillUnmount() {
    const { wrapper } = this.state;
    if (wrapper) {
      document.body.removeChild(wrapper);
    }
  }

  render() {
    const { wrapper } = this.state;
    if (wrapper) {
      const { className } = this.props;
      return createPortal(<Box className={className} {...this.props} />, wrapper);
    }
    return null;
  }
}

const StyledPortal = styled(PortalComponent)`
  ${theme("Portal")};
  border-radius: 8px;
`;

const Portal = (props: PortalProps) => {
  const { children, ...attrs } = props;
  return <StyledPortal {...attrs}>{children}</StyledPortal>;
};

export { Backdrop, Hidden, Overlay, Portal };
