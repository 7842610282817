import { UserType } from "../../../api/bluecrew/types";
import {
  EditClientRoleRequest,
  UpdateUsersCompaniesAndRoleRequest,
} from "../../../api/bluecrew/user";
import { InternalUser } from "../ManageUsersTable/InternalUser";
import { EXTERNALID_PLACEHOLDER } from "../constants";

// ------------------------ Creating a dummy Internal User ------------------------ //
// The Assign Companies Modal can only assign companies to an InternalUser but
// an InternalUser is a user that already exist. I.e. they have a userId, externalId, etc.
// So from the AddNewUserModal, placeholder values are used in place of these fields to create
// the dummy InternalUser. This is then passed to the Assign Companies Modal for company assignment.
// Once the companies are assigned, the assigned companies, along with the non-placeholder values
// (E.g. name, phone number, role type, etc.) are passed to v1/companies/sendInvite to create the
// user WITH the assigned companies.

/**
 * @param {InternalUser} selectedUser
 * @returns {boolean} true if the selectedUser is a dummy InternalUser. Otherwise, false.
 *
 * A dummy InternalUser is an InternalUser that was created from the AddNewUserModal.
 * Branch users cannot be created without any company assignments. So, a dummy InternalUser
 * is created with placeholder values to be passed to the Assign Companies Modal first.
 * This function checks if the InternalUser passed has the placeholder value(s).
 */
export function isDummyInternalUser(selectedUser: InternalUser): boolean {
  return selectedUser.externalId === EXTERNALID_PLACEHOLDER;
}

type NewUserRequest = {
  companyIds: string[];
  username: string;
  firstName: string;
  lastName: string;
  userType: string;
  phoneNumber: string | null;
  region: null;
};

/**
 * @param {InternalUser} selectedUser
 * @param {Company[]} assignedCompanies
 * @returns {NewUserRequest} Payload format the v1/companies/sendInvite endpoint expects.
 */
export const toNewUserRequest = (
  selectedUser: InternalUser,
  assignedCompanies: number[],
): NewUserRequest => {
  const [fName = "", lName = ""] = selectedUser.name.split(" ");
  const companyIds = assignedCompanies
    .map((companyId) => companyId.toString());
  return {
    companyIds,
    username: selectedUser.emailAddress,
    firstName: fName,
    lastName: lName,
    userType: UserType[selectedUser.userRole],
    phoneNumber: selectedUser.phoneNumber?.replace(/-/g, "") ?? null,
    region: null,
  };
};

// ------------------------ Assigning Companies to Users ------------------------ //
type UsersCompanies = {
  userId: number;
  companyIds: number[];
};

type UpdateUsersCompaniesRequest = {
  usersCompanies: UsersCompanies[];
};

/**
 * @param userIds is a list of userIds belonging to selectedUsers (InternalUsers)
 * @param assignedCompanies is a list of companyIds of type 'Company'.
 * @returns UpdateUsersCompaniesRequest which is what v2/users_companies/updateUsersCompanies expects.
 *
 * Maps the selected companies to each userId. Used to assign the selected companies
 * to multiple users at once. Returns a UpdateUsersRequest type which
 * is what v2/users_companies/updateUsersCompanies expects.
 *
 * E.g. If assignedCompanies = [A, B, C] and userIds = [1, 2, 3]...
 * It would return { 1: [A, B, C], 2: [A, B, C], 3: [A, B, C] } where each 'key'
 * is the userId, and the 'value' is the list of companyIds that the user is assigned to.
 * It will assign all userIds in the list the same set of companies.
 */
export function mapUsersAssignedCompanies(
  userIds: number[],
  assignedCompaniesIds: number[],
): UpdateUsersCompaniesRequest {
  const usersAssignedCompanies: UsersCompanies[] = userIds.map((userId) => ({
    userId,
    companyIds: assignedCompaniesIds,
  }));

  return { usersCompanies: usersAssignedCompanies };
}

// ------------------------ Bulk Assign Companies ------------------------ //

/**
 * @param selectedUsers are InternalUsers who will be assigned to the selected companies
 * @param partialAssignedCompanies is a Map<number, number[]> where the key is the companyId and the value is a list of userIds.
 * @param allAssignedCompanies is a Set<number> of companyIds that every selected user will be assigned to.
 * @returns UpdateUsersCompaniesRequest which is what v2/users_companies/updateUsersCompanies expects.
 *
 * Similar in functionality to mapUsersAssignedCompanies but for multiple users. It has an additional
 * requirement to preserve partial assignments of companies.
 * E.g. 'selectedUsers' has 3 InternalUsers: user 1, user 2, and user 3.
 * User 1 and 2 were previously assigned to 'company A'. All three users were assigned to company B and C as well.
 * It must preserve User 1 and 2's company A assignment but also assign all three users to company B and C.
 */
export function mapBulkUsersAssignedCompanies(
  selectedUsers: InternalUser[],
  partialAssignedCompanies: Map<number, number[]>,
  allAssignedCompanyIds: number[],
): UpdateUsersCompaniesRequest {
  const userIds = selectedUsers.map((user) => user.userId);

  const usersCompaniesMap: Map<number, number[]> = new Map<number, number[]>();
  userIds.forEach((userId) => {
    usersCompaniesMap.set(userId, [...allAssignedCompanyIds]);
  });

  partialAssignedCompanies.forEach((partialUserIds, companyId) => {
    partialUserIds.forEach((userId) => {
      usersCompaniesMap.get(userId)?.push(companyId);
    });
  });

  const usersAssignedCompanies: UsersCompanies[] = Array.from(usersCompaniesMap.entries()).map(
    ([userId, companyIds]) => ({
      userId,
      companyIds,
    }),
  );

  return { usersCompanies: usersAssignedCompanies };
}

/**
 * @param {InternalUser[]} users is a list of InternalUsers
 * @param {number} displayLimit is the number of users' names to display. Default is 5.
 * @returns {string} The first names of up to displayLimit number of users. If there are more users than the displayLimit,
 * then it will display the first names of the first displayLimit users, and "and x others" (plural) or "and x other" (singular).
 *
 * The modal should display up to 'displayLimit' number of users' profile pictures or initials
 * when the modal pops up. Next to it, displays the first names of the first 'displayLimit'
 * number of users. Any additional users will be displayed as "and x others" (plural). But, if there's
 * 'displayLimit' + 1 number of users, then the last user will be displayed as
 * "and x other" (singular)
 */
export const formatNames = (users: InternalUser[], displayLimit: number = 5): string => {
  const firstNames = users.map((user) => user.name.split(" ")[0]);

  // Less than or equal to the display limit selected
  if (firstNames.length <= displayLimit) {
    return `${firstNames.slice(0, -1).join(", ")}, and ${firstNames[firstNames.length - 1]} `;
  }
  // Over the display limit selected. If limit = 5 and 6 selected, then: 'A', 'B', 'C', 'D', 'E', and 1 other.
  // If the limit = 5 and 7 selected, then: 'A', 'B', 'C', 'D', 'E', and 2 others.
  return `${firstNames.slice(0, displayLimit).join(", ")}, and \
  ${firstNames.length - displayLimit} \
  other${firstNames.length === displayLimit + 1 ? "" : "s"}`;
};

/**
 *
 * @param selectedUserId The external id of the user whose role will be updated
 * @param selectedUserRole The new role to be assigned to the user
 * @returns A combined request to update the user's role and clear their company assignments
 */
export const updateRoleAndClearAssignments = (
  selectedUserExternalId: string,
  selectedUserInternalId: number,
  selectedUserRole: string,
  companyIds: number[],
): UpdateUsersCompaniesAndRoleRequest => {
  const editClientRoleRequest: EditClientRoleRequest = {
    userId: selectedUserExternalId,
    clientType: selectedUserRole,
  };
  const clearUsersCompaniesRequest: UpdateUsersCompaniesRequest = {
    usersCompanies: [{ userId: selectedUserInternalId, companyIds }],
  };
  const updateCompaniesAndRole: UpdateUsersCompaniesAndRoleRequest = {
    updateUsersCompaniesData: clearUsersCompaniesRequest,
    editClientRoleData: editClientRoleRequest,
  };
  return updateCompaniesAndRole;
};
