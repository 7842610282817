import { ShiftAdjustModalInputText } from "../styledComponents";
import { AdjustmentReasonWrapper } from "./styledComponents";
import React from "react";
import { ReasonCodeAndText } from "./ShiftAdjustModal";
import { Dropdown } from "../../../../../components/Dropdown";

export type ShiftAdjustReasonCodeSelectProps = {
  reasonCodeAndTextList: Array<ReasonCodeAndText>;
  setReasonCodeAndText: (reasonAndText: ReasonCodeAndText) => void;
  reasonCodeAndText?: ReasonCodeAndText;
};

export const ShiftAdjustReasonCodeSelect = ({
  reasonCodeAndText,
  setReasonCodeAndText,
  reasonCodeAndTextList,
}: ShiftAdjustReasonCodeSelectProps) => {
  return (
    <AdjustmentReasonWrapper>
      <ShiftAdjustModalInputText>Adjustment Reason</ShiftAdjustModalInputText>
      <Dropdown
        value={reasonCodeAndText}
        onChange={(e) => setReasonCodeAndText(e.value)}
        options={reasonCodeAndTextList}
        optionLabel={"text"}
        placeholder="Select a Reason"
      />
    </AdjustmentReasonWrapper>
  );
};
