/* eslint-disable import/no-cycle */
import {
  EditModalDate,
  EditModalHeaderText,
  EditModalSubHeaderText,
  HeaderJobInfoContainer,
} from "./styledComponents";
import React from "react";
import { format } from "date-fns";
import { Divider } from "primereact/divider";
import { UserColumnCell, UserColumnCellProps } from "../UserColumnCell/UserColumnCell";

export type ShiftAdjustHeaderProps = {
  headerText: string;
  subHeaderText: string;
  jobDate: Date;
  userInfo: UserColumnCellProps;
};
export const ShiftAdjustHeader = ({
  headerText,
  subHeaderText,
  jobDate,
  userInfo,
}: ShiftAdjustHeaderProps) => (
  <>
    <EditModalHeaderText>{headerText}</EditModalHeaderText>
    <EditModalSubHeaderText>{subHeaderText}</EditModalSubHeaderText>
    <HeaderJobInfoContainer>
      <UserColumnCell
        firstName={userInfo.firstName}
        lastName={userInfo.lastName}
        jobInfo={userInfo.jobInfo}
        profilePic={userInfo.profilePic}
      />
      <EditModalDate>{format(jobDate, "EEE, MMM do")}</EditModalDate>
    </HeaderJobInfoContainer>
    <Divider />
  </>
);
