import { useNavigate } from "react-router-dom";
import React from "react";
import { MenuItemShape } from ".";
import { Can } from "../../containers/PermissionsContext";
import { COMPANY_INFO_TITLE } from "../NavBar/utils";
import { DropdownItem } from "./DropdownItem";

interface DropdownItemsProps {
  items: Array<MenuItemShape>;
  handleRedirect: (url: string) => void;
  closeMenu: () => void;
}

export const DropdownItems = ({ items, handleRedirect, closeMenu }: DropdownItemsProps) => {
  const requiresPermission = (
    item: MenuItemShape,
  ): item is MenuItemShape & {
    canIpermission: NonNullable<MenuItemShape["canIpermission"]>;
  } => item.canIpermission !== undefined;

  const navigate = useNavigate();

  return (
    <>
      {items.map((item: MenuItemShape) => {
        if (requiresPermission(item)) {
          return (
            <Can I={item.canIpermission.I} a={item.canIpermission.a} key={item.key}>
              <DropdownItem
                closeMenu={closeMenu}
                item={item}
                handleRedirect={handleRedirect}
                key={item.key as React.Key}
              />
            </Can>
          );
        }
        if (item.title === COMPANY_INFO_TITLE) {
          return (
            <DropdownItem
              key={item.title}
              handleRedirect={navigate}
              closeMenu={closeMenu}
              item={{
                ...item,
                url: `/settings/company`,
              }}
            />
          );
        }
        return (
          <DropdownItem
            closeMenu={closeMenu}
            item={item}
            handleRedirect={handleRedirect}
            key={item.key as React.Key}
          />
        );
      })}
    </>
  );
};
