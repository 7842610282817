import React, { useMemo, useCallback, useContext } from "react";
import { NameCell } from "./NameCell";
import { SupervisorCell } from "./SupervisorCell";
import { compareDateTime, compareHours } from "../SharedFunctions";
import { ScheduleAndIndividualShift } from "../../../api/bluecrew/types";
import { GenericCell } from "./GenericCell";
import { WorkDaysCell } from "./WorkDaysCell";
import { FillStatusCell } from "./FillStatusCell";
import { AlertIconCell } from "../ScheduleRequestTableCells/AlertIconCell";
import { DirectHireCell } from "../ScheduleRequestTableCells/DirectHireCell";
import { DropdownActionsCell } from "./DropdownActionsCell";
import { AbilityContext } from "../../PermissionsContext";

export const columns = ({ openRequestModal, refillOn, updateCancelSchedule }) => {
  const fillStatusSort = useCallback((a, b) => {
    const valueA = a.original.filled / a.original.needed;
    const valueB = b.original.filled / b.original.needed;
    if (valueA > valueB) {
      return 1;
    }
    if (valueA < valueB) {
      return -1;
    }
    return 0;
  }, []);
  const ability = useContext(AbilityContext);
  const canViewWages = ability.can("view", "wages");

  return useMemo(
    () => [
      {
        id: "name",
        Header: "Schedule Name",
        className: "schedule-name",
        accessor: "schedule_name",
        Cell: ({ row, rowData }) => (
          <NameCell
            isExpanded={row.isExpanded}
            row={row}
            rowData={rowData}
            openRequestModal={openRequestModal}
          />
        ),
      },
      {
        id: "supervisor",
        Header: "Supervisor",
        className: "supervisor",
        accessor: "supervisor_first_name",
        Cell: ({ rowData }) => <SupervisorCell rowData={rowData} />,
      },
      {
        id: "schedule-date",
        className: "schedule-date",
        Header: "Dates",
        accessor: "start_date_time",
        sortType: compareDateTime,
        Cell: ({ rowData }: { rowData: ScheduleAndIndividualShift }) => (
          <GenericCell value={rowData.dates} justifyContent={"left"} />
        ),
      },
      {
        id: "schedule-time",
        Header: "Schedule Time",
        className: "schedule-time",
        accessor: "start_date_time",
        sortType: compareHours,
        Cell: ({ rowData }: { rowData: ScheduleAndIndividualShift }) => (
          <GenericCell value={rowData.scheduled_time} justifyContent={"left"} />
        ),
      },
      {
        Header: "Work Days",
        className: "work-days",
        Cell: ({ rowData }: { rowData: ScheduleAndIndividualShift }) => {
          const work_days: string[] = [];
          rowData.work_days.forEach((element, index) => (work_days[index] = element.toString()));
          return <WorkDaysCell work_days={work_days} />;
        },
      },
      {
        id: "wage",
        Header: "Wage",
        className: "wage",
        accessor: "base_wage",
        Cell: ({ rowData }: { rowData: ScheduleAndIndividualShift }) => (
          <GenericCell
            value={canViewWages ? (rowData as ScheduleAndIndividualShift).dashboard_wage : "$-.--"}
            justifyContent={"left"}
          />
        ),
      },
      {
        id: "fill-status",
        Header: "Fill Status",
        className: "fill-status",
        accessor: "filled",
        sortType: fillStatusSort,
        Cell: ({ rowData }: { rowData: ScheduleAndIndividualShift }) => (
          <FillStatusCell refillOn={refillOn} rowData={rowData} />
        ),
      },
      {
        id: "alertIcon",
        className: "alert-icon",
        Header: "",
        Cell: ({ rowData }) => <AlertIconCell rowData={rowData} />,
      },
      {
        id: "directHire",
        className: "direct-hire",
        Header: "",
        Cell: ({ rowData }) => <DirectHireCell rowData={rowData} />,
      },
      {
        id: "actionButtons",
        className: "action-buttons",
        Header: "",
        Cell: ({ row, rowData }) => (
          <DropdownActionsCell
            updateCancelSchedule={updateCancelSchedule}
            canExpand={row.canExpand}
            rowData={rowData}
          />
        ),
      },
    ],
    [],
  );
};
