import { CrewMember } from "../types/propTypes/ChooseCrewModal.types";
import { CrewMemberListItem } from "../types/propTypes/CrewMembersTab.types";

export class CrewMemberMapper {
  static toListItem = (crewMember: CrewMember, selected: boolean = false): CrewMemberListItem => ({
    id: crewMember.id,
    title: crewMember.name,
    subtitle: crewMember.shifts,
    lastWorkedDate: crewMember.lastWorkedDate,
    lastWorkedPositionTitle: crewMember.lastWorkedPositionTitle,
    selected,
    imgUrl: crewMember.imgUrl,
  });

  static toModel = (listItem: CrewMemberListItem): Omit<CrewMember, "externalId"> => ({
    id: listItem.id,
    name: listItem.title,
    shifts: listItem.subtitle || "",
    lastWorkedDate: listItem.lastWorkedDate || "",
    lastWorkedPositionTitle: listItem.lastWorkedPositionTitle || "",
    imgUrl: listItem.imgUrl || "",
  });
}
