import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ErrorRedirect from "../components/ErrorRedirect";
import { logError } from "../sentry";

const ErrorBoundaryWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export default class ErrorBoundaryWithRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    logError({ error, context: "ErrorBoundaryWithRedirect" });
    // if we're in development environment, just show an error message to help
    // the developers determine what went wrong.
    if (import.meta.env.MODE === "development") {
      return { hasError: true };
    }

    // in all other environments, redirect to the error page
    window.location.href = "/error";
    return undefined;
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return (
        <ErrorBoundaryWrapper>
          <ErrorRedirect />
        </ErrorBoundaryWrapper>
      );
    }

    return this.props.children;
  }
}

// @ts-ignore
ErrorBoundaryWithRedirect.propTypes = {
  children: PropTypes.any,
};
