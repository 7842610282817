import React from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  Backdrop,
  Heading,
  CompanyAvatar,
  Paragraph,
  Button,
  Field,
  Portal,
} from "@bluecrew/web-react-core";
import { Formik, Form, Field as FormikField } from "formik";
import { SearchableList } from "../SearchableList";
import companyActions from "../../redux/actions/company";
import { getCompanyId, getFormattedUserCompanies } from "../../redux/selectors/company";
import { StateShape } from "../../redux/reducers";
import {
  StyledActionsContainer,
  StyledCompaniesContainer,
  StyledCompaniesModal,
  StyledDivider,
  StyledLogoContainer,
  SwitchCompanyOverlay,
} from "./styledComponents";

const mapDispatchToProps = (dispatch: any) => ({
  switchCompany: (payload) => {
    dispatch(companyActions.switchCompany.request(payload));
  },
});

const mapStateToProps = (state: StateShape) => ({
  companyId: getCompanyId(state),
  userCompanies: getFormattedUserCompanies(state),
});

type FormValues = {
  companyId: string;
};

export type SwitchCompanyModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    onModalClose: () => void;
    userEmail: string;
    companyLogoUrl: string;
    show: boolean;
  };

export const SwitchCompanyModal = ({
  onModalClose,
  switchCompany,
  userEmail,
  companyLogoUrl,
  show,
  userCompanies,
  companyId,
}: SwitchCompanyModalProps) => {
  const initialValues = {
    companyId: "",
  };

  const handleModalClose = () => onModalClose();

  const handleSubmit = (formValues: FormValues) => {
    const companyIdToNum = parseInt(formValues.companyId, 10);

    switchCompany({
      companyId: companyIdToNum,
      audience: [import.meta.env.VITE_AUDIENCE],
      username: userEmail,
    });

    handleModalClose();
  };

  const formattedCompanies = userCompanies
    .filter((company) => parseInt(company.id, 10) !== companyId)
    .sort((first, second) => (first.name > second.name ? 1 : -1));

  return (
    <Portal>
      <Backdrop
        fade
        visible={show}
        onClick={handleModalClose}
        data-testid={`SwitchCompanyModal-Backdrop-${show ? "Visible" : "Hidden"}`}
      />
      <SwitchCompanyOverlay fade visible={show}>
        <StyledCompaniesModal>
          <StyledLogoContainer>
            <CompanyAvatar imgSrc={companyLogoUrl} classes="company-logo" size="md" />
            <Heading as="h4">Switch Company</Heading>
            <Paragraph>Select a company</Paragraph>
          </StyledLogoContainer>
          <StyledDivider />
          <StyledCompaniesContainer>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              validationSchema={Yup.object().shape({
                companyId: Yup.string().required(),
              })}
              render={({ isSubmitting, isValid }) => (
                <Form>
                  <Field>
                    <FormikField
                      name="companyId"
                      render={({ form: { setFieldValue } }) => (
                        <SearchableList
                          renderAvatar={(params) => (
                            <CompanyAvatar imgSrc={params.avatarUrl} size="md" />
                          )}
                          singleSelect
                          searchBarPlaceholder="Search for a company..."
                          listItems={formattedCompanies}
                          noListItemsMessage="Sorry, you don't have any companies to switch to..."
                          onChangeItem={(selectedIds: Array<string>) => {
                            // selectedIs will always be an array with one item since it is a single select
                            setFieldValue("companyId", selectedIds[0]);
                          }}
                        />
                      )}
                    />
                  </Field>
                  <StyledActionsContainer>
                    <Button
                      palette="secondary"
                      onClick={handleModalClose}
                      data-testid={"SwitchCompanyModal-cancel-button"}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#3246F0",
                        color: "white",
                      }}
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      data-testid={"SwitchCompanyModal-submit-button"}
                    >
                      Done
                    </Button>
                  </StyledActionsContainer>
                </Form>
              )}
            />
          </StyledCompaniesContainer>
        </StyledCompaniesModal>
      </SwitchCompanyOverlay>
    </Portal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchCompanyModal);
