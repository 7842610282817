import { useQuery } from "@tanstack/react-query";
import { Wage } from "../../../containers/GetCrewMembers/types/propTypes/HourlyPayField.types";
import { readMinWage } from "../minWage";

type AddressType = {
  city?: string;
  country_area?: string;
  county?: string;
};

export const useMinWageQuery = (enabled: boolean, address: AddressType) =>
  useQuery(
    ["minWage", JSON.stringify(address)],
    () => {
      const today = new Date();
      const payload = {
        date: today.toISOString(),
        city: address.city || "",
        state: address.country_area || "",
        county: address.county || "",
      };
      return readMinWage(payload);
    },
    { enabled, select: (data: Wage) => data.wage },
  );
