const { addIndex, reduce } = require("ramda");

const reduceI = addIndex(reduce);

const createHash = (p, k, i) => {
  p[k] = i;
  return p;
};

module.exports = reduceI(createHash, {});
