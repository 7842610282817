import React from "react";
import styled from "styled-components";
import { LastActive, UserName } from "./styledComponents";
import { InternalUser, SignUpStatuses } from "./InternalUser";
import { ValueOf } from "../../../../types/util-types";

const Colors = {
  Coral: "coral",
  Sunset: "sunset",
  Unset: "unset",
} as const;
type Color = ValueOf<typeof Colors>;

const StyledLastActive = styled(LastActive)<{ color?: Color }>`
  color: ${({ color, theme }) => {
    switch (color) {
      case Colors.Coral:
        return theme.colors.coral;
      case Colors.Sunset:
        return theme.colors.sunset;
      default:
        return Colors.Unset;
    }
  }};
`;

/**
 * Formats date in format: Month_Name DD, YYYY
 */
function formatDate(date: Date) {
  return Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
}

function getTextAndColor(rowData: InternalUser): { text: string; color?: Color } {
  switch (rowData.signUpStatus) {
    case SignUpStatuses.Invited:
      return {
        text: `Invited on ${formatDate(new Date(rowData.invitedAt ?? ""))}`,
        color: Colors.Sunset,
      };

    case SignUpStatuses.NotMigrated:
      return { text: "Not migrated" };

    case SignUpStatuses.ResetRequired:
      return { text: "Password Reset Required", color: Colors.Coral };

    case SignUpStatuses.Expired:
      return { text: "Invite Expired", color: Colors.Coral };

    default:
      return { text: `Last active ${formatDate(new Date(rowData.lastActive))}` };
  }
}

export const NameAndStatus = ({ rowData }: { rowData: InternalUser }) => {
  const { text, color } = getTextAndColor(rowData);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <UserName>{rowData.name}</UserName>
        <StyledLastActive color={color}>{text}</StyledLastActive>
      </div>
    </div>
  );
};
