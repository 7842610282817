// File for holding cognito user pool configuration

export type CognitoConfig = {
  REGION: string;
  USER_POOL_ID: string;
  USER_POOL_APP_CLIENT_ID: string;
};

// Defaults are for a test pool in bc-nonprod. Defaults are not blank to
// prevent errors from showing in console when cognito migration is not enabled
export const COGNITO_CONFIG: CognitoConfig = {
  REGION: import.meta.env.VITE_COGNITO_REGION || "us-west-2",
  USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID || "us-west-2_ZCaIPqU7h",
  USER_POOL_APP_CLIENT_ID:
    import.meta.env.VITE_COGNITO_USER_POOL_APP_CLIENT_ID || "2ajdfsgf5h5p6a5pclv25emkvo",
};
