import { ifProp, prop, theme, withProp } from "styled-tools";
import styled, { css } from "styled-components";
import { BaseStyleCss, BaseStyleProps } from "./Box";

function getSelector(object: any): string {
  if (object != null && object.styledComponentId) {
    return `.${object.styledComponentId}`;
  }
  return "";
}

const GroupItem = styled.div`
  ${BaseStyleCss};
  ${theme("GroupItem")};
`;

const groupItemSelector = getSelector(GroupItem);

const verticalAt = (pass: any, fail?: any) =>
  ifProp(
    "verticalAt",
    css<{ fail: any }>`
      @media (min-width: ${withProp("verticalAt", (x) => x + 1)}px) {
        ${fail};
      }
      @media (max-width: ${prop("verticalAt")}px) {
        ${pass};
      }
    `,
    fail,
  );

interface GroupProps extends BaseStyleProps {
  vertical: boolean;
  verticalAt?: number;
}

const Group = styled.div<GroupProps>`
  ${BaseStyleCss};
  display: flex;
  flex-direction: ${ifProp("vertical", "column", "row")};
  ${verticalAt("flex-direction: column")};

  > *:not(:first-child):not(:last-child),
  > *:not(:first-child):not(:last-child) ${groupItemSelector} {
    border-radius: 0;
    ${verticalAt(
      css`
        border-top-width: 0;
      `,
      css`
        border-${ifProp("vertical", "top", "left")}-width: 0;
      `,
    )};
  }

  > *:first-child,
  > *:first-child ${groupItemSelector} {
    border-bottom-right-radius: 0;
    ${verticalAt(
      css`
        border-bottom-left-radius: 0;
      `,
      css`border-${ifProp("vertical", "bottom-left", "top-right")}-radius: 0;`,
    )};
  }

  > *:last-child,
  > *:last-child ${groupItemSelector} {
    border-top-left-radius: 0;
    ${verticalAt(
      css`
        border-top-width: 0;
        border-top-right-radius: 0;
      `,
      css`
        border-${ifProp("vertical", "top", "left")}-width: 0;
        border-${ifProp("vertical", "top-right", "bottom-left")}-radius: 0;
      `,
    )};
  }

  ${theme("Group")};
`;

Group.defaultProps = {
  role: "group",
};

export { Group, GroupItem };
