import React from "react";
import { Link, useMatch } from "react-router-dom";
import { Flex } from "@bluecrew/web-react-core";

type NavLinkProps = {
  to: string;
  match?: Array<string>;
  children: React.ReactNode;
};

/**
 * returns a boolean that indicates whether or not if the path matches the current location
 */
const useIsMatch = ({ match, to }: Pick<NavLinkProps, "to" | "match">) => {
  let isMatch = false;

  match?.forEach((matchPath) => {
    isMatch = !!useMatch(matchPath) || isMatch;
  });
  isMatch = !!useMatch(to) || isMatch;

  return isMatch;
};

export const NavLink = ({ to, match, children }: NavLinkProps) => {
  const isMatch = useIsMatch({ match, to });

  return (
    <Flex>
      <Link to={to} className={isMatch ? "active" : ""}>
        {children}
      </Link>
    </Flex>
  );
};
