import { UserType } from "../../../api/bluecrew/types";
import { NewUser } from "../../../forms/validation";
import {
  InternalUser,
  SignUpStatusFields,
  SignUpStatuses,
  typeToOrder,
} from "../ManageUsersTable/InternalUser";
import { EXTERNALID_PLACEHOLDER, LAST_ACTIVE_PLACEHOLDER, USERID_PLACEHOLDER } from "../constants";

// ------------------------ Creating a dummy Internal User ------------------------ //
// Branch Users aren't created immediately, so a dummy InternalUser is made in place. This dummy InternalUser is
// passed to the Assign Companies Modal for company assignment. Then they're created passing the right data & company assignments to
// v1/companies/sendInvite.
type NewBranchUserRequest = {
  companyIds: number[];
  userId: number;
  externalId: string;
  name: string;
  emailAddress: string;
  lastActive: string;
  assignedCompanies: number;
  assignedCompaniesIds: number[];
  userRole: any;
  phoneNumber: string;
  userPfp: null;
  userOrder: any;
} & SignUpStatusFields;

/**
 * Format that the Assign Companies Modal requires. Branch users can only be created if they have a
 * company assignment, so this is essentially to mock an InternalUser so we can assign companies to it.
 * Afterwards, it's passed off to the v1/companies/sendInvite API endpoint with the company assignments.
 */
export const convertToNewBranchUserRequest = (data: NewUser): NewBranchUserRequest => {
  return {
    companyIds: [],
    userId: USERID_PLACEHOLDER,
    externalId: EXTERNALID_PLACEHOLDER,
    name: `${data.normalized_first_name} ${data.normalized_last_name}`,
    emailAddress: data.email,
    lastActive: LAST_ACTIVE_PLACEHOLDER,
    assignedCompanies: 0,
    assignedCompaniesIds: [],
    userRole: UserType[data.userType],
    phoneNumber: data.phone,
    userPfp: null,
    userOrder: typeToOrder[data.userType],
    signUpStatus: SignUpStatuses.Invited,
    invitedAt: Date(),
  };
};

// ------------------------ Creating an InternalUser ------------------------ //
// The v1/companies/sendInvite endpoint has a different format for creating a user.
// This just converts the NewUser data to the format that the endpoint requires.

type NewInternalUserRequest = {
  companyIds: string[];
  username: string;
  firstName: string;
  lastName: string;
  userType: string;
  phoneNumber: string;
  region: null | string;
};

// Format that the Add New User modal requires to pass along to the v1/companies/sendInvite API endpoint.
export const convertToNewInternalUserRequest = (
  data: NewUser,
  userCompanyId: number,
  clonedUser?: InternalUser,
): NewInternalUserRequest => {
  let companyIds: string[];
  if (clonedUser?.assignedCompaniesIds.length) {
    companyIds = clonedUser.assignedCompaniesIds.map(String);
  } else {
    // create with just the parent company Id for Admin and Payroll Admin
    companyIds = [userCompanyId.toString()];
  }
  return {
    companyIds,
    username: data.email,
    firstName: data.normalized_first_name,
    lastName: data.normalized_last_name,
    userType: data.userType,
    phoneNumber: data.phone.replace(/-/g, ""),
    region: null,
  };
};
