import React from "react";
import SubRowComponent from "./SubRowComponent";
import { useNavigateToScheduleDetailPage } from "../navigationHooks";

interface IndividualShiftsRowProps {
  rowProps: any;
  row: any;
  sortBy: any;
}

const IndividualShiftsRow = ({ rowProps, row, sortBy }: IndividualShiftsRowProps) => {
  const navigateToScheduleDetailPage = useNavigateToScheduleDetailPage();
  return (
    <React.Fragment key={`${rowProps.key}-expanded-${row.original.blueshift_request_id}`}>
      <tr
        {...row.getRowProps()}
        className={"top-level-row-individual-shift"}
        data-pendo-key="ScheduleRequestTableTopLevelRow"
      >
        {row.cells.map((cell) => {
          const cellKey = cell.getCellProps().key;
          return (
            <td
              key={`${rowProps.key}-expanded-${row.original.blueshift_request_id}-${cellKey}`}
              {...cell.getCellProps([{ className: cell.column.className }])}
            >
              {cell.render("Cell", {
                rowData: {
                  ...row.original,
                },
                row: {
                  ...row,
                  canExpand: true,
                },
              })}
            </td>
          );
        })}
      </tr>
      {row.isExpanded ? (
        <SubRowComponent
          row={row}
          rowProps={rowProps}
          sortBy={sortBy}
          openModal={(job: any) => {
            navigateToScheduleDetailPage(job.schedule_id);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

export default IndividualShiftsRow;
