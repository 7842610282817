import { RESIZE_VALUE, Textarea, Input as LegacyInput } from "@bluecrew/blueprint-web";
import { Button, H2Heading, H4Heading, Input } from "@bluecrew/web-react-core";
import React, { useState } from "react";
import {
  COMPANY_DESCRIPTION_MAX_LENGTH,
  COMPANY_DESCRIPTION_MIN_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
} from "../../../../shared/constants";
import { CompanyInfo } from "../../../api/bluecrew/types";
import { getCompanyInformation } from "../../../redux/selectors/company";
import { FieldTextLength } from "../../GetCrewMembers/FieldTextLength";
import { AbilityContext } from "../../PermissionsContext";
import { generalInfoStrings } from "../CompanySettingStringsEN";
import {
  ButtonWrapper,
  FlexBaselineStartRow,
  SettingsCard,
  StyledCompanyAvatar,
  StyledPointerLink,
} from "../styledComponents";
import { ContactDiv, InputField, ErrorText } from "./styledComponents";
import { useRetrieveLinkedCompaniesQuery } from "./hooks";
import { LinkedCompaniesModal } from "./LinkedCompaniesModal";
import { ParentCompanyRelationalData } from "./types";
import { usePendo, useUpdateCompanyDetailsMutation } from "../hooks";
import { useAppSelector } from "../../../redux";

export type GeneralInfoProps = {
  initialName: string;
  initialPhoneNumber: string;
  initialDescription: string;
};

export const GeneralInfo = ({
  initialName,
  initialPhoneNumber,
  initialDescription,
}: GeneralInfoProps) => {
  const { companyLogoUrl } = useAppSelector(getCompanyInformation);
  const trackWithPendo = usePendo();

  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [description, setDescription] = useState(initialDescription);
  const [childCompaniesData, setChildCompaniesData] = useState<ParentCompanyRelationalData[]>([]);
  const [parentCompanyData, setParentCompanyData] = useState<ParentCompanyRelationalData>();
  const [fieldFocus, setFieldFocus] = useState(false);
  const [showLinkedCompaniesModal, setShowLinkedCompaniesModal] = useState(false);

  const ability = React.useContext(AbilityContext);
  const canUpdateCompanyInformation = ability.can("update", "company_information");

  // Disable the save button if the description is too large or too small
  // Additionally, set error so the FieldTextLength component tells user if
  // the description is too short, or too long.
  const hasDescriptionError =
    description.length < COMPANY_DESCRIPTION_MIN_LENGTH ||
    description.length > COMPANY_DESCRIPTION_MAX_LENGTH;
  const parsedPhoneNumber = phoneNumber.replace(/[^0-9]+/g, "");
  const hasPhoneNumberError =
    parsedPhoneNumber.length > 0 && parsedPhoneNumber.length < MAX_PHONE_NUMBER_LENGTH;
  const hasError = hasDescriptionError || hasPhoneNumberError;

  const updateCompanyDetailsMutation = useUpdateCompanyDetailsMutation();

  const handleClick = (newName: string, newPhoneNumber: string, newDescription: string) => {
    const companyInfo: CompanyInfo = {
      name: newName,
      phone_number: newPhoneNumber,
      description: newDescription,
    };

    updateCompanyDetailsMutation.mutate(companyInfo, {
      onSuccess: () => {
        trackWithPendo("Company Name and Description (general info)", { newValue: companyInfo });
      },
    });

    // Blur button after click so that hover color clears when button is un-hovered
    (document.activeElement as HTMLElement | undefined)?.blur();
  };

  const { refetch: refetchLinkedCompanies } = useRetrieveLinkedCompaniesQuery();

  const retrieveLinkedCompanies = async () => {
    const { data: linkedCompaniesData } = await refetchLinkedCompanies();
    if (!linkedCompaniesData) {
      return;
    }
    const { parentCompany, childCompanies } = linkedCompaniesData;
    setParentCompanyData(parentCompany);
    setChildCompaniesData(childCompanies);
    setShowLinkedCompaniesModal(true);
  };

  const canViewParentCompanyRelations = ability.can("view", "parent_company_relations");

  return (
    <SettingsCard data-pendo-key="generalInfoSection">
      <H2Heading>{generalInfoStrings.title}</H2Heading>
      <StyledCompanyAvatar imgSrc={companyLogoUrl} />
      <FlexBaselineStartRow>
        <H4Heading>{generalInfoStrings.labels.name}</H4Heading>

        {canViewParentCompanyRelations ? (
          <StyledPointerLink
            onClick={() => {
              retrieveLinkedCompanies();
            }}
          >
            {generalInfoStrings.linkedCompaniesModal.openModalText}
          </StyledPointerLink>
        ) : null}
      </FlexBaselineStartRow>
      <ContactDiv>
        <Input
          // @dloukusa 9/22/22 - Talked to product, leaving this disabled to match web-angular unless a need arises to make name editable
          disabled
          placeholder={generalInfoStrings.labels.name}
          defaultValue={initialName}
        />

        <InputField>
          <LegacyInput
            error={hasPhoneNumberError}
            type="tel"
            placeholder={generalInfoStrings.labels.phoneNumber}
            value={phoneNumber}
            onChange={setPhoneNumber}
            mask="(999) 999-9999"
          />
          {hasPhoneNumberError && <ErrorText>Invalid phone number</ErrorText>}
        </InputField>
      </ContactDiv>
      <H4Heading>{generalInfoStrings.labels.description}</H4Heading>
      {showLinkedCompaniesModal && parentCompanyData ? (
        <LinkedCompaniesModal
          setShowLinkedCompaniesModal={setShowLinkedCompaniesModal}
          childCompaniesData={childCompaniesData}
          parentCompanyData={parentCompanyData}
          show={showLinkedCompaniesModal}
        />
      ) : null}
      <Textarea
        disabled={!canUpdateCompanyInformation}
        placeholder={generalInfoStrings.labels.description}
        value={description}
        onChange={(text) => {
          setDescription(text);
        }}
        onFocus={() => setFieldFocus(true)}
        onBlur={() => {
          setFieldFocus(false);
        }}
        resize={RESIZE_VALUE.vertical}
        styles={{ minHeight: "100px" }}
      />
      {/* Show characters remaining */}
      <FieldTextLength
        focused={fieldFocus}
        error={hasError}
        minLength={COMPANY_DESCRIPTION_MIN_LENGTH}
        maxLength={COMPANY_DESCRIPTION_MAX_LENGTH}
        length={String(description).length}
      />
      <ButtonWrapper>
        <Button
          disabled={hasError || !canUpdateCompanyInformation}
          palette="primary"
          onClick={() => handleClick(initialName, parsedPhoneNumber, description)}
        >
          {generalInfoStrings.labels.saveButton}
        </Button>
      </ButtonWrapper>
    </SettingsCard>
  );
};
