import React from "react";
import styled from "styled-components";

export interface JobRequestTableCellProps {
  cellTitle: string;
  // eslint-disable-next-line no-undef
  cellContents: JSX.Element | string;
}

const JobRequestTableCellStyle = styled("div")`
  padding-bottom: 24px;
`;

const JobRequestTableCellTitleStyle = styled("div")`
padding-top: 2px;
padding-bottom: 2px
color: ${({ theme }) => theme.colorTokens.gray600};
font-size: 12px 
`;

const JobRequestTableCellContentsStyle = styled("div")`
  padding-top: 8px;
`;

export const JobRequestTableCell = ({ cellTitle, cellContents }: JobRequestTableCellProps) => (
  <JobRequestTableCellStyle>
    <JobRequestTableCellTitleStyle>{cellTitle}</JobRequestTableCellTitleStyle>
    <JobRequestTableCellContentsStyle>{cellContents}</JobRequestTableCellContentsStyle>
  </JobRequestTableCellStyle>
);
