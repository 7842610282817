import React from "react";
import { Switch, SwitchType, useThemedStyletron } from "@bluecrew/blueprint-web";
import { styled, withStyle } from "baseui";
import { Theme } from "baseui/theme";
import { CenteredWrapper } from "../CenteredWrapper";
import { ChooseCrewFieldProps } from "../types/propTypes/ChooseCrewField.types";

export const ChooseCrewField = ({
  isEnabled,
  onSwitchClick,
  isBlueshiftSchedule,
  disabled,
}: ChooseCrewFieldProps) => {
  const [, $theme] = useThemedStyletron();
  return (
    <StyledContainer
      $theme={$theme}
      hidden={isBlueshiftSchedule}
      data-pendo-key="GetCrewMembersPageDirectInviteContainer"
      data-testid="choose-crew-field-container"
    >
      <CenteredWrapper>
        <Switch
          type={disabled ? SwitchType.disabled : undefined}
          value={isEnabled}
          onChange={(state) => onSwitchClick && onSwitchClick(state)}
        />
        <TextWrapper>
          <BoldText>Direct Invite</BoldText>
          <StyledText>From your workforce</StyledText>
        </TextWrapper>
      </CenteredWrapper>
    </StyledContainer>
  );
};

type PropsT = { $theme: Theme };
const StyledContainer = styled("div", ({ $theme }: PropsT) => ({
  paddingTop: $theme.sizing.scale800,
}));

const TextWrapper = styled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale400,
}));

const StyledText = styled("p", ({ $theme }) => ({
  margin: 0,
  ...$theme.typography.font200,
}));

const BoldText = withStyle(StyledText, () => ({
  fontWeight: 600,
}));
