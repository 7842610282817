/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserCompanyInfo } from "../../../api/bluecrew/types";

export type ManageUsersCompanyFilterState = {
  selectedCompanies: UserCompanyInfo[];
};

const initialState: ManageUsersCompanyFilterState = {
  selectedCompanies: [],
};

export const manageUsersCompanyFilterSlice = createSlice({
  name: "manageUsersCompanyFilter",
  initialState,
  reducers: {
    setSelectedCompanies: (state, action: PayloadAction<UserCompanyInfo[]>) => {
      state.selectedCompanies = action.payload;
    },
    addToSelectedCompanies: (state, action: PayloadAction<UserCompanyInfo>) => {
      state.selectedCompanies = [...state.selectedCompanies, action.payload];
    },
    removeFromSelectedCompanies: (state, action: PayloadAction<number>) => {
      state.selectedCompanies = state.selectedCompanies.filter((company) => 
        company.companyId !== action.payload);
    },
  },
});

export const {
  setSelectedCompanies,
  addToSelectedCompanies,
  removeFromSelectedCompanies,
} = manageUsersCompanyFilterSlice.actions;

export default manageUsersCompanyFilterSlice.reducer;
