import ReactGoogleMapsLoader from "react-google-maps-loader";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { HeadingXXLarge, LabelSmall } from "baseui/typography";
import { Block } from "baseui/block";
import {
  AlertIcon,
  Button,
  GreaterThanIcon,
  SelectionButton,
  SelectionButtonKinds,
  Spinner,
  themedStyled,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import { KIND } from "baseui/button";
import moment from "moment-timezone";
import { HourlyPayField } from "../HourlyPayField";
import { ArrivalInstructionsField } from "../ArrivalInstructionsField";
import { DescriptionField } from "../DescriptionField";
import { DressCodeField } from "../DressCodeField";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { PositionTitleField } from "../PositionTitleField";
import { WorksiteField } from "../WorksiteField";
import { FormFieldTypes } from "../types/propTypes/CreatePositionScreen.types";
import {
  Address,
  CrewMemberProps,
  PositionDetailsEditScreenProps,
  ReasonCodeItem,
  ReasonCodes,
  StringObjInterface,
  VersionItem,
} from "../types/propTypes/PositionDetailsEdit.types";
import { EditHistoryModal } from "./EditHistoryModal";
import { CrewMembersConfirmationsModal } from "./CrewMembersConfiramtionsModal";
import { WorksiteAdvancedControl } from "./WorksiteAdvancedControl";
import { ConfirmChangesModal } from "./ConfirmChangesModal";
import { GeneralRequirementsField } from "../GeneralRequirementsField";
import { SkillsetSection } from "../CreatePositionScreen/SkillsetSection";
import { RequirementsSection } from "../CreatePositionScreen/RequirementsSection";
import { useSelectionList } from "../useSelectionList";
import { formatAddress } from "../mapPostionData";
import { Divider } from "../../../components/Divider";

const formFieldsTitles: StringObjInterface = {
  arrivalInstructions: "Arrival Instructions",
  arrival_instructions: "Arrival Instructions",
  positionTitle: "Position Title",
  title: "Position Title",
  dressCode: "Dress Code",
  dress_code: "Dress Code",
  worksite: "Worksite",
  description: "Description",
};

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";
const minWageErrorDefaultState = {
  show: false,
  minWage: 0,
};

const allFieldsDisabled = {
  address: true,
  arrivalInstructions: true,
  baseWage: true,
  description: true,
  dressCode: true,
  generalRequirements: true,
  positionTitle: true,
  requirements: true,
  skillsSet: true,
};

export const PositionDetailsEditScreen = React.memo(
  ({
    backButtonTitle,
    disabledFields,
    editHistory,
    hideTagsIfDisabled,
    notificationStatuses,
    onBackPressed,
    onCancel,
    onLocationSelect,
    onPublish,
    position,
    positions,
    requestPending = false,
    skillsRequirements,
    certifications,
    canViewWages,
    canEditPosition,
  }: PositionDetailsEditScreenProps) => {
    const historyID = "versionId";
    let crewMembers: CrewMemberProps[] = [];
    let crewMembersStatuses: any = null;
    // Get Crew Members that are currently can be affected by position change
    crewMembers = notificationStatuses?.filter(
      (notification: any) => notification.jobIdAndApplicationStatuses?.length,
    );
    // Get Crew Members accept status
    const getCrewMembersStatuses = () => {
      const acknowledged = crewMembers.filter((cm) => cm.notificationStatus === "ACCEPTED").length;
      const pending = crewMembers.filter(
        (cm) =>
          cm.notificationStatus === "SENT" ||
          cm.notificationStatus === "CONTACTING_SUPPORT" ||
          cm.notificationStatus === "PENDING",
      ).length;
      return { pending, acknowledged };
    };
    crewMembersStatuses = crewMembers?.length ? getCrewMembersStatuses() : null;

    const {
      control,
      trigger,
      errors,
      handleSubmit,
      setValue,
      reset,
      watch,
      formState: { dirtyFields, isValid },
    } = useForm<FormFieldTypes>({
      defaultValues: position,
      mode: "onChange",
    });
    const { worksite } = watch(["worksite"]);
    const firstRender = useRef(true);
    const [, theme] = useThemedStyletron();
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [minWageError, setMinWageError] = useState(minWageErrorDefaultState);
    const [changes, setChanges] = useState("");
    const [sameAddressSelected, setSameAddressSelected] = useState(false);
    // Position Edit History
    const [editHistoryModalOpen, setEditHistoryModalOpen] = useState(false);
    // Crew Members Confirmations Modal
    const [crewMembersModalOpen, setCrewMembersModalOpen] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(editHistory?.[0] || null);
    const isPreviousVersion =
      editHistory && selectedVersion?.[historyID] !== editHistory[0]?.[historyID];

    const disabledFieldStatus =
      isPreviousVersion || !canEditPosition ? allFieldsDisabled : disabledFields;
    const dressCodeEditEnabled = !disabledFieldStatus.dressCode;

    const editVersionTitle = isPreviousVersion ? "Previous version" : "Current version";

    useEffect(() => {
      if (!firstRender.current && worksite?.placeId === position.worksite?.placeId) {
        // If user selects same address we should not send this info in update request
        setSameAddressSelected(true);
      } else if (sameAddressSelected) {
        setSameAddressSelected(false);
      }
    }, [worksite?.placeId]);

    useEffect(() => {
      if (firstRender.current) {
        // We need this useEffect only as componentDidUpdate function
        // No need to execute on the first render
        firstRender.current = false;
        return;
      }
      if (isPreviousVersion && selectedVersion) {
        const getAddressString = (address: Address) =>
          `${address.street_address}, ${address.city}, ${address.country_area} ${address.postal_code}`;
        const { arrivalInstructions, additionalDescription, dressCode, title } =
          selectedVersion.position;
        minWageError.show && setMinWageError(minWageErrorDefaultState);
        const fieldsToBeUpdated: any = {
          positionTitle: title,
          arrivalInstructions,
          dressCode,
          description: additionalDescription,
          worksite: {
            ...formatAddress(selectedVersion.position.address),
            displayAddress: getAddressString(selectedVersion.position.address),
          },
        };

        const fieldsArray = Object.keys(fieldsToBeUpdated);
        for (let i = 0; i < fieldsArray.length; i += 1) {
          const fieldName = fieldsArray[i];
          // Update form fields according to the selected version
          setValue(fieldName, fieldsToBeUpdated[fieldName]);
        }
      } else {
        // Set default Form values
        reset();
      }
    }, [selectedVersion, isPreviousVersion]);

    const fieldsChanged = Object.keys(dirtyFields);
    // If user selects current position address from the list, disable Publish button
    const preventSameAddressChange =
      fieldsChanged.length === 1 && fieldsChanged[0] === "worksite" && sameAddressSelected;
    const disablePublishButton =
      !fieldsChanged.length ||
      requestPending ||
      !isValid ||
      minWageError.show ||
      preventSameAddressChange ||
      !canEditPosition;

    const onCloseConfirmModal = () => setConfirmModalOpen(false);

    const getChangedFields = (fields: any) =>
      Object.keys(fields)
        .filter((k) => fields[k] !== null)
        .map((key: string) => formFieldsTitles[key])
        .join(", ");

    const getListItems = () =>
      // Get positions edit versions list
      editHistory?.map((version: any) => {
        const { createdBy, createdAt } = version;
        const positionChanges = version.positionUpdate;
        const changedFields = positionChanges && getChangedFields(positionChanges);
        const createByFullName = createdBy ? `by ${createdBy.firstName} ${createdBy.lastName}` : "";
        const createdAtFormatted = moment(createdAt).format("MMM D, YYYY h:mm A");
        const selected = selectedVersion?.[historyID] === version?.[historyID];
        const showCurrentVersionTitle = !isPreviousVersion && selected;
        // Formatted data to display in List Component
        return {
          ...version,
          title: `v${version.versionId}${showCurrentVersionTitle ? " - Current version" : ""}`,
          subtitle: changedFields ? `Changes to ${changedFields}` : "",
          submittedBy: `Submitted ${createByFullName} on ${createdAtFormatted}`,
          selected,
        };
      }) || [];

    const { list, select } = useSelectionList<VersionItem>(getListItems(), {
      searchBy: historyID,
    });

    const onPublishChanges = (reasonCode: ReasonCodeItem) => {
      handleSubmit((formData) => {
        const fieldsToUpdate = Object.keys(dirtyFields);
        for (let i = 0; i < fieldsToUpdate?.length; i += 1) {
          // Check which fields have been changed
          const fieldName = fieldsToUpdate[i];
          dirtyFields[fieldName] = formData[fieldName];
        }

        const requestBody = {
          ...dirtyFields,
          reason_code: ReasonCodes[reasonCode.code],
          reason_text: reasonCode.code === ReasonCodes.OTHER ? reasonCode.inputValue : undefined,
          ...(dirtyFields.worksite && {
            // If user selected same position address that was before,
            // Do not send this info in response
            worksite:
              // @ts-ignore
              dirtyFields.worksite?.placeId !== position.worksite?.placeId &&
              // @ts-ignore
              dirtyFields.worksite?.address !== position.worksite?.address &&
              // @ts-ignore
              dirtyFields.worksite?.secondaryText !== position.worksite?.secondaryText
                ? dirtyFields.worksite
                : undefined,
          }),
        };
        onPublish(requestBody);
        onCloseConfirmModal();
      })();
    };

    const onSelectVersion = (item: VersionItem) => {
      setSelectedVersion(item);
      select(item);
      // Close History modal with delay, to make the UI look smoother
      setTimeout(() => setEditHistoryModalOpen(false), 100);
    };

    const onReturnCurrentVersion = () => {
      const currentVersion = editHistory[0];
      setSelectedVersion(currentVersion);
      select(currentVersion);
    };

    const onOpenConfirmModal = () => {
      const fieldsChanges = getChangedFields(dirtyFields);
      setChanges(fieldsChanges);
      setConfirmModalOpen(true);
    };

    return (
      <Block marginTop="scale400" paddingBottom="scale1200" maxWidth="827px">
        <StyledBackButton onClick={onBackPressed}>
          <LabelSmall
            $style={{
              color: theme.colors.ultramarine,
              marginRight: theme.sizing.scale300,
            }}
          >
            {backButtonTitle}
          </LabelSmall>
          <GreaterThanIcon color={theme.colors.ultramarine} />
        </StyledBackButton>
        <HeadingXXLarge $style={{ marginTop: theme.sizing.scale300 }}>
          {position.positionTitle}
        </HeadingXXLarge>
        <AlignCenterContainer>
          {selectedVersion ? (
            <AlignCenterContainer data-pendo-key="PositionVersionSelectContainer">
              <SelectionButton
                placeholder="Select edit version"
                kind={SelectionButtonKinds.NORMAL}
                onClick={() => setEditHistoryModalOpen(true)}
                header={`v${selectedVersion.versionId} - ${editVersionTitle}`}
                buttonWidth="234px"
              />
              {isPreviousVersion ? (
                <Button onClick={onReturnCurrentVersion} kind={KIND.tertiary} fontWeight="400">
                  Return to current version
                </Button>
              ) : null}
            </AlignCenterContainer>
          ) : null}

          {!isPreviousVersion && crewMembersStatuses ? (
            <CrewMemberStatusContainer onClick={() => setCrewMembersModalOpen(true)}>
              <LabelSmall $style={{ color: "#55565F" }}>
                Currently assigned Crew Members:
              </LabelSmall>
              <AlignCenterContainer>
                {crewMembersStatuses.acknowledged ? (
                  <>
                    <LabelSmall $style={{ color: "#42923DFF" }}>
                      {crewMembersStatuses.acknowledged} acknowledged edits
                    </LabelSmall>
                    <DotStyles>•</DotStyles>
                  </>
                ) : null}
                {crewMembersStatuses.pending ? (
                  <LabelSmall $style={{ color: "#E88030FF" }}>
                    {crewMembersStatuses.pending} pending
                  </LabelSmall>
                ) : null}
              </AlignCenterContainer>
            </CrewMemberStatusContainer>
          ) : null}
        </AlignCenterContainer>

        <Divider $marginTop={theme.sizing.scale800} $marginBottom={theme.sizing.scale800} />

        {/* TODO:BW-1105 - Find way to make this testable https://bluecrewjobs.atlassian.net/browse/BW-1105 */}
        {/* Position Form */}
        <ReactGoogleMapsLoader
          params={{
            key: GOOGLE_MAPS_API_KEY,
            libraries: "places,geocode",
          }}
          render={(googleMaps) =>
            googleMaps && (
              <>
                <SkillsetSection
                  control={control}
                  trigger={trigger}
                  // TODO: replace this with real data when this field will be enabled
                  skills={[]}
                  disabled={disabledFieldStatus.skillsSet}
                />
                <RequirementsSection
                  control={control}
                  trigger={trigger}
                  requirements={skillsRequirements}
                  certifications={certifications}
                  showNextButton={false}
                  disabled={disabledFieldStatus.requirements}
                  hideTagsIfDisabled={hideTagsIfDisabled}
                  skillsetId={position.skillset ? Number(position.skillset.id) : undefined}
                  isEditPosition
                />
                <div>
                  <PositionTitleField
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    disabled={disabledFieldStatus.positionTitle}
                  />
                  <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                  <WorksiteField
                    control={control}
                    trigger={trigger}
                    onLocationSelect={onLocationSelect}
                    disabled={disabledFieldStatus.address}
                    positions={positions || []}
                  />
                  <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                  {position?.minBlockRadius &&
                    position?.minWarnRadius &&
                    worksite?.lat &&
                    worksite?.lng && (
                      <>
                        <Divider
                          $marginTop={theme.sizing.scale800}
                          $marginBottom={theme.sizing.scale800}
                        />
                        <WorksiteAdvancedControl
                          control={control}
                          trigger={trigger}
                          errors={errors}
                          workplaceLocation={{
                            lat: worksite.lat,
                            lng: worksite.lng,
                          }}
                        />
                        <Divider
                          $marginTop={theme.sizing.scale800}
                          $marginBottom={theme.sizing.scale800}
                        />
                      </>
                    )}
                  {position.hourlyPay?.wage ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <HourlyPayField
                          control={control}
                          trigger={trigger}
                          disabled={disabledFieldStatus.baseWage}
                          canViewField={canViewWages}
                        />
                        {
                          // If new location has minWage higher then current position wage,
                          // we should notify user about that
                          minWageError.show ? (
                            <WageErrorContainer>
                              <AlertIcon color="#e72e3e" />
                              <WageErrorMessage>
                                The minimum wage in this location is ${minWageError.minWage}. <br />
                                Please create a new position for this worksite.
                              </WageErrorMessage>
                            </WageErrorContainer>
                          ) : null
                        }
                      </div>
                      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                    </>
                  ) : null}
                  <ArrivalInstructionsField
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    disabled={disabledFieldStatus.arrivalInstructions}
                  />
                  <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                  <DescriptionField
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    disabled={disabledFieldStatus.description}
                    editPositionScreen
                  />
                  <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                  {position.generalRequirements ? (
                    <>
                      <GeneralRequirementsField
                        control={control}
                        trigger={trigger}
                        errors={errors}
                        disabled={disabledFieldStatus.generalRequirements}
                      />
                      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                    </>
                  ) : null}
                  <DressCodeField
                    control={control}
                    trigger={trigger}
                    errors={errors}
                    disabled={!dressCodeEditEnabled}
                  />
                  <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                </div>
                <Divider $marginTop={theme.sizing.scale800} $marginBottom={theme.sizing.scale800} />
                {
                  /* Cancel & Publish buttons */
                  isPreviousVersion ? (
                    <StyledButtonsContainer>
                      <Button
                        onClick={onReturnCurrentVersion}
                        kind={KIND.tertiary}
                        fontWeight="600"
                      >
                        Return to current version
                      </Button>
                    </StyledButtonsContainer>
                  ) : (
                    <StyledButtonsContainer data-pendo-key="EditPositionPageButtonContainer">
                      <Button onClick={onCancel} kind={KIND.tertiary} disabled={requestPending}>
                        Cancel
                      </Button>
                      <ContainerWithMargin>
                        <Button disabled={disablePublishButton} onClick={onOpenConfirmModal}>
                          Publish
                          {requestPending && (
                            <SpinnerContainer>
                              <Spinner size="10px" darkTrack />
                            </SpinnerContainer>
                          )}
                        </Button>
                      </ContainerWithMargin>
                    </StyledButtonsContainer>
                  )
                }

                {
                  /* Edit History modal */
                  editHistory ? (
                    <EditHistoryModal
                      list={list}
                      modalOpen={editHistoryModalOpen}
                      onClose={() => setEditHistoryModalOpen(false)}
                      onItemClick={onSelectVersion}
                    />
                  ) : null
                }

                {
                  /* Crew Member Confirmation status modal */
                  crewMembersModalOpen && crewMembers.length ? (
                    <CrewMembersConfirmationsModal
                      list={crewMembers}
                      modalOpen={crewMembersModalOpen}
                      onClose={() => setCrewMembersModalOpen(false)}
                    />
                  ) : null
                }

                {/* Confirm Changes modal */}
                <ConfirmChangesModal
                  changes={changes}
                  modalOpen={confirmModalOpen}
                  onClose={onCloseConfirmModal}
                  onPublish={onPublishChanges}
                />
              </>
            )
          }
        />
      </Block>
    );
  },
  PositionDetailsEditScreenPropsAreEqual,
);

function PositionDetailsEditScreenPropsAreEqual(
  prevProps: PositionDetailsEditScreenProps,
  nextProps: PositionDetailsEditScreenProps,
) {
  return (
    prevProps.position.lat === nextProps.position.lat &&
    prevProps.position.lng === nextProps.position.lng
  );
}

const StyledBackButton = themedStyled("div", ({ $theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  marginTop: $theme.sizing.scale900,
  color: $theme.colors.ultramarine,
}));

const StyledButtonsContainer = themedStyled("div", () => ({
  display: "flex",
  justifyContent: "end",
}));

const ContainerWithMargin = themedStyled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
}));

const SpinnerContainer = themedStyled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale300,
}));

const AlignCenterContainer = themedStyled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const CrewMemberStatusContainer = themedStyled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
  cursor: "pointer",
}));

const DotStyles = themedStyled("span", ({ $theme }) => ({
  margin: `0 ${$theme.sizing.scale300}`,
  color: $theme.colors.pebble,
}));

const WageErrorContainer = themedStyled("div", () => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
}));

const WageErrorMessage = themedStyled("span", ({ $theme }) => ({
  color: $theme.colors.negative,
  fontSize: "14px",
  fontWeight: 600,
  marginLeft: "12px",
}));
