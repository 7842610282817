import styled from "styled-components";

export const SearchWarningIconWrapper = styled.div`
  text-align: center;
  padding-top: 24px;
  background-color: ${({ theme }) => theme.colorTokens.white};
`;

export const SearchWarningTextWrapper = styled.div`
  padding-top: 24px;
  //styleName: Text/Normal Large;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #888992;
  background-color: ${({ theme }) => theme.colorTokens.white};
`;

export const SearchEmptyIcon = styled.img``;
