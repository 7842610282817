import { FieldErrors } from "react-hook-form";
import { Requirements } from "./RequirementsField.types";
import { BaseFieldProps } from "./BaseField.types";
import { Certifications } from "./CertificationsField.type";
// eslint-disable-next-line import/no-cycle
import { FormFieldTypes } from "./CreatePositionScreen.types";
// eslint-disable-next-line import/no-cycle
import { Positions } from "./PositionField.types";
// eslint-disable-next-line import/no-cycle

export interface StringObjInterface {
  [key: string]: string;
}

export interface ReasonCodeInterface {
  title: string;
  code: ReasonCodes;
}

interface DisabledFields {
  [key: string]: boolean;
}

export interface VersionItem {
  versionId: number;
  // TODO: remove type after edit position phase 3 split will be removed
  id: number;
  selected: boolean;
  submittedBy: string;
  subtitle: string;
  title: string;
}

export interface PositionDetailsEditScreenProps {
  backButtonTitle: string;
  disabledFields: DisabledFields;
  editHistory?: any; // TODO: add type
  hideTagsIfDisabled: boolean;
  notificationStatuses?: any; // TODO: add type
  onBackPressed: () => void;
  onCancel: () => void;
  onPublish: (data: any) => void;
  position: FormFieldTypes;
  positions: Positions;
  requestPending?: boolean;
  skillsRequirements: Requirements;
  certifications: Certifications;
  onLocationSelect: () => void;
  canViewWages: boolean;
  canEditPosition: boolean;
}

export interface WorksiteAdvancedControlProps extends BaseFieldProps {
  workplaceLocation: {
    lat: number;
    lng: number;
  };
  defaultWarnRadius?: number;
  defaultBlockRadius?: number;
  errors?: FieldErrors;
}

export interface EditPositionGeofenceProps {
  center: google.maps.LatLngLiteral;
  warnRadius?: number;
  blockRadius?: number;
  onChangeWarnRadius: (e: number) => void;
  onChangeBlockRadius: (e: number) => void;
  mapHeight?: string;
  mapWidth?: string;
}

export interface ConfirmChangesModalProps {
  changes: string;
  modalOpen: boolean;
  onClose: () => void;
  onPublish: (reasonCode: ReasonCodeItem) => void;
}

export interface EditHistoryModalProps {
  list: VersionItem[];
  modalOpen: boolean;
  onClose: () => void;
  onItemClick: (item: VersionItem) => void;
}

export interface ReasonCodeItem {
  code: ReasonCodes;
  selected: boolean;
  title: string;
  inputValue?: string;
}

export interface ListProps {
  items: ReasonCodeItem[];
  onItemClick: (val: ReasonCodeItem) => void;
  listItemRenderer: (val: ReasonCodeItem, onCLick: () => void) => void;
}

export interface ReasonListItemProps {
  charactersError: boolean;
  inputValue: string;
  item: ReasonCodeItem;
  onClick: () => void;
  setCharactersError: (val: boolean) => void;
  setInputValue: (val: string) => void;
}

export enum ReasonCodes {
  UNKNOWN = "UNKNOWN",
  TYPO = "TYPO",
  REQUIREMENTS = "REQUIREMENTS",
  OTHER = "OTHER",
}

interface CrewMemberInfo {
  firstName: string;
  lastName: string;
  profilePicture?: string;
}

export interface CrewMemberProps {
  crewMember: CrewMemberInfo;
  crewMemberId: string;
  notificationStatus: string;
  updatedAt: string;
  jobIdAndApplicationStatuses?: any[];
}

export interface CrewMembersConfirmationsProps {
  list: CrewMemberProps[];
  modalOpen: boolean;
  onClose: () => void;
}

export type Address = {
  display_address: string;
  id: number;
  meta: {
    google_place_id?: string;
  } | null;
  name: string;
  country_code: string;
  country_area: string;
  coords: {
    type: string;
    coordinates: number[];
  };
  city: string;
  city_area: null | string;
  street_address: string;
  street_address_two: null | string;
  postal_code: string;
  sorting_code: string | null;
  latitude: string;
  longitude: string;
  timezone: string;
  created_at: string;
  updated_at: string;
  route?: string | null;
};
