import styled from "styled-components";

export const ExamplesToggleButton = styled.div`
  cursor: pointer;
  color: #3246f0;
  text-align: right;
  padding-top: 4px;
`;

export const ExamplesTitleContainer = styled.div`
  align-self: start;
`;
