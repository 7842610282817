import { Button } from "primereact/button";
import styled from "styled-components";

export const SendMessageStyle = styled.div`
  border-color: #ddd;
  border-style: solid;
  border-width: 0 1px;
  box-shadow: 0 0 30px 0 #ccc;
  text-align: center;
  margin: 0 auto;
  padding: 10px 26px;
  max-width: 520px;
  width: 520px;

  form.sms-form {
    text-align: center;
  }

  .sms-form h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.72px;
    margin: 16px 0;
    padding: 5px;
    text-align: left;
  }

  .sms-form p {
    color: rgb(30, 31, 31);
    font-size: 16px;
    letter-spacing: -0.48px;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 5px;
    text-align: left;
  }

  .sms-form textarea {
    border-color: rgba(0, 0, 0, 0.13);
    border-style: solid;
    border-width: 0 0 1px 0;
    box-sizing: border-box;
    font-size: 16px;
    height: 140px;
    margin-top: 16px;
    padding: 5px;
    resize: none;
    width: 100%;
  }

  .sms-form textarea:focus {
    border-color: rgb(50, 70, 240);
    border-style: solid;
    border-width: 0 0 2px 0;
    outline: none;
  }

  .sms-form div {
    margin-bottom: 0.5em;
  }
`;

export const SubmitButton = styled(Button)`
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  letter-spacing: -0.36px;
  margin: 16px 0;
  width: 100%;
  text-align: center;
  display: inline-block;
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
`;
