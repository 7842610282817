import React, { useState } from "react";
import { FullLogo, Portal } from "@bluecrew/web-react-core";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  LogoContainer,
  StyledBackdrop,
  StyledOverlay as BCStyledOverlay,
} from "../styledComponents";
import { RequestPasswordResetForm } from "../Forms/RequestPasswordResetForm";
import ConfirmPasswordResetForm from "../Forms/ConfirmPasswordResetForm";
import { useConfirmPasswordReset, useRequestPasswordReset } from "../hooks";
import { ValueOf } from "../../../../types/util-types";
import { SuccessFailureComponent } from "../../SuccessFailureModal/SuccessFailureComponent";
import { MessageVariant, MessageVariants } from "../../Message";
import { CollapsibleMessage } from "../../CollapsableMessage";
import { loginStrings } from "../strings";
import { BackToLoginButton } from "../BackToLoginButton";
import { LoginRouteStrings } from "../../../../shared/constants";
import { setSentryUser } from "../../../sentry";

const ResetPasswordSteps = {
  Request: "requestReset",
  Confirm: "confirmReset",
  Success: "success",
} as const;
type ResetPasswordStep = ValueOf<typeof ResetPasswordSteps>;

// Most of this styling is setup to allow the second step (Complete password
// reset) to be responsive when zoomed in.
// This overlay and the Wrapper below are setup with overflow: hidden and
// overflow: auto respectively so that the scroll bar does not remove the
// radius from the modal
const StyledOverlay = styled(BCStyledOverlay)<{ flowStep: ResetPasswordStep }>`
  height: min-content;
  max-height: 95vh;
  margin-top: 0.1px;
  overflow: hidden;
  && > * {
    margin: unset;
  }
`;
const ResetPasswordFlowWrapper = styled.div<{ flowStep: ResetPasswordStep }>`
  overflow: ${({ flowStep }) => (flowStep === ResetPasswordSteps.Confirm ? "auto" : "unset")};
  display: grid;
  padding: 1.5rem 1rem 1rem 1rem;
`;

export const ResetPasswordFlow = () => {
  const navigate = useNavigate();

  const [flowStep, setFlowStep] = useState<ResetPasswordStep>(ResetPasswordSteps.Request);
  const [flowMessage, setFlowMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState<MessageVariant>(MessageVariants.Positive);
  const [userUsername, setUsername] = useState("");

  const { mutate: requestPasswordReset, isLoading: requestResetIsLoading } =
    useRequestPasswordReset(
      () => {
        // onSuccess
        setFlowMessage("");
        setFlowStep(ResetPasswordSteps.Confirm);
      },
      (error) => {
        setMessageVariant(MessageVariants.Negative);
        setFlowMessage(error.message); // onError
      },
    );

  const { mutate: confirmPasswordReset } = useConfirmPasswordReset(
    () => setFlowStep(ResetPasswordSteps.Success),
    (error) => {
      setMessageVariant(MessageVariants.Negative);
      setFlowMessage(error.message); // onError
    },
  );

  return (
    <>
      <StyledBackdrop visible />
      <Portal>
        <StyledOverlay visible flowStep={flowStep}>
          <ResetPasswordFlowWrapper flowStep={flowStep}>
            {/* Don't show logo on confirm reset to free up space */}
            {flowStep !== ResetPasswordSteps.Confirm && (
              <LogoContainer>
                <FullLogo height="3rem" />
              </LogoContainer>
            )}
            <div style={{ flex: 1 }}>
              {flowStep === ResetPasswordSteps.Request && (
                <RequestPasswordResetForm
                  requestPasswordReset={(username: string) => {
                    setUsername(username);
                    setSentryUser(username);
                    requestPasswordReset({ username });
                  }}
                  onAnyFieldChange={() => setFlowMessage("")}
                  requestResetLoading={requestResetIsLoading}
                  footer={<BackToLoginButton />}
                >
                  <CollapsibleMessage
                    isOpen={!!flowMessage}
                    text={flowMessage}
                    variant={messageVariant}
                  />
                </RequestPasswordResetForm>
              )}
              {flowStep === ResetPasswordSteps.Confirm && (
                <ConfirmPasswordResetForm
                  onSubmit={(code: string, password: string) => {
                    confirmPasswordReset({ username: userUsername, code, password });
                  }}
                  resendCode={() => {
                    requestPasswordReset(
                      { username: userUsername },
                      {
                        onSuccess: () => {
                          setFlowMessage(loginStrings.codeResent);
                          setMessageVariant(MessageVariants.Positive);
                        },
                      },
                    );
                  }}
                  onAnyFieldChange={() => setFlowMessage("")}
                  footer={<BackToLoginButton />}
                >
                  <CollapsibleMessage
                    isOpen={!!flowMessage}
                    text={flowMessage}
                    variant={messageVariant}
                  />
                </ConfirmPasswordResetForm>
              )}
              {flowStep === ResetPasswordSteps.Success && (
                <SuccessFailureComponent
                  isSuccess
                  title={loginStrings.resetPasswordSuccess.title}
                  text={loginStrings.resetPasswordSuccess.blurb}
                  onButtonClick={() => navigate(LoginRouteStrings.Login)}
                  buttonLabel={loginStrings.resetPasswordSuccess.goToLogin}
                />
              )}
            </div>
          </ResetPasswordFlowWrapper>
        </StyledOverlay>
      </Portal>
    </>
  );
};
