import styled from "styled-components";
import { InputText } from "../../../components/InputText";

export const TimesheetsHeaderGroup = styled.div`
  position: sticky;
  padding-top: 1em;
  top: 0;
  background: ${({ theme }) => theme.colorTokens.white};
  z-index: 100;
`;

export const CrewMemberSearchContainer = styled(InputText)`
  min-width: 300px;
`;

export const TimesheetsBannersWrapper = styled.div`
`
