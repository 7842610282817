import React, { useState } from "react";
import { H3Heading, Paragraph, Button } from "@bluecrew/web-react-core";
import { SettingsCard, ButtonWrapper } from "../styledComponents";
import { RadioList, RadioOption } from "../../../components/RadioList";
import { continuousFillStrings } from "../CompanySettingStringsEN";
import { CompanyInfo } from "../../../api/bluecrew/types";
import { CONTINUOUS_FILL_OFF, CONTINUOUS_FILL_ON } from "../../../../shared/constants";
import { AbilityContext } from "../../PermissionsContext";
import { usePendo, useUpdateCompanyDetailsMutation } from "../hooks";

export const continuousFillOnTestId = "continuousFillOn";
export const continuousFillOffTestId = "continuousFillOff";

const options: Array<RadioOption> = [
  {
    label: continuousFillStrings.labels.on,
    text: continuousFillStrings.descriptions.on,
    value: CONTINUOUS_FILL_ON,
    testId: continuousFillOnTestId,
  },
  {
    label: continuousFillStrings.labels.off,
    text: continuousFillStrings.descriptions.off,
    value: CONTINUOUS_FILL_OFF,
    testId: continuousFillOffTestId,
  },
];

type ContinuousFillProps = { continuousFillSetting: number };

export const ContinuousFill = ({ continuousFillSetting }: ContinuousFillProps) => {
  const trackWithPendo = usePendo();
  const [selected, setSelected] = useState(
    options.findIndex((option) => option.value === continuousFillSetting),
  );

  const ability = React.useContext(AbilityContext);
  const canUpdateContinuousFill = ability.can("update", "continuous_fill");

  const updateCompanyDetailsMutation = useUpdateCompanyDetailsMutation();

  const handleClick = () => {
    const companyInfo: CompanyInfo = { refill_on: options[selected].value };

    updateCompanyDetailsMutation.mutate(companyInfo, {
      onSuccess: () => {
        trackWithPendo("Company level Refill Info", { newValue: companyInfo });
      },
    });

    // Blur button after click so that hover color clears when button is un-hovered
    (document.activeElement as HTMLElement | undefined)?.blur();
  };

  return (
    <SettingsCard data-pendo-key="continuousFillSection">
      <H3Heading>{continuousFillStrings.title}</H3Heading>
      <Paragraph>{continuousFillStrings.descriptions.general}</Paragraph>
      <RadioList
        disabled={!canUpdateContinuousFill}
        options={options}
        selected={selected}
        setSelected={setSelected}
      />
      <ButtonWrapper>
        <Button disabled={!canUpdateContinuousFill} palette="primary" onClick={handleClick}>
          {continuousFillStrings.labels.saveButton}
        </Button>
      </ButtonWrapper>
    </SettingsCard>
  );
};
