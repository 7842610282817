import { assetUrl, webV1Url } from "../../api/bluecrew/constants/index";

type PanelItemsType = {
  title: string;
  icon: string;
  url: string;
  key: string;
};

export const panelItems: Array<PanelItemsType> = [
  {
    title: "Manage jobs",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-manage-jobs.svg`,
    url: `${webV1Url}/app.html#!/managejobs`,
    key: "manage-jobs",
  },
  {
    title: "Timesheets",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-timesheets.svg`,
    url: `${webV1Url}/app.html#!/payroll/company`,
    key: "timesheets",
  },
  {
    title: "Companies",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-companies.svg`,
    url: `${webV1Url}/app.html#!/companies`,
    key: "companies",
  },
  {
    title: "Crew Member profiles",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-crew-member-profile.svg`,
    url: `${webV1Url}/app.html#!/viewprofiles`,
    key: "view-profiles",
  },
  {
    title: "Manage interviews",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-manage-interviews.svg`,
    url: `${webV1Url}/app.html#!/new-company-reviews`,
    key: "manage-interviews",
  },
  {
    title: "Direct deposit",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-direct-deposit.svg`,
    url: `${webV1Url}/app.html#!/direct-deposit`,
    key: "direct-deposit",
  },
  {
    title: "New Candidates",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-new-candidates.svg`,
    url: `${webV1Url}/app.html#!/candidates`,
    key: "candidates",
  },
  {
    title: "Manage Users",
    icon: `${assetUrl}/icons/AdminTools/admin-menu-icon-manage-users.svg`,
    url: `/manageusers`,
    key: "manage-users",
  },
];
