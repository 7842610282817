import Cookies from "js-cookie";
import { COGNITO_CONFIG } from "../../shared/aws-exports";
import { COGNITO_ENABLED_KEY, COGNITO_USER_NAME_KEY, REDIRECT_URL } from "../../shared/constants";

export const CognitoItemNames = {
  clockDrift: "clockDrift",
  idToken: "idToken",
  refreshToken: "refreshToken",
  userData: "userData",
  accessToken: "accessToken",
  LastAuthUser: "LastAuthUser",
};

export const cookieStorageKeys = [
  CognitoItemNames.idToken,
  CognitoItemNames.accessToken,
  CognitoItemNames.refreshToken,
  CognitoItemNames.LastAuthUser,
];

export type CognitoItemName = keyof typeof CognitoItemNames;

export const extractItemNameFromKey = (key: string) => {
  const tokens = key.split(".");
  return tokens[tokens.length - 1];
};

export const useCookieStorage = (key: string): Boolean => {
  const itemName = extractItemNameFromKey(key);
  // If web-react is running in localhost mode, put the user data into cookies so web-angular can access it
  if (itemName === "userData" && import.meta.env.VITE_COOKIE_DOMAIN?.includes("localhost")) {
    return true;
  }
  return cookieStorageKeys.includes(itemName);
};

export class CognitoUtils {
  // This 'flag' is set in scripts/components/login/index.tsx
  // It is set in local storage once a user is authenticated through cognito
  static setCognitoUsername = (username: string) =>
    window.localStorage.setItem(COGNITO_USER_NAME_KEY, username);

  static getCognitoUsername = () => window.localStorage.getItem(COGNITO_USER_NAME_KEY);

  static async setCognitoUsernameAsync(username: string) {
    this.setCognitoUsername(username);
    await this.getCognitoUsernameAsync();
  }

  static async getCognitoUsernameAsync() {
    const item = await this.getItemWithTimeout(() =>
      window.localStorage.getItem(COGNITO_USER_NAME_KEY),
    );
    return item;
  }

  static setRedirectUrl = (url?: string) => url && window.sessionStorage.setItem(REDIRECT_URL, url);

  static getRedirectUrl = () => window.sessionStorage.getItem(REDIRECT_URL);

  static removeRedirectUrl = () => window.sessionStorage.removeItem(REDIRECT_URL);

  /**
   * Run callback 'getItem' until it returns a non-null item or timesout.
   * Useful if you are waiting for something to show up in local storage before proceeding.
   * @param getItem
   * @param maxTimeout
   * @returns
   */
  static async getItemWithTimeout<T>(
    getItem: () => T | null,
    maxTimeout: number = 5000,
  ): Promise<T | null> {
    let item = getItem();
    const timeStep = 250; // MS
    let timePassed = 0;
    while (item == null) {
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, timeStep));
      item = getItem();
      if (timePassed > maxTimeout) {
        return null;
      }
      timePassed += timeStep;
    }
    return item;
  }

  /**
   * Function to cleanup anything that might be left over by cognito.
   * Should be used before serving legacy login experince after turning cognito split off
   */
  static cleanupCognito = () => {
    window.localStorage.removeItem(COGNITO_USER_NAME_KEY);
    window.localStorage.removeItem(COGNITO_ENABLED_KEY);
  };

  static getCognitoIdToken = () =>
    // NOTE: Everything below _should_ be defined before any requests are made
    // The login component in src/scripts/components/Login/index.tsx waits until a user is defined
    // then it writes COGNITO_USER_NAME to local storage
    // The amplify component also writes the tokens to local storage
    CognitoUtils.getCognitoItem("idToken");

  static getCognitoItem = (itemName: CognitoItemName) => {
    const itemKey = `${CognitoUtils.getCognitoKeyPrefix()}.${itemName}`;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (useCookieStorage(itemKey)) {
      return Cookies.get(itemKey) ?? null;
    }
    return window.localStorage.getItem(itemKey);
  };

  static getExternalUserId(): string | null {
    const userData = CognitoUtils.getCognitoItem("userData");

    if (userData === null) {
      return null;
    }

    const parsedUserData: { UserAttributes: { Name: string; Value: string }[] } =
      JSON.parse(userData);

    const externalIdPair = parsedUserData.UserAttributes.find(
      (e: { Name: string; Value: string }) => e.Name === "custom:external_user_id",
    );
    return externalIdPair!.Value;
  }

  static getCognitoKeyPrefix() {
    const cognitoUserName = window.localStorage.getItem(COGNITO_USER_NAME_KEY);
    return `CognitoIdentityServiceProvider.${COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID}.${cognitoUserName}`;
  }
}
