import { styled } from "baseui";
import React from "react";
import { BlueprintTheme, getMargin, UserAvatar } from "@bluecrew/blueprint-web";
import { NotificationManagerListItemProps } from "../types/propTypes/NotificationManagerField.types";

export const NotificationManagerListItem = ({
  item: { name, imgUrl },
}: NotificationManagerListItemProps) => (
  <StyledListItem>
    <StyledAvatarContainer>
      <UserAvatar imgSrc={imgUrl} fullName={name} size="lg" />
    </StyledAvatarContainer>
    <StyledLabelsContainer>
      <StyledUserTitle>{name}</StyledUserTitle>
    </StyledLabelsContainer>
  </StyledListItem>
);

const StyledListItem = styled("div", ({ $theme }) => ({
  display: "flex",
  width: "230px",
  marginBottom: $theme.sizing.scale600,
  marginRight: $theme.sizing.scale600,
}));

const StyledAvatarContainer = styled("div", ({ $theme }) => ({
  marginRight: $theme.sizing.scale300,
  display: "flex",
  justifyContent: "center",
}));

const StyledLabelsContainer = styled("div", {
  flex: 1,
  overflow: "hidden",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
});

const StyledUserTitle = styled<{}, "h6", BlueprintTheme>("h6", ({ $theme }) => ({
  ...$theme.typography.font350,
  fontWeight: 600,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.obsidian,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ...getMargin("0"),
  marginRight: $theme.sizing.scale300,
}));
