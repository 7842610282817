import type { Certifications } from "../../../types/propTypes/CertificationsField.type";
import type { Requirements } from "../../../types/propTypes/RequirementsField.types";

/**
 * Returns the list items of either Certifications or Requirements in a format usable by either the CertificationField
 * or the RequirementsField
 * @param isTouched Boolean Flag for different behavior on first render which is that the resulting list is
 * the same as the available Certifications or Requirements returned by the server just with a different format
 * @param availableItems Array<Certification | Requirement> View state list of Requirements or Certifications
 * @param [listItems] Array<Certification | Requirement> Untouched Certifications or Requirements from the Server
 */
export const getListItems = (
  isTouched: boolean,
  availableItems: Requirements | Certifications,
  listItems?: Requirements | Certifications,
) => {
  if (!Array.isArray(listItems)) {
    // eslint-disable-next-line no-param-reassign
    listItems = [];
  }
  if (!isTouched && !availableItems.length) {
    return listItems.map((item) => ({
      ...item,
      title: item.label,
    }));
  }
  return listItems.map((item) => ({
    ...item,
    title: item.label,
    selected: !!availableItems.find((selected) => item.id === selected.id),
  }));
};
