import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Field, FormikProps } from "formik";
import Select from "react-select";

import { ReasonCode } from "../../api/bluecrew/types";
import {
  ErrorMessage,
  FormContainer,
  FormTextFieldContainer,
  StyledFieldLabel,
  StyledTextArea,
  TextFieldContainer,
} from "./CancelScheduleModal.StyledComponents";

type DropdownOption = { label: string; value: number };

const dropdownOptions: DropdownOption[] = [
  {
    label: "Job description or details changed",
    value: ReasonCode.JOB_DESCRIPTION_OR_DETAILS_CHANGED,
  },
  {
    label: "Start/end date changed",
    value: ReasonCode.START_OR_END_DATE_CHANGED,
  },
  { label: "Schedule changed", value: ReasonCode.SCHEDULE_CHANGED },
  { label: "No longer needed", value: ReasonCode.NO_LONGER_NEEDED },
  { label: "Other", value: ReasonCode.OTHER },
];

export const validationSchema: Yup.SchemaOf<CancelScheduleValues> = Yup.object().shape({
  reasonCode: Yup.number().required("Please select a reason for cancelling"),
  reasonText: Yup.string().when("reasonCode", {
    is: ReasonCode.OTHER,
    then: Yup.string()
      .min(1, "Reason is too Short!")
      .max(500, "Reason is too long!")
      .required("Please enter why you are cancelling these job requests"),
  }),
});

interface CancelScheduleValues {
  reasonCode?: ReasonCode;
  reasonText?: string;
}

interface FormContentProps {
  formProps: FormikProps<CancelScheduleValues>;
}

export const initialValues: CancelScheduleValues = {
  reasonCode: undefined,
  reasonText: undefined,
};

export const FormContent: React.FC<FormContentProps> = ({ formProps }) => (
  <FormContainer>
    <StyledFieldLabel htmlFor="reasonCode">Reason for canceling</StyledFieldLabel>
    <Field
      name={"reasonCode"}
      options={dropdownOptions}
      onChange={(option: DropdownOption) => {
        formProps.setFieldValue("reasonCode", option.value);
      }}
      component={Select}
    />
    {formProps.errors.reasonCode ? (
      <ErrorMessage>{formProps.errors.reasonCode}</ErrorMessage>
    ) : null}
    {formProps.values.reasonCode === ReasonCode.OTHER && (
      <FormTextFieldContainer>
        <TextFieldContainer>
          <StyledFieldLabel htmlFor="reasonText">
            Why are you cancelling these job requests?
          </StyledFieldLabel>
          <Field
            name="reasonText"
            render={({ field }) => (
              <StyledTextArea {...field} rows={6} invalid={!!formProps.errors.reasonText} />
            )}
          />
          {formProps.errors.reasonText ? (
            <ErrorMessage>{formProps.errors.reasonText}</ErrorMessage>
          ) : null}
        </TextFieldContainer>
      </FormTextFieldContainer>
    )}
  </FormContainer>
);

FormContent.propTypes = {
  formProps: PropTypes.any.isRequired,
};
