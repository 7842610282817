import { useSplitClient } from "@splitsoftware/splitio-react";
import { RESEND_INVITE_MANAGE_USERS_SPLIT_NAME } from "../constants";

export const useResendInviteManageUsersSplitIsOn = () => {
  const split = useSplitClient();
  return split.isReady
    ? split.client?.getTreatment(RESEND_INVITE_MANAGE_USERS_SPLIT_NAME) === "on"
    : false;
};

export const DEBUG_REACT_SPLIT_NAME = "debug_react";
export const useDebugReactSplitIsOn = () => {
  const split = useSplitClient();
  return split.isReady ? split.client?.getTreatment(DEBUG_REACT_SPLIT_NAME) === "on" : false;
};
