// JOB - REDUCER
// =============================================================================

import { handleActions } from "redux-actions";
import { PartialRequestErrorModalData } from "../../api/bluecrew/types";

import jobActions from "../actions/job";

// ACTION TYPES
import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

export type DirectInviteUserIdsType = string;

const initialState = {
  directInviteUserIds: [] as Array<DirectInviteUserIdsType>,
  crewForHireIds: [] as Array<number>,
  jobs: { ...initialEndpointState },
  createJob: { ...initialEndpointState },
  jobTypes: { ...initialEndpointState },
  offerJob: { ...initialEndpointState },
  supervisors: { ...initialEndpointState },
  departments: { ...initialEndpointState },
  createPosition: { ...initialEndpointState },
  addWorkRequestToSch: { ...initialEndpointState },
  createDirectInviteJobAndSchedule: { ...initialEndpointState },
  createDirectInviteAndOptJobAndSchedule: { ...initialEndpointState },
  createCrewJobAndSchedule: { ...initialEndpointState },
  createCrewAndOptJobAndSchedule: { ...initialEndpointState },
  createBlueShiftScheduleJobs: { ...initialEndpointState },
  addCrewJobToSchedule: { ...initialEndpointState },
  numberOfBulkSingleShiftsToCreate: 0,
  failedBulkSingleShifts: [] as PartialRequestErrorModalData[],
  successfulBulkSingleShifts: [] as PartialRequestErrorModalData[],
};

export type JobState = typeof initialState;

const handlerMap = {
  [jobActions.jobs.request]: (state: JobState) => handleRequest("jobs", state),
  [jobActions.jobs.success]: (state: JobState, action) => handleSuccess("jobs", state, action),
  [jobActions.jobs.failure]: (state: JobState, action) => handleFailure("jobs", state, action),

  [jobActions.createJob.request]: (state: JobState) => handleRequest("createJob", state),
  [jobActions.createJob.success]: (state: JobState, action) =>
    handleSuccess("createJob", state, action),
  [jobActions.createJob.failure]: (state: JobState, action) =>
    handleFailure("createJob", state, action),

  [jobActions.jobTypes.request]: (state: JobState) => handleRequest("jobTypes", state),
  [jobActions.jobTypes.success]: (state: JobState, action) =>
    handleSuccess("jobTypes", state, action),
  [jobActions.jobTypes.failure]: (state: JobState, action) =>
    handleFailure("jobTypes", state, action),

  [jobActions.offerJob.request]: (state: JobState) => handleRequest("offerJob", state),
  [jobActions.offerJob.success]: (state: JobState, action) =>
    handleSuccess("offerJob", state, action),
  [jobActions.offerJob.failure]: (state: JobState, action) =>
    handleFailure("offerJob", state, action),

  [jobActions.supervisors.request]: (state: JobState) => handleRequest("supervisors", state),
  [jobActions.supervisors.success]: (state: JobState, action) =>
    handleSuccess("supervisors", state, action),
  [jobActions.supervisors.failure]: (state: JobState, action) =>
    handleFailure("supervisors", state, action),

  [jobActions.createPosition.request]: (state: JobState) => handleRequest("createPosition", state),
  [jobActions.createPosition.failure]: (state: JobState, action) =>
    handleFailure("createPosition", state, action),

  [jobActions.addWorkRequestToSch.request]: (state: JobState) =>
    handleRequest("addWorkRequestToSch", state),
  [jobActions.addWorkRequestToSch.success]: (state: JobState, action) =>
    handleSuccess("addWorkRequestToSch", state, action),
  [jobActions.addWorkRequestToSch.failure]: (state: JobState, action) =>
    handleFailure("addWorkRequestToSch", state, action),

  [jobActions.createBlueShiftScheduleJobs.request]: (state: JobState) =>
    handleRequest("createBlueShiftScheduleJobs", state),
  [jobActions.createBlueShiftScheduleJobs.success]: (state: JobState, action) =>
    handleSuccess("createBlueShiftScheduleJobs", state, action),
  [jobActions.createBlueShiftScheduleJobs.failure]: (state: JobState, action) =>
    handleFailure("createBlueShiftScheduleJobs", state, action),

  [jobActions.createDirectInviteJobAndSchedule.request]: (state: JobState) =>
    handleRequest("createDirectInviteJobAndSchedule", state),
  [jobActions.createDirectInviteJobAndSchedule.success]: (state: JobState, action) =>
    handleSuccess("createDirectInviteJobAndSchedule", state, action),
  [jobActions.createDirectInviteJobAndSchedule.failure]: (state: JobState, action) =>
    handleFailure("createDirectInviteJobAndSchedule", state, action),
  [jobActions.createDirectInviteAndOptJobAndSchedule.request]: (state: JobState) =>
    handleRequest("createDirectInviteAndOptJobAndSchedule", state),
  [jobActions.createDirectInviteAndOptJobAndSchedule.success]: (state: JobState, action) =>
    handleSuccess("createDirectInviteAndOptJobAndSchedule", state, action),
  [jobActions.createDirectInviteAndOptJobAndSchedule.failure]: (state: JobState, action) =>
    handleFailure("createDirectInviteAndOptJobAndSchedule", state, action),

  [jobActions.createCrewJobAndSchedule.request]: (state: JobState) =>
    handleRequest("createCrewJobAndSchedule", state),
  [jobActions.createCrewJobAndSchedule.success]: (state: JobState, action) =>
    handleSuccess("createCrewJobAndSchedule", state, action),
  [jobActions.createCrewJobAndSchedule.failure]: (state: JobState, action) =>
    handleFailure("createCrewJobAndSchedule", state, action),
  [jobActions.createCrewAndOptJobAndSchedule.request]: (state: JobState) =>
    handleRequest("createCrewAndOptJobAndSchedule", state),
  [jobActions.createCrewAndOptJobAndSchedule.success]: (state: JobState, action) =>
    handleSuccess("createCrewAndOptJobAndSchedule", state, action),
  [jobActions.createCrewAndOptJobAndSchedule.failure]: (state: JobState, action) =>
    handleFailure("createCrewAndOptJobAndSchedule", state, action),

  [jobActions.addCrewJobToSchedule.request]: (state: JobState) =>
    handleRequest("addCrewJobToSchedule", state),
  [jobActions.addCrewJobToSchedule.success]: (state: JobState, action) =>
    handleSuccess("addCrewJobToSchedule", state, action),
  [jobActions.addCrewJobToSchedule.failure]: (state: JobState, action) =>
    handleFailure("addCrewJobToSchedule", state, action),

  [jobActions.saveDirectInviteUserIds]: (state: JobState, action: any): JobState => ({
    ...state,
    directInviteUserIds: action.payload.userIds,
  }),

  [jobActions.clearDirectInviteUserIds]: (state: JobState): JobState => ({
    ...state,
    directInviteUserIds: [],
  }),

  [jobActions.saveCrewForHireIds]: (state: JobState, action: any): JobState => ({
    ...state,
    crewForHireIds: action.payload.crewIds,
  }),

  [jobActions.clearCrewForHireIds]: (state: JobState): JobState => ({
    ...state,
    crewForHireIds: [],
  }),

  [jobActions.beginAnyJobPost]: (state: JobState): JobState => ({
    // When beginning any job, reset the fetching/success/failure values
    // of all job-posting states.
    ...state,
    addWorkRequestToSch: { ...initialEndpointState },
    createJob: { ...initialEndpointState },
    createDirectInviteJobAndSchedule: { ...initialEndpointState },
    createDirectInviteAndOptJobAndSchedule: { ...initialEndpointState },
    createCrewJobAndSchedule: { ...initialEndpointState },
    createCrewAndOptJobAndSchedule: { ...initialEndpointState },
    addCrewJobToSchedule: { ...initialEndpointState },
    createPosition: { ...initialEndpointState },
  }),
  [jobActions.updateBulkSingleShiftResponses]: (state: JobState, action: any): JobState => {
    if (action.payload.successfullyCreatedShift) {
      return {
        ...state,
        successfulBulkSingleShifts: [...state.successfulBulkSingleShifts, action.payload],
      };
    }
    return {
      ...state,
      failedBulkSingleShifts: [...state.failedBulkSingleShifts, action.payload],
    };
  },
  [jobActions.updateBulkSingleShiftResponsesRequired]: (
    state: JobState,
    action: any,
  ): JobState => ({
    ...state,
    numberOfBulkSingleShiftsToCreate: action.payload,
    failedBulkSingleShifts: [] as PartialRequestErrorModalData[],
    successfulBulkSingleShifts: [] as PartialRequestErrorModalData[],
  }),
  [jobActions.setBulkSingleShiftsToCreate]: (state: JobState, action: any) => ({
    ...state,
    numberOfBulkSingleShiftsToCreate: action.payload.data,
  }),
};

export default handleActions<JobState>(handlerMap, initialState);
