import styled from "styled-components";
import { theme } from "styled-tools";
import { BaseStyleCss, BaseStyleProps } from "./Box";
import { bool } from "./styledProps";

const directions = ["row", "column", "rowReverse", "columnReverse"];
const wraps = ["nowrap", "wrap", "wrapReverse"];

interface FlexProps extends BaseStyleProps {
  row?: boolean;
  column?: boolean;
  rowReverse?: boolean;
  columnReverse?: boolean;
  nowrap?: boolean;
  wrap?: boolean;
  wrapReverse?: boolean;
}

const Flex = styled.div<FlexProps>`
  ${BaseStyleCss};
  display: flex;
  &&& {
    ${bool("flex-direction", directions)};
    ${bool("flex-wrap", wraps)};
  }

  ${theme("Flex")};
`;

const InlineFlex = styled(Flex)`
  display: inline-flex;
  ${theme("InlineFlex")};
`;

export { Flex, InlineFlex };
