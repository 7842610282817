import styled from "styled-components";
import { LabelMedium } from "baseui/typography";

export const ListItemContainer = styled.div`
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListItem = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 4px 4px 4px 8px;
  font-size: 5px;
`;

export const CenterAlignedFlexText = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
`;
export const Text = styled.p`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;
  color: black;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 500px;
`;

export const SubText = styled.p`
  font-family: Inter;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.granite};
  width: 500px;
`;

export const InputWrapper = styled.div`
  max-width: 160px;
  position: relative;
`;

export const DistanceUnitButton = styled(LabelMedium)`
  display: flex;
  padding-left: 10px;
  font-weight: 300;
  line-height: 20px;
  gap: 10px;
`;
