/**
 * NOTE: Imports the Default Reakit Theme
 * Visible either in node_modules/reakit-theme-default/src/index.ts, or by
 * following this link (which may be newer than the version installed here)
 *
 * https://github.com/reakit/reakit/blob/master/packages/reakit-theme-default/src/index.ts
 */
import { css } from "styled-components";
import defaultTheme from "./reakit-theme-default";

import { DEVICE_CLASSES } from "../constants/responsive";

const bcBlue = "#5492AF";
const bcOrange = "#EFA15D";
const bcGray = "#58595B";

export const theme = {
  ...defaultTheme,

  name: "Legacy (Material)",

  palette: {
    ...defaultTheme.palette,

    grayscale: ["#1E1F1F", "#3F4041", bcGray, "#A2A4A7", "#E0E2E7"],
    grayscaleText: ["white", "white", "black", "black", "black"],

    primary: ["#274350", "#3E6B80", bcBlue, "#92B9CC", "#C0D7E1"],
    primaryText: ["white", "white", "black", "black", "black"],

    secondary: ["#904604", "#ce6f1a", bcOrange, "#F4C397", "#F9DCC4"],
    secondaryText: ["white", "white", "black", "black", "black"],

    danger: ["#5B232D", "#923949", "#C84D63", "#DC8D9B", "#EBBEC6"],
    dangerText: ["white", "white", "black", "black", "black"],

    alert: ["#b38d06", "#dcae0d", "#EACA57", "#F1DD94", "#F7EBC1"],
    alertText: ["black", "black", "black", "black", "black"],

    success: ["#405A2A", "#669143", "#8CC65B", "#B5DA96", "#D5EAC3"],
    successText: ["white", "white", "white", "black", "black"],
  },

  sizes: { ...DEVICE_CLASSES },

  /**
   * COMPONENTS
   */
  Avatar: [...defaultTheme.Avatar],
  Blockquote: [...defaultTheme.Blockquote],
  Button: [
    ...defaultTheme.Button, // @ts-ignore $FlowFixStyledComponents
    ...css`
      cursor: default;
      border-radius: 0;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.75);
    `,
  ],
  Code: [...defaultTheme.Code],
  Field: [...defaultTheme.Field],
  GroupItem: [...defaultTheme.GroupItem],
  Heading: [...defaultTheme.Heading],
  Image: [...defaultTheme.Image],
  Input: [...defaultTheme.Input],
  Link: [...defaultTheme.Link],
  List: [...defaultTheme.List],
  Overlay: [...defaultTheme.Overlay],
  Paragraph: [...defaultTheme.Paragraph],
  Popover: [...defaultTheme.Popover],
  PopoverArrow: [...defaultTheme.PopoverArrow],
  Sidebar: [...defaultTheme.Sidebar],
  Table: [...defaultTheme.Table],
  Tabs: [...defaultTheme.Tabs],
  TabsTab: [...defaultTheme.TabsTab],
};
