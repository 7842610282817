import { palette as p } from "styled-tools";
import styled from "styled-components";
import { Box, Heading, Paragraph, Overlay, Button } from "@bluecrew/web-react-core";
import { assetUrl } from "../../api/bluecrew/constants/index";

export const StyledOverlay = styled(Overlay)`
  background-color: #fff;
  padding: 0;
`;

export const ModalBody = styled(Box)`
  padding: 2rem 2rem 1rem 2rem;

  ${Heading} {
    font-size: 1.5rem;
    color: ${p("slate")};
    line-height: 3rem;
    padding-left: 4rem;
    font-weight: 500;
  }

  &.success {
    ${Heading} {
      background: left center no-repeat url(${assetUrl}/check-mark.png);
    }
  }

  &.failure {
    ${Heading} {
      background: left center no-repeat url(${assetUrl}/error.png);
    }
  }

  // TODO: remove when moving all confirmation modals to FAILURE type
  &.error {
    ${Heading} {
      background: left center no-repeat url(${assetUrl}/error.png);
    }
  }

  ${Paragraph} {
    margin: 1rem 0;
    font-size: 1rem;
    color: ${p("platinum")};
  }
`;

export const ModalFooter = styled(Box)`
  text-align: right;
  padding: 1rem;
  border-top: solid 1px #e8e9e9;

  ${Button} {
    margin-left: 0.75rem;
  }
`;
