import React from "react";
import PropTypes from "prop-types";
import ConfirmModal from "./ConfirmModal";
import messages from "../../shared/messages";

const ArchiveScheduleModal = (props) => (
  <ConfirmModal
    handleConfirmationClick={props.archiveSchedule}
    show={props.showModal}
    toggleModal={props.toggle}
    headingText={messages.ARCHIVE_SCHEDULE.confirm.heading}
    paragraphText={messages.ARCHIVE_SCHEDULE.confirm.body(props.scheduleName)}
    confirmationButtonText="Archive Schedule"
  />
);

ArchiveScheduleModal.propTypes = {
  archiveSchedule: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  scheduleName: PropTypes.string.isRequired,
};

export default ArchiveScheduleModal;
