import moment from "moment";
import { ListedPosition, Schedule } from "../../../api/bluecrew/types";
import { mapPositions } from "../GetCrewForm";

export const findPositionByScheduleId = (scheduleId: number, positions: Array<ListedPosition>) =>
  mapPositions(positions).find((position) => {
    if (position.schedules.length) {
      return position.schedules.find(({ id }) => id === scheduleId);
    }
    return undefined;
  });

export const getPreselectedSchedule = (
  scheduleData: Schedule | undefined,
  scheduleId: string | undefined,
) => {
  if (!scheduleData || !scheduleId) return undefined;

  const scheduleInfo = {
    ...scheduleData,
    ...(scheduleData.supervisor
      ? {
          supervisor: {
            id: scheduleData.supervisor.user_id,
            name: scheduleData.supervisor.full_name,
            ...(scheduleData.supervisor.avatar_url && {
              imgUrl: scheduleData.supervisor.avatar_url,
            }),
          },
        }
      : null),
  };

  // const infoPromise = ;
  const startTime = moment.tz(scheduleInfo.start_date_time, scheduleInfo.timezone);
  const endTime = moment.tz(scheduleInfo.end_date_time, scheduleInfo.timezone);
  const preSelectedSchedule = {
    id: +scheduleId,
    name: scheduleInfo.work_request_name,
    workingHours: {
      formatted: `${startTime.format("hh:mma")} - ${endTime.format("hh:mma z")}`,
      startTime: startTime.format("HH:mm"),
      endTime: endTime.format("HH:mm"),
    },
    workingDays: scheduleInfo.shifts.map((shift) => shift.day_of_week),
    scheduleType: scheduleInfo.schedule_type,
  };

  return preSelectedSchedule;
};
