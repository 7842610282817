import React, { createRef, useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { BlueprintTheme, Button, getMargin, Modal } from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { LabelText } from "../LabelText";
import { ModalBodyWrapper } from "../ModalBodyWrapper";
import { ModalFooterWrapper } from "../ModalFooterWrapper";
import { NotificationManagerMapper } from "./NotificationManagerMapper";
import { NotificationManagerListItem } from "./NotificationManagerListItem";
import {
  NotificationManager,
  NotificationManagerListItem as NotificationManagerListItemType,
  NotificationManagersFieldProps,
} from "../types/propTypes/NotificationManagerField.types";
import { useSelectionList } from "../useSelectionList";
import { SelectionList } from "../../../components/SelectionList";
import { UserListItem } from "../../../components/SelectionList/UserListItem";

export const NotificationField = ({
  control,
  notificationManagers = [],
  notificationManagersSelected = [],
  openModal,
  isModalOpen,
  reset,
  currentFormValues,
}: NotificationManagersFieldProps) => {
  const fieldName = "notificationManager";
  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
  });
  const [notificationManagersWithSelection, setNotificationManagersWithSelection] =
    useState(notificationManagers);

  useEffect(() => {
    if (notificationManagers?.length) {
      const addDefaultSelection = notificationManagers.map((notificationManager) => ({
        ...notificationManager,
        selected: notificationManagersSelected?.length
          ? notificationManagersSelected.filter(
              (selectedNotificationManager: NotificationManager) =>
                selectedNotificationManager.id === notificationManager.id,
            ).length > 0
          : false,
      }));
      setNotificationManagersWithSelection(addDefaultSelection);
    }
    // Render only once on the init render
  }, []);

  const getListItems = () =>
    notificationManagersWithSelection.map((notificationManager: NotificationManager) =>
      NotificationManagerMapper.toListItem(notificationManager),
    );

  const { list, toggleSelection, selected, setList } =
    useSelectionList<NotificationManagerListItemType>(getListItems(), {
      multiSelect: true,
      searchBy: "id",
    });

  useEffect(() => {
    const newList = getListItems();
    setList(newList);
  }, [notificationManagersWithSelection]);

  const setNotificationManagerValue = (notificationManager: NotificationManagerListItemType) => {
    toggleSelection(notificationManager);
  };

  const done = () => {
    const managers = selected.map((el: NotificationManagerListItemType) =>
      NotificationManagerMapper.toModel(el),
    );
    const values = currentFormValues();
    reset({ ...values, notificationManager: managers });
    onChange(managers);
    openModal(false);
  };

  const modalRootRef = createRef<HTMLDivElement>();

  return (
    <>
      {value?.length && (
        <StyledListWrapper>
          <LabelText>
            Notifications
            <EditText onClick={() => openModal(true)}>edit</EditText>
          </LabelText>
          <StyledList>
            {value.map((el: any) => (
              <NotificationManagerListItem key={el.id} item={el} />
            ))}
          </StyledList>
        </StyledListWrapper>
      )}
      <Modal
        header="Notification Managers"
        body={
          <ModalBodyWrapper ref={modalRootRef}>
            <SelectionList<NotificationManagerListItemType>
              rootRef={modalRootRef}
              items={list}
              listItemRenderer={UserListItem}
              onItemClick={(item) => setNotificationManagerValue(item)}
              bottomDivider
            />
          </ModalBodyWrapper>
        }
        isOpen={isModalOpen}
        onClose={() => {
          openModal(false);
        }}
        footer={
          <ModalFooterWrapper>
            <Button width="100%" onClick={() => done()}>
              Done
            </Button>
          </ModalFooterWrapper>
        }
      />
    </>
  );
};

const StyledListWrapper = styled("div", ({ $theme }) => ({
  marginTop: $theme.sizing.scale800,
}));

const StyledList = styled<{}, "div", BlueprintTheme>("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.crystal,
  borderRadius: $theme.sizing.scale200,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingTop: $theme.sizing.scale600,
  paddingLeft: $theme.sizing.scale600,
  marginTop: $theme.sizing.scale300,
}));

const EditText = styled("span", ({ $theme }) => ({
  ...$theme.typography.font150,
  color: $theme.colors.accent700,
  cursor: "pointer",
  ...getMargin("0"),
  marginLeft: $theme.sizing.scale100,
}));
