import { styled } from 'baseui';
import { FlexDirection } from 'baseui/block';
import { ProgressBar as BaseProgressBar } from 'baseui/progress-bar';
import { ParagraphSmall } from 'baseui/typography';
import React, { ReactNode } from 'react';

import { useThemedStyletron } from '../theme';
import { getBorderRadius, getMargin } from '../helpers/styleHelpers';

export enum ProgressBarLabelPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export type ProgressBarProps = {
  currentFillValue?: number;
  totalFillValue?: number;
  label?: ReactNode;
  labelPosition?: ProgressBarLabelPosition;
  labelSpacing?: string;
  width?: string;
  isActive?: boolean;
};

type ProgressBarWrapperProps = {
  $flexDirection: FlexDirection;
  $alignItems: string;
  $justifyContent: string;
};

export const ProgressBar = ({
  currentFillValue = 0,
  totalFillValue = 100,
  label = null,
  isActive = true,
  labelPosition = ProgressBarLabelPosition.RIGHT,
  labelSpacing = '8px',
  width = '100%',
}: ProgressBarProps) => {
  const { flexDirection, alignItems, justifyContent, labelMargin } =
    getContainerStyle(labelPosition, labelSpacing);

  const [, theme] = useThemedStyletron();

  const hasLabel = !!label;

  return (
    <ProgressBarWrapper
      $flexDirection={flexDirection as FlexDirection}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
    >
      <BaseProgressBar
        value={currentFillValue}
        successValue={totalFillValue}
        overrides={{
          BarProgress: {
            style: ({ $theme }) => ({
              backgroundColor: isActive
                ? $theme.colors.positive
                : $theme.colors.accent,
              minWidth: theme.sizing.scale300,
              minHeight: theme.sizing.scale300,
              ...getBorderRadius($theme.sizing.scale100),
            }),
          },
          Bar: {
            style: ({ $theme }) => ({
              height: $theme.sizing.scale300,
              backgroundColor: $theme.colors.mono400,
              ...getBorderRadius($theme.sizing.scale100),
            }),
          },
          BarContainer: {
            style: () => ({
              width,
              ...getMargin('0'),
              marginRight: hasLabel ? theme.sizing.scale300 : 0,
            }),
          },
        }}
      />
      {hasLabel && (
        <ParagraphSmall
          overrides={{
            Block: {
              style: () => ({
                ...getMargin('0'),
                ...labelMargin,
                whiteSpace: 'nowrap',
              }),
            },
          }}
        >
          {label}
        </ParagraphSmall>
      )}
    </ProgressBarWrapper>
  );
};

const getContainerStyle = (
  labelPosition: ProgressBarLabelPosition,
  labelSpacing: string,
) => {
  switch (labelPosition) {
    case ProgressBarLabelPosition.TOP:
      return {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifyContent: 'center',
        labelMargin: {
          marginBottom: labelSpacing,
        },
      };
    case ProgressBarLabelPosition.BOTTOM:
      return {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        labelMargin: {
          marginTop: labelSpacing,
        },
      };
    case ProgressBarLabelPosition.LEFT:
      return {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        justifyContent: 'flex-start',
        labelMargin: {
          marginRight: labelSpacing,
        },
      };
    case ProgressBarLabelPosition.RIGHT:
    default:
      return {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        labelMargin: {
          marginLeft: labelSpacing,
        },
      };
  }
};

const ProgressBarWrapper = styled<ProgressBarWrapperProps, 'div'>(
  'div',
  ({ $flexDirection, $alignItems, $justifyContent }) => ({
    display: 'flex',
    flexDirection: $flexDirection,
    alignItems: $alignItems,
    justifyContent: $justifyContent,
  }),
);
