// TODO: move CSS specific to schedule details fields to ScheduleDetailsFields.js
import styled from "styled-components";
import { Form } from "formik";
import { CONTAINER_MAX_WIDTH } from "../../../shared/constants";
import { Field } from "@bluecrew/reakit";
import { palette as p } from "styled-tools";
import { assetUrl } from "../../api/bluecrew/constants";

export const StyledForm = styled(Form)`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  padding: 2rem;

  ${Field} {
    padding: 0.75rem 0rem;
  }

  h2 {
    margin-top: 0;
    color: ${p("slate")};
    font-size: 1.75rem;
    font-weight: 500;
  }

  .menu {
    &.time-menu {
      width: 16.6875rem;
    }

    .item {
      width: 100%;
      cursor: pointer;
      height: 3rem;
      line-height: 3rem;
      padding: 0 0.5rem;

      &:hover {
        background-color: ${p("frost")};
      }
    }
  }

  // Error Message CSS
  .error-message {
    color: red;
    font-size: 0.75rem;
  }

  // Remove spinning counter on number input for workers-needed
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .button-left {
    margin: 0.5vw;
  }

  .weekDays-selector {
    width: 25rem;
  }

  //WORKERS NEEDED COUNTER

  .workers-needed {
    padding: 0rem;
    width: 3rem;
    height: 3.375rem;
    border-radius: 0.25rem;
    text-align: center;
  }

  .round-button {
    width: 3.375rem;
    height: 3.375rem;
    border-radius: 1.6875rem;
    border: solid 0.0625rem #e1e2e4;
    background-color: ${p("white")};
    color: #646a75;
    font-size: 1.25rem;
    font-weight: 600;
  }

  //END OF COUNTER CSS

  h2 {
    margin-bottom: 1.875rem;
  }

  //TIME SELECTOR
  .time-selector {
    display: flex;
    justify-content: space-between;
  }

  .start-time,
  .end-time {
    & input {
      margin-bottom: 0.5em;
      background-image: url(${assetUrl}/icons/clock-input-bg.svg);
      background-repeat: no-repeat;
      background-position: left 1rem center;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 48px;
  padding-top: 4rem;

  button:first-child {
    margin-right: 1em;
  }
`;
