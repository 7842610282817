import React from 'react';
import { SvgPropType } from './SvgPropType';

export const PlusIcon = ({
  width = 16,
  height = 16,
  color = 'none',
}: SvgPropType) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.333328 8.00004C0.333328 9.51636 0.78297 10.9986 1.62539 12.2594C2.46782 13.5202 3.66519 14.5028 5.06609 15.0831C6.46699 15.6634 8.0085 15.8152 9.49569 15.5194C10.9829 15.2236 12.3489 14.4934 13.4211 13.4212C14.4933 12.349 15.2235 10.9829 15.5193 9.49573C15.8152 8.00855 15.6633 6.46703 15.0831 5.06613C14.5028 3.66523 13.5201 2.46786 12.2594 1.62544C10.9986 0.783016 9.51632 0.333374 8 0.333374C5.96737 0.335668 4.01866 1.14414 2.58138 2.58142C1.14409 4.0187 0.335622 5.96742 0.333328 8.00004ZM3.66666 7.66671C3.66666 7.4899 3.7369 7.32033 3.86192 7.1953C3.98695 7.07028 4.15652 7.00004 4.33333 7.00004H6.83333C6.87753 7.00004 6.91992 6.98248 6.95118 6.95123C6.98244 6.91997 7 6.87758 7 6.83337V4.33337C7 4.15656 7.07023 3.98699 7.19526 3.86197C7.32028 3.73695 7.48985 3.66671 7.66666 3.66671H8.33333C8.51014 3.66671 8.67971 3.73695 8.80473 3.86197C8.92976 3.98699 9 4.15656 9 4.33337V6.83337C9 6.87758 9.01755 6.91997 9.04881 6.95123C9.08007 6.98248 9.12246 7.00004 9.16666 7.00004H11.6667C11.8435 7.00004 12.013 7.07028 12.1381 7.1953C12.2631 7.32033 12.3333 7.4899 12.3333 7.66671V8.33337C12.3333 8.51019 12.2631 8.67975 12.1381 8.80478C12.013 8.9298 11.8435 9.00004 11.6667 9.00004H9.16666C9.12246 9.00004 9.08007 9.0176 9.04881 9.04886C9.01755 9.08011 9 9.12251 9 9.16671V11.6667C9 11.8435 8.92976 12.0131 8.80473 12.1381C8.67971 12.2631 8.51014 12.3334 8.33333 12.3334H7.66666C7.48985 12.3334 7.32028 12.2631 7.19526 12.1381C7.07023 12.0131 7 11.8435 7 11.6667V9.16671C7 9.12251 6.98244 9.08011 6.95118 9.04886C6.91992 9.0176 6.87753 9.00004 6.83333 9.00004H4.33333C4.15652 9.00004 3.98695 8.9298 3.86192 8.80478C3.7369 8.67975 3.66666 8.51019 3.66666 8.33337V7.66671Z"
      fill={color}
    />
  </svg>
);
