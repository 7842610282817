import React from "react";
import { useThemedStyletron } from "@bluecrew/blueprint-web";
import { useWatch } from "react-hook-form";
import { styled } from "baseui";
import { RequirementsSectionProps } from "../../types/propTypes/RequirementsSection.types";
import { CustomRequirementsField } from "./CustomRequirementsField";
import { Spacer, SpacerType } from "../../../../components/Spacer";
import { CertificationsField } from "./CertificationsField/CertificationsField";
import { RequirementsField } from "./RequirementsField";
import { Divider } from "../../../../components/Divider";

export const RequirementsSection = ({
  control,
  trigger,
  requirements,
  certifications,
  disabled = false,
  hideTagsIfDisabled = false,
  skillsetId,
  isEditPosition = false,
}: RequirementsSectionProps) => {
  const [, theme] = useThemedStyletron();

  // At this point in time certifications are not required for form validity
  const { customRequirements: watchCustomRequirements } = useWatch({
    name: ["customRequirements"],
    control,
  });

  const customRequirementsVisible = !disabled || watchCustomRequirements.length > 0;

  const getInvalidCustomReqs = () =>
    ([] as string[])
      .concat(requirements?.map((r) => r.label) || [])
      .concat(certifications?.map((c) => c.label) || []);

  return (
    <div>
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
      <StyledText>Crew Members will confirm each of your requirements.</StyledText>
      {/* requirements list being empty can cause rendering issues. Thus if empty, do not render */}
      {requirements && requirements?.length > 0 && (
        <RequirementsField
          control={control}
          trigger={trigger}
          requirements={requirements}
          disabled={disabled}
          hideTagsIfDisabled={hideTagsIfDisabled}
        />
      )}
      {certifications && (
        <CertificationsField
          control={control}
          certifications={certifications}
          disabled={disabled}
          skillsetId={skillsetId}
          isEditPosition={isEditPosition}
        />
      )}
      {customRequirementsVisible && (
        <CustomRequirementsField
          disabled={disabled}
          control={control}
          trigger={trigger}
          invalidCustomReqs={getInvalidCustomReqs()}
        />
      )}
      <Divider $marginTop={theme.sizing.scale1000} $marginBottom={theme.sizing.scale1000} />
    </div>
  );
};

const StyledText = styled("p", ({ $theme }) => ({
  ...$theme.typography.font300,
  color: $theme.colors.primary700,
  paddingBottom: $theme.sizing.scale100,
  margin: 0,
  fontWeight: "bold",
}));
