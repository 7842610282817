import React from 'react';
import { Input, InputOverrides } from 'baseui/input';
import { MagnifyingGlassIcon } from '../assets';
import {
  getPadding,
  getBorderRadius,
  getBorderWidth,
} from '../helpers/styleHelpers';

interface SearchBarProps {
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchBar = ({ placeholder, value, onChange }: SearchBarProps) => (
  <Input
    value={value}
    onChange={(e) => onChange && onChange(e.currentTarget.value)}
    placeholder={placeholder}
    startEnhancer={<MagnifyingGlassIcon />}
    overrides={overrides}
  />
);

const overrides: InputOverrides = {
  Root: {
    style: ({ $theme }) => ({
      ...getBorderRadius($theme.sizing.scale950),
      ...getBorderWidth('0'),
      height: $theme.sizing.scale1000,
      backgroundColor: $theme.colors.primary100,
    }),
  },
  InputContainer: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.primary100,
    }),
  },
  Input: {
    style: ({ $theme }) => ({
      '::placeholder': {
        color: $theme.colors.inputPlaceholder,
        opacity: 1,
      },
      paddingLeft: $theme.sizing.scale400,
    }),
  },
  StartEnhancer: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.primary100,
      ...getPadding('0'),
    }),
  },
};
