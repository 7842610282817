import React from 'react';

export const GetCrewMembersPositionIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75003 6.75V3.9C6.74649 3.29375 7.10379 2.74345 7.65903 2.5L11.409 0.873C11.786 0.708959 12.2141 0.708959 12.591 0.873L16.341 2.5C16.8963 2.74345 17.2536 3.29375 17.25 3.9V6.75"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3.375C11.7929 3.375 11.625 3.54289 11.625 3.75C11.625 3.95711 11.7929 4.125 12 4.125C12.2071 4.125 12.375 3.95711 12.375 3.75C12.375 3.54289 12.2071 3.375 12 3.375V3.375"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 6.75H18.75"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6352 23.25C20.8955 18.5014 16.8061 14.9999 12.0002 15V15C7.19435 14.9999 3.10502 18.5014 2.36523 23.25"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 15.75L12 21.75L15.75 15.75"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.438 9C16.0744 11.1677 14.198 12.7556 12 12.7556C9.80201 12.7556 7.92562 11.1677 7.56201 9"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.79823 15.949L6.11523 21L9.86523 23.25"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4772 16.086L18.1152 21L14.3652 23.25"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
