import React, { useRef } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { ActionColumnButtonWrapper } from "../styledComponents";
import {
  getPayrollActionKeyPrefix,
  useUndoPayrollActionMutation,
} from "../../../../../api/bluecrew/hooks/payroll";
import { ActionButton, ActionButtonIcon } from "./styledComponents";
import { Toast } from "primereact/toast";
import { ActionButtonIconPathPrefix } from "./index";
import { buildShiftKey } from "../../../shiftUtils";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";
import { Tooltip } from "../../../../../components/Tooltip";

export type ButtonUndoProps = {
  rowData: TimesheetsShiftInfo;
  disabled: boolean;
};

export const ButtonUndo = ({ rowData, disabled }: ButtonUndoProps) => {
  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast?.current?.show({ severity: "error", summary: "Error", detail: message });
  };

  const shiftKey = buildShiftKey(rowData);
  const tooltipAnchor = `undo-${shiftKey.cmId}-${shiftKey.jobId}-${shiftKey.shiftIndex}`;
  const { mutate } = useUndoPayrollActionMutation(shiftKey, showErrorToast);
  const isAnyPayrollActionMutating = !!useIsMutating(getPayrollActionKeyPrefix(shiftKey));

  return (
    <ActionColumnButtonWrapper>
      <Toast ref={toast} />
      <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
        {"Undo"}
      </Tooltip>
      <ActionButton
        className={tooltipAnchor}
        disabled={disabled || isAnyPayrollActionMutating}
        icon={<ActionButtonIcon src={`${ActionButtonIconPathPrefix}/undo.svg`} />}
        rounded
        outlined
        onClick={() => mutate(rowData.userHoursId!)} // safe if used in tabs that ensure hours entry
      />
    </ActionColumnButtonWrapper>
  );
};
