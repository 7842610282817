import React from "react";
import {format} from "date-fns";
import {TimestampAttributeName} from "../../../shiftUtils";
import {AttributeView} from "./AttributeView";
import {ShiftComponent, TimesheetsShiftInfo} from "../../../types/Shift.types";
import {PayrollHoursStatus} from "../../../types/Payroll.types";

const IconAttributes = {
  [TimestampAttributeName.GEOFENCE_WARNING]: "geofence-warn.svg",
  [TimestampAttributeName.GEOFENCE_ERROR]: "geofence-error.svg",
  [TimestampAttributeName.TIME_ERROR]: "time-error.svg",
  [TimestampAttributeName.CM_EDITED_SUBMISSION]: "cm-edit.svg",
  [TimestampAttributeName.CM_MISSING_SUBMISSION]: "time-missing.svg",
};

const tooltipMessages = {
  [TimestampAttributeName.TIME_ERROR]: {
    [ShiftComponent.CLOCK_IN]: "This timestamp is outside the defined early clock-in buffer",
    [ShiftComponent.CLOCK_OUT]: "This timestamp is outside the defined late clock-out buffer",
    default: "The default break duration has not been met",
  },
  [TimestampAttributeName.GEOFENCE_WARNING]:
    "This timestamp is outside the the established warn radius",
  [TimestampAttributeName.GEOFENCE_ERROR]:
    "This timestamp is outside the the established block radius",
  [TimestampAttributeName.CM_EDITED_SUBMISSION]: {
    [ShiftComponent.BREAK_DURATION]: "This break duration was edited by the crew member",
    default: "This timestamp was edited by the crew member",
  },
  [TimestampAttributeName.CM_MISSING_SUBMISSION]: "This timestamp has not been submitted",
};

const getTooltipMessage = (attr: TimestampAttributeName, timeType: ShiftComponent) => {
  const message = tooltipMessages[attr];

  if (typeof message === "object") {
    return message[timeType] || message.default;
  }

  return message || "";
};

const getTimesheetIcon = (
  attribute: TimestampAttributeName, // Is ensured to be a part of IconAttributes because of the filter below
) => `${import.meta.env.VITE_ASSET_BASE_URL}/icons/Timesheets/${IconAttributes[attribute]}`;

const getDefaultModalMessage = (
  attrName: TimestampAttributeName,
  cmName: string,
  formattedDate: string,
  companyName: string,
  userShift: any,
) => {
  const defaultModalMessages = {
    // for now only 2 cases have a default message.
    [TimestampAttributeName.GEOFENCE_ERROR]: `Hello ${cmName}, one or more of the timeclock punches for your shift on ${formattedDate} at ${companyName} was offsite and cannot be approved until we hear back from you. What is the correct time that you started/finished work for the day? Please also let us know why you clocked out offsite.`,
    [TimestampAttributeName.CM_MISSING_SUBMISSION]: `Hello ${cmName}, one or more of the timeclock punches for your shift on ${formattedDate} at ${companyName} is missing and your shift cannot be approved until we hear back from you. What time did you start and finish working? Please also let us know why you did not use the app to submit all timestamps for the day.`,
  };
  const multipleReasonsModalMessages = {
    [TimestampAttributeName.GEOFENCE_ERROR]: `- one or more of the timeclock punches was offsite`,
    [TimestampAttributeName.CM_MISSING_SUBMISSION]: `- one or more of the timeclock punches is missing`,
    [TimestampAttributeName.TIME_ERROR]: `- one or more of the timeclock punches is outside of the defined early or late clock-in/out buffer`,
  };

  const allAttributes: string[] = [userShift.start.attributes, userShift.break.attributes, userShift.end.attributes]
    .reduce((sum, attributes)=> sum.concat(attributes || []), []);

  const uniqueAttributes: string[] = [... new Set(allAttributes)];

  if (uniqueAttributes.length <= 1) {
    return defaultModalMessages[attrName as keyof typeof defaultModalMessages] || "";
  }

  const StartMessage = `Hello ${cmName}, there are multiple issues with your timeclock punches for the shift on ${formattedDate} at ${companyName}:`;
  const EndMessage = `Your shift cannot be approved until we hear back from you.`;

  const msgBody: string = uniqueAttributes.reduce((sum, attribute) => {
    const attrMsg = multipleReasonsModalMessages[attribute as keyof typeof multipleReasonsModalMessages] || "";
    if (attrMsg.length > 0) {
      return `${sum}${attrMsg}\n`;
    }
    return sum;
  }, "");

  return `${StartMessage}\n\n${msgBody}\n${EndMessage}`;  
};

export type AttributesDisplayProps = {
  attributes: Array<TimestampAttributeName>;
  timeType: ShiftComponent;
  shiftInfo: TimesheetsShiftInfo;
  tooltipAnchor: string;
  showMessaging?: boolean;
};
export const AttributesDisplay = ({
  attributes,
  timeType,
  shiftInfo,
  tooltipAnchor,
  showMessaging,
}: AttributesDisplayProps) => {
  const { userShift, company, user, job } = shiftInfo;
  const isUnapprovedHours = userShift.status !== PayrollHoursStatus.APPROVED;
  const isNotDisputed = userShift.status !== PayrollHoursStatus.DISPUTED;

  const formattedShiftDate = format(job.start, "MMM do, yyyy");
  const cmName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      {isUnapprovedHours && isNotDisputed &&
        attributes
          .filter((attrName) => attrName in IconAttributes)
          .map((attrName) => (
            <AttributeView
              key={`${tooltipAnchor}-${attrName}`}
              attributeName={attrName}
              attributeIconPath={getTimesheetIcon(attrName)}
              tooltipMessage={getTooltipMessage(attrName, timeType)}
              buttonText="Message Crew Member"
              tooltipAnchor={`${tooltipAnchor}-${attrName}`}
              userInfo={user}
              defaultTooltipModalMessage={getDefaultModalMessage(
                attrName,
                cmName,
                formattedShiftDate,
                company.name,
                userShift,
              )}
              showMessaging={showMessaging}
              shiftInfo={shiftInfo}
            />
          ))}
    </>
  );
};
