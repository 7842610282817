// AUTH - REDUCER
// =============================================================================

import { handleActions } from "redux-actions";
import companyActions from "../actions/company";

// ACTION TYPES
import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

const handlerMap = {
  [companyActions.getCompanyDetails.request]: (state: CompanyState) =>
    handleRequest("getCompanyDetails", state),
  [companyActions.getCompanyDetails.success]: (state: CompanyState, action) =>
    handleSuccess("getCompanyDetails", state, action),
  [companyActions.getCompanyDetails.failure]: (state: CompanyState, action) =>
    handleFailure("getCompanyDetails", state, action),

  [companyActions.switchCompany.request]: (state: CompanyState) =>
    handleRequest("switchCompany", state),
  [companyActions.switchCompany.success]: (state: CompanyState, action) =>
    handleSuccess("switchCompany", state, action),
  [companyActions.switchCompany.failure]: (state: CompanyState, action) =>
    handleFailure("switchCompany", state, action),

  [companyActions.userCompanies.request]: (state: CompanyState) =>
    handleRequest("userCompanies", state),
  [companyActions.userCompanies.success]: (state: CompanyState, action) =>
    handleSuccess("userCompanies", state, action),
  [companyActions.userCompanies.failure]: (state: CompanyState, action) =>
    handleFailure("userCompanies", state, action),
};

const initialState = {
  getCompanyDetails: { ...initialEndpointState },
  switchCompany: { ...initialEndpointState },
  userCompanies: { ...initialEndpointState },
};

export type CompanyState = typeof initialState;

export default handleActions<CompanyState>(handlerMap, initialState);
