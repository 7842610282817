import React from "react";
import {
  ShiftDisplayWrapper,
  TimeDisplayWrapper,
  TimestampColumnWrapper,
} from "../styledComponents";
import { TimeDisplay } from "./TimeDisplay";
import { BreakDisplay } from "./BreakDisplay";
import { ShiftComponent, TimesheetsShiftInfo } from "../../../types/Shift.types";
import { PayrollHoursStatus } from "../../../types/Payroll.types";
import { ApprovalTextDisplay } from "./ApprovalTextDisplay";
import { TimeArrow } from "../arrow";

export type TimestampsColumnCellProps = {
  shiftInfo: TimesheetsShiftInfo;
  spaced?: boolean;
  showMessaging?: boolean;
};
export const TimestampColumnCell = ({
  shiftInfo,
  spaced,
  showMessaging,
}: TimestampsColumnCellProps) => {
  const { job, user, userShift } = shiftInfo;
  const shiftKey = `${user.externalId}-${job.externalId}-${job.shiftIndex}`;

  return (
    <TimestampColumnWrapper>
      <ShiftDisplayWrapper>
        <TimeDisplayWrapper spaced={spaced}>
          <TimeDisplay
            timestamp={userShift.start}
            shiftInfo={shiftInfo}
            tsType={ShiftComponent.CLOCK_IN}
            shiftKey={shiftKey}
            showMessaging={showMessaging}
          />
        </TimeDisplayWrapper>
        <TimeArrow />
        <TimeDisplayWrapper spaced={spaced}>
          <BreakDisplay
            shiftBreak={userShift.break}
            shiftInfo={shiftInfo}
            shiftKey={shiftKey}
            showMessaging={showMessaging}
          />
        </TimeDisplayWrapper>
        <TimeArrow />
        <TimeDisplayWrapper spaced={spaced}>
          <TimeDisplay
            timestamp={userShift.end}
            shiftInfo={shiftInfo}
            tsType={ShiftComponent.CLOCK_OUT}
            shiftKey={shiftKey}
            showMessaging={showMessaging}
          />
        </TimeDisplayWrapper>
      </ShiftDisplayWrapper>
      {userShift.status === PayrollHoursStatus.APPROVED && (
        <ApprovalTextDisplay
          isAutoApproved={userShift.isAutoApproved}
          lastUpdatedBy={userShift.lastUpdateBy!!}
          statusReason={userShift.statusReason}
        />
      )}
    </TimestampColumnWrapper>
  );
};
