import { Image } from "@bluecrew/web-react-core";
import React from "react";
import { DashboardScheduleRowData } from "../../../api/bluecrew/types";
import { TooltipMessage } from "../../../components/TooltipMessage";
import { AbilityContext } from "../../PermissionsContext";
import { useNavigateToApplicantReview } from "../navigationHooks";
import { RowContainer } from "../SharedComponents";

interface AlertIconCellProps {
  rowData: DashboardScheduleRowData;
}

export const AlertIconCell = ({ rowData }: AlertIconCellProps) => {
  const navigateToApplicantReview = useNavigateToApplicantReview();
  const ability = React.useContext(AbilityContext);
  const canUpdateJob = ability.can("update", "job");

  return rowData.applications_to_review && rowData.applications_to_review > 0 ? (
    <RowContainer
      data-pendo-key="ScheduleRequestTableColumnApplicationReviewIndicator"
      data-testid="schedule-request-table-alert-icon-cell"
    >
      <TooltipMessage message={`Review your applicants ${rowData.applications_to_review}`}>
        <Image
          className="bell-icon"
          src={`${import.meta.env.VITE_ASSET_BASE_URL}/icons/DashboardAlertIcon.svg`}
          alt="candidate review"
          onClick={() => {
            canUpdateJob && navigateToApplicantReview(rowData.schedule_id);
          }}
        />
      </TooltipMessage>
    </RowContainer>
  ) : null;
};
