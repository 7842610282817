import * as React from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { StepContainerSelectors } from "./StepContainer";
import { Hidden, HiddenProps } from "./Hidden";

interface TabsPanelProps extends HiddenProps {
  isCurrent: StepContainerSelectors["isCurrent"];
  tab: string;
  role?: string;
}

const TabsPanelComponent = (props: TabsPanelProps) => {
  const { isCurrent, tab } = props;
  return (
    <Hidden
      id={`${tab}Panel`}
      aria-labelledby={`${tab}Tab`}
      unmount
      visible={isCurrent && isCurrent(tab)}
      {...props}
    />
  );
};

const StyledTabsPanel = styled(TabsPanelComponent)`
  ${theme("TabsPanel")};
`;

const TabsPanel = (props: TabsPanelProps) => {
  const { children, ...attrs } = props;
  return <StyledTabsPanel {...attrs}>{children}</StyledTabsPanel>;
};

TabsPanel.defaultProps = {
  role: "tabpanel",
};

export default TabsPanel;
