import { JobType, JobTypeResponse } from "../types";

export const createJobType = (
  jobTypesResponse: JobTypeResponse,
  isCertificateFilteringDisabled: boolean = false,
): JobTypeResponse => {
  if (!isCertificateFilteringDisabled) {
    const ALCOHOL_SERVER_CERTIFICATE_ID = 61;
    const RESPONSIBLE_BEVERAGE_SERVER_CERTIFICATION_CALIFORNIA_ONLY_ID = 60;
    const FORKLIFT_CERTIFICATE_ID = 62;
    const FOOD_HANDLER_CARD_ID = 63;
    const CLEAN_MOTOR_VEHICLE_LICENSE_ID = 64;
    const filterIds = [
      ALCOHOL_SERVER_CERTIFICATE_ID,
      RESPONSIBLE_BEVERAGE_SERVER_CERTIFICATION_CALIFORNIA_ONLY_ID,
      FORKLIFT_CERTIFICATE_ID,
      FOOD_HANDLER_CARD_ID,
      CLEAN_MOTOR_VEHICLE_LICENSE_ID,
    ];

    const jobTypes: JobType[] = [];

    jobTypesResponse.jobtypes.forEach((jobType) => {
      const separatedJobType: JobType = jobType;

      separatedJobType.users_requirements = jobType.users_requirements.filter(
        (requirement) => !filterIds.includes(requirement.id),
      );
      separatedJobType.users_certs = jobType.users_certs.filter(
        (cert) => !filterIds.includes(cert.id),
      );
      jobTypes.push(separatedJobType);
    });
    return {
      jobtypes: jobTypes,
    };
  }
  return jobTypesResponse;
};
