import React from "react";

const DashboardPositionCertificationBadgeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_144_16650)">
      <path
        d="M18 5.25L12.53 10.72C12.4604 10.7897 12.3778 10.8449 12.2869 10.8826C12.1959 10.9203 12.0984 10.9398 12 10.9398C11.9016 10.9398 11.8041 10.9203 11.7131 10.8826C11.6222 10.8449 11.5396 10.7897 11.47 10.72L9.75 9"
        stroke="#3246F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.717 13.152L0.75 18L4.5 18.75L6 23.25L9.99 16.918"
        stroke="#3246F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.283 13.152L23.25 18L19.5 18.75L18 23.25L14.01 16.918"
        stroke="#3246F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4999 9.537L20.3439 11.753C20.4052 11.9129 20.4098 12.0889 20.357 12.2517C20.3042 12.4146 20.1973 12.5544 20.0539 12.648L18.0679 13.948L17.4479 16.238C17.4032 16.4032 17.3033 16.5481 17.1649 16.6486C17.0265 16.7492 16.8578 16.7995 16.6869 16.791L14.3179 16.673L12.4709 18.161C12.3374 18.2683 12.1712 18.3268 11.9999 18.3268C11.8286 18.3268 11.6625 18.2683 11.5289 18.161L9.68195 16.67L7.31295 16.788C7.14205 16.7965 6.97339 16.7462 6.83497 16.6456C6.69655 16.5451 6.59668 16.4002 6.55195 16.235L5.93195 13.945L3.94595 12.645C3.80261 12.5514 3.69566 12.4116 3.64289 12.2487C3.59012 12.0859 3.59473 11.9099 3.65594 11.75L4.49995 9.537L3.65594 7.32C3.59363 7.15949 3.58844 6.98243 3.64125 6.81854C3.69405 6.65466 3.80164 6.51393 3.94595 6.42L5.93195 5.12L6.55195 2.83C6.59668 2.66484 6.69655 2.51995 6.83497 2.41936C6.97339 2.31877 7.14205 2.26854 7.31295 2.277L9.68195 2.4L11.5289 0.916001C11.6625 0.808712 11.8286 0.750229 11.9999 0.750229C12.1712 0.750229 12.3374 0.808712 12.4709 0.916001L14.3179 2.4"
        stroke="#3246F0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_144_16650">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DashboardPositionCertificationBadgeIcon;
