import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { StateShape } from "../../redux/reducers";
import { ManageUsersTable } from "./ManageUsersTable/ManageUsersTable";
import {
  useGetCompaniesInfoQuery,
  useGetCompanyUsersQuery,
} from "../../api/bluecrew/hooks/company";
import {
  HeaderContainer,
  MainContainer,
  ManageUsersContainer,
  PageContainer,
  SideBarContainer,
  PageTitle,
} from "./ManageUsersTable/styledComponents";
import { RoleFilterSideBar } from "./Sidebar/RoleFilterSideBar";
import { CompanyFilterSideBar } from "./Sidebar/CompanyFilterSideBar";
import { AssignCompaniesModal } from "./AssignCompaniesModal/AssignCompaniesModal";
import { BulkAssignCompaniesModal } from "./AssignCompaniesModal/BulkAssignCompaniesModal";
import { InternalUser } from "./ManageUsersTable/InternalUser";
import { AbilityContext } from "../PermissionsContext";
import { AddNewUserModal } from "./AddUserModal/AddNewUserModal";
import { getUserCompany } from "../../redux/selectors/auth";
import { UserCompanyInfo } from "../../api/bluecrew/types";
import { SuccessFailureModal } from "../../components/SuccessFailureModal/SuccessFailureModal";
import { dropdownOptions } from "./constants";
import { UserSearchInput } from "./Header/UserSearchInput";
import { HeaderButtons } from "./Header/HeaderButtons";
import { setSelectedCompanies } from "./slices/CompanyFilterSlice";
import { setTableLoading } from "./slices/manageUsersModalSlice";
import { useAppSelector } from "../../redux";
import { useResendInviteManageUsersSplitIsOn } from "../../../shared/hooks/split";

export const ManageUsers = () => {
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch();
  // Current company the client-user is signed in to.
  const activeCompanyId = useAppSelector((state) => getUserCompany(state));
  const selectedCompanies = useAppSelector(
    (state) => state.manageUsersCompanyFilter.selectedCompanies,
  );
  const companiesInfoQuery = useGetCompaniesInfoQuery();
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [companies, setCompanies] = useState<UserCompanyInfo[]>([]);
  const [selectedCompaniesNumber, setSelectedCompaniesNumber] = useState<number[]>([
    activeCompanyId,
  ]);

  const [firstRender, setFirstRender] = useState<boolean>(true);
  const tableLoading = useAppSelector((state: StateShape) => state.manageUsersModal.tableLoading);
  const [longQueryComplete, setLongQueryComplete] = useState<boolean>(true);
  const longQueryTimer = useRef<number | undefined>(undefined);

  const [successModalVisible, setSuccessModalVisible] = useState<{
    isSuccess: boolean;
    isVisible: boolean;
  }>({ isSuccess: true, isVisible: false });
  const [successModalData, setSuccessModalData] = useState<{ title: string; text: string }>({
    text: "",
    title: "",
  });
  const [tableData, setTableData] = useState<InternalUser[]>([]);

  // Resend invite in manage users is actually always "turned on":
  // Whether or not the features are visible depend on if the users have certian fields present in their objects
  // If we pass a given flag to the api call that pulls this data that data will / won't be present
  const includeSignUpInfo = useResendInviteManageUsersSplitIsOn();

  const internalUsersQuery = useGetCompanyUsersQuery(
    selectedCompaniesNumber.length > 0 ? selectedCompaniesNumber : [activeCompanyId],
    includeSignUpInfo,
  );

  const navigate = useNavigate();
  const canViewManageUsers = ability.can("view", "manage_users");

  const handleSuccessModal = useCallback(
    (isSuccess: boolean, title: string, text: string) => {
      dispatch(setTableLoading(false));
      // wait to allow the table to update before showing the modal
      setTimeout(() => {
        setSuccessModalData({ title, text });
        setSuccessModalVisible({ isVisible: true, isSuccess });
      }, 500);
    },
    [setSuccessModalData, setSuccessModalVisible],
  );

  if (!canViewManageUsers) {
    navigate("/");
  }

  useEffect(() => {
    setSelectedCompaniesNumber(selectedCompanies.map((company) => company.companyId));
  }, [selectedCompanies]);

  useEffect(() => {
    if (internalUsersQuery.isSuccess && companiesInfoQuery.isSuccess) {
      const roles = Array.from(new Set(internalUsersQuery.data.map((item) => item.userOrder))).map(
        (role) => dropdownOptions.find((roleOption) => roleOption.order === role)!.label,
      );
      // sort the roles by the order they appear in the dropdown
      roles.sort((a, b) => {
        const roleA = dropdownOptions.find((roleOption) => roleOption.label === a)!.order;
        const roleB = dropdownOptions.find((roleOption) => roleOption.label === b)!.order;
        return roleA - roleB;
      });
      setAvailableRoles(roles);

      let userData = cloneDeep(internalUsersQuery.data);
      userData = userData.map((user) => {
        const newUser = user;
        // companyId to name mappings are not known until the companiesInfo query is complete
        // this loop will add the company names to each InternalUser object
        for (let i = 0, { length } = newUser.companiesInfo; i < length; i += 1) {
          if (companies.length === 0) {
            break;
          }
          const company = companies.find(
            (companyWithName) => companyWithName.companyId === newUser.companiesInfo[i].companyId,
          );
          newUser.companiesInfo[i].companyName = company?.companyName ? company.companyName : "";
        }
        return newUser;
      });

      setTableData(userData as InternalUser[]);

      setFirstRender(false);
      dispatch(setTableLoading(false));
    }
  }, [
    internalUsersQuery.isSuccess && companiesInfoQuery.isSuccess && internalUsersQuery.data,
    companiesInfoQuery.data,
  ]);

  useEffect(() => {
    if (companiesInfoQuery.isSuccess) {
      const rawCompaniesInfo = companiesInfoQuery.data.companiesInfo;
      if (rawCompaniesInfo) {
        const companyInfos = rawCompaniesInfo
          .map((companiesInfo) => ({
            companyId: companiesInfo.companyId,
            companyName: companiesInfo.companyName,
          }))
          .sort((a, b) => a.companyName.localeCompare(b.companyName));
        // On initial load, set the selected companies to the company the user is signed in to
        if (selectedCompanies.length === 0) {
          dispatch(
            setSelectedCompanies(
              companyInfos.filter((company) => company.companyId === activeCompanyId),
            ),
          );
        }
        setCompanies(companyInfos);
      } else {
        setCompanies([]);
      }
    }
  }, [companiesInfoQuery.isSuccess]);

  useEffect(() => {
    // if there is a fetch longer than 500ms, remove the table and show a spinner
    if (internalUsersQuery.isFetching) {
      longQueryTimer.current = window.setTimeout(() => setLongQueryComplete(false), 500);
    } else if (!longQueryComplete) {
      window.clearTimeout(longQueryTimer.current);
      longQueryTimer.current = undefined;
      setLongQueryComplete(true);
    } else {
      window.clearTimeout(longQueryTimer.current);
      longQueryTimer.current = undefined;
    }
  }, [internalUsersQuery.isFetching]);

  return (
    <ManageUsersContainer id="manage-users-container">
      {firstRender && <SpinnerWithOverlay />}
      {!firstRender && (
        <PageContainer>
          <HeaderContainer>
            <PageTitle>Manage Users</PageTitle>
            <UserSearchInput />
            <HeaderButtons />
          </HeaderContainer>
          <MainContainer>
            <SideBarContainer>
              <CompanyFilterSideBar companies={companies} />
              <RoleFilterSideBar availableRoles={availableRoles} />
            </SideBarContainer>
            {tableLoading && <SpinnerWithOverlay />}
            <ManageUsersTable data={tableData} longQueryComplete={longQueryComplete} />
            <AssignCompaniesModal companies={companies} handleSuccessModal={handleSuccessModal} />
            <BulkAssignCompaniesModal
              allCompanies={companies}
              handleSuccessModal={handleSuccessModal}
            />
            <AddNewUserModal handleSuccessModal={handleSuccessModal} />
            <SuccessFailureModal
              title={successModalData.title}
              text={successModalData.text}
              visible={successModalVisible.isVisible}
              onButtonClick={() =>
                setSuccessModalVisible({
                  isVisible: false,
                  isSuccess: successModalVisible.isSuccess,
                })
              }
              isSuccess={successModalVisible.isSuccess}
            />
          </MainContainer>
        </PageContainer>
      )}
      {internalUsersQuery.isError && (
        // Handle error case, you can render an error message or take appropriate action
        <div>Error loading user data</div>
      )}
    </ManageUsersContainer>
  );
};
