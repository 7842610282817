import React from "react";
import { Button } from "@bluecrew/web-react-core";
import { useNavigate } from "react-router-dom";
import { webV1Url } from "../../api/bluecrew/constants/index";
import { Can } from "../../containers/PermissionsContext";
import { canPostCompanyJobs } from "../../redux/selectors/segment";
import { SharedCopy, SHOW_TIMESHEETS_SPLIT_NAME } from "../../../shared/constants";
import { StyledNavBar } from "./styledComponents";
import { NavLink } from "./NavLink";
import { TimesheetsNavItem } from "./TimesheetsNavItem";
import { getTreatmentValue } from "../../redux/selectors/splitio";
import { useAppSelector } from "../../redux";

export const NavMenu = () => {
  const navigate = useNavigate();
  const canPostJobs = useAppSelector(canPostCompanyJobs);
  const showTimesheets = useAppSelector((state) =>
    getTreatmentValue(state, SHOW_TIMESHEETS_SPLIT_NAME),
  );

  return (
    <StyledNavBar>
      <ul>
        <li>
          <NavLink to="/positions" match={["/"]}>
            Dashboard
          </NavLink>
        </li>
        <li>
          <a href={`${webV1Url}/app.html#!/roster`}>Roster</a>
        </li>
        <li>
          <a href={`${webV1Url}/app.html#!/workforce`}>Workforce</a>
        </li>
        <Can I="create" a="job">
          <li>
            <a href={`${webV1Url}/app.html#!/analytics`}>Reports</a>
          </li>
          <li>
            <a href={`${webV1Url}/app.html#!/crews`}>Groups</a>
          </li>
          <Can I="view" a="timesheets">
            {showTimesheets === "on" && <TimesheetsNavItem />}
          </Can>
          <Button
            data-pendo-key={`PostJobButton`}
            data-testid="NavMenu-post-job"
            onClick={() => navigate("/jobs/post")}
            palette="primary"
            disabled={!canPostJobs}
            style={{ width: 99, height: 40 }}
          >
            <span>{SharedCopy.post_a_job}</span>
          </Button>
        </Can>
      </ul>
    </StyledNavBar>
  );
};
