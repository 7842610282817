import styled, { css } from "styled-components";
import { theme } from "styled-tools";
import { bool, bgColorWithProps, textColorWithProps } from "./styledProps";

const positions = ["static", "absolute", "fixed", "relative", "sticky"];

const BaseStyleCss = css`
  margin: unset;
  padding: unset;
  border: unset;
  background: unset;
  font: unset;
  font-family: inherit;
  font-size: 100%;
  box-sizing: border-box;
  background-color: ${bgColorWithProps};
  color: ${textColorWithProps};
`;

interface BaseStyleProps {
  tabIndex?: number;
  "aria-modal"?: boolean;
  role?: string;
  static?: boolean;
  absolute?: boolean;
  fixed?: boolean;
  relative?: boolean;
  sticky?: boolean;
  opaque?: boolean;
  palette?: string;
  tone?: number;
  className?: string;
}

interface BoxProps extends BaseStyleProps {
  position?: boolean;
}

const Box = styled.div<BoxProps>`
  ${BaseStyleCss};
  ${theme("Box")};
  &&& {
    ${bool("position", positions)};
  }
`;

Box.defaultProps = {
  palette: "grayscale",
};

export { BaseStyleCss, BaseStyleProps, Box };
