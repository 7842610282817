// Taken from legacy-db.bluecrew.job_types table
export enum JobType {
  UNKNOWN = 1,
  COMMERCIAL_MOVING,
  WAREHOUSE_WORK,
  OFFICE_WORK,
  CUSTOMER_SUPPORT,
  FORKLIFT,
  DELIVERY_AND_PICKUP,
  TRUCK_DRIVER,
  EVENT_STAFF,
  JANITORIAL_WORK,
  FOOD_SERVICES,
  IT_WORK,
  DRIVERS,
  BARTENDING,
  PRODUCTION_FOOD,
  PRODUCTION_NON_FOOD,
  HOUSEKEEPER,
  RETAIL,
  EVENT_SERVER,
  CONCESSION_OR_STAND_WORKER,
  COOK,
  DISHWASHER,
  SERVER,
}

export const OFFSITE_JOBS = [JobType.DELIVERY_AND_PICKUP, JobType.TRUCK_DRIVER, JobType.DRIVERS];

export const isOffSiteJob = (skillId: number) => OFFSITE_JOBS.includes(skillId);
