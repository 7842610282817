import React from "react";
import { Button } from "../../../../components/Button";

export const RefreshButton = () => (
  <Button
    label="Refresh"
    className="p-button-outlined refresh-button"
    onClick={() => {
      window.location.reload();
    }}
  />
);
