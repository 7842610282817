import React from "react";

export const loginStrings = {
  login: {
    enterEmailLabel: "Email",
    emailRequired: "Email is required",
    passwordRequired: "Password is required",
    enterPasswordLabel: "Password",
    submitLabel: "Login",
    submittingLabel: "Logging in...",
  },
  backToLogin: "Back to Login",
  requestNewCode: "Request new code",
  codeResent: "Code resent!",
  confirmLogin: {
    codeSentBlurb: (email: String) => (
      <>
        <div>{`A verification code was sent to ${email}`}</div>
        <div>{"Please enter it below to log in:"}</div>
      </>
    ),
    enterCode: "Enter your verification code",
    mfaRequired: "Verification code is required",
  },
  resetPassword: {
    enterEmailBlurb:
      "Please enter your account email address below and we will send a verification code that will allow you to reset your password.",
    enterEmailLabel: "Email",
    submitLabel: "Request password reset",
    submittingLabel: "Requesting password reset...",
  },
  confirmResetPassword: {
    enterCodeBlurb:
      "A verification code has been sent to your email. Enter it here to reset your password",
    enterCodeLabel: "Enter your verification code",
    confirmationCodeRequired: "Verification code is required",
    enterNewPasswordLabel: "New Password",
    confirmPasswordLabel: "Confirm Password",
    confirmPasswordRequired: "Confirm Password is required",
    changePasswordLabel: "Change password",
  },
  resetPasswordSuccess: {
    title: "Password Reset Successfull",
    blurb: "You may now login with your new password.",
    goToLogin: "Go to Login",
  },
  errors: {
    invalidLoginDetails: "Incorrect username or password",
    userNotFound: "User not found",
    expiredMFACode: "Verification Code has expired",
    expiredConfirmationCode: "Verification code has expired",
    invalidMFACode: "Invalid verification code",
    invalidConfirmationCode: "Incorrect verification code",
    passwordCannotBeReset: "Your password cannot be reset. Please contact support.",
    incorrectActivationCode: "Incorrect username or activation code",
    activationCodeExpired: "Activation code has expired. Please contact support for a new one.",
    notAuthorizedDefault: "User is not authorized to peform requested action",
    limitExceededDefault: "Attempt limit exceeded. Please try again later.",
    unknownErrorDefault: "An unknown error occured",
  },
  flowNavigation: {
    forgotPasswordLabel: "Forgot Password?",
    activateAccountLabel: "Activate Account",
  },
  activateAccount: {
    blurb:
      "Enter your email address and the activation code provided to you in your welcome email from bluecrew.",
    activationCodeFieldLabel: "Account Activation Code",
    submitButtonText: "Activate Account",
    submittingButtonText: "Activating Account...",
  },
  completeAccountActivation: {
    blurb: "Choose a password to complete account activation.",
    passwordLabel: "Password",
    confirmPasswordLabel: "Confirm Password",
    submitButtonText: "Complete Activation",
    submittingButtonText: " Completing Activation...",
  },
} as const;
