import React, { createRef, useEffect } from "react";
import { useController } from "react-hook-form";
import { getListItems } from "./utilities";
import {
  CertificationsFieldProps,
  Certifications,
  Certification,
} from "../../../types/propTypes/CertificationsField.type";
import { StyledCertList } from "./styledComponents";
import { EditVersionListItem, SelectionList } from "../../../../../components/SelectionList";
import { useSelectionList } from "../../../useSelectionList";

export const CertificationsField = ({
  control,
  certifications = [],
  disabled,
}: CertificationsFieldProps) => {
  const rootElementRef = createRef<HTMLDivElement>();
  const handleCertChange = (newCerts: Certification[]) => {
    // empty array always resets form
    setList(getListItems(false, [], newCerts));
    newCerts.forEach((cert: Certification) => {
      if (cert.selected) {
        updateSelected(cert);
      }
    });
  };

  const updatedCerts: Certifications = [...certifications];

  const isLocked = (): boolean => {
    let locked = false;
    if (updatedCerts) {
      const unlocked = updatedCerts.find((cert) => !cert.is_locked);
      if (!unlocked) {
        locked = true;
      }
    }
    return locked || Boolean(disabled);
  };

  const {
    field: { onChange, value: unsafeValue },
  } = useController({
    name: "certifications",
    defaultValue: [],
    control,
    rules: {}, // no rules, just right
  });

  const certsArray: Certifications = Array.isArray(unsafeValue)
    ? (unsafeValue as Certifications)
    : [];

  const {
    list: certCheckboxList,
    toggleSelection,
    setList,
    selected,
  } = useSelectionList(getListItems(false, certsArray, certifications || updatedCerts), {
    searchBy: "id",
    multiSelect: true,
  });

  const firstClick = (item: Certification) => [item];

  const updateSelected = (item: Certification) => {
    // someone broke what is passed to the update Selected a long time ago it looks like
    // @ts-ignore
    if (!item.appearance.is_locked) {
      const foundIndex = selected.findIndex(
        (selectedCertification: Certification) => selectedCertification.id === item.id,
      );
      if (foundIndex >= 0) {
        selected.splice(foundIndex, 1);
      } else {
        // eslint-disable-next-line no-param-reassign
        item.selected = !item.selected;
        selected.push(item);
      }
    }
    onChange(selected);
  };

  const handleClick = (item: Certification) => {
    if (item.is_locked) return;
    toggleSelection(item);
    if (!selected.length) {
      onChange(firstClick(item));
    } else {
      updateSelected(item);
    }
  };

  // Refresh selection list when skillset has changed.
  useEffect(() => {
    if (updatedCerts) {
      handleCertChange(updatedCerts);
    }
  }, [certifications]);

  if (certCheckboxList?.length) {
    return (
      <>
        <StyledCertList className={isLocked() ? "is-disabled" : ""}>
          <div className="cert-list-title">Certifications</div>
          <div className="cert-list-message">
            Select all certifications which apply to this position. Unless Crew Members indicate
            they have those certifications, they will not be assigned to the job. This may affect
            your fill rate.
          </div>
          <SelectionList<Certification>
            items={certCheckboxList || []}
            rootRef={rootElementRef}
            onItemClick={handleClick}
            topDivider={false}
            bottomDivider={false}
            listItemRenderer={EditVersionListItem}
          />
        </StyledCertList>
      </>
    );
  }
  return <></>;
};
