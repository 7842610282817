import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobChecklistIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip-checklist-icon)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.75 17.25H17.625C17.5757 17.2502 17.5268 17.2406 17.4813 17.2219C17.4357 17.2031 17.3943 17.1755 17.3594 17.1406C17.3245 17.1057 17.2969 17.0643 17.2781 17.0187C17.2594 16.9732 17.2498 16.9243 17.25 16.875V15.375C17.25 15.2755 17.2895 15.1802 17.3598 15.1098C17.4302 15.0395 17.5255 15 17.625 15H24C24.7956 15 25.5587 14.6839 26.1213 14.1213C26.6839 13.5587 27 12.7956 27 12V3C27 2.20435 26.6839 1.44129 26.1213 0.87868C25.5587 0.316071 24.7956 0 24 0H7.5C6.70435 0 5.94129 0.316071 5.37868 0.87868C4.81607 1.44129 4.5 2.20435 4.5 3V12C4.5 12.7956 4.81607 13.5587 5.37868 14.1213C5.94129 14.6839 6.70435 15 7.5 15H13.875C13.9745 15 14.0698 15.0395 14.1402 15.1098C14.2105 15.1802 14.25 15.2755 14.25 15.375V16.875C14.2502 16.9243 14.2406 16.9732 14.2219 17.0187C14.2031 17.0643 14.1755 17.1057 14.1406 17.1406C14.1057 17.1755 14.0643 17.2031 14.0187 17.2219C13.9732 17.2406 13.9243 17.2502 13.875 17.25H2.25C1.65326 17.25 1.08097 17.4871 0.65901 17.909C0.237053 18.331 0 18.9033 0 19.5V34.5C0 34.8978 0.158035 35.2794 0.43934 35.5607C0.720644 35.842 1.10218 36 1.5 36C1.89782 36 2.27936 35.842 2.56066 35.5607C2.84196 35.2794 3 34.8978 3 34.5V23.25C3 23.0511 3.07902 22.8603 3.21967 22.7197C3.36032 22.579 3.55109 22.5 3.75 22.5H35.25C35.4489 22.5 35.6397 22.421 35.7803 22.2803C35.921 22.1397 36 21.9489 36 21.75V19.5C36 18.9033 35.7629 18.331 35.341 17.909C34.919 17.4871 34.3467 17.25 33.75 17.25ZM7.5 3H24V12H7.5V3ZM35.25 31.5H24.75C24.5511 31.5 24.3603 31.579 24.2197 31.7197C24.079 31.8603 24 32.0511 24 32.25V33.75C24 34.3467 24.2371 34.919 24.659 35.341C25.081 35.7629 25.6533 36 26.25 36H33.75C34.3467 36 34.919 35.7629 35.341 35.341C35.7629 34.919 36 34.3467 36 33.75V32.25C36 32.0511 35.921 31.8603 35.7803 31.7197C35.6397 31.579 35.4489 31.5 35.25 31.5ZM24.75 24.7485H35.25C35.6642 24.7485 36 25.0843 36 25.4985V28.4985C36 28.9127 35.6642 29.2485 35.25 29.2485H24.75C24.3358 29.2485 24 28.9127 24 28.4985V25.4985C24 25.0843 24.3358 24.7485 24.75 24.7485Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip-checklist-icon">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
