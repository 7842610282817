import React, { useState } from "react";
import {
  DropdownButton,
  FilterCheckBoxLabelStyle,
  SideBarLabel,
  SideBarSectionContainer,
  SideBarHeaderContainer,
  RoleFilterRow,
} from "./styledComponents";
import { StyledCheckbox, } from "../ManageUsersTable/styledComponents";
import { dropdownOptions } from "../constants";

type RoleFilterProps = {
  availableRoles: string[];
  selectedRoles: string[];
  onChange: (role: string | "all") => void;
};

export function getFilterValues(selectedRoles: string[]) {
  const convertedRoles = dropdownOptions.filter((role) => !selectedRoles.includes(role.label));
  const filterRoles = convertedRoles.map((role) => role.order);
  return filterRoles;
}

export const RoleFilter: React.FC<RoleFilterProps> = ({
  availableRoles,
  selectedRoles,
  onChange,
}) => {
  const isAllSelected = selectedRoles.length === availableRoles.length;
  const [isActive, setIsActive] = useState(true);
  const handleAllChange = (checked: boolean) => {
    if (checked) {
      onChange("all");
    } else {
      onChange("none");
    }
  };

  return (
    <SideBarSectionContainer>
      <SideBarHeaderContainer>
        <SideBarLabel>Roles</SideBarLabel>
        <DropdownButton
          onClick={() => setIsActive(!isActive)}
          style={{ transform: isActive ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      </SideBarHeaderContainer>
      {isActive && (
        <>
          <RoleFilterRow onClick={() => handleAllChange(!isAllSelected)} key="selectAll">
            <StyledCheckbox
              checked={isAllSelected}
            />
            <FilterCheckBoxLabelStyle style={{width: "100%", height: "100%"}}  htmlFor="selectAllCheckbox">All</FilterCheckBoxLabelStyle>
          </RoleFilterRow>
          {availableRoles.map((role) => (
            <RoleFilterRow key={role} onClick={() => onChange(role)}>
              <StyledCheckbox
                checked={selectedRoles.includes(role)}
              />
              <FilterCheckBoxLabelStyle style={{width: "100%", height: "100%"}} htmlFor={`roleCheckbox_${role}`}>
                {role}
              </FilterCheckBoxLabelStyle>
            </RoleFilterRow>
          ))}
        </>
      )}
    </SideBarSectionContainer>
  );
};
