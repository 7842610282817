import React from "react";
import GoogleMapLoader from "react-google-maps-loader";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { TimesheetsShiftInfo, UserShift } from "../../../types/Shift.types";
import {
  AuditTrailHeader,
  InfoColumn,
  ModalBody,
  ShiftDetailsHeader,
  ShiftDetailsSubHeader,
  StyledModal,
} from "./styledComponents";
import { ShiftDetailsMap } from "./ShiftDetailsMap";
import { UserTimestamp } from "../../../../../api/bluecrew/payroll";
import { UserInfo } from "./UserInfo";
import { TimestampColumnCell } from "../TimeColumnCell/TimestampColumnCell";
import { DateInfo } from "./DateInfo";
import { PositionInfo } from "./PositionInfo";
import { Divider } from "../../../../../components/Divider";
import { getAlternativeName } from "../utils";
import { useGetAuditTrail } from "../../../../../api/bluecrew/hooks/finance";
import { AuditTrailView } from "../AuditTrail";
import { AuditTrailWarning } from "../AuditTrail/AuditTrailWarning";
import { buildOriginalTimestamps } from "../AuditTrail/auditTrailUtils";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";

const latLngFromUserTimestamps = (userTimestamps: Array<UserTimestamp | undefined>) =>
  userTimestamps
    .filter((v): v is UserTimestamp => !!v?.latitude && !!v?.longitude)
    .map((timestamp) => ({
      lat: timestamp.latitude!!,
      lng: timestamp.longitude!!,
    }));

const getClockInTimestamps = (userShift: UserShift) =>
  latLngFromUserTimestamps([
    userShift.start.userTimestamp,
    ...userShift.break.breakTimestamps.map((breakEvent) => breakEvent.start),
  ]);

const getClockOutTimestamps = (userShift: UserShift) =>
  latLngFromUserTimestamps([
    userShift.end.userTimestamp,
    ...userShift.break.breakTimestamps.map((breakEvent) => breakEvent.end),
  ]);

export type ShiftDetailsModalProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  shiftInfo: TimesheetsShiftInfo;
};

export const ShiftDetailsModal = ({
  isModalOpen,
  onCloseModal,
  shiftInfo,
}: ShiftDetailsModalProps) => {
  const { company, job, user, userShift, shiftThresholds } = shiftInfo;
  const { warnRadius, blockRadius } = shiftThresholds;
  const { timezone } = job;
  const formattedTimezone = getAlternativeName(timezone);

  const auditTrailQuery = useGetAuditTrail(
    company.externalId,
    job.externalId,
    job.shiftIndex,
    user.externalId,
  );

  return (
    <StyledModal
      header={
        <div>
          <ShiftDetailsHeader>Timestamps</ShiftDetailsHeader>
          <ShiftDetailsSubHeader>
            The exact location and times of a Crew Member&apos;s timestamps
          </ShiftDetailsSubHeader>
        </div>
      }
      footer={<></>}
      visible={isModalOpen}
      onHide={onCloseModal}
    >
      <ModalBody>
        <GoogleMapLoader
          params={{
            key: GOOGLE_MAPS_API_KEY,
            libraries: "places,geocode",
          }}
          render={(googleMaps) =>
            googleMaps && (
              <ShiftDetailsMap
                warnRadius={warnRadius || 0}
                blockRadius={blockRadius || 0}
                workplaceLatLng={job.coordinates}
                clockInPunches={getClockInTimestamps(userShift) || []}
                clockOutPunches={getClockOutTimestamps(userShift) || []}
              />
            )
          }
        />
        <InfoColumn>
          <UserInfo
            firstName={user.firstName}
            lastName={user.lastName}
            internalId={user.internalId}
          />
          <PositionInfo
            positionName={job.name}
            positionAddress={job.address}
            timezone={formattedTimezone}
          />
          <Divider />
          <DateInfo date={shiftThresholds.expectedStart} />
          {auditTrailQuery.isLoading && <SpinnerWithOverlay />}
          {auditTrailQuery.isSuccess && (
            <>
              <AuditTrailHeader>Original Timestamps:</AuditTrailHeader>
              <TimestampColumnCell
                shiftInfo={buildOriginalTimestamps(shiftInfo, auditTrailQuery.data.original)}
              />
              <AuditTrailHeader>Edits made by:</AuditTrailHeader>
              <AuditTrailView edits={auditTrailQuery.data.edits} shiftInfo={shiftInfo} />
            </>
          )}
          {auditTrailQuery.isError && (
            <>
              <AuditTrailHeader>Submitted:</AuditTrailHeader>
              <TimestampColumnCell shiftInfo={shiftInfo} />
              <AuditTrailHeader>Edits made by:</AuditTrailHeader>
              <AuditTrailWarning messageContent={"Error loading timestamp edits for this shift"} />
            </>
          )}
        </InfoColumn>
      </ModalBody>
    </StyledModal>
  );
};
