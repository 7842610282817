import React from 'react';
import { styled } from 'baseui';
import { Spinner as BaseSpinner } from 'baseui/spinner';

import { useThemedStyletron } from '../theme';
import {
  centeredItem,
  getHorizontalPadding,
  getVerticalPadding,
} from '../helpers/styleHelpers';

type SpinnerContainerProps = {
  $paddingVertical?: string;
  $paddingHorizontal?: string;
};

type SpinnerProps = {
  size: string;
  paddingVertical?: string;
  paddingHorizontal?: string;
  darkTrack?: boolean;
  trackOpacity?: number;
  dataTestId?: string;
  spinnerTitle?: string;
};

const SpinnerContainer = styled<SpinnerContainerProps, 'div'>(
  'div',
  ({
    $theme,
    $paddingVertical = $theme.sizing.scale3200,
    $paddingHorizontal = '0px',
  }) => ({
    ...centeredItem,
    ...getVerticalPadding($paddingVertical),
    ...getHorizontalPadding($paddingHorizontal),
  }),
);

export const Spinner = ({
  size,
  paddingVertical,
  paddingHorizontal,
  darkTrack,
  trackOpacity = 1,
  spinnerTitle,
  dataTestId,
}: SpinnerProps) => {
  const [, theme] = useThemedStyletron();

  return (
    <SpinnerContainer
      data-testid={dataTestId}
      $paddingVertical={paddingVertical}
      $paddingHorizontal={paddingHorizontal}
    >
      <BaseSpinner
        title={spinnerTitle}
        size={size}
        color={theme.colors.accent}
        overrides={{
          TrackPath: {
            style: () => ({
              fill: darkTrack ? theme.colors.mono400 : theme.colors.mono300,
              opacity: trackOpacity,
            }),
          },
        }}
      />
    </SpinnerContainer>
  );
};
