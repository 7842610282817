import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { FlexRow } from "./FilterListFilters";
import { MdSearch } from "react-icons/md";

import { Input } from "@bluecrew/web-react-core";

// @ts-ignore $FlowFixStyledComponents
const InputContainer = styled(FlexRow)`
  border: ${p("aluminum")} 0.0625rem solid;
  :focus-within {
    border: solid 0.0938rem ${p("brandBlue")};
  }
`;

// @ts-ignore $FlowFixStyledComponents
const BorderlessInput = styled(Input)`
  border: none;
  margin: 0 0 0 0.0625rem; /* InputContainer border correction */
  padding: 0;
  color: ${p("secondaryText")};
  background: transparent;
  :focus {
    border: none;
    outline: none;
  }
  ::placeholder {
    color: ${p("aluminum")};
    opacity: 1; /* Firefox */
  }
`;

// @ts-ignore $FlowFixUnknownDefinition
export const FilterListInput = ({
  onChange = () => {},
  placeholder = "Search For...",
  value = "",
  classes = undefined, // null
}) => (
  <InputContainer className={classes}>
    <MdSearch />
    <BorderlessInput value={value} onChange={onChange} placeholder={placeholder} />
  </InputContainer>
);
