import React from 'react';
/* Libraries */
import { KIND, Tag as BaseUiTag, VARIANT } from 'baseui/tag';
/* Utils */
import { Block } from 'baseui/block';
import {
  getBorder,
  getBorderRadius,
  getHorizontalPadding,
} from '../helpers/styleHelpers';
import { BlueprintTheme } from '../theme';
/* Assets */
import { CheckedIcon, CircleOutlinedIcon } from '../assets';

interface TagProps {
  label: string;
  selected: boolean;
  onClick?: (value: boolean) => void;
}

export const Tag = (props: TagProps) => {
  const { label, selected, onClick } = props;

  return (
    <BaseUiTag
      kind={KIND.accent}
      variant={selected ? VARIANT.solid : VARIANT.outlined}
      closeable={false}
      // We are passing undefined to remove interactions if tag is not clickable
      onClick={onClick ? () => onClick(!selected) : undefined}
      overrides={{
        Root: {
          style: ({ $theme }) => rootStyles($theme, selected),
        },
        Text: {
          style: ({ $theme }) => textStyles($theme),
        },
      }}
    >
      <Block marginRight="scale400">
        {selected ? <CheckedIcon /> : <CircleOutlinedIcon />}
      </Block>
      {label}
    </BaseUiTag>
  );
};

const rootStyles = ($theme: BlueprintTheme, isSelected: boolean) => ({
  width: 'auto',
  height: $theme.sizing.scale1000,
  transitionDuration: '200ms',
  transitionProperty: 'background',
  transitionTimingFunction: $theme.animation.linearCurve,
  ':hover': {
    backgroundColor: 'none',
  },
  ...getBorderRadius($theme.sizing.scale950),
  ...(!isSelected && { ...getBorder($theme.borders.border100) }),
});

const textStyles = ($theme: BlueprintTheme) => ({
  maxWidth: 'auto',
  display: 'flex',
  ...getHorizontalPadding($theme.sizing.scale300),
});
