import React from "react";
import { DateInfoContainer } from "./styledComponents";
import { format } from "date-fns";

export type DateInfoProps = {
  date: Date;
};
export const DateInfo = ({ date }: DateInfoProps) => {
  return <DateInfoContainer>{format(date, "EEE, MMM dd")}</DateInfoContainer>;
};
