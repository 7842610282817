import React, { useState } from "react";
import { TableCollapseFormat, TimesheetTabs } from "../../types/Payroll.types";
import { TimesheetsTableStage } from "./TableStage";
import {
  CollapseDateWrapper,
  CollapseHeaderWrapper,
  CollapseHoursWrapper,
  CollapseButtonWrapper,
} from "./styledComponents";
import { calculateHours } from "../../payrollDataProcessors";

export type CollapseTabProps = {
  collapseData: TableCollapseFormat;
  activeIndex: TimesheetTabs;
};

export const CollapseTab = ({ collapseData, activeIndex }: CollapseTabProps) => {
  const [isActive, setIsActive] = useState(true);

  const totalHours = collapseData.rows.reduce(
    (accumulator, row) => (accumulator += calculateHours(row.userShift)),
    0,
  );

  return (
    <div>
      <CollapseHeaderWrapper>
        <CollapseDateWrapper>
          {collapseData.headerDate}
        </CollapseDateWrapper>
        <div
          style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "right" }}
        >
          <CollapseHoursWrapper>Total hours {totalHours.toFixed(2)}</CollapseHoursWrapper>
          <CollapseButtonWrapper>
            <i
              className={`pi pi-angle-${isActive ? "up" : "down"}`}
              onClick={() => setIsActive(!isActive)}
            />
          </CollapseButtonWrapper>
        </div>
      </CollapseHeaderWrapper>
      {isActive && <TimesheetsTableStage data={collapseData.rows} activeIndex={activeIndex} />}
    </div>
  );
};
