import React from "react";
import { InputText } from "../AssignCompaniesModal/styledComponents";

type CompanySearchbarProps = {
  onChange: (e) => void;
};

export const CompanySearchbar = ({ onChange }: CompanySearchbarProps) => {
  return (
    <span className="p-input">
      <InputText type="text" onChange={onChange} placeholder="Search companies" />
    </span>
  );
};
