import styled from "styled-components";
import { theme } from "styled-tools";
import { BaseStyleCss, BaseStyleProps } from "./Box";
import TabsContainer from "./TabsContainer";
import TabsPanel from "./TabsPanel";
import Tab from "./TabsTab";

const Tabs = styled.ul<BaseStyleProps>`
  ${BaseStyleCss}
  ${theme("Tabs")};
`;

export { Tab, Tabs, TabsContainer, TabsPanel };
