import { Modal, ModalKind } from "@bluecrew/blueprint-web";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  AssignCompaniesBodyContainter,
  AssignCompaniesModalFooter,
  CancelButton,
  CompaniesAssigned,
  CompaniesListingContainer,
  BulkNames,
  UpdateButton,
  HeaderContainer,
  AvatarColumn,
  NamesColumn,
  SectionDivider,
} from "./styledComponents";
import { UserAvatars } from "./OverlappingAvatar";
import { CompanySearchbar } from "../Sidebar/CompanySearchbar";
import { BulkCompaniesItem, AssignedUsersState } from "./BulkAssignCompaniesItem";
import { formatNames, mapBulkUsersAssignedCompanies } from "./AssignCompaniesHelper";
import { useUpdateUsersCompanies } from "../../../api/bluecrew/hooks/user";
import { UserCompanyInfo, CompanyId, UserId } from "../../../api/bluecrew/types";
import {
  setSelectedUsers,
  setShowBulkAssignCompaniesModal,
  setTableLoading,
} from "../slices/manageUsersModalSlice";
import { StateShape } from "../../../redux/reducers";
import {
  BULK_ASSIGN_COMPANIES_CANCEL_BUTTON,
  BULK_ASSIGN_COMPANIES_MODAL_BODY,
  BULK_ASSIGN_COMPANIES_UPDATE_BUTTON,
} from "../constants";
import { useAppSelector } from "../../../redux";

type BulkAssignCompaniesModalProps = {
  allCompanies: UserCompanyInfo[];
  handleSuccessModal: (isSuccess: boolean, title: string, text: string) => void;
};

export const BulkAssignCompaniesModal = ({
  allCompanies,
  handleSuccessModal,
}: BulkAssignCompaniesModalProps) => {
  // Used to preserve the mapping of companies that are partially assigned to the users that have them assigned.
  // e.g. { a: [1], b: [1, 2], c: [3] } means that company a is assigned to user 1 and company b is assigned to users 1 and 2 and company c is assigned to user 3.
  const [companiesToUsersMap, setCompaniesToUsersMap] = useState<Map<CompanyId, UserId[]>>(
    new Map<CompanyId, UserId[]>(),
  );
  const [allAssignedCompanies, setAllAssignedCompanies] = useState<UserCompanyInfo[]>([]);
  // Used to display the companies that are partially assigned to the selected users.
  const [partialAssignedDisplay, setPartialAssignedDisplay] = useState<UserCompanyInfo[]>([]);
  const [noneAssignedCompanies, setNoneAssignedCompanies] = useState<UserCompanyInfo[]>([]);
  const [allUsersHaveACompany, setAllUsersHaveACompany] = useState(true);
  const dispatch = useDispatch();
  const showBulkAssignCompaniesModal = useAppSelector(
    (state: StateShape) => state.manageUsersModal.showBulkAssignCompaniesModal,
  );
  const selectedUsers = useAppSelector((state) => state.manageUsersModal.selectedUsers);

  const [companiesFilter, setCompaniesFilter] = useState("");
  const { mutate: runUpdateUsersCompanies } = useUpdateUsersCompanies({
    onSuccess: (/* isUpdateSuccess */) => {
      handleSuccessModal(
        true,
        "User updated",
        "We have successfully updated the company permissions for the selected user.",
      );
      dispatch(setSelectedUsers([]));
    },
    onError: (/* updateError */) => {
      const errorTitle = "Could not update user(s)";
      const errorText =
        selectedUsers.length > 1
          ? "We experienced an issue while updating these users"
          : "We experienced an issue while updating this user";
      handleSuccessModal(false, errorTitle, errorText);
    },
  });

  const handleAssignedCompaniesChange = (company: UserCompanyInfo) => {
    // (ALL | PARTIAL) -> NONE
    const isAllAssigned =
      allAssignedCompanies.filter((c) => c.companyId === company.companyId).length > 0;
    if (isAllAssigned) {
      setAllAssignedCompanies(
        allAssignedCompanies.filter((c) => c.companyId !== company.companyId),
      );
      setNoneAssignedCompanies([...noneAssignedCompanies, company]);
    } else if (companiesToUsersMap.has(company.companyId)) {
      // PARTIAL -> NONE
      setCompaniesToUsersMap((prevState) => {
        const newState = new Map(prevState);
        newState.delete(company.companyId);
        return newState;
      });
    } else {
      // NONE -> ALL
      setAllAssignedCompanies([...allAssignedCompanies, company]);
      setNoneAssignedCompanies(
        noneAssignedCompanies.filter((c) => c.companyId !== company.companyId),
      );
    }
  };

  // The way that Manage Users is set up, it only deals with two company states:
  //   1. Assigned (isChecked === true), 2. Unassigned (isChecked === false).
  //
  // The Bulk Assign Companies Modal has to deal with three company states (AssignedUsersState):
  //   1. NONE: No users are assigned to this company
  //   2. PARTIAL: 1 or more users, but not every user, is assigned to this company
  //   3. ALL: All users are assigned to this company.
  //
  // There're a few things to note/keep track of:
  //   1. If a user had a company assignment, that not every user has, prior to opening the modal, it is marked as PARTIAL.
  //        It also needs to be preserved if the client-user doesn't change the assignment. Companies with partial assignments are
  //        tracked using a Map<number, number[]> where the key is the companyId and the value is a list of userIds.
  //   2. Companies that every user is assigned to are tracked using a 'Set<number>'. Since every 'InternalUser' in 'selectedUsers'
  //        is assigned to these companies, just keeping track of the company ID should be enough.
  //   3. If a company is not in either of these, they are unassigned AKA 'NONE'.
  //   4. onClick, ('ALL' | 'PARTIAL) -> NONE, NONE -> ALL. Requirement is to toggle between these
  //        two states but preserve PARTIAL if untouched.
  useEffect(() => {
    if (!showBulkAssignCompaniesModal) return;
    // Used to keep track of partial company assignments.
    const tempPartialAssigned = new Map<number, number[]>();
    const tempAllAssigned = new Set<number>();

    // Getting a count for how many times each companyId appears in the selectedUsers' companiesInfo.
    // Comparing the count vs. numUsers -> AssignedUserState.
    const numAllUsers = selectedUsers.length;
    selectedUsers.forEach((user) => {
      user.companyIds.forEach((companyId) => {
        if (tempPartialAssigned.has(companyId)) {
          tempPartialAssigned.get(companyId)!.push(user.userId);
        } else {
          tempPartialAssigned.set(companyId, [user.userId]);
        }

        const numCompanyUsers = tempPartialAssigned.get(companyId)!.length;

        // All users have this company. Remove company from 'partial' and add to 'all'.
        if (numCompanyUsers === numAllUsers) {
          tempAllAssigned.add(companyId);
          tempPartialAssigned.delete(companyId);
        }
      });
    });

    // Used to display the companies within one of three sections based on
    // their user assigned state - (ALL, PARTIAL, NONE)
    const { allAssigned, partialAssigned, noneAssigned } = allCompanies.reduce(
      (acc, company) => {
        if (tempAllAssigned.has(company.companyId)) {
          acc.allAssigned.push(company);
        } else if (tempPartialAssigned.has(company.companyId)) {
          acc.partialAssigned.push(company);
        } else {
          acc.noneAssigned.push(company);
        }
        return acc;
      },
      {
        allAssigned: [] as UserCompanyInfo[],
        partialAssigned: [] as UserCompanyInfo[],
        noneAssigned: [] as UserCompanyInfo[],
      },
    );

    setAllAssignedCompanies(allAssigned);
    setPartialAssignedDisplay(partialAssigned);
    setNoneAssignedCompanies(noneAssigned);
    setCompaniesToUsersMap(tempPartialAssigned);
  }, [showBulkAssignCompaniesModal]);

  useEffect(() => {
    // if allAssignedCompanies is not empty, then at least one company is assigned
    if (allAssignedCompanies.length > 0) {
      setAllUsersHaveACompany(true);
    } else if (companiesToUsersMap.size > 0) {
      const userIdSetWithCompanyAssignment = new Set<number>();
      companiesToUsersMap.forEach((companyIds) => {
        companyIds.forEach((userIdWithCompanyAssignment) => {
          userIdSetWithCompanyAssignment.add(userIdWithCompanyAssignment);
        });
      });
      // check if all selected users have at least one company assigned
      if (userIdSetWithCompanyAssignment.size === selectedUsers.length) {
        setAllUsersHaveACompany(true);
      } else {
        setAllUsersHaveACompany(false);
      }
    } else {
      setAllUsersHaveACompany(false);
    }
  }, [allAssignedCompanies, companiesToUsersMap]);

  const filter = (e) => {
    setCompaniesFilter(e.target.value);
  };

  // To keep the companies that were partially assigned prior to the assign modal opening,
  // we need to filter out the companies that are already in the 'partial' section.
  // For the other sections, if a company is toggled from unassigned -> assigned or vice-versa,
  // they'll be moved to the respective section. We don't want this to happen to the 'partial' section
  // in the case that a client-user wants to assign the company to all users, they'd need to
  // click on the company which will 'unassign' it for all companies, then click it again to assign it to all.
  // If the companies in the partial section followed the same rule as the other sections, the user
  // would need to search or find the company again to click it. This is not a good user experience.
  // The assignment still happens in the background, but we don't want to display duplicates so we 'hide'
  // them from the 'AllAssigned' and 'NoneUnassigned' sections.
  const getFilteredAllAssignedCompanies = () => {
    return allAssignedCompanies.filter(
      (company) =>
        partialAssignedDisplay.filter((c) => c.companyId === company.companyId).length === 0 &&
        company.companyName.toLowerCase().includes(companiesFilter.toLowerCase()),
    );
  };

  // Filter the companies that are partially assigned to every user based on company name
  const getFilteredPartialAssignedCompanies = () => {
    return partialAssignedDisplay.filter((company) =>
      company.companyName.toLowerCase().includes(companiesFilter.toLowerCase()),
    );
  };

  // See note for 'getFilteredAllAssignedCompanies' about why we filter out the companies that are in the 'partial' section.
  const getFilteredUnassignedCompanies = () => {
    return noneAssignedCompanies.filter(
      (company) =>
        partialAssignedDisplay.filter((c) => c.companyId === company.companyId).length === 0 &&
        company.companyName.toLowerCase().includes(companiesFilter.toLowerCase()),
    );
  };

  function getUserAssignedState({ companyId }: UserCompanyInfo): AssignedUsersState {
    const isAllAssigned: boolean =
      allAssignedCompanies.filter((c) => c.companyId === companyId).length > 0;
    if (isAllAssigned) {
      return AssignedUsersState.ALL;
    }
    if (companiesToUsersMap.has(companyId)) {
      return AssignedUsersState.PARTIAL;
    }
    return AssignedUsersState.NONE;
  }

  const onCancelClick = () => {
    setAllAssignedCompanies([]);
    setCompaniesFilter("");
    setCompaniesToUsersMap(new Map<number, number[]>());
    dispatch(setShowBulkAssignCompaniesModal(false));
  };

  const onSubmitButtonClick = () => {
    const allAssignedCompanyIds = allAssignedCompanies.map((company) => company.companyId);
    const updateCompAssignRequest = mapBulkUsersAssignedCompanies(
      selectedUsers,
      companiesToUsersMap,
      allAssignedCompanyIds,
    );
    runUpdateUsersCompanies(updateCompAssignRequest);
    dispatch(setTableLoading(true));
    onCancelClick();
  };

  function getNumUniqueAssignedCompanies(): number {
    return allAssignedCompanies.length + companiesToUsersMap.size;
  }

  return (
    <Modal
      maxHeight={"100%"}
      width={"480px"}
      header={
        <HeaderContainer>
          <AvatarColumn>
            <UserAvatars selectedUsers={selectedUsers} />
          </AvatarColumn>
          <NamesColumn>
            <BulkNames>{formatNames(selectedUsers)}</BulkNames>
          </NamesColumn>
        </HeaderContainer>
      }
      body={
        <AssignCompaniesBodyContainter data-testid={BULK_ASSIGN_COMPANIES_MODAL_BODY}>
          <CompaniesAssigned>
            Companies assigned({getNumUniqueAssignedCompanies()})
          </CompaniesAssigned>
          <CompanySearchbar onChange={filter} />
          <CompaniesListingContainer>
            {getFilteredAllAssignedCompanies().map((company) => {
              return (
                <BulkCompaniesItem
                  key={company.companyId}
                  company={company}
                  assignedUsersState={getUserAssignedState(company)}
                  onSelectionChange={handleAssignedCompaniesChange}
                />
              );
            })}
            <SectionDivider />
            {getFilteredPartialAssignedCompanies().map((company) => {
              return (
                <BulkCompaniesItem
                  key={company.companyId}
                  company={company}
                  assignedUsersState={getUserAssignedState(company)}
                  onSelectionChange={handleAssignedCompaniesChange}
                />
              );
            })}
            <SectionDivider />
            {getFilteredUnassignedCompanies().map((company) => {
              return (
                <BulkCompaniesItem
                  key={company.companyId}
                  company={company}
                  assignedUsersState={getUserAssignedState(company)}
                  onSelectionChange={handleAssignedCompaniesChange}
                />
              );
            })}
          </CompaniesListingContainer>
        </AssignCompaniesBodyContainter>
      }
      footer={
        <AssignCompaniesModalFooter>
          <CancelButton data-testid={BULK_ASSIGN_COMPANIES_CANCEL_BUTTON} onClick={onCancelClick}>
            Cancel
          </CancelButton>
          <UpdateButton
            data-testid={BULK_ASSIGN_COMPANIES_UPDATE_BUTTON}
            disabled={!allUsersHaveACompany}
            onClick={onSubmitButtonClick}
          >
            Update
          </UpdateButton>
        </AssignCompaniesModalFooter>
      }
      kind={ModalKind.DEFAULT}
      withCloseButton={false}
      isOpen={showBulkAssignCompaniesModal}
    />
  );
};
