import React from "react";
import styled from "styled-components";
import { JobRequest } from "../../../api/bluecrew/types";

interface JobRequestModalHeaderProps {
  jobRequestData: JobRequest;
}

const StyledTextSubHeader = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const StyledJobRequestModalHeaderWrapper = styled.div`
  padding-top: 8px;
  padding-left: 8px;
`;

const StyledJobRequestModalLargerHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #080912;
`;

export const JobRequestModalHeader = ({ jobRequestData }: JobRequestModalHeaderProps) => {
  const jobRequestSubHeaderString = `Job ID: ${jobRequestData.request_id} • Requested at ${jobRequestData.created_time} on ${jobRequestData.created_date} by ${jobRequestData.created_by_first_name} ${jobRequestData.created_by_last_name}`;
  return (
    <StyledJobRequestModalHeaderWrapper>
      <StyledJobRequestModalLargerHeading>Job request details</StyledJobRequestModalLargerHeading>
      <StyledTextSubHeader>{jobRequestSubHeaderString}</StyledTextSubHeader>
    </StyledJobRequestModalHeaderWrapper>
  );
};
