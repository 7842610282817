import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { VirtualScroller } from "primereact/virtualscroller";
import { StyledCheckbox } from "../ManageUsersTable/styledComponents";
import {
  CompanyFiltersContainer,
  FilterCheckBoxLabelStyle,
  SideBarSectionContainer,
  CompanyFilterRow,
  AllCheckboxContainer,
} from "./styledComponents";
import { UserCompanyInfo } from "../../../api/bluecrew/types";
import {
  setSelectedCompanies,
  addToSelectedCompanies,
  removeFromSelectedCompanies,
} from "../slices/CompanyFilterSlice";
import { getUserCompany } from "../../../redux/selectors/auth";
import useWindowDimensions from "../hooks/WindowDimensions";
import { TABLE_ROW_HEIGHT } from "../constants";
import { useAppSelector } from "../../../redux";

type CompanyFilterProps = {
  allCompanies: UserCompanyInfo[];
  companies: UserCompanyInfo[];
  isFiltered: boolean;
};

export const CompanyFilter: React.FC<CompanyFilterProps> = ({
  companies,
  isFiltered,
  allCompanies,
}) => {
  const dispatch = useDispatch();
  const selectedCompanies = useAppSelector(
    (state) => state.manageUsersCompanyFilter.selectedCompanies,
  );
  const activeCompanyId = useAppSelector((state) => getUserCompany(state));
  const { height } = useWindowDimensions();
  const MAX_HEIGHT = height - 580;
  const [scrollerHeight, setScrollerHeight] = useState(TABLE_ROW_HEIGHT);
  const isAllSelected = selectedCompanies.length > 0 && companies.length === 0;

  function handleAllChange() {
    if (isAllSelected) {
      dispatch(setSelectedCompanies(allCompanies.filter((c) => c.companyId === activeCompanyId)));
    } else {
      dispatch(setSelectedCompanies([...companies, ...selectedCompanies]));
    }
  }

  const handleClick = (company: UserCompanyInfo) => {
    const checked = selectedCompanies.some(
      (selectedCompany) => selectedCompany.companyId === company.companyId,
    );
    if (!checked) {
      dispatch(addToSelectedCompanies(company));
    } else if (selectedCompanies.length === 1) {
      // At minimum, one company should be selected. Default to active.
      dispatch(setSelectedCompanies(allCompanies.filter((c) => c.companyId === activeCompanyId)));
    } else {
      dispatch(removeFromSelectedCompanies(company.companyId));
    }
  };

  useEffect(() => {
    const newHeight = Math.min(companies.length * TABLE_ROW_HEIGHT, MAX_HEIGHT);
    setScrollerHeight(newHeight);
  }, [selectedCompanies, companies, MAX_HEIGHT]);

  return (
    <CompanyFiltersContainer>
      <SideBarSectionContainer>
        {!isFiltered && ( // only display 'All' checkbox when it's not filtered.
          <AllCheckboxContainer key="selectAllCompanies">
            <StyledCheckbox checked={isAllSelected} onChange={handleAllChange} />
            <FilterCheckBoxLabelStyle
              style={{ width: "100%" }}
              onClick={handleAllChange}
              htmlFor="selectAllCompanies"
            >
              All
            </FilterCheckBoxLabelStyle>
          </AllCheckboxContainer>
        )}
        <div>
          <VirtualScroller
            items={companies}
            itemSize={TABLE_ROW_HEIGHT}
            style={{
              width: "280px",
              height: `${scrollerHeight}px`,
              overflowX: "hidden",
              marginLeft: "-5px",
            }}
            numToleratedItems={15}
            itemTemplate={(company: UserCompanyInfo) => {
              return (
                <div key={`companyFilter_${company.companyId}`}>
                  <CompanyFilterRow
                    onClick={() => {
                      handleClick(company);
                    }}
                  >
                    <StyledCheckbox
                      checked={selectedCompanies.some(
                        (selectedCompany) => selectedCompany.companyId === company.companyId,
                      )}
                    />
                    <FilterCheckBoxLabelStyle
                      style={{ width: "100%" }}
                      htmlFor={`companyCheckbox_${company.companyId}`}
                    >
                      {company.companyName}
                    </FilterCheckBoxLabelStyle>
                  </CompanyFilterRow>
                </div>
              );
            }}
          />
        </div>
      </SideBarSectionContainer>
    </CompanyFiltersContainer>
  );
};
