import { useQuery } from "@tanstack/react-query";
import { getDepartments, getJobTypes, getJobTypesHistory, getSupervisors } from "../job";

export const useGetJobTypesQuery = () =>
  useQuery({
    queryKey: ["jobTypes"],
    queryFn: () =>
      // This api call needs to be changed if certificate filtering is desired
      getJobTypes(),
    select: (data) => data.jobtypes,
  });

export const useGetDepartmentsQuery = (companyId: number) =>
  useQuery({
    queryKey: ["departments", companyId],
    queryFn: () => getDepartments({ companyId }),
    select: (data) => {
      return data.sort((a, b) => a.tag_name.localeCompare(b.tag_name));
    },
  });

export const useGetSupervisorsQuery = (companyId?: string) =>
  useQuery({
    queryKey: ["supervisors", companyId],
    queryFn: () => getSupervisors(companyId),
    select: (data) => {
      const supervisors: any = data ?? [];

      return supervisors.map((supervisor) => ({
        id: supervisor.id,
        name: `${supervisor.first_name} ${supervisor.last_name}`,
      }));
    },
  });

export const useGetJobTypesHistoryQuery = () =>
  useQuery({
    queryKey: ["jobTypesHistory"],
    queryFn: () => getJobTypesHistory(),
    select: (data) => data.history ?? [],
  });
