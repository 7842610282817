import React from "react";
import { RequestLog } from ".";
import { GenericCell } from "../../positions/DashboardSchedulesAndIndividualShiftsTable/GenericCell";
import { RowContainer } from "../../positions/SharedComponents";

interface CreatedByCellProps {
  rowData: RequestLog;
}

export const CreatedByCell = ({ rowData }: CreatedByCellProps) => {
  return (
    <RowContainer
      data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnGeneric"
      justifyContent={"left"}
    >
      {rowData.poster.is_internal_employee ? (
        <GenericCell value={"Bluecrew"} justifyContent={"left"} />
      ) : (
        <a href={`mailto:${rowData.poster.username}`}>{rowData.poster.full_name}</a>
      )}
    </RowContainer>
  );
};
