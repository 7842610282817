import {
  GetCrewMembersScheduleIcon,
  GetCrewMembersClockIcon,
  GetCrewMembersRepeatingScheduleIcon,
  DayOfWeek,
  DaysOfWeek,
  Tabs,
  TabsKind,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { isDate, isEmpty } from "lodash";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useController } from "react-hook-form";
import DateObject from "react-date-object";
import { MultiSelectDatePicker } from "./MultiSelectDatePicker";
import { ScheduleDatePickerField } from "./ScheduleDatePickerField";
import { ScheduleTimeField } from "./ScheduleTimeField";
import { FieldWrapper } from "./FieldWrapper";
import { DateRange } from "../types/propTypes/ScheduleDatePickerField.types";
import { TimeRange } from "../types/propTypes/TimeField.type";
import { ScheduleType, SINGLE_SHIFT_SUFFIX } from "../types/propTypes/Schedule.types";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { ScheduleNameField } from "./ScheduleNameField";
import { ScheduleDayField } from "./ScheduleDayField";
import { ScheduleTabSectionProps } from "../types/propTypes/ScheduleTabSection.types";
import {
  Schedule,
  MAX_SCHEDULE_NAME_LENGTH,
  MIN_SCHEDULE_NAME_LENGTH,
  SCHEDULE_NAME_REQUIRED_MSG,
  SCHEDULE_NAME_TOO_LONG_MSG,
  SCHEDULE_NAME_TOO_SHORT_MSG,
  SHIFT_NAME_REQUIRED,
} from "../types/propTypes/ScheduleField.types";
import { ScheduleField } from "./ScheduleField";
import { defaultFormValues, NEW_REPEATING_SCHEDULE, NEW_SINGLE_SHIFT } from "../formUtils";
import {
  BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT,
  TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT,
} from "../../../../shared/constants";

export const getScheduleNameFromDate = (d: Date) =>
  moment(d).format(`MMM D, YYYY [${SINGLE_SHIFT_SUFFIX}]`);

export const ScheduleTabSection = ({
  control,
  trigger,
  errors,
  isNewScheduleSelected,
  isPositionSelected,
  schedules,
  scheduleMapper,
  shiftsCount,
  onGetScheduleInfo,
  setExistingScheduleInfo,
  isBulkSingleShiftEnabled,
  isBulkSingleShiftMax50DayEnabled,
  disabled,
  currentFormValues,
}: ScheduleTabSectionProps) => {
  const MAX_SHIFTS_COUNT = 1000;

  const MIN_TIME_RANGE_HOURS = 4;
  const MAX_TIME_RANGE_HOURS = 12;

  const DATE_RANGE_FIELD = "date";
  const TIME_RANGE_FIELD = "timeRange";
  const SCHEDULE_FIELD = "schedule";
  const SCHEDULE_NAME_FIELD = "scheduleName";
  const WORKDAYS_FIELD = "workDays";
  const SCHEDULE_TYPE_FIELD = "scheduleType";

  const validateDateRange = trigger.bind(null, DATE_RANGE_FIELD);
  const validateTimeRange = trigger.bind(null, TIME_RANGE_FIELD);
  const validateSchedule = trigger.bind(null, SCHEDULE_FIELD);
  const validateScheduleName = trigger.bind(null, SCHEDULE_NAME_FIELD);
  const validateWorkDays = trigger.bind(null, WORKDAYS_FIELD);

  const [, theme] = useThemedStyletron();

  const isDateRangeInThePast = (date: DateRange): boolean =>
    moment(date[0]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ||
    moment(date[1]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD");

  const isStartTimeInThePastIfSelectToday = (date: DateRange, startDateTime: string): boolean =>
    moment(date[0]).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
    startDateTime <= moment().format("HH:mm");

  const {
    field: { onChange: onChangeScheduleType, value: scheduleType },
  } = useController({
    name: SCHEDULE_TYPE_FIELD,
    control,
  });

  const setScheduleTypeSearchParams = (newScheduleType: ScheduleType) => {
    const url = new URL(window.location.href);
    url.searchParams.set("scheduleType", newScheduleType.toString());

    window.history.replaceState({}, "", url.toString());
  };

  const {
    field: { onChange: onChangeDateRange, value: unsafeDateRange },
    meta: { invalid: invalidDate, isDirty: isDirtyDate },
  } = useController({
    name: DATE_RANGE_FIELD,
    control,
    rules: {
      validate: (data: Array<Date>) => {
        if (isEmpty(schedule)) return true;

        const currentStartTime = currentFormValues().timeRange?.startDateTime ?? "09:00";
        if (
          isDateRangeInThePast(data as DateRange) ||
          isStartTimeInThePastIfSelectToday(data, currentStartTime)
        ) {
          // With direct invite with single-shift, it's possible now (if someone gets funky with the URL params) to have a pre-populated date which is in the past.
          return `Start date/time must be in the future`;
        }

        if (isBulkSingleShiftMax50DayEnabled) {
          if (data.length > TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT) {
            return `Can't select more than ${TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT} days`;
          }
        } else if (data.length > BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT) {
          return `Can't select more than ${BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT} days`;
        }

        if (isPositionSelected) {
          if (data.length !== 2 && scheduleType === ScheduleType.REPEATING) {
            return "Date value must be a date or range";
          }
          if (
            (data.length === 0 && scheduleType === ScheduleType.SINGLE_SHIFT) ||
            (shiftsCount === 0 && scheduleType === ScheduleType.REPEATING)
          ) {
            // You can only set the correct days when position is selected
            return "Request must contain at least 1 shift: Adjust selected workdays or selected date range";
          }
          if (shiftsCount > MAX_SHIFTS_COUNT) {
            return `Maximum ${MAX_SHIFTS_COUNT} shifts per request`;
          }
        }

        return true;
      },
      required: true,
    },
  });

  useEffect(() => {
    // Validate prepopulated date
    // Validate date range whenever date changes
    !isEmpty(unsafeDateRange) && validateDateRange();
  }, [validateDateRange, unsafeDateRange, isDirtyDate, shiftsCount]);

  const formatDate = (date: Date) => moment(date).format("MM-DD-YYYY");

  const setDateRangeSearchParams = (date: Date[]) => {
    const START_DATE_IDX = 0;
    const END_DATE_IDX = 1;

    const url = new URL(window.location.href);
    url.searchParams.set("startShiftDate", formatDate(date[START_DATE_IDX]));
    url.searchParams.set("endShiftDate", formatDate(date[END_DATE_IDX]));
    window.history.replaceState({}, "", url.toString());
  };

  const setSingleShiftDatesSearchParams = (dateArr: Date[]) => {
    const url = new URL(window.location.href);
    const urlDateArr: string[] = [];
    dateArr.forEach((date, index) => {
      urlDateArr[index] = formatDate(date);
    });
    if (url.searchParams.has("shiftDate")) {
      url.searchParams.delete("shiftDate");
    }
    url.searchParams.set("shiftDate", urlDateArr.join(","));
    window.history.replaceState({}, "", url.toString());
  };

  const clearAllDateSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("shiftDate");
    url.searchParams.delete("startShiftDate");
    url.searchParams.delete("endShiftDate");

    window.history.replaceState({}, "", url.toString());
  };

  const setDateRange = (val: Date | Date[]) => {
    let rangedDate: Date[] = [];
    if (isDate(val)) {
      const validatedDate = val as Date;
      // This branch is for single shift date changes
      rangedDate = [validatedDate, validatedDate];
    } else if (Array.isArray(val)) {
      // This branch is for repeating shift date changes
      rangedDate = val;
    }
    setDateRangeSearchParams(rangedDate);
    onChangeDateRange(rangedDate);
  };

  const onCloseDateRange = () => {
    let standardizedRange: DateRange | [] = [];
    if (unsafeDateRange.length === 1) {
      standardizedRange = [unsafeDateRange[0], unsafeDateRange[0]];
    } else if (dateRange?.length === 2) {
      standardizedRange = [unsafeDateRange[0], unsafeDateRange[1]];
    }
    onChangeDateRange(standardizedRange);
    validateDateRange();
  };

  const {
    field: { onChange: onChangeTimeRange, value: unsafeTimeRange },
    meta: { invalid: invalidTimeRange },
  } = useController({
    name: TIME_RANGE_FIELD,
    control,
    rules: {
      validate: (data: TimeRange) => {
        switch (true) {
          case !data.startDateTime && !data.endDateTime:
            return "Start & End time are required";
          case !data.startDateTime:
            return "Start time is required";
          case !data.endDateTime:
            return "End time is required";
          case isLessThenRequired(data.startDateTime, data.endDateTime):
            return "Shift duration must be at least 4 hours";
          case isMoreThenRequired(data.startDateTime, data.endDateTime):
            return "Shift duration must be not more than 12 hours";
          default:
            return true;
        }
      },
    },
  });

  const onChangeScheduleField = (data: Schedule) => {
    onChangeSchedule(data);
    // Clear existing schedule info, when schedule was changed
    setExistingScheduleInfo(null);
    onGetScheduleInfo?.(data.id);
    onChangeScheduleType(ScheduleType.REPEATING);
  };

  const setTimeRangeSearchParams = (timeRange: TimeRange) => {
    const url = new URL(window.location.href);
    url.searchParams.set("startTime", timeRange.startDateTime);
    url.searchParams.set("endTime", timeRange.endDateTime);
    window.history.replaceState({}, "", url.toString());
  };

  const clearTimeRangeSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("startTime");
    url.searchParams.delete("endTime");

    window.history.replaceState({}, "", url.toString());
  };

  const setTimeRange = (t: TimeRange) => {
    onChangeTimeRange(t);
    setTimeRangeSearchParams(t);

    return validateTimeRange();
  };

  const {
    field: { onChange: onChangeSchedule, value: schedule },
    meta: { invalid: invalidSchedule },
  } = useController({
    name: SCHEDULE_FIELD,
    control,
    rules: { required: true },
  });

  const {
    field: { onChange: onChangeScheduleName, value: scheduleName },
    meta: { invalid: invalidScheduleName },
  } = useController({
    name: SCHEDULE_NAME_FIELD,
    control,
    rules: {
      validate: (name: string | null) => {
        if (!name && isNewScheduleSelected) {
          if (scheduleType === ScheduleType.SINGLE_SHIFT) {
            return SHIFT_NAME_REQUIRED;
          }
          return SCHEDULE_NAME_REQUIRED_MSG;
        }
        return true;
      },
      minLength: {
        value: MIN_SCHEDULE_NAME_LENGTH,
        message: SCHEDULE_NAME_TOO_SHORT_MSG,
      },
      maxLength: {
        value: MAX_SCHEDULE_NAME_LENGTH,
        message: SCHEDULE_NAME_TOO_LONG_MSG,
      },
    },
  });

  const {
    field: { onChange: onChangeWorkdays, value: workDays },
    meta: { invalid: invalidWorkdays },
  } = useController({
    name: WORKDAYS_FIELD,
    control,
    rules: {
      validate: (data: Array<DayOfWeek>) => data.length > 0,
    },
  });

  const dateRange: DateRange = (unsafeDateRange as DateRange) || [];
  const timeRange: TimeRange = (unsafeTimeRange as TimeRange) || {};

  const calculateStartToEndTimeDuration = (start: string, end: string): number => {
    const startTime = moment(start, "HH:mm");
    const endTime = moment(end, "HH:mm");
    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    return moment.duration(endTime.diff(startTime)).asHours();
  };

  const isLessThenRequired = (startTime: string, endTime: string) =>
    calculateStartToEndTimeDuration(startTime, endTime) < MIN_TIME_RANGE_HOURS;

  const isMoreThenRequired = (startTime: string, endTime: string) =>
    calculateStartToEndTimeDuration(startTime, endTime) > MAX_TIME_RANGE_HOURS;

  const originalSingleShiftOnChange = (value: Date | Date[]) => {
    setDateRange(value);

    const date = isDate(value) ? value : value[0];
    const scheduleNameFromDate = getScheduleNameFromDate(date);
    const day = date.getDay();
    const workingDays = [`${day}`];

    onChangeScheduleName(scheduleNameFromDate);
    onChangeSchedule({
      ...schedule,
      name: scheduleNameFromDate,
      workingDays,
    });
    onChangeWorkdays(workingDays);
  };

  const bulkSingleShiftOnChange = (values: DateObject[]) => {
    const hasShiftCapacityReached =
      (isBulkSingleShiftMax50DayEnabled && values.length > TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT) ||
      (!isBulkSingleShiftMax50DayEnabled && values.length > BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT);

    const dateValues: Date[] = [];
    const workingDays: string[] = [];
    for (let i = 0; i < values.length; i += 1) {
      const dateValue = values[i].toDate();

      const dateExistsInExistingArray =
        dateRange.filter((d) => d.getTime() === dateValue.getTime()).length > 0;

      // When we reach to the maximum capacity, don't let user
      // select more dates
      if (!hasShiftCapacityReached || dateExistsInExistingArray) {
        dateValues.push(dateValue);
        workingDays.push(`${dateValue.getDay()}`);
      }
    }

    setSingleShiftDatesSearchParams(dateValues);
    onChangeDateRange(dateValues);
    onChangeSchedule({
      ...schedule,
      workingDays,
    });
    onChangeWorkdays(workingDays);
  };

  return (
    <Tabs
      disabled={disabled}
      kind={TabsKind.UNDERLINED}
      tabHeaderMarginLeft={"40px"}
      renderList={[
        {
          title: "Repeating Schedule",
          content: (
            <div data-pendo-key="RepeatingScheduleTab">
              <TopAlignmentWrapper $paddingTop={theme.sizing.scale800}>
                <ScheduleDatePickerField
                  disabled={disabled}
                  value={dateRange}
                  errors={errors}
                  invalid={invalidDate}
                  onChange={setDateRange}
                  onClose={onCloseDateRange}
                  rangedDatePicker
                  placeholder="Choose a start & end date"
                />
                {invalidDate ? (
                  <ErrorText>{shiftsCount} shifts</ErrorText>
                ) : (
                  <NormalText>{shiftsCount} shifts</NormalText>
                )}
              </TopAlignmentWrapper>
              {isPositionSelected && (
                <FieldWrapper icon={<GetCrewMembersRepeatingScheduleIcon />} label="Schedule">
                  <div data-pendo-key="ScheduleFieldSelectionTab">
                    <ScheduleField
                      value={schedule}
                      onChange={onChangeScheduleField}
                      onClose={() => validateSchedule()}
                      invalid={invalidSchedule}
                      schedules={schedules}
                      mapper={scheduleMapper}
                      isPositionSelected={isPositionSelected}
                    />
                  </div>
                  {isNewScheduleSelected && (
                    <div>
                      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
                      <ScheduleNameField
                        value={scheduleName}
                        onChange={(s: string) => {
                          onChangeScheduleName(s);
                          validateScheduleName();
                        }}
                        invalid={invalidScheduleName}
                        errors={errors}
                        scheduleType={scheduleType}
                      />
                      <TopAlignmentWrapper $paddingTop={theme.sizing.scale800}>
                        <ScheduleDayField
                          value={workDays}
                          onChange={(w: DaysOfWeek) => {
                            onChangeWorkdays(w);
                            validateWorkDays();
                          }}
                          invalid={invalidWorkdays}
                        />
                        <ScheduleTimeField
                          value={timeRange}
                          setTimeRange={setTimeRange}
                          invalid={invalidTimeRange}
                          errors={errors}
                        />
                      </TopAlignmentWrapper>
                    </div>
                  )}
                </FieldWrapper>
              )}
            </div>
          ),
        },
        {
          title: "Individual Shifts",
          content: (
            <div>
              {isBulkSingleShiftEnabled ? (
                <>
                  <FieldWrapper icon={<GetCrewMembersClockIcon />} paddingTop="0px">
                    <ScheduleTimeField
                      value={timeRange}
                      setTimeRange={setTimeRange}
                      invalid={invalidTimeRange}
                      errors={errors}
                      $noMargin
                    />
                  </FieldWrapper>
                  <TopAlignmentWrapper $marginTop={"24px"}>
                    <MultiSelectDatePicker
                      isMultiple
                      disableDatePicker={disabled}
                      value={dateRange}
                      errors={errors}
                      inError={invalidDate}
                      onChange={(values: DateObject[]) => {
                        bulkSingleShiftOnChange(values);
                      }}
                      isBulkSingleShiftMax50DayEnabled={isBulkSingleShiftMax50DayEnabled}
                    />
                  </TopAlignmentWrapper>
                </>
              ) : (
                <TopAlignmentWrapper height={"46px"}>
                  <ScheduleDatePickerField
                    value={dateRange}
                    errors={errors}
                    invalid={invalidDate}
                    onChange={(value) => {
                      originalSingleShiftOnChange(value);
                    }}
                    onClose={() => onCloseDateRange()}
                    rangedDatePicker={false}
                    placeholder="Choose a date"
                  />
                  <ScheduleTimeField
                    value={timeRange}
                    setTimeRange={setTimeRange}
                    invalid={invalidTimeRange}
                    errors={errors}
                  />
                </TopAlignmentWrapper>
              )}
              <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
              <FieldWrapper
                icon={<GetCrewMembersScheduleIcon />}
                label={scheduleType === ScheduleType.REPEATING ? "Schedule" : ""}
                paddingTop="0px"
              >
                <ScheduleNameField
                  value={scheduleName}
                  onChange={(s: string) => {
                    onChangeScheduleName(s);
                    validateScheduleName();
                  }}
                  invalid={invalidScheduleName}
                  errors={errors}
                  scheduleType={scheduleType}
                />
              </FieldWrapper>
            </div>
          ),
        },
      ]}
      activeTab={scheduleType ? scheduleType.toString() : ScheduleType.REPEATING.toString()}
      onChange={(tabIndex: string) => {
        /*
         * Resets the form values to the default values (workingDays, scheduleName, schedule, timeRange)
         * for a new repeating or single shift schedule.
         */
        const newScheduleType = parseInt(tabIndex, 10);

        if (newScheduleType === ScheduleType.SINGLE_SHIFT) {
          const date = isDate(dateRange) ? (dateRange as unknown as Date) : (dateRange[0] as Date);
          let workingDays;
          let newScheduleName;
          if (date) {
            const scheduleNameFromDate = getScheduleNameFromDate(date);
            const day = date.getDay();
            workingDays = [`${day}`];
            newScheduleName = scheduleNameFromDate;
          } else {
            workingDays = [] as Date[];
            newScheduleName = "";
          }

          const newSingleShiftSchedule = {
            ...NEW_SINGLE_SHIFT,
            workingDays,
            scheduleName: newScheduleName,
          };
          onChangeScheduleName(scheduleName);
          onChangeWorkdays(workingDays);
          onChangeSchedule(newSingleShiftSchedule);
        } else {
          // ScheduleType.REPEATING
          onChangeScheduleName("");
          onChangeWorkdays(NEW_REPEATING_SCHEDULE.workingDays);
          onChangeSchedule(isPositionSelected ? NEW_REPEATING_SCHEDULE : {});
        }
        // New dates each time type is changed
        setDateRange([]);

        onChangeTimeRange(defaultFormValues.getCrew.timeRange);
        clearTimeRangeSearchParams();
        clearAllDateSearchParams();
        onChangeScheduleType(newScheduleType as ScheduleType);
        setScheduleTypeSearchParams(newScheduleType as ScheduleType);

        // Clear existing schedule info, when schedule type was changed
        setExistingScheduleInfo(null);
      }}
    />
  );
};

const TopAlignmentWrapper = styled<
  { $paddingTop?: string; height?: string; $marginTop?: string },
  "div"
>("div", ({ $paddingTop, height, $marginTop }) => ({
  display: "flex",
  paddingTop: $paddingTop,
  alignItems: "flex-start",
  height,
  marginTop: $marginTop,
}));

const ErrorText = styled("p", ({ $theme }) => ({
  color: $theme.colors.negative,
  ...$theme.typography.font200,
  marginLeft: "1em",
  alignItems: "center",
}));

const NormalText = styled("p", ({ $theme }) => ({
  ...$theme.typography.font200,
  marginLeft: "1em",
  alignItems: "center",
}));
