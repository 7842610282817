import { useQuery } from "@tanstack/react-query";
import { getAuditTrail, getCompanyShifts } from "../finance";
import { getPayrollWeek } from "../payroll";
import { mapFinanceResponseToFinanceTableData } from "../../../containers/Payroll/Timesheets/utility/TimesheetsMappingUtil";
import QueryKeys from "./queryKeys";

export const useGetCompanyShifts = (date: Date, companyId?: string) => {
  const payrollWeek = getPayrollWeek(date);
  return useQuery({
    queryKey: [...QueryKeys.Finance_GetCompanyShifts, companyId, payrollWeek],
    queryFn: () => getCompanyShifts(companyId!, payrollWeek),
    enabled: !!companyId,
    select: (data) => mapFinanceResponseToFinanceTableData(data),
  });
};

export const useGetAuditTrail = (
  companyId: string,
  jobId: string,
  shiftId: number,
  userId: string,
) =>
  useQuery({
    queryKey: ["auditTrail", companyId, jobId, shiftId, userId],
    queryFn: () => getAuditTrail(companyId, jobId, shiftId, userId),
    refetchInterval: false,
  });
