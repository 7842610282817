import styled from "styled-components";

import { Calendar } from "../../../../components/Calendar";
import { Dropdown } from "../../../../components/Dropdown";
import { Button } from "../../../../components/Button";
import { Dialog } from "../../../../components/Dialog";
import { HoverStyledButton } from "../../styledComponents";

export const StyledSelect = styled(Dropdown)`
  width: 100%;
`;

export const CancelButton = styled(Button)`
  .p-button-label {
    color: ${({ theme }) => theme.colorTokens.blue400};
  }
  :enabled:hover {
    border-color: ${({ theme }) => theme.colorTokens.white};
  }
`;

export const DownloadButton = styled(HoverStyledButton)`
  background: ${({ theme }) => theme.colorTokens.blue400};
`;

export const StyledIcon = styled.img``;

export const StyledCalendar = styled(Calendar)`
  min-width: 100%;
`;

export const StyledDialog = styled(Dialog)`
  width: 542px;
  .p-dialog-content {
    padding-bottom: 0;
  }
`;
