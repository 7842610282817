import React, { useState } from 'react';
import { Textarea as BaseTextarea } from 'baseui/textarea';
import { SIZE } from 'baseui/input';
import { styled } from 'baseui';
import {
  getBorder,
  getBorderRadius,
  getHorizontalPadding,
  getVerticalPadding,
} from '../helpers/styleHelpers';
import { BlueprintTheme, useThemedStyletron } from '../theme';

interface WrapperProps {
  $textareaWrapperStyle: object;
  $theme: BlueprintTheme;
  $isFocused: boolean;
  $error: boolean;
}

export const RESIZE_VALUE = {
  auto: 'auto',
  horizontal: 'horizontal',
  vertical: 'vertical',
  both: 'both',
  none: 'none',
};

interface TextareaProps {
  disabled?: boolean;
  error?: boolean;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (val: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  resize?: any;
  size?: SIZE[keyof SIZE];
  textareaWrapperStyle?: { width: string | number; height: string | number };
  value?: string;
  styles?: any;
}

export const Textarea = (props: TextareaProps) => {
  const {
    placeholder = '',
    value,
    error = false,
    disabled = false,
    size = SIZE.default,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    inputRef,
    textareaWrapperStyle = { width: '100%', height: 'auto' },
    resize = RESIZE_VALUE.none,
    styles,
  } = props;

  const [isFocused, setFocus] = useState(false);

  const [, $theme] = useThemedStyletron();

  const { paddingY, paddingX } = {
    [SIZE.mini]: {
      paddingY: $theme.sizing.scale400,
      paddingX: $theme.sizing.scale500,
    },
    [SIZE.compact]: {
      paddingY: $theme.sizing.scale400,
      paddingX: $theme.sizing.scale500,
    },
    [SIZE.default]: {
      paddingY: $theme.sizing.scale550,
      paddingX: $theme.sizing.scale600,
    },
    [SIZE.large]: {
      paddingY: $theme.sizing.scale600,
      paddingX: $theme.sizing.scale700,
    },
  }[size];

  return (
    <Wrapper
      $textareaWrapperStyle={textareaWrapperStyle}
      $theme={$theme}
      $isFocused={isFocused}
      $error={error}
    >
      <BaseTextarea
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          onChange && onChange(event.currentTarget.value);
        }}
        onBlur={(event) => {
          onBlur && onBlur(event);
          setFocus(false);
        }}
        onFocus={(event) => {
          onFocus && onFocus(event);
          setFocus(true);
        }}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        inputRef={inputRef}
        disabled={disabled}
        error={error}
        size={size}
        overrides={{
          Input: {
            style: {
              ...getVerticalPadding(paddingY),
              ...getHorizontalPadding(paddingX),
              ...styles,
              resize,
              fontSize: $theme.sizing.scale550, // "14px" Per Eric Singley 10/21/22
            },
          },
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled<WrapperProps, 'div'>(
  'div',
  ({ $theme, $textareaWrapperStyle, $isFocused, $error }) => ({
    ':nth-child(n) > div[data-baseweb="textarea"]': {
      ...getTextareaBorder($theme, $isFocused, $error),
      ...getBorderRadius($theme.sizing.scale200),
      boxShadow: $isFocused ? $theme.lighting.inputShadowActive : undefined,
      ...$textareaWrapperStyle,
    },
  }),
);

function getTextareaBorder(
  $theme: BlueprintTheme,
  $isFocused: boolean,
  $error: boolean,
) {
  const { colors, borders } = $theme;

  if ($isFocused) {
    return getBorder({
      ...borders.border100,
      borderColor: colors.inputBorderActive,
    });
  }
  if ($error) {
    return getBorder({
      ...borders.border200,
      borderColor: colors.inputBorderError,
    });
  }

  return getBorder(borders.border100);
}
