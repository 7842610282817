import React, { Component } from "react";
import { palette as p } from "styled-tools";
import styled from "styled-components";
import { CurrencyNumber } from "./CurrencyNumber";
import { Paragraph } from "../index";

type Props = {
  inputId: string;
  wageFloor: number;
  wageCeiling: number;
  defaultWage: string;
  wageIncrement: Number;
  disabled: Boolean;
  payIntervalLabel: string;
  currencySymbol: string;
  handleUpdate: Function;
  inlineText: string;
  inlineValue: string;
  value: string;
};

const StyledContainer = styled.div`
  display: flex;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Paragraph} {
    font-size: 1rem;
    color: ${p("platinum")};
    padding: 0.25rem;
  }
`;
export class WageInput extends Component<Props> {
  state = {
    wageValue: this.props.defaultWage,
  };

  handleChange = (event) => {
    if (event && event.target) {
      const amount = event.target.value;

      // NOTE: Regex restricts a range of only 2 values past the decimal
      // Allowing only digits. Can not start with a decimal without a preceding value
      // the check for no amount allows the user to clear the value completely, so they freely type a new amount
      if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
        this.setState(
          {
            wageValue: amount,
          },
          this.propagateChangeToParent,
        );
      }
    }
  };

  propagateChangeToParent = () => {
    return this.props.handleUpdate && this.props.handleUpdate(this.state.wageValue);
  };

  componentDidUpdate(prevProps) {
    // state is initialized on mount, and defaultWage prop may not be defined yet.
    // here we are checking if they have updated and resetting state values
    if (prevProps.defaultWage !== this.props.defaultWage) {
      this.setState(
        {
          wageValue: this.props.defaultWage,
        },
        this.propagateChangeToParent,
      );
    }
  }

  render() {
    const {
      disabled,
      inputId,
      currencySymbol,
      wageFloor,
      wageCeiling,
      wageIncrement,
      inlineText,
      inlineValue,
    } = this.props;

    const { wageValue } = this.state;

    return (
      <StyledContainer>
        <CurrencyNumber
          // @ts-ignore
          className="currency-number"
          inputId={inputId}
          disabled={disabled}
          symbol={currencySymbol}
          minVal={wageFloor}
          maxVal={wageCeiling}
          curVal={wageValue}
          increment={wageIncrement}
          onChange={this.handleChange}
        />
        {inlineText && (
          <StyledTextContainer>
            <span>
              <Paragraph>{inlineText}</Paragraph>
            </span>
            <span>
              <Paragraph>{inlineValue} /hr</Paragraph>
            </span>
          </StyledTextContainer>
        )}
      </StyledContainer>
    );
  }

  static defaultProps = {
    inputId: "wageInput",
    wageFloor: 10,
    wageCeiling: 50,
    defaultWage: 15,
    wageIncrement: 1,
    disabled: false,
    payIntervalLabel: "per hour",
    currencySymbol: "$",
  };
}
