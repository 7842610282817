// COMPANY - ACTIONS
// =============================================================================

import { createActions } from "redux-actions";
import { GetCompanyDetailsPayload, SwitchCompanyPayload } from "../../api/bluecrew/company";

export type SwitchCompanyAction = {
  type: string;
  payload: SwitchCompanyPayload;
};

export type GetCompanyDetailsAction = {
  type: string;
  payload: GetCompanyDetailsPayload;
};

export type CheckCompanyPayload = {
  existingCompanyId: number;
};

export type CheckCompanyAction = {
  type: string;
  payload: CheckCompanyPayload;
};

export default createActions({
  GET_COMPANY_DETAILS: {
    REQUEST: (req: GetCompanyDetailsPayload): GetCompanyDetailsPayload => ({
      ...req,
    }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CHECK_COMPANY: (payload: CheckCompanyPayload) => payload,
  SWITCH_COMPANY: {
    REQUEST: (req: SwitchCompanyAction) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  USER_COMPANIES: {
    REQUEST: undefined,
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
}) as any;
