import React, { useEffect } from "react";
import { useThemedStyletron } from "@bluecrew/blueprint-web";
import { useWatch } from "react-hook-form";
import { HourlyPayField } from "../../HourlyPayField";
import { ArrivalInstructionsField } from "../../ArrivalInstructionsField";
import { DescriptionField } from "../../DescriptionField";
import { DressCodeField } from "../../DressCodeField";
import { Divider } from "../../../../components/Divider";
import { Spacer, SpacerType } from "../../../../components/Spacer";
import { PositionTitleField } from "../../PositionTitleField";
import { WorksiteField } from "../../WorksiteField";
import { PositionDetailsSectionProps } from "../../types/propTypes/PositionDetailsSection.types";
import { WorksiteAddress } from "../../types/propTypes/Worksite.types";
import { CreatePositionGeofenceSection } from "./CreatePositionGeofenceSection";
import { GeneralRequirementsField } from "../../GeneralRequirementsField";

export const PositionDetailsSection = ({
  control,
  trigger,
  errors,
  onLocationSelect,
  config = {},
  disabled = false,
  positions,
  geofence,
  skill,
}: PositionDetailsSectionProps) => {
  const [, theme] = useThemedStyletron();
  const dressCode = useWatch({
    control,
    name: "dressCode",
  });
  const worksite: WorksiteAddress | null | undefined = useWatch({
    control,
    name: "worksite",
  });
  const showHourlyPay = config.hourlyPay !== false;
  const showDressCode = !disabled || !!dressCode;
  const skillId = Number(skill?.id);

  useEffect(() => {
    control.setValue("generalRequirements", `***General Requirements***\n${skill?.description}`);
  }, []);

  return (
    <div data-pendo-key="CreatePositionDetailsSection">
      <PositionTitleField control={control} trigger={trigger} errors={errors} disabled={disabled} />
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
      <WorksiteField
        control={control}
        trigger={trigger}
        onLocationSelect={onLocationSelect}
        disabled={disabled}
        positions={positions}
      />
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
      {worksite?.lat && worksite?.lng && !Number.isNaN(skillId) && (
        <>
          <Divider $marginTop={theme.sizing.scale800} $marginBottom={theme.sizing.scale800} />
          <CreatePositionGeofenceSection
            control={control}
            trigger={trigger}
            errors={errors}
            workplaceLocation={{
              lat: worksite.lat,
              lng: worksite.lng,
            }}
            skillId={skillId}
            minOffsiteWarnRadius={geofence?.min_offsite_warn_radius}
            minOffsiteBlockRadius={geofence?.min_offsite_block_radius}
            minOnsiteWarnRadius={geofence?.min_onsite_warn_radius}
            minOnsiteBlockRadius={geofence?.min_onsite_block_radius}
          />
          <Divider $marginTop={theme.sizing.scale800} $marginBottom={theme.sizing.scale800} />
        </>
      )}
      {showHourlyPay && (
        <>
          <HourlyPayField control={control} trigger={trigger} disabled={disabled} />
          <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
        </>
      )}
      <ArrivalInstructionsField
        control={control}
        trigger={trigger}
        errors={errors}
        disabled={disabled}
        examplesEnabled
      />
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
      <GeneralRequirementsField
        control={control}
        trigger={trigger}
        errors={errors}
        disabled
        examplesEnabled
      />
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
      <DescriptionField
        control={control}
        trigger={trigger}
        errors={errors}
        disabled={disabled}
        examplesEnabled
      />
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
      {showDressCode && (
        <>
          <DressCodeField
            control={control}
            trigger={trigger}
            errors={errors}
            disabled={disabled}
            examplesEnabled
          />
          <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
        </>
      )}
    </div>
  );
};
