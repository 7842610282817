import styled from "styled-components";
import { InputText as PRInputText, InputTextProps } from "primereact/inputtext";
import React from "react";

const InputTextWrapper = (props: InputTextProps) => (
  <PRInputText className={props.className} {...props} />
);

export const InputText = styled(InputTextWrapper)`
  :enabled:focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colorTokens.blue100};
  }
  :enabled:hover {
    border-color: ${({ theme }) => theme.colorTokens.blue850};
  }
`;
