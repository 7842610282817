// ROUTES
// =============================================================================
// Top level routes for the application.
import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoginFlow } from "../components/Login/Flows/LoginFlow";
import { ResetPasswordFlow } from "../components/Login/Flows/ResetPasswordFlow";
import { ActivateAccountFlow } from "../components/Login/Flows/ActivateAccountFlow";
import { LoginRouteStrings } from "../../shared/constants";

export const LoginRoutesComponent = () => (
  <Routes>
    <Route path="/*" element={<LoginFlow />} />
    <Route path={LoginRouteStrings.Login} element={<LoginFlow />} />
    <Route path={LoginRouteStrings.ResetPassword} element={<ResetPasswordFlow />} />
    <Route path={LoginRouteStrings.ActivateAccount} element={<ActivateAccountFlow />} />
  </Routes>
);
