import React from "react";

export type PanelItemType = {
  title: string;
  icon: string;
  url: string;
  key: string;
};

const handleV1Redirect = (url: string) => {
  window.location.href = url;
};

type PanelItemProps = {
  item: PanelItemType;
};

export const PanelItem = ({ item: { title, icon, url } }: PanelItemProps) => (
  <li key={title} onClick={() => handleV1Redirect(url)}>
    <img src={icon} />
    <span>{title}</span>
  </li>
);
