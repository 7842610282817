import React from "react";
import { getIsLoggedIn } from "../../utility/auth";
import { getQueryParam } from "../../utility";
import { cmos } from "../../api/bluecrew/constants";

const CrewMemberSecureAssets = () => {
  const loggedIn = getIsLoggedIn();

  if (!loggedIn) return null;

  if (window.location.search.match(/\?eid=/)) {
    const eidParam = getQueryParam(window.location, "eid");
    try {
      if (!eidParam) {
        throw new Error("eid param is null");
      }
      const url = `secureassets?eid=${encodeURIComponent(eidParam)}`;
      cmos.get(url).then((response) => {
        if (response.status === 200) {
          response.text().then((text) => {
            window.location.replace(text);
          });
        }
      });

      // Clean the URL up. Take the query string out of the URL
    } catch (error) {
      console.error(`Invalid eid: ${eidParam || "null"}`, error);
    }
  }

  return <div></div>;
};

export default CrewMemberSecureAssets;
