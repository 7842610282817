import React, { ForwardedRef, forwardRef, Ref } from "react";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import { UserAvatar } from "@bluecrew/blueprint-web/src/components/UserAvatar";
import { ComponentPosition, ListItemRendererT, UserListItemProps, UserListItemT } from "./types";
import { RichListItem } from "./RichListItem";
import { StyledUserSubtitle, StyledUserTitle } from "./styled-components";

const UserListItemCmp = <T extends UserListItemT>(
  { item, onClick, underlineLastItem, subtitleRenderer }: UserListItemProps<T>,
  ref: Ref<HTMLLIElement>,
) => (
  <RichListItem<T>
    ref={ref}
    item={item}
    checkboxPosition={ComponentPosition.RIGHT}
    onClick={onClick}
    titleRenderer={titleRenderer}
      subtitleRenderer={subtitleRenderer ?? internalSubtitleRenderer}
    avatarRenderer={avatarRenderer}
    underlineLastItem={underlineLastItem}
    showCheckBox
  />
);

const titleRenderer: ListItemRendererT<UserListItemT> = (listItem) => (
  <StyledUserTitle $theme={blueprintTheme}>{listItem.title}</StyledUserTitle>
);
const internalSubtitleRenderer: ListItemRendererT<UserListItemT> = (listItem) => (
  <StyledUserSubtitle $theme={blueprintTheme}>{listItem.subtitle}</StyledUserSubtitle>
);

const avatarRenderer: ListItemRendererT<UserListItemT> = (listItem) => (
  <UserAvatar fullName={listItem.title} size="lg" imgSrc={listItem.imgUrl} />
);

export const UserListItem = forwardRef(UserListItemCmp) as <T extends UserListItemT>(
  props: UserListItemProps<T> & { ref?: ForwardedRef<HTMLLIElement> },
) => JSX.Element;
