/**
 * Normalizes icon names that have changed over time
 * @param name {string}
 * @returns {string}
 */
export const getIconName = (name: string): string => {
  switch (name) {
    case "job-bar-tender":
      return "job-bartending";
    case "job-truck":
      return "job-delivery-driving";
    default:
      return name;
  }
};
