import { createSelector } from "reselect";
import get from "lodash/get";
import { StateShape } from "../reducers";
import { DEFAULT_BASE_WAGE } from "../../../shared/constants";

export const getMinWageIsFetching = (state: StateShape) =>
  get(state, "minWage.readMinWage.isFetching");
const getLatestMinWageSuccess = (state: StateShape) =>
  get(state, "minWage.readMinWage.success") || DEFAULT_BASE_WAGE;

export const getMinWage = createSelector(
  getLatestMinWageSuccess,
  // @ts-ignore TODO: BW-1482 Fix this error
  (response) => response.wage || DEFAULT_BASE_WAGE,
);
