import { apiV1 } from "./constants";

export type GetCrewMembersResultType = {
  user_id: number;
  username: string;
  external_id: string;
  phone_number: string;
  firstName: string;
  lastName: string;
  covidVaccinationResponse: string;
  covidVaccinationDate: string | null;
  covidExemptionApprovedDate: string | null;
  covidTestingLatestDate: string | null;
  covidTestingOptInDate: string | null;
  profilePic: null | string;
  shiftsWorked: number;
  lastWorkedDate: string | null;
  lastWorkedPositionTitle: string | null;
};

export const getCrewMembers = async (): Promise<GetCrewMembersResultType[]> =>
  apiV1.get("routes/company-workers-slim?excludeDsb=true").json();

type CrewGroupUserType = {
  external_id: string;
  first_name: string;
  hide: number | null;
  last_name: string;
  last_shift: string | null;
  points: number;
  profile_picture_url: null | string;
  shifts_worked: number;
  user_id: number;
};

type NonFavoriteCrewGroupType = {
  createdAt: string;
  id: number;
  name: string;
  users: Array<CrewGroupUserType>;
};

type FavoriteCrewGroupType = {
  createdAt: null;
  id: null;
  name: "favorites";
  users: Array<CrewGroupUserType>;
};

export type GetCrewGroupsQueryResultType = Array<FavoriteCrewGroupType | NonFavoriteCrewGroupType>;

export const getCrewGroups = (): Promise<GetCrewGroupsQueryResultType> =>
  apiV1.get("company/crew/get?getFavorites=true").json();
