import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
};

const FormFlowContainer = (props: Props) => {
  const { children } = props;

  return <StyledContainer>{children}</StyledContainer>;
};

const StyledContainer = styled("div")`
  margin-top: 46px;
  margin-bottom: 46px;
  width: 100% !important;
`;

export default FormFlowContainer;
