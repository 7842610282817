import { styled } from 'baseui';
import React, { ReactNode, useCallback, useState } from 'react';
import { themedStyled } from '../theme';

import { UserInitials, AvatarSizes, AvatarSizesList } from './UserInitials';

type AvatarProps = {
  backgroundColor?: string;
  customLabel?: string | ReactNode;
  fullName?: string;
  imgSrc?: string;
  isNew?: boolean;
  noBorder?: boolean;
  size?: AvatarSizesList;
};

type AvatarWrapperProps = {
  $backgroundColor?: string;
  $noBorder?: boolean;
  $size: AvatarSizesList;
};

export const UserAvatar = ({
  imgSrc,
  fullName,
  size = 'md',
  customLabel,
  backgroundColor,
  isNew = false,
  noBorder = false,
}: AvatarProps) => {
  const [loadError, setLoadError] = useState(false);
  const onErrorHandler = useCallback(() => setLoadError(true), []);

  const imageAvailable = imgSrc && !loadError;

  return (
    <AvatarWrapper
      $size={size}
      $backgroundColor={backgroundColor}
      $noBorder={noBorder}
    >
      {imageAvailable ? (
        <>
          <StyledAvatar
            alt={fullName}
            src={imgSrc}
            title={fullName}
            onError={onErrorHandler}
          />
        </>
      ) : (
        <UserInitials
          size={size}
          fullName={fullName}
          customLabel={customLabel}
        />
      )}
      {isNew && <NewUserLabel>New</NewUserLabel>}
    </AvatarWrapper>
  );
};

const NewUserLabel = themedStyled('div', ({ $theme }) => ({
  width: $theme.sizing.scale950,
  ...$theme.typography.font100,
  fontSize: $theme.sizing.scale400,
  color: $theme.colors.white,
  backgroundColor: $theme.colors.ultramarine,
  textTransform: 'uppercase',
  borderRadius: $theme.sizing.scale100,
  textAlign: 'center',
  position: 'absolute',
  bottom: 0,
  right: 0,
}));

const AvatarWrapper = themedStyled<'div', AvatarWrapperProps>(
  'div',
  ({ $theme, $size, $backgroundColor, $noBorder }) => ({
    width: `${AvatarSizes[$size]}px`,
    height: `${AvatarSizes[$size]}px`,
    backgroundColor:
      $backgroundColor ?? $theme.colors.userAvatarBackgroundColor,
    ...(!$noBorder && {
      border: `${$theme.sizing.scale0} solid ${$theme.colors.white}`,
    }),
    borderRadius: '100%',
    boxSizing: 'content-box', // on designs borders are outside of sized area
    position: 'relative',
  }),
);

const StyledAvatar = styled('img', () => ({
  width: '100%',
  height: '100%',
  borderRadius: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
}));
