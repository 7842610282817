import React, { useEffect, useState } from "react";
import {
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  useThemedStyletron,
  Input,
  SelectedIcon,
  UnselectedIcon,
} from "@bluecrew/blueprint-web";
import { KIND, SIZE } from "baseui/button";
import { useController } from "react-hook-form";
import { LabelMedium } from "baseui/typography";
import { FieldRowWrapper } from "../FieldRowWrapper";
import { FieldLabel } from "../FieldLabel";
import { makeObservable } from "./observable";
import { ErrorMessage } from "../ErrorMessage";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { WorksiteAdvancedControlProps } from "../types/propTypes/PositionDetailsEdit.types";
import {
  EditPositionGeofence,
  MAX_RADIUS_VALUE_KM,
  MIN_BLOCK_RADIUS_M,
  MIN_BLOCK_WARN_RADIUS_DIFF_M,
  MIN_RADIUS_VALUE_M,
} from "./EditPositionGeofence";
import { ListItem, ListItemContainer, Text, InputWrapper } from "../../styledComponents";

enum DistanceUnit {
  METERS = "meters",
  KILOMETERS = "km",
}

enum RadiusUnit {
  WARN,
  BLOCK,
}

const warnRadiusUnitStore = makeObservable(DistanceUnit.METERS);
const blockRadiusUnitStore = makeObservable(DistanceUnit.METERS);

const kmToM = (km: number) => km * 1000;
const mToKm = (m: number) => m / 1000;
const MIN_VALID_RADIUS = 0;

export const WorksiteAdvancedControl = ({
  workplaceLocation,
  defaultWarnRadius,
  defaultBlockRadius,
  control,
  trigger,
}: WorksiteAdvancedControlProps) => {
  const [, theme] = useThemedStyletron();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [warnRadiusUnit, setWarnRadiusUnit] = useState<DistanceUnit>(DistanceUnit.METERS);
  const [blockRadiusUnit, setBlockRadiusUnit] = useState<DistanceUnit>(DistanceUnit.METERS);

  const WARN_RADIUS_FIELD = "minWarnRadius";
  const BLOCK_RADIUS_FIELD = "minBlockRadius";
  const validateWarnRadius = trigger.bind(null, WARN_RADIUS_FIELD);
  const validateBlockRadius = trigger.bind(null, BLOCK_RADIUS_FIELD);

  const validateRadii = () => {
    if (warnRadius < MIN_RADIUS_VALUE_M) {
      return `Radius value must be at least ${MIN_RADIUS_VALUE_M}m`;
    }
    if (blockRadius < MIN_BLOCK_RADIUS_M) {
      return `Block radius must be at least ${MIN_BLOCK_RADIUS_M}m`;
    }
    if (warnRadius > MAX_RADIUS_VALUE_KM || blockRadius > MAX_RADIUS_VALUE_KM) {
      return `Radius value must be at most ${MAX_RADIUS_VALUE_KM}m`;
    }
    if (blockRadius < warnRadius + MIN_BLOCK_WARN_RADIUS_DIFF_M) {
      return `Block radius must be smaller than warn radius with a minimum difference of ${MIN_BLOCK_WARN_RADIUS_DIFF_M}m`;
    }
    return undefined;
  };

  const {
    field: { onChange: onChangeWarnRadius, value: warnRadius },
    meta: { invalid: invalidWarnRadius, isDirty: isDirtyWarnRadius },
  } = useController({
    name: WARN_RADIUS_FIELD,
    control,
    rules: {
      required: true,
      validate: validateRadii,
    },
  });

  const {
    field: { onChange: onChangeBlockRadius, value: blockRadius },
    meta: { invalid: invalidBlockRadius, isDirty: isDirtyBlockRadius },
  } = useController({
    name: BLOCK_RADIUS_FIELD,
    control,
    rules: {
      required: true,
      validate: validateRadii,
    },
  });

  const handleWarnRadiusChange = (val: number | undefined) => {
    if (val && val >= MIN_VALID_RADIUS && val <= MAX_RADIUS_VALUE_KM) {
      onChangeWarnRadius(Math.floor(val));
    }
  };

  const handleBlockRadiusChange = (val: number | undefined) => {
    if (val && val >= MIN_VALID_RADIUS && val <= MAX_RADIUS_VALUE_KM) {
      onChangeBlockRadius(Math.floor(val));
    }
  };

  useEffect(() => {
    warnRadiusUnitStore.subscribe(setWarnRadiusUnit);
    blockRadiusUnitStore.subscribe(setBlockRadiusUnit);
  }, []);

  useEffect(() => {
    if (isDirtyWarnRadius || isDirtyBlockRadius) {
      validateWarnRadius();
      validateBlockRadius();
    }
  }, [isDirtyWarnRadius, isDirtyBlockRadius, blockRadius, warnRadius]);

  useEffect(() => {
    if (defaultWarnRadius && defaultBlockRadius) {
      onChangeWarnRadius(defaultWarnRadius);
      onChangeBlockRadius(defaultBlockRadius);
    }
  }, [defaultWarnRadius, defaultBlockRadius]);

  const getDistanceSuffix = (unit: DistanceUnit) => {
    if (unit === DistanceUnit.KILOMETERS) {
      return "km";
    }
    return "m";
  };

  const getUnitAdjustedDisplay = (unit: DistanceUnit, val: number) => {
    if (unit === DistanceUnit.KILOMETERS) {
      return mToKm(val).toString();
    }
    return val.toString();
  };

  const unitAdjusted = (unit: DistanceUnit, rawVal: string, defaultVal: number | undefined) => {
    if (rawVal === "") {
      return MIN_VALID_RADIUS;
    }

    const val = Number(rawVal);
    if (!Number.isNaN(val)) {
      if (unit === DistanceUnit.KILOMETERS) {
        return kmToM(val);
      }
      return val;
    }

    return defaultVal;
  };

  const hasError = invalidWarnRadius || invalidBlockRadius;

  return (
    <>
      <FieldRowWrapper data-pendo-key="WorksiteAdvancedControl">
        <Button
          kind={KIND.minimal}
          width={"100%"}
          size={SIZE.default}
          style={{
            marginLeft: "81px",
            alignItems: "flex-start",
            whiteSpace: "normal",
          }}
          onClick={() => setShowAdvanced(hasError || !showAdvanced)}
          rightIcon={showAdvanced ? ChevronUpIcon : ChevronDownIcon}
          color={theme.colors.accent}
          withPadding={false}
        >
          <div
            style={{
              marginRight: "auto",
              textAlign: "left",
            }}
          >
            <div>Advanced controls</div>
            <Text>
              {`
                Control auto-approval of Crew Member timestamp submissions
                via their GPS location relative to the workplace location.
              `}
            </Text>
          </div>
        </Button>
      </FieldRowWrapper>
      {(showAdvanced || hasError) && (
        <>
          <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
          <FieldRowWrapper>
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale850} />
            <FieldLabel>Warn at</FieldLabel>
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale800} />
            <InputWrapper>
              <Input
                value={getUnitAdjustedDisplay(warnRadiusUnit, warnRadius)}
                error={invalidWarnRadius}
                onChange={(e) =>
                  handleWarnRadiusChange(unitAdjusted(warnRadiusUnit, e, warnRadius))
                }
                endIcon={<>{getDistanceSuffix(warnRadiusUnit)}</>}
              />
            </InputWrapper>
            <DistanceUnitSelector value={warnRadiusUnit} type={RadiusUnit.WARN} />
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale1200} />
            <FieldLabel>Block at</FieldLabel>
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale800} />
            <InputWrapper>
              <Input
                value={getUnitAdjustedDisplay(blockRadiusUnit, blockRadius)}
                error={invalidBlockRadius}
                onChange={(e) =>
                  handleBlockRadiusChange(unitAdjusted(blockRadiusUnit, e, blockRadius))
                }
                endIcon={<>{getDistanceSuffix(blockRadiusUnit)}</>}
              />
            </InputWrapper>
            <DistanceUnitSelector value={blockRadiusUnit} type={RadiusUnit.BLOCK} />
          </FieldRowWrapper>
          <FieldRowWrapper $style={{ marginLeft: "113px" }}>
            {hasError && <ErrorMessage text={validateRadii() || ""} />}
          </FieldRowWrapper>
          <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale800} />
          <FieldRowWrapper $style={{ marginLeft: "113px" }}>
            <EditPositionGeofence
              center={workplaceLocation}
              warnRadius={warnRadius}
              blockRadius={blockRadius}
              onChangeWarnRadius={handleWarnRadiusChange}
              onChangeBlockRadius={handleBlockRadiusChange}
            />
          </FieldRowWrapper>
        </>
      )}
    </>
  );
};

interface DistanceUnitSelectorProps {
  value: DistanceUnit;
  type: RadiusUnit;
}

const DistanceUnitSelector = ({ value, type }: DistanceUnitSelectorProps) => (
  <ListItemContainer>
    {Object.values(DistanceUnit).map((unit) => renderDistanceUnitItem(unit === value, unit, type))}
  </ListItemContainer>
);

const renderDistanceUnitItem = (selected: boolean, unit: DistanceUnit, type: RadiusUnit) => (
  <ListItem onClick={() => updateRadiusUnit(type, unit)}>
    {selected ? <SelectedIcon /> : <UnselectedIcon />}
    <LabelMedium $style={{ fontWeight: 400, paddingLeft: "8px", paddingRight: "8px" }}>
      {unit}
    </LabelMedium>
  </ListItem>
);

const updateRadiusUnit = (radiusType: RadiusUnit, unit: DistanceUnit) => {
  if (radiusType === RadiusUnit.WARN) {
    warnRadiusUnitStore.set(unit);
  } else {
    blockRadiusUnitStore.set(unit);
  }
};
