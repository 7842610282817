import { SagaIterator } from "redux-saga";
import { Effect, call, fork, put, takeLatest } from "redux-saga/effects";
import { logError } from "../../sentry";
import { getUserForIntercom } from "../../api/bluecrew/user";
import intercomActions from "../actions/intercom";
import { bootIntercom } from "../../intercom";

function* intercomBootSaga(): Generator<Effect, void, any> {
  try {
    const userIntercomData = yield call(getUserForIntercom);
    const { intercom } = userIntercomData;
    // Boot Intercom widget and pass user data
    yield fork(bootIntercom, { user: intercom });
  } catch (error: any) {
    yield put(intercomActions.intercomData.failure(error));
    logError(error);
  }
}

export default {
  *watchIntercomBootSaga(): SagaIterator {
    yield takeLatest(intercomActions.intercomData.request.toString(), intercomBootSaga);
  },
};
