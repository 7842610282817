import { Message } from "primereact/message";
import React from "react";
import { WarnExclaim } from "@bluecrew/blueprint-web/src/assets/svg/WarnExclaim";
import { WarningWrapper } from "./styledComponents";
import "./styledComponents.css";

export type MessageWarningProps = {
  messageContent: string;
};

export const MessageWarning = ({ messageContent }: MessageWarningProps) => (
  <WarningWrapper>
    <Message
      className="p-inline-message p-inline-message-info timesheets-message"
      content={
        <>
          <span style={{ verticalAlign: "bottom" }}>
            <WarnExclaim height={"19.2px"} width={"19.2px"} color="#3246f0" />
          </span>
          <span className="timesheets-message-text">{messageContent}</span>
        </>
      }
    />
  </WarningWrapper>
);
