import React from "react";
import PropTypes from "prop-types";
import { CrewMemberCard } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { palette as p } from "styled-tools";

const StyledCrewContainer = styled.div`
  padding: 16px 0 16px 0;
`;

const StyledContainer = styled.div`
  .list-container {
    padding-bottom: 32px;
  }

  .text-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: fit-content;

    :hover {
      cursor: pointer;
    }
  }

  .toggle-text {
    color: ${p("brandBlue")};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

class ViewDeclines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declinesVisible: false,
    };
  }

  handleToggle = () => {
    this.setState({
      declinesVisible:
        // @ts-ignore
        !this.state.declinesVisible,
    });
  };

  render() {
    const {
      // @ts-ignore
      declinesVisible,
    } = this.state;
    const {
      // @ts-ignore
      declinedOffers,
      // @ts-ignore
      timezone,
      // @ts-ignore
    } = this.props;
    const text = declinesVisible ? "Hide" : "View";
    const iconSrc = declinesVisible
      ? "/public/images/icons/blue-up-arrow.svg"
      : "/public/images/icons/blue-down-arrow.svg";

    return (
      <StyledContainer>
        <div className="text-container" onClick={this.handleToggle}>
          <span className="toggle-text">{`${text} declines (${declinedOffers.length})`}</span>
          <img src={iconSrc} alt="" />
        </div>
        {declinesVisible && (
          <div className="list-container">
            {declinedOffers.map((user, i) => (
              <StyledCrewContainer key={`${user.full_name}-${i}`}>
                <CrewMemberCard
                  // @ts-ignore
                  timezone={timezone}
                  crewMember={user}
                  status="declined"
                />
              </StyledCrewContainer>
            ))}
          </div>
        )}
      </StyledContainer>
    );
  }
}

// @ts-ignore
ViewDeclines.propTypes = {
  declinedOffers: PropTypes.array.isRequired,
  timezone: PropTypes.string,
};

export default ViewDeclines;
