import { useController } from "react-hook-form";
import { RESIZE_VALUE, Textarea, useThemedStyletron } from "@bluecrew/blueprint-web";
import React, { useState } from "react";
import { styled } from "baseui";
import { FieldLabelRow } from "./FieldLabelRow";
import { FieldRowWrapper } from "./FieldRowWrapper";
import { errorMsg } from "./validationErrorMessages";
import { RelativeFlexFieldWrapper } from "./RelativeFlexFieldWrapper";
import { FieldContentExample } from "./FieldContentExample/FieldContentExample";
import { ExamplesTitleContainer, ExamplesToggleButton } from "./ExamplesToggleButton";
import { Spacer, SpacerType } from "../../components/Spacer";
import { DressCodeFieldProps } from "./types/propTypes/DressCodeField.types";
import { FieldTextLength } from "./FieldTextLength";

const DRESSCODE_MIN_LENGTH = 5;
const DRESSCODE_MAX_LENGTH = 500;

const dressCodeExamples = [
  {
    id: "3.1",
    text: "Shirt (e.g., short sleeve, long sleeve, specific colors)",
  },
  {
    id: "3.2",
    text: "Pants (e.g., long pants, shorts, specific colors)",
  },
  {
    id: "3.3",
    text: "Shoes (e.g., closed-toe, steel toe)",
  },
  {
    id: "3.4",
    text: (
      <span>
        Uniforms or PPE (e.g., <i>Must wear a standard, company shirt)</i>
      </span>
    ),
  },
  {
    id: "3.5",
    text: "Masks (e.g., must wear masks)",
  },
  {
    id: "3.6",
    text: "Not allowed (e.g., logos, torn cloths, offensive slogans, jewelry)",
  },
  {
    id: "3.7",
    text: "No visible tattoos",
  },
];

export const DressCodeField = ({
  control,
  trigger,
  disabled = false,
  examplesEnabled = false,
}: DressCodeFieldProps) => {
  const [examplesVisible, setExamplesVisible] = useState(false);
  const fieldName = "dressCode";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value: unsafeValue },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      minLength: {
        value: DRESSCODE_MIN_LENGTH,
        message: errorMsg("min", DRESSCODE_MIN_LENGTH),
      },
      maxLength: {
        value: DRESSCODE_MAX_LENGTH,
        message: errorMsg("max", DRESSCODE_MAX_LENGTH),
      },
    },
  });

  // NOTE: the types for react hook form are terrible, so overriding them for safety
  const value = typeof unsafeValue === "string" ? unsafeValue : "";

  const handleChange = (instructions: string) => {
    onChange(instructions);
  };

  const [, theme] = useThemedStyletron();
  const [fieldFocus, onFieldFocus] = useState(false);

  return (
    <RelativeFlexFieldWrapper data-pendo-key="DressCodeEditField">
      <FieldRowWrapper>
        <ExamplesTitleContainer>
          <FieldLabelRow>Dress Code</FieldLabelRow>
          {examplesEnabled && (
            <ExamplesToggleButton onClick={() => setExamplesVisible(!examplesVisible)}>
              Suggestions
            </ExamplesToggleButton>
          )}
        </ExamplesTitleContainer>
        <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale600} />
        <InputWrapper>
          <Textarea
            value={value}
            error={invalid}
            onChange={(text) => handleChange(text)}
            onFocus={() => onFieldFocus(true)}
            onBlur={() => {
              onFieldFocus(false);
              validate();
            }}
            disabled={disabled}
            placeholder="Optional"
            resize={RESIZE_VALUE.vertical}
            styles={{ minHeight: "178px" }}
          />
          <FieldTextLength
            focused={fieldFocus}
            error={invalid}
            minLength={DRESSCODE_MIN_LENGTH}
            maxLength={DRESSCODE_MAX_LENGTH}
            length={value.length}
          />
        </InputWrapper>
      </FieldRowWrapper>
      <FieldContentExample examples={dressCodeExamples} examplesVisible={examplesVisible} />
    </RelativeFlexFieldWrapper>
  );
};

const InputWrapper = styled("div", () => ({ width: "502.82px" }));
