import React from "react";
import { UserType } from "../../../api/bluecrew/types";
import { GenericCell } from "../../positions/DashboardSchedulesAndIndividualShiftsTable/GenericCell";
import { ReactTableComponent } from "../../positions/JobRequestModal/ReactTableComponent";
import { compareDateTime } from "../../positions/SharedFunctions";
import { CreatedByCell } from "./CreatedByCell";
import { RequestLogTableContainer } from "./SharedComponents";

interface Poster {
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  username: string;
  type: UserType;
  is_internal_employee: boolean;
}

export interface RequestLog {
  direct_hire: number;
  end_date: string;
  end_date_time: string;
  external_id: string;
  filled: number;
  id: number;
  is_application: number;
  poster: Poster;
  request: number;
  request_date: string;
  request_date_time: string;
  rosterAndWaitlist: any;
  start_date: string;
  start_date_time: string;
}

interface RequestLogTableProps {
  data: RequestLog[];
}

export const RequestLogTable = ({ data }: RequestLogTableProps) => {
  const fillStatusSort = (a, b) => {
    const valueA = a.original.filled / a.original.request;
    const valueB = b.original.filled / b.original.request;
    if (valueA > valueB) {
      return 1;
    }
    if (valueA < valueB) {
      return -1;
    }
    return 0;
  };

  const columns = React.useMemo(
    () => [
      {
        id: "job-id",
        Header: "Job ID",
        className: "job-id",
        accessor: "id",
      },
      {
        id: "fill",
        Header: "Fill",
        className: "fill",
        sortType: fillStatusSort,
        accessor: "filled",
        Cell: ({ rowData }: { rowData: RequestLog }) => (
          <GenericCell value={`${rowData.filled}/${rowData.request}`} justifyContent={"left"} />
        ),
      },
      {
        id: "direct-invite",
        Header: "Direct Invite",
        className: "direct-invite",
        accessor: "direct_hire",
        Cell: ({ rowData }: { rowData: RequestLog }) => (
          <GenericCell
            value={rowData.direct_hire && rowData.direct_hire > 0 ? "Yes" : "No"}
            justifyContent={"left"}
          />
        ),
      },
      {
        id: "start-date",
        Header: "Start Date",
        className: "start-date",
        accessor: "start_date_time",
        Cell: ({ rowData }: { rowData: RequestLog }) => (
          <GenericCell value={rowData.start_date} justifyContent={"left"} />
        ),
        sortType: compareDateTime,
      },
      {
        id: "end-date",
        Header: "End Date",
        className: "end-date",
        accessor: "end_date_time",
        Cell: ({ rowData }: { rowData: RequestLog }) => (
          <GenericCell value={rowData.end_date} justifyContent={"left"} />
        ),
        sortType: compareDateTime,
      },
      {
        id: "request-date",
        Header: "Request Date",
        className: "request-date",
        accessor: "request_date_time",
        Cell: ({ rowData }: { rowData: RequestLog }) => (
          <GenericCell value={rowData.request_date} justifyContent={"left"} />
        ),
        sortType: compareDateTime,
      },
      {
        id: "created-by",
        Header: "Created By",
        className: "created-by",
        accessor: "poster.full_name",
        Cell: ({ rowData }: { rowData: RequestLog }) => <CreatedByCell rowData={rowData} />,
      },
    ],
    [],
  );

  return (
    <RequestLogTableContainer>
      <ReactTableComponent
        noRowsMessage="No crew members added to job request yet"
        columns={columns}
        data={data}
      />
    </RequestLogTableContainer>
  );
};
