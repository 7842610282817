module.exports = {
  i9ResendReasons: [
    {
      value: 0,
      description: "Blurry",
      message: `Your ID upload was hard to read, please open your app https://app.bluecrewjobs.com/app.html#!/signup, head to the account page under the New Hire Paperwork tab, carefully read the directions, and re-upload your 2 forms of ID`,
    },
    {
      value: 1,
      description: "Wrong Form",
      message: `The combination of IDs for your I9 is incorrect, please open your app https://app.bluecrewjobs.com/app.html#!/signup, head to the account page under the New Hire Paperwork tab, carefully read the directions, and re-upload your 2 forms of ID`,
    },
    {
      value: 2,
      description: "Expired Documents",
      message: `Your ID has expired, please open your app https://app.bluecrewjobs.com/app.html#!/signup, head to the account page under the New Hire Paperwork tab, carefully read the directions, and re-upload your 2 forms of ID`,
    },
    {
      value: 3,
      description: "Photo Does not match",
      message: `Your ID upload was hard to read, please open your app https://app.bluecrewjobs.com/app.html#!/signup, head to the account page under the New Hire Paperwork tab, carefully read the directions, and re-upload your 2 forms of ID`,
    },
    {
      value: 4,
      description: "Missing Profile Photo",
      message: `You have not uploaded a profile photo of Bluecrew so we cannot validate your ID, please open your app https://app.bluecrewjobs.com/app.html#!/signup, head to the account page under the New Hire Paperwork tab, carefully read the directions, and re-upload your 2 forms of ID`,
    },
    {
      value: 5,
      description: "Other",
      message: `Your ID upload was not properly submitted, please open your app https://app.bluecrewjobs.com/app.html#!/signup, head to the account page under the New Hire Paperwork tab, carefully read the directions, and re-upload your 2 forms of ID`,
    },
  ],
};
