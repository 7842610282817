import React from "react";
import { Link } from "@bluecrew/web-react-core";
import { ScheduleType } from "../../../GetCrewMembers/types/propTypes/Schedule.types";
import {
  DashboardPositionJob,
  DashboardPositionJobTypes,
  DashboardPositionRepeatingSchedule,
} from "../../../../api/bluecrew/types";
import { ActionsContainer, InviteUserButton, StyledDivider } from "../../SharedComponents";
import { Can } from "../../../PermissionsContext";
import { TooltipMessage } from "../../../../components/TooltipMessage";
import {
  useNavigateToHandleRequestCrewPage,
  useNavigateToScheduleDetailPage,
  useNavigateToScheduleEditPage,
} from "../../navigationHooks";
import DropDownMenu, { DropDownTypes } from "../../../../components/DropDrownMenu";
import { DropdownCancelButton } from "./DropdownCancelButton";

interface ScheduleActionsButtonCellProps {
  rowData: DashboardPositionRepeatingSchedule | DashboardPositionJob;
  updateCancelSchedule: React.Dispatch<
    React.SetStateAction<{
      jobExternalIds: string[];
      show: boolean;
      type: DashboardPositionJobTypes;
      scheduleId: number;
      workerShifts: number;
    }>
  >;
  postJobsPermission: boolean;
}

export const ScheduleActionsButtonCell = ({
  rowData,
  updateCancelSchedule,
  postJobsPermission,
}: ScheduleActionsButtonCellProps) => {
  const navigateToScheduleDetailPage = useNavigateToScheduleDetailPage();
  const navigateToScheduleEditPage = useNavigateToScheduleEditPage();
  const navigateToHandleRequestCrewPage = useNavigateToHandleRequestCrewPage();

  const onRequestCrewClick = () => {
    const schedule_type =
      rowData.type === DashboardPositionJobTypes.repeatingSchedule
        ? ScheduleType.REPEATING
        : ScheduleType.SINGLE_SHIFT;
    navigateToHandleRequestCrewPage(
      rowData.schedule_id,
      rowData.militaryStartTime,
      rowData.militaryEndTime,
      schedule_type,
    );
  };

  return (
    <ActionsContainer>
      <Can I="post" a="company_jobs">
        <Can I="create" a="job">
          <div data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnRequestAdditionalCrewButton">
            <TooltipMessage message="Request additional crew">
              <InviteUserButton
                onClick={() => {
                  onRequestCrewClick();
                }}
              />
            </TooltipMessage>
          </div>
        </Can>
      </Can>
      <div data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnRequestActionsDropdown">
        <DropDownMenu
          buttonText=""
          buttonPalette="secondary"
          borderless
          displayType={DropDownTypes.THREE_DOT_NO_BOX}
          canPostJobs={postJobsPermission ? "canPostJobs" : ""}
        >
          <Link
            onClick={() => {
              navigateToScheduleDetailPage(rowData.schedule_id);
            }}
          >
            View details
          </Link>
          <Can I="update" a="schedule">
            <Link
              data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnDropdownChangeSupervisor"
              onClick={() => {
                navigateToScheduleEditPage(rowData.schedule_id);
              }}
            >
              Change supervisor
            </Link>
          </Can>
          <Can I="post" a="company_jobs">
            <Can I="delete" a="schedule">
              <StyledDivider />
              <DropdownCancelButton rowData={rowData} updateCancelSchedule={updateCancelSchedule} />
            </Can>
          </Can>
        </DropDownMenu>
      </div>
    </ActionsContainer>
  );
};
