import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const DollarSign = ({ width, height, color }: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 22"
      fill={svgColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0176 9.65555C4.24316 8.93444 3.35094 8.18889 3.35094 7.02778C3.35094 5.69556 4.58538 4.76667 6.65094 4.76667C8.82649 4.76667 9.63316 5.80556 9.70649 7.33333H12.4076C12.322 5.23111 11.0387 3.3 8.48427 2.67667V0H4.8176V2.64C2.44649 3.15333 0.539824 4.69333 0.539824 7.05222C0.539824 9.87556 2.87427 11.2811 6.28427 12.1C9.33982 12.8333 9.95093 13.9089 9.95093 15.0456C9.95093 15.8889 9.35205 17.2333 6.65094 17.2333C4.13316 17.2333 3.14316 16.1089 3.00871 14.6667H0.319824C0.466491 17.3433 2.47094 18.8467 4.8176 19.3478V22H8.48427V19.3722C10.8676 18.92 12.762 17.5389 12.762 15.0333C12.762 11.5622 9.79205 10.3767 7.0176 9.65555Z"
        fill={svgColor}
      />
    </svg>
  );
};
