import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { useResendAccountInvite } from "../../../../api/bluecrew/hooks/user";
import { ConfirmResendInviteModal } from "./ConfirmResendInviteModal";
import QueryKeys from "../../../../api/bluecrew/hooks/queryKeys";
import { SuccessFailureModal } from "../../../../components/SuccessFailureModal/SuccessFailureModal";
import { logError } from "../../../../sentry";
import { ModalContent } from "../../../../components/SuccessFailureModal/styledComponents";
import { run } from "../../../../utility/misc";

const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    gap: 5px;
  }
`;

const StyledList = ({ children }: { children: React.ReactNode }) => (
  <StyledListWrapper>
    <ul>{children}</ul>
  </StyledListWrapper>
);

export type ResendAccountInviteProps = {
  users: { email: string }[];
  setIsMounted: (value: boolean) => void;
};

export const resendInviteSuccesStrings = {
  successIntro: "Succesfully resent invites for the following users:",
  failureIntro: "Failed to resend invites for the following users:",
};

export const ResendAccountInvite = ({ users, setIsMounted }: ResendAccountInviteProps) => {
  const [resendInviteModalIsVisible, setResendInviteModalIsVisible] = useState(true);
  const queryClient = useQueryClient();

  const { mutateAsync: resendAccountInvite, isLoading } = useResendAccountInvite();

  const SuccessModalDefaults = { isVisible: false, isSuccess: true, title: "" };
  const [succesModalState, setSuccessModalState] =
    useState<typeof SuccessModalDefaults>(SuccessModalDefaults);
  const { isSuccess, isVisible: successModalIsVisible, title } = succesModalState;

  const [successfulResends, setSuccessfulResends] = useState<string[]>([]);
  const [failedResends, setFailedResends] = useState<{ email: string; errorMsg: any }[]>([]);

  const onConfirm = async () => {
    const successes: string[] = [];
    const failures: { email: string; errorMsg: string }[] = [];

    const promises = users.map((user) =>
      resendAccountInvite({ email: user.email })
        .then(() => successes.push(user.email))
        .catch(async (error) => {
          // If error is an HttpError, the body will have a more descriptive
          // error message than the root error, so try to read it
          const bodyMsg = await run(async () => {
            /**
             * We could import ky.HTTPError here, and test if error is instanceof it,
             * but then when running Jest tests, importing from ky breaks the tests.
             *
             * We could import from the umd module, but that seems to break the instanceof comparison.
             *
             * Hence, just basic object property existance checking;
             * If error has a response obj, and that has a `json` function, it's probably an HTTPError object
             */
            if (typeof error?.response?.json === typeof Function) {
              const { message }: { message: string } = await error.response.json();
              return message;
            }

            return null;
          });
          const errorMsg = bodyMsg || error.message;
          failures.push({ email: user.email, errorMsg });
          logError({ error: Error(errorMsg), context: "Resending account invitation" });
        }),
    );
    await Promise.all(promises);

    setSuccessfulResends(successes);
    setFailedResends(failures);

    const successTitle: string = run(() => {
      if (successes.length > 0 && failures.length > 0) {
        return "Partial Success";
      }
      if (failures.length === 0) {
        return "Success";
      }
      if (successes.length === 0) {
        return "Failure";
      }
      return "";
    });

    setResendInviteModalIsVisible(false);
    setSuccessModalState({
      isVisible: true,
      isSuccess: successTitle !== "Failure",
      title: successTitle,
    });
  };

  return (
    <>
      {resendInviteModalIsVisible && (
        <ConfirmResendInviteModal
          users={users}
          resendIsLoading={isLoading}
          onConfirm={onConfirm}
          visible
          setModalIsVisible={setIsMounted}
        />
      )}
      {successModalIsVisible && (
        <SuccessFailureModal
          visible
          title={title}
          isSuccess={isSuccess}
          onButtonClick={() => {
            // This triggers a reload the users table, so that signup status gets refreshed
            queryClient.invalidateQueries({ queryKey: [QueryKeys.ManageUsers_Users] });
            setSuccessModalState({ ...succesModalState, isVisible: false });
            setIsMounted(false);
          }}
        >
          {successfulResends.length > 0 && (
            <ModalContent>{resendInviteSuccesStrings.successIntro}</ModalContent>
          )}
          <StyledList>
            {successfulResends.map((email) => (
              <li key={email}>{email}</li>
            ))}
          </StyledList>
          {failedResends.length > 0 && (
            <ModalContent>{resendInviteSuccesStrings.failureIntro}</ModalContent>
          )}
          <StyledList>
            {failedResends.map((item) => (
              <li key={item.email}>
                {`${item.email} - `}
                <span style={{ color: "red" }}>{`${item.errorMsg}`}</span>
              </li>
            ))}
          </StyledList>
        </SuccessFailureModal>
      )}
    </>
  );
};
