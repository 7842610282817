import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ErrorRedirect from "./components/ErrorRedirect";
import { logError } from "./sentry";

const ErrorBoundaryWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    logError({ error, context: "ErrorBoundary" });
    return { hasError: true };
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      return (
        <ErrorBoundaryWrapper>
          <ErrorRedirect />
        </ErrorBoundaryWrapper>
      );
    }

    return this.props.children;
  }
}

// @ts-ignore
ErrorBoundary.propTypes = {
  children: PropTypes.any,
};
