import { Supervisor, SupervisorsListItem } from "../types/propTypes/SupervisorField.types";

export class SupervisorMapper {
  static toListItem = (supervisor: Supervisor, selected: boolean = false): SupervisorsListItem => ({
    id: supervisor.id,
    title: supervisor.name,
    imgUrl: supervisor.imgUrl,
    selected,
  });

  static toModel = (listItem: SupervisorsListItem): Supervisor => ({
    id: listItem.id,
    name: listItem.title,
    imgUrl: listItem.imgUrl,
  });
}
