import styled from "styled-components";
import { sizes } from "@bluecrew/web-react-core";
import { InputText as PRInputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { assetUrl } from "../../../api/bluecrew/constants";
import { ASSIGN_MODAL_WIDTH } from "../constants";

export const UserName = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray800};
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  width: 352px;
  height: 36px;
`;

export const EmailAddress = styled.div`
  color: ${({ theme }) => theme.colorTokens.blue400};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const InputText = styled(PRInputText)`
  width: 431px;
  height: 48px;
  border: 1px solid #babbc4;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colorTokens.gray700};
  padding: 0.75rem 0.75rem;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
`;

export const SectionDivider = styled.hr`
  border: none;
  border-top: 1px solid #ccc; // Change color as needed
  margin: 10px 0; // Change margin as needed
`;

export const AssignCompaniesCheckbox = styled.div<{ isChecked: boolean }>`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme, isChecked }) =>
    isChecked ? theme.colorTokens.gray700 : theme.colorTokens.gray500};
  padding: 0 5px;
  padding-top: 6px;
  width: 416px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorTokens.aqua100};
    border-radius: 5px;
    div {
      background-image: url(${assetUrl}/icons/ManageUsers/${({ isChecked }) =>
  isChecked ? "assign-companies-remove-icon.svg" : "assign-companies-add-icon.svg"});
    }
  }
`;

export const AddButton = styled.button`
  background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-add-icon.svg
  background-repeat: no-repeat;
  background-position: center;
`;

export const RemoveButton = styled.button`
  background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-remove-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
`;
//

export const BulkAssignCompaniesCheckbox = styled.div<{
  shouldShowRemoveIcon: boolean;
}>`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme, shouldShowRemoveIcon }) =>
    shouldShowRemoveIcon ? theme.colorTokens.gray700 : theme.colorTokens.gray500};
  padding: 0 5px;
  padding-top: 6px;
  width: 416px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colorTokens.aqua100};
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    div {
      ${(
    { shouldShowRemoveIcon }, 
  ) =>
    !shouldShowRemoveIcon &&
        `background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-add-icon.svg);`}
      ${(
    { shouldShowRemoveIcon }, 
  ) =>
    shouldShowRemoveIcon &&
        `background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-remove-icon.svg);`}
    }
`;

export const BulkAssignCompaniesIcon = styled.div<{
  isAllAssigned: boolean;
  isPartialAssigned: boolean;
}>`
  width: 16px;
  height: 16px;
  ${({ isAllAssigned, isPartialAssigned }) =>
    isPartialAssigned
      ? `background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-shared-companies.svg)`
      : isAllAssigned &&
        `background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-checkmark-icon.svg)`};
`;

export const AssignCompaniesIcon = styled.div<{ isChecked?: boolean }>`
  width: 16px;
  height: 16px;
  ${({ isChecked }) =>
    isChecked &&
    `background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-checkmark-icon.svg)`};
`;

export const AssignCompaniesSharedCompaniesIcon = styled.div<{ isChecked?: boolean }>`
  width: 16px;
  height: 16px;
  ${({ isChecked }) =>
    isChecked &&
    `background-image: url(${assetUrl}/icons/ManageUsers/assign-companies-shared_companies.svg)`};
`;

export const UserInfoContainer = styled.div`
  padding-left: 16px;
`;

export const AssignCompaniesBodyContainter = styled.div`
  padding: 16px 24px;
`;

export const CompaniesListingContainer = styled.div`
  margin-top: 12px;
  overflow-y: auto;
  width: 431px;
  height: 284px;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colorTokens.gray200};
    border-radius: 2px;
  }
`;

export const CompaniesAssigned = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 8px;
  width: 431px;
`;

export const PhoneNumber = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray700};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

export const RowAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
`;

export const CancelButton = styled(Button)`
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: ${({ theme }) => theme.colorTokens.white};
  color: ${({ theme }) => theme.colorTokens.blue400};
  border-color: transparent;
  margin-right: 16px;
`;

export const UpdateButton = styled(Button)`
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
  margin-right: 24px;
`;
export const AssignCompaniesModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  justify-content: right;
`;

// To get the pictures/initials overlapping and one on top of the other, each avatar (picture or initials)
// needed to be positioned absolutely within a parent container (the header).
// The user avatar in the styledComponents in ManageUsersTable has the components that manage pictures and initials
// combined into one but they're styled differently and display differently when next to each other.
// StyledBase was created to solve that.
const StyledBase = styled.div<{ size: string; index: number }>`
  width: ${(props) => sizes[props.size]}px;
  height: ${(props) => sizes[props.size]}px;
  border: none;
  border-radius: 50%;
  top: 20px;
  position: absolute;
  background: ${({ theme }) => theme.colorTokens.aqua100};
  left: ${({ index }) => index * 35 + 20}px;
  z-index: index;
  > span {
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    color: ${({ theme }) => theme.colorTokens.gray800} !important;
  }
`;

export const StyledAvatar = styled(StyledBase)<{ src: string }>`
  background: ${({ src }) => `url(${src}) no-repeat center center`};
  background-size: cover;
  object-fit: cover;
`;

export const StyledInitials = styled(StyledBase)<{ fullName: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const BulkNames = styled.span`
  color: ${({ theme }) => theme.colorTokens.gray800};
  font-weight: 400;
  position: relative;
  font-size: 15px;
  letter-spacing: -0.01em;
  text-align: left;
`;

export const HeaderContainer = styled.div`
  width: ${ASSIGN_MODAL_WIDTH}px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: space-between;
`;

export const AvatarColumn = styled.div`
  flex: 1;
  max-width: 50%;
`;

export const NamesColumn = styled.div`
  flex: 1;
  max-width: 50%;
  align-items: left;
  justify-content: left;
  padding-top: 20px;
  line-height: 1.5;
  display: flex;
`;
