import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Row } from "react-styled-flexboxgrid";

export const StyledRow = styled(Row)`
  border-bottom: 1px solid ${p("aluminum")};
  height: 4rem;
  .account-dropdown {
    padding-right: 14px;
  }
  .company-name {
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .bluecrew-logo {
    padding-left: 24px;
    padding-top: 17px;
    padding-bottom: 16px;
  }
  .small-logo {
    display: none;
  }
  .nav-menu-container {
    font-size: 14px
    justify-content: center;
    display: flex;
  }
  .styled-button {
    width: 101px;
  }
  .right-pane {
    flex-wrap: nowrap;
  }
  @media only screen and (max-width: 1200px) {
    .icon-area {
      max-width: 0;
    }
    .bluecrew-logo {
      display: none;
    }
    .small-logo {
      display: block;
      padding-left: 24px;
      padding-top: 17px;
      padding-bottom: 16px;
      max-width: 80px;
    }
    .button-area {
      margin-left: auto;
    }
  }
`;
