import { Auth } from "aws-amplify";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { DependencyList, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { CognitoUser } from "amazon-cognito-identity-js";
import authActions from "../../redux/actions/auth";
import { useAppSelector } from "../../redux";
import { getUserCompany } from "../../redux/selectors/auth";
import companyActions, { CheckCompanyPayload } from "../../redux/actions/company";

/**
 * Hook which returns a function that can be used to log a user out.
 */
export const useLogout = () => {
  const dispatch = useDispatch();
  const logoutUser = () => dispatch(authActions.logout.request());

  const logout = async () => {
    // Call "Amplify" signout function to notify the Amplify auth context provider
    // that the user is no longer signed in
    await Auth.signOut();

    // Trigger the old logout sagas, which take care of clearing cookies, local storage etc
    logoutUser();
  };

  return logout;
};

/**
 * Attempt to refresh the current user's session.
 * If session is still valid, nothing happens.
 * If session is expired and can be refreshed, it is refreshed.
 * If session is expired and connot be refreshed, user is logged out.
 *
 * After a succesful session check or refresh, the user's company is checked, and
 */
export const useCheckSessionOrRefreshAuth = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (message: string) => void;
} = {}) => {
  const dispatch = useDispatch();
  const logout = useLogout();
  const userCompanyId = useAppSelector(getUserCompany);
  const checkCompany = (payload: CheckCompanyPayload) =>
    dispatch(companyActions.checkCompany(payload));

  // onSuccess: () => checkCompany({ existingCompanyId: userCompanyId }),
  // onError: () => logout(),
  return useMutation(
    ["refreshUserAuthentication"],
    // NOTE: Auth.currentSession() will automatically refresh the accessToken
    // and idToken if tokens are expired and a valid refreshToken is present.
    // Also note: It will only make a remote call if the access and id tokens are expired
    () => Auth.currentSession(),
    {
      retry: false, // Don't retry if auth refresh call fails
      onSuccess: () => {
        onSuccess && onSuccess();
        checkCompany({ existingCompanyId: userCompanyId });
      },
      onError: (error: Error) => {
        // If session is invalid, and refresh auth call fails, logout user
        onError && onError(error.message);
        logout();
      },
    },
  );
};

/**
 * A hook which can be used to run logic after the window is focused.
 */
export const useOnWindowFocusEffect = (onFocus: () => void, deps?: DependencyList) => {
  useEffect(() => {
    window.addEventListener("focus", onFocus);

    // Remove listener when component is unloaded
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, deps);
};

/**
 * A hook which can be used to watch the users auth status.
 * After a user becomes authenticated, the given callback is run.
 * @returns A cognito user object and the user's current auth status
 */
export const useWatchUserAuthStatus = ({
  postAuthentication,
}: {
  postAuthentication?: (user: CognitoUser) => void;
}) => {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);

  // Watch login status and dispatch the login saga if user becomes authenticated
  // in amplify's eyes. We check for both the user object and authStatus beacuse
  // a user can be defined, but not authenticated: If a user has MFA turned on,
  // the user object will become defined after they enters a correct
  // username + password, but they will still not be authenticated

  useEffect(() => {
    // Amplify types are incomplete; `user` is undefined when no user is logged in
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (user && authStatus === "authenticated") {
      postAuthentication && postAuthentication(user);
    }
  }, [user, authStatus]);

  return { user, authStatus };
};
