import React from "react";
import { ExamplesContainer, ListContainer } from "./styledComponents";

interface ExamplePropType {
  id: string;
  text: string | JSX.Element;
}

interface FieldContentExampleProps {
  examples: ExamplePropType[];
  examplesVisible?: boolean;
}

export const FieldContentExample = ({
  examples,
  examplesVisible = false,
}: FieldContentExampleProps) => (
  <ExamplesContainer>
    {examplesVisible ? (
      <ListContainer>
        {examples.map((example: ExamplePropType) => (
          <li key={example.id}>{example.text}</li>
        ))}
      </ListContainer>
    ) : null}
  </ExamplesContainer>
);
