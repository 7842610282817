import styled from "styled-components";

/**
 * Anchor / `a` tag colored blue.
 */
export const Anchor = styled.a`
  color: ${({ theme }) => theme.colorTokens.blue400};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
