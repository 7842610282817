// Menu Dropdown - VIEW
// =============================================================================
// Component for showing a list of options

import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";

import { Box, Button, Paragraph } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import { assetUrl } from "../api/bluecrew/constants/index";
import { TooltipMessage } from "./TooltipMessage";

export const DropDownTypes = {
  WITH_TEXT: "with-text",
  ARROW_ONLY: "arrow-only",
  THREE_DOT: "three-dot",
  THREE_DOT_NO_BOX: "three-dot-no-box",
};

const Container = styled(Box)`
  position: relative;
  display: block;

  // TODO: BCEMPL-665: button theming
  .menu-toggle {
    cursor: pointer;
    min-width: 34px;
    padding: 0;
  }

  &.with-text {
    .menu-toggle {
      background-image: url(${assetUrl}/down-carat.png);
      background-position: center right 0.7rem;
      background-repeat: no-repeat;
      padding-left: 1rem;
      padding-right: 2rem !important;
    }
  }

  &.arrow-only {
    .menu-toggle {
      width: 2.125rem !important;
      height: 2rem !important;
      border: ${({
        // @ts-ignore
        borderless,
      }) => (borderless ? "none" : "1.5px solid #e1e2e4")};
      background-image: url(${assetUrl}/down-arrow-default.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      display: flex;
      &.canPostJobs {
        border-radius: 0;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:hover,
      &:active {
        border: none;
        box-shadow: none; // Otherwise the arrow becomes gray
        background-color: #34353c;
        background-image: url(${assetUrl}/down-arrow-active.svg);
      }
    }
  }

  &.three-dot {
    .menu-toggle {
      width: 2.125rem !important;
      height: 2rem !important;
      border: ${({
        // @ts-ignore
        borderless,
      }) => (borderless ? "none" : "1.5px solid #e1e2e4")};
      background-image: url(${assetUrl}/PositionBoxHeaderActionsDropdownIcon.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      display: flex;
    }
  }

  &.three-dot-no-box {
    .menu-toggle {
      width: 2.125rem !important;
      height: 2rem !important;
      border: ${({
        // @ts-ignore
        borderless,
      }) => (borderless ? "none" : "1.5px solid #e1e2e4")};
      background-image: url(${assetUrl}/DashboardTableRowDropdownMenuIcon.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: transparent;
      display: flex;
    }
  }
`;

const MenuBody = styled(Box)`
  padding: 20px 0;
  position: absolute;
  z-index: 1000;
  background-color: ${p("white")};
  border-radius: 0.5rem;
  margin-top: 0.25rem;
  text-align: left;
  width: 16.75rem; // TODO: figure out a way to make dropdown menu auto-size
  right: ${(props) =>
    // @ts-ignore
    props.alignRight ? "" : 0};
  border: 1px solid #e7e8ea;
  box-shadow: 0 0.625rem 1.25rem 0 rgba(100, 106, 117, 0.25);

  a,
  hr {
    display: block;
  }

  hr {
    width: 100%;
  }

  a {
    font-size: 16px;
    height: 43px;
    line-height: 43px;
    padding: 0 44px 0 20px;
    cursor: pointer;
    color: ${p("slate")};

    &:hover,
    &:active {
      background-color: ${p("frost")};
      text-decoration: none;
    }
  }

  a:first-child {
    margin-top: 0;
  }

  a:last-child {
    margin-bottom: 0;
  }

  hr {
    color: #ccc;
  }
`;

type Props = {
  alignRight: boolean;
  borderless: boolean;
  buttonText: string;
  displayType: string;
  buttonPalette: string;
  children?: React.ReactNode;
};

type State = {
  visible: boolean;
};

export default class DropDownMenu extends React.Component<Props, State> {
  state = {
    visible: false,
  };

  static defaultProps = {
    alignRight: false,
    canPostJobs: "",
    borderless: false,
    buttonText: "",
    displayType: DropDownTypes.WITH_TEXT,
    buttonPalette: "primary",
  };

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const {
      alignRight,
      borderless,
      buttonText,
      buttonPalette,
      children,
      displayType,
      // @ts-ignore
      canPostJobs,
    } = this.props;
    const { visible } = this.state;
    const menu = this;

    const styleProps = { alignRight, borderless };

    // TODO: BCEMPL-665: button theming - remove
    // TODO: maintain "active" style when menu is open
    const buttonClasses = ["menu-toggle", canPostJobs, buttonPalette].join(" ");

    // merge children's onClick methods w/ toggleVisibility
    const childrenWithProps = React.Children.map(children, (child) => {
      // @ts-ignore
      if (child.props && child.props.onClick) {
        // @ts-ignore
        const oldClickHandler = child.props.onClick;
        const newClickHandler = function (event) {
          menu.toggleVisibility();
          oldClickHandler(event);
        };

        // @ts-ignore
        return React.cloneElement(child, { onClick: newClickHandler });
      }
      return child;
    });

    const Shell = this.state.visible
      ? (
          { children }: { children: React.ReactNode }, // Only render content within an outside click handler if the
        ) => (
          // dropdown menu is visible. Otherwise, every dropdown on the page
          // will be listening to outside click events.
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ visible: false });
            }}
          >
            {children}
          </OutsideClickHandler>
        )
      : React.Fragment;

    return (
      <Shell>
        <div>
          <Container
            // @ts-ignore
            canPostJobs={canPostJobs}
            className={displayType}
            {...styleProps}
          >
            <TooltipMessage message="More actions">
              <Button
                className={buttonClasses}
                data-testid="DropDownMenu-opener"
                palette={buttonPalette}
                onClick={this.toggleVisibility}
              >
                <Paragraph>{buttonText}</Paragraph>
              </Button>
            </TooltipMessage>
            {visible && (
              <MenuBody {...styleProps} data-testid="DropDownMenu-MenuBody">
                {childrenWithProps}
              </MenuBody>
            )}
          </Container>
        </div>
      </Shell>
    );
  }
}
