import React from "react";

interface DatePickerArrowIconProps {
  directionIsLeft: boolean;
}

export const DatePickerArrowIcon = ({ directionIsLeft }: DatePickerArrowIconProps) => (
  <svg
    transform={directionIsLeft ? "rotate(180)" : ""}
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.34 0.54289L15.7071 4.90977C16.0976 5.30029 16.0976 5.93346 15.7071 6.32398L11.34 10.6929C10.9495 11.0835 10.3205 11.0835 9.93 10.6929C9.53948 10.3024 9.53948 9.66345 9.93 9.27293L12.5858 6.61688H0V4.61688H12.5858L9.93 1.96293C9.53948 1.57241 9.53948 0.933421 9.93 0.542896C10.3205 0.152372 10.9495 0.152366 11.34 0.54289Z"
      fill="white"
    />
  </svg>
);
