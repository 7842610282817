import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import MaskedInput from "react-text-mask";
import { useDispatch } from "react-redux";
import { addNewUserSchema, NewUser } from "../../../forms/validation";
import {
  AddNewUserModalFooter,
  AddNewUserModalHeader,
  CancelButton,
  FormContainer,
  Group,
  Input,
  Label,
  PhoneNumberFieldContainer,
  StyledForm,
  StyledModal,
  SubmitButton,
} from "./styledComponents";
import {
  SubmitButtonText,
  UserRoleNames,
  BRANCH_ROLES,
  UserTypeMapping,
  BRANCH_TYPES,
  DUPLICATE_USER_ERROR,
} from "../constants";
import { RoleDropdownField } from "./RoleDropdownField";
import { getUserCompany } from "../../../redux/selectors/auth";
import { StateShape } from "../../../redux/reducers";
import { InternalUser } from "../ManageUsersTable/InternalUser";
import {
  UseCreateAndInviteNewUserError,
  useCreateAndInviteNewUser,
} from "../../../api/bluecrew/hooks/user";
import { convertToNewBranchUserRequest, convertToNewInternalUserRequest } from "./AddNewUserHelper";
import {
  setClonedUser,
  setSelectedUser,
  setShowAssignCompaniesModal,
  setShowAddUserModal,
  setTableLoading,
} from "../slices/manageUsersModalSlice";
import { useAppSelector } from "../../../redux";

const MaskTypes = {
  PHONE: [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
};

const getSubmitButtonText = (selectedRole: string, clonedUser?: InternalUser) => {
  return clonedUser ||
    [UserRoleNames.ADMIN, UserRoleNames.PAYROLL_ADMIN].includes(UserRoleNames[selectedRole])
    ? SubmitButtonText.CREATE_USER
    : SubmitButtonText.ASSIGN_COMPANIES;
};

export type AddNewUserModalProps = {
  handleSuccessModal: (isSuccess: boolean, title: string, text: string) => void;
};

export const AddNewUserModal = ({ handleSuccessModal }: AddNewUserModalProps) => {
  const [selectedRole, setSelectedRole] = useState("");
  const dispatch = useDispatch();
  const showAddUserModal = useAppSelector(
    (state: StateShape) => state.manageUsersModal.showAddUserModal,
  );
  const clonedUser = useAppSelector((state) => state.manageUsersModal.clonedUser);
  const userCompanyId = useAppSelector((state) => getUserCompany(state));
  const [userFirstName, setUserFirstName] = useState("");
  const { mutate: runCreateNewUser } = useCreateAndInviteNewUser({
    onSuccess: (/* newUser */) => {
      handleSuccessModal(true, `${userFirstName} created`, "User has been created successfully");
    },
    onError: (error: UseCreateAndInviteNewUserError) => {
      let errorTitle = "";
      let errorText = "";

      if (error.reason === DUPLICATE_USER_ERROR) {
        errorTitle = "User already exists";
        errorText = "An existing user with the same name and email address is currently an admin.";
      } else {
        errorTitle = "Could not create user";
        errorText = "We experienced an issue while creating this user.";
      }
      handleSuccessModal(false, errorTitle, errorText);
    },
  });
  const formMethodsAndState = useForm({
    resolver: zodResolver(addNewUserSchema),
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { handleSubmit, errors, control, register, clearErrors } = formMethodsAndState;

  const onSubmit = (data: NewUser) => {
    // Branch users aren't created immediately. It's passed to the Assign Companies Modal via a call-back function.
    // It's then assigned companies and created in the Assign Companies Modal.
    if (!clonedUser && BRANCH_ROLES.includes(UserRoleNames[data.userType])) {
      const newBranchUser = convertToNewBranchUserRequest(data);
      if (BRANCH_TYPES.includes(newBranchUser.userRole)) {
        // TODO: If newUser username/email already exists, show error message.
        dispatch(setShowAssignCompaniesModal(true));
        dispatch(setSelectedUser(newBranchUser));
      }
    } else {
      // For (Super | Payroll) Admins, we create the user immediately since they have all company access.
      const newAdminUserRequest = convertToNewInternalUserRequest(data, userCompanyId, clonedUser);
      dispatch(setTableLoading(true));
      setUserFirstName(data.normalized_first_name);
      runCreateNewUser(newAdminUserRequest);
    }
    onCancel();
  };

  const onCancel = () => {
    clearErrors();
    setSelectedRole("");
    dispatch(setClonedUser(undefined));
    dispatch(setShowAddUserModal(false));
  };

  return (
    <StyledModal
      id={"AddNewUserModal"}
      position={"center"}
      resizable={false}
      draggable={false}
      closable={false}
      closeOnEscape={false}
      onHide={onCancel}
      visible={showAddUserModal}
      data-testid="add-new-user-modal"
      header={<AddNewUserModalHeader>Add user</AddNewUserModalHeader>}
    >
      <FormProvider {...formMethodsAndState}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledForm>
            <FormContainer>
              <Group>
                <Label htmlFor="normalized_first_name" style={{ width: "196px" }}>
                  First Name
                  <Input type="text" name="normalized_first_name" ref={register} />
                  <ErrorMessage
                    name="normalized_first_name"
                    errors={errors}
                    render={({ message }) => <span className="error-message">{message}</span>}
                  />
                </Label>
                <Label htmlFor="normalized_last_name" style={{ width: "196px" }}>
                  Last Name
                  <Input type="text" name="normalized_last_name" ref={register} />
                  <ErrorMessage
                    name="normalized_last_name"
                    errors={errors}
                    render={({ message }) => <span className="error-message">{message}</span>}
                  />
                </Label>
              </Group>
              <Label htmlFor="email">
                Email
                <Input type="text" name="email" ref={register} />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({ message }) => <span className="error-message">{message}</span>}
                />
              </Label>
              <Label htmlFor="phone">
                Phone Number
                <PhoneNumberFieldContainer>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    render={({ value, onChange, onBlur }) => (
                      <MaskedInput
                        mask={MaskTypes.PHONE}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                      ></MaskedInput>
                    )}
                  />
                </PhoneNumberFieldContainer>
                <ErrorMessage
                  name="phone"
                  errors={errors}
                  render={({ message }) => <span className="error-message">{message}</span>}
                />
              </Label>
              <Label htmlFor="userType">
                Role
                <Controller
                  name="userType"
                  control={control}
                  defaultValue={
                    clonedUser?.userRole ? UserTypeMapping[clonedUser.userRole] : "BRANCH_OPS"
                  }
                  render={({ onChange, onBlur }) => (
                    <RoleDropdownField
                      onChange={(role) => {
                        onChange(role);
                        setSelectedRole(role);
                      }}
                      onBlur={onBlur}
                      defaultRole={clonedUser?.userRole ? UserTypeMapping[clonedUser.userRole] : ""}
                    />
                  )}
                />
                <ErrorMessage
                  name="userType"
                  errors={errors}
                  render={({ message }) => <span className="error-message">{message}</span>}
                />
              </Label>
              <AddNewUserModalFooter>
                <CancelButton
                  type="reset"
                  onClick={onCancel}
                  data-testid="add-new-user-cancel-button"
                >
                  Cancel
                </CancelButton>
                <SubmitButton type="submit">
                  {getSubmitButtonText(selectedRole, clonedUser)}
                </SubmitButton>
              </AddNewUserModalFooter>
            </FormContainer>
          </StyledForm>
        </form>
      </FormProvider>
    </StyledModal>
  );
};
