import React from "react";
import moment, { Moment } from "moment";

import cloneDeep from "lodash/cloneDeep";
import "react-dates/initialize";
import { DayPickerSingleDateController, CalendarDay } from "react-dates";
import { Box } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { assetUrl } from "../api/bluecrew/constants/index";

const borderRadius = "0.25rem";

// @ts-ignore $FlowFixStyledComponents
const ReactDatesStyleWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  .PresetDateRangePicker_panel {
    padding: 0 22px 11px;
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 600;
    line-height: normal;
    overflow: visible;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
  }
  .PresetDateRangePicker_button:active {
    outline: 0;
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699;
  }
  .SingleDatePickerInput {
    display: inline-block;
    background-color: #fff;
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb;
  }
  .SingleDatePickerInput__rtl {
    direction: rtl;
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2;
  }
  .SingleDatePickerInput__block {
    display: block;
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 30px;
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .SingleDatePickerInput_clearDate__small {
    padding: 6px;
  }
  .SingleDatePickerInput_clearDate__hide {
    visibility: hidden;
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle;
  }
  .SingleDatePickerInput_clearDate_svg__small {
    height: 9px;
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px;
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle;
  }
  .SingleDatePicker {
    position: relative;
    display: inline-block;
  }
  .SingleDatePicker__block {
    display: block;
  }
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
  }
  .SingleDatePicker_picker__rtl {
    direction: rtl;
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0;
  }
  .SingleDatePicker_picker__directionRight {
    right: 0;
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff;
  }
  .SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2;
  }
  .SingleDatePicker_closeButton:focus,
  .SingleDatePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
  .SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd;
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px;
    // Hiding the keyboard shortcuts green icon, per Kate
    display: none;
  }
  .DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_show {
    width: 33px;
    height: 26px;
    position: absolute;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_show::before {
    content: "";
    display: block;
    position: absolute;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    top: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__topRight::before {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0;
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    top: 0;
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__topLeft::before {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0;
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover::before {
    border-left: 33px solid #008489;
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute;
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: 5px;
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: 5px;
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: 5px;
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px;
    text-align: left;
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px;
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2;
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0;
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd;
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a;
  }
  .CalendarDay {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }
  .CalendarDay:active {
    outline: 0;
  }
  .CalendarDay__defaultCursor {
    cursor: default;
  }
  .CalendarDay__default {
    border: 1px solid ${p("white")};
    background: ${p("brandBlue")};
    background: #fff;
  }
  .CalendarDay__default:hover {
    background: #e4e7e7;
    color: inherit;
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit;
  }
  .CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848;
  }
  .CalendarDay__outside:hover {
    border: 0;
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd;
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd;
  }
  .CalendarDay__highlighted_calendar {
    background: rgba(54, 150, 191, 0.2);
    color: #484848;
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    color: #484848;
  }
  // Add Days hover state
  .CalendarDay__selected_span:hover,
  .CalendarDay__default_2:not(.CalendarDay__highlighted_calendar):not(
      .CalendarDay__selected
    ):hover {
    border-radius: 50%;
    position: relative;
    &::after {
      content: url(${assetUrl}/icons/add-day.svg);
      background-size: 15px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 19px;
      left: 10px;
    }
  }
  // Remove Days hover state
  :not(.allow-highlight-selection)
    .CalendarDay__highlighted_calendar:not(.CalendarDay__selected_span):hover {
    position: relative;
    &::after {
      content: url(${assetUrl}/icons/delete-item.svg);
      background-size: 15px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 19px;
      left: 10px;
    }
  }

  .CalendarDay__selected_span {
    background: none;
    color: ${p("slate")};
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #e4e7e7;
    color: ${p("slate")};
  }
  .CalendarDay__last_in_range,
  .CalendarDay__last_in_range:hover {
    border-style: solid;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: ${p("brandBlue")};
    border: 1px double ${p("white")};
    color: ${p("white")};
    border-radius: 50%;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: rgba(54, 150, 191, 0.2);
    border: 1px double ${p("white")};
    color: ${p("slate")};
  }
  .CalendarDay__hovered_span:active {
    background: ${p("brandBlue")};
    border: 1px double ${p("white")};
    color: ${p("white")};
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    color: #82888a;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 0.0625rem solid ${p("white")};
    color: #cacccd;
  }
  .CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .CalendarMonth_table {
    border-top: 10px ${p("platinum")};
    border-collapse: separate;
    border-spacing: 0;
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate;
  }
  .CalendarMonth_caption {
    color: #484848;
    font-size: 1.25rem;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial;
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px;
  }
  .CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0;
  }
  .CalendarMonthGrid__animating {
    z-index: 1;
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px;
  }
  .CalendarMonthGrid__vertical {
    margin: 0 auto;
  }
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll;
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%;
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden;
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2;
  }
  .DayPickerNavigation__horizontal {
    height: 0;
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0;
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative;
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575;
  }
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4;
  }
  .DayPickerNavigation_button__default:active {
    background: #f2f2f2;
  }
  .DayPickerNavigation_button__disabled {
    cursor: default;
    border: 1px solid #f2f2f2;
  }
  .DayPickerNavigation_button__disabled:focus,
  .DayPickerNavigation_button__disabled:hover {
    border: 1px solid #f2f2f2;
  }
  .DayPickerNavigation_button__disabled:active {
    background: 0 0;
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: 0.78;
    border-radius: 3px;
    padding: 6px 9px;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px;
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 50%;
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0;
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%;
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block;
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848;
  }
  .DayPickerNavigation_svg__disabled {
    fill: #f2f2f2;
  }
  .DayPicker {
    background: #fff;
    position: relative;
    text-align: left;
  }
  .DayPicker__horizontal {
    background: #fff;
  }
  .DayPicker__verticalScrollable {
    height: 100%;
  }
  .DayPicker__hidden {
    visibility: hidden;
  }
  .DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .DayPicker_portal__vertical {
    position: initial;
  }
  .DayPicker_focusRegion {
    outline: 0;
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top;
  }
  .DayPicker_weekHeaders {
    position: relative;
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px;
  }
  .DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left;
  }
  .DayPicker_weekHeader__vertical {
    left: 50%;
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center;
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
  }
  .DayPicker_transitionContainer__horizontal {
    -webkit-transition: height 0.2s ease-in-out;
    -moz-transition: height 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%;
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll;
  }
  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 16.6875rem;
    vertical-align: middle;
  }
  .DateInput__small {
    width: 97px;
  }
  .DateInput__block {
    width: 100%;
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb;
  }
  .DateInput_input {
    font-weight: 200;
    font-size: 1rem;
    line-height: 24px;
    color: ${p("platinum")};
    background-color: ${p("frost")};
    width: 100%;
    height: 3.375rem;
    padding: 0.9375rem 1.25rem;
    border: 0;
    border-radius: ${borderRadius};
  }
  .DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2px;
    padding: 7px 7px 5px;
  }
  .DateInput_input__regular {
    font-weight: auto;
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .DateInput_input__focused {
    outline: 0;
    border-radius: 0.25rem;
    border: solid 0.0938rem ${p("brandBlue")};
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic;
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2;
  }
  .DateInput_fangShape {
    fill: #fff;
  }
  .DateInput_fangStroke {
    display: none;
    stroke: #dbdbdb;
    fill: transparent;
  }
  .DateRangePickerInput {
    background-color: #fff;
    display: inline-block;
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2;
  }
  .DateRangePickerInput__withBorder {
    width: 34.625rem;
    display: flex;
    justify-content: space-between;
    border-radius: 0;
    border: 0;
  }
  .DateRangePickerInput__rtl {
    direction: rtl;
  }
  .DateRangePickerInput__block {
    display: block;
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px;
  }
  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848;
  }
  .DateRangePickerInput_arrow_svg {
    display: none;
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px;
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .DateRangePickerInput_clearDates__small {
    padding: 6px;
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden;
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle;
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 9px;
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px;
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle;
  }
  .DateRangePicker {
    position: relative;
    display: inline-block;
  }
  .DateRangePicker__block {
    display: block;
  }
  .DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute;
  }
  .DateRangePicker_picker__rtl {
    direction: rtl;
  }
  .DateRangePicker_picker__directionLeft {
    left: 0;
  }
  .DateRangePicker_picker__directionRight {
    right: 0;
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff;
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2;
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd, 10%);
    text-decoration: none;
  }
  .DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd;
  }

  // Doing the hokey-pokey to override styles when selecting dates within highlighted range
  &.allow-highlight-selection {
    .CalendarDay__selected_span {
      background: ${p("brandBlue")};
      border-radius: 0;
      color: #fff;
      &::after {
        content: " ";
        background: none;
      }
    }
    .CalendarDay__selected_span:active,
    .CalendarDay__selected_span:hover {
      background: ${p("brandBlue")};
      color: #fff;
    }
  }
`;

type Props = {
  today: Moment;
  dates: Array<Moment>;
  allowPastDates: boolean;
  isDayBlocked?: (day: Moment) => boolean;
  renderCalendarDay?: (arg0: { day: null | Moment; props: any; modifiers: Set<string> }) => Object;
  allowCurrentDate: boolean;
  allowDateOutsideHighlightedRange: boolean;
  // For a given day, this can return true to make it selectable.
  dayIsOutsideRange?: (arg0: {
    day: Moment;
    // This is the default calculation. Pass this through if you don't
    // wish to override the value.
    isOutside: boolean;
  }) => boolean;
  // For a given day, this can return true if it should be
  // initially highlighted, i.e. before user interaction.
  dayIsHighlighted?: (arg0: {
    day: Moment;
    // This is the default calculation. Pass this through if you don't
    // wish to override the value.
    isHighlighted: boolean;
  }) => boolean;
  onChange?: (params: {
    daysNotHighlightedSelected: any;
    daysHighlightedSelected: any;
  }) => void | undefined;
  allowSelectHighlightedOnly: boolean;
};

type State = {
  highlighted: any;
  // e.g. "adding days"
  daysNotHighlightedSelected: any;
  // e.g. "editing times for specific days" or "removing days"
  daysHighlightedSelected: any;
  startDate: boolean | Moment;
  endDate: boolean | Moment;
  initialVisibleMonth: Moment;
};

class MultiDatePicker extends React.Component<Props, State> {
  static defaultProps = {
    dates: [],
    today: moment(),
    allowPastDates: false,
    allowCurrentDate: false,
    allowDateOutsideHighlightedRange: false,
    allowSelectHighlightedOnly: false,
  };

  constructor(props: Props) {
    super(props);

    const { dayIsHighlighted, today } = props;

    const highlighted = {};

    const sortedDates = props.dates
      .map((date) => moment(date))
      .sort((date1, date2) => date1.unix() - date2.unix());
    sortedDates.forEach((date) => {
      let shouldHighlight = true;
      if (dayIsHighlighted) {
        shouldHighlight = dayIsHighlighted({
          day: date,
          isHighlighted: shouldHighlight,
        });
      }
      if (shouldHighlight) {
        highlighted[date.format("YYYY-MM-DD")] = date;
      }
    });

    // no date or just 1 date isn't really a range
    const startDate = sortedDates.length > 1 && sortedDates[0];
    const endDate = sortedDates.length > 1 && sortedDates[sortedDates.length - 1];

    // When opening component start on either the first day of the schedule (according to schedule details page) or the current day - whichever is later
    const initialVisibleMonth = startDate && startDate.isAfter(today) ? startDate : today;

    // init the state
    this.state = {
      highlighted,
      daysNotHighlightedSelected: {},
      daysHighlightedSelected: {},
      startDate,
      endDate,
      initialVisibleMonth,
    };
  }

  /**
   * Which month to focus on when the calendar opens, a react-dates prop.
   */
  initialVisibleMonth = () => this.state.initialVisibleMonth;

  /**
   * react-dates prop function to determine if a date is actionable or not
   * @param {*} day
   */
  isOutsideRange = (day: Moment) => {
    const { startDate, endDate } = this.state;
    const {
      today,
      allowPastDates,
      allowCurrentDate,
      allowDateOutsideHighlightedRange,
      allowSelectHighlightedOnly,
      dayIsOutsideRange,
    } = this.props;

    let isOutside = false;
    // disabling action on past date by default (unless allowPastDates is set to true)
    if (day && !allowPastDates && day.isBefore(today, "day")) {
      isOutside = true;
    } // disabling action on current date by default (unless allowCurrentDate is set to true)
    else if (day && !allowCurrentDate && day.isSame(today, "day")) {
      isOutside = true;
    } // and outside of startDate / endDate (unless allowDateOutsideHighlightedRange is set to true, or one of startDate and endDate is undefined)
    else if (
      day &&
      !allowDateOutsideHighlightedRange &&
      startDate &&
      endDate && // @ts-ignore $FlowFixPossiblyWrongCode
      !day.isBetween(startDate, endDate, "day", "[]")
    ) {
      // isBetween, but also including start and end date
      isOutside = true;
    } else if (
      day &&
      allowSelectHighlightedOnly &&
      !this.state.highlighted[day.format("YYYY-MM-DD")]
    ) {
      // day in not in our date array
      isOutside = true;
    }

    if (dayIsOutsideRange) {
      isOutside = dayIsOutsideRange({ day, isOutside });
    }

    return isOutside;
  };

  propagateChangeToParent = () => {
    const formattedValues = {
      daysNotHighlightedSelected: this.state.daysNotHighlightedSelected,
      daysHighlightedSelected: this.state.daysHighlightedSelected,
    };
    return this.props.onChange && this.props.onChange(formattedValues);
  };

  handleChange = (date: Moment) => {
    const { daysNotHighlightedSelected, daysHighlightedSelected, highlighted } = this.state;

    if (date) {
      // if the date is already in daysNotHighlightedSelected, we remove it
      const iso = date.format("YYYY-MM-DD");
      if (daysNotHighlightedSelected[iso]) {
        const daysNotHighlightedSelectedClone = cloneDeep(daysNotHighlightedSelected);
        delete daysNotHighlightedSelectedClone[iso];
        this.setState(
          {
            ...this.state,
            daysNotHighlightedSelected: daysNotHighlightedSelectedClone,
          },
          this.propagateChangeToParent,
        );
      } // else if the date is already in daysHighlightedSelected, we remove it
      else if (daysHighlightedSelected[iso]) {
        const daysHighlightedSelectedClone = cloneDeep(daysHighlightedSelected);
        delete daysHighlightedSelectedClone[iso];
        this.setState(
          {
            ...this.state,
            daysHighlightedSelected: daysHighlightedSelectedClone,
          },
          this.propagateChangeToParent,
        );
      } // if the date is already highlighted,  we add it to `daysHighlightedSelected`
      else if (highlighted[iso]) {
        this.setState(
          {
            ...this.state,
            daysHighlightedSelected: {
              ...daysHighlightedSelected,
              [iso]: date,
            },
          },
          this.propagateChangeToParent,
        );
      } // else we add it to `daysNotHighlightedSelected`
      else {
        this.setState(
          {
            ...this.state,
            daysNotHighlightedSelected: {
              ...daysNotHighlightedSelected,
              [iso]: date,
            },
          },
          this.propagateChangeToParent,
        );
      }
    }
  };

  highlightDays = (day: Moment) => {
    const iso = day.format("YYYY-MM-DD");
    return (
      this.state.highlighted[iso] &&
      !this.state.daysNotHighlightedSelected[iso] &&
      !this.state.daysHighlightedSelected[iso] &&
      day
    );
  };

  render() {
    const { isDayBlocked, renderCalendarDay } = this.props;
    const conditionalClasses = this.props.allowSelectHighlightedOnly
      ? "allow-highlight-selection"
      : "";

    return (
      <ReactDatesStyleWrapper className={conditionalClasses}>
        <DayPickerSingleDateController
          numberOfMonths={2}
          onDateChange={this.handleChange}
          isDayBlocked={isDayBlocked}
          isDayHighlighted={this.highlightDays}
          isOutsideRange={this.isOutsideRange}
          focused
          initialVisibleMonth={this.initialVisibleMonth}
          renderCalendarDay={(props) => {
            const { day, modifiers } = props;
            if (day && modifiers) {
              const iso = day.format("YYYY-MM-DD");
              if (this.state.daysNotHighlightedSelected[iso]) {
                modifiers && modifiers.add("selected");
              } else {
                modifiers && modifiers.delete("selected");
              }
              if (this.state.daysHighlightedSelected[iso]) {
                modifiers && modifiers.add("selected-span");
              } else {
                modifiers && modifiers.delete("selected-span");
              }
            }

            const newModifiers = new Set<string>(modifiers);

            if (renderCalendarDay) {
              return renderCalendarDay({ day, props, modifiers: newModifiers });
            }

            return <CalendarDay {...props} modifiers={newModifiers} />;
          }}
        />
      </ReactDatesStyleWrapper>
    );
  }
}

export default MultiDatePicker;
