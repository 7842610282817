import { SearchBar, Tabs } from "@bluecrew/blueprint-web";
import React, { createRef, useEffect, useState } from "react";
import { styled } from "baseui";
import InfiniteScroll from "react-infinite-scroll-component";
import { useFilteredList } from "../../useFilteredList";
import { isCrewMember } from "../../types/propTypes/ChooseCrewModal.types";
import { ActiveTab, WorkforcePanelProps } from "../../types/propTypes/WorkforcePanel.types";
import { Spacer, SpacerType } from "../../../../components/Spacer";
import { CrewMemberMapper } from "../CrewMemberMapper";
import { CrewMemberListItem } from "../../types/propTypes/CrewMembersTab.types";
import { CrewGroupListItem } from "../../types/propTypes/CrewGroupsTab.types";
import { CrewGroupMapper } from "./CrewGroupMapper";
import { CrewMembersTab } from "./CrewMembersTab";
import { CrewGroupsTab } from "./CrewGroupsTab";
import { useSelectionList } from "../../useSelectionList";

const ITEM_HEIGHT = 76;
const ITEMS_PER_PAGE = 5;
export const WorkforcePanel = ({
  crewMembers,
  crewGroups = [],
  onSelectionChange,
  selectedCrew,
  hideCrewGroups = false,
}: WorkforcePanelProps) => {
  const windowHeight = window.innerHeight;
  const visibleItem = Math.ceil(windowHeight / ITEM_HEIGHT);
  const getActiveTab = () => {
    if (Array.isArray(selectedCrew) && selectedCrew.length) {
      return isCrewMember(selectedCrew[0]) ? ActiveTab.member : ActiveTab.group;
    }

    return ActiveTab.member;
  };

  const [previousTab, setPreviousTab] = useState<string>(getActiveTab());
  const [activeTab, setActiveTab] = useState<string>(getActiveTab());
  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [chunkList, setChunkList] = useState<Array<CrewMemberListItem | CrewGroupListItem>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const getMemberList = () => crewMembers.map((member) => CrewMemberMapper.toListItem(member));

  const getGroupList = () => crewGroups.map((group) => CrewGroupMapper.toListItem(group));

  const { list, setList, selected, toggleSelection } = useSelectionList<
    CrewMemberListItem | CrewGroupListItem
  >(getMemberList(), { multiSelect: true, searchBy: "id" });

  const {
    list: filteredList,
    setList: setFilteredList,
    filter,
    reset,
  } = useFilteredList<CrewMemberListItem | CrewGroupListItem>(getMemberList(), (item, searchText) =>
    item.title.toLowerCase().includes(searchText.toLowerCase()),
  );

  const [cachedMemberList, setCachedMemberList] =
    useState<Array<CrewMemberListItem>>(getMemberList);
  const [cachedGroupList, setCachedGroupList] = useState<Array<CrewGroupListItem>>(getGroupList());

  useEffect(() => {
    let newList;
    if (activeTab === ActiveTab.member) {
      if (previousTab !== activeTab) {
        setCachedGroupList(list as Array<CrewGroupListItem>);
      }
      newList = cachedMemberList;
    } else {
      if (previousTab !== activeTab) {
        setCachedMemberList(list as Array<CrewMemberListItem>);
      }
      newList = cachedGroupList;
    }
    setList(newList);
    setSearchCriteria("");
    reset();
    setPreviousTab(activeTab);
  }, [activeTab]);

  // preselect certain items when user pressed edit in selected list
  useEffect(() => {
    Array.isArray(selectedCrew) &&
      selectedCrew.length &&
      setList(
        (activeTab === ActiveTab.member
          ? cachedMemberList
          : (cachedGroupList as Array<CrewMemberListItem | CrewGroupListItem>)
        ).map((item) => ({
          ...item,
          selected: !!selectedCrew.find((selectedItem) => selectedItem.id === item.id),
        })),
      );
  }, []);

  useEffect(() => {
    if (!onSelectionChange) {
      return;
    }
    setFilteredList(list);

    onSelectionChange(
      selected.map((item) =>
        activeTab === ActiveTab.member
          ? CrewMemberMapper.toModel(item as CrewMemberListItem)
          : CrewGroupMapper.toModel(item as CrewGroupListItem),
      ),
    );
  }, [list]);

  useEffect(() => {
    if (activeTab === ActiveTab.member) {
      setChunkList(filteredList.slice(0, visibleItem * currentPage));
    }
  }, [filteredList]);

  const modalRootRef = createRef<HTMLDivElement>();

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setChunkList(
      filteredList.slice(
        0,
        currentPage * (ITEMS_PER_PAGE > visibleItem ? visibleItem : ITEMS_PER_PAGE),
      ),
    );
  }, [currentPage]);

  return (
    <StyledPanel ref={modalRootRef}>
      <SearchBar
        placeholder="Search"
        value={searchCriteria}
        onChange={(text) => {
          setSearchCriteria(text);
          filter(text);
        }}
      />
      <Spacer $type={SpacerType.vertical} $size="8px" />
      {hideCrewGroups ? (
        <InfiniteScroll
          dataLength={chunkList.length}
          next={fetchMoreData}
          hasMore={!!(chunkList.length <= list.length)}
          loader={<></>}
          scrollableTarget="scrollableDiv"
        >
          <CrewMembersTab
            rootRef={modalRootRef}
            items={chunkList as Array<CrewMemberListItem>}
            onItemClick={toggleSelection}
          />
        </InfiniteScroll>
      ) : (
        <Tabs
          renderList={[
            {
              title: "Crew Members",
              content: (
                <div
                  style={{
                    marginRight: "-16px",
                    height: `${windowHeight - ITEM_HEIGHT}px`,
                    overflow: "auto",
                  }}
                  id="scrollableDiv"
                >
                  <InfiniteScroll
                    dataLength={chunkList.length}
                    next={fetchMoreData}
                    hasMore={!!(chunkList.length <= list.length)}
                    loader={<></>}
                    scrollableTarget="scrollableDiv"
                  >
                    <CrewMembersTab
                      rootRef={modalRootRef}
                      items={chunkList as Array<CrewMemberListItem>}
                      onItemClick={toggleSelection}
                    />
                  </InfiniteScroll>
                </div>
              ),
            },
            {
              title: "Groups",
              content: (
                <div style={{ marginRight: "-16px" }}>
                  <CrewGroupsTab
                    rootRef={modalRootRef}
                    items={filteredList as Array<CrewGroupListItem>}
                    onItemClick={toggleSelection}
                  />
                </div>
              ),
            },
          ]}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      )}
    </StyledPanel>
  );
};

const StyledPanel = styled("div", ({ $theme }) => ({
  paddingLeft: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
}));
