// SESSION - ACTIONS
// =============================================================================

import { createActions } from "redux-actions";

export default createActions({
  SESSION: {
    START: () => null,
    END: undefined,
  },
  RESUME: {
    REQUEST: undefined,
    SUCCESS: (res: any) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
}) as any;
