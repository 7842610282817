import { useQuery } from "@tanstack/react-query";
import { getDashboardPositions } from "../dashboard";
import { ListedPosition } from "../types";

// TODO: BW-1488 Update Type
export const INITIAL_DASHBOARD_POSITIONS: ListedPosition[] = [];

export const useGetDashboardPositionsQuery = (userCompanyId: number) =>
  useQuery(
    ["dashboardPositions", userCompanyId],
    () =>
      getDashboardPositions({
        companyId: userCompanyId,
      }),
    { initialData: INITIAL_DASHBOARD_POSITIONS },
  );
