import { DashboardPositionHeaderDetail, PositionDetail } from "../api/bluecrew/types";
import {
  Requirement,
  Requirements,
} from "../containers/GetCrewMembers/types/propTypes/RequirementsField.types";

export const getCertifications = (
  skill: any,
  position: PositionDetail | DashboardPositionHeaderDetail,
) =>
  skill?.users_certs.reduce((accumulator: Requirements, req: Requirement) => {
    if (position?.requirements.includes(req.label)) {
      accumulator.push({ ...req, selected: true });
    } else {
      accumulator.push({ ...req, selected: false });
    }
    return accumulator;
  }, []);
