import React from 'react';

export const GetCrewMembersCalendarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.5V19.5"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16.5H15"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 5.25C0.75 4.42157 1.42157 3.75 2.25 3.75H21.75C22.5784 3.75 23.25 4.42157 23.25 5.25V21.75C23.25 22.5784 22.5784 23.25 21.75 23.25H2.25C1.42157 23.25 0.75 22.5784 0.75 21.75V5.25Z"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 9.75H23.25"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 6V0.75"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 6V0.75"
        stroke="#33343D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
