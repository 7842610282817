import React from 'react';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { Check } from 'baseui/icon';
import {
  getBorderRadius,
  getHorizontalPadding,
  getMargin,
  getVerticalPadding,
} from '../helpers/styleHelpers';
import { useThemedStyletron } from '../theme';

export enum SwitchType {
  default = 'normal',
  disabled = 'disabled',
  error = 'error',
}

interface SwitchProps {
  value: boolean;
  onChange: (val: boolean) => void;
  type?: SwitchType;
  isSmaller?: boolean;
}

export const Switch = ({
  value = false,
  type = SwitchType.default,
  onChange,
  isSmaller,
}: SwitchProps) => {
  const [, $theme] = useThemedStyletron();

  const switchOffColor = {
    [SwitchType.default]: {
      fillColor: $theme.colors.toggleFill,
      trackFillColor: $theme.colors.toggleTrackFill,
    },
    [SwitchType.disabled]: {
      fillColor: $theme.colors.toggleFillDisabled,
      trackFillColor: $theme.colors.toggleTrackFillDisabled,
    },
    [SwitchType.error]: {
      fillColor: $theme.colors.toggleFill,
      trackFillColor: $theme.colors.toggleTrackFillError,
    },
  };

  const switchOnColor = {
    [SwitchType.default]: {
      fillColor: $theme.colors.toggleFillChecked,
      trackFillColor: $theme.colors.toggleTrackFillChecked,
    },
    [SwitchType.disabled]: {
      fillColor: $theme.colors.toggleFillDisabled,
      trackFillColor: $theme.colors.toggleTrackFillDisabled,
    },
    [SwitchType.error]: {
      fillColor: $theme.colors.toggleFillChecked,
      trackFillColor: $theme.colors.toggleTrackFillErrorChecked,
    },
  };

  const { fillColor, trackFillColor } = (
    value ? switchOnColor : switchOffColor
  )[type];

  return (
    <Checkbox
      checked={value}
      disabled={type === SwitchType.disabled}
      onChange={(e) => onChange(e.currentTarget.checked)}
      checkmarkType={STYLE_TYPE.toggle_round}
      error={type === SwitchType.error}
      overrides={{
        Label: {
          style: () => ({
            // Remove padding, to properly align switch to the left
            ...getHorizontalPadding('0'),
          }),
        },
        Toggle: {
          style: ({ $checked }) => ({
            width: isSmaller ? $theme.sizing.scale800 : $theme.sizing.scale900,
            height: isSmaller ? $theme.sizing.scale800 : $theme.sizing.scale900,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: fillColor,
            boxShadow: $theme.lighting.shadow400,
            transform: $checked
              ? `translateX(${$theme.sizing.scale700})`
              : undefined,
          }),
        },
        ToggleTrack: {
          style: () => ({
            boxSizing: 'border-box',
            width: isSmaller ? '50px' : '60px',
            backgroundColor: trackFillColor,
            ...getBorderRadius(
              isSmaller ? $theme.sizing.scale750 : $theme.sizing.scale850,
            ),
            ...getVerticalPadding(
              isSmaller ? $theme.sizing.scale550 : $theme.sizing.scale700,
            ),
            ...getHorizontalPadding($theme.sizing.scale100),
            ...getMargin('0'),
          }),
        },
        ToggleInner: () =>
          value ? <Check color={trackFillColor} size={25} /> : null,
      }}
    />
  );
};
