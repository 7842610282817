import { omit } from "lodash";
import { Position, PositionsListItem } from "../types/propTypes/PositionField.types";

export class PositionMapper {
  static toListItem = (position: Position, selected: boolean = false): PositionsListItem => ({
    ...position,
    title: position.name,
    subtitle: position.address.displayAddress,
    selected,
  });

  static toModel = (listItem: PositionsListItem): Position =>
    omit<PositionsListItem, keyof PositionsListItem>(listItem, [
      "title",
      "subtitle",
      "selected",
    ]) as Position;
}
