// TODO Update this component to a function component in its own directory broken into multiple files per https://bluecrewjobs.atlassian.net/wiki/spaces/WS/pages/1676967961/React+-+Best+practices
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Flex,
  Block,
  Button,
  InlineBlock,
  H4Heading,
  Portal,
  Backdrop,
  SpinnerWithOverlay,
  UserAvatar,
} from "@bluecrew/web-react-core";
import get from "lodash/get";
import { useNavigate, useParams } from "react-router-dom";
import { DaysOfWeek, Weekdays } from "@bluecrew/blueprint-web";
import moment from "moment";
import { ScheduleType } from "../GetCrewMembers/types/propTypes/Schedule.types";
import { StateShape } from "../../redux/reducers";
import {
  ScheduleStatus,
  UpdateScheduleRequest,
  UserDetail,
  ReasonCode,
} from "../../api/bluecrew/types/index";
import scheduleActions, {
  CancelWorkRequestPayload,
  fetchSchedule,
  cancelSchedule,
} from "../../redux/actions/schedule";
import { logError } from "../../sentry";
import { getDirectInviteUserIds } from "../../redux/selectors/job";
import {
  getFormattedSchedule,
  getIsCreatingScheduleException,
  getIsFetching,
  getScheduleIsActive,
  getUpdateScheduleIsFetching,
} from "../../redux/selectors/schedule";
import Roster from "./roster";
import { assetUrl } from "../../api/bluecrew/constants/index";
import ArchiveScheduleModal from "../../components/ArchiveScheduleModal";
import { CancelScheduleModal } from "../../components/CancelScheduleModal";
import AddPreviousCrewMember from "../../components/AddPreviousCrewMember";
import { ScheduleHeading, SaveScheduleNameType } from "../../components/ScheduleHeading";
import ModifyDaysOrTimes from "./ModifyDaysOrTimes";
import { formatPhone } from "../../utility";
import { CREWS_ONLY_POST_TYPE, Modals, NumericTypeOfPost } from "../../../shared/constants";
import { AbilityContext, Can } from "../PermissionsContext";
import { formatUri } from "../../utility/uri";
import {
  AvatarContainer,
  ChangeSupervisor,
  DepartmentCard,
  StyledActionButtons,
  StyledOverlay,
  StyledPayRate,
  SupervisorCard,
  SupervisorContainer,
  SupervisorInfo,
  ViewContainer,
  ViewContent,
  ViewHeader,
} from "./Schedule.StyledComponents";
import { getWageText } from "../../utility/getWageText";
import { getIsSplitioReady } from "../../redux/selectors/splitio";
import { RequestLogTable } from "./RequestLogTable";

const drawDaysOfWeek = (shifts) => {
  const results = [] as any;

  shifts.forEach((shift) => {
    results.push(shift.day_of_week);
  });

  return results;
};

export const getMilitaryTimeFromSchedule = (schedule) => {
  const formatAmPmToMilitary = (time) => moment(time, ["h:mm A"]).format("HH:mm");

  const startTime = schedule.start_date_time.time;
  const endTime = schedule.end_date_time.time;

  return {
    startTime: formatAmPmToMilitary(startTime),
    endTime: formatAmPmToMilitary(endTime),
  };
};

type RequestCrewLocationState = {
  directInviteUsers: Array<UserDetail> | typeof undefined;
  redirectPathOnCancel: string | typeof undefined;
};

const mapDispatchToProps = (dispatch: any) => ({
  onMount: (scheduleId: string) => {
    dispatch(fetchSchedule(scheduleId));
  },
  archiveSchedule: (data: { scheduleId: number }) => {
    const payload: UpdateScheduleRequest = {
      scheduleId: data.scheduleId,
      payload: {
        active: ScheduleStatus.ARCHIVED,
      },
    };
    dispatch(scheduleActions.updateSchedule.request(payload));
  },
  cancelSchedule: (payload: CancelWorkRequestPayload) => {
    dispatch(cancelSchedule(payload));
  },
  updateSchedule: (data: UpdateScheduleRequest) =>
    dispatch(scheduleActions.updateSchedule.request(data)),
});

const mapStateToProps = (state: StateShape) => ({
  directInviteUserIds: getDirectInviteUserIds(state),
  isFetching: getIsFetching(state),
  isCreatingScheduleException: getIsCreatingScheduleException(state),
  schedule: getFormattedSchedule(state),
  archiveSchedulePending: getUpdateScheduleIsFetching(state),
  isScheduleActive: getScheduleIsActive(state),
  isSplitioReady: getIsSplitioReady(state),
});

export type ScheduleProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    schedule: any;
  };

export const Schedule = ({
  onMount,
  directInviteUserIds,
  isScheduleActive,
  archiveSchedulePending,
  isFetching,
  isCreatingScheduleException,
  schedule = {
    work_request_name: "WORK_REQUEST_NAME",
    position_name: "POSITION_NAME",
    total_work_requests: {
      total_request: "TOTAL_REQUEST",
      this_week: "THIS_WEEK",
      filled: "FILLED",
    },
    start_date_time: {
      date: "DATE",
      time: "TIME",
    },
    end_date_time: {
      date: "DATE",
      time: "TIME",
    },
    shifts: [],
    supervisor: {
      user_id: "",
      user_external_id: "",
      full_name: "N/A",
      phone: "N/A",
    },
    request_log: [],
    roster: {},
  },
  cancelSchedule,
  updateSchedule,
  archiveSchedule,
  isSplitioReady,
}: ScheduleProps) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<string>(Modals.DEFAULT);
  const routeParams = useParams();
  const id = routeParams.id!; // id  is always defined based on how this route is defined
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const canViewWages = ability.can("view", "wages");
  const canViewSchedule = ability.can("view", "schedule");

  useEffect(() => {
    onMount(id);

    if (directInviteUserIds.length) {
      // We are mounting as part of the direct invite flow.
      // Open a modal to continue the direct invite process.
      setModalContent(Modals.ADD_PREVIOUS_CREW);
      setShowModal(true);
    }
  }, []);

  if (!canViewSchedule) {
    navigate("/");
  }

  const toggleModal = () => {
    setShowModal((prevState: boolean) => !prevState);
  };

  const handleArchiveSchedule = () => {
    archiveSchedule({
      scheduleId: schedule.schedule_id,
    });

    toggleModal();
  };

  const handleCancelSchedule = (reasonCode?: ReasonCode, reasonText?: string) => {
    const jobIds = (get(schedule, "request_log") || []).map((request) => request.external_id);

    cancelSchedule({
      jobIds,
      reasonCode,
      reasonText,
    });

    toggleModal();
  };

  const handleEditScheduleClick = () => {
    const { schedule_id: scheduleId } = schedule;

    navigate(`/schedules/${scheduleId}/edit`);
  };

  const saveScheduleName: SaveScheduleNameType = (params) => {
    const { scheduleId, scheduleName } = params;
    const payload = { schedule_name: scheduleName };
    updateSchedule({
      payload,
      // @ts-ignore
      scheduleId,
    });
  };

  const handleNavToPositions = () => {
    navigate("/positions");
  };

  const openModal = (modalName: string) => () => {
    if (modalName && modalName in Modals) {
      setModalContent(modalName);
      setShowModal(true);
    } else {
      logError({
        error: new Error(
          `Schedule container: cannot open modal "${modalName}" because it does not exist`,
        ),
      });
    }
  };

  /**
   * Navigate to uri, which contains scheduleType, postType, startTime, endTime query parameters for state
   */
  const navigateToRequestCrew = ({
    postType,
  }: {
    postType?: NumericTypeOfPost;
  } = {}) => {
    const { startTime, endTime } = getMilitaryTimeFromSchedule(schedule);

    let uri = `/schedules/${id}/request-crew`;
    let paramsObject = {};

    if (postType !== undefined) {
      paramsObject = { ...paramsObject, postType: String(postType) };
    }

    paramsObject = {
      ...paramsObject,
      scheduleType: schedule.schedule_type,
      startTime,
      endTime,
    };
    uri = formatUri(uri, paramsObject);

    const state: RequestCrewLocationState = {
      directInviteUsers: undefined,
      redirectPathOnCancel: `/schedules/${id}`,
    };
    // @ts-ignore
    navigate(uri, { ...state });
  };

  const handleRequestAdditionalCrew = () => {
    navigateToRequestCrew();
  };

  const handleAddPreviousCrewMember = () => {
    navigateToRequestCrew({ postType: CREWS_ONLY_POST_TYPE });
  };

  const isRosterEmpty = () => !(schedule?.request?.length > 0);

  const getModalContent = () => {
    switch (modalContent) {
      case Modals.ADD_REMOVE_DAY:
        // @ts-ignore
        return <ModifyDaysOrTimes handleClose={toggleModal} />;
      case Modals.MODIFY_TIME:
        // @ts-ignore
        return <ModifyDaysOrTimes includeTimes handleClose={toggleModal} />;
      case Modals.ARCHIVE_SCHEDULE:
        return (
          <ArchiveScheduleModal
            archiveSchedule={handleArchiveSchedule}
            showModal={showModal}
            toggle={toggleModal}
            scheduleName={schedule.work_request_name}
          />
        );
      case Modals.DELETE_SCHEDULE:
        return (
          <CancelScheduleModal
            cancelSchedule={handleCancelSchedule}
            showModal={showModal}
            toggle={toggleModal}
            workerShifts={schedule.number_of_worker_shifts}
          />
        );
      case Modals.ADD_PREVIOUS_CREW:
        return (
          <AddPreviousCrewMember
            // @ts-ignore
            toggleModal={toggleModal}
            show={showModal}
            scheduleId={schedule.schedule_id}
          />
        );
      default:
        return null;
    }
  };

  const canShowAddRemoveDays = schedule.schedule_type === ScheduleType.REPEATING;

  return (
    <>
      <Can I="view" a="schedule">
        <ViewContainer>
          {schedule && !isFetching ? (
            <Block>
              <ViewHeader>
                <Block className="heading-container">
                  <img src={`${assetUrl}/backwards-chevron.svg`} onClick={handleNavToPositions} />
                  <Block>
                    <ScheduleHeading
                      positionName={schedule.position_name}
                      scheduleName={schedule.work_request_name}
                      scheduleId={schedule.schedule_id}
                      saveScheduleName={saveScheduleName}
                      scheduleType={schedule.schedule_type}
                    />

                    {/* START SINGLE MODAL SET UP */}
                    <Portal>
                      <Backdrop fade visible={showModal} onClick={toggleModal} />
                    </Portal>
                    {/* NOTE: doing it this way so component is mounted/unmounted on each summon to avoid stale state */}
                    {showModal && (
                      <Portal>
                        <StyledOverlay fade visible>
                          {getModalContent()}
                        </StyledOverlay>
                      </Portal>
                    )}
                    {/* END SINGLE MODAL SET UP */}

                    {/* TODO: this is a temporary workaround, needs different permissions for each list item */}
                    <Can I="update" a="schedule">
                      <Can I="post" a="company_jobs">
                        <StyledActionButtons>
                          <Button
                            data-testid="Schedule-request-additional-crew-button"
                            palette="primary"
                            onClick={handleRequestAdditionalCrew}
                          >
                            Get more crew members
                          </Button>
                          {canShowAddRemoveDays && (
                            <Button
                              data-testid="Schedule-add-remove-days-button"
                              palette="secondary"
                              onClick={openModal(Modals.ADD_REMOVE_DAY)}
                            >
                              Add/remove days
                            </Button>
                          )}
                          <Button palette="secondary" onClick={openModal(Modals.MODIFY_TIME)}>
                            Change start/end time
                          </Button>

                          <Button
                            data-testid="ViewSchedulePage-edit-schedule-btn"
                            palette="secondary"
                            onClick={handleEditScheduleClick}
                          >
                            Edit
                          </Button>

                          <Button
                            data-testid="ScheduleDetails-action-cancel"
                            palette="secondary"
                            onClick={openModal(Modals.DELETE_SCHEDULE)}
                            disabled={isRosterEmpty()}
                          >
                            Cancel all jobs
                          </Button>

                          <Button
                            data-testid="ScheduleDetails-action-archive"
                            palette="secondary"
                            onClick={openModal(Modals.ARCHIVE_SCHEDULE)}
                            disabled={isScheduleActive}
                          >
                            Archive
                          </Button>
                        </StyledActionButtons>
                      </Can>
                    </Can>
                  </Block>
                </Block>
              </ViewHeader>
              <ViewContent>
                <Block className="pane-container">
                  <Block className="left-pane">
                    <section>
                      <Flex className="date-time-container">
                        <Block className="date-time">
                          <H4Heading>Start Time</H4Heading>
                          <Block className="time">
                            {schedule.start_date_time.time || "--"}
                            <span className="tz"> {schedule.displayTimeZone || "--"}</span>
                          </Block>
                        </Block>
                        <Block className="date-time">
                          <H4Heading>End Time</H4Heading>
                          <Block className="time">
                            {schedule.end_date_time.time || "--"}
                            <span className="tz"> {schedule.displayTimeZone || "--"}</span>
                          </Block>
                        </Block>
                        <Block>
                          <H4Heading>Total Pay</H4Heading>
                          <StyledPayRate data-testid="ViewSchedulePage-total-pay">
                            {`${getWageText(canViewWages, schedule.base_wage)}/hr`}
                          </StyledPayRate>
                        </Block>
                      </Flex>
                      <Block className="workdays-container">
                        <H4Heading>Workdays</H4Heading>
                        <Weekdays
                          editable={false}
                          selected={drawDaysOfWeek(schedule.shifts) as DaysOfWeek}
                          size="large"
                        />
                      </Block>

                      <SupervisorCard>
                        <SupervisorContainer>
                          <H4Heading>Supervisor</H4Heading>
                          <Can I="update" a="schedule">
                            <Can I="post" a="company_jobs">
                              <ChangeSupervisor onClick={handleEditScheduleClick}>
                                change
                              </ChangeSupervisor>
                            </Can>
                          </Can>
                        </SupervisorContainer>
                        <AvatarContainer>
                          <UserAvatar
                            size="md"
                            imgSrc={schedule.supervisor.avatar_url}
                            fullName={schedule.supervisor.full_name}
                          />
                        </AvatarContainer>
                        <SupervisorInfo>
                          <InlineBlock>
                            <Block className="name" data-testid="ViewSchedulePage-supervisor-name">
                              {schedule.supervisor.full_name || "N/A"}
                            </Block>
                            <Block className="phone">
                              {schedule.supervisor.phone
                                ? formatPhone(schedule.supervisor.phone)
                                : "N/A"}
                            </Block>
                          </InlineBlock>
                        </SupervisorInfo>
                      </SupervisorCard>

                      <DepartmentCard>
                        <H4Heading>Department</H4Heading>
                        <span>{schedule.tag_name ? schedule.tag_name : "N/A"}</span>
                      </DepartmentCard>
                    </section>
                    <section>
                      <RequestLogTable data={schedule.request_log.reverse()} />
                    </section>
                  </Block>
                  <Block className="right-pane">
                    <Roster
                      scheduleId={schedule.schedule_id}
                      handleAddPreviousCrewMemberClick={handleAddPreviousCrewMember}
                    />
                  </Block>
                </Block>
              </ViewContent>
            </Block>
          ) : null}
          {(!schedule ||
            isFetching ||
            isCreatingScheduleException ||
            archiveSchedulePending ||
            !isSplitioReady) && <SpinnerWithOverlay />}
        </ViewContainer>
      </Can>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
