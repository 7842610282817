import { UserType } from "../../../api/bluecrew/types";
import { TimesheetsShiftInfo, TimesheetsJobInfo } from "./Shift.types";
import { ShiftAdjustment } from "../Timesheets/TimesheetsPageTable/ShiftAdjust/ShiftAdjustModal";

export enum PayrollHoursStatus {
  APPROVED, // 0 ADMIN APPROVED TIMESTAMP
  REJECTED, // 1 NO SHOWS
  HIDDEN, // 2 HIDDEN FROM SCHEDULE
  DAY_OFF, // 3 excused absence and will show up in schedule as day off
  IGNORE, // 4 Ignore hours after a shift has started or when a job is cancelled
  FOLLOW_UP, // 5 investigate further or follow up for more information from the Client
  NO_RESPONSE, // 6 employee/employer never responded during the pay period
  NEEDS_VERIFICATION, // 7 needs verification of the users hours record
  DISPUTED, // 8 disputed users hours
}

export enum PayrollAutoApprovalStatus {
  AUTO_APPROVED = "AUTO_APPROVED",
  NEEDS_MANUAL_APPROVAL = "NEEDS_MANUAL_APPROVAL",
}

export enum TimestampType {
  SHIFT_START = "SHIFT_START",
  SHIFT_END = "SHIFT_END",
  LUNCH_START = "LUNCH_START", // TODO(@dchhina): One day refactor to say BREAK_START/BREAK_END
  LUNCH_END = "LUNCH_END",
}

export enum MarkupType {
  ADD = "ADD",
  MULTIPLY = "MULTIPLY",
}

export enum PayrollStatusReason {
  NO_ADJUSTMENTS,
  INTERNET_ISSUE,
  FORGOT_TIMESTAMP,
  PERIMETER_TOO_SMALL,
  TERMINAL_ISSUE,
  EMPLOYER_OFFLINE,
  NO_PHONE_ALLOWED_ONSITE,
  RESIGNED,
  NO_CALL_NO_SHOW,
  WALK_OFF,
  NO_TIMESTAMPS,
  NO_CLOCK_OUT,
  LATE_CLOCK_IN,
  LATE_CLOCK_OUT,
  FAMILY_EMERGENCY,
  HOLIDAY,
  CLIENT_CANCELLED,
  SICK,
  TRANSPORT_ISSUE,
  OTHER,
  OFFSITE_WARNING,
  LATE_WARNING,
  SUBMIT_HOURS,
  TIME_THEFT,
  UNQUALIFIED,
  NO_LONGER_NEEDED,
  NO_SHOW,
  VACATION,
  NO_CALL_TRANSPORTATION_ISSUE,
  NO_CALL_SICK,
  NO_CALL_FAMILY_EMERGENCY,
  NO_CALL_VACATION,
  SUPPORT_ADJUSTED,
  SUPPORT_UNADJUSTED, // aka WORKPLACE_ADJUSTED
  RECEIVED_BREAKS,
}

export const TimesheetReasonCodeToText = {
  [PayrollStatusReason.NO_ADJUSTMENTS]: "Crew Member timestamps accepted",
  [PayrollStatusReason.RESIGNED]: "Resigned before shift start",
  [PayrollStatusReason.NO_CALL_NO_SHOW]: "No call no show",
  [PayrollStatusReason.WALK_OFF]: "Crew Member walk-off",
  [PayrollStatusReason.TIME_THEFT]: "Time theft (Crew Member did not show up to entire shift)",
  [PayrollStatusReason.UNQUALIFIED]: "Sent home - unqualified",
  [PayrollStatusReason.NO_LONGER_NEEDED]: "Sent home - lack of work (overfill)",
  [PayrollStatusReason.SUPPORT_UNADJUSTED]: "Crew Member submitted incorrect hours",
  [PayrollStatusReason.RECEIVED_BREAKS]: "Crew Member received required lunch break(s)",
  [PayrollStatusReason.SUPPORT_ADJUSTED]: "Support adjustment required",
};

export type PayrollStatusReasonAndText = {
  code: PayrollStatusReason;
  text: string;
};

// TODO(@dchhina): Bake the usages here into the backend permissions model, using PermissionsContext
export const TimesheetSupportUserTypes = [
  UserType.ADMIN,
  UserType.BRANCH_OPS,
  UserType.PAYROLL_ADMIN,
  UserType.SUPER_ADMIN,
];

export type TableCollapseFormat = {
  formattedDate: string;
  headerDate: string;
  rows: TimesheetsShiftInfo[];
  isExpanded: boolean;
};

export enum TimesheetTabs {
  PENDING_REVIEW,
  APPROVED,
  DISPUTED,
  REMOVED,
}

export const TimesheetTableTabPayrollStatusMap = {
  [TimesheetTabs.PENDING_REVIEW]: [
    PayrollHoursStatus.NEEDS_VERIFICATION,
    PayrollHoursStatus.NO_RESPONSE,
    PayrollHoursStatus.FOLLOW_UP,
  ],
  [TimesheetTabs.APPROVED]: [PayrollHoursStatus.APPROVED],
  [TimesheetTabs.DISPUTED]: [PayrollHoursStatus.DISPUTED],
  [TimesheetTabs.REMOVED]: [PayrollHoursStatus.REJECTED, PayrollHoursStatus.DAY_OFF],
} as const;

export type TimesheetsFiltersType = {
  search: {
    userName: string;
  };
  position: Pick<TimesheetsJobInfo, "name">;
  supervisor: Pick<TimesheetsJobInfo, "supervisorName">;
};

export type TimesheetsFilterOptions = {
  userName: string[];
  jobName: string[];
  jobShiftSupervisor: string[];
};

export type ApproveShiftRequest = {
  userId: string;
  jobId: string;
  shiftIndex: number;
  shiftStart: Date;
  shiftEnd: Date;
  lunchDurationMinutes: number;
  reason?: PayrollStatusReason;
};

export type ExcuseShiftRequest = {
  userId: string;
  jobId: string;
  shiftIndex: number;
  reason: PayrollStatusReason;
};

export type RejectShiftRequest = {
  userId: string;
  jobId: string;
  shiftIndex: number;
  reason: PayrollStatusReason;
};

export type DisputeShiftRequest = {
  userId: string;
  jobId: string;
  shiftIndex: number;
  shiftInfo: TimesheetsShiftInfo;
  wpUserId: string;
  disputeInfo: ShiftAdjustment;
};

export enum PayrollAction {
  APPROVE,
  DISPUTE,
  REJECT,
  Excuse,
  UNDO,
}

export type TimecardStatusCounts = {
  pendingReview: number;
  approved: number;
  disputed: number;
  removed: number;
}
