import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchSchedule, updateSchedule } from "../schedule";

export const useGetScheduleQuery = (scheduleId: string, enabled?: boolean) =>
  useQuery(
    ["schedule"],
    () =>
      fetchSchedule({
        scheduleId: parseInt(scheduleId, 10),
      }),
    { select: (data) => data.schedule, enabled: enabled ?? true },
  );

// This mutation does not currently support confirmation modal actions
export const useUpdateScheduleMutation = () =>
  useMutation(({ payload, scheduleId }: any) =>
    updateSchedule({
      scheduleId,
      payload,
    }),
  );
