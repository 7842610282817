import React from "react";
import { CrewGroupListItem, CrewGroupsTabProps } from "../../types/propTypes/CrewGroupsTab.types";
import { SelectionList } from "../../../../components/SelectionList";
import { CrewGroupListItem as CrewGroupListItemR } from "../../../../components/SelectionList/CrewGroupListItem";

export const CrewGroupsTab = ({ items, onItemClick, rootRef }: CrewGroupsTabProps) => (
  <SelectionList<CrewGroupListItem>
    rootRef={rootRef}
    items={items}
    listItemRenderer={CrewGroupListItemR}
    onItemClick={onItemClick}
  />
);
