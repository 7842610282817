import React from "react";
import { EmptyPositions } from "./EmptyState";
import { Can } from "../../PermissionsContext";
import { StyledBanner } from "../SharedComponents";
import { PositionListContainer } from "./styledComponents";

type PositionsType = any;

type PositionsListProps = {
  children?: React.ReactNode;
  sortedPositions: Array<PositionsType>;
};

export const PositionsList: React.FC<PositionsListProps> = ({ sortedPositions, children }) => (
  <>
    <Can not I="post" a="company_jobs">
      <StyledBanner
        data-testid="NotAllowedToPost"
        icon={`${import.meta.env.VITE_ASSET_BASE_URL}/icons/alarm-bell-ring.svg`}
        message="This company has been disabled and cannot post jobs. Please contact an
          admin to re-enable this company account."
      />
      {sortedPositions.length > 0 && (
        <PositionListContainer data-testid="PositionsList-container">
          {children}
        </PositionListContainer>
      )}
    </Can>

    <Can I="post" a="company_jobs">
      <PositionListContainer data-testid="PositionsList-container">
        {sortedPositions.length === 0 && <EmptyPositions />}
        {sortedPositions.length > 0 && <>{children}</>}
      </PositionListContainer>
    </Can>
  </>
);
