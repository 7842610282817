import moment from "moment";
import { Schedule } from "../../../api/bluecrew/types";
import { getQueryParam } from "../../../utility";
import { ScheduleType } from "../../GetCrewMembers/types/propTypes/Schedule.types";
import { DEFAULT_GET_CREW_VALUES } from "./getCrewFormValuesUtils";
import { getPreselectedSchedule } from "./utils";

export const getDirectInvitePositionIdFromUrl = (directInviteUserIds: string | undefined) => {
  const params = new URL(window.location.toString()).searchParams;
  const positionId = params.get("position");
  const hasDirectInviteParams = !!directInviteUserIds;

  if (hasDirectInviteParams && positionId) {
    return positionId;
  }
  return undefined;
};

export const getDirectInviteUserIdsFromUrl = () => {
  if (!window.location.search.match(/A?directInviteUserIds=/)) return undefined;

  const directInviteUserIds = getQueryParam(window.location, "directInviteUserIds");

  if (directInviteUserIds) {
    return directInviteUserIds;
  }

  return undefined;
};

export const getHasDirectInviteParams = (directInviteUserIds: string | undefined) =>
  !!directInviteUserIds;

export const parseDirectInviteUserIds = (unparsedDirectInviteUserIds: string) => {
  try {
    const parsedDirectInviteUserIds: string | string[] = JSON.parse(unparsedDirectInviteUserIds);
    return parsedDirectInviteUserIds;
  } catch (error) {
    console.error(
      `Invalid direct invite user ids: ${unparsedDirectInviteUserIds || "null"}`,
      error,
    );
  }
  return undefined;
};

export const getScheduleTypeFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;
  const scheduleType = params.get("scheduleType") || "";
  const formattedScheduleType = parseInt(scheduleType, 10) as ScheduleType;
  if (formattedScheduleType in ScheduleType) {
    return formattedScheduleType;
  }
  return undefined;
};

export const getPositionIdFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;
  const positionId = params.get("positionId");

  const parsedPositionId = Number(positionId);
  if (parsedPositionId) return parsedPositionId;
  return undefined;
};

export const getSingleAssignmentViewFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;

  const singleAssignmentView = params.get("singleAssignmentView") || false;
  return !!singleAssignmentView;
};

const MILITARY_TIME_REGEX = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/; // "HH:MM";

export const getStartTimeFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;
  const startTime = decodeURIComponent(params.get("startTime") || "");
  if (MILITARY_TIME_REGEX.test(startTime)) {
    return startTime;
  }
  return undefined;
};

export const getEndTimeFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;
  const endTime = decodeURIComponent(params.get("endTime") || "");
  if (MILITARY_TIME_REGEX.test(endTime)) {
    return endTime;
  }
  return undefined;
};

const formatDateToMomentType = (date: string | number) => moment(date, "MM/DD/YYYY").toDate();

const DATE_REGEX = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/; // MM-DD-YYYY

export const getStartShiftDateFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;
  const startShiftDate = params.get("startShiftDate") || "";
  return DATE_REGEX.test(startShiftDate) ? formatDateToMomentType(startShiftDate) : undefined;
};

export const getEndShiftDateFromUrl = () => {
  const params = new URL(window.location.toString()).searchParams;
  const endShiftDate = params.get("endShiftDate") || "";
  return DATE_REGEX.test(endShiftDate) ? formatDateToMomentType(endShiftDate) : undefined;
};

export const getSingleShiftDatesFromUrl = (): Date[] => {
  const params = new URL(window.location.toString()).searchParams;
  const shiftDates = params.get("shiftDate") || "";
  const shiftDateArray = shiftDates.split(",");
  shiftDateArray.filter((shiftDate) => DATE_REGEX.test(shiftDate));

  const dateArray: Date[] = [];
  shiftDateArray.forEach((shiftDate, index) => {
    const formattedDate = formatDateToMomentType(shiftDate);
    if (formattedDate instanceof Date && !Number.isNaN(formattedDate.getTime())) {
      dateArray[index] = formattedDate;
    }
  });

  return dateArray;
};

export const getShiftDate = ({
  scheduleType,
  shiftDates,
  startShiftDate,
  endShiftDate,
  isBulkSingleShiftEnabled,
}: {
  scheduleType?: ScheduleType;
  shiftDates: Date[];
  startShiftDate: Date | undefined;
  endShiftDate: Date | undefined;
  isBulkSingleShiftEnabled: boolean;
}) => {
  if (scheduleType === ScheduleType.SINGLE_SHIFT && isBulkSingleShiftEnabled) {
    return shiftDates;
  }

  if (startShiftDate && endShiftDate) {
    return [
      startShiftDate,
      scheduleType === ScheduleType.SINGLE_SHIFT ? startShiftDate : endShiftDate,
    ];
  }

  return undefined;
};

/**
 * This function is used to return search params from the URL for the get crew form
 */
export const getCrewValuesFromUrl = () => {
  const scheduleType = getScheduleTypeFromUrl();
  const positionId = getPositionIdFromUrl();
  const startTime = getStartTimeFromUrl();
  const endTime = getEndTimeFromUrl();
  const startShiftDate = getStartShiftDateFromUrl();
  const endShiftDate = getEndShiftDateFromUrl();
  const singleShiftDatesFromUrl = getSingleShiftDatesFromUrl();
  const directInviteUserIds = getDirectInviteUserIdsFromUrl();
  const directInvitePositionId = getDirectInvitePositionIdFromUrl(directInviteUserIds);

  return {
    scheduleType,
    positionId,
    startTime,
    endTime,
    startShiftDate,
    endShiftDate,
    singleShiftDatesFromUrl,
    directInviteUserIds,
    directInvitePositionId,
  };
};

type FormatCrewValuesFromUrlType = {
  scheduleType: ScheduleType | undefined;
  positionId: number | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
  startShiftDate: Date | undefined;
  endShiftDate: Date | undefined;
  singleShiftDatesFromUrl: Date[];
  isBulkSingleShiftEnabled: boolean;
  scheduleId: string | undefined;
  directInviteUserIds: string | undefined;
  directInvitePositionId: string | undefined;
  preselectedScheduleData: Schedule | undefined;
};

/**
 * It gathers the values from the URL and formats them to be used in the get crew form
 * It replaces undefined values from the URL with default values
 * It also formats the shiftDate value to be used in the get crew form
 * It also gets the preselected schedule based on the scheduleId from the URL
 */
export const formatCrewValuesFromUrl = ({
  scheduleType,
  positionId,
  startTime,
  endTime,
  startShiftDate,
  endShiftDate,
  singleShiftDatesFromUrl,
  isBulkSingleShiftEnabled,
  directInviteUserIds,
  directInvitePositionId,
  preselectedScheduleData,
  scheduleId,
}: FormatCrewValuesFromUrlType) => {
  const shiftDate = getShiftDate({
    scheduleType,
    shiftDates: singleShiftDatesFromUrl,
    startShiftDate,
    endShiftDate,
    isBulkSingleShiftEnabled,
  });

  const preselectedSchedule = getPreselectedSchedule(preselectedScheduleData, scheduleId);

  return {
    scheduleType: scheduleType ?? DEFAULT_GET_CREW_VALUES.scheduleType,
    positionId: positionId ?? DEFAULT_GET_CREW_VALUES.positionId,
    startTime: startTime ?? DEFAULT_GET_CREW_VALUES.startTime,
    endTime: endTime ?? DEFAULT_GET_CREW_VALUES.endTime,
    preselectedSchedule: preselectedSchedule ?? DEFAULT_GET_CREW_VALUES.schedule,
    shiftDate: shiftDate ?? DEFAULT_GET_CREW_VALUES.shiftDate,
    directInviteUserIds: directInviteUserIds ?? DEFAULT_GET_CREW_VALUES.directInviteUserIds,
    directInvitePositionId: directInvitePositionId ?? DEFAULT_GET_CREW_VALUES.positionId,
  };
};
