import React from "react";
import { Box, Field, Heading, Image, Input, Paragraph, Divider } from "@bluecrew/web-react-core";
import { Field as FormikField } from "formik";
import MultistepForm, { ImplicitValidatorProp } from "../../../components/MultistepForm";
import { calculateStartToEndTimeDuration } from "../../../utility/index";
import { InputContainer, PageContainer, TimeSelectorGroup } from "./styledComponents";
import { PageCopy } from "./types";
import { MAX_SHIFT_LENGTH_HOURS } from "../../../../shared/constants";

type TimeProps = {
  iconPath: string;
  pageCopy: PageCopy;
  validate: ImplicitValidatorProp;
};

export const ModifyTimes = ({ iconPath, pageCopy }: TimeProps) => (
  <PageContainer>
    {/* @ts-ignore */}
    <MultistepForm.Page>
      <Image className="modal-icon" src={iconPath} />
      <Heading as="h3">{pageCopy.header}</Heading>
      <Paragraph>{pageCopy.subheader}</Paragraph>
      <Divider />
      {/* START TIME PICKER */}
      <Box>
        {/* @ts-ignore */}
        <TimeSelectorGroup>
          {/* TODO: extract to TimeSelector component */}
          <InputContainer>
            <Field>
              <FormikField
                name="start_time"
                render={({ form: { errors, setFieldTouched, setFieldValue, touched, values } }) => (
                  <>
                    <Input
                      type="time"
                      value={values.start_time}
                      onChange={(e) => {
                        setFieldTouched("start_time");
                        setFieldValue("start_time", e.target.value);
                      }}
                      step="900" // TODO: add onBlur handler to round to nearest 15min increment, if value is not multiple of 15 #BCEMPL-588
                    />
                    {(touched.start_time || touched.end_time) && errors.times && (
                      <Box className="error-message bottom-form-error">{errors.times}</Box>
                    )}
                  </>
                )}
              />
            </Field>
          </InputContainer>
          <InputContainer>
            <Field>
              <FormikField
                name="end_time"
                render={({ form: { touched, errors, setFieldTouched, values, setFieldValue } }) => {
                  const duration = calculateStartToEndTimeDuration(
                    values.start_time,
                    values.end_time,
                  );
                  return (
                    <>
                      <Input
                        type="time"
                        value={values.end_time}
                        onChange={(e) => {
                          setFieldTouched("end_time");
                          setFieldValue("end_time", e.target.value);
                        }}
                        step="900"
                      />
                      {(touched.start_time || touched.end_time) && (
                        <>
                          {duration >= MAX_SHIFT_LENGTH_HOURS && (
                            <Box className="error-message bottom-form-error">
                              Warning: you are creating a shift longer than 12 hours!
                            </Box>
                          )}
                          {errors.end_time && (
                            <Box className="error-message bottom-form-error">{errors.end_time}</Box>
                          )}
                          {errors.duration && (
                            <Box className="error-message bottom-form-error">{errors.duration}</Box>
                          )}
                        </>
                      )}
                    </>
                  );
                }}
              />
            </Field>
          </InputContainer>
        </TimeSelectorGroup>
      </Box>
      {/* END TIME PICKER */}
    </MultistepForm.Page>
  </PageContainer>
);
