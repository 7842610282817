import React from "react";
import moment from "moment-timezone";
// @ts-ignore TODO:(BW-1002) Fix import from web-react-core
import FullVaccinatedIcon from "@bluecrew/web-react-core/src/assets/icons/full-vaccinated-icon.svg";
// @ts-ignore TODO:(BW-1002) Fix import from web-react-core
import TestedForCovidIcon from "@bluecrew/web-react-core/src/assets/icons/tested-for-covid-icon.svg";
// @ts-ignore TODO:(BW-1002) Fix import from web-react-core
import ExemptFromVaccinationIcon from "@bluecrew/web-react-core/src/assets/icons/exempt-from-vaccination-icon.svg";
import { TooltipMessage } from "../TooltipMessage";
import { StyledContainer, VaccinationIcon, VaccinationStatusContainer } from "./styledComponents";

interface CovidPolicyStatusProps {
  covidVaccinationDate: string;
  covidTestingLatestDate: string;
  covidExemption: string;
  timezone?: string;
}

export const CovidPolicyStatus = ({
  covidVaccinationDate,
  covidTestingLatestDate,
  covidExemption,
  timezone,
}: CovidPolicyStatusProps) => (
  <VaccinationStatusContainer>
    {covidVaccinationDate && (
      <StyledContainer>
        <TooltipMessage placement="bottom" message="Full vaccinated against Covid-19">
          <VaccinationIcon src={FullVaccinatedIcon} />
        </TooltipMessage>
      </StyledContainer>
    )}
    {covidTestingLatestDate && !covidExemption && (
      <StyledContainer>
        <TooltipMessage
          placement="top"
          message={`Tested negative for COVID, valid through${" "} ${moment(covidTestingLatestDate)
            .tz(timezone === undefined ? "" : timezone)
            .format("ll")}`}
        >
          <VaccinationIcon src={TestedForCovidIcon} />
        </TooltipMessage>
      </StyledContainer>
    )}
    {covidExemption && (
      <>
        <StyledContainer>
          <TooltipMessage
            placement="bottom"
            message="Medically or religiously exempt from COVID vaccination mandates"
          >
            <VaccinationIcon src={ExemptFromVaccinationIcon} />
          </TooltipMessage>
        </StyledContainer>
        {covidTestingLatestDate && (
          <StyledContainer>
            <TooltipMessage
              placement="bottom"
              message={`Tested negative for COVID, valid through${" "}
                ${moment().format("ll")}`}
              relativeToParentElem
            >
              <VaccinationIcon src={TestedForCovidIcon} />
            </TooltipMessage>
          </StyledContainer>
        )}
      </>
    )}
  </VaccinationStatusContainer>
);
