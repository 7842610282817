import React from "react";
import { Backdrop, Spinner, Portal, Heading } from "@bluecrew/web-react-core";
import { BasicModalBody, SpinnerShell, StyledOverlay } from "./styledComponents";

interface LoadingModalProps {
  message: string;
}

export const LoadingModal = ({ message }: LoadingModalProps) => (
  <div>
    <Portal>
      <Backdrop fade visible />
      <StyledOverlay fade visible>
        <BasicModalBody>
          <Heading as="h3">{message || "Loading..."}</Heading>
          <SpinnerShell>
            <Spinner />
          </SpinnerShell>
        </BasicModalBody>
      </StyledOverlay>
    </Portal>
  </div>
);
