import { handleActions } from "redux-actions";
import confirmationModalActions from "../actions/confirmationModal";

const handlerMap = {
  [confirmationModalActions.openModal]: (state: ConfirmationModalState, action) => {
    const { body, contentType, heading, redirectPath, responseType } = action.payload;
    return {
      ...state,
      body,
      contentType,
      heading,
      redirectPath,
      responseType,
      show: true,
    };
  },
  [confirmationModalActions.closeModal]: (state: ConfirmationModalState) => ({
    ...state,
    body: null,
    contentType: null,
    heading: null,
    redirectPath: null,
    responseType: null,
    show: false,
  }),
};

const initialState = {
  show: false,
  contentType: null,
  redirectPath: null,
  responseType: null,
};

export type ConfirmationModalState = typeof initialState;

export default handleActions<ConfirmationModalState>(handlerMap, initialState);
