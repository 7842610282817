import { Dispatch } from "redux";
import { ReasonCode } from "../../api/bluecrew/types";
import { cancelRequests } from "../../redux/actions/schedule";

// If the timestamp is not in milliseconds, it can't be converted to correct Date object
export const convertSecondsToMillis = (sec: number): number => {
  return sec * 1000;
};

export const sortByDate = (start_date_time_a: Date, start_date_time_b: Date, desc: boolean) => {
  const timeA = new Date(start_date_time_a).getTime();
  const timeB = new Date(start_date_time_b).getTime();
  return desc ? timeB - timeA : timeA - timeB;
};

export const compareHours = (rowA: any, rowB: any, id: string) => {
  const timeA = new Date(convertSecondsToMillis(rowA.values[id])).getHours();
  const timeB = new Date(convertSecondsToMillis(rowB.values[id])).getHours();

  if (timeA === timeB) {
    return 0;
  }
  if (timeA > timeB) {
    return 1;
  }
  return -1;
};

export const compareDateTime = (rowA: any, rowB: any, id: string) => {
  const timeA = new Date(rowA.values[id]).getTime() || -1;
  const timeB = new Date(rowB.values[id]).getTime() || -1;
  if (timeA === timeB) {
    return 0;
  }
  if (timeA > timeB) {
    return 1;
  }
  return -1;
};

export const compareFillStatus = (
  a_filled: number,
  b_filled: number,
  a_needed: number,
  b_needed: number,
  desc: boolean,
) => {
  const valueA = a_filled / a_needed;
  const valueB = b_filled / b_needed;
  return desc ? valueB - valueA : valueA - valueB;
};

export const handleCancelRepeatingSchedule = async (
  jobExternalIds: string[],
  reasonCode: ReasonCode | undefined,
  reasonText: string | undefined,
  dispatch: Dispatch<any>,
) => {
  dispatch(
    cancelRequests({
      jobIds: jobExternalIds,
      reasonCode,
      reasonText,
    }),
  );
};
