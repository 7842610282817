import { webV1Url } from "../../api/bluecrew/constants/index";

export const COMPANY_INFO_TITLE = "Company info";
export const BILLING_INFO_TITLE = "Billing info";
export const INVOICES_TITLE = "Invoices";
export const REFERRALS_TITLE = "Referrals";

export const accountMenuItems = [
  {
    title: "Your account",
    icon: "account-icon",
    url: `${webV1Url}/app.html#!/settings/profile`,
    key: "account",
  },
  {
    title: COMPANY_INFO_TITLE,
    icon: "company-icon",
    url: `${webV1Url}/app.html#!/settings/info`,
    key: "company-info",
  },
  {
    title: "Company users",
    icon: "company-users-icon",
    url: `${webV1Url}/app.html#!/settings/users`,
    key: "company-users",
  },
  {
    title: BILLING_INFO_TITLE,
    icon: "billing-info-icon",
    url: `${webV1Url}/app.html#!/settings/billing`,
    key: "billing-info",
    canIpermission: { I: "view", a: "payroll" },
  },
  {
    title: INVOICES_TITLE,
    icon: "invoices-icon",
    url: `${webV1Url}/app.html#!/settings/invoices`,
    key: "invoices",
    canIpermission: { I: "view", a: "payroll" },
  },
  // We are temporarily disabling Referrals page while the campaign is paused.
  // We will enable it back once the campaign is back alive.
  // {
  //   title: REFERRALS_TITLE,
  //   icon: "referrals-icon",
  //   url: `${webV1Url}/app.html#!/settings/referrals`,
  //   key: "referalls",
  //   canIpermission: { I: "create", a: "company_referral" },
  // },
  {
    title: "Help",
    icon: "help-icon",
    url: "https://help.bluecrewjobs.com/en/collections/3365179-client-support",
    key: "help",
  },
];
