import { Modal, ModalKind } from "@bluecrew/blueprint-web";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { StyledUserAvatar } from "../ManageUsersTable/styledComponents";
import {
  AssignCompaniesBodyContainter,
  AssignCompaniesModalFooter,
  CancelButton,
  CompaniesAssigned,
  CompaniesListingContainer,
  EmailAddress,
  PhoneNumber,
  RowAlign,
  UpdateButton,
  UserInfoContainer,
  UserName,
  SectionDivider,
} from "./styledComponents";
import { CompanySearchbar } from "../Sidebar/CompanySearchbar";
import { AssignCompaniesItem } from "./AssignCompaniesItem";
import {
  toNewUserRequest,
  isDummyInternalUser,
  mapUsersAssignedCompanies,
  updateRoleAndClearAssignments,
} from "./AssignCompaniesHelper";
import {
  UseCreateAndInviteNewUserError,
  useCreateAndInviteNewUser,
  useUpdateUsersCompanies,
  useUpdateUsersCompaniesAndRole,
} from "../../../api/bluecrew/hooks/user";
import { UpdateUsersCompaniesRequest } from "../../../api/bluecrew/user";
import { UserCompanyInfo } from "../../../api/bluecrew/types";
import { resetAssignCompaniesModal, setTableLoading } from "../slices/manageUsersModalSlice";
import { StateShape } from "../../../redux/reducers";
import {
  ASSIGN_COMPANIES_CANCEL_BUTTON,
  ASSIGN_COMPANIES_MODAL_BODY,
  DUPLICATE_USER_ERROR,
  ASSIGN_COMPANIES_UPDATE_BUTTON,
} from "../constants";
import { useAppSelector } from "../../../redux";

export type AssignCompaniesModalProps = {
  companies: UserCompanyInfo[];
  handleSuccessModal: (isSuccess: boolean, title: string, text: string) => void;
};

// This enum is used to determine the number of assigned companies for a user in specific cases.
enum AssignedCompaniesEnum {
  INVALID_USER = -1, // shouldn't happen but user is null, we can't determine the number of assigned companies
}

export const AssignCompaniesModal = ({
  companies,
  handleSuccessModal,
}: AssignCompaniesModalProps) => {
  // if they're in the set, they're assigned. If not, unassigned.
  const dispatch = useDispatch();
  const selectedUser = useAppSelector((state) => state.manageUsersModal.selectedUser);
  const selectedUserRole = useAppSelector(
    (state: StateShape) => state.manageUsersModal.selectedUserRole,
  );
  const showAssignCompaniesModal = useAppSelector(
    (state: StateShape) => state.manageUsersModal.showAssignCompaniesModal,
  );

  const [assignedCompanies, setAssignedCompanies] = useState<Set<number>>(new Set<number>());
  const [displayAssigned, setDisplayAssigned] = useState<UserCompanyInfo[]>(
    selectedUser?.companiesInfo ?? [],
  );

  const [companiesFilter, setCompaniesFilter] = useState("");
  // Hook for updating a current user's companies
  const { mutate: runUpdateUsersCompanies } = useUpdateUsersCompanies({
    onSuccess: (/* isUpdateSuccess */) => {
      handleSuccessModal(
        true,
        "User updated",
        "We have successfully updated the company permissions for the selected user.",
      );
    },
    onError: (/* updateError */) => {
      handleSuccessModal(
        false,
        "Could not update user",
        "We experienced an issue while updating this user.",
      );
    },
  });
  // Hook for creating a new user with company assignments
  const { mutate: runCreateAndAssign } = useCreateAndInviteNewUser({
    onSuccess: (/* newUser */) => {
      handleSuccessModal(
        true,
        `${selectedUser?.name} created`,
        "User has been created successfully",
      );
    },
    onError: (error: UseCreateAndInviteNewUserError) => {
      let errorTitle = "";
      let errorText = "";

      if (error.reason === DUPLICATE_USER_ERROR) {
        errorTitle = "User already exists";
        errorText = "An existing user with the same name and email address is currently an admin.";
      } else {
        errorTitle = "Could not create user";
        errorText = "We experienced an issue while creating this user.";
      }
      handleSuccessModal(false, errorTitle, errorText);
    },
  });

  const { mutate: runUpdateUsersCompaniesAndRole } = useUpdateUsersCompaniesAndRole({
    onSuccess: (/* isUpdateSuccess */) => {
      handleSuccessModal(
        true,
        "User updated",
        "We have successfully updated the company permissions for the selected user.",
      );
    },
    onError: (/* updateError */) => {
      handleSuccessModal(
        false,
        "Could not update user",
        "We experienced an issue while updating this user.",
      );
    },
  });

  const handleAssignedCompaniesChange = (company: UserCompanyInfo) => {
    // Was already assigned and was clicked -> unassign this company
    if (assignedCompanies.has(company.companyId)) {
      setAssignedCompanies((prevState) => {
        const newState = new Set(prevState);
        newState.delete(company.companyId);
        return newState;
      });
      setDisplayAssigned((prevState) => prevState.filter((c) => c.companyId !== company.companyId));
    } else {
      // Was unassigned and was clicked -> assign this company
      setAssignedCompanies(new Set(assignedCompanies).add(company.companyId));
      setDisplayAssigned((prevState) => [...prevState, company]);
    }
  };

  const filter = (e) => {
    setCompaniesFilter(e.target.value);
  };

  const getFilteredAssignedCompanies = () => {
    return displayAssigned.filter((company) =>
      company.companyName.toLowerCase().includes(companiesFilter.toLowerCase()),
    );
  };

  const getFilteredCompanies = () => {
    return companies.filter(
      (company) =>
        !assignedCompanies.has(company.companyId) &&
        company.companyName.toLowerCase().includes(companiesFilter.toLowerCase()),
    );
  };

  const onSubmitClick = () => {
    if (!selectedUser) return;
    // Passed from AddNewUserModal. Branch users aren't created immediately so they're assigned companies first.
    // Then passed to the same create runCreateNewUser mutation hook that's used to create new users.
    if (isDummyInternalUser(selectedUser)) {
      const newUserRequest = toNewUserRequest(selectedUser, [...assignedCompanies]);
      runCreateAndAssign(newUserRequest);
    } else {
      // Otherwise, it's an existing user. We just update the selected user's companies assignments.
      const flattenedAssignedCompanies = [...assignedCompanies];
      const updateCompAssignRequest: UpdateUsersCompaniesRequest = mapUsersAssignedCompanies(
        [selectedUser.userId],
        flattenedAssignedCompanies,
      );
      if (selectedUserRole !== "") {
        const updateCompaniesAndRole = updateRoleAndClearAssignments(
          selectedUser.externalId,
          selectedUser.userId,
          selectedUserRole,
          flattenedAssignedCompanies,
        );
        runUpdateUsersCompaniesAndRole(updateCompaniesAndRole);
      } else {
        runUpdateUsersCompanies(updateCompAssignRequest);
      }
    }
    setAssignedCompanies(new Set<number>());
    dispatch(resetAssignCompaniesModal());
    dispatch(setTableLoading(true));
  };

  function getNumAssignedCompanies(): number {
    if (selectedUser === undefined) return AssignedCompaniesEnum.INVALID_USER;
    return displayAssigned.length;
  }

  useEffect(() => {
    if (!selectedUser) return;
    const tempAssignedCompanies = new Set<number>();
    selectedUser.companyIds.forEach((companyId) => {
      tempAssignedCompanies.add(companyId);
    });
    setAssignedCompanies(tempAssignedCompanies);
    // Some InternalUsers have 'companiesInfo', so we can grab it from there, otherwise we'll just use the companyIds.
    if (selectedUser.companiesInfo) {
      setDisplayAssigned(selectedUser.companiesInfo);
    } else {
      setDisplayAssigned(companies.filter((company) => assignedCompanies.has(company.companyId)));
    }
  }, [selectedUser, selectedUserRole, showAssignCompaniesModal]);

  return (
    <Modal
      maxHeight={"100%"}
      width={"480px"}
      header={
        selectedUser ? (
          <RowAlign>
            <StyledUserAvatar
              size={"lg"}
              imgSrc={selectedUser.userPfp}
              fullName={selectedUser.name}
            />
            <UserInfoContainer>
              <UserName>{selectedUser.name}</UserName>
              <EmailAddress>{selectedUser.emailAddress}</EmailAddress>
              {selectedUser.phoneNumber && <PhoneNumber>{selectedUser.phoneNumber}</PhoneNumber>}
            </UserInfoContainer>
          </RowAlign>
        ) : undefined
      }
      body={
        <AssignCompaniesBodyContainter data-testid={ASSIGN_COMPANIES_MODAL_BODY}>
          <CompaniesAssigned>Companies assigned ({getNumAssignedCompanies()})</CompaniesAssigned>
          <CompanySearchbar onChange={filter} />
          <CompaniesListingContainer>
            {getFilteredAssignedCompanies().map((company) => {
              return (
                <AssignCompaniesItem
                  key={company.companyId}
                  company={company}
                  isChecked={assignedCompanies.has(company.companyId)}
                  onSelectionChange={handleAssignedCompaniesChange}
                />
              );
            })}
            <SectionDivider />
            {getFilteredCompanies().map((company) => {
              return (
                <AssignCompaniesItem
                  key={company.companyId}
                  company={company}
                  isChecked={assignedCompanies.has(company.companyId)}
                  onSelectionChange={handleAssignedCompaniesChange}
                />
              );
            })}
          </CompaniesListingContainer>
        </AssignCompaniesBodyContainter>
      }
      footer={
        <AssignCompaniesModalFooter>
          <CancelButton
            data-testid={ASSIGN_COMPANIES_CANCEL_BUTTON}
            onClick={() => {
              dispatch(resetAssignCompaniesModal());
              setAssignedCompanies(new Set<number>());
              setDisplayAssigned([]);
              setCompaniesFilter("");
            }}
          >
            Cancel
          </CancelButton>
          <UpdateButton
            data-testid={ASSIGN_COMPANIES_UPDATE_BUTTON}
            disabled={!assignedCompanies.size}
            onClick={onSubmitClick}
          >
            Update
          </UpdateButton>
        </AssignCompaniesModalFooter>
      }
      kind={ModalKind.DEFAULT}
      isOpen={showAssignCompaniesModal}
      withCloseButton={false}
    />
  );
};
