import React, { useEffect } from "react";
import { Switch } from "@bluecrew/blueprint-web";
import { styled, withStyle } from "baseui";
import { useController } from "react-hook-form";
import { CenteredWrapper } from "../CenteredWrapper";
import { BaseFieldProps } from "../types/propTypes/BaseField.types";

export interface OptimizedJobToggleFieldProps extends BaseFieldProps {
  isBlueshiftSchedule: boolean;
}

export const OptimizedJobToggleField = ({
  control,
  isBlueshiftSchedule,
}: OptimizedJobToggleFieldProps) => {
  const fieldName = "optimizedJob";

  const {
    field: { onChange, value = 0 },
  } = useController({
    name: fieldName,
    control,
  });

  useEffect(() => {
    onChange(Number(value));
  }, [value]);

  return (
    <DivWrapper $hidden={isBlueshiftSchedule}>
      <FieldWrapper>
        <Switch value={value} onChange={() => onChange(Number(!value))} />
        <TextWrapper>
          <BoldText>Manually review applications</BoldText>
          <StyledText>Takes 42% longer to fill.</StyledText>
        </TextWrapper>
      </FieldWrapper>
    </DivWrapper>
  );
};

type PropsT = { $hidden: boolean };
const DivWrapper = styled("div", ({ $hidden }: PropsT) => ({
  visibility: $hidden ? "hidden" : "visible",
}));

const TextWrapper = styled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
}));

const StyledText = styled("p", ({ $theme }) => ({
  margin: 0,
  ...$theme.typography.font200,
}));

const BoldText = withStyle(StyledText, () => ({
  fontWeight: 600,
}));

const FieldWrapper = withStyle(CenteredWrapper, () => ({
  marginLeft: "40px",
}));
