import styled from "styled-components";

export const ModalHeaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

export const ModalBodyWrapper = styled.div`
  padding: 32px;
`;

const ModalIconWrapper = styled.div`
  width: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 22px;
  padding-bottom: 26px;
`;

export const WarningModalIconWrapper = styled(ModalIconWrapper)`
  background-color: #f8790033;
`;

export const FailedModalIconWrapper = styled(ModalIconWrapper)`
  background-color: rgba(231, 46, 62, 0.2);
`;

export const Spacer = styled.div`
  margin-bottom: 16px;
`;

export const ModalHeader = styled.p`
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;
export const FlexDiv = styled.div`
  display: flex;
`;
export const ModalBoldText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 0;
`;

export const ModalText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`;

export const ModalFooterWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
