import { ValueOf } from "../../../../types/util-types";
import { UserType } from "../../../api/bluecrew/types";
import { INTERNAL_USER_ROLES } from "../constants";

// Enum for getUsers returning signup status using JavaScript
export const SignUpStatuses = {
  Expired: "INVITE_EXPIRED",
  Invited: "INVITED",
  Accepted: "INVITE_ACCEPTED",
  NotMigrated: "NOT_MIGRATED",
  ResetRequired: "RESET_REQUIRED",
  Unknown: "UNKNOWN",
} as const;
export type SignUpStatus = ValueOf<typeof SignUpStatuses>;

export function userCanBeReinvited(user: InternalUser): boolean {
  return (
    user.signUpStatus === SignUpStatuses.Invited || user.signUpStatus === SignUpStatuses.Expired
  );
}

// TODO: fix up this object, companyIds, assignedCompanies, assignedCompaniesIds and companiesInfo can be collasped to companiesInfo
export type InternalUser = {
  companyIds: number[];
  userId: number;
  externalId: string;
  name: string;
  emailAddress: string;
  phoneNumber: string | null;
  lastActive: string;
  assignedCompanies: number;
  assignedCompaniesIds: number[];
  userPfp: string | null;
  userRole: UserType;
  userOrder: number;
  companiesInfo?: [
    {
      companyId: number;
      companyName: string;
    },
  ];
} & SignUpStatusFields;

export type SignUpStatusFields = {
  invitedAt?: string | null;
  signUpStatus?: SignUpStatus;
};

export type InternalUsersResponse = {
  users: Array<InternalUserResponse>;
};

export type InternalUserResponse = {
  userId: number;
  firstName: string;
  lastName: string;
  type: number;
  email: string;
  phoneNumber: string;
  externalId: string;
  activeAt: string;
  url: string;
  companyIds: number[];
  allCompanyAccess: boolean;
  invitedAt?: string | null;
  signUpStatus?: SignUpStatus;
};

export const typeToOrder = {
  SUPER_ADMIN: 0,
  PAYROLL_ADMIN: 1,
  ADMIN: 2,
  BRANCH_OPS: 3,
  BRANCH_VIS: 4,
} as const;

/*
 * This function takes a phone number and formats it to be displayed in the format (123) 456-7890
 * @param phoneNumber - the phone number to be formatted
 * @returns the formatted phone number
 * @example
 * formatPhoneNumber("11234567890") -> (123) 456-7890
 */
const formatPhoneNumber = (phoneNumber: string | null) => {
  if (phoneNumber && phoneNumber.length > 10) {
    return phoneNumber
      .slice(phoneNumber.length - 10, phoneNumber.length)
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return phoneNumber ? phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : phoneNumber;
};

export const convertInternalUsersResponseToTableData = (data: InternalUsersResponse) => {
  return data.users
    .filter((InternalUser) => INTERNAL_USER_ROLES.includes(InternalUser.type))
    .map(
      ({
        userId,
        firstName,
        lastName,
        type,
        email,
        phoneNumber,
        externalId,
        activeAt,
        url,
        companyIds,
        signUpStatus,
        invitedAt,
      }) => ({
        userId,
        externalId,
        name: `${firstName} ${lastName}`,
        emailAddress: email,
        lastActive: activeAt,
        phoneNumber: formatPhoneNumber(phoneNumber),
        userRole: type,
        userOrder: typeToOrder[UserType[type]],
        assignedCompanies: companyIds.length,
        companyIds,
        userPfp: url,
        assignedCompaniesIds: companyIds,
        companiesInfo: companyIds.map((companyId) => ({
          companyId,
          companyName: "",
        })),
        signUpStatus,
        invitedAt,
      }),
    );
};
