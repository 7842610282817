import { CrewGroup, CrewMember } from "./ChooseCrewModal.types";

export enum ActiveTab {
  member = "0",
  group = "1",
}

export interface WorkforcePanelProps {
  crewMembers: Array<CrewMember>;
  crewGroups?: Array<CrewGroup>;
  selectedCrew?: Array<CrewMember | CrewGroup>;
  onSelectionChange?: (selected: Array<Omit<CrewMember, "externalId"> | CrewGroup>) => void;
  hideCrewGroups?: boolean;
}
