import { CrewGroup } from "../../types/propTypes/ChooseCrewModal.types";
import { CrewGroupListItem } from "../../types/propTypes/CrewGroupsTab.types";

export class CrewGroupMapper {
  static toListItem = (crewMember: CrewGroup, selected: boolean = false): CrewGroupListItem => ({
    id: crewMember.id,
    title: crewMember.name,
    membersCount: crewMember.members,
    selected,
  });

  static toModel = (listItem: CrewGroupListItem): CrewGroup => ({
    id: listItem.id,
    name: listItem.title,
    members: listItem.membersCount,
  });
}
