import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Contexts, AuthenticationResult, Statuses } from "./authenticationTypes";
import { AuthenticationError } from "./Errors/AuthenticationError";
import { CognitoUtils } from "../../utility/cognito";
import authActions from "../../redux/actions/auth";
import { LoginRouteStringsArray } from "../../../shared/constants";
import { translateError } from "./Errors";
import { setSentryUser } from "../../sentry";

function translateToAuthenticationResult(result: CognitoUser): AuthenticationResult {
  const username = result.getUsername();
  if (result.challengeName !== undefined) {
    if (result.challengeName === "SMS_MFA") {
      return { username, user: result, status: Statuses.ConfirmSignIn };
    }
    if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
      return { username, user: result, status: Statuses.ChangePassword };
    }
    throw Error(`Unsupported Challenge type ${result.challengeName}`);
  } else if (result.getSignInUserSession()?.getIdToken() != null) {
    return { username, user: result, status: Statuses.SignedIn };
  }
  throw Error(`An unexpected result was returned`, { cause: result });
}

/**
 * Function which can be used to initate the sign in process for a user.
 * If user does not have MFA enabled, SignInResult will contain a cognito user object with tokens
 * If user has MFA enabled, SignResult will contain a cognito user object with challenge type info
 */
const signIn = async (input: {
  username: string;
  password: string;
}): Promise<AuthenticationResult> => {
  const { username, password } = input;
  const user: CognitoUser = await Auth.signIn(username.toLowerCase(), password);
  return translateToAuthenticationResult(user);
};

export const useSignIn = (
  onSuccess: (result: AuthenticationResult) => void,
  onError: (error: AuthenticationError) => void,
) =>
  useMutation({
    mutationKey: ["Login", "SignIn"],
    mutationFn: signIn,
    onSuccess,
    onError: (error: Error) => onError(translateError(error, Contexts.SignIn)),
    retry: false,
  });

/**
 * Mutation hook which can be used to complete the sign in process for user who has MFA enabled and has logged in.
 */
export const useConfirmSignIn = (
  onSuccess: (result: AuthenticationResult) => void,
  onError: (error: AuthenticationError) => void,
) =>
  useMutation({
    mutationKey: ["Login", "ConfirmSignIn"],
    mutationFn: async (input: {
      user: CognitoUser | null;
      code: string;
    }): Promise<AuthenticationResult> => {
      const { user, code } = input;
      if (user === null) throw Error("CognitoUser cannot be null");

      const updatedUser: CognitoUser = await Auth.confirmSignIn(user, code, "SMS_MFA");
      return translateToAuthenticationResult(updatedUser);
    },
    onSuccess,
    onError: (error: Error) => onError(translateError(error, Contexts.ConfirmSignIn)),
    retry: false,
  });

/**
 * Mutation hook which can be used to initiate a password reset for a user.
 */
export const useRequestPasswordReset = (
  onSuccess: (result: any) => void,
  onError: (error: AuthenticationError) => void,
) =>
  useMutation({
    mutationKey: ["Login", "RequestPasswordReset"],
    mutationFn: (input: { username: string }) => Auth.forgotPassword(input.username.toLowerCase()),
    onSuccess,
    onError: (error: Error) => {
      onError(translateError(error, Contexts.RequestReset));
    },
    retry: false,
  });

/**
 * Mutation hook which can be used to complete a password reset for a user.
 */
export const useConfirmPasswordReset = (
  onSuccess: (result: any) => void,
  onError: (error: Error) => void,
) =>
  useMutation({
    mutationKey: ["Login", "ConfirmPasswordReset"],
    mutationFn: (input: { username: string; code: string; password: string }) => {
      const { username, code, password } = input;
      return Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
    },
    onSuccess,
    onError: (error: Error) => onError(translateError(error, Contexts.ConfirmReset)),
    retry: false,
  });

export const useActivateAccount = (
  onSuccess: (result: AuthenticationResult) => void,
  onError: (error: AuthenticationError) => void,
) =>
  useMutation({
    mutationKey: ["Login", "ActivateAccount"],
    mutationFn: signIn, // Re-use signIn function because that's all the first step of account activation is; a sign in
    onSuccess,
    onError: (error: Error) => {
      onError(translateError(error, Contexts.ActivateAccount));
    },
    retry: false,
  });

/**
 * Used to set a users password after a user has authenticated with a temporary password.
 * This is only used during account activation.
 */
export const useChangePassword = (
  onSuccess: (result: AuthenticationResult) => void,
  onError: (error: AuthenticationError) => void,
) =>
  useMutation({
    mutationKey: ["Login", "ActivateAccount"],
    mutationFn: async (input: { user: CognitoUser; password: string }) => {
      const result = await Auth.completeNewPassword(input.user, input.password);
      return translateToAuthenticationResult(result);
    },
    onSuccess,
    onError: (error: any) => {
      onError(translateError(error, Contexts.ActivateAccount));
    },
    retry: false,
  });

/**
 * This function does not need to be awaited.
 * It exists to kick of legacy login sagas, which perform additional side
 * effects, e.g. get user details, permissions etc.
 *
 * Once the legacy token exchange completes, the rest of the app is rendered;
 * We control app rendering using computed loggedIn props in Main.tsx.
 */
export const usePostSignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  return async (username: string) => {
    // Record what the cognito user name is.
    // Cognito tokens are stored in local storage with a prefix, which includes
    // the cognito username. Store it with a known key so we can easily lookup
    // the cognito tokens without having to iterate through all keys in local storage
    await CognitoUtils.setCognitoUsernameAsync(username.toLowerCase());
    const externalUserId = CognitoUtils.getExternalUserId();

    setSentryUser(username, externalUserId);

    // This is also used to re-init stuff like Split and Intercom
    // after the page is refreshed
    dispatch(authActions.login.request({}));

    const path = location.pathname;
    // Make sure to navigate away from any login routes
    if (LoginRouteStringsArray.includes(path)) {
      navigate("/");
    }
  };
};
