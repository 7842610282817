import React from "react";
import DashboardPositionCertificationBadgeIcon from "../../../svg/DashboardPositionCertificationBadgeIcon";
import { Container, StyledText } from "./SharedComponents";

interface DashboardCertificationBadgeProps {
  label: string;
}

const DashboardCertificationBadge = ({ label }: DashboardCertificationBadgeProps) => (
  <Container>
    <DashboardPositionCertificationBadgeIcon />
    <StyledText>{label}</StyledText>
  </Container>
);

export default DashboardCertificationBadge;
