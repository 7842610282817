import { GeofenceDefaults } from "../../../../shared/constants";
import {
  DistanceUnit,
  GeofenceFieldUnit,
  GeofenceFieldType,
  GeofenceFieldMeta,
} from "../../../api/bluecrew/types";
import { geofenceStrings } from "../CompanySettingStringsEN";

export const radiusToMeters = (value: string | number, unit: GeofenceFieldUnit): number => {
  const num = Number(value);
  return unit === DistanceUnit.KILOMETERS ? num * 1000 : num;
};

export const switchUnits = (
  currentUnit: GeofenceFieldUnit,
  currentRadius: number,
  setUnit: (newUnit: string) => void,
  setRadius: (newRadius: number) => void,
) => {
  if (currentUnit === DistanceUnit.METERS && currentRadius >= 1000) {
    setRadius(currentRadius / 1000);
    setUnit(DistanceUnit.KILOMETERS);
    return;
  }
  if (currentUnit === DistanceUnit.KILOMETERS && currentRadius <= 0) {
    setRadius(999);
    setUnit(DistanceUnit.METERS);
  }
};

export const validate = (
  fieldName: string,
  meta: GeofenceFieldMeta,
  getValues: (fieldName: string) => any,
) => {
  const valueInMeters = (fname: string) =>
    radiusToMeters(getValues(fname), getValues(meta[fname].unitFieldName));
  const { fieldNames, type: fieldType } = meta[fieldName];

  // Check that field is within allowable range
  if (
    valueInMeters(fieldName) < GeofenceDefaults.minRadiusMeters &&
    fieldType === GeofenceFieldType.Warn
  ) {
    return geofenceStrings.validationWarnings.tooSmall;
  }

  if (
    valueInMeters(fieldName) < GeofenceDefaults.minBlockRadiusMeters &&
    fieldType === GeofenceFieldType.Block
  ) {
    return geofenceStrings.validationWarnings.tooSmallBlockRadius;
  }
  if (valueInMeters(fieldName) > GeofenceDefaults.maxRadiusMeters) {
    return geofenceStrings.validationWarnings.tooBig;
  }

  // Need to validate across fields to prevent block being less than warn
  const [warnName, blockName] = fieldNames;
  const warn = valueInMeters(warnName);
  const block = valueInMeters(blockName);
  if (warn + GeofenceDefaults.minWarnBlockRadiusDifferenceMeters > block) {
    return fieldType === GeofenceFieldType.Warn
      ? geofenceStrings.validationWarnings.warnMustBeLessThanBlock
      : geofenceStrings.validationWarnings.blockMustBeGreaterThanOrEq;
  }

  // RHF validate property of register is a bit odd.
  // It accepts functions that return true, or an
  // error msg as a string so we have to do the same.
  return true;
};
