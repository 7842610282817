import { KIND } from "baseui/toast";
import { ToastInfo } from "../../api/bluecrew/types";

export const SPLIT_IO_IS_BROKEN = "SPLIT_IO_IS_BROKEN";

export type SplitIoIsBrokenAction = {
  type: string;
  payload: typeof undefined;
};

export function splitIoIsBroken(): SplitIoIsBrokenAction {
  return {
    type: SPLIT_IO_IS_BROKEN,
    payload: undefined,
  };
}

export const SHOW_TOAST = "SHOW_TOAST";
export type ShowToastAction = {
  type: typeof SHOW_TOAST;
  payload: ToastInfo;
};

export const showToastAction = (message: string, kind: KIND[keyof KIND]): ShowToastAction => ({
  type: SHOW_TOAST,
  payload: {
    visible: true,
    date: Date.now(),
    message,
    kind,
  },
});
