import React from "react";
import { Paragraph, UserAvatar, Button } from "@bluecrew/web-react-core";
import { Modal, ModalKind } from "@bluecrew/blueprint-web";
import {
  CrewMembers,
  ModalWrapper,
  ButtonWrapper,
  AvatarContainer,
  TertiaryButton,
} from "../styledComponents";
import { covidPolicyStrings, commonButtonStrings } from "../CompanySettingStringsEN";
import { ConfirmCancellationsCrewMember } from "../../../api/bluecrew/company";
import { VaccinePolicies } from "../../../api/bluecrew/types";
import { ValueOf } from "../../../../types/util-types";

type ConfirmCancellationsProps = {
  potentialCancellations: Array<ConfirmCancellationsCrewMember>;
  newCovidPolicyValue: ValueOf<typeof VaccinePolicies>;
  modalOpen: boolean;
  setModalOpen: (modalOpenNewValue: boolean) => void;
  handleUpdateClick: (newCovidPolicyValue: ValueOf<typeof VaccinePolicies>) => void;
};

export const ConfirmCancellations = ({
  potentialCancellations,
  newCovidPolicyValue,
  modalOpen,
  setModalOpen,
  handleUpdateClick,
}: ConfirmCancellationsProps) => (
  <Modal
    width="45rem"
    body={
      <ModalWrapper>
        <Paragraph>
          {potentialCancellations.length}
          {covidPolicyStrings.modal.text}
        </Paragraph>
        {/* Display all of the CMs with assignments that could be canceled if the policy was updated */}
        <CrewMembers>
          {potentialCancellations.map((cm) => (
            <div className="crew-member" key={cm.id}>
              <AvatarContainer>
                <UserAvatar
                  imgSrc={cm.avatar_url}
                  fullName={`${cm.first_name} ${cm.last_name}`}
                  size="md"
                />
              </AvatarContainer>
              <div className="cm-info">
                <span className="name">
                  {cm.first_name} {cm.last_name}
                </span>
                <span className="shift-count">
                  {`${cm.shift_count} ${covidPolicyStrings.upcomingShifts}`}
                </span>
              </div>
            </div>
          ))}
        </CrewMembers>
        <ButtonWrapper>
          <TertiaryButton onClick={() => setModalOpen(false)}>
            {commonButtonStrings.cancel}
          </TertiaryButton>
          <Button palette="primary" onClick={() => handleUpdateClick(newCovidPolicyValue)}>
            {commonButtonStrings.update}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    }
    header={covidPolicyStrings.modal.header}
    isOpen={modalOpen}
    kind={ModalKind.DEFAULT}
    withCloseButton={false}
  />
);
