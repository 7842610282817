// AUTH - SAGA
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, call, select, put, takeLatest } from "redux-saga/effects";

import isEmpty from "lodash/isEmpty";
import { initSplitSdk, getTreatments } from "@splitsoftware/splitio-redux";
import { logError } from "../../sentry";
import * as authAPI from "../../api/bluecrew/auth";
import { splitIoIsBroken } from "../actions/app";
import authActions, { TokenRequestAction } from "../actions/auth";
import companyActions from "../actions/company";
import segmentActions from "../actions/segment";

import { getIsSplitioReady } from "../selectors/splitio";
import { ACTIVE_SPLIT_NAMES, approvedRedirectUrls } from "../../../shared/constants";
import { IntercomGlobalVariableName } from "../../intercom";
import intercomActions from "../actions/intercom";
import { eraseCookie } from "../../utility";
import { getUserDetails } from "../../api/bluecrew/user";
import { getIsLoggedIn } from "../../utility/auth";
import { CognitoUtils } from "../../utility/cognito";

const splitEnabled = import.meta.env.VITE_DISABLE_3RD_PARTIES === "false";

function* loginSaga(action: any): Generator<Effect, void, any> {
  try {
    let response: any = null;
    if (action?.payload?.username && action.payload.password) {
      // If username and password are present, then the legacy login system is being used
      response = yield call(authAPI.login, action.payload);
    } else if (getIsLoggedIn()) {
      // If username and password were not passed to the saga, then cognito
      // authentication is in use; User has already been authenticated via Cognito
      // and as such the username and password are not passed to the saga.
      //
      // We still trigger this saga when that occurs so user details are filled in
      // TODO(dloukusa-bc@) Move this into login success and delete this saga, along with the actions & reducers for login request
      response = yield call(getUserDetails);
    } else throw new Error("User not authenticated");
    const {
      user: { company_id: companyId },
    } = response;

    yield put(authActions.login.success(response));
    yield put(
      companyActions.getCompanyDetails.request({
        companyId,
      }),
    );
  } catch (error) {
    yield put(authActions.login.failure(error));
  }
}

function* loginSuccessSaga(): Generator<Effect, void, any> {
  yield put(intercomActions.intercomData.request());
  // This action invokes segmentPendoIdentifySaga to initialize
  // Segment and Pendo with user traits from api-v1
  yield put(segmentActions.identifyTraits.request());
  const redirectUrl = CognitoUtils.getRedirectUrl();
  if (redirectUrl && approvedRedirectUrls.includes(redirectUrl)) {
    CognitoUtils.removeRedirectUrl(); // Reset to not cause any further unnecessary redirects
    window.location.href = redirectUrl;
  }
}

function* tokenSaga(action: TokenRequestAction): Generator<Effect, void, any> {
  try {
    const response = yield call(authAPI.token, action.payload);
    const {
      user: { company_id: companyId },
    } = response;
    yield put(authActions.login.success(response));
    yield put(
      companyActions.getCompanyDetails.request({
        companyId,
      }),
    );
  } catch (error) {
    yield put(authActions.login.failure(error));
    // If we failed to refresh the legacy tokens, log the user out
    yield put(authActions.logout.request());
  }
}

function* logoutSaga(): Generator<Effect, void, any> {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i += 1) {
    eraseCookie(cookies[i].split("=")[0]);
  }

  // Shutdown Intercom widget on logout
  if (window[IntercomGlobalVariableName]) {
    window[IntercomGlobalVariableName]("shutdown");
  }
  // Clear Pendo user data
  if (window?.pendo?.clearSession) {
    // If adblock is enabled, Pendo cannot be initialized and clearSession is not available.
    window.pendo.clearSession();
  }

  localStorage.clear();

  yield put(authActions.login.destroy());
  yield put(authActions.logout.success());
}

function* initSplitSaga(action: any): Generator<Effect, void, any> {
  const { user, company } = action.payload;

  // TODO: Split is currently being re-initialized everytime on login success and getCompanyDetails success.
  // if this is causing issues in the future, figure out a way to handle this intializaiton appropriately
  try {
    // initializing the Split sdk only when we have user data available
    if (!isEmpty(user)) {
      // This starts a async thunk to initialize the SDK
      if (!splitEnabled) {
        yield put(splitIoIsBroken());
      } else {
        yield put(
          // @ts-ignore
          initSplitSdk({
            config: {
              core: {
                authorizationKey: import.meta.env.VITE_SPLIT_IO_BROWSER_API_KEY,
                key: user.id,
              },
            },
          }),
        );
      }
    }

    // setting the company_external_id only when we have company data available
    if (!isEmpty(company)) {
      const attributes = {
        company_external_id: company.external_id,
        company_id: company.id,
      };
      if (splitEnabled) {
        // Even though the SDK is not ready yet, this will Q the action
        yield put(
          // @ts-ignore
          getTreatments({ splitNames: ACTIVE_SPLIT_NAMES, attributes }),
        );
      } else {
        yield put(splitIoIsBroken());
      }
    }
  } catch (error) {
    // Report errors but don't throw.
    if (error instanceof Error) logError({ error, context: "Initializing split.io" });
  }

  // Check to see if split.io never becomes ready, which could indicate
  // a catastrophic error. If this happens, the app could appear broken.
  const timeout = 3000;
  // eslint-disable-next-line no-promise-executor-return
  yield call(() => new Promise((resolve) => setTimeout(resolve, timeout)));

  const isReady = yield select(getIsSplitioReady);
  if (!isReady) {
    // NOTE: even for split errors, we don't expect this to ever happen.
    yield put(splitIoIsBroken());
    logError({
      error: new Error(`split.io was not ready after ${timeout} milleseconds`),
    });
  }
}

export default {
  *watchLoginSaga(): SagaIterator {
    yield takeLatest(authActions.login.request.toString(), loginSaga);
  },
  *watchLoginSuccessSaga(): SagaIterator {
    const loginSuccess = authActions.login.success.toString();
    const getCompanyDetailsSuccess = companyActions.getCompanyDetails.success.toString();

    yield takeLatest(loginSuccess, loginSuccessSaga);

    // combining actions here to expose the company external_id within the initSplitSaga
    // this gives us payloads from both loginSuccess and getCompanyDetailsSuccess actions
    yield takeLatest([loginSuccess, getCompanyDetailsSuccess], initSplitSaga);
  },
  *watchTokenSaga(): SagaIterator {
    yield takeLatest(authActions.token.request.toString(), tokenSaga);
  },
  *watchLogoutSaga(): SagaIterator {
    yield takeLatest(authActions.logout.request.toString(), logoutSaga);
  },
};
