import React from "react";
import { Can } from "../../../PermissionsContext";
import { StyledDeleteButton } from "../../SharedComponents";
import { DashboardPositionJob, DashboardPositionJobTypes } from "../../../../api/bluecrew/types";

interface RequestActionsButtonsCellProps {
  postJobsPermission: boolean;
  rowData: DashboardPositionJob;
  updateCancelSchedule: React.Dispatch<
    React.SetStateAction<{
      jobExternalIds: string[];
      show: boolean;
      type: DashboardPositionJobTypes;
      scheduleId: number;
      workerShifts: number;
    }>
  >;
}

export const RequestActionsButtonsCell = ({
  postJobsPermission,
  rowData,
  updateCancelSchedule,
}: RequestActionsButtonsCellProps) => (
  <Can I="update" a="job">
    <StyledDeleteButton
      disabled={!postJobsPermission}
      onClick={() => {
        updateCancelSchedule({
          jobExternalIds: [rowData.external_id],
          show: true,
          scheduleId: rowData.schedule_id,
          type: rowData.type,
          workerShifts: rowData.shifts_needed,
        });
      }}
      title={"Cancel request"}
    >
      <div>Cancel</div>
    </StyledDeleteButton>
  </Can>
);
