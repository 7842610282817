import React from "react";
import { Button, Modal, ModalKind } from "@bluecrew/blueprint-web";
import { PartialRequestErrorModalData } from "../../../api/bluecrew/types";
import { RequestFailedForUnknownReasonBody } from "./RequestFailedForUnknownReasonBody";
import { PartialRequestFailBody } from "./PartialRequestFailBody";
import { ModalFooterWrapper } from "./styledComponents";

interface ErrorModalProps {
  modalOpen: boolean;
  closeModal: () => void;
  partialRequestFail: boolean;
  failedBulkSingleShifts: PartialRequestErrorModalData[];
  successfulBulkSingleShifts: PartialRequestErrorModalData[];
}

export const ErrorModal = ({
  modalOpen,
  closeModal,
  partialRequestFail,
  failedBulkSingleShifts,
  successfulBulkSingleShifts,
}: ErrorModalProps) => (
  <Modal
    kind={ModalKind.DEFAULT}
    width={partialRequestFail ? "460px" : "396px"}
    body={
      successfulBulkSingleShifts?.length > 0 ? (
        <PartialRequestFailBody
          failedBulkSingleShifts={failedBulkSingleShifts}
          successfulBulkSingleShifts={successfulBulkSingleShifts}
        />
      ) : (
        <RequestFailedForUnknownReasonBody />
      )
    }
    footer={
      <ModalFooterWrapper>
        <Button width="100%" onClick={closeModal}>
          OK
        </Button>
      </ModalFooterWrapper>
    }
    isOpen={modalOpen}
    onClose={closeModal}
    withCloseButton={false}
  />
);
