import React, { useState } from "react";
import { Button, Input, PlusIcon, useThemedStyletron } from "@bluecrew/blueprint-web";
import { KIND, SIZE } from "baseui/button";
import { useController } from "react-hook-form";
import { styled } from "baseui";
import { useUniqKeys } from "./useUniqKeys";
import { LabelText } from "../../LabelText";
import { ErrorMessage } from "../../ErrorMessage";
import { FieldTextLength } from "../../FieldTextLength";
import {
  CustomRequirement,
  CustomRequirements,
  CustomRequirementsFieldProps,
} from "../../types/propTypes/CustomRequirementsField.type";
import { Spacer, SpacerType } from "../../../../components/Spacer";

const CUSTOM_REQUIREMENT_MIN_LENGTH = 0;
const CUSTOM_REQUIREMENT_MAX_LENGTH = 100;
const MAX_CUSTOM_REQUIREMENTS = 3;

const getIsFieldDuplicated = (
  fieldValues: CustomRequirements,
  currentFieldValue: CustomRequirement,
) => {
  if (!currentFieldValue) return false;

  const timesfieldAppears = fieldValues.filter(
    (fieldValue) => fieldValue === currentFieldValue,
  ).length;
  return timesfieldAppears > 1;
};

export const CustomRequirementsField = ({
  control,
  trigger,
  disabled = false,
  invalidCustomReqs = [],
}: CustomRequirementsFieldProps) => {
  const [, theme] = useThemedStyletron();
  const [errorMessages, setErrorMessages] = useState<Array<string | undefined>>(
    new Array(MAX_CUSTOM_REQUIREMENTS).fill(undefined),
  );
  const fieldName = "customRequirements";
  const validate = trigger.bind(null, fieldName);

  const normalize = (req: CustomRequirement | String) => req.toLowerCase().replace(/\s/g, "");

  const normalizedInvalidReqs = invalidCustomReqs.map(normalize);

  const validateUniqueReq = (reqs: CustomRequirements, val: CustomRequirement) => {
    if (val === "") return undefined;

    const normalizedVal = normalize(val);
    const normalizedReqs = reqs.map(normalize);

    if (normalizedInvalidReqs.includes(normalizedVal)) {
      return "Requirement already exists.";
    }
    if (getIsFieldDuplicated(normalizedReqs, normalizedVal)) {
      return "Requirement is duplicated.";
    }
    return undefined;
  };

  const getErrorMessages = (reqs: CustomRequirements) => {
    const newErrorMessages: Array<string | undefined> = [];

    for (const customRequirementsValue of reqs) {
      const errorMessage = validateUniqueReq(reqs, customRequirementsValue);
      newErrorMessages.push(errorMessage);
    }
    return newErrorMessages;
  };

  const {
    field: { onChange, value: customRequirementsValues },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: (customRequirements: CustomRequirements) => {
        const newErrorMessages = getErrorMessages(customRequirements);
        setErrorMessages(newErrorMessages);
        const hasErrors = !newErrorMessages.some((newErrorMessage) => newErrorMessage);
        return hasErrors;
      },
    },
  });

  const addCustomRequirementHandler = () => {
    const newValue: string[] = [];
    for (let i = 0; i < MAX_CUSTOM_REQUIREMENTS; i += 1) {
      if (i < customRequirementsValues.length) {
        newValue[i] = customRequirementsValues[i];
      } else {
        newValue[i] = "";
      }
    }
    onChange(newValue);
  };

  const onChangeHandler = (val: CustomRequirement, ind: number) => {
    const newValue = [...customRequirementsValues];
    newValue[ind] = val;

    onChange(newValue);
    trigger([fieldName, "requirements"]);
    validate();
  };

  const { keys } = useUniqKeys(MAX_CUSTOM_REQUIREMENTS);
  const [fieldFocus, onFieldFocus] = useState("");

  return (
    <>
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale700} />
      <LabelText>Custom requirements</LabelText>
      {customRequirementsValues.map((value: CustomRequirement, idx: number) => {
        const key = keys[idx];
        const errorMessage = errorMessages[idx];
        const hasError = !!errorMessage;

        return (
          <InputWrapper key={`${key}`}>
            <Input
              value={value}
              onChange={(val: CustomRequirement) => onChangeHandler(val, idx)}
              onFocus={() => onFieldFocus(key)}
              onBlur={() => onFieldFocus("")}
              error={hasError}
              disabled={disabled}
              maxLength={CUSTOM_REQUIREMENT_MAX_LENGTH}
              placeholder={"Optional"}
            />
            {hasError && <ErrorMessage text={errorMessage} />}
            <FieldTextLength
              focused={fieldFocus === key}
              error={false}
              minLength={CUSTOM_REQUIREMENT_MIN_LENGTH}
              maxLength={CUSTOM_REQUIREMENT_MAX_LENGTH}
              length={String(value).length}
            />
          </InputWrapper>
        );
      })}

      <ButtonWrapper>
        {!disabled && customRequirementsValues.length < MAX_CUSTOM_REQUIREMENTS && (
          <Button
            kind={KIND.secondary}
            size={SIZE.compact}
            width="190px"
            leftIcon={PlusIcon}
            onClick={() => {
              addCustomRequirementHandler();
            }}
          >
            Custom requirement
          </Button>
        )}
      </ButtonWrapper>
    </>
  );
};

const InputWrapper = styled("div", ({ $theme }) => ({
  width: "439px",
  marginTop: $theme.sizing.scale300,
}));

const ButtonWrapper = styled("div", ({ $theme }) => ({
  marginTop: $theme.sizing.scale300,
  marginBottom: $theme.sizing.scale300,
}));
