import styled from "styled-components";
import { Button as PRButton, ButtonProps } from "primereact/button";
import React from "react";
import { ValueOf } from "../../types/util-types";

export const BCButtonVariants = {
  Primary: "primary",
  Secondary: "secondary",
} as const;
export type BCButtonVariant = ValueOf<typeof BCButtonVariants>;
const ButtonWrapper = (props: ButtonProps) => <PRButton className={props.className} {...props} />;

export const Button = styled(ButtonWrapper)`
  :enabled:hover {
    border-color: ${({ theme }) => theme.colorTokens.blue850};
  }

  :focus {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colorTokens.blue100};
  }
`;
