import styled from "styled-components";
import { Button } from "primereact/button";

export const HeaderImage = styled.img`
  width: 64px;
  height: 64px;
`;

export const ModalHeader = styled.div`
  font-weight: 600;
  line-height: 36px;
  font-size: 20px;
`;

export const ModalContent = styled.div`
  font-size: 14px;
`;

export const ModalFooter = styled.div`
  button,
  div {
    width: 100%;
  }
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.ultramarine};
  color: ${({ theme }) => theme.colorTokens.white};
  width: 100%;
`;

export const ModalStyles = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
`;
