import { createTheme } from 'baseui';
import { Theme } from 'baseui/theme';
import { borders } from './borders';
import { colors } from './colors';
import { lighting } from './lighting';
import { primitives } from './primitives';
import { sizing } from './sizing';
import { typography } from './typography';

const overrides = {
  colors,
  typography,
  sizing,
  lighting,
  borders,
};

export type BlueprintTheme = Theme & typeof overrides;

export const blueprintTheme: BlueprintTheme = createTheme(
  primitives,
  overrides,
);
