import React, { useState } from "react";
import { ADD_PERMISSIONS_BUTTON, BRANCH_TYPES } from "../constants";
import { InternalUser, userCanBeReinvited } from "../ManageUsersTable/InternalUser";
import { ResendAccountInvite } from "../ManageUsersTable/ResendAccountInvite";
import {
  AddPermissionsButton,
  ResendInvitesButton,
  StyledCopyEmailsButton,
  StyledDiv,
} from "./styledComponents";

export type BulkUserActionsProps = {
  selectedUsers: Array<InternalUser>;
  onBulkAssignCompanies: () => void;
};

type CopyEmailsButtonProps = {
  selectedUsers: Array<InternalUser>;
};

// Disable the add permissions button if there are no branch users selected.
const disableButton = (selectedUsers: Array<InternalUser>) => {
  const numBranchUsers = selectedUsers.filter((user) =>
    BRANCH_TYPES.includes(user.userRole),
  ).length;
  return numBranchUsers === 0;
};

const CopyEmailsButton = ({ selectedUsers }: CopyEmailsButtonProps) => {
  if (selectedUsers.length === 1) return null; // only show the button if more than one user is selected
  const handleCopyEmails = () => {
    // Copy emails compliant to RFC 5322 like:
    //   John Doe <john.doe@machine.example>; Mary Smith <mary@example.net>; etc.
    const emails = selectedUsers
      .map((user: InternalUser) => `${user.name} <${user.emailAddress}>`)
      .join("; ");
    navigator.clipboard.writeText(emails);
  };

  return (
    <StyledCopyEmailsButton data-testid="copy-emails-button" onClick={handleCopyEmails}>
      Copy {selectedUsers.length === 1 ? "email address" : "email addresses"}
    </StyledCopyEmailsButton>
  );
};

export const resendInviteButtonLabel = (plural: boolean) => `Resend Invite${plural ? "s" : ""}`;

export const BulkUserActions = ({ selectedUsers, onBulkAssignCompanies }: BulkUserActionsProps) => {
  const [resendInviteIsMounted, setResendInviteIsMounted] = useState(false);

  const userEmailsForResend = selectedUsers
    .filter(userCanBeReinvited)
    .map((user) => ({ email: user.emailAddress }));

  const showResendInvite = userEmailsForResend.length > 0;

  return (
    <StyledDiv style={{ marginLeft: "auto" }}>
      <CopyEmailsButton selectedUsers={selectedUsers} />
      <AddPermissionsButton
        data-testid={ADD_PERMISSIONS_BUTTON}
        onClick={onBulkAssignCompanies}
        disabled={disableButton(selectedUsers)}
      >
        {disableButton(selectedUsers)}
        Add Permissions
      </AddPermissionsButton>
      {showResendInvite && (
        <ResendInvitesButton
          icon={<i className="pi pi-send"></i>}
          label={resendInviteButtonLabel(userEmailsForResend.length > 1)}
          onClick={() => setResendInviteIsMounted(true)}
        />
      )}
      {resendInviteIsMounted && (
        <ResendAccountInvite users={userEmailsForResend} setIsMounted={setResendInviteIsMounted} />
      )}
    </StyledDiv>
  );
};
