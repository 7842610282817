import React from "react";
import { DownChevronIcon } from "@bluecrew/web-react-core";
import { StyledCollapse } from "./StyledCollapse";
import { RotatableSvg } from "./RotatableSvg";

// Wrapper around a styled rc-collapse component.

// Each panel in the collapse is given a chevron that points to the right when
// the panel is closed, and points downwards when the panel is open
// The chevron smoothly rotates between the two positons when the panel is clicked

// See the docs for props: https://github.com/react-component/collapse#api
export const StyledCollapseWithChevron = (props) => (
  <StyledCollapse
    {...props}
    expandIcon={({ isActive }) => (
      <RotatableSvg
        isActive={isActive}
        svgToRotate={DownChevronIcon}
        degreesA={0}
        degreesB={-90}
        transitionDuration={0.2}
      />
    )}
  />
);
