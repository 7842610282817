import React from "react";
import { ChevronDownIcon } from "@bluecrew/blueprint-web";
import { useParams } from "react-router-dom";
import { CreateShiftScreenText, StyledDropdown } from "../styledComponents";
import { Position } from "../AddShiftForm.types";
import { useGetCompanyPositionsQuery } from "../../../../../api/bluecrew/hooks/payroll";

export type PositionFieldProps = {
  setPosition: (p: Position) => void;
  position?: Position;
};
export const PositionSelectField = ({ position, setPosition }: PositionFieldProps) => {
  const companyId = useParams().internalCompanyId!!;
  const { data: positions, isLoading: isPositionsFetching } =
    useGetCompanyPositionsQuery(companyId);

  return (
    <>
      <CreateShiftScreenText>Position</CreateShiftScreenText>
      <StyledDropdown
        value={position}
        dropdownIcon={ChevronDownIcon}
        onChange={(e) => setPosition(e.value)}
        disabled={isPositionsFetching}
        options={positions}
        optionLabel="name"
        placeholder="Select a position"
      />
    </>
  );
};
