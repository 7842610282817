import React from "react";
import { styled } from "baseui";
import { AlertIcon, useThemedStyletron } from "@bluecrew/blueprint-web";
import { Spacer, SpacerType } from "../../components/Spacer";

type ErrorMessageProps = {
  text: string | undefined;
};

export const ErrorMessage = ({ text }: ErrorMessageProps) => {
  const [, theme] = useThemedStyletron();
  return (
    <>
      {text && (
        <ErrorMessageWrapper>
          <AlertIcon width={16} height={16} />
          <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale300} />
          <ErrorText>{text}</ErrorText>
        </ErrorMessageWrapper>
      )}
    </>
  );
};

// TODO:(BW-375) Same with this as with ErrorText below
const ErrorMessageWrapper = styled("div", ({ $theme }) => ({
  display: "flex",
  paddingTop: $theme.sizing.scale300,
  alignItems: "center",
}));

// TODO:(BW-375) May end up exporting this since some files just use ErrorText components that they
// define locally. We can clean those up by exporting this and importing it where needed.
const ErrorText = styled("p", ({ $theme }) => ({
  color: $theme.colors.negative,
  ...$theme.typography.font200,
  margin: "0px",
}));
