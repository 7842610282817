import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Heading, Paragraph, Image } from "../index";

const StyledContent = styled.div`
  padding: 32px 80px;
  display: flex;
  flex-direction: column;
  text-align: center;

  ${Heading} {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${p("slate")};
    text-transform: capitalize;
  }

  ${Paragraph} {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${p("platinum")};
  }
`;

export const OfferJobModalContent = (props) => {
  const { icon, messageHeader, messageBody } = props;

  return (
    <StyledContent>
      <Image src={icon} alt="" />
      <Heading as="h3">{messageHeader}</Heading>
      <Paragraph>{messageBody}</Paragraph>
    </StyledContent>
  );
};

OfferJobModalContent.propTypes = {
  icon: PropTypes.string,
  messageHeader: PropTypes.string,
  messageBody: PropTypes.string,
};
