import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { palette as p } from "styled-tools";

// @ts-ignore
function fillColor(props) {
  if (props.alert) {
    return p("danger");
  }

  if (props.percentage === 100 && !props.alert) {
    return p("success");
  }

  if (props.percentage < 100 && !props.alert) {
    return p("inProgress");
  }
}

const StyledProgressBar = styled.div<{ fullWidth?: boolean }>`
  display: inline-block;
  background: ${p("aluminum")};
  position: relative;
  height: 10px;
  width: ${(props) => (props.fullWidth ? "100%" : "100px")};
  border-radius: 50px;
  border: none;
`;

const StyledFiller = styled.div<{ percentage: number }>`
  background: ${(props) => fillColor(props)};
  height: 100%;
  border-radius: inherit;
  border-top-right-radius: ${(props) => (props.percentage === 100 ? "inherit" : 0)};
  border-bottom-right-radius: ${(props) => (props.percentage === 100 ? "inherit" : 0)};
  transition: width 0.2s ease-in;
  width: ${(props) => props.percentage}%;
`;

export const ProgressBar = (props) => {
  const { alert, amountFilled, amountNeeded } = props;
  const percentFilled = amountFilled > amountNeeded ? 100 : (amountFilled / amountNeeded) * 100;

  return (
    <StyledProgressBar {...props} className={props.classes}>
      <StyledFiller
        percentage={percentFilled}
        // @ts-ignore
        alert={alert}
      />
    </StyledProgressBar>
  );
};

ProgressBar.propTypes = {
  amountFilled: PropTypes.number.isRequired,
  amountNeeded: PropTypes.number.isRequired,
  classes: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ProgressBar.defaultProps = {
  classes: null,
  amountFilled: 0,
  amountNeeded: 0,
  alert: false,
  fullWidth: null,
};
