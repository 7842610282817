import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobHousekeeperIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4288_23585)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.9431 9.12216L1.58723 9.91234C1.42269 10.2777 1.33061 10.6836 1.33061 11.1139C1.33061 12.5819 2.41102 13.7994 3.81982 14.0114L4.89065 14.1725L4.69995 15.2385C4.66985 15.4068 4.65401 15.5807 4.65401 15.7592C4.65401 17.3774 5.96577 18.6893 7.58398 18.6895L8.73919 18.6877L8.64728 19.8275C8.64091 19.9064 8.63765 19.9864 8.63765 20.0674C8.63765 21.6857 9.94958 22.9977 11.5679 22.9977C12.0542 22.9977 12.5099 22.8801 12.9111 22.6726L14.7415 21.7264L14.4366 23.7642C14.4153 23.9067 14.4041 24.0531 14.4041 24.2028C14.4041 25.8211 15.7161 27.133 17.3344 27.133C18.7034 27.133 19.8555 26.1933 20.176 24.9222L20.5858 23.2968L21.8725 24.3711C22.3812 24.7959 23.034 25.0506 23.7489 25.0506C25.3673 25.0506 26.6792 23.7387 26.6792 22.1203C26.6792 21.5409 26.5121 21.0037 26.2238 20.5505L25.4871 19.3924L26.7965 18.9804C28.2039 18.5375 29.0712 17.4144 29.0712 16.2355C29.0712 14.7448 27.641 13.3052 25.5718 13.3052C25.4824 13.3052 25.3939 13.308 25.3064 13.3135L24.1343 13.3873L24.1879 12.2141C24.1897 12.1763 24.1905 12.1383 24.1905 12.1001C24.1905 10.6664 22.8757 9.28606 20.9286 9.17649L20.052 9.12716L19.9427 8.25602C19.7615 6.81281 18.5281 5.69582 17.0354 5.69582C16.7304 5.69582 16.4382 5.74207 16.1643 5.82719L14.9285 6.21125L14.8033 4.92323C14.6591 3.44073 13.4078 2.28127 11.8869 2.28127C11.597 2.28127 11.3187 2.32306 11.0568 2.40024L10.2725 2.63132L9.85411 1.92891C9.34137 1.06818 8.40414 0.495361 7.3342 0.495361C6.00825 0.495361 4.88545 1.37688 4.52506 2.58815L4.26477 3.46301L3.36177 3.32985C3.2212 3.30912 3.07684 3.2983 2.9293 3.2983C1.31095 3.2983 -0.000976562 4.61023 -0.000976562 6.22858C-0.000976562 7.21507 0.485481 8.08799 1.2363 8.62069L1.9431 9.12216Z"
          fill={svgColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1367 25.9382L28.2605 30.062C28.2366 30.2364 28.2243 30.4144 28.2243 30.5953C28.2243 32.7426 29.965 34.4833 32.1123 34.4833C34.2595 34.4833 36.0003 32.7426 36.0003 30.5953C36.0003 28.448 34.2595 26.7073 32.1123 26.7073C31.9314 26.7073 31.7535 26.7197 31.5792 26.7435L27.3918 22.5562C27.383 24.3781 25.9419 25.8615 24.1367 25.9382ZM32.6089 29.9959C32.4793 29.9093 32.3268 29.8631 32.1709 29.8631C31.9619 29.8634 31.7615 29.9465 31.6137 30.0943C31.4659 30.2421 31.3828 30.4425 31.3824 30.6515C31.3824 30.8074 31.4287 30.9599 31.5153 31.0895C31.602 31.2192 31.7251 31.3203 31.8692 31.3799C32.0132 31.4396 32.1718 31.4552 32.3247 31.4248C32.4777 31.3944 32.6181 31.3193 32.7284 31.209C32.8387 31.0987 32.9138 30.9583 32.9442 30.8053C32.9746 30.6524 32.959 30.4938 32.8993 30.3498C32.8396 30.2057 32.7386 30.0826 32.6089 29.9959ZM33.2825 32.3162C32.9535 32.5362 32.5667 32.6538 32.1709 32.654V32.6529C31.6403 32.6525 31.1315 32.4418 30.7561 32.0668C30.3807 31.6918 30.1693 31.1833 30.1684 30.6527C30.1684 30.2569 30.2857 29.87 30.5056 29.5409C30.7254 29.2118 31.0379 28.9553 31.4036 28.8038C31.7692 28.6523 32.1716 28.6126 32.5597 28.6897C32.9479 28.7668 33.3045 28.9573 33.5845 29.2371C33.8644 29.5169 34.0551 29.8734 34.1325 30.2615C34.2098 30.6497 34.1704 31.052 34.0191 31.4177C33.8678 31.7835 33.6114 32.0961 33.2825 32.3162Z"
          fill={svgColor}
        />
        <path
          d="M24.435 0.61586L24.8712 1.75283C24.8831 1.78523 24.9038 1.81364 24.9311 1.8348L25.8518 2.55371C25.8745 2.57125 25.8928 2.59374 25.9054 2.61946C25.918 2.64518 25.9246 2.67344 25.9246 2.70208C25.9246 2.73072 25.918 2.75898 25.9054 2.7847C25.8928 2.81042 25.8745 2.83291 25.8518 2.85045L24.9311 3.56936C24.9038 3.59052 24.8831 3.61893 24.8712 3.65133L24.435 4.7883C24.4213 4.82378 24.3972 4.85428 24.3658 4.87578C24.3344 4.89729 24.2972 4.90879 24.2592 4.90879C24.2211 4.90879 24.184 4.89729 24.1526 4.87578C24.1212 4.85428 24.097 4.82378 24.0833 4.7883L23.6471 3.65133C23.635 3.61907 23.6143 3.59073 23.5872 3.56936L22.6665 2.85045C22.6438 2.83291 22.6255 2.81042 22.6129 2.7847C22.6003 2.75898 22.5938 2.73072 22.5938 2.70208C22.5938 2.67344 22.6003 2.64518 22.6129 2.61946C22.6255 2.59374 22.6438 2.57125 22.6665 2.55371L23.5872 1.8348C23.6143 1.81343 23.635 1.78509 23.6471 1.75283L24.0833 0.61586C24.097 0.58038 24.1212 0.549884 24.1526 0.528378C24.184 0.506872 24.2211 0.495361 24.2592 0.495361C24.2972 0.495361 24.3344 0.506872 24.3658 0.528378C24.3972 0.549884 24.4213 0.58038 24.435 0.61586Z"
          fill={svgColor}
        />
        <path
          d="M27.0637 5.12308L27.1782 6.21627C27.1815 6.24713 27.193 6.27655 27.2114 6.30154L27.8472 7.14404C27.8628 7.16465 27.8735 7.18856 27.8785 7.21394C27.8835 7.23932 27.8826 7.2655 27.8759 7.29048C27.8692 7.31547 27.8569 7.33859 27.8399 7.35808C27.8229 7.37757 27.8017 7.39293 27.7778 7.40296L26.8059 7.81467C26.7776 7.82731 26.753 7.84703 26.7346 7.87193L26.0888 8.76141C26.0687 8.78932 26.0405 8.81044 26.0081 8.82198C25.9757 8.83352 25.9405 8.83493 25.9072 8.82602C25.874 8.81711 25.8442 8.79831 25.8219 8.7721C25.7996 8.74589 25.7858 8.71352 25.7823 8.67928L25.6678 7.58608C25.6642 7.55528 25.6528 7.52591 25.6346 7.50081L24.9988 6.65832C24.9833 6.63765 24.9727 6.61375 24.9678 6.5884C24.963 6.56306 24.9639 6.53693 24.9706 6.512C24.9772 6.48707 24.9895 6.46399 25.0064 6.44448C25.0233 6.42497 25.0444 6.40956 25.0682 6.3994L26.04 5.98768C26.0685 5.97524 26.0931 5.95549 26.1114 5.93043L26.7572 5.04095C26.7773 5.01305 26.8055 4.99192 26.8379 4.98037C26.8703 4.96883 26.9055 4.96742 26.9387 4.97633C26.972 4.98524 27.0018 5.00405 27.0241 5.03026C27.0464 5.05647 27.0602 5.08884 27.0637 5.12308Z"
          fill={svgColor}
        />
        <path
          d="M1.27586 23.2363L2.42721 23.633C2.45965 23.6447 2.49475 23.6471 2.52848 23.6398L3.67104 23.3966C3.69904 23.3905 3.72805 23.3909 3.75585 23.3978C3.78366 23.4047 3.80952 23.4178 3.83146 23.4362C3.8534 23.4546 3.87084 23.4778 3.88243 23.504C3.89403 23.5302 3.89948 23.5587 3.89836 23.5873L3.85722 24.7548C3.85591 24.7892 3.86434 24.8234 3.88155 24.8533L4.47214 25.9183C4.49049 25.9516 4.49833 25.9897 4.49463 26.0276C4.49093 26.0654 4.47585 26.1013 4.45139 26.1305C4.42694 26.1596 4.39423 26.1807 4.35758 26.1909C4.32093 26.2011 4.28205 26.2 4.24604 26.1877L3.09469 25.791C3.06219 25.7796 3.02718 25.7772 2.99342 25.7842L1.85086 26.0274C1.82286 26.0335 1.79385 26.0331 1.76605 26.0262C1.73824 26.0193 1.71238 26.0062 1.69044 25.9878C1.66851 25.9694 1.65107 25.9462 1.63947 25.92C1.62787 25.8938 1.62242 25.8653 1.62354 25.8367L1.66468 24.6693C1.66569 24.6348 1.65728 24.6007 1.64035 24.5707L1.04977 23.5057C1.03141 23.4724 1.02358 23.4343 1.02728 23.3964C1.03098 23.3586 1.04605 23.3227 1.07051 23.2936C1.09497 23.2644 1.12767 23.2433 1.16432 23.2331C1.20097 23.2229 1.23986 23.224 1.27586 23.2363Z"
          fill={svgColor}
        />
        <path
          d="M6.04881 24.6659L7.00999 25.1992C7.03717 25.2141 7.06784 25.2216 7.09886 25.2209L8.15369 25.1833C8.17954 25.1823 8.20527 25.1872 8.22891 25.1977C8.25256 25.2082 8.27349 25.224 8.29011 25.2438C8.30674 25.2636 8.31862 25.2869 8.32483 25.312C8.33105 25.3371 8.33145 25.3633 8.32599 25.3886L8.1058 26.4209C8.0999 26.4513 8.10196 26.4828 8.11176 26.5122L8.46998 27.5514C8.48129 27.5839 8.48245 27.6191 8.47331 27.6522C8.46416 27.6854 8.44514 27.715 8.41877 27.7372C8.3924 27.7593 8.35993 27.7729 8.32566 27.7761C8.2914 27.7794 8.25696 27.7721 8.22691 27.7553L7.26573 27.2221C7.23847 27.2073 7.20785 27.1999 7.17685 27.2004L6.12203 27.238C6.09623 27.2388 6.07058 27.2338 6.04701 27.2232C6.02345 27.2127 6.00258 27.1969 5.98599 27.1772C5.9694 27.1574 5.95752 27.1341 5.95124 27.1091C5.94495 27.084 5.94444 27.0579 5.94973 27.0326L6.16992 26.0004C6.17604 25.9699 6.17398 25.9384 6.16395 25.9091L5.80574 24.8699C5.79443 24.8374 5.79327 24.8022 5.80241 24.769C5.81156 24.7358 5.83058 24.7062 5.85695 24.6841C5.88331 24.662 5.91579 24.6484 5.95006 24.6451C5.98432 24.6419 6.01877 24.6491 6.04881 24.6659Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4288_23585">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
