import * as React from 'react';
import { ReactNode } from 'react';
import { styled } from 'baseui';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { KIND } from 'baseui/notification';
import { LabelSmall, ParagraphMedium } from 'baseui/typography';

import { AlertIcon, CircleCheckIcon, InfoIcon } from '../assets';
import {
  getVerticalPadding,
  getBorderRadius,
  getMargin,
} from '../helpers/styleHelpers';
import { BlueprintTheme, useThemedStyletron } from '../theme';

type AlertProps = {
  toast?: boolean;
  type?: KIND[keyof KIND];
  children?: ReactNode;
  width?: string;
};

type AlertWrapperProps = {
  $width: string;
  $borderColor?: string;
  $boxShadow?: string;
  $toast?: boolean;
};

export const Alert = ({
  type = KIND.info,
  toast,
  children,
  width = '345px',
}: AlertProps) => {
  const [, theme] = useThemedStyletron();

  const {
    icon: AlertTypeIcon,
    textWrapper: TextWrapper,
    iconStyle,
    wrapperStyle,
  } = alertStyles(theme)[type];

  return (
    <AlertWrapper
      $width={width}
      $boxShadow={wrapperStyle.boxShadow}
      $borderColor={iconStyle.color}
      $toast={toast}
    >
      <FlexGrid flexGridColumnCount={[2]}>
        <FlexGridItem
          overrides={{
            Block: {
              style: () => ({
                width: 'auto',
                flexGrow: 'unset',
                display: 'flex',
                alignItems: iconStyle.verticalAlign,
                marginRight: iconStyle.marginRight,
              }),
            },
          }}
        >
          <AlertTypeIcon
            width={iconStyle.width}
            height={iconStyle.height}
            stroke={iconStyle.stroke}
            color={iconStyle.color}
          />
        </FlexGridItem>
        <FlexGridItem>
          <TextWrapper
            overrides={{
              Block: {
                style: () => ({
                  ...getMargin('0'),
                  color: theme.colors.mono900,
                }),
              },
            }}
          >
            {children}
          </TextWrapper>
        </FlexGridItem>
      </FlexGrid>
    </AlertWrapper>
  );
};

const AlertWrapper = styled<AlertWrapperProps, 'div'>(
  'div',
  ({ $theme, $borderColor, $boxShadow, $toast, $width }) => ({
    width: $width,
    boxSizing: 'border-box',
    paddingLeft: $theme.sizing.scale300,
    paddingRight: $theme.sizing.scale500,
    ...getVerticalPadding($theme.sizing.scale550),
    ...getBorderRadius($theme.borders.radius200),
    boxShadow: $boxShadow,
    borderLeft: $toast ? '4px solid transparent' : `8px solid ${$borderColor}`,
    backgroundColor: $theme.colors.white,
  }),
);

const alertStyles = (theme: BlueprintTheme) => ({
  [KIND.positive]: {
    icon: CircleCheckIcon,
    iconStyle: {
      width: theme.sizing.scale600,
      height: theme.sizing.scale600,
      color: theme.colors.positive400,
      stroke: theme.colors.primaryB,
      verticalAlign: 'center',
      marginRight: theme.sizing.scale300,
    },
    wrapperStyle: {
      boxShadow: theme.lighting.shadow600,
    },
    textWrapper: LabelSmall,
  },
  [KIND.negative]: {
    icon: AlertIcon,
    iconStyle: {
      width: theme.sizing.scale600,
      height: theme.sizing.scale600,
      color: theme.colors.negative400,
      stroke: theme.colors.primaryB,
      verticalAlign: 'center',
      marginRight: theme.sizing.scale300,
    },
    wrapperStyle: {
      boxShadow: theme.lighting.shadow600,
    },
    textWrapper: LabelSmall,
  },
  [KIND.info]: {
    icon: InfoIcon,
    iconStyle: {
      width: theme.sizing.scale800,
      height: theme.sizing.scale800,
      color: theme.colors.accent400,
      stroke: theme.colors.primaryB,
      verticalAlign: 'flex-start',
      marginRight: theme.sizing.scale500,
    },
    wrapperStyle: {
      boxShadow: theme.lighting.shadow400,
    },
    textWrapper: ParagraphMedium,
  },
  [KIND.warning]: {
    icon: AlertIcon,
    iconStyle: {
      width: theme.sizing.scale800,
      height: theme.sizing.scale800,
      color: theme.colors.warning400,
      stroke: theme.colors.primaryB,
      verticalAlign: 'flex-start',
      marginRight: theme.sizing.scale500,
    },
    wrapperStyle: {
      boxShadow: theme.lighting.shadow400,
    },
    textWrapper: ParagraphMedium,
  },
});
