import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useExpanded, useRowSelect, useSortBy, useTable } from "react-table";
import { useDispatch } from "react-redux";
import {
  DashboardPositionBulkSingleShifts,
  DashboardPositionJob,
  DashboardPositionJobTypes,
  DashboardPositionRepeatingSchedule,
  DashboardSchedulesAndIndividualShifts,
  ReasonCode,
  ScheduleAndIndividualShift,
} from "../../../api/bluecrew/types";
import ArchiveScheduleModal from "../../../components/ArchiveScheduleModal";
import { CancelScheduleModal } from "../../../components/CancelScheduleModal";
import { TableColumnSortIcon } from "../../../svg/TableColumnSortIcon";
import { JobRequestModal } from "../JobRequestModal/JobRequestModal";
import {
  DashboardSchedulesAndIndividualShiftsTableContainer,
  TableHeaderColumnContainer,
} from "../SharedComponents";
import { handleCancelRepeatingSchedule } from "../SharedFunctions";
import { columns } from "./tableColumns";
import { IndividualShiftsHeader } from "./IndividualShiftsHeader";
import RepeatingScheduleRow from "./RepeatingScheduleRow";
import IndividualShiftsRow from "./IndividualShiftsRow";
import { SortingContainer } from "../../../components/ReactTable/SortingContainer";

type ScheduleRequestTableProps = {
  refillOn: boolean;
  scheduleData: DashboardSchedulesAndIndividualShifts;
  setScheduleData: Dispatch<SetStateAction<DashboardSchedulesAndIndividualShifts>>;
  positionId: number;
  companyId: number;
  hasIndividualShifts: boolean;
};

export const NON_EXISTENT_JOB_REQUEST_ID = -1;

const DashboardSchedulesAndIndividualShiftsTable = ({
  scheduleData,
  setScheduleData,
  refillOn,
  positionId,
  companyId,
  hasIndividualShifts,
}: ScheduleRequestTableProps) => {
  const [isIndividualShiftRowsOpen, updateIsIndividualShiftRowsOpen] = useState(true);

  const defaultCancelShowModalValue = {
    jobExternalIds: [] as string[],
    show: false,
    scheduleId: null as null | number,
    workerShifts: 1,
    type: DashboardPositionJobTypes.repeatingSchedule,
  };
  const defaultShowModalValue = {
    schedule: {} as ScheduleAndIndividualShift,
    show: false,
  };
  const [showCancelSchedule, updateCancelSchedule] = useState(defaultCancelShowModalValue);
  const [showArchiveSchedule, updateShowArchiveSchedule] = useState(defaultShowModalValue);

  const [requestModalIsOpen, setRequestModalIsOpen] = useState(false);
  const [activeJobRequestModalId, setActiveJobRequestModalId] = useState(
    NON_EXISTENT_JOB_REQUEST_ID,
  );

  useEffect(() => {
    if (activeJobRequestModalId !== NON_EXISTENT_JOB_REQUEST_ID) {
      setRequestModalIsOpen(true);
    } else {
      setRequestModalIsOpen(false);
    }
  }, [activeJobRequestModalId]);

  const openRequestModal = (requestId: number) => {
    setActiveJobRequestModalId(requestId);
  };

  const closeRequestModal = () => {
    setActiveJobRequestModalId(NON_EXISTENT_JOB_REQUEST_ID);
  };

  const updateCancelScheduleForJobRequest = (
    jobRequestExternalId: string,
    scheduleId: number,
    workerShifts: number,
  ) => {
    updateCancelSchedule({
      jobExternalIds: [jobRequestExternalId],
      show: false,
      scheduleId,
      workerShifts,
      type: DashboardPositionJobTypes.repeatingSchedule,
    });
  };
  const dispatch = useDispatch();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // visibleColumns,
    // @ts-ignore TODO:(BW-1045) Fix React Table Types
    state: { sortBy },
  } = useTable(
    {
      // @ts-ignore
      columns: columns({
        openRequestModal,
        refillOn,
        updateCancelSchedule,
      }),
      initialState: {
        hiddenColumns: [],
        // @ts-ignore TODO:(BW-1045) Fix React Table Types
        sortBy: [
          {
            id: "schedule-date",
            desc: false,
          },
        ],
      },
      data: scheduleData,
      disableSortRemove: true,
    },
    useSortBy,
    useExpanded, // Use the useExpanded plugin hook
    useRowSelect,
  );

  const archiveSchedule = async () => {
    // This is disabled for now
    // TODO: Discuss with AMs whether to remove this func. or not
  };

  const cancelSchedule = async (reasonCode?: ReasonCode, reasonText?: string) => {
    updateCancelSchedule(defaultCancelShowModalValue);
    await handleCancelRepeatingSchedule(
      showCancelSchedule.jobExternalIds,
      reasonCode,
      reasonText,
      dispatch,
    );
    if (
      showCancelSchedule.type === DashboardPositionJobTypes.repeatingScheduleSubRow ||
      showCancelSchedule.type === DashboardPositionJobTypes.bulkSingleShiftSubRow
    ) {
      setScheduleData((prev) =>
        prev
          .map((schedule) => {
            let neededCountToBeRemoved = 0;
            let filledCountToBeRemoved = 0;
            if (
              schedule.type === DashboardPositionJobTypes.repeatingSchedule ||
              schedule.type === DashboardPositionJobTypes.repeatingScheduleSubRow ||
              schedule.type === DashboardPositionJobTypes.bulkSingleShift
            ) {
              return {
                ...schedule,
                jobs: (
                  schedule as DashboardPositionRepeatingSchedule | DashboardPositionBulkSingleShifts
                ).jobs.filter((job) => {
                  const activeRequest =
                    showCancelSchedule.jobExternalIds &&
                    !showCancelSchedule.jobExternalIds.includes(job.external_id);
                  if (!activeRequest) {
                    filledCountToBeRemoved += job.filled;
                    neededCountToBeRemoved += job.needed;
                  }
                  return activeRequest;
                }),
                filled: schedule.filled - filledCountToBeRemoved,
                needed: schedule.needed - neededCountToBeRemoved,
              };
            }
            return {
              ...schedule,
            };
          })
          .filter((schedule) => {
            return (
              (schedule as DashboardPositionRepeatingSchedule | DashboardPositionBulkSingleShifts)
                .jobs.length > 0
            );
          }),
      );
    } else {
      const updatedScheduleData = scheduleData.filter((schedule) => {
        const activeSchedule =
          showCancelSchedule.scheduleId &&
          showCancelSchedule.scheduleId !== (schedule as DashboardPositionJob).schedule_id;
        return activeSchedule;
      });
      setScheduleData(updatedScheduleData);
    }
    setActiveJobRequestModalId(NON_EXISTENT_JOB_REQUEST_ID);
  };

  const emptyHeaderRow = {
    type: DashboardPositionJobTypes.emptyHeaderRow,
    name: "Individual Shifts",
  };

  return (
    <DashboardSchedulesAndIndividualShiftsTableContainer key={`scheduleRequestTable_${positionId}`}>
      <table {...getTableProps()} data-pendo-key="DashboardSchedulesAndIndividualShiftsTable">
        <thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupKey = headerGroup.getHeaderGroupProps().key;
            return (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroupKey}
                data-pendo-key="ScheduleRequestTableHeaderRow"
              >
                {headerGroup.headers.map((column) => {
                  const columnKey = column.getHeaderProps().key;
                  return (
                    <th
                      {...column.getHeaderProps([
                        { className: column.className },
                        column.getSortByToggleProps(),
                      ])}
                      key={`${headerGroupKey}-${columnKey}`}
                      data-pendo-key={`DashboardSchedulesAndIndividualShiftsTableContainerHeader-${columnKey}`}
                    >
                      <TableHeaderColumnContainer className={column.className}>
                        {column.render("Header")}
                        {column.canSort && (
                          <SortingContainer
                            data-pendo-key={`ScheduleRequestTableSortingContainer-${columnKey}`}
                            isSorted={column.isSorted}
                          >
                            <TableColumnSortIcon isAscending={!column.isSortedDesc || false} />
                          </SortingContainer>
                        )}
                      </TableHeaderColumnContainer>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            if (row.original.type === DashboardPositionJobTypes.repeatingSchedule) {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <RepeatingScheduleRow
                  // @ts-ignore TODO:(BW-1045) Fix React Table Types
                  key={`RepeatingScheduleRow-${rowProps.key}-expanded-${row.original.schedule_id}`}
                  rowProps={rowProps}
                  row={row}
                  sortBy={sortBy}
                  openRequestModal={openRequestModal}
                />
              );
            }
            return null;
          })}
          {hasIndividualShifts && (
            <IndividualShiftsHeader
              isIndividualShiftRowsOpen={isIndividualShiftRowsOpen}
              updateIsIndividualShiftRowsOpen={updateIsIndividualShiftRowsOpen}
              name={emptyHeaderRow.name}
            />
          )}
          {isIndividualShiftRowsOpen &&
            rows.map((row) => {
              if (row.original.type !== DashboardPositionJobTypes.repeatingSchedule) {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <IndividualShiftsRow
                    // @ts-ignore TODO:(BW-1045) Fix React Table Types
                    key={`IndividualShiftsHeader-${rowProps.key}-expanded-${row.original.blueshift_request_id}`}
                    rowProps={rowProps}
                    row={row}
                    sortBy={sortBy}
                  />
                );
              }
              return null;
            })}
        </tbody>
      </table>

      {showCancelSchedule && showCancelSchedule.show && showCancelSchedule.jobExternalIds && (
        <CancelScheduleModal
          cancelSchedule={cancelSchedule}
          showModal={showCancelSchedule.show}
          toggle={() => {
            updateCancelSchedule(defaultCancelShowModalValue);
          }}
          workerShifts={showCancelSchedule.workerShifts || 1}
        />
      )}
      {showArchiveSchedule && showArchiveSchedule.show && showArchiveSchedule.schedule && (
        <ArchiveScheduleModal
          archiveSchedule={archiveSchedule}
          showModal={showArchiveSchedule.show}
          toggle={() => {
            updateShowArchiveSchedule(defaultShowModalValue);
          }}
          scheduleName={
            (showArchiveSchedule.schedule as DashboardPositionRepeatingSchedule).schedule_name
          }
        />
      )}
      <JobRequestModal
        cancelSchedule={cancelSchedule}
        updateCancelScheduleForJobRequest={updateCancelScheduleForJobRequest}
        requestId={activeJobRequestModalId}
        positionId={positionId}
        companyId={companyId}
        requestModalIsOpen={requestModalIsOpen}
        closeRequestModal={closeRequestModal}
      />
    </DashboardSchedulesAndIndividualShiftsTableContainer>
  );
};

export default DashboardSchedulesAndIndividualShiftsTable;
