// MAIN - VIEW
// =============================================================================
// Main application container, the default window chrome for all other tools
// TODO Update this component to a function component in its own directory broken into multiple files per https://bluecrewjobs.atlassian.net/wiki/spaces/WS/pages/1676967961/React+-+Best+practices
// One of the primary purposes of this component is to check the authentication status of the user, and log them out if they are no longer authenticated
import React from "react";
import { SpinnerWithOverlay, BlueCrewTheme as theme } from "@bluecrew/web-react-core";
import { ThemeProvider } from "styled-components";
import { CustomerSupportButton } from "@bluecrew/web-react-core/src/components/CustomerSupportButton";
import "../../../styles/global";
import NavBar from "../../components/NavBar";
import { getUserPermissions } from "../../redux/selectors/auth";
import { AbilityContext } from "../PermissionsContext";
import ConfirmationModalContainer from "../ConfirmationModalContainer";
import ErrorBoundaryWithRedirect from "../ErrorBoundaryWithRedirect";
import { MAIN_SCROLLABLE_PANEL } from "../../utility";
import { App, AppContent } from "./styledComponents";
import { getIsSplitioReady } from "../../redux/selectors/splitio";
import { sessionFetched as sessionFetchedSelector } from "../../redux/selectors/segment";
import {
  useCheckSessionOrRefreshAuth,
  useOnWindowFocusEffect,
  useWatchUserAuthStatus,
} from "./hooks";
import { LoginRoutesComponent } from "../../routes/LoginRoutes";
import { useAppSelector } from "../../redux";
import { usePostSignIn } from "../../components/Login/hooks";
import { getIsLoggedIn, legacyTokensExist } from "../../utility/auth";

const { GlobalStyles } = theme;

export const Main = ({ children }: { children?: React.ReactNode }) => {
  const postSignIn = usePostSignIn();
  // Watch login status and dispatch the login saga if user becomes authenticated
  // NOTE: This is used to re-setup the app after a full page refresh
  const { authStatus } = useWatchUserAuthStatus({
    postAuthentication: (user) => postSignIn(user.getUsername()),
  });

  const { mutate: checkSessionOrRefreshAuth } = useCheckSessionOrRefreshAuth();
  // When user clicks back into page check if they are still
  // authenticated / refresh their cognito tokens etc
  useOnWindowFocusEffect(() => {
    if (!getIsLoggedIn()) {
      // If user is not logged in we don't need to check if session is valid
      return;
    }

    // Whenever we check a users authentication status, also
    // Check to make sure the user's company hasn't been switched.
    checkSessionOrRefreshAuth();
  });

  // We want user to be setup in Amplify + have our legacy tokens present
  // Legacy tokens are still used for some purposes
  const legacyTokensPresent = legacyTokensExist();
  const isLoggedIn = authStatus === "authenticated";
  const ability = useAppSelector(getUserPermissions);
  const sessionFetched = useAppSelector(sessionFetchedSelector);
  const isSplitioReady = useAppSelector(getIsSplitioReady);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AbilityContext.Provider value={ability}>
        <ConfirmationModalContainer />
        {isLoggedIn && !legacyTokensPresent && <SpinnerWithOverlay />}
        {legacyTokensPresent && isSplitioReady ? (
          <App fade visible={sessionFetched}>
            {isLoggedIn && sessionFetched && <NavBar />}
            {/* This error boundary wraps any problems within the app, to hopefully recover
             by redirecting to the error page */}
            <ErrorBoundaryWithRedirect>
              <AppContent id={MAIN_SCROLLABLE_PANEL}>{children}</AppContent>
            </ErrorBoundaryWithRedirect>
          </App>
        ) : null}
        {!isLoggedIn && <LoginRoutesComponent />}
        {isLoggedIn && <CustomerSupportButton />}
      </AbilityContext.Provider>
    </ThemeProvider>
  );
};
