import React, { useState } from "react";
import { AddShiftModal } from "../AddShift/AddShiftModal";
import { StyledIcon } from "../TimesheetsPageTable/styledComponents";
import { StyledAddShiftButton } from "./styledComponents";

const addShiftIcon = `${import.meta.env.VITE_ASSET_BASE_URL}/icons/Timesheets/add-shift.svg`;
export const ButtonAddShift = () => {
  const [isAddShiftModalOpen, setAddShiftModalOpen] = useState(false);

  return (
    <>
      <StyledAddShiftButton
        data-testid={"add-shift-button"}
        label="Add Shift"
        icon={<StyledIcon src={addShiftIcon} />}
        onClick={() => setAddShiftModalOpen(true)}
      />
      <AddShiftModal isModalOpen={isAddShiftModalOpen} setModalOpen={setAddShiftModalOpen} />
    </>
  );
};
