import * as React from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Hidden, HiddenProps } from "./Hidden";
import { StepContainerSelectors, StepContainerActions } from "./StepContainer";

export interface StepProps extends HiddenProps {
  step: string;
  isCurrent: StepContainerSelectors["isCurrent"];
  register: StepContainerActions["register"];
  update: StepContainerActions["update"];
  unregister: StepContainerActions["unregister"];
  order?: number;
}

class StepComponent extends React.Component<StepProps> {
  constructor(props: StepProps) {
    super(props);
    const { register, step, order } = this.props;
    register(step, order);
  }

  componentDidUpdate(prevProps: StepProps) {
    const { step, update, order } = this.props;
    if (prevProps.step !== step || prevProps.order !== order) {
      update(prevProps.step, step, order);
    }
  }

  componentWillUnmount() {
    const { step, unregister } = this.props;
    unregister(step);
  }

  render() {
    const { isCurrent, step } = this.props;
    return <Hidden unmount visible={isCurrent(step)} {...this.props} />;
  }
}

const StyledStep = styled(StepComponent)`
  ${theme("Step")};
`;

export const Step = (props: StepProps) => {
  const { children, ...attrs } = props;
  return <StyledStep {...attrs}>{children}</StyledStep>;
};
