import React from "react";
import { differenceInDays, isBefore, isToday } from "date-fns";
import {
  StyledWorkerName,
  StyledWorkerStats,
  WorkerDetailsContainer,
  WorkerInfoContainer,
  WorkerStatContainer,
} from "../styledComponents";
import { StyledUserAvatar } from "../../../PayrollReports/PayrollReportsTable/styledComponents";
import { StyledIcon } from "../../TimesheetsPageTable/styledComponents";
import { CompanyWorker } from "../../../../../api/bluecrew/payroll";

const approvalRatingIconPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/AddShift/approval-rating.svg`;
const shiftsWorkedIconPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/AddShift/shifts-worked.svg`;
const lastShiftWorkedIconPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/AddShift/last-shift-worked.svg`;

// TODO(@dchhina): WTF is this?
// https://github.com/Bluecrew/web-angular/blob/2bc322909c32dd6415317817b44a46cde36736a3/src/client/public/js/ng-bluecrew/components/manager-app/workforce/managerWorkforce.html#L36-L36
const getApprovalRating = (worker: CompanyWorker) =>
  ((worker.neutralShiftsCount + worker.platformShiftsCount) /
    (worker.platformShiftsCount + worker.neutralShiftsCount + worker.negativeShiftsCount)) *
  100;

const getLastShiftWorkedDisplay = (lastShiftDate: Date) =>
  isToday(lastShiftDate) ? "Today" : `${differenceInDays(lastShiftDate, new Date())} days ago`;

const getFullName = (worker: CompanyWorker) => `${worker.firstName} ${worker.lastName}`;

export type WorkerInfoProps = {
  worker: CompanyWorker;
};
export const WorkerInfo = ({ worker }: WorkerInfoProps) => (
  <WorkerInfoContainer>
    <StyledUserAvatar
      fullName={getFullName(worker)}
      imgSrc={worker.profilePicSrc?.toString()}
      size={"lg"}
    />
    <WorkerDetailsContainer>
      <StyledWorkerName>{getFullName(worker)}</StyledWorkerName>
      <div style={{ display: "flex" }}>
        <WorkerStatContainer>
          {Number.isFinite(getApprovalRating(worker)) && (
            <StyledWorkerStats>
              <StyledIcon src={approvalRatingIconPath} />{" "}
              {`${getApprovalRating(worker).toFixed(0)}%`}
            </StyledWorkerStats>
          )}
        </WorkerStatContainer>
        <WorkerStatContainer>
          {worker.platformShiftsCount >= 0 && (
            <StyledWorkerStats>
              <StyledIcon src={shiftsWorkedIconPath} /> {worker.platformShiftsCount}
            </StyledWorkerStats>
          )}
        </WorkerStatContainer>
        <WorkerStatContainer>
          {worker.lastShift &&
            isBefore(worker.lastShift, new Date()) && ( // For some reason we have this case
              <StyledWorkerStats>
                <StyledIcon src={lastShiftWorkedIconPath} />{" "}
                {getLastShiftWorkedDisplay(worker.lastShift)}
              </StyledWorkerStats>
            )}
        </WorkerStatContainer>
      </div>
    </WorkerDetailsContainer>
  </WorkerInfoContainer>
);
