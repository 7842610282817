// POSITION - ACTIONS
// =============================================================================

/* IMPORTANT MESSAGE TO ENG. ABOUT TO EDIT THIS FILE:
 Don't add anything to this file unless you have to, which means that only do it
 if you are editing one of these functions, or adding a new function into somewhere where
 these are being used.
 Instead, prefer using this structure: https://redux.js.org/tutorials/essentials/part-2-app-structure
*/
import { createActions } from "redux-actions";
import { PositionRequest, ReadPositionRequest } from "../../api/bluecrew/types/index";

export default createActions({
  POSITIONS: {
    REQUEST: (req: PositionRequest) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  READ_POSITION: {
    REQUEST: (req: ReadPositionRequest) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CLEAR_READ_POSITION: () => {},
  CLEAR_READ_POSITION_EDITS: () => {},
  UPDATE_SINGLE_PROPERTY_SCHEDULE: (req: any) => ({ ...req }),
  GET_POSITIONS_HEADER_DETAILS: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
}) as any;
