import React, { ForwardedRef, forwardRef, Ref } from "react";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import {
  ComponentPosition,
  CrewGroupListItemProps,
  CrewGroupListItemT,
  ListItemRendererT,
  VerticalAlign,
} from "./types";
import { RichListItem } from "./RichListItem";
import {
  StyledCrewGroupListItemContainer,
  StyledCrewGroupMemberCount,
  StyledCrewGroupTitle,
} from "./styled-components";

const CrewGroupListItemCmp = <T extends CrewGroupListItemT>(
  { item, onClick, underlineLastItem }: CrewGroupListItemProps<T>,
  ref: Ref<HTMLLIElement>,
) => (
  <RichListItem<T>
    ref={ref}
    item={item}
    checkboxPosition={ComponentPosition.LEFT}
    leftContainerContentAlign={VerticalAlign.CENTER}
    onClick={onClick}
    underlineLastItem={underlineLastItem}
    titleRenderer={titleRenderer}
    showCheckBox
  />
);

const titleRenderer: ListItemRendererT<CrewGroupListItemT> = (listItem) => (
  <StyledCrewGroupListItemContainer>
    <StyledCrewGroupTitle $theme={blueprintTheme}>{listItem.title}</StyledCrewGroupTitle>
    <StyledCrewGroupMemberCount>{listItem.membersCount}</StyledCrewGroupMemberCount>
  </StyledCrewGroupListItemContainer>
);

export const CrewGroupListItem = forwardRef(CrewGroupListItemCmp) as <T extends CrewGroupListItemT>(
  props: CrewGroupListItemProps<T> & { ref?: ForwardedRef<HTMLLIElement> },
) => JSX.Element;
