import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { UserInitials } from "./UserInitials";

export const sizes = {
  sm: 32,
  smd: 40,
  md: 48,
  lg: 64,
  xl: 80,
};

const StyledAvatar = styled.img<{ size: string }>`
  width: ${(props) => sizes[props.size]}px;
  height: ${(props) => sizes[props.size]}px;
  border: none;
  border-radius: 50%;
`;

export const UserAvatar = (props) => {
  return (
    <div className={props.classes}>
      {props.imgSrc ? (
        <StyledAvatar
          alt={props.fullName}
          size={props.size}
          src={props.imgSrc}
          title={props.fullName}
        />
      ) : (
        <UserInitials {...props} />
      )}
    </div>
  );
};

UserAvatar.propTypes = {
  size: PropTypes.oneOf(["sm", "smd", "md", "lg", "xl"]),
  classes: PropTypes.string,
  imgSrc: PropTypes.string,
  fullName: PropTypes.string,
};

UserAvatar.defaultProps = {
  classes: null,
  size: "sm",
  fullName: "A A",
};
