import React, { useState } from "react";
import { useController, useWatch } from "react-hook-form";
import { styled } from "baseui";
import { MAX_INVITEES_COUNT } from "./maxInviteesCount.constant";
import { CenteredWrapper } from "../CenteredWrapper";
import {
  CrewGroup,
  CrewGroups,
  CrewMember,
  CrewMembers,
} from "../types/propTypes/ChooseCrewModal.types";
import { CrewMemberFieldSetProps } from "../types/propTypes/CrewMemberFieldSet.types";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { ChooseCrewList } from "./ChooseCrewList";
import { ChooseCrewModal } from "./workforce/ChooseCrewModal";
import { ChooseCrewField } from "./ChooseCrewField";
import { MemberCountField } from "./MemberCountField";

export const CrewMemberFieldSet = ({
  control,
  trigger,
  crewMembers,
  crewGroups,
  hideCrewGroups,
  disabled,
}: CrewMemberFieldSetProps) => {
  const fieldName = "crew";
  const validate = trigger.bind(null, fieldName);

  const crew = useWatch<CrewMembers | CrewGroups>({
    name: fieldName,
    control,
  });

  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: (list: []) => !list || list.length <= MAX_INVITEES_COUNT,
    },
  });

  const [isChooseCrewEnabled, setIsChooseCrewEnabled] = useState<boolean>(!!crew?.length);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Array<Omit<CrewMember, "externalId"> | CrewGroup>>([]);

  const onSwitchClick = (isEnabled: boolean) => {
    setIsChooseCrewEnabled(isEnabled);
    setModalOpen(isEnabled);
    !isEnabled && onChange([]);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsChooseCrewEnabled(Array.isArray(value) && !!value.length);
    (!Array.isArray(value) || !value.length) && onChange([]);
  };

  const onDone = () => {
    setModalOpen(false);
    setIsChooseCrewEnabled(!!selected.length);
    onChange(selected);
    validate();
  };

  const isBlueshiftSchedule = control.getValues().isBlueShiftSchedule;

  return (
    <>
      <CenteredWrapper>
        <MemberCountField disabled={disabled} control={control} trigger={trigger} />
        <Spacer $type={SpacerType.horizontal} $size="38px" />
        <ChooseCrewField
          disabled={disabled}
          isBlueshiftSchedule={isBlueshiftSchedule}
          isEnabled={isChooseCrewEnabled}
          onSwitchClick={onSwitchClick}
        />
      </CenteredWrapper>
      {isChooseCrewEnabled && Array.isArray(crew) && crew.length > 0 && (
        <SelectedCrewWrapper>
          <Spacer $type={SpacerType.vertical} $size="24px" />
          <ChooseCrewList
            disabled={disabled}
            isBlueshiftSchedule={isBlueshiftSchedule}
            list={crew}
            onEditClick={() => setModalOpen(true)}
          />
        </SelectedCrewWrapper>
      )}
      <ChooseCrewModal
        crewMembers={crewMembers}
        crewGroups={crewGroups}
        selected={value}
        isOpen={modalOpen}
        onSelectionChange={setSelected}
        onDoneClick={onDone}
        onClose={closeModal}
        hideCrewGroups={hideCrewGroups}
      />
    </>
  );
};

const SelectedCrewWrapper = styled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale1000,
}));
