import React from "react";
import { UserShiftLastUpdateBy } from "../../../types/Shift.types";
import { ApprovalTextDisplayWrapper, StyledApprovalText, StyledIcon } from "../styledComponents";
import { PayrollStatusReason, TimesheetSupportUserTypes } from "../../../types/Payroll.types";

const approvedIconPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/approved-icon.svg`;

const getApprovedByText = (
  lastUpdateBy: UserShiftLastUpdateBy,
  statusReason?: PayrollStatusReason,
) => {
  if (TimesheetSupportUserTypes.includes(lastUpdateBy.userType)) {
    const supportApprovedPrefix = "Approved by Bluecrew support";
    // TODO: This inference should ideally come from an audit log. Using reason code as a proxy.
    if (statusReason === PayrollStatusReason.SUPPORT_UNADJUSTED) {
      return `${supportApprovedPrefix} (with Workplace edits)`;
    }
    if (statusReason === PayrollStatusReason.NO_ADJUSTMENTS) {
      return `${supportApprovedPrefix} (with Crew Member timestamps)`;
    }
    if (statusReason === PayrollStatusReason.SUPPORT_ADJUSTED) {
      return `${supportApprovedPrefix} (with Support adjustments)`;
    }
    return supportApprovedPrefix;
  }
  return `Approved by ${lastUpdateBy.name}`;
};

export type ApprovalTextDisplayProps = {
  isAutoApproved: boolean;
  lastUpdatedBy: UserShiftLastUpdateBy;
  statusReason?: PayrollStatusReason;
};

export const ApprovalTextDisplay = ({
  isAutoApproved,
  lastUpdatedBy,
  statusReason,
}: ApprovalTextDisplayProps) => (
  <ApprovalTextDisplayWrapper>
    <StyledIcon src={approvedIconPath} />
    <StyledApprovalText>
      {isAutoApproved ? "Auto-approved" : getApprovedByText(lastUpdatedBy, statusReason)}
    </StyledApprovalText>
  </ApprovalTextDisplayWrapper>
);
