import React, { ChangeEvent } from "react";
import styled from "styled-components";
import { InputText } from "../../../components/InputText";
import { Dropdown } from "../../../components/Dropdown";
import { PositionsSortOptions } from "./Positions";

const Container = styled.div`
  flex-direction: row;
  margin-top: 40px;
  height: 20px;
`;

const SortDropdown = styled.div`
  display: flex;
  float: right;
  flex-direction: row;
  align-items: center;
`;

const StyledInput = styled(InputText)`
  width: 380px;
`;

const SearchBar = styled.div`
  float: left;
`;

const StyledDropdown = styled(Dropdown)`
  width: 280px;
`;

const SortTextContainer = styled.div`
  margin-right: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
`;

type PositionsHeaderProps = {
  positionNameSearchInput: string;
  setPositionNameSearchInput: (newValue: string) => void;
  positionSortOption: string;
  setPositionSortOption: React.Dispatch<React.SetStateAction<PositionsSortOptions>>;
};

export const PositionsHeader = ({
  positionNameSearchInput,
  setPositionNameSearchInput,
  positionSortOption,
  setPositionSortOption,
}: PositionsHeaderProps) => (
  <Container>
    <SearchBar>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <StyledInput
          placeholder="Search positions"
          value={positionNameSearchInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPositionNameSearchInput(e.target.value)
          }
        />
      </span>
    </SearchBar>
    <SortDropdown>
      <SortTextContainer>Sort by</SortTextContainer>
      <StyledDropdown
        value={positionSortOption}
        onChange={(e) => setPositionSortOption(e.value)}
        options={Object.values(PositionsSortOptions)}
        placeholder={"Choose a skill set"}
      />
    </SortDropdown>
  </Container>
);
