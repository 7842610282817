import { KIND } from "baseui/toast";
import { ToastInfo } from "../../api/bluecrew/types";
import {
  SPLIT_IO_IS_BROKEN,
  SplitIoIsBrokenAction,
  ShowToastAction,
  SHOW_TOAST,
} from "../actions/app";

export type AppState = {
  splitIoIsBroken: boolean;
  toast: ToastInfo;
};

const initialState: AppState = {
  splitIoIsBroken: false,
  toast: {
    visible: false,
    date: Date.now(),
    message: "",
    kind: KIND.info,
  },
};

export default function appReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AppState = initialState,
  action: SplitIoIsBrokenAction | ShowToastAction,
): AppState {
  switch (action.type) {
    case SPLIT_IO_IS_BROKEN:
      return { ...state, splitIoIsBroken: true };
    case SHOW_TOAST:
      // @ts-ignore if type is SHOW_TOAST, action will always have a payload
      return { ...state, toast: action.payload };
    default:
      return state;
  }
}
