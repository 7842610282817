import { styled } from "baseui";
import React from "react";
import { BlueprintTheme, CrewGroupIcon, UserAvatar } from "@bluecrew/blueprint-web";
import { CrewMemberListItemProps } from "../../types/propTypes/CrewMemberListItem.types";
import { CrewGroup, CrewMember, isCrewGroup } from "../../types/propTypes/ChooseCrewModal.types";

export const CrewMemberListItem = ({ item }: CrewMemberListItemProps) => {
  const isCrewGroupItem = isCrewGroup(item);

  return (
    <StyledListItem>
      <StyledAvatarContainer>
        {isCrewGroupItem ? (
          <CrewGroupIcon />
        ) : (
          <UserAvatar fullName={item.name} size="lg" imgSrc={(item as CrewMember).imgUrl} />
        )}
      </StyledAvatarContainer>
      <StyledLabelsContainer>
        <StyledUserTitle>{item.name}</StyledUserTitle>
        <StyledUserSubtitle>
          {isCrewGroupItem
            ? `${(item as CrewGroup).members} Crew Members`
            : (item as CrewMember).shifts}
        </StyledUserSubtitle>
      </StyledLabelsContainer>
    </StyledListItem>
  );
};

const StyledListItem = styled("div", ({ $theme }) => ({
  display: "flex",
  width: "230px",
  marginBottom: $theme.sizing.scale600,
  marginRight: $theme.sizing.scale600,
}));

const StyledAvatarContainer = styled("div", ({ $theme }) => ({
  marginRight: $theme.sizing.scale300,
  display: "flex",
  justifyContent: "center",
}));

const StyledLabelsContainer = styled("div", {
  flex: 1,
  overflow: "hidden",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
});

const StyledUserTitle = styled<{}, "h6", BlueprintTheme>("h6", ({ $theme }) => ({
  ...$theme.typography.font350,
  fontWeight: 600,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.obsidian,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  margin: `0 ${$theme.sizing.scale300} 0 0`,
}));

const StyledUserSubtitle = styled<{}, "div", BlueprintTheme>("div", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.slate,
  margin: 0,
  fontSize: "13px",
}));
