import React, { ReactNode, useMemo } from 'react';
import { themedStyled } from '../theme';

export const AvatarSizes = {
  xxs: 20,
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
  xl: 64,
  xxl: 80,
  xxxl: 96,
};

export type AvatarSizesList = keyof typeof AvatarSizes;

type UserInitialsProps = {
  size: AvatarSizesList;
  customLabel?: string | ReactNode;
  fullName?: string;
};

type InitialsWrapperProps = {
  $size: AvatarSizesList;
};

const LABEL_SCALE_FACTOR = 2.4;

const InitialsWrapper = themedStyled<'div', InitialsWrapperProps>(
  'div',
  ({ $theme, $size }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    // apply base text style
    ...$theme.typography.font100,
    color: $theme.colors.obsidian,
    textTransform: 'uppercase',

    // compute font size based on size
    fontSize: `${AvatarSizes[$size] / LABEL_SCALE_FACTOR}px`,
    lineHeight: 1.3,
  }),
);

export const UserInitials = ({
  size,
  customLabel,
  fullName,
}: UserInitialsProps) => {
  const imageText = useMemo(() => {
    if (customLabel) return customLabel;

    /* limit display to only two and handle case if there is only the first name */
    const initialsArr = (fullName ?? '')
      .split(' ')
      .map((name) => name.substring(0, 1));

    return `${initialsArr.shift()}${initialsArr.pop() ?? ''}`;
  }, [fullName, customLabel]);

  return (
    <InitialsWrapper $size={size}>
      <span>{imageText}</span>
    </InitialsWrapper>
  );
};
