export const GoogleMapMarkerIconSvg = `<svg width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d)">
    <path d="M18.836 37.2C18.5172 37.2 18.2046 37.1134 17.9325 36.9497C14.8563 35.0977 5 28.5379 5 18.4724C5 9.95786 10.187 4 18.8315 4C27.476 4 32.663 9.95786 32.6666 18.4724C32.6666 28.5308 22.8121 35.0977 19.7395 36.9497C19.4674 37.1134 19.1548 37.2 18.836 37.2Z" fill="#3246F0"/>
    <path d="M17.6747 37.3781L17.6748 37.3782C18.025 37.5888 18.4268 37.7 18.836 37.7C19.2452 37.7 19.647 37.5888 19.9972 37.3782L19.9976 37.378C21.5518 36.4412 24.8304 34.3072 27.7252 31.1249C30.6188 27.9439 33.1666 23.6726 33.1666 18.4724V18.4722C33.1648 14.1145 31.8354 10.3662 29.3553 7.70253C26.8712 5.03446 23.2742 3.5 18.8315 3.5C14.3889 3.5 10.7919 5.03445 8.30811 7.70256C5.82846 10.3663 4.5 14.1147 4.5 18.4724C4.5 23.6762 7.04824 27.9474 9.94291 31.1277C12.8386 34.3091 16.1187 36.4413 17.6747 37.3781Z" stroke="white"/>
  </g>
  <circle cx="18.8332" cy="17.8333" r="8.29999" fill="white"/>
  <defs>
    <filter id="filter0_d" x="0" y="0" width="37.6666" height="43.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="2"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0313726 0 0 0 0 0.0352941 0 0 0 0 0.0705882 0 0 0 0.16 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
  </defs>
</svg>`;
