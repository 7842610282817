// CONSTANTS
// =============================================================================
// Collection of otherwise magic numbers and misc application-wide data

export const DEFAULT_BASE_WAGE = "15.00";
export const DEFAULT_TAX_RATE = 12.39;
export const OFFER_JOB = "offer";
export const REJECT_APPLICATION = "reject";
export const DAY_MASK = [
  { idx: "0", name: "S", value: 1 },
  { idx: "1", name: "M", value: 2 },
  { idx: "2", name: "T", value: 4 },
  { idx: "3", name: "W", value: 8 },
  { idx: "4", name: "T", value: 16 },
  { idx: "5", name: "F", value: 32 },
  { idx: "6", name: "S", value: 64 },
];

export const BULK_SINGLE_SHIFT_SPLIT_NAME = "blueshift_bulk_single_shift";
export const BULK_SINGLE_SHIFT_50_DAY_MAXIMUM_SPLIT_NAME =
  "blueshift_bulk_single_shift_50_day_maximum";
export const TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME = "timetracking_initial_migration";
export const SHOW_TIMESHEETS_SPLIT_NAME = "show_timesheets";
export const COGNITO_MIGRATION_SPLIT_NAME = "workplace_cognito_migration";
export const CUSTOM_COGNITO_FORMS_SPLIT_NAME = "custom_cognito_forms";
export const RESEND_INVITE_MANAGE_USERS_SPLIT_NAME = "resend_invite_manage_users";
export const TIMESHEETS_P1_MESSAGING_SPLIT_NAME = "timesheets-p1-messaging";
export const TIMESHEETS_BACKEND_MIGRATION_SPLIT_NAME = "timetracking_backend_migration";
export const ADVANCED_BACKGROUND_CHECK_SPLIT_NAME = "advanced_background_check_p0";
export const DRUG_SCREENING_SPLIT_NAME = "drug_screening_p0";

// Split.io treatment keys
export const ACTIVE_SPLIT_NAMES = [
  BULK_SINGLE_SHIFT_SPLIT_NAME,
  BULK_SINGLE_SHIFT_50_DAY_MAXIMUM_SPLIT_NAME,
  TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME,
  SHOW_TIMESHEETS_SPLIT_NAME,
  COGNITO_MIGRATION_SPLIT_NAME,
  TIMESHEETS_P1_MESSAGING_SPLIT_NAME,
  TIMESHEETS_BACKEND_MIGRATION_SPLIT_NAME,
  CUSTOM_COGNITO_FORMS_SPLIT_NAME,
  ADVANCED_BACKGROUND_CHECK_SPLIT_NAME,
  DRUG_SCREENING_SPLIT_NAME,
];

const OPTIMIZED_POST_TYPE = 0;
export const CREWS_ONLY_POST_TYPE = 1;

export type NumericTypeOfPost = typeof OPTIMIZED_POST_TYPE | typeof CREWS_ONLY_POST_TYPE;

export const CONTAINER_MAX_WIDTH = "1367px"; // Per Brandon

export const Modals = {
  DEFAULT: "DEFAULT",
  REQUEST_CREW: "REQUEST_CREW",
  ADD_REMOVE_DAY: "ADD_REMOVE_DAY",
  MODIFY_TIME: "MODIFY_TIME",
  EDIT_SCHEDULE: "EDIT_SCHEDULE",
  ARCHIVE_SCHEDULE: "ARCHIVE_SCHEDULE",
  UNPIN_SCHEDULE: "UNPIN_SCHEDULE",
  ARCHIVE_UNPINNED_SCHEDULE: "ARCHIVE_UNPINNED_SCHEDULE",
  PIN_SCHEDULE: "PIN_SCHEDULE",
  DELETE_SCHEDULE: "DELETE_SCHEDULE",
  DELETE_WORK_REQUEST: "DELETE_WORK_REQUEST",
  ADD_PREVIOUS_CREW: "ADD_PREVIOUS_CREW",
  ARCHIVE_POSITION: "ARCHIVE_POSITION",
  UPDATE_POSITION_DETAILS: "UPDATE_POSITION_DETAILS",
  OFFER_JOB: "OFFER_JOB",
  OPEN_APPLICATION_JOBS_TO_PLATFORM: "OPEN_APPLICATION_JOBS_TO_PLATFORM",
  REJECT_APPLICATION_JOB: "REJECT_APPLICATION_JOB",
  ENABLE_CONTINUOUS_FILL: "ENABLE_CONTINUOUS_FILL",
  DISABLE_CONTINUOUS_FILL: "DISABLE_CONTINUOUS_FILL",
};

// COPY

export const ZeroStates = {
  NoJobsAccepted: {
    icon: "/public/images/icons/accept-job.svg",
    text: "Crew member will appear here once they accept your job.",
  },
};

export const ScheduleDetailsCopy = {
  schedule_nickname: {
    label: "Schedule name",
    placeholder: "e.g. 1st Shift, Weekday Shift",
  },
  work_days: {
    label: "Set work days",
  },
  start_time: {
    label: "Start time",
  },
  end_time: {
    label: "End time",
  },
  supervisor: {
    label: "Supervisor",
    placeholder: "Assign a supervisor...",
  },
  differentialWage: {
    label: "Additional pay (hourly)",
    wageInputText: "Total pay: (base + additional)",
  },
  department: {
    label: "Department",
    placeholder: "Assign a department...",
  },
  jobNotifications: {
    label: "Job Notifications",
    placeholder: "Add teammates to notify...",
  },
  workers_needed: {
    label: "Number of crew members",
  },
};

export const SharedCopy = {
  post_a_job: "Post a job",
};

// Company Settings
export const TimeSheetsDefaults = {
  terminalModeEnabled: false,
  lateClockoutBufferMinutes: 30,
  minLunchDurationHours: 0.5,
  earlyClockinBufferMinutes: 30,
  autoApprovalEnabled: false,
  thirdPartyTimetracker: null,
};

export const CONTINUOUS_FILL_ON = 1;
export const CONTINUOUS_FILL_OFF = 0;

export const COMPANY_DESCRIPTION_MIN_LENGTH = 10;
export const COMPANY_DESCRIPTION_MAX_LENGTH = 2000;
// In company creation flow (which is still in web-angular as of 9/21/22), we allow max 2000 chars for company description
// We allow 5000 for position description... Do we want to allow more for company desc? Not sure it matters - @dloukusa

export const GeofenceDefaults = {
  minRadiusMeters: 250,
  minBlockRadiusMeters: 8000,
  minWarnBlockRadiusDifferenceMeters: 3000,
  maxRadiusMeters: 10_000_000, // 10,000 km
  onsiteWarnRadiusMeters: 250,
  onsiteBlockRadiusMeters: 10_0000,
  offsiteWarnRadiusMeters: 250,
  offsiteBlockRadiusMeters: 10_0000,
};

// Cognito do define a type for AuthStatus, but it's just a union of string literals
export enum AuthStatus {
  CONFIGURING = "configuring",
  AUTHENTICATED = "authenticated",
  UNAUTHENTICATED = "unauthenticated",
}

export const COGNITO_USER_NAME_KEY = "COGNITO_USER_NAME";
export const COGNITO_ENABLED_KEY = "COGNITO_ENABLED";
export const LOGGING_OUT_KEY = "LOGGING_OUT";
export const REDIRECT_URL = "REDIRECT_URL";

export const MIN_SHIFT_LENGTH_HOURS = 4;
export const MAX_SHIFT_LENGTH_HOURS = 24;
export const ADD_SHIFT_SCHEDULE_NAME = "AddShiftSchedule";

export const payrollPortalRedirectUrls = [
  "http://localhost:3000/",
  "http://local.dev.bluecrewenv.com/",
  "https://local.dev.bluecrewenv.com/",
  "https://payroll-portal-web.dev.bluecrewenv.com/",
  "https://payroll-portal-web.stg.bluecrewenv.com/",
  "https://payroll.bluecrewjobs.com/",
];

export const approvedRedirectUrls = [
  "http://localhost:8080/app.html",
  "https://app.stg.bluecrewenv.com",
  "https://app.dev.bluecrewenv.com",
  "https://app.bluecrewjobs.com",
  ...payrollPortalRedirectUrls,
];
export const MAX_PHONE_NUMBER_LENGTH = 10; // +1 prefix is added in backend

export const BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT = 5;
export const TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT = 50;

export const IS_SECURE = import.meta.env.VITE_COOKIE_DOMAIN !== "localhost";
export const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN || "localhost";

export const LoginRouteStrings = {
  Login: "/login",
  ResetPassword: "/resetPassword",
  ActivateAccount: "/activate",
} as const;
export const LoginRouteStringsArray: string[] = Object.values(LoginRouteStrings);

export const JWT_TOKEN_NAME = "bc.jwt";
export const BEARER_TOKEN_NAME = "bc.bearer";
