import { calculateShifts } from "@bluecrew/bluecrew-lib";
import { DAY_MASK } from "../../shared/constants";

// This function determines a schedule's shift days dependending on value selected from the sched name dropdown
const calculateShiftDays = (daysNeededArray: Array<String>) => {
  let result = DAY_MASK.map((element) => {
    if (daysNeededArray.includes(element.idx)) {
      return element.value;
    }
    return undefined;
  });

  result = result.filter(Number);
  return result.reduce(
    // @ts-ignore
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );
};

// This function returns a total number of shifts for a schedule
export const totalShifts = (
  days: Array<Object>,
  startDate: string,
  endDate: string,
  timezone: string,
) => {
  // @ts-ignore
  const dayMaskValue = calculateShiftDays(days);

  const total = calculateShifts(startDate, endDate, dayMaskValue, timezone);
  return total.length;
};
