const convertTimezoneKeepingTime = require("./convert-timezone-keeping-time");
const moment = require("moment-timezone");

const timeComponents = ["hours", "minutes", "seconds", "milliseconds"];

/*
 Gets the length in seconds (to prevent rounding errors) of the shifts for a job
 taking place from the given start date to the given end date.

 In most cases, this will be the difference in seconds between the end time's hour value
 and the start time's hour value (e.g, a job from 9:00 to 17:00 will return eight hours),
 with additional logic for jobs that run overnight.

 The returned value may be different than initially expected if the job's last
 shift crosses over a DST change. For example, a job posted for
 November 1st 2016 at 10:00 PM to November 6th 2016 at 4:00 AM actually
 has a seven hour shift each day (rather than six) because the last shift
 runs from November 5th at 10:00 PM to November 6th 2016 at 4:00 AM,
 crossing over the end of DST.
 */
module.exports = function (startDateStr, endDateStr, timezone) {
  // get the end date of the last shift.
  var lastEndDate = convertTimezoneKeepingTime(moment.parseZone(endDateStr), timezone);

  // get the start date of the first shift.
  var firstStartDate = convertTimezoneKeepingTime(moment.parseZone(startDateStr), timezone);

  // get the start date of the last shift.
  // we do this by replacing the time of the end date with that of the start date ...
  var lastStartDate = lastEndDate.clone();
  timeComponents.forEach(function (timeComponent) {
    lastStartDate.set(timeComponent, firstStartDate.get(timeComponent));
  });

  // ... and moving it a day backwards if the end date now occurs earlier (for overnight shifts)
  if (lastStartDate > lastEndDate) {
    lastStartDate.add(-1, "day");
  }

  // return the difference between the last shift's end date and start date
  var diff = lastEndDate.diff(lastStartDate);
  return moment.duration(diff).asSeconds();
};
