import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobCateringIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip-catering-icon)">
        <path
          d="M18.372 7.17161L23.88 4.02161C23.9987 3.9536 24.0966 3.8544 24.163 3.73477C24.2294 3.61514 24.2618 3.47962 24.2567 3.34289C24.2516 3.20617 24.2092 3.07344 24.134 2.95908C24.0589 2.84473 23.954 2.75309 23.8305 2.69411L18.3225 0.073611C18.2082 0.0191845 18.082 -0.00543594 17.9557 0.00204297C17.8293 0.00952188 17.7069 0.0488568 17.5999 0.116384C17.4928 0.183911 17.4046 0.277434 17.3434 0.388242C17.2822 0.49905 17.2501 0.623538 17.25 0.750111V6.51911C17.2498 6.65058 17.2841 6.77979 17.3496 6.8938C17.415 7.00781 17.5093 7.10262 17.623 7.16871C17.7366 7.2348 17.8656 7.26985 17.9971 7.27036C18.1286 7.27087 18.2578 7.23682 18.372 7.17161Z"
          fill={svgColor}
        />
        <path
          d="M9.36897 15.75H26.6325C26.7093 15.7499 26.7842 15.7262 26.8471 15.6822C26.9101 15.6381 26.9579 15.5758 26.9843 15.5036C27.0106 15.4315 27.0142 15.353 26.9944 15.2787C26.9747 15.2045 26.9327 15.1381 26.874 15.0885L18.4845 7.98902C18.349 7.87441 18.1774 7.81152 18 7.81152C17.8226 7.81152 17.6509 7.87441 17.5155 7.98902L9.12597 15.0885C9.06718 15.1382 9.02509 15.2047 9.0054 15.2791C8.98571 15.3535 8.98939 15.4321 9.01592 15.5043C9.04245 15.5766 9.09056 15.6389 9.15372 15.6828C9.21687 15.7268 9.29202 15.7502 9.36897 15.75Z"
          fill={svgColor}
        />
        <path
          d="M34.2795 33.7171C32.3295 30.5431 29.1045 23.9671 28.5795 18.3405C28.5708 18.2464 28.5268 18.159 28.4565 18.0959C28.3861 18.0327 28.2945 17.9985 28.1999 18H7.79995C7.70645 18 7.61631 18.0349 7.54722 18.0979C7.47812 18.1609 7.43505 18.2474 7.42645 18.3405C6.89695 23.9671 3.66745 30.5446 1.72645 33.7171C1.58756 33.944 1.51154 34.2038 1.50617 34.4698C1.50079 34.7358 1.56627 34.9984 1.69588 35.2308C1.8255 35.4631 2.01458 35.6568 2.24374 35.792C2.4729 35.9272 2.73389 35.999 2.99995 36.0001H10.7999C10.9363 36.0001 11.0701 35.963 11.1869 35.8927C11.3037 35.8224 11.3991 35.7215 11.4629 35.6011L17.3384 24.501C17.4022 24.3805 17.4976 24.2796 17.6145 24.2092C17.7313 24.1389 17.8651 24.1017 18.0014 24.1017C18.1378 24.1017 18.2716 24.1389 18.3884 24.2092C18.5053 24.2796 18.6007 24.3805 18.6644 24.501L24.54 35.6011C24.6035 35.7211 24.6985 35.8216 24.8147 35.8919C24.931 35.9622 25.0641 35.9995 25.1999 36.0001H33C33.2665 36.0001 33.5283 35.929 33.7583 35.7943C33.9883 35.6595 34.1782 35.4659 34.3085 35.2334C34.4389 35.0009 34.5049 34.7378 34.4998 34.4713C34.4946 34.2048 34.4186 33.9444 34.2795 33.7171Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip-catering-icon">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
