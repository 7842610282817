module.exports = {
  CSVheaders: {
    "FIRST.NAME": "",
    "LAST.NAME": "",
    "MIDDLE.INIT": "",
    "BIRTH.DATE": "",
    "COMPANY.ID": "",
    "EMPLOYER.ID": "",
    "ETHNIC.CODE": "",
    "MARITAL.STATUS": "",
    TOBACCO: "",
    "ADDR.ONE": "",
    "ADDR.TWO": "",
    "ZIP.CODE": "",
    CITY: "",
    STATE: "",
    TELEPHONE: "",
    HANDICAPPED: "",
    BLIND: "",
    "VIETNAM.VET": "",
    "DISABLED.VET": "",
    CITIZEN: "",
    GENDER: "",
    "EMER.CONTACT": "",
    "EMER.PHONE": "",
    "EMER.RELATION": "",
    "SOC.SEC.NUM": "",
    "STATUS.CODE": "A", // CONSTANT
    "TYPE.CODE (FT=F/PT=P)": "P", // CONSTANT
    "LOCATION.CODE": "",
    "WORK COMP.CODE": "",
    "ORIG.HIRE": "",
    "LAST.HIRE": "",
    "PEO.START.DT": "",
    "EMPLOYEE.NO": "",
    "WORK.PHONE": "",
    "WORK.EXT": "",
    "MAIL.ADDR.ONE": "",
    "MAIL.ADDR.TWO": "",
    "MAIL.CITY": "",
    "MAIL.STATE": "",
    "MAIL.ZIP": "",
    "SHIFT.CODE": "",
    "FUTURE USE_1": "",
    "PAY.METHOD": "H", // CONSTANT
    "HOURLY.RATE": "12.25", // CONSTANT
    "FUTURE USE_2": "",
    "STD HOURS": "40", // CONSTANT
    "EIC.FILING.STATUS": "",
    "FS.FEDERAL": "",
    "FEDERAL.ALLOWS": "",
    "EXTRA.FEDERAL": "",
    "HOME.STATE.STATUS": "",
    "HOME.STATE.ALLOWS": "",
    "HOME STATE ADDITIONAL AMOUNT": "",
    "WORK.STATE.FS. STATUS": "",
    "WORK.STATEALLOW": "",
    "WORK STATE OVERRIDEAMOUNT": "",
    OFFICER: "",
    "DEPT CODE": "TEMP", // CONSTANT
    "TERM.DATE": "",
    "TERMD.CODE": "",
    "EMAIL.ADDRESS": "",
    "BENEFIT.GROUP": "",
    "USER.FIELD.1": "",
    "USER.FIELD.2": "",
    "USER.FIELD.3": "",
    "USER.FIELD.4": "",
    "USER.FIELD.5": "",
    "ZIP SUFFIX": "",
    "ALT CALC STATE CODE": "",
    "ALT CALC WORK STATE CODE (AZ EMPLOYEES ONLY)": "",
    "FUTURE USE_3": "",
    "FUTURE USE_4": "",
    "FUTURE USE_5": "",
    "1099 EMPLOYEE": "",
    "FUTURE USE_6": "",
    "PAY.GROUP": "W1", // CONSTANT
    "DIVISION.CODE": "",
    PROJECT: "",
    "AUTO.PAY": "",
    "AUTO.PAY.HOURS": "",
    "HOME.STATE.EXEPMT.AMOUNT": "",
    "HOME.STATE.SEC.ALLOWS": "",
    "HOME.STATE.SUPP.AMOUNT": "",
    "HOME.STATE.EXPEMT.AMOUNT": "",
    "WORK.STATE.SECONDARYALLOWS": "",
    "WS.SUPP.AMOUNT": "",
    "PAY PERIOD": "H", // CONSTANT
    VETERAN: "",
    "NEWLY.SEPARATED.VET": "",
    "SERVICE.METAL.VET": "",
    "OTHER.PROTECTED.VET": "",
    "I9.DOCUMENT.TITLE.A": "",
    "I9.DOCUMENT.NUMBER.A": "",
    "I9.ISSUING.AUTHORITY.A": "",
    "I9.EXPIRATION.DATE.A": "",
    "I9.DOCUMENT.TITLE.B": "",
    "I9.DOCUMENT.NUMBER.B": "",
    "I9.ISSUING.AUTHORITY.B": "",
    "I9.EXPIRATION.DATE.B": "",
    "I9.EXPIRATION.DATE.B": "",
    "ALIEN.REG.NO": "",
    "FICA.EXEMPT": "",
    "UNION.CODE": "",
    "SUPERVISOR.ID": "",
    "BENE.THRU.DATE": "",
    "PSD CODE (SCHOOL DISTRICT)": "",
    AGRICULTURAL: "",
    "HOME.PHONE.2": "",
    "ALT.PAY.RATE.1": "",
    "ALT.PAY.RATE.2": "",
    "ALT.PAY.RATE.3": "",
    "ALT.PAY.RATE.4": "",
    "ALT.PAY.RATE.5": "",
    "ALT.PAY.RATE.6": "",
    "ALT.PAY.RATE.7": "",
    "ALT.PAY.RATE.8": "",
    "ALT.PAY.RATE.9": "",
    "ALT.PAY.RATE.10": "",
    "NEW.HIRE.REPORT.DATE": "",
    "MAIL.CHECK.HOME": "",
    W2_ADRESS_ONE: "",
    W2_ADRESS_TWO: "",
    "W2.CITY": "",
    "W2.STATE": "",
    "W2.ZIPCODE": "",
    LICENSE_NUMBER: "",
    LICENSE_EXPIRE_DATE: "",
    LICENSE_STATE: "",
    S_CORP_PRINCIPAL: "",
    ELECT_PAY_STUB: "",
    ELEC_W2FORM: "",
    "ALLOC_LOCATION.1": "",
    "ALLOC_LOCATION.2": "",
    "ALLOC_LOCATION.3": "",
    "ALLOC_LOCATION.4": "",
    "ALLOC_LOCATION.5": "",
    "ALLOC.DIVISION.1": "",
    "ALLOC.DIVISION.2": "",
    "ALLOC.DIVISION.3": "",
    "ALLOC.DIVISION.4": "",
    "ALLOC.DIVISION.5": "",
    "ALLOC.DEPT.1": "",
    "ALLOC.DEPT.2": "",
    "ALLOC.DEPT.3": "",
    "ALLOC.DEPT.4": "",
    "ALLOC.DEPT.5": "",
    "ALLOC.PROJ.1": "",
    "ALLOC.PROJ.2": "",
    "ALLOC.PROJ.3": "",
    "ALLOC.PROJ.4": "",
    "ALLOC.PROJ.5": "",
    "ALLOC.JOB.1": "",
    "ALLOC.JOB.2": "",
    "ALLOC.JOB.3": "",
    "ALLOC.JOB.4": "",
    "ALLOC.JOB.5": "",
    "ALLOC.PCNT.1": "",
    "ALLOC.PCNT.2": "",
    "ALLOC.PCNT.3": "",
    "ALLOC.PCNT.4": "",
    "ALLOC.PCNT.5": "",
    "ONHRP.ONBOARDED": "",
    "HANDBK.RCD": "",
    "LAST.REVIEW": "",
    "NEXT.REVIEW": "",
    NICKNAME: "",
    "MULTIPLE JOBS (Y OR N)": "",
    "DEPENDENTS ($)": "",
    "OTHER INCOME ($)": "",
    "DEDUCTIONS ($)": "",
    "WORKSTATE MULTIPLE JOBS (Y/N)": "",
    "WORKSTATE CLAIM DEPENDENTS ($)": "",
    "WORKSTATE OTHER INCOME ($)": "",
    "WORKSTATE DEDUCTIONS ($)": "",
    "HOMESTATE MULTIPLE JOBS (Y/N)": "",
    "HOMESTATE CLAIM DEPENDENTS ($)": "",
    "HOMESTATE OTHER INCOME ($)": "",
    "HOMESTATE DEDUCTIONS ($)": "",
  },
  ethnicCode: [
    "x", // 'UNKNOWN'
    "C", // 'WHITE'
    "B", // 'BLACK'
    "H", // 'HISP'
    "A", // 'ASIAN'
    "I", // 'NATIVE_AMERICAN'
    "P", // 'ISLANDER'
    "T", // 'MULTI'
  ],
  locationCode: {
    CA: 1,
    NV: 312,
    PA: 293,
    CO: 442,
    TX: 540,
    TN: 542,
    GA: 544,
    VA: 508,
    MD: 508,
    DC: 508,
    MO: 393,
    IL: 393,
    MI: 393,
  },
  filingStatus: {
    1: "SS", // Single or Married Filing Separately (2020 W4)
    2: "MJ", // Married Filing Jointly (2020 W4)
    7: "H", // Head of Household (2020 W4)
  },
};
