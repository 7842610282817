import React from "react";
import styled from "styled-components";
import {
  DashboardPositionJob,
  DashboardPositionJobTypes,
  ScheduleAndIndividualShift,
} from "../../../api/bluecrew/types";

const SupervisorText = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray800};
  font-size: 14px;
  font-weight: normal;
  justify-content: left;
  display: flex;
  padding-right: 8px;
`;

interface ScheduleNameCellProps {
  rowData: ScheduleAndIndividualShift;
}

export const SupervisorCell = ({ rowData }: ScheduleNameCellProps) => {
  if (
    rowData.type !== DashboardPositionJobTypes.repeatingSchedule &&
    rowData.type !== DashboardPositionJobTypes.bulkSingleShift
  ) {
    return (
      <SupervisorText data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnSupervisor">
        {(rowData as DashboardPositionJob).supervisor_first_name}{" "}
        {(rowData as DashboardPositionJob).supervisor_last_name}
      </SupervisorText>
    );
  }
  return null;
};
