import React from "react";
import styled from "styled-components";

export const PasswordWordReqColors = {
  ReqMetColor: "green",
  ReqNotMetColor: "red",
} as const;

const SpanWithColor = styled.span`
  display: flex;
  gap: 4px;
`;

interface PasswordRequirementProps {
  text: string;
  reqMet: boolean;
}

export const PasswordRequirement = ({ text, reqMet }: PasswordRequirementProps) => {
  const { ReqMetColor, ReqNotMetColor } = PasswordWordReqColors;
  const color = reqMet ? ReqMetColor : ReqNotMetColor;
  const icon = reqMet ? "pi-check" : "pi-times";

  // Without this the reqs visually jump horizontally a little
  const paddingLeft = reqMet ? "0" : "1px";
  return (
    <SpanWithColor key={text} style={{ paddingLeft }}>
      <i className={`pi ${icon}`} style={{ color }} />
      <div style={{ color }}>{text}</div>
    </SpanWithColor>
  );
};
