import { Calendar } from "react-multi-date-picker";
import styled from "styled-components";
import { colorTokens } from "@bluecrew/blueprint-web/src/theme/colorTokens";
import { typography } from "@bluecrew/blueprint-web/src/theme/typography";

export const StyledDatePicker = styled(Calendar)<{ inError?: boolean }>`
  border: ${(props) => props.inError && "2px solid #E72E3E"};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  .rmdp-calendar {
    padding: 0px;
  }

  .rmdp-header {
    color: #ffffff;
    background-color: #3246f0;
    margin: 0px;
    height: 50px;
    padding: 0px 10px;
  }

  .rmdp-header > div {
    height: 100%;
  }

  .rmdp-day-picker {
    padding: 0px;
  }

  .rmdp-header-values {
    color: #ffffff;
    padding: 5px;
  }

  .rmdp-header-values:first-of-type {
    margin-left: 60px;
  }

  .rmdp-header-values:last-of-type {
    margin-right: 60px;
  }

  .rmdp-day-picker > div:nth-child(1) {
    margin: 0px !important;
    .rmdp-week {
      padding-right: 32px;
      padding-left: 10px;
    }
  }

  .rmdp-day-picker > div:nth-child(2) {
    .rmdp-week {
      padding-right: 10px;
    }
  }

  .rmdp-wrapper {
    border: 1px solid #3246f0;
    box-shadow: 0 0 5px #3246f0;
  }

  .rmdp-panel-body li {
    background-color: #3246f0;
    box-shadow: 0 0 2px #3246f0;
  }

  .rmdp-week:nth-child(1) {
    background-color: #3246f0;
  }

  .rmdp-week-day {
    color: #ffffff;
  }

  .rmdp-day {
    height: 40px;
    width: 40px;
  }

  .rmdp-day.rmdp-deactive {
    color: #3246f0;
  }

  .rmdp-range {
    background-color: #3246f0;
    box-shadow: 0 0 3px #3246f0;
  }

  .rmdp-arrow {
    border: solid #3246f0;
    border-width: 0 2px 2px 0;
  }

  .rmdp-arrow-container:hover {
    background-color: #3246f0;
    box-shadow: 0 0 3px #3246f0;
  }

  .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #3246f0;
  }

  .rmdp-day.rmdp-today span {
    background-color: #ffffff;
    color: #000000;
  }

  .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #3246f0;
  }

  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #3246f0;
    box-shadow: 0 0 3px #3246f0;
    color: #ffffff;
  }

  .rmdp-day:not(.rmdp-day-hidden):not(.rmdp-disabled) span:hover {
    background-color: #3246f0 !important;
  }

  .b-deselect {
    color: #3246f0;
    background-color: white;
  }

  .rmdp-day {
    color: ${(props) => props.disabled && "#8798ad"};
  }

  .rmdp-today span {
    // Adding !important here because we overriding library styles
    color: ${(props) => props.disabled && "#8798ad"} !important;
  }
`;

export const StyledButton = styled.button`
  background-color: #3246f0;
  border: none;
  min-width: 32px;

  :hover {
    cursor: pointer;
  }
`;

export const GetCrewMembersFieldHeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colorTokens.black};
  margin: 10px 0px 8px 0px;
  font-family: ${typography.font350.fontFamily};
  font-size: ${typography.font350.fontSize};
  font-weight: ${typography.font350.fontWeight};
  line-height: ${typography.font350.lineHeight};
`;

export const DateSelectedNumberText = styled.div`
  color: ${colorTokens.blue400};
  margin: 0px 8px 0px 0px;
  font-family: ${typography.font650.fontFamily};
  font-size: ${typography.font650.fontSize};
  font-weight: ${typography.font650.fontWeight};
  line-height: ${typography.font650.lineHeight};
`;
