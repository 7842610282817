import { Border } from 'baseui/theme';
import type { Property } from 'csstype';
import { StyleObject } from 'styletron-standard';

type BorderStyle =
  | Property.BorderTopStyle
  | Property.BorderRightStyle
  | Property.BorderBottomStyle
  | Property.BorderLeftStyle;

/**
 *  Mixing shorthand and longhand properties
 * */

export const getPadding = (value: string) => ({
  paddingTop: value,
  paddingBottom: value,
  paddingLeft: value,
  paddingRight: value,
});

export const getMargin = (value: string) => ({
  marginTop: value,
  marginBottom: value,
  marginLeft: value,
  marginRight: value,
});

export const getBorderRadius = (value: string) => ({
  borderBottomLeftRadius: value,
  borderBottomRightRadius: value,
  borderTopLeftRadius: value,
  borderTopRightRadius: value,
});

export const getBorderWidth = (value: string): StyleObject => ({
  borderBottomWidth: value,
  borderTopWidth: value,
  borderRightWidth: value,
  borderLeftWidth: value,
});

export const getBorderColor = (value: string): StyleObject => ({
  borderTopColor: value,
  borderBottomColor: value,
  borderLeftColor: value,
  borderRightColor: value,
});

export const getVerticalPadding = (vertical: string) => ({
  paddingTop: vertical,
  paddingBottom: vertical,
});

export const getHorizontalPadding = (horizontal: string) => ({
  paddingLeft: horizontal,
  paddingRight: horizontal,
});

export const getBorderStyle = (value: BorderStyle): StyleObject => ({
  borderTopStyle: value,
  borderBottomStyle: value,
  borderLeftStyle: value,
  borderRightStyle: value,
});

export const getBorder = ({
  borderWidth,
  borderColor,
  borderStyle,
}: Border): StyleObject => ({
  ...getBorderWidth(borderWidth),
  ...getBorderStyle(borderStyle as BorderStyle),
  ...getBorderColor(borderColor),
});

/**
 *  Position helpers
 * */
export const centeredItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
