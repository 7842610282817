import React from "react";
import styled from "styled-components";
import { ProgressBar } from "@bluecrew/web-react-core";
import { DashboardPositionJobTypes, ScheduleAndIndividualShift } from "../../../api/bluecrew/types";
import { ProgressBarContainer, StyledFilledStatus, FillStatusContainer } from "../SharedComponents";

interface FillStatusCellProps {
  rowData: ScheduleAndIndividualShift;
  refillOn: boolean;
}

const FillStatusCellContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
`;

export const FillStatusCell = ({ rowData, refillOn }: FillStatusCellProps) => (
  <FillStatusCellContainer data-pendo-key="DashboardSchedulesAndIndividualShiftsFillStatusCellContainer">
    {rowData.needed !== 0 && (
      <>
        <span data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnFillStatus">
          <StyledFilledStatus
            activeSchedule={
              rowData.needed !== 0 &&
              (refillOn || new Date(rowData.scheduleStartDateTime) >= new Date())
            }
            useBigFont={
              rowData.type === DashboardPositionJobTypes.repeatingSchedule ||
              rowData.type === DashboardPositionJobTypes.bulkSingleShift
            }
          >
            {rowData.type === DashboardPositionJobTypes.repeatingSchedule ||
            rowData.type === DashboardPositionJobTypes.bulkSingleShift
              ? `${Math.floor((rowData.filled / rowData.needed) * 100)}%`
              : `${rowData.filled} / ${rowData.needed}`}
          </StyledFilledStatus>
        </span>
        <ProgressBarContainer data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnProgressbar">
          <FillStatusContainer>
            {rowData.shifts_filled} / {rowData.shifts_needed} shifts
          </FillStatusContainer>
          <ProgressBar
            alert={!!(rowData.applications_to_review && rowData.applications_to_review)}
            amountFilled={rowData.filled}
            amountNeeded={rowData.needed}
            classes="progress-bar"
          />
        </ProgressBarContainer>
      </>
    )}
  </FillStatusCellContainer>
);
