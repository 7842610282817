// Schedule Heading - VIEW
// =============================================================================
// Component for displaying Schedule heading on the schedule page

import React from "react";
import { H2Heading, Heading, Button, Input, Link, Block } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { Formik, Form, Field as FormikField } from "formik";
import { ScheduleType } from "../containers/GetCrewMembers/types/propTypes/Schedule.types";
import { Can } from "../containers/PermissionsContext";

import { editScheduleNameSchema } from "../forms/validation";
import { ScheduleDetailsCopy } from "../../shared/constants";

const StyledScheduleName = styled.div`
  display: flex;
`;

const StyledTextLink = styled.div`
  padding-left: 6px;
  margin-bottom: 7px;
  display: flex;
  :hover {
    cursor: pointer;
  }

  a {
    text-decoration: none !important;
  }
`;

const StyledErrorString = styled.div`
  margin-top: 5px;
  color: red;
  font-size: 0.75rem;
`;

const StyledScheduleEdit = styled.div`
  display: flex;
  margin-bottom: 2rem;
  width: 50%;
  button {
    margin-top: 5px;
    margin-left: 15px;
    width: 100px;
  }
`;

export type SaveScheduleNameType = (params: {
  scheduleId: string;
  scheduleName: string | typeof undefined;
}) => void;

type State = {
  scheduleName: string;
  editingScheduleName: boolean;
};

type Props = {
  positionName: string;
  scheduleName: string;
  scheduleId: string;
  saveScheduleName: SaveScheduleNameType;
  scheduleType: ScheduleType;
};

export class ScheduleHeading extends React.Component<Props, State> {
  state = {
    editingScheduleName: false,
    scheduleName: this.props.scheduleName,
  };

  toggleEditScheduleName = () => {
    const { editingScheduleName } = this.state;
    this.setState({ editingScheduleName: !editingScheduleName });
  };

  saveScheduleName = (scheduleName: string) => {
    const { scheduleId, saveScheduleName } = this.props;
    saveScheduleName({ scheduleId, scheduleName });
    this.setState({ scheduleName }, this.toggleEditScheduleName);
  };

  renderEditScheduleName = () => {
    const scheduleNamePlaceholder = ScheduleDetailsCopy.schedule_nickname.placeholder;

    return (
      <Formik
        initialValues={{ schedule_nickname: this.state.scheduleName }}
        validationSchema={editScheduleNameSchema}
        onSubmit={(values) => {
          this.saveScheduleName(values.schedule_nickname);
        }}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form>
            <StyledScheduleEdit>
              <FormikField name="schedule_nickname" placeholder="Schedule Name">
                {({ field, form }) => (
                  <div>
                    <Input
                      {...field}
                      onBlur={(event) => {
                        const value = event.target.value.trim();
                        setFieldValue("schedule_nickname", value);
                      }}
                      defaultValue={values.schedule_nickname}
                      placeholder={scheduleNamePlaceholder}
                    />
                    {!isValid && (
                      <StyledErrorString>{form.errors.schedule_nickname}</StyledErrorString>
                    )}
                  </div>
                )}
              </FormikField>
              <Button
                type="submit"
                palette="primary"
                disabled={values.schedule_nickname.trim() === this.state.scheduleName || !isValid}
              >
                Save
              </Button>
              <Button type="button" palette="secondary" onClick={this.toggleEditScheduleName}>
                Cancel
              </Button>
            </StyledScheduleEdit>
          </Form>
        )}
      </Formik>
    );
  };

  renderScheduleName = () => {
    const { scheduleName } = this.state;

    return (
      <StyledScheduleName>
        <Heading data-testid="ViewSchedulePage-schedule-name">{scheduleName}</Heading>
        <Can I="update" a="schedule">
          <StyledTextLink onClick={this.toggleEditScheduleName}>
            <Link>rename</Link>
          </StyledTextLink>
        </Can>
      </StyledScheduleName>
    );
  };

  render() {
    const { editingScheduleName } = this.state;
    const { positionName } = this.props;

    return (
      <Block>
        {editingScheduleName ? this.renderEditScheduleName() : this.renderScheduleName()}
        <H2Heading>{positionName}</H2Heading>
      </Block>
    );
  }
}
