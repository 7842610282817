import React, { Dispatch, SetStateAction, useState } from "react";
import { FilterWrapper, InputWrapper, SearchWrapper } from "./styledComponents";
import { TimesheetsFiltersType, TimesheetsFilterOptions } from "../../types/Payroll.types";
import { TimesheetsSearchBar } from "./SearchBar";
import { TimesheetsPageFilterControls } from "./FilterControls";

export type TimesheetsPageFiltersProps = {
  setTableFilters: Dispatch<SetStateAction<TimesheetsFiltersType>>;
  filterOptions: TimesheetsFilterOptions;
  tableFilters: TimesheetsFiltersType;
};

export const TimesheetsPageFilters = ({
  setTableFilters,
  filterOptions,
  tableFilters,
}: TimesheetsPageFiltersProps) => {
  const [queryText, setQueryText] = useState<string>("");

  return (
    <>
      <SearchWrapper>
        <InputWrapper>
          <TimesheetsSearchBar
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
            queryText={queryText}
            setQueryText={setQueryText}
          />
        </InputWrapper>
        <FilterWrapper>
          <TimesheetsPageFilterControls
            setTableFilters={setTableFilters}
            tableFilters={tableFilters}
            filterOptions={filterOptions}
            setQueryText={setQueryText}
          />
        </FilterWrapper>
      </SearchWrapper>
    </>
  );
};
