export const getAlternativeName = (timeZone?: string) => {
  if (!timeZone) {
    return "";
  }
  const opts: Intl.DateTimeFormatOptions = { timeZoneName: "longGeneric", timeZone };
  return Intl.DateTimeFormat("en-EN", opts).format(Date.now()).split(",")[1].trim();
};

export const formatDateTZ = (date: Date | string, timezoneString: string): Date => {
  return new Date(
    date.toLocaleString("en-US", {
      timeZone: timezoneString,
    }),
  );
};
