import React from "react";

interface TableColumnSortIconProps {
  isAscending: boolean;
}

export const TableColumnSortIcon = (props: TableColumnSortIconProps) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transform={props.isAscending ? "rotate(180)" : ""}
  >
    <path
      d="M7.82281 0.994607C7.76478 0.891655 7.68043 0.805978 7.5784 0.746351C7.47637 0.686725 7.36032 0.655292 7.24214 0.655273H0.75814C0.640027 0.655331 0.524049 0.686767 0.422075 0.746365C0.3201 0.805962 0.23579 0.891582 0.177771 0.994463C0.119752 1.09734 0.0901079 1.21379 0.0918712 1.33189C0.0936345 1.44999 0.126742 1.5655 0.187807 1.66661L3.42981 7.02327C3.48919 7.12139 3.57288 7.20252 3.67279 7.25884C3.7727 7.31515 3.88545 7.34474 4.00014 7.34474C4.11483 7.34474 4.22758 7.31515 4.32749 7.25884C4.4274 7.20252 4.51109 7.12139 4.57047 7.02327L7.81247 1.66661C7.87356 1.56555 7.9067 1.45008 7.90852 1.332C7.91033 1.21393 7.88075 1.0975 7.82281 0.994607V0.994607Z"
      fill="#888992"
    />
  </svg>
);
