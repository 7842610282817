import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Heading, Paragraph } from "../index";
import { UserAvatar } from "./UserAvatar";
// @ts-ignore
import applicantSearchIcon from "../assets/icons/ApplicationJobs/empty-state-icon.svg";

const StyledWrapper = styled.div`
  padding: 48px;
  text-align: center;
  border: none;

  ${Heading} {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.0005em;
    color: ${p("slate")};
  }

  ${Paragraph} {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${p("platinum")};
  }
`;
export const NoApplicants = () => (
  <StyledWrapper>
    <UserAvatar imgSrc={applicantSearchIcon} size="lg" />
    <Heading as="h3">No applicants yet...</Heading>
    <Paragraph>
      Crew members were notified of your post, expect to see applications in the next 24 hours.
    </Paragraph>
  </StyledWrapper>
);
