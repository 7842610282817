import { Button } from "primereact/button";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { assetUrl } from "../../../api/bluecrew/constants";
import { Dropdown } from "../../../components/Dropdown";
import { CONTAINER_MAX_WIDTH } from "../../../../shared/constants";
import { Dialog } from "../../../components/Dialog";

export const AddNewUserButton = styled(Button)`
  margin-left: auto;
  float: right;
  margin-right: 20px;
  width: 150px;
  height: 36px;
  padding: 16px;
  border-radius: 6px;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
  border-color: transparent;
  justify-content: right;
  background-repeat: no-repeat;
  background-image: url(${assetUrl}/icons/ManageUsers/add-new-user-icon.svg);
  background-position: 16px center;
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.colorTokens.gray700};
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 16px 12px 16px;
  border-radius: 6px;
  gap: 8px;
  border-color: ${({ theme }) => theme.colorTokens.gray400};
  border-width: 1px;
  border-style: solid;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colorTokens.gray700};
  margin-top: 8px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PhoneNumberFieldContainer = styled.div`
  input {
    width: 100%;
    padding: 12px 16px 12px 16px;
    border-radius: 6px;
    gap: 8px;
    border-color: ${({ theme }) => theme.colorTokens.gray400};
    border-width: 1px;
    border-style: solid;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.colorTokens.gray700};
    margin-top: 8px;
  }
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  margin-top: 8px;
  border: 1px solid #babbc4 !important;
  .p-dropdown-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    overflow: visible;
  }
  .p-dropdown-items-wrapper {
    z-index: 999999;
  }
  .p-dropdown-trigger {
    color: ${({ theme }) => theme.palette.brandBlue};
  }
`;

export const AddNewUserModalHeader = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  padding-left: 16px;
  padding-top: 8px;
`;

export const AddNewUserModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: end;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: right;
`;

export const StyledForm = styled.div`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  padding: 1rem 2rem;

  h2 {
    margin-top: 0;
    color: ${p("slate")};
    font-size: 1.75rem;
    font-weight: 500;
  }

  // Error Message CSS
  .error-message {
    color: red;
    font-size: 0.75rem;
  }
`;

export const CancelButton = styled(Button)`
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: ${({ theme }) => theme.colorTokens.white};
  color: ${({ theme }) => theme.colorTokens.blue400};
  border-color: transparent;
  margin-right: 16px;
`;

export const SubmitButton = styled(Button)`
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
  border-color: transparent;
`;

export const StyledModal = styled(Dialog)`
  width: 480px;
  .p-dialog {
    position: static;
  }
  .p-dialog-header {
    padding: 16px;
  }
  .p-dialog-content {
    padding: 0;
  }
`;
