// FETCH CREW MEMBERS
export const FETCH_CREW_MEMBERS_REQUEST = "FETCH_CREW_MEMBERS_REQUEST";
export const FETCH_CREW_MEMBERS_SUCCESS = "FETCH_CREW_MEMBERS_SUCCESS";
export const FETCH_CREW_MEMBERS_FAILURE = "FETCH_CREW_MEMBERS_FAILURE";
// SCHEDULE
export const FETCH_SCHEDULE_REQUEST = "FETCH_SCHEDULE_REQUEST";
export const FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS";
export const FETCH_SCHEDULE_FAILURE = "FETCH_SCHEDULE_FAILURE";
// CANCEL SCHEDULE
export const CANCEL_SCHEDULE_REQUEST = "CANCEL_SCHEDULE_REQUEST";
export const CANCEL_SCHEDULE_SUCCESS = "CANCEL_SCHEDULE_SUCCESS";
export const CANCEL_SCHEDULE_FAILURE = "CANCEL_SCHEDULE_FAILURE";
// POST CREW MEMBERS
export const POST_CREW_MEMBERS_REQUEST = "POST_CREW_MEMBERS_REQUEST";
export const POST_CREW_MEMBERS_SUCCESS = "POST_CREW_MEMBERS_SUCCESS";
export const POST_CREW_MEMBERS_FAILURE = "POST_CREW_MEMBERS_FAILURE";
