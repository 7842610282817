import styled from "styled-components";
import { Dialog } from "../../../../../components/Dialog";

export const StyledModal = styled(Dialog)`
  .p-dialog-header {
    padding: 16px;
  }
  .p-dialog-content {
    max-width: 100%;
    overflow: visible;
    padding: 0px;
  }
`;

export const AuditTrailHeader = styled.div`
  color: #33343d;
  font-family: Inter;
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  line-height: ${({ theme }) => theme.typography.font350.lineHeight};
  font-style: normal;
  padding-top: 10px;
  width: 361px;
`;

export const ShiftDetailsHeader = styled.div`
  color: ${({ theme }) => theme.colorTokens.black};
  font-family: Inter;
  font-weight: ${({ theme }) => theme.typography.font750.fontWeight};
  font-size: ${({ theme }) => theme.typography.font750.fontSize};
  line-height: ${({ theme }) => theme.typography.font750.lineHeight};
  font-style: normal;
`;

export const ShiftDetailsSubHeader = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray500};
  font-family: Inter;
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  line-height: ${({ theme }) => theme.typography.font300.lineHeight};
  font-style: normal;
`;

export const ModalBody = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.scale600};
  margin-left: ${({ theme }) => theme.sizing.scale600};
  margin-right: ${({ theme }) => theme.sizing.scale600};
  display: inline-flex;
`;

export const InfoColumn = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.scale600};
  margin-left: ${({ theme }) => theme.sizing.scale600};
  margin-right: ${({ theme }) => theme.sizing.scale600};
`;

export const DateInfoContainer = styled.div`
  /* Tue, Jun 14 */

  width: 361px;
  height: 24px;

  /* Text/Bold Large */
  font-family: ${({ theme }) => theme.typography.font350.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  line-height: ${({ theme }) => theme.typography.font350.lineHeight};
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;

  /* Obsidian */
  color: ${({ theme }) => theme.colorTokens.black};

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  padding-top: ${({ theme }) => theme.sizing.scale800};
  padding-bottom: ${({ theme }) => theme.sizing.scale600};
`;

export const UserIdContainer = styled.span`
  flex-basis: content;
  order: 2;
  padding-left: 0.5em;

  font-family: ${({ theme }) => theme.typography.font100.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font100.fontWeight};
  font-size: ${({ theme }) => theme.typography.font100.fontSize};
  line-height: 16px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const UserNameContainer = styled.div`
  /* Ahron Weisbrod */

  height: 24px;

  /* Text/Bold Large */
  font-family: ${({ theme }) => theme.typography.font450.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.font450.fontWeight};
  font-size: ${({ theme }) => theme.typography.font450.fontSize};
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Obsidian */
  color: ${({ theme }) => theme.colorTokens.black};

  flex-basis: content;
  order: 1;
  flex-shrink: 3;
  padding-left: 0.5em;
`;

export const PositionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: ${({ theme }) => theme.sizing.scale300};
  margin-bottom: ${({ theme }) => theme.sizing.scale800};
  margin-top: ${({ theme }) => theme.sizing.scale600};
  margin-right: ${({ theme }) => theme.sizing.scale600};
`;

export const PositionNameContainer = styled.div`
  /* Warehouse Associate */

  width: 399.8px;
  height: 24px;

  /* Text / Bold Medium */
  font-family: ${({ theme }) => theme.typography.font350.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  line-height: ${({ theme }) => theme.typography.font350.lineHeight};

  /* Obsidian */
  color: ${({ theme }) => theme.colorTokens.gray800};

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const PositionTimezoneContainer = styled.div`
  /* Warehouse Associate */

  width: 399.8px;
  height: 24px;

  /* Text / Bold Medium */
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  line-height: ${({ theme }) => theme.typography.font300.lineHeight};

  /* Obsidian */
  color: ${({ theme }) => theme.colorTokens.black};

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const PositionAddressContainer = styled.div`
  /* 661 Logan St, Denver CO 80203 */

  width: 399.8px;
  height: 20px;

  /* Text / Normal Compact */
  font-family: ${({ theme }) => theme.typography.font200.fontFamily};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.font200.fontWeight};
  font-size: ${({ theme }) => theme.typography.font200.fontSize};
  line-height: ${({ theme }) => theme.typography.font200.lineHeight};
  /* identical to box height, or 143% */

  /* Obsidian */
  color: ${({ theme }) => theme.colorTokens.black};

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const PositionColumn = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.scale600};
  margin-left: ${({ theme }) => theme.sizing.scale600};
  margin-right: ${({ theme }) => theme.sizing.scale600};
  width: 399.8px;
`;
