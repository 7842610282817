module.exports = (function () {
  const enums = {
    PayrollHoursStatus: {
      APPROVED: 0,
      REJECTED: 1,
      HIDDEN: 2,
      DAY_OFF: 3,
      EXCUSED: 3,
      IGNORE: 4,
      FOLLOW_UP: 5,
      NO_RESPONSE: 6,
      NEEDS_VERIFICATION: 7,
    },
    RatingCategories: {
      POOR_WORK_ETHIC: 1,
      REPORT_INCIDENT: 2,
      ATTENDANCE_OR_TARDIES: 3,
      POOR_ATTITUDE: 4,
      EXCESSIVE_PHONE_USE: 5,
      NOT_THE_RIGHT_FIT: 6,
    },
    UserStatus: {
      STARTED_SIGN_UP: 0,
      ELIGIBLE: 1,
      ENTERED_INTERESTS: 2,
      ENTERED_GENERAL_INFO: 3,
      SIGNED_CONTRACT: 4,
      ENTERED_BACKGROUND_CHECK_INFO: 5,
      RUNNING_BACKGROUND_CHECK: 6,
      PENDING: 7,
      SUSPENDED: 8,
      FIRED: 9,
      ELIGIBLE_EOR: 10,
      ENTERED_CLIENT_INFO: 11,
      ENTERED_TAX_INFO: 12,
      PASSED_INTERVIEW: 13,
      FAILED_INTERVIEW: 14,
      FOLLOW_UP_INTERVIEW: 15,
      NO_SHOW_INTERVIEW: 16,
      DOUBLE_PROFILE: 17,
      PAUSED_PROFILE: 18,
      DELETED_CLIENT: 19,
      FAILED_BACKGROUND_CHECK: 20,
      NEEDS_I9: 21,
      REGION_WAITLIST: 22,
      NEEDS_CREWMEMBER_I9: 23,
      NEEDS_I9_AGENT: 24,
    },
  };

  var freezeObject = function (obj) {
    var objKeys = Object.keys(obj);
    for (var i = 0; i < objKeys.length; i++) {
      var curObj = obj[objKeys[i]];
      if (curObj && typeof curObj === "object") {
        if (Array.isArray(curObj)) {
          var enumObj = {};
          for (var j = 0; j < curObj.length; j++) {
            enumObj[curObj[j]] = j;
          }
          (function (array) {
            enumObj.get = function (ind) {
              return array[ind];
            };
          })(curObj);
          obj[objKeys[i]] = enumObj;
        }
        freezeObject(obj[objKeys[i]]);
      }
    }
    Object.freeze(obj);
  };
  freezeObject(enums);

  return enums;
})();
