import {
  createThemedStyled,
  createThemedWithStyle,
  createThemedUseStyletron,
} from 'baseui';
import { BlueprintTheme } from './blueprintTheme';

export const themedStyled = createThemedStyled<BlueprintTheme>();
export const withThemedStyle = createThemedWithStyle<BlueprintTheme>();
export const useThemedStyletron = createThemedUseStyletron<BlueprintTheme>();
