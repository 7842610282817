import React from 'react';
import { SvgPropType } from './SvgPropType';
import { useThemedStyletron } from '../../theme';

export const CurrencyIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 20, height = 20, color = theme.colors.ultramarine } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1109 13.4167C15.1136 12.6141 14.8742 11.8293 14.4241 11.1648C13.9739 10.5003 13.3339 9.9869 12.5876 9.69167L8.3376 7.9925C8.01127 7.86186 7.74063 7.62165 7.57219 7.31313C7.40374 7.00461 7.34802 6.64706 7.41458 6.30191C7.48114 5.95677 7.66584 5.64558 7.93694 5.42183C8.20803 5.19809 8.54859 5.07574 8.9001 5.07583H13.0859C13.4175 5.07583 13.7354 4.94414 13.9698 4.70972C14.2042 4.4753 14.3359 4.15735 14.3359 3.82583C14.3359 3.49431 14.2042 3.17637 13.9698 2.94195C13.7354 2.70753 13.4175 2.57583 13.0859 2.57583H11.4584C11.4032 2.57583 11.3502 2.55388 11.3111 2.51481C11.272 2.47574 11.2501 2.42275 11.2501 2.3675V1.25C11.2501 0.918479 11.1184 0.600537 10.884 0.366116C10.6496 0.131696 10.3316 0 10.0001 0C9.66858 0 9.35064 0.131696 9.11621 0.366116C8.88179 0.600537 8.7501 0.918479 8.7501 1.25V2.3925C8.7503 2.44472 8.73089 2.49511 8.6957 2.53369C8.66051 2.57228 8.61212 2.59624 8.5601 2.60083C7.65878 2.67312 6.80854 3.04866 6.14806 3.6662C5.48757 4.28374 5.0558 5.10684 4.92316 6.00128C4.79052 6.89571 4.96484 7.80869 5.4177 8.59133C5.87055 9.37397 6.57521 9.98009 7.41676 10.3108L11.6668 12.01C11.9931 12.1406 12.2637 12.3809 12.4322 12.6894C12.6006 12.9979 12.6563 13.3554 12.5898 13.7006C12.5232 14.0457 12.3385 14.3569 12.0674 14.5807C11.7963 14.8044 11.4558 14.9268 11.1043 14.9267H6.91176C6.58024 14.9267 6.2623 15.0584 6.02788 15.2928C5.79346 15.5272 5.66177 15.8451 5.66177 16.1767C5.66177 16.5082 5.79346 16.8261 6.02788 17.0605C6.2623 17.295 6.58024 17.4267 6.91176 17.4267H8.54176C8.59702 17.4267 8.65001 17.4486 8.68908 17.4877C8.72815 17.5268 8.7501 17.5797 8.7501 17.635V18.75C8.7501 19.0815 8.88179 19.3995 9.11621 19.6339C9.35064 19.8683 9.66858 20 10.0001 20C10.3316 20 10.6496 19.8683 10.884 19.6339C11.1184 19.3995 11.2501 19.0815 11.2501 18.75V17.6075C11.2499 17.5553 11.2693 17.5049 11.3045 17.4663C11.3397 17.4277 11.3881 17.4038 11.4401 17.3992C12.4392 17.3147 13.3704 16.859 14.0499 16.1217C14.7295 15.3845 15.108 14.4193 15.1109 13.4167Z"
        fill={color}
      />
    </svg>
  );
};
