import React, { createRef } from "react";
import { Modal, ModalKind } from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { EditHistoryModalProps } from "../types/propTypes/PositionDetailsEdit.types";
import { EditVersionListItem, SelectionList } from "../../../components/SelectionList";

export const EditHistoryModal = (props: EditHistoryModalProps) => {
  const { list, onItemClick, modalOpen, onClose } = props;
  const modalRootRef = createRef<HTMLDivElement>();

  return (
    <Modal
      kind={ModalKind.DEFAULT}
      header="Versions"
      body={
        <ModalWrapper ref={modalRootRef}>
          <SelectionList
            rootRef={modalRootRef}
            items={list}
            bottomDivider={false}
            listItemRenderer={EditVersionListItem}
            onItemClick={onItemClick}
          />
        </ModalWrapper>
      }
      isOpen={modalOpen}
      onClose={onClose}
    />
  );
};

const ModalWrapper = styled("div", ({ $theme }) => ({
  display: "flex",
  maxHeight: "500px",
  paddingBottom: $theme.sizing.scale800,
  paddingLeft: $theme.sizing.scale600,
}));
