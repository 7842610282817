export const IntercomGlobalVariableName = "Intercom";

export function bootIntercom({ user }) {
  const intercomID = import.meta.env.VITE_INTERCOM_APP_ID;
  // Boot Intercom widget so it can be called from any place in the app
  window[IntercomGlobalVariableName]("boot", {
    app_id: intercomID,
    hide_default_launcher: true,
    ...user,
  });
}

export function getIntercomInjectionScriptContent() {
  let intercomID = import.meta.env.VITE_INTERCOM_APP_ID;
  if (!intercomID) {
    intercomID = "PURPOSEFULLY_INVALID_ID_FOR_LOCAL_DEV";
  }

  // Copied from: https://developers.intercom.com/installing-intercom/docs/basic-javascript
  const content = `
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercomID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`;
  return content;
}
