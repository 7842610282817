// WORKER - SELECTORS
// =============================================================================
import get from "lodash/get";
import { createSelector } from "reselect";
import { StateShape } from "../reducers";

import { UserDetail } from "../../api/bluecrew/types";
import { getDirectInviteUserIds } from "./job";

export const getIsFetchingCrewMembers = (state: StateShape) =>
  state.crewMember.crewMembers.isFetching;

const getCrewMembers = (state: StateShape) => get(state, "crewMember.crewMembers.success") || [];

export const getformattedCrewMembersForCrewPicker = createSelector(getCrewMembers, (crewMembers) =>
  // @ts-ignore TODO: BW-1482 Fix this error
  crewMembers.map((crewMember) => ({
    external_id: crewMember.user_id,
    firstName: crewMember.firstName,
    lastName: crewMember.lastName,
    profilePic: crewMember.profilePic,
    user_id: crewMember.external_id,
  })),
);

export const getCrewMembersForCrewForm = createSelector(getCrewMembers, (crewMembers) =>
  // @ts-ignore TODO: BW-1482 Fix this error
  crewMembers.map((crewMember) => ({
    id: crewMember.user_id,
    externalId: crewMember.external_id,
    name: `${crewMember.firstName} ${crewMember.lastName}`,
    shifts: `${crewMember.shiftsWorked.toString()} Bluecrew shifts`,
    ...(crewMember.profilePic && { imgUrl: crewMember.profilePic }),
  })),
);

export const getDirectInviteUsers = (state: StateShape): UserDetail[] => {
  const crewMembers = getformattedCrewMembersForCrewPicker(state);
  const directInviteUserIds = getDirectInviteUserIds(state);

  return crewMembers.filter((person) => directInviteUserIds.includes(person.user_id));
};

export const isFetchingCrewmembers = (state: StateShape) =>
  get(state, "crewMember.crewMembers.isFetching");
