// SkillsetField - COMPONENT
// =============================================================================

import React, { useEffect, useState } from "react";
import { useThemedStyletron, jobIconsOrder } from "@bluecrew/blueprint-web";
import { useController } from "react-hook-form";
import { FieldLabel } from "../../../FieldLabel";
import { SkillMapper } from "./SkillMapper";
import {
  SkillItem,
  SkillItems,
  SkillItemsGroupList,
  GroupDefinitions,
} from "../../../types/propTypes/SkillsetField.types";
import { SkillsetSectionProps } from "../../../types/propTypes/SkillsetSection.types";
import { Spacer, SpacerType } from "../../../../../components/Spacer";
import { useSelectionList } from "../../../useSelectionList";
import { FieldContainer } from "./styledComponents";
import { createGroups, sortBy } from "./utils";
import { Dropdown } from "../../../../../components/Dropdown";

export const SKILLSET_SEARCH_DEBOUNCE_LENGTH_MS = 100;

// Adding a new group:
// 1. Add a definition in the list below
// 2. Modify selectGroupDefinitions() so your new group is displayed when you
//    want it to be displayed
const DEFAULT_GROUP_NAME = "default";
const groupDefinitions: GroupDefinitions = [
  {
    // DEFAULT / FALLBACK GROUP DEFINITION
    groupName: DEFAULT_GROUP_NAME,
    displayName: "",
    filter: (list: SkillItems) => list,
    sort: (list: SkillItems) => list.concat().sort((item) => jobIconsOrder.indexOf(item.name)),
  },
  {
    groupName: "Recent",
    displayName: "Recent",
    filter: (list: SkillItems) => list.filter((skill) => skill.lastUsedAt != null),
    sort: (list: SkillItems) => sortBy(list, "lastUsedAt"),
  },
  {
    groupName: "More",
    displayName: "More",
    filter: (list: SkillItems) => list.filter((skill) => skill.lastUsedAt === null),
    sort: (list: SkillItems) => sortBy(list, "name"),
  },
];

// Function that controls which groups are displayed
// This is run ONCE on component load to select WHICH groups should be
// displayed while the user selects a job type
const selectGroupDefinitions = (
  filteredList: SkillItems,
  // TODO:(dloukusa-bc) Fix this so it's not shadowed
  // eslint-disable-next-line @typescript-eslint/no-shadow
  groupDefinitions: GroupDefinitions,
): GroupDefinitions => {
  // The easiest way to see what groups we want is to first create all of them
  const groups = createGroups(filteredList, groupDefinitions);

  // If the "Recent" group is empty, this means the company has not yet posted
  // a job. The current groups "Recent" and "More" only make sense to display
  // if a company HAS posted a job. As such, the below logic is setup to
  // facilitate this. This may change in the future if we ever add more groups
  // or give users more control over how job types are presented / filtered

  // Groups will always assign (at a minimum) an empty list for every group
  // in groupDefinitions, so "Recent" will always exist in groups and will
  // always have at minimum, an empty list assigned to recent.list
  const recent = groups.filter((g) => g.name === "Recent")[0];

  // Return the default group definition if Recent is empty
  if (recent?.list?.length === 0)
    return groupDefinitions.filter((def) => def.groupName === DEFAULT_GROUP_NAME);

  // Return every definition but the default otherwise
  return groupDefinitions.filter((def) => def.groupName !== DEFAULT_GROUP_NAME);
};

const updateFilteredGroups = (
  filteredList: SkillItems,
  // TODO:(dloukusa-bc) Fix this so it's not shadowed
  // eslint-disable-next-line @typescript-eslint/no-shadow
  groupDefinitions: GroupDefinitions,
): SkillItemsGroupList => {
  // Filter Skills / Job Types based on what is in filteredList
  const groups = createGroups(filteredList, groupDefinitions);

  // Only show groups which have members, I.E. don't show orphaned headers
  return groups.filter((group) => group.list.length > 0);
};

export const SkillsetField = ({
  trigger,
  control,
  skills = [],
  disabled = false,
  onNextHandler,
}: SkillsetSectionProps) => {
  const fieldName = "skillset";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value },
  } = useController({
    name: fieldName,
    control,
    rules: { required: true },
  });

  const getListItems = () =>
    skills.map((skill) => SkillMapper.toListItem(skill, skill.id === value?.id));

  const { select } = useSelectionList<SkillItem>(getListItems(), {
    searchBy: "id",
  });

  // State to hold the actual contents of the groups we want to render
  const [filteredGroups, setFilteredGroups] = useState<SkillItemsGroupList>([]);

  const [dropdownValue, setDropdownValue] = useState<SkillItem>();

  // Setup initial states for selected group definitions and groups
  useEffect(() => {
    let list = getListItems();

    // When it is edit, we aren't getting this data list because it is disabled.
    // In that case we still need an array to display the selected option
    if (list.length === 0 && !!value) {
      list = [value];
    }

    // First select WHICH group definitions we want to use
    const initialDefinitions = selectGroupDefinitions(list, groupDefinitions);
    // Then use the selected definitons to create groups with SkillItems
    const initialGroups = updateFilteredGroups(list, initialDefinitions);

    setFilteredGroups(initialGroups);
    setDropdownValue(value);
  }, []);

  const onDone = (item: SkillItem) => {
    if (item) {
      onChange(SkillMapper.toModel(item));
      onNextHandler?.(item);
      return validate();
    }
    return false;
  };

  const onDropdownSelect = async (item: SkillItem) => {
    await select(item);
    await setDropdownValue(item);
    await onDone(item);
  };

  const [, theme] = useThemedStyletron();

  return (
    <>
      <FieldContainer>
        <FieldLabel>Skill set</FieldLabel>
        <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale100} />
        <Dropdown
          value={dropdownValue}
          onChange={(e) => onDropdownSelect(e.value)}
          options={filteredGroups}
          placeholder={"Choose a skill set"}
          optionLabel={"name"}
          optionGroupLabel={"name"}
          optionGroupChildren={"list"}
          disabled={disabled}
          scrollHeight={"300px"}
          filter
        />
      </FieldContainer>
    </>
  );
};
