// Presentational component for Company settings container

import React from "react";
import { GeneralInfo, GeneralInfoProps } from "./GeneralInfo";
import { CovidPolicy } from "./CovidPolicy";
import { Geofence } from "./Geofence";
import { ContinuousFill } from "./ContinuousFill";
import { Timesheets } from "./Timesheets";
import { GridWrapper, HeightHack, SettingsWrapper } from "./styledComponents";
import { NavSidebar } from "./NavSidebar/NavSidebar";
import { getTreatmentValue } from "../../redux/selectors/splitio";
import {
  SHOW_TIMESHEETS_SPLIT_NAME,
  ADVANCED_BACKGROUND_CHECK_SPLIT_NAME,
  DRUG_SCREENING_SPLIT_NAME,
  TimeSheetsDefaults,
  GeofenceDefaults,
  MAX_PHONE_NUMBER_LENGTH,
} from "../../../shared/constants";
import { AppToast } from "./AppToast";
import { useAppSelector } from "../../redux";
import { useGetCompanyDetails } from "../../api/bluecrew/hooks/company";
import {
  AdvancedBackgroundCheckTypes,
  DrugScreeningTypes,
  CompanyResponse,
  ThirdPartyTimetracker,
} from "../../api/bluecrew/types";
import { AdvancedBackgroundCheck } from "./AdvancedBackgroundCheck";
import { DrugScreening } from "./DrugScreening";
import { getUserType } from "../../redux/selectors/auth";
import { INTERNAL_USER_ROLES } from "../ManageUsers/constants";

export const getGeneralInfoFormData = (companyDetails: CompanyResponse): GeneralInfoProps => {
  let phoneNumber = companyDetails.company.data.phone_number?.toString() || "";
  if (phoneNumber.length > MAX_PHONE_NUMBER_LENGTH) {
    // remove country code
    const start = phoneNumber.length - MAX_PHONE_NUMBER_LENGTH;
    phoneNumber = phoneNumber.slice(start);
  }

  return {
    initialName: companyDetails.company.data.name,
    initialDescription: companyDetails.company.data.description ?? "",
    initialPhoneNumber: phoneNumber,
  };
};

export const getCovidPolicyFormData = (companyDetails: CompanyResponse) => {
  return { initialCovidPolicy: companyDetails.company.data.covid_policy };
};

const getBackgroundCheckFormData = (companyDetails: CompanyResponse) => {
  return {
    initialBackgroundCheck: companyDetails.company.data.background_check_required,
    backgroundCheckActive: companyDetails.company.data.background_check_active,
  };
};

const getDrugScreenFormData = (companyDetails: CompanyResponse) => {
  return {
    initialDrugScreening: companyDetails.company.data.drug_screen_required,
    drugScreenActive: companyDetails.company.data.drug_screen_active,
  };
};

export const getContinuousFillFormData = (companyDetails: CompanyResponse) => {
  return { continuousFillSetting: companyDetails.company.refill_on };
};

export const getTimesheetsFormData = (companyDetails: CompanyResponse) => {
  const companyData = companyDetails.company.data;

  return {
    initialTerminalModeEnabled: !!companyData.terminal_mode,
    initialLateClockOutBufferMinutes:
      companyData.late_clockout_buffer || TimeSheetsDefaults.lateClockoutBufferMinutes,
    initialMinLunchDurationHours:
      companyData.min_lunch_duration || TimeSheetsDefaults.minLunchDurationHours,
    initialEarlyClockInBufferMinutes:
      Number(companyData.early_clockin_buffer) || TimeSheetsDefaults.earlyClockinBufferMinutes,
    initialAutoApprovalEnabled:
      companyData.auto_approval_on || TimeSheetsDefaults.autoApprovalEnabled,
    initialThirdPartyTimetracker:
      (companyData.third_party_timetracker as ThirdPartyTimetracker | null) ||
      TimeSheetsDefaults.thirdPartyTimetracker,
  };
};

export const getGeofenceFormData = (companyDetails: CompanyResponse) => {
  const companyData = companyDetails.company.data;

  return {
    initialOnsiteWarnRadiusMeters:
      companyData.min_onsite_warn_radius || GeofenceDefaults.onsiteWarnRadiusMeters,
    initialOnsiteBlockRadiusMeters:
      companyData.min_onsite_block_radius || GeofenceDefaults.onsiteBlockRadiusMeters,
    initialOffsiteWarnRadiusMeters:
      companyData.min_offsite_warn_radius || GeofenceDefaults.offsiteWarnRadiusMeters,
    initialOffsiteBlockRadiusMeters:
      companyData.min_offsite_block_radius || GeofenceDefaults.offsiteBlockRadiusMeters,
  };
};

export const CompanySettings = () => {
  const userType = useAppSelector(getUserType);
  const timesheetsSplit = useAppSelector((state) =>
    getTreatmentValue(state, SHOW_TIMESHEETS_SPLIT_NAME),
  );
  const advancedBackgroundCheckEnabled = useAppSelector(
    (state) => getTreatmentValue(state, ADVANCED_BACKGROUND_CHECK_SPLIT_NAME) === "on",
  );
  const drugScreeningEnabled = useAppSelector(
    (state) => getTreatmentValue(state, DRUG_SCREENING_SPLIT_NAME) === "on",
  );

  const companyDetailsQuery = useGetCompanyDetails({});

  const Settings = () => {
    if (!companyDetailsQuery.data) {
      return null;
    }

    const backgroundCheckFormData = getBackgroundCheckFormData(companyDetailsQuery.data);
    const drugScreenFormData = getDrugScreenFormData(companyDetailsQuery.data);

    const shouldRenderAdvancedBackgroundCheck =
      backgroundCheckFormData.initialBackgroundCheck !== AdvancedBackgroundCheckTypes.DISABLED &&
      advancedBackgroundCheckEnabled;
    const shouldRenderDrugScreening =
      drugScreenFormData.initialDrugScreening !== DrugScreeningTypes.DISABLED &&
      drugScreeningEnabled;

    return (
      <>
        <GeneralInfo {...getGeneralInfoFormData(companyDetailsQuery.data)} />
        <CovidPolicy {...getCovidPolicyFormData(companyDetailsQuery.data)} />
        {shouldRenderAdvancedBackgroundCheck && (
          <AdvancedBackgroundCheck {...getBackgroundCheckFormData(companyDetailsQuery.data)} />
        )}
        {shouldRenderDrugScreening && (
          <DrugScreening {...getDrugScreenFormData(companyDetailsQuery.data)} />
        )}
        <ContinuousFill {...getContinuousFillFormData(companyDetailsQuery.data)} />
        <Geofence {...getGeofenceFormData(companyDetailsQuery.data)} />
        {(timesheetsSplit === "on" || INTERNAL_USER_ROLES.includes(userType)) && (
          <Timesheets {...getTimesheetsFormData(companyDetailsQuery.data)} />
        )}
      </>
    );
  };

  return (
    <>
      <HeightHack>
        <GridWrapper>
          <NavSidebar />
          <SettingsWrapper>
            <Settings />
          </SettingsWrapper>
        </GridWrapper>
      </HeightHack>
      <AppToast />
    </>
  );
};
