import styled from "styled-components";
import { Link, Image, Button, InlineBlock, CompanyAvatar } from "@bluecrew/web-react-core";
import {Dropdown} from "../../components/Dropdown";
// TODO:(BW-1079) - Try to use blueprint web where possible

// TODO:(@dloukusa) Undo the hardcoded colors and font sizes?

// position: sticky is used to keep the NavSidebar always visible while scrolling through the settings
// In order to make sure the sidebar does not disapear because the viewport it's in is too small (vertically)
// We have to make the parent container as big as the settings container
// There may be a better way to handle this... Not sure.
// And yes, it DOES have to be as separate elemnet from the one that is holding the grid properties
export const HeightHack = styled.div`
  height: 210%;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr;
  align-items: start;
`;

export const SettingsWrapper = styled.div`
  grid-column: 3;
  width: 100%;
`;

export const ToastContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0;
  z-index: 9999999;
  box-shadow: 1px 2px 9px #bebebe;
  margin: 0.5rem;
  border-radius: 6px;
  transform: translateX(-50%);
`;

export const SidebarLink = styled(Link)`
  padding: 0.75rem 0rem 0.75rem 1rem;
  font-size: large;
  width: 100%;
  color: grey;
  justify-content: start;
  :hover {
    background-color: rgba(122, 123, 129, 0.2);
    text-decoration: none;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const SideBarWrapper = styled.div`
  grid-column: 2;
  margin-left: 2rem;
  padding-top: 2rem;
  position: sticky;
  top: 0;
`;

export const StyledContainer = styled.div<{ assetPath: string }>`
  i.icon {
    background-repeat: no-repeat;
    background-position: left 0.25rem center;
    padding-left: 2rem;
    height: 20px;
    width: 20px;

    &.account-icon {
      background-image: url(${({ assetPath }) => assetPath}/account.svg);
    }

    &.billing-info-icon {
      background-image: url(${({ assetPath }) => assetPath}/billing-info.svg);
    }

    &.company-icon {
      background-image: url(${({ assetPath }) => assetPath}/company.svg);
    }

    &.company-users-icon {
      background-image: url(${({ assetPath }) => assetPath}/company-users.svg);
    }

    &.invoices-icon {
      background-image: url(${({ assetPath }) => assetPath}/invoices.svg);
    }

    &.referrals-icon {
      background-image: url(${({ assetPath }) => assetPath}/referrals.svg);
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SettingsCard = styled.div`
  width: 40rem;
  padding: 1rem;
  margin: 0.75rem;
  border: thin solid #d7d7d9;
  border-radius: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-top: 1rem;
  gap: 0.5rem;
`;

export const SpinnerWrapper = styled.div`
  z-index: 9999;
  background: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  animation-name: fadeIn;
  animation-duration: 0.25s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const CrewMembers = styled.div`
  display: grid;
  padding: 0.5rem
  border-radius: 10px;
  border: thin solid #f2f3f7;
  grid-template-columns: repeat(2,1fr);

  .crew-member {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 10px;
    background: #F2F3F7;
    font-size: large;
    display: flex;
    flex-direction: row;

    .cm-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      .name {
        font-weight: bold;
      }
    
      .shift-count {
        font-size: small;
      }
    }
  }
`;

export const AvatarContainer = styled(InlineBlock)`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  ${Image} {
    border-radius: 48px;
  }
`;

export const ModalWrapper = styled.div`
  padding: 0rem 1rem 1rem 1rem;
`;

export const SwitchWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const ToolTipContainer = styled.div`
  cursor: pointer;
`;

export const ThirdPartyDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const StyledThirdPartyDropdown = styled(Dropdown)`
  max-width: 20rem;
`;

export const StyledCompanyAvatar = styled(CompanyAvatar)`
  width: auto;
  max-height: 145px;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  .geofence-unit {
    padding-right: 1.5rem;
    padding-top: 1.6rem;
  }

  .timesheets-unit {
    padding-right: 0.5rem;
    padding-top: 1.5rem;
    font-family: ${({ theme }) => theme.typography.font150.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
    font-size: ${({ theme }) => theme.typography.font150.fontSize};
    line-height: ${({ theme }) => theme.typography.font150.lineHeight};
  }

  .input-block {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const GeofenceUnit = styled.input`
  border: none;
  background: none;
  width: 10ch;
  height: 4ch;
  margin-top: 1.1rem;
  outline: none;
`;

export const GeofenceInputRow = styled.div`
  display: grid;
  grid-template-columns: .9fr 1fr;
  gap: 0.5rem;

  .geofence-unit {
    padding-right: 1.5rem;
    padding-top: 1.6rem;
  }

  .geofence-unit-input {
    border: none,
    background: none,
    width: 10ch,
    height: 4ch,
    margin-top: 1.1rem,
    outline: none
  }

  .input-block-and-error-msg {
    display; flex;
    flex-direction: column;
  }

  .input-block {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;

export const SpaceBetweenRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

export const FlexBaselineStartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  margin-top: 1em;
  margin-bottom: 0.5em;
  gap: 0.75rem;
`;

export const Text = styled.div`
  margin-bottom: 0.5rem;
`;

export const TertiaryButton = styled(Button)`
  font-size: 13.6px;
  color: rgb(50, 70, 240);
  background-color: unset;
  border: none;
  margin-left: 0.5rem;

  // We don't seem to do this anywhere else, but this is nice.
  transition: background 0.25s, color 0.25s;

  :hover {
    color: white;
    background-color: rgb(20, 54, 114);
  }
`;

export const StyledPointerLink = styled(Link)`
  cursor: pointer;
`;

export const StyledSettingText = styled.div`
  font-family: ${({ theme }) => theme.typography.font150.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
  font-size: ${({ theme }) => theme.typography.font150.fontSize};
  line-height: ${({ theme }) => theme.typography.font150.lineHeight};
  color: ${({ theme }) => theme.colorTokens.gray800};
  margin-bottom: 8px;
`;
