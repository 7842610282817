import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobCustomerSupportIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1627_121)">
        <path
          d="M35.9999 21.3765C36.0003 19.9812 35.5677 18.6201 34.7616 17.4812C33.9556 16.3423 32.8159 15.4816 31.4999 15.018V13.8765C31.4999 10.296 30.0776 6.86226 27.5459 4.33052C25.0141 1.79878 21.5803 0.376465 17.9999 0.376465C14.4195 0.376465 10.9857 1.79878 8.45397 4.33052C5.92223 6.86226 4.49992 10.296 4.49992 13.8765V15.018C3.18248 15.4824 2.04158 16.3441 1.23456 17.4843C0.427535 18.6246 -0.00585937 19.9871 -0.00585938 21.384C-0.00585937 22.7809 0.427535 24.1434 1.23456 25.2836C2.04158 26.4238 3.18248 27.2855 4.49992 27.75C4.83998 27.8702 5.20396 27.9069 5.56119 27.857C5.91841 27.8071 6.25841 27.6721 6.55251 27.4633C6.84661 27.2544 7.0862 26.978 7.25108 26.6572C7.41597 26.3364 7.50131 25.9807 7.49992 25.62V13.8765C7.49992 12.4976 7.77151 11.1322 8.29918 9.85829C8.82686 8.58437 9.60028 7.42686 10.5753 6.45184C11.5503 5.47683 12.7078 4.7034 13.9817 4.17573C15.2557 3.64806 16.621 3.37646 17.9999 3.37646C19.3788 3.37646 20.7442 3.64806 22.0181 4.17573C23.292 4.7034 24.4495 5.47683 25.4245 6.45184C26.3996 7.42686 27.173 8.58437 27.7007 9.85829C28.2283 11.1322 28.4999 12.4976 28.4999 13.8765V25.62C28.5031 25.9321 28.5715 26.24 28.7007 26.5242C28.8298 26.8083 29.0169 27.0623 29.2499 27.27V28.122C29.2499 30.333 27.3134 31.122 25.4999 31.122H22.8329C22.5027 30.55 21.993 30.1031 21.3829 29.8503C20.7728 29.5976 20.0963 29.5533 19.4584 29.7242C18.8205 29.8951 18.2568 30.2717 17.8548 30.7957C17.4528 31.3196 17.2348 31.9616 17.2348 32.622C17.2348 33.2824 17.4528 33.9243 17.8548 34.4482C18.2568 34.9722 18.8205 35.3488 19.4584 35.5197C20.0963 35.6907 20.7728 35.6463 21.3829 35.3936C21.993 35.1409 22.5027 34.6939 22.8329 34.122H25.4999C29.4734 34.122 32.2499 31.6545 32.2499 28.122V27.405C33.3733 26.8476 34.3192 25.9884 34.9815 24.9236C35.6439 23.8588 35.9965 22.6305 35.9999 21.3765Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_121">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
