import { apiV1WithErrorRedirect } from "./constants";

export type TimesheetMessagePayload = {
  externalCompanyId: string;
  week: number;
  externalJobId: string;
  timeSegmentAbsoluteId: number;
};

export type IntercomGroupPayload = {
  userIds: string[];
  messages: string[];
  fromCompany: boolean;
  timesheetMessages?: TimesheetMessagePayload[];
};

export type TimesheetUserMessage = {
  id: number;
  sender_user_id: number;
  sender_user_first_name: string;
  recipient_user_id: number;
  recipient_user_first_name: string;
  external_job_id: string;
  time_segment_absolute_id: number;
  sent_at: Date;
};

export type TimesheetMessagesResponse = {
  externalCompanyId: string;
  week: string;
  messages: TimesheetUserMessage[];
} ;

export const intercomGroup = (payload: IntercomGroupPayload) => {
  return apiV1WithErrorRedirect
    .post("intercom/group", {
      json: payload,
    })
    .json();
};

export const getTimesheetMessages = (companyId: string, week: number): Promise<TimesheetMessagesResponse> => {
  return apiV1WithErrorRedirect
    .get(`intercom/timesheetMessages?externalCompanyId=${companyId}&week=${week}`)
    .json<TimesheetMessagesResponse>();
};
