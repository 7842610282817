// Supervisor Form - VIEW
// =============================================================================
// Modal content to create a new supervisor account

import React, { useContext, useState } from "react";

// STYLED COMPONENTS
import {
  Input,
  Box,
  Label,
  Button,
  Field,
  Group,
  Heading,
  Paragraph,
  MaskedField,
} from "@bluecrew/web-react-core";
import { Formik, Field as FormikField, ErrorMessage } from "formik";
import Select, { SingleValue } from "react-select";
import { reactSelectStyles } from "../../../shared/styles";

// REDUX
import { CreateSupervisorUserRequest } from "../../api/bluecrew/user";

import { supervisorSchema } from "../../forms/validation";
import { StyleContainer, StyledContent, StyledForm } from "./sytledComponents";
import { AbilityContext } from "../../containers/PermissionsContext";
import {
  CreateSupervisorUserMutateRequest,
  useCreateSupervisorUser,
} from "../../api/bluecrew/hooks/user";

// TODO:(BW-1015) - Change here
export const supervisorDropdownOptions = [
  {
    value: "CLIENT",
    label: "Admin (Full access)",
  },
  {
    value: "CLIENT_ADMIN",
    label: "Admin (No wage access)",
  },
  {
    value: "MANAGER",
    label: "Manager (Can't post jobs or see invoices)",
  },
  {
    value: "NO_BLUECREW_ACCOUNT",
    label: "No access (will not create a user account)",
  },
];

const supervisorDropdownOptionsWithNoWageAccess = [
  {
    value: "CLIENT_ADMIN",
    label: "Admin (No wage access)",
  },
  {
    value: "MANAGER",
    label: "Manager (Can't post jobs or see invoices)",
  },
  {
    value: "NO_BLUECREW_ACCOUNT",
    label: "No access (will not create a user account)",
  },
];

export enum SupervisorFormDropDownOptions {
  CLIENT = 0,
  CLIENT_ADMIN = 1,
  MANAGER = 2,
  NO_BLUECREW_ACCOUNT = 3,
}

export const defaultSupervisorDropDownIndex = SupervisorFormDropDownOptions.MANAGER;

type SupervisorFormProps = {
  handleSupervisorSubmit: Function;
  setValue: Function;
};

export const SupervisorForm = ({ handleSupervisorSubmit, setValue }: SupervisorFormProps) => {
  const [showFormError, setShowFormError] = useState<boolean>(false);
  const ability = useContext(AbilityContext);
  const canViewWages = ability.can("view", "wages");
  const { mutate: createSupervisorUser } = useCreateSupervisorUser(
    (data, { values, actions }: CreateSupervisorUserMutateRequest) => {
      setShowFormError(false);

      // reset form and values if successful
      if (actions !== undefined) {
        actions.setSubmitting(false);
        actions.resetForm(InitialValues);
      }

      // close modal
      const fullName = `${values.normalized_first_name} ${values.normalized_last_name}`;
      handleSupervisorSubmit(
        // @ts-ignore
        data.user.id,
        fullName,
        setValue,
      );
    },
    () => {
      setShowFormError(true);
    },
  );

  const InitialValues: CreateSupervisorUserRequest = {
    normalized_first_name: "",
    normalized_last_name: "",
    username: "",
    phone: "",
    userType: supervisorDropdownOptions[defaultSupervisorDropDownIndex].value,
  };

  type SetFieldValue = (fieldName: string, value?: string) => void;
  const handleSupervisorType = (
    setFieldValue: SetFieldValue,
    selected: SingleValue<{ value: string; label: string }>,
  ) => {
    setFieldValue("userType", selected?.value);

    if (
      selected?.value ===
      supervisorDropdownOptions[SupervisorFormDropDownOptions.NO_BLUECREW_ACCOUNT].value
    ) {
      setFieldValue("username", "");
    }
  };

  const renderSupervisorsList = (setFieldValue: SetFieldValue) => {
    return (
      <Select
        isMulti={false}
        isSearchable={false}
        onChange={(selected) => handleSupervisorType(setFieldValue, selected)}
        options={
          canViewWages ? supervisorDropdownOptions : supervisorDropdownOptionsWithNoWageAccess
        }
        styles={reactSelectStyles}
        defaultValue={supervisorDropdownOptions[defaultSupervisorDropDownIndex]}
      />
    );
  };

  return (
    <StyleContainer>
      <StyledContent>
        <Heading as="h4">Add a new supervisor</Heading>
        <Paragraph>Please enter the following information.</Paragraph>
        <Formik
          validationSchema={supervisorSchema}
          initialValues={InitialValues}
          enableReinitialize
          onSubmit={async (values, actions) => {
            createSupervisorUser({ values, actions });
          }}
          render={() => (
            <StyledForm>
              {/** @ts-ignore */}
              <Group>
                <Field>
                  <FormikField
                    name="normalized_first_name"
                    render={({ field }) => (
                      <Label>
                        First Name*
                        <Input type="text" name="normalized_first_name" {...field} />
                        <ErrorMessage
                          name="normalized_first_name"
                          render={(msg) => <div className="error-message">{msg}</div>}
                        />
                      </Label>
                    )}
                  />
                </Field>
                <Field>
                  <FormikField
                    name="normalized_last_name"
                    render={({ field }) => (
                      <Label>
                        Last Name*
                        <Input type="text" name="normalized_last_name" {...field} />
                        <ErrorMessage
                          name="normalized_last_name"
                          render={(msg) => <div className="error-message">{msg}</div>}
                        />
                      </Label>
                    )}
                  />
                </Field>
              </Group>
              {/** @ts-ignore */}
              <Group>
                <Field>
                  <FormikField
                    name="username"
                    render={({ field, form: { values } }) => (
                      <Label>
                        Email*
                        <Input
                          type="text"
                          disabled={
                            values.userType ===
                            supervisorDropdownOptions[
                              SupervisorFormDropDownOptions.NO_BLUECREW_ACCOUNT
                            ].value
                          }
                          name="username"
                          {...field}
                        />
                        <ErrorMessage
                          name="username"
                          render={(msg) => <div className="error-message">{msg}</div>}
                        />
                      </Label>
                    )}
                  />
                </Field>
                <Field>
                  <FormikField
                    name="phone"
                    render={({ field }) => (
                      <Label>
                        Phone Number*
                        <MaskedField name="phone" classes="masked-phone" {...field} />
                        <ErrorMessage
                          name="phone"
                          render={(msg) => <div className="error-message">{msg}</div>}
                        />
                      </Label>
                    )}
                  />
                </Field>
              </Group>
              <Field>
                <FormikField
                  name="userType"
                  render={({
                    form: { setFieldValue },
                  }: {
                    form: { setFieldValue: SetFieldValue };
                  }) => renderSupervisorsList(setFieldValue)}
                />
              </Field>
              <Field>
                <Input type="hidden" name="company_id" />
              </Field>
              {showFormError && (
                <Box className="error-message form-error">
                  A supervisor with that email already exists.
                </Box>
              )}
              <Button type="submit">Add Supervisor</Button>
            </StyledForm>
          )}
        />
      </StyledContent>
    </StyleContainer>
  );
};
