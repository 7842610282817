import {
  BlueprintTheme,
  Modal,
  PinIcon,
  SearchBar,
  themedStyled,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import React, { useEffect, useState } from "react";
import { styled } from "baseui";
import ReactGoogleMapsLoader from "react-google-maps-loader";
import GooglePlacesSuggest from "react-google-places-suggest";
import { ModalBodyWrapper } from "./ModalBodyWrapper";
import { Spacer, SpacerType } from "../../components/Spacer";
import { WorksiteAddressMapper } from "./WorksiteAddressMapper";
import { WorksiteModalProps } from "./types/propTypes/WorksiteModal.types";
import { WorksiteAddress } from "./types/propTypes/Worksite.types";
import { Position, Positions } from "./types/propTypes/PositionField.types";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";

interface AddressesCount {
  [key: string]: number;
}

const getUniqueAddressesList = (arr: WorksiteAddress[]): WorksiteAddress[] => {
  const counts: AddressesCount = {};
  // Create hash from coordinates (lat, lng) to prevent duplicates in the list
  const listWithKeys = arr.map((item): [string, WorksiteAddress] => {
    const address = item;
    const locationIdentity = `${item.placeId}`;
    // Increase address count if same address was used for different positions
    counts[locationIdentity] = (counts[locationIdentity] || 0) + 1;
    // How many times same address was used in positions
    address.addressCount = counts[locationIdentity];
    return [locationIdentity, address];
  });

  // Map is used here to get uniq addresses, it uses hash string from the "listWithKeys" func to remove duplicates.
  // Then crete actual array from Map iterator.
  // The last inserted item with the existing key stays in the Map, that's why addressCount property should be with latest count
  const uniqueAddresses = Array.from(new Map(listWithKeys).values());

  // Sort addresses in descending order by number of positions with that address
  uniqueAddresses.sort((a, b) => (b.addressCount || 0) - (a.addressCount || 0));
  return uniqueAddresses;
};

const getRecentAddresses = (positions: Positions) => {
  const companyPositions = positions?.map((position: Position) => ({
    ...position.address,
    // Change key name to be consistent across the app
    address: position.address.displayAddress,
  }));
  const recentAddress = companyPositions?.length ? getUniqueAddressesList(companyPositions) : [];
  return [...recentAddress];
};

const hasAddress = (arr: Array<WorksiteAddress>, address: WorksiteAddress) =>
  !!arr.find((item) => item.placeId === address.placeId);

const isCompatibleLocation = (prediction: google.maps.places.AutocompletePrediction) =>
  !prediction.types.includes("street_address") &&
  !prediction.types.includes("point_of_interest") &&
  !prediction.types.includes("premise") &&
  !prediction.types.includes("subpremise");

export const WorksiteModal = ({
  isOpen,
  onClose,
  onItemSelect,
  onLocationSelect,
  positions,
  editPositionPage,
  selectedPosition,
}: WorksiteModalProps) => {
  const [, theme] = useThemedStyletron();
  const [searchCriteria, setSearchCriteria] = useState<string>("");
  const [recentAddress, setRecentAddress] = useState<Array<WorksiteAddress>>([]);

  useEffect(() => {
    // If recentAddress array is empty and component received positions list,
    // create recentAddress array from it.
    if (!recentAddress.length && positions.length) {
      setRecentAddress(getRecentAddresses(positions));
      filterRecentPositions();
    }
  }, [positions]);

  useEffect(() => {
    filterRecentPositions();
  }, [selectedPosition?.placeId]);

  const filterRecentPositions = () => {
    if (editPositionPage && selectedPosition) {
      // Filter out selected position address from the Recent list,
      // to prevent user from selecting the same address.
      // In this case, Form can set the same address and assume that form values
      // were modified. Used only for Edit Position page.
      const recentAddresses = getRecentAddresses(positions);
      const filteredPositions = recentAddresses.filter(
        (p) => p.placeId !== selectedPosition.placeId,
      );
      setRecentAddress(filteredPositions);
    }
  };
  const { google } = window;

  const onSelectSuggest = (
    geoCodedPrediction: google.maps.GeocoderResult,
    originalPrediction: google.maps.places.AutocompletePrediction,
  ) => {
    const address: WorksiteAddress = WorksiteAddressMapper.toModel(
      originalPrediction,
      geoCodedPrediction,
    );

    setRecentAddress(
      hasAddress(recentAddress, address) ? [...recentAddress] : [...recentAddress, address],
    );

    setSearchCriteria("");
    onItemSelect && onItemSelect(address);
    onLocationSelect && onLocationSelect(address);
  };

  const renderMap = (googleMap: typeof google.maps) => (
    <FixListItemsWrapper $showBorder={!!searchCriteria.length}>
      <GooglePlacesSuggest
        googleMaps={googleMap}
        autocompletionRequest={{
          input: searchCriteria,
        }}
        // @ts-ignore
        displayPoweredByGoogle={false}
        textNoResults="No results"
        onSelectSuggest={onSelectSuggest}
        customRender={(prediction) =>
          !prediction || isCompatibleLocation(prediction)
            ? ""
            : predictionRender(theme, WorksiteAddressMapper.toModel(prediction))
        }
      >
        <div style={{ paddingRight: theme.sizing.scale600 }}>
          <SearchBar
            value={searchCriteria}
            onChange={(text) => {
              setSearchCriteria(text);
            }}
          />
        </div>
        <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
        {!searchCriteria.length && (
          <>
            <Label>recent</Label>
            <AddressList>
              {recentAddress.map((item: WorksiteAddress) =>
                predictionRender(
                  theme,
                  item,
                  () => {
                    onItemSelect && onItemSelect(item);
                    onLocationSelect && onLocationSelect(item);
                  },
                  true,
                ),
              )}
            </AddressList>
          </>
        )}
        <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale300} />
      </GooglePlacesSuggest>
    </FixListItemsWrapper>
  );

  return (
    <Modal
      header="Worksite address"
      body={
        <ModalBodyWrapper id="ModalBodyWrapper">
          {google && google.maps ? (
            renderMap(google.maps)
          ) : (
            <ReactGoogleMapsLoader
              params={{
                key: GOOGLE_MAPS_API_KEY,
                libraries: "places,geocode",
              }}
              render={(googleMaps) => googleMaps && renderMap(googleMaps)}
            />
          )}
        </ModalBodyWrapper>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

const predictionRender = (
  theme: BlueprintTheme,
  address: WorksiteAddress,
  onClick?: () => void,
  recentSection = false,
) => {
  let addressText = address.address;
  if (recentSection && address.displayAddress) {
    // We are using displayAddress with zip code only for recent section.
    // If address is displayed in suggested section while user is searching
    // we will show address without zip code because we don't have this info yet
    addressText = address.displayAddress;
  }
  return (
    address && (
      <AddressItem key={`${address.lat}, ${address.lng}`} onClick={onClick}>
        <IconWrapper>
          <PinIcon color={theme.colors.ultramarine} />
        </IconWrapper>
        <div>
          <AddressWrapper>{addressText}</AddressWrapper>
          {address?.addressCount ? (
            <AddressCountWrapper>{address.addressCount} positions</AddressCountWrapper>
          ) : null}
        </div>
      </AddressItem>
    )
  );
};

const Label = themedStyled("div", ({ $theme }) => ({
  textTransform: "uppercase",
  ...$theme.typography.font150,
  color: $theme.colors.stone,
  marginBottom: $theme.sizing.scale300,
}));

const AddressList = themedStyled("div", ({ $theme }) => ({
  borderTopColor: $theme.colors.stratus,
  borderTopStyle: "solid",
  borderTopWidth: "1px",
}));

const AddressItem = themedStyled("div", ({ $theme }) => ({
  borderBottomColor: $theme.colors.stratus,
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingTop: $theme.sizing.scale500,
  paddingBottom: $theme.sizing.scale500,
  paddingRight: $theme.sizing.scale500,
  cursor: "pointer",
}));

const IconWrapper = styled("span", ({ $theme }) => ({
  marginRight: $theme.sizing.scale300,
  display: "flex",
}));

const AddressWrapper = styled("div", ({ $theme }) => ({
  ...$theme.typography.font350,
  marginRight: $theme.sizing.scale300,
}));

const AddressCountWrapper = styled("div", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: "rgb(136, 137, 146)",
  fontWeight: 500,
}));

const FixListItemsWrapper = styled<{ $showBorder?: boolean }, "div", BlueprintTheme>(
  "div",
  ({ $theme, $showBorder }) => ({
    ":last-child > div:last-child > div:last-child": {
      position: "inherit",
      boxShadow: "none",
      height: "calc(100vh - 128px)",
      ...($showBorder && {
        borderTopColor: $theme.colors.stratus,
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      }),
    },
  }),
);
