// JOB - ACTIONS
// =============================================================================

import { createActions } from "redux-actions";
import {
  AddWorkRequestToSchPayload,
  CreateCrewJobAndSchedPayload,
  CreateCrewAndOptJobAndSchedPayload,
  CreateDirectInviteJobAndSchedPayload,
  CreateDirectInviteAndOptJobAndSchedPayload,
  PostJobRequest,
  SupervisorsResponse,
  PostPositionRequest,
  RejectApplicantAction,
  AddCrewJobToSchedulePayload,
  CreateBlueShiftScheduleJobPayload,
} from "../../api/bluecrew/types";

export type CreateDirectInviteAndOptJobAndSchedAction = {
  type: string;
  payload: CreateDirectInviteAndOptJobAndSchedPayload;
};

export type CreateCrewJobAndSchedAction = {
  type: string;
  payload: CreateCrewJobAndSchedPayload;
};
export type CreateCrewAndOptJobAndSchedAction = {
  type: string;
  payload: CreateCrewAndOptJobAndSchedPayload;
};

export type AddCrewJobToScheduleAction = {
  type: string;
  payload: AddCrewJobToSchedulePayload;
};

export type AddWorkRequestToSchAction = {
  type: string;
  payload: AddWorkRequestToSchPayload;
};

export type CreateBlueShiftScheduleJobsAction = {
  type: string;
  payload: CreateBlueShiftScheduleJobPayload;
};

// Export this action type so the crewMembers reducer can reference it.
export const BEGIN_ANY_JOB_POST = "BEGIN_ANY_JOB_POST";

export default createActions({
  // This action has an empty payload.
  [BEGIN_ANY_JOB_POST]: () => {},
  JOBS_LIST_VIEW: {
    OPEN: undefined,
    LOADED: undefined,
  },
  JOBS: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_JOB: {
    REQUEST: (req: PostJobRequest) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  // Offer job to applicant on an application job
  OFFER_JOB: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  // reject applicant on an application job
  REJECT_APPLICATION_JOB: {
    REQUEST: (req: RejectApplicantAction) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  // Actions to add WR/Job to existing Schedule
  ADD_WORK_REQUEST_TO_SCH: {
    REQUEST: (req: AddWorkRequestToSchPayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_BLUE_SHIFT_SCHEDULE_JOBS: {
    REQUEST: (req: CreateBlueShiftScheduleJobPayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  TEMPLATE: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  SUPERVISORS: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: SupervisorsResponse) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  // TODO: JOB_TYPES and CREATE_POSITION should be moved to position file
  JOB_TYPES: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_POSITION: {
    REQUEST: (req: PostPositionRequest) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_DIRECT_INVITE_JOB_AND_SCHEDULE: {
    REQUEST: (req: CreateDirectInviteJobAndSchedPayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_DIRECT_INVITE_AND_OPT_JOB_AND_SCHEDULE: {
    REQUEST: (req: CreateDirectInviteAndOptJobAndSchedPayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_CREW_JOB_AND_SCHEDULE: {
    REQUEST: (req: CreateCrewJobAndSchedPayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_CREW_AND_OPT_JOB_AND_SCHEDULE: {
    REQUEST: (req: CreateCrewAndOptJobAndSchedPayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  ADD_CREW_JOB_TO_SCHEDULE: {
    REQUEST: (req: AddCrewJobToSchedulePayload) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  SAVE_DIRECT_INVITE_USER_IDS: (data: Array<string>) => ({ ...data }),
  CLEAR_DIRECT_INVITE_USER_IDS: (data: any) => ({ ...data }),
  SAVE_CREW_FOR_HIRE_IDS: (data: any) => ({ ...data }),
  CLEAR_CREW_FOR_HIRE_IDS: (data: any) => ({ ...data }),
  UPDATE_BULK_SINGLE_SHIFT_RESPONSES: (data: any) => data,
  UPDATE_BULK_SINGLE_SHIFT_RESPONSES_REQUIRED: (numberOfBulkSingleShiftsToCreate: number) =>
    numberOfBulkSingleShiftsToCreate,
}) as any;
