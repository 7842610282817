import { Input } from "@bluecrew/blueprint-web";
import { SIZE } from "baseui/input";
import React from "react";
import {Column, StyledSettingText} from "../styledComponents";

type TimesheetsInputProps = {
  disabled: boolean;
  label: string;
  unit: string;
  value: number;
  onChange: (value: number) => void;
};

export const TimesheetsInput = ({
  disabled,
  label,
  unit,
  value,
  onChange,
}: TimesheetsInputProps) => (
  <>
    <Column>
      <StyledSettingText>{label}</StyledSettingText>
      <div
        data-testid={label}
      >
        <Input
          disabled={disabled}
          size={SIZE.compact}
          type="number"
          value={value.toString()}
          onChange={(val) => onChange(parseInt(val, 10))}
          // @dloukusa-bc Not a huge fan of styling in this manner, but base-web/baseui components don't work with styled()
          styles={{ textAlign: "end", maxWidth: "140px" }}
        />
      </div>
    </Column>
    <div className="timesheets-unit">{unit}</div>
  </>
);
