// COMPANY - SAGA
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, call, put, takeLatest } from "redux-saga/effects";
import companyActions, {
  CheckCompanyAction,
  GetCompanyDetailsAction,
  SwitchCompanyAction,
} from "../actions/company";
import * as companyAPI from "../../api/bluecrew/company";
import { queryClient } from "../../queryClient";

function* getCompanyDetailsSaga(action: GetCompanyDetailsAction): Generator<Effect, void, any> {
  try {
    const response = yield call(companyAPI.getCompanyDetails, action.payload);
    yield put(companyActions.getCompanyDetails.success(response));
  } catch (error) {
    yield put(companyActions.getCompanyDetails.failure(error));
  }
}

function* switchCompanySaga(action: SwitchCompanyAction): Generator<Effect, void, any> {
  try {
    const response = yield call(companyAPI.switchCompany, action.payload);
    yield put(companyActions.switchCompany.success(response));
    queryClient.clear();
    // We need to do a hard redirect here to get the latest split.io
    // treatement and make sure user state is up to date (see sagas/auth).
    window.location.href = "/";
  } catch (error) {
    yield put(companyActions.switchCompany.failure(error));
  }
}

type FetchUserCompaniesAction = {
  type: string;
  payload: {
    parentCompanyId: number | null;
    shouldFetchAllCompanies: boolean;
    shouldFetchBranchCompanies: boolean;
  };
};

// Generator<CallEffect<ReturnType<companyAPI.getAllCompanies>> | PutEffect<AnyAction>,
function* userCompaniesSaga(action: FetchUserCompaniesAction): Generator<Effect, void, any> {
  try {
    let response;

    // Branch users should only see the companies they are assigned to.
    if (action.payload.shouldFetchBranchCompanies) {
      const options = {
        includeCompanyLogo: true,
        includeCompanyCity: true,
      };
      response = yield call(companyAPI.fetchUsersOwnCompanies, options);
      response.companies = response.companies.map((company) => {
        return {
          company_id: company.companyId || "",
          name: company.companyName,
          url: company.url,
          city: company.city,
        };
      });
      // user has correct permissions, fetch all companies.
    } else if (action.payload.shouldFetchAllCompanies) {
      response = yield call(companyAPI.getAllCompanies);
    } else if (action.payload.parentCompanyId) {
      // user does not have permission to view all companies, fetch all sibling companies.
      response = yield call(companyAPI.getClientUserCompanies, action.payload);
    } else {
      throw new Error("Invalid permissions, parentCompanyId is null ");
    }
    yield put(companyActions.userCompanies.success(response));
  } catch (error) {
    yield put(companyActions.userCompanies.failure(error));
  }
}

function* checkCompanySaga(action: CheckCompanyAction): Generator<Effect, void, any> {
  const { existingCompanyId } = action.payload;
  try {
    const companyDetails = yield call(companyAPI.getCompanyDetails, {
      companyId: existingCompanyId,
    });
    // super users can still fetch company data from companies they don't belong to
    if (!companyDetails.company.isUserCurrentCompany) {
      throw new Error("Super Admin doesn't belong to company");
    }
  } catch (error) {
    // This will throw an error, such as a 403, if the user is
    // no longer part of the company or if they are logged out
    // or if the user is a SUPER_USER
    // Reload the app to refresh company data.
    window.location.href = "/";
  }
}

export default {
  *watchGetCompanyDetailsSaga(): SagaIterator {
    yield takeLatest(companyActions.getCompanyDetails.request, getCompanyDetailsSaga);
  },
  *watchUserCompaniesSaga(): SagaIterator {
    yield takeLatest(companyActions.userCompanies.request.toString(), userCompaniesSaga);
  },
  *watchSwitchCompanySaga(): SagaIterator {
    yield takeLatest(companyActions.switchCompany.request, switchCompanySaga);
  },
  *watchCheckCompanySaga(): SagaIterator {
    yield takeLatest(companyActions.checkCompany, checkCompanySaga);
  },
};
