type PendoInitializerType = {
  userTraits: any;
  windowWidth: number;
  windowHeight: number;
  mobileWindowPhysicalWidth: number;
  mobileWindowPhysicalHeight: number;
};

export function initializePendo({
  userTraits,
  windowWidth,
  windowHeight,
  mobileWindowPhysicalWidth,
  mobileWindowPhysicalHeight,
}: PendoInitializerType) {
  window.pendo.initialize({
    visitor: {
      ...userTraits,
      id: userTraits.external_id,
      companyId: userTraits.company_id,
      windowWidth,
      windowHeight,
      mobileWindowPhysicalWidth,
      mobileWindowPhysicalHeight,
    },
  });
}

export function getPendoInjectionScriptContent() {
  // We are using same key for every env
  const PENDO_KEY = "c3c39d2f-cb44-46c8-6cef-66f52e9d0ec8";
  const content = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('${PENDO_KEY}');`;

  return content;
}
