import React from "react";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";

import { Portal, Backdrop } from "@bluecrew/web-react-core";

import { ReasonCode } from "../../api/bluecrew/types";
import { ConfirmModalProps } from "../ConfirmModal/ConfirmModal";
import {
  StyleContainer,
  StyledButtonGroup,
  StyledOverlay,
} from "../ConfirmModal/ConfirmModal.StyledComponents";
import { ModalBody } from "../ConfirmModal/ModalBody";
import { ConfirmButton } from "../ConfirmModal/ConfirmButton";
import { CancelButton } from "../ConfirmModal/CancelButton";

type ConfirmationClickHandlerType = (arg0?: ReasonCode, arg1?: string) => void;
interface ConfirmModalWithFormProps extends Omit<ConfirmModalProps, "handleConfirmationClick"> {
  initialValues: any;
  validationSchema: Yup.SchemaOf<any>;
  FormContent: React.FC<any>;
  handleConfirmationClick: ConfirmationClickHandlerType;
}

export const ConfirmModalWithForm: React.FC<ConfirmModalWithFormProps> = ({
  icon,
  show,
  toggleModal,
  handleConfirmationClick,
  headingText,
  paragraphText,
  confirmationButtonText,
  confirmationButtonPalette,
  noIcon,
  initialValues,
  validationSchema,
  FormContent,
}) => (
  <>
    <Portal>
      <Backdrop fade visible={false} onClick={toggleModal} />
      <StyledOverlay fade visible={show}>
        <StyleContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) =>
              handleConfirmationClick(
                values.reasonCode,
                values.reasonCode === ReasonCode.OTHER
                  ? values.reasonText
                  : initialValues.reasonText,
              )
            }
          >
            {(formProps: FormikProps<typeof initialValues>) => (
              <>
                <ModalBody
                  noIcon={noIcon}
                  icon={icon}
                  headingText={headingText}
                  paragraphText={paragraphText}
                >
                  <FormContent formProps={formProps} />
                </ModalBody>

                <StyledButtonGroup>
                  <CancelButton onClick={toggleModal} />
                  <ConfirmButton
                    onClick={() => formProps.validateForm().then(() => formProps.handleSubmit())}
                    confirmationButtonPalette={confirmationButtonPalette}
                    disabled={formProps.isSubmitting}
                    type="submit"
                  >
                    {confirmationButtonText}
                  </ConfirmButton>
                </StyledButtonGroup>
              </>
            )}
          </Formik>
        </StyleContainer>
      </StyledOverlay>
    </Portal>
  </>
);
