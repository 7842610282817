import React from 'react';
import { CheckIndeterminate, Plus } from 'baseui/icon';
import { styled } from 'baseui';
import {
  getBorderColor,
  getBorderRadius,
  getBorderWidth,
  getVerticalPadding,
} from '../helpers/styleHelpers';
import { useThemedStyletron } from '../theme';

interface CounterProps {
  onChange?: (item: number) => void;
  value: number;
  step?: number;
  error?: boolean;
  disabled?: boolean;
}

interface CounterWrapperProps {
  $error?: boolean;
  $padding?: object;
  $disabled?: boolean;
}

interface CounterInputProps {
  $disabled?: boolean;
}

const CounterWrapper = styled<CounterWrapperProps, 'div'>(
  'div',
  ({ $error, $theme }) => {
    const styles = {
      borderRadius: $theme.sizing.scale200,
      borderWidth: $error ? $theme.sizing.scale0 : '1px',
      borderColor: $error ? $theme.colors.negative : $theme.colors.mono600,
    };

    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      borderStyle: 'solid',
      width: '127px',
      height: $theme.sizing.scale1200,
      ...getBorderRadius(styles.borderRadius),
      ...getBorderWidth(styles.borderWidth),
      ...getBorderColor(styles.borderColor),
    };
  },
);

const CounterButton = styled<CounterWrapperProps, 'div'>(
  'div',
  ({ $padding, $theme, $disabled }) => ({
    display: 'flex',
    pointerEvents: $disabled ? 'none' : 'auto',
    color: $disabled ? $theme.colors.primary500 : $theme.colors.primary,
    ...getVerticalPadding($theme.sizing.scale200),
    ...$padding,
    ':hover': {
      cursor: 'pointer',
    },
  }),
);

const CounterInput = styled<CounterInputProps, 'input'>(
  'input',
  ({ $theme, $disabled }) => {
    const styles = {
      borderWidth: '0',
    };

    return {
      ...$theme.typography.ParagraphLarge,
      pointerEvents: $disabled ? 'none' : 'auto',
      textAlign: 'center',
      width: '60%',
      MozAppearance: 'textfield',
      ...getBorderWidth(styles.borderWidth),
      ':focus': {
        outline: 'none',
      },
      '::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    };
  },
);

export const Counter = (props: CounterProps) => {
  const [, theme] = useThemedStyletron();
  const { onChange, value = 1, step = 1, error, disabled } = props;
  return (
    <CounterWrapper $error={error}>
      <CounterButton
        $disabled={disabled}
        $padding={{ paddingLeft: theme.sizing.scale600 }}
        onClick={() => {
          onChange && onChange(value - step);
        }}
      >
        <CheckIndeterminate
          size={20}
          color={disabled ? theme.colors.primary500 : theme.colors.accent500}
        />
      </CounterButton>
      <CounterInput
        $disabled={disabled}
        type="number"
        value={value}
        onChange={(e) => {
          onChange && onChange(Number(e.target.value));
        }}
      />
      <CounterButton
        $disabled={disabled}
        $padding={{ paddingRight: theme.sizing.scale600 }}
        onClick={() => {
          onChange && onChange(value + step);
        }}
      >
        <Plus
          size={20}
          color={disabled ? theme.colors.primary500 : theme.colors.accent500}
        />
      </CounterButton>
    </CounterWrapper>
  );
};
