import styled from "styled-components";

export const AuditViewColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const AuditEditWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  padding-top: 8px;
`;

export const AuditTimestampWrapper = styled.div`
  align-self: flex-start;
  flex-grow: 1;
  display: contents;
`;

export const TimestampEditHeader = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  align-self: flex-end;
  flex-grow: 1;
  order: 2;
`;

export const UserTypeHeader = styled.div`
  font-family: Inter;
  font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
  font-size: ${({ theme }) => theme.typography.font250.fontSize};
  line-height: ${({ theme }) => theme.typography.font250.lineHeight};
  font-style: normal;
  align-self: flex-start;
  flex-grow: 1;
  order: 1;
  padding: 0 1rem 0 0;
`;

export const NoEditsHeader = styled.div`
  color: #55565f;
  font-family: Inter;
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  line-height: ${({ theme }) => theme.typography.font300.lineHeight};
  font-style: italic;
`;

export const WarningWrapper = styled.div`
  display: block;
  text-align: center;
  height: 152px;
  width: 410px;
  margin-top: 12px;
  padding-top: 24px;
  top: 0px;
  background-color: #e72e3e12;
  padding-bottom: 0px;
  border-radius: 8px;
`;

export const WarningMessage = styled.div`
  text-align: center;
  color: #e72e3e;
  font-family: "Inter";
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  line-height: ${({ theme }) => theme.typography.font350.lineHeight};
  margin-top: 8px;
`;

export const TriageMessage = styled.div`
  text-align: center;
  color: #33343d;
  font-family: "Inter";
  font-weight: ${({ theme }) => theme.typography.font200.fontWeight};
  font-size: ${({ theme }) => theme.typography.font200.fontSize};
  line-height: ${({ theme }) => theme.typography.font200.lineHeight};
  font-style: normal;
  margin-top: 8px;
`;
