import React, { useState } from "react";
import { MessageModal } from "../MessageModal";
import { Tooltip } from "../../../../../components/Tooltip";
import {
  StyledTooltipButton,
  StyledTooltipContent,
  StyledTooltipMessage,
} from "../styledComponents";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";

export type AttributeTooltipProps = {
  message: string;
  defaultModalMessage: string;
  target: string | HTMLElement;
  buttonText: string;
  cmFirstName: string;
  cmId: string;
  shiftInfo: TimesheetsShiftInfo;
};

export const AttributeTooltip = ({
  message,
  defaultModalMessage,
  target,
  buttonText,
  cmFirstName,
  cmId, // crew member id
  shiftInfo, // shift info - we need this to send this data along with intercom message
}: AttributeTooltipProps) => {
  const [showModal, setShowModal] = useState(false);

  const onClose = (e) => {
    e?.stopPropagation();
    setShowModal(false);
  };

  return (
    <>
      <MessageModal
        onModalClose={onClose}
        defaultModalMessage={defaultModalMessage}
        cmId={cmId}
        cmFirstName={cmFirstName}
        show={showModal}
        shiftInfo={shiftInfo}
      />
      <Tooltip target={target} position="top" autoHide={false}>
        <StyledTooltipContent>
          <StyledTooltipMessage>{message}</StyledTooltipMessage>
          <StyledTooltipButton
            outlined
            onClick={(e) => {
              e.stopPropagation();
              setShowModal(true);
            }}
          >
            {buttonText}
          </StyledTooltipButton>
        </StyledTooltipContent>
      </Tooltip>
    </>
  );
};
