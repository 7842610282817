import React, { ReactChildren, ReactNode } from "react";
import styled from "styled-components";

interface TooltipMessageProps {
  children: ReactChildren | ReactNode;
  message: string;
  placement?: string;
  relativeToParentElem?: boolean;
}

export const TooltipMessage = ({
  children,
  message,
  placement = "top",
  relativeToParentElem = false,
}: TooltipMessageProps) => (
  <StyledTooltipContainer relativeToParentElem={relativeToParentElem}>
    <div className="tooltip">
      {children}
      <span className={`tooltip-message tooltip-${placement}`}>{message}</span>
    </div>
  </StyledTooltipContainer>
);

interface StyledTooltipContainerProps {
  // If set to 'true' tooltip will be placed relatively to the parent component
  // with 'position: relative' styles.
  // This is needed for better placement of small elements with tooltips, like icons.
  relativeToParentElem: boolean;
}

const StyledTooltipContainer = styled.div<StyledTooltipContainerProps>`
  .tooltip {
    position: ${(props) => (props.relativeToParentElem ? "unset" : "relative")};
    display: flex;
  }

  .tooltip-top {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .tooltip-bottom {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  .tooltip-right {
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
  }

  .tooltip-left {
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

  .tooltip-left-newline {
    top: 50%;
    right: 50%;
    white-space: pre-line;
    text-align: left !important;
    margin-right: 14vh;
    margin-top: -30px;
  }

  .tooltip-message {
    width: max-content;
    max-width: 240px;

    font-size: 10px;
    line-height: 150%;
    background-color: #55565f;
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 3px;

    position: absolute;
    z-index: 9999999999999;

    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip {
    &:hover .tooltip-message {
      visibility: visible;
      opacity: 1;
    }
  }
`;
