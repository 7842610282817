import {
  GetCrewMembersScheduleIcon,
  Calendar,
  useThemedStyletron,
  GetCrewMembersCalendarIcon,
} from "@bluecrew/blueprint-web";
import React from "react";
import styled from "styled-components";
import { ScheduleDatePickerFieldProps } from "../types/propTypes/ScheduleDatePickerField.types";
import { FieldWrapper } from "./FieldWrapper";
import { ErrorMessage } from "../ErrorMessage";

export const ScheduleDatePickerField = ({
  disabled,
  value,
  errors,
  invalid,
  onChange,
  onClose,
  minDate = new Date(),
  rangedDatePicker = true,
  placeholder = "Start/end date",
}: ScheduleDatePickerFieldProps) => {
  const [,] = useThemedStyletron();

  return (
    <FieldWrapper
      paddingTop="0px"
      icon={rangedDatePicker ? <GetCrewMembersScheduleIcon /> : <GetCrewMembersCalendarIcon />}
    >
      <ScheduleDatePickerFieldContainer>
        <div data-pendo-key="ScheduleDatePickerField">
          <Calendar
            disabled={disabled}
            range={!!rangedDatePicker}
            error={invalid}
            value={rangedDatePicker ? value : value[0] || []}
            onChange={onChange}
            onClose={() => onClose()}
            placeholder={placeholder}
            minDate={minDate}
          />
          {invalid && errors && <ErrorMessage text={errors.date.message} />}
        </div>
      </ScheduleDatePickerFieldContainer>
    </FieldWrapper>
  );
};

const ScheduleDatePickerFieldContainer = styled.div`
  min-width: 316px;
  max-width: 316px;
`;
