import React, { Dispatch, SetStateAction, ChangeEvent } from "react";
// import { debounce } from "lodash"; // maybe use this later to improve performance for big clients
import { TimesheetsFiltersType } from "../../types/Payroll.types";
import { CrewMemberSearchContainer } from "../styledComponents";

export type TimesheetsSearchBarProps = {
  setTableFilters: Dispatch<SetStateAction<TimesheetsFiltersType>>;
  tableFilters: TimesheetsFiltersType;
  queryText: string;
  setQueryText: Dispatch<SetStateAction<string>>;
};

export const TimesheetsSearchBar = ({
  setTableFilters,
  tableFilters,
  queryText,
  setQueryText,
}: TimesheetsSearchBarProps) => {
  const placeholder = "Search for crew members";

  return (
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <CrewMemberSearchContainer
        placeholder={placeholder}
        value={queryText}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const searchText = e.target.value;
          if (searchText.length) {
            // maybe debounce
            setQueryText(searchText);
            setTableFilters({
              search: { userName: searchText },
              position: tableFilters.position,
              supervisor: tableFilters.supervisor,
            });
          } else {
            setQueryText(""); // handles removal of last letter when deleting text
            setTableFilters({
              search: { userName: "" },
              position: tableFilters.position,
              supervisor: tableFilters.supervisor,
            });
          }
        }}
      />
    </span>
  );
};
