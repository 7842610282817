import React from "react";
import { Controller } from "react-hook-form";
import { BaseLoginFieldProps } from "./types";
import { FieldWrapperWithError } from "./FieldWrapperWithError";
import { StyledInputText } from "../styledComponents";
import { removeNonDigits } from "../../../utility/misc";

interface VerificationCodeFieldProps extends BaseLoginFieldProps {}

/**
 * Verification code field component, to be used inside of a React Hook Form.
 * Input field only allows for number inputs, and will automatically strip anything that isn't a digit from the input.
 */
export const VerificationCodeField = (props: VerificationCodeFieldProps) => {
  const { fieldName, control, rules, onChange } = props;
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={(field) => (
        <FieldWrapperWithError {...props}>
          <StyledInputText
            id={field.name}
            value={field.value}
            onChange={(e) => {
              if (onChange) onChange(e);
              /**
               * Why the 'removeNonDigits' function?
               *  (1) Prime-react keyfilter blocks keyboard pasting >:(
               *  (2) type="number" causes spin button to appear (and still allows the char 'e');
               *      Can remove with CSS but this is simpler than doing that
               */
              field.onChange(removeNonDigits(e.target.value));
            }}
          />
        </FieldWrapperWithError>
      )}
    />
  );
};
