import React from 'react';
import {
  Input as BaseInput,
  MaskedInput as BaseMaskedInput,
  SIZE,
} from 'baseui/input';
import { getBorder, getHorizontalPadding } from '../helpers/styleHelpers';
import { BlueprintTheme, useThemedStyletron } from '../theme';

interface InputProps {
  placeholder?: string;
  icon?: JSX.Element;
  endIcon?: JSX.Element;
  error?: boolean;
  disabled?: boolean;
  value?: string;
  size?: SIZE[keyof SIZE];
  maxLength?: number;
  onChange?: (val: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  type?: string;
  autocomplete?: string;
  name?: string;
  mask?: string;
  styles?: any;
}

export const Input = (props: InputProps) => {
  const {
    placeholder = '',
    icon,
    endIcon,
    error = false,
    disabled = false,
    value,
    size = SIZE.default,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    inputRef,
    type,
    autocomplete,
    name,
    mask,
    styles,
  } = props;

  const [, $theme] = useThemedStyletron();

  const { height, paddingX } = {
    [SIZE.mini]: {
      height: $theme.sizing.scale850,
      paddingX: $theme.sizing.scale300,
    },
    [SIZE.compact]: {
      height: $theme.sizing.scale950,
      paddingX: $theme.sizing.scale500,
    },
    [SIZE.default]: {
      height: $theme.sizing.scale1200,
      paddingX: $theme.sizing.scale600,
    },
    [SIZE.large]: {
      height: $theme.sizing.scale1400,
      paddingX: $theme.sizing.scale700,
    },
  }[size];

  const BaseComponent = typeof mask === 'string' ? BaseMaskedInput : BaseInput;

  return (
    <BaseComponent
      autoComplete={autocomplete}
      value={value}
      startEnhancer={icon}
      endEnhancer={endIcon}
      placeholder={placeholder}
      disabled={disabled}
      onChange={(event) => {
        if (onChange) onChange(event.currentTarget.value);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      inputRef={inputRef}
      size={size}
      error={error}
      maxLength={maxLength}
      type={type}
      name={name}
      mask={mask}
      overrides={{
        Root: {
          style: ({ $isFocused, $error }) => ({
            ...getInputBorder($theme, $isFocused, $error),
            boxShadow: $isFocused
              ? $theme.lighting.inputShadowActive
              : undefined,
            height,
          }),
        },
        Input: {
          style: {
            ...getHorizontalPadding(paddingX),
            ...styles,
          },
        },
        StartEnhancer: {
          style: {
            ...getHorizontalPadding('0px'),
          },
        },
      }}
    />
  );
};

function getInputBorder(
  $theme: BlueprintTheme,
  $isFocused: boolean,
  $error: boolean,
) {
  const { borders, colors } = $theme;

  if ($isFocused) {
    return getBorder({
      ...borders.border100,
      borderColor: colors.inputBorderActive,
    });
  }
  if ($error) {
    return getBorder({
      ...borders.border200,
      borderColor: colors.inputBorderError,
    });
  }

  return getBorder(borders.border100);
}
