import React from "react";
import { Spinner } from "@bluecrew/web-react-core";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";

const StyledRow = styled(Row)`
  height: 80%;
`;

function withLoading(Component) {
  return function WithLoadingComponent({ isFetching, ...props }) {
    if (!isFetching) return <Component {...props} />;
    return (
      <StyledRow middle="xs" center="xs">
        <Col>
          <Spinner />
        </Col>
      </StyledRow>
    );
  };
}
export default withLoading;
