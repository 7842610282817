import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Button } from "@bluecrew/web-react-core";

export const StyledContainer = styled.div<{ showReviewButton?: boolean }>`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .fill-components {
    display: flex;
    align-items: center;
    height: ${({ showReviewButton }) => (showReviewButton ? 95 : 101)}px;
  }

  .review-applicants {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${p("frost")};
    height: 95px;
  }

  .fill-info {
    color: #646a75;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .filling-container {
    .header-with-progress {
      display: flex;
      align-items: center;
    }
  }

  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-right: 10px;

    &.reviewing {
      color: #e03131;
    }
  }

  .icon {
    margin-left: 24px;
    margin-right: 16px;
  }

  .info-container {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)<{ reviewButtonWidth?: number }>`
  width: ${({ reviewButtonWidth }) => reviewButtonWidth && reviewButtonWidth}%;
`;
