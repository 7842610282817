import React from "react";
import { UserAvatar } from "@bluecrew/web-react-core";
import { UserDetail } from "../../api/bluecrew/types";

interface DirectInviteAvatarProps {
  user: UserDetail;
}

export const DirectInviteAvatar = ({ user }: DirectInviteAvatarProps) => (
  <UserAvatar imgSrc={user.profilePic} size="md" fullName={`${user.firstName} ${user.lastName}`} />
);
