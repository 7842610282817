import React, { ForwardedRef, forwardRef, Ref } from "react";
import { DEFAULT_ICON_NAME, jobIconsMap } from "@bluecrew/blueprint-web";
import styled from "styled-components";
import { BaseListItemProps, ComponentPosition, ListItemRendererT } from "./types";
import { RichListItem } from "./RichListItem";
import { PositionsListItem } from "../../containers/GetCrewMembers/types/propTypes/PositionField.types";
import { StyledLeftHandContainer } from "./styled-components";

const IconContainer = styled(StyledLeftHandContainer)`
  align-self: center;
  width: 32px;
  margin-left: 8px;
`;

const PriceContainer = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: black;
  padding-right: 20px;
`;

const PositionListItemCmp = <T extends PositionsListItem>(
  { item, onClick, underlineLastItem }: BaseListItemProps<T>,
  ref: Ref<HTMLLIElement>,
) => (
  <RichListItem<T>
    ref={ref}
    item={item}
    checkboxPosition={ComponentPosition.LEFT}
    onClick={onClick}
    underlineLastItem={underlineLastItem}
    subtitleRenderer={subtitleRenderer}
    extraInfoRendererLeft={extraInfoRendererLeft}
    extraInfoRendererRight={extraInfoRendererRight}
    showCheckBox={false}
  />
);

const subtitleRenderer: ListItemRendererT<PositionsListItem> = (listItem) => (
  <>
    {listItem.address.mainText}, {listItem.address.route || listItem.address.city}
  </>
);

const extraInfoRendererLeft: ListItemRendererT<PositionsListItem> = (listItem) => {
  const iconByName =
    jobIconsMap[listItem.jobTypeIcon || DEFAULT_ICON_NAME] || jobIconsMap[DEFAULT_ICON_NAME];

  const Icon = iconByName.type;

  return (
    // @ts-ignore
    <IconContainer>
      <Icon width={"23px"} height={"23px"} color={"#3246F0"} />
    </IconContainer>
  );
};

const extraInfoRendererRight: ListItemRendererT<PositionsListItem> = (listItem) => (
  <PriceContainer>${listItem.baseWage}</PriceContainer>
);

export const PositionListItem = forwardRef(PositionListItemCmp) as <T extends PositionsListItem>(
  props: BaseListItemProps<T> & { ref?: ForwardedRef<HTMLLIElement> },
) => JSX.Element;
