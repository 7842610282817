import { Box, Group, Heading, Paragraph, Divider } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { assetUrl } from "../../../api/bluecrew/constants/index";

export const RevealedBlock = styled("div")``;

export const UnrevealedBlock = styled("div")``;

export const RevealOnHover = styled("div")`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  &:hover {
    ${RevealedBlock} {
      display: none;
    }
  }

  &:not(:hover) {
    ${UnrevealedBlock} {
      display: none;
    }
  }
`;

export const Container = styled(Box)`
  background-color: ${p("white")};
  border-radius: 4px;
  padding: 32px 75px;
  position: relative;
`;

export const CloseModal = styled(Box)`
  background: #fff url(${assetUrl}/icons/modal-close.svg) center center no-repeat;
  height: 32px;
  width: 32px;
  position: absolute;
  top: 32px;
  right: 32px;

  &:hover {
    cursor: pointer;
  }
`;

export const PageContainer = styled(Box)`
  margin: 0 auto;
  width: 100%;

  .modal-icon {
    margin: 0 auto 24px auto;
    height: 48px;
    width: 48px;
  }

  // Error Message CSS
  .error-message {
    color: red;
    font-size: 0.75rem;
    text-align: center;

    &.bottom-form-error {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(calc(100% - 0.75rem));
    }
  }

  ${Heading} {
    font-weight: 500; //TODO: remove when fixed in web-core theme
    color: ${p("slate")};
    margin-bottom: 16px;
    text-align: center;
    font-size: 20px;
  }

  ${Paragraph} {
    font-size: 16px;
    font-weight: normal;
    color: ${p("platinum")};
    text-align: center;
    margin: 0;
  }

  ${Divider} {
    margin: 1rem 0 1.5rem 0;
  }

  & > div {
    margin: 0 auto;
  }

  // TODO: BCEMPL-682: move styling to web-core
  input[type="time"] {
    background-image: url(${assetUrl}/icons/clock-input-bg.svg);
    background-repeat: no-repeat;
    background-position: left 1rem center;
    padding-left: 3rem;
  }
`;

export const TimeSelectorGroup = styled(Group)`
  display: flex;
  justify-content: center;
  width: 23rem;
  position: relative;
`;

export const InputContainer = styled(Box)`
  display: flex;
  margin: 0 1rem;

  & input {
    margin-bottom: 0.5em;
  }
`;
