import React from "react";
import { Link } from "@bluecrew/web-react-core";
import { StyledDisabledLink } from "../../SharedComponents";
import {
  DashboardPositionJob,
  DashboardPositionJobTypes,
  DashboardPositionRepeatingSchedule,
} from "../../../../api/bluecrew/types";

interface DropdownCancelButtonProps {
  rowData: DashboardPositionRepeatingSchedule | DashboardPositionJob;
  updateCancelSchedule: React.Dispatch<
    React.SetStateAction<{
      jobExternalIds: string[];
      show: boolean;
      type: DashboardPositionJobTypes;
      scheduleId: number;
      workerShifts: number;
    }>
  >;
}

const REPEATING_CANCEL_TEXT = "Cancel all jobs";
const SINGLE_SHIFT_CANCEL_TEXT = "Cancel";

export const DropdownCancelButton = ({
  rowData,
  updateCancelSchedule,
}: DropdownCancelButtonProps) => {
  const onCancelClick = () => {
    const jobExternalIds = [] as any;
    if (rowData.type === DashboardPositionJobTypes.repeatingSchedule) {
      (rowData as DashboardPositionRepeatingSchedule).jobs.forEach((req) => {
        jobExternalIds.push(req.external_id);
      });
    } else {
      jobExternalIds.push((rowData as DashboardPositionJob).external_id);
    }
    updateCancelSchedule({
      jobExternalIds,
      show: true,
      scheduleId: rowData.schedule_id,
      type: rowData.type,
      workerShifts: rowData.shifts_needed,
    });
  };

  if (rowData.needed === 0) {
    return (
      <StyledDisabledLink>
        {rowData.type === DashboardPositionJobTypes.repeatingSchedule
          ? REPEATING_CANCEL_TEXT
          : SINGLE_SHIFT_CANCEL_TEXT}
      </StyledDisabledLink>
    );
  }
  return (
    <Link
      onClick={() => {
        onCancelClick();
      }}
    >
      {rowData.type === DashboardPositionJobTypes.repeatingSchedule
        ? REPEATING_CANCEL_TEXT
        : SINGLE_SHIFT_CANCEL_TEXT}
    </Link>
  );
};
