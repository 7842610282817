import React, { useState } from "react";
import styled from "styled-components";
import { useController } from "react-hook-form";
import { Input, CurrencyIcon } from "@bluecrew/blueprint-web";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import { LabelText } from "../LabelText";
import { ExtraPayFieldProps } from "../types/propTypes/ExtraPay.types";

const formatWage = (wage: number): string => Number(wage)?.toFixed(2);
export const paySum = (a: number, b: number) => formatWage(Number(a) + Number(b));

export const ExtraPayField = ({ control, trigger, errors, baseWage }: ExtraPayFieldProps) => {
  const fieldName = "extraPay";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: (data: number) =>
        Number(maxWage) < data ? `Extra Pay shouldn't be more than $${maxWage}` : true,
    },
  });

  const maxWage: string = formatWage(Number(baseWage) * 2);
  const [userInput, setUserInput] = useState<string>(value ? value.toFixed(2) : "0");

  const handleChange = (wage: string) => {
    const [whole, decimal] = wage.split(".");

    switch (true) {
      case wage !== "" && !wage.match(/^\d{0,}(\.\d{0,2})?$/):
        break;
      case wage.length === 10:
        break;
      case decimal && decimal.length > 2:
        updateFormState(`${whole}.${decimal.substr(0, 2)}`);
        break;
      default:
        updateFormState(wage);
    }
  };

  const updateFormState = (input: string) => {
    const wage = parseFloat(input);

    setUserInput(input);
    onChange(!Number.isNaN(wage) ? wage : 0);

    validate();
  };
  return (
    <Container>
      <LabelText>Extra pay</LabelText>
      <FieldWrapper>
        <SmallText>Base pay: ${baseWage || "0.00"} +</SmallText>
        <InputContainer>
          <Input
            value={userInput}
            error={invalid}
            placeholder="0.00"
            onChange={(e) => handleChange(e)}
            onBlur={() => {
              setUserInput(Number(userInput || "0").toFixed(2));
            }}
            icon={<CurrencyIcon />}
          />
        </InputContainer>
        <SmallText>=</SmallText>
        <LargeText>
          Total hourly wage: ${paySum(Number(baseWage || "0.00"), value || "0")}
        </LargeText>
      </FieldWrapper>
      {errors?.extraPay && <ErrorText>{errors.extraPay.message}</ErrorText>}
    </Container>
  );
};

const InputContainer = styled.div`
  width: 127px;
  margin: 0 10px;
`;

const Container = styled.div`
  margin-top: ${blueprintTheme.sizing.scale800};
`;

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${blueprintTheme.sizing.scale300};
`;

const SmallText = styled.span`
  color: ${blueprintTheme.colors.primary500};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-family: Inter, sans-serif;
  display: flex;
  align-items: center;
`;

const LargeText = styled.span`
  color: ${blueprintTheme.colors.negative400};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  font-family: Inter, sans-serif;
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const ErrorText = styled.p`
  color: ${blueprintTheme.colors.negative};
  font-size: 14px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  line-height: 20px;
  margin: 0px;
`;
