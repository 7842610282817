import React from "react";
import styled from "styled-components";
import { InfoIcon } from "@bluecrew/blueprint-web/src/assets";
import { colorTheme } from "@bluecrew/blueprint-web/src/theme";
import { TooltipMessage } from "../TooltipMessage";

interface ListInformationItemProps {
  information: string;
}

export const ListInformationItem = ({ information }: ListInformationItemProps) => {
  const height = 20;
  const width = 20;
  const color = colorTheme.accent400;
  const stroke = colorTheme.white;
  return (
    <StyledListInformationItem>
      <TooltipMessage message={information}>
        <InfoIcon height={height} width={width} color={color} stroke={stroke} />
        <div>Learn more</div>
      </TooltipMessage>
    </StyledListInformationItem>
  );
};

const StyledListInformationItem = styled.div`
  display: flex;
  flex: 1;
  margin-left: 1em;
  justify-content: flex-start;
  margin-bottom: 2px;
  color: ${colorTheme.accent400};
  line-height: 20px;
  pointer-events: auto;
  svg {
    transform: translate(-4px, 0px);
  }
`;
