import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Button } from "@bluecrew/web-react-core";

export const StyledNavBar = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5rem;

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin: 0;
    list-style: none;
  }
  a {
    color: ${p("platinum")};
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 2rem 0 0;
    text-decoration: none;
    white-space: nowrap;

    &.active,
    &:hover {
      color: ${p("brandBlue")};
    }

    &.active {
      font-weight: 700;
    }
  }

  ${Button} {
    margin: auto 0;
    width: 101px;
  }

  @media only screen and (max-width: 800px) {
    li a {
      margin: 0 1.25rem 0 0;
    }
  }
`;
