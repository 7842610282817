import { PayrollTimesheetResponse, TimesheetShiftDto } from "@bluecrew/finance-service-node-client";
import {
  ShiftThresholds,
  TimesheetsCompanyInfo,
  TimesheetsJobInfo,
  TimesheetsShiftInfo,
  TimesheetsUserInfo,
} from "../../types/Shift.types";
import { convertFinanceUserShiftToLegacyUserShift } from "./UserShiftMappingUtil";

export const mapFinanceResponseToFinanceTableData = (
  response?: PayrollTimesheetResponse,
): TimesheetsShiftInfo[] => {
  return (response?.shifts ?? []).map(convertItemToTimesheetShiftInfo);
};

const convertItemToTimesheetShiftInfo = (
  timesheetShiftDto: TimesheetShiftDto,
): TimesheetsShiftInfo => {
  return {
    company: convertToCompany(timesheetShiftDto),
    job: convertToJob(timesheetShiftDto),
    shiftThresholds: convertToShiftThresholds(timesheetShiftDto),
    user: convertToUser(timesheetShiftDto),
    userShift: convertFinanceUserShiftToLegacyUserShift(timesheetShiftDto),
    userHoursId: timesheetShiftDto.usersHoursId,
  };
};

const convertToCompany = (timesheetShiftDto: TimesheetShiftDto): TimesheetsCompanyInfo => {
  return {
    externalId: timesheetShiftDto.company.externalId,
    name: timesheetShiftDto.company.name,
  };
};

const convertToJob = (timesheetShiftDto: TimesheetShiftDto): TimesheetsJobInfo => {
  const unconvertedJobInfo = timesheetShiftDto.job;
  return {
    externalId: unconvertedJobInfo.externalId,
    name: unconvertedJobInfo.name,
    start: new Date(unconvertedJobInfo.start),
    end: new Date(unconvertedJobInfo.end),
    wage: unconvertedJobInfo.wage.amount,
    timezone: unconvertedJobInfo.timezone,
    shiftIndex: unconvertedJobInfo.shiftIndex,
    address: unconvertedJobInfo.address,
    coordinates: {
      lat: unconvertedJobInfo.coordinates.latitude,
      lng: unconvertedJobInfo.coordinates.longitude,
    },
    supervisorName: unconvertedJobInfo.supervisor?.name,
  };
};

const convertToShiftThresholds = (timesheetShiftDto: TimesheetShiftDto): ShiftThresholds => {
  const unconvertedShiftThresholds = timesheetShiftDto.shiftThresholds;
  return {
    warnRadius: unconvertedShiftThresholds.warnRadius ?? null,
    blockRadius: unconvertedShiftThresholds.blockRadius ?? null,
    earlyClockinBuffer: unconvertedShiftThresholds.earlyClockinBuffer,
    lateClockoutBuffer: unconvertedShiftThresholds.lateClockoutBuffer,
    minLunchDurationHours: unconvertedShiftThresholds.minLunchDurationHours,
    expectedStart: new Date(timesheetShiftDto.job.start),
    expectedEnd: new Date(timesheetShiftDto.job.end),
  };
};

const convertToUser = (timesheetShiftDto: TimesheetShiftDto): TimesheetsUserInfo => {
  const unconvertedUser = timesheetShiftDto.user;
  return {
    externalId: unconvertedUser.externalId,
    firstName: unconvertedUser.firstName,
    lastName: unconvertedUser.lastName,
    profilePic: !unconvertedUser.profilePic ? undefined : new URL(unconvertedUser.profilePic),
    internalId: unconvertedUser.internalId,
  };
};
