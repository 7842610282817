/* eslint-disable camelcase */

// FORM VALIDATION - FUNCTIONS
// =============================================================================
// Rules and functions for validation of common form elements. Use this area to
// consolidate and share business logic for form validation. A good idea is to
// use the same rules for forms and API submission.
import * as Yup from "yup";
import { z } from "zod";
import { Moment } from "moment";
import { calculateStartToEndTimeDuration } from "../utility/index";

const phoneRegEx =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// TODO: move the ebInternalDomains constant to a shared location
const ebInternalDomains = [
  "bluecrewjobs",
  "resourcemfg",
  "select",
  "prologistix",
  "employbridge",
  "ebselect",
  "westaff",
  "remx",
  "remedystaff",
  "remedystaffing",
  "staffingsolutions",
  "futurewrxsolutions",
  "remedywfs",
];
const adminRegex = new RegExp(`^[^@\\s]+@(${ebInternalDomains.join("|")})\\.com$`);
const username = Yup.string().email("Invalid email address").required("Email address is required");
const password = Yup.string().required("Password is required");

export const loginSchema = Yup.object().shape({
  username,
  password,
});

// SCHEDULE
const schedule_nickname = Yup.string()
  .min(5, "Schedule name must be at least 5 characters long")
  .max(128, "Schedule name must be 128 characters or less")
  .required("Schedule Name is required");
const supervisor_user_id = Yup.string().required("Supervisor is required");
const work_days = Yup.array().compact().required("Work days required");
const workers_needed = Yup.number()
  .moreThan(0, "Number of crew members must be greater than 0")
  .required("Number of crew members must be greater than 0");
const end_time = Yup.string()
  .required("End time is required")
  .test("minimum-4-hour-shift", "Shift duration must be at least 4 hours", function (value = "") {
    const start = this.parent.start_time;
    const end = value;
    const duration = calculateStartToEndTimeDuration(start, end);
    return duration >= 4;
  });

export const editScheduleNameSchema = Yup.object().shape({
  schedule_nickname,
});

export const editScheduleSchema = Yup.object().shape({
  end_time,
  schedule_nickname,
  supervisor_user_id,
  work_days,
  workers_needed,
  start_time: Yup.string().required("Start time is required"),
});

export const supervisorSchema = Yup.object().shape({
  normalized_first_name: Yup.string().required("First name is required"),
  normalized_last_name: Yup.string().required("Last name is required"),
  userType: Yup.string().required("User type is required"),
  username: Yup.string().when("userType", {
    is: "NO_BLUECREW_ACCOUNT",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required(),
  }),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(phoneRegEx, "Please enter a valid Phone Number"),
});

export const addNewUserSchema = z.object({
  normalized_first_name: z.string().min(1, "First name is required"),
  normalized_last_name: z.string().min(1, "Last name is required"),
  phone: z
    .string()
    .min(1, "Phone Number is required")
    .regex(phoneRegEx, "Please enter a valid Phone Number"),
  email: z
    .string()
    .min(1, "Email address is required")
    .email("Invalid email address")
    .regex(adminRegex, "Internal users must have an Employbridge brand email address."),
  userType: z.string().min(1, "Role is required"),
});

export type NewUser = z.infer<typeof addNewUserSchema>;

// this needs to just validate that the field is populated
// @ts-ignore $FlowFixUnknownDefinition
export const validateDepartment = (department) => {
  if (department === undefined || department === null) {
    return "Department is required";
  }
  return undefined;
};

type DateRange = {
  startDate: Moment | null | undefined;
  endDate: Moment | null | undefined;
};

export const validateDateRange = (dates: DateRange): string | null | undefined => {
  let error;
  if (!(dates.startDate && dates.endDate)) {
    error = "A start date and end date are required";
  }
  return error;
};
