import { KIND } from "baseui/tag";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getParentCompanyRelations } from "../../../api/bluecrew/company";
import { showToastAction } from "../../../redux/actions/app";
import { getCompanyId } from "../../../redux/selectors/company";
import { useAppSelector } from "../../../redux";

export const useRetrieveLinkedCompaniesQuery = () => {
  const dispatch = useDispatch();
  const companyId = useAppSelector(getCompanyId);
  const companyIdAsANumber = parseInt(companyId.toString(), 10);

  return useQuery(["linkedCompanies"], () => getParentCompanyRelations(companyIdAsANumber), {
    onError: () => {
      dispatch(showToastAction("Failed to retrieve linked companies!", KIND.negative));
    },
    select: (linkedCompaniesData) => {
      const parentCompanyIndex = linkedCompaniesData.findIndex(
        (companyData) =>
          companyData.parent_company_id === null ||
          companyData.parent_company_id === companyData.id,
      );
      const parentCompany = linkedCompaniesData[parentCompanyIndex];
      const childCompanies = linkedCompaniesData.filter((_, i) => i !== parentCompanyIndex);
      return { parentCompany, childCompanies };
    },
    enabled: false,
  });
};
