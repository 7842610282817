import React from "react";
import { getTreatmentValue } from "../../redux/selectors/splitio";
import { TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME } from "../../../shared/constants";
import { NavLink } from "./NavLink";
import { webV1Url } from "../../api/bluecrew/constants";
import { useAppSelector } from "../../redux";

export const TimesheetsNavItem = () => {
  const showTimesheetsInReact = useAppSelector((state) =>
    getTreatmentValue(state, TIMETRACKING_INITIAL_MIGRATION_SPLIT_NAME),
  );

  return (
    <li>
      {showTimesheetsInReact === "on" ? (
        <NavLink to="/payroll">Timesheets</NavLink>
      ) : (
        <a href={`${webV1Url}/app.html#!/payroll/company`}>Timesheets</a>
      )}
    </li>
  );
};
