import { parseISO } from "date-fns";

export const checkIfApplicationJobs = (scheduleRequest) =>
  scheduleRequest.filter((job) => job.is_application === 1).length !== 0;

/**
 * Javascript will treat strings as Dates but `date-fns` will not.
 */
export const convergeDate = (arg: Date | string): Date =>
  typeof arg === "string" ? parseISO(arg) : arg;
