// AUTH - REDUCER
// =============================================================================

import { handleActions } from "redux-actions";
import get from "lodash/get";
import authActions from "../actions/auth";
import sessionActions from "../actions/session";

// ACTION TYPES
import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";
import { queryClient } from "../../queryClient";
import { createAuthCookies } from "../../utility";

const handlerMap = {
  [authActions.token.request]: (state: AuthState) => handleRequest("token", state),
  [authActions.login.request]: (state: AuthState) => handleRequest("login", state),
  [authActions.login.success]: (state: AuthState, action) => {
    // FIXME this is terribly hacky, this needs to go away when we have a proper auth
    const v1token = get(action, "payload.tokens.v1");
    const v2token = get(action, "payload.tokens.v2");
    if (v1token && v2token) {
      // saving the tokens in cookies
      // TODO: put this side effect in a more predictable place, e.g. saga
      // NOTE: this function is called in multiple places.

      createAuthCookies({
        tokens: { v1: v1token, v2: v2token },
        expireInDays: 60,
      });
    } else {
      const report = (msg) => reportError({ error: new Error(msg) });
      if (!v1token && !v2token) {
        report("Missing V1 and V2 tokens from auth response");
      } else if (!v2token) {
        report("Missing V2 token from auth response");
      } else {
        report("Missing V1 token from auth response");
      }
    }

    return handleSuccess("login", state, action);
  },
  [authActions.login.failure]: (state: AuthState, action) => {
    // redirecting the user to '/' when the login is successful, to avoid use cases where the user is stuck in a page in a weird state (e.g. '/error')
    window.location.href = "/";

    return handleFailure("login", state, action);
  },
  [authActions.login.destroy]: (state: AuthState) => {
    queryClient.clear();

    return {
      ...state,
      login: {
        ...state.login,
        success: null,
      },
    };
  },
  [sessionActions.resume.request]: (state: AuthState) => handleRequest("resume", state),
  [sessionActions.resume.success]: (state: AuthState, action) =>
    handleSuccess("resume", state, action),
  [sessionActions.resume.failure]: (state: AuthState, action) =>
    handleFailure("resume", state, action),
};

export const initialState = {
  login: { ...initialEndpointState },
  resume: { ...initialEndpointState },
  token: undefined, // Added this to make TypeScript happy
};

export type AuthState = typeof initialState & {
  token: undefined; // Added this to make TypeScript happy
};

export default handleActions<AuthState>(handlerMap, initialState);
