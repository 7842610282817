import React, { useCallback } from "react";
import { EmptyHeaderRowName, FlexCenterRow, SeeMoreRequestsContainer } from "../SharedComponents";
import { SeeMoreRequestsButton } from "../SeeMoreRequestsButton";

interface IndividualShiftsHeaderProps {
  isIndividualShiftRowsOpen: boolean;
  updateIsIndividualShiftRowsOpen: (value: boolean) => void;
  name: string;
}

export const IndividualShiftsHeader = ({
  isIndividualShiftRowsOpen,
  updateIsIndividualShiftRowsOpen,
  name,
}: IndividualShiftsHeaderProps) => (
  <tr
    className={"empty-header-row"}
    data-pendo-key="DashboardSchedulesAndIndividualShiftsTableContainerIndividualShiftsHeaderRow"
    onClick={useCallback(() => {
      updateIsIndividualShiftRowsOpen(!isIndividualShiftRowsOpen);
    }, [isIndividualShiftRowsOpen])}
  >
    <td key={"EmptyHeaderRow-ScheduleName"} className={"schedule-name"}>
      <FlexCenterRow data-pendo-key="DashboardSchedulesAndIndividualShiftsTableContainerIndividualShiftsHeaderRowNameAndDropdown">
        <EmptyHeaderRowName data-pendo-key="DashboardSchedulesAndIndividualShiftsTableContainerIndividualShiftsHeaderRowName">
          {name}
        </EmptyHeaderRowName>
        <SeeMoreRequestsContainer data-pendo-key="DashboardSchedulesAndIndividualShiftsTableContainerIndividualShiftsHeaderRowDropdown">
          <SeeMoreRequestsButton isExpanded={isIndividualShiftRowsOpen} />
        </SeeMoreRequestsContainer>
      </FlexCenterRow>
    </td>
    <td key={"EmptyHeaderRow-Rest"} colSpan={11}></td>
  </tr>
);
