import { Weekdays, DaysOfWeek } from "@bluecrew/blueprint-web";
import React from "react";
import { RowContainer } from "../SharedComponents";

interface WorkDaysCellProps {
  work_days: string[];
}

export const WorkDaysCell = ({ work_days }: WorkDaysCellProps) => (
  <RowContainer data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnDays">
    <Weekdays editable={false} selected={work_days as DaysOfWeek} size="compact" />
  </RowContainer>
);
