/**
 * Remove any any non-digit character from the string.
 * Ex: 123abcd4$ becomes 1234
 */
export function removeNonDigits(inputValue: string): string {
  return inputValue.replace(/[^\d]/g, "");
}

/**
 * Run a given code block (aka callback) and return it's result.
 * Useful if you want to use chained if else statements or a switch statement to assign a const value; it means you don't have to use let :)
 * 
 * @example
 *  const successTitle: string = run(() => {
      if (successes.length > 0 && failures.length > 0) {
        return "Partial Success";
      }
      if (failures.length === 0) {
        return "Success";
      }
      if (successes.length === 0) {
        return "Failure";
      }
      return "";
    });
 */
export function run<T>(callback: () => T): T {
  return callback();
}
