import React from "react";
import {
  DashboardPositionJob,
  DashboardPositionJobTypes,
  DashboardPositionRepeatingSchedule,
  ScheduleAndIndividualShift,
} from "../../../../api/bluecrew/types";
import { AbilityContext } from "../../../PermissionsContext";
import { RequestActionsButtonsCell } from "./RequestActionsButtonsCell";
import { ScheduleActionsButtonCell } from "./ScheduleActionsButtonCell";

interface ActionButtonsCellProps {
  rowData: ScheduleAndIndividualShift;
  canExpand: boolean;
  updateCancelSchedule: React.Dispatch<
    React.SetStateAction<{
      jobExternalIds: string[];
      show: boolean;
      type: DashboardPositionJobTypes;
      scheduleId: any;
      workerShifts: number;
    }>
  >;
}

export const DropdownActionsCell = ({ rowData, updateCancelSchedule }: ActionButtonsCellProps) => {
  const ability = React.useContext(AbilityContext);
  const canPostCompanyJobs = ability.can("post", "company_jobs");
  const canCreateJob = ability.can("create", "job");
  const postJobsPermission = canPostCompanyJobs && canCreateJob;
  if (
    rowData.type === DashboardPositionJobTypes.repeatingSchedule ||
    rowData.type === DashboardPositionJobTypes.bulkSingleShiftSubRow ||
    rowData.type === DashboardPositionJobTypes.singleShift
  ) {
    return (
      <ScheduleActionsButtonCell
        updateCancelSchedule={updateCancelSchedule}
        postJobsPermission={postJobsPermission}
        rowData={rowData as DashboardPositionRepeatingSchedule | DashboardPositionJob}
      />
    );
  }
  if (rowData.type === DashboardPositionJobTypes.repeatingScheduleSubRow) {
    return (
      <RequestActionsButtonsCell
        updateCancelSchedule={updateCancelSchedule}
        postJobsPermission={postJobsPermission}
        rowData={rowData as DashboardPositionJob}
      />
    );
  }
  return null;
};
