/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InternalUser } from "../ManageUsersTable/InternalUser";
import { UserTypeMapping } from "../constants";

export type ManageUsersModalState = {
  showAssignCompaniesModal: boolean;
  selectedUser: InternalUser | undefined;
  selectedUserRole: string;
  showAddUserModal: boolean;
  clonedUser: InternalUser | undefined;
  showBulkAssignCompaniesModal: boolean;
  selectedUsers: InternalUser[];
  tableLoading: boolean;
};

const initialState: ManageUsersModalState = {
  showAssignCompaniesModal: false,
  selectedUser: undefined,
  selectedUserRole: "",
  showAddUserModal: false,
  clonedUser: undefined,
  showBulkAssignCompaniesModal: false,
  selectedUsers: [],
  tableLoading: true,
};

export const manageUsersModalSlice = createSlice({
  name: "manageUsersModal",
  initialState,
  reducers: {
    setShowAssignCompaniesModal: (state, action: PayloadAction<boolean>) => {
      state.showAssignCompaniesModal = action.payload;
    },
    setShowAddUserModal: (state, action: PayloadAction<boolean>) => {
      state.showAddUserModal = action.payload;
    },
    setShowBulkAssignCompaniesModal: (state, action: PayloadAction<boolean>) => {
      state.showBulkAssignCompaniesModal = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<InternalUser | undefined>) => {
      state.selectedUser = action.payload;
    },
    setSelectedUserRole: (state, action: PayloadAction<string>) => {
      state.selectedUserRole = action.payload;
    },
    setClonedUser: (state, action: PayloadAction<InternalUser | undefined>) => {
      state.clonedUser = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<InternalUser[]>) => {
      state.selectedUsers = action.payload;
    },
    addToSelectedUsers: (state, action: PayloadAction<InternalUser>) => {
      state.selectedUsers = [...state.selectedUsers, action.payload];
    },
    removeFromSelectedUsers: (state, action: PayloadAction<number>) => {
      state.selectedUsers = state.selectedUsers.filter((user) => user.userId !== action.payload);
    },
    resetAssignCompaniesModal: (state) => {
      state.showAssignCompaniesModal = false;
      state.selectedUser = undefined;
      state.clonedUser = undefined;
      state.selectedUserRole = "";
    },
    showClonedUserModal: (state, action: PayloadAction<InternalUser>) => {
      state.clonedUser = action.payload;
      state.selectedUserRole = UserTypeMapping[action.payload.userRole];
      state.showAddUserModal = true;
    },
    setTableLoading: (state, action: PayloadAction<boolean>) => {
      state.tableLoading = action.payload;
    },
  },
});

export const {
  setShowAssignCompaniesModal,
  setSelectedUser,
  setSelectedUserRole,
  setShowAddUserModal,
  setClonedUser,
  setShowBulkAssignCompaniesModal,
  setSelectedUsers,
  addToSelectedUsers,
  removeFromSelectedUsers,
  resetAssignCompaniesModal,
  showClonedUserModal,
  setTableLoading,
} = manageUsersModalSlice.actions;

export default manageUsersModalSlice.reducer;
