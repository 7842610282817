import styled from "styled-components";
import { Button } from "../../../../components/Button";
import { HoverStyledButton } from "../../styledComponents";
import { IconCalendar } from "../../../../components/IconCalendar";

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 36px;
  padding-top: 0px;
  top: 0px;
  background-color: ${({ theme }) => theme.colorTokens.white};
  padding-bottom: 72px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderText = styled.div`
  font-family: ${({ theme }) => theme.typography.font750.fontFamily};
  font-size: ${({ theme }) => theme.typography.font750.fontSize};
  font-weight: ${({ theme }) => theme.typography.font750.fontWeight};
  line-height: ${({ theme }) => theme.typography.font750.lineHeight};
  font-style: normal;
  color: ${({ theme }) => theme.colorTokens.gray800};
  padding-right: 1rem;
`;

export const SubHeaderText = styled.div`
  font-family: ${({ theme }) => theme.typography.font750.fontFamily};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-style: bold;
  color: ${({ theme }) => theme.colorTokens.gray500};

  height: 20px;

  flex: none;
  flex-grow: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0;
  button:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ChevronWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-direction: row;
  button:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledInvoiceButton = styled(Button)`
  width: 113px;
  height: 36px;

  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const StyledRefreshButton = styled(Button)`
  width: 77px;
  height: 36px;
  justify-content: center;

  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const StyledWeekChangeButton = styled(HoverStyledButton)`
  width: 48px;
  height: 36px;
  background-color: white;
  color: ${({ theme }) => theme.colors.accent};
  border: 0;
`;

export const StyledCalendarChangeButton = styled(IconCalendar)`
  .p-button {
    border: 0;
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
  }

  :hover .p-button {
    background: ${({ theme }) => theme.colorTokens.blue850};
    border: 0;
    border-radius: 8px;
  }

  svg {
    height: 20px;
    width: 20px;
    filter: brightness(0) saturate(100%) invert(25%) sepia(82%) saturate(3407%) hue-rotate(231deg)
      brightness(91%) contrast(107%);
  }
  svg:hover {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(75deg) brightness(103%) contrast(104%);
  }
`;

export const StyledAddShiftButton = styled(HoverStyledButton)`
  width: 128px;
  height: 36px;
  background: ${({ theme }) => theme.colors.accent};

  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    color: white;
  }
`;
