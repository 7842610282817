import React from "react";
import PropTypes from "prop-types";
import { Backdrop, Box, Button, Overlay, Portal } from "@bluecrew/web-react-core";
import styled from "styled-components";
import { palette as p } from "styled-tools";
// @ts-ignore
import closeIcon from "../assets/icons/close-icon.svg";
// @ts-ignore
import tooltipTriggerIcon from "../assets/icons/help-tooltip.svg";
// @ts-ignore
import tooltipIcon from "../assets/icons/tooltip-icon.svg";

const StyledOverlay = styled(Overlay)`
  padding: 0;
  width: 42rem;
`;

const StyledContainer = styled(Box)`
  background: ${p("white")};
  border-radius: 4px;
  padding: 32px 32px 64px 32px;
  position: relative;

  .close-icon {
    display: flex;
    justify-content: flex-end;

    img:hover {
      cursor: pointer;
    }
  }

  .confirm-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description {
    color: ${p("platinum")};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 31px;
    text-align: center;
    width: 492px;
  }

  .heading {
    color: ${p("mandarin")};
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.0005em;
    margin-bottom: 16px;
    margin-top: 24px;
  }
`;

const StyledTrigger = styled.div`
  display: flex;
  img:hover {
    cursor: pointer;
  }
}`;

export class TooltipOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => {
    this.setState({
      showModal:
        // @ts-ignore
        !this.state.showModal,
    });
  };

  render() {
    const {
      // @ts-ignore
      showModal,
    } =
      // @ts-ignore
      this.state;
    const {
      // @ts-ignore
      description,
      // @ts-ignore
      heading,
    } = this.props;

    return (
      <React.Fragment>
        <StyledTrigger>
          <img
            className="trigger-icon"
            onClick={this.toggleModal}
            src={tooltipTriggerIcon}
            alt="help icon"
          />
        </StyledTrigger>
        <Portal>
          <Backdrop fade visible={showModal} onClick={this.toggleModal} />
          <StyledOverlay fade visible={showModal}>
            <StyledContainer>
              <div>
                <div className="close-icon">
                  <img onClick={this.toggleModal} src={closeIcon} alt="close-tooltip" />
                </div>
                <div className="content">
                  <img src={tooltipIcon} alt="" />
                  <span className="heading">{`Tip: ${heading}`}</span>
                  <p className="description">{description}</p>
                  <Button onClick={this.toggleModal} palette="secondary">
                    <span className="confirm-text">Okay, got it</span>
                  </Button>
                </div>
              </div>
            </StyledContainer>
          </StyledOverlay>
        </Portal>
      </React.Fragment>
    );
  }
}

// @ts-ignore
TooltipOverlay.propTypes = {
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};
