import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";
import { Portal, Overlay } from "@bluecrew/web-react-core";

const SpinnerOverlay = styled(Overlay)`
  background-color: #fff;
  box-shadow: none;
  margin: 0;
  height: 0;
  padding: 0;
  width: 0;
`;

const SpinnerWithOverlay = () => (
  <Portal>
    <SpinnerOverlay fade visible>
      <Spinner />
    </SpinnerOverlay>
  </Portal>
);

export default SpinnerWithOverlay;
