import styled from "styled-components";
import { InputNumber as PRInputNumber, InputNumberProps } from "primereact/inputnumber";
import React from "react";

const InputNumberWrapper = (props: InputNumberProps) => (
  <PRInputNumber className={props.className} {...props} />
);

export const InputNumber = styled(InputNumberWrapper)`
  .p-inputtext {
    :enabled:hover {
      border-color: ${({ theme }) => theme.colorTokens.blue850};
    }

    :enabled:focus {
      box-shadow: none;
      border-color: ${({ theme }) => theme.colorTokens.blue100};
    }
  }
`;
