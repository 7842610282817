import React, { useState } from "react";
import { useThemedStyletron } from "@bluecrew/blueprint-web";
import { useWatch } from "react-hook-form";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { ExistingScheduleInfo } from "./ExistingScheduleInfo";
import { isNewSchedule, Schedule } from "../types/propTypes/ScheduleField.types";
import { ScheduleFieldSetProps } from "../types/propTypes/ScheduleFieldSet.types";
import { ScheduleOptions } from "./ScheduleOptions";
import { ScheduleTabSection } from "./ScheduleTabSection";
import { SupervisorField } from "./SupervisorField";
import { DepartmentField } from "./DepartmentField";
import { Divider } from "../../../components/Divider";

export const ScheduleFieldSet = ({
  control,
  trigger,
  schedules,
  supervisors,
  departments,
  scheduleMapper,
  notificationManagers,
  errors,
  isPositionSelected,
  baseWage,
  isDepartmentsEnabled,
  currentFormValues,
  reset,
  shiftsCount: shiftCounts,
  onGetScheduleInfo,
  isBulkSingleShiftEnabled,
  isBulkSingleShiftMax50DayEnabled,
  disabled,
}: ScheduleFieldSetProps) => {
  const [existingScheduleInfo, setExistingScheduleInfo] = useState<any>(null);
  const [supervisorList, setSupervisorList] = useState<any>(supervisors);
  const schedule: Schedule | undefined = useWatch({
    control,
    name: "schedule",
  });

  const [, theme] = useThemedStyletron();

  const getSchedule = async (id: string | number) => {
    // If selected schedule is new, don't make a request to get schedule info
    if (id === -1) return;
    const response = await onGetScheduleInfo?.(id);
    // Get existing schedule info
    // If request will be errored just old UX will be used:
    // Only name of the schedule will be shown
    setExistingScheduleInfo(response);
  };

  const createSupervisor = (supervisor) => {
    const newSupervisors =
      supervisorList === undefined ? [supervisor] : supervisorList.concat(supervisor);
    setSupervisorList(newSupervisors);
  };
  return (
    <div data-pendo-key="ScheduleFieldSetContainer">
      <ScheduleTabSection
        disabled={disabled}
        setExistingScheduleInfo={setExistingScheduleInfo}
        onGetScheduleInfo={getSchedule}
        control={control}
        trigger={trigger}
        errors={errors}
        shiftsCount={shiftCounts}
        isNewScheduleSelected={isNewSchedule(schedule)}
        isPositionSelected={isPositionSelected}
        schedules={schedules}
        scheduleMapper={scheduleMapper}
        isBulkSingleShiftEnabled={isBulkSingleShiftEnabled}
        isBulkSingleShiftMax50DayEnabled={isBulkSingleShiftMax50DayEnabled}
        currentFormValues={currentFormValues}
      />
      {existingScheduleInfo && schedule && !isNewSchedule(schedule) ? (
        <ExistingScheduleInfo
          baseWage={baseWage}
          existingScheduleInfo={existingScheduleInfo}
          isDepartmentsEnabled={isDepartmentsEnabled}
          notificationManagers={notificationManagers}
          schedule={schedule}
          control={control}
        />
      ) : null}
      {isNewSchedule(schedule) && (
        <div style={{ marginLeft: theme.sizing.scale1000 }}>
          <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
          <div style={{ display: "flex" }}>
            <SupervisorField
              control={control}
              trigger={trigger}
              supervisors={supervisorList}
              createSupervisor={createSupervisor}
              errors={errors}
            />
            {isDepartmentsEnabled && (
              <DepartmentField control={control} trigger={trigger} departments={departments} />
            )}
          </div>
          <ScheduleOptions
            control={control}
            trigger={trigger}
            errors={errors}
            notificationManagers={notificationManagers!}
            baseWage={baseWage}
            currentFormValues={currentFormValues}
            reset={reset}
          />
        </div>
      )}
      <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
      <Divider />
    </div>
  );
};
