import * as Finance from "@bluecrew/finance-service-node-client";
import {
  BreakTimestamps,
  ShiftBreak,
  ShiftTimestamp,
  SupportAction,
  UserShift, UserShiftLastUpdateBy,
  WorkplaceAction
} from "../../types/Shift.types";
import {TimestampAttributeName} from "../../shiftUtils";
import {UserTimestamp} from "../../../../api/bluecrew/payroll";
import {
  convertTimestampAttributeName,
  convertShiftComponent,
  convertTimestampType,
  convertWorkplaceAction, convertSupportAction, convertUserType, convertPayrollHoursStatus, convertPayrollStatusReason
} from "./EnumConverters";

export const convertFinanceUserShiftToLegacyUserShift = (timesheetShiftDto : Finance.TimesheetShiftDto) : UserShift => {
  const unconvertedShift = timesheetShiftDto.userShift
  return {
    start: convertFinanceShiftTimestamp(unconvertedShift.start),
    end: convertFinanceShiftTimestamp(unconvertedShift.end),
    break: convertShiftBreak(unconvertedShift.break),
    allowedWorkplaceActions: convertWorkplaceActions(unconvertedShift.allowedWorkplaceActions),
    allowedSupportActions: convertSupportActions(unconvertedShift.allowedSupportActions),
    isAutoApproved: unconvertedShift.isAutoApproved,
    lastUpdateBy: convertUserShiftLastUpdatedBy(unconvertedShift.lastUpdateBy),
    status: convertPayrollHoursStatus(unconvertedShift.status),
    statusReason: convertPayrollStatusReason(unconvertedShift.statusReason)
  }
}

const convertFinanceShiftTimestamp = (shiftTimestampDto :Finance.ShiftTimestampDto) : ShiftTimestamp => {
  return {
    type: convertShiftComponent(shiftTimestampDto.type),
    attributes: convertAttributeList(shiftTimestampDto.attributes),
    time: new Date(shiftTimestampDto.time),
    isDisputedTimeMissing: shiftTimestampDto.isDisputedTimeMissing,
    userTimestamp: shiftTimestampDto.userTimestamp ? convertToUserTimestamp(shiftTimestampDto.userTimestamp!!) : undefined,
    timeInDispute: shiftTimestampDto.timeInDispute ?? undefined
  }
}

const convertAttributeList = (attributes : Finance.TimestampAttributeName[] | undefined) : TimestampAttributeName[] => {
  return (attributes ?? []).map(convertTimestampAttributeName)
}

const convertToUserTimestamp = (unconvertedTimestamp : Finance.TimestampDto) : UserTimestamp => {
  return {
    id: unconvertedTimestamp.id,
    time: new Date(unconvertedTimestamp.time),
    type: convertTimestampType(unconvertedTimestamp.type!!),
    distance: unconvertedTimestamp.distance ?? null,
    latitude: unconvertedTimestamp.latitude ?? null,
    longitude: unconvertedTimestamp.longitude ?? null,
    isUserEdited: unconvertedTimestamp.isEdited!!,
    noPhoneClock: unconvertedTimestamp.isNoPhoneClock!!,
    createdAt: unconvertedTimestamp.createdAt,
    updatedAt: unconvertedTimestamp.updatedAt
  }
}

const convertShiftBreak = (shiftBreakDto : Finance.ShiftBreakDto) : ShiftBreak => {
  return {
    type : convertShiftComponent(shiftBreakDto.type!!),
    attributes: convertAttributeList(shiftBreakDto.attributes),
    durationMinutes: shiftBreakDto.durationMinutes!!,
    breakTimestamps: convertBreakTimestamps(shiftBreakDto.breakTimestamps),
    isDisputedDurationMissing: shiftBreakDto.isDisputedDurationMissing,
    durationInDispute: shiftBreakDto.durationInDispute ?? undefined
  }
}

const convertBreakTimestamps = (financeBreakTimestamps : Finance.BreakDto[] | undefined) : BreakTimestamps[] => {
  return (financeBreakTimestamps ?? []).map((breakTimestampPairs) => {
    return {
      start: convertToUserTimestamp(breakTimestampPairs.clockIn!!)!!,
      end: convertToUserTimestamp(breakTimestampPairs.clockOut!!)!!
    }
  })
}

const convertWorkplaceActions = (financeWorkplaceActions : Finance.WorkplaceAction[]) : WorkplaceAction[] => {
  return financeWorkplaceActions.map(convertWorkplaceAction);
}

const convertSupportActions = (financeSupportActions : Finance.SupportAction[]) : SupportAction[] => {
  return financeSupportActions.map(convertSupportAction);
}

const convertUserShiftLastUpdatedBy = (financeUserShiftLastUpdatedBy : Finance.UserShiftLastUpdateByDto | undefined) : UserShiftLastUpdateBy | undefined => {
  return !financeUserShiftLastUpdatedBy ? undefined : {
    id: financeUserShiftLastUpdatedBy.id,
    name: financeUserShiftLastUpdatedBy.name,
    userType: convertUserType(financeUserShiftLastUpdatedBy.userType),
    updatedAt: financeUserShiftLastUpdatedBy.updatedAt
  }
}
