import React from "react";
import { useDispatch } from "react-redux";
import { ActionsContainer, DuplicateUserButton } from "./styledComponents";
import { InternalUser } from "./InternalUser";
import { UserType } from "../../../api/bluecrew/types";
import { showClonedUserModal } from "../slices/manageUsersModalSlice";

type TableActionsProps = {
  user: InternalUser;
};

export const TableActions = ({ user }: TableActionsProps) => {
  const dispatch = useDispatch();

  return (
    <ActionsContainer>
      {user.userRole !== UserType.SUPER_ADMIN && (
        <DuplicateUserButton
          onClick={() => dispatch(showClonedUserModal(user))}
          className={"action"}
        ></DuplicateUserButton>
      )}
      {/* <DeactivateUserButton className={"action"}></DeactivateUserButton> */}
    </ActionsContainer>
  );
};
