/* IMPORTANT MESSAGE TO ENG. ABOUT TO EDIT THIS FILE:
 Don't add anything to this file unless you have to, which means that only do it
 if you are editing one of these functions, or adding a new function into somewhere where
 these are being used.
 Instead, prefer using this structure: https://redux.js.org/tutorials/essentials/part-2-app-structure
*/

import cloneDeep from "lodash/cloneDeep";

// JOB - REDUCER
// =============================================================================

import { handleActions } from "redux-actions";

import positionActions from "../actions/position";
import {
  DashboardPositionHeaderDetail,
  ListedPositionResponse,
  ScheduleStatus,
} from "../../api/bluecrew/types";

// ACTION TYPES
import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

const handlerMap = {
  [positionActions.positions.request]: (state: PositionState) => handleRequest("positions", state),
  [positionActions.positions.success]: (state: PositionState, action) =>
    handleSuccess("positions", state, action),
  [positionActions.positions.failure]: (state: PositionState, action) =>
    handleFailure("positions", state, action),

  [positionActions.readPosition.request]: (state: PositionState) =>
    handleRequest("readPosition", state),
  [positionActions.readPosition.success]: (state: PositionState, action) =>
    handleSuccess("readPosition", state, action),
  [positionActions.readPosition.failure]: (state: PositionState, action) =>
    handleFailure("readPosition", state, action),
  [positionActions.clearReadPosition]: (state: PositionState) => ({
    ...state,
    readPosition: { ...initialEndpointState },
  }),

  [positionActions.updateSinglePropertySchedule]: (state: PositionState, action) => {
    const tempState = cloneDeep(state);
    // @ts-ignore
    let tempSchedules = tempState.positions.success.positions.find(
      (pos) => pos.id === action.payload.positionId,
    ).schedules;
    if (action.payload.payload.active === ScheduleStatus.ARCHIVED) {
      tempSchedules = tempSchedules.filter((item) => item.id !== action.payload.scheduleId);
    } else if (
      action.payload.payload.active === ScheduleStatus.ACTIVE ||
      action.payload.payload.active === ScheduleStatus.PINNED
    ) {
      tempSchedules.forEach((item) => {
        if (item.id === action.payload.scheduleId) {
          item.active = action.payload.payload.active;
        }
      });
    }
    // @ts-ignore
    tempState.positions.success.positions.find(
      (pos) => pos.id === action.payload.positionId,
    ).schedules = tempSchedules;
    return tempState;
  },

  [positionActions.getPositionsHeaderDetails.request]: (state: PositionState) =>
    handleRequest("getPositionsHeaderDetails", state),
  [positionActions.getPositionsHeaderDetails.success]: (state: PositionState, action) =>
    handleSuccess("getPositionsHeaderDetails", state, action),
  [positionActions.getPositionsHeaderDetails.failure]: (state: PositionState, action) =>
    handleFailure("getPositionsHeaderDetails", state, action),
};

const initialState = {
  positions: { ...initialEndpointState },
  readPosition: { ...initialEndpointState },
  updateSinglePropertySchedule: { ...initialEndpointState },
  updateSinglePropertyDashboardPositionRefill: { ...initialEndpointState },
  updateDashboardPositionDeletion: { ...initialEndpointState },
  getPositionsHeaderDetails: { ...initialEndpointState },
};

type InitialState = typeof initialState;

export type PositionState = InitialState & {
  positions: InitialState["positions"] & {
    success: ListedPositionResponse;
  };
  getPositionsHeaderDetails: InitialState["getPositionsHeaderDetails"] & {
    success: {
      positions: Array<DashboardPositionHeaderDetail>;
    };
  };
};

// @ts-ignore PositionState and InitialState do not match, but this correctly overrides the type
export default handleActions<PositionState>(handlerMap, initialState);
