import { selectCrewGroups, selectCrewMembers } from "../../../../api/bluecrew/hooks/crewMembers";

export type CrewMember = ReturnType<typeof selectCrewMembers>[0];

export const isCrewMember = (item: any): item is CrewMember =>
  typeof item === "object" && "shifts" in item;

export type CrewMembers = Array<CrewMember>;

export type CrewGroup = ReturnType<typeof selectCrewGroups>[0];

export const isCrewGroup = (item: any): item is CrewGroup =>
  typeof item === "object" && "members" in item;

export type CrewGroups = Array<CrewGroup>;

export interface ChooseCrewModalProps {
  crewMembers: CrewMembers;
  crewGroups?: CrewGroups;
  onSelectionChange?: (selected: Array<Omit<CrewMember, "externalId"> | CrewGroup>) => void;
  onDoneClick?: () => void;
  isOpen: boolean;
  onClose?: () => void;
  selected?: CrewMembers | CrewGroups;
  hideCrewGroups?: boolean;
}
