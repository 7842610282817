import React from "react";

interface SortIconProps {
  isAscending: boolean;
  canSort: boolean;
}

export const SortIcon = ({ isAscending, canSort }: SortIconProps) => {
  return canSort ? (
    <svg
      width="12"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={isAscending ? "rotate(180)" : ""}
    >
      <rect x="3" y="4" width="10" height="2" rx="1" fill="#3246F0" />
      <rect width="16" height="2" rx="1" fill="#3246F0" />
      <rect x="6" y="8" width="4" height="2" rx="1" fill="#3246F0" />
    </svg>
  ) : (
    <svg width="0" height="10"></svg>
  );
};
