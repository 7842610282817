import React, { useEffect, useRef, useState } from "react";

/**
 * A very simple component that allows you to expand a section, with an animation.
 * Provides no styling, just the expand functionality.
 */

interface CollapseProps {
  isOpen: boolean;
  animDurationMS?: number; // Time in MS of animation
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const FALLBACK_TIME_MS = 400;

export const Collapse = ({ isOpen, animDurationMS, children, style }: CollapseProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.clientHeight);
    }
  }, [children]);

  return (
    <div
      style={{
        height: isOpen ? contentHeight : 0,
        transition: `height ${animDurationMS ?? FALLBACK_TIME_MS}ms`,
        overflow: "hidden",
        ...style,
      }}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
};
