// ROUTES
// =============================================================================
// Top level routes for the application.
import React from "react";
import { Route, Routes } from "react-router-dom";
import { SchedulesRoutes } from "./SchedulesRoutes";
import SimulateErrors from "../containers/SimulateErrors";
import { PositionsRoutes } from "./PositionsRoutes";
import { GetCrewMembersRoutes } from "./GetCrewMembersRoutes";
import { SettingsRoutes } from "./SettingsRoutes";
import ErrorRedirect from "../components/ErrorRedirect";
import { PayrollRoutes } from "./PayrollRoutes";
import { SecureAssetsRoutes } from "./SecureAssetsRoutes";
import { ManageUsers } from "../containers/ManageUsers/ManageUsers";
import { SendMessage } from "../containers/SendMessage";

export const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/*" element={<PositionsRoutes />} />
      <Route path="positions/*" element={<PositionsRoutes />} />
      <Route path="jobs/*" element={<GetCrewMembersRoutes />} />
      <Route path="schedules/*" element={<SchedulesRoutes />} />
      <Route path="settings/*" element={<SettingsRoutes />} />
      <Route path="error/*" element={<ErrorRedirect />} />
      <Route path="payroll/*" element={<PayrollRoutes />} />
      <Route path="secureassets/*" element={<SecureAssetsRoutes />} />
      <Route path="manageusers/*" element={<ManageUsers />} />
      {import.meta.env.VITE_ALLOW_UNSAFE_ERROR_SIMULATION === "true" && (
        <Route path="simulate-errors/" element={<SimulateErrors />} />
      )}
      <Route path="/send-message" element={<SendMessage />} />
    </Routes>
  );
};
