import React from "react";
import { ReasonCellReasonTextWrapper, ReasonCellContentWrapper } from "./styledComponents";
import { PayrollStatusReason, TimesheetSupportUserTypes } from "../../types/Payroll.types";
import { getReasonTextFromCode } from "../../shiftUtils";
import { UserShiftLastUpdateBy } from "../../types/Shift.types";

export type ReasonColumnCellProps = {
  reason: PayrollStatusReason;
  lastUpdateBy?: UserShiftLastUpdateBy;
};

function identifyUser(lastUpdateBy: UserShiftLastUpdateBy): string {
  return TimesheetSupportUserTypes.includes(lastUpdateBy.userType)
    ? "Bluecrew support"
    : lastUpdateBy.name;
}

export const ReasonColumnCell = ({ reason, lastUpdateBy }: ReasonColumnCellProps) => {
  const userText = lastUpdateBy ? identifyUser(lastUpdateBy) : "Unknown User";

  return (
    <>
      <ReasonCellContentWrapper>
        <ReasonCellReasonTextWrapper>
          {`${userText}: ${getReasonTextFromCode(reason)}`}
        </ReasonCellReasonTextWrapper>
      </ReasonCellContentWrapper>
    </>
  );
};
