import styled from "styled-components";
import { Block, InlineBlock, Overlay } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import { CONTAINER_MAX_WIDTH } from "../../../shared/constants";

// not sure if we should add this to global styles...
const dividerColor = "#E4E5E6";

export const ViewContainer = styled(Block)`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: ${p("white")};
  padding: 0 32px;
`;

export const ViewHeader = styled(Block)`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;

  .heading-container {
    width: 100%;
    padding-top: 64px;
    display: flex;
    border-bottom: solid 1px ${dividerColor};
    position: relative;
    img {
      position: absolute;
      top: 88px;
      left: -43px;
      cursor: pointer;
    }
    h1 {
      color: ${p("slate")};
      font-size: 1.75rem;
      font-weight: 500;
      margin-bottom: 8px;
    }

    h2 {
      color: ${p("platinum")};
      font-size: 1.25rem;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 20px;
    }

    > div {
      flex: 1;
    }
  }
`;

export const ViewContent = styled(Block)`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin: 0 auto;
  height: auto;
  overflow-y: auto;

  h4 {
    color: ${p("platinum")};
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
  }

  .pane-container {
    display: flex;

    .left-pane {
      flex: 2;
      border-right: solid 1px ${dividerColor};

      section {
        padding: 2rem 0 2rem 0;
        border-bottom: solid 1px ${dividerColor};

        &:first-child {
          padding: 32px 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .workdays-container {
        margin: 1.5rem 0;
        margin-bottom: 1.5rem;
      }

      .date-time-container {
        margin-bottom: 1rem;
        margin-right: 8rem;

        &:first-child {
          margin-bottom: 2rem;
        }

        .date-time {
          flex: 1;

          .date,
          .time {
            font-weight: 600;
            font-size: 20px;
            color: ${p("slate")};
          }
        }
      }

      .numbers-container {
        .big-number {
          font-size: 4rem;
          line-height: 5rem;
          color: ${p("brandBlue")};
        }

        .caption {
          font-size: 1.5rem;
          color: ${p("platinum")};
        }

        .requested {
          display: flex;

          .content {
            padding-left: 2rem;
          }
        }

        .this-week {
          display: flex;
          padding-left: 4rem;
        }

        .filled {
          display: flex;
          padding-left: 4rem;
        }
      }
    }

    .right-pane {
      flex: 1;
      padding: 2rem 0 0 2rem;
    }

    @media only screen and (max-width: 1024px) {
      .left-pane .date-time-container {
        margin-right: 2rem;
      }
    }
  }
`;

export const SupervisorCard = styled(Block)`
  .name {
    font-weight: bold;
    color: ${p("slate")};
    font-size: 1.1rem;
  }

  .phone {
    font-weight: normal;
    color: ${p("platinum")};
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const AvatarContainer = styled(InlineBlock)`
  vertical-align: top;
  margin-right: 1rem;
`;

export const SupervisorInfo = styled(InlineBlock)`
  vertical-align: center;
`;

export const StyledOverlay = styled(Overlay)`
  background-color: #fff;
  padding: 0;
`;

export const StyledPayRate = styled(Block)`
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.75rem;
`;

export const DepartmentCard = styled(Block)`
  font-weight: bold;
  color: ${p("slate")};
  font-size: 1.1rem;
  margin: 2rem 0 1.5rem 0;
`;

export const StyledActionButtons = styled.div`
  margin-bottom: 24px;

  button {
    margin-right: 14px;
  }
`;
export const SupervisorContainer = styled.div`
  align-items: center;
  display: flex;
  line-height: 20px;
`;

export const ChangeSupervisor = styled.span`
  color: ${p("brandBlue")};
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 6px;
  margin-left: 6px;
`;
