import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Input, Button } from "@bluecrew/web-react-core";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 185px;

  .workers-needed {
    padding: 0rem;
    width: 3rem;
    height: 3.375rem;
    border-radius: 0.25rem;
    text-align: center;
  }

  .round-button {
    width: 3.375rem;
    height: 3.375rem;
    border-radius: 1.6875rem;
    border: solid 0.0625rem ${p("platinum")};
    background-color: ${p("white")};
    color: ${p("platinum")};
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

type WorkersNeededProps = {
  workersNeeded: number;
};

export function WorkersNeeded({ workersNeeded }: WorkersNeededProps) {
  return (
    <StyledDiv>
      <Button data-testid="workers-needed-minus-1" disabled type="button" className="round-button">
        &mdash;
      </Button>
      <Input
        data-testid="workers-needed-input"
        disabled
        type="number"
        className="workers-needed"
        value={workersNeeded}
      />
      <Button data-testid="workers-needed-plus-1" disabled type="button" className="round-button">
        &#43;
      </Button>
    </StyledDiv>
  );
}
