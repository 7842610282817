import React, { useCallback } from "react";
import { useNavigateToScheduleDetailPage } from "../navigationHooks";
import SubRowComponent from "./SubRowComponent";

interface RepeatingScheduleRowProps {
  rowProps: any;
  row: any;
  sortBy: any;
  openRequestModal: (id: number) => void;
}

const RepeatingScheduleRow = ({
  rowProps,
  row,
  sortBy,
  openRequestModal,
}: RepeatingScheduleRowProps) => {
  const navigateToScheduleDetailPage = useNavigateToScheduleDetailPage();

  const onRowClick = useCallback((cell: any) => {
    cell.column.id !== "name" &&
      cell.column.id !== "alertIcon" &&
      cell.column.id !== "actionButtons" &&
      cell.column.id !== "requestDropdown" &&
      navigateToScheduleDetailPage(row.original.schedule_id);
  }, []);

  return (
    <React.Fragment key={`${rowProps.key}-expanded-${row.original.schedule_id}`}>
      <tr
        {...row.getRowProps()}
        className={"top-level-row-repeating-schedule"}
        data-pendo-key="ScheduleRequestTableTopLevelRow"
      >
        {row.cells.map((cell) => {
          const cellKey = cell.getCellProps().key;
          return (
            <td
              key={`${rowProps.key}-expanded-${row.original.schedule_id}-${cellKey}`}
              {...cell.getCellProps([{ className: cell.column.className }])}
              onClick={() => {
                onRowClick(cell);
              }}
            >
              {cell.render("Cell", {
                rowData: {
                  ...row.original,
                },
                row: {
                  ...row,
                  canExpand: true,
                },
              })}
            </td>
          );
        })}
      </tr>
      {row.isExpanded ? (
        <SubRowComponent
          row={row}
          rowProps={rowProps}
          sortBy={sortBy}
          openModal={(job: any) => {
            openRequestModal(job.job_id);
          }}
          captureDrag
        />
      ) : null}
    </React.Fragment>
  );
};

export default RepeatingScheduleRow;
