import React, { useEffect, useState } from "react";
import { ArrowRightIcon, Button, DaysOfWeek, useThemedStyletron } from "@bluecrew/blueprint-web";
import { useForm } from "react-hook-form";
import { styled } from "baseui";
import { Toolbar } from "../Toolbar";
import { ScheduleType } from "../types/propTypes/Schedule.types";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { GetCrewMemberScreenProps } from "../types/propTypes/GetCrewMemberScreen.types";
import { FormFieldTypes } from "../types/propTypes/GetCrewMemberForm.types";
import { CrewMemberFieldSet } from "./CrewMemberFieldSet";
import { ScheduleFieldSet } from "./ScheduleFieldSet";
import { getFormattedWorkingHours, getNewRepeatingScheduleWithFormattedHours } from "../formUtils";
import { OptimizedJobToggleField } from "./OptimizedJobToggleField";
import { PositionField } from "./PositionField";
import { ScreenHeader } from "../ScreenHeader";

const NUMS_SHIFTS_FOR_APPLICATION_JOB = 27;

type GetValuesUndefinedParamType = (arg?: undefined) => FormFieldTypes;
type GetValuesStringParamType = <T extends keyof FormFieldTypes>(payload: T) => FormFieldTypes[T];
type GetValuesStringArrayParamType = <T extends keyof FormFieldTypes>(
  payload: T[],
) => { [K in T]: FormFieldTypes[K] };
export type GetCrewMembersScreenGetValuesType = GetValuesUndefinedParamType &
  GetValuesStringParamType &
  GetValuesStringArrayParamType;

export const GetCrewMemberScreen = ({
  data: {
    positions,
    crewMembers,
    crewGroups,
    supervisors,
    departments,
    notificationManagers,
    isDepartmentsEnabled,
  },
  events,
  defaultValues,
  updateFormState,
  isBulkSingleShiftEnabled,
  isBulkSingleShiftMax50DayEnabled,
  isSingleAssignmentView,
  preselectedSchedule,
  preselectedPosition,
}: GetCrewMemberScreenProps) => {
  const { control, trigger, handleSubmit, watch, setValue, errors, getValues, reset } =
    useForm<FormFieldTypes>({
      defaultValues,
    });

  const [, theme] = useThemedStyletron();
  const {
    position,
    workDays,
    timeRange,
    schedule,
    date,
    scheduleName,
    scheduleType,
    isBlueShiftSchedule,
  } = watch([
    "position",
    "workDays",
    "timeRange",
    "schedule",
    "date",
    "scheduleName",
    "scheduleType",
    "isBlueShiftSchedule",
  ]);

  const [shiftCounts, setShiftCounts] = useState<number>(0);
  const repeatingScheduleType = scheduleType === ScheduleType.REPEATING;

  useEffect(() => {
    // When position is changed clear schedule field for repeating schedules, since a schedule template could be selected
    if (repeatingScheduleType) {
      setValue("schedule", getNewRepeatingScheduleWithFormattedHours(position, date, timeRange));
      setValue("scheduleName", "");
    }
    // We can keep the schedule values the same for single shifts since, we cannot select a schedule template
  }, [position?.id]);

  useEffect(() => {
    if (workDays.length && position) {
      setValue("schedule", { ...schedule, workingDays: workDays });
    }
  }, [workDays]);

  useEffect(() => {
    if (preselectedSchedule) {
      setValue("schedule", {
        ...schedule,
        ...preselectedSchedule,
      });
    }
  }, [preselectedSchedule]);

  useEffect(() => {
    // With many re-render in the parent components
    // default values that we are passing are not set properly.
    // So we need manually update some of them.
    if (preselectedPosition) {
      setValue("position", {
        ...position,
        ...preselectedPosition,
      });
    }
  }, [preselectedPosition]);

  useEffect(() => {
    // Update schedule name when creating new one
    if (scheduleName.length && position) {
      setValue("schedule", { ...schedule, name: scheduleName });
    }
  }, [scheduleName]);

  useEffect(() => {
    setWorkingHours();
  }, [timeRange]);

  useEffect(() => {
    if (schedule?.id && date.length === 2 && position) {
      let data = {
        workingDays: schedule.workingDays as DaysOfWeek,
        date,
        position,
      };

      if (schedule.id === -1) {
        data = {
          ...data,
          workingDays: workDays,
        };
      }

      const res = events?.onGetNumShifts && events?.onGetNumShifts(data);
      setShiftCounts(res!);
    }
  }, [schedule, date, workDays, position]);

  const onNewPositionClick = () => {
    updateFormState(getValues());
    events && events.onNewPosition && events.onNewPosition();
  };

  const onNextClick = () => {
    handleSubmit((formData) => {
      updateFormState(formData);
      events && events.onNext && events.onNext(formData);
    })();
  };

  const setWorkingHours = () => {
    setValue("schedule", {
      ...schedule,
      workingHours: {
        startTime: timeRange.startDateTime,
        endTime: timeRange.endDateTime,
        formatted: getFormattedWorkingHours(position, date, timeRange),
      },
    });
  };

  return (
    <div>
      <ScreenHeader>Get Crew Members </ScreenHeader>
      <FormStyledWrapper>
        <PositionField
          disabled={isSingleAssignmentView && repeatingScheduleType}
          control={control}
          trigger={trigger}
          positions={positions}
          onNewPosition={onNewPositionClick}
        />
        <CrewMemberFieldSet
          disabled={isSingleAssignmentView}
          control={control}
          trigger={trigger}
          crewMembers={crewMembers}
          crewGroups={crewGroups}
        />
        <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale900} />
        <ScheduleFieldSet
          disabled={isSingleAssignmentView}
          onGetScheduleInfo={events?.onGetScheduleInfo}
          isDepartmentsEnabled={isDepartmentsEnabled}
          control={control}
          trigger={trigger}
          schedules={position?.schedules || []}
          baseWage={position?.baseWage}
          supervisors={supervisors}
          departments={departments}
          notificationManagers={notificationManagers}
          isPositionSelected={!!position}
          errors={errors}
          reset={reset}
          currentFormValues={getValues}
          shiftsCount={shiftCounts}
          isBulkSingleShiftEnabled={isBulkSingleShiftEnabled}
          isBulkSingleShiftMax50DayEnabled={isBulkSingleShiftMax50DayEnabled}
        />
        <Toolbar
          data-pendo-key="GetCrewMemberToolbar"
          $justifyContent={
            shiftCounts >= NUMS_SHIFTS_FOR_APPLICATION_JOB ? "space-between" : "flex-end"
          }
        >
          {shiftCounts >= NUMS_SHIFTS_FOR_APPLICATION_JOB && (
            <OptimizedJobToggleField
              isBlueshiftSchedule={isBlueShiftSchedule}
              control={control}
              trigger={trigger}
            />
          )}
          <Button
            rightIcon={ArrowRightIcon}
            onClick={onNextClick}
            dataTestId="GetCrewMember-next-button"
          >
            Next
          </Button>
        </Toolbar>
      </FormStyledWrapper>
    </div>
  );
};

const FormStyledWrapper = styled("div", () => ({
  marginLeft: "109px",
  width: "800px",
  paddingBottom: "80px",
}));
