/* eslint-disable import/no-cycle */
import {
  differenceInMinutes,
  format,
  getDay,
  startOfDay,
  differenceInHours,
} from "date-fns";
import {
  ApproveShiftPayload,
  CompaniesResponse,
  CompanyPayrollResponseType,
  CompanyShiftPool,
  CompanyWorker,
  CreateIntercomDisputePayload,
  CreateRawJobPayload,
  GetAllWorkersResponseEntry,
  GetCompanyPositionsResponseEntry,
  UpdatePayrollHoursRequest,
  UpdatePayrollHoursStatusPayload,
  UserTimestamp,
} from "../../api/bluecrew/payroll";
import { buildUserShift, getDurationOfAllBreaksInMins, TimestampAttributeName } from "./shiftUtils";
import {
  ApproveShiftRequest,
  DisputeShiftRequest,
  PayrollHoursStatus,
  PayrollStatusReason,
  RejectShiftRequest,
  ExcuseShiftRequest,
  TableCollapseFormat,
  TimesheetsFilterOptions,
  TimesheetsFiltersType,
  TimesheetTableTabPayrollStatusMap,
  TimesheetTabs,
  TimecardStatusCounts,
} from "./types/Payroll.types";
import { BreakTimestamps, UserShift, TimesheetsShiftInfo } from "./types/Shift.types";
import { AddShiftRequest, Position } from "./Timesheets/AddShift/AddShiftForm.types";
import { ADD_SHIFT_SCHEDULE_NAME } from "../../../shared/constants";
import { ScheduleType } from "../GetCrewMembers/types/propTypes/Schedule.types";
import { FormattedPayrollReportDataType } from "./PayrollReports/utils";
import { GetAllCompaniesResponse } from "../../api/bluecrew/company";
import { convergeDate } from "../../../shared/helpers";

/*
 * This file contains utility functions used throughout the Timesheets page
 */

/**
 *
 * @param companyPayroll
 * @returns shift info formatted for timesheets table
 */
export function processTimesheetsShiftInfoApiV1(
  companyPayroll: CompanyPayrollResponseType,
): TimesheetsShiftInfo[] {
  const users = companyPayroll?.users;
  const jobs = companyPayroll?.jobs;
  const companies = companyPayroll?.companies;

  if (!companyPayroll.payroll.companies || !users || !jobs || !companies) {
    console.error("Irregular Company Payroll Response: unable to generate timesheets.");
    return [];
  }

  const dateStem: CompanyShiftPool[] = companyPayroll.payroll.companies[0].dates;
  const payload: TimesheetsShiftInfo[] = [];

  for (let dateIdx = 0; dateIdx < dateStem.length; dateIdx += 1) {
    for (let jobIdx = 0; jobIdx < dateStem[dateIdx].jobs.length; jobIdx += 1) {
      for (let userIdx = 0; userIdx < dateStem[dateIdx].jobs[jobIdx].users.length; userIdx += 1) {
        // this is the job-user-shift level -- timestamps array accessible here

        const user = dateStem[dateIdx].jobs[jobIdx].users[userIdx];
        const job = dateStem[dateIdx].jobs[jobIdx];
        const userExternalId = user.id;
        const jobExternalId = job.id;
        const jobStartDate = convergeDate(job.start);
        const jobEndDate = convergeDate(job.end);
        const companyExternalId = companyPayroll.payroll.companies[0].id;
        const companyName = companies[companyExternalId].name;

        try {
          const companyInfo = {
            externalId: companyExternalId,
            name: companyName,
          };

          const userInfo = {
            externalId: user.id,
            firstName: users[userExternalId]?.firstName ?? "",
            lastName: users[userExternalId]?.lastName ?? "",
            profilePic: users[userExternalId]?.profilePictureURL || undefined,
            internalId: users[userExternalId].id,
          };

          const jobInfo = {
            externalId: jobExternalId,
            name: jobs[jobExternalId].title,
            start: jobStartDate,
            end: jobEndDate,
            shiftIndex: job.shift,
            address: jobs[jobExternalId].address,
            timezone: jobs[jobExternalId].timezone,
            wage: jobs[jobExternalId].wage,
            coordinates: {
              lat: jobs[jobExternalId].latitude,
              lng: jobs[jobExternalId].longitude,
            },
            supervisorName: job.supervisorName ?? undefined,
          };

          const shiftThresholds = {
            warnRadius: job.min_warn_radius,
            blockRadius: job.min_block_radius,
            earlyClockinBuffer: companies[companyExternalId].earlyClockinBuffer,
            lateClockoutBuffer: companies[companyExternalId].lateClockoutBuffer,
            minLunchDurationHours: companies[companyExternalId].minLunchDuration,
            expectedStart: jobStartDate,
            expectedEnd: jobEndDate,
          };

          const userShift = buildUserShift({
            shiftThresholds,
            createdBy: user.createdByName,
            createdAt: user.createdAt,
            createdByUserType: user.createdByPermission,
            sentPayroll: user.sentPayroll,
            sentInvoice: user.sentInvoice,
            timestamps: coalesceDates(job.users[userIdx].timestamps),
            usersHoursStart: user.hoursStart,
            usersHoursEnd: user.hoursEnd,
            usersHoursLunchDurationHours: user.lunchDuration,
            usersHoursStatus: user.status,
            usersHoursStatusReason: user.reason,
            updatedBy: user.updatedByName,
            updatedAt: user.updatedAt,
            updatedByUserType: user.updatedByPermission,
            autoApprovalDetermination: user.autoApprovalDetermination,
            autoApprovalTime: user.autoApprovalTimestamp,
          });

          const record = {
            company: companyInfo,
            job: jobInfo,
            shiftThresholds,
            user: userInfo,
            userShift,
            userHoursId: user.hoursId ?? undefined,
          };
          payload.push(record);
        } catch (error) {
          console.log(`Irregular Company Payroll Response: ${error}`);
        }
      }
    }
  }

  return payload;
}

/**
 * Ensures Date fields in every timestamp are actually Dates and not strings.
 * @param timestamps An array of UserTimestamp, which will be empty if parameter is undefined.
 */
function coalesceDates(timestamps?: UserTimestamp[]): UserTimestamp[] {
  if (timestamps)
    return timestamps.map((ts) => {
      return {
        ...ts,
        time: convergeDate(ts.time),
        createdAt: convergeDate(ts.createdAt),
        updatedAt: convergeDate(ts.updatedAt),
      };
    });
  return [];
}

// placeholder function
export const processTimesheetsShiftInfoApiV2 = processTimesheetsShiftInfoApiV1;

/**
 *
 * @param companyPayroll
 * @returns timesheets table formatted data
 */

export function processCompanyPositions(
  positions: Array<GetCompanyPositionsResponseEntry>,
): Array<Position> {
  return positions.map((position) => ({
    id: position.id,
    name: position.title,
    baseWage: position.wage_floor.toString(),
    timezone: position.timezone,
  }));
}

export function processCompaniesResponse(
  companies: Array<GetAllCompaniesResponse>,
): Array<CompaniesResponse> {
  return companies.map((company) => ({
    name: company.name,
    id: company.id,
    accountManager: company.account_manager,
    accountManagerId: company.am_id,
  }));
}

export const mergeCompaniesData = (
  companiesData: Array<CompaniesResponse>,
  payrollReportsData: Array<FormattedPayrollReportDataType>,
) => {
  const payrollReportCompanyIds = payrollReportsData.map((company) => company.internalCompanyId);
  const formattedCompaniesData: Array<FormattedPayrollReportDataType> = companiesData
    .filter((company) => !payrollReportCompanyIds.includes(company.id))
    .map((company) => ({
      needsAction: 0,
      totalThisWeek: 0,
      pendingReview: 0,
      overdue: 0,
      disputed: 0,
      companyName: company.name,
      internalCompanyId: company.id,
      accountManagerName: company.accountManager || "",
      accountManagerId: company.accountManagerId || null,
    }));
  return [...payrollReportsData, ...formattedCompaniesData];
};

// TODO(@dhhina): Move this (bad casing and other unnecessary data) into the view/proxy layer
export function processAllWorkers(
  allWorkersResponse: Array<GetAllWorkersResponseEntry>,
): Array<CompanyWorker> {
  return allWorkersResponse.map((workerEntry) => ({
    externalId: workerEntry.external_id,
    firstName: workerEntry.firstName,
    lastName: workerEntry.lastName,
    email: workerEntry.username,
    companyShiftsCount: workerEntry.shiftsWorked || 0,
    platformShiftsCount: workerEntry.total_shift_count || 0,
    neutralShiftsCount: workerEntry.neutral_count,
    negativeShiftsCount: workerEntry.negative_count,
    phoneNumber: workerEntry.phoneNumber,
    profilePicSrc: workerEntry.profilePic,
    lastShift: workerEntry.lastShift,
  }));
}

const minutesToHours = (minutes: number, fractionDigits: number) =>
  (minutes / 60).toFixed(fractionDigits);

export function buildCreateRawJobPayload(addShiftRequest: AddShiftRequest): CreateRawJobPayload {
  return {
    position_id: addShiftRequest.position!.id,
    schedule_name: ADD_SHIFT_SCHEDULE_NAME,
    schedule_type: ScheduleType.SINGLE_SHIFT,
    start_date_time: addShiftRequest.clockIn!.toISOString(),
    end_date_time: addShiftRequest.clockOut!.toISOString(),
    work_days: [
      ...new Set([
        getDay(addShiftRequest.clockIn!).toString(),
        getDay(addShiftRequest.clockOut!).toString(), // Use only for single day shifts, accounting for overnight shifts
      ]),
    ],
    base_wage: addShiftRequest.wageOverride
      ? addShiftRequest.wageOverride.toString()
      : addShiftRequest.position!.baseWage,
    workers_needed: addShiftRequest.workers.length,
    supervisor_user_id: addShiftRequest.supervisor!.id,
    is_application: 0,
  };
}

export function buildPutOnJobPayload(addShiftRequest: AddShiftRequest, jobId: number) {
  return {
    userIds: addShiftRequest.workers.map((worker) => worker.externalId),
    jobId,
    approveHours: true,
    lunchHours: minutesToHours(addShiftRequest.breakDurationMins!, 2),
  };
}

export function buildApproveShiftPayload(
  approveShiftRequest: ApproveShiftRequest,
): ApproveShiftPayload {
  return {
    userId: approveShiftRequest.userId,
    jobId: approveShiftRequest.jobId,
    shift: approveShiftRequest.shiftIndex,
    shiftStart: approveShiftRequest.shiftStart.toISOString(),
    shiftEnd: approveShiftRequest.shiftEnd.toISOString(),
    lunchDuration: minutesToHours(approveShiftRequest.lunchDurationMinutes, 2),
    reason: approveShiftRequest.reason,
  };
}

export function buildUpdatePayrollHoursStatusPayload(
  request: ExcuseShiftRequest | RejectShiftRequest | DisputeShiftRequest,
  status: PayrollHoursStatus,
  reason?: PayrollStatusReason,
): UpdatePayrollHoursStatusPayload {
  return {
    userId: request.userId,
    jobId: request.jobId,
    shift: request.shiftIndex,
    status,
    reason,
  };
}

function formattedDate(date: Date, timeOnly = false) {
  const fmt = timeOnly ? "hh:mm aaa" : "MMM d → hh:mm aaa (xxx)";
  return format(date, fmt);
}

function formattedTimestamp(timestamp: UserTimestamp, timeOnly = false) {
  const distanceSuffix = timestamp.distance
    ? `(punched ${timestamp.distance}m away from workplace location`
    : "";
  return `${formattedDate(timestamp.time, timeOnly)} ${distanceSuffix}`;
}

function formattedBreakTimestamps(breaks: Array<BreakTimestamps>) {
  return `[${breaks.map(
    (b) =>
      `{start: ${formattedTimestamp(b.start, true)}, end: ${formattedTimestamp(b.end, true)}}, `,
  )}]`;
}

function buildDisputeMessage(request: DisputeShiftRequest) {
  const { user, job, company } = request.shiftInfo;
  const {
    start: { userTimestamp: clockInTimestamp },
    end: { userTimestamp: clockOutTimestamp },
    break: { breakTimestamps },
  } = request.shiftInfo.userShift;
  const {
    clockIn: adjustedClockIn,
    clockOut: adjustedClockOut,
    breakDurationMins: adjustedBreakDurationMins,
  } = request.disputeInfo;

  return `<h1>Dispute Raised for shift(${job.name}) on ${format(job.start, "MMM d, yyyy")}</h1>
<b>Company name:</b> ${company.name}<br />
<b>Crew member:</b> ${user.firstName} ${user.lastName}<br />
<br /><h2>Crew member submissions:</h2>
<b>Clock-in:</b> ${clockInTimestamp?.time ? formattedTimestamp(clockInTimestamp) : "Missing"}<br />
<b>Clock-out:</b> ${
    clockOutTimestamp?.time ? formattedTimestamp(clockOutTimestamp) : "Missing"
  }<br />
<b>Breaks (Total: ${getDurationOfAllBreaksInMins(breakTimestamps)} min):</b> ${
    breakTimestamps.length ? `${formattedBreakTimestamps(breakTimestamps)}` : "Missing"
  }<br />
<br /><h2>Workplace dispute:</h2>
<b>Reason:</b> ${request.disputeInfo.reason.text}</br>
${adjustedClockIn ? `<b>Adjusted clock-in:</b> ${formattedDate(adjustedClockIn)}</br>` : ""}
${adjustedClockOut ? `<b>Adjusted clock-out:</b> ${formattedDate(adjustedClockOut)}</br>` : ""}
${
  Number.isFinite(adjustedBreakDurationMins)
    ? `<b>Adjusted break duration:</b> ${adjustedBreakDurationMins} min</br>`
    : ""
}
`;
}

export function buildCreateIntercomDisputePayload(
  request: DisputeShiftRequest,
): CreateIntercomDisputePayload {
  const { user, job, company } = request.shiftInfo;

  return {
    cmExternalId: user.externalId,
    wpExternalId: request.wpUserId,
    initialMessage: buildDisputeMessage(request),
    companyName: company.name,
    shiftDate: format(job.start, "MM/dd/yy"),
    adjustmentReason: PayrollStatusReason[request.disputeInfo.reason.code],
    onsiteSupervisorName: job.supervisorName,
  };
}

export function buildUpdateHoursPayload(
  hoursId: number,
  clockIn?: Date,
  clockOut?: Date,
  breakDurationMins?: number,
): UpdatePayrollHoursRequest {
  return {
    id: hoursId,
    shiftStart: clockIn && clockIn.toISOString(),
    shiftEnd: clockOut && clockOut.toISOString(),
    lunchDuration: Number.isFinite(breakDurationMins)
      ? minutesToHours(breakDurationMins!, 2)
      : undefined,
  };
}

/**
 *
 * @param userShift
 * @returns number - total hours worked for a given user hours entry
 */
export const calculateHours = (userShift: UserShift) =>
  (differenceInMinutes(userShift.end.time, userShift.start.time) -
    userShift.break.durationMinutes) /
  60;

export type DatedRowHolder = {
  shiftRows: TimesheetsShiftInfo[];
  headerDate: string;
};

/**
 *
 * @param tableData
 * @returns row data used to populate the collapsible tabs of the timesheets table
 */
export const generateCollapseData = (tableData: TimesheetsShiftInfo[]): TableCollapseFormat[] =>
  Array.from(
    tableData.reduce((accumulator, entry) => {
      const sod = startOfDay(convergeDate(entry.shiftThresholds.expectedStart));
      const sodKey = sod.toString();
      if (accumulator.has(sodKey)) {
        accumulator.get(sodKey)?.shiftRows.push(entry);
      } else {
        accumulator.set(sodKey, {
          shiftRows: [entry],
          headerDate: format(sod, "EEEE MMMM do"),
        });
      }
      return accumulator;
    }, new Map<string, DatedRowHolder>()),
  ).map(([date, holder]) => ({
    formattedDate: date,
    headerDate: holder.headerDate,
    // Make a copy of array and sort this copy.
    // We do not want to sort the original array (to avoid unpredictable behaviour)
    rows: holder.shiftRows.slice().sort(tabTimeshiftsSorter),
    isExpanded: true,
  }));

/**
 * For each tab (i.e. date) we sort its timeshifts with following criteria:
 *  - shifts with all provided timestamps go first
 *  - then shifts with partially provided timestamps go
 *  - then shifts with all missing timestamps go
 *
 *  Inside each of these groups we sort timeshifts  by start timestamp first,
 *  and then alphabetically by CM last name.
 *
 */
const tabTimeshiftsSorter = (a: TimesheetsShiftInfo, b: TimesheetsShiftInfo): number => {
  const diff = classifyTimesheetsShiftInfo(a) - classifyTimesheetsShiftInfo(b);
  return diff === 0 ? sortTimesheetsShiftInfoByStartTimeThenByLastName(a,b) : diff;
}

enum TimestampPresenceCriteria {
  ALL_TIMESTAMPS_PRESENT = 1,  // go first
  SOME_TIMESTAMPS_PRESENT = 2, // go second
  ALL_TIMESTAMPS_MISSING = 3,  // go last
}

function classifyTimesheetsShiftInfo(a: TimesheetsShiftInfo): TimestampPresenceCriteria {
  if (timesheetsShiftInfoHasAllTimestamps(a)) {
    return TimestampPresenceCriteria.ALL_TIMESTAMPS_PRESENT;
  }
  if (timesheetsShiftInfoHasAllTimestampsMissing(a)) {
    return TimestampPresenceCriteria.ALL_TIMESTAMPS_MISSING;
  }
  return TimestampPresenceCriteria.SOME_TIMESTAMPS_PRESENT;
}

const timesheetsShiftInfoHasAllTimestamps = (a: TimesheetsShiftInfo): boolean => {
  return ('userTimestamp' in a.userShift.start && a.userShift.start.userTimestamp !== undefined)
    && ('userTimestamp' in a.userShift.end && a.userShift.end.userTimestamp !== undefined)
    && ('breakTimestamps' in a.userShift.break && a.userShift.break.breakTimestamps.length > 0);
}

const timesheetsShiftInfoHasAllTimestampsMissing = (a: TimesheetsShiftInfo): boolean => {
  return a.userShift.start.attributes.includes(TimestampAttributeName.CM_MISSING_SUBMISSION) &&
         a.userShift.end.attributes.includes(TimestampAttributeName.CM_MISSING_SUBMISSION) &&
         a.userShift.break.attributes.includes(TimestampAttributeName.CM_MISSING_SUBMISSION);
}

const sortTimesheetsShiftInfoByStartDate = (a: TimesheetsShiftInfo, b: TimesheetsShiftInfo): number => a.userShift.start.time.valueOf() - b.userShift.start.time.valueOf();

const sortTimesheetsShiftInfoByLastName =(a: TimesheetsShiftInfo, b: TimesheetsShiftInfo): number => a.user.lastName.localeCompare(b.user.lastName);

const sortTimesheetsShiftInfoByStartTimeThenByLastName = (a: TimesheetsShiftInfo, b: TimesheetsShiftInfo): number => {
  const startDateCompareResult = sortTimesheetsShiftInfoByStartDate(a,b);
  return startDateCompareResult === 0 ? sortTimesheetsShiftInfoByLastName(a,b) : startDateCompareResult;
}

/**
 *
 * @param data
 * @param tabIndex
 * @returns timesheets table data - filtered for the selected tab (0-->pending review; 1-->approved; 2-->disputed; 3-->removed)
 */
export function filterTableDataByTabIndex(
  data: TimesheetsShiftInfo[],
  tabIndex: TimesheetTabs,
): TimesheetsShiftInfo[] {
  return data.filter((row) =>
    Array.from(TimesheetTableTabPayrollStatusMap[tabIndex]).some(
      (status) => status === row.userShift.status,
    ),
  );
}

export function parsePayrollFilterOptions(data: TimesheetsShiftInfo[]): TimesheetsFilterOptions {
  const userName: string[] = [];
  const jobTitles: string[] = [];
  const supervisors: string[] = [];

  data.forEach((row) => {
    userName.push(row.user.firstName.concat(" ", row.user.lastName).trim());
    jobTitles.push(row.job.name);
    row.job.supervisorName && supervisors.push(row.job.supervisorName);
  });

  return {
    userName: [...new Set(userName)],
    jobName: [...new Set(jobTitles)],
    jobShiftSupervisor: [...new Set(supervisors)],
  };
}

export function applyPayrollFilters(
  data: TimesheetsShiftInfo[],
  filters: TimesheetsFiltersType,
): TimesheetsShiftInfo[] {
  let filteredSet = data;
  if (!filters.position && !filters.supervisor && !filters.search) {
    return filteredSet;
  }
  if (filters.position.name.length) {
    filteredSet = filteredSet.filter((record) => record.job.name === filters.position.name);
  }
  if (filters.supervisor.supervisorName && filters.supervisor.supervisorName.length) {
    filteredSet = filteredSet.filter(
      (record) => record.job.supervisorName === filters.supervisor.supervisorName,
    );
  }
  if (filters.search.userName.length) {
    filteredSet = filteredSet.filter(
      (record) =>
        record.user.firstName.toLowerCase().includes(filters.search.userName.toLowerCase()) ||
        record.user.lastName.toLowerCase().includes(filters.search.userName.toLowerCase()),
    );
  }
  return filteredSet;
}

/**
 *
 * @param tableData
 * @returns number - count of records that are "PENDING REVIEW"
 */
export function getPendingReviewCount(tableData: TimesheetsShiftInfo[]): number {
  return tableData.reduce(
    (acc, record) =>
      isPendingReview(record) ? acc + 1 : acc,
    0,
  );
}

function isPendingReview(record: TimesheetsShiftInfo): boolean {
  return TimesheetTableTabPayrollStatusMap[TimesheetTabs.PENDING_REVIEW].some(
    (status) =>
      status === record.userShift.status && differenceInHours(new Date(), record.shiftThresholds.expectedEnd) >= 24
  )
}

/**
 *
 * @param tableData
 * @returns number - count of records that are "DISPUTED"
 */
export function getDisputedCount(tableData: TimesheetsShiftInfo[]): number {
  return tableData.reduce(
    (acc, record) =>
      isDisputed(record) ? acc + 1 : acc,
    0,
  );
}

function isDisputed(record: TimesheetsShiftInfo): boolean {
  return TimesheetTableTabPayrollStatusMap[TimesheetTabs.DISPUTED].some(
    (status) => status === record.userShift.status
  )
}

function isApproved(record: TimesheetsShiftInfo): boolean {
  return TimesheetTableTabPayrollStatusMap[TimesheetTabs.APPROVED].some(
    (status) => status === record.userShift.status
  )
}

function isRemoved(record: TimesheetsShiftInfo): boolean {
  return TimesheetTableTabPayrollStatusMap[TimesheetTabs.REMOVED].some(
    (status) => status === record.userShift.status
  )
}

/* eslint-disable no-param-reassign */
export function getTimecardStatusCounts(timecards:TimesheetsShiftInfo[]): any {
  return timecards.reduce((accum: TimecardStatusCounts, cv) => {
    if(isPendingReview(cv)) {accum.pendingReview += 1; return accum}
    if(isApproved(cv)) {accum.approved += 1; return accum}
    if(isDisputed(cv)) {accum.disputed += 1; return accum}
    if(isRemoved(cv)) {accum.removed += 1; return accum}
    return accum
  }, {pendingReview: 0, approved: 0, disputed: 0, removed: 0})
}
/* eslint-enable no-param-reassign */

export function getOverdueTimecardMessageText(timecards: TimesheetsShiftInfo[]): string {
  const pendingReview= getPendingReviewCount(timecards) > 0
  const disputed= getDisputedCount(timecards) > 0
  const prefix = (pr: boolean, disp: boolean) => {
    if (pr && !disp) return `shifts "Pending Review"`
    if (disp && !pr) return `"Disputed" shifts`
    if (disp && pr) return `"Pending Review" and "Disputed" shifts`
    return ""
  }
  return ((!pendingReview && !disputed)) ? "" : `There are ${prefix(pendingReview, disputed)} from last week. Please see previous week to address these issues.`
}

