import React from "react";
import {
  BlueprintTheme,
  Button,
  DaysOfWeek,
  MapType,
  StaticMap,
  Weekdays,
} from "@bluecrew/blueprint-web";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { styled } from "baseui";
import moment from "moment-timezone";
import ReactGoogleMapsLoader from "react-google-maps-loader";
import { BackIcon } from "./BackIcon";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { ReviewOrderScreenProps } from "../types/propTypes/ReviewOrderScreen.types";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";

const convertFeeFormat = (val: number): string =>
  val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const ReviewOrderScreen = ({
  data: { position, shift_wage, schedule, fees, date, shiftsCount, countWorkers },
  events,
  isBulkSingleShiftEnabled,
}: ReviewOrderScreenProps) => {
  let [startDate, endDate] = date;
  if (isBulkSingleShiftEnabled) {
    // eslint-disable-next-line prefer-destructuring
    startDate = date[0];
    endDate = date[date.length - 1];
  }
  const serviceFee = convertFeeFormat(fees.serviceFee);
  const serviceFeeDisplay = fees.pricingType === "markup" ? `${serviceFee}%` : `$${serviceFee}`;

  return (
    <MainContainer>
      <HeaderContainer>
        <StyledEditText
          data-pendo-key="GoBackEditRequestButton"
          onClick={() => events && events.onEditRequest && events.onEditRequest()}
        >
          <BackIcon />
          <span style={{ marginLeft: "5px" }}>Edit request</span>
        </StyledEditText>
      </HeaderContainer>
      <StyledPageHeading>Review your order</StyledPageHeading>
      {/* Position && Schedule Info Container */}
      <InnerContainer id="InnerContainer">
        <ReactGoogleMapsLoader
          params={{
            key: GOOGLE_MAPS_API_KEY,
            libraries: "places,geocode",
          }}
          render={(googleMaps) =>
            googleMaps && (
              <StaticMap
                type={MapType.reviewOrder}
                isMarkerShown
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...(position && {
                  position: {
                    lat: position.address.lat,
                    lng: position.address.lng,
                  },
                })}
                width="100%"
                height="160px"
              />
            )
          }
        />
        <SectionContainer data-pendo-key="JobDataReviewOrder">
          <Block>
            <StyledTitle>
              {countWorkers} &#215; {position?.name}
            </StyledTitle>
          </Block>
          <Block>
            <StyledAddress>{position?.address.displayAddress}</StyledAddress>
          </Block>
        </SectionContainer>

        <SectionContainer>
          <FlexGrid
            flexGridColumnCount={4}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
            marginBottom="8px"
          >
            {/* Row Titles */}
            <FlexGridItem overrides={flexGridItemFirstColumn}>
              <StyledScheduleText data-pendo-key="TimesReviewOrder">schedule</StyledScheduleText>
            </FlexGridItem>
            <FlexGridItem overrides={flexGridItemSecondColumn}>
              <StyledScheduleText data-pendo-key="DatesReviewOrder">
                start/end date
              </StyledScheduleText>
            </FlexGridItem>
            <FlexGridItem overrides={flexGridItemThirdColumn}>
              <StyledScheduleText data-pendo-key="ShiftsReviewOrder">shifts</StyledScheduleText>
            </FlexGridItem>
            <FlexGridItem overrides={flexGridItemForthColumn}>
              <StyledScheduleText data-pendo-key="WageReviewOrder"> wage</StyledScheduleText>
            </FlexGridItem>

            {/* Row Values */}
            <FlexGridItem overrides={flexGridItemFirstColumn}>
              {position && schedule && <StyledTime>{schedule.workingHours.formatted}</StyledTime>}
            </FlexGridItem>
            <FlexGridItem overrides={flexGridItemSecondColumn}>
              <StyledShiftText>
                {moment(startDate).format("YYYY") === moment(endDate).format("YYYY")
                  ? moment(startDate).format("MMM D")
                  : moment(startDate).format("MMM D, YYYY")}{" "}
                - {moment(endDate).format("MMM D, YYYY")}
              </StyledShiftText>
            </FlexGridItem>
            <FlexGridItem overrides={flexGridItemThirdColumn}>
              <StyledShiftText>{shiftsCount}</StyledShiftText>
            </FlexGridItem>
            <FlexGridItem overrides={flexGridItemForthColumn}>
              <StyledShiftText>${convertFeeFormat(shift_wage)}</StyledShiftText>
            </FlexGridItem>
          </FlexGrid>

          <Weekdays
            editable={false}
            selected={schedule?.workingDays as DaysOfWeek}
            size="compact"
          />
        </SectionContainer>
      </InnerContainer>

      <InnerContainer data-pendo-key="ReviewOrderEstimatedCostPerShiftPerCM">
        <SectionContainer>
          <FeeTextContainer>
            <StyledTotal>Estimated cost per shift per Crew Member</StyledTotal>
            <StyledTotal>${convertFeeFormat(fees.total / shiftsCount / countWorkers)}</StyledTotal>
          </FeeTextContainer>
        </SectionContainer>
      </InnerContainer>

      {/* Fees Container */}
      <InnerContainer data-pendo-key="TotalFeesReviewOrder">
        <SectionContainer>
          <FeeTextContainer>
            <span>Crew Member wages</span>
            <span>${convertFeeFormat(fees.wages)}</span>
          </FeeTextContainer>
          <FeeTextContainer>
            <span>Service fee</span>
            <span>{serviceFeeDisplay}</span>
          </FeeTextContainer>
          {!Number.isNaN(fees.taxes) && (
            <FeeTextContainer>
              <span>Estimated taxes & Workers&apos; Comp</span>
              <span>${convertFeeFormat(fees.taxes)}</span>
            </FeeTextContainer>
          )}
        </SectionContainer>

        <StyledDivider />

        <SectionContainer>
          <FeeTextContainer>
            <StyledTotal>Estimated total</StyledTotal>
            <StyledTotal>${convertFeeFormat(fees.total)}</StyledTotal>
          </FeeTextContainer>
        </SectionContainer>
      </InnerContainer>

      {/* Send Request Button */}
      <Button onClick={events?.onSendRequest} width="100%" dataTestId="ReviewOrder-Submit-Button">
        Send request
      </Button>
      <Spacer $type={SpacerType.vertical} $size="46px" />
    </MainContainer>
  );
};

const MainContainer = styled("div", () => ({
  width: "560px",
  boxSizing: "border-box",
  marginLeft: "auto",
  marginRight: "auto",
}));

const InnerContainer = styled("div", ({ $theme }) => ({
  border: `1px solid ${$theme.colors.primary300}`,
  borderRadius: $theme.borders.radius200,
  marginBottom: $theme.sizing.scale600,
}));

const SectionContainer = styled("div", ({ $theme }) => ({
  margin: $theme.sizing.scale600,
}));

const HeaderContainer = styled("div", () => ({
  display: "flex",
  margin: 0,
  alignItems: "center",
  flexDirection: "row",
}));

const StyledTitle = styled("span", ({ $theme }) => ({
  ...$theme.typography.font550,
  color: $theme.colors.primary700,
}));

const StyledPageHeading = styled("div", ({ $theme }) => ({
  ...$theme.typography.HeadingLarge,
  marginBottom: "24px",
}));

const StyledScheduleText = styled("span", ({ $theme }) => ({
  ...$theme.typography.font150,
  color: $theme.colors.primary700,
  letterSpacing: "0.03em",
  textTransform: "uppercase",
}));

const StyledTime = styled("span", ({ $theme }) => ({
  ...$theme.typography.font350,
  color: $theme.colors.primary700,
  letterSpacing: "0.03em",
}));

const StyledShiftText = styled("span", ({ $theme }) => ({
  ...$theme.typography.font300,
  color: $theme.colors.primary700,
  letterSpacing: "0.03em",
}));

const StyledAddress = styled("span", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.primary700,
  letterSpacing: "0.03em",
}));

const StyledDivider = styled("hr", ({ $theme }) => ({
  border: "none",
  borderTop: `1px solid ${$theme.colors.primary300}`,
  margin: $theme.sizing.scale600,
}));

const StyledEditText = styled("div", ({ $theme }) => ({
  ...$theme.typography.font300,
  color: $theme.colors.accent,
  fontSize: "18px",
  ":hover": {
    cursor: "pointer",
  },
  display: "flex",
  alignItems: "center",
}));

const FeeTextContainer = styled("div", ({ $theme }) => ({
  ...$theme.typography.font300,
  display: "flex",
  justifyContent: "space-between",
}));

const StyledTotal = styled("span", ({ $theme }) => ({
  ...$theme.typography.font550,
}));

const flexGridItemFirstColumn = {
  Block: {
    style: ({ $theme }: { $theme: BlueprintTheme }) => ({
      marginBottom: "0",
      linHeight: $theme.sizing.scale700,
      width: "160px",
    }),
  },
};

const flexGridItemSecondColumn = {
  Block: {
    style: ({ $theme }: { $theme: BlueprintTheme }) => ({
      marginBottom: "0",
      linHeight: $theme.sizing.scale700,
      width: "150px",
    }),
  },
};

const flexGridItemThirdColumn = {
  Block: {
    style: ({ $theme }: { $theme: BlueprintTheme }) => ({
      width: "20px",
      marginBottom: "0",
      linHeight: $theme.sizing.scale700,
    }),
  },
};

const flexGridItemForthColumn = {
  Block: {
    style: ({ $theme }: { $theme: BlueprintTheme }) => ({
      width: "40px",
      marginBottom: "0",
      linHeight: $theme.sizing.scale700,
    }),
  },
};
