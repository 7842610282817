import React, { useState } from "react";
import { GetCrewMembersCalendarIcon } from "@bluecrew/blueprint-web";
import { FieldErrors } from "react-hook-form";
import DateObject from "react-date-object";
import { FieldWrapper } from "../FieldWrapper";
import { ErrorMessage } from "../../ErrorMessage";
import {
  StyledDatePicker,
  GetCrewMembersFieldHeaderText,
  DateSelectedNumberText,
} from "./styledComponents";
import { StyledCalendarArrowButton } from "./StyledCalendarArrowButton";
import styled from "styled-components";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import { typography } from "@bluecrew/blueprint-web/src/theme/typography";
import {
  BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT,
  TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT,
} from "../../../../../shared/constants";

export interface MultiSelectDatePickerProps {
  isMultiple?: boolean;
  value: Date[];
  errors: FieldErrors;
  inError: boolean;
  disableDatePicker?: boolean;
  onChange: (date: DateObject[]) => void;
  isBulkSingleShiftMax50DayEnabled: boolean;
}

const InfoText = styled.p`
  color: ${blueprintTheme.colors.forest};
  font-family: ${typography.font200.fontFamily};
  font-size: ${typography.font200.fontSize};
  line-height: ${typography.font200.lineHeight};
  padding-top: ${blueprintTheme.sizing.scale300};
  margin: 0px;
`;

export const MultiSelectDatePicker = ({
  isMultiple,
  value,
  onChange,
  errors,
  inError,
  disableDatePicker,
  isBulkSingleShiftMax50DayEnabled,
}: MultiSelectDatePickerProps) => {
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  const [showMaxCapacityReachError, updateShowMaxCapacityReachError] = useState(false);

  return (
    <div data-pendo-key="MultiSelectDatePicker">
      <FieldWrapper paddingTop="0px" icon={<GetCrewMembersCalendarIcon />}>
        <GetCrewMembersFieldHeaderText>
          {value?.length > 0 ? (
            <>
              <DateSelectedNumberText>{value.length}</DateSelectedNumberText>
              Date{value?.length > 1 && "s"} selected
            </>
          ) : (
            `Select date(s) ${
              isBulkSingleShiftMax50DayEnabled
                ? ""
                : `- maximum ${BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT} days`
            } `
          )}
        </GetCrewMembersFieldHeaderText>
        <StyledDatePicker
          disabled={disableDatePicker}
          numberOfMonths={2}
          multiple={isMultiple}
          minDate={new Date()}
          weekDays={weekDays}
          format={"ddd D MMM, YYYY"}
          renderButton={(direction: string, handleClick: any, disabled: boolean) => (
            <StyledCalendarArrowButton
              direction={direction}
              handleClick={handleClick}
              disabled={disabled}
            />
          )}
          value={value}
          onChange={(values: DateObject[]) => {
            const hasShiftCapacityReached =
              (isBulkSingleShiftMax50DayEnabled &&
                values.length > TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT) ||
              (!isBulkSingleShiftMax50DayEnabled &&
                values.length > BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT);

            updateShowMaxCapacityReachError(hasShiftCapacityReached);
            onChange(values);
          }}
          inError={inError}
        />
        {showMaxCapacityReachError && (
          <InfoText>
            Individual shifts can be created a maximum of{" "}
            {isBulkSingleShiftMax50DayEnabled
              ? TOP_MAX_FOR_BULK_SINGLE_SHIFT_COUNT
              : BASE_MAX_FOR_BULK_SINGLE_SHIFT_COUNT}{" "}
            days at a time
          </InfoText>
        )}
        {inError && errors && <ErrorMessage text={errors.date.message} />}
      </FieldWrapper>
    </div>
  );
};
