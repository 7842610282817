import React from "react";
import {
  DashboardPositionHeaderDetail,
  JobTypeResponse,
  ListedPosition,
  PositionDetail,
} from "../../../api/bluecrew/types";
import { getUser } from "../../../redux/selectors/auth";
import { getCertifications } from "../../../utility/getCertifications";
import {
  HandleArchivePositionType,
  HandleUpdateContinuousFillType,
  PositionsBox,
} from "../PositionsBox";
import { useAppSelector } from "../../../redux";

type PositionBoxesType = {
  jobTypes: JobTypeResponse["jobtypes"];
  // TODO: BW-1488 Update Type
  sortedDashboardPositions: ListedPosition[];
  handleArchivePosition: HandleArchivePositionType;
  updateContinuousFill: HandleUpdateContinuousFillType;
  positionNameSearchInput: string;
};

export const PositionBoxes = ({
  jobTypes,
  sortedDashboardPositions,
  handleArchivePosition,
  updateContinuousFill,
  positionNameSearchInput,
}: PositionBoxesType) => {
  const user = useAppSelector((state) => getUser(state));

  const filteredPositions = sortedDashboardPositions.filter(
    (position) => position.title.toLowerCase().indexOf(positionNameSearchInput.toLowerCase()) > -1,
  );

  return (
    <>
      {filteredPositions.map((position) => {
        const skill = jobTypes.find(({ id }) => id === position.job_type);
        const certifications = getCertifications(
          skill,
          // TODO: BW-1488 Update Type
          position as unknown as PositionDetail | DashboardPositionHeaderDetail,
        );

        return (
          <PositionsBox
            address={position.display_address}
            has_applications_to_review={position.has_applications_to_review}
            key={position.position_id}
            positionId={position.position_id}
            title={position.title}
            requestStats={position.requestStats}
            job_type_icon_name={position.job_type_icon_name}
            user={user.user}
            refillOn={position.refill_on}
            handleArchivePosition={handleArchivePosition}
            updateContinuousFill={updateContinuousFill}
            certifications={certifications}
          />
        );
      })}
    </>
  );
};
