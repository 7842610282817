import { handleActions } from "redux-actions";

import segmentActions from "../actions/segment";

import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

const handlerMap = {
  [segmentActions.identifyTraits.request]: (state: SegmentState) =>
    handleRequest("identifyTraits", state),
  [segmentActions.identifyTraits.success]: (state: SegmentState, action) =>
    handleSuccess("identifyTraits", state, action),
  [segmentActions.identifyTraits.failure]: (state: SegmentState, action) =>
    handleFailure("identifyTraits", state, action),
};

const initialState = {
  identifyTraits: { ...initialEndpointState },
};

export type SegmentState = typeof initialState;

export default handleActions<SegmentState>(handlerMap, initialState);
