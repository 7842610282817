import React from "react";
import {
  CreateShiftScreenText,
  StyledWageInput,
  WageSelectFieldContainer,
} from "../styledComponents";

export type WageSelectFieldProps = {
  setWage: (w: number) => void;
  wage?: number;
  placeholderWage?: string;
};

export const WageSelectField = ({ wage, setWage, placeholderWage }: WageSelectFieldProps) => {
  return (
    <WageSelectFieldContainer>
      <CreateShiftScreenText>Base Wage</CreateShiftScreenText>
      <StyledWageInput
        value={wage}
        placeholder={placeholderWage && `$${placeholderWage}`}
        onChange={(e) => e.value && setWage(e.value)}
        mode={"currency"}
        currency={"USD"}
      />
    </WageSelectFieldContainer>
  );
};
