import Cookies from "js-cookie";
import { BEARER_TOKEN_NAME, JWT_TOKEN_NAME } from "../../shared/constants";
import { CognitoUtils } from "./cognito";

export const getIsLoggedIn = () => {
  return CognitoUtils.getCognitoIdToken() != null;
};

export const legacyTokensExist = () => Boolean(getAuthToken() && getV1AuthToken());

export const getAuthToken = (): string => {
  return Cookies.get(JWT_TOKEN_NAME) ?? "";
};

export const getV1AuthToken = (): string => {
  return Cookies.get(BEARER_TOKEN_NAME) ?? "";
};
