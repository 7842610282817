import { Flex, Hidden } from "@bluecrew/web-react-core";
import styled from "styled-components";

export const App = styled(Hidden)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  height: 100vh;
  visiblity: visible;
  animation: fadein ease-out 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const AppContent = styled(Flex)`
  flex: 1;
  flex-flow: row nowrap;
  position: relative;
  // This makes AppContent the main scrollable panel of the app.
  // Be careful when changing this value. See MAIN_SCROLLABLE_PANEL.
  overflow-y: auto;
`;
