import React from "react";
import { connect } from "react-redux";
import { ContentColumn } from "./styledComponents";
import { CrewTabs } from "../../components/CrewTabs";
import {
  getApplicantsAcceptedOffers,
  getApplicantsDeclinedOffers,
  getApplicantsWithOffers,
  getApplicantsWithoutOffers,
  getCurrentTotalFilled,
  getCurrentTotalRequested,
} from "../../redux/selectors/schedule";
import { checkIfApplicationJobs } from "../../../shared/helpers";
import { FillStatus } from "../../components/FillStatus";

const mapStateToProps = (state) => ({
  applicantsWithOffers: getApplicantsWithOffers(state),
  applicantsWithoutOffers: getApplicantsWithoutOffers(state),
  applicantsAcceptedOffers: getApplicantsAcceptedOffers(state),
  applicantsDeclinedOffers: getApplicantsDeclinedOffers(state),
  totalRequested: getCurrentTotalRequested(state),
  totalFilled: getCurrentTotalFilled(state),
});

interface ColumnTwoContentProps extends ReturnType<typeof mapStateToProps> {
  timezone: string;
  request: Array<any>;
}

const ColumnTwoContent = ({
  applicantsWithOffers,
  applicantsWithoutOffers,
  applicantsAcceptedOffers,
  applicantsDeclinedOffers,
  totalRequested,
  totalFilled,
  timezone,
  request,
}: ColumnTwoContentProps) => {
  const hasApplicationJobs = checkIfApplicationJobs(request);

  return (
    <ContentColumn className="col-2">
      {/* Heading */}
      <div className="crew-heading">
        <span className="crew-text">Crew Members</span>
        <span className="crew-requested">{totalRequested} Requested</span>
      </div>

      {/* Fill Status component */}
      <div className="fill-status-container">
        <FillStatus
          classes="fill-status"
          workersNeeded={totalRequested}
          workersFilled={totalFilled}
          offersPending={applicantsWithOffers.length}
          hasApplicationJobs={hasApplicationJobs}
          applicantsWithoutOffers={applicantsWithoutOffers.length}
        />
      </div>

      {/* List of Crew accepted/declined */}
      <div className="crew-list">
        <CrewTabs
          timezone={timezone}
          accepted={applicantsAcceptedOffers}
          offers={applicantsWithOffers}
          declinedOffers={applicantsDeclinedOffers}
        />
      </div>
    </ContentColumn>
  );
};

export default connect(mapStateToProps, null)(ColumnTwoContent);
