import React, { Component } from "react";
import {
  FilterList,
  FilterListFilters,
  Button,
  Paragraph,
  FilterListInput,
  Link,
  Overlay,
  Portal,
  Backdrop,
  OfferJobModalContent,
  NoApplicants,
} from "@bluecrew/web-react-core";
import styled from "styled-components";
import { connect } from "react-redux";
import { get, head } from "lodash";
import jobActions from "../../redux/actions/job";
import { modalWidth } from "../../../styles/variables";
import { OFFER_JOB, REJECT_APPLICATION } from "../../../shared/constants";
import { assetUrl } from "../../api/bluecrew/constants";
import { Applicant } from "./Applicant";

const StyledFilterListFilters = styled(FilterListFilters)`
  // override justify content
  justify-content: unset;

  .filter-list-input {
    width: -webkit-fill-available;
  }

  ${Button} {
    ${Paragraph} {
      // xs font style
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

const StyledOverlay = styled(Overlay)`
  padding: 0; // Overwriting padding that was displaying as grey
  background-color: white; //background isn't white (default) for some reason
  width: ${modalWidth};

  .modal-footer {
    text-align: right;
    padding: 1.5rem;
    border-top: solid 1px #e8e9e9; // TODO: change to palette color (need to get from design)

    ${Button} {
      margin-left: 0.75rem;
    }
  }
`;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  offerJob: (payload) => dispatch(jobActions.offerJob.request(payload)),
  rejectApplicationJob: (payload) => dispatch(jobActions.rejectApplicationJob.request(payload)),
});

type ApplicantListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ApplicantList extends Component<ApplicantListProps> {
  state = {
    actionType: undefined,
    applicantsList: [],
    // @ts-ignore
    applicantArr: this.props.applicants,
    searchValue: "",
    showOfferJobModal: false,
    showRejectJobModal: false,
  };

  componentDidUpdate(prevProps) {
    // @ts-ignore
    if (prevProps.applicants !== this.props.applicants) {
      this.setState({
        // @ts-ignore
        applicantArr: this.props.applicants,
      });
    }
  }

  handleInputChange = (e) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
  };

  renderSortDropDownItems = () => (
    <>
      <Link>Most recent</Link>
      <Link>Ratings (High - Low)</Link>
      <Link>Shifts worked (Most- Least)</Link>
      <Link>First name (A - Z)</Link>
    </>
  );

  formatApplicantsForPayload = (applicants) =>
    applicants.map((applicant) => ({
      // using userId and jobId instead of applicant/workRequest because that is what backend is expecting
      userId: applicant.applicantId,
      jobId: applicant.workRequestId,
    }));

  handleRejectApplicant = () => {
    // @ts-ignore
    const { rejectApplicationJob, scheduleId } = this.props;
    const { applicantsList } = this.state;
    const formattedApplicantList = this.formatApplicantsForPayload(applicantsList);

    rejectApplicationJob({
      data: formattedApplicantList,
      scheduleId,
    });

    this.setState({ showRejectJobModal: false });
  };

  handleOfferJob = () => {
    // @ts-ignore
    const { offerJob, scheduleId } = this.props;
    const { applicantsList } = this.state;
    const formattedApplicantList = this.formatApplicantsForPayload(applicantsList);

    // offerJob here is the redux action that kicks off API call
    offerJob({
      data: formattedApplicantList,
      scheduleId,
    });

    this.setState({ showOfferJobModal: false });
  };

  openModal = (applicantId, workRequestId, fullName, actionType) => {
    const applicants = [
      {
        applicantId,
        workRequestId,
        fullName,
      },
    ];

    this.setState({
      showOfferJobModal: !!(actionType !== undefined && actionType === OFFER_JOB),
      showRejectJobModal: !!(actionType !== undefined && actionType === REJECT_APPLICATION),
      applicantsList: applicants,
    });
  };

  closeModal = () => {
    this.setState({
      showOfferJobModal: false,
      showRejectJobModal: false,
    });
  };

  renderApplicantCards = () => {
    // @ts-ignore
    const { timezone } = this.props;
    const { applicantArr, searchValue } = this.state;
    return applicantArr // filter applicants based on input
      .filter((applicant) => applicant.full_name.toLowerCase().includes(searchValue.toLowerCase()))
      .map((applicant) => (
        <Applicant // TODO: refactor applicant component to use entire user object vs passing individ props
          applicantId={applicant.id}
          applicationQuote={applicant.appeal}
          // @ts-ignore
          avatarUrl={applicant.avatar_url}
          // @ts-ignore
          externalId={applicant.external_id}
          fullName={applicant.full_name}
          key={applicant.id}
          ratingPercentage={applicant.rating}
          shiftsWorked={applicant.shifts_worked}
          workRequestId={applicant.jobId}
          workRequestDateStr={`${applicant.start_date} - ${applicant.end_date}`}
          openModal={this.openModal}
          timezone={timezone}
          covidPolicy={{
            covidExemption: applicant.covid_exemption_approved_date,
            covidTestingLatestDate: applicant.covid_testing_latest_date,
            covidVaccinationDate: applicant.covid_vaccination_date,
          }}
        />
      ));
  };

  render() {
    const { applicantArr, applicantsList, searchValue, showOfferJobModal, showRejectJobModal } =
      this.state;

    const buttonPalette = showOfferJobModal ? "primary" : "danger";
    const buttonText = showOfferJobModal ? "Yes, send an offer" : "Reject applicant";
    const shouldRenderModal = showOfferJobModal || showRejectJobModal;

    const fullName = get(applicantsList, "[0].fullName", "");
    const firstName = head(fullName.split(" "));

    const messageHeader = showOfferJobModal
      ? `Send ${firstName} an offer`
      : `Reject ${firstName}'s application`;
    const messageBody = showOfferJobModal
      ? "They’ll be able to accept or decline the offer after sending."
      : "";
    const icon = showOfferJobModal
      ? `${assetUrl}/icons/offer-job-icon.svg`
      : `${assetUrl}/icons/reject-application.svg`;

    return (
      <>
        {/* OFFER/REJECT JOB MODAL */}
        {shouldRenderModal && (
          <>
            <Portal>
              <Backdrop fade visible onClick={this.closeModal} />
              <StyledOverlay fade visible>
                <OfferJobModalContent
                  icon={icon}
                  messageHeader={messageHeader}
                  messageBody={messageBody}
                />
                <div className="modal-footer">
                  <Button palette="secondary" onClick={this.closeModal}>
                    Cancel
                  </Button>
                  <Button
                    palette={buttonPalette}
                    onClick={showOfferJobModal ? this.handleOfferJob : this.handleRejectApplicant}
                  >
                    {buttonText}
                  </Button>
                </div>
              </StyledOverlay>
            </Portal>
          </>
        )}

        {/* APPLICANT LIST */}
        <FilterList>
          <StyledFilterListFilters>
            <FilterListInput
              // @ts-ignore
              classes="filter-list-input"
              // @ts-ignore
              placeholder={"Search by name..."}
              // @ts-ignore
              onChange={this.handleInputChange}
              value={searchValue}
            />
            {/* TODO: uncomment when handling message and sort
            <Button palette="secondary">
            <Paragraph>
            // TODO: add message bubble icon
             Message all
            </Paragraph>
            </Button>
            <DropDownMenu
            buttonPalette="secondary"
            buttonText="Sort"
            displayType="with-text"
            >
            {this.renderSortDropDownItems()}
            </DropDownMenu>
            */}
          </StyledFilterListFilters>
          {/* TODO: add style wrapper to make content scroll-able  */}
          {applicantArr.length ? this.renderApplicantCards() : <NoApplicants />}
        </FilterList>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantList);
