import styled from "styled-components";

export const PayrollReportsPageBannerWrapper = styled.div`
  display: block;
  text-align: left;
  height: 70px;
  padding-top: 12px;
  top: 0px;
  background-color: ${({ theme }) => theme.colorTokens.white};
  padding-bottom: 0px;
`;
