import styled from "styled-components";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "../../../../../components/Calendar";
import { Button } from "../../../../../components/Button";
import { ShiftAdjustTimeActionsContainerClassName } from "./ShiftAdjustTimeActions";
import { Dialog } from "../../../../../components/Dialog";
import { InputNumber } from "../../../../../components/InputNumber";
import { HoverStyledButton } from "../../../styledComponents";

export const SyledModal = styled(Dialog)`
  width: 630px;
  .p-dialog-header {
    padding: 1rem 1rem 0 1rem;
  }
`;

export const HeaderJobInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const StyledCancelButton = styled(Button)`
  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    color: ${({ theme }) => theme.colors.accent};
  }
  :enabled:hover {
    border-color: ${({ theme }) => theme.colorTokens.white};
  }
`;

export const StyledConfirmButton = styled(HoverStyledButton)`
  background: ${({ theme }) => theme.colorTokens.blue400};

  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const EditModalDate = styled.div`
  font-family: ${({ theme }) => theme.typography.font350.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  line-height: ${({ theme }) => theme.typography.font350.lineHeight};
`;

export const EditModalHeaderText = styled.div`
  font-family: ${({ theme }) => theme.typography.font750.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font750.fontWeight};
  font-size: ${({ theme }) => theme.typography.font750.fontSize};
  line-height: ${({ theme }) => theme.typography.font750.lineHeight};
  font-style: normal;

  color: ${({ theme }) => theme.colorTokens.black};

  padding-bottom: 8px;
`;

export const EditModalSubHeaderText = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  line-height: ${({ theme }) => theme.typography.font300.lineHeight};

  color: ${({ theme }) => theme.colorTokens.gray500};
`;

export const AdjustmentReasonWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  align-content: center;
`;

export const BreakAddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const BreakAddButton = styled(Button)`
  width: 100px;
  height: 30px;
  margin-top: 1rem;
  padding: 0;
  background: ${({ theme }) => theme.colorTokens.blue400};
  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font150.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
    font-size: ${({ theme }) => theme.typography.font150.fontSize};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ShiftAdjustBodyText = styled.div`
  font-family: ${({ theme }) => theme.typography.font350.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  line-height: ${({ theme }) => theme.typography.font350.lineHeight};
  font-style: normal;
  min-width: 100px;
`;

export const ShiftAdjustBodyTimeText = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  line-height: ${({ theme }) => theme.typography.font300.lineHeight};
  font-style: normal;
`;

export const ShiftAdjustBreakCalendar = styled(Calendar)`
  min-width: 250px;
`;

export const ShiftAdjustBreakContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  width: 100%;
  :hover .${ShiftAdjustTimeActionsContainerClassName} {
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 1s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const ShiftAdjustTimeEditingContainer = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const ShiftAdjustTimeActionsContainer = styled.div<{ paddingTop: number }>`
  display: flex;
  min-width: 72px;
  margin-left: auto;
  justify-content: flex-end;
  padding-top: ${(props) => props.paddingTop}px;
  padding-left: 8px;
  align-items: center;
  opacity: 0;
`;

export const ShiftAdjustTimeConfirmedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ShiftAdjustTimeActionButton = styled(Button)`
  max-height: 36px;
  max-width: 36px;
`;

export const StyledTimeActionIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ShiftAdjustBreaksContainer = styled.div``;

export const BreakDurationInput = styled(InputNumber)`
  min-width: 100%;
`;

export const ShiftAdjustDatePicker = styled(Calendar)`
  min-width: 100%;
  .p-timepicker {
    display:none;
  }
  .p-inputtext {
    width: 100%;
  }
`

export const ShiftAdjustTimePickerContainer = styled.div`
  display: flex;
  padding-top: 8px;

  :hover .${ShiftAdjustTimeActionsContainerClassName} {
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 1s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const ShiftAdjustTimeInputContainer = styled.div`
  margin: auto;
  padding 8px 20% 8px 20%;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  border-top: 1px solid #ced4da;
`

export const ShiftAdjustInputNumber = styled(InputNumber)`
  margin: auto;
  .p-inputtext {
    width: 50px;
    text-align: center;
    line-height: 100%;
    border: 1px solid ${({ theme }) => theme.colorTokens.gray400};
  }
  .p-inputnumber-button {
    width: 50px;
    color: ${({ theme }) => theme.colorTokens.blue400};
    border: 0;
  }
  .p-icon {
    width: 20px;
    height: 20px;
  }
`

export const AmPmPickerDropdown = styled(Dropdown)`
  height: 45px;
  margin-top: 44px;
`

export const ShiftAdjustBreakTimeEditingContainer = styled.div`
  flex-direction: column;
`;

export const ShiftAdjustBreakEditingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ShiftAdjustBodyContainer = styled.div``;

export const ShiftAdjustTooltip = styled.div`
  color: ${({ theme }) => theme.colorTokens.red400};
  padding: 10px;
  font-family: ${({ theme }) => theme.typography.font100.fontFamily};
  text-align: center;
`
