import React from "react";
import { Text } from "../styledComponents";

type BlockWithBoldTextProps = {
  inputText: string;
};

export const BlockWithBoldText = ({ inputText }: BlockWithBoldTextProps) => {
  const [textBeforeBold, boldText, textAfterBold] = inputText.split("**");

  return (
    <Text>
      <span>{textBeforeBold}</span>
      <strong>{boldText}</strong>
      <span>{textAfterBold}</span>
    </Text>
  );
};
