/**
 * Confusingly, this is the second step of account activation, not password reseting.
 */

import React from "react";
import { useForm } from "react-hook-form";
import { PasswordField } from "../FormFields/PasswordField";
import { passwordIsValid } from "../CompanyPasswordRequirements/utils";
import { CompanyPasswordRequirements } from "../CompanyPasswordRequirements";
import { ValueOf } from "../../../../types/util-types";
import { StyledButton, StyledLoginFlowForm } from "../styledComponents";
import { BCButtonVariants } from "../../Button";
import { CommonFormProps } from "./types";
import { loginStrings } from "../strings";

export const FieldNames = {
  NewPassword: "password",
  ConfirmPassword: "confirmPassword",
} as const;
type FieldName = ValueOf<typeof FieldNames>;

const defaultValues = {
  [FieldNames.NewPassword]: "",
  [FieldNames.ConfirmPassword]: "",
};
type FormValueProps = typeof defaultValues;

export function ChangePasswordForm({
  onSubmit,
  children,
  onAnyFieldChange,
  submitIsLoading,
  footer,
}: {
  onSubmit: (newPassword: string) => void;
  submitIsLoading: boolean;
} & CommonFormProps) {
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    watch,
  } = useForm({ mode: "onChange", defaultValues });

  const onSubmitWrapper = ({ password }: FormValueProps) => {
    onSubmit(password);
  };

  const getError = (name: FieldName): string | undefined => formErrors[name]?.message;

  const { blurb, passwordLabel, confirmPasswordLabel, submitButtonText, submittingButtonText } =
    loginStrings.completeAccountActivation;

  return (
    <StyledLoginFlowForm onSubmit={handleSubmit(onSubmitWrapper)}>
      <div>{blurb}</div>
      <PasswordField
        fieldName={FieldNames.NewPassword}
        fieldLabel={passwordLabel}
        control={control}
        rules={{
          required: `${passwordLabel} is required`,
          validate: (value: string) => passwordIsValid(value),
        }}
        fieldError={getError(FieldNames.NewPassword)}
        onChange={onAnyFieldChange}
      />
      <PasswordField
        fieldName={FieldNames.ConfirmPassword}
        fieldLabel={confirmPasswordLabel}
        control={control}
        rules={{
          required: `${confirmPasswordLabel} is required`,
          validate: (value: string) => watch(FieldNames.ConfirmPassword) === value,
        }}
        fieldError={getError(FieldNames.ConfirmPassword)}
        onChange={onAnyFieldChange}
      />
      <CompanyPasswordRequirements
        password={watch(FieldNames.NewPassword)}
        confirmPassword={watch(FieldNames.ConfirmPassword)}
      />
      <div style={{ display: "grid", gap: "12px" }}>
        {children}
        <StyledButton
          variant={BCButtonVariants.Primary}
          label={submitIsLoading ? submittingButtonText : submitButtonText}
          type="submit"
        />
        {footer}
      </div>
    </StyledLoginFlowForm>
  );
}
