import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import PropTypes from "prop-types";

const sizes = {
  sm: 32,
  smd: 40,
  md: 48,
  lg: 64,
  xl: 80,
};

const StyleWrapper = styled.div<{ size: string }>`
  display: flex;
  justify-content: center;
  background-color: ${p("platinum")};
  border: none;
  border-radius: 50%;
  width: ${(props) => sizes[props.size]}px;
  height: ${(props) => sizes[props.size]}px;

  span {
    line-height: ${(props) => sizes[props.size]}px;
    color: ${p("white")} !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: ${(props) => 0.45 * sizes[props.size]}px;
  }
`;

export const UserInitials = (props) => {
  const initialsArr = props.fullName.split(" ").map((name) => name.substring(0, 1));

  return (
    <StyleWrapper {...props}>
      {/* limit display to only two and handle case if there is only the first name */}
      <span>{initialsArr.shift() + (initialsArr.pop() || "")}</span>
    </StyleWrapper>
  );
};

UserInitials.propTypes = {
  fullName: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["sm", "smd", "md", "lg", "xl"]),
};

UserInitials.defaultProps = {
  size: "sm",
  fullName: "A A",
};
