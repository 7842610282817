/* eslint-disable import/no-cycle */
import React from "react";
import { format } from "date-fns";
import { getAlternativeName, formatDateTZ } from "../utils";
import {
  UserColumnJobShiftSchedule,
  UserColumnJobShiftWrapper,
  UserColumnJobTimezone,
  UserColumnJobTitle
} from "./styledComponents";


export type AttributeJobInfoProps = {
    name: string,
    start: Date,
    end: Date,
    timezone: string,
}

export const AttributeJobInfo = ({name, start, end, timezone}: AttributeJobInfoProps) => {

  const formattedTimezone = getAlternativeName(timezone);

  const formattedStartTime = format(formatDateTZ(start, timezone), "h:mm a");
  const formattedEndTime = format(formatDateTZ(end, timezone), "h:mm a")

  return (
    <>
      <UserColumnJobTitle>{name}</UserColumnJobTitle>
      <UserColumnJobShiftWrapper>
        <UserColumnJobShiftSchedule>{`${formattedStartTime} – ${formattedEndTime}`}</UserColumnJobShiftSchedule>
        <UserColumnJobTimezone>{`(${formattedTimezone})`}</UserColumnJobTimezone>
      </UserColumnJobShiftWrapper>
    </>
  )
};
