import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { InvoicePreviewFileFormat, InvoicePreviewPayload, invoicePreview } from "../invoice";
import { getPayrollWeek } from "../payroll";
import { FileType, downloadFile } from "../../../utility/files";

export type InvoicePreviewMutationArgs = {
  invoiceStartDateOfWeek: Date;
  invoiceEndDateOfWeek: Date;
  companyName: string;
  companyExternalId: string;
  selectedPreviewRegion: string;
  selectedFileFormat: InvoicePreviewFileFormat;
};

export const useInvoicePreviewMutation = (
  onSuccess: () => void,
  onError: (message: string) => void,
) => {
  return useMutation(
    ["invoicePreview"],
    (props: InvoicePreviewMutationArgs) => {
      const apiV1Payload: InvoicePreviewPayload = {
        companyId: props.companyExternalId,
        week: getPayrollWeek(props.invoiceStartDateOfWeek),
        pullAllChildInvoices: props.selectedPreviewRegion === "all",
        fileFormat: props.selectedFileFormat,
      };

      if (props.invoiceStartDateOfWeek !== props.invoiceEndDateOfWeek) {
        apiV1Payload.weekEnd = getPayrollWeek(props.invoiceEndDateOfWeek);
      }
      return invoicePreview(apiV1Payload);
    },
    {
      onSuccess: (blobData, { selectedFileFormat, invoiceStartDateOfWeek, companyName }) => {
        const isCSV = selectedFileFormat === InvoicePreviewFileFormat.CSV;
        const sanitizedCompanyName = companyName.replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, "-");
        const formattedDate = format(invoiceStartDateOfWeek, "yyyy-MM-dd");
        const fileName = `${sanitizedCompanyName}-${formattedDate}${isCSV ? ".csv" : ".xlsx"}`;

        downloadFile({
          type: isCSV ? FileType.CSV : FileType.XML,
          fileName,
          blobData,
        });
        onSuccess();
      },
      onError: (error) => {
        const errorMessage =
          (error as any)?.response?.status === 404
            ? "No approved hours found for a given period"
            : "An error occurred. Please, try again.";
        onError(errorMessage);
      },
    },
  );
};
