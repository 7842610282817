import moment from "moment-timezone";
import { Address } from "./types/propTypes/PositionDetailsEdit.types";
import { Position, PositionRawData } from "./types/propTypes/PositionField.types";
import { ScheduleRawDataType, ShiftRaw } from "./types/propTypes/ScheduleField.types";

export const formatAddress = (address: Address) => ({
  // Disable rule for next line, because if we move values on the different lines
  // template string adds "/n" new line symbol
  displayAddress: `${address.display_address} ${address.postal_code}`,
  timezone: address.timezone,
  lat: Number(address.latitude),
  lng: Number(address.longitude),
  route: address.street_address_two,
  mainText: address.street_address,
  state: address.country_area,
  city: address.city,
  postalCode: address.postal_code,
  placeId: address.meta?.google_place_id,
});

export const mapPosition = (position: PositionRawData): Position => ({
  id: position.id,
  name: position.title,
  address: formatAddress(position.address),
  schedules:
    position.schedules &&
    position.schedules.map((schedule: ScheduleRawDataType) => ({
      id: schedule.id,
      name: schedule.name,
      scheduleType: schedule.schedule_type,
      workingHours: {
        formatted: `${schedule.start_time} - ${schedule.end_time} ${moment
          .tz(position.timezone)
          .format("z")}`,
        startTime: schedule.start_time_military,
        endTime: schedule.end_time_military,
      },
      workingDays: schedule.shifts.map((shift: ShiftRaw) => shift.day_of_week),
      totalWage: schedule.base_wage,
      positionWage: position.base_wage,
    })),
  timezone: position.timezone,
  baseWage: position.base_wage,
  jobTypeIcon: position.jobTypeIcon,
});
