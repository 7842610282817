import React, { Dispatch, SetStateAction } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Badge } from "primereact/badge";
import "./styledComponents.css";
import { TimecardStatusCounts, TimesheetTabs } from "../../types/Payroll.types";

export type TimesheetsTabsProps = {
  timecardStatusCounts?: TimecardStatusCounts;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
};

type TabContent = {
  order: number;
  text: string;
};

export const TimesheetsTabs = ({
  timecardStatusCounts = {pendingReview: 0, approved: 0, disputed: 0, removed: 0},
  activeIndex,
  setActiveIndex,
}: TimesheetsTabsProps) => {
  const tabsInfo: TabContent[] = [
    {
      order: TimesheetTabs.PENDING_REVIEW,
      text: "Pending Review",
    },
    {
      order: TimesheetTabs.APPROVED,
      text: "Approved",
    },
    {
      order: TimesheetTabs.DISPUTED,
      text: "Disputed",
    },
    {
      order: TimesheetTabs.REMOVED,
      text: "Removed",
    },
  ];
  const isPendingReviewTab = (tabInfo: TabContent) => tabInfo.order === TimesheetTabs.PENDING_REVIEW;
  const isDisputedTab = (tabInfo: TabContent) => tabInfo.order === TimesheetTabs.DISPUTED;
  const isApprovedTab = (tabInfo: TabContent) => tabInfo.order === TimesheetTabs.APPROVED;
  const isRemovedTab = (tabInfo: TabContent) => tabInfo.order === TimesheetTabs.REMOVED;
  const hasPendingReview = timecardStatusCounts.pendingReview > 0;
  const hasDisputed = timecardStatusCounts.disputed > 0;
  const hasApproved = timecardStatusCounts.approved > 0;
  const hasRemoved = timecardStatusCounts.removed > 0;

  return (
    <TabView
      data-testid="timesheets-table"
      activeIndex={activeIndex}
      onTabChange={(tabChangeEvent) => setActiveIndex(tabChangeEvent.index)}
    >
      {tabsInfo.map((tabInfo) => (
        <TabPanel
          className="p-unselectable-text"
          key={tabInfo.order}
          headerTemplate={
            <div
              className="p-tabview-nav-link timesheets-tab"
              onClick={() => setActiveIndex(tabInfo.order)}
            >
              <span className="p-tabview-title">{tabInfo.text}</span>
              {isPendingReviewTab(tabInfo) && hasPendingReview && (
                <Badge
                  className={`p-badge ${activeIndex===0 ? "pending-badge" : "inactive-badge"}`}
                  value={timecardStatusCounts.pendingReview}
                />
              )}
              {isDisputedTab(tabInfo) && hasDisputed && (
                <Badge
                  className={`p-badge ${activeIndex===2 ? "pending-badge" : "inactive-badge"}`}
                  value={timecardStatusCounts.disputed}
                />
              )}
              {isApprovedTab(tabInfo) && hasApproved && (
                <Badge
                  className={`p-badge ${activeIndex===1 ? "pending-badge" : "inactive-badge"}`}
                  value={timecardStatusCounts.approved}
                />
              )}
              {isRemovedTab(tabInfo) && hasRemoved && (
                <Badge
                  className={`p-badge ${activeIndex===3 ? "pending-badge" : "inactive-badge"}`}
                  value={timecardStatusCounts.removed}
                />
              )}
            </div>
          }
        ></TabPanel>
      ))}
    </TabView>
  );
};
