import { DaysOfWeek } from "@bluecrew/blueprint-web/src/components/Weekdays";
import { ListedPosition, Schedule } from "../../../api/bluecrew/types";
import {
  defaultFormValues,
  NEW_REPEATING_SCHEDULE,
  NEW_SINGLE_SHIFT,
} from "../../GetCrewMembers/formUtils";
import { ScheduleType } from "../../GetCrewMembers/types/propTypes/Schedule.types";
import { GetCrewFormProps, mapPositions } from "../GetCrewForm";
import { CrewMembers } from "../../GetCrewMembers/types/propTypes/ChooseCrewModal.types";
import { getScheduleNameFromDate } from "../../GetCrewMembers/GetCrewMemberScreen/ScheduleTabSection";
import { SupervisorType } from "../../../redux/selectors/job";
import { findPositionByScheduleId } from "./utils";
import { formatCrewValuesFromUrl, getCrewValuesFromUrl } from "./urlSearchParamUtils";

export const DEFAULT_GET_CREW_VALUES = {
  shiftDate: defaultFormValues.getCrew.date,
  startTime: defaultFormValues.getCrew.timeRange.startDateTime,
  endTime: defaultFormValues.getCrew.timeRange.endDateTime,
  scheduleType: ScheduleType.REPEATING,
  positionId: undefined,
  directInviteUserIds: undefined,
  schedule: undefined,
};

type GetGetCrewValuesType = {
  scheduleId: string | undefined;
  supervisors: GetCrewFormProps["supervisors"];
  crewMembers: GetCrewFormProps["crewMembers"];
  directInviteUserIds: string | string[] | null | undefined;
  crewValuesFromUrl: {
    scheduleType: ScheduleType;
    startTime: string;
    endTime: string;
    shiftDate: Date[];
    directInvitePositionId: string | undefined;
    positionId: number | undefined;
    preselectedSchedule: any;
  };
  userId: GetCrewFormProps["userId"];
  positions: GetCrewFormProps["positions"];
};

export const getGetCrewValues = ({
  scheduleId,
  supervisors,
  crewMembers,
  directInviteUserIds,
  crewValuesFromUrl,
  userId,
  positions,
}: GetGetCrewValuesType) => {
  const {
    scheduleType,
    startTime,
    endTime,
    shiftDate,
    directInvitePositionId,
    positionId: addScheduleToPositionId,
    preselectedSchedule,
  } = crewValuesFromUrl;

  let position;
  let schedule;
  let { workDays, scheduleName } = defaultFormValues.getCrew;

  // Redirect from Add Schedule
  if (addScheduleToPositionId) {
    position = mapPositions(positions).find(({ id }) => id === addScheduleToPositionId);
  }

  // Redirect from Add Crew To Schedule
  if (scheduleId) {
    position = findPositionByScheduleId(Number(scheduleId), positions);
    schedule = { id: Number(scheduleId) }; // TODO: Maybe add scheduleType here
  }

  // Open empty schedule to fill in
  if (addScheduleToPositionId) {
    schedule = NEW_REPEATING_SCHEDULE;
  }
  // Remove prefilled position and schedule when finished
  if (!scheduleId && !addScheduleToPositionId) {
    position = null;
    schedule = null;
  }
  if (preselectedSchedule) {
    schedule = preselectedSchedule;
    scheduleName = preselectedSchedule.name;
  }

  const supervisor = supervisors.find((supervisor) => supervisor.id === userId) || supervisors[0];

  let crew: CrewMembers = [];
  if (directInviteUserIds) {
    crew = crewMembers.filter(({ externalId }) => directInviteUserIds.includes(externalId));
  }

  if (directInvitePositionId) {
    position = mapPositions(positions).find((p) => p.id === Number(directInvitePositionId));
  }

  if (scheduleType === ScheduleType.SINGLE_SHIFT) {
    if (shiftDate.length) {
      const day = shiftDate[0].getDay();
      workDays = [`${day}`] as unknown as DaysOfWeek;
      scheduleName = getScheduleNameFromDate(shiftDate[0]);
    }

    schedule = {
      ...NEW_SINGLE_SHIFT,
      workingHours: { formatted: "", startTime, endTime },
      workingDays: workDays,
      name: scheduleName,
    };
  }

  const timeRange = { startDateTime: startTime, endDateTime: endTime };

  const getCrewValues = {
    ...defaultFormValues.getCrew,
    position,
    schedule,
    supervisor,
    crew,
    scheduleType: schedule ? schedule.scheduleType || scheduleType : scheduleType,
    timeRange,
    date: shiftDate,
    workDays,
    scheduleName,
  };

  return getCrewValues;
};

type GetGetCrewFormValuesType = {
  scheduleId: string | undefined;
  isBulkSingleShiftEnabled: boolean;
  supervisors: SupervisorType[];
  crewMembers: {
    name: string;
    externalId: string;
    shifts: string;
    id: number;
    lastWorkedDate: string | null;
    lastWorkedPositionTitle: string | null;
    imgUrl?: string;
  }[];
  userId: number;
  positions: Array<ListedPosition>;
  preselectedScheduleData: Schedule | undefined;
};

/**
 * This function is used to get the values for the GetCrewForm
 * It will get the values from the url and format them
 * Then it will add the rest of the data needed for the form
 */
export function getGetCrewFormValues({
  preselectedScheduleData,
  isBulkSingleShiftEnabled,
  supervisors,
  crewMembers,
  userId,
  positions,
  scheduleId,
}: GetGetCrewFormValuesType) {
  const {
    scheduleType,
    positionId,
    startTime,
    endTime,
    startShiftDate,
    endShiftDate,
    singleShiftDatesFromUrl,
    directInviteUserIds,
    directInvitePositionId,
  } = getCrewValuesFromUrl();

  const formattedCrewValuesFromUrl = formatCrewValuesFromUrl({
    scheduleType,
    positionId,
    startTime,
    endTime,
    startShiftDate,
    endShiftDate,
    singleShiftDatesFromUrl,
    isBulkSingleShiftEnabled,
    directInviteUserIds,
    directInvitePositionId,
    preselectedScheduleData,
    scheduleId,
  });

  const getCrewValues = getGetCrewValues({
    scheduleId,
    supervisors,
    crewMembers,
    directInviteUserIds,
    crewValuesFromUrl: formattedCrewValuesFromUrl,
    userId,
    positions,
  });

  return getCrewValues;
}
