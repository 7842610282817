import React from "react";
import { SuccessFailureComponent } from "./SuccessFailureComponent";
import { Dialog } from "../Dialog";

export interface SuccessFailureModalProps {
  title: string;
  text?: string | string[]; // Single string of text, or series of lines. (Lines will be separated with newlines)
  visible: boolean;
  onButtonClick: () => void;
  isSuccess?: boolean;
  children?: React.ReactNode;
}

export const SuccessFailureModal = (props: SuccessFailureModalProps) => {
  const { visible, onButtonClick } = props;
  const onHide = () => {
    onButtonClick();
  };
  return (
    <Dialog
      data-testid={"success-failure-modal"}
      closable={false}
      onHide={onHide}
      visible={visible}
      contentStyle={{ width: "100%" }}
    >
      <SuccessFailureComponent {...props} />
    </Dialog>
  );
};
