// Legacy reakit (0.15.3) adapted for current reakit (v1)
// Note that ariakit (v2) is in alpha
// In 0.15.3 seems to use @emotion/is-prop-valid for types

import styled from "styled-components";
import s from "styled-selector";
import { theme, withProp, palette as p } from "styled-tools";

import numberToPx from "./numberToPx";
import { Box, BaseStyleCss, BaseStyleProps } from "./Box";

export const Block = styled.div<BaseStyleProps>`
  ${BaseStyleCss};
  display: block;
  ${theme("Block")};
`;

export { Box };

export const Button = styled.button<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Button")}
`;

export const CardFit = styled.div<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("CardFit")};
`;

interface CardProps extends BaseStyleProps {
  gutter?: number | string;
}

// used once in the login page - and not easy to get out otherwise.
// https://github.com/styled-components/styled-components/issues/1209
export const Card = styled.div<CardProps>`
  ${BaseStyleCss};
  display: inline-block;
  && > *:not(${s(CardFit)}) {
    margin: ${withProp("gutter", numberToPx)};
  }
  ${theme("Card")};
`;

Card.defaultProps = {
  gutter: "1rem",
  opaque: true,
  palette: "background",
  tone: -1,
};

export * from "./Divider";

export const Field = styled.div<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Field")};
`;

export * from "./Flex";

export * from "./Grid";

export * from "./Group";

export const H2Heading = styled.h2<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Heading")};
`;

export const H3Heading = styled.h3<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Heading")};
`;

export const H4Heading = styled.h4<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Heading")};
`;

export const Heading = styled.h1<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Heading")};
`;

export * from "./Hidden";

export const Image = styled.img<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Image")};
`;

export const InlineBlock = styled.div<BaseStyleProps>`
  ${BaseStyleCss};
  display: inline-block;
  ${theme("InlineBlock")};
`;

export const Input = styled.input<BaseStyleProps>`
  ${BaseStyleCss}
  ${theme("Input")};
`;

Input.defaultProps = {
  type: "text",
  opaque: true,
  palette: "white",
};

export const Label = styled.label<BaseStyleProps>`
  ${BaseStyleCss};
  display: inline-block;
  ${theme("Label")};
`;

export const Link = styled.a<BaseStyleProps>`
  ${BaseStyleCss};
  ${theme("Link")};
`;

Link.defaultProps = {
  palette: "primary",
};

export const Paragraph = styled.p<BaseStyleProps>`
  ${BaseStyleCss};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${p("platinum")};
  ${theme("Paragraph")};
`;

export const Table = styled.table`
  ${BaseStyleCss};
  ${theme("Table")};
`;

export * from "./Tabs";

export * from "./Toolbar";
