import { createSelector } from "reselect";
import get from "lodash/get";
import { StateShape } from "../reducers";

const getIdentifyTraits = (state: StateShape) => get(state, "segment.identifyTraits.success");

export const canPostCompanyJobs = createSelector(getIdentifyTraits, (traits): boolean => {
  // @ts-ignore TODO: BW-1482 Fix this error
  const { can_post_jobs: canPostJobs } = traits || {};
  return canPostJobs === null || canPostJobs === undefined ? true : !!canPostJobs; // TODO: what in the world is this doing?
});

export const sessionFetched = (state: StateShape) =>
  !!state.segment.identifyTraits.lastUpdate && !state.segment.identifyTraits.isFetching;
