import React, { useState } from "react";
import { useThemedStyletron } from "@bluecrew/blueprint-web";
import { GoogleMap, GoogleMapProps, Circle, CircleProps } from "@react-google-maps/api";
import { EditPositionGeofenceProps } from "../types/propTypes/PositionDetailsEdit.types";

export const DEFAULT_WARN_RADIUS_M = 250;
export const DEFAULT_BLOCK_RADIUS_M = 100_000;

export const MIN_RADIUS_VALUE_M = 250;

export const MIN_BLOCK_RADIUS_M = 8000;

export const MIN_BLOCK_WARN_RADIUS_DIFF_M = 3000;
export const MAX_RADIUS_VALUE_KM = 10_000_000;

// This component assumes the Google API is loaded elsewhere (in some parent component).
export const EditPositionGeofence = ({
  center,
  warnRadius = DEFAULT_WARN_RADIUS_M,
  onChangeWarnRadius,
  blockRadius = DEFAULT_BLOCK_RADIUS_M,
  onChangeBlockRadius,
  mapWidth = "500px",
  mapHeight = "500px",
}: EditPositionGeofenceProps) => {
  /*
   * Google Maps runs 0 ~ 18 with 0 as the lowest (whole world)
   * and each step up zooming in 2x.
   */
  const calculateZoom = (radius: number) => 15 - Math.log(radius / 500) / Math.log(2);

  return (
    <Map
      zoom={calculateZoom(blockRadius)}
      center={center}
      width={mapWidth}
      height={mapHeight}
      warnCircleOptions={{
        center,
        radius: warnRadius,
      }}
      onChangeWarnRadius={onChangeWarnRadius}
      blockCircleOptions={{
        center,
        radius: blockRadius,
      }}
      onChangeBlockRadius={onChangeBlockRadius}
    />
  );
};

interface MapProps extends GoogleMapProps {
  width: string;
  height: string;
  center: google.maps.LatLngLiteral;
  zoom: number;
  warnCircleOptions: CirclePropsWithRadius;
  onChangeWarnRadius: (e: number) => void;
  blockCircleOptions: CirclePropsWithRadius;
  onChangeBlockRadius: (e: number) => void;
}

interface CirclePropsWithRadius extends CircleProps {
  radius: number;
}

const Map = ({
  zoom,
  center,
  width,
  height,
  warnCircleOptions: warnCircleOptionOverrides,
  onChangeWarnRadius,
  blockCircleOptions: blockCircleOptionOverrides,
  onChangeBlockRadius,
}: MapProps) => {
  const [, theme] = useThemedStyletron();
  const [warnCircle, setWarnCircle] = useState<google.maps.Circle>();
  const [blockCircle, setBlockCircle] = useState<google.maps.Circle>();

  const warnCircleOptions = {
    options: {
      strokeWeight: 2,
      strokeColor: "#FFA500",
      fillColor: "#00FF00",
      fillOpacity: 0.4,
      editable: true,
      draggable: false,
    },
    ...warnCircleOptionOverrides,
  };

  const blockCircleOptions = {
    options: {
      strokeWeight: 2,
      strokeColor: "#FF0000",
      fillColor: "#FFA500",
      fillOpacity: 0.3,
      editable: true,
      draggable: false,
    },
    ...blockCircleOptionOverrides,
  };

  const handleWarnRadiusChange = () => {
    const hasRadiusChanged =
      JSON.stringify(warnCircle?.getRadius()) !== JSON.stringify(warnCircleOptions.radius);
    if (warnCircle && hasRadiusChanged) {
      const warnRadiusWithinAcceptableBounds =
        warnCircle.getRadius() >= MIN_RADIUS_VALUE_M &&
        warnCircle.getRadius() < blockCircleOptions.radius;
      const warnRadiusEqualBlockRadius = warnCircle.getRadius() === blockCircleOptions.radius;

      if (warnRadiusWithinAcceptableBounds) {
        onChangeWarnRadius(warnCircle.getRadius());
      } else if (warnRadiusEqualBlockRadius) {
        onChangeWarnRadius(warnCircle.getRadius() - 1);
      } else {
        warnCircle.setRadius(
          Math.max(
            MIN_RADIUS_VALUE_M,
            Math.min(warnCircle.getRadius(), blockCircleOptions.radius!),
          ),
        );
      }
    }
  };

  const handleBlockRadiusChange = () => {
    const hasRadiusChanged =
      JSON.stringify(blockCircle?.getRadius()) !== JSON.stringify(blockCircleOptions.radius);

    if (blockCircle && hasRadiusChanged) {
      const blockCircleWithinAcceptableBounds =
        blockCircle.getRadius() > warnCircleOptions.radius &&
        blockCircle.getRadius() <= MAX_RADIUS_VALUE_KM;
      const blockRadiusEqualWarnRadius = blockCircle.getRadius() === warnCircleOptions.radius;

      if (blockCircleWithinAcceptableBounds) {
        onChangeBlockRadius(blockCircle.getRadius());
      } else if (blockRadiusEqualWarnRadius) {
        onChangeBlockRadius(blockCircle.getRadius() + 1);
      } else {
        blockCircle.setRadius(
          Math.min(
            MAX_RADIUS_VALUE_KM,
            Math.max(blockCircle.getRadius(), warnCircleOptions.radius!),
          ),
        );
      }
    }
  };

  const hasWarnCircleCenterChanged = () =>
    warnCircle && JSON.stringify(warnCircle?.getCenter()?.toJSON()) !== JSON.stringify(center);
  const hasBlockCircleCenterChanged = () =>
    blockCircle && JSON.stringify(blockCircle?.getCenter()?.toJSON()) !== JSON.stringify(center);

  return (
    <GoogleMap
      zoom={zoom}
      center={center}
      mapContainerStyle={{
        width,
        height,
        borderRadius: theme.sizing.scale200,
        borderColor: theme.colors.pebble,
      }}
    >
      <Circle
        options={warnCircleOptions}
        onLoad={(c: google.maps.Circle) => setWarnCircle(c)}
        onCenterChanged={() => hasWarnCircleCenterChanged() && warnCircle?.setCenter(center)}
        onRadiusChanged={() => handleWarnRadiusChange()}
      />
      <Circle
        options={blockCircleOptions}
        onLoad={(c: google.maps.Circle) => setBlockCircle(c)}
        onCenterChanged={() => hasBlockCircleCenterChanged() && blockCircle?.setCenter(center)}
        onRadiusChanged={() => handleBlockRadiusChange()}
      />
    </GoogleMap>
  );
};
