import styled from "styled-components";
import { Button } from "../../../../../components/Button";

export const ActionButton = styled(Button)`
  max-height: 36px;
  max-width: 36px;
  .p-button-icon {
    width: 16px;
    height: 16px;
  }
`;

export const ActionButtonIcon = styled.img``;
