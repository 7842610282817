import React from "react";
import { connect } from "react-redux";
import { ApplicantList } from "../../components/ApplicantList";
import { StateShape } from "../../redux/reducers";
import { getApplicantsWithoutOffers } from "../../redux/selectors/schedule";
import { ContentColumn } from "./styledComponents";

const mapStateToProps = (state: StateShape) => ({
  applicantsWithoutOffers: getApplicantsWithoutOffers(state),
});

interface ColumnOneContentProps extends ReturnType<typeof mapStateToProps> {
  scheduleId: string;
  timezone: string;
}

const ColumnOneContent = ({
  applicantsWithoutOffers,
  scheduleId,
  timezone,
}: ColumnOneContentProps) => (
  <ContentColumn className="col-1">
    <ApplicantList
      // @ts-ignore
      applicants={applicantsWithoutOffers}
      scheduleId={scheduleId}
      timezone={timezone}
    />
  </ContentColumn>
);

export default connect(mapStateToProps, null)(ColumnOneContent);
