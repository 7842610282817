import React from "react";
import { AssignCompaniesCheckbox, AssignCompaniesIcon } from "./styledComponents";
import { UserCompanyInfo } from "../../../api/bluecrew/types";
import { truncateName } from "../../../api/bluecrew/company";

type AssignCompaniesCheckboxProps = {
  company: UserCompanyInfo;
  isChecked: boolean;
  onSelectionChange: (company: UserCompanyInfo) => void;
};

export const AssignCompaniesItem = ({
  company,
  isChecked,
  onSelectionChange,
}: AssignCompaniesCheckboxProps) => {

  return (
    <AssignCompaniesCheckbox
      data-testid={`assign-companies-checkbox-${company.companyId}`}
      onClick={() => {
        onSelectionChange(company);
      }}
      isChecked={isChecked}
    >
      {`${truncateName(company.companyName)} - ${company.companyId}`}
      <AssignCompaniesIcon isChecked={isChecked} />
    </AssignCompaniesCheckbox>
  );
};
