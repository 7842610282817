import styled from "styled-components";
import { Flex } from "@bluecrew/web-react-core";

export const FlexRow = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  * {
    margin: 0.5rem;
    justify-content: space-around;
    border-radius: 0.25rem;
  }
`;

export { FlexRow as FilterListFilters };
