// AUTH - ACTIONS
// =============================================================================

import { createActions } from "redux-actions";

import { TokenMap, TokenRequest, LoginRequest, TokenResponse } from "../../api/bluecrew/types";
import { createAuthCookies } from "../../utility";

export type TokenRequestPayload = TokenRequest & {
  token: TokenMap;
};

export type TokenRequestAction = {
  type: string;
  payload: TokenRequestPayload;
};

export default createActions({
  LOGOUT: {
    REQUEST: (req) => req,
    SUCCESS: () => null,
  },
  LOGIN: {
    REQUEST: (req: LoginRequest) => req,
    SUCCESS: (res: TokenResponse) => {
      // TODO: put this side effect in a more predictable place, e.g. saga
      // NOTE: this function is called in multiple places.
      createAuthCookies({ tokens: res.tokens, expireInDays: undefined });

      return res;
    },
    DESTROY: () => null,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  TOKEN: {
    REQUEST: (req: TokenRequest, token: TokenMap): TokenRequestPayload => ({ ...req, token }),
  },
  USER_DETAILS: {
    REQUEST: undefined,
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
}) as any;
