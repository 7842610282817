// ============================================================================
//                  Shared Strings and String builders
// ============================================================================

// TODO:(BW-1181) Migrate these strings to the existing file in 'shared/messages.tsx'

import { GeofenceDefaults } from "../../../shared/constants";
import { AdvancedBackgroundCheckTypes, DrugScreeningTypes } from "../../api/bluecrew/types/index";

export const UpdateCompanySettingsSuccessMessage = `Company settings successfully updated!`;
export const UpdateCompanySettingsFailureMessage = `An error occurred while updating company settings -- Please try again later`;
export const UpdateCompanySettingsInputErrorMessage = `Please enter a valid phone number`;

// NOTE: Currently none of the custom messages for each section being used
//       This could change if refactoring occurs. For now they live here and are not used.
const baseSuccessMsg = (section: string) => `${section} updated sucessfully`;
const baseFailureMsg = (section: string) =>
  `An error occurred while updating the ${section} -- try again later`;

export const commonButtonStrings = {
  resetToDefaults: "Reset to defaults",
  update: "Update",
  cancel: "Cancel",
};

// ============================================================================
//                      General Info (Name & Description)
// ============================================================================

export const generalInfoStrings = {
  title: "Company Info",
  labels: {
    name: "Company Name",
    description: "Company Description",
    saveButton: "Update Company Info",
    phoneNumber: "Phone Number",
  },
  update: {
    success: baseSuccessMsg("Company info"),
    failure: baseFailureMsg("company info"),
  },
  linkedCompaniesModal: {
    title: "Parent Company",
    subTitle: "Linked Companies",
    noCompaniesMessage: "Parent Company has no Linked Companies",
    openModalText: "Linked Companies",
  },
};

// ============================================================================
//                            Covid Policy Setting
// ============================================================================
// NOTE: Some covid strings are commented out. These strings would be served if the
// 'covid19-workplace-policy-settings' split was configured to serve them.
// However, the split is set to serve preOsha to ALL users.
// They have been added here in case the split is ever modified to serve postOsha to any users.
export const covidPolicyStrings = {
  title: "COVID-19 vaccination requirement",
  modal: {
    header:
      "Crew Members without proof of vaccination or an approved accommodation will be removed from assignments once this change goes into effect",
    text: " Crew Members currently do not meet the updated requirement. They will be notified of the updated requirement and will have until shortly before their next shift to update their vaccination status.",
  },
  options: {
    notRequired: {
      label: "Vaccination not required",
      text: "",
      value: "NO_POLICY",
      // postOshaValue: "VACCINE_OR_WEEKLY_TESTING"
      // postOshaText: postOsha Vaccination not required: Crew Members can be fully vaccinated or undergo weekly COVID testing
    },
    required: {
      label: "Vaccination required: ",
      text: "Crew Members must either be fully vaccinated or have a documented medical or religious exemption. Bluecrew collects and verifies proof of vaccination and requests for exemption.",
      value: "VACCINE_OR_EXEMPTION",
      // postOshaValue: "VACCINE_OR_EXEMPTION_AND_WEEKLY_TESTING"
      // postOshaText: Crew Members must either be fully vaccinated or have a documented medical or religious exemption and undergo weekly COVID testing. Bluecrew collects and verifies proof of vaccination or request for exemption
    },
  },
  labels: {
    saveButton: "Update vaccination requirement",
  },
  update: {
    success: "Covid policy updated successfully",
    failure: "An error occurred while updating the covid policy -- try again later",
  },
  upcomingShifts: "upcoming shifts",
};

// ============================================================================
//                        Continuous fill Settings
// ============================================================================
export const continuousFillStrings = {
  title: "Continuous fill",
  labels: {
    saveButton: "Update Continous Fill",
    on: "Turn on for new positions: ",
    off: "Turn off for new positions: ",
  },
  descriptions: {
    general:
      "Increase fill rates by continuing to fill openings even after jobs have started. Openings will refill starting on the next scheduled shift.",
    on: "You can override the default by disabling the continuous fill toggle on any position from your dashboard",
    off: "You can override the default by enabling the continuous fill toggle on any position from your dashboard.",
  },
  update: {
    success: baseSuccessMsg("Continuous fill setting"),
    failure: baseFailureMsg("continuous fill setting"),
  },
};

// ============================================================================
//                           Geofence Settings
// ============================================================================

export const geofenceStrings = {
  title: "Timestamp geofencing",
  subTitles: {
    companyLevel: "Company-level settings",
    positionLevel: "Position-level settings",
  },
  headers: {
    onsitePolicy: "Company-level Onsite distance",
    offsitePolicy: "Company-level Offsite distance",
  },
  descriptions: {
    companyLevel:
      "Geofence settings are used to compare a Crew Member's location to the work location when they clock in or out. This helps to validate timestamps. Set your company-level geofencing values below. They will be used as defaults for all new positions. **These settings do not change existing Positions.** You can update the values for existing Positions on its Position Detail page.",
    // As of 10/2/22 The position level description has a link embeded in it, using 'Advanced Settings' as the link text
    // this is why there are '|' embedded in the string, so it can be split apart easily.
    positionLevel:
      "Company-level defaults can be overridden on the Position Detail page by using |Advanced Settings| when creating or editing Positions.",
    onsitePolicy:
      "For Crew Members you expect to clock-in/out at a specific worksite. **Applies only to newly created Positions.** You can update the values for existing Positions on its Position Detail page.",
    offsitePolicy:
      "For Crew Members who work in a wider area & do not need to clock-in/out near the worksite. These distances apply to newly created Positions using the “Driver” or “Delivery & Pickup” skill sets. **Applies only to newly created Positions.** You can update the values for existing Positions on its Position Detail page.",
  },
  labels: {
    saveButton: "Update geofence settings",
    warn: "Warn at",
    block: "Block at",
  },
  validationWarnings: {
    tooBig: `Must be less than ${GeofenceDefaults.maxRadiusMeters / 1000 + 1} km`,
    tooSmall: `Must be greater than ${GeofenceDefaults.minRadiusMeters - 1} m`,
    tooSmallBlockRadius: `Must be greater than ${GeofenceDefaults.minBlockRadiusMeters - 1} m`,
    warnMustBeLessThanBlock: `Must be less than block distance for ${GeofenceDefaults.minWarnBlockRadiusDifferenceMeters} m`,
    blockMustBeGreaterThanOrEq: `Must be greater than or equal to warn distance ${GeofenceDefaults.minWarnBlockRadiusDifferenceMeters} m`,
  },
  update: {
    success: baseSuccessMsg("Geofence settings"),
    failure: baseFailureMsg("geofence settings"),
  },
};

// ============================================================================
//                            Timesheets settings
// ============================================================================

export const timesheetsStrings = {
  title: "Timesheets Settings",
  description:
    "Use the following settings to create rules that automatically flag deviating Crew Member timestamps. Flagged timestamps will be isolated for your review.",
  labels: {
    default_lunch_length: "Default Lunch Length",
    flag_as_late_buffer: "Late clock-out buffer",
    early_clockin: "Early clock-in buffer",
    terminal_mode: "Terminal Mode",
    save_button: "Update timesheets settings",
    pendo_terminal_mode: "Timesheets Terminal Mode Value & Timesheets Settings value",
    pendo_timesheets: "Timesheets Settings value",
    auto_approve: "Auto-approve",
    third_party_timetracker: "Third-party timetracker",
  },
  update: {
    success: baseSuccessMsg("Timesheets settings"),
    failure: baseFailureMsg("timesheets settings"),
  },
};

// ============================================================================
//                       Advanced Background Check settings
// ============================================================================

const backgroundCheckTypeText = (companyName: String, backgroundCheckTypeNumber: number) => {
  const strings: { [index: number]: string } = {
    [AdvancedBackgroundCheckTypes.DISABLED]:
      "No advanced background check. A basic background check will still be conducted, as required by Bluecrew.",
    [AdvancedBackgroundCheckTypes.BASIC_CRIMINAL_WITH_THREE_ALIASES]: `**Tasker Basic with 3 Aliases** background check is enabled for ${companyName}. Requiring background check affects the fill rate. Background checks may take 1-3 days to complete.`,
    [AdvancedBackgroundCheckTypes.TASKER_PRO]: `**Tasker Pro** background check is enabled for ${companyName}. Requiring background check affects the fill rate. Background checks may take 1-3 days to complete.`,
    [AdvancedBackgroundCheckTypes.ESSENTIAL_CRIMINAL_WITH_ALIASES]: `**Essential Criminal (Tasker Pro) with Aliases 10 year Look back**  background check is enabled for ${companyName}. Requiring background check affects the fill rate. Background checks may take 1-3 days to complete.`,
    [AdvancedBackgroundCheckTypes.ESSENTIAL_CRIMINAL_WITH_UNLIMITED_ALIASES]: `**Essential Criminal (Tasker Pro) with Unlimited Aliases plus Federal and State with 10yr Look back** background check is enabled for ${companyName}. Requiring background check affects the fill rate. Background checks may take 1-3 days to complete.`,
    [AdvancedBackgroundCheckTypes.ESSENTIAL_CRIMINAL_WITH_THREE_ALIASES_SEVEN_YEAR]: `**Essential Criminal (Tasker Pro) with 3 Aliases and 7 year Look back** background check is enabled for ${companyName}. Requiring background check affects the fill rate. Background checks may take 1-3 days to complete.`,
    [AdvancedBackgroundCheckTypes.ESSENTIAL_CRIMINAL_PLUS_FEDERAL]: `**Essential Criminal (Tasker Pro) plus Federal with 3 Aliases 7 Year Look back** background check is enabled for ${companyName}. Requiring background check affects the fill rate. Background checks may take 1-3 days to complete.`,
  };

  return strings[backgroundCheckTypeNumber];
};

export const backgroundCheckStrings = (companyName: String, backgroundCheckType: number) => {
  return {
    title: "Advanced background check",
    options: {
      enabled: {
        label: "Enabled for your company",
        text: backgroundCheckTypeText(companyName, backgroundCheckType),
        value: backgroundCheckType,
      },
      disabled: {
        label: "Disabled",
        text: "No advanced background check. A basic background check will still be conducted, as required by Bluecrew.",
        value: AdvancedBackgroundCheckTypes.DISABLED,
      },
    },
    labels: {
      updateButtonText: "Update background check settings",
    },
  };
};

// ============================================================================
//                       Drug Screening settings
// ============================================================================

const drugScreeningTypeText = (companyName: String, drugScreeningTypeNumber: number) => {
  const strings: { [index: number]: string } = {
    [DrugScreeningTypes.DISABLED]: `Not enabled. Contact your account admin to change this status.`,
    [DrugScreeningTypes.PANEL_5_NO_THC]: `${companyName} has 5-panel no THC drug screening level enabled at the contract level. Contact your account admin to change this status.`,
    [DrugScreeningTypes.PANEL_5]: `${companyName} has 5-panel drug screening level enabled at the contract level. Contact your account admin to change this status.`,
    [DrugScreeningTypes.PANEL_6]: `${companyName} has 6-panel drug screening level enabled at the contract level. Contact your account admin to change this status.`,
    [DrugScreeningTypes.PANEL_10]: `${companyName} has 10-panel drug screening level enabled at the contract level. Contact your account admin to change this status.`,
  };

  return strings[drugScreeningTypeNumber];
};

export const getDrugScreeningStrings = (drugScreeningType: number, companyName: String) => {
  return {
    title: "Drug screening",
    options: {
      enabled: {
        label: "Enabled for your company",
        text: drugScreeningTypeText(companyName, drugScreeningType),
        value: drugScreeningType,
      },
      disabled: {
        label: "Disabled",
        text: "", // Figma doesn't have any text for when it's disabled.
        value: DrugScreeningTypes.DISABLED,
      },
    },
    labels: {
      updateButtonText: "Update drug screening settings",
    },
  };
};
