import React from "react";
import { Message, MessageProps } from "./Message";
import { Collapse } from "./Collapse";
import { Prettify, ValueOf } from "../../types/util-types";

export const MessageAlignment = {
  Left: "left",
  Center: "center",
  Right: "right",
} as const;

export type CollapsibleMessageProps = Prettify<MessageProps> & {
  isOpen: boolean;
  alignment?: ValueOf<typeof MessageAlignment>;
  style?: React.CSSProperties;
};

export const CollapsibleMessage = ({
  isOpen,
  alignment = MessageAlignment.Center,
  style,
  ...props
}: CollapsibleMessageProps) => {
  return (
    <Collapse isOpen={isOpen} style={style}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: alignment,
        }}
      >
        <Message {...props} />
      </div>
    </Collapse>
  );
};
