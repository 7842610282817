import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { styled } from 'baseui';
import { Property } from 'csstype';
import { GoogleMapMarkerIconSvg, MapPlaceholderIcon } from '../assets';
import { BlueprintTheme, useThemedStyletron } from '../theme';

type Coordinates = {
  lat: number;
  lng: number;
};

export enum MapType {
  worksiteAddress,
  reviewOrder,
}

type StaticMapProps = {
  type?: MapType;
  isMarkerShown: boolean;
  position?: Coordinates;
  width?: string;
  height?: string;
};

const mapOptions = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  scrollwheel: false,
  draggable: false,
};

const StaticMapComponent = ({
  type = MapType.worksiteAddress,
  isMarkerShown = false,
  position,
  width = '500px',
  height = '120px',
}: StaticMapProps) => {
  const [, theme] = useThemedStyletron();

  const MarkerIcon = {
    url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(
      GoogleMapMarkerIconSvg,
    )}`,
  };

  return position ? (
    <GoogleMap
      mapContainerStyle={{
        width,
        height,
        ...(type === MapType.worksiteAddress && {
          borderRadius: theme.sizing.scale200,
          ...theme.borders.border100,
          borderColor: theme.colors.pebble,
        }),
        ...(type === MapType.reviewOrder && {
          borderTopLeftRadius: theme.sizing.scale200,
          borderTopRightRadius: theme.sizing.scale200,
        }),
      }}
      center={position}
      zoom={15}
      options={mapOptions}
    >
      {isMarkerShown && <Marker position={position} icon={MarkerIcon} />}
    </GoogleMap>
  ) : (
    <MapPlaceholder $width={width} $height={height}>
      <MapPlaceholderIcon />
    </MapPlaceholder>
  );
};

export const StaticMap = React.memo(StaticMapComponent);

interface MapPlaceholderProps {
  $width?: Property.Width;
  $height?: Property.Height;
}

const MapPlaceholder = styled<MapPlaceholderProps, 'div', BlueprintTheme>(
  'div',
  ({ $theme, $width = '100%', $height = '100%' }) => ({
    width: $width,
    height: $height,
    backgroundColor: $theme.colors.crystal,
    borderRadius: $theme.sizing.scale200,
    ...$theme.borders.border100,
    borderColor: $theme.colors.pebble,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);
