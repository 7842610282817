import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const CloseIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 15, height = 15, color = theme.colors.accent } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5607 0.43934C15.1465 1.02513 15.1465 1.97487 14.5607 2.56066L9.62137 7.5L14.5607 12.4393C15.1465 13.0251 15.1465 13.9749 14.5607 14.5607C13.9749 15.1464 13.0252 15.1464 12.4394 14.5607L6.43976 8.56103L7.50005 7.5L6.43941 6.43932L12.4394 0.43934C13.0252 -0.146447 13.9749 -0.146447 14.5607 0.43934ZM7.50005 7.5L6.43941 6.43932C5.85363 7.0251 5.85397 7.97524 6.43976 8.56103L7.50005 7.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.43929 14.5607C-0.146496 13.9749 -0.146496 13.0251 0.43929 12.4393L5.37863 7.5L0.439291 2.56066C-0.146495 1.97487 -0.146495 1.02513 0.439291 0.439339C1.02508 -0.146448 1.97483 -0.146448 2.56061 0.439339L8.56025 6.43897L7.49995 7.5L8.56059 8.56068L2.56061 14.5607C1.97482 15.1464 1.02508 15.1464 0.43929 14.5607ZM7.49995 7.5L8.56059 8.56068C9.14637 7.9749 9.14603 7.02476 8.56025 6.43897L7.49995 7.5Z"
        fill={color}
      />
    </svg>
  );
};
