import React from "react";
import { StyledIcon, AlignmentContainer } from "../styledComponents";
import { AttributeTooltip } from "./AttributeTooltip";
import { TimestampAttributeName } from "../../../shiftUtils";
import { getTreatmentValue } from "../../../../../redux/selectors/splitio";
import { TIMESHEETS_P1_MESSAGING_SPLIT_NAME } from "../../../../../../shared/constants";
import { Tooltip } from "../../../../../components/Tooltip";
import { TimesheetsShiftInfo, TimesheetsUserInfo } from "../../../types/Shift.types";
import { useAppSelector } from "../../../../../redux";

export type AttributeViewProps = {
  attributeName: TimestampAttributeName;
  attributeIconPath: string;
  tooltipMessage: string;
  buttonText: string;
  tooltipAnchor: string;
  defaultTooltipModalMessage: string;
  userInfo: TimesheetsUserInfo;
  showMessaging?: boolean;
  shiftInfo: TimesheetsShiftInfo;
};

export const AttributeView = ({
  attributeIconPath,
  attributeName,
  tooltipMessage,
  buttonText,
  tooltipAnchor,
  defaultTooltipModalMessage,
  userInfo,
  showMessaging,
  shiftInfo,
}: AttributeViewProps) => {

  const timesheetsMessagingSplit = useAppSelector((state) =>
    getTreatmentValue(state, TIMESHEETS_P1_MESSAGING_SPLIT_NAME),
  );
  return (
    <AlignmentContainer onClick={(e) => e.stopPropagation()}>
      {/* TODO(FIN-134): Remove once released */}
      {timesheetsMessagingSplit === "on" && showMessaging ? (
        <AttributeTooltip
          target={`.${tooltipAnchor}`}
          message={tooltipMessage}
          defaultModalMessage={defaultTooltipModalMessage}
          buttonText={buttonText}
          cmFirstName={userInfo.firstName}
          cmId={userInfo.externalId}
          shiftInfo={shiftInfo}
        />
      ) : (
        <Tooltip position={"top"} target={`.${tooltipAnchor}`}>
          {tooltipMessage}
        </Tooltip>
      )}
      <StyledIcon className={tooltipAnchor} src={attributeIconPath} alt={attributeName} />
    </AlignmentContainer>
  );
};
