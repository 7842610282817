import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  display: inline-block;

  .admin-icon {
    display: none;
  }

  .admin-text {
    font-size: 14px;
    color: #080912;
    margin-left: 16px;
  }

  .admin-dropdown {
    background-color: #fff;
    border: 1px solid #D7D7D9;
    border-radius: 4px;
    box-shadow: 0px 3px 7px rgba(100, 106, 117, 0.08);
    left: -60px;
    position: absolute;
    text-align: left;
    width: 195px;
    z-index: 1000;
    color: ${({ theme }) => theme.colorTokens.gray700};
  }

  .admin-container {
    display: flex;
    align-items: center;
    border-left: 1px solid #D7D7D9;
    height: 4rem;
    width: 100%;

    :hover {
      cursor: pointer;
    }
  }

  .test {
    width: 95%;
    margin: 0 auto;
  }

  li {
    img {
      margin-right: 8px;
    }
    :hover {
      background-color: ${({ theme }) => theme.colorTokens.gray50};;
      cursor: pointer;
    }
    display: flex;
    align-items: center;

    width: 100%;
    height: 32px;
    padding: 15px 10px 15px 10px;

    font-family: ${({ theme }) => theme.typography.font100.fontFamily};
    font-size: ${({ theme }) => theme.typography.font100.fontSize};
    font-weight: ${({ theme }) => theme.typography.font100.fontWeight};
    line-height: ${({ theme }) => theme.typography.font100.lineHeight};
    font-style: normal;
  }

  ul {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 8px auto;
    padding-left: 0;
    width: 90%;
  }

  @media only screen and (max-width: 1000px) {
  .admin-text {
    display: none;
  }

  .admin-icon {
    padding-left: 1rem;
    display: inline;
}
`;
