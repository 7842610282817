import { useMutation, useQuery } from "@tanstack/react-query";
import QueryKeys from "./queryKeys";
import { getPrepareMessageBody, sendMessage } from "../message";

export const useGetPrepareMessageQuery = (body: string[]) =>
  useQuery({
    queryKey: [...QueryKeys.Message_Prepare, body],
    queryFn: () => getPrepareMessageBody(body),
    select: (data) => data,
  });

export const useSendMessageMutation = (
  onSuccess: () => void,
  onError: (message: string) => void,
) => {
  return useMutation(
    QueryKeys.Message_Send,
    ({ toExternalIds, messageBody }: { toExternalIds: string[]; messageBody: string }) => {
      return sendMessage(toExternalIds, messageBody);
    },
    {
      onSuccess,
      onError: () => {
        const errorMessage = "An error occurred. Please, try again.";
        onError(errorMessage);
      },
    },
  );
};
