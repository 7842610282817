import React from "react";

interface SvgPropType {
  width?: number | string;
  height?: number | string;
  color?: string;
  stroke?: string;
}

export const AlertIcon = (props: SvgPropType) => {
  const { width = 24, height = 24, color = "#E72E3E" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.839 13.8559L9.24928 0.784762C9.12985 0.548047 8.95034 0.349786 8.73019 0.211456C8.51005 0.0731256 8.25765 0 8.00035 0C7.74305 0 7.49066 0.0731256 7.27051 0.211456C7.05036 0.349786 6.87085 0.548047 6.75143 0.784762L0.160975 13.8559C0.0483995 14.0791 -0.00681482 14.3288 0.000671534 14.5806C0.00815789 14.8325 0.0780916 15.0781 0.20371 15.2937C0.329328 15.5093 0.506381 15.6876 0.717746 15.8113C0.92911 15.9351 1.16764 16 1.41026 16H14.5897C14.8324 16 15.0709 15.9351 15.2823 15.8113C15.4936 15.6876 15.6707 15.5093 15.7963 15.2937C15.9219 15.0781 15.9918 14.8325 15.9993 14.5806C16.0068 14.3288 15.9516 14.0791 15.839 13.8559ZM7.29499 5.37833C7.29499 5.18412 7.36927 4.99786 7.50148 4.86053C7.6337 4.72321 7.81302 4.64606 8 4.64606C8.18698 4.64606 8.3663 4.72321 8.49852 4.86053C8.63073 4.99786 8.70501 5.18412 8.70501 5.37833V9.77199C8.70501 9.9662 8.63073 10.1525 8.49852 10.2898C8.3663 10.4271 8.18698 10.5043 8 10.5043C7.81302 10.5043 7.6337 10.4271 7.50148 10.2898C7.36927 10.1525 7.29499 9.9662 7.29499 9.77199V5.37833ZM8.03525 13.8068H8.01551C7.73748 13.8059 7.47056 13.6934 7.27058 13.4927C7.0706 13.2921 6.95304 13.019 6.94248 12.7304C6.9374 12.5869 6.95991 12.4437 7.00869 12.3094C7.05747 12.175 7.13155 12.052 7.22659 11.9477C7.32163 11.8434 7.43574 11.7598 7.56224 11.7018C7.68874 11.6438 7.82509 11.6126 7.96334 11.61H7.98308C8.26105 11.6103 8.52813 11.7224 8.72839 11.9226C8.92866 12.1228 9.0466 12.3958 9.05752 12.6843C9.06299 12.8281 9.04071 12.9716 8.99201 13.1064C8.94331 13.2412 8.86916 13.3645 8.77392 13.4691C8.67868 13.5737 8.56427 13.6574 8.43742 13.7154C8.31057 13.7734 8.17383 13.8045 8.03525 13.8068Z"
        fill={color}
      />
    </svg>
  );
};
