import styled from "styled-components";
import { Box, Button, Field, Group, Heading, Input, Paragraph } from "@bluecrew/reakit";
import { palette as p } from "styled-tools";
import { Form } from "formik";

export const StyleContainer = styled(Box)`
  background-color: ${p("white")};
  height: 38.3125rem;
  width: 42rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Heading} {
    text-align: center;
    font-weight: 500;
  }

  ${Paragraph} {
    text-align: center;
    font-size: 1rem;
    opacity: 0.85;
    font-weight: 500;
    margin-bottom: 1.25rem;
  }
`;

export const StyledContent = styled(Box)`
  width: 28.25rem;
`;

export const StyledForm = styled(Form)`
  .type-input {
    cursor: pointer;
  }

  .error-message {
    color: red;
    font-size: 0.75rem;

    &.form-error {
      font-size: 1rem;
      text-align: center;
    }
  }

  ${Button} {
    margin: 4rem auto 0;
    display: block;
    height: 3.25rem;
    width: 9.5625rem;
    border-radius: 4px;
    background-color: ${p("brandBlue")};
    font-size: 1rem;
    font-weight: 500;
  }

  ${Field} {
    margin-bottom: 1.6875rem;
  }

  ${Group} {
    ${Input} {
      width: 13.5rem;
    }
  }

  .item {
    &:hover {
      background-color: ${p("frost")};
    }
  }

  // Masked inputs require special-ish treatment
  .masked-phone {
    width: 13.5rem;
  }
`;
