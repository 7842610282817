import { styled, withStyle } from "baseui";
import { StyledList } from "baseui/menu";
import { getMargin, getPadding } from "@bluecrew/blueprint-web/src/helpers/styleHelpers";
import type { BlueprintTheme } from "@bluecrew/blueprint-web/src/theme";
import { withThemedStyle } from "@bluecrew/blueprint-web/src/theme";
import { StyledSelectionListProps, VerticalAlign } from "./types";

export const StyledRichListItem = styled<{ $underlineLastItem: boolean; $selected: boolean }, "li">(
  "li",
  ({ $underlineLastItem, $selected }) => ({
    ":hover": {
      backgroundColor: "#F2F3F7",
    },
    ...($selected && { backgroundColor: "#F2F3F7" }),
    position: "relative",
    display: "flex",
    cursor: "pointer",
    borderBottomColor: "#DEDFE8",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    ...(!$underlineLastItem && {
      ":last-child > div:last-child": {
        borderBottomColor: "transparent",
      },
    }),
  }),
);

export const StyledLeftHandContainer = styled<
  { $leftContainerContentAlign: VerticalAlign },
  "div",
  BlueprintTheme
>("div", ({ $theme, $leftContainerContentAlign }) => ({
  marginRight: $theme.sizing.scale300,
  paddingTop: $theme.sizing.scale500,
  paddingBottom: $theme.sizing.scale500,
  alignItems: $leftContainerContentAlign,
  display: "flex",
}));

export const StyledRightHandContainer = styled<{}, "div", BlueprintTheme>("div", ({ $theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "flex-start",
  overflow: "visible",
  paddingTop: $theme.sizing.scale500,
  paddingBottom: $theme.sizing.scale500,
  alignItems: "center",
}));

export const StyledLabelsContainer = styled("div", {
  flex: 1,
  overflow: "visible",
});

export const StyledInfoGraphicContainer = styled("div", {
  flex: 4,
  overflow: "visible",
});

export const StyledTitle = styled<{}, "h6", BlueprintTheme>("h6", ({ $theme }) => ({
  ...$theme.typography.font350,
  fontWeight: 600,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.obsidian,
  overflow: "visible",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ...getMargin("0"),
  marginRight: $theme.sizing.scale600,
}));

export const StyledSubtitle = styled<{}, "p", BlueprintTheme>("p", ({ $theme }) => ({
  ...$theme.typography.font200,
  color: $theme.colors.obsidian,
  ...getMargin("0"),
}));

export const StyledScheduleSubtitle = withStyle(StyledSubtitle, ({ $theme }) => ({
  marginLeft: $theme.sizing.scale400,
}));

export const StyledUserTitle = withThemedStyle(StyledTitle, ({ $theme }) => ({
  marginRight: $theme.sizing.scale300,
}));

export const StyledUserSubtitle = withThemedStyle(StyledSubtitle, ({ $theme }) => ({
  color: $theme.colors.slate,
}));

export const StyledWeekdaysContainer = styled("div", ({ $theme }) => ({
  marginTop: $theme.sizing.scale100,
}));

export const StyledListItemCheckboxContainer = styled("div", ({ $theme }) => ({
  marginRight: $theme.sizing.scale600,
  display: "flex",
}));

export const StyledSelectionList = withThemedStyle<typeof StyledList, StyledSelectionListProps>(
  StyledList,
  ({ $theme, $topDivider, $bottomDivider }) => ({
    ...getPadding("0"),
    boxShadow: "none",
    ":focus": {
      outline: "none",
    },
    ":hover": {
      outline: "none",
    },
    overflow: "hidden",
    ...($topDivider && {
      borderTopWidth: "1px",
      borderTopColor: $theme.colors.stratus,
      borderTopStyle: "solid",
    }),
    ...($bottomDivider && {
      borderBottomWidth: "1px",
      borderBottomColor: $theme.colors.stratus,
      borderBottomStyle: "solid",
    }),
  }),
);

export const StyledCrewGroupListItemContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledCrewGroupTitle = withThemedStyle(StyledTitle, ({ $theme }) => ({
  ...$theme.typography.font300,
}));

export const StyledCrewGroupMemberCount = styled<{}, "div", BlueprintTheme>(
  "div",
  ({ $theme }) => ({
    ...$theme.typography.font300,
    color: $theme.colors.slate,
    marginRight: $theme.sizing.scale600,
  }),
);
