import React, { useState } from "react";
import { DropdownChangeEvent } from "primereact/dropdown";
import { getUserType } from "../../../redux/selectors/auth";
import { dropdownOptions } from "../constants";
import { UserType } from "../../../api/bluecrew/types";
import { StyledDropdown } from "./styledComponents";
import { useAppSelector } from "../../../redux";

export type RoleDropdownFieldProps = {
  onChange: (value: string) => void;
  onBlur?: () => void;
  defaultRole?: string;
};

export const RoleDropdownField = ({ onChange, onBlur, defaultRole }: RoleDropdownFieldProps) => {
  const siteUserType = useAppSelector(getUserType);
  const siteUserTypeOption = dropdownOptions.find(
    (roleOption) => UserType[roleOption.value] === siteUserType,
  )!;
  const [selectedRole, setSelectedRole] = useState(defaultRole);

  return (
    <StyledDropdown
      value={selectedRole}
      onChange={(e: DropdownChangeEvent) => {
        setSelectedRole(e.value);
        onChange(e.value);
      }}
      onBlur={() => {
        onBlur && onBlur();
      }}
      options={dropdownOptions.filter((roleOption) => roleOption.order > siteUserTypeOption.order)}
      placeholder={"Select a role"}
    />
  );
};
