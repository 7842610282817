import { DaysOfWeek } from "@bluecrew/blueprint-web";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { TimeRange } from "./types/propTypes/TimeField.type";
import { Range } from "./types/propTypes/DatePickerField.types";
import { ScheduleType } from "./types/propTypes/Schedule.types";
import { SiteDesignation } from "./types/propTypes/CreatePositionScreen.types";
import { OptimizedJobTypes } from "./types/propTypes/GetNumShifts.types";
import { Position } from "./types/propTypes/PositionField.types";
import { NewSchedule, WorkingHours } from "./types/propTypes/ScheduleField.types";
import {
  DEFAULT_WARN_RADIUS_M,
  DEFAULT_BLOCK_RADIUS_M,
} from "./PositionDetailsEditScreen/EditPositionGeofence";

export const defaultFormValues = {
  getCrew: {
    position: null,
    schedule: null,
    crew: [],
    countWorkers: 1,
    workDays: ["1", "2", "3", "4", "5"] as DaysOfWeek,
    timeRange: {
      startDateTime: "09:00",
      endDateTime: "17:00",
    },
    date: [] as Date[],
    scheduleName: "",
    supervisor: null,
    extraPay: 0,
    department: null,
    optimizedJob: 0 as unknown as OptimizedJobTypes,
    scheduleType: null,
    isBlueShiftSchedule: false,
  },
  createPosition: {
    hourlyPay: {
      minWage: 15,
      wage: 0,
    },
    skillset: null,
    requirements: [],
    customRequirements: [],
    certifications: [],
    positionTitle: "",
    arrivalInstructions: "",
    description: "",
    generalRequirements: "",
    dressCode: "",
    worksite: null,
    siteDesignation: SiteDesignation.UNKNOWN,
    minWarnRadius: DEFAULT_WARN_RADIUS_M,
    minBlockRadius: DEFAULT_BLOCK_RADIUS_M,
    backgroundCheckRequired: 0,
    drugScreenRequired: 0,
  },
  reviewOrder: {
    shiftsCount: 0,
    shift_wage: 0,
    fees: {
      wages: 0,
      pricingType: "",
      serviceFee: 0,
      taxes: 0,
      total: 0,
    },
  },
};

const NEW_SCHEDULE_WORKING_HOURS: WorkingHours = {
  formatted: "",
  startTime: defaultFormValues.getCrew.timeRange.startDateTime,
  endTime: defaultFormValues.getCrew.timeRange.endDateTime,
};

export const NEW_REPEATING_SCHEDULE: NewSchedule = {
  isNewSchedule: true,
  id: -1,
  name: "+ New Schedule",
  workingDays: defaultFormValues.getCrew.workDays,
  workingHours: NEW_SCHEDULE_WORKING_HOURS,
  scheduleType: ScheduleType.REPEATING,
  totalWage: "0",
  positionWage: "0",
};

const joinDateWithTime = (currentDate: moment.MomentInput, time: string, timezone: string) =>
  moment.tz(`${moment(currentDate).format("YYYY-MM-DD")} ${time}`, timezone!);

export const getFormattedWorkingHours = (
  position: Position | null,
  date: Range,
  timeRange: TimeRange,
) =>
  `${joinDateWithTime(date[0], timeRange.startDateTime, position?.timezone!).format(
    "hh:mma",
  )} - ${joinDateWithTime(date[0], timeRange.endDateTime, position?.timezone!).format("hh:mma z")}`;

export const getNewRepeatingScheduleWithFormattedHours = (
  position: Position | null,
  date: Range,
  timeRange: TimeRange,
): NewSchedule => {
  const schedule = cloneDeep(NEW_REPEATING_SCHEDULE);
  schedule.workingHours = {
    startTime: timeRange.startDateTime,
    endTime: timeRange.endDateTime,
    formatted: getFormattedWorkingHours(position, date, timeRange),
  };

  return schedule;
};

export const NEW_SINGLE_SHIFT: NewSchedule = {
  isNewSchedule: true,
  id: -1,
  name: "", // This is set when the schedule date is selected
  workingDays: [], // This is set when the schedule date is selected
  workingHours: NEW_SCHEDULE_WORKING_HOURS,
  scheduleType: ScheduleType.SINGLE_SHIFT,
  totalWage: "0",
  positionWage: "0",
};
