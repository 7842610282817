import React from "react";
import { ValueOf } from "../../types/util-types";

export const MessageSizes = {
  Small: "small",
  Normal: "normal",
  Big: "big",
} as const;
export type MessageSize = ValueOf<typeof MessageSizes>;

export const MessageVariants = {
  Neutral: "neutral",
  Positive: "positive",
  Negative: "negative",
} as const;
export type MessageVariant = ValueOf<typeof MessageVariants>;

export interface MessageProps {
  text?: string;
  size?: MessageSize;
  variant?: MessageVariant;
}

export const Message = ({ text, size, variant }: MessageProps) => {
  let color: string | undefined;

  switch (variant) {
    case MessageVariants.Positive:
      color = "green";
      break;
    case MessageVariants.Negative:
      color = "red";
      break;
    default:
      color = undefined;
  }

  const content = text !== "" && text != null ? text : null;

  switch (size) {
    case MessageSizes.Small:
      return <small style={{ color }}>{content}</small>;
    case MessageSizes.Big:
      return <big style={{ color }}>{content}</big>;
    default:
      return <div style={{ color }}>{content}</div>;
  }
};
