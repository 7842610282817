import styled from "styled-components";
import { Divider, Overlay } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";

export const SwitchCompanyOverlay = styled(Overlay)`
  padding: 0;
  color: rgb(33, 33, 33);
`;

export const StyledCompaniesModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 32px 75px;

  height: 675px;
`;

export const StyledLogoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCompaniesContainer = styled("div")`
  height: 70vh;
`;

export const StyledActionsContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center
  height: 12vh;
  padding-top: 4rem;

  button:first-child {
    margin-right: 1rem;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 1rem 0 1.5rem 0;
  color: ${p("slate")};
  width: 100%;
`;
