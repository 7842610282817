import { $Keys } from "utility-types";

// UTILITIES
// =============================================================================
// Collection of reusable utility functions not found in external libraries.

import moment, { Moment } from "moment-timezone";
import { isNil } from "lodash";

import Cookies from "js-cookie";
import { logError } from "../sentry";
import { APITokenMap } from "../api/bluecrew/types";
import {
  BEARER_TOKEN_NAME,
  COOKIE_DOMAIN,
  IS_SECURE,
  JWT_TOKEN_NAME,
} from "../../shared/constants";

// Add new types when the Add a Supervisor form is updated to allow different country codes
const phoneTemplates = { US: "($1) $2-$3" };

type FormatPhoneOptions = {
  type?: $Keys<typeof phoneTemplates>;
  template?: string;
};

export const formatPhone = (input: number | string, options: FormatPhoneOptions = {}) => {
  if (isNil(input)) {
    return "";
  }

  const type = options.type || "US";
  const template = options.template || phoneTemplates[type];
  const phoneDigits = String(input).replace(/[^\d]+/g, "");
  const regex = /^1?(\d{3})(\d{3})(\d{4})/;

  return phoneDigits.replace(regex, template);
};

/**
 * Takes a start time and end time as strings, converts them into moment objects,
 * and evaluates the difference between the two times
 * @param  {string} start
 * @param  {string} end
 * @returns {Number}
 */
export const calculateStartToEndTimeDuration = (start: string, end: string) => {
  const startTime = moment(start, "HH:mm");
  const endTime = moment(end, "HH:mm");
  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  return moment.duration(endTime.diff(startTime)).asHours();
};

function configureAuthCookies(tokens: APITokenMap): { name: string; value: string }[] {
  return [
    { name: BEARER_TOKEN_NAME, value: tokens.v1 },
    { name: JWT_TOKEN_NAME, value: tokens.v2 },
  ];
}

export function createAuthCookies({
  tokens,
  expireInDays,
}: {
  tokens: APITokenMap;
  expireInDays: number | null | undefined;
}) {
  configureAuthCookies(tokens).forEach((cookie) => {
    createCookie(cookie.name, cookie.value, expireInDays);
  });
}

const createCookie = (name: string, value: string, days: number | null | undefined) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  const cookie = `${name}=${value}${expires}; path=/;${
    import.meta.env.VITE_COOKIE_DOMAIN ? `domain=${import.meta.env.VITE_COOKIE_DOMAIN}` : ""
  }`;
  document.cookie = cookie;
};

export const eraseCookie = (name: string) => {
  createCookie(name, "", -1);
};

export const enumerateDaysBetweenDates = (
  startDate: string,
  endDate: string,
  workDays: Array<string>,
  timezone: string,
): Array<Moment> => {
  const dates = [] as Array<Moment>;
  const currDate = moment(startDate).tz(timezone).startOf("day");
  const lastDate = moment(endDate).tz(timezone).startOf("day");
  while (currDate.diff(lastDate) <= 0) {
    if (workDays.includes(`${currDate.format("e")}`)) {
      dates.push(currDate.clone());
    }
    currDate.add(1, "days");
  }

  return dates;
};

/**
 * converts array of week days: ex: ["0","2"]
 * to corresponding daymask value: ex. 4
 * @param  {Array<string>} daysOfWeek
 */
export const convertToDayMaskValue = (daysOfWeek: Array<string>) => {
  let days = 0;
  daysOfWeek.forEach((day) => (days += 2 ** parseInt(day)));
  return days;
};

type GeocodedAddressComponent = {
  long_name: string;
  postcode_localities?: Array<string>;
  short_name: string;
  types: Array<string>;
};

/**
 * takes in addressComponent returned from geocoding api
 * https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingResults
 * grabs correct data for the endpoint query strings
 * @param  {Array} addressComponents
 */
export const formatMinWagePayload = (addressComponents: Array<GeocodedAddressComponent>) => {
  const today = new Date();
  const payload = {
    date: today.toISOString(),
    city: "",
    state: "",
    county: "",
  };
  addressComponents.forEach((component) =>
    component.types.forEach((type) => {
      if (type === "locality") {
        payload.city = component.short_name;
      } else if (type === "administrative_area_level_1") {
        payload.state = component.short_name;
      } else if (type === "administrative_area_level_2") {
        payload.county = component.short_name;
      }
    }),
  );
  return payload;
};

type updateDateWithTimeTypes = {
  newStartDateMoment: Moment;
  newEndDateMoment: Moment;
  startDateTimestamp: string;
  endDateTimestamp: string;
  timezone: string;
};

/**
 * combines user selected new start and end dates from DatePicker
 * and existing times from schedule (start_date_time/end_date_time)
 * and returns a moment object that reflects new dates with existing times
 *
 * @param  {Moment} newStartDateMoment
 * @param  {Moment} newEndDateMoment
 * @param  {string} startDateTimestamp
 * @param  {string} endDateTimestamp
 * @param  {string} timezone
 */
export const updateDateWithTime = ({
  newStartDateMoment,
  newEndDateMoment,
  startDateTimestamp,
  endDateTimestamp,
  timezone,
}: updateDateWithTimeTypes) => {
  // Format existing times so they are in the timezone of the schedule and can be interpolated with the new dates
  const startTime = moment(startDateTimestamp).tz(timezone).format("HH:mm");
  const endTime = moment(endDateTimestamp).tz(timezone).format("HH:mm");

  // Format new dates to be interpolated with existing times
  // Not converting to timezone because we are assuming the user is selected dates in the timezone of existing schedule
  const startDate = newStartDateMoment.format("YYYY-MM-DD");
  const endDate = newEndDateMoment.format("YYYY-MM-DD");

  // create moment objects using new dates and existing schedule's times and timezone
  const startDateTime = moment.tz(`${startDate} ${startTime}`, timezone);
  const endDateTime = moment.tz(`${endDate} ${endTime}`, timezone);

  return { startDateTime, endDateTime };
};

// @ts-ignore $FlowFixUnknownDefinition
export const convertDecimalToPercent = (decimal) => parseFloat(decimal) * 100;

export const MAIN_SCROLLABLE_PANEL = "Main_AppContent_ScrollablePanel";

export function scrollAppToTop() {
  // See containers/Main for how the ID is applied.
  const elem = document.querySelector(`#${MAIN_SCROLLABLE_PANEL}`);
  if (elem) {
    elem.scrollTop = 0;
  } else {
    logError({
      error: new Error(`Could not query element ${MAIN_SCROLLABLE_PANEL}`),
    });
  }
}

type DomLocation = typeof location;

export function getQueryParam(location: DomLocation, param: string) {
  try {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  } catch (error) {
    // Older browsers don't have these APIs so let's keep an eye on it in prod.
    if (error instanceof Error) logError({ error, context: "attempting to use URLSearchParams" });
    return undefined;
  }
}

/**
 * Remove all falsy values from an array
 * @param arr of strings
 */

export const getAllNotFalsyValuesFromArray = (arr: Array<string>) =>
  arr.map((r) => r.trim()).filter(Boolean);

/**
 * Get cookie options
 * @param expires If passed as number, number is interpreted as number of days before expiry
 * @returns
 */
export const getCookieOptions = (
  expires: number | Date | undefined = undefined,
): Cookies.CookieAttributes => {
  return {
    domain: COOKIE_DOMAIN,
    path: "/",
    expires,
    secure: IS_SECURE,
  };
};
