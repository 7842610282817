import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobProductionFoodIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4302_23756)">
        <rect
          x="1.45801"
          y="24.175"
          width="33.0374"
          height="9.06556"
          rx="4.53278"
          stroke={svgColor}
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.66227 28.8043C7.66227 29.2628 7.2906 29.6344 6.83211 29.6344C6.37363 29.6344 6.00195 29.2628 6.00195 28.8043C6.00195 28.3458 6.37363 27.9741 6.83211 27.9741C7.2906 27.9741 7.66227 28.3458 7.66227 28.8043ZM11.3771 28.8043C11.3771 29.2628 11.0054 29.6344 10.547 29.6344C10.0885 29.6344 9.7168 29.2628 9.7168 28.8043C9.7168 28.3458 10.0885 27.9741 10.547 27.9741C11.0054 27.9741 11.3771 28.3458 11.3771 28.8043ZM14.2618 29.6344C14.7203 29.6344 15.092 29.2628 15.092 28.8043C15.092 28.3458 14.7203 27.9741 14.2618 27.9741C13.8033 27.9741 13.4316 28.3458 13.4316 28.8043C13.4316 29.2628 13.8033 29.6344 14.2618 29.6344ZM18.8068 28.8043C18.8068 29.2628 18.4351 29.6344 17.9766 29.6344C17.5182 29.6344 17.1465 29.2628 17.1465 28.8043C17.1465 28.3458 17.5182 27.9741 17.9766 27.9741C18.4351 27.9741 18.8068 28.3458 18.8068 28.8043ZM21.6915 29.6344C22.15 29.6344 22.5216 29.2628 22.5216 28.8043C22.5216 28.3458 22.15 27.9741 21.6915 27.9741C21.233 27.9741 20.8613 28.3458 20.8613 28.8043C20.8613 29.2628 21.233 29.6344 21.6915 29.6344ZM26.2365 28.8043C26.2365 29.2628 25.8648 29.6344 25.4063 29.6344C24.9478 29.6344 24.5762 29.2628 24.5762 28.8043C24.5762 28.3458 24.9478 27.9741 25.4063 27.9741C25.8648 27.9741 26.2365 28.3458 26.2365 28.8043ZM29.1212 29.6344C29.5797 29.6344 29.9513 29.2628 29.9513 28.8043C29.9513 28.3458 29.5797 27.9741 29.1212 27.9741C28.6627 27.9741 28.291 28.3458 28.291 28.8043C28.291 29.2628 28.6627 29.6344 29.1212 29.6344Z"
          fill={svgColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.657 12.6943H25.0853C26.9271 11.8213 26.0478 8.524 24.0432 8.27236C23.9383 8.53843 23.7899 8.78522 23.6041 9.00267C23.3133 9.33634 22.9425 9.562 22.4917 9.67965C22.3418 9.71885 22.185 9.64089 22.1416 9.50532C22.0981 9.36975 22.1844 9.22819 22.3343 9.18878C22.6543 9.11008 22.9422 8.93454 23.1586 8.68613C23.3508 8.46554 23.4995 8.17998 23.6048 7.82946C23.8646 6.11912 23.1042 4.96525 21.3237 4.36787C21.4329 2.41894 20.2502 0.621646 17.4746 0.0123394C17.2551 -0.0399175 17.2189 0.0845139 17.2605 0.184959C18.157 1.96774 16.9873 2.3215 15.7067 2.70882C14.613 3.03959 13.4384 3.39484 13.4005 4.68548C13.8893 4.61116 14.3966 4.56747 14.8997 4.52442C16.7672 4.36401 18.5671 4.20938 18.6836 2.29708C18.6939 2.12789 18.8549 1.99725 19.0415 2.00753C19.228 2.01781 19.3722 2.16151 19.3627 2.3307C19.2122 4.76824 17.1281 4.94769 14.9651 5.13393L14.9624 5.13416C14.1433 5.20441 13.3114 5.27594 12.6049 5.48668C11.5739 6.06622 10.8629 7.17475 11.6656 8.23916C13.0407 7.71884 14.6714 7.66098 16.2157 7.60618H16.2157L16.2185 7.60608L16.2188 7.60607C18.3501 7.5309 20.3006 7.46211 20.9277 6.09877C20.9996 5.94221 21.1986 5.8679 21.3718 5.933C21.5451 5.99811 21.6273 6.17758 21.5554 6.33435C20.7617 8.05946 18.602 8.13572 16.2426 8.21903L16.2425 8.21903C14.4178 8.28328 12.4627 8.35246 11.0597 9.25646C9.84855 9.94372 9.29107 11.7836 10.657 12.6943ZM11.4415 13.9693H24.3654C24.438 13.9687 24.5097 13.9841 24.5749 14.0143C24.64 14.0445 24.6967 14.0888 24.7405 14.1434C24.7874 14.1983 24.821 14.2622 24.8389 14.3307C24.8567 14.3991 24.8585 14.4705 24.8439 14.5396L23.5516 21.2847C23.5088 21.5138 23.3094 21.6762 23.0729 21.6762H12.7338C12.4982 21.6762 12.2992 21.5144 12.2553 21.2847L10.9628 14.5396C10.9483 14.4704 10.9501 14.3991 10.968 14.3306C10.9859 14.2622 11.0195 14.1983 11.0665 14.1434C11.1103 14.0888 11.167 14.0445 11.2321 14.0143C11.2972 13.9841 11.3689 13.9687 11.4415 13.9693Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4302_23756">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
