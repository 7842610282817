import React, { FunctionComponent, useEffect, useState } from "react";
import { withStyle } from "baseui";
import {
  Button,
  Modal,
  ModalKind,
  themedStyled,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import { LabelMedium } from "baseui/typography";
import { KIND } from "baseui/button";
import { ModalFooterWrapper } from "../ModalFooterWrapper";
import { pushEmptyState } from "./stop-back-button";
import { HistoryConfirmationModalArgs } from "../types/HistoryConfirmationModalArgs.type";

const FooterWrapper = withStyle(ModalFooterWrapper, () => ({
  justifyContent: "end",
}));

const ContainerWithMargin = themedStyled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
}));

const ModalBody = themedStyled("div", ({ $theme }) => ({
  marginBottom: $theme.sizing.scale500,
  marginLeft: $theme.sizing.scale600,
  marginRight: $theme.sizing.scale600,
}));

const modalQueryParam = "modalOpen";

export const HistoryConfirmationModal = <T extends {}>(
  Component: FunctionComponent<T>,
  { header, label, body }: HistoryConfirmationModalArgs,
) =>
  function AddModal(props: T) {
    const params = new URL(window.location.toString()).searchParams;
    const modalState: boolean = params.get(modalQueryParam) === "true";
    const [, theme] = useThemedStyletron();
    const [modalOpen, setModalOpen] = useState(modalState);

    const removeQueryString = (): URL => {
      const url = new URL(window.location.href);
      url.searchParams.delete(modalQueryParam);
      return url;
    };

    const cancelNavigation = () => {
      const url = removeQueryString();
      window.history.replaceState(null, "", url.toString());
      setModalOpen(false);
    };

    const continueNavigation = () => {
      setModalOpen(false);
      const url = removeQueryString();
      window.history.replaceState(null, "", url.toString());
      window.removeEventListener("popstate", popStateHandler);
      window.location.assign(window.location.origin);
    };

    const popStateHandler = () => {
      const url = new URL(window.location.href);
      url.searchParams.set(modalQueryParam, "true");
      window.history.replaceState(null, "", url.toString());
      setModalOpen(true);
    };

    pushEmptyState();

    window.addEventListener("popstate", popStateHandler);
    useEffect(() => () => window.removeEventListener("popstate", popStateHandler));

    return (
      <>
        <Modal
          kind={ModalKind.DEFAULT}
          header={header}
          withCloseButton
          onClose={() => cancelNavigation()}
          body={
            <ModalBody data-testid="popstate-modal">
              <LabelMedium
                $style={{
                  fontWeight: 400,
                  marginBottom: theme.sizing.scale700,
                }}
              >
                {label}
              </LabelMedium>
              {body}
            </ModalBody>
          }
          footer={
            <FooterWrapper>
              <Button onClick={cancelNavigation} kind={KIND.tertiary}>
                Cancel
              </Button>
              <ContainerWithMargin>
                <Button onClick={continueNavigation}>Continue</Button>
              </ContainerWithMargin>
            </FooterWrapper>
          }
          isOpen={modalOpen}
        />
        {
          // spreading the passed in props to uknown component is recommended for React HOCs
          // https://reactjs.org/docs/higher-order-components.html
        }
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </>
    );
  };
