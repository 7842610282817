import React from 'react';
import { SvgPropType } from '../SvgPropType';
import { useThemedStyletron } from '../../../theme';

export const JobEventServerIcon = ({
  width = 36,
  height = 36,
  color,
}: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const svgColor = color ?? theme.colors.primary600;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4300_24605)">
        <path
          d="M23.5235 5.42168C23.8616 5.12246 24.1006 4.72745 24.2088 4.28913C24.317 3.85082 24.2892 3.38996 24.1292 2.96779C23.9693 2.54562 23.6846 2.18213 23.3131 1.92563C22.9415 1.66912 22.5008 1.53174 22.0493 1.53174C21.5978 1.53174 21.157 1.66912 20.7855 1.92563C20.414 2.18213 20.1293 2.54562 19.9693 2.96779C19.8093 3.38996 19.7816 3.85082 19.8898 4.28913C19.998 4.72745 20.237 5.12246 20.5751 5.42168C14.5639 6.1522 9.89062 11.2858 9.89062 17.4904C9.89062 17.8805 9.90981 18.2777 9.94817 18.6717L9.97615 18.9658H34.1216L34.1496 18.6717C34.188 18.2777 34.2071 17.8805 34.2071 17.4904C34.2071 11.2882 29.5347 6.1522 23.5235 5.42168ZM16.9696 9.76963C15.1816 11.9468 14.2035 14.6764 14.2018 17.4936C14.2018 17.7662 14.2114 18.0419 14.2298 18.3177H12.8567C12.8375 18.0419 12.8271 17.7662 12.8271 17.4936C12.8176 14.9228 13.6726 12.4235 15.2549 10.3973C16.8371 8.37109 19.0546 6.93564 21.551 6.32164C19.7722 7.09164 18.2019 8.27346 16.9696 9.76963Z"
          fill={svgColor}
        />
        <path
          d="M34.9108 22.0236H9.29709C8.96344 22.0236 8.64346 21.8911 8.40753 21.6552C8.1716 21.4192 8.03906 21.0992 8.03906 20.7656C8.03906 20.6004 8.0716 20.4368 8.13482 20.2842C8.19804 20.1315 8.29071 19.9929 8.40753 19.876C8.52435 19.7592 8.66303 19.6666 8.81566 19.6033C8.96829 19.5401 9.13188 19.5076 9.29709 19.5076H34.9108C35.076 19.5076 35.2396 19.5401 35.3922 19.6033C35.5449 19.6666 35.6835 19.7592 35.8004 19.876C35.9172 19.9929 36.0098 20.1315 36.0731 20.2842C36.1363 20.4368 36.1688 20.6004 36.1688 20.7656C36.1688 21.0992 36.0363 21.4192 35.8004 21.6552C35.5644 21.8911 35.2444 22.0236 34.9108 22.0236Z"
          fill={svgColor}
        />
        <path
          d="M5.12033 24.6094L6.58936 33.3101L0.168945 34.4682L0.310412 25.4454L5.12033 24.6094Z"
          fill={svgColor}
        />
        <path
          d="M5.89941 24.7246L10.1291 23.0653L16.3912 22.9486C16.5906 22.9448 16.7849 23.0115 16.9399 23.137C17.0949 23.2625 17.2006 23.4386 17.2384 23.6344C17.2786 23.8406 17.2406 24.0544 17.1319 24.2342C17.0232 24.414 16.8515 24.5469 16.6502 24.6071L12.2679 25.9075L18.1448 26.2975L24.6595 23.7079C24.8769 23.6212 25.119 23.6191 25.3378 23.7021C25.5567 23.7851 25.7365 23.9472 25.8416 24.1563C25.9446 24.3626 25.9678 24.5996 25.9068 24.8219C25.8457 25.0442 25.7047 25.2361 25.5107 25.3608L19.8472 28.9998C18.7355 29.7142 17.4418 30.094 16.1203 30.094H9.28666C8.80726 30.09 8.33606 30.2183 7.92487 30.4648C7.51368 30.7113 7.17844 31.0664 6.95603 31.4911C6.93924 31.523 6.92326 31.5566 6.90807 31.5902L5.89941 24.7246Z"
          fill={svgColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4300_24605">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
