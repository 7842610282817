import { Colors } from 'baseui/theme';
import { colorTokens } from './colorTokens';

import { primitives } from './primitives';

const { primaryFontFamily, ...primitiveColors } = primitives;

const customColors = {
  // Color Aliases
  cirrus: colorTokens.white,
  crystal: colorTokens.gray100,
  stratus: colorTokens.gray200,
  tempest: colorTokens.gray300,
  pebble: colorTokens.gray400,
  stone: colorTokens.gray500,
  slate: colorTokens.gray600,
  granite: colorTokens.gray700,
  obsidian: colorTokens.gray800,
  highlight: colorTokens.blue100,
  ultramarine: colorTokens.blue400,
  forest: colorTokens.green400,
  sunrise: colorTokens.yellow400,
  sunset: colorTokens.orange400,
  coral: colorTokens.red400,
  coral100: colorTokens.red100,
  siren: colorTokens.red1000,

  // Input
  inputBorderActive: primitives.accent,

  // HourlyWage
  hourlyWageTrackFill: primitives.accent100,
  hourlyWageTrackFillSelected: primitives.accent,
  hourlyWageHandleText: primitives.accent,

  // Tab
  tabColor: primitives.primary700,
  tabFill: 'transparent',
  tabColorActive: primitives.accent,
  tabFillActive: primitives.primaryB,
  tabBarFill: primitives.primary100,

  // Toggle
  toggleFill: primitives.primaryB,
  toggleFillChecked: primitives.primaryB,
  toggleFillDisabled: primitives.primary100,
  toggleTrackFill: primitives.primary200,
  toggleTrackFillChecked: primitives.accent,
  toggleTrackFillDisabled: primitives.primary300,
  toggleTrackFillError: primitives.negative100,
  toggleTrackFillErrorChecked: primitives.negative,

  // Weekdays
  weekdaysFill: 'transparent',
  weekdaysText: primitives.primary,
  weekdaysFillSelected: primitives.accent,
  weekdaysTextSelected: primitives.primaryB,
  weekdaysTextDisabled: primitives.primary500,
  weekdaysFillSelectedDisabled: primitives.primary300,
  weekdaysTextSelectedDisabled: primitives.primary,

  // User Avatar
  userAvatarBackgroundColor: colorTokens.aqua100,

  // Header
  headerHoverColor: colorTokens.blue150,

  // Dashboard
  dashboardMainColor: colorTokens.blue400,
  dashboardMainHoverColor: colorTokens.blue850,
  dashboardSecondaryColor: colorTokens.white,
  dashboardSubComponentBorderColor: colorTokens.gray400,
  dashboardCircularProgressBarSecondaryColor: colorTokens.blue100,
  dashboardTableHeaderTextColor: colorTokens.gray600,
  dashboardSubComponentSecondaryBorderColor: colorTokens.gray200,
  dashboardTableHeaderBackgroundColor: colorTokens.white,
  dashboardDropdownRowDividerColor: colorTokens.gray250,
};

export const colors: Partial<Colors> & typeof customColors = {
  ...primitiveColors,

  /* ---- Semantic Colors ---- */

  /* CoreSemanticColorTokensT */

  // Background

  // Content

  // Border
  /* CoreExtensionSemanticColorTokensT */

  // Backgrounds
  // Content

  // Border

  /* ---- End of Semantic Colors ---- */

  /* ---- Component Specific Colors ---- */
  ...customColors,

  // Buttons
  buttonPrimaryFill: primitives.accent,
  buttonPrimaryText: primitives.primaryB,
  buttonSecondaryFill: primitives.primaryB,
  buttonSecondaryText: primitives.accent,
  buttonTertiaryFill: 'transparent',
  buttonTertiaryText: primitives.accent,

  // Breadcrumbs
  breadcrumbsText: primitives.primary500,

  // Datepicker
  calendarHeaderBackground: primitives.accent400,
  // Combobox

  // FileUploader

  // Links

  // Lists

  // ProgressSteps

  // Toggle

  // Ticks

  // Slider/Toggle

  // Input
  inputBorder: primitives.primary400,
  inputFill: primitives.primaryB,
  inputFillError: primitives.primaryB,
  inputFillDisabled: primitives.primary100,
  inputFillActive: primitives.primaryB,
  inputTextDisabled: primitives.primary500,
  inputBorderError: primitives.negative,
  inputEnhancerTextDisabled: primitives.primary500,
  inputPlaceholder: primitives.primary500,
  inputPlaceholderDisabled: primitives.primary500,

  // Notification

  // Menu

  // Modal

  // Pagination

  // Header navigation

  // Tab

  // Shadow

  // Tag

  // Table

  // Toast

  // Spinner

  // Progress bar

  // Tooltip
  /* ---- End of Component Specific Colors ---- */
};
