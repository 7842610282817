import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const PinIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 16, height = 20, color = theme.colors.slate } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 20C8.10513 20.0002 8.20926 19.98 8.30645 19.9405C8.40364 19.9011 8.49197 19.8431 8.5664 19.7701C8.8688 19.4708 16 12.8693 16 7.875C16 5.78642 15.1571 3.78338 13.6569 2.30653C12.1566 0.829685 10.1217 0 8 0C5.87827 0 3.84344 0.829685 2.34315 2.30653C0.842855 3.78338 0 5.78642 0 7.875C0 12.8693 7.1312 19.4708 7.4352 19.7701C7.58513 19.9173 7.78824 20 8 20ZM5.2 7.875C5.2 7.32986 5.36422 6.79697 5.67189 6.34371C5.97955 5.89045 6.41685 5.53717 6.92849 5.32856C7.44012 5.11994 8.00311 5.06536 8.54625 5.17171C9.0894 5.27806 9.58831 5.54057 9.9799 5.92604C10.3715 6.31151 10.6382 6.80262 10.7462 7.33728C10.8542 7.87194 10.7988 8.42613 10.5869 8.92977C10.3749 9.43341 10.0161 9.86388 9.5556 10.1667C9.09514 10.4696 8.55379 10.6312 8 10.6312C7.25765 10.6304 6.54595 10.3398 6.02104 9.82304C5.49612 9.30633 5.20085 8.60575 5.2 7.875Z"
        fill={color}
      />
    </svg>
  );
};
