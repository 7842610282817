import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { produce } from "immer";
import { useNavigate } from "react-router-dom";
import {
  archivePosition,
  getPositions,
  readPosition,
  readPositionEdits,
  updatePositionDetails,
  UpdatePositionDetailsProps,
} from "../position";
import confirmationModalActions from "../../../redux/actions/confirmationModal";
import { SimpleModalTypes } from "../../../components/SimpleModal";
import { Modals } from "../../../../shared/constants";
import { queryClient } from "../../../queryClient";

export const useGetPositionsQuery = () =>
  useQuery(["positions"], () => getPositions({}), {
    select: (data) => data.positions,
    retry: false,
  });

export const useEditPositionMutation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation(
    ({
      payload,
      positionId,
    }: {
      payload: UpdatePositionDetailsProps["payload"];
      positionId: string;
    }) =>
      updatePositionDetails({
        payload,
        positionId: parseInt(positionId, 10),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboardPositions"]);
        queryClient.invalidateQueries(["position"]);
        queryClient.invalidateQueries(["editHistory"]);
        navigate("/positions", { replace: true });
      },
      onError: () => {
        dispatch(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.FAILURE,
            contentType: Modals.UPDATE_POSITION_DETAILS,
            redirectPath: null,
          }),
        );
      },
    },
  );
};

export const useReadPositionQuery = (positionId: number, enabled?: boolean) =>
  useQuery(
    ["position", positionId],
    () =>
      readPosition({
        positionId,
      }),
    { select: (data) => data.position, enabled },
  );

export const useGetEditHistoryQuery = (positionId: string) =>
  useQuery(
    ["editHistory", positionId],
    () =>
      readPositionEdits({
        positionId: positionId as unknown as number,
      }),
    {
      enabled: !!positionId,
      select: (data) => {
        // Reverse edit history array, to display list in the right order
        const reversedEditHistory = produce(data, (draft) => {
          draft.positionHistory.reverse();
        });
        return reversedEditHistory;
      },
    },
  );

export const useArchivePositionMutation = () => {
  const dispatch = useDispatch();

  return useMutation((positionId: number) => archivePosition({ positionId }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["dashboardPositions"]);

      dispatch(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.SUCCESS,
          contentType: Modals.ARCHIVE_POSITION,
          redirectPath: null,
        }),
      );
    },
    onError: () => {
      dispatch(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.FAILURE,
          contentType: Modals.ARCHIVE_POSITION,
          redirectPath: null,
        }),
      );
    },
  });
};

export type UpdateContinuousFillProps = {
  positionId: number;
  refillOn: boolean;
};

export const useUpdateContinuousFillMutation = () => {
  const dispatch = useDispatch();

  return useMutation(
    ({ positionId, refillOn }: UpdateContinuousFillProps) =>
      updatePositionDetails({
        payload: { refill_on: refillOn, reason_code: "UNKNOWN" },
        positionId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["dashboardPositions"]);
      },
      onError: (_err, { refillOn: continuousFill }) => {
        dispatch(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.FAILURE,
            contentType: continuousFill
              ? Modals.ENABLE_CONTINUOUS_FILL
              : Modals.DISABLE_CONTINUOUS_FILL,
            redirectPath: null,
          }),
        );
      },
    },
  );
};
