import { CognitoUser } from "amazon-cognito-identity-js";
import { ValueOf } from "../../../types/util-types";

export const ErrorTypes = {
  NotAuthorized: "NotAuthorizedException",
  CodeMismatch: "CodeMismatchException",
  UserNotFound: "UserNotFoundException",
  ExpiredCode: "ExpiredCodeException",
  LimitExceededException: "LimitExceededException",
  Unknown: "UnknownError",
} as const;

export type AuthenticationErrorType = ValueOf<typeof ErrorTypes>;

export const Statuses = {
  PreSignin: "preSignIn",
  ConfirmSignIn: "confirmSignIn",
  SignedIn: "signedIn",
  ChangePassword: "changePassword",
} as const;
export type AuthenticationStatus = ValueOf<typeof Statuses>;

export const Contexts = {
  SignIn: "signIn",
  ConfirmSignIn: "confirmSignIn",
  RequestReset: "requestReset",
  ConfirmReset: "confirmReset",
  ActivateAccount: "activateAccount",
  ActivateAccountCodeExpired: "activateAccountCodeExpired",
} as const;
export type AuthenticationContext = ValueOf<typeof Contexts>;

export interface AuthenticationResult {
  username: string;
  user: CognitoUser;
  status: AuthenticationStatus;
}

export interface AuthenticationErrorInfo {
  errorType: AuthenticationErrorType;
  message: string;
}
