import * as React from 'react';
import { Drawer as BaseDrawer } from 'baseui/drawer';
import { CloseIcon } from '../../assets';
import { Body, CloseButton, Footer, Header } from './styles';
import { getMargin } from '../../helpers/styleHelpers';
import { ModalProps } from './Modal.types';

export const Drawer = ({
  header,
  body,
  footer,
  isOpen,
  onClose,
  maxHeight,
  width,
  withCloseButton = true,
}: ModalProps) => (
  <BaseDrawer
    onClose={onClose}
    isOpen={isOpen}
    animate={false}
    autoFocus={false}
    overrides={{
      Root: {
        style: () => ({
          zIndex: 999999, // NOTE: Must be at least 999999, since we want it to cover the zendesk widget on web-react (which has z-index 999998)
        }),
      },
      Backdrop: {
        style: ({ $theme }) => ({
          backgroundColor: 'rgba(8,9,18, 0.8)',
          transitionProperty: 'opacity',
          transitionDuration: '1s',
          transitionTimingFunction: $theme.animation.easeOutCurve,
        }),
      },
      DrawerBody: {
        style: () => ({
          ...getMargin('0'),
        }),
      },
      DrawerContainer: {
        style: ({ $isOpen, $theme }) =>
          $isOpen
            ? {
                width,
                borderTopLeftRadius: $theme.borders.radius400,
                borderBottomLeftRadius: $theme.borders.radius400,
                opacity: 1,
                transitionDuration: '0.250s',
                transitionProperty: 'all',
                transitionTimingFunction: 'ease',
                transform: 'translateX(0)',
              }
            : {
                opacity: 1,
                transitionDuration: '0.250s',
                transitionProperty: 'all',
                transitionTimingFunction: 'ease',
                transform: 'translateX(500px)',
              },
      },
      Close: {
        style: {
          display: 'none',
        },
      },
    }}
  >
    <Header>
      {header}
      {withCloseButton && (
        <CloseButton onClick={onClose} data-testid="Drawer-CloseButton">
          <CloseIcon />
        </CloseButton>
      )}
    </Header>
    <Body maxheight={maxHeight}>{body}</Body>
    {footer && <Footer>{footer}</Footer>}
  </BaseDrawer>
);
