// =============================================================================
// Form fields for schedule details. Must be render inside a formik form

import React, { Component } from "react";
import { connect } from "react-redux";
import { connect as formikConnect, Field as FormikField, ErrorMessage } from "formik";
import Select from "react-select";
import { Dispatch } from "redux";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import {
  Field,
  Label,
  Grid,
  Group,
  Input,
  Portal,
  Backdrop,
  Overlay,
  Heading,
  Box,
  WageInput,
} from "@bluecrew/web-react-core";
import { DAY_MASK, ScheduleDetailsCopy, SharedCopy } from "../../shared/constants";
import { assetUrl } from "../api/bluecrew/constants/index";
import { validateDepartment } from "../forms/validation";
import { StateShape } from "../redux/reducers";
import jobActions from "../redux/actions/job";
import { getSupervisors, isDepartmentsEnabled } from "../redux/selectors/job";
import { getUserCompany } from "../redux/selectors/auth";
import { Can } from "../containers/PermissionsContext";
import { SupervisorForm } from "./SupervisorForm/SupervisorForm";
import { WorkersNeeded } from "./WorkersNeeded";
import { reactSelectStyles } from "../../shared/styles";
import { ScheduleType } from "../containers/GetCrewMembers/types/propTypes/Schedule.types";
import { Departments } from "../containers/GetCrewMembers/types/propTypes/DepartmentField.types";

const FieldsContainer = styled(Box)`
  ${Label} {
    display: flex;

    span {
      margin-right: 10px;
    }
  }
  h6 {
    color: ${p("platinum")};
    font-weight: 500;
    font-size: 1.25rem;
    margin: 32px 0 8px 0;
  }
  // TODO: fix in web-core theme
  .styled-auto-complete {
    input {
      font-size: 16px;
      width: 100%;
    }
  }

  .disabled {
    cursor: not-allowed;
    color: ${p("platinum")};
    font-style: italic;
  }

  //TIME SELECTOR
  .start-time {
    margin-right: 32px;
  }

  .start-time,
  .end-time {
    display: inline-block;
    width: 16rem;
    & input {
      background-image: url(${assetUrl}/icons/clock-input-bg.svg);
      background-repeat: no-repeat;
      background-position: left 1rem center;
      padding-left: calc(2rem + 14px);
      width: 16.6875rem;

      & ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        position: relative;
        height: 100%;
        opacity: 0;
      }
    }
  }
`;

const StyledOverlay = styled(Overlay)`
  background-color: ${p("white")};
  padding: 0;
`;

// TODO: remove when RoundCheckbox component is updated in webcore (see comment in renderWorkDays)
// TODO: The border is throwing of the vertical centering of text
// currently rectifying the issue by adjusting the line-height in the
// unchecked stated, and checked states to ensure correct centering
// We'll want to refactor this
const StyledCheckbox = styled(Box)`
  label {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  input[type="checkbox"] + label {
    background-color: ${p("white")};
    border: solid 0.0938rem #e1e2e4;
    border-radius: 50%; /* the magic */
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: ${p("platinum")};
    font-size: 1.3125rem;
    font-weight: 500;
    font-style: normal;
    display: inline-block;
    height: 3rem;
    line-height: 2.9374rem;
    text-align: center;
    text-transform: uppercase;
    width: 3rem;
    &:hover {
      background-color: ${p("platinum")};
      border: none;
      color: ${p("white")};
      line-height: 3.125rem;
      cursor: pointer;
    }
  }

  input[type="checkbox"]:checked + label {
    background: ${p("brandBlue")};
    border: 0;
    color: ${p("white")};
    line-height: 3.125rem;
    &.disabled {
      background: ${p("platinum")};
    }
  }
`;

const StyledStepperField = styled(Field)`
  .round-button {
    cursor: pointer;
    padding-bottom: 0.125rem;
    &:hover {
      background-color: ${p("platinum")};
      border: none;
      color: ${p("white")};
    }

    &[disabled],
    .disabled {
      cursor: not-allowed;
      &:active {
        pointer-events: none;
      }
      &:after {
        display: block;
      }
    }
  }
`;

const PaySliderContainer = styled(Box)``;

const JobNotificationsLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
`;

function buildFullName(user) {
  return `${user.first_name} ${user.last_name}`;
}

/**
 * Return a decimal calculation given a base wage and a differential amount
 */
function calculateTotalWage(
  wageFloor: string | typeof undefined,
  differentialWage: string | typeof undefined,
) {
  return (
    (wageFloor ? parseFloat(wageFloor) : 0.0) +
    (differentialWage ? parseFloat(differentialWage) : 0.0)
  ).toFixed(2);
}

const mapStateToProps = (state: StateShape) => ({
  isDepartmentsEnabled: isDepartmentsEnabled(state),
  supervisors: getSupervisors(state),
  userCompanyId: getUserCompany(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  fetchSupervisors: () => dispatch(jobActions.supervisors.request()),
});

type State = {
  showCreateSupervisorModal: boolean;
  newSupervisor: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    setFieldValue: Function;
    heading: string;
    position: any | typeof undefined;
    values: any;
    departments: Departments;
    scheduleType: ScheduleType;
  };

class ScheduleDetailsFields extends Component<Props, State> {
  state = {
    showCreateSupervisorModal: false,
    newSupervisor: false,
  };

  componentDidMount() {
    const { fetchSupervisors } = this.props;
    fetchSupervisors();
  }

  toggleCreateSupervisorModal = () => {
    this.setState({
      showCreateSupervisorModal: !this.state.showCreateSupervisorModal,
    });
  };

  handleSupervisorSubmit = (id: string, name: string, setFieldValue: Function) => {
    const { fetchSupervisors } = this.props;
    const supervisorObj = { value: id, label: name };

    // setting a supervisor object when creating a new supervisor
    setFieldValue("supervisor", supervisorObj);
    setFieldValue("supervisor_user_id", id);

    this.setState((prevState) => ({
      showCreateSupervisorModal: !prevState.showCreateSupervisorModal,
      newSupervisor: true,
    }));

    fetchSupervisors();
  };

  renderWorkDays = (values: any) => {
    return DAY_MASK.map((day, idx) => {
      const inputId = `work_day_${idx}`;
      return (
        <StyledCheckbox key={idx}>
          <Input
            type="checkbox"
            id={inputId}
            style={{ display: "none" }}
            checked={values.work_days.includes(`${idx}`)}
            disabled
          />
          <Label htmlFor={inputId} className={"disabled"}>
            {day.name}
          </Label>
        </StyledCheckbox>
      );
    });
  };

  handleChangeSupervisor = (supervisor) => {
    if (supervisor && supervisor.value === "++add") {
      return this.toggleCreateSupervisorModal();
    }

    // setting a supervisor for React Select to handle rendering a selected supervisor properly
    this.props.setFieldValue("supervisor", supervisor);
    this.props.setFieldValue("supervisor_user_id", supervisor.value);
  };

  renderSupervisorsDropdown = (values) => {
    const { supervisors } = this.props;
    const defaultOptions = [{ value: "++add", label: "+ Add a Supervisor" }];

    const options = defaultOptions.concat(
      // @ts-ignore
      supervisors.map((supervisor) => ({
        value: supervisor.id,
        label: buildFullName(supervisor),
      })),
    );

    return (
      <Select
        className="test-ScheduleDetailsFields-select-supervisor"
        isMulti={false}
        onChange={this.handleChangeSupervisor}
        options={options}
        placeholder={ScheduleDetailsCopy.supervisor.placeholder}
        styles={reactSelectStyles}
        value={values.supervisor}
      />
    );
  };

  renderJobNotifications = (values) => {
    const { supervisors } = this.props;

    // @ts-ignore
    const options = supervisors.map((supervisor) => ({
      value: supervisor.id,
      label: buildFullName(supervisor),
    }));
    return (
      <Select
        isDisabled
        isMulti
        options={options}
        placeholder={ScheduleDetailsCopy.jobNotifications.placeholder}
        styles={reactSelectStyles}
        value={values.job_updates}
      />
    );
  };

  renderDepartment = (values) => {
    const { departments } = this.props;
    // This first option allows them to bring up the modal to create a department
    const defaultOptions = [{ value: "++add", label: "+ Add a department" }];

    const options = defaultOptions.concat(
      // @ts-ignore
      departments.map((department) => ({
        value: department.id,
        label: department.tag_name,
      })),
    );

    return (
      <Select
        className="test-ScheduleDetailsFields-select-dept"
        isClearable={false}
        isDisabled
        options={options}
        placeholder={ScheduleDetailsCopy.department.placeholder}
        styles={reactSelectStyles}
        value={values.department}
      />
    );
  };

  // @ts-ignore
  renderScheduleNameField = (values: any, isDisabled: boolean) => {
    const scheduleNamePlaceholder = ScheduleDetailsCopy.schedule_nickname.placeholder;

    return (
      <Field data-testid="schedule-nickname-field">
        <Label htmlFor="schedule_nickname">
          <span>{ScheduleDetailsCopy.schedule_nickname.label}</span>
        </Label>
        {/* TODO:(BW-228) Remove or update any related tests for what used be a CreatableSelect
                      className="test-ScheduleDetailsFields-select-sched-name" */}
        <FormikField name="schedule_nickname">
          {({
            field,
            // @ts-ignore
            values,
            form: { setFieldValue, setFieldTouched },
          }) => (
            <Input
              {...field}
              {...values}
              onBlur={(event) => {
                const value = event.currentTarget.value.trim();
                setFieldValue("schedule_nickname", value);
                // Touch the field so validation runs on the schedule name
                // Formik won't do it otherwise if you use a custom onBlur handler
                setFieldTouched("schedule_nickname");
              }}
              placeholder={scheduleNamePlaceholder}
              disabled={isDisabled}
            />
          )}
        </FormikField>
        <ErrorMessage
          name="schedule_nickname"
          render={(msg) => <div className="error-message">{msg}</div>}
        />
      </Field>
    );
  };

  render() {
    const { setFieldValue, values, heading, position } = this.props;
    const wageFloor = position ? position.wage_floor : undefined;

    const maxWage = wageFloor !== undefined ? (parseFloat(wageFloor) * 2).toFixed(2) : undefined;
    return (
      <FieldsContainer>
        <Group vertical>
          <h6>{SharedCopy.post_a_job}</h6>
          <Heading as="h2" data-testid="CreateScheduleTitle">
            {heading}
          </Heading>
          <Can I="update" a="schedule_name" passThrough>
            {(canUpdateScheduleName) => {
              // Disable this field when editing a schedule (but not while
              // creating one) only if the user does not have permission.
              const isDisabled = !canUpdateScheduleName;
              return this.renderScheduleNameField(values, isDisabled);
            }}
          </Can>
          <Field>
            <Label htmlFor="set_work_days">{ScheduleDetailsCopy.work_days.label}</Label>
            <FormikField
              name="work_days"
              render={() => (
                <Grid className="weekDays-selector" columns="repeat(7, 1fr)" gap="0.85rem">
                  {this.renderWorkDays(values)}
                </Grid>
              )}
            />
          </Field>
          <div>
            <Field className="start-time">
              <Label htmlFor="start_time">{ScheduleDetailsCopy.start_time.label}</Label>
              <FormikField
                name="start_time"
                render={() => (
                  <React.Fragment>
                    <Input
                      type="time"
                      value={values.start_time}
                      step="900" // TODO: add onBlur handler to round to nearest 15min increment, if value is not multiple of 15 #BCEMPL-588
                      disabled
                      className={"disabled"}
                    />
                  </React.Fragment>
                )}
              />
            </Field>
            <Field className="end-time">
              <Label htmlFor="end_time">{ScheduleDetailsCopy.end_time.label}</Label>
              <FormikField
                name="end_time"
                render={() => (
                  <Input
                    type="time"
                    value={values.end_time}
                    step="900"
                    disabled
                    className={"disabled"}
                  />
                )}
              />
            </Field>
          </div>

          <Field>
            <Label htmlFor="supervisor_manager">{ScheduleDetailsCopy.supervisor.label}</Label>
            <FormikField
              name="supervisor_user_id"
              render={({ form: { values } }) => (
                <>
                  <Portal>
                    <Backdrop
                      fade
                      visible={this.state.showCreateSupervisorModal}
                      onClick={this.toggleCreateSupervisorModal}
                    />
                    <StyledOverlay fade visible={this.state.showCreateSupervisorModal}>
                      <SupervisorForm
                        handleSupervisorSubmit={this.handleSupervisorSubmit}
                        setValue={setFieldValue}
                      />
                    </StyledOverlay>
                  </Portal>
                  {this.renderSupervisorsDropdown(values)}
                </>
              )}
            />

            <ErrorMessage
              name="supervisor_user_id"
              render={(msg) => <div className="error-message">{msg}</div>}
            />
          </Field>
          <Field>
            <Label htmlFor="differentialWage">{ScheduleDetailsCopy.differentialWage.label}</Label>
            <FormikField
              name="differentialWage"
              render={({ form: { values } }) => {
                const totalWage = calculateTotalWage(wageFloor, values.differentialWage);
                return (
                  <PaySliderContainer>
                    {/** @ts-ignore */}
                    <WageInput
                      inputId="differentialWage"
                      wageFloor={0.0}
                      wageCeiling={maxWage}
                      defaultWage={values.differentialWage}
                      wageIncrement=".01"
                      payIntervalLabel="per hour"
                      currencySymbol="$"
                      disabled
                      inlineText={ScheduleDetailsCopy.differentialWage.wageInputText}
                      inlineValue={totalWage}
                    />
                  </PaySliderContainer>
                );
              }}
            />
          </Field>
          {this.props.isDepartmentsEnabled && (
            <Field className={"disabled"}>
              <Label htmlFor="department">{ScheduleDetailsCopy.department.label}</Label>
              <FormikField
                name="department"
                validate={validateDepartment}
                render={() => this.renderDepartment(values)}
              />
            </Field>
          )}
          <Field className={"disabled"}>
            <JobNotificationsLabel htmlFor="jobNotifications">
              <span>{ScheduleDetailsCopy.jobNotifications.label}</span>
              <span>Optional</span>
            </JobNotificationsLabel>
            <FormikField
              name="jobNotifications"
              render={({ form: { values } }) => this.renderJobNotifications(values)}
            />
          </Field>
          <StyledStepperField>
            <Label htmlFor="workers_needed">{ScheduleDetailsCopy.workers_needed.label}</Label>
            <FormikField
              name="workers_needed"
              render={() => <WorkersNeeded workersNeeded={values.workers_needed} />}
            />
          </StyledStepperField>
        </Group>
      </FieldsContainer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  formikConnect(
    // @ts-ignore
    ScheduleDetailsFields,
  ),
);
