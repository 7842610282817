import React from "react";
import { useForm } from "react-hook-form";
import { VerificationCodeField } from "../FormFields/VerificationCodeField";
import { PasswordField } from "../FormFields/PasswordField";
import { passwordIsValid } from "../CompanyPasswordRequirements/utils";
import { CompanyPasswordRequirements } from "../CompanyPasswordRequirements";
import { Prettify, ValueOf } from "../../../../types/util-types";
import { CommonFormProps } from "./types";
import { StyledButton, StyledLoginFlowForm } from "../styledComponents";
import { loginStrings } from "../strings";
import { BCButtonVariants } from "../../Button";

export const FieldNames = {
  VerificationCode: "verificationCode",
  NewPassword: "newPassword",
  ConfirmPassword: "confirmPassword",
} as const;
type FieldName = ValueOf<typeof FieldNames>;

const defaultValues = {
  [FieldNames.VerificationCode]: "",
  [FieldNames.NewPassword]: "",
  [FieldNames.ConfirmPassword]: "",
};
type FormValueProps = typeof defaultValues;

export default function ConfirmResetPasswordForm({
  onSubmit,
  resendCode,
  onAnyFieldChange,
  children,
  footer,
}: {
  onSubmit: (code: string, newPassword: string) => void;
  resendCode: () => void;
} & Prettify<CommonFormProps>) {
  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    watch,
  } = useForm({ mode: "onChange", defaultValues });

  const onSubmitWrapper = ({ verificationCode, newPassword }: FormValueProps) => {
    onSubmit(verificationCode, newPassword);
  };

  const getError = (name: FieldName): string | undefined => formErrors[name]?.message;
  const { passwordRequired, enterPasswordLabel } = loginStrings.login;

  const {
    enterCodeLabel,
    confirmPasswordLabel,
    confirmPasswordRequired,
    confirmationCodeRequired,
    changePasswordLabel,
  } = loginStrings.confirmResetPassword;

  return (
    <div>
      <StyledLoginFlowForm onSubmit={handleSubmit(onSubmitWrapper)}>
        <div>{loginStrings.confirmResetPassword.enterCodeBlurb}</div>
        <VerificationCodeField
          fieldName={FieldNames.VerificationCode}
          fieldLabel={enterCodeLabel}
          control={control}
          rules={{
            required: confirmationCodeRequired,
          }}
          fieldError={getError(FieldNames.VerificationCode)}
          onChange={onAnyFieldChange}
        />
        <PasswordField
          fieldName={FieldNames.NewPassword}
          fieldLabel={enterPasswordLabel}
          control={control}
          rules={{
            required: passwordRequired,
            validate: (value: string) => passwordIsValid(value),
          }}
          fieldError={getError(FieldNames.NewPassword)}
          onChange={onAnyFieldChange}
          dataTestId={FieldNames.NewPassword}
        />
        <PasswordField
          fieldName={FieldNames.ConfirmPassword}
          fieldLabel={confirmPasswordLabel}
          control={control}
          rules={{
            required: confirmPasswordRequired,
            validate: (value: string) => watch(FieldNames.NewPassword) === value,
          }}
          fieldError={getError(FieldNames.ConfirmPassword)}
          onChange={onAnyFieldChange}
          dataTestId={FieldNames.ConfirmPassword}
        />
        <div style={{ display: "grid", gap: "12px" }}>
          <CompanyPasswordRequirements
            password={watch(FieldNames.NewPassword)}
            confirmPassword={watch(FieldNames.ConfirmPassword)}
          />
          {children}
          <StyledButton
            variant={BCButtonVariants.Primary}
            label={changePasswordLabel}
            type="submit"
          />
        </div>
      </StyledLoginFlowForm>
      <StyledButton
        style={{ marginTop: "15px" }}
        label={loginStrings.requestNewCode}
        variant={BCButtonVariants.Secondary}
        onClick={(e) => {
          e.preventDefault();
          resendCode();
        }}
      />
      {footer}
    </div>
  );
}
