import { Department, DepartmentListItem } from "../types/propTypes/DepartmentField.types";

export class DepartmentMapper {
  static toListItem = (department: Department): DepartmentListItem => ({
    id: department.id,
    label: department.tag_name,
  });

  static toModel = (department: DepartmentListItem): Department => ({
    id: department.id,
    tag_name: department.label,
  });
}
