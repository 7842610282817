import { FieldErrors } from "react-hook-form";
import { BaseFieldProps } from "./BaseField.types";
import { UserListItemT } from "../../../../components/SelectionList/types";

export interface SupervisorsListItem extends UserListItemT {
  id: number | string;
}

export interface Supervisor {
  id: number | string;
  name: string;
  imgUrl?: string;
}

export type Supervisors = Array<Supervisor>;

export enum SupervisorAccountType {
  CLIENT_ADMIN,
  MANAGER,
  NO_ACCOUNT,
}

export interface CreateSupervisorInput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountType: SupervisorAccountType;
}

export interface SupervisorsFieldProps extends BaseFieldProps {
  supervisors?: Supervisors;
  mixed?: boolean;
  createSupervisor?: (supervisor) => void;
  errors: FieldErrors;
}
