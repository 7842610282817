import React, { Dispatch, SetStateAction } from "react";
import { addDays } from "date-fns";
import { StyledWeekChangeButton } from "./styledComponents";

export type ButtonNextProps = { setWeek: Dispatch<SetStateAction<Date>>; week: Date };

export const ButtonNext = ({ setWeek, week }: ButtonNextProps) => {
  const isCurrentWeek: boolean = new Date() > week && new Date() < addDays(week, 7);

  return (
    <StyledWeekChangeButton
      icon="pi pi-chevron-right"
      disabled={isCurrentWeek}
      onClick={() => {
        setWeek(addDays(week, 7));
      }}
    />
  );
};
