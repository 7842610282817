import React, { Dispatch, SetStateAction } from "react";
import { addDays } from "date-fns";
import { StyledWeekChangeButton } from "./styledComponents";

export type ButtonPreviousProps = { setWeek: Dispatch<SetStateAction<Date>>; week: Date };

export const ButtonPrevious = ({ setWeek, week }: ButtonPreviousProps) => (
  <StyledWeekChangeButton
    icon="pi pi-chevron-left"
    onClick={() => {
      setWeek(addDays(week, -7));
    }}
  />
);
