// ROSTER - COMPONENT
// =============================================================================

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  InlineBlock,
  Button,
  Flex,
  Block,
  Heading,
  Image,
  Link,
  UserAvatar,
  StyledCollapseWithChevron,
  StyledPanel,
} from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";
import moment from "moment-timezone";
import get from "lodash/get";
import { webV1Url } from "../../api/bluecrew/constants/index";
import {
  getApplicantsWithOffers,
  getApplicantsWithoutOffers,
  getSchedule,
  getRosterActiveAndUpcoming,
  getCurrentTotalRequested,
  getCurrentTotalFilled,
} from "../../redux/selectors/schedule";
import { checkIfApplicationJobs } from "../../../shared/helpers";
import { AbilityContext, Can } from "../PermissionsContext";
import { CovidPolicyStatus } from "../../components/CovidPolicyStatus";
import { FillStatus } from "../../components/FillStatus";

const RosterContainer = styled(Flex)`
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 100%;

  ${Heading} {
    display: inline-block;
  }
`;

const RosterHeader = styled(Flex)`
  line-height: 42px;
  margin-bottom: 1rem;

  > ${Block} {
    flex: 1;
  }

  ${Heading} {
    font-size: 20px;
    color: ${p("slate")};
  }
`;

const Tools = styled(Block)`
  text-align: right;
`;

const TotalCount = styled(InlineBlock)`
  color: ${p("brandBlue")};
  padding-left: 0.5rem;
  font-size: 20px;
`;

const RosterBody = styled(Flex)`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 80px;

  ul {
    width: 100%;
    flex: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const StyledFillStatusContainer = styled.div`
  margin-bottom: 32px;
`;

const ListGroup = styled.li`
  margin-top: 32px;

  &:first-child {
    margin-top: 0;
  }

  list-style-type: none;
`;

const GroupHeader = styled(Flex)`
  border-radius: 4px;
  font-size: 12px;
  background-color: ${p("frost")};
  color: #33343d;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.7px;
  padding: 0.8rem 0.5rem 0.8rem 1.5rem;
  display: inline-block;
  width: 25.5rem;
`;

const GroupTitle = styled(Block)`
  display: inline-block;
  float: left;
  flex: 4;
`;

const GroupCount = styled(Block)`
  display: inline-block;
  flex: 1;
  float: right;
`;

const GroupBody = styled.ul`
  li {
    padding: 0.5rem 0;
  }
`;

const RosterItem = styled.li`
  float: none;
  clear: both;

  &:hover {
    background-color: ${p("frost")};
  }
`;

const AvatarContainer = styled(InlineBlock)`
  vertical-align: top;

  ${Image} {
    border-radius: 48px;
  }
`;

const MemberContainer = styled(InlineBlock)`
  vertical-align: top;
  padding: 0 1rem;
`;

const MemberName = styled(Block)`
  display: flex;
  flex-direction: row;

  ${Link} {
    font-size: 1.1rem;
    color: ${p("slate")};
    font-weight: bold;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  & > div {
    margin-left: 6px;
    color: black;
  }
`;

const StyledSpan = styled.span`
  vertical-align: middle;
  padding: 0 6px;
  font-size: 3px; /* workaround for rem bug in Chrome */
  height: 1rem;
  line-height: 1rem;
`;

const VaccinationStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlignedListItem = styled.li`
  vertical-align: middle;
`;

const MemberInfo = styled.ul`
  color: ${p("platinum")};

  li {
    display: table-cell;
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
  }
`;

const mapStateToProps = (state) => ({
  applicantsWithOffers: getApplicantsWithOffers(state),
  applicantsWithoutOffers: getApplicantsWithoutOffers(state),
  rosterActiveAndUpcoming: getRosterActiveAndUpcoming(state),
  schedule: getSchedule(state),
  totalRequested: getCurrentTotalRequested(state),
  totalFilled: getCurrentTotalFilled(state),
});

const mapDispatchToProps = () => ({});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    scheduleId: number;
    handleAddPreviousCrewMemberClick: Function;
  };

class Roster extends React.Component<Props, any> {
  static defaultProps = {
    roster: {
      active: [],
      upcoming: [],
    },
  };

  getRosterTotal() {
    const roster = this.props.rosterActiveAndUpcoming;

    return (get(roster, "active.length") || 0) + (get(roster, "upcoming.length") || 0);
  }

  renderCrewMember(member: any, index: number, key: string) {
    const { schedule } = this.props;
    const {
      covid_exemption_approved_date: covidExemption,
      covid_testing_latest_date: covidTestingLatestDate,
      covid_vaccination_date: covidVaccinationDate,
    } = member;
    const covidPolicyStatusEnabled =
      covidExemption || covidTestingLatestDate || covidVaccinationDate;
    const shiftsWorkedText = member.shifts_worked > 1 ? "Shifts" : "Shift";
    const { timezone } = schedule;
    const dsbSection = key === "dontSendBack";

    const workerStartDate = moment.tz(member.worker_start_date, timezone).format("D MMM");
    const lastWorkedDate = moment.tz(member.last_worked_date, timezone).format("D MMM");
    const dsbDateRange = member.last_worked_date
      ? `${workerStartDate} - ${lastWorkedDate}`
      : "DSB'd before first shift";
    // last_worked_date can be null if a CM has no clockout timestamps for
    // the current job, i.e. the CM hasn't worked a shift for this job.
    // This can happen if a CM NCNS and is DSB'd before working a shift
    const scheduleDates = dsbSection
      ? `${dsbDateRange}`
      : `${member.start_date} - ${member.end_date}`;

    return (
      <RosterItem key={`${member.external_id}-${index}`}>
        <AvatarContainer>
          <UserAvatar imgSrc={member.avatar_url} fullName={member.full_name} size="md" />
        </AvatarContainer>
        <MemberContainer>
          <MemberName>
            <Link href={`${webV1Url}/app.html#!/worker/${member.external_id}`} target="blank">
              {member.full_name}
            </Link>
            <div>{member.job_id}</div>
          </MemberName>
          <MemberInfo>
            <li>{scheduleDates}</li>
            <li>
              <StyledSpan>●</StyledSpan>
            </li>
            <li>
              {member.shifts_worked} {`${shiftsWorkedText} worked`}
            </li>
            {covidPolicyStatusEnabled && (
              <>
                <li>
                  <StyledSpan>●</StyledSpan>
                </li>
                <AlignedListItem>
                  <VaccinationStatusContainer>
                    <CovidPolicyStatus
                      timezone={timezone}
                      covidVaccinationDate={covidVaccinationDate}
                      covidTestingLatestDate={covidTestingLatestDate}
                      covidExemption={covidExemption}
                    />
                  </VaccinationStatusContainer>
                </AlignedListItem>
              </>
            )}
          </MemberInfo>
        </MemberContainer>
      </RosterItem>
    );
  }

  renderGroups() {
    const { rosterActiveAndUpcoming } = this.props;
    return Object.keys(rosterActiveAndUpcoming).map((key) => {
      const group = rosterActiveAndUpcoming[key];
      let title = "";
      switch (key) {
        case "dontSendBack":
          title = "Don't Send Back";
          break;
        case "openDirectInvitees":
          title = "Pending Direct Invites";
          break;
        default:
          title = key;
          break;
      }
      const cannotViewAdmin = this.context.cannot("view", "admin");

      // Only show DSB section to admins per BW-686
      if (key === "dontSendBack" && cannotViewAdmin) {
        return null;
      }

      const groupHeader = (
        <GroupHeader>
          <GroupTitle>{title}</GroupTitle>
          <GroupCount>{group.length}</GroupCount>
        </GroupHeader>
      );

      const scheduleRoster = (
        <StyledPanel key={key} header={groupHeader}>
          <ListGroup>
            <GroupBody>{group.map((member, i) => this.renderCrewMember(member, i, key))}</GroupBody>
          </ListGroup>
        </StyledPanel>
      );

      return group.length > 0 ? scheduleRoster : null;
    });
  }

  render() {
    const rosterTotal = this.getRosterTotal();
    const {
      applicantsWithOffers,
      applicantsWithoutOffers,
      schedule,
      scheduleId,
      totalFilled,
      totalRequested,
    } = this.props;
    const hasApplicationJobs = checkIfApplicationJobs(schedule.request);
    const defaultActiveKeys = ["active", "upcoming"];

    return (
      <RosterContainer>
        <RosterHeader>
          <Block>
            <Heading as="h3">Roster</Heading>
            <TotalCount>{rosterTotal}</TotalCount>
          </Block>

          <Can I="create" a="job">
            <Can I="post" a="company_jobs">
              <Tools>
                <Button
                  data-testid="Add-crew-member"
                  palette="secondary"
                  // @ts-ignore
                  onClick={this.props.handleAddPreviousCrewMemberClick}
                >
                  Add a crew member
                </Button>
              </Tools>
            </Can>
          </Can>
        </RosterHeader>
        <StyledFillStatusContainer>
          {/* TODO: Add correct status here */}
          <FillStatus
            classes="fill-status"
            curated
            workersNeeded={totalRequested}
            workersFilled={totalFilled}
            reviewButtonWidth={85}
            showReviewButton
            applicantsWithoutOffers={applicantsWithoutOffers.length}
            offersPending={applicantsWithOffers.length}
            hasApplicationJobs={hasApplicationJobs}
            scheduleId={scheduleId}
          />
        </StyledFillStatusContainer>
        <RosterBody>
          <StyledCollapseWithChevron defaultActiveKey={defaultActiveKeys}>
            {rosterTotal > 0 && this.renderGroups()}
          </StyledCollapseWithChevron>
        </RosterBody>
      </RosterContainer>
    );
  }
}

Roster.contextType = AbilityContext;

export default connect(mapStateToProps, mapDispatchToProps)(Roster);
