import { styled } from "baseui";

const getMargin = (val: string) => val || "0";

interface DividerProps {
  $marginTop?: string;
  $marginLeft?: string;
  $marginBottom?: string;
  $marginRight?: string;
}

export const Divider = styled<DividerProps, "hr">(
  "hr",
  ({ $marginTop, $marginLeft, $marginBottom, $marginRight, $theme }) => ({
    borderTop: "none",
    borderBottom: `1px solid ${$theme.colors.primary200}`,
    marginTop: getMargin($marginTop!),
    marginLeft: getMargin($marginLeft!),
    marginBottom: getMargin($marginBottom!),
    marginRight: getMargin($marginRight!),
  }),
);
