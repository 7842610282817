import {
  GroupDefinitions,
  SkillItems,
  SkillItemsGroupList,
} from "../../../types/propTypes/SkillsetField.types";

const sortByFunc =
  <T, K extends keyof T>(key: K) =>
  (a: T, b: T) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (b[key] > a[key]) {
      return -1;
    }
    return 0;
  };

/**
 * Sorts a collection
 * @param {list} list - The collection to be sorted
 * @param {key} author - The key to sort by, which should be defined on each object in the collection
 * @return Returns a new, sorted, copy of the collection
 */
export const sortBy = <T, K extends keyof T>(list: Array<T>, key: K) =>
  list.concat().sort(sortByFunc(key));

/**
 * Partitions a list of SkillItems into groups of SkillItems
 * @param {SkillItems} list - The list of skills to be partioned
 * @param {GroupDefinitions} definitions - The definitions for the to be created groups
 * @return A list of SkillItemsGroups
 */
export const createGroups = (list: SkillItems, definitions: GroupDefinitions) => {
  const groups: SkillItemsGroupList = [];
  definitions.forEach((groupDef) => {
    let groupList = groupDef.filter(list);
    groupList = groupDef.sort(groupList);
    groups.push({
      name: groupDef.displayName,
      list: groupList,
    });
  });
  return groups;
};
