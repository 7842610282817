import styled from "styled-components";
import { palette as p } from "styled-tools";

export const StyledContainer = styled.div<{ assetPath: string }>`
  position: relative;
  display: inline-block;

  .account-container {
    display: flex;
    :hover {
      cursor: pointer;
    }
  }

  .avatar-container {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }

  .company-info {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    background-color: #f6f6f7;
    border-bottom: 1px solid #d7d7d9;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 0.5rem;
    height: 4.5rem;

    .company-text {
      display: flex;
      flex-direction: column;
      width: 140px;
    }
    .company-logo {
      border-radius: 3em;
      margin-right: 0.5rem;
    }
  }

  .down-arrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #7a7b81;
    border-radius: 1em;
  }

  .dropdown {
    background-color: #fff;
    border: 1px solid #d7d7d9;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 7px rgba(100, 106, 117, 0.08);

    margin-top: 0.25rem;
    position: absolute;
    text-align: left;
    top: 120%;
    right: 5px;
    width: 15rem;
    z-index: 1000;
  }

  i.icon {
    background-repeat: no-repeat;
    background-position: left 0.25rem center;
    color: #7a7b81;
    padding-left: 2rem;
    margin-left: 0.5rem;

    &.account-icon {
      background-image: url(${({ assetPath }) => assetPath}/account.svg);
    }

    &.billing-info-icon {
      background-image: url(${({ assetPath }) => assetPath}/billing-info.svg);
    }

    &.company-icon {
      background-image: url(${({ assetPath }) => assetPath}/company.svg);
    }

    &.company-users-icon {
      background-image: url(${({ assetPath }) => assetPath}/company-users.svg);
    }

    &.invoices-icon {
      background-image: url(${({ assetPath }) => assetPath}/invoices.svg);
    }

    &.help-icon {
      background-image: url(${({ assetPath }) => assetPath}/help.svg);
    }

    &.log-out-icon {
      background-image: url(${({ assetPath }) => assetPath}/log-out.svg);
    }

    &.referrals-icon {
      background-image: url(${({ assetPath }) => assetPath}/referrals.svg);
    }
  }

  .notification-icon {
    margin-left: 24px;
  }

  .styled-hr {
    width: 200px;
    border-top: 1px solid #d7d7d9;
  }

  ul {
    background-color: #ffffff;
    border-radius: 0.5rem;
    list-style: none;
    padding: 1rem 0 1rem 0;
    margin: 0;
  }
  li {
    padding: 10px;
  }

  li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
  }

  .user-avatar {
    margin-right: 5px;
  }

  .user-container {
    display: flex;
    align-items: center;
    border-left: 1px solid #d7d7d9;
    height: 4rem;
    width: 100%;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 8px;
    text-align: right;
  }

  .user-name {
    font-weight: 600;
  }

  @media only screen and (max-width: 1124px) {
    .notification-icon {
      margin-left: 1.5rem;
      margin-right: 1rem;
    }

    .user-info {
      display: none;
      margin-right: 5px;
      text-align: right;
    }
  }
`;

export const StyledCompanyName = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  word-break: break-all;
  margin-bottom: 2px;
`;

export const StyledSwitchTrigger = styled.span`
  color: ${p("brandBlue")};
  :hover {
    cursor: pointer;
  }
`;
