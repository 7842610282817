import React from "react";
import { connect } from "react-redux";
import messages from "../../../shared/messages";
import { LoadingModal } from "../../components/LoadingModal";
import { ConfirmModal } from "../../components/ConfirmModal";
import StickyNoteIcon from "../../svg/StickyNoteIcon";
import { getOpenApplicationJobsInProgress } from "../../redux/selectors/schedule";
import { StateShape } from "../../redux/reducers";

const mapStateToProps = (state: StateShape) => ({
  openApplicationJobsInProgress: getOpenApplicationJobsInProgress(state),
});

interface OpenRequestToPlatformModalProps extends ReturnType<typeof mapStateToProps> {
  scheduleName: string;
  handleOpenRequestToPlatform: () => void;
  toggleOpenRequestToPlatformModal: () => void;
  showOpenRequestToPlatformModal: boolean;
}

const OpenRequestToPlatformModal = ({
  scheduleName,
  openApplicationJobsInProgress,
  handleOpenRequestToPlatform,
  toggleOpenRequestToPlatformModal,
  showOpenRequestToPlatformModal,
}: OpenRequestToPlatformModalProps) =>
  openApplicationJobsInProgress ? (
    <LoadingModal message={messages.OPEN_APPLICATION_JOBS_TO_PLATFORM.loading} />
  ) : (
    <ConfirmModal
      handleConfirmationClick={handleOpenRequestToPlatform}
      show={showOpenRequestToPlatformModal}
      toggleModal={toggleOpenRequestToPlatformModal}
      headingText={messages.OPEN_APPLICATION_JOBS_TO_PLATFORM.confirm.heading}
      paragraphText={messages.OPEN_APPLICATION_JOBS_TO_PLATFORM.confirm.body(scheduleName)}
      confirmationButtonText={messages.OPEN_APPLICATION_JOBS_TO_PLATFORM.confirm.button}
      confirmationButtonPalette="primary"
      icon={<StickyNoteIcon />}
    />
  );

export default connect(mapStateToProps, null)(OpenRequestToPlatformModal);
