// MODAL
// =============================================================================
// General modal component

import * as React from "react";
import styled from "styled-components";
import { Backdrop, Block, Box, Overlay, Portal } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";

type SvgProps = {
  strokeColor: string;
};

export class CloseModalSvg extends React.Component<SvgProps, any> {
  static defaultProps = {
    strokeColor: "#8A8A8C",
  };

  render() {
    const { strokeColor } = this.props;

    return (
      <svg height="16px" width="16px">
        <title>Close</title>
        <g
          stroke={strokeColor}
          strokeWidth={2}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <path
            d="M-2.28021978,8.06593407 L17.2802198,7.93406593"
            id="Line"
            transform="translate(7.500000, 8.000000) rotate(-45.000000) translate(-7.500000, -8.000000) "
          />
          <path
            d="M-2.28021978,7.93406593 L17.2802198,8.06593407"
            id="Line"
            transform="translate(7.500000, 8.000000) rotate(-315.000000) translate(-7.500000, -8.000000) "
          />
        </g>
      </svg>
    );
  }
}

// @ts-ignore $FlowFixStyledComponents
const StyledOverlay = styled(Overlay)`
  padding: 0;
  width: 42rem;
`;

const StyledContainer = styled(Box)`
  background: ${p("white")};
  border-radius: 4px;
  padding: 32px 100px;
  position: relative;
`;

// @ts-ignore $FlowFixStyledComponents
const CloseModal = styled(Box)`
  height: 32px;
  width: 32px;
  position: absolute;
  top: 32px;
  right: 32px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;

type Props = {
  className: string;
  children?: React.ReactNode;
  showModal: boolean;
  toggleModal: () => void;
};

export class Modal extends React.Component<Props, any> {
  static defaultProps = {
    className: "",
    showModal: false,
    toggleModal: () => {},
  };

  render() {
    const { children, className, showModal, toggleModal } = this.props;

    return (
      <React.Fragment>
        <Portal>
          <Backdrop fade visible={showModal} onClick={toggleModal} />
          <StyledOverlay fade visible={showModal}>
            <StyledContainer className={className}>
              <CloseModal
                // @ts-ignore
                onClick={toggleModal}
              >
                <CloseModalSvg />
              </CloseModal>
              <Block>{children}</Block>
            </StyledContainer>
          </StyledOverlay>
        </Portal>
      </React.Fragment>
    );
  }
}
