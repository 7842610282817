import React, { useEffect, useState } from "react";
import {
  TagsList,
  Tag,
  AlertIcon,
  themedStyled,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import { useController, useWatch } from "react-hook-form";
import { styled } from "baseui";
import { getListItems } from "./CertificationsField/utilities";
import { CustomRequirements } from "../../types/propTypes/CustomRequirementsField.type";
import {
  Requirement,
  Requirements,
  RequirementsFieldProps,
} from "../../types/propTypes/RequirementsField.types";
import { Spacer, SpacerType } from "../../../../components/Spacer";
import { useSelectionList } from "../../useSelectionList";

const LIMIT_FOR_SHOWING_REQUIREMENTS_TAGS = 20;

const isFormValid = (req: Requirements, customReq: CustomRequirements) =>
  req.length || customReq.filter((r) => !!r.trim()).length;

export const RequirementsField = ({
  control,
  trigger,
  requirements = [],
  disabled = false,
  hideTagsIfDisabled = false,
}: RequirementsFieldProps) => {
  const [, theme] = useThemedStyletron();
  const fieldName = "requirements";
  const validate = trigger.bind(null, fieldName);
  const [circuit, setCircuit] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const { customRequirements } = useWatch({
    name: ["customRequirements"],
    control,
  });

  const {
    field: { onChange, value: unsafeValue },
  } = useController({
    name: fieldName,
    control,
    rules: {
      validate: (selected: Requirements) => !!isFormValid(selected, customRequirements),
    },
  });

  const value: Requirements = Array.isArray(unsafeValue) ? (unsafeValue as Requirements) : [];

  const { list, toggleSelection, setList, selected } = useSelectionList(
    getListItems(isTouched, value, requirements),
    {
      searchBy: "id",
      multiSelect: true,
    },
  );

  // Refresh selection list when skillset has changed.
  useEffect(() => {
    setList(getListItems(isTouched, value, requirements));
  }, [requirements]);

  useEffect(() => {
    if (!circuit) {
      setList(getListItems(isTouched, value, requirements));
      setCircuit(true);
    } else {
      setCircuit(false);
    }
  }, [value]);

  useEffect(() => {
    setIsTouched(true);
    onChange(selected);
    validate();
  }, [list]);

  return (
    <>
      {!isFormValid(selected, customRequirements) && (
        <>
          {/* TODO:(BW-375) Re-use existing ErrorMessage component from ErrorMessage.tsx */}
          <ErrorMessageWrapper>
            <AlertIcon />
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale300} />
            <ErrorMessage>Please choose at least one requirement.</ErrorMessage>
          </ErrorMessageWrapper>
          <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
        </>
      )}
      <TagsList
        list={list}
        cap={LIMIT_FOR_SHOWING_REQUIREMENTS_TAGS}
        expander="link"
        renderListItem={(item: Requirement) => {
          if (disabled && hideTagsIfDisabled && !item.selected) {
            // If select is disabled we might want to hide unselected Tags,
            // so user wont be confused with thinking that they are still clickable
            return null;
          }
          return (
            <span key={item.id} style={{ opacity: disabled ? 0.8 : 1 }}>
              <Tag
                label={item.label}
                selected={item.selected}
                // We are passing undefined to remove any visible UI interactions if tag is not clickable
                // @ts-ignore all this old form code needs to be rewritten
                onClick={!disabled ? () => toggleSelection(item) : undefined}
              />
            </span>
          );
        }}
      />
    </>
  );
};

// TODO:(BW-375) Re-use existing ErrorMessage component from ErrorMessage.tsx
const ErrorMessageWrapper = styled("div", () => ({
  display: "flex",
}));

const ErrorMessage = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font250,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.coral,
}));
