export enum ModalKind {
  DEFAULT = 'default',
  RIGHT = 'right',
}

export interface ModalProps {
  body?: JSX.Element;
  footer?: JSX.Element;
  header?: JSX.Element | string;
  isOpen?: boolean;
  kind?: ModalKind;
  maxHeight?: string;
  onClose?: () => void;
  width?: string;
  withCloseButton?: boolean;
}
