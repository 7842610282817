import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import GoogleMapLoader from "react-google-maps-loader";
import { GetCrewFormWrapper } from "../containers/requestCrew/GetCrewFormWrapper";
import { GetCrewMembersRouteComponentProps } from "../containers/requestCrew/GetCrewForm";
import { AbilityContext } from "../containers/PermissionsContext";

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";

export const GetCrewMembersRoutes: React.FC<GetCrewMembersRouteComponentProps> = (
  // eslint-disable-next-line no-empty-pattern
  {},
) => {
  const ability = React.useContext(AbilityContext);
  const navigate = useNavigate();

  const canPostCompanyJobs = ability.can("post", "company_jobs");
  const canCreateJob = ability.can("create", "job");
  const postJobsPermission = canPostCompanyJobs && canCreateJob;

  if (!postJobsPermission) {
    navigate("/positions");
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <GoogleMapLoader
            params={{
              key: GOOGLE_MAPS_API_KEY,
              libraries: "places,geocode",
            }}
            render={(googleMaps) => googleMaps && <GetCrewFormWrapper googleMaps={googleMaps} />}
          />
        }
      />
    </Routes>
  );
};
