import React from "react";
import { ButtonApprove } from "./ButtonApprove";
import { ButtonFlag } from "./ButtonFlag";
import { ButtonUndo } from "./ButtonUndo";
import { TimesheetTabs } from "../../../types/Payroll.types";
import { WorkplaceAction, TimesheetsShiftInfo } from "../../../types/Shift.types";

export type ClientUserActionButtonsProps = {
  activeTabIndex: number;
  allowedWorkplaceActions: Array<WorkplaceAction>;
  rowData: TimesheetsShiftInfo;
};

export const ClientUserActionButtons = ({
  activeTabIndex,
  allowedWorkplaceActions,
  rowData,
}: ClientUserActionButtonsProps) => (
  <>
    {activeTabIndex === TimesheetTabs.PENDING_REVIEW && (
      <>
        <ButtonApprove
          rowData={rowData}
          disabled={!allowedWorkplaceActions.includes(WorkplaceAction.APPROVE)}
        />
        <ButtonFlag
          rowData={rowData}
          disabled={!allowedWorkplaceActions.includes(WorkplaceAction.FLAG)}
        />
      </>
    )}
    {activeTabIndex === TimesheetTabs.APPROVED && (
      <>
        <ButtonFlag
          rowData={rowData}
          disabled={!allowedWorkplaceActions.includes(WorkplaceAction.FLAG)}
        />
        <ButtonUndo
          rowData={rowData}
          disabled={!allowedWorkplaceActions.includes(WorkplaceAction.UNDO)}
        />
      </>
    )}
  </>
);
