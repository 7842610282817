export function formatUri(uri: string, paramsObject: Record<string, string | number>) {
  const formattedParamsObject = {};

  Object.keys(paramsObject).forEach((key) => {
    if (paramsObject[key] !== null && paramsObject[key] !== undefined) {
      formattedParamsObject[key] = paramsObject[key].toString();
    }
  });

  if (Object.keys(formattedParamsObject).length === 0) return uri;

  const searchParams = new URLSearchParams(formattedParamsObject);
  return `${uri}?${searchParams}`;
}
