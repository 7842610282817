import React, { useState } from "react";
import { FullLogo, Portal } from "@bluecrew/web-react-core";

import { createSearchParams, useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import styled from "styled-components";
import { LogoContainer, StyledBackdrop, StyledButton, StyledOverlay } from "../styledComponents";
import { CognitoUtils } from "../../../utility/cognito";
import { LoginForm } from "../Forms/LoginForm";
import { MfaForm } from "../Forms/MfaForm";
import { CreateCompanyAccountLink, CreateWorkerAccountLink } from "../LoginBottomNav";
import { useConfirmSignIn, usePostSignIn, useSignIn } from "../hooks";
import { AuthenticationResult } from "../authenticationTypes";
import { AuthenticationError } from "../Errors/AuthenticationError";
import { CollapsibleMessage } from "../../CollapsableMessage";
import { MessageVariant, MessageVariants } from "../../Message";
import { BCButtonVariants } from "../../Button";
import { loginStrings } from "../strings";
import { ValueOf } from "../../../../types/util-types";
import { BackToLoginButton } from "../BackToLoginButton";
import { setSentryUser } from "../../../sentry";
import { LoginRouteStrings } from "../../../../shared/constants";

const FlowSteps = {
  PreSignin: "preSignIn",
  ConfirmSignIn: "confirmSignIn",
  SignedIn: "signedIn",
} as const;
type FlowStep = ValueOf<typeof FlowSteps>;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.sizing.scale700};
`;

export const LoginFlow = () => {
  const navigate = useNavigate();
  CognitoUtils.setRedirectUrl(window.location.search.match(/\?redirectUrl=(https?:.+)/)?.at(1));

  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [flowStep, setFlowStep] = useState<FlowStep>(FlowSteps.PreSignin);
  const [flowMessage, setFlowMessage] = useState("");
  const [messageVariant, setMessageVariant] = useState<MessageVariant>(MessageVariants.Negative);
  const [userLoginCredentials, setUserLoginCredentials] = useState({
    username: "",
    password: "",
  });

  const postSignIn = usePostSignIn();

  const onSignInSuccess = (result: AuthenticationResult) => {
    // We only need to set cog user and signInStep if there is a second step to do
    // I.e. a user has to put an MFA token in.
    // If the login was successful, skip that (user does not have MFA enabled)
    switch (result.status) {
      case "confirmSignIn":
        setFlowStep(FlowSteps.ConfirmSignIn);
        setFlowMessage(""); // Reset errors between steps
        // Store user creds, so they can be used in the MFA step
        // to request a new MFA code, if needed
        setCognitoUser(result.user);
        break;
      case "signedIn":
        postSignIn(result.username);
        break;
      case "changePassword":
        navigate({
          pathname: LoginRouteStrings.ActivateAccount,
          search: createSearchParams({
            email: userLoginCredentials.username,
            activationCode: userLoginCredentials.password,
          }).toString(),
        });
        break;
      default:
        throw Error(`Sign in status was unexpectedly: ${result.status}`);
    }
  };

  const { mutate: signIn, isLoading: signInIsLoading } = useSignIn(
    onSignInSuccess,
    (error: AuthenticationError) => {
      setMessageVariant(MessageVariants.Negative);
      setFlowMessage(error.message); // onSignInError
    },
  );

  const { mutate: confirmSignIn, isLoading: confirmSignInIsLoading } = useConfirmSignIn(
    () => postSignIn(userLoginCredentials.username), // onConfirmSignInSuccess,
    (error: AuthenticationError) => {
      setMessageVariant(MessageVariants.Negative);
      setFlowMessage(error.message); // onConfirmSignInError,
    },
  );

  return (
    <Portal>
      <StyledBackdrop visible />
      <StyledOverlay visible>
        <LogoContainer>
          <FullLogo height="3rem" />
        </LogoContainer>
        {flowStep === FlowSteps.PreSignin && (
          <LoginForm
            loginInProgress={signInIsLoading}
            login={(username, password) => {
              setUserLoginCredentials({ username, password });
              setSentryUser(username);
              signIn({ username, password });
            }}
            onAnyFieldChange={() => setFlowMessage("")}
          >
            <CollapsibleMessage
              isOpen={!!flowMessage}
              text={flowMessage}
              variant={messageVariant}
            />
          </LoginForm>
        )}
        {flowStep === FlowSteps.ConfirmSignIn && (
          <MfaForm
            username={userLoginCredentials.username}
            requestNewCode={() =>
              signIn(userLoginCredentials, {
                onSuccess: () => {
                  setFlowMessage(loginStrings.codeResent);
                  setMessageVariant(MessageVariants.Positive);
                },
              })
            }
            confirmSignInLoading={confirmSignInIsLoading}
            confirmSignIn={(code: string) => confirmSignIn({ user: cognitoUser, code })}
            footer={
              <BackToLoginButton
                onClickHandler={() => {
                  setFlowMessage("");
                  setFlowStep(FlowSteps.PreSignin);
                }}
              />
            }
            onAnyFieldChange={() => setFlowMessage("")}
          >
            <CollapsibleMessage
              isOpen={!!flowMessage}
              text={flowMessage}
              variant={messageVariant}
            />
          </MfaForm>
        )}
        {/* Only show the bottom action buttons when the user is signing in.
            Otherwise hide them (during password reset, when entering mfa code etc) */}
        {flowStep === FlowSteps.PreSignin && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "5px",
              }}
            >
              <ButtonColumn>
                <StyledButton
                  style={{ paddingTop: 0 }}
                  label={loginStrings.flowNavigation.forgotPasswordLabel}
                  variant={BCButtonVariants.Secondary}
                  onClick={() => navigate(LoginRouteStrings.ResetPassword)}
                />
                <CreateWorkerAccountLink />
              </ButtonColumn>
              <ButtonColumn>
                <StyledButton
                  style={{ paddingTop: 0 }}
                  label={loginStrings.flowNavigation.activateAccountLabel}
                  variant={BCButtonVariants.Secondary}
                  onClick={() => navigate(LoginRouteStrings.ActivateAccount)}
                />
                <CreateCompanyAccountLink />
              </ButtonColumn>
            </div>
          </>
        )}
      </StyledOverlay>
    </Portal>
  );
};
