import React from "react";
import { MenuItemShape } from ".";

interface DropdownItemProps {
  item: MenuItemShape;
  handleRedirect: (url: string) => void;
  key: React.Key;
  closeMenu: () => void;
}

export const DropdownItem = ({ item, handleRedirect, closeMenu }: DropdownItemProps) => {
  const { icon, key, title, url } = item;

  return (
    <li
      onClick={() => {
        handleRedirect(url);
        closeMenu();
      }}
      key={key}
    >
      <i className={`icon ${icon}`} />
      <span>{title}</span>
    </li>
  );
};
