import React from "react";
import { useForm } from "react-hook-form";
import { UsernameField } from "../FormFields/UsernameField";
import { PasswordField } from "../FormFields/PasswordField";
import { ValueOf } from "../../../../types/util-types";
import { StyledButton, StyledLoginFlowForm } from "../styledComponents";
import { BCButtonVariants } from "../../Button";
import { CommonFormProps } from "./types";
import { loginStrings } from "../strings";

export const FieldNames = {
  Username: "username",
  ActivationCode: "activationCode",
} as const;
type FieldName = ValueOf<typeof FieldNames>;

const defaultValues = {
  [FieldNames.Username]: "",
  [FieldNames.ActivationCode]: "",
};
type FormValueProps = typeof defaultValues;

export function InitiateActivationForm({
  activateAccount,
  children,
  activationInProgress,
  showInputs,
  onAnyFieldChange,
  footer,
}: {
  activateAccount: (username: string, password: string) => void;
  activationInProgress: boolean;
  showInputs?: boolean;
} & CommonFormProps) {
  const onSubmit = ({ username, activationCode }: FormValueProps) => {
    activateAccount(username, activationCode);
  };

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const getError = (name: FieldName): string | undefined => formErrors[name]?.message;

  const { blurb, activationCodeFieldLabel, submitButtonText, submittingButtonText } =
    loginStrings.activateAccount;

  return (
    <StyledLoginFlowForm onSubmit={handleSubmit(onSubmit)}>
      {!showInputs && (
        <>
          <div>{blurb}</div>
          <div style={{ display: "grid", gap: "24px" }}>
            <UsernameField
              fieldName={FieldNames.Username}
              fieldLabel={loginStrings.login.enterEmailLabel}
              rules={{ required: `${loginStrings.login.enterEmailLabel} is required.` }}
              control={control}
              fieldError={getError(FieldNames.Username)}
              onChange={onAnyFieldChange}
            />
            <PasswordField
              fieldName={FieldNames.ActivationCode}
              fieldLabel={activationCodeFieldLabel}
              rules={{ required: `${activationCodeFieldLabel} is required.` }}
              control={control}
              fieldError={getError(FieldNames.ActivationCode)}
              onChange={onAnyFieldChange}
              dataTestId={FieldNames.ActivationCode}
            />
          </div>
        </>
      )}
      <div style={{ display: "grid", gap: "12px" }}>
        {children}
        <StyledButton
          label={activationInProgress ? submittingButtonText : submitButtonText}
          type="submit"
          variant={BCButtonVariants.Primary}
        />
        {footer}
      </div>
    </StyledLoginFlowForm>
  );
}
