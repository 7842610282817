import styled from "styled-components";
import { Block, Heading, Image, Paragraph } from "@bluecrew/web-react-core";
import { palette as p } from "styled-tools";

export const Header = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 0 0;
  opacity: 1;
  background: ${p("white")};
  .svg-container,
  ${Image} {
    width: 3.375rem;
    height: 3.375rem;
    padding: 0;
    border-radius: 50%;
    overflow: hidden;
    background: rgb(195, 241, 255);
    text-align: center;
    line-height: 3rem;
    margin: 0 auto;
  }

  .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${Heading} {
    text-align: center;
    font-size: 1.26rem;
    font-weight: 500;
    line-height: 1.75rem;
    margin: 1rem auto 0 auto;
    color: ${p("slate")};
    padding: 0;
    width: 75%;
  }

  ${Paragraph} {
    font-size: 1.01rem;
    line-height: 1.75rem;
    color: ${p("platinum")};
    width: 80%;
    margin: 0.5rem auto 0 auto;
    padding: 0;
  }
`;
