import { webV1Url } from "@bluecrew/web-react-core/src/constants";
import React, { useContext } from "react";
import { AbilityContext } from "../../PermissionsContext";
import { Column, SidebarLink, SideBarWrapper, StyledContainer } from "../styledComponents";

export const BILLING_INFO_TITLE = "Billing info";
export const INVOICES_TITLE = "Invoices";
export const REFERRALS_TITLE = "Referrals";

export const NavSidebar = () => {
  const ability = useContext(AbilityContext);
  const sidebarMenuItems = [
    {
      title: "Your profile",
      icon: "account-icon",
      url: `${webV1Url}/app.html#!/settings/profile`,
      key: "account",
      canView: true,
    },
    {
      title: "Company settings",
      icon: "company-icon",
      url: "/settings/company",
      key: "company-info",
      canView: true,
    },
    {
      title: "Company users",
      icon: "company-users-icon",
      url: `${webV1Url}/app.html#!/settings/users`,
      key: "company-users",
      canView: true,
    },
    {
      title: BILLING_INFO_TITLE,
      icon: "billing-info-icon",
      url: `${webV1Url}/app.html#!/settings/billing`,
      key: "billing-info",
      canView: ability.can("view", "payroll"),
    },
    {
      title: INVOICES_TITLE,
      icon: "invoices-icon",
      url: `${webV1Url}/app.html#!/settings/invoices`,
      key: "invoices",
      canView: ability.can("view", "payroll"),
    },
    // We are temporarily disabling Referrals page while the campaign is paused.
    // We will enable it back once the campaign is back alive.
    // {
    //   title: REFERRALS_TITLE,
    //   icon: "referrals-icon",
    //   url: `${webV1Url}/app.html#!/settings/referrals`,
    //   key: "referalls",
    //   canView: ability.can("create", "company_referral"),
    // },
  ];

  return (
    <SideBarWrapper>
      <Column>
        <StyledContainer assetPath={`${import.meta.env.VITE_ASSET_BASE_URL}/icons`}>
          {sidebarMenuItems.map(
            (item) =>
              item.canView && (
                <SidebarLink key={item.key} href={item.url} target="_self">
                  <i className={`icon ${item.icon}`} />
                  <div>{item.title}</div>
                </SidebarLink>
              ),
          )}
        </StyledContainer>
      </Column>
    </SideBarWrapper>
  );
};
