import { Option } from "baseui/select";
import { BaseFieldProps } from "./BaseField.types";

export interface DepartmentListItem extends Option {
  id: number | string;
  label: string;
}

export interface Department {
  id: number | string;
  tag_name: string;
}

export interface NewDepartment extends DepartmentListItem {
  isNewDepartment?: boolean;
}

export const isNewDepartment = (item: any): item is NewDepartment =>
  typeof item === "object" && "isNewDepartment" in item;

export type Departments = Array<Department>;

export interface DepartmentsFieldProps extends BaseFieldProps {
  departments?: Departments;
  mixed?: boolean;
}
