import { Column, Row } from "react-table";
import React from "react";
import { InternalUser } from "./InternalUser";
import { UserCheckbox } from "./UserCheckbox";
import { TableActions } from "./TableActions";
import { EmailAddress, PhoneNumber, StyledUserAvatar } from "./styledComponents";
import { RoleDropdown } from "./RoleDropdown";
import { USERS_THAT_CAN_ACCESS_ALL_COMPANIES } from "../constants";
import { NameAndStatus } from "./NameAndStatus";

export const TableColumns: Column<InternalUser>[] = [
  {
    id: "select",
    Header: "Select",
    className: "select-user",
    Cell: ({ row }: { row: Row<InternalUser> }) => <UserCheckbox row={row} />,
    disableSortBy: true,
  },
  {
    id: "userPfp",
    Header: "",
    accessor: "userPfp",
    className: "user-pfp",
    Cell: ({ rowData }: { rowData: InternalUser }) => (
      <StyledUserAvatar size={"sm"} imgSrc={rowData.userPfp} fullName={rowData.name} />
    ),
    disableSortBy: true,
  },
  {
    id: "name",
    Header: "Name",
    accessor: "name",
    className: "internal-user-name",
    disableSortBy: false,
    Cell: ({ rowData }: { rowData: InternalUser }) => <NameAndStatus rowData={rowData} />,
  },
  {
    id: "contactInfo",
    Header: "Contact info",
    className: "contact-info",
    disableSortBy: true,
    Cell: ({ rowData }: { rowData: InternalUser }) => (
      <div>
        {rowData.phoneNumber && <PhoneNumber>{rowData.phoneNumber}</PhoneNumber>}
        <EmailAddress href={`mailto:${rowData.emailAddress}`}>{rowData.emailAddress}</EmailAddress>
      </div>
    ),
  },
  {
    id: "companies",
    Header: "Companies",
    accessor: "companyIds",
    className: "assigned-companies",
    disableSortBy: true,
    Cell: ({ rowData }: { rowData: InternalUser }) => (
      <div>
        {USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(rowData.userRole)
          ? "All"
          : rowData.assignedCompanies}
      </div>
    ),
  },
  {
    id: "roles",
    Header: "Roles",
    accessor: "userOrder",
    className: "user-roles",
    Cell: ({ rowData }: { rowData: InternalUser }) => <RoleDropdown rowData={rowData} />,
    disableSortBy: false,
    sortInverted: true,
  },
  {
    id: "tableActions",
    Header: "",
    className: "table-actions",
    Cell: ({ rowData }: { rowData: InternalUser }) => <TableActions user={rowData} />,
    disableSortBy: true,
  },
];
