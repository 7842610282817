import { $Values } from "utility-types";

// Confirmation Modal - ACTIONS
// =============================================================================

import { createActions } from "redux-actions";

import { SimpleModalTypes } from "../../components/SimpleModal";
import { Modals } from "../../../shared/constants";

type OpenModalPayload = {
  contentType?: $Values<typeof Modals>;
  heading?: string;
  body?: string;
  redirectPath?: null | string;
  responseType: $Values<typeof SimpleModalTypes>;
};

export default createActions({
  CLOSE_MODAL: (data: any) => ({ ...data }),
  OPEN_MODAL: (data: OpenModalPayload) => ({ ...data }),
}) as any;
