import * as React from 'react';

import { DefaultModal } from './DefaultModal';
import { Drawer } from './Drawer';
import { ModalKind, ModalProps } from './Modal.types';

export const Modal = ({
  kind = ModalKind.RIGHT,
  header,
  body,
  footer,
  isOpen,
  onClose,
  maxHeight = '80%',
  width = '467px',
  withCloseButton = true,
}: ModalProps) => {
  const Components = {
    right: Drawer,
    default: DefaultModal,
  };

  return React.createElement(Components[kind], {
    kind,
    header,
    body,
    footer,
    isOpen,
    onClose,
    maxHeight,
    width,
    withCloseButton,
  });
};
