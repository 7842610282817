import { Typography } from 'baseui/theme';
import { primitives } from './primitives';

const { primaryFontFamily } = primitives;

const font100 = {
  fontFamily: primaryFontFamily,
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '20px',
};
const font150 = {
  fontFamily: primaryFontFamily,
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '20px',
};
const font200 = {
  fontFamily: primaryFontFamily,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
};
const font250 = {
  fontFamily: primaryFontFamily,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
};
const font300 = {
  fontFamily: primaryFontFamily,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
};
const font350 = {
  fontFamily: primaryFontFamily,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
};
const font400 = {
  fontFamily: primaryFontFamily,
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28px',
};
const font450 = {
  fontFamily: primaryFontFamily,
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px',
};
const font550 = {
  fontFamily: primaryFontFamily,
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
};
const font650 = {
  fontFamily: primaryFontFamily,
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
};
const font750 = {
  fontFamily: primaryFontFamily,
  fontSize: '28px',
  fontWeight: 600,
  lineHeight: '36px',
};
const font850 = {
  fontFamily: primaryFontFamily,
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '40px',
};
const font950 = {
  fontFamily: primaryFontFamily,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
};
const font1050 = {
  fontFamily: primaryFontFamily,
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '52px',
};
const font1150 = {
  fontFamily: primaryFontFamily,
  fontSize: '36px',
  fontWeight: 600,
  lineHeight: '44px',
};
const font1250 = {
  fontFamily: primaryFontFamily,
  fontSize: '44px',
  fontWeight: 600,
  lineHeight: '52px',
};
const font1350 = {
  fontFamily: primaryFontFamily,
  fontSize: '52px',
  fontWeight: 600,
  lineHeight: '64px',
};
const font1450 = {
  fontFamily: primaryFontFamily,
  fontSize: '96px',
  fontWeight: 600,
  lineHeight: '112px',
};

export const typography: Typography = {
  font100,
  font150,
  font200,
  font250,
  font300,
  font350,
  font400,
  font450,
  font550,
  font650,
  font750,
  font850,
  font950,
  font1050,
  font1150,
  font1250,
  font1350,
  font1450,

  ParagraphXSmall: font100,
  ParagraphSmall: font200,
  ParagraphMedium: font300,
  ParagraphLarge: font400,
  LabelXSmall: font150,
  LabelSmall: font250,
  LabelMedium: font350,
  LabelLarge: font450,
  HeadingXSmall: font550,
  HeadingSmall: font650,
  HeadingMedium: font750,
  HeadingLarge: font850,
  HeadingXLarge: font950,
  HeadingXXLarge: font1050,
  DisplayXSmall: font1150,
  DisplaySmall: font1250,
  DisplayMedium: font1350,
  DisplayLarge: font1450,
};
