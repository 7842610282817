import React from "react";

export const RejectedIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.274" cy="12" r="12" fill="#E72E3E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.54847 15.4001C7.18233 15.7662 7.18233 16.3598 7.54847 16.726C7.9146 17.0921 8.50822 17.0921 8.87435 16.726L12.2739 13.3265L15.6736 16.7262C16.0398 17.0924 16.6334 17.0924 16.9995 16.7262C17.3657 16.3601 17.3657 15.7665 16.9995 15.4003L13.5998 12.0006L16.9995 8.60084C17.3657 8.23471 17.3657 7.64109 16.9995 7.27496C16.6334 6.90882 16.0398 6.90882 15.6736 7.27496L12.2741 10.6745L8.87435 7.27469C8.50822 6.90856 7.9146 6.90856 7.54847 7.27469C7.18233 7.64083 7.18233 8.23445 7.54847 8.60058L10.9482 12.0003L7.54847 15.4001Z"
      fill="white"
    />
  </svg>
);
