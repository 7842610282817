import { PasswordReqs, passwordReqs } from "./constants";

export const passwordIsValid = (password: string) => {
  const result = passwordReqs.filter((req) => !req.reqTest(password));
  return result.length === 0;
};

/**
 * Get password requirements that the given password do not meet.
 */
export const getRequirementsNotMet = (password: string): PasswordReqs[] =>
  passwordReqs.filter((req) => !req.reqTest(password));

/**
 * Get password requirements that the given password meet.
 */
export const getRequirementsMet = (password: string): PasswordReqs[] =>
  passwordReqs.filter((req) => req.reqTest(password));
