// NavBar - COMPONENT
// =============================================================================

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import { AdminDropdown } from "../AdminDropdown";
import { AccountDropdown } from "../AccountDropdown";
import {
  getCompanyName,
  getCompanyLogoUrl,
  getParentCompanyId,
  getUserCompanies,
} from "../../redux/selectors/company";
import {
  getUserAvatar,
  getUser,
  getUserFullName,
  getViewAdminToolsPermission,
  getBranchSwitchCompanyPermission,
} from "../../redux/selectors/auth";
import { NavMenu } from "../NavMenu";
import companyActions from "../../redux/actions/company";
import { Can } from "../../containers/PermissionsContext";
import { StateShape } from "../../redux/reducers";
import { StyledRow } from "./styledComponents";
import { accountMenuItems } from "./utils";

const mapStateToProps = (state: StateShape) => ({
  companyName: getCompanyName(state),
  companyLogoUrl: getCompanyLogoUrl(state),
  parentCompanyId: getParentCompanyId(state),
  hasViewAdminToolsPermission: getViewAdminToolsPermission(state),
  userCanSwitchCompaniesAsBranch: getBranchSwitchCompanyPermission(state),
  userAvatar: getUserAvatar(state),
  userCompanies: getUserCompanies(state),
  userData: getUser(state),
  userFullName: getUserFullName(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserCompanies: (payload: any): void => {
    dispatch(companyActions.userCompanies.request(payload));
  },
});

interface NavBarProps
  extends ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}

const NavBar = ({
  companyName,
  companyLogoUrl,
  userAvatar,
  userCompanies,
  userFullName,
  fetchUserCompanies,
  hasViewAdminToolsPermission,
  userCanSwitchCompaniesAsBranch,
  parentCompanyId,
  userData,
}: NavBarProps) => {
  // @ts-ignore
  const user = userData?.user || {};

  const userCanSwitchCompanies = () =>
    Boolean(hasViewAdminToolsPermission || userCanSwitchCompaniesAsBranch || parentCompanyId);

  useEffect(() => {
    if (userCanSwitchCompanies()) {
      fetchUserCompanies({
        parentCompanyId,
        shouldFetchAllCompanies: hasViewAdminToolsPermission,
        shouldFetchBranchCompanies: userCanSwitchCompaniesAsBranch,
      });
    }
  }, [parentCompanyId]);

  return (
    <StyledRow middle="xs" data-testid="navbar">
      <Col xs={1} sm={1} md={1} lg={1} className="icon-area">
        <Link to="/">
          <img src="/public/images/icons/bc-logo.svg" alt="" className="bluecrew-logo" />
          <img src="/public/images/icons/bluecrew-logo.svg" alt="" className="small-logo" />
        </Link>
      </Col>
      <Col xs={11} sm={11} md={11} lg={11} className="button-area">
        <Row middle="xs" end="xs" className="right-pane">
          <Col className="nav-menu-container">
            <NavMenu />
          </Col>
          <Col className="admin-dropdown">
            <Can I="view" a="admin">
              <AdminDropdown />
            </Can>
          </Col>

          <Col className="account-dropdown">
            <AccountDropdown
              assetPath="/public/images/icons/"
              canSwitchCompanies={userCanSwitchCompanies()}
              companyName={companyName}
              companyLogoUrl={companyLogoUrl}
              menuItems={accountMenuItems}
              userCompanies={userCompanies}
              userEmail={user.username}
              userImage={userAvatar}
              userFullName={userFullName}
            />
          </Col>
        </Row>
      </Col>
    </StyledRow>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
