import React, { createRef, useEffect, useState } from "react";
import { Button, Modal, SelectionButton, SelectionButtonKinds } from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { ModalBodyWrapper } from "../ModalBodyWrapper";
import { ScheduleType } from "../types/propTypes/Schedule.types";
import {
  Schedule,
  ScheduleFieldProps,
  ScheduleListItem,
} from "../types/propTypes/ScheduleField.types";
import { ScheduleMapper } from "./ScheduleMapper";
import { NEW_REPEATING_SCHEDULE } from "../formUtils";
import {
  SelectionList,
  ScheduleListItem as ScheduleListItemRenderer,
} from "../../../components/SelectionList";
import { useSelectionList } from "../useSelectionList";
import { StyledModalFooterWrapper } from "../StyledModalFooterWrapper";

export const ScheduleField = ({
  value,
  onChange,
  onClose,
  invalid,
  schedules,
  mapper = ScheduleMapper,
  isPositionSelected,
}: ScheduleFieldProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const closeModal = () => setModalOpen(false);

  const getListItems = () => {
    if (schedules) {
      return schedules
        .filter((s) => s.scheduleType === ScheduleType.REPEATING)
        .map((s) => mapper.toListItem(s, value && value.id === s.id));
    }

    return [];
  };

  const { list, toggleSelection, setList, selected, deselect } = useSelectionList<ScheduleListItem>(
    getListItems(),
    {
      searchBy: "id",
    },
  );

  // Refresh selection list when position changes.
  useEffect(() => {
    schedules && setList(getListItems());
  }, [schedules]);

  const setScheduleValue = (schedule: Schedule) => {
    onChange(schedule);
    closeModal();
  };

  useEffect(() => {
    const [schedule] = selected;

    if (schedule) {
      setScheduleValue(mapper?.toModel(schedule));
    }
  }, [list]);

  const modalRootRef = createRef<HTMLDivElement>();

  return (
    <>
      <SelectionButton
        placeholder="Choose a schedule"
        kind={SelectionButtonKinds.NORMAL}
        onClick={() => setModalOpen(true)}
        error={invalid}
        header={value?.name}
        dataTestId="ScheduleField-SelectionButton"
      />
      <Modal
        header="Schedules"
        body={
          <ModalBodyWrapper ref={modalRootRef}>
            {isPositionSelected ? (
              <SelectionList<ScheduleListItem>
                rootRef={modalRootRef}
                items={list}
                listItemRenderer={ScheduleListItemRenderer}
                onItemClick={toggleSelection}
              />
            ) : (
              <Text>Before selecting a schedule, please select a position</Text>
            )}
          </ModalBodyWrapper>
        }
        footer={
          <StyledModalFooterWrapper>
            <Button
              disabled={!isPositionSelected}
              width="100%"
              onClick={() => {
                // Deselect selected schedule after Create New Schedule button was pressed
                selected.length && deselect(selected[0]);
                setScheduleValue(NEW_REPEATING_SCHEDULE);
              }}
              dataTestId="ScheduleField-NewScheduleButton"
            >
              New Schedule
            </Button>
          </StyledModalFooterWrapper>
        }
        isOpen={modalOpen}
        onClose={() => {
          closeModal();
          return onClose();
        }}
        width={"750px"}
      />
    </>
  );
};

const Text = styled("p", ({ $theme }) => ({
  color: $theme.colors.mono700,
  ...$theme.typography.font200,
  marginTop: $theme.sizing.scale200,
  marginBottom: "0px",
}));
