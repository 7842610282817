import React from "react";
import { RowContainer } from "../SharedComponents";

type GenericCellType = {
  value: string;
  justifyContent?: string;
};

export const GenericCell = ({ value, justifyContent }: GenericCellType) => (
  <RowContainer
    data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnGeneric"
    justifyContent={justifyContent}
  >
    {value}
  </RowContainer>
);
