/* eslint-disable max-classes-per-file */
import React from "react";
import debounce from "lodash/debounce";
import styled from "styled-components";
import { Input, Label, Divider, Spinner, UserAvatar } from "@bluecrew/web-react-core";

import { FixedSizeList } from "react-window";

import { palette as p } from "styled-tools";

const StyledContainer = styled("div")`
  background-color: ${p("white")};
  height: 20.25rem;
  width: 31.25rem;

  ${Input} {
    border-radius: 0.3125rem;
  }
`;

const StyledListContainer = styled.div`
  overflow: hidden;
`;

const StyledDivider = styled(Divider)`
  margin: 1.25rem 0 0 0;
  color: ${p("aluminum")};
`;

const StyledSelectAllLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${Label} {
    padding: 0;
    margin: 0;
  }
`;

const StyledSelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.9375rem 0;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${Label} {
    padding-bottom: 0;
    padding-left: 0.625rem;
  }
`;

const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;

  ${Label} {
    text-align: start;
    :hover {
      cursor: pointer;
    }
  }
`;

const StyledRadioInput = styled.input`
  height: 1.25rem;
  width: 1.25rem;
  background-color: ${p("white")};
  border-radius: 50%;
  margin-right: 1rem;

  border: 0.0625rem solid #ddd;
  -webkit-appearance: none;

  :hover {
    cursor: pointer;
  }

  :checked {
    background-color: ${p("brandBlue")};
    border-color: ${p("brandBlue")};
    background-image: url("/public/images/check-mark-white.svg");
  }

  :focus {
    outline: none;
  }
`;

const StyledSpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 325px;
`;

const StyledResultsContainer = styled.div`
  margin-top: 1rem;
`;

const StyledZeroState = styled.p`
  text-align: center;
`;

type ListItemShape = {
  avatar: string | null;
  id: string;
  location: string | null;
  name: string;
};

type RenderAvatar = (arg0: { avatarUrl: string }) => React.ReactElement<typeof React.Component>;

type Props = {
  isFetchingList?: boolean;
  listItems: Array<ListItemShape>;
  noListItemsMessage: string;
  onChangeItem: (valueOrValues: Array<string>) => void;
  renderAvatar?: RenderAvatar;
  searchBarPlaceholder: string | null | undefined;
  selectAllText: string | null | undefined;
  selectedIds: Array<number> | null | undefined;
  singleSelect: boolean | null | undefined;
};

type State = {
  searchTerm: string;
  selectedListItems: any;
  isAllChecked: boolean;
};

type ItemProps = {
  item: ListItemShape;
  handler: (e: React.SyntheticEvent<any>) => void;
  renderAvatar?: RenderAvatar;
  selected: boolean;
};

class ListItem extends React.PureComponent<ItemProps> {
  render() {
    const { handler, selected, renderAvatar } = this.props;
    const { avatar, id, location, name } = this.props.item;

    return (
      <StyledListItem key={id}>
        <StyledInfoContainer>
          {avatar && renderAvatar ? (
            renderAvatar({ avatarUrl: avatar })
          ) : (
            <UserAvatar imgSrc={avatar} fullName={name} size="md" />
          )}
          <StyledLabelContainer>
            <Label htmlFor={id}>{name}</Label>
            {location && <Label htmlFor={id}>{location}</Label>}
          </StyledLabelContainer>
        </StyledInfoContainer>
        <StyledRadioInput
          name={name}
          id={id}
          type="checkbox"
          checked={!!selected}
          onChange={handler}
          data-testid="ListItem-RadioInput"
        />
      </StyledListItem>
    );
  }
}

type ReactWindowRenderProps = {
  data: any;
  index: number;
  style: any;
};

const RenderListItem = ({ data, index, style }: ReactWindowRenderProps) => {
  const { filteredListItems, handler, renderAvatar, selected } = data;
  const item = filteredListItems[index];

  return (
    <div style={style}>
      <ListItem
        handler={handler}
        item={item}
        renderAvatar={renderAvatar}
        selected={selected[item.id]}
      />
    </div>
  );
};

export class SearchableList extends React.Component<Props, State> {
  static defaultProps = {
    isFetchingList: false,
    noListItemsMessage: "Sorry, you don't have any items... Please add some and try again.",
    searchBarPlaceholder: "Search...",
    selectAllText: "Items",
    selectedIds: [],
    singleSelect: false,
  };

  constructor(props: Props) {
    const selectedIds = {};
    props.selectedIds &&
      props.selectedIds.forEach((id) => {
        selectedIds[id] = true;
      });
    super(props);
    this.state = {
      searchTerm: "",
      selectedListItems: selectedIds,
      isAllChecked: false,
    };
  }

  handleSelectAll = () => {
    const { listItems } = this.props;
    const { selectedListItems } = this.state;
    const listItemsCopy = {};

    const selectedItems = Object.keys(selectedListItems);

    if (listItems.length === selectedItems.length) {
      // deselect all
      this.setState({ selectedListItems: listItemsCopy, isAllChecked: false }, this.handleChange);
    } else {
      listItems.forEach((member) => {
        listItemsCopy[member.id] = true;
      });
      this.setState({ selectedListItems: listItemsCopy, isAllChecked: true }, this.handleChange);
    }
  };

  handleSelectItem = (e: React.SyntheticEvent<any>) => {
    const { listItems, singleSelect } = this.props;
    const clickedCheckbox = e.currentTarget.attributes.id.value;
    const listItemsCopy = singleSelect ? {} : { ...this.state.selectedListItems };

    if (listItemsCopy[clickedCheckbox]) {
      delete listItemsCopy[clickedCheckbox];
    } else {
      listItemsCopy[clickedCheckbox] = true;
    }

    this.setState({ selectedListItems: listItemsCopy }, () => {
      // handling when items have been individually selected
      if (listItems.length === Object.keys(listItemsCopy).length) {
        this.setState({
          selectedListItems: listItemsCopy,
          isAllChecked: true,
        });
      } else {
        this.setState({ isAllChecked: false });
      }

      this.handleChange();
    });
  };

  /*
   * De-select any items that do not match the search results.
   *
   * This will prevent the user from submitting the form with
   * invisible values.
   */
  syncWithSearchResults = debounce(() => {
    const { singleSelect } = this.props;

    if (!singleSelect) {
      // TODO: think of a way to support this feature for multi-selects.
      // When in multi-select mode, the search box is helpful in finding
      // items that can be added; deselecting items will not be helpful.
      return;
    }

    const selectedListItems = { ...this.state.selectedListItems };
    const filteredItemIds = new Set(this.getItemsForSearch().map((item) => item.id));

    let deselected = false;
    for (const id of Object.keys(selectedListItems)) {
      if (!filteredItemIds.has(id)) {
        delete selectedListItems[id];
        deselected = true;
      }
    }
    if (deselected) {
      this.setState({ selectedListItems });
      this.handleChange();
    }
  }, 400);

  // @ts-ignore
  handleUserInput = (e: React.InputEvent<EventTarget>) => {
    this.setState({ searchTerm: e.target.value }, this.syncWithSearchResults);
  };

  handleChange = () => {
    const { onChangeItem } = this.props;
    const { selectedListItems } = this.state;

    // formatting the selected list items into an array of id's for both multi and single select
    const formattedValues = Object.keys(selectedListItems);

    onChangeItem(formattedValues);
  };

  getItemsForSearch(): Array<ListItemShape> {
    const { listItems } = this.props;

    const term = this.state.searchTerm.toLowerCase();
    return listItems.filter((item) => item.name.toLowerCase().indexOf(term) !== -1);
  }

  render() {
    const {
      isFetchingList,
      listItems,
      noListItemsMessage,
      selectAllText,
      searchBarPlaceholder,
      singleSelect,
    } = this.props;
    const { selectedListItems } = this.state;

    const numSelectedItems = Object.keys(selectedListItems).length;
    const selectDeselectText = listItems.length === numSelectedItems ? "Deselect" : "Select";

    const filteredListItems = this.getItemsForSearch();

    const itemData = {
      filteredListItems,
      renderAvatar: this.props.renderAvatar,
      handler: this.handleSelectItem,
      selected: this.state.selectedListItems,
    };

    return (
      <StyledContainer>
        {isFetchingList ? (
          <StyledSpinnerContainer>
            <Spinner />
          </StyledSpinnerContainer>
        ) : (
          <div>
            {listItems.length ? (
              <>
                <Input
                  onChange={this.handleUserInput}
                  // @ts-ignore
                  placeholder={searchBarPlaceholder}
                />
                {!singleSelect && selectAllText && (
                  <StyledSelectAllContainer>
                    <StyledSelectAllLabels>
                      <Label>{`${selectAllText} (${numSelectedItems}/${listItems.length})`}</Label>
                      <Label>{`${selectDeselectText} All`}</Label>
                    </StyledSelectAllLabels>
                    <div>
                      <StyledRadioInput
                        onChange={this.handleSelectAll}
                        id="selectAll"
                        type="checkbox"
                        checked={this.state.isAllChecked}
                      />
                    </div>
                  </StyledSelectAllContainer>
                )}

                <StyledDivider />

                <div>
                  <StyledListContainer>
                    {filteredListItems.length === 0 ? (
                      <StyledResultsContainer>
                        <span>No results found...</span>
                      </StyledResultsContainer>
                    ) : (
                      <FixedSizeList
                        height={275}
                        itemCount={filteredListItems.length}
                        itemData={itemData}
                        itemSize={65}
                        width={500}
                      >
                        {RenderListItem}
                      </FixedSizeList>
                    )}
                  </StyledListContainer>
                </div>
              </>
            ) : (
              <StyledZeroState>{noListItemsMessage}</StyledZeroState>
            )}
          </div>
        )}
      </StyledContainer>
    );
  }
}
