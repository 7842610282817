import React from "react";
import { Route, Routes } from "react-router-dom";
import { Positions } from "../containers/positions/Positions";
import { EditPosition } from "../containers/EditPosition";
import { GetCrewMembersRoutes } from "./GetCrewMembersRoutes";

export const PositionsRoutes = () => (
  <Routes>
    <Route path="/" element={<Positions />} />
    <Route path="new/*" element={<GetCrewMembersRoutes />} />
    <Route path=":positionId/edit" element={<EditPosition />} />
  </Routes>
);
