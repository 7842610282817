import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 16px;
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  line-height: 16px;
  font-weight: 400;
  color: #55565f;
  margin-left: 8px;
`;
