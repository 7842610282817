import { DaysOfWeek } from "@bluecrew/blueprint-web";
import { Schedule, ScheduleListItem } from "../types/propTypes/ScheduleField.types";

export class ScheduleMapper {
  static toListItem = (schedule: Schedule, selected: boolean = false): ScheduleListItem => ({
    id: schedule.id,
    title: schedule.name,
    workingHours: schedule.workingHours.formatted,
    workingHoursEx: schedule.workingHours,
    workingDays: schedule.workingDays as DaysOfWeek,
    selected,
    scheduleType: schedule.scheduleType,
    totalWage: schedule.totalWage,
    positionWage: schedule.positionWage,
  });

  static toModel = (listItem: ScheduleListItem): Schedule => ({
    id: listItem.id,
    name: listItem.title,
    workingHours: listItem.workingHoursEx,
    workingDays: listItem.workingDays,
    scheduleType: listItem.scheduleType,
    totalWage: listItem.totalWage,
    positionWage: listItem.positionWage,
  });
}
