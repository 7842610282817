import { StatefulMenu } from "baseui/menu";
import React from "react";
import { RichListItem } from "./RichListItem";
import { BaseListItemT, SelectionListProps } from "./types";
import { StyledSelectionList } from "./styled-components";

export function SelectionList<T extends BaseListItemT>({
  items,
  onItemClick,
  topDivider = false,
  bottomDivider = false,
  listItemRenderer,
  rootRef,
}: SelectionListProps<T>) {
  return (
    <StatefulMenu
      rootRef={rootRef}
      items={items}
      onItemSelect={({ item }: { item: T }) => onItemClick && onItemClick(item)}
      overrides={{
        List: {
          component: StyledSelectionList,
          props: {
            $topDivider: topDivider,
            $bottomDivider: bottomDivider,
          },
        },
        Option: {
          component: listItemRenderer || RichListItem,
          props: {
            underlineLastItem: !bottomDivider,
          },
        },
      }}
    />
  );
}
