// MINIMUM WAGE - ACTIONS
// =============================================================================

import { createActions } from "redux-actions";

const START_CALC = "START_CALC";

export default createActions({
  [START_CALC]: () => {},
  READ_MIN_WAGE: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => ({ ...res }),
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
}) as any;
