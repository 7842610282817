import { DaysOfWeek, Weekdays } from "@bluecrew/blueprint-web";
import styled from "styled-components";
import React, { useContext } from "react";
import { Grid, Link } from "@bluecrew/web-react-core";
import { JobRequest } from "../../../api/bluecrew/types";
import { useNavigateToScheduleDetailPage } from "../navigationHooks";
import { JobRequestTableRow } from "./JobRequestTableRow";
import { AbilityContext } from "../../PermissionsContext";
import { getWageText } from "../../../utility/getWageText";

interface JobRequestTableProps {
  jobRequestData: JobRequest;
}

const StyledLink = styled(Link)`
  cursor: pointer;
  font-size: 16px;
  font-weight: medium;
`;

export const JobRequestTable = ({ jobRequestData }: JobRequestTableProps) => {
  const ability = useContext(AbilityContext);
  const canViewWages = ability.can("view", "wages");
  const navigateToScheduleDetailPage = useNavigateToScheduleDetailPage();

  const row1 = [
    {
      cellTitle: "SCHEDULE NAME",
      cellContents: (
        <>
          <StyledLink
            onClick={() => {
              navigateToScheduleDetailPage(jobRequestData.schedule_id);
            }}
          >
            <span>{jobRequestData.schedule_name}</span>
          </StyledLink>
        </>
      ),
    },
    {
      cellTitle: "WAGE",
      cellContents: getWageText(canViewWages, jobRequestData.base_wage.toFixed(2)),
    },
  ];

  /**
   * Returns date formatted as `Tue, Mar 8, 2022`
   */
  const formatDateString = (dateString: string) =>
    new Date(dateString).toLocaleDateString("en-US", {
      timeZone: jobRequestData.timezone,
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    });

  const workDays = jobRequestData.work_days.map((w) => w.toString()) as DaysOfWeek;
  const row2 = [
    {
      cellTitle: "WORKDAYS",
      cellContents: <Weekdays size="compact" selected={workDays} editable={false} />,
    },
    {
      cellTitle: "START TIME",
      cellContents: `${jobRequestData.start_time}`,
    },
    {
      cellTitle: "END TIME",
      cellContents: `${jobRequestData.end_time}`,
    },
  ];
  const row3 = [
    {
      cellTitle: "START DATE",
      cellContents: `${formatDateString(jobRequestData.start_date_time)}`,
    },
    {
      cellTitle: "END DATE",
      cellContents: `${formatDateString(jobRequestData.end_date_time)}`,
    },
    {
      cellTitle: "SHIFTS",
      cellContents: `${jobRequestData.shifts}`,
    },
  ];

  const row4 = [
    {
      cellTitle: "FILL STATUS",
      cellContents: `${jobRequestData.filled} / ${jobRequestData.needed}`,
    },
    {
      cellTitle: "DIRECT INVITED",
      cellContents: `${jobRequestData.direct_invite_count}`,
    },
    {
      cellTitle: "APPLICATION JOB",
      cellContents: `${jobRequestData.is_application > 0 ? "Yes" : "No"}`,
    },
  ];

  return (
    <Grid>
      <JobRequestTableRow cells={row1} />
      <JobRequestTableRow cells={row2} />
      <JobRequestTableRow cells={row3} />
      <JobRequestTableRow cells={row4} />
    </Grid>
  );
};
