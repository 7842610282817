import { DashboardPositionJobs, JobRequest, ListedPosition } from "./types";
import { apiWithErrorRedirect } from "./constants";

type GetPositionsProps = {
  companyId: number;
  // positionId: number;
};

type ApplicationsToReviewData = {
  position_count: number;
  schedule_count: number;
  review_count: number;
};

export const getDashboardPositions = ({
  companyId,
}: // TODO: BW-1488 Update Type
GetPositionsProps): Promise<Array<ListedPosition>> => {
  const url = `v2/dashboard/${companyId}/positions`;

  return apiWithErrorRedirect.get(url).json();
};

export const getDashboardCompanyApplicationsToReviewData = (
  companyId: number,
): Promise<ApplicationsToReviewData> => {
  const url = `v2/dashboard/${companyId}/openApplicationCount`;

  return apiWithErrorRedirect.get(url).json();
};

export const getRepeatingSchedulesAndIndividualShiftsPerPosition = (
  companyId: number,
  positionId: number,
): Promise<DashboardPositionJobs> => {
  const url = `v2/dashboard/${companyId}/positions/${positionId}/repeatingSchedulesAndIndividualShifts`;

  return apiWithErrorRedirect.get(url).json();
};

export const getJobRequestDetails = (
  companyId: number,
  positionId: number,
  requestId: number,
): Promise<JobRequest> => {
  const url = `v2/dashboard/${companyId}/positions/${positionId}/requests/${requestId}`;

  return apiWithErrorRedirect.get(url).json();
};

export const insertBlueshiftRequestDataEntry = async (companyId: number): Promise<number> => {
  const url = `v2/dashboard/${companyId}/insertBlueshiftRequestEntry`;

  return apiWithErrorRedirect.post(url).json();
};
