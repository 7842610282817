// API - UTILITIES
// =============================================================================
// General purpose utilities required by the generated output files.

import { ApiEndpointState, FSA } from "@bluecrew/web-react-core/src/types/permissive-redux";
import { Moment } from "moment";
import { produce } from "immer";
import { PartialRequestErrorModalData } from "../api/bluecrew/types";
import { logError } from "../sentry";

// REDUCER HELPERS
export const initialEndpointState: ApiEndpointState = Object.freeze({
  request: null,
  success: null,
  failure: null,
  timeout: null,
  mistake: null,
  isFetching: false,
  lastUpdate: null,
  lastResult: null,
});

export const handleRequest = <State extends { [name: string]: any }>(
  name: keyof State,
  state: State,
) =>
  produce(state, (draft: State) => {
    draft[name] = { ...initialEndpointState, ...draft[name] };
    draft[name].isFetching = true;
  });

export const handleSuccess = <State extends { [name: string]: any }>(
  name: keyof State,
  state: State,
  action: FSA<any, any>,
): State => {
  const { payload } = action;
  if (!payload) {
    console.error(
      `action.payload was unexpectedly empty in handleSuccess({ name: "${String(name)}", ...})`,
    );
  }

  return produce(state, (draft: State) => {
    draft[name] = { ...initialEndpointState, ...draft[name] };
    draft[name].success = payload || {};
    draft[name].isFetching = false;
    draft[name].lastUpdate = Date.now();
    draft[name].lastResult = "success";
  });
};

export const handleFailure = <State extends { [name: string]: any }>(
  name: keyof State,
  state: State,
  action: FSA<any, any>,
): State => {
  const { payload } = action;
  if (!payload) {
    logError({
      error: new Error(
        `action.payload was unexpectedly empty in handleFailure({ name: "${String(name)}", ...})`,
      ),
    });
  } else {
    if (!(payload instanceof Error)) {
      // The payload should always be an error object but it's hard to
      // verify this in the current architecture.
      console.error(`handleFailure:${String(name)} received non-Error payload`, payload);
    }
    logError({ error: payload, context: `handleFailure:${String(name)}` });
  }

  return produce(state, (draft: State) => {
    draft[name] = { ...initialEndpointState, ...draft[name] };
    draft[name].failure = payload || new Error("Unknown error");
    draft[name].isFetching = false;
    draft[name].lastUpdate = Date.now();
    draft[name].lastResult = "failure";
  });
};

export const getErrorModalDataFromPayload = (
  payload: any,
  successfullyCreatedShift: boolean,
  successfulDirectInvite: boolean,
  requestIncludedDirectInvite: boolean,
  requestIncludedWorkRequest: boolean,
): PartialRequestErrorModalData => {
  let formattedDate = "";
  let momentDate: Moment;

  // get date formatted
  if (payload?.data?.start_date_time) {
    formattedDate = payload.data.start_date_time.format("MMMM DD YYYY");
    momentDate = payload.data.start_date_time;
  } else {
    formattedDate = payload.data.startDate.format("MMMM DD YYYY");
    momentDate = payload.data.startDate;
  }

  // get number of shifts
  let shifts = 0;
  if (payload?.data?.workers_requested) {
    shifts = payload.data.workers_requested;
  } else if (payload?.data?.needed) {
    shifts = payload.data.needed;
  } else if (payload?.data?.workers_needed) {
    shifts = payload.data.workers_needed;
  }

  const errMsg = getMessageForErrorModal(
    successfulDirectInvite,
    requestIncludedWorkRequest,
    requestIncludedDirectInvite,
  );

  return {
    momentDate,
    formattedDate,
    shifts,
    invitee: errMsg,
    successfullyCreatedShift,
  };
};

const getMessageForErrorModal = (
  successfulDirectInvite: boolean,
  requestIncludedWorkRequest: boolean,
  requestIncludedDirectInvite: boolean,
) => {
  if (!requestIncludedDirectInvite) {
    // request didn't include a direct invite
    return "public shift";
  } else if (!requestIncludedWorkRequest && requestIncludedDirectInvite) {
    // only had direct invite and no work request
    return "all direct invitees";
  } else if (requestIncludedWorkRequest && successfulDirectInvite) {
    // direct invite succeeds, but work additional work request fails
    return "public shift";
  } else if (requestIncludedWorkRequest && !successfulDirectInvite) {
    // both direct invite and work request fail
    return "public shift and direct invitees";
  } else {
    return "all shifts"; // fallback
  }
};
