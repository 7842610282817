// Create Shift- VIEW
// =============================================================================
// Create a shfit
import React from "react";
import PropTypes from "prop-types";
import { Portal, Backdrop } from "@bluecrew/web-react-core";
import { StyleContainer, StyledButtonGroup, StyledOverlay } from "./ConfirmModal.StyledComponents";
import { ConfirmButton } from "./ConfirmButton";
import { CancelButton } from "./CancelButton";
import { ModalBody } from "./ModalBody";

type ConfirmationClickHandlerType = () => void;
type ToggleModalType = () => void;
export interface ConfirmModalProps {
  icon?: React.ReactElement<"svg">;
  show: boolean;
  toggleModal: ToggleModalType;
  handleConfirmationClick: ConfirmationClickHandlerType;
  headingText: string;
  paragraphText: string | React.ReactElement<any>;
  confirmationButtonText: string;
  confirmationButtonPalette?: string;
  noIcon?: boolean;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  icon,
  show,
  toggleModal,
  handleConfirmationClick,
  headingText,
  paragraphText,
  confirmationButtonText,
  confirmationButtonPalette,
  noIcon,
}) => (
  <>
    <Portal>
      <Backdrop fade visible={show} onClick={toggleModal} />
      <StyledOverlay fade visible={show}>
        <StyleContainer>
          <ModalBody
            noIcon={noIcon}
            icon={icon}
            headingText={headingText}
            paragraphText={paragraphText}
          />

          <StyledButtonGroup>
            <CancelButton onClick={toggleModal} />
            <ConfirmButton
              onClick={handleConfirmationClick}
              confirmationButtonPalette={confirmationButtonPalette}
            >
              {confirmationButtonText}
            </ConfirmButton>
          </StyledButtonGroup>
        </StyleContainer>
      </StyledOverlay>
    </Portal>
  </>
);

ConfirmModal.propTypes = {
  icon: PropTypes.element,
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleConfirmationClick: PropTypes.func.isRequired,
  headingText: PropTypes.string.isRequired,
  paragraphText: PropTypes.any.isRequired,
  confirmationButtonText: PropTypes.string.isRequired,
  confirmationButtonPalette: PropTypes.any,
  noIcon: PropTypes.bool,
};

export default ConfirmModal;
