import { format } from "date-fns";
import React from "react";
import { TimeView } from "./TimeView";
import { AttributesDisplay } from "./AttributesDisplay";
import { hasShiftSubmissionTimeEnded, TimestampAttributeName } from "../../../shiftUtils";
import { ShiftComponent, TimesheetsShiftInfo, ShiftTimestamp } from "../../../types/Shift.types";
import { PayrollHoursStatus, PayrollStatusReason } from "../../../types/Payroll.types";

type TimeDisplayProps = {
  timestamp: ShiftTimestamp;
  shiftInfo: TimesheetsShiftInfo;
  tsType: ShiftComponent;
  shiftKey: string;
  showMessaging?: boolean;
};

export const TimeDisplay = ({
  timestamp,
  shiftInfo,
  tsType,
  shiftKey,
  showMessaging,
}: TimeDisplayProps) => {
  const convertTZ = (date, timezoneString) => {
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
        timeZone: timezoneString,
      }),
    );
  };

  const { userShift, shiftThresholds, job } = shiftInfo;
  const { timezone } = job;
  const formattedTime = format(convertTZ(timestamp.time, timezone), "h:mm a");

  const formattedTimeInDispute = timestamp.isDisputedTimeMissing
    ? "Missing"
    : timestamp.timeInDispute && format(convertTZ(timestamp.timeInDispute, timezone), "h:mm a");

  const isCompleteShiftInDispute =
    userShift.status === PayrollHoursStatus.DISPUTED &&
    !!userShift.statusReason &&
    ![PayrollStatusReason.SUPPORT_UNADJUSTED, PayrollStatusReason.RECEIVED_BREAKS].includes(
      userShift.statusReason,
    );

  return (
    <>
      <TimeView
        formattedTime={formattedTime}
        isCompleteShiftInDispute={isCompleteShiftInDispute}
        formattedTimeInDispute={formattedTimeInDispute}
        missingTimeTooltipMessage={"This timestamp has not been submitted"}
        isTimestampSubmitted={timestamp.attributes.includes(TimestampAttributeName.CM_SUBMITTED)}
        isTimestampMissing={timestamp.attributes.includes(
          TimestampAttributeName.CM_MISSING_SUBMISSION,
        )}
        tooltipAnchor={`time-${shiftKey}-${tsType}`}
        hasShiftSubmissionTimeEnded={hasShiftSubmissionTimeEnded(shiftThresholds)}
      />
      <AttributesDisplay
        attributes={timestamp.attributes}
        timeType={tsType}
        shiftInfo={shiftInfo}
        tooltipAnchor={`attribute-${shiftKey}-${tsType}`}
        showMessaging={showMessaging}
      />
    </>
  );
};
