import React, { useCallback } from "react";
import { H4Heading, H2Heading, Button } from "@bluecrew/web-react-core";
import { FormProvider, useForm } from "react-hook-form";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { SettingsCard, ButtonWrapper, TertiaryButton, GeofenceInputRow } from "../styledComponents";
import { geofenceStrings, commonButtonStrings } from "../CompanySettingStringsEN";
import {
  CompanyInfo,
  DistanceUnit,
  GeofenceFieldMeta,
  GeofenceFieldType,
  GeofenceFieldUnit,
} from "../../../api/bluecrew/types";
import { StyledDivider } from "../../positions/SharedComponents";
import { GeofenceDefaults } from "../../../../shared/constants";
import { BlockWithBoldText } from "./BlockWithBoldText";
import { radiusToMeters, validate } from "./utils";
import { GeofenceInput } from "./GeofenceInput";
import { ParagraphWithBoldText } from "./ParagraphWithBoldText";
import { PositionLevelDescriptionWithLink } from "./PositionDescriptionWithLink";
import { AbilityContext } from "../../PermissionsContext";
import { usePendo, useUpdateCompanyDetailsMutation } from "../hooks";

const { title, labels, headers, subTitles, descriptions } = geofenceStrings;

export const onsiteWarnTestId = "onsiteWarnTestId";
export const onsiteBlockTestId = "onsiteBlockTestId";
export const offsiteWarnTestId = "offsiteWarnTestId";
export const offsiteBlockTestId = "offsiteBlockTestId";

// Field names
const ONSITE_WARN_FIELD = "onsiteWarn";
const ONSITE_WARN_UNIT = "onsiteWarnUnit";

const ONSITE_BLOCK_FIELD = "onsiteBlock";
const ONSITE_BLOCK_UNIT = "onsiteBlockUnit";

const OFFSITE_WARN_FIELD = "offsiteWarn";
const OFFSITE_WARN_UNIT = "offsiteWarnUnit";

const OFFSITE_BLOCK_FIELD = "offsiteBlock";
const OFFSITE_BLOCK_UNIT = "offsiteBlockUnit";

// Info about fields which is handy to have programatic access to
const fieldMeta: GeofenceFieldMeta = {
  [ONSITE_WARN_FIELD]: {
    type: GeofenceFieldType.Warn,
    unitFieldName: ONSITE_WARN_UNIT,
    fieldNames: [ONSITE_WARN_FIELD, ONSITE_BLOCK_FIELD],
  },
  [ONSITE_BLOCK_FIELD]: {
    type: GeofenceFieldType.Block,
    unitFieldName: ONSITE_BLOCK_UNIT,
    fieldNames: [ONSITE_WARN_FIELD, ONSITE_BLOCK_FIELD],
  },
  [OFFSITE_WARN_FIELD]: {
    type: GeofenceFieldType.Warn,
    unitFieldName: OFFSITE_WARN_UNIT,
    fieldNames: [OFFSITE_WARN_FIELD, OFFSITE_BLOCK_FIELD],
  },
  [OFFSITE_BLOCK_FIELD]: {
    type: GeofenceFieldType.Block,
    unitFieldName: OFFSITE_BLOCK_UNIT,
    fieldNames: [OFFSITE_WARN_FIELD, OFFSITE_BLOCK_FIELD],
  },
};

type GeofenceProps = {
  initialOnsiteWarnRadiusMeters: number;
  initialOnsiteBlockRadiusMeters: number;
  initialOffsiteWarnRadiusMeters: number;
  initialOffsiteBlockRadiusMeters: number;
};

export const Geofence = ({
  initialOnsiteWarnRadiusMeters,
  initialOnsiteBlockRadiusMeters,
  initialOffsiteWarnRadiusMeters,
  initialOffsiteBlockRadiusMeters,
}: GeofenceProps) => {
  const ability = React.useContext(AbilityContext);
  const canUpdateGeofencePolicy = ability.can("update", "geofence_policy");
  const trackWithPendo = usePendo();

  // Setup form
  const formMethodsAndState = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    formState: { isValid },
  } = formMethodsAndState;

  const updateCompanyDetailsMutation = useUpdateCompanyDetailsMutation();

  const updateGeofenceSettings = (
    newOnsiteWarnRadiusMeters: number,
    newOnsiteBlockRadiusMeters: number,
    newOffsiteWarnRadiusMeters: number,
    newOffsiteBlockRadiusMeters: number,
  ) => {
    const companyInfo: CompanyInfo = {
      min_offsite_radius: Math.min(newOnsiteWarnRadiusMeters, newOffsiteWarnRadiusMeters),
      min_onsite_warn_radius: newOnsiteWarnRadiusMeters,
      min_onsite_block_radius: newOnsiteBlockRadiusMeters,
      min_offsite_warn_radius: newOffsiteWarnRadiusMeters,
      min_offsite_block_radius: newOffsiteBlockRadiusMeters,
    };

    updateCompanyDetailsMutation.mutate(companyInfo, {
      onSuccess: () => {
        trackWithPendo("Geofence settings Value", { newValue: companyInfo });
      },
    });

    // Blur button after click so that hover color clears when button is un-hovered
    (document.activeElement as HTMLElement | undefined)?.blur();
  };

  const onSubmit = (data: {
    offsiteBlock: number;
    offsiteBlockUnit: GeofenceFieldUnit;
    offsiteWarn: number;
    offsiteWarnUnit: GeofenceFieldUnit;
    onsiteBlock: number;
    onsiteBlockUnit: GeofenceFieldUnit;
    onsiteWarn: string;
    onsiteWarnUnit: GeofenceFieldUnit;
  }) => {
    updateGeofenceSettings(
      radiusToMeters(data[ONSITE_WARN_FIELD], data[ONSITE_WARN_UNIT]),
      radiusToMeters(data[ONSITE_BLOCK_FIELD], data[ONSITE_BLOCK_UNIT]),
      radiusToMeters(data[OFFSITE_WARN_FIELD], data[OFFSITE_WARN_UNIT]),
      radiusToMeters(data[OFFSITE_BLOCK_FIELD], data[OFFSITE_BLOCK_UNIT]),
    );
  };

  const resetToBluecrewDefaults = useCallback(() => {
    clearErrors();
    const setFields = (
      warnField: string,
      warnValue: number,
      blockField: string,
      blockValue: number,
    ) => {
      setValue(warnField, warnValue);
      setValue(fieldMeta[warnField].unitFieldName, DistanceUnit.METERS);
      setValue(blockField, blockValue);
      setValue(fieldMeta[blockField].unitFieldName, DistanceUnit.KILOMETERS);
    };
    setFields(
      ONSITE_WARN_FIELD,
      GeofenceDefaults.onsiteWarnRadiusMeters,
      ONSITE_BLOCK_FIELD,
      GeofenceDefaults.onsiteBlockRadiusMeters / 1000,
    );
    setFields(
      OFFSITE_WARN_FIELD,
      GeofenceDefaults.offsiteWarnRadiusMeters,
      OFFSITE_BLOCK_FIELD,
      GeofenceDefaults.offsiteBlockRadiusMeters / 1000,
    );
  }, []);

  const validateField = useCallback((fieldName) => validate(fieldName, fieldMeta, getValues), []);

  const sharedInputProps = {
    disabled: !canUpdateGeofencePolicy,
    validateField,
  };

  return (
    <SettingsCard data-pendo-key="geofencingTimestampsSection">
      <FormProvider {...formMethodsAndState}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <H2Heading>{title}</H2Heading>
          <H4Heading>{subTitles.companyLevel}</H4Heading>
          <ParagraphWithBoldText inputText={descriptions.companyLevel} />
          <H4Heading>{subTitles.positionLevel}</H4Heading>
          <PositionLevelDescriptionWithLink />
          <Spacer $size="8px" $type={SpacerType.vertical} />

          <StyledDivider />

          <Spacer $size="8px" $type={SpacerType.vertical} />
          <H4Heading>{headers.onsitePolicy}</H4Heading>
          <BlockWithBoldText inputText={descriptions.onsitePolicy} />
          <Spacer $size="16px" $type={SpacerType.vertical} />
          <GeofenceInputRow>
            <GeofenceInput
              label={labels.warn}
              testId={onsiteWarnTestId}
              unitFieldName={ONSITE_WARN_UNIT}
              radiusFieldName={ONSITE_WARN_FIELD}
              defaultValue={initialOnsiteWarnRadiusMeters}
              {...sharedInputProps}
            />
            <GeofenceInput
              label={labels.block}
              testId={onsiteBlockTestId}
              unitFieldName={ONSITE_BLOCK_UNIT}
              radiusFieldName={ONSITE_BLOCK_FIELD}
              defaultValue={initialOnsiteBlockRadiusMeters}
              {...sharedInputProps}
            />
          </GeofenceInputRow>
          <Spacer $size="16px" $type={SpacerType.vertical} />
          <H4Heading>{headers.offsitePolicy}</H4Heading>
          <BlockWithBoldText inputText={descriptions.offsitePolicy} />
          <Spacer $size="16px" $type={SpacerType.vertical} />
          <GeofenceInputRow>
            <GeofenceInput
              label={labels.warn}
              testId={offsiteWarnTestId}
              unitFieldName={OFFSITE_WARN_UNIT}
              radiusFieldName={OFFSITE_WARN_FIELD}
              defaultValue={initialOffsiteWarnRadiusMeters}
              {...sharedInputProps}
            />
            <GeofenceInput
              label={labels.block}
              testId={offsiteBlockTestId}
              unitFieldName={OFFSITE_BLOCK_UNIT}
              radiusFieldName={OFFSITE_BLOCK_FIELD}
              defaultValue={initialOffsiteBlockRadiusMeters}
              {...sharedInputProps}
            />
          </GeofenceInputRow>
          <ButtonWrapper>
            <TertiaryButton
              disabled={!canUpdateGeofencePolicy}
              type="submit"
              onClick={resetToBluecrewDefaults}
            >
              {commonButtonStrings.resetToDefaults}
            </TertiaryButton>
            <Button disabled={!canUpdateGeofencePolicy || !isValid} palette="primary" type="submit">
              {labels.saveButton}
            </Button>
          </ButtonWrapper>
        </form>
      </FormProvider>
    </SettingsCard>
  );
};
