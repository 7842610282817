import React from "react";
import PropTypes from "prop-types";

import { H3Heading, Paragraph } from "@bluecrew/web-react-core";
import { WarningIcon } from "../../svg/WarningIcon";
import { ConfirmModalProps } from "./ConfirmModal";
import { StyledContent } from "./ConfirmModal.StyledComponents";

interface ModalBodyProps
  extends Pick<ConfirmModalProps, "icon" | "noIcon" | "headingText" | "paragraphText"> {
  children?: any;
}

export const ModalBody: React.FC<ModalBodyProps> = ({
  icon,
  noIcon,
  headingText,
  paragraphText,
  children,
}) => (
  <StyledContent>
    {noIcon ? null : icon || <WarningIcon />}
    {/* TODO: Update typography once style guide is complete */}
    <H3Heading>{headingText}</H3Heading>
    <Paragraph>{paragraphText}</Paragraph>
    {children}
  </StyledContent>
);

ModalBody.propTypes = {
  icon: PropTypes.element,
  noIcon: PropTypes.bool,
  headingText: PropTypes.string.isRequired,
  paragraphText: PropTypes.any.isRequired,
  children: PropTypes.any,
};
