import React from "react";
import { DashboardPositionBulkSingleShifts } from "../../../../api/bluecrew/types";
import { RequestText } from "./styledComponents";

export const DashboardScheduleRequestNameCell = ({
  rowData,
}: {
  rowData: DashboardPositionBulkSingleShifts;
}) => (
  <RequestText data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnNameRequestId">
    Request {rowData.blueshift_request_id}
  </RequestText>
);
