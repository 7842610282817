import styled from "styled-components";
import React from "react";
import { Calendar as PRCalendar, CalendarProps } from "primereact/calendar";
import { GetCrewMembersScheduleIcon } from "@bluecrew/blueprint-web";

const IconCalendarWrapper = (props: CalendarProps) => (
  <PRCalendar
    panelClassName={props.className}
    icon={props.icon || GetCrewMembersScheduleIcon}
    showIcon
    appendTo={props.appendTo || "self"}
    dateFormat={props.showTime ? "M dd, yy •" : "M dd, yy"}
    hourFormat={"12"}
    {...props}
  />
);

export const IconCalendar = styled(IconCalendarWrapper)`
  height: 36px; // same as icon

  :hover .p-button {
    background: ${({ theme }) => theme.colorTokens.white};
    border-color: ${({ theme }) => theme.colorTokens.blue850};
    border-radius: 8px;
    cursor: pointer;
  }

  .p-button {
    background: ${({ theme }) => theme.colorTokens.white};
    border-color: ${({ theme }) => theme.colorTokens.gray400};
    border-radius: 8px;
    height: 36px;
    width: 36px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .p-datepicker {
    height: fit-content;
  }

  .p-datepicker-group {
    background-color: rgba(255, 255, 255, 1);
  }

  // hide the input text component
  .p-inputtext {
    display: none;
    position: absolute;
  }
`;
