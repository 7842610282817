import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const CircleCheckIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const {
    width = 24,
    height = 24,
    color = theme.colors.positive400,
    stroke = theme.colors.mono100,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={color} />
      <path
        d="M4 8.17647L6.78261 11L12 5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
