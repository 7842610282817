import styled from "styled-components";
import { Button } from "../../components/Button";

export const Container = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  width: 100%;
  align-self: flex-start;
  background: ${({ theme }) => theme.colorTokens.white};
`;
export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.negative};
  font-size: ${({ theme }) => theme.typography.font200.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font200.fontFamily};
  font-family: ${({ theme }) => theme.typography.font200.fontFamily};
  line-height: ${({ theme }) => theme.typography.font200.fontFamily};
  margin: 0px;
`;

export const HoverStyledButton = styled(Button)`
  :enabled:hover {
    border-color: ${({ theme }) => theme.colorTokens.blue850};
    background: ${({ theme }) => theme.colorTokens.blue850};
    color: ${({ theme }) => theme.colorTokens.white};
  }
`;
