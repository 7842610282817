import React from "react";
import { StyledInput } from "./styledComponents";

type SendMessageContentProps = {
  message: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export const SendMessageContent = ({ message, onChange }: SendMessageContentProps) => (
  <div>
    <b>Message</b>
    <StyledInput value={message} onChange={onChange} cols={60} rows={message ? 5 : 3} />
  </div>
);
