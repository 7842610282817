import { UserCertification } from "../api/bluecrew/types";
import { Certifications } from "../containers/GetCrewMembers/types/propTypes/CertificationsField.type";

export const sortByAppearanceOrder = (a, b): number => a.appearance.order - b.appearance.order;

export const userCertToCertMapper = (userCertifications: UserCertification[]): Certifications =>
  userCertifications
    .map((requirement) => ({
      ...requirement,
      title: requirement.label,
      selected: requirement.appearance.preselected || requirement.appearance.is_locked,
      is_locked: requirement.appearance.is_locked,
      information: requirement.appearance.information,
    }))
    .sort(sortByAppearanceOrder);
