import styled from "styled-components";
import { Dialog } from "../../../../components/Dialog";
import { Dropdown } from "../../../../components/Dropdown";
import { InputNumber } from "../../../../components/InputNumber";
import { HoverStyledButton } from "../../styledComponents";

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px 10px;
`;

export const FooterButton = styled(HoverStyledButton)`
  background: ${({ theme }) => theme.colorTokens.blue400};
`;

export const CreateShiftScreenContainer = styled.div`
  display: block;
`;

export const CreateShiftScreenText = styled.div`
  font-family: ${({ theme }) => theme.typography.font350.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
  font-size: ${({ theme }) => theme.typography.font350.fontSize};
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Obsidian */
  color: ${({ theme }) => theme.colorTokens.gray800};

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  padding: 5px 5px;
`;

export const StyledDropdown = styled(Dropdown)`
  min-width: 100%;
`;

export const SupervisorSelectContainer = styled.div`
  padding-top: 24px;
`;

export const WageSelectFieldContainer = styled.div`
  padding-top: 24px;
`;

export const StyledWageInput = styled(InputNumber)`
  min-width: 100%;
`;

export const StyledAddShiftModal = styled(Dialog)`
  width: 630px;

  .p-dialog-footer {
    padding-right: 0;
  }
`;

export const WorkerSearchContainer = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

export const SelectedWorkerText = styled.div`
  font-family: ${({ theme }) => theme.typography.font200.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font200.fontWeight};
  font-size: ${({ theme }) => theme.typography.font200.fontSize};
  font-style: normal;

  color: black;
`;

export const WorkerListContainer = styled.div`
  width: 100%;
`;

export const WorkerListItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  cursor: pointer;
`;

export const SelectWorkersScreenContainer = styled.div`
  flex-direction: column;
`;

export const WorkerInfoContainer = styled.div`
  display: flex;
`;

export const WorkerDetailsContainer = styled.div`
  align-self: center;
  justify-content: space-around;
  padding-left: 8px;
`;

export const StyledWorkerName = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  font-style: normal;

  color: black;
`;

export const StyledWorkerStats = styled.div`
  font-family: ${({ theme }) => theme.typography.font100.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font100.fontWeight};
  font-size: ${({ theme }) => theme.typography.font100.fontSize};
  font-style: normal;

  padding-top: 0.4em;
  display: flex;

  color: black;
`;

export const WorkerStatContainer = styled.div`
  padding-right: 0.4em;
`;
