import { Amplify } from "aws-amplify";
import { COGNITO_CONFIG } from "../shared/aws-exports";
import { AuthStorage } from "./utility/customAmplifyStorage";

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      authenticationFlowType: "USER_PASSWORD_AUTH",
      region: COGNITO_CONFIG.REGION,
      userPoolId: COGNITO_CONFIG.USER_POOL_ID,
      userPoolWebClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
      storage: AuthStorage,
    },
  });
};
