export const MIN_PASSWORD_LENGTH = 8;
export const PasswordsMustMatchString = "Passwords must match";

export type PasswordReqs = {
  reqTest: (value: string) => boolean;
  reqText: string;
};

export const passwordReqs: PasswordReqs[] = [
  {
    reqTest: (password: string) => /^(.*[A-Z])/.test(password),
    reqText: "Contains at least one uppercase character",
  },
  {
    reqTest: (password: string) => /^(.*[a-z])/.test(password),
    reqText: "Contains at least one lowercase character",
  },
  {
    reqTest: (password: string) => /^(.*[0-9])/.test(password),
    reqText: "Contains at least one number",
  },
  {
    reqTest: (password: string) => /^(.*[!@#%&{}><;:|_\-$~`+=[\],.*()'])/.test(password),
    reqText: "Contains at least one special character",
  },
  {
    reqTest: (password: string) => password.length >= MIN_PASSWORD_LENGTH,
    reqText: `Be at least ${MIN_PASSWORD_LENGTH} characters long`,
  },
];
