import {
  ArrowRightIcon,
  getBorder,
  getBorderColor,
  getBorderWidth,
  themedStyled,
} from "@bluecrew/blueprint-web";
import React from "react";
import { blueprintTheme } from "@bluecrew/blueprint-web/src/theme/blueprintTheme";
import { typography } from "@bluecrew/blueprint-web/src/theme/typography";
import styled from "styled-components";
import { ScheduleTimeFieldProps } from "../types/propTypes/ScheduleTimeField.types";

export const ScheduleTimeField = ({
  disabled = false,
  errors,
  invalid,
  setTimeRange,
  value,
  $noMargin,
}: ScheduleTimeFieldProps) => (
  <div>
    <FieldWrapper $noMargin={$noMargin}>
      <div>
        <TimeFieldInput
          disabled={disabled}
          type="time"
          onChange={(e) => setTimeRange({ ...value, startDateTime: e.target.value })}
          value={value.startDateTime}
          $invalid={invalid}
          required
          data-testid="TimeField-StartTime"
        />
        <HelperText>Start time</HelperText>
      </div>
      <IconWrapper>
        <ArrowRightIcon width="16px" height="11px" color="#3246F0" />
      </IconWrapper>
      <div>
        <TimeFieldInput
          disabled={disabled}
          type="time"
          onChange={(e) => setTimeRange({ ...value, endDateTime: e.target.value })}
          value={value.endDateTime}
          $invalid={invalid}
          step="900"
          required
          data-testid="TimeField-EndTime"
        />
        <HelperText>End time</HelperText>
      </div>
    </FieldWrapper>
    <FieldWrapper $noMargin={$noMargin} style={{ flexDirection: "column" }}>
      {errors?.timeRange && <ErrorText>{errors.timeRange.message}</ErrorText>}
    </FieldWrapper>
  </div>
);

const FieldWrapper = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  align-items: baseline;
  margin-left: ${(props) => (props.$noMargin ? "" : blueprintTheme.sizing.scale800)};
`;

const TimeFieldInput = themedStyled<"input", { $invalid: boolean; disabled?: boolean }>(
  "input",
  ({ $invalid, disabled, $theme }) => ({
    width: "102px",
    outline: "none",
    boxSizing: "border-box",
    paddingTop: $theme.sizing.scale300,
    paddingLeft: $theme.sizing.scale500,
    paddingBottom: $theme.sizing.scale300,
    ...getBorder({
      ...$theme.borders.border100,
      borderColor: $theme.colors.primary400,
    }),
    ...getBorderColor($invalid ? "red" : $theme.colors.primary400),
    ...getBorderWidth($invalid ? "2px" : "1px"),
    borderRadius: $theme.borders.radius200,
    ...$theme.typography.font300,
    color: disabled ? "rgb(136, 137, 146)" : $theme.colors.obsidian,
    ":focus": {
      borderColor: $theme.colors.accent400,
      boxShadow: $theme.lighting.inputShadowActive,
    },
    ":focus:required:invalid": {
      borderColor: $theme.colors.accent400,
      boxShadow: $theme.lighting.inputShadowActive,
    },
    ":invalid": {
      outline: "none",
      boxShadow: "none",
    },
    "::-webkit-calendar-picker-indicator": {
      display: "none",
    },
    ...(disabled && { backgroundColor: "rgb(242, 243, 247)" }),
  }),
);

const HelperText = styled.p`
  color: ${blueprintTheme.colors.mono700};
  font-family: ${typography.font200.fontFamily};
  font-size: ${typography.font200.fontSize};
  font-weight: ${typography.font200.fontWeight};
  line-height: ${typography.font200.lineHeight};
  margin-top: ${blueprintTheme.sizing.scale200};
  margin-bottom: 0px;
`;

const IconWrapper = styled.span`
  margin-left: ${blueprintTheme.sizing.scale300};
  margin-right: ${blueprintTheme.sizing.scale300};
`;

// TODO:(BW-375) Re-use existing ErrorMessage componentry from ErrorMessage.tsx
const ErrorText = styled.p`
  color: ${blueprintTheme.colors.negative};
  font-family: ${typography.font200.fontFamily};
  font-size: ${typography.font200.fontSize};
  font-weight: ${typography.font200.fontWeight};
  line-height: ${typography.font200.lineHeight};
  margin-bottom: 0px;
`;
