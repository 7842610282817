import React from "react";
import { palette as p } from "styled-tools";
import styled from "styled-components";

const Styled = styled.div<{ size: number }>`
  @keyframes dotWindmill {
    0% {
      transform: rotateZ(0deg) translate3d(0, 0, 0);
    }

    100% {
      transform: rotateZ(720deg) translate3d(0, 0, 0);
    }
  }

  & .dot-windmill {
    position: relative;
    top: -10px;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: ${(props) => props.size / 2}px;
    background-color: ${p("brandBlue")};
    transform-origin: ${(props) => props.size / 2}px ${(props) => props.size / 2 + props.size}px;
    animation: dotWindmill 2s infinite linear;

    &:before,
    &:after {
      background-color: ${p("brandBlue")};
      border-radius: ${(props) => props.size / 2}px;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      position: absolute;
      top: ${(props) => props.size + props.size / 2}px;
      width: ${(props) => props.size}px;
      height: ${(props) => props.size}px;
    }

    &:before {
      left: -${(props) => (props.size / 2) * 1.732}px;
    }

    &: after {
      left: ${(props) => (props.size / 2) * 1.732}px;
    }
  }
`;

const Spinner = (props) => (
  <Styled {...props}>
    <div className="dot-windmill" data-testid="loading-spinner" />
  </Styled>
);

Spinner.defaultProps = {
  size: 16,
};

export default Spinner;
