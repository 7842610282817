import { Button, Modal } from "@bluecrew/blueprint-web";
import React from "react";
import { ModalFooterWrapper } from "../../ModalFooterWrapper";
import { ChooseCrewModalProps } from "../../types/propTypes/ChooseCrewModal.types";
import { WorkforcePanel } from "./WorkforcePanel";

export const ChooseCrewModal = ({
  crewMembers,
  crewGroups,
  onSelectionChange,
  onDoneClick,
  isOpen,
  onClose,
  selected,
  hideCrewGroups,
}: ChooseCrewModalProps) => (
  <Modal
    header="Workforce"
    body={
      <WorkforcePanel
        crewMembers={crewMembers}
        crewGroups={crewGroups}
        selectedCrew={selected}
        onSelectionChange={onSelectionChange}
        hideCrewGroups={hideCrewGroups}
      />
    }
    footer={
      <ModalFooterWrapper>
        <Button width="100%" onClick={onDoneClick}>
          Done
        </Button>
      </ModalFooterWrapper>
    }
    isOpen={isOpen}
    onClose={onClose}
  />
);
