import React from "react";
import PropTypes from "prop-types";
import { Tab } from "./Tabs";
import { Badge } from "./Badge";
import styled from "styled-components";

const StyledBadge = styled(Badge)`
  margin-left: 10px;
`;

export const TabWithBadge = (props) => {
  const { badgeCount, classes, tabId, tabs, tabText } = props;
  return (
    <Tab tab={tabId} {...tabs} className={classes}>
      <span className="tab-text">{tabText}</span>
      {/* @ts-ignore */}
      <StyledBadge count={badgeCount} active={tabId === tabs.getCurrentId()} />
    </Tab>
  );
};

TabWithBadge.propTypes = {
  badgeCount: PropTypes.number.isRequired,
  classes: PropTypes.string,
  tabId: PropTypes.string.isRequired,
  tabText: PropTypes.string.isRequired,
  // Reakit tabs object must be passed in from parent to track active tab states
  tabs: PropTypes.object.isRequired,
};

TabWithBadge.defaultProps = {
  classes: null,
};
