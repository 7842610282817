import React from "react";
import { Col } from "react-styled-flexboxgrid";
import { Button, Paragraph } from "@bluecrew/web-react-core";
import { OpenApplicationJobsButton, StyledHeaderRow } from "./styledComponents";

interface HeaderContentProps {
  handleViewScheduleClick: () => void;
  toggleOpenRequestToPlatformModal: () => void;
  scheduleName: string;
  positionName: string;
}

export const HeaderContent = ({
  handleViewScheduleClick,
  toggleOpenRequestToPlatformModal,
  scheduleName,
  positionName,
}: HeaderContentProps) => (
  <StyledHeaderRow between="xs" middle="xs">
    <Col>
      <div className="position-details">
        <span className="main-text">{scheduleName}</span>
        <span className="sub-text">{positionName}</span>
      </div>
    </Col>
    <Col>
      <Button palette="secondary" onClick={handleViewScheduleClick}>
        <Paragraph>View schedule details</Paragraph>
      </Button>
      <OpenApplicationJobsButton palette="secondary" onClick={toggleOpenRequestToPlatformModal}>
        <Paragraph>Open request to platform</Paragraph>
      </OpenApplicationJobsButton>
    </Col>
  </StyledHeaderRow>
);
