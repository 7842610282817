import React from "react";
import { Alert } from "@bluecrew/blueprint-web";
import { KIND } from "baseui/notification";
import { TimedContainer, TimedContainerProps } from "./TimedContainer";

type ToastProps = TimedContainerProps & {
  type?: KIND[keyof KIND];
};

export const Toast = ({ trigger, durationMS = 3000, type, children }: ToastProps) => (
  <TimedContainer trigger={trigger} durationMS={durationMS}>
    <Alert toast type={type}>
      {children}
    </Alert>
  </TimedContainer>
);
