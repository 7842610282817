import React, { ReactNode, useEffect, useState } from "react";

export type TimedContainerProps = {
  trigger: any;
  durationMS?: number;
  children?: ReactNode;
};

const ANIMATION_LENGTH_MS = 300;

const mountedStyle = {
  opacity: 1,
};

const unmountedStyle = {
  opacity: 0,
  transition: `opacity ${ANIMATION_LENGTH_MS}ms ease-in`,
};

export const TimedContainer = ({ trigger, durationMS = 3000, children }: TimedContainerProps) => {
  // Using 2 state variables so can trigger the fade out animation to play
  // then we can conditionally de-render the component

  // isMounted controls the animation and style of the container
  const [isMounted, setIsMounted] = useState(false);

  // containerVisible controlls if the component is conditionally rendered or not
  const [containerVisible, setContainerVisible] = useState(false);

  useEffect(() => {
    setContainerVisible(true);
    setIsMounted(true);
    setTimeout(() => {
      setIsMounted(false);
      // Let the fade out animation play on the component before we stop rendering it's container
      setTimeout(() => setContainerVisible(false), ANIMATION_LENGTH_MS);
    }, durationMS);
  }, [trigger]);

  return (
    <>
      {containerVisible && <div style={isMounted ? mountedStyle : unmountedStyle}>{children}</div>}
    </>
  );
};
