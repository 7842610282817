import { Button } from "@bluecrew/web-react-core";
import styled from "styled-components";

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${Button} {
    border: unset;
    background: unset;
    padding-right: unset;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }

  .item-text {
    padding-top: 0.75rem;
    padding-left: 0.25rem;
  }

  .label {
    font-weight: bold;
    font-size: medium;
  }
`;
