import React, { useEffect, useState } from "react";
import { Can } from "../PermissionsContext";
import { getDashboardCompanyApplicationsToReviewData } from "../../api/bluecrew/dashboard";
import { StyledBanner } from "./SharedComponents";

type DashboardBannerProps = {
  companyId: number;
};

const ApplicationsToReviewBanner = ({ companyId }: DashboardBannerProps) => {
  const [applicationsToReviewData, updateApplicationsToReviewData] = useState({
    position_count: 0,
    schedule_count: 0,
    review_count: 0,
  });

  const getApplicationsToReviewData = async () => {
    const data = await getDashboardCompanyApplicationsToReviewData(companyId);
    updateApplicationsToReviewData(data);
  };

  useEffect(() => {
    getApplicationsToReviewData();
  }, []);

  return (
    <Can I="update" a="job">
      {applicationsToReviewData.position_count > 0 && (
        <StyledBanner
          icon={`${import.meta.env.VITE_ASSET_BASE_URL}/icons/alarm-bell-ring.svg`}
          message={`Action Required: For ${applicationsToReviewData.position_count} positions, you have ${applicationsToReviewData.review_count} CMs that have applied to ${applicationsToReviewData.schedule_count} schedules. Please review the applicants under each schedule.`}
        />
      )}
    </Can>
  );
};

export default ApplicationsToReviewBanner;
