import styled from "styled-components";
import { Dropdown as PRDropdown, DropdownProps } from "primereact/dropdown";
import React from "react";
import { ChevronDownIcon } from "@bluecrew/blueprint-web";
import { colorTokens } from "@bluecrew/blueprint-web/src/theme/colorTokens";

const DropdownWrapper = (props: DropdownProps) => (
  <PRDropdown className={props.className} dropdownIcon={ChevronDownIcon} {...props} />
);

export const Dropdown = styled(DropdownWrapper)`
  :not(.p-disabled):hover {
    border-color: ${colorTokens.blue850};
  }
  :not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: ${colorTokens.blue100};
  }
`;
