const { construct } = require("ramda");

class NotAllowedError extends Error {
  constructor(message) {
    super(message);
    this.statusCode = 405;
    this.name = "NotAllowedError";
  }
}

module.exports = (msg) => construct(NotAllowedError)(msg);
