import React from "react";

import { Button } from "@bluecrew/web-react-core";
import { ConfirmModalProps } from "./ConfirmModal";

type ClickHandlerType = () => void;
interface ConfirmButtonProps extends Pick<ConfirmModalProps, "confirmationButtonPalette"> {
  onClick: ClickHandlerType;
  disabled?: boolean;
  type?: "submit" | "reset" | "button" | undefined;
  children: any;
}

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  onClick,
  confirmationButtonPalette,
  disabled,
  type,
  children,
}) => (
  <Button
    data-testid="ConfirmModal-confirm"
    onClick={onClick}
    palette={confirmationButtonPalette == null ? "danger" : confirmationButtonPalette}
    disabled={disabled}
    type={type}
  >
    {children}
  </Button>
);
