import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./init";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { withBlueCrewTheme } from "@bluecrew/blueprint-web";
import { QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { configureStore } from "./redux";
import { RoutesComponent } from "./routes";
import ErrorBoundary from "./ErrorBoundary";
import { queryClient } from "./queryClient";
import { Main } from "./containers/Main";
import { initializeSentry } from "./sentry";
import { initAmplify } from "./amplify";
import { SPLIT_CONFIG } from "./splitConfig";

// Setup sentry as early as possible, to catch as many errors as possible
initializeSentry();

const StyledMain = withBlueCrewTheme(Main);

// Configure Amplify in index file or root file
initAmplify();

// REDUX
configureStore()
  .then((store) => {
    // RENDER WEBAPP TO ROOT NODE
    render(
      // This error boundary is the top most catch-all for any problems in the app
      <ErrorBoundary>
        <Authenticator.Provider>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <BrowserRouter>
                <SplitFactoryProvider config={SPLIT_CONFIG}>
                  <StyledMain>
                    <RoutesComponent />
                  </StyledMain>
                </SplitFactoryProvider>
              </BrowserRouter>
            </Provider>
          </QueryClientProvider>
        </Authenticator.Provider>
      </ErrorBoundary>,
      document.getElementById("REACT_ROOT"),
    );
  })
  .then(() => {
    const hideFOUC = document.getElementById("HIDE_FOUC");
    if (hideFOUC) {
      hideFOUC.remove();
    }
  });
