import React from "react";
import styled from "styled-components";
import { CreateCompanyAccountIcon } from "../../svg/CreateCompanyAccountIcon";
import { CreateCrewMemberAccountIcon } from "../../svg/CreateCrewMemberAccountIcon";

export const UltramarineLink = styled.span`
  color: ${({ theme }) => theme.colors.ultramarine};
  font-family: ${({ theme }) => theme.typography.font250.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
  font-size: ${({ theme }) => theme.typography.font250.fontSize};
  line-height: ${({ theme }) => theme.typography.font250.lineHeight};
  cursor: pointer;
`;

const handleCreateCompanyAccountRedirect = () => {
  window.location.href = "https://www.bluecrewjobs.com/contact";
};

const handleCreateWorkerAccountRedirect = () => {
  window.location.href = `${import.meta.env.VITE_CM7_URL}/account/register`;
};

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CreateWorkerAccountLink = () => (
  <ActionContainer onClick={handleCreateWorkerAccountRedirect}>
    <CreateCrewMemberAccountIcon />
    <UltramarineLink>Create Worker Account</UltramarineLink>
  </ActionContainer>
);

export const CreateCompanyAccountLink = () => (
  <ActionContainer onClick={handleCreateCompanyAccountRedirect}>
    <CreateCompanyAccountIcon />
    <UltramarineLink>Create Company Account</UltramarineLink>
  </ActionContainer>
);
