import React, { FC } from "react";
import { AvatarContainer, StyledAvatar, StyledInitials } from "./styledComponents";
import { BULK_ASSIGN_USER_DISPLAY_LIMIT } from "../constants";
import { InternalUser } from "../ManageUsersTable/InternalUser";

// OverlappingAvatar is a component that displays multiple users 'avatars' in a single container.
// An 'avatar' is either a user's profile picture or their initials in the absence of one - in a
// circular container. The avatars overlap and are positioned absolutely within a parent container.
// Each avatar, depending on its index, is stacked on top of the previous (via z-index) and shifted to
// the right by a certain amount. The parent container is a flex container that displays the avatars.
// An example of this can be seen in our company's Figma mockups for the Manage Users page.

interface AvatarProps {
  size?: string;
  imgSrc?: string;
  fullName: string;
  index: number;
}

const OverlappingAvatar: FC<AvatarProps> = ({ size = "md", imgSrc, fullName, index }) => {
  return (
    <AvatarContainer>
      {imgSrc ? (
        <StyledAvatar size={size} src={imgSrc} title={fullName} index={index} />
      ) : (
        <StyledInitials size={size} fullName={fullName} index={index}>
          <span>
            {fullName
              .split(" ")
              .map((part) => part[0])
              .join("")}
          </span>
        </StyledInitials>
      )}
    </AvatarContainer>
  );
};

type OverlappingAvatarProps = {
  selectedUsers: InternalUser[];
};

export const UserAvatars = ({ selectedUsers }: OverlappingAvatarProps) => {
  return (
    <>
      {selectedUsers
        .slice(0, Math.min(selectedUsers.length, BULK_ASSIGN_USER_DISPLAY_LIMIT))
        .map((user, index) => (
          <OverlappingAvatar
            key={user.userId}
            imgSrc={user.userPfp ?? undefined}
            fullName={user.name}
            index={index}
            size="lg"
          />
        ))}
    </>
  );
};
