// TODO Update this component to a function component in its own directory broken into multiple files per https://bluecrewjobs.atlassian.net/wiki/spaces/WS/pages/1676967961/React+-+Best+practices
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-styled-flexboxgrid";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { useNavigate, useParams } from "react-router-dom";
import scheduleActions, {
  fetchSchedule as fetchScheduleAction,
} from "../../redux/actions/schedule";
import {
  getRoster,
  getSchedule,
  getIsFetching,
  getOpenApplicationJobsInProgress,
} from "../../redux/selectors/schedule";
import { Can } from "../PermissionsContext";
import {
  ColumnOneContainer,
  ColumnTwoContainer,
  StyledContentRow,
  StyledGrid,
} from "./styledComponents";
import { HeaderContent } from "./HeaderContent";
import ColumnOneContent from "./ColumnOneContent";
import ColumnTwoContent from "./ColumnTwoContent";
import OpenRequestToPlatformModal from "./OpenRequestToPlatformModal";

const mapStateToProps = (state) => ({
  roster: getRoster(state),
  schedule: getSchedule(state),
  isFetching: getIsFetching(state),
  openApplicationJobsInProgress: getOpenApplicationJobsInProgress(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSchedule: (scheduleId) => dispatch(fetchScheduleAction(scheduleId)),
  openApplicationJobsToPlatform: ({ scheduleId }) =>
    dispatch(
      scheduleActions.openApplicationJobsToPlatform.request({
        scheduleId,
      }),
    ),
});

type ApplicationsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Applications = ({
  openApplicationJobsToPlatform,
  fetchSchedule,
  isFetching,
  schedule,
}: ApplicationsProps) => {
  const [showOpenRequestToPlatformModal, setShowOpenRequestToPlatformModal] = useState(false);
  const navigate = useNavigate();
  const routeParams = useParams();
  const scheduleId = routeParams.scheduleId!; // scheduleId  is always defined based on how this route is defined
  const {
    work_request_name: scheduleName,
    // @ts-ignore TODO: BW-1482 Fix this error
    position_name: positionName,
    timezone,
    request,
  } = schedule;
  const isLoading = isFetching && !request;

  const handleViewScheduleClick = () => {
    navigate(`/schedules/${scheduleId}/`);
  };

  const toggleOpenRequestToPlatformModal = () => {
    setShowOpenRequestToPlatformModal((prevState: boolean) => !prevState);
  };

  const handleOpenRequestToPlatform = () => {
    openApplicationJobsToPlatform({ scheduleId });
    toggleOpenRequestToPlatformModal();
  };

  useEffect(() => {
    // TODO: add logic to only fetch data if we don't currently have this data in the store
    fetchSchedule(scheduleId);
  }, []);

  return (
    <StyledGrid>
      {/*
        // @ts-ignore  */}
      <OpenRequestToPlatformModal
        handleOpenRequestToPlatform={handleOpenRequestToPlatform}
        showOpenRequestToPlatformModal={showOpenRequestToPlatformModal}
        toggleOpenRequestToPlatformModal={toggleOpenRequestToPlatformModal}
        scheduleName={scheduleName}
      />
      <Can I="update" a="job">
        {isLoading ? (
          <SpinnerWithOverlay />
        ) : (
          <Row>
            <Col xs={12}>
              {/* Position Details Header */}
              <HeaderContent
                handleViewScheduleClick={handleViewScheduleClick}
                toggleOpenRequestToPlatformModal={toggleOpenRequestToPlatformModal}
                scheduleName={scheduleName}
                positionName={positionName}
              />

              {/* Two-column content layout */}
              <StyledContentRow>
                <Col xs={12}>
                  <Row>
                    {/* Column 1 */}
                    <ColumnOneContainer xs={12} sm={12} md={6} lg={7}>
                      {/*
                      // @ts-ignore  */}
                      <ColumnOneContent scheduleId={scheduleId} timezone={timezone} />
                    </ColumnOneContainer>

                    {/* Column 2 */}
                    <ColumnTwoContainer xs={12} sm={12} md={6} lg={5}>
                      {/*
                      // @ts-ignore  */}
                      <ColumnTwoContent timezone={timezone} request={request} />
                    </ColumnTwoContainer>
                  </Row>
                </Col>
              </StyledContentRow>
            </Col>
          </Row>
        )}
      </Can>
    </StyledGrid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
