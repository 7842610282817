import React from "react";
import {Column, FilterType} from "react-table";
import { Link } from "react-router-dom";
import { FormattedPayrollReportDataType } from "../utils";
import { AccountManagerCell } from "./AccountManagerCell";

type PayrollReportsRowDataType = { rowData: FormattedPayrollReportDataType };

// TODO: Version Bump Table to V8 so we can use the native case-insensitive search functions rather than this crap
const payrollReportsTableNoTotalsCompanyFilter: FilterType<FormattedPayrollReportDataType> = (rows, _, filterValue) => filterValue?.length > 0
  ? rows.filter(row => row.original.companyName.toLowerCase().includes(filterValue.toString().toLowerCase()))
  : rows.filter(row => row.original.totalThisWeek > 0)

export const TableColumns: Column<FormattedPayrollReportDataType>[] = [
  {
    id: "company",
    Header: "Company",
    accessor: "companyName",
    className: "company-name",
    Cell: ({ rowData }: PayrollReportsRowDataType) => <Link to={"#"}>{rowData.companyName}</Link>,
    filter: payrollReportsTableNoTotalsCompanyFilter
  },
  {
    id: "pendingReview",
    Header: "Pending Review",
    accessor: "pendingReview",
    className: "pending-review",
  },
  {
    id: "overdue",
    Header: "Overdue",
    accessor: "overdue",
    className: "overdue",
  },
  {
    id: "disputed",
    Header: "Disputed",
    accessor: "disputed",
    className: "disputed",
  },
  {
    id: "accountManagerName",
    Header: "Account manager",
    accessor: "accountManagerName",
    className: "account-manager-name",
    Cell: ({ rowData }: PayrollReportsRowDataType) =>
      rowData.accountManagerName && (
        <AccountManagerCell accountManagerName={rowData.accountManagerName} />
      ),
  },
];
