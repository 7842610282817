import styled from "styled-components";
import { UserAvatar } from "@bluecrew/web-react-core";

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  thead {
    background-color: ${({ theme }) => theme.colorTokens.white};
    position: sticky;
    align-self: flex-start;
    top: 152px;
    box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colorTokens.gray200};
    height: 20px;
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    font-style: normal;
    color: ${({ theme }) => theme.colorTokens.gray800};
    text-align: left;
  }
  tbody {
    td {
      font-family: ${({ theme }) => theme.typography.font300.fontFamily};
      font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
      font-size: ${({ theme }) => theme.typography.font300.fontSize};
      line-height: ${({ theme }) => theme.typography.font300.lineHeight};
      font-style: normal;
      text-align: left;
      color: ${({ theme }) => theme.colorTokens.gray800};
    }
    tr {
      height: 80px;
      border-top: 1px solid ${({ theme }) => theme.colorTokens.gray200};
      border-bottom: 1px solid ${({ theme }) => theme.colorTokens.gray200};
    }
  }
  td.company-name {
    text-align: left;
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    font-size: ${({ theme }) => theme.typography.font350.fontSize};
    line-height: ${({ theme }) => theme.typography.font350.lineHeight};
    font-style: normal;
    color: ${({ theme }) => theme.colorTokens.blue400};
  }
  a {
    text-decoration: none;
  }
  .account-manager-name {
    padding-left: 96px;
  }
  th.account-manager-name {
    padding-left: 0px;
  }
`;

export const AccountManagerNameText = styled.span`
  margin-left: 8px;
`;

export const StyledUserAvatar = styled(UserAvatar)`
  background: ${({ theme }) => theme.colorTokens.aqua100};
  > span {
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    font-size: 16.6667px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.colorTokens.gray800} !important;
  }
`;

export const RowAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexRow = styled.div<{ className?: string }>`
  display: flex;
  flex-direction: row;
  ${({ className }) =>
    className === "needs-action" || className === "total-this-week"
      ? "justify-content: right"
      : "justify-content: left"};
`;

export const TableRow = styled.tr`
  .company-name {
    cursor: pointer;
  }
`;
