import React from "react";

import messages from "../../../shared/messages";
import { FormContent, initialValues, validationSchema } from "./FormContent";
import { ConfirmModalWithForm } from "../ConfirmModalWithForm";
import { ReasonCode } from "../../api/bluecrew/types";

export type CancelScheduleType = (arg0?: ReasonCode, arg1?: string) => void;
type ToggleType = () => void;
export interface CancelScheduleModalProps {
  cancelSchedule: CancelScheduleType;
  showModal: boolean;
  toggle: ToggleType;
  workerShifts?: number;
  forIndividualWorkRequest?: boolean;
}

export const CancelScheduleModal = (props: CancelScheduleModalProps) => {
  const cancelCount = props.workerShifts === undefined ? "..." : props.workerShifts;
  let thingBeingCancelled = props.forIndividualWorkRequest ? "job" : "shift";
  if (props.workerShifts !== 1) {
    thingBeingCancelled += "s";
  }
  return (
    <ConfirmModalWithForm
      handleConfirmationClick={props.cancelSchedule}
      show={props.showModal}
      toggleModal={props.toggle}
      headingText={
        props.forIndividualWorkRequest
          ? messages.DELETE_WORK_REQUEST.confirm.heading
          : messages.DELETE_SCHEDULE.confirm.heading
      }
      initialValues={initialValues}
      FormContent={FormContent}
      validationSchema={validationSchema}
      paragraphText={
        props.forIndividualWorkRequest
          ? messages.DELETE_WORK_REQUEST.confirm.body
          : messages.DELETE_SCHEDULE.confirm.body
      } // TODO: we should fix the jobs/shifts thing at some point so that it's consistent
      confirmationButtonText={`Yes, cancel ${cancelCount} ${thingBeingCancelled}`}
    />
  );
};
