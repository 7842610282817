import React from "react";
import { Controller } from "react-hook-form";
import { BaseLoginFieldProps } from "./types";
import { FieldWrapperWithError } from "./FieldWrapperWithError";
import { StyledPassword } from "../styledComponents";

interface PasswordFieldProps extends BaseLoginFieldProps {
  dataTestId?: string;
}

export const PasswordField = (props: PasswordFieldProps) => {
  const { fieldName, control, rules, onChange, dataTestId } = props;
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={(field) => (
        <FieldWrapperWithError {...props}>
          <StyledPassword
            toggleMask
            id={field.name}
            {...field}
            inputRef={field.ref}
            feedback={false}
            autoComplete="off"
            keyfilter={/[^\s]/} // Block spaces
            showIcon={(options) => (
              <img
                src="/public/images/icons/Login/show-password-eye.svg"
                onClick={options.iconProps.onClick}
              />
            )}
            hideIcon={(options) => (
              <img
                src="/public/images/icons/Login/hide-password-eye.svg"
                onClick={options.iconProps.onClick}
              />
            )}
            onChange={(event) => {
              if (onChange) onChange(event);
              field.onChange(event.target.value);
            }}
            pt={{ input: { "data-testid": dataTestId ?? field.name } }}
          />
        </FieldWrapperWithError>
      )}
    />
  );
};
