import { Dispatch } from "redux";
import { createActions } from "redux-actions";
import { apiWithErrorRedirect } from "../../api/bluecrew/constants/index";
import confirmationModalActions from "./confirmationModal";
import {
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
} from "../constants";

import * as scheduleApi from "../../api/bluecrew/schedule";
import positionActions from "./position";
import { SimpleModalTypes } from "../../components/SimpleModal";
import { Modals } from "../../../shared/constants";
import {
  ScheduleExceptionsResponse,
  OpenApplicationJobsToPlatformRequest,
  ReasonCode,
} from "../../api/bluecrew/types";

export type CancelWorkRequestPayload = {
  currentWorkRequests?: number;
  jobIds: Array<string>;
  scheduleId?: number | typeof undefined;
  forIndividualWorkRequest?: boolean;
  reasonCode?: ReasonCode;
  reasonText?: string;
};

export type OpenApplicationJobsToPlatformAction = {
  type: string;
  payload: OpenApplicationJobsToPlatformRequest;
};

export type ReadSchedExcReqPayload = {
  scheduleId: number;
};

export type ReadSchedExcReqAction = {
  type: string;
  payload: ReadSchedExcReqPayload;
};

export default createActions({
  UPDATE_SCHEDULE: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  OPEN_APPLICATION_JOBS_TO_PLATFORM: {
    REQUEST: (req: OpenApplicationJobsToPlatformRequest) => ({ ...req }),
    SUCCESS: (res: any) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  READ_SCHEDULE_WORKERS: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  CREATE_SCHEDULE_EXCEPTION: {
    REQUEST: (req: any) => ({ ...req }),
    SUCCESS: (res: any) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
  READ_SCHEDULE_EXCEPTIONS: {
    REQUEST: (req: ReadSchedExcReqPayload) => ({ ...req }),
    SUCCESS: (res: ScheduleExceptionsResponse) => res,
    FAILURE: [(error: Error) => ({ error }), (error: Error) => ({ type: typeof error })],
  },
}) as any;

const fetchScheduleRequest = () => ({
  type: FETCH_SCHEDULE_REQUEST,
});

const fetchScheduleSuccess = (data) => ({
  type: FETCH_SCHEDULE_SUCCESS,
  payload: data,
});

const fetchScheduleFailure = (data) => ({
  type: FETCH_SCHEDULE_FAILURE,
  payload: data,
});

const cancelScheduleRequest = () => ({
  type: "CANCEL_SCHEDULE_REQUEST",
});

const cancelScheduleSuccess = (res: any) => ({
  type: "CANCEL_SCHEDULE_SUCCESS",
  payload: res,
});

const cancelScheduleFailure = (res: any) => ({
  type: "CANCEL_SCHEDULE_FAILURE",
  payload: res,
});

export const fetchSchedule =
  (scheduleId: number | string) =>
  (dispatch: Dispatch<any>): any => {
    dispatch(fetchScheduleRequest());
    return apiWithErrorRedirect
      .get(`v2/schedules/${scheduleId}`)
      .then((res) => res.json())
      .then((body) => dispatch(fetchScheduleSuccess(body)))
      .catch((err) => dispatch(fetchScheduleFailure(err)));
  };

// EXAMPLE OF REDUX-THUNK IMPLEMENTATION
export const cancelSchedule =
  (params: CancelWorkRequestPayload) =>
  // @ts-ignore $FlowFixUnknownDefinition
  (dispatch: Dispatch<any>): any => {
    dispatch(cancelScheduleRequest());
    return Promise.all(
      params.jobIds.map((jobId) =>
        scheduleApi.cancelSchedule({
          jobId,
          reasonCode: params.reasonCode,
          reasonText: params.reasonText,
        }),
      ),
    )
      .then((response) => {
        const onLastWorkRequest = params.currentWorkRequests === 1;

        let redirectPath = "/positions";

        if (params.scheduleId) {
          if (!onLastWorkRequest) {
            // If we didn't delete the last work request,
            // stay on schedule detail page, else redirect away from it
            redirectPath = `/schedules/${params.scheduleId}`;

            // Re-fetch the schedule after deleting the requests.
            dispatch(fetchSchedule(params.scheduleId));
          }
          // This needs to redirect immediately so that modal is open on the correct page
          window.location.href = redirectPath;
        }

        dispatch(cancelScheduleSuccess(response));
        dispatch(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.SUCCESS,
            contentType: params.forIndividualWorkRequest
              ? Modals.DELETE_WORK_REQUEST
              : Modals.DELETE_SCHEDULE,
            redirectPath,
          }),
        );

        dispatch(positionActions.positions.request());
      })
      .catch((err) => {
        dispatch(cancelScheduleFailure(err));
        dispatch(
          confirmationModalActions.openModal({
            responseType: SimpleModalTypes.FAILURE,
            contentType: params.forIndividualWorkRequest
              ? Modals.DELETE_WORK_REQUEST
              : Modals.DELETE_SCHEDULE,
            redirectPath: null,
          }),
        );
      });
  };

// EXAMPLE OF REDUX-THUNK IMPLEMENTATION
export const cancelRequests =
  (params: CancelWorkRequestPayload) =>
  // @ts-ignore $FlowFixUnknownDefinition
  (dispatch: Dispatch<any>): any => {
    dispatch(cancelScheduleRequest());
    return Promise.all(
      params.jobIds.map((jobId) =>
        scheduleApi.cancelSchedule({
          jobId,
          reasonCode: params.reasonCode,
          reasonText: params.reasonText,
        }),
      ),
    ).catch((err) => {
      dispatch(cancelScheduleFailure(err));
      dispatch(
        confirmationModalActions.openModal({
          responseType: SimpleModalTypes.FAILURE,
          contentType: params.forIndividualWorkRequest
            ? Modals.DELETE_WORK_REQUEST
            : Modals.DELETE_SCHEDULE,
          redirectPath: null,
        }),
      );
    });
  };
