import React from "react";
import {
  BreakDurationInput,
  ShiftAdjustBodyText,
  ShiftAdjustTimeEditingContainer,
} from "./styledComponents";

type ShiftAdjustBreakDurationProps = {
  label: string;
  setBreakDuration: (d?: number) => void;
  breakDuration?: number;
};

export const ShiftAdjustBreakDuration = ({
  label,
  breakDuration,
  setBreakDuration,
}: ShiftAdjustBreakDurationProps) => {
  return (
    <ShiftAdjustTimeEditingContainer>
      <ShiftAdjustBodyText>{label}</ShiftAdjustBodyText>
      <BreakDurationInput
        value={breakDuration}
        min={0}
        onChange={(d) => d.value !== null && setBreakDuration(d.value)} // onChange returns a Date | null. Check to ensure nulls do not make through into the state
        suffix={" min"}
        maxFractionDigits={0}
      />
    </ShiftAdjustTimeEditingContainer>
  );
};
