// SCHEDULE - API
// =============================================================================
import {
  CancelScheduleRequest,
  ReadScheduleWorkersRequest,
  CreateScheduleExceptionRequest,
  UpdateScheduleRequest,
  OpenApplicationJobsToPlatformRequest,
  Schedule,
} from "./types";
import { ReadSchedExcReqPayload } from "../../redux/actions/schedule";
import { api, apiV1, apiWithErrorRedirect } from "./constants/index";

export const cancelSchedule = ({ jobId, reasonCode, reasonText }: CancelScheduleRequest) =>
  apiV1
    .post("job/cancel", {
      json: { jobId, reasonCode, reasonText },
    })
    .text();

export const readScheduleWorkers = ({ scheduleId }: ReadScheduleWorkersRequest) =>
  api.get(`v2/schedules/${scheduleId}/workers`).json();

export const readScheduleExceptions = ({ scheduleId }: ReadSchedExcReqPayload) =>
  api.get(`v2/schedules/${scheduleId}/schedule_exceptions`).json();

export const createScheduleException = ({ scheduleId, payload }: CreateScheduleExceptionRequest) =>
  api
    .post(`v2/schedules/${scheduleId}/schedule_exceptions`, {
      json: payload,
    })
    .json();

export const updateSchedule = ({ scheduleId, payload }: UpdateScheduleRequest) =>
  api
    .put(`v2/schedules/${scheduleId}/`, {
      json: payload,
    })
    .json();

export const openApplicationJobsToPlatform = ({
  scheduleId,
}: OpenApplicationJobsToPlatformRequest) =>
  api.post(`v2/schedules/${scheduleId}/open_application_jobs`);

type FetchScheduleRequestType = {
  scheduleId: number;
};

export const fetchSchedule = ({
  scheduleId,
}: FetchScheduleRequestType): Promise<{ schedule: Schedule }> =>
  apiWithErrorRedirect.get(`v2/schedules/${scheduleId}`).json();
