import { ThemePrimitives } from 'baseui/theme';
import { colorTokens } from './colorTokens';

export const primitives: ThemePrimitives = {
  primaryFontFamily: 'Inter, sans-serif',

  // Primary Palette
  primaryA: colorTokens.black,
  primaryB: colorTokens.white,
  primary: colorTokens.black,
  primary50: colorTokens.gray50,
  primary100: colorTokens.gray100,
  primary200: colorTokens.gray200,
  primary300: colorTokens.gray300,
  primary400: colorTokens.gray400,
  primary500: colorTokens.gray500,
  primary600: colorTokens.gray600,
  primary700: colorTokens.gray700,

  // Accent Palette
  accent: colorTokens.blue400,
  accent50: colorTokens.blue100,
  accent100: colorTokens.blue100,
  accent200: colorTokens.blue200,
  accent300: colorTokens.blue300,
  accent400: colorTokens.blue400,
  accent500: colorTokens.blue500,
  accent600: colorTokens.blue600,
  accent700: colorTokens.blue700,

  // Negative Palette
  negative: colorTokens.red400,
  negative50: colorTokens.red100,
  negative100: colorTokens.red100,
  negative200: colorTokens.red200,
  negative300: colorTokens.red300,
  negative400: colorTokens.red400,
  negative500: colorTokens.red500,
  negative600: colorTokens.red600,
  negative700: colorTokens.red700,

  // Warning Palette
  warning: colorTokens.orange400,
  warning50: colorTokens.orange100,
  warning100: colorTokens.orange100,
  warning200: colorTokens.orange200,
  warning300: colorTokens.orange300,
  warning400: colorTokens.orange400,
  warning500: colorTokens.orange500,
  warning600: colorTokens.orange600,
  warning700: colorTokens.orange700,

  // Positive Palette
  positive: colorTokens.green400,
  positive50: colorTokens.green100,
  positive100: colorTokens.green100,
  positive200: colorTokens.green200,
  positive300: colorTokens.green300,
  positive400: colorTokens.green400,
  positive500: colorTokens.green500,
  positive600: colorTokens.green600,
  positive700: colorTokens.green700,

  // Monochrome Palette
  mono100: colorTokens.white,
  mono200: colorTokens.gray50,
  mono300: colorTokens.gray100,
  mono400: colorTokens.gray200,
  mono500: colorTokens.gray300,
  mono600: colorTokens.gray400,
  mono700: colorTokens.gray500,
  mono800: colorTokens.gray600,
  mono900: colorTokens.gray700,
  mono1000: colorTokens.black,

  // Rating Palette,
  rating200: colorTokens.yellow200,
  rating400: colorTokens.yellow400,
};
