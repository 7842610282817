import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { palette as p } from "styled-tools";

const StyledBadge = styled.div<{ active: BadgeProps["active"] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.active ? p("lightSky") : p("frost"))};
  border-radius: 16px;
  color: ${(props) => (props.active ? p("brandBlue") : p("platinum"))};
  height: 32px;
  width: 42px;
`;

type BadgeProps = {
  active?: boolean;
  count: number;
};

export const Badge = (props: BadgeProps) => {
  const { count } = props;
  return (
    // @ts-ignore
    <StyledBadge {...props}>
      <span>{count}</span>
    </StyledBadge>
  );
};

Badge.propTypes = {
  active: PropTypes.bool,
  count: PropTypes.number.isRequired,
};

Badge.defaultProps = {
  count: 0,
  active: false,
};
