import styled from "styled-components";
import { Dropdown } from "../../../../components/Dropdown";
import { HoverStyledButton } from "../../styledComponents";

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 40px;
  margin-bottom: 24px;

  height: 36px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  height: 36px;

  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const FilterWrapper = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 20px;
  float: right;

  height: 36px;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;

export const ClearFilterButton = styled(HoverStyledButton)`
  background: ${({ theme }) => theme.colorTokens.blue400};
  .p-button {
    width: 103px;
    height: 36px;
    padding: 0;
  }
  .p-button-label {
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    font-size: ${({ theme }) => theme.typography.font350.fontSize};
    line-height: ${({ theme }) => theme.typography.font350.lineHeight};
    font-style: normal;
  }
`;

export const StyledSelect = styled(Dropdown)`
  min-width: 19rem;
`;
