import React from "react";
import { Link } from "@bluecrew/web-react-core";
import {
  DashboardPositionJob,
  DashboardPositionRepeatingSchedule,
} from "../../../../api/bluecrew/types";
import { useNavigateToScheduleDetailPage } from "../../navigationHooks";
import { LinkContainer, NameFlexCenterRow } from "./styledComponents";

export const DashboardScheduleNameCell = ({
  rowData,
  isSubRow,
  isWidthSet,
  isBigFont,
}: {
  rowData: DashboardPositionRepeatingSchedule | DashboardPositionJob;
  isSubRow?: boolean;
  isWidthSet?: boolean;
  isBigFont?: boolean;
}) => {
  const navigateToScheduleDetailPage = useNavigateToScheduleDetailPage();

  return (
    <NameFlexCenterRow isSubRow={isSubRow} isWidthSet={isWidthSet} isBigFont={isBigFont}>
      <LinkContainer>
        <Link
          data-pendo-key="DashboardSchedulesAndIndividualShiftsTableColumnNameLink"
          onClick={() => {
            navigateToScheduleDetailPage(rowData.schedule_id);
          }}
        >
          {rowData.schedule_name}
        </Link>
      </LinkContainer>
    </NameFlexCenterRow>
  );
};
