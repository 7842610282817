import { palette as p } from "styled-tools";
import styled from "styled-components";
import { Grid, Col, Row } from "react-styled-flexboxgrid";
import { Button, Paragraph } from "@bluecrew/web-react-core";

export const StyledGrid = styled(Grid)`
  // TODO: put a max-width on this container
  width: 90%;
`;

export const StyledHeaderRow = styled(Row)`
  height: 100px;
  margin-top: 25px;

  .position-details {
    display: flex;
    flex-direction: column;

    .main-text {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
      letter-spacing: -0.01em;
      color: ${p("slate")};
    }

    .sub-text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.0005em;
      color: ${p("platinum")};
    }
  }

  ${Button} {
    height: 54px;

    ${Paragraph} {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const StyledContentRow = styled(Row)`
  margin-top: 25px;
`;

export const ColumnOneContainer = styled(Col)`
  padding: 0;
  padding-right: 25px;

  @media only screen and (max-width: 1024px) {
    padding-right: 0;
    margin-bottom: 25px;
  }
`;

export const ColumnTwoContainer = styled(Col)`
  min-width: 420px;
  padding: 0;

  .fill-status-container {
    margin-bottom: 32px;
    padding: 2rem 2rem 0 2rem;
  }
`;

export const ContentColumn = styled.div`
  border: 1px solid ${p("aluminum")};
  border-radius: 8px;

  &.col-1 {
    // FilterList component already has border
    border: none;
  }

  &.col-2 {
    .crew-heading {
      display: flex;
      flex-direction: column;
      padding: 32px 0 32px 32px;

      .crew-text {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.0005em;
      }

      .crew-requested {
        color: ${p("platinum")};

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export const OpenApplicationJobsButton = styled(Button)`
  margin-left: 1rem;
`;
