import React from "react";
import styled from "styled-components";
import { RotatableSvgProps } from "../types/RotatableSvg.types";

// Omit is a workaround to keep typescript happy. With it, we can type both the styled
// component and the below convenience func with one set of props, but not have ts get
// mad at us for not including svgToRotate in StyledRotatableSvg as prop in said func
const StyledRotatableSvg = styled.svg<Omit<RotatableSvgProps, "svgToRotate">>`
  transition: ${({ transitionDuration }) => `transform ${transitionDuration}s`};
  transform: ${({ isActive, degreesA, degreesB }) =>
    isActive ? `rotate(${degreesA}deg)` : `rotate(${degreesB}deg)`};
`;

// Convenience component function for using the above styled svg
export const RotatableSvg = ({
  isActive,
  svgToRotate,
  degreesA,
  degreesB,
  transitionDuration,
}: RotatableSvgProps) => (
  <StyledRotatableSvg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    isActive={isActive}
    degreesA={degreesA}
    degreesB={degreesB}
    transitionDuration={transitionDuration}
  >
    <image href={svgToRotate} />
  </StyledRotatableSvg>
);
