import React from "react";
import { styled } from "baseui";
import { useController } from "react-hook-form";
import { Counter, GetCrewMembersMemberCountIcon } from "@bluecrew/blueprint-web";
import { CenteredWrapper } from "../CenteredWrapper";
import { FieldWrapper } from "./FieldWrapper";
import { BaseFieldProps } from "../types/propTypes/BaseField.types";

export const MemberCountField = ({
  control,
  trigger,
  disableLowering = false,
  minVal = 1,
  disabled,
}: BaseFieldProps) => {
  const maxVal = 200;
  const fieldName = "countWorkers";
  const validate = trigger.bind(null, fieldName);

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: {
      min: 1,
    },
  });

  const setCountOfWorkers = async (count: number) => {
    if (count >= 1 && count <= maxVal) {
      if (disableLowering && count < minVal) {
        return;
      }
      if (count > maxVal) {
        return;
      }
      onChange(count);

      await validate();
    }
  };

  return (
    <FieldWrapper icon={<GetCrewMembersMemberCountIcon />}>
      <CenteredWrapper data-pendo-key="MemberCountFieldWrapper">
        <Counter disabled={disabled} value={value} onChange={setCountOfWorkers} error={invalid} />
        <TextWrapper>
          <StyledText>How many</StyledText>
          <StyledText>Crew Members?</StyledText>
        </TextWrapper>
      </CenteredWrapper>
    </FieldWrapper>
  );
};

const TextWrapper = styled("div", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
}));

const StyledText = styled("p", ({ $theme }) => ({
  margin: 0,
  ...$theme.typography.font200,
}));
