import { format } from "date-fns";
import React from "react";
import { AuditTrailItemDto } from "@bluecrew/finance-service-node-client";
import { AuditEditRow, TimesheetsShiftInfo } from "../../../types/Shift.types";
import {
  AuditEditWrapper,
  AuditTimestampWrapper,
  AuditViewColumn,
  NoEditsHeader,
  TimestampEditHeader,
  UserTypeHeader,
} from "./styledComponents";
import { TimestampColumnCell } from "../TimeColumnCell/TimestampColumnCell";
import { createClientSupportRow } from "./auditTrailUtils";
import { TooltipMessage } from "../../../../../components/TooltipMessage";
import { mapUser, mapUserShift } from "../../shiftMapperUtils";

export type AuditTrailViewProps = {
  edits: Array<AuditTrailItemDto>;
  shiftInfo: TimesheetsShiftInfo;
};

export const AuditTrailView = ({ edits, shiftInfo }: AuditTrailViewProps) => {
  const timestampRows: Array<AuditEditRow> = edits.map((editDto, index) => {
    const cid = shiftInfo.company.externalId;
    const jid = shiftInfo.job.externalId;
    const uid = shiftInfo.user.externalId;
    const rowKey = `${cid}-${jid}-${uid}-${index}`;
    if (editDto.editTimestamp && editDto.user && editDto.userShift) {
      const modifiedShift: TimesheetsShiftInfo = {
        company: shiftInfo.company,
        job: shiftInfo.job,
        shiftThresholds: shiftInfo.shiftThresholds,
        user: mapUser(editDto.user),
        userShift: mapUserShift(editDto.userShift),
      };

      const editDate = new Date(editDto.editTimestamp);
      return {
        editDescription: editDto.editDescription,
        editTimestamp: format(editDate, "MMM dd, yyyy h:mm aaa"),
        editUsername: `${editDto.user.firstName} ${editDto.user.lastName}`,
        shiftInfo: modifiedShift,
        rowKey,
      };
    }
    return {
      editDescription: editDto.editDescription,
      rowKey,
    };
  });

  // check for client/support edit in current row's shiftInfo
  const csEdit = createClientSupportRow(shiftInfo);
  const allTimestampRows: Array<AuditEditRow> = [...timestampRows, csEdit];

  return (
    <AuditViewColumn>
      {allTimestampRows.map((timestampRow) => (
        <div key={timestampRow.rowKey}>
          <AuditEditWrapper>
            <TooltipMessage
              placement={"top"}
              message={
                timestampRow.editUsername ? `Edited by ${timestampRow.editUsername}` : "No edits"
              }
            >
              <UserTypeHeader>{timestampRow.editDescription}</UserTypeHeader>
              <TimestampEditHeader>{timestampRow.editTimestamp || ""}</TimestampEditHeader>
            </TooltipMessage>
          </AuditEditWrapper>
          <AuditTimestampWrapper>
            {timestampRow.shiftInfo && <TimestampColumnCell shiftInfo={timestampRow.shiftInfo} />}
            {!timestampRow.shiftInfo && <NoEditsHeader>No edits</NoEditsHeader>}
          </AuditTimestampWrapper>
        </div>
      ))}
    </AuditViewColumn>
  );
};
