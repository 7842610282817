import React from "react";
import {
  BackgroundCheckModalBodyWrapper,
  BodyTextBold,
  BodyTextNonbold,
  CancelButton,
  NoticeWarningImage,
  RemoveButton,
  SettingsNameHeader,
  StyledDialog,
} from "./BackgroundCheckDrugScreening.styledComponents";

interface ModalProps {
  settingName: string;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpenNewValue: boolean) => void;
  handleUpdateClick: () => void;
}

// Design splits the text into 3 lines with the middle one being bolded.
// Separated it here to make it easier to read.
const BodyText = () => {
  return (
    <BackgroundCheckModalBodyWrapper>
      <BodyTextNonbold>{modalTextStrings.bodyPreBoldText}</BodyTextNonbold>
      <BodyTextBold>{modalTextStrings.bodyBoldText}</BodyTextBold>
      <BodyTextNonbold>{modalTextStrings.bodyPostBoldText}</BodyTextNonbold>
    </BackgroundCheckModalBodyWrapper>
  );
};

const modalTextStrings = {
  bodyPreBoldText: "You are about to remove this requirement for",
  bodyBoldText: "all new positions.",
  bodyPostBoldText: "Existing positions will not be affected.",
  cancelButtonText: "No, go back",
  confirmButtonText: "Remove",
};

// Modal is shared between the `AdvancedBackgroundCheck` and `Drug Screening` components.
export const ConfirmDisableSettingsModal = ({
  settingName,
  isModalOpen,
  setIsModalOpen,
  handleUpdateClick,
}: ModalProps) => {
  const formattedHeaderText = `Remove ${settingName} requirement`;
  return (
    <StyledDialog
      header={
        <NoticeWarningImage src={`${import.meta.env.VITE_ASSET_BASE_URL}/icons/notice.svg`} />
      }
      visible={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      modal
      closable={false}
      draggable={false}
      footer={
        <>
          <CancelButton onClick={() => setIsModalOpen(false)}>
            {modalTextStrings.cancelButtonText}
          </CancelButton>
          <RemoveButton onClick={() => handleUpdateClick()}>
            {modalTextStrings.confirmButtonText}
          </RemoveButton>
        </>
      }
    >
      <SettingsNameHeader>{formattedHeaderText}</SettingsNameHeader>
      <BodyText />
    </StyledDialog>
  );
};
