import React, { Dispatch, SetStateAction, useState } from "react";
import { TimesheetsFilterOptions, TimesheetsFiltersType } from "../../types/Payroll.types";
import "./styledComponents.css";
import { ClearFilterButton, StyledSelect } from "./styledComponents";

export type TimesheetsPageFilterControlsProps = {
  setTableFilters: Dispatch<SetStateAction<TimesheetsFiltersType>>;
  filterOptions: TimesheetsFilterOptions;
  tableFilters: TimesheetsFiltersType;
  setQueryText: Dispatch<SetStateAction<string>>;
};

export const TimesheetsPageFilterControls = ({
  setTableFilters,
  filterOptions,
  tableFilters,
  setQueryText,
}: TimesheetsPageFilterControlsProps) => {
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [managerFilter, setManagerFilter] = useState<string>("");
  return (
    <>
      <span className="filter-label-position">Position: </span>
      <StyledSelect
        value={positionFilter}
        onChange={(e) => {
          const jobName = e.value;
          setPositionFilter(jobName);
          setTableFilters({
            search: tableFilters.search,
            position: { name: jobName },
            supervisor: tableFilters.supervisor,
          });
        }}
        options={filterOptions.jobName}
        placeholder="Select a position"
      />
      <div></div>
      <span className="filter-label-manager">Manager: </span>
      <StyledSelect
        value={managerFilter}
        onChange={(e) => {
          const managerName = e.value;
          setManagerFilter(managerName);
          setTableFilters({
            search: tableFilters.search,
            position: tableFilters.position,
            supervisor: { supervisorName: managerName },
          });
        }}
        options={filterOptions.jobShiftSupervisor}
        placeholder="Select a manager"
      />
      <div></div>
      <ClearFilterButton
        label="Clear filters"
        onClick={() => {
          setTableFilters({
            search: { userName: "" },
            position: { name: "" },
            supervisor: { supervisorName: "" },
          });
          setPositionFilter("");
          setManagerFilter("");
          setQueryText("");
        }}
      />
    </>
  );
};
