import React, { useState } from "react";
import { ShiftDetailsModal } from "../ShiftDetails/ShiftDetailsModal";
import { TimestampColumnCell } from "./TimestampColumnCell";
import { TimestampCellWrapper } from "../styledComponents";
import { getUserType } from "../../../../../redux/selectors/auth";
import { TimesheetSupportUserTypes } from "../../../types/Payroll.types";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";
import { useAppSelector } from "../../../../../redux";

export type TimestampColumnContainerProps = {
  rowData: TimesheetsShiftInfo;
};
export const TimestampColumnContainer = ({ rowData }: TimestampColumnContainerProps) => {
  const userType = useAppSelector(getUserType);
  const isSupportUser = TimesheetSupportUserTypes.includes(userType);
  const [isShiftDetailsModalOpen, setShiftDetailsModalOpen] = useState(false);

  return (
    <>
      <TimestampCellWrapper onClick={() => setShiftDetailsModalOpen(true)}>
        <TimestampColumnCell shiftInfo={rowData} spaced showMessaging={isSupportUser} />
      </TimestampCellWrapper>
      {isShiftDetailsModalOpen && (
        <ShiftDetailsModal
          isModalOpen={isShiftDetailsModalOpen}
          onCloseModal={() => setShiftDetailsModalOpen(false)}
          shiftInfo={rowData}
        />
      )}
    </>
  );
};
