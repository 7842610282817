import React from "react";
import { useForm } from "react-hook-form";
import { UsernameField } from "../FormFields/UsernameField";
import { PasswordField } from "../FormFields/PasswordField";
import { Prettify, ValueOf } from "../../../../types/util-types";
import { CommonFormProps } from "./types";
import { StyledButton, StyledLoginFlowForm } from "../styledComponents";
import { BCButtonVariants } from "../../Button";
import { loginStrings } from "../strings";

const FieldNames = {
  Username: "username",
  Password: "password",
} as const;
type FieldName = ValueOf<typeof FieldNames>;

const defaultValues = {
  [FieldNames.Username]: "",
  [FieldNames.Password]: "",
};
type FormValueProps = typeof defaultValues;

export function LoginForm({
  login,
  loginInProgress,
  children,
  onAnyFieldChange,
}: {
  login: (username: string, password: string) => void;
  loginInProgress: boolean;
} & Prettify<CommonFormProps>) {
  const onSubmit = ({ username, password }: FormValueProps) => {
    login(username, password);
  };

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm({ mode: "onChange", defaultValues });

  const getError = (name: FieldName): string | undefined => formErrors[name]?.message;

  const {
    enterEmailLabel,
    emailRequired,
    passwordRequired,
    enterPasswordLabel,
    submitLabel,
    submittingLabel,
  } = loginStrings.login;

  return (
    <StyledLoginFlowForm onSubmit={handleSubmit(onSubmit)}>
      <UsernameField
        fieldName={FieldNames.Username}
        fieldLabel={enterEmailLabel}
        rules={{ required: emailRequired }}
        control={control}
        fieldError={getError(FieldNames.Username)}
        onChange={onAnyFieldChange}
      />
      <div style={{ display: "grid", gap: "12px" }}>
        <PasswordField
          fieldName={FieldNames.Password}
          fieldLabel={enterPasswordLabel}
          rules={{ required: passwordRequired }}
          control={control}
          fieldError={getError(FieldNames.Password)}
          onChange={onAnyFieldChange}
          dataTestId={FieldNames.Password}
        />
        {children}
        <StyledButton
          variant={BCButtonVariants.Primary}
          label={loginInProgress ? submittingLabel : submitLabel}
          type="submit"
        />
      </div>
    </StyledLoginFlowForm>
  );
}
