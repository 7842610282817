import { useEffect, useRef, useState } from "react";

export const useFilteredList = <T extends {}>(
  list: Array<T>,
  filter: (item: T, searchCriteria: string) => boolean,
) => {
  const [collection, setCollection] = useState<Array<T>>(list);

  const [filteredCollection, setFilteredCollection] = useState<Array<T>>(list);

  const lastSearchCriteria = useRef<string>("");

  useEffect(() => {
    setFilteredCollection(collection);

    if (lastSearchCriteria.current.length) {
      filterCollection(lastSearchCriteria.current);
    }
  }, [collection]);

  const filterCollection = (searchCriteria: string) => {
    lastSearchCriteria.current = searchCriteria;

    if (searchCriteria.length) {
      setFilteredCollection(collection.filter((item) => filter(item, searchCriteria)));
    } else {
      reset();
    }
  };

  const reset = () => {
    setFilteredCollection(collection);
    lastSearchCriteria.current = "";
  };

  return {
    list: filteredCollection,
    setList: setCollection,
    filter: filterCollection,
    reset,
  };
};
