import React, { useState } from "react";
import {addWeeks, startOfWeek} from "date-fns";
import { useParams, useLocation } from "react-router-dom";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { Container } from "../styledComponents";
import { MessageWarning } from "./TimesheetsPageWarning";
import { TimesheetsPageHeader } from "./TimesheetsPageHeader";
import { TimesheetsTabs } from "./TimesheetsTabs";
import { TimesheetsTable } from "./TimesheetsPageTable";
import { getTreatmentValue, getIsSplitioReady } from "../../../redux/selectors/splitio";
import { TimesheetsPageFilters } from "./TimesheetsPageFilters";
import { TIMESHEETS_BACKEND_MIGRATION_SPLIT_NAME } from "../../../../shared/constants";
import {
  TimesheetsFiltersType,
  TimesheetsFilterOptions,
  TimesheetTabs,
} from "../types/Payroll.types";
import { useGetCompanyV1Query } from "../../../api/bluecrew/hooks/company";
import { useSplitPostCompanyPayrollQuery } from "../../../api/bluecrew/hooks/payroll";
import { TimesheetsHeaderGroup } from "./styledComponents";
import { SimpleModal, SimpleModalTypes } from "../../../components/SimpleModal";
import {
  getOverdueTimecardMessageText,
  getTimecardStatusCounts
} from "../payrollDataProcessors";
import { useAppSelector } from "../../../redux";
import { PayrollReportsPageBanner } from "../PayrollReports/PayrollReportsPageBanner";

export type TimesheetsProps = {};

// eslint-disable-next-line no-empty-pattern
export const Timesheets = ({}: TimesheetsProps) => {
  const location = useLocation()

  // week is mon-sun in timesheets
  // grab date state stored during payroll reports navigation
  const [timesheetsWeek, setTimesheetsWeek] = useState(
    startOfWeek(location.state?.payrollWeek ?? new Date(), { weekStartsOn: 1 }),
  );
  // clear payweek from state history without triggering rerender
  window.history.replaceState({}, '')

  const [tableFilters, setTableFilters] = useState<TimesheetsFiltersType>({
    search: {
      userName: "",
    },
    position: {
      name: "",
    },
    supervisor: {
      supervisorName: "",
    },
  });
  const [tableFilterOptions, setTableFilterOptions] = useState<TimesheetsFilterOptions>({
    userName: [],
    jobName: [],
    jobShiftSupervisor: [],
  });
  const [activeIndex, setActiveIndex] = useState<TimesheetTabs>(TimesheetTabs.PENDING_REVIEW);

  const financeServiceApiSplit = useAppSelector((state) =>
    getTreatmentValue(state, TIMESHEETS_BACKEND_MIGRATION_SPLIT_NAME),
  );
  const isSplitioReady = useAppSelector(getIsSplitioReady);

  const params = useParams();
  const internalCompanyId = params.internalCompanyId!; // We know internalCompanyId is not null based on how the route is defined
  const warningText: string = `You can take actions on a shift 24 hours after it ends. Bluecrew will review shifts as normal 48 hours after a shift has ended. Further adjustments require a ticket.`;

  const companyDetailsQuery = useGetCompanyV1Query(parseInt(internalCompanyId, 10), isSplitioReady);
  const companyDetails = companyDetailsQuery.data;
  const companyExternalId = companyDetails?.id;
  const companyPayrollQuery = useSplitPostCompanyPayrollQuery(
    timesheetsWeek,
    companyExternalId,
    financeServiceApiSplit,
  );
  const retroCompanyPayrollQuery = useSplitPostCompanyPayrollQuery(
    addWeeks(timesheetsWeek, -1),
    companyExternalId,
    financeServiceApiSplit,
  );

  return (
    <Container>
      <MessageWarning messageContent={warningText} />
      {retroCompanyPayrollQuery.isSuccess && companyDetails && (
        <PayrollReportsPageBanner messageContent={getOverdueTimecardMessageText(retroCompanyPayrollQuery.data)} />
      )}
      {!companyDetails && <SpinnerWithOverlay />}
      <SimpleModal
        headingText={"Error"}
        bodyText={"Something went wrong, please try again."}
        styleType={SimpleModalTypes.ERROR}
        visible={companyDetailsQuery.isError || companyPayrollQuery.isError}
        toggle={() => {}}
      />
      {companyPayrollQuery.isLoading && <SpinnerWithOverlay />}
      {companyDetailsQuery.isSuccess && companyDetails && (
        <>
          <TimesheetsHeaderGroup>
            <TimesheetsPageHeader
              week={timesheetsWeek}
              setWeek={setTimesheetsWeek}
              companyDetails={companyDetails}
            />
            <TimesheetsPageFilters
              setTableFilters={setTableFilters}
              filterOptions={tableFilterOptions}
              tableFilters={tableFilters}
            />
            {companyPayrollQuery.data && (
              <TimesheetsTabs
                timecardStatusCounts={getTimecardStatusCounts(companyPayrollQuery.data)}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            )}
          </TimesheetsHeaderGroup>
          {companyPayrollQuery.data && (
            <TimesheetsTable
              activeIndex={activeIndex}
              setFilterOptions={setTableFilterOptions}
              tableData={companyPayrollQuery.data}
              tableFilters={tableFilters}
            />
          )}
        </>
      )}
    </Container>
  );
};
