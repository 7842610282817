import { Paragraph } from "@bluecrew/web-react-core";
import React from "react";

type ParagraphWithBoldTextProps = {
  inputText: string;
};

export const ParagraphWithBoldText = ({ inputText }: ParagraphWithBoldTextProps) => {
  const [textBeforeBold, boldText, textAfterBold] = inputText.split("**");

  return (
    <Paragraph>
      <span>{textBeforeBold}</span>
      <strong>{boldText}</strong>
      <span>{textAfterBold}</span>
    </Paragraph>
  );
};
