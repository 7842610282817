// ModifyDaysOrTimes - VIEW
// =============================================================================
// Component for modifies times and/or dates and applying to crews
// NOTE that this is done in one component since these functions share 2/3 of functionality

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import MultistepForm, { ButtonAlignments, ButtonBarWidth } from "../../../components/MultistepForm";
import {
  getSchedule,
  getScheduleExceptions,
  getUsersForCrewPicker,
  ScheduleWorkers,
} from "../../../redux/selectors/schedule";
import { enumerateDaysBetweenDates, calculateStartToEndTimeDuration } from "../../../utility/index";
import scheduleActions from "../../../redux/actions/schedule";
import { assetUrl } from "../../../api/bluecrew/constants/index";
import { ModifyCrew } from "./ModifyCrew";
import { ModifyTimes } from "./ModifyTimes";
import { ModifyDates } from "./ModifyDates";
import { CloseModal, Container } from "./styledComponents";
import { PageCopy } from "./types";
import { StateShape } from "../../../redux/reducers";

type AllPageCopy = {
  times: PageCopy;
  dates: PageCopy;
  crew: PageCopy;
};

const mapStateToProps = (state: StateShape) => ({
  schedule: getSchedule(state),
  scheduleExceptions: getScheduleExceptions(state),
  crewData: getUsersForCrewPicker(state),
});

const mapDispatchToProps = (dispatch: Dispatch): any => ({
  fetchScheduleWorkers: (credentials) =>
    dispatch(scheduleActions.readScheduleWorkers.request(credentials)),
  fetchScheduleExceptions: (payload) =>
    dispatch(scheduleActions.readScheduleExceptions.request(payload)),
  createScheduleException: (data) =>
    dispatch(scheduleActions.createScheduleException.request(data)),
});

type ModifyDaysOrTimesProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    schedule: any;
    includeTimes: boolean;
    handleClose: Function;
    // Explicitly define this type since reselect Flow types are broken.
    crewData: ScheduleWorkers;
  };

const isEmpty = (obj: any) => Object.keys(obj).length === 0;

const ModifyDaysOrTimes = ({
  includeTimes = false,
  handleClose = () => {},
  schedule,
  scheduleExceptions,
  crewData,
  fetchScheduleWorkers,
  fetchScheduleExceptions,
  createScheduleException,
}: ModifyDaysOrTimesProps) => {
  const [workerIds, setWorkerIds] = useState<number[]>([]);

  useEffect(() => {
    fetchScheduleWorkers({
      scheduleId: schedule.schedule_id,
    });

    fetchScheduleExceptions({
      scheduleId: schedule.schedule_id,
    });
  }, []);

  const formatDates = (modifiedDays, formValues) => {
    const { shifts } = schedule;
    return {
      addDays: Object.keys(modifiedDays.daysNotHighlightedSelected).map((day) => ({
        date: day,
        start_time: shifts[0].start_time,
        end_time: shifts[0].end_time,
      })),
      removeDays: includeTimes
        ? []
        : Object.keys(modifiedDays.daysHighlightedSelected).map((day) => ({
            date: day,
            start_time: shifts[0].start_time,
            end_time: shifts[0].end_time,
          })),
      editDays: includeTimes
        ? Object.keys(modifiedDays.daysHighlightedSelected).map((day) => ({
            date: day,
            start_time: formValues.start_time,
            end_time: formValues.end_time,
          }))
        : [],
    };
  };

  const handleCrewPickerChange = (crewIds: Array<string>) => {
    setWorkerIds(crewIds.map((s) => parseInt(s, 10)));
  };

  const handleSubmit = (formValues: any) => {
    const daysObject = formatDates(formValues.dates, formValues);
    const values = {
      worker_ids: formValues.workerIds,
      ...daysObject,
    };

    handleClose();

    return createScheduleException({
      payload: values,
      scheduleId: schedule.schedule_id,
    });
  };

  const { timezone, start_date_time, end_date_time, shifts } = schedule;

  const dates = enumerateDaysBetweenDates(
    start_date_time,
    end_date_time,
    shifts.map((shift) => shift.day_of_week),
    timezone,
  );

  const highlightedOnly = includeTimes;

  const iconPath = includeTimes
    ? `${assetUrl}/icons/modify-clock.png`
    : `${assetUrl}/icons/calendar.png`;

  const headerCopy = includeTimes ? "Modify start / end time" : "Add / remove days";

  const datesSubheaderCopy = includeTimes
    ? "Choose the days this modification applies to"
    : "Choose which days you'd like to add or remove";

  const pageCopy: AllPageCopy = {
    times: {
      header: headerCopy,
      subheader: "When do you need crew members to work?",
    },
    dates: {
      header: headerCopy,
      subheader: datesSubheaderCopy,
    },
    crew: {
      header: headerCopy,
      subheader: "Choose the crew this modification applies to",
    },
  };

  // Managing correct sequence & accurate props got weird, could be a better way to do this
  const formPages = [
    // @ts-ignore
    <ModifyCrew
      key="modify-crew"
      crewData={crewData}
      iconPath={iconPath}
      pageCopy={pageCopy.crew}
      onChange={handleCrewPickerChange}
      validate={(values) => {
        const errors = {};
        if (values.workerIds.length < 1) {
          // @ts-ignore
          errors.workerIds = "Please select at least one crew member";
        }
        return errors;
      }}
    />,
  ];

  if (includeTimes) {
    formPages.push(
      <ModifyTimes
        key="modify-times"
        iconPath={iconPath}
        pageCopy={pageCopy.times}
        validate={(values) => {
          const errors = {};
          if (
            values.start_time === schedule.shifts[0].start_time &&
            values.end_time === schedule.shifts[0].end_time
          ) {
            // @ts-ignore
            errors.times = "Please select new times ";
          }
          if (calculateStartToEndTimeDuration(values.start_time, values.end_time) < 4) {
            // @ts-ignore
            errors.duration = "Shift duration must be at least 4 hours";
          }
          return errors;
        }}
      />,
    );
  }

  formPages.push(
    <ModifyDates
      key="modify-dates"
      dates={dates}
      highlightedOnly={highlightedOnly}
      iconPath={iconPath}
      pageCopy={pageCopy.dates}
      workerIds={workerIds}
      crewData={crewData}
      scheduleExceptions={scheduleExceptions}
      timezone={timezone}
      validate={(values) => {
        const errors = {};
        if (
          isEmpty(values.dates.daysHighlightedSelected) &&
          isEmpty(values.dates.daysNotHighlightedSelected)
        ) {
          // @ts-ignore
          errors.dates = "Choose the days this modification applies to";
        }
        return errors;
      }}
    />,
  );

  // TODO: adjust inputs to fit modal
  return (
    <Container>
      <CloseModal onClick={handleClose} />
      {/* @ts-ignore */}
      <MultistepForm
        buttonbarwidth={ButtonBarWidth.FULL}
        initialvalues={
          // grab existing times for schedule
          {
            start_time: schedule.shifts[0].start_time,
            end_time: schedule.shifts[0].end_time,
            workerIds: [],
            timezone: schedule.timezone,
            dates: {
              daysHighlightedSelected: {},
              daysNotHighlightedSelected: {},
            },
          }
        }
        submittext="Modify Schedule"
        buttonalignment={ButtonAlignments.CENTER}
        onSubmit={(values) => handleSubmit(values)}
      >
        {formPages}
      </MultistepForm>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyDaysOrTimes);
