// Use the id as part of the sorting for cases where
// position names are identical.
import { ListedPosition } from "../../../api/bluecrew/types";

export const sortDashboardPositionTitle = (
  positionA: ListedPosition,
  positionB: ListedPosition,
  isDesc: boolean,
) => {
  const titleA = `${positionA.title.toLowerCase()}:${positionB.position_id}`;
  const titleB = `${positionB.title.toLowerCase()}:${positionB.position_id}`;

  if (isDesc) {
    return titleA > titleB ? 1 : -1;
  }

  return titleB > titleA ? 1 : -1;
};

export const sortDashboardPositionCreatedDate = (
  positionA: ListedPosition,
  positionB: ListedPosition,
  isDesc: boolean,
) => {
  const dateA = positionA.created_at;
  const dateB = positionB.created_at;

  if (isDesc) {
    return dateA > dateB ? 1 : -1;
  }

  return dateB > dateA ? 1 : -1;
};
