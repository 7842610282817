// MINIMUM WAGE - SAGA
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, call, put, takeLatest } from "redux-saga/effects";

import * as minWageAPI from "../../api/bluecrew/minWage";
import minWageActions from "../actions/minWage";

function* readMinWageSaga(action: any): Generator<Effect, void, any> {
  try {
    const response = yield call(minWageAPI.readMinWage, action.payload);
    yield put(minWageActions.readMinWage.success(response));
  } catch (error) {
    yield put(minWageActions.readMinWage.failure(error));
  }
}

export default {
  *watchReadMinWageSaga(): SagaIterator {
    yield takeLatest(minWageActions.readMinWage.request, readMinWageSaga);
  },
};
