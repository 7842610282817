// REDUX STORE
// =============================================================================
// Configures and de-boilerplates the creation of the Redux store. The
// application uses a single store. It should be required by a `<Provider>`
// client-side or server-side.

import { EnhancedStore, Reducer, configureStore as configureStoreRedux } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
// Redux-persist
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
// eslint-disable-next-line no-restricted-imports
import { TypedUseSelectorHook, useSelector } from "react-redux";
import rootReducer, { StateShape } from "./reducers";
import rootSaga from "./sagas";
import authActions from "./actions/auth";

const config = {
  key: "bluecrewemployer",
  storage,
  whitelist: ["auth"],
};

export function configureStore(): Promise<EnhancedStore<StateShape>> {
  const sagaMiddleware = createSagaMiddleware();

  // @ts-ignore SplitIoIsBrokenAction has a very odd payload field
  const reducers: Reducer<StateShape> = persistCombineReducers(config, {
    ...rootReducer,
  });
  const wipeoutRootReducer: Reducer<StateShape> = (state, action) => {
    // Clear all data in root store to initial state on logout.
    if (action.type === authActions.login.destroy().type) {
      state = undefined;
    }

    return reducers(state, action);
  };

  // Create store
  const store: EnhancedStore<StateShape> = configureStoreRedux({
    reducer: wipeoutRootReducer,
    devTools: import.meta.env.MODE !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(sagaMiddleware),
  });

  // Begin persisting store
  return new Promise((resolve) => {
    // Redux Saga configuration
    persistStore(store, {}, () => {
      sagaMiddleware.run(rootSaga);
      resolve(store);
    });
  });
}

export const useAppSelector: TypedUseSelectorHook<StateShape> = useSelector;
