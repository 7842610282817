import { createSelector } from "reselect";
import get from "lodash/get";
import { StateShape } from "../reducers";
import { CompanyResponse, CompanyNameDescAndURL } from "../../api/bluecrew/types";

type Company = CompanyResponse["company"];

export const getCompanyDetails = (state: StateShape) =>
  get(state, "company.getCompanyDetails.success") as unknown as CompanyResponse | null;

export const getUserCompanies = (state: StateShape): Array<any> =>
  get(state, "company.userCompanies.success.companies") || [];

interface UserCompany {
  avatar: string | null;
  id: string; // internal id
  name: string;
  location: string;
}

export const getFormattedUserCompanies = createSelector<StateShape, any, Array<UserCompany>>(
  getUserCompanies,
  (companies) =>
    companies.map((company) => ({
      avatar: company.url || null,
      id: company.company_id.toString(),
      name: company.name || company.company_name,
      location: company.city,
    })),
);

export const getCompanyName = createSelector<StateShape, any, string>(
  [getCompanyDetails],
  (details) => get(details, "company.data.name") || "",
);

export const getCompanyInformation = createSelector<StateShape, any, CompanyNameDescAndURL>(
  [getCompanyDetails],
  (details) => ({
    companyName: get(details, "company.data.name") || "",
    companyDescription: get(details, "company.data.description") || "",
    companyLogoUrl:
      get(details, "company.data.logo.url") || "/public/images/icons/default-company-logo-lg.svg",
    companyPhoneNumber: get(details, "company.data.phone_number") || "",
  }),
);

export const getRefillPolicy = createSelector<StateShape, any, number>(
  [getCompanyDetails],
  (details) => get(details, "company.refill_on") || 0,
);

export const getCompanyId = createSelector<StateShape, any, number | "">(
  [getCompanyDetails],
  (details) => get(details, "company.id") || "",
);

export const getCompanyExternalId = createSelector<StateShape, any, string>(
  [getCompanyDetails],
  (details) => get(details, "company.external_id") || "",
);

export const getCompanyLogoUrl = createSelector<StateShape, any, string>(
  [getCompanyDetails],
  (details) =>
    get(details, "company.data.logo.url") || "/public/images/icons/default-company-logo-lg.svg",
);

export const getParentCompanyId = createSelector<StateShape, any, number | null>(
  [getCompanyDetails],
  (details): Company["parent_company_id"] => get(details, "company.parent_company_id") || null,
);
