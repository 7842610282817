// MODAL - COMPONENT
// =============================================================================

import React from "react";
import { Heading, Paragraph, Portal, Backdrop } from "@bluecrew/web-react-core";
import { ModalBody, ModalFooter, StyledOverlay } from "./styledComponents";

export const SimpleModalTypes = {
  SUCCESS: "success",
  FAILURE: "failure", // TODO: thinking we should use either failure or error, but putting both in for now to avoid errors in places ERROR is being used
  ERROR: "error",
};

interface SimpleModalProps {
  children?: React.ReactNode;
  headingText: string;
  bodyText: string;
  styleType: string;
  visible: boolean;
  toggle: () => void;
}

export const SimpleModal = ({
  headingText = "Error",
  bodyText = "Something went wrong, please try again.",
  styleType = "success",
  visible = false,
  toggle,
  children,
}: SimpleModalProps) => (
  <React.Fragment>
    <Portal>
      <Backdrop fade visible={visible} onClick={toggle} />
      <StyledOverlay fade visible={visible}>
        <ModalBody className={styleType}>
          <Heading as="h3">{headingText}</Heading>
          <Paragraph>{bodyText}</Paragraph>
        </ModalBody>
        <ModalFooter>{children}</ModalFooter>
      </StyledOverlay>
    </Portal>
  </React.Fragment>
);
