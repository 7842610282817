// AUTH - SELECTORS
// =============================================================================

import { createSelector } from "reselect";
import { AbilityBuilder, Ability } from "@casl/ability";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { StateShape } from "../reducers";
import { canPostCompanyJobs } from "./segment";
import { UserDetail, UserType } from "../../api/bluecrew/types";

export const getLastLoggedInAt = (state: StateShape): number | typeof undefined =>
  state.auth.login.lastUpdate ? state.auth.login.lastUpdate : undefined;

// USER STUFF
// might want to move these to a user selector file. or not?
const getPermissions = (state: StateShape) => get(state, "auth.login.success.permissions") || [];

export const getUser = (state: StateShape) =>
  state.auth.login.success as unknown as { user: UserDetail };

// TODO: Remove this. Don't use this selector for new code because it
// returns a partial user object before the user has loaded.
const getUserDetails = (state: StateShape) =>
  get(state, "auth.login.success.user") || {
    company_id: null,
  };

export const getUserCompany = createSelector<StateShape, any, number>(
  getUserDetails,
  (user) => user.company_id,
);

export const getUserId = createSelector<StateShape, any, number>(getUserDetails, (user) => user.id);

export const getExternalUserId = createSelector<StateShape, any, string>(
  getUserDetails,
  (user) => user.external_id,
);

export const getUserUsername = createSelector<StateShape, any, string>(
  getUserDetails,
  (user) => user.username,
);

export const getUserAvatar = createSelector<StateShape, any, string>(getUserDetails, (user) =>
  user && user.data && user.data.avatar ? user.data.avatar.url : "",
);

export const getUserFullName = createSelector<StateShape, any, string>(
  getUserDetails,
  (user) => `${capitalize(user.normalized_first_name)} ${capitalize(user.normalized_last_name)}`,
);

export const getUserType = createSelector<StateShape, any, UserType>(
  getUserDetails,
  (user) => user.type,
);

export const resumeIsFetching = (state: StateShape) => state.auth.resume.isFetching;

/*
type PermissionAbility = {
  action: string;
  entity: string;
};
*/

export const getUserPermissions = createSelector(
  getPermissions,
  canPostCompanyJobs,
  (permissionsArr, canPostCompanyJobs) => {
    const { can, build } = new AbilityBuilder(Ability);
    const postCompanyJobsAbility = {
      // TODO: figure out why this permission isn't defined in getRolesAndPermissions of api-v2
      action: "post",
      entity: "company_jobs",
    };

    [...permissionsArr, ...(canPostCompanyJobs ? [postCompanyJobsAbility] : [])].forEach(
      ({ action, entity }) => can(action, entity),
    );
    return build();
  },
);

export const getViewAdminToolsPermission = createSelector<StateShape, Array<any>, boolean>(
  getPermissions,
  (permissionsArr) => permissionsArr.some((perm) => perm.slug === "view_admin_tools"),
);

export const getBranchSwitchCompanyPermission = createSelector<StateShape, Array<any>, boolean>(
  getPermissions,
  (permissionsArr) => permissionsArr.some((perm) => perm.slug === "switch_company_as_branch"),
);

export const isLoginPending = (state: StateShape) => Boolean(get(state, "auth.login.isFetching"));

export const isLoginFailedUnauthorized = (state: StateShape) => {
  const responseStatus = get(state, "auth.login.failure.response.status");
  if (responseStatus === 401) {
    return true;
  }

  return false;
};

export const isLoginFailure = (state: StateShape): boolean => {
  const responseStatus = get(state, "auth.login.failure.response.status");
  return !!responseStatus && responseStatus >= 400;
};
