import React, { useState } from "react";
import { useThemedStyletron } from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { useWatch } from "react-hook-form";
import { SkillsetSectionProps } from "../../types/propTypes/SkillsetSection.types";
import { SkillItem } from "../../types/propTypes/SkillsetField.types";
import { SkillsetField } from "./SkillsetField";
import { Divider } from "../../../../components/Divider";

export const SkillsetSection = ({
  onNext,
  control,
  trigger,
  skills,
  disabled = false,
}: SkillsetSectionProps) => {
  const skillset = useWatch({
    control,
    name: "skillset",
  });

  const [nextHandler, setNextHandler] = useState<boolean>(!skillset);
  const [, theme] = useThemedStyletron();
  const onNextHandler = (item?: SkillItem) => {
    // Next handler should be called only once
    if (nextHandler) {
      setNextHandler(!item);
      onNext?.();
    }
  };

  return (
    <FieldContainer>
      <FieldRow>
        <SkillsetField
          control={control}
          disabled={disabled}
          onNextHandler={onNextHandler}
          skills={skills}
          trigger={trigger}
        />
      </FieldRow>
      {!skillset && <Divider $marginTop={theme.sizing.scale1000} />}
    </FieldContainer>
  );
};

const FieldContainer = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
}));

const FieldRow = styled("div", () => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
}));
