import styled from "styled-components";
import { colorTheme } from "@bluecrew/blueprint-web";

export const RequestLogTableContainer = styled.div`
  max-height: 250px;
  overflow: auto;

  thead tr th {
    z-index: 2;
    position: sticky;
    top: 0;
    text-align: left;
  }

  table {
    border-collapse: collapse;
  }

  th {
    z-index: 2;
    /* With border-collapse, we must use box-shadow or psuedo elements
    for the header borders */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    background-color: #F2F3F7;
    text-transform: uppercase;
    color: ${colorTheme.dashboardTableHeaderTextColor};
  }

  /* Basic Demo styling */
  table {
    width: 100%;
  }
  table td {
    padding: 16px;
  }
  tbody tr {
    z-index: 1
  }
  thead {
    z-index: 2;
  }

  th,
  td {
    padding: 16px 16px;

    &.collapse {
      width: 0.0000000001%;
      rotate(180);
    }

    border-right: 0;
    text-align: left;
  }
`;
