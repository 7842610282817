import React, {useState} from "react";
import { Row } from "react-table";
import { StyledRow } from "./styledComponents";
import { TimesheetTabs } from "../../types/Payroll.types";
import { TimesheetsShiftInfo } from "../../types/Shift.types";

export type TableStageRowProps = {
  row: Row<TimesheetsShiftInfo>;
  activeIndex: TimesheetTabs;
};
export const TableStageRow = ({ row, activeIndex }: TableStageRowProps) => {
  const [rowHoverState, setRowHoverState] = useState(false);

  return (
    <StyledRow {...row.getRowProps()} onMouseOver={() => setRowHoverState(true)} onMouseOut={() => setRowHoverState(false)}>
      {row.cells.map((cell) => {
        const cellKey = cell.getCellProps().key;

        return (
          <td {...cell.getCellProps([{ className: cell.column.className }])} key={cellKey}>
            {cell.render("Cell", {
              rowData: { ...row.original },
              activeTabIndex: activeIndex,
              rowHoverState,
            })}
          </td>
        );
      })}
    </StyledRow>
  );
};
