import React from "react";

export const ApprovedIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.274" cy="12" r="12" fill="#08942E" />
    <path
      d="M6.27399 13L10.274 17L18.274 8"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
