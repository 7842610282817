/**
 * NOTE: Imports the Default Reakit Theme
 * Visible either in node_modules/reakit-theme-default/src/index.ts, or by
 * following this link (which may be newer than the version installed here)
 *
 * https://github.com/reakit/reakit/blob/master/packages/reakit-theme-default/src/index.ts
 */
import { css, createGlobalStyle as injectGlobal } from "styled-components";
import { palette as p, switchProp } from "styled-tools";
import { colorTokens } from "@bluecrew/blueprint-web/src/theme/colorTokens";
import { colors } from "@bluecrew/blueprint-web/src/theme/colors";
import { borders } from "@bluecrew/blueprint-web/src/theme/borders";
import { lighting } from "@bluecrew/blueprint-web/src/theme/lighting";
import { sizing } from "@bluecrew/blueprint-web/src/theme/sizing";
import { primitives } from "@bluecrew/blueprint-web/src/theme/primitives";
import { typography } from "@bluecrew/blueprint-web/src/theme/typography";
import defaultTheme from "./reakit-theme-default";
import { DEVICE_CLASSES } from "../constants/responsive";

const brand = {
  blue: "#3696BF",
};

const tunable = {
  borderRadius: "0.25rem",
};

const GlobalStyles = injectGlobal`
  html,
  body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  body {
    font-size: 85%;
  }

  * {
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
  }

  /*
  REACH ROUTER FIX: Router renders a <div> with no other way to apply styling.
  For the purposes of this app, each of these <div>s is a view or a child
  view. Beacuse we use a Flexbox layout, the safest default is a flex element
  with flex: 1.
*/
  div[role="group"] div[tabindex] {
    display: flex;
    flex: 1;
  }
`;

const name = "BlueCrew";

const palette = {
  white: "#ffffff",
  whiteText: p("black"),

  black: "#212121",
  blackText: p("white"),

  brandBlue: "#3246F0",
  brandBlueText: p("brandBlue"),

  slate: "#34353C",
  slateText: p("slate"),

  frost: "#f6f6f7",
  frostText: p("frost"),

  platinum: "#7A7B81",
  platinumText: p("platinum"),

  aluminum: "#D7D7D9",
  aluminumText: p("aluminum"),

  midnight: "#06638B",
  midnightText: p("midnight"),

  oxford: "#143672",
  oxfordText: p("oxford"),

  lightSky: "#DEF6FF",

  sky: "#00B2FF",
  skyText: p("sky"),

  mandarin: "#F78F48",
  mandarinText: p("mandarin"),

  sunstroke: "#F9C139",
  sunstrokeText: p("sunstroke"),

  mint: "#24EF98",
  mintText: p("mint"),

  siren: "#E03131",
  sirenText: p("siren"),

  grayscale: ["#1E1F1F", "#3F4041", "#A2A4A7", "#E0E2E7", "#f5f4f6"],
  grayscaleText: ["white", "white", "white", "black", "black"],

  // default, hover, active, disabled
  primary: [p("brandBlue"), p("oxford"), p("oxford"), "#7A7B81"],
  primaryText: ["white", "white", "white", "white"],

  secondary: ["#fff", "#34353C", "#34353C", "white"],
  secondaryText: ["#34353C", "white", "white", p("platinum")],
  secondaryBorder: [p("aluminum"), "#34353C", "#34353C", p("platinum")],

  success: ["#29BE2F"],
  inProgress: ["#3246F0"],

  danger: ["#e03130", "#ba2929", "#9c2222", "#9c2222"],
  dangerText: ["white", "white", "white", "white"],

  background: [p("grayscale", -4), p("grayscale", -3), p("grayscale", -2), p("grayscale", -1)],
  backgroundText: p("black"),

  shadow: [
    "rgba(0, 0, 0, 0.9)",
    "rgba(0, 0, 0, 0.7)",
    "rgba(0, 0, 0, 0.5)",
    "rgba(0, 0, 0, 0.3)",
    "rgba(0, 0, 0, 0.15)",
    "rgba(0, 0, 0, 0.075)",
  ],
  shadowText: [p("white"), p("white"), p("white"), p("black"), p("black"), p("black")],

  transparent: "transparent",
  transparentText: p("black"),

  border: p("shadow", -2),
};

const sizes = { ...DEVICE_CLASSES };

/**
 * COMPONENTS
 */
const { Avatar, Blockquote } = defaultTheme;

const Button = css`
  height: 42px;
  min-width: 2.5em;
  padding: 0 20px;

  position: relative;
  display: inline-flex;
  flex: none;
  align-items: center;
  justify-content: center;

  font-weight: normal;
  white-space: nowrap;
  text-decoration: none;

  border-radius: ${tunable.borderRadius};
  appearance: none;
  cursor: pointer;
  outline: none;
  user-select: none;

  background-color: ${p("grayscale", 3)};
  color: ${p("grayscaleText", 3)};

  ${switchProp("palette", {
    primary: css`
      background-color: ${p("primary")};
      color: ${p("primaryText")};

      &:hover,
      &.hover {
        background-color: ${p("primary", 1)};
        color: ${p("primaryText", 1)};
      }

      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: ${p("primary", 2)};
        color: ${p("primaryText", 2)};
      }

      &[disabled],
      .disabled {
        background-color: ${p("primary", 3)};
        color: ${p("primaryText", 3)};
      }
    `,

    secondary: css`
      background-color: ${p("secondary")};
      color: ${p("secondaryText")};
      border: solid 1px ${p("secondaryBorder")};

      &:hover,
      &.hover {
        background-color: ${p("secondary", 1)};
        color: ${p("secondaryText", 1)};
        border: solid 1px ${p("secondaryBorder", 1)};
      }

      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: ${p("secondary", 2)};
        color: ${p("secondaryText", 2)};
        border: solid 1px ${p("secondaryBorder", 2)};
      }

      &[disabled],
      .disabled {
        background-color: ${p("secondary", 3)};
        color: ${p("secondaryText", 3)};
        border: solid 1px ${p("secondaryBorder", 3)};
      }
    `,

    danger: css`
      background-color: ${p("danger")};
      color: ${p("dangerText")};

      &:hover,
      &.hover {
        background-color: ${p("danger", 1)};
        color: ${p("dangerText", 1)};
      }

      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: ${p("danger", 2)};
        color: ${p("dangerText", 2)};
      }

      &[disabled],
      .disabled {
        background-color: ${p("danger", 3)};
        color: ${p("dangerText", 3)};
      }
    `,

    success: css`
      background-color: ${p("success", 2)};
      color: ${p("successText", 2)};
    `,

    alert: css`
      background-color: ${p("alert", 2)};
      color: ${p("alertText", 2)};
    `,
  })}

  &[disabled],
  .disabled {
    background-color: ${p("platinum", 3)};
    color: ${p("primaryText", 3)};
    cursor: not-allowed;

    &:active {
      pointer-events: none;
    }

    &:after {
      display: block;
    }
  }

  &:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background-color: rgba(255, 255, 255, 0.35);
  }
`;

const { Code, Field, GroupItem, Heading, Image } = defaultTheme;

const Label = css`
  margin-bottom: 0.375rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${p("platinum")};
`;

const Backdrop = css`
  background-color: rgba(0, 0, 0, 0.5);
`;

const Input = css`
  display: block;
  width: 100%;
  padding: 0.9375rem 1.25rem;
  height: 3.375rem;
  border-radius: ${tunable.borderRadius};
  background-color: ${p("frost")};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.0063rem;
  color: ${p("slate")};

  &[type="checkbox"],
  &[type="radio"] {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0.1875rem 0.3125rem;
  }
  &::placeholder {
    color: ${p("platinum")};
    opacity: 0.85;
  }
  textarea & {
    padding: 0.5rem;
    height: 10.125rem;
  }
  &:focus {
    outline: none;
    border-radius: 0.25rem;
    border: solid 0.0938rem ${p("brandBlue")};
  }
  &[disabled],
  .disabled {
    cursor: not-allowed;
    &:active {
      pointer-events: none;
    }
    &:after {
      display: block;
    }
  }
`;

const { Link, List, Overlay, Paragraph, Popover, PopoverArrow, Sidebar, Table, Tabs, TabsTab } =
  defaultTheme;

type ThemeType = {
  tunable: typeof tunable;
  GlobalStyles: typeof GlobalStyles;
  sizes: typeof sizes;

  // STORYBOOK AND TOOLING
  name: typeof name;

  // REAKIT THEME COMPATABILITY
  brand: typeof brand;
  palette: any; // Make TypeScript Happy :)
  Avatar: typeof Avatar;
  Backdrop: typeof Backdrop;
  Blockquote: typeof Blockquote;
  Button: typeof Button;
  Code: typeof Code;
  Field: typeof Field;
  GroupItem: typeof GroupItem;
  Heading: typeof Heading;
  Image: typeof Image;
  Input: typeof Input;
  Label: typeof Label;
  Link: typeof Link;
  List: typeof List;
  Overlay: typeof Overlay;
  Paragraph: typeof Paragraph;
  Popover: typeof Popover;
  PopoverArrow: typeof PopoverArrow;
  Sidebar: typeof Sidebar;
  Table: typeof Table;
  Tabs: typeof Tabs;
  TabsTab: typeof TabsTab;

  // Blueprint Theme
  borders: typeof borders;
  colors: typeof colors;
  colorTokens: typeof colorTokens;
  lighting: typeof lighting;
  primitives: typeof primitives;
  sizing: typeof sizing;
  typography: typeof typography;
};

export const theme: ThemeType = {
  tunable,
  GlobalStyles,
  sizes,

  // STORYBOOK AND TOOLING
  name,

  // REAKIT THEME COMPATABILITY
  brand,
  palette,
  Avatar,
  Backdrop,
  Blockquote,
  Button,
  Code,
  Field,
  GroupItem,
  Heading,
  Image,
  Input,
  Label,
  Link,
  List,
  Overlay,
  Paragraph,
  Popover,
  PopoverArrow,
  Sidebar,
  Table,
  Tabs,
  TabsTab,

  // Blueprint Theme
  borders,
  colors,
  colorTokens,
  lighting,
  primitives,
  sizing,
  typography,
};
