import { styled } from "baseui";
import React from "react";
import { AlertIcon, BlueprintTheme, useThemedStyletron } from "@bluecrew/blueprint-web";
import { MAX_INVITEES_COUNT } from "./maxInviteesCount.constant";
import { isCrewMember } from "../types/propTypes/ChooseCrewModal.types";
import { ChooseCrewListProps } from "../types/propTypes/ChooseCrewList.types";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { CrewMemberListItem } from "./workforce/CrewMemberListItem";

export const ChooseCrewList = ({
  list = [],
  onEditClick,
  isBlueshiftSchedule,
  disabled,
}: ChooseCrewListProps) => {
  const isMember = isCrewMember(list[0]);
  const [, theme] = useThemedStyletron();
  return (
    <div hidden={isBlueshiftSchedule}>
      <StyledHeaderWrapper>
        <StyledHeader>{isMember ? "Direct Invitees" : "Groups"}</StyledHeader>
        <StyledEditLink onClick={onEditClick} hidden={disabled}>
          Add / Remove
        </StyledEditLink>
      </StyledHeaderWrapper>
      <Spacer $type={SpacerType.vertical} $size="8px" />
      <StyledList>
        {list.map((item) => (
          <CrewMemberListItem item={item} key={item.id} />
        ))}
      </StyledList>
      {list.length > MAX_INVITEES_COUNT && (
        <>
          <Spacer $type={SpacerType.vertical} $size="5px" />
          {/* TODO:(BW-375) Re-use existing ErrorMessage component from ErrorMessage.tsx  */}
          <ErrorMessageWrapper>
            <AlertIcon width={16} height={16} />
            <Spacer $type={SpacerType.horizontal} $size={theme.sizing.scale300} />
            <ErrorText>{`Maximum ${MAX_INVITEES_COUNT} invitees per request`}</ErrorText>
          </ErrorMessageWrapper>
        </>
      )}
    </div>
  );
};

const StyledList = styled<{}, "div", BlueprintTheme>("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.crystal,
  borderRadius: $theme.sizing.scale200,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingTop: $theme.sizing.scale600,
  paddingLeft: $theme.sizing.scale600,
}));

const StyledHeaderWrapper = styled("div", () => ({}));
const StyledHeader = styled("span", ({ $theme }) => ({
  ...$theme.typography.font350,
}));

const StyledEditLink = styled<{}, "a", BlueprintTheme>("a", ({ $theme }) => ({
  ...$theme.typography.font150,
  lineHeight: $theme.sizing.scale600,
  color: $theme.colors.ultramarine,
  marginLeft: $theme.sizing.scale300,
  cursor: "pointer",
}));

// TODO:(BW-375) Re-use existing ErrorMessage component from ErrorMessage.tsx
const ErrorMessageWrapper = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

// TODO:(BW-375) Ditto
const ErrorText = styled("p", ({ $theme }) => ({
  color: $theme.colors.negative,
  ...$theme.typography.font200,
  margin: "0px",
}));
