import { DEFAULT_BASE_WAGE, DEFAULT_TAX_RATE } from "../../../shared/constants";

export const DEFAULT_POSITION_TO_COPY = {
  base_wage: DEFAULT_BASE_WAGE,
  rate: DEFAULT_TAX_RATE,
  title: "",
  JobType: {
    name: "",
    description: "",
  },
  address: {
    latitude: 41.8781,
    longitude: -87.6298,
    display_address: "",
  },
};
