import {
  AuthenticationErrorType,
  ErrorTypes,
  Contexts,
  AuthenticationContext,
} from "../authenticationTypes";
import { AuthenticationError } from "./AuthenticationError";
import { loginStrings } from "../strings";

export const TEMP_PASSWORD_EXPIRED_ERROR_MESSAGE =
  "Temporary password has expired and must be reset by an administrator.";

type ErrorsByCode = Record<string, ErrorsForCode>;
type ErrorsForCode = Record<string, string> & { default: string };
const errorTypesToMessages: ErrorsByCode = {
  [ErrorTypes.NotAuthorized]: {
    [Contexts.SignIn]: loginStrings.errors.invalidLoginDetails,
    [Contexts.RequestReset]: loginStrings.errors.passwordCannotBeReset,
    [Contexts.ConfirmReset]: loginStrings.errors.expiredConfirmationCode,
    [Contexts.ConfirmSignIn]: loginStrings.errors.expiredMFACode,
    [Contexts.ActivateAccount]: loginStrings.errors.incorrectActivationCode,
    default: loginStrings.errors.notAuthorizedDefault,
  },
  [ErrorTypes.UserNotFound]: {
    default: loginStrings.errors.userNotFound, // This applies for all contexts
  },
  [ErrorTypes.CodeMismatch]: {
    [Contexts.ConfirmReset]: loginStrings.errors.invalidConfirmationCode,
    default: loginStrings.errors.invalidMFACode,
  },
  [ErrorTypes.LimitExceededException]: {
    default: loginStrings.errors.limitExceededDefault,
  },
  [ErrorTypes.Unknown]: {
    default: loginStrings.errors.unknownErrorDefault,
  },
};

const errorTypesToMessagesMap: Map<string | undefined, ErrorsForCode> = new Map(
  Object.entries(errorTypesToMessages),
);

export function translateError(
  error: { code?: string; message?: string },
  context: AuthenticationContext,
): AuthenticationError {
  const errorType = errorTypesToMessagesMap.get(error.code) ? error.code : ErrorTypes.Unknown;
  // errorType cannot be undefined, if error.code is undefined, result of
  // valueFromObject will undefined, and thus errorType will be AuthenticationErrorTypes.Unknown
  const code = errorType!;
  let errorMessage = errorTypesToMessages[code][context] || errorTypesToMessages[code].default;

  // If we encounter an error type from cognito that we don't handle, and it has an error message set
  // we want to pass that through to the end user, so they get a more useful error message.
  if (code === ErrorTypes.Unknown && error.message != null) {
    errorMessage = error.message;
  }

  // Account activation is a special case:
  // For activation codes (which are actually just temp passwords) cognito will
  // return error of type NotAuthorizedException when a user enters their
  // activation code in incorrectly OR if the activation code is expired.
  // So we must parse the error message itself to correctly translate the
  // error. A bit ugly but cognito does not provide a good way to identify
  // the errors being returned >:(
  if (code === ErrorTypes.NotAuthorized && error.message === TEMP_PASSWORD_EXPIRED_ERROR_MESSAGE) {
    errorMessage = loginStrings.errors.activationCodeExpired;
  }

  return new AuthenticationError({
    errorType: errorType as AuthenticationErrorType,
    message: errorMessage,
  });
}
