// React Select style overrides
// https://react-select.com/styles
export const reactSelectStyles = {
  clearIndicator: (styles) => ({
    ...styles,
    color: "#7a7b81",
    ":hover": {
      color: "#34353C",
    },
  }),
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#f6f6f7",
    boxShadow: state.isFocused ? "0 0 0 2px #3246F0" : null,
    border: 0,
    borderRadius: "4px",
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: "8px 14px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    width: 0,
  }),
  input: (styles) => ({
    ...styles,
    fontSize: "16px",
  }),
  multiValue: (styles) => ({
    ...styles,
    fontSize: "16px",
    margin: "2px",
    padding: "6px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#7a7b81",
    ":hover": {
      color: "#34353C",
    },
  }),
  option: (styles, state) => ({
    ...styles,
    ":active": {
      backgroundColor: "#f6f6f7",
    },
    backgroundColor: state.isFocused ? "#f6f6f7" : "transparent",
    color: "#34353C",
    fontSize: "16px",
    padding: "12px",
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: "16px",
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: "16px",
  }),
  valueContainer: (styles) => ({
    ...styles,
    minHeight: "54px",
    padding: "8px 18px",
  }),
};
