import React from "react";
import { Heading, Image, Paragraph, Divider } from "@bluecrew/web-react-core";
import { Field as FormikField, ErrorMessage } from "formik";
import MultistepForm, { ImplicitValidatorProp } from "../../../components/MultistepForm";
import { ScheduleWorkers } from "../../../redux/selectors/schedule";
import { SearchableList } from "../../../components/SearchableList";
import { PageContainer } from "./styledComponents";
import { PageCopy } from "./types";

type CrewProps = {
  iconPath: string;
  pageCopy: PageCopy;
  crewData: ScheduleWorkers;
  onChange: (selectedIds: Array<string>) => void;
  validate: ImplicitValidatorProp;
  isFetchingCrewData: boolean;
};

export const ModifyCrew = ({
  crewData,
  iconPath,
  isFetchingCrewData,
  onChange,
  pageCopy,
}: CrewProps) => {
  const formattedCrewData = crewData.map((member) => ({
    id: String(member.user_id),
    name: `${member.firstName} ${member.lastName}`,
    avatar: member.profilePic || undefined,
  }));

  return (
    <PageContainer>
      {/* @ts-ignore */}
      <MultistepForm.Page>
        <Image className="modal-icon" src={iconPath} />
        <Heading as="h4">{pageCopy.header}</Heading>
        <Paragraph>{pageCopy.subheader}</Paragraph>
        <Divider />
        <FormikField
          name="workerIds"
          render={({ form: { setFieldValue, values } }) => (
            <SearchableList
              isFetchingList={isFetchingCrewData}
              // @ts-ignore
              listItems={formattedCrewData}
              noListItemsMessage="Sorry, you currently don't have any crew members."
              onChangeItem={(selectedIds) => {
                setFieldValue("workerIds", selectedIds);
                onChange(selectedIds);
              }}
              selectAllText="CREW MEMBERS"
              selectedIds={values.workerIds}
            />
          )}
        />
        <ErrorMessage
          name="workerIds"
          render={(msg) => <div className="error-message">{msg}</div>}
        />
      </MultistepForm.Page>
    </PageContainer>
  );
};
