import React from "react";
import {
  WageColumnCellWrapper,
  WageColumnCellHoursWrapper,
  WageColumnCellRateWrapper,
} from "./styledComponents";
import { calculateHours } from "../../payrollDataProcessors";
import { Can } from "../../../PermissionsContext";
import { TimesheetsShiftInfo } from "../../types/Shift.types";

export type WageColumnCellProps = {
  rowData: TimesheetsShiftInfo;
};

export const WageColumnCell = ({ rowData }: WageColumnCellProps) => {
  return (
    <WageColumnCellWrapper>
      <WageColumnCellHoursWrapper>
        {calculateHours(rowData.userShift).toFixed(2)}
      </WageColumnCellHoursWrapper>
      <Can I="view" a="wages">
        <WageColumnCellRateWrapper>@ ${rowData.job.wage}/hr</WageColumnCellRateWrapper>
      </Can>
    </WageColumnCellWrapper>
  );
};
