import React from "react";
import styled from "styled-components";
import { theme, withProp } from "styled-tools";
import { BaseStyleCss, BaseStyleProps } from "./Box";
import numberToPx from "./numberToPx";

interface ToolbarProps extends BaseStyleProps {
  role?: string;
  gutter?: number | string;
  vertical?: boolean;
}

const StyledToolbar = styled.div<ToolbarProps>`
  ${BaseStyleCss};
  position: relative;
  display: grid;
  width: 100%;
  padding: ${withProp("gutter", numberToPx)};
  grid-gap: ${withProp("gutter", numberToPx)};
  grid-template:
    "start center end"
    / 1fr auto 1fr;

  &[aria-orientation="vertical"] {
    width: min-content;
    height: 100%;
    grid-template:
      "start" 1fr
      "center" auto
      "end" 1fr;
  }

  ${theme("Toolbar")};
`;

const Toolbar = (props: ToolbarProps) => (
  <StyledToolbar aria-orientation={props.vertical ? "vertical" : "horizontal"} {...props} />
);

Toolbar.defaultProps = {
  role: "toolbar",
  gutter: 8,
};

export { Toolbar };
