import { DaysOfWeek, Weekdays } from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { SIZE } from "baseui/button-group";
import React from "react";
import { WorkDaysProps } from "../types/propTypes/ScheduleField.types";

export const ScheduleDayField = ({ value, onChange, invalid, editable = true }: WorkDaysProps) => (
  /* TODO  Add validation MESSAGE after design will be ready */
  <FieldWrapper>
    <Weekdays
      editable={editable}
      size={SIZE.large}
      selected={value}
      onChange={(val: DaysOfWeek) => onChange(val)}
    />
    <SmallText $invalid={invalid}>Work days {invalid && <span>is required</span>}</SmallText>
  </FieldWrapper>
);

const FieldWrapper = styled("div", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale200,
}));

const SmallText = styled<{ $invalid: boolean }, "p">("p", ({ $invalid, $theme }) => ({
  ...$theme.typography.font200,
  /* TODO  Add validation COLORS after design will be ready */
  color: $invalid ? "red" : "#888992",
  marginTop: "6px",
  marginBottom: 0,
}));
