import React from 'react';
import { useThemedStyletron } from '../../theme';
import { SvgPropType } from './SvgPropType';

export const CircleOutlinedIcon = (props: SvgPropType) => {
  const [, theme] = useThemedStyletron();
  const { width = 8, height = 8, stroke = theme.colors.accent } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="3.5" fill="inherit" stroke={stroke} />
    </svg>
  );
};
