import React, { useCallback, useEffect, useState } from "react";
import { useFilters, useSortBy, useTable, useRowSelect, Cell } from "react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useDispatch } from "react-redux";
import { Spinner } from "@bluecrew/web-react-core";
import { FlexRow, StyledTable, StyledTableContainer } from "./styledComponents";
import { SortingContainer } from "../../../components/ReactTable/SortingContainer";
import { TableColumns } from "./TableColumns";
import { InternalUser } from "./InternalUser";
import { SortIcon } from "./SortIcon";
import { TooltipMessage } from "../../../components/TooltipMessage";
import { USERS_THAT_CAN_ACCESS_ALL_COMPANIES } from "../constants";
import { setSelectedUser, setShowAssignCompaniesModal } from "../slices/manageUsersModalSlice";
import { useAppSelector } from "../../../redux";

type ManageUsersTableProps = {
  data: Array<InternalUser>;
  longQueryComplete: boolean;
};

export const ManageUsersTable = ({ data, longQueryComplete }: ManageUsersTableProps) => {
  const columns = React.useMemo(() => TableColumns, []);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    useRowSelect,
  );
  const dispatch = useDispatch();
  const roleFilter = useAppSelector((state) => state.manageUsersFilter.roleFilter);
  const nameFilter = useAppSelector((state) => state.manageUsersFilter.nameFilter);
  const [filteredRows, setFilteredRows] = useState(rows);

  useEffect(() => {
    const rowFiltered = rows.filter((row) => {
      if (row.original.name.toLowerCase().indexOf(nameFilter.toLowerCase()) < 0) {
        return false;
      }
      if (roleFilter.indexOf(row.original.userRole) < 0) {
        return false;
      }
      return true;
    });

    setFilteredRows(rowFiltered);
  }, [nameFilter, roleFilter, rows]);

  const rowVirtualizer = useVirtualizer({
    count: filteredRows.length,
    getScrollElement: () => document.getElementById("scrollableTableContainer") as HTMLElement,
    estimateSize: useCallback(() => 60, []),
    overscan: 20,
  });

  const items = rowVirtualizer.getVirtualItems();
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.getTotalSize() - items[items.length - 1].end : 0;

  /**
   * Concatenates company names with a limit to be displayed in tooltip
   * @param companiesInfo - array of companies info
   * @returns string - message to be displayed in tooltip
   */
  function getToolTipMessage(
    companiesInfo: [{ companyId: number; companyName: string }] | undefined,
  ) {
    if (companiesInfo === undefined) {
      return "";
    }
    const maxCompanies = 25;
    const companies = companiesInfo.slice(0, maxCompanies);
    return companies.map((company) => company.companyName).join("\n");
  }

  function onCellClick(row: InternalUser, cell: Cell<InternalUser>) {
    const disallowedColumns = ["select", "roles", "tableActions"];
    if (
      !disallowedColumns.includes(cell.column.id) &&
      !USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(row.userRole)
    ) {
      dispatch(setSelectedUser(row));
      dispatch(setShowAssignCompaniesModal(true));
    }
  }

  return (
    <StyledTableContainer id={"scrollableTableContainer"}>
      {!longQueryComplete && (
        <Spinner style={{ position: "relative", top: "50%", left: "50%", height: "50px" }} />
      )}
      {longQueryComplete && (
        <StyledTable
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          data-testid="manage-users-table"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.getHeaderGroupProps().key}
                >
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps([
                          { className: column.className },
                          column.getSortByToggleProps(),
                        ])}
                        key={column.getHeaderProps().key}
                      >
                        <FlexRow>
                          {column.render("Header")}
                          <SortingContainer isSorted={column.isSorted}>
                            <SortIcon
                              isAscending={!column.isSortedDesc}
                              canSort={!column.disableSortBy!!}
                            />
                          </SortingContainer>
                        </FlexRow>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = filteredRows[virtualRow.index];
              prepareRow(row);
              return (
                <tr
                  className={virtualRow.index % 2 === 0 ? "even" : "odd"}
                  {...row.getRowProps()}
                  key={row.original.userId}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([{ className: cell.column.className }])}
                        key={cell.column.id}
                        onClick={() => onCellClick(row.original, cell)}
                      >
                        {cell.column.className === "assigned-companies" &&
                        !USERS_THAT_CAN_ACCESS_ALL_COMPANIES.includes(row.original.userRole) ? (
                          <TooltipMessage
                            placement="left-newline"
                            message={getToolTipMessage(row.original.companiesInfo)}
                          >
                            {cell.render("Cell", {
                              rowData: { ...row.original },
                            })}
                          </TooltipMessage>
                        ) : (
                          cell.render("Cell", {
                            rowData: { ...row.original },
                          })
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      )}
    </StyledTableContainer>
  );
};
