import styled from "styled-components";
import { InputTextArea } from "../../../../../components/InputTextArea";
import { Button } from "../../../../../components/Button";
import { HoverStyledButton } from "../../../styledComponents";

export const StyledIcon = styled.img``;

export const StyledInput = styled(InputTextArea)`
  margin-top: 4px;
  width: 100%;
`;

export const StyledOkButton = styled(HoverStyledButton)`
  width: 100%;
  background: ${({ theme }) => theme.colors.accent};
`;

export const StyledCancelButton = styled(Button)`
  :enabled:hover {
    border-color: ${({ theme }) => theme.colorTokens.white};
  }
`;

export const StyledSendButton = styled(HoverStyledButton)`
  background: ${({ theme }) => theme.colors.accent};
`;

export const StyledMessageSentContent = styled.div`
  text-align: center;
`;
