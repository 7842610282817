import {
  NotificationManager,
  NotificationManagerListItem,
} from "../types/propTypes/NotificationManagerField.types";

export class NotificationManagerMapper {
  static toListItem = (manager: NotificationManager): NotificationManagerListItem => ({
    id: manager.id,
    title: manager.name,
    imgUrl: manager.imgUrl,
    identity: manager?.identity || "",
    selected: manager.selected || false,
  });

  static toModel = (listItem: NotificationManagerListItem): NotificationManager => ({
    id: listItem.id,
    name: listItem.title,
    imgUrl: listItem.imgUrl,
    identity: listItem?.identity || "",
  });
}
