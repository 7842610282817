import styled from "styled-components";

type SortingContainerProps = {
  isSorted: boolean;
};

export const SortingContainer = styled.div<SortingContainerProps>`
  margin-left: 4px;
  opacity: ${({ isSorted }) => (isSorted ? 1 : 0)};
  padding: 2px;
  :hover {
    opacity: 1;
  }
`;
