// MIN WAGE - REDUCER
// =============================================================================

import { handleActions } from "redux-actions";

import minWageActions from "../actions/minWage";

// ACTION TYPES
import { handleRequest, handleSuccess, handleFailure, initialEndpointState } from "../utility";

const handlerMap = {
  [minWageActions.readMinWage.request]: (state: MinWageState) =>
    handleRequest("readMinWage", state),
  [minWageActions.readMinWage.success]: (state: MinWageState, action) =>
    handleSuccess("readMinWage", state, action),
  [minWageActions.readMinWage.failure]: (state: MinWageState, action) =>
    handleFailure("readMinWage", state, action),
  [minWageActions.startCalc]: (state: MinWageState) => ({
    ...state,
    readMinWage: { ...initialEndpointState, isFetching: true },
  }),
};

const initialState = {
  readMinWage: { ...initialEndpointState },
};

export type MinWageState = typeof initialState;

export default handleActions<MinWageState>(handlerMap, initialState);
