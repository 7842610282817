import { Paragraph } from "@bluecrew/web-react-core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";

const BaseModalButton = styled(Button)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
  letter-spacing: 0em;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

export const CancelButton = styled(BaseModalButton)`
  background-color: ${({ theme }) => theme.colorTokens.white};
  color: ${({ theme }) => theme.colorTokens.blue400};
`;

export const RemoveButton = styled(BaseModalButton)`
  background-color: ${({ theme }) => theme.colorTokens.blue400};
`;

export const BackgroundCheckModalBodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

export const StyledDialog = styled(Dialog)`
  width: 27rem;
  background-color: ${({ theme }) => theme.colorTokens.white};
`;

export const ModalHeader = styled.div`
  font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
  text-align: center;
  font-size: 23px; // went off the button font sizes being used.
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const BodyTextNonbold = styled(Paragraph)`
  text-align: center;
  color: ${({ theme }) => theme.colorTokens.black};
  line-height: 0.5;
`;

export const BodyTextBold = styled(BodyTextNonbold)`
  font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
`;

export const ButtonWrapperWithMargin = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.typography.font150.fontWeight};
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

export const NoticeWarningImage = styled.img<{ src: string }>`
  padding-top: 1rem;
  display: block;
  margin: auto;
  width: 20%;
  height: auto;
`;

export const SettingsNameHeader = styled.div`
  font-size: 25px;
  color: ${({ theme }) => theme.colorTokens.black};
  font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
  text-align: center;
  margin-bottom: 1.5rem;
`;
