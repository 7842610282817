import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import React from "react";
import { LatLng } from "../../../types/Shift.types";

const clockInMarkerPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/clock-in-marker.svg`;
const clockOutMarkerPath = `${
  import.meta.env.VITE_ASSET_BASE_URL
}/icons/Timesheets/clock-out-marker.svg`;
/*
 * Google Maps runs 0 ~ 18 with 0 as the lowest (whole world)
 * and each step up zooming in 2x.
 */
const calculateZoom = (distance: number) => 15 - Math.log(distance / 500) / Math.log(2);

const getFarthestPunchFromWorkplace = (punches: LatLng[], workplaceAddress: LatLng) =>
  punches
    .map((punch) => getHaversineDistance(punch, workplaceAddress))
    .reduce((a, b) => (a > b ? a : b), 0);

const degreesToRadians = (degrees: number) => (degrees * Math.PI) / 180;

// https://stackoverflow.com/questions/365826/calculate-distance-between-2-gps-coordinates
const getHaversineDistance = (pointA: LatLng, pointB: LatLng) => {
  const earthRadiusM = 6371_000;

  const dLat = degreesToRadians(pointB.lat - pointA.lat);
  const dLon = degreesToRadians(pointB.lng - pointB.lng);

  const lat1 = degreesToRadians(pointA.lat);
  const lat2 = degreesToRadians(pointB.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.abs(earthRadiusM * c);
};

export type ShiftDetailsMapProps = {
  warnRadius: number;
  blockRadius: number;
  workplaceLatLng: LatLng;
  clockInPunches: LatLng[];
  clockOutPunches: LatLng[];
};
export const ShiftDetailsMap = ({
  warnRadius,
  blockRadius,
  workplaceLatLng,
  clockInPunches,
  clockOutPunches,
}: ShiftDetailsMapProps) => {
  const allPunches = [...clockInPunches, ...clockOutPunches];
  const farthestDistanceFromWorkplace = Math.max(
    blockRadius,
    getFarthestPunchFromWorkplace(allPunches, workplaceLatLng),
  );
  return (
    <GoogleMap
      zoom={calculateZoom(farthestDistanceFromWorkplace)}
      center={workplaceLatLng}
      mapContainerStyle={{
        width: "546px",
        height: "546px",
      }}
      options={{
        streetViewControl: false,
      }}
    >
      <Marker position={workplaceLatLng} />
      {clockInPunches.map((clockInPunch, idx) => (
        <Marker
          key={`clockIn-${clockInPunch.lat}-${clockInPunch.lng}-${idx}`}
          position={clockInPunch}
          icon={{ url: clockInMarkerPath }}
        />
      ))}
      {clockOutPunches.map((clockOutPunch, idx) => (
        <Marker
          key={`clockOut-${clockOutPunch.lat}-${clockOutPunch.lng}-${idx}`}
          position={clockOutPunch}
          icon={{ url: clockOutMarkerPath }}
        />
      ))}

      <Circle
        options={{
          strokeWeight: 2,
          strokeColor: "#FFA500",
          fillColor: "#00FF00",
          fillOpacity: 0.4,
          center: workplaceLatLng,
          radius: warnRadius,
          editable: false,
          draggable: false,
        }}
      />
      <Circle
        options={{
          strokeWeight: 2,
          strokeColor: "#FF0000",
          fillColor: "#FFA500",
          fillOpacity: 0.3,
          center: workplaceLatLng,
          radius: blockRadius,
          editable: false,
          draggable: false,
        }}
      />
    </GoogleMap>
  );
};
