import React, { useState } from "react";
import { ShiftAdjustModalInputText } from "../styledComponents";
import { AdjustmentReasonWrapper, SyledModal } from "../ShiftAdjust/styledComponents";
import { PayrollStatusReason, PayrollStatusReasonAndText } from "../../../types/Payroll.types";
import { ShiftAdjustHeader } from "../ShiftAdjust/ShiftAdjustHeader";
import { ShiftAdjustFooter } from "../ShiftAdjust/ShiftAdjustFooter";
import { getReasonTextFromCode } from "../../../shiftUtils";
import { ErrorText } from "../../../styledComponents";
import { TimesheetsShiftInfo } from "../../../types/Shift.types";
import { Dropdown } from "../../../../../components/Dropdown";

export type RemoveModalProps = {
  isRemoveModalVisible: boolean;
  setIsRemoveModalVisible: (visible: boolean) => void;
  headerText: string;
  footerButtonLabel: string;
  shiftInfo: TimesheetsShiftInfo;
  adjustmentCodes: Array<PayrollStatusReason>;
  removeShiftHandler: (reason: PayrollStatusReason) => unknown;
};

export const RemoveModal = ({
  isRemoveModalVisible,
  setIsRemoveModalVisible,
  headerText,
  footerButtonLabel,
  shiftInfo,
  adjustmentCodes,
  removeShiftHandler,
}: RemoveModalProps) => {
  const [reasonCodeAndText, setReasonCodeAndText] = useState<PayrollStatusReasonAndText>();
  const [error, setError] = useState<string>();

  const { job, user } = shiftInfo;

  const adjustmentCodesAndText = adjustmentCodes.map((reason) => ({
    code: reason,
    text: getReasonTextFromCode(reason),
  }));

  const getValidationMessage = () => {
    if (!reasonCodeAndText) {
      return "Adjustment reason must be present.";
    }
    return;
  };

  const confirmationHandler = () => {
    const errorMessage = getValidationMessage();
    setError(errorMessage);
    if (!errorMessage) {
      setIsRemoveModalVisible(false);
      removeShiftHandler(reasonCodeAndText?.code!); // safe after validation
    }
  };

  return (
    <SyledModal
      header={
        <ShiftAdjustHeader
          headerText={headerText}
          subHeaderText={""}
          jobDate={job.start}
          userInfo={{
            firstName: user.firstName,
            lastName: user.lastName,
            jobInfo: job,
            profilePic: user.profilePic,
          }}
        />
      }
      visible={isRemoveModalVisible}
      onHide={() => setIsRemoveModalVisible(false)}
      footer={
        <ShiftAdjustFooter
          footerButtonLabel={footerButtonLabel}
          onCancel={() => {
            setIsRemoveModalVisible(false);
          }}
          onConfirm={confirmationHandler}
        />
      }
    >
      <AdjustmentReasonWrapper>
        <ShiftAdjustModalInputText>Adjustment Reason</ShiftAdjustModalInputText>
        <Dropdown
          value={reasonCodeAndText}
          onChange={(e) => setReasonCodeAndText(e.value)}
          options={adjustmentCodesAndText}
          optionLabel={"text"}
          placeholder="Select a Reason"
        />
      </AdjustmentReasonWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </SyledModal>
  );
};
