// JOB - SELECTORS
import { createSelector } from "reselect";
// =============================================================================
import get from "lodash/get";
import { StateShape } from "../reducers";

import { getCompanyDetails } from "./company";

export const getJobTypes = (
  state: // @ts-ignore $FlowFixPossiblyWrongCode
  StateShape,
) =>
  state.job.jobTypes.success
    ? // @ts-ignore
      state.job.jobTypes.success.jobtypes
    : [];

// WARNING: The following state props must be kept in sync with how
// state gets reset in all reducers using jobActions.beginAnyJobPost.
export const getCreateAnyJobTypePendingResponse = (state: StateShape) =>
  state.crewMember.postCrewMembers.isFetching ||
  state.job.addWorkRequestToSch.isFetching ||
  state.job.createJob.isFetching ||
  state.job.createDirectInviteJobAndSchedule.isFetching ||
  state.job.createDirectInviteAndOptJobAndSchedule.isFetching ||
  state.job.createCrewJobAndSchedule.isFetching ||
  state.job.createCrewAndOptJobAndSchedule.isFetching ||
  state.job.addCrewJobToSchedule.isFetching ||
  state.job.createBlueShiftScheduleJobs.isFetching ||
  getBulkSingleShiftCreationIsPending(state);

export const getCreateAnyJobTypeSuccessResponse = (state: StateShape) =>
  state.crewMember.postCrewMembers.success ||
  state.job.addWorkRequestToSch.success ||
  state.job.createJob.success ||
  state.job.createDirectInviteJobAndSchedule.success ||
  state.job.createDirectInviteAndOptJobAndSchedule.success ||
  state.job.createCrewJobAndSchedule.success ||
  state.job.createCrewAndOptJobAndSchedule.success ||
  state.job.addCrewJobToSchedule.success ||
  state.job.createBlueShiftScheduleJobs.success;

export const getCreateAnyJobTypeFailureResponse = (state: StateShape) =>
  state.crewMember.postCrewMembers.failure ||
  state.job.addWorkRequestToSch.failure ||
  state.job.createJob.failure ||
  state.job.createDirectInviteJobAndSchedule.failure ||
  state.job.createDirectInviteAndOptJobAndSchedule.failure ||
  state.job.createCrewJobAndSchedule.failure ||
  state.job.createCrewAndOptJobAndSchedule.failure ||
  state.job.addCrewJobToSchedule.failure ||
  state.job.createBlueShiftScheduleJobs.failure ||
  getFailedBulkSingleShifts(state).length > 0;

export const getSupervisors = (state: StateShape) => state.job.supervisors.success || [];

export const getDirectInviteUserIds = (state: StateShape) => state.job.directInviteUserIds;

export const isDepartmentsEnabled = (state: StateShape) => {
  const companyDetails = getCompanyDetails(state);
  return get(companyDetails, "company.data.tag_category", false);
};

export const getPositionPendingResponse = (state: StateShape) =>
  state.job.createPosition.isFetching;

export const postCreatePositionFailure = (state: StateShape) => state.job.createPosition.failure;

export type SupervisorType = {
  id: number;
  name: string;
  imgUrl?: any; // TODO: Update this type
};

export const getSupervisorsForCrewForm = createSelector(
  getSupervisors,
  (supervisors: any): Array<SupervisorType> =>
    supervisors.map((supervisor) => ({
      id: supervisor.id,
      name: `${supervisor.first_name} ${supervisor.last_name}`,
      ...(supervisor.avatar_url && { imgUrl: supervisor.avatar_url }),
    })),
);

export const getFailedBulkSingleShifts = (state: StateShape) => state.job.failedBulkSingleShifts;

export const getSuccessfulBulkSingleShifts = (state: StateShape) =>
  state.job.successfulBulkSingleShifts;

// When creating individual single shifts this function determines whether the response from all calls to requestJob is pending.
const getBulkSingleShiftCreationIsPending = (state: StateShape) =>
  state.job.failedBulkSingleShifts.length + state.job.successfulBulkSingleShifts.length <
  state.job.numberOfBulkSingleShiftsToCreate;
