/**
 * Temporary file for all texts
 *
 * Please use the following format
 * ComponentName: {
 *   textName: "Your text"
 * }
 */
export const translations = {
  Weekdays: {
    sunday: {
      short: 'S',
      full: 'Sunday',
    },
    monday: {
      short: 'M',
      full: 'Monday',
    },
    tuesday: {
      short: 'T',
      full: 'Tuesday',
    },
    wednesday: {
      short: 'W',
      full: 'Wednesday',
    },
    thursday: {
      short: 'T',
      full: 'Thursday',
    },
    friday: {
      short: 'F',
      full: 'Friday',
    },
    saturday: {
      short: 'S',
      full: 'Saturday',
    },
  },
};
