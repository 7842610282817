// BLUECREW FULL LOGO - SVG
// =============================================================================

import * as React from "react";

export const FullLogo = ({
  color = "#3246F0",
  width = 150,
  height,
  ...props
}: {
  color?: string;
  monochrome?: boolean;
  onDark?: boolean;
  width?: number;
  height?: string;
}) => (
  <svg viewBox="0 0 115 20" width={width} height={height} {...props}>
    <title>BlueCrew Logo</title>
    <desc>BlueCrew Logo</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6729 17.0157H43.1619V2.63354H40.6729V17.0157Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.7723 15.3348C51.9779 16.5952 50.7959 17.3107 49.3187 17.3107C46.7666 17.3107 45.1616 15.8169 45.1616 13.3833V6.43237H47.6511V12.6275C47.6511 14.4711 48.6186 15.117 50.0406 15.117C51.4361 15.117 52.6616 13.5144 52.6616 12.3112V6.43237H55.1507V17.0155H52.7723V15.3348Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.4488 10.467H64.9456C64.6698 9.15234 63.6439 8.13209 62.2059 8.13209C60.7669 8.13209 59.6937 9.21057 59.4488 10.467M62.6846 17.3664C59.2271 17.3664 56.8804 14.9415 56.8804 11.7148C56.8804 8.47039 59.1619 6.08142 62.2059 6.08142C65.5137 6.08142 67.5131 8.47039 67.5131 11.7148V12.3214H59.3459C59.5519 14.1569 60.8142 15.2978 62.6846 15.2978C64.2091 15.2978 65.2703 14.7455 66.024 13.9609L67.4111 15.476C66.3317 16.5108 64.9281 17.3664 62.6846 17.3664"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.5132 16.8313C77.0027 17.1805 75.7431 17.3664 74.9688 17.3664C71.4101 17.3664 68.8857 14.9141 68.8857 11.724C68.8857 8.55232 71.4101 6.08142 74.9688 6.08142C75.7431 6.08142 76.9967 6.24803 77.5132 6.61671V8.86589C77.5132 8.86589 76.4898 8.20238 74.9688 8.20238C72.8852 8.20238 71.3728 9.71383 71.3728 11.724C71.3728 13.7518 72.8852 15.2455 74.9688 15.2455C76.5574 15.2455 77.5132 14.582 77.5132 14.582V16.8313Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.8777 17.0157H79.3892V6.43237H81.7678V8.68173C82.0474 7.80789 83.163 6.43237 84.8205 6.43237C85.4996 6.43237 85.7555 6.54737 85.7555 6.54737V9.1779C85.3863 9.07227 84.853 9.02322 84.4891 9.02322C82.812 9.02322 81.8777 10.3359 81.8777 11.4601V17.0157Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.833 10.0647L103.602 17.0156H101.113L97.5171 6.4325H100.209L102.385 13.6235L104.579 6.4325H107.216L109.373 13.6598L111.567 6.4325H114.075L110.571 17.0156H108.101L105.833 10.0647Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9691 15.209C31.0444 15.209 29.484 13.6487 29.484 11.7241C29.484 9.79933 31.0444 8.23902 32.9691 8.23902C34.8939 8.23902 36.454 9.79933 36.454 11.7241C36.454 13.6487 34.8939 15.209 32.9691 15.209M33.4246 6.09126C31.714 6.09126 30.4587 6.72648 29.5582 7.63927V2.63354H27.0691V17.0157H29.5582V15.7684C30.434 16.7137 31.7181 17.3568 33.4246 17.3568C36.6883 17.3568 38.9432 15.0514 38.9432 11.7241C38.9432 8.39665 36.6523 6.09126 33.4246 6.09126"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.1149 10.467H94.6117C94.3359 9.15234 93.31 8.13209 91.872 8.13209C90.4329 8.13209 89.3598 9.21057 89.1149 10.467M92.3506 17.3664C88.8931 17.3664 86.5464 14.9415 86.5464 11.7148C86.5464 8.47039 88.8279 6.08142 91.8719 6.08142C95.1797 6.08142 97.1791 8.47039 97.1791 11.7148V12.3214H89.012C89.2179 14.1569 90.4801 15.2978 92.3506 15.2978C93.8751 15.2978 94.9363 14.7455 95.69 13.9609L97.0771 15.476C95.9977 16.5108 94.5941 17.3664 92.3506 17.3664"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.715 6.99238C15.4882 8.09548 17.8199 7.55229 18.9232 5.77925C20.0263 4.00603 19.4831 1.67419 17.7099 0.571097C15.9366 -0.531999 13.605 0.011191 12.5019 1.78423C11.3986 3.55745 11.9418 5.88928 13.715 6.99238"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.56046 8.11346C7.62916 6.0263 5.99298 4.27861 3.90564 4.20973C1.81849 4.14103 0.0707999 5.77721 0.00209752 7.86455C-0.0667886 9.95152 1.56958 11.6994 3.65673 11.7683C5.74389 11.837 7.49157 10.2006 7.56046 8.11346"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6084 12.8827C9.76643 13.8667 9.07096 16.1576 10.055 17.9995C11.0391 19.8416 13.33 20.5371 15.1719 19.553C17.0138 18.569 17.7093 16.2779 16.7252 14.4362C15.7413 12.5941 13.4503 11.8986 11.6084 12.8827"
      fill={color}
    />
  </svg>
);
