import { Toast } from "primereact/toast";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SpinnerWithOverlay } from "@bluecrew/web-react-core";
import { SendMessageStyle, SubmitButton } from "./styledComponents";
import {
  useGetPrepareMessageQuery,
  useSendMessageMutation,
} from "../../api/bluecrew/hooks/message";

export const SendMessage = () => {
  let toExternalIds: string[];
  const [isDone, setIsDone] = useState(false);
  const [names, setNames] = useState<string[]>([]);
  const [messageBody, setMessageBody] = useState("");
  const [searchParams] = useSearchParams();

  if (searchParams.has("to") || searchParams.has("jobId")) {
    const rawTo = searchParams.get("to") || searchParams.get("jobId");
    if (!rawTo) return null;
    if (rawTo.indexOf("[") === 0) {
      toExternalIds = JSON.parse(rawTo);
      if (!toExternalIds.length) return null;
    } else {
      toExternalIds = [rawTo];
    }
  } else {
    return null;
  }

  // Get names for message recipients
  const { data: prepareMessageBodyResult, isLoading: isPrepareMessageBodyFetching } =
    useGetPrepareMessageQuery(toExternalIds);

  const toast = useRef<Toast>(null);
  const showErrorToast = (message: string) => {
    toast.current?.show({ severity: "error", summary: "Error", detail: message });
  };
  const { mutate, isLoading } = useSendMessageMutation(() => setIsDone(true), showErrorToast);

  const handleSubmit = (event: SyntheticEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    mutate({
      toExternalIds,
      messageBody,
    });
  };

  useEffect(() => {
    if (prepareMessageBodyResult) {
      setNames(prepareMessageBodyResult.recipientNames);
    }
  }, [isPrepareMessageBodyFetching, prepareMessageBodyResult]);

  const getBody = () => {
    if (isDone) {
      return <h2>Your message has been sent! This page can be closed now.</h2>;
    }
    return (
      <div>
        <div>
          <h2>
            Send a message to {names[0]}
            {names.length > 1 ? ` and ${names.length - 1} other` : ""}
            {names.length > 2 ? "s" : ""}
          </h2>
          <p>
            Write your message below. Be sure your recipient
            {names.length > 1 ? "s " : " "}
            know{names.length === 1 ? "s" : ""} how to reach you if they have any follow-ups or
            questions.
          </p>
        </div>
        <div>
          <textarea
            placeholder="Write your message here"
            value={messageBody}
            onChange={(e) => setMessageBody(e.target.value)}
          />
        </div>
        <div>
          <SubmitButton disabled={messageBody === ""} onClick={handleSubmit} type="submit">
            Send
          </SubmitButton>
        </div>
      </div>
    );
  };

  return (
    <SendMessageStyle>
      <>
        {isLoading && <SpinnerWithOverlay />}
        <Toast ref={toast} />
        <div>
          <form className="sms-form">{getBody()}</form>
        </div>
      </>
    </SendMessageStyle>
  );
};
