import { Paragraph } from "@bluecrew/web-react-core";
import React from "react";
import { links } from "../../../../shared/links";
import { geofenceStrings } from "../CompanySettingStringsEN";
import { StyledLink } from "../styledComponents";

export const PositionLevelDescriptionWithLink = () => {
  const [textBeforeLink, linkText, textAfterLink] =
    geofenceStrings.descriptions.positionLevel.split("|");
  return (
    <Paragraph>
      <span>{textBeforeLink}</span>
      <StyledLink href={links.geofence.positionLevelGeofenceHelp}>{linkText}</StyledLink>
      <span>{textAfterLink}</span>
    </Paragraph>
  );
};
