import styled from "styled-components";
import { UserAvatar } from "@bluecrew/web-react-core";
import { ActionColumnCellWrapperClassName } from "./ActionColumnCell";
import { Button } from "../../../../components/Button";

export const UserColumnStyledAvatar = styled(UserAvatar)`
  background: ${({ theme }) => theme.colorTokens.aqua100};

  > span {
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.font350.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font350.fontWeight};
    font-size: 16.6667px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.colorTokens.gray800} !important;
  }
`;

export const TimestampCellWrapper = styled.div`
  cursor: pointer;
`;

export const TimestampColumnWrapper = styled.div`
  flex-direction: column;
`;

export const ShiftDisplayWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const TimeDisplayWrapper = styled.div<{ spaced?: boolean }>`
  display: flex;
  white-space:nowrap;
  overflow: hidden;
  min-width: ${(props) => (props.spaced ? 25 : 20)}%;
`;

export const ApprovalTextDisplayWrapper = styled.div`
  display: flex;
`;

export const StyledApprovalText = styled.div`
  font-family: ${({ theme }) => theme.typography.font150.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font150.fontFamily};
  font-size: ${({ theme }) => theme.typography.font150.fontFamily};
  color: ${({ theme }) => theme.colorTokens.gray500};
`;

export const AlignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.1rem;
  text-align: center;
  align-self: center;
`;

export const JobInProgressTime = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  color: ${({ theme }) => theme.colorTokens.gray500};
`;

export const StyledTime = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  color: ${({ theme }) => theme.colorTokens.black};
`;

export const SubmittedTime = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  color: ${({ theme }) => theme.colorTokens.black};
`;

export const MissingTime = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  color: ${({ theme }) => theme.colorTokens.red400};
`;

export const DisputedShiftTime = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  color: ${({ theme }) => theme.colorTokens.gray500};
  text-decoration: line-through;
`;

export const DisputedTime = styled.div`
  font-family: ${({ theme }) => theme.typography.font100.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font100.fontWeight};
  font-size: ${({ theme }) => theme.typography.font100.fontSize};
  color: ${({ theme }) => theme.colorTokens.gray500};
  text-decoration: line-through;
`;

export const TimeApprovalText = styled.div`
  /* Approved by Bluecrew support (with Workplace edits). */

  width: 318px;
  height: 16px;

  /* Text/Bold Mini */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  text-align: center;

  /* Stone */
  color: ${({ theme }) => theme.colorTokens.gray500};

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const StyledIcon = styled.img`
  padding: 0.1em 0.1em 0.1em 0.1em;
`;
export const StyledArrow = styled.img`
  padding: 1em 1em 1em 1em;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  thead {
    background-color: ${({ theme }) => theme.colorTokens.white};
    position: sticky;
    align-self: flex-start;
    box-shadow: inset 0 -1px 0 ${({ theme }) => theme.colorTokens.gray200};
    height: 0px;
    font-family: ${({ theme }) => theme.typography.font250.fontFamily};
    font-weight: ${({ theme }) => theme.typography.font250.fontWeight};
    font-size: ${({ theme }) => theme.typography.font250.fontSize};
    line-height: ${({ theme }) => theme.typography.font250.lineHeight};
    font-style: normal;
    color: ${({ theme }) => theme.colorTokens.gray800};
    text-align: left;
    tr {
      th {
        div {
          height: 0;
        }
      }
    }
  }
  tbody {
    td {
      font-family: ${({ theme }) => theme.typography.font300.fontFamily};
      font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
      font-size: ${({ theme }) => theme.typography.font300.fontSize};
      line-height: ${({ theme }) => theme.typography.font300.lineHeight};
      font-style: normal;
      text-align: right;
      color: ${({ theme }) => theme.colorTokens.gray800};
    }
    tr {
      height: 80px;
      border-top: 1px solid ${({ theme }) => theme.colorTokens.gray200};
      border-bottom: 1px solid ${({ theme }) => theme.colorTokens.gray200};
    }
  }
`;

export const RowAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CollapseHeaderWrapper = styled.div`
  height: 32px;
  flex-direction: row;
  display: flex;
  align-items: center;

  /* Crystal */

  background: #f2f3f7;
  border-top: 1px solid #dedfe8;
`;

export const CollapseDateWrapper = styled.div`
  width: 940px;
  height: 16px;
  padding-left: 16px;

  /* Text/Bold Mini */

  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Slate */

  color: ${({ theme }) => theme.colorTokens.gray600};
`;

export const CollapseHoursWrapper = styled.div`
  width: 176px;
  height: 16px;
  left: 949px;
  top: 8px;

  /* Text/Bold Mini */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: right;

  /* Slate */

  color: ${({ theme }) => theme.colorTokens.gray500};
`;

export const CollapseButtonWrapper = styled.div`
  justify-content: right;
  color: ${({ theme }) => theme.colorTokens.blue400};
  padding-right: 16px;
  padding-left: 186px;
`;

export const WageColumnCellWrapper = styled.div`
  float: right;
  padding: 0px;
`;

export const WageColumnCellHoursWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.font550.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font550.fontWeight};
  font-size: ${({ theme }) => theme.typography.font550.fontSize};
  line-height: ${({ theme }) => theme.typography.font550.lineHeight};
  font-style: normal;
  text-align: right;
  color: ${({ theme }) => theme.colorTokens.gray800};
`;

export const WageColumnCellRateWrapper = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorTokens.gray500};
`;

export const ActionColumnCellWrapper = styled.div`
  flex-direction: row;
  display: flex;
  gap: 8px;
  justify-content: right;
  padding-right: 16px;
  opacity: 0; // Is set to be visible on :hover TableStageRow.StyledRow
`;

export const ActionColumnButtonWrapper = styled.div``;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  margin-top: 24px;
  margin-bottom: 44px;
  border-top: 1px solid ${({ theme }) => theme.colorTokens.gray200};
`;

export const ShiftAdjustModalInputText = styled.div`
  font-family: ${({ theme }) => theme.typography.font300.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font300.fontWeight};
  font-size: ${({ theme }) => theme.typography.font300.fontSize};
  line-height: ${({ theme }) => theme.typography.font300.lineHeight};
  font-style: normal;
  color: ${({ theme }) => theme.colorTokens.black};
  padding-bottom: 5px;
`;

export const ReasonCellContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const ReasonCellReasonTextWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.font200.fontFamily};
  font-weight: ${({ theme }) => theme.typography.font200.fontWeight};
  font-size: ${({ theme }) => theme.typography.font200.fontSize};
  line-height: ${({ theme }) => theme.typography.font200.lineHeight};
  font-style: normal;
  color: ${({ theme }) => theme.colorTokens.gray500};
  text-align: left;
`;

export const StyledRow = styled.tr`
  .user-column {
    width: 25%;
  }

  .time-column {
    width: 40%;
  }

  .actions-column {
    width: 186px;
  }

  .wage-column {
    padding-right: 32px;
  }
  
  :hover {
    background-color: #F2F3F7;
  }

  :hover .${ActionColumnCellWrapperClassName} {
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 1s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const StyledTooltipButton = styled(Button)`
  padding: 8px;
  border-radius: 36px;
  justify-content: center;
  background: ${({ theme }) => theme.colorTokens.white};
  font-family: ${({ theme }) => theme.typography.font100.fontFamily};
  font-size: ${({ theme }) => theme.typography.font100.fontSize};
  font-weight: ${({ theme }) => theme.typography.font100.fontWeight};
  line-height: ${({ theme }) => theme.typography.font100.lineHeight};
  font-style: normal;
`;

export const StyledTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 160px;
`;

export const StyledTooltipMessage = styled.span`
  text-align: center;
`;
