import React from "react";
import { InputText } from "../../../../components/InputText";

type SearchBarProps = {
  onChange: (arg: string) => void;
};

export const SearchBar = ({ onChange }: SearchBarProps) => (
  <span className="p-input-icon-left">
    <i className="pi pi-search" />
    <InputText
      type="text"
      placeholder="Search for companies"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
    />
  </span>
);
