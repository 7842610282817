import React, { useState } from "react";
import { CompanyAvatar, UserAvatar } from "@bluecrew/web-react-core";
import OutsideClickHandler from "react-outside-click-handler";
import SwitchCompanyModal from "../SwitchCompanyModal";
import { StyledCompanyName, StyledContainer, StyledSwitchTrigger } from "./styledComponents";
import { DropdownItems } from "./DropdownItems";
import { useLogout } from "../../containers/Main/hooks";

export type MenuItemShape = {
  title: string;
  icon: string;
  url: string;
  key: string;
  canIpermission?: { I: string; a: string };
};

interface AccountDropdownProps {
  assetPath: string;
  canSwitchCompanies?: boolean;
  companyLogoUrl: string;
  companyName: string;
  menuItems: Array<MenuItemShape>;
  userCompanies?: Array<Object>;
  userEmail: string;
  userFullName: string;
  userImage: string;
  showNotificationIcon?: boolean;
}

export const AccountDropdown = ({
  assetPath,
  canSwitchCompanies,
  companyLogoUrl,
  companyName,
  menuItems,
  userCompanies,
  userEmail,
  userFullName,
  userImage,
  showNotificationIcon = false,
}: AccountDropdownProps) => {
  const [open, setOpen] = useState(false);
  const [showCompaniesModal, setShowCompaniesModal] = useState(false);

  const toggleMenu = () => {
    setOpen((prevState) => !prevState);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const handleV1Redirect = (url: string) => {
    window.location.href = url;
  };

  const showSwitchCompanyModal = () => {
    setOpen(false);
    setShowCompaniesModal(true);
  };

  const handleSwitchModalClose = () => {
    setOpen(false);
    setShowCompaniesModal(false);
  };

  const logout = useLogout();

  return (
    <>
      {showCompaniesModal && (
        <SwitchCompanyModal
          show
          companyLogoUrl={companyLogoUrl}
          onModalClose={handleSwitchModalClose}
          userEmail={userEmail}
        />
      )}
      <OutsideClickHandler onOutsideClick={closeMenu}>
        <StyledContainer assetPath={assetPath}>
          <div className="user-container">
            {showNotificationIcon && (
              <img className="notification-icon" src={`${assetPath}/alarm-bell.svg`} alt="" />
            )}
            <div className="account-container" onClick={toggleMenu}>
              <div className="user-info">
                <span className="user-name">{userFullName}</span>
                <span className="company-name">{companyName}</span>
              </div>
              <div className="avatar-container">
                <UserAvatar classes="user-avatar" imgSrc={userImage} fullName={userFullName} />
              </div>
            </div>
          </div>

          {open && (
            <div className="dropdown">
              <div className="company-info">
                <CompanyAvatar imgSrc={companyLogoUrl} classes="company-logo" />
                <div className="company-text">
                  <StyledCompanyName>{companyName}</StyledCompanyName>
                  {canSwitchCompanies && userCompanies && userCompanies.length > 0 && (
                    <StyledSwitchTrigger onClick={showSwitchCompanyModal}>
                      Switch company
                    </StyledSwitchTrigger>
                  )}
                </div>
              </div>
              <ul>
                <DropdownItems
                  items={menuItems}
                  handleRedirect={handleV1Redirect}
                  closeMenu={closeMenu}
                />
                <hr className="styled-hr" />
                <li onClick={logout}>
                  <i className="icon log-out-icon" />
                  <span>Log out</span>
                </li>
              </ul>
            </div>
          )}
        </StyledContainer>
      </OutsideClickHandler>
    </>
  );
};
