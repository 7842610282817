import React from "react";
import { Image } from "@bluecrew/web-react-core";
// @ts-ignore
import InfoIcon from "@bluecrew/web-react-core/src/assets/icons/infoIcon.svg";
import { TooltipMessage } from "../TooltipMessage";
import { ToolTipContainer, ToolTipText } from "./styledComponents";

export const PositionAutoRefillToolTip = () => (
  <ToolTipContainer>
    <ToolTipText>Continuous fill</ToolTipText>
    <TooltipMessage
      placement="top"
      relativeToParentElem
      message="Increase fill rates by continuing to fill openings even after jobs have
        started. Openings will refill starting on the next scheduled shift."
    >
      <Image src={InfoIcon} />
    </TooltipMessage>
  </ToolTipContainer>
);
