import React from "react";
import PropTypes from "prop-types";
import { Image } from "@bluecrew/web-react-core";
import styled from "styled-components";

// @ts-ignore
import defaultAvatar from "../assets/icons/default-company-avatar.svg";

const sizes = {
  sm: 32,
  md: 48,
  lg: 64,
  xl: 80,
};

const StyledAvatar = styled(Image)`
  width: ${(props) =>
    sizes[
      // @ts-ignore
      props.size
    ]}px;
  border-radius: 0.25rem;
`;

export const CompanyAvatar = (props) => {
  return (
    <div className={props.classes}>
      <StyledAvatar
        {...props}
        src={props.imgSrc.length ? props.imgSrc : defaultAvatar}
        data-testid="company-logo"
        alt="logo"
      />
    </div>
  );
};

CompanyAvatar.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  classes: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
};

CompanyAvatar.defaultProps = {
  classes: null,
  imgSrc: "",
  size: "sm",
};
