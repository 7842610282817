import { Button } from "primereact/button";
import styled from "styled-components";
import { assetUrl } from "../../../api/bluecrew/constants";

export const StyledDiv = styled.div`
  display: flex;
`;

const BulkUserActionButton = styled(Button)`
  margin-right: 20px;
  height: 36px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 6px;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  justify-content: right;
  background-repeat: no-repeat;
  background-position: 16px center;
`;

export const AddPermissionsButton = styled(BulkUserActionButton)`
  width: 180px;
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
  border-color: transparent;
  background-image: url(${assetUrl}/icons/ManageUsers/add-permissions-icon.svg);
`;

export const StyledCopyEmailsButton = styled(BulkUserActionButton)`
  width: 208px;
  background-color: ${({ theme }) => theme.colorTokens.white};
  color: ${({ theme }) => theme.colorTokens.blue400};
  border-color: ${({ theme }) => theme.colorTokens.blue400};
  background-image: url(${assetUrl}/icons/ManageUsers/copy-emails-icon.svg);
`;

export const ResendInvitesButton = styled(BulkUserActionButton)`
  width: 180px;
  background-color: ${({ theme }) => theme.colorTokens.blue400};
  color: ${({ theme }) => theme.colorTokens.white};
  border-color: transparent;
`;
