// MINIMUM WAGE - API
// =============================================================================
import { api } from "./constants/index";

interface Wage {
  wage: number;
}

export const readMinWage = ({ state, county, city, date }): Promise<Wage> =>
  api.get(`minimum_wages?date=${date}&state=${state}&county=${county}&city=${city}`).json();
