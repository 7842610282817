import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalKind,
  Select,
  getPadding,
  useThemedStyletron,
} from "@bluecrew/blueprint-web";
import { useController } from "react-hook-form";
import { styled } from "baseui";
import { Option } from "baseui/select";
import { ModalFooterWrapper } from "../ModalFooterWrapper";
import { FieldLabel } from "../FieldLabel";
import { DepartmentMapper } from "./DepartmentMapper";
import {
  DepartmentListItem,
  DepartmentsFieldProps,
  isNewDepartment,
  NewDepartment,
} from "../types/propTypes/DepartmentField.types";
import { Spacer, SpacerType } from "../../../components/Spacer";

export const DepartmentField = ({
  control,
  trigger,
  departments = [],
  mixed = false,
}: DepartmentsFieldProps) => {
  const fieldName = "department";
  const validate = trigger.bind(null, fieldName);

  const [isMixed, setIsMixed] = useState(mixed);

  const newDepartmentListItem: NewDepartment = {
    id: -1,
    label: "+ New Department",
    isNewDepartment: true,
  };

  const {
    field: { onChange, value },
    meta: { invalid },
  } = useController({
    name: fieldName,
    control,
    rules: { required: true },
  });

  const getListItems = () =>
    departments.map((department) => DepartmentMapper.toListItem(department));

  const [newDepartment, setNewDepartment] = useState<string>("");
  const [isModalOpen, setIsOpen] = React.useState<boolean>(false);

  const [departmentOptions, setDepartmentOptions] = useState<Array<DepartmentListItem>>(
    getListItems(),
  );

  const setSelectedValue = async (selected: DepartmentListItem) => {
    if (!selected) {
      onChange(null);
      return;
    }

    if (isNewDepartment(selected)) {
      setNewDepartment("");
      setIsOpen(true);
      return;
    }
    onChange(DepartmentMapper.toModel(selected));
    await validate();
  };

  const close = () => {
    setIsOpen(false);
    return validate();
  };

  const addNewDepartment = async () => {
    if (!newDepartment.trim().length) {
      setNewDepartment("");
      return;
    }

    const department: NewDepartment = {
      id: departmentOptions.length + 1,
      label: newDepartment.trim(),
    };

    onChange(DepartmentMapper.toModel(department));
    setDepartmentOptions([department, ...getListItems()]);
    await close();
  };

  const [, theme] = useThemedStyletron();

  return (
    <>
      <FieldContainer>
        <FieldLabel>Department</FieldLabel>
        <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale300} />
        <Select
          placeholder={isMixed ? "Mixed" : "Choose a department"}
          error={invalid}
          value={value && [DepartmentMapper.toListItem(value)]}
          options={[newDepartmentListItem, ...departmentOptions]}
          onChange={(val: ReadonlyArray<Option>) => {
            if (isMixed) setIsMixed(false);
            setSelectedValue(val[0] as DepartmentListItem);
          }}
          maxDropdownHeight="150px"
        />
        <Modal
          header="New Department"
          kind={ModalKind.DEFAULT}
          body={
            <InputWrapper>
              <Input
                placeholder="Department name"
                error={!newDepartment}
                onChange={(text) => setNewDepartment(text)}
              />
            </InputWrapper>
          }
          footer={
            <ModalFooterWrapper>
              <Button width="100%" onClick={addNewDepartment}>
                Done
              </Button>
            </ModalFooterWrapper>
          }
          isOpen={isModalOpen}
          onClose={close}
        />
      </FieldContainer>
    </>
  );
};

const FieldContainer = styled("div", () => ({
  width: "329px",
}));

const InputWrapper = styled("div", ({ $theme }) => ({
  ...getPadding($theme.sizing.scale600),
}));
