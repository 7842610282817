import { ChevronDownIcon } from "@bluecrew/blueprint-web";
import React from "react";
import { useParams } from "react-router-dom";
import { Supervisor } from "../AddShiftForm.types";
import {
  CreateShiftScreenText,
  StyledDropdown,
  SupervisorSelectContainer,
} from "../styledComponents";
import { useGetSupervisorsQuery } from "../../../../../api/bluecrew/hooks/job";

export type SupervisorSelectFieldProps = {
  supervisor?: Supervisor;
  setSupervisor: (s: Supervisor) => void;
};
export const SupervisorSelectField = ({
  supervisor,
  setSupervisor,
}: SupervisorSelectFieldProps) => {
  const companyId = useParams().internalCompanyId!!;
  const { data: supervisors, isLoading: isSupervisorsLoading } = useGetSupervisorsQuery(companyId);

  return (
    <SupervisorSelectContainer>
      <CreateShiftScreenText>Supervisor</CreateShiftScreenText>
      <StyledDropdown
        value={supervisor}
        dropdownIcon={ChevronDownIcon}
        onChange={(e) => setSupervisor(e.value)}
        disabled={isSupervisorsLoading}
        options={supervisors || []}
        optionLabel="name"
        placeholder="Select a supervisor"
      />
    </SupervisorSelectContainer>
  );
};
