/* eslint-disable camelcase */
// BLUECREW API - TYPES
// =============================================================================
import type { Moment } from "moment";
import { KIND } from "baseui/toast";
import { ScheduleType } from "../../../containers/GetCrewMembers/types/propTypes/Schedule.types";
import { SiteDesignation } from "../../../containers/GetCrewMembers/types/propTypes/CreatePositionScreen.types";

enum DayNum {
  SUNDAY = "0",
  MONDAY = "1",
  TUESDAY = "2",
  WEDNESDAY = "3",
  THURSDAY = "4",
  FRIDAY = "5",
  SATURDAY = "6",
}

export enum UserType {
  BLUECREW = 0,
  ADMIN = 1,
  CLIENT_ADMIN = 2,
  CLIENT = 3,
  MANAGER = 4,
  BRANCH_OPS = 5,
  BRANCH_VIS = 6,
  PAYROLL_ADMIN = 7,
  SUPER_ADMIN = 8,
}

enum UserStatus {
  STARTED_SIGN_UP = 0,
  ELIGIBLE = 1,
  ENTERED_INTERESTS = 2,
  ENTERED_GENERAL_INFO = 3,
  SIGNED_CONTRACT = 4,
  ENTERED_BACKGROUND_CHECK_INFO = 5,
  RUNNING_BACKGROUND_CHECK = 6,
  PENDING = 7,
  SUSPENDED = 8,
  FIRED = 9,
  ELIGIBLE_EOR = 10,
  ENTERED_CLIENT_INFO = 11,
  ENTERED_TAX_INFO = 12,
  PASSED_INTERVIEW = 13,
  FAILED_INTERVIEW = 14,
  FOLLOW_UP_INTERVIEW = 15,
  NO_SHOW_INTERVIEW = 16,
  DOUBLE_PROFILE = 17,
  PAUSED_PROFILE = 18,
  DELETED_CLIENT = 19,
  FAILED_BACKGROUND_CHECK = 20,
  NEEDS_I9 = 21,
  REGION_WAITLIST = 22,
  NEEDS_CREWMEMBER_I9 = 23,
  NEEDS_I9_AGENT = 24,
}

export enum ReasonCode {
  JOB_DESCRIPTION_OR_DETAILS_CHANGED = 0,
  START_OR_END_DATE_CHANGED = 1,
  SCHEDULE_CHANGED = 2,
  NO_LONGER_NEEDED = 3,
  OTHER = 4,
}

export type TokenRequest = {
  audience?: Array<string | typeof undefined | null>;
};

export type TokenMap = {
  "bc.jwt"?: string | typeof undefined;
  "bc.bearer"?: string | typeof undefined;
};

export type APITokenMap = {
  v1: string;
  v2: string;
};

export type User = {
  address_latitude: null | number;
  address_longitude: null | number;
  active_at: string;
  auth: {
    auth_token_hash: string;
    created_at: string;
    id: number;
    user_id: number;
    updated_at: string;
  }[];
  checkr_id: string;
  checkr_dmv_id: null | string;
  company_id: number;
  created_at: string;
  eor: number;
  email_cadence: number;
  external_id: string;
  data: {
    avatar: {
      created_at: string;
      external_id: string;
      id: number;
      updated_at: string;
      url: string;
    };
    first_name: string;
    full_name: string;
    last_name: string;
    profile_picture_id: number;
  };
  first_job_date: null | string;
  id: number;
  interviewed: number;
  level: number;
  level_status: number;
  normalized_first_name: string;
  normalized_last_name: string;
  phone_number: string;
  points: number;
  referred_by_user_id: null | number;
  referral_code: string;
  region_id: number;
  roles: Record<string, unknown>[];
  settings: Record<string, unknown>[];
  status: UserStatus;
  team_lead: number;
  type: UserType;
  updated_at: string;
  username: string;
  vensure_id: null | string;
};

export type SessionResponse = User;

export type LoginRequest = {
  username: string;
  password: string;
  audience: any | null | undefined;
};

type Permission = {
  name: string;
  slug: string;
  entity: string;
  action: string;
};

export type TokenResponse = {
  tokens: APITokenMap;
  permissions: Permission[];
  user: User;
};

export type TokenExchangeResponse = TokenResponse;

// TODO: verify this response
export type TokenBearerResponse = TokenResponse;

export type CancelScheduleRequest = {
  jobId: string;
  reasonCode?: ReasonCode;
  reasonText?: string;
};

export type RejectApplicant = {
  data: {
    userId: number;
    jobId: number;
  };
  scheduleId: number;
};

export type RejectApplicantAction = {
  type: string;
  payload: RejectApplicant;
};

export type PostJobRequest = {
  data: {
    schedule_nickname: string;
    end_date_time: string;
    start_date_time: string;
  };
};

export type PostPositionRequest = {
  data: {
    company_id: number; // TODO: remove once endpoint is updated to get company id on back-end
    is_cloneable: number; // future-proofing - leave for now
    address_id: number; // TODO: remove once endpoint is updated to select or create address
    address: number;
    requirements: string;
    title: string;
    description: string;
    job_type: string;
    dress_code: string;
    rate: string;
    arrival_instructions: string;
    background_check_required: number;
    drug_screen_required: number;
  };
};

export type PositionRequest = {
  address: string;
  base_pay: string;
  dress_code_required: boolean;
  job_type: string;
  position_description: string;
  requirements: string;
};

type Shift = {
  day_of_week: DayNum;
  start_time: string;
  end_time: string;
};

type WorkRequest = {
  job_id: number;
  external_id: string;
  supervisor_id: number | null;
  supervisor_user_id: number;
  workers_needed: number;
  workers_accepted: number;
  jobStartDate: string;
  jobEndDate: string;
  nickname: string;
  work_request_id: number;
  schedule_id: number;
  shifts: Array<Shift>;
};

// This is a partial schedule object.
// TODO: make this type more explicit to the endpoints that reference it.
export type Schedule = {
  active: ScheduleStatus;
  jobs: number[];
  id: number;
  schedule_type: ScheduleType;
  name: string;
  start_time: string;
  end_time: string;
  start_time_military: string;
  end_time_military: string;
  filled: number;
  needed: number;
  applications_to_review: number;
  shifts: Array<Shift>;
  supervisor_user_id?: number;
  base_wage: string | number;
  end_date_time: string; // "2023-01-18T01:00:00.000Z"
  start_date_time: string; // "2023-01-18T01:00:00.000Z"
  job_updates: string;
  timezone: string;
  tag_id: number | null;
  total_work_requests: {
    total_request: number;
    this_week: number;
    filled: number;
  };
  position_id: number;
  work_request_name: string;
  supervisor: {
    avatar_url: string;
    full_name: string;
    phone: string;
    user_external_id: string;
    user_id: number;
  };
};

type ScheduleSegment = {
  id: number;
  day_of_week: string;
  start_time: string;
  end_time: string;
  active: number;
  schedule_id: number;
};

// These must be kept in sync with:
// https://github.com/Bluecrew/api-v2/blob/develop/app/lib/enums/databaseEnums.js#L32-L36
// https://github.com/Bluecrew/api-v1/blob/develop/src/server/internal/enums.js#L160-L164
export const SCHED_EXC_DAY_OFF = 0; // the day was removed
export const SCHED_EXC_ADD_DAY = 1;
export const SCHED_EXC_EDIT_DAY = 2;

export type ScheduleExceptionType =
  | typeof SCHED_EXC_DAY_OFF
  | typeof SCHED_EXC_ADD_DAY
  | typeof SCHED_EXC_EDIT_DAY;

export type ScheduleException = {
  id: number;
  jobId: number;
  // Both startDateTime and endDateTime are UTC dates.
  startDateTime: string;
  endDateTime: string;
  type: ScheduleExceptionType;
  scheduleId: number;
};

type UserAppearance = {
  preselected: boolean;
  is_cert: boolean;
  order: number;
  is_locked: boolean;
  information: string;
};

export type UserRequirement = {
  id: number;
  label: string;
  scope_type: "Global" | "Company";
  scope_id?: number;
  appearance: UserAppearance;
};

export type UserCertification = {
  id: number;
  label: string;
  scope_type: "Global" | "Company";
  scope_id?: number;
  appearance: UserAppearance;
  information?: string;
};

export type JobType = {
  backup_job_type?: number;
  code: number;
  created_at?: string;
  description: string;
  enabled: boolean;
  icon_name: string;
  id: number;
  image_url: string;
  name: string;
  rate: number;
  requirements: string[];
  updated_at?: string;
  users_requirements: UserRequirement[];
  users_certs: UserCertification[];
};

export type JobTypeResponse = {
  jobtypes: JobType[];
};

export type ScheduleExceptionsResponse = {
  scheduleexceptions: ScheduleException[];
};

type Address = {
  display_address: string;
  id: number;
  meta: {
    google_place_id?: string;
  } | null;
  name: string;
  country_code: string;
  country_area: string;
  coords: {
    type: string;
    coordinates: number[];
  };
  city: string;
  city_area: null | string;
  street_address: string;
  street_address_two: null | string;
  postal_code: string;
  sorting_code: string | null;
  latitude: string;
  longitude: string;
  timezone: string;
  created_at: string;
  updated_at: string;
};

type Job = {
  id: number;
  is_current_week: boolean;
  external_id: string;
  company_id: number;
  poster_id: number;
  status: number;
  supervisor_id: null | number;
  supervisor_user_id: number;
  address_id: number;
  position_id: number;
  workers: Array<Object>;
  data: {
    is_short_notice: any;
    needed: number;
    job_template_id: number;
    is_application: number;
  };
  schedules: Array<Schedule>;
  scheduleSegments: Array<ScheduleSegment>;
};

type PositionRequestStatsItem = {
  fillPercentage: number;
  filled: number;
  needed: number;
};

export type PositionRequestStats = {
  today: PositionRequestStatsItem;
  sevenDays: PositionRequestStatsItem;
  fourteenDays: PositionRequestStatsItem;
  all: PositionRequestStatsItem;
};

export type DashboardPositionHeaderDetail = {
  position_id: number;
  title: string;
  refill_on: boolean;
  // This is the job type name.
  job_type_icon_name: string;
  requestStats: PositionRequestStats;
  has_applications_to_review: boolean;
  display_address: string;
  zipcode: number;
  job_type: JobType;
  requirements: Array<string>;
};

type DashboardScheduleRequest = {
  schedule_id: number;
  type: number;
  request_id: number;
  applications_to_review: number;
  direct_hire: number;
  start_date: string;
  end_date: string;
  start_date_time: string;
  end_date_time: string;
  shifts: number;
  filled: number;
  needed: number;
  supervisor: string;
  supervisor_phone_number: string;
  external_id: string;
  start_time: string;
  end_time: string;
};

type DashboardSchedule = {
  schedule_id: number;
  name: string;
  start_time: string;
  start_time_military: string;
  end_time: string;
  end_time_military: string;
  start_date: string;
  end_date: string;
  start_date_time: string;
  end_date_time: string;
  base_wage: number;
  active: ScheduleStatus;
  type: number;
  applications_to_review: number;
  direct_hire: number;
  shifts: number;
  filled: number;
  needed: number;
  work_days: Array<string>;
  supervisor: string;
  supervisor_phone_number: string;
  requests: Array<DashboardScheduleRequest>;
  position_id: number;
};

type ComputedDashboardRowData = {
  shifts_filled: number;
  shifts_needed: number;
};

export type DashboardScheduleRowData = DashboardSchedule & ComputedDashboardRowData;

type BasePosition = {
  address: Address;
  base_wage: string;
  code: number;
  company_id: number;
  id: number;
  job_template_id?: number; // It looks like this gets removed with blueshift
  rate: number;
  requirements: Array<string>;
  schedules: Array<Schedule>;
  timezone: string;
  title: string;
  work_requests: Array<WorkRequest>;
};

// This is a position object returned by the listing endpoint. It does not
// contain all fields.
export type ListedPosition = BasePosition & {
  dressCode: string | null;
  // This is the job type name.
  jobType: string;
  jobTypeDescription: string;
  jobTypeIcon: string;
  minLunchDuration: number;
  // The getPositionsNoDuplicateWorkRequests selector mutates the position
  // object, adding this property.
  work_request_no_duplicate?: Array<WorkRequest>;
  refill_on: boolean;

  display_address: string;
  has_applications_to_review: boolean;
  position_id: number;
  requestStats: PositionRequestStats;
  job_type_icon_name: string;
  job_type: number;
  created_at: string;
};

type WorkRequestStat = {
  filled: number;
  needed: number;
  unfilledRequests: number;
  fillPercentage: number;
};

type WorkRequestStatSummary = {
  sevenDays: WorkRequestStat;
  fourteenDays: WorkRequestStat;
  thirtyDays: WorkRequestStat;
};

export type ListedPositionResponse = {
  positions: Array<ListedPosition>;
  workRequestStats: WorkRequestStatSummary;
};

// This is a position object returned by the detail endpoint. For brevity,
// some unused fields are not defined.
export type PositionDetail = BasePosition & {
  JobType: JobType;
  Jobs: Array<Job>;
  active: number;
  additional_description: string;
  address_id: 415;
  arrival_instructions: string;
  created_at: string;
  deleted_at: string | null;
  description: string;
  dress_code: string | null;
  job_type: number;
  wage_floor: string;
  site_designation: SiteDesignation;
  min_warn_radius: number;
  min_block_radius: number;
  background_check_required: number;
  drug_screen_required: number;
};

export type Supervisor = {
  id: number;
  external_id: string;
  username: string;
  first_name: string;
  last_name: string;
  company_id: number;
  type: number;
  phone_number: string;
};

export type UserDetail = User & {
  approvalRating: null | number;
  dont_sendor: null | boolean;
  favorite: number;
  firstName: string;
  lastName: string;
  lastShift: null | string;
  negative_count: number;
  neutral_count: number;
  phoneNumber: string;
  profilePic: null | string;
  ratingId: null | string;
  shiftsWorked: null | number;
  total_shift_count: null | number;
  user_id: null | number;
};

export type SupervisorsResponse = Array<Supervisor>;

type AddWorkRequestToSchPayloadData = {
  // TODO: require this parameter when removing the old
  // request additional crew modal
  is_application?: 1 | 0;
  end_date: string;
  job_template_id?: number;
  position_id: number;
  start_date: string;
  supervisor_user_id: number;
  workers_needed: number;
  blueshift_request_id?: number;
};

export type AddWorkRequestToSchPayload = {
  controlModals?: boolean;
  scheduleId: number;
  payload: AddWorkRequestToSchPayloadData;
};

export type ReadPositionRequest = {
  positionId: number;
};

export type ReadScheduleWorkersRequest = {
  scheduleId: number;
};

export type CreateScheduleExceptionRequest = {
  scheduleId: number;
  payload: {
    timezone: string;
    addDays: Array<Object>;
    removeDays: Array<Object>;
    worker_ids: Array<string>;
  };
};

export enum ScheduleStatus {
  ARCHIVED,
  ACTIVE,
  PINNED,
}

export type UpdateScheduleRequest = {
  scheduleId: number;
  positionId?: string | number;
  payload: Partial<{
    timezone: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    work_days: Array<string>;
    base_wage: number;
    schedule_name: string;
    supervisor_user_id: number;
    workers_needed: number;
    active: ScheduleStatus;
  }>;
};

export type GetSegmentIdentifyTraitsRequest = {
  userId: number;
};

export type OpenApplicationJobsToPlatformRequest = {
  scheduleId: number;
};

type JobAndSchedData = {
  base_wage: string;
  endDate: string;
  needed: number;
  position_id: number;
  schedule_name: string;
  startDate: string;
  supervisor: number;
  work_days: Array<string>;
  tag_id?: number;
  blueshift_request_id?: number;
};

export type CreateDirectInviteJobAndSchedPayload = {
  data: JobAndSchedData & {
    userIds: Array<string>;
  };
};
export type CreateDirectInviteAndOptJobAndSchedPayload = {
  data: JobAndSchedData & {
    userIds: Array<string>;
  };
  newDepartment?: string;
  companyId?: number;
  additionalData?: AddWorkRequestToSchPayloadData;
};

type CrewJobData = {
  crewIds: Array<number>;
  favorites?: boolean;
  userIds?: Array<string>;
};

export type CreateCrewJobAndSchedPayload = {
  data: JobAndSchedData & CrewJobData;
};
export type CreateCrewAndOptJobAndSchedPayload = {
  data: JobAndSchedData & CrewJobData;
  newDepartment?: string;
  companyId?: number;
  additionalData?: AddWorkRequestToSchPayloadData;
};

export type AddCrewJobToSchedulePayload = {
  scheduleId: number;
  data: CrewJobData & {
    endDate: string;
    jobTemplateId: number;
    needed: number;
    startDate: string;
    supervisor: number;
    work_days: Array<string>;
  };
};

type Company = {
  id: number;
  external_id: string;
  normalized_name: string;
  parent_company_id: null | number;
  account_manager: null | number;
  beta_active: number;
  can_post: number;
  refill_on: number;
  region_id: number;
  active: number;
  project_number: null | string;
  hubspot_id: null | string;
  created_at: string;
  updated_at: string;
  data: {
    min_lunch_duration: number;
    company_id: number;
    address: string;
    address_latitude: number;
    address_longitude: number;
    avg_weekly_workers: null | number;
    city: string;
    description: null | string;
    eor: number;
    flex_forty: number;
    job_description: null | string;
    job_type: null | number;
    logo_id: number;
    name: string;
    child_descriptor: null | string;
    net_pay: number;
    markup: string;
    pricing_type: number;
    roster_headers: null | string;
    signed_contract: number;
    source: number;
    state: string;
    temp_to_hire: null | string;
    terminal_mode: number;
    use_case: null | string;
    zipcode: string;
    segment: null | string;
    quickbooks_id: null | string;
    late_tolerance: null | string;
    late_limit: null | string;
    absence_limit: null | string;
    early_clockin_buffer: null | string;
    late_clockout_buffer: number;
    top_company: number;
    tag_category: null | string;
    hide_jobs_sneak_peek: number;
    hide_logo_sneak_peek: number;
    logo: {
      id: number;
      external_id: string;
      url: string;
      created_at: string;
      updated_at: string | null;
    };
    min_offsite_block_radius: number | null;
    min_offsite_radius: number;
    min_offsite_warn_radius: number | null;
    min_onsite_block_radius: number | null;
    min_onsite_warn_radius: number | null;
    disable_radii_use: boolean;
    auto_approval_on: boolean;
    covid_policy: string;
    background_check_required: number;
    background_check_active: boolean;
    drug_screen_required: number;
    drug_screen_active: boolean;
    third_party_timetracker: unknown;
    phone_number: number | null;
  };
  isUserCurrentCompany: boolean;
};

export type CompanyResponse = {
  company: Company;
};

export type CompanyId = number;
export type UserId = number;

/**
 * Represents basic information about a company.
 *
 * @typedef {Object} CompanyBasicInfo
 * @property {number} companyId - The unique id for the company.
 * @property {string} companyName - The name of the company.
 * @property {string} external_id - The external id for the company.
 */
type CompanyBasicInfo = {
  companyId: number;
  companyName: string;
  external_id: string;
};

/**
 * Represents information about a company associated with a user.
 *
 * @typedef {Object} UserCompanyInfo
 * @property {number} companyId - The unique id for the company.
 * @property {string} companyName - The name of the company.
 * @property {string} [url] - The URL of the company's logo.
 * @property {string} [city] - The city where the company is located.
 */
export type UserCompanyInfo = {
  companyId: number;
  companyName: string;
  url?: string;
  city?: string;
};

/**
 * Represents the response containing information about multiple companies from endpoint: v2/companies/info.
 *
 * @typedef {Object} CompaniesInfoResponse
 * @property {CompanyBasicInfo[]} companiesInfo - An array of basic information about companies.
 */
export type CompaniesInfoResponse = {
  companiesInfo?: CompanyBasicInfo[];
};

/**
 * Represents the response containing information about companies associated with users from endpoint v2/users_companies/companies.
 *
 * @typedef {Object} UsersCompaniesResponse
 * @property {Object.<number, UserCompanyInfo[]>} userIds - An object where keys are user IDs, and values are arrays of user company information.
 */
export type UsersCompaniesResponse = {
  userIds: {
    [userId: number]: UserCompanyInfo[];
  };
};

export interface Window {
  pendo: any;
}

type CreatedByJobRequest = {
  username: string;
  first_name: string;
  last_name: string;
};

export type CrewMember = {
  external_id: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  covid_vaccination_date: string;
  covid_exemption_approved_date: string;
  covid_testing_latest_date: string;
  covid_testing_opt_in_date: string;
  status: string;
  shifts_worked: number;
  avatar_url: null | string;
};

export type JobRequest = {
  position_id: number;
  schedule_id: number;
  schedule_type: number;
  request_id: number;
  external_id: string;
  schedule_name: string;
  base_wage: number;
  days: number;
  filled: number;
  needed: number;
  direct_hire: number;
  created_by_first_name: string;
  created_by_last_name: string;
  username: string;
  created_at: string;
  shifts: number;
  start_date_time: string;
  end_date_time: string;
  start_time_offset: number;
  end_time_offset: number;
  created_time: string;
  start_time: string;
  end_time: string;
  start_date: string;
  work_days: number[];
  created_date: string;
  created_by: CreatedByJobRequest;
  end_date: string;
  crew_members: CrewMember[];
  direct_invite_count: number;
  timezone: string;
  is_application: number;
  application_review_count: number;
};

export type AddBlueShiftWorkRequestToSchPayloadData = {
  work_days: Array<string>;
  start_date_time: string;
  end_date_time: string;
  supervisor_user_id: number;
  workers_needed: number;
} & ScheduleIdXORScheduleDetails;

// schedule_id exclusive or schedule_details. i.e. one or the other
type ScheduleIdXORScheduleDetails =
  | {
      schedule_id: number;
      schedule_details?: never;
    }
  | {
      schedule_id?: never;
      schedule_details: BlueshiftScheduleDetails;
    };

type BlueshiftScheduleDetails = {
  base_wage: number;
  job_updates: number[];
  schedule_name: string;
  schedule_type: number;
  poster_id: number;
};

export type CreateBlueShiftScheduleJobPayload = {
  controlModals?: boolean;
  companyId: number;
  positionId: number;
  payload: AddBlueShiftWorkRequestToSchPayloadData;
};

export type CreateBlueShiftScheduleJobsResponse = {
  // TODO:(BW-585) minor typo here scheduled should be scheduleIds on the API response. Should be changed on api-v2 then fixed here as well.
  // end point is -> v2/dashboard/${payload.companyId}/positions/${payload.positionId}/postJob
  createdScheduledAndJobsIds: CreatedScheduleAndJobId[];
  blueshift_request_id: number;
  topLevelScheduleId: number;
};

type CreatedScheduleAndJobId = {
  createdJobId: number;
  scheduleId: number;
};

export type CompanyInfoWithId = CompanyInfo & {
  id: string; // External company id
};

export type CompanyInfo = {
  // Company Info
  name?: string;
  phone_number?: string;
  description?: string;

  // Continuous fill
  refill_on?: number;

  // Geofence settings (meters)
  min_offsite_radius?: number;
  min_onsite_warn_radius?: number;
  min_onsite_block_radius?: number;
  min_offsite_warn_radius?: number;
  min_offsite_block_radius?: number;

  // Timesheets settings (minutes)
  terminal_mode?: number;
  late_clockout_buffer?: number;
  min_lunch_duration?: number; // Except me! Hours
  early_clockin_buffer?: number;
  auto_approval_on?: boolean;
  third_party_timetracker?: string | null;

  // Advanced background check & Drug Screening settings
  background_check_required?: number;
  background_check_active?: boolean;
  drug_screen_required?: number;
  drug_screen_active?: boolean;
};

export type CompanyNameDescAndURL = {
  companyName: string;
  companyDescription: string;
  companyLogoUrl: string;
  companyPhoneNumber: string;
};

export type CompanyGeofenceSettings = {
  minOnsiteWarnRadiusMeters: number;
  minOnsiteBlockRadiusMeters: number;
  minOffsiteWarnRadiusMeters: number;
  minOffsiteBlockRadiusMeters: number;
};

export const DistanceUnit = {
  METERS: "meters",
  KILOMETERS: "km",
} as const;

export type GeofenceFieldUnit = typeof DistanceUnit.METERS | typeof DistanceUnit.KILOMETERS;

export enum GeofenceFieldType {
  Warn,
  Block,
}

type GeofenceFieldMetaObject = {
  type: GeofenceFieldType;
  unitFieldName: string;
  fieldNames: string[];
};

export type GeofenceFieldMeta = Record<string, GeofenceFieldMetaObject>;

export type CompanyTimesheetsSettings = {
  terminalModeEnabled: boolean;
  lateClockOutBufferMinutes: number;
  minLunchDurationHours: number;
  earlyClockInBufferMinutes: number;
  autoApprovalEnabled: boolean;
  thirdPartyTimetracker: ThirdPartyTimetracker | null;
};

export type PartialRequestErrorModalData = {
  momentDate: Moment;
  formattedDate: string;
  shifts: number;
  invitee: string;
  successfullyCreatedShift: boolean;
};

export interface DashboardPositionJob {
  position_id: number;
  schedule_id: number;
  job_id: number;
  external_id: string;
  days: number;
  needed: number;
  direct_hire: number;
  blueshift_request_id: number | null;
  schedule_name: string;
  base_wage: number;
  active: number;
  schedule_type: number;
  supervisor_first_name: string;
  supervisor_last_name: string;
  supervisor_phone_number: string;
  shifts: number;
  applications_to_review: number;
  start_date_time: number;
  end_date_time: number;
  start_time_offset: number;
  end_time_offset: number;
  filled: number;
  dates: string;
  scheduled_time: string;
  work_days: number[];
  scheduleStartDateTime: Date;
  scheduleEndDateTime: Date;
  shifts_needed: number;
  shifts_filled: number;
  dashboard_wage: string;
  militaryStartTime: string;
  militaryEndTime: string;
  type: DashboardPositionJobTypes;
}

export interface DashboardPositionRepeatingSchedule {
  schedule_id: number;
  schedule_name: string;
  dates: string;
  scheduled_time: string;
  work_days: number[];
  base_wage: number;
  shifts: number;
  filled: number;
  needed: number;
  shifts_needed: number;
  shifts_filled: number;
  dashboard_wage: string;
  applications_to_review: number;
  direct_hire: number;
  scheduleStartDateTime: Date;
  scheduleEndDateTime: Date;
  militaryStartTime: string;
  militaryEndTime: string;
  jobs: DashboardPositionJob[];
  type: DashboardPositionJobTypes;
}

export interface DashboardPositionBulkSingleShifts {
  blueshift_request_id: number;
  dates: string;
  scheduled_time: string;
  work_days: number[];
  base_wage: number;
  fill_percentage: number;
  shifts: number;
  filled: number;
  needed: number;
  shifts_needed: number;
  shifts_filled: number;
  dashboard_wage: string;
  applications_to_review: number;
  direct_hire: number;
  scheduleStartDateTime: Date;
  scheduleEndDateTime: Date;
  militaryStartTime: string;
  militaryEndTime: string;
  jobs: DashboardPositionJob[];
  type: DashboardPositionJobTypes;
}

export interface DashboardPositionJobs {
  repeatingSchedules: DashboardPositionRepeatingSchedule[];
  bulkSingleShifts: DashboardPositionBulkSingleShifts[];
  singleShifts: DashboardPositionJob[];
}

export enum DashboardPositionJobTypes {
  repeatingSchedule = "repeatingSchedule",
  repeatingScheduleSubRow = "repeatingScheduleSubRow",
  bulkSingleShift = "bulkSingleShift",
  bulkSingleShiftSubRow = "bulkSingleShiftSubRow",
  singleShift = "singleShift",
  emptyHeaderRow = "emptyHeaderRow",
}

export type ScheduleAndIndividualShift =
  | DashboardPositionRepeatingSchedule
  | DashboardPositionBulkSingleShifts
  | DashboardPositionJob;

export type DashboardSchedulesAndIndividualShifts = ScheduleAndIndividualShift[];

export type PendoInfo = {
  trackType: string;
  newValue: any;
};

export const VaccinePolicies = {
  // preOsha
  NO_POLICY: "NO_POLICY",
  VACCINE_OR_EXEMPTION: "VACCINE_OR_EXEMPTION",
  // postOsha (Not used, split is set to serve preOsha values to all customers)
  VACCINE_OR_WEEKLY_TESTING: "VACCINE_OR_WEEKLY_TESTING",
  VACCINE_OR_EXEMPTION_AND_WEEKLY_TESTING: "VACCINE_OR_EXEMPTION_AND_WEEKLY_TESTING",
} as const;

export type ToastInfo = {
  visible: boolean;
  date: number;
  message: string;
  kind: KIND[keyof KIND];
};

export type GetCompanyV1Response = {
  id: string;
  absence_limit: null | string;
  account_manager: null | number;
  additionalEmails: string[];
  address: string;
  address_latitude: number;
  address_longitude: number;
  beta_active: number;
  business_unit: string | null;
  can_post: number;
  city: string;
  covid_policy: string;
  customer_type: string;
  description: null | string;
  disable_radii_use: number;
  early_clockin_buffer: number;
  has_past_due_invoices: number;
  hubspot_id: null | string;
  icp: number;
  implementation_mode: number;
  internal_id: number;
  late_limit: null | string;
  late_tolerance: null | number;
  logoURL: string;
  logo_id: number;
  markup: number;
  min_lunch_duration: number;
  min_offsite_block_radius: number;
  min_offsite_radius: number;
  min_offsite_warn_radius: number;
  min_onsite_block_radius: number;
  min_onsite_warn_radius: number;
  name: string;
  net_pay: number;
  parent_company_id: null | number;
  pricing_type: number;
  primaryContactEmail: null;
  primaryContactFirstName: null;
  primaryContactLastName: null;
  product_code: null;
  project_number: null | string;
  quickbooks_id: null | string;
  refill_on: number;
  region_id: number;
  signed_contract: number;
  source: number;
  state: string;
  tag_category: string;
  terminal_mode: number;
  zipcode: string;
};

export type PrepareMessageBodyResultType = {
  commMethod?: string;
  recipientNames: string[];
  recipientRole?: string;
  senderRole?: string;
  senderName?: string;
  toExternalIds: string[];
};

export enum ThirdPartyTimetracker {
  TIMESTAR = "TIMESTAR",
  ADP = "ADP",
  WHENIWORK = "WHENIWORK",
  PAYLOCITY = "PAYLOCITY",
  DEPUTY = "DEPUTY",
  NOVATIME = "NOVATIME",
}

export enum AdvancedBackgroundCheckTypes {
  DISABLED = 0,
  BASIC_CRIMINAL_WITH_THREE_ALIASES = 1,
  TASKER_PRO = 2,
  ESSENTIAL_CRIMINAL_WITH_ALIASES = 3,
  ESSENTIAL_CRIMINAL_WITH_UNLIMITED_ALIASES = 4,
  ESSENTIAL_CRIMINAL_WITH_THREE_ALIASES_SEVEN_YEAR = 5,
  ESSENTIAL_CRIMINAL_PLUS_FEDERAL = 6,
}

export enum DrugScreeningTypes {
  DISABLED = 0,
  PANEL_5_NO_THC = 1,
  PANEL_5 = 2,
  PANEL_6 = 3,
  PANEL_10 = 4,
}

export enum SettingStates {
  ENABLED = 0,
  DISABLED = 1,
}
