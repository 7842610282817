import React from "react";
import {
  HeaderImage,
  ModalHeader,
  ModalFooter,
  SubmitButton,
  ModalContent,
  ModalStyles as SuccessFailureWrapper,
} from "./styledComponents";
import { assetUrl } from "../../api/bluecrew/constants";

interface SuccessFailureProps {
  title: string;
  text?: string | string[];
  onButtonClick: () => void;
  isSuccess?: boolean;
  buttonLabel?: string;
  children?: React.ReactNode;
}

const successModalImagePath = `${assetUrl}/icons/success-modal-icon.svg`;
const failureModalImagePath = `${assetUrl}/icons/failure-modal-icon.svg`;

export const SuccessFailureComponent = (props: SuccessFailureProps) => {
  const { isSuccess, title, text, buttonLabel, onButtonClick, children } = props;
  const onHide = () => {
    onButtonClick();
  };
  return (
    <SuccessFailureWrapper>
      <div style={{ gap: "8px" }}>
        <HeaderImage src={isSuccess ? successModalImagePath : failureModalImagePath} alt="Header" />
        <ModalHeader>{title}</ModalHeader>
        {text instanceof String && <ModalContent>{text}</ModalContent>}
        {text instanceof Array &&
          text.map((line) => <ModalContent key={line}>{line}</ModalContent>)}
        {children && <ModalContent>{children}</ModalContent>}
      </div>
      <ModalFooter>
        <SubmitButton onClick={onHide} label={buttonLabel ?? "OK"} />
      </ModalFooter>
    </SuccessFailureWrapper>
  );
};
