import React from "react";
import { UserColumnStyledAvatar } from "../styledComponents";
import { UserIdContainer, UserInfoContainer, UserNameContainer } from "./styledComponents";

export type UserInfoProps = {
  firstName: string;
  lastName: string;
  internalId?: number;
};

const capitalizeFirstLetter = (word: string): string =>
  word.charAt(0).toUpperCase() + word.slice(1);
const formatName = (first: string, last: string) =>
  `${capitalizeFirstLetter(first)} ${capitalizeFirstLetter(last)}`;

export const UserInfo = ({ firstName, lastName, internalId }: UserInfoProps) => {
  const userNameText = formatName(firstName, lastName);

  return (
    <UserInfoContainer>
      <UserColumnStyledAvatar fullName={userNameText || " "} size={"smd"} />
      <UserNameContainer>{userNameText}</UserNameContainer>
      {internalId && <UserIdContainer>BC Id: {internalId}</UserIdContainer>}
    </UserInfoContainer>
  );
};
