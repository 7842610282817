/* eslint-disable import/no-cycle */
import {addMinutes, differenceInHours, differenceInMinutes, getUnixTime, isBefore, isSameMinute,} from "date-fns";
import {UserTimestamp} from "../../api/bluecrew/payroll";
import {
  PayrollAutoApprovalStatus,
  PayrollHoursStatus,
  PayrollStatusReason,
  TimesheetReasonCodeToText,
  TimesheetSupportUserTypes,
  TimesheetTableTabPayrollStatusMap,
  TimesheetTabs,
  TimestampType,
} from "./types/Payroll.types";
import {
  BreakTimestamps,
  ShiftComponent,
  ShiftThresholds,
  ShiftTimes,
  SupportAction,
  TimesheetsShiftInfo,
  TimestampAttribute,
  UserShift,
  UserShiftLastUpdateBy,
  WorkplaceAction,
} from "./types/Shift.types";
import {UserType} from "../../api/bluecrew/types";

export const CREWMEMBER_SUBMISSION_BUFFER_HOURS = 24;

export enum TimestampAttributeName {
  GEOFENCE_ERROR = "GEOFENCE_ERROR",
  GEOFENCE_WARNING = "GEOFENCE_WARNING",
  TIME_ERROR = "TIME_ERROR",
  CM_SUBMITTED = "CM_SUBMITTED",
  CM_MISSING_SUBMISSION = "CM_MISSING_SUBMISSION",
  CM_EDITED_SUBMISSION = "CM_EDITED_SUBMISSION",
}

const GEOFENCE_WARNING: TimestampAttribute = {
  name: TimestampAttributeName.GEOFENCE_WARNING,
  validate: ({ thresholds, timestamp }) => {
    if (!timestamp?.distance || !thresholds.warnRadius || !thresholds.blockRadius) return false;

    const punchOutsideWarnRadius = timestamp.distance > thresholds.warnRadius;
    const punchInsideBlockRadius = timestamp.distance < thresholds.blockRadius;

    return punchOutsideWarnRadius && punchInsideBlockRadius;
  },
};

const GEOFENCE_ERROR: TimestampAttribute = {
  name: TimestampAttributeName.GEOFENCE_ERROR,
  validate: ({ thresholds, timestamp }) => {
    if (!timestamp?.distance || !thresholds.blockRadius) return false;

    return timestamp.distance > thresholds.blockRadius;
  },
};

const TIME_ERROR: TimestampAttribute = {
  name: TimestampAttributeName.TIME_ERROR,
  validate: ({ thresholds, timestamp, breaks }) => {
    if (!timestamp) return false;

    const isShiftStart = TimestampType.SHIFT_START === timestamp.type;
    const isShiftEnd = TimestampType.SHIFT_END === timestamp.type;

    if (isShiftStart) {
      const earlyClockInDuration = differenceInMinutes(thresholds.expectedStart, timestamp.time);
      return earlyClockInDuration > thresholds.earlyClockinBuffer;
    }

    if (isShiftEnd) {
      const lateClockOutDuration = differenceInMinutes(timestamp.time, thresholds.expectedEnd);
      return lateClockOutDuration > thresholds.lateClockoutBuffer;
    }

    if (breaks !== undefined) {
      return getDurationOfAllBreaksInMins(breaks) < thresholds.minLunchDurationHours * 60;
    }

    return false;
  },
};

const CM_SUBMITTED: TimestampAttribute = {
  name: TimestampAttributeName.CM_SUBMITTED,
  validate: ({ timestamp }) => !!timestamp,
};

const CM_MISSING_SUBMISSION: TimestampAttribute = {
  name: TimestampAttributeName.CM_MISSING_SUBMISSION,
  validate: ({ thresholds, timestamp, hoursStatus }) => {
    if (
      TimesheetTableTabPayrollStatusMap[TimesheetTabs.PENDING_REVIEW].some(
        (status) => status === hoursStatus,
      )
    ) {
      return hasShiftSubmissionTimeEnded(thresholds) && !timestamp;
    }
    return false;
  },
};

const CM_EDITED_SUBMISSION: TimestampAttribute = {
  name: TimestampAttributeName.CM_EDITED_SUBMISSION,
  validate: ({ timestamp }) => !!timestamp?.isUserEdited,
};

const TimestampAttributes = [
  GEOFENCE_ERROR,
  GEOFENCE_WARNING,
  TIME_ERROR,
  CM_SUBMITTED,
  CM_MISSING_SUBMISSION,
  CM_EDITED_SUBMISSION,
];

type BuildUserShiftType = {
  shiftThresholds: ShiftThresholds;
  createdBy: string;
  createdAt: Date;
  createdByUserType: UserType;
  sentPayroll: boolean;
  sentInvoice: boolean;
  timestamps?: UserTimestamp[];
  usersHoursStart?: Date | null;
  usersHoursEnd?: Date | null;
  usersHoursLunchDurationHours?: number;
  usersHoursStatus?: PayrollHoursStatus | null;
  usersHoursStatusReason?: PayrollStatusReason | null;
  updatedBy?: string | null;
  updatedAt?: Date | null;
  updatedByUserType?: UserType | null;
  autoApprovalDetermination?: PayrollAutoApprovalStatus | null;
  autoApprovalTime?: Date | null;
};

export const buildUserShift = ({
  shiftThresholds,
  createdBy,
  createdAt,
  createdByUserType,
  sentPayroll,
  sentInvoice,
  timestamps,
  usersHoursStart,
  usersHoursEnd,
  usersHoursLunchDurationHours,
  usersHoursStatus,
  usersHoursStatusReason,
  updatedBy,
  updatedAt,
  updatedByUserType,
  autoApprovalDetermination,
  autoApprovalTime,
}: BuildUserShiftType): UserShift => {
  const status = Number.isFinite(usersHoursStatus)
    ? (usersHoursStatus as PayrollHoursStatus)
    : PayrollHoursStatus.NEEDS_VERIFICATION; // Needs verification by default

  const start = buildShiftTimestamp(
    shiftThresholds,
    TimestampType.SHIFT_START,
    status,
    usersHoursStart,
    timestamps,
  );
  const end = buildShiftTimestamp(
    shiftThresholds,
    TimestampType.SHIFT_END,
    status,
    usersHoursEnd,
    timestamps,
  );
  const shiftBreak = buildShiftBreak(
    shiftThresholds,
    start.time,
    end.time,
    status,
    usersHoursLunchDurationHours,
    timestamps,
  );

  const statusReason = usersHoursStatusReason || undefined;
  const lastUpdateBy = {
    name: updatedBy || createdBy,
    userType: updatedByUserType || createdByUserType,
    updatedAt: updatedAt || createdAt,
  };
  const isAutoApproved =
    autoApprovalDetermination === PayrollAutoApprovalStatus.AUTO_APPROVED &&
    isSameMinute(autoApprovalTime!, lastUpdateBy.updatedAt); // Check to rule out updates after auto-approver is run.
  const allowedWorkplaceActions = getAllowedWorkplaceActions(
    lastUpdateBy,
    shiftThresholds.expectedEnd,
    isAutoApproved,
    sentPayroll,
    sentInvoice,
    usersHoursStatus, // Need to pass raw hours status to check if a users_hours entry exists
  );
  const allowedSupportActions = getAllowedSupportActions(
    shiftThresholds.expectedEnd,
    usersHoursStatus, // Need to pass raw hours status to check if a users_hours entry exists
    statusReason,
  );

  return {
    start: { ...start, type: ShiftComponent.CLOCK_IN },
    end: { ...end, type: ShiftComponent.CLOCK_OUT },
    break: { ...shiftBreak, type: ShiftComponent.BREAK_DURATION },
    allowedWorkplaceActions,
    allowedSupportActions,
    status,
    statusReason,
    isAutoApproved,
    lastUpdateBy,
  };
};

export const getShiftTimestampAttributes = (
  thresholds: ShiftThresholds,
  hoursStatus: PayrollHoursStatus,
  timestamp: UserTimestamp | undefined,
) => {
  const validatedAttributes: Set<TimestampAttributeName> = new Set();
  TimestampAttributes.forEach(
    (attribute) =>
      attribute.validate({ thresholds, timestamp, hoursStatus }) &&
      validatedAttributes.add(attribute.name),
  );
  return Array.from(validatedAttributes);
};

// Always collect the latest LUNCH_START/LUNCH_END pairs.
export const getBreaksFromTimestamps = (timestamps: UserTimestamp[]): Array<BreakTimestamps> => {
  const breaks: Array<BreakTimestamps> = [];
  let breakEvent: { start: UserTimestamp | null; end: UserTimestamp | null } = {
    start: null,
    end: null,
  };

  for (const timestamp of timestamps) {
    if (
      timestamp.type === TimestampType.LUNCH_START &&
      breakEvent.start !== null &&
      breakEvent.end !== null
    ) {
      breaks.push(<BreakTimestamps>breakEvent);
      breakEvent = { start: null, end: null };
    }

    if (timestamp.type === TimestampType.LUNCH_START) breakEvent.start = timestamp;
    if (timestamp.type === TimestampType.LUNCH_END) breakEvent.end = timestamp;
  }

  if (breakEvent.start !== null && breakEvent.end !== null)
    breaks.push(<BreakTimestamps>breakEvent);

  return breaks;
};

const getShiftTimestampTime = (
  defaultShiftTime: Date,
  usersHoursTime?: Date | null,
  finalizedUserTimestamp?: UserTimestamp,
) => {
  if (usersHoursTime) return usersHoursTime;
  if (finalizedUserTimestamp) return finalizedUserTimestamp.time;
  return defaultShiftTime;
};

const getShiftIsDisputeTimestampMissing = (
  usersHoursStatus: PayrollHoursStatus,
  finalizedUserTimestamp?: UserTimestamp,
) => usersHoursStatus === PayrollHoursStatus.DISPUTED && !finalizedUserTimestamp;

const getShiftTimeInDispute = (
  usersHoursStatus: PayrollHoursStatus,
  finalizedUserTimestamp?: UserTimestamp,
) => {
  if (usersHoursStatus === PayrollHoursStatus.DISPUTED && finalizedUserTimestamp)
    return finalizedUserTimestamp.time;
  return undefined;
};

const buildShiftTimestamp = (
  shiftThresholds: ShiftThresholds,
  timestampType: TimestampType.SHIFT_START | TimestampType.SHIFT_END,
  usersHoursStatus: PayrollHoursStatus,
  usersHoursTime?: Date | null,
  timestamps?: UserTimestamp[],
) => {
  const defaultShiftTime =
    timestampType === TimestampType.SHIFT_START
      ? shiftThresholds.expectedStart
      : shiftThresholds.expectedEnd;
  const filteredTimestamps = timestamps?.filter((t) => timestampType === t.type);
  const finalizedUserTimestamp = filteredTimestamps?.reduce((t1, t2) => {
    if (t1 && isBefore(t1.updatedAt, t2.updatedAt)) {
      return t2;
    }
    return t1;
  }, filteredTimestamps[0]);
  const type =
    timestampType === TimestampType.SHIFT_START
      ? ShiftComponent.CLOCK_IN
      : ShiftComponent.CLOCK_OUT;

  const time = new Date(
    getShiftTimestampTime(defaultShiftTime, usersHoursTime, finalizedUserTimestamp),
  );
  const attributes = getShiftTimestampAttributes(
    shiftThresholds,
    usersHoursStatus,
    finalizedUserTimestamp,
  );
  const isDisputedTimeMissing = getShiftIsDisputeTimestampMissing(
    usersHoursStatus,
    finalizedUserTimestamp,
  );
  const timeInDispute = getShiftTimeInDispute(usersHoursStatus, finalizedUserTimestamp);

  return {
    time,
    type,
    attributes,
    isDisputedTimeMissing,
    timeInDispute,
    userTimestamp: finalizedUserTimestamp,
  };
};

const getShiftBreakDuration = (
  defaultBreakDuration: number,
  breakEvents: Array<BreakTimestamps>,
  usersHoursLunchDurationHours?: number,
) => {
  if (usersHoursLunchDurationHours) return usersHoursLunchDurationHours * 60;
  if (breakEvents.length > 0) return getDurationOfAllBreaksInMins(breakEvents);
  return defaultBreakDuration;
};

export const getShiftBreakAttributes = (
  thresholds: ShiftThresholds,
  breakEvents: Array<BreakTimestamps>,
  hoursStatus: PayrollHoursStatus,
) => {
  const validatedAttributes: Set<TimestampAttributeName> = new Set();
  if (!breakEvents.length && CM_MISSING_SUBMISSION.validate({ thresholds, hoursStatus })) {
    validatedAttributes.add(TimestampAttributeName.CM_MISSING_SUBMISSION);
  }
  TimestampAttributes.forEach((attribute) =>
    breakEvents.forEach(
      (breakEvent) =>
        (attribute.validate({
          thresholds,
          timestamp: breakEvent.start,
          breaks: breakEvents,
          hoursStatus,
        }) ||
          attribute.validate({
            thresholds,
            timestamp: breakEvent.end,
            breaks: breakEvents,
            hoursStatus,
          })) &&
        validatedAttributes.add(attribute.name),
    ),
  );
  return Array.from(validatedAttributes);
};

const getShiftBreakIsDisputedDurationMissing = (
  breakEvents: Array<BreakTimestamps>,
  usersHoursStatus: PayrollHoursStatus,
) => usersHoursStatus === PayrollHoursStatus.DISPUTED && breakEvents.length === 0;

const getShiftBreakDurationInDispute = (
  breakEvents: Array<BreakTimestamps>,
  usersHoursStatus: PayrollHoursStatus,
) => {
  if (usersHoursStatus === PayrollHoursStatus.DISPUTED && breakEvents.length > 0) {
    return getDurationOfAllBreaksInMins(breakEvents);
  }
  return undefined;
};

const buildShiftBreak = (
  shiftThresholds: ShiftThresholds,
  shiftStart: Date,
  shiftEnd: Date,
  usersHoursStatus: PayrollHoursStatus,
  usersHoursLunchDurationHours?: number,
  timestamps?: UserTimestamp[],
) => {
  const defaultShiftBreakDuration =
    differenceInHours(shiftEnd, shiftStart) > 5 ? shiftThresholds.minLunchDurationHours * 60 : 0;

  const breakTimestamps = timestamps ? getBreaksFromTimestamps(timestamps) : [];
  const durationMinutes = getShiftBreakDuration(
    defaultShiftBreakDuration,
    breakTimestamps,
    usersHoursLunchDurationHours,
  );
  const attributes = getShiftBreakAttributes(shiftThresholds, breakTimestamps, usersHoursStatus);
  const isDisputedDurationMissing = getShiftBreakIsDisputedDurationMissing(
    breakTimestamps,
    usersHoursStatus,
  );
  const durationInDispute = getShiftBreakDurationInDispute(breakTimestamps, usersHoursStatus);

  return {
    durationMinutes,
    breakTimestamps,
    attributes,
    isDisputedDurationMissing,
    durationInDispute,
  };
};

export const getDurationOfAllBreaksInMins = (breakEvents: Array<BreakTimestamps>) =>
  breakEvents
    .map((breakEvent) => differenceInMinutes(breakEvent.end.time, breakEvent.start.time))
    .reduce(
      (totalBreakDuration, currentBreakDuration) => currentBreakDuration + totalBreakDuration,
      0,
    );

// Auto-approver updates users_hours as a support user,
// but should not be considered support for Timesheets use-cases
const getHoursUpdatedBySupport = (
  shiftLastUpdatedBy: UserShiftLastUpdateBy,
  isAutoApproved: boolean,
) => !isAutoApproved && TimesheetSupportUserTypes.includes(shiftLastUpdatedBy.userType);

const getAllowedWorkplaceActions = (
  shiftLastUpdatedBy: UserShiftLastUpdateBy,
  expectedShiftEnd: Date,
  isAutoApproved: boolean,
  sentPayroll: boolean,
  sentInvoice: boolean,
  rawHoursStatus?: PayrollHoursStatus | null,
): Array<WorkplaceAction> => {
  const allowedActions: Array<WorkplaceAction> = [];
  const clientsBilled = sentPayroll || sentInvoice;
  const hoursUpdatedBySupport = getHoursUpdatedBySupport(shiftLastUpdatedBy, isAutoApproved);
  const cmSubmissionCompleteProxy =
    differenceInHours(new Date(), expectedShiftEnd) > CREWMEMBER_SUBMISSION_BUFFER_HOURS ||
    Number.isFinite(rawHoursStatus);

  if (!hoursUpdatedBySupport && cmSubmissionCompleteProxy) {
    allowedActions.push(WorkplaceAction.FLAG);
    if (!clientsBilled) allowedActions.push(WorkplaceAction.APPROVE, WorkplaceAction.UNDO);
  }

  return allowedActions;
};

const getAllowedSupportActions = (
  expectedShiftEnd: Date,
  hoursStatus?: PayrollHoursStatus | null,
  statusReason?: PayrollStatusReason,
): Array<SupportAction> => {
  const allowedActions: Array<SupportAction> = [];
  if (isBefore(expectedShiftEnd, new Date()) || Number.isFinite(hoursStatus)) {
    allowedActions.push(SupportAction.EDIT, SupportAction.EXCUSE, SupportAction.REJECT);
    if (
      hoursStatus !== PayrollHoursStatus.DISPUTED ||
      (hoursStatus === PayrollHoursStatus.DISPUTED &&
        statusReason &&
        getDisputeReasonCodesWithShiftAdjustment().includes(statusReason))
    ) {
      allowedActions.push(SupportAction.APPROVE);
    }
  }
  return allowedActions;
};

const formatReasonCodeLabel = (reasonCodeLabel: string) =>
  (reasonCodeLabel.charAt(0).toUpperCase() + reasonCodeLabel.slice(1).toLowerCase()).replace(
    "_",
    " ",
  );
export const getReasonTextFromCode = (reasonCode: PayrollStatusReason) =>
  TimesheetReasonCodeToText[reasonCode] ?? formatReasonCodeLabel(PayrollStatusReason[reasonCode]);

export const getDisputeReasonCodesWithShiftAdjustment = () => [
  PayrollStatusReason.SUPPORT_UNADJUSTED,
  PayrollStatusReason.RECEIVED_BREAKS,
];

export const validateShiftTimes = (shiftTimes: ShiftTimes) => {
  const { clockIn, clockOut, breakDurationMins } = shiftTimes;

  if (!clockIn || !clockOut) {
    return "Clock-in and clock-out times must be present";
  }
  if (!Number.isFinite(breakDurationMins)) {
    return "Break duration must be present";
  }
  const shiftLength = differenceInMinutes(clockOut, clockIn);
  if (breakDurationMins! >= shiftLength) {
    return `Break duration must be less than shift length (${shiftLength} min)`;
  }
  return;
};

// show edited values as default for approved/disputed/rejected records
export const buildShiftTimes = (rowData: TimesheetsShiftInfo): ShiftTimes => {
  return {
    clockIn: new Date(
      isNotPendingReview(rowData.userShift.status) && getUnixTime(rowData.userShift.start.time) !== 0
        ? rowData.userShift.start.time : (rowData.userShift.start.userTimestamp?.time || rowData.job.start)
    ),
    clockOut: new Date(
      isNotPendingReview(rowData.userShift.status) && getUnixTime(rowData.userShift.end.time) !== 0
        ? rowData.userShift.end.time : (rowData.userShift.end.userTimestamp?.time || rowData.job.end)
    ),
    breakDurationMins: rowData.userShift.break.durationMinutes,
  };
};

export const buildShiftKey = (rowData: TimesheetsShiftInfo) => ({
  jobId: rowData.job.externalId,
  cmId: rowData.user.externalId,
  shiftIndex: rowData.job.shiftIndex,
});

export const hasShiftSubmissionTimeEnded = (thresholds: ShiftThresholds): boolean =>
  isBefore(addMinutes(thresholds.expectedEnd, thresholds.lateClockoutBuffer), new Date());

const isNotPendingReview = (status: PayrollHoursStatus) =>
  !TimesheetTableTabPayrollStatusMap[TimesheetTabs.PENDING_REVIEW].some(
    (hoursStatus) => hoursStatus === status,
  )
