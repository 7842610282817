import React, { Dispatch, SetStateAction } from "react";
import { format, addDays } from "date-fns";
import { Spacer, SpacerType } from "../../../../components/Spacer";
import { ButtonRefresh } from "./ButtonRefresh";
import { ButtonPrevious } from "./ButtonPreviousWeek";
import { ButtonNext } from "./ButtonNextWeek";
import { ButtonInvoices } from "./ButtonInvoices";
import { GetCompanyV1Response } from "../../../../api/bluecrew/types";
import {
  Header,
  PageHeaderWrapper,
  ButtonWrapper,
  HeaderText,
  SubHeaderText,
  ChevronWrapper,
} from "./styledComponents";
import { ButtonAddShift } from "./ButtonAddShift";
import { TimesheetSupportUserTypes } from "../../types/Payroll.types";
import { getUserType } from "../../../../redux/selectors/auth";
import { Can } from "../../../PermissionsContext";
import { useAppSelector } from "../../../../redux";
import { ButtonCalendar } from "./ButtonCalendarWeek";

export type TimesheetsPageHeaderProps = {
  week: Date;
  setWeek: Dispatch<SetStateAction<Date>>;
  companyDetails: GetCompanyV1Response;
};

export const TimesheetsPageHeader = ({
  week,
  setWeek,
  companyDetails,
}: TimesheetsPageHeaderProps) => {
  const supportUser = TimesheetSupportUserTypes.includes(useAppSelector(getUserType));

  return (
    <PageHeaderWrapper data-testid="timesheets-header">
      <Header>
        <HeaderText>{`Timesheets for week of ${format(week, "MMM do")} - ${format(
          addDays(week, 6),
          "MMM do",
        )}`}</HeaderText>
        <ChevronWrapper className="p-buttonset" style={{ textAlign: "left" }}>
          <ButtonPrevious setWeek={setWeek} week={week} />
          <ButtonCalendar setWeek={setWeek} week={week} />
          <ButtonNext setWeek={setWeek} week={week} />
        </ChevronWrapper>
        <ButtonWrapper>
          <ButtonRefresh />
          <Can I="view" a="payroll">
            <Spacer $type={SpacerType.horizontal} $size="16px" />
            <ButtonInvoices
              companyExternalId={companyDetails.id}
              week={week}
              companyName={companyDetails.name}
            />
          </Can>
          {supportUser && (
            <>
              <Spacer $type={SpacerType.horizontal} $size="16px" />
              <ButtonAddShift />
            </>
          )}
        </ButtonWrapper>
      </Header>
      <Spacer $type={SpacerType.vertical} $size="16px" />
      <SubHeaderText>{companyDetails.name}</SubHeaderText>
    </PageHeaderWrapper>
  );
};
