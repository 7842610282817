// Currency - COMPONENT
// =============================================================================

import React, { Component } from "react";
import styled from "styled-components";
import { Input } from "../index";
// @ts-ignore
import dollarSignIcon from "../assets/icons/dollar-sign.svg";

const StyledContainer = styled.div`
  padding: 0 0.5rem;
  height: 3.375rem;
  border-radius: 0.5rem;
  background-color: #f5f4f6;
  margin-right: 0.625rem;
  width: 50%;

  .currency-input {
    width: 100%;
    height: 3.375rem;
    text-align: left;
    display: inline-block;
    padding: 0 0 0 1.75rem;
    background-color: transparent;
    background-image: url(${dollarSignIcon});
    background-repeat: no-repeat;
    background-position: left 0.25rem center;
    text-indent: 5px;

    &:focus {
      border: none;
    }
  }
  .currency-label {
    height: 3.375rem;
  }
`;

type Props = {
  inputId: string;
  minVal: number;
  maxVal: number;
  curVal: string;
  increment: Number;
  symbol: String;
  disabled: Boolean;
  onChange: Function;
};

type State = {
  currentValue: Number;
};

export class CurrencyNumber extends Component<Props, State> {
  // we are not using internal state
  static defaultProps = {
    inputId: "currencyNumber",
    minVal: 0,
    maxVal: 50,
    curVal: 10,
    increment: 1,
    symbol: "$",
    disabled: false,
    onChange: function () {},
  };

  render() {
    const { inputId, minVal, maxVal, curVal, increment, disabled } = this.props;

    return (
      <StyledContainer>
        {/** @ts-ignore */}
        <Input
          id={inputId}
          name={inputId}
          className="currency-input"
          type="number"
          min={minVal}
          max={maxVal}
          step={increment}
          disabled={disabled}
          placeholder="0.00"
          onChange={this.props.onChange}
          value={curVal}
        />
      </StyledContainer>
    );
  }
}
