import { Table as BaseTable } from 'baseui/table-semantic';
import React, { ReactNode } from 'react';

import { styled } from 'baseui';
import {
  getBorderColor,
  getBorderRadius,
  getBorderStyle,
  getHorizontalPadding,
  getVerticalPadding,
} from '../helpers/styleHelpers';
import { Checkbox } from './Checkbox';

type Row = ReactNode[];

export type SelectableRow = {
  cols: Row[];
  id: string;
};

export interface TableProps {
  actionsButton?: ReactNode;
  checkboxesVisible?: boolean;
  columns: ReactNode[];
  data: Row[] | SelectableRow[];
  onSelectionChange?: (selection: string[]) => void;
  selectable?: boolean;
  selection?: string[];
  verticalAlign?: string;
}

const SlyledSelectWrap = styled('div', () => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const TABLE_ALIGNMENT = {
  MIDDLE: 'middle',
  TOP: 'top',
};

export const Table = ({
  checkboxesVisible = true,
  columns,
  data,
  onSelectionChange,
  selectable = false,
  selection = [],
  verticalAlign = TABLE_ALIGNMENT.MIDDLE,
}: TableProps) => {
  const hasAny = selectable && Boolean(selection.length);
  const hasAll =
    selectable &&
    hasAny &&
    (data as SelectableRow[]).every((x) => x && selection.includes(x.id));

  const tableColumns = selectable
    ? [
        <SlyledSelectWrap data-testid="select-row">
          {checkboxesVisible && (
            <Checkbox
              onChange={(e: any) =>
                onSelectionChange &&
                onSelectionChange(
                  e.target.checked
                    ? (data as SelectableRow[]).map((x) => x.id)
                    : [],
                )
              }
              checked={hasAll}
            />
          )}
        </SlyledSelectWrap>,
        ...columns,
      ]
    : columns;
  const tableData: ReactNode[][] = selectable
    ? (data as SelectableRow[]).map((row) => [
        <SlyledSelectWrap data-testid="select-row">
          {checkboxesVisible && (
            <Checkbox
              onChange={(e: any) =>
                onSelectionChange &&
                onSelectionChange(
                  e.target.checked
                    ? [...selection, row.id]
                    : selection.filter((item) => item !== row.id),
                )
              }
              checked={selection.includes(row.id)}
            />
          )}
        </SlyledSelectWrap>,
        ...row.cols,
      ])
    : (data as ReactNode[][]);

  return (
    <BaseTable
      columns={tableColumns}
      data={tableData}
      overrides={{
        Root: {
          style: () => ({
            ...getBorderRadius('0'),
            ...getBorderColor('none'),
            ...getBorderStyle('none'),
          }),
        },
        TableHeadCell: {
          style: ({ $theme }) => ({
            paddingLeft: 0,
            borderBottom: `1px solid ${$theme.colors.primary200}`,
            ...$theme.typography.font150, // 12px
            lineHeight: $theme.sizing.scale600,
            textTransform: 'uppercase',
            ...(selectable
              ? {
                  ':first-of-type': {
                    width: $theme.sizing.scale800,
                    ...getVerticalPadding($theme.sizing.scale300),
                    ...getHorizontalPadding($theme.sizing.scale600),
                  },
                }
              : {}),
            ':before': {
              display: 'none',
            },
            ':after': {
              display: 'none',
            },
          }),
        },
        TableBodyCell: {
          style: ({ $theme }) => ({
            paddingTop: $theme.sizing.scale600, // 16px
            paddingBottom: $theme.sizing.scale600,
            paddingLeft: 0,
            borderBottom: `1px solid ${$theme.colors.primary200}`,
            ...$theme.typography.font300,
            lineHeight: $theme.sizing.scale800,
            verticalAlign: `${verticalAlign}`,
            whiteSpace: 'nowrap',
            ...(selectable
              ? {
                  ':first-of-type': {
                    width: $theme.sizing.scale800,
                    ...getVerticalPadding($theme.sizing.scale600),
                    ...getHorizontalPadding($theme.sizing.scale600),
                  },
                }
              : {}),
            ':last-of-type': {
              textAlign: 'right',
              // fill all the remaining space
              width: '99%',
            },
          }),
        },
      }}
    />
  );
};
