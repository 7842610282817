// TODO Update this component to a function component in its own directory broken into multiple files per https://bluecrewjobs.atlassian.net/wiki/spaces/WS/pages/1676967961/React+-+Best+practices
import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { Button, Link } from "@bluecrew/web-react-core";
import { Alert } from "@bluecrew/blueprint-web";
import { KIND } from "baseui/notification";
import jobActions from "../redux/actions/job";

const StyledShell = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const StyledP = styled("p")`
  margin: 0 0 1rem 0;
  text-align: center;
`;

const StyledButtons = styled("div")`
  display: flex;

  > :not(:last-child) {
    margin-right: 1rem;
  }
`;

const Bomb = styled("div")`
  font-size: 10rem;
`;

// https://www.utf8icons.com/character/128163/bomb
const bomb = <Bomb>&#128163;</Bomb>;

type AlertContainerProps = {
  visible: boolean;
};

const StyledAlertContainer = styled.div<AlertContainerProps>`
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`;

type SimulateErrorsProps = {
  dispatch: any;
};

function SimulateErrors({ dispatch }: SimulateErrorsProps) {
  const [errorTriggered, setErrorTriggered] = useState(false);
  const [throwRenderError, setThrowRenderError] = useState(false);

  const flashNotice = () => {
    setErrorTriggered(true);
    setTimeout(() => {
      setErrorTriggered(false);
    }, 2000);
  };

  if (throwRenderError) {
    throw new Error("This is a simulated render error");
  }

  return (
    <StyledShell>
      {bomb}
      <StyledP>
        Break stuff and check{" "}
        <Link href="https://rpm.newrelic.com/accounts/2160836/browser">New Relic</Link>
      </StyledP>
      <StyledP>Check the browser console for debugging</StyledP>
      <StyledButtons>
        <Button
          onClick={() => {
            console.log("About to simulate a synchronous error");
            flashNotice();
            throw new Error("Simulated synchronous error");
          }}
        >
          Synchronous error
        </Button>
        <Button
          onClick={() => {
            console.log("About to simulate an asynchronous error");
            setTimeout(() => {
              flashNotice();
              throw new Error("Simulated asynchronous error");
            }, 1);
          }}
        >
          Asynchronous error
        </Button>
        <Button
          onClick={() => {
            console.log("About to simulate a render error");
            flashNotice();
            setThrowRenderError(true);
          }}
        >
          Render error
        </Button>
        <Button
          onClick={() => {
            console.log("About to simulate an API error");
            dispatch(
              jobActions.createJob.request({
                data: {},
                // This will trigger an API error.
                token: "PURPOSEFULLY-INVALID-TOKEN",
              }),
            );
            flashNotice();
          }}
        >
          API error
        </Button>
      </StyledButtons>
      <StyledAlertContainer visible={errorTriggered}>
        <Alert toast type={KIND.info}>
          Error simulated!
        </Alert>
      </StyledAlertContainer>
    </StyledShell>
  );
}

// @ts-ignore $FlowFixReduxConnect
export default connect()(SimulateErrors);
