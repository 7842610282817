const calculateShiftLength = require("./calculate-shift-length");
const convertTimezoneKeepingTime = require("./convert-timezone-keeping-time");
const moment = require("moment-timezone");

module.exports = function (startDateStr, endDateStr, days, timezone) {
  // when passing an invalid date string, this code will loop forever, so we need to throw
  if (!moment(startDateStr).isValid() || !moment(endDateStr).isValid()) {
    throw new Error(
      `Invalid date, can't calculate shifts.\n Start Date: ${startDateStr} \n End date:${endDateStr}\n`,
    );
  }
  // the default value for days is a bitmask containing every day
  if (!days) {
    days = (1 << 7) - 1;
  }

  var shiftLength = calculateShiftLength(startDateStr, endDateStr, timezone);

  // get the start and end dates as moments
  var startDate = moment.parseZone(startDateStr);
  var endDate = moment.parseZone(endDateStr);

  // get the first shift start date in the given timezone
  var currentShiftStartDate = convertTimezoneKeepingTime(startDate, timezone);
  var currentShiftEndDate = currentShiftStartDate.clone().add(shiftLength, "seconds");

  // iterate through all days from the start to the end, and add the resulting shift data to the timeSegments array
  var timeSegments = [];
  var zone = moment.tz.zone(timezone);
  do {
    // if this shift takes place on a non-requested day of the week, increment the current shift dates and continue
    if (!(days & (1 << currentShiftStartDate.day()))) {
      currentShiftStartDate.add(1, "days");
      currentShiftEndDate = currentShiftStartDate.clone().add(shiftLength, "seconds");
      continue;
    }

    // add data to timeSegments array
    timeSegments.push({
      startDate: currentShiftStartDate.unix(),
      startDateOffset: zone.utcOffset(currentShiftStartDate.valueOf()),
      endDate: currentShiftEndDate.unix(),
      endDateOffset: zone.utcOffset(currentShiftEndDate.valueOf()),
      crossesDST: currentShiftStartDate.isDST() !== currentShiftEndDate.isDST(),
    });

    // increment the current shift dates and continue
    currentShiftStartDate.add(1, "days");
    currentShiftEndDate = currentShiftStartDate.clone().add(shiftLength, "seconds");
  } while (currentShiftEndDate.format("YYYY-MM-DD") <= endDate.format("YYYY-MM-DD"));

  return timeSegments;
};
