import styled from "styled-components";
import { FlexCenterRow } from "../../SharedComponents";

export const NameFlexCenterRow = styled(FlexCenterRow)<{
  isSubRow?: boolean;
  isWidthSet?: boolean;
  isBigFont?: boolean;
}>`
  padding-left: ${({ isSubRow }) => (isSubRow ? "40px" : "0px")};
  width: ${({ isWidthSet }) => isWidthSet && "200px"};
  justify-content: start;
  font-weight: ${({ isBigFont }) => isBigFont && "600"};
  font-size: ${({ isBigFont }) => isBigFont && "16px"};
  line-height: ${({ isBigFont }) => isBigFont && "24px"};
`;

export const LinkContainer = styled.span`
  margin-right: 2px;
  justify-items: center;
`;

export const RequestText = styled.div`
  color: ${({ theme }) => theme.colorTokens.gray700};
  font-size: 14px;
  font-weight: normal;
  width: 90px;
`;
