import React, { ForwardedRef, forwardRef, Ref } from "react";
import {
  StyledInfoGraphicContainer,
  StyledLabelsContainer,
  StyledLeftHandContainer,
  StyledListItemCheckboxContainer,
  StyledRichListItem,
  StyledRightHandContainer,
  StyledSubtitle,
  StyledTitle,
} from "./styled-components";
import { BaseListItemT, ComponentPosition, RichListItemProps, VerticalAlign } from "./types";
import { ListItemCheckbox } from "./ListItemCheckbox";
import { ListInformationItem } from "./ListInformationItem";

const RichListItemCmp = <T extends BaseListItemT>(
  {
    item,
    checkboxPosition = ComponentPosition.HIDDEN,
    leftContainerContentAlign = VerticalAlign.START,
    underlineLastItem = true,
    avatarRenderer,
    titleRenderer,
    subtitleRenderer,
    onClick,
    extraInfoRendererLeft,
    extraInfoRendererRight,
    showCheckBox = true,
  }: RichListItemProps<T>,
  ref: Ref<HTMLLIElement>,
) => (
  <StyledRichListItem
    onClick={onClick}
    $underlineLastItem={underlineLastItem}
    ref={ref}
    $selected={item.selected}
  >
    {showCheckBox && (
      <StyledLeftHandContainer $leftContainerContentAlign={leftContainerContentAlign}>
        {checkboxPosition === ComponentPosition.LEFT && (
          <ListItemCheckbox selected={item.selected} />
        )}
        {avatarRenderer && avatarRenderer(item)}
      </StyledLeftHandContainer>
    )}
    {extraInfoRendererLeft && extraInfoRendererLeft(item)}
    <StyledRightHandContainer>
      <StyledLabelsContainer>
        {titleRenderer
          ? titleRenderer(item)
          : item.title && <StyledTitle>{item.title}</StyledTitle>}
        {subtitleRenderer
          ? subtitleRenderer(item)
          : item.subtitle && <StyledSubtitle>{item.subtitle}</StyledSubtitle>}
      </StyledLabelsContainer>
      {extraInfoRendererRight && extraInfoRendererRight(item)}
      {item.information !== undefined && item.information?.length > 0 ? (
        <StyledInfoGraphicContainer>
          <ListInformationItem information={item.information} />
        </StyledInfoGraphicContainer>
      ) : null}
      {checkboxPosition === ComponentPosition.RIGHT && (
        <StyledListItemCheckboxContainer>
          <ListItemCheckbox selected={item.selected} />
        </StyledListItemCheckboxContainer>
      )}
    </StyledRightHandContainer>
  </StyledRichListItem>
);

export const RichListItem = forwardRef(RichListItemCmp) as <T extends BaseListItemT>(
  props: RichListItemProps<T> & { ref?: ForwardedRef<HTMLLIElement> },
) => JSX.Element;
