import styled from "styled-components";
import { Block } from "@bluecrew/web-react-core";

import { CONTAINER_MAX_WIDTH } from "../../shared/constants";

const PositionBlock = styled(Block)`
  margin: 2.5rem auto;
  max-width: ${CONTAINER_MAX_WIDTH};
`;

export default PositionBlock;
