import React from "react";
import PropTypes from "prop-types";
import ConfirmModal from "./ConfirmModal";
import messages from "../../shared/messages";

type Props = {
  onConfirm: () => void;
  onClose: () => void;
  show: boolean;
  positionName: string;
  currentlyContinuouslyFilling: boolean;
};

const ToggleContinuousFillModal = (props: Props) => {
  const confirmMessages = props.currentlyContinuouslyFilling
    ? messages.DISABLE_CONTINUOUS_FILL.confirm
    : messages.ENABLE_CONTINUOUS_FILL.confirm;
  return (
    <ConfirmModal
      handleConfirmationClick={props.onConfirm}
      show={props.show}
      toggleModal={props.onClose}
      headingText={confirmMessages.heading}
      paragraphText={confirmMessages.body(props.positionName)}
      confirmationButtonText={confirmMessages.button}
      confirmationButtonPalette={"primary"}
      noIcon
    />
  );
};

ToggleContinuousFillModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ToggleContinuousFillModal;
