import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Box, Overlay, Paragraph, Heading } from "@bluecrew/web-react-core";
import { modalWidth } from "../../../styles/variables";

export const StyledOverlay = styled(Overlay)`
  background-color: ${p("white")};
  padding: 0;
`;

export const BasicModalBody = styled(Box)`
  width: ${modalWidth};
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;

  ${Heading} {
    font-size: 1.5rem;
    color: ${p("slate")};
    line-height: 3rem;
  }

  ${Paragraph} {
    margin: 1rem 0;
    font-size: 1rem;
    color: ${p("platinum")};
  }
`;

export const SpinnerShell = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 2rem 2rem 2rem;
`;
