import { FieldValues } from "react-hook-form";
import { HourlyPayFieldType, Wage } from "./HourlyPayField.types";
import { CustomRequirements } from "./CustomRequirementsField.type";
import { Skill, Skills } from "./SkillsetField.types";
import { Requirements } from "./RequirementsField.types";
import { GeofenceFieldType } from "./GeofenceModal.types";
import { Certifications } from "./CertificationsField.type";
// eslint-disable-next-line import/no-cycle
import { Positions } from "./PositionField.types";
// eslint-disable-next-line import/no-cycle
import { WorksiteAddress } from "./Worksite.types";

export interface CreatePositionScreenData {
  skills: Skills;
  positions: Positions;
  geofence?: GeofenceFieldType;
}

interface CreatePositionScreenEvents {
  onSave?: (data: FormFieldTypes) => void;
  onLocationSelect?: (address: WorksiteAddress) => Promise<Wage>;
}

export interface CreatePositionScreenConfig {
  positionDetails?: {
    hourlyPay?: boolean;
  };
}

export interface CreatePositionScreenProps {
  data: CreatePositionScreenData;
  events?: CreatePositionScreenEvents;
  defaultValues: FormFieldTypes;
  config?: CreatePositionScreenConfig;
}

export interface FormFieldTypes extends FieldValues {
  skillset: Skill | null;
  hourlyPay?: HourlyPayFieldType;
  positionTitle: string;
  requirements: Requirements;
  customRequirements: CustomRequirements;
  certifications: Certifications;
  arrivalInstructions: string;
  description: string;
  dressCode: string;
  worksite: WorksiteAddress | null;
  siteDesignation?: SiteDesignation;
  minWarnRadius?: number;
  minBlockRadius?: number;
  generalRequirements: string;
  backgroundCheckRequired?: number;
  drugScreenRequired?: number;
}

export enum SiteDesignation {
  UNKNOWN = "UNKNOWN",
  ONSITE = "ONSITE",
  OFFSITE = "OFFSITE",
}
