import React from "react";
import styled from "styled-components";
import { palette as p } from "styled-tools";
import { Link } from "../index";
import { UserAvatar } from "./UserAvatar";
import { webV1Url } from "../constants/index";
import { CovidPolicyStatus } from "@bluecrew/web-react-employer/src/scripts/components/CovidPolicyStatus";

const states = {
  awaiting: { message: "Awaiting response..." },
  declined: { message: "Declined offer ", reason: "location" },
};

const StyledCard = styled.div`
  display: flex;
  align-items: center;

  .crew-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 8px;
  }

  .crew-member-info {
    display: flex;
    flex-direction: column;

    .crew-member-name {
      color: ${p("slate")};
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .crew-member-status {
      color: ${p("platinum")};
      font-size: 16px;
      line-height: 24px;
    }

    .sub-title-container {
      display: flex;
      align-items: center;
    }
  }

  .user-avatar {
    margin-right: 15px;
    flex-shrink: 0;
    flex-grow: 1;
  }
`;

type CrewMemberCardProps = {
  crewMember: Object;
  status: string;
  timezone?: string;
};

export class CrewMemberCard extends React.Component<CrewMemberCardProps> {
  render() {
    // @ts-ignore
    const { crewMember, status, timezone } = this.props;
    const {
      // @ts-ignore
      covid_exemption_approved_date: covidExemption,
      // @ts-ignore
      covid_testing_latest_date: covidTestingLatestDate,
      // @ts-ignore
      covid_vaccination_date: covidVaccinationDate,
    } = crewMember;
    const covidPolicyStatusEnabled =
      covidExemption || covidTestingLatestDate || covidVaccinationDate;

    return (
      <StyledCard>
        <UserAvatar
          size="md"
          imgSrc={
            // @ts-ignore
            crewMember.avatar_url
          }
          // @ts-ignore
          className="user-avatar"
          fullName={
            // @ts-ignore
            crewMember.full_name
          }
        />
        <div className="crew-container">
          <div className="crew-member-info">
            <Link
              // @ts-ignore
              href={`${webV1Url}/app.html#!/worker/${crewMember.external_id}`}
              target="blank"
            >
              <span className="crew-member-name">
                {
                  // @ts-ignore
                  crewMember.full_name
                }
              </span>
            </Link>
            {status === "awaiting" || status === "declined" ? (
              <div className="sub-title-container">
                <span className="crew-member-status">{states[status].message}</span>
                {covidPolicyStatusEnabled && (
                  <>
                    <StyledSpan>●</StyledSpan>
                    <CovidPolicyStatus
                      timezone={timezone}
                      covidVaccinationDate={covidVaccinationDate}
                      covidTestingLatestDate={covidTestingLatestDate}
                      covidExemption={covidExemption}
                    />
                  </>
                )}
              </div>
            ) : (
              <div className="sub-title-container">
                <span className="crew-member-status">
                  {
                    // @ts-ignore
                    crewMember.shifts_worked
                  }{" "}
                  Shifts
                </span>
                {covidPolicyStatusEnabled && (
                  <>
                    <StyledSpan>●</StyledSpan>
                    <CovidPolicyStatus
                      timezone={timezone}
                      covidVaccinationDate={covidVaccinationDate}
                      covidTestingLatestDate={covidTestingLatestDate}
                      covidExemption={covidExemption}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </StyledCard>
    );
  }
}

const StyledSpan = styled.span`
  vertical-align: middle;
  padding: 0 6px;
  font-size: 3px; /* workaround for rem bug in Chrome */
  height: 1rem;
  line-height: 1rem;
`;
