import React from "react";

export const BackIcon = () => (
  <svg width="20" height="46" viewBox="0 0 20 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6568 30.0711L6.29288 23.7072C5.90236 23.3166 5.90236 22.6835 6.29288 22.2929L12.6568 15.929C13.0474 15.5385 13.6805 15.5385 14.0711 15.929C14.4616 16.3195 14.4616 16.9527 14.0711 17.3432L8.41421 23.0001L14.0711 28.6569C14.4616 29.0474 14.4616 29.6806 14.0711 30.0711C13.6805 30.4616 13.0474 30.4616 12.6568 30.0711Z"
      fill="#3246F0"
    />
  </svg>
);
