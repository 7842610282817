// REDUX SAGA ROOT
// =============================================================================

import { SagaIterator } from "redux-saga";
import { Effect, call, all } from "redux-saga/effects";

import auth from "./auth";
import job from "./job";
import position from "./position";
import schedule from "./schedule";
import minWage from "./minWage";
import company from "./company";
import segment from "./segment";
import intercom from "./intercom";

type WatcherMap = {
  [key: string]: () => SagaIterator;
};

const reduceWatchers = (acc: Array<Effect>, watchers: WatcherMap) =>
  acc.concat(Object.keys(watchers).map((name) => call(watchers[name])));

export default function* rootSaga(): Generator<any, any, any> {
  yield all(
    [job, auth, position, schedule, minWage, company, segment, intercom].reduce(reduceWatchers, []),
  );
}
