import React from "react";
import { styled } from "baseui";
import { themedStyled } from "@bluecrew/blueprint-web";

type FieldTextLengthProps = {
  focused: boolean;
  error: boolean;
  minLength: number;
  maxLength: number;
  length: number;
};

export const FieldTextLength = ({
  focused,
  error,
  minLength,
  maxLength,
  length,
}: FieldTextLengthProps) => {
  let charsLeft = -1;

  // Show minimum characters required when focus is lost and input not valid.
  if (error && !focused && length < minLength) {
    charsLeft = minLength - length;
    return (
      <FieldTextLengthWrapper>
        <ErrorText>
          {charsLeft} more character{charsLeft === 1 ? "" : "s"} required
        </ErrorText>
      </FieldTextLengthWrapper>
    );
  }

  // Show over limit message when in error state or in focus.
  if ((error || focused) && length > maxLength) {
    charsLeft = length - maxLength;
    return (
      <FieldTextLengthWrapper>
        <ErrorText>
          {charsLeft} character{charsLeft === 1 ? "" : "s"} over limit
        </ErrorText>
      </FieldTextLengthWrapper>
    );
  }

  // Show current number of characters only when in focus.
  if (focused) {
    charsLeft = maxLength - length;
    return (
      <FieldTextLengthWrapper>
        <InfoText>
          {charsLeft} character{charsLeft === 1 ? "" : "s"} left
        </InfoText>
      </FieldTextLengthWrapper>
    );
  }

  return <></>;
};

const FieldTextLengthWrapper = styled("div", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale300,
  textAlign: "right",
}));

const ErrorText = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font250,
  lineHeight: $theme.sizing.scale800,
  color: $theme.colors.coral,
}));

const InfoText = themedStyled("span", ({ $theme }) => ({
  ...$theme.typography.font250,
  lineHeight: $theme.sizing.scale800,
  fontStyle: "normal",
  fontWeight: "normal",
  color: $theme.colors.slate,
}));
