import { UserAvatar } from "@bluecrew/blueprint-web";
import { Link } from "@bluecrew/web-react-core";
import { webV1Url } from "@bluecrew/web-react-core/src/constants";
import React from "react";
import styled from "styled-components";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { CrewMember, JobRequest } from "../../../api/bluecrew/types";
import { CovidPolicyStatus } from "../../../components/CovidPolicyStatus";
import { JobRequestCrewMemberTableStyles } from "../SharedComponents";
import { ReactTableComponent } from "./ReactTableComponent";

const StyledLink = styled(Link)`
  cursor: pointer;
  font-size: 16px;
  font-weight: medium;
`;
interface JobRequestCrewMemberTableProps {
  crewMembers: JobRequest["crew_members"];
}

export const JobRequestCrewMemberTable = ({ crewMembers }: JobRequestCrewMemberTableProps) => {
  const columns = React.useMemo(
    () => [
      {
        id: "name",
        Header: "Crew Member",
        className: "crew-member",
        accessor: "name",
        Cell: ({ rowData }) => <CrewMemberDetailsTableCell crewMember={rowData} />,
      },
      {
        id: "status",
        Header: "Status",
        className: "status",
        accessor: "status",
        Cell: ({ rowData }) => <CrewMemberStatusTableCell crewMember={rowData} />,
      },
      {
        id: "status-updated",
        Header: "Status Updated",
        className: "status-updated",
        accessor: "updated_at",
        Cell: ({ rowData }) => <CrewMemberStatusUpdatedTableCell crewMember={rowData} />,
      },
    ],
    [],
  );

  return (
    <JobRequestCrewMemberTableStyles>
      <ReactTableComponent
        noRowsMessage="No crew members added to job request yet"
        columns={columns}
        data={crewMembers}
      />
    </JobRequestCrewMemberTableStyles>
  );
};

interface CrewMemberDetailsTableCellProps {
  crewMember: CrewMember;
}

const CrewMemberDetailsTableCell = ({ crewMember }: CrewMemberDetailsTableCellProps) => (
  <CrewMemberDetailsTableCellStyled>
    <UserAvatar
      imgSrc={crewMember.avatar_url || undefined}
      fullName={`${crewMember.first_name} ${crewMember.last_name}`}
      size="sm"
    />
    <Spacer $type={SpacerType.horizontal} $size="16px" />
    <div>
      <div>
        <StyledLink
          data-pendo-key={`JobRequestModalCrewMemberLink`}
          href={`${webV1Url}/app.html#!/resume/${crewMember.external_id}`}
          target="blank"
        >
          {crewMember.first_name} {crewMember.last_name}
        </StyledLink>
      </div>

      <CrewMemberDetailsTableCellStyled>
        <div>
          {crewMember.shifts_worked} shift
          {crewMember.shifts_worked !== 1 && "s"} worked
        </div>
        <Spacer $type={SpacerType.horizontal} $size="16px" />
        <div>
          <CovidPolicyStatus
            covidExemption={crewMember.covid_exemption_approved_date}
            covidTestingLatestDate={crewMember.covid_testing_latest_date}
            covidVaccinationDate={crewMember.covid_vaccination_date}
          />
        </div>
      </CrewMemberDetailsTableCellStyled>
    </div>
  </CrewMemberDetailsTableCellStyled>
);

const CrewMemberDetailsTableCellStyled = styled("div")`
  display: flex,
  alignItems: center,
  gap: 16px
`;

interface CrewMemberStatusTableCellProps {
  crewMember: CrewMember;
}

const CrewMemberDetailsTableStatusStyled = styled.div`
  color: ${(props) => props.color || undefined};
  fontsize: 14px;
`;

const CrewMemberStatusTableCell = ({ crewMember }: CrewMemberStatusTableCellProps) => (
  <CrewMemberDetailsTableStatusStyled color={crewMember.status === "On Job" ? "green" : undefined}>
    {crewMember.status}
  </CrewMemberDetailsTableStatusStyled>
);

interface CrewMemberStatusUpdatedTableCellProps {
  crewMember: CrewMember;
}

const CrewMemberStatusUpdatedTableCell = ({
  crewMember,
}: CrewMemberStatusUpdatedTableCellProps) => {
  const dateUpdatedAt = new Date(crewMember.updated_at).toDateString();
  const timeUpdatedAt = new Date(crewMember.updated_at).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    <CrewMemberDetailsTableStatusStyled>
      {dateUpdatedAt} at {timeUpdatedAt}
    </CrewMemberDetailsTableStatusStyled>
  );
};
