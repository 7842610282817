export const DISABLED_FIELDS = {
  address: false,
  arrivalInstructions: false,
  baseWage: true,
  description: false,
  dressCode: false,
  generalRequirements: true,
  positionTitle: false,
  requirements: true,
  skillsSet: true,
};
