import {
  Button,
  getMargin,
  Input,
  Modal,
  ModalKind,
  Select,
  useThemedStyletron,
  EMAIL_REGEX,
  SUPERVISOR_PHONE_REGEX,
} from "@bluecrew/blueprint-web";
import { styled } from "baseui";
import { Option } from "baseui/select";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { EMPLOYER_EMAIL_MAX_LENGTH, EMPLOYER_FIRST_OR_LAST_NAME_MAX_LENGTH } from "./consts";
import { FieldRowWrapper } from "../FieldRowWrapper";
import { ModalFooterWrapper } from "../ModalFooterWrapper";
import { FieldTextLength } from "../FieldTextLength";
import { Spacer, SpacerType } from "../../../components/Spacer";
import { CreateSupervisorModalProps } from "../types/propTypes/CreateSupervisorModal.types";
import {
  CreateSupervisorInput,
  SupervisorAccountType,
} from "../types/propTypes/SupervisorField.types";

const accountTypeListItems: Readonly<Option[]> = [
  {
    id: SupervisorAccountType.CLIENT_ADMIN,
    label: "Admin (Full access)",
  },
  {
    id: SupervisorAccountType.MANAGER,
    label: "Manager (Can't post jobs or see invoices)",
  },
  {
    id: SupervisorAccountType.NO_ACCOUNT,
    label: "No access (will not create a user account)",
  },
];

const mapAccountTypeSelectionToEnum = (selection: Readonly<Option[]>) => {
  if (selection.length) {
    return selection[0].id;
  }
  return SupervisorAccountType.CLIENT_ADMIN;
};

const mapEnumToAccountTypeSelection = (accountType: SupervisorAccountType) => {
  switch (accountType) {
    case SupervisorAccountType.CLIENT_ADMIN:
      return [accountTypeListItems[0]];
    case SupervisorAccountType.MANAGER:
      return [accountTypeListItems[1]];
    case SupervisorAccountType.NO_ACCOUNT:
      return [accountTypeListItems[2]];
    default:
      return [accountTypeListItems[0]];
  }
};

export const CreateSupervisorModal = ({
  control,
  errors,
  responseError,
  isOpen,
  loading = false,
  onSubmit = () => {},
  onClose = () => {},
}: CreateSupervisorModalProps) => {
  const [, theme] = useThemedStyletron();

  const accountType = useWatch<CreateSupervisorInput["accountType"]>({
    name: "accountType",
    defaultValue: SupervisorAccountType.CLIENT_ADMIN,
    control,
  });

  useEffect(() => {
    if (accountType === SupervisorAccountType.NO_ACCOUNT) {
      control.setValue("email", "", { shouldValidate: true });
    }
  }, [accountType]);

  let buttonLabel = "Done";

  if (loading) {
    buttonLabel = "Creating supervisor...";
  } else if (responseError) {
    buttonLabel = "Try again";
  }

  const [fieldFocus, onFieldFocus] = useState("");

  return (
    <div data-testid="create-supervisor-modal">
      <Modal
        header="Add a supervisor"
        kind={ModalKind.DEFAULT}
        body={
          <ModalBodyWrapper>
            <FieldRowWrapper>
              <Controller
                name="firstName"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <InputWrapper>
                    <Input
                      placeholder="First name"
                      value={props.value}
                      error={!!errors.firstName}
                      onChange={props.onChange}
                      onFocus={() => onFieldFocus(props.name)}
                      onBlur={() => {
                        onFieldFocus("");
                        props.onBlur();
                      }}
                      maxLength={EMPLOYER_FIRST_OR_LAST_NAME_MAX_LENGTH}
                      disabled={loading}
                    />
                    <FieldTextLength
                      focused={fieldFocus === props.name}
                      error={!!errors.firstName}
                      minLength={0}
                      maxLength={EMPLOYER_FIRST_OR_LAST_NAME_MAX_LENGTH}
                      length={String(props.value).length}
                    />
                  </InputWrapper>
                )}
              />
            </FieldRowWrapper>
            <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
            <FieldRowWrapper>
              <Controller
                name="lastName"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <InputWrapper>
                    <Input
                      placeholder="Last name"
                      value={props.value}
                      error={!!errors.lastName}
                      onChange={props.onChange}
                      onFocus={() => onFieldFocus(props.name)}
                      onBlur={() => {
                        onFieldFocus("");
                        props.onBlur();
                      }}
                      maxLength={EMPLOYER_FIRST_OR_LAST_NAME_MAX_LENGTH}
                      disabled={loading}
                    />
                    <FieldTextLength
                      focused={fieldFocus === props.name}
                      error={!!errors.lastName}
                      minLength={0}
                      maxLength={EMPLOYER_FIRST_OR_LAST_NAME_MAX_LENGTH}
                      length={String(props.value).length}
                    />
                  </InputWrapper>
                )}
              />
            </FieldRowWrapper>
            <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
            <FieldRowWrapper>
              <InputWrapper>
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  rules={{
                    validate(value: string) {
                      const { accountType: currentAccountType } = control.getValues();
                      if (currentAccountType === SupervisorAccountType.NO_ACCOUNT) {
                        return true;
                      }
                      if (!value) {
                        return false;
                      }
                      if (!EMAIL_REGEX.test(value)) {
                        return "Please enter a valid email address.";
                      }
                      return true;
                    },
                  }}
                  render={(props) => (
                    <Input
                      placeholder={
                        accountType === SupervisorAccountType.NO_ACCOUNT
                          ? "Email address (not required)"
                          : "Email address"
                      }
                      value={props.value}
                      error={!!errors.email}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      maxLength={EMPLOYER_EMAIL_MAX_LENGTH}
                      disabled={loading || accountType === SupervisorAccountType.NO_ACCOUNT}
                    />
                  )}
                />
                {errors.email && errors.email.message && (
                  <ErrorText data-testid="email-error-msg">{errors.email.message}</ErrorText>
                )}
              </InputWrapper>
            </FieldRowWrapper>
            <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
            <FieldRowWrapper>
              <InputWrapper>
                <Controller
                  name="phone"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: SUPERVISOR_PHONE_REGEX,
                      message: "Please enter a valid phone number.",
                    },
                  }}
                  render={(props) => (
                    <Input
                      placeholder="Mobile phone number"
                      mask="999-999-9999"
                      value={props.value}
                      error={!!errors.phone}
                      onChange={props.onChange}
                      onBlur={props.onBlur}
                      disabled={loading}
                    />
                  )}
                />
                {errors.phone && errors.phone.type === "pattern" && (
                  <ErrorText>{errors.phone.message}</ErrorText>
                )}
              </InputWrapper>
            </FieldRowWrapper>
            <Spacer $type={SpacerType.vertical} $size={theme.sizing.scale600} />
            <FieldRowWrapper>
              <Controller
                name="accountType"
                control={control}
                rules={{ required: true }}
                defaultValue={SupervisorAccountType.CLIENT_ADMIN}
                render={(props) => (
                  <Select
                    value={mapEnumToAccountTypeSelection(props.value)}
                    error={!!errors.accountType}
                    options={accountTypeListItems}
                    searchable={false}
                    onChange={(v) => {
                      props.onChange(mapAccountTypeSelectionToEnum(v));
                    }}
                    onBlur={props.onBlur}
                    disabled={loading}
                  />
                )}
              />
            </FieldRowWrapper>
          </ModalBodyWrapper>
        }
        footer={
          <ModalFooterWrapper>
            <InputWrapper>
              <Button
                width="100%"
                disabled={loading}
                onClick={() => {
                  control.trigger().then((inputsValid) => {
                    if (inputsValid) {
                      onSubmit();
                    }
                  });
                }}
              >
                {buttonLabel}
              </Button>
              {responseError && <ErrorText>Oops! Something went wrong.</ErrorText>}
            </InputWrapper>
          </ModalFooterWrapper>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};

const ModalBodyWrapper = styled("div", ({ $theme }) => ({
  ...getMargin($theme.sizing.scale600),
  marginBottom: 0,
}));

const InputWrapper = styled("div", () => ({ width: "100%" }));

const ErrorText = styled("p", ({ $theme }) => ({
  color: $theme.colors.negative,
  ...$theme.typography.font200,
  margin: "0px",
}));
