import { styled } from "baseui";

interface ToolbarProps {
  $justifyContent?: string;
}

export const Toolbar = styled<ToolbarProps, "div">("div", ({ $justifyContent, $theme }) => ({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: $justifyContent,
  margin: `${$theme.sizing.scale900} 0`,
}));
