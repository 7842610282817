import styled from "styled-components";
import { theme } from "styled-tools";
import { BaseStyleCss, BaseStyleProps } from "./Box";
import { bool, value } from "./styledProps";

interface GridProps extends BaseStyleProps {
  row?: boolean;
  column?: boolean;
  dense?: boolean;
  gap?: string | number;
  template?: string | number;
  areas?: string | number;
  columns?: string | number;
  rows?: string | number;
  autoColumns?: string | number;
  autoRows?: string | number;
}

const Grid = styled.div<GridProps>`
  ${BaseStyleCss};
  display: grid;
  &&& {
    ${bool("grid-auto-flow", ["row", "column", "dense"])}
    ${value("grid-gap", "gap")}
    ${value("grid-template", "template")}
    ${value("grid-template-areas", "areas")}
    ${value("grid-template-columns", "columns")}
    ${value("grid-template-rows", "rows")}
    ${value("grid-auto-columns", "autoColumns")}
    ${value("grid-auto-rows", "autoRows")}
  }
  ${theme("Grid")};
`;

interface GridItemProps extends BaseStyleProps {
  area?: string | number;
  column?: string | number;
  row?: string | number;
  columnStart?: string | number;
  columnEnd?: string | number;
  rowStart?: string | number;
  rowEnd?: string | number;
}

const GridItem = styled.div<GridItemProps>`
  ${BaseStyleCss};
  &&& {
    ${value("grid-area", "area")};
    ${value("grid-column", "column")};
    ${value("grid-row", "row")};
    ${value("grid-column-start", "columnStart")};
    ${value("grid-column-end", "columnEnd")};
    ${value("grid-row-start", "rowStart")};
    ${value("grid-row-end", "rowEnd")};
  }
  ${theme("GridItem")};
`;

export { Grid, GridItem };
